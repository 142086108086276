<template>
  <div>
      <b-container v-if="dataRefresh">
          <b-card header-tag="header">
              <h5 slot="header">{{ dataShareType.toUpperCase() }} <em v-if="userData.Role == 'admin'"> ( id: {{ itemId }} )</em></h5>
           <posttype v-if="returnedItem != '' && !viewHere" :post="returnedItem" :showPost="true" />
           <training v-if="returnedItem != '' && viewHere && dataShareType == 'trainingrecord'" :training="returnedItem[0]" />
          </b-card>
      </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import posttype from '../secondarymenu/userpaneltab/userpanelPosts.vue'
import training from '../tracking/training/trainingView/trainingRecordView.vue'
import { bus } from '../../main'
export default {
props:["dataShareType"],
components:{
    'posttype' : posttype,
    training
},
  data () {
    return {
        returnedItem: '',
        itemId: '',
        dataRefresh: true,
        viewHere: false,
    }
  },
  created(){
      this.itemId = this.$route.query.id
      this.getDataShareInfo()
      bus.$on('refreshDataShare', this.getDataShareInfo)
  },
  computed:{
      ...mapState([
          'getSessionData',
          'userData'
      ])
  },
   methods:{
       getDataShareInfo(){
            this.dataRefresh = false
            if(this.dataShareType == 'trainingrecord'){
              var content = '/training/'
              this.viewHere = true
            } else {
              var content = '/content/'
            }
            axios({
            method: 'get',
            url: '/api/v1' + content + this.dataShareType + '/' + this.$route.query.id,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
             var datashareitem = response.data
             datashareitem['contentType'] = this.dataShareType
             this.returnedItem = datashareitem
             this.$nextTick(function(){
               this.dataRefresh = true
             })

       })

       },
  },
}
</script>

<style scoped>
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
