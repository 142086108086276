<template>
  <b-container class="hello pt-2" fluid>
    <h6 v-if="inspectionCodes.length > 0 && checkForChanges.length > 0" class="text-success">***Unsaved Changes to Code Violations, please click update below***</h6>
        <download-excel  class="ml-2"
    v-if="fixDataExcel.length > 0"
    name="Download Current Code Violation List"
    :data="fixDataExcel">
    <b-btn variant="primary">Full Excel</b-btn>
    </download-excel>
    <b-btn variant="primary" @click="setAsActive" v-if="userData.role == 'admin' && false">Deactivate Codes</b-btn>
    <b-btn variant="success" v-if="inspectionCodes.length > 0 && checkForChanges.length > 0 && checkRole" @click="updateChanges">Update</b-btn>
      <b-container fluid class="pt-2" v-if="original && inspectionCodes.length > 0">
        <b-card  v-for="group in inspectionCodesReturned"  header-tag="header" :key="group.tempId">
          <b-row slot="header">
            <b-col v-if="editId != group.tempId"><h5 class="font-weight-bold">{{ group.group }}</h5></b-col>
            <b-col v-if="editId == group.tempId"><b-form-input type="text" v-model="editGroupName" /></b-col>
            <b-col cols="*">
              <b-btn v-if="editId != group.tempId && checkRole" size="sm" variant="info" @click="editId = group.tempId, editGroupName = group.group">Edit Name</b-btn>
              <b-btn v-if="editId != group.tempId && checkRole" size="sm" variant="primary" @click="addNewVioloation(group.group)">Add Violation</b-btn>
              <b-btn v-if="editId == group.tempId && checkRole" size="sm" variant="primary" @click="saveUpdatedGroupName(group.group)">Save</b-btn>
              <b-btn v-if="editId == group.tempId && checkRole" size="sm" variant="danger" @click="editId = '', editGroupName = ''">Cancel</b-btn>
            </b-col>
            </b-row>
           <b-table small :fields="fieldsReturned" :items="group.violations">
              <template slot="codeViolation" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeViolation }}</span>
                <b-form-input v-if="currentTableEditId == data.item.id" v-model="data.item.codeViolation" />
                </template>
                <template slot="codeCompliance" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeCompliance }}</span>
                <b-form-input v-if="currentTableEditId == data.item.id" v-model="data.item.codeCompliance" />
                </template>
                <template slot="codeReference" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeReference }}</span>
                <b-form-input v-if="currentTableEditId == data.item.id" v-model="data.item.codeReference" />
                </template>
               <template slot="codeFee" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeFee }}</span>
                <b-form-input type="number" v-if="currentTableEditId == data.item.id" v-model="data.item.codeFee" />
                </template>
                <template slot="active" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id"><b-form-checkbox  v-model="data.item.active" :disabled="true" /></span>
                <b-form-checkbox v-if="currentTableEditId == data.item.id" v-model="data.item.active" />
                </template>
                <template slot="edit" slot-scope="data">
                  <b-btn :variant="currentTableEditId == data.item.id ? 'danger' : 'primary'" 
                      :disabled="currentTableEditId == data.item.id && (data.item.codeViolation == '' || data.item.codeCompliance == '' || data.item.codeReference == '')"
                      size="sm" 
                      @click="currentTableEditId == data.item.id ? (currentTableEditId = '', updateDataObj(data.item)) : currentTableEditId = data.item.id">
                      {{ currentTableEditId == data.item.id ? 'save' : 'edit' }}</b-btn>
                  </template>
             </b-table>
          </b-card>
        </b-container>
      <b-btn v-if="userData.role == 'admin'" @click="showBulkLoad = !showBulkLoad">Bulk Load</b-btn>
      <codeviolationExcel v-if="userData.role == 'admin' && showBulkLoad" />
    </b-container>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from "moment";
import { mapState } from "vuex";
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import axios from "axios";
import codeviolationExcel from "./codeViolationsExcel.vue";
import { bus } from '../../../main.js';
import JsonExcel from 'vue-json-excel';
export default {
  props: ['inspectionCodes'],
  name: "codevioloations",
  components: {
    codeviolationExcel,
    'download-excel' : JsonExcel,
  },
  data() {
    return {
      showBulkLoad: false,
      original: '',
      editcodes: '',
      editId: '',
      editGroupName: '',
      currentTableEditId: '',

    };
  },
  created() {
    this.original = _.cloneDeep(this.inspectionCodes)
    this.editcodes = _.cloneDeep(this.inspectionCodes)
  },
  mounted() {

  },
  computed: {
    ...mapState([
      "deptContactData",
      "departmentBlogs",
      "getSessionData",
      "userData"
    ]),
    fieldsReturned(){
      var array = ['codeViolation', 'codeCompliance', 'codeReference', 'codeFee', 'active']
      if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
        array.push('edit')
      }
      return array
    },
    checkRole(){
      if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
       return true
      } return false
    },
    checkForChanges(){
      var original = this.original
      var changes = this.editcodes.filter(p => {
            if(!p.id || (p.id.split('_')[0] && p.id.split('_')[0] == 'new')){
              if(!p.presave){
               return p
              }
              } else {
             var org = original.find(f => f.id == p.id)
              if(!_.isEqual(org, p)){
                 return p
              }
        }
        
        
      })
      return changes
    },
    fixDataExcel(){
          var array = _.cloneDeep(this.editcodes)
          array = array.map(item => {
              var obj = item
              delete obj.id
              delete obj.updatedAt
              delete obj.createdAt
              delete obj.codeFee
              obj.codeFee = item.codeFee == '' ? null : obj.codeFee
              return obj
          })
          return array
        },
    inspectionCodesReturned(){
      var array = _.cloneDeep(this.editcodes)
      var parents = [... new Set(array.map(p => p.codeGroup))]
      var order = _.sortBy(parents)
      return order.map(item => {
          var obj = {}
          obj['tempId'] = _.uniqueId()
          obj['group'] = item
          var hold = array.filter(p => p.codeGroup == item)
          obj['violations'] = _.orderBy(hold, ['codeViolation'], ['asc'])
          return obj
      })
    },
  },
  watcher: {},
  methods: {
    setAsActive(){
      var array = this.editcodes.map(p => p.id)
      for(var i in array){
      axios({
        method: "patch",
        url: "/api/v1/preplan/inspectioncodes/" + array[i],
        data: {active: false},
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
       // console.log(response.data)
 
      });
      }
    },
    updateDataObj(data){
      var index = this.editcodes.map(p => p.id).indexOf(data.id)
      this.editcodes.splice(index, 1)
      if(data.presave){
        data.presave = false
      }
      this.editcodes.push(data)
    },
    addNewVioloation(group){
      var obj = { id: 'new_' + moment().toISOString(), codeGroup: group, codeViolation: '', codeCompliance: '', codeReference: '', codeFee: null, active: true, presave: true }
      this.editcodes.push(obj)
      this.currentTableEditId = obj.id
    },
    saveUpdatedGroupName(group){
      var array = this.editcodes
      this.editcodes = array.map(item => {
          var obj = item
          if(obj.codeGroup == group){
            obj.codeGroup = this.editGroupName
          }
          return obj
      })
      this.editGroupName = ''
      this.editId = ''
      
    },
    updateChanges(){
      var changes = this.checkForChanges
      this.currentTableEditId = ''
      this.editId = ''
      this.editGroupName = ''
      var length = changes.length
      var count = 0
      for(var c in changes){
        var p = changes[c]
        var type = p.id.split('_')[0] && p.id.split('_')[0] == 'new' ? 'post' : 'patch'
        if(type == 'post'){
          var id = ''
        } else {
          var id = '/' + p.id
        }
        if(p.codeFee == '' || p.codeFee == 0){
          p.codeFee = null
        }
        delete p.createdAt
        delete p.updatedAt
        delete p.id
        delete p.presave
        axios({
        method: type,
        url: "/api/v1/preplan/inspectioncodes" + id,
        data: p,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
          count = count + 1
          if(count == length){
            this.$emit('updateInspectionCodes')
          }
 
      });
      }
    },


  }
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.blogCardClass {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space: nowrap;
}
.userDataWrap {
  text-align: center;
}
.userRank {
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer {
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: 0.4;
}
.headerContactRow {
  border-radius: 5px;
}
.A-shiftROW {
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.B-shiftROW {
  border: 1px solid #201b9f;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.C-shiftROW {
  border: 1px solid #0fb906;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.Day-shiftROW {
  border: 1px solid #e4cb07;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.A-shift {
  background: #e2070d;
  background: -moz-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift {
  background: #201b9f;
  background: -moz-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.C-shift {
  background: #0fb906;
  background: -moz-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.Day-shift {
  background: #e4cb07;
  background: -moz-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;
}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138, 138, 138, 1);
  color: #fff;
  border-radius: 10px;
}
h1,
h2 {
  font-weight: normal;
}

@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
