import moment from 'moment'
import { parse } from 'papaparse'

var custom = {
  custom_export_gfr (user, originalCode){
    const codeOveride = [{"rank":"F/R Chief","override":"100"},{"rank":"Deputy F/R Chief","override":"100"},{"rank":"Deputy Fire Marshall Lieutenant","override":"105"},{"rank":"Battalion Chief","override":"100"},{"rank":"Battalion Chief Shift","override":"101"},{"rank":"Battalion Chief/Fire Marshal","override":"100"},{"rank":"Captain/Deputy Fire Marshall","override":"100"},{"rank":"Captain","override":"100"},{"rank":"Captain Shift","override":"101"},{"rank":"EMS Specialist","override":"110"},{"rank":"EMT-B","override":"105"},{"rank":"EMT-P","override":"105"},{"rank":"Lieutenant","override":"110"},{"rank":"Squad Leader","override":"110"},{"rank":"FR2","override":"110"},{"rank":"Inspector","override":"105"},{"rank":"Admin. Assistant","override":"105"},{"rank":"F/R Admin. Assistant","override":"105"},{"rank":"FR1","override":"110"},{"rank":"Fire and Life Saftey Educator/PIO","override":"105"},{"rank":"FR Trainee","override":"110"},{"rank":"Part-time","override":"190"}]
    if(user){
      var payrollCode = originalCode
      var newExport = codeOveride.find(p => p.rank.replace(' ', '') == user.deptRank.replace(' ', ''))
    //  console.log('newExport', newExport)
    //  console.log('export', newExport.override)
      if(newExport && newExport.override && payrollCode){
       // console.log('Overide')
        payrollCode['payrollCodeExport'] = newExport.override
        return payrollCode
      } 
      console.log('Error in Overide Code', user, originalCode)
      return originalCode
    }
    console.log('ERROR IN USER')
    console.log(user)
    return originalCode

  },
  custom_payroll (data) {
    const array = data
    var hold = []
    for (var i in array) {
      var shifts = array[i].shifts
      var total = array[i].shifts.map(t => t.payrollModHours)
      var holdTotal = 0
      for (var t in total) {
        holdTotal = holdTotal + parseFloat(total[t])
      }
      var obj = { name: array[i].lastName + ', ' + array[i].firstName, employeeRank: array[i].deptRank, employeeId: array[i].employeeId, entryDate: '', entryTime: '', entryCode: '', entryName: '', entryType: '', entryHours: '', totalHours: holdTotal, note: '' }
      hold.push(obj)
      for (var s in shifts) {
        var sobj = { name: array[i].lastName + ', ' + array[i].firstName, employeeRank: array[i].deptRank, employeeId: array[i].employeeId, entryDate: moment(shifts[s].payrollModDatetime).format('YYYY-MM-DD'), entryTime: moment(shifts[s].payrollModDatetime).format('HH:mm:ss'), entryCode: shifts[s].payrollModExport, entryName: shifts[s].payrollModCode, entryType: shifts[s].payrollModType, entryHours: shifts[s].payrollModHours, totalHours: '', note: shifts[s].payrollModNote }
        hold.push(sobj)
      }
    }
    return hold
  },
    custom_payroll_scfd (data) {
    const array = data
    var hold = []
    for (var i in array) {
      var shifts = array[i].shifts
      for (var s in shifts) {
        var endTime = moment(shifts[s].payrollModDatetime).add(shifts[s].payrollModHours, 'hours')
        var id = array[i].employeeId.toString()
        var note = shifts[s].payrollModNote ? shifts[s].payrollModNote : ''
        var sobj = {
          EmployeeId: id,
          Person_Name: array[i].lastName + ', ' + array[i].firstName,
          Start_DTS: moment(shifts[s].payrollModDatetime).utc(), //.format('MM/DD/YYYY HH:mm'),
          Payroll_Code: shifts[s].payrollModCode,
          Account_No: '',
          End_DTS: moment(endTime).utc(), //.format('MM/DD/YYYY HH:mm'),
          Hours_worked: shifts[s].payrollModHours,
          Permanent_Shift_Name: array[i].shiftAssignment,
          Category: shifts[s].payrollModExport,
          Note: note,
        }
        hold.push(sobj)
      }
    }
    return hold
  },
  custom_payroll_gfr (data, start) {
    const array = data
    // console.log(data)
    var hold = []
    for (var i in array) {
      var shifts = array[i].shifts
      for (var s in shifts) {
        var id = array[i].employeeId.toString()
        var sobj = {
          employee: id,
          from: moment(shifts[s].payrollModDatetime).format('MM/DD/YYYY'),
          through: moment(shifts[s].payrollModDatetime).format('MM/DD/YYYY'),
          payrollCode: shifts[s].payrollModExport,
          causedByPayrollCode: shifts[s].payrollModType.match('leave') && shifts[s].payrollModExport != 385 ? 'Y' : 'N',
          hours: shifts[s].payrollModHours,
          Period: moment(shifts[s].payrollModDatetime).isSame(moment(start, 'YYYY-MM-DD'), 'day') ? 'NEXT' : 'CURRENT'
        }
        hold.push(sobj)
        if(((shifts[s].payrollModExport == '110' && moment(shifts[s].payrollModDatetime).format('HH:mm:ss') == '08:00:00' && shifts[s].payrollModCode != 'Exchange Off') || 
        (moment(shifts[s].payrollModDatetime).format('HH:mm:ss') == '08:00:00' && shifts[s].payrollModCode == 'Exchange On' )) && !shifts[s].daily){
          var aobj = {
            employee: id,
            from: moment(shifts[s].payrollModDatetime).format('MM/DD/YYYY'),
            through: moment(shifts[s].payrollModDatetime).format('MM/DD/YYYY'),
            payrollCode: 200,
            causedByPayrollCode: shifts[s].payrollModType.match('leave') ? 'Y' : 'N',
            hours: 0.25,
            Period: moment(shifts[s].payrollModDatetime).isSame(moment(start, 'YYYY-MM-DD'), 'day') ? 'NEXT' : 'CURRENT'
          }
          hold.push(aobj)
        }

      }
    }
    return hold.filter(p => p.payrollCode != 100 && p.payrollCode != '100' && p.payrollCode != 'Remove' && p.payrollCode != 101 && p.payrollCode != '101')
  },
  custom_payroll_lbkfr (data, stations) {
    const array = data
    var hold = []
    for (var i in array) {
      var shifts = array[i].shifts
      for (var s in shifts) {
        var endTime = shifts[s].payrollModDollar ? moment(shifts[s].payrollModDatetime) : moment(shifts[s].payrollModDatetime).add(shifts[s].payrollModHours, 'hours')
        var id = array[i].employeeId.toString()
        var note = shifts[s].payrollModNote ? shifts[s].payrollModNote : ''
        var sobj = {
          UniqueId: id,
          BadgeId: id,
          TimeEntryType: shifts[s].payrollModExport,
          StartDate: moment(shifts[s].payrollModDatetime).format('MM/DD/YYYY'),
          StartTime: moment(shifts[s].payrollModDatetime).format('HH:mm'),
          EndDate: moment(endTime).format('MM/DD/YYYY'),
          EndTime: moment(endTime).format('HH:mm'),
          Duration: shifts[s].payrollModHours,
          Location: '',
          Department: stations && shifts[s].timecardDepartment && 
                      stations.find(p => p.stationName == shifts[s].timecardDepartment) && 
                      stations.find(p => p.stationName == shifts[s].timecardDepartment).stationPayCode ? 
                      stations.find(p => p.stationName == shifts[s].timecardDepartment).stationPayCode : 
                      array[i].stationAssignment &&
                      stations && stations.find(p => p.stationName == array[i].stationAssignment) &&
                      stations.find(p => p.stationName == array[i].stationAssignment).stationPayCode ? 
                      stations.find(p => p.stationName == array[i].stationAssignment).stationPayCode : '',
          Shift: '',
          WorkOrder: '',
          Job: '',
          Project: '',
          Account: '',
          PlanningNumber: '',
          Code: '',
          SubCode: '',
          CustomSelect1: '',
          CustomSelect2: '',
          CustomSelect3: '',
          CustomSelect4: '',
          CustomTier1: '',
          CustomTier2: '',
          CustomTier3: '',
          CustomText1: array[i].lastName,
          CustomText2: array[i].firstName,
          CustomText3: '',
          CustomText4: shifts[s].payrollModType,
          Comment: shifts[s].payrollModCode + ' ' + note,
          scheduleViolationOverride: false
        }
        hold.push(sobj)
      }
    }
    return hold
  }
}
export default custom
