<template>
  <b-container class="hello pt-2 px-1 px-md-2" fluid>
    <h3 v-if="business && business.businessTableData && formTabTitle">{{ getTabTitle(business.businessTableData, formTabTitle, 'title') }} | {{ inspectionPreviousId ? 'Reinspection ' : 'Annual/Periodic' }} </h3>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Check List" class="px-1 px-md-2">
      <b-container fluid  v-if="original && inspectionCodes.length > 0" class="px-1 px-md-2 pt-2">
        <b-card body-class="px-1 px-md-2" v-for="group in inspectionCodesReturnedWatch"  header-tag="header" :key="group.tempId" header-bg-variant="primary" :no-body="selectedParent == group.tempId ? false : true">
          <b-row slot="header" class="pointer" @click="selectedParent = group.tempId">
            <b-col><h5 class="font-weight-bold text-light">{{ group.group }} <em>(click to view) </em></h5></b-col>
            </b-row>
            <div v-if="selectedParent == group.tempId">
                 <b-card no-body  v-for="violation in group.violations" :key="violation.id" >
            <b-container no-body class="pt-2 pb-1">
           
              <b-row>
                <b-col cols="*" class="ml-2 pt-1" align="left"><b-badge :variant="!violation.complianceOn ? 'secondary' : getViolationCount(violation.id) == 0 ? 'success' : 'danger'">{{ !violation.complianceOn ? 'N/A' : getViolationCount(violation.id) }}</b-badge></b-col>
                <b-col sm="8" class="mx-1  pt-1" align="left"><h5>{{ violation.codeViolation }}</h5></b-col>
                 <b-col align="right" cols="2" class="m1-1 mr-2 pt-1"><b-form-checkbox v-model="violation.complianceOn" :disabled="getViolationCount(violation.id) != 0">On</b-form-checkbox></b-col>
                <b-col align="right" cols="*" class="ml-1 mr-2"><b-btn :disabled="!violation.complianceOn || currentTableEditId != null" variant="danger" size="sm" @click="currentTableEditId = violation.id">Add Violation</b-btn></b-col>
              </b-row>
              <b-card no-body v-if="currentTableEditId == violation.id" class="pt-2 pb-2">
                <b-container  >
                  <b-row>
                    <b-col> <h3 class="font-weight-bold mt-1">Violation Info</h3></b-col>
                    <b-col cols="*" class="mx-2"> <b-btn class="align-left" size="sm" variant="danger"  @click="currentTableEditId = null, violationAdditionalInfo = ''">X</b-btn></b-col>
                    </b-row>
              <b-row class="mt-2">
                     <b-col md="8" class="ml-4" align="left"><h3>{{ violation.codeCompliance }}</h3>
                     <h3><em>({{ violation.codeReference }})</em></h3></b-col>
                    <b-col md="8" align="right"><h3>Compliance Date</h3><b-form-input type="date" v-model="violation.complianceDate" /></b-col>
                    <b-col md="4" ><h3>Violation Photo</h3><b-form-file v-if="!violationPhoto" ref="fileinput"  @change="postImage" v-model="pictureUpload" />
                    <b-img style="max-width: 300px;" :src="violationPhoto" />
                     </b-col>
              </b-row>
              <b-textarea class="my-2" v-model="violationAdditionalInfo" placeholder="Additional Violation Info" />
              <b-btn block size="sm" variant="danger"  @click="saveViolation(violation)">Save Violation</b-btn>
                </b-container>
                </b-card>
              
    

            </b-container>
               </b-card>
            </div>
          </b-card>
        </b-container>
          </b-tab>
          <b-tab title="Violations" class="px-1 px-md-2">
            <b-card  v-for="group in currentViolationsReturn"  header-tag="header" :key="group.tempId" header-bg-variant="danger" no-body header-class="m-0 pb-0 pt-2">
            <b-row slot="header">
            <b-col><h5 class="font-weight-bold text-light">{{ group.group }}</h5></b-col>
            </b-row>
            <b-card v-if="editViolationObj">
                            <b-row class="mt-2">
                     <b-col md="8" class="ml-4" align="left"><h3>{{ editViolationObj.codeCompliance }}</h3>
                     <h3><em>({{ editViolationObj.codeReference }})</em></h3></b-col>
                    <b-col md="8" align="right"><h3>Compliance Date</h3><b-form-input type="date" v-model="editViolationObj.complianceDate" /></b-col>
              </b-row>
                <b-textarea class="my-2" v-model="editViolationObj.violationInfo" placeholder="Additional Violation Info" />
                 <b-btn @click="saveUpdate(editViolationObj)" size="sm" variant="success">Save Changes</b-btn>
              </b-card>
           <b-table small :fields="fieldsReturnedFail" :items="group.violations" stacked="md">
              <template slot="codeViolation" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeViolation }}</span>
                <b-form-input v-if="currentTableEditId == data.item.id" v-model="data.item.codeViolation" />
                </template>
                <template slot="codeReference" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeReference }}</span>
                <b-form-input v-if="currentTableEditId == data.item.id" v-model="data.item.codeReference" />
                </template>
                <template slot="Resolved" slot-scope="data">
                  <b-btn @click="removeViolation(data.item.violationTempId)" size="sm" variant="danger">Remove Violation</b-btn>
                   <b-btn @click="editViolation(data.item)" size="sm" variant="warning">Edit Violation</b-btn>
                </template>
             </b-table>
             </b-card>
          </b-tab>
          <b-tab title="Passed" class="px-1 px-md-2"> 
              <b-card  v-for="group in currentPassedReturn"  header-tag="header" :key="group.tempId" header-bg-variant="primary" :no-body="selectedParentPassed == group.tempId ? false : true">
            <b-row slot="header" class="pointer" @click="selectedParentPassed = group.tempId">
            <b-col><h5 class="font-weight-bold text-light">{{ group.group }} <em>(click to view) </em></h5></b-col>
            </b-row>
           <b-table v-if="selectedParentPassed == group.tempId" small :fields="fieldsReturnedPass" :items="group.violations" stacked="md">
              <template slot="codeViolation" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeViolation }}</span>
                <b-form-input v-if="currentTableEditId == data.item.id" v-model="data.item.codeViolation" />
                </template>
                <template slot="codeReference" slot-scope="data">
                <span v-if="currentTableEditId != data.item.id">{{ data.item.codeReference }}</span>
                <b-form-input v-if="currentTableEditId == data.item.id" v-model="data.item.codeReference" />
                </template>
                <template slot="pass">
                  <b-badge variant="success">Pass</b-badge>
                </template>
             </b-table>
             </b-card>
          </b-tab>
          <b-tab title="Final Review/Submission" class="px-1 px-md-2" @click="consoleLogData">
            <div :id="'print_report_' + this.randomField" :class="printReportOn ? 'printReport' : ''">
              <b-row class="mb-3">
                <b-col align="left" class="ml-4" md="8">
                  <h3>Business  {{ inspectionPreviousId ? 'Reinspection ' : 'Annual/Periodic Inspection' }} Report</h3>
                </b-col>
                <b-col align="right">
                  <b-row>
                <b-col v-if="!printReportOn" align="right" class="mt-2">Invoice: </b-col>
                <b-col v-if="!printReportOn"><b-form-input type="text" v-model="inspectionInvoiceId" /></b-col>
                <b-col v-if="printReportOn" align="right">Invoice: {{ inspectionInvoiceId }}</b-col>
                </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="8" lg="5" align="right">
                  <img class="imageMaxWidth" v-if="departmentInspectionImage" :src="departmentInspectionImage" />
                 </b-col>
                <b-col align="center" lg="7" >
                  <h2 class="mt-3 font-weight-bold">Business Information:</h2>
                  <h3 v-if="business.businessTableData && formTabTitle">{{ getTabTitle(business.businessTableData, formTabTitle, 'title') }}</h3>
                  <h3 v-if="business.businessTableData && formTabTitle">{{ getTabTitle(business.businessTableData, formTabTitle, 'address') }}</h3>
                </b-col>
              </b-row>
              <b-row class="mx-4"><b-col><h3 >Completed by {{ inspectionAuthor }} on {{ formatDate(inspectionDate) }}</h3></b-col></b-row>
              <b-row v-if="currentViolations.length > 0">
                <b-col>
            <b-card no-body>
           <b-table small :fields="fieldsReturnedFailPrint" :items="currentViolationsPrint" responsive="md" v-if="reloadFinal">
              <template slot="codeViolation" slot-scope="data">
                <h3 class="mb-0"><span class="font-weight-bold">Violation: </span> {{ data.item.codeViolation }}<em> ({{ data.item.codeReference }})</em></h3>
                
                </template>
                <template slot="codeCompliance" slot-scope="data">
                  <h3 class="mb-0"><span class="font-weight-bold">Compliance: </span>{{ data.item.codeCompliance }}</h3>
                </template>
                <template slot="row-details" slot-scope="row">
                   <span class="font-weight-bold float-left mr-1 ml-4 smallText">Additional Info:</span><div class="printNote">
                 {{ row.item.violationInfo }}</div>
                 <img  style="max-width: 300px;" v-if="row.item.violationPhoto" :src="violationsImage[row.item.violationPhoto]"  />
                </template>
             </b-table>
            </b-card>
             </b-col>
              </b-row>
              <b-card v-else>
              <h3> NO VIOLATIONS - PASSED </h3>
              </b-card>
              <b-card :title="!printReportOn ? 'Signature Options' : ''">
              <b-row class="mx-1 mx-md-3">
                <b-col v-if="!signatureCapture && !printReportOn"><b-form-input type="text" v-model="inspectionWitness" placeholder="Name and/or title of Individual Confirming the Report" /></b-col>
                <b-col v-if="!signatureCapture && printReportOn"><h3>{{ inspectionWitness }}</h3></b-col>
                <b-col v-if="!signatureCapture && !printReportOn"><b-btn :disabled="inspectionWitness == ''" @click="toggleUserSign">Add Signature</b-btn></b-col>
                <b-col v-if="signatureCapture"><h3> Report Signed by: {{ inspectionWitness }}</h3><b-img :src="signatureCapture" class="imgWidth" /></b-col>
                <b-col v-if="signatureCapture && !printReportOn" cols="*"><b-btn variant="danger" @click="signatureCapture = ''">Reset</b-btn></b-col>
                </b-row>
                <b-container v-if="!signatureCapture" fluid class="px-4">
                <b-textarea class="mt-2" v-if="!signatureCapture && !printReportOn" v-model="noSignatureText" placeholder="To submit as complete requires a signature from a business representive, if you are unable to obtain a signature please explain why" />
                <h3 v-if="!signatureCapture && printReportOn && !(this.previous && this.previous.regenerate)">Missing Signature Reason: {{ noSignatureText }}</h3>
                <h3 v-if="printReportOn && (this.previous && this.previous.regenerate)">Regenerated Report Data</h3>
                </b-container>
              </b-card>
              <b-card :title="!printReportOn ? 'Submission and Saving Options' : ''">
               
                <b-container class="pb-2" v-if="!printReportOn">
                   <b-row class="mt-2">
                          <b-col>
                            <b-form-checkbox :disabled="!signatureCapture && !noSignatureText" v-model="inspectionCompleteBoolean">Submit Inspection As Complete?</b-form-checkbox>
                          </b-col>
                          </b-row>
                          <b-row v-if="inspectionCompleteBoolean"><b-col>Inspection Invoice Amount</b-col><b-col><b-form-input type="number" v-model="inspectionInvoiceAmount"></b-form-input></b-col></b-row>
                  <b-row class="mt-2" v-if="inspectionCompleteBoolean"><b-textarea v-model="inspectionComment" placeholder="Additional Comments" /></b-row>
                  <b-row class="mt-2" v-if="inspectionCompleteBoolean">
                    <b-col  cols="12" lg="6">
                      <b-row>
                        <b-col cols="*" lg="8">
                           <b-form-checkbox class="mt-2" v-model="emailBoolean">Email Report?</b-form-checkbox>
                        </b-col>
                        <b-col>
                      <b-form-input type="email" :disabled="!emailBoolean" v-model="emailTempHold" placeholder="Add Email" />
                        </b-col>
                        <b-col cols="*"  lg="8">
                          <b-btn variant="info" :disabled="!emailBoolean" @click="emailArray.push(emailTempHold), emailTempHold = ''">Add</b-btn>
                          </b-col>
                        </b-row>
                        <b-row v-if="emailBoolean" class="mt-2">
                          <b-col>
                          <div v-for="(email, index) in emailArray" :key="index">{{ email }} <b-badge variant="danger" class="pointer" @click="removeEmailArray(index)">X</b-badge></div>
                          </b-col>
                          <b-col cols="*">
                          Default: {{ this.userData.email }}
                          </b-col>
                          </b-row>
                    </b-col>
                    <b-col>
                        <b-row>
                          <b-col>
                            <b-form-checkbox class="mt-2"  v-model="scheduleBoolean">Schedule Re-inspection?</b-form-checkbox>
                              </b-col>
                          <b-col>
                            <b-form-input type="date" :disabled="!scheduleBoolean" v-model="defaultComplianceDate" />
                            </b-col>
                          </b-row>
                    </b-col>
                  </b-row>
                  <b-row v-if="!printReportOn">

                    <b-col align="right">
                  <b-btn variant="success" v-if="!inspectionCompleteBoolean" @click="saveInspection(false)">Save Progress</b-btn>
                  <b-btn variant="success" v-if="inspectionCompleteBoolean && !(this.previous && this.previous.regenerate)" @click.once="executePrintJS">Submit As Complete</b-btn>
                  <b-btn variant="warning" class="mr-2" v-if="scheduleBoolean && inspectionCompleteBoolean && (this.previous && this.previous.regenerate)" @click.once="scheduleReInspectionPost">Schedule Re-inspection</b-btn>
                  <b-btn variant="success" v-if="inspectionCompleteBoolean && (this.previous && this.previous.regenerate)" @click.once="executePrintJSTest">Print Regenerated</b-btn>
                    </b-col>
                    </b-row>
                </b-container>
                <b-container v-if="printReportOn">
                  <b-container v-if="inspectionComment">
                    <b-row><b-col>Inspection Invoice Amount</b-col><b-col><b-form-input type="number" v-model="inspectionInvoiceAmount"></b-form-input></b-col></b-row>
                    <label>Additional Comments</label>
                  <b-textarea v-if="!printReportOn" :readonly="printReportOn"  v-model="inspectionComment" />
                   <h3 v-if="printReportOn">{{ inspectionComment }}</h3>
                  </b-container>
                  <p v-if="emailBoolean">Report Emailed to:  <span v-for="(email, index) in emailArray" :key="index">{{ email }}, </span><span>{{ userData.email }}</span></p>
                  <p v-if="scheduleBoolean">Re-inspection previous for {{ defaultComplianceDate }}</p>
                </b-container>
              </b-card>  
            </div>
          </b-tab>
        </b-tabs>
        </b-card>
        <signature v-if="userSign" :showModalSignature="userSign" :name="inspectionWitness" @signatureCapture="signatureMethodUser($event)" @closeModalCancel="userSign = !userSign"></signature>
    
<b-modal title="Processing Inspection" v-model="inspectionProcessing" :hide-footer="true">
<h3>Stand by... {{ currentStatus }}</h3>
</b-modal>
</b-container>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from "moment";
import { mapState } from "vuex";
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import signature from "../inputfields/signaturepad.vue";
import axios from "axios";
import { bus } from '../../main.js';
import ImageCompressor from 'image-compressor.js'
import printJS from 'print-js'
import html2canvas from 'html2canvas';
export default {
  props: ['inspectionCodes', 'business', 'previous', 'formTableStyle'],
  name: "codevioloations",
  components: {
    signature
  },
  data() {
    return {
      showBulkLoad: false,
      original: '',
      editcodes: '',
      editId: '',
      editGroupName: '',
      inspectionDate: moment().toISOString(),
      inspectionInvoiceId: moment().format('YYYY-MM-DD-HH-mm'),
      inspectionAuthor: '',
      currentTableEditId: null,
      selectedParentPassed: '',
      printReportOn: false,
      noSignatureText: '',
      inspectionProcessing: false,
      defaultComplianceDate: '',
      inspectionWitness: '',
      formTabTitle: "businessName",
      scheduleBoolean: false,
      pictureUpload: '',
      inspectionCompleteBoolean: false,
      emailBoolean: false,
      selectedParent: '',
      inspectionCodesReturned: [],
      violationPhoto: '',
      emailArray: [],
      emailTempHold: '',
      violationAdditionalInfo: '',
      currentViolations: [],
      randomField: '',
      signatureCapture: '',
      violationsImage: {},
      userSign: false,
      editViolationObj: null,
      reloadFinal: true,
      currentStatus: 'Processing Images',
      emailSavedFile: '',
      departmentInspectionImage: '',
      savedFile: [],
      inspectionPreviousId: null,
      inspectionComment: '',
      inspectionInvoiceAmount: '',

    };
  },
  created() {
    this.defaultComplianceDate = moment().add(2, 'weeks').format("YYYY-MM-DD")
    if(true){
    this.original = _.cloneDeep(this.inspectionCodes)
    this.editcodes = _.cloneDeep(this.inspectionCodes)
    this.inspectionCodesReturned = this.inspectionCodesBuild(this.editcodes)
    }
    this.inspectionAuthor = this.userData.firstName + ' ' + this.userData.lastName
    this.randomField = _.uniqueId()
    if(this.previous){
      console.log(this.previous)
      this.inspectionPreviousId = this.previous.inspectionPreviousId ? this.previous.inspectionPreviousId : null
      this.currentViolations = this.previous.inspectionViolations ? this.previous.inspectionViolations : []
      this.checkForPreviousViolationPictures(this.previous.inspectionViolations)
      this.inspectionDate = this.previous.inspectionDate ? this.previous.inspectionDate : moment().toISOString()
      this.inspectionCodesReturned = this.inspectionCodesBuild(this.editcodes, this.previous.inspectionPassed, this.previous.inspectionViolations)
      if(this.previous.regenerate){
        this.inspectionAuthor = this.previous.inspectionAuthor ? this.previous.inspectionAuthor : this.userData.firstName + ' ' + this.userData.lastName
        this.inspectionCompleteBoolean = true
        this.inspectionInvoiceId = moment(this.previous.inspectionCompletedDate).format('YYYY-MM-DD-HH-mm')
      }
    }
    if(!this.siteconfig.inspectionImage){
      this.onLoadImage(this.siteconfig.staticHost + 'banners/' + this.siteconfig.deptUrlSub + '/banner.png')
    }
    if(this.siteconfig.inspectionImage){
      this.onLoadImage(this.siteconfig.staticHost + 'banners/' + this.siteconfig.deptUrlSub + '/inspection.png')
    }         

  },
  mounted() {

  },
  computed: {
    ...mapState([
      "deptContactData",
      "departmentBlogs",
      "getSessionData",
      "userData",
      "siteconfig"
    ]),
    fieldsReturned(){
      var array = ['codeViolation', 'codeCompliance', 'codeReference', 'codeFee', 'complianceDate', 'add']
      return array
    },
    fieldsReturnedFailPrint(){
      //  {label: 'Additional', key: 'violationInfo'},
      var array = ['codeViolation', 'codeCompliance', 'complianceDate']
      return array
    },
    fieldsReturnedFail(){
      var array = ['codeViolation', 'codeReference', 'complianceDate', 'Resolved']
      return array
    },
    fieldsReturnedPass(){
      var array = ['codeViolation', 'codeReference', 'pass']
      return array
    },
    checkRole(){
      if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
       return true
      } return false
    },
    inspectionCodesReturnedWatch(){
      var array = this.inspectionCodesReturned
      return array
    },
    currentViolationsReturn(){
      var data = this.currentViolations
      return this.inspectionCodesBuildForPassFail(data)
    },
    currentViolationsPrint(){
      var data = this.currentViolations
      console.log(data)
      return data.map(item => {
          var obj = item
          if(obj.violationInfo || obj.violationPhoto){
           obj['_showDetails'] = true
          }
          return obj
      })
    },
    currentPass(){
      var hold = this.editcodes
      var testArray = this.currentViolations.map(p => p.id)
      var secondTestArray = []
      var naBuild = this.inspectionCodesReturnedWatch
      for(var i in naBuild){
          var items = naBuild[i].violations
          for(var v in items){
            if(!items[v].complianceOn){
              secondTestArray.push(items[v].id)
            }
          }
      }
      hold = hold.filter(p => {
        return testArray.indexOf(p.id) == -1 && secondTestArray.indexOf(p.id) == -1 
      })
      return hold
    },
    currentPassedReturn(){
      var hold = this.currentPass
      var holdreturn =  this.inspectionCodesBuildForPassFail(hold)
      if(holdreturn[0] && holdreturn[0].tempId){
        this.selectedParentPassed = holdreturn[0].tempId
      }
      return holdreturn
    },
    arrayOfPreviousFiles(){
      if(this.business && this.business.businessInspectionPdfs){
        var newData = this.savedFile
        var array = this.business.businessInspectionPdfs
        for(var i in newData){
          array.push(newData[i])
        }
        return array
      } else {
        return this.savedFile
      }
    },

  },
  watcher: {},
  methods: {
    saveUpdate(data){
      var index = this.currentViolations.map(p => p.violationTempId).indexOf(data.violationTempId)
      this.currentViolations[index] = data
      var data = this.currentViolations
      this.currentViolations = null
      this.currentViolations = data
      this.editViolationObj = null
    },
    editViolation(data){
    //  console.log(data)
     this.editViolationObj = data
    },
    checkForPreviousViolationPictures(data){
      if(data){
        for(var d in data){
          if(data[d].violationPhoto){
            this.loadViolationImage(data[d].violationPhoto)
          }
        }
      }
    },
    consoleLogData(){
      this.reloadFinal = false
      this.$nextTick(function(){
        this.reloadFinal = true
      })
    },
    onLoadImage(url){
      var vm = this
      console.log('NEW IMAGE')
      console.log('image loaded')
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        vm.departmentInspectionImage = dataURL // .replace(/^data:image\/(png|jpg);base64,/, "")
      };
    },
    loadViolationImage(url){
     // console.log(url)
      var vm = this
     // console.log('NEW IMAGE')
     // console.log('image loaded')
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        vm.violationsImage[url] = dataURL //.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
        return 
      };
    },
    loadViolationImage2(url){
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.getElementById(url + '2');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        // console.log(dataURL)
        return dataURL //.replace(/^data:image\/(png|jpg);base64,/, "")
      };
    },
    postImage(e, violation){
      this.displayItem = 'Compressing'
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxiosViolation(result, violation)
        }
        })
      
     },
    formatDate(date){
      if(date){
        return moment(date).format('MM/DD/YYYY')
      } return 'Missing Date'
    },
    getTabTitle(data, title, type) {
      if(type == 'title'){
      var obj = data.find(p => p.key == title);
      } else if(type == 'address'){
        var obj = data.find(p => p.key == 'streetNumberStreetStreetSuffix')
      } else  {
        return 'Missing Data'
      }
      if(obj && obj.value){
        return obj.value
      } return 'Missing Data'
    },
    removeEmailArray(index){
      this.emailArray.splice(index, 1)
    },
    removeViolation(id){
    //  console.log(id)
      var index = this.currentViolations.map(p => p.violationTempId).indexOf(id)
   //   console.log(index)
       var hold = this.currentViolations
       hold.splice(index, 1)
       console.log(hold)
       this.currentViolations = []
       this.currentViolations = hold
    

    },
    getViolationCount(id){
      var filterData = this.currentViolations.filter(p => p.id == id)
      if(filterData && filterData.length){
        return filterData.length
      } else {
        return 0
      }
    },
    executePrintJSTest(){
        this.printReportOn = true
        var printId = '#print_report_' + this.randomField
        this.$nextTick(function(){
        var documentHold = document.querySelector(printId)
        var obj = {width: documentHold.scrollWidth, height: documentHold.scrollHeight, windowWidth: documentHold.scrollWidth, windowHeight: documentHold.scrollHeight}
        console.log(obj)
        html2canvas(documentHold, obj).then(canvas => {
                var image = canvas.toDataURL("image/jpeg")
              printJS({
                            printable: image,
                            type: 'image',
                            style: 'img { width:1200px !important; }',
                            //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
                          })

        }); 
      })
      this.$nextTick(function(){
        this.printReportOn = false
      })
    },
    executePrintJS(){
        this.printReportOn = true
        var printId = '#print_report_' + this.randomField
        this.$nextTick(function(){
        var documentHold = document.querySelector(printId)
        var obj = {width: documentHold.scrollWidth, height: documentHold.scrollHeight, windowWidth: documentHold.scrollWidth, windowHeight: documentHold.scrollHeight}
        console.log(obj)
        html2canvas(documentHold, obj).then(canvas => {
      /*  var image = canvas.toDataURL("image/jpeg")
        return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              imageStyle: 'margin-bottom:20px;'
            }) */
       var hm = this
        canvas.toBlob(function(blob) {
        hm.postImageAxios(blob) 
        }); 
      });
      this.$nextTick(function(){
        this.printReportOn = false
      })
           })
    },
    postImageAxiosViolation(result, violation){
        this.inspectionProcessing = true
        var data = new FormData();
        if(this.business && this.business.id){
          var id = this.business.id
        } else {
          var id = 'test'
        }
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/violation/' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        this.violationPhoto = this.siteconfig.deptStore + response.data

        this.inspectionProcessing = false

        return
        var obj = {date: this.inspectionDate, violations: this.currentViolations.length, author: this.inspectionAuthor, inspection: this.siteconfig.deptStore + response.data }
        
        this.savedFile.push(obj)
        this.$nextTick(function(){
          this.updateBusiness()
        })
        });
     },
    postImageAxios(result){
        this.inspectionProcessing = true
        var data = new FormData();
        if(this.business && this.business.id){
          var id = this.business.id
        } else {
          var id = 'test'
        }
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/inspection/' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        var obj = {date: this.inspectionDate, violations: this.currentViolations.length, author: this.inspectionAuthor, inspection: this.siteconfig.deptStore + response.data }
        this.emailSavedFile = this.siteconfig.deptStore + response.data
        this.savedFile.push(obj)
        this.$nextTick(function(){
          this.updateBusiness()
        })
        });
     },
     updateBusiness(){
       this.currentStatus = 'Updating Business'
      if(this.business.id){
      var businessUpdate = {
              business_inspection_pdfs: this.arrayOfPreviousFiles && this.arrayOfPreviousFiles.length > 0 ? this.arrayOfPreviousFiles : null
            }
       if(this.inspectionPreviousId){
         businessUpdate['business_last_inspection'] = this.inspectionDate
       } else {
         businessUpdate['business_last_annual'] = this.inspectionDate
       }
       axios({
        method: 'patch',
        url: "/api/v1/preplan/business/" + this.business.id,
        data: businessUpdate,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
          console.log(response.data)
          this.scheduleReInspection()
 
      });
       
      } else {
        console.log('ERROR Business ID Missing')
      }
     },
     scheduleReInspectionPost(){
          console.log(this.previous)
          this.currentStatus = 'Scheduling Re-Inspection'
       var data = {
               inspectionPreviousId: this.inspectionPreviousId ? this.inspectionPreviousId : this.previous && this.previous.id ? this.previous.id : null,
               inspectionAssignedType: this.previous && this.previous.inspectionAssignedType ? this.previous.inspectionAssignedType : null,
               inspectionAssignedId: this.previous && this.previous.inspectionAssignedId ? this.previous.inspectionAssignedId : null,
               inspectionDate: this.defaultComplianceDate,
               inspectionBusinessId: this.business.id,
               inspectionViolations: this.previous && this.previous.inspectionViolations ? this.previous.inspectionViolations : null,
               inspectionPassed: this.previous && this.previous.inspectionPassed ? this.previous.inspectionPassed : null,
       }
       axios({
        method: 'post',
        url: "/api/v1/preplan/inspection/",
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.$emit('getInspections')
 
      })
     },
     scheduleReInspection(){
     console.log(this.previous)
       if(this.scheduleBoolean){
          this.currentStatus = 'Scheduling Re-Inspection'
       var data = {
               inspectionPreviousId: this.inspectionPreviousId ? this.inspectionPreviousId : this.previous && this.previous.id ? this.previous.id : null,
               inspectionAssignedType: this.previous && this.previous.inspectionAssignedType ? this.previous.inspectionAssignedType : null,
               inspectionAssignedId: this.previous && this.previous.inspectionAssignedId ? this.previous.inspectionAssignedId : null,
               inspectionDate: this.defaultComplianceDate,
               inspectionBusinessId: this.business.id
       }
       axios({
        method: 'post',
        url: "/api/v1/preplan/inspection/",
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
          var scheduleResponse = response.data
          if(scheduleResponse && scheduleResponse[0] && scheduleResponse[0].id){
            this.saveInspection(scheduleResponse[0].id)
          } else {
            this.saveInspection()
          }
          
 
      });

       } else {
         this.saveInspection()
       }
     },
     saveInspection(previousId){
              this.currentStatus = 'Saving Inspection'
       var data = {
               inspectionDate: this.inspectionDate, 
               inspectionPreviousId: this.inspectionPreviousId,
               inspectionBusinessId: this.business.id,
               inspectionViolations: this.currentViolations,
               inspectionComplete: this.inspectionCompleteBoolean ? this.inspectionCompleteBoolean : null,
               inspectionCompletedDate: this.inspectionCompleteBoolean ? moment().toISOString() : null , 
               inspectionAuthor: this.inspectionAuthor, 
               inspectionAuthorId: this.userData.id,
               inspectionPassed: this.currentPass,
               inspectionFinalPdf: this.savedFile && this.savedFile.length > 0 ? this.savedFile : null,
               inspectionInvoiceAmount: this.inspectionInvoiceAmount ? this.inspectionInvoiceAmount : null
       }
       if(this.previous && this.previous.id){
         var type = 'patch'
         var id = this.previous.id
       } else {
         var type = 'post'
         var id = ''
       }
       if(this.inspectionCompleteBoolean && this.emailBoolean){
         var array = this.emailArray
         array.push(this.userData.email)
         data['emailArray'] = array
         data['emailImage'] = this.emailSavedFile
       }
      axios({
        method: type,
        url: "/api/v1/preplan/inspection/" + id,
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
          var savedInspection = response.data
                 
          if(savedInspection && savedInspection[0] && savedInspection[0].id && previousId){
            this.currentStatus = 'Applying Violations'
               axios({
                method: 'patch',
                url: "/api/v1/preplan/inspection/" + previousId,
                data: {
                  inspectionPreviousId: savedInspection[0].id,
                  inspectionViolations: this.currentViolations,
                  inspectionPassed: this.currentPass,
                },
                headers: {
                  Authorization: this.getSessionData,
                  "Content-Type": "application/json",
                  'Cache-Control': 'no-cache',
                  'Pragma': 'no-cache',
                  'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
                }
              }).then(response => {
                this.inspectionProcessing = false
          this.$emit('getInspections')
              });
          }
          this.inspectionProcessing = false
          this.$emit('getInspections')
 
      });
     },
    toggleUserSign(){
      this.userSign = !this.userSign
    },
    signatureMethodUser(data) {
      this.toggleUserSign();
      this.signatureCapture = data
    },
    saveViolation(data){
      var obj = _.cloneDeep(data)
      if(this.violationPhoto){
        obj['violationPhoto'] = this.violationPhoto
        this.loadViolationImage(this.violationPhoto)
      }
      obj['violationInfo'] = this.violationAdditionalInfo
      obj['violationTempId'] = obj.id + '_' + moment().toISOString()
      this.currentViolations.push(obj)
      this.$nextTick(function(){
        this.violationAdditionalInfo = ''
        this.currentTableEditId = null
        this.violationPhoto = ''
      })
    },
    inspectionCodesBuildForPassFail(data){
      var array = _.cloneDeep(data)
      var parents = [... new Set(array.map(p => p.codeGroup))]
      var order = _.sortBy(parents)
      order = order.map(item => {
          var obj = {}
          obj['tempId'] = _.uniqueId()
          obj['group'] = item
          var hold = array.filter(p => p.codeGroup == item)
          var violations = _.orderBy(hold, ['codeViolation'], ['asc'])
          obj['violations'] = violations
          return obj
      })
      return order
    },
    inspectionCodesBuild(data, previous, fail){
      if(previous){
        var testIds = previous.map(p => p.id)
        if(fail){
          testIds = testIds.concat(fail.map(p => p.id))
        }
      } else {
        var testIds = null
      }
      var array = _.cloneDeep(data)
      var parents = [... new Set(array.map(p => p.codeGroup))]
      var order = _.sortBy(parents)
      order = order.map(item => {
          var obj = {}
          obj['tempId'] = _.uniqueId()
          obj['group'] = item
          var hold = array.filter(p => p.codeGroup == item)
          var violations = _.orderBy(hold, ['codeViolation'], ['asc'])
          obj['violations'] = violations.map(itemV => {
            var vObj = itemV
            vObj['complianceOn'] = !testIds ? true : testIds && testIds.indexOf(itemV.id) != -1 ? true : false
            vObj['complianceDate'] = this.defaultComplianceDate
            return vObj
          }) 
          return obj
      })
      if(order[0] && order[0].tempId){
        this.selectedParent = order[0].tempId
      }
      return order
    },


  }
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.font-size-increase {
  font-size: 16px;
}
.smallText {
  font-size: 15px;
}
.printReport {
  width: 1200px;
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
    width: 1200px;
    height: auto;
}
.printNote {
  font-style: italic;
  margin: 0 40px;
  font-size: 14px;
  text-align: left;
}
.floatRight {
  float: right;
}
.blogCardClass {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.imgWidth {
  width: 150px;
}
.imageMaxWidth {
  max-width: 400px;
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
.pointer {
  cursor: pointer;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space: nowrap;
}
.userDataWrap {
  text-align: center;
}
.userRank {
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer {
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: 0.4;
}
.headerContactRow {
  border-radius: 5px;
}
.A-shiftROW {
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.B-shiftROW {
  border: 1px solid #201b9f;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.C-shiftROW {
  border: 1px solid #0fb906;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.Day-shiftROW {
  border: 1px solid #e4cb07;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.A-shift {
  background: #e2070d;
  background: -moz-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift {
  background: #201b9f;
  background: -moz-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.C-shift {
  background: #0fb906;
  background: -moz-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.Day-shift {
  background: #e4cb07;
  background: -moz-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;
}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138, 138, 138, 1);
  color: #fff;
  border-radius: 10px;
}
h1,
h2 {
  font-weight: normal;
}

@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
