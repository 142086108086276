<template>
<div >
    <b-form-input v-if="!stacked" type="date" v-model="payrollBankRecoDate" :disabled="false" @change="getPayrollModByDateByUser" />
    <b-table :fields="fields" :items="adjustForAccurals" :stacked="stacked ? true : 'lg'" v-if="refreshData" >
 <template v-for="bank in bankIds" :slot="bank.key" slot-scope="data">
         <span 
         :key="bank.key + data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id" 
         :id="'popoveruser-'+ data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id">
         <span v-if="!siteconfig.payroll.importPayBanks">{{ data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].newTotal.toString().replace(/,/g, '') }}</span>
         <span v-else :class="data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].newTotal < 0 ? 'text-danger' : ''">{{ data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].newTotal }}</span>
         <span v-if="data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].mods" :class="getClassColor(data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].mods, data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].prev)">
           ({{ data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].mods }})
           </span>
         </span>
         <b-popover
          :key="'popover-' + bank.key + data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id"
          :target="'popoveruser-'+ data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id"
          placement="right"
          :title="bank.label"
          triggers="hover focus"
          content="test"
        >
        <b-container>
          <div  v-for="(items, key, index) in data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)]" :key="index">
         <b-row  v-if="key != 'id' && key != 'name'">
           <b-col>{{ key }}</b-col>
           <b-col v-if="key != 'modsAttached'">{{ items }}</b-col>
           <b-card v-if="key == 'modsAttached' && items.length > 0" title="Mods Attached" class="p-0">
              <b-table :fields="modfields" :items="items" class="m-0"  small>
                  <template slot="payrollModCode" slot-scope="data">
                    <span :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
                  </template>
                  <template slot="payrollModDatetime" slot-scope="data">
                    {{ getFormatedDateTime(data.item.payrollModDatetime)}}
                  </template>
                   <template slot="payrollModHours" slot-scope="data">
                    <span :class="data.item.mod == 'addTo' ? 'text-success' : data.item.payrollModHours < 0 ? 'text-danger' : 'text-danger'" >{{ data.item.mod == 'addTo' ? "+" : "-" }}{{ data.item.payrollModHours }}</span>
                  </template>
                </b-table>
             </b-card>
           </b-row>
           </div>
          </b-container></b-popover>
       </template>
    </b-table>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,

},
props: ['previousDataReco', 'userSelected', 'payrollAccuralData', 'payrollBanksData', 'payrollCodesData', 'stacked'],
data() {
    return {
      fields: ['date'],
      bankIds: [],
      cycleAdvance: '',
      userMonthViewMods: [],
      payrollAccuralAssignedData: [],
            modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'},
    ],
      accuralLoaded: false,
      modsLoaded: false,
      payrollBankRecoDate: '',
      payrollRecoObjectIds: [],
  }
},
created () {
  this.payrollBankRecoDate = this.siteconfig.payroll.showApprovedTimeTotals ? moment().add(1, 'year').format('YYYY-MM-DD') : moment().add(2, 'weeks').format('YYYY-MM-DD')
  this.getAccuralAssigned()
  this.getPayrollModByDateByUser()

},
beforeDestroy(){
  	
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  userBanks(){
    this.payrollRecoObjectIds = []
    if(this.userSelected){
      var user = this.userSelected.id
    } else {
      var user = this.userData.id
    }
    var array = {}
    var array = _.cloneDeep(this.previousDataReco.payrollRecoData.find(p => p.payrollUserId == user))
    // console.log(array)
    if(array && array.banks){
      array.banks = array.banks.map(p => {
          var obj = p
          obj.modsAttached = []
          obj.mods = 0
          return obj
      })
      this.cycleAdvance = this.getCyclesSinceLastReport(this.previousDataReco.payrollBankRecoDate, this.payrollBankRecoDate)
      var pulseArray = []
      var banks = array.banks
      var obj = {}
      for(var b in banks){
        this.fields.push({key: banks[b].id, label: banks[b].name })
        this.bankIds.push({key: banks[b].id, label: banks[b].name })
        obj[banks[b].id] = banks[b]
        
      }
      var assigned = this.payrollAccuralAssignedData //NEED TO FILTER BY DATE
      var mods = this.modsAdjustedForBanks.filter(p => p.payrollModTargetUserId == user && moment(p.payrollModDatetime).isAfter(moment(this.previousDataReco.payrollBankRecoDate)))
      var modIds = mods.map(p => p.bankid)
      array['mods'] = mods
      array['modIds'] = modIds
      array['accurals'] = assigned.map(item => {
        var accObj = this.payrollAccuralData.find(p => p.id == item.payrollAccuralId)
        return accObj
      })
      if(this.cycleAdvance > 0){
        var updatedCycles = this.getNextCycleAccurals(banks)
      }
      array['date'] = moment(this.previousDataReco.payrollBankRecoDate).format('MM/DD/YY') + ' (last report)'
      pulseArray.push(array)
    //  console.log('this.userBanks', pulseArray)
      return pulseArray
    } else {
    return []
    }


  },
  adjustForAccurals(){
          var array = this.userBanks.map(item => {
                  var obj = item
                  var bank = obj.banks
                  for(var b in bank){
                  var accurals = item.accurals.indexOf(undefined) >= 0 ? [] : item.accurals
                  bank[b] = this.updateAccuralTimes(bank[b], accurals, item.payrollUserId, item.mods, item.modIds)
                  }
                  return obj
             })
        //    console.log('adjustForAccurals', array)
          return array
        },
        modsAdjustedForBanks(){
          var returnArray = []
          var mods = this.userMonthViewMods
          for(var i in mods){
              var bank = this.payrollCodesData.find(p => p.id == mods[i].payrollModCode)
              if(bank){
              var adj = bank.payrollCodeBankMod
            //  console.log('modsAdjustedForBanks', adj)
              for(var b in adj){
                var obj = _.cloneDeep(mods[i])
                obj['mod'] = adj[b].mod
                obj['bankid'] = adj[b].id
                returnArray.push(obj)
              }
              }
          }   
       //   console.log('modsAdjustForBanksEnd', returnArray)
          return returnArray
        },
        firstOfTheMonthsSinceLastReport(){
          if(this.previousDataReco){
            var array = [this.previousDataReco]
          } else {
            array = []
          }
          var dates = []
          if(array[0]){
            var recoFirst = moment(array[0].payrollBankRecoDate).startOf('Month')
            var currentFirst = moment(this.payrollBankRecoDate).startOf('Month')
           // console.log(recoFirst)
           // console.log(currentFirst)
            var diff = currentFirst.diff(recoFirst, 'Month')
            if(diff > 0){
              return diff
            } return 0
          }
        },
        firstMonthPayperiodsSinceLastReport(){
          if(this.previousDataReco){
            var array = [this.previousDataReco]
          } else {
            array = []
          }
          var dates = []
          var payroll = this.siteconfig.payroll
          var cycleType = payroll.payCycleType == 'biweekly' ? 14 : 14
          if(array[0]){
            var newest = _.cloneDeep(array[0])
            var previousDate = newest.payrollBankRecoDate
            var endDate = moment(this.payrollBankRecoDate).toDate()
            var holddates = this.previousPaydatesFromLastReco(previousDate, endDate)
            var monthHold = []
            for(var i in holddates){
              var month = moment(holddates[i]).format('MM/YYYY')
              var precycle = moment(holddates[i]).subtract(cycleType, 'd').format('MM/YYYY')
              var index = monthHold.indexOf(month)
              if(index < 0 && month != precycle){
                dates.push(holddates[i])
                monthHold.push(month)
              }
            }
          }
          return dates
        },
        refreshData(){
          if(this.accuralLoaded && this.modsLoaded){
            return true
          } return false
        }
},
mounted(){

},
watch:{
 userSelected(newData){
        this.fields = ['date']
        this.bankIds = []
    this.getAccuralAssigned()
    this.getPayrollModByDateByUser()
 }
},
methods: {
  getClassColor(mod, total){
    if(mod < 0){
      if((mod * -1) > total){
        return 'text-danger'
      } return 'text-success'
    } return ''
  },
  getFormatedDateTime(date){
      if(date){
        return moment(date).format('MM/DD/YY')
      } else {
        return 'Unknown'
      }
    },
   getPayrollModByDateByUser(){
     this.userMonthViewMods = []
     this.modsLoaded = false
      var user = this.userSelected ? this.userSelected.id : this.userData.id
      var end = moment().toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/usermonthview/' + this.previousDataReco.payrollBankRecoDate + '/' + this.payrollBankRecoDate + '/' + user,
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
    this.userMonthViewMods = response.data ? response.data.filter(p => p.payrollModApproved) : []
    // this.rosterReload = true
   })
      this.$nextTick(function(){
        this.modsLoaded = true
      })
    },
   getCyclesSinceLastReport(previousDate, endDate){
      var payroll = this.siteconfig.payroll
      var cyclesAdvance = 0
      var diff = moment(endDate).diff(previousDate, 'days')
      var presetVarDate = moment(payroll.payStartDate, "YYYY-MM-DD")
      if(payroll.payCycleType == 'biweekly'){
         for(var i = 0; i < diff; i++){
         var testDate = moment(endDate).add(i, 'days')
         var dateDiffCal = moment(testDate).diff(presetVarDate, "days")
          if(Math.floor(dateDiffCal/14) == dateDiffCal/14){
              cyclesAdvance = cyclesAdvance + 1
          } 
      } 
     }
    this.previousDate = previousDate
    if(!(moment(endDate).isSame(this.endDate))){
         this.endDate = endDate
    }
    return cyclesAdvance    
    },
    getNextCycleAccurals(obj){
   //   console.log(obj)
    },
    getAccuralAssigned(){
      this.accuralLoaded = false
      var user = this.userSelected ? this.userSelected.id : this.userData.id
        axios({
        method: 'get',
        url: '/api/v1/staffing/payrollaccuralassigned/user/' + user,
        headers: {
              'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.payrollAccuralAssignedData = response.data
      this.$nextTick(function(){
        this.accuralLoaded = true
      })
      })
    },
    parseNumber(value) {
    //  console.log(value)
      var example = Intl.NumberFormat('en-US', {minimumFractionDigits: 2, useGrouping: false}).format(value);
      var returnExp = parseFloat(example)
      if(isNaN(example) || isNaN(returnExp)){
          const cleaned = value.replace(/,(?=\d{3})/g, '');
          return parseFloat(cleaned)
      } return returnExp
    },
    updateAccuralTimes(bank, accurals, user, mods, modIds, lastName){
     // console.log(bank)
     // console.log(accurals)
      var acc = accurals.find(p => p.payrollBankId == bank.id)
      // console.log(acc)
      // console.log(this.cycleAdvance)
      var previousData = this.previousDataReco.payrollRecoData.find(p => p.payrollUserId == user)
      var holdBank = {}
      if(previousData && previousData.banks){
         var previousBank = previousData.banks.find(p => p.id == bank.id)
          if(previousBank){
          var newBank = previousBank
       //   console.log(newBank)
      //prev: 0, accural: 0, newTotal: 0
      bank.prev = this.parseNumber(newBank.newTotal)
      if(acc && acc.payrollBankAccuralType == 'paycycle' && !this.siteconfig.payroll.importPayBanks){
          var amount = acc.payrollBankAccuralRate * this.cycleAdvance
          bank.accural = amount
          bank.newTotal = Number.parseFloat(bank.prev.replace(',', '')) + Number.parseFloat(amount)
          holdBank = bank
      } else if(acc && acc.payrollBankAccuralType == 'firstpayperiodmonth' && !this.siteconfig.payroll.importPayBanks){  /// need to do a firstofthemonthsetup for EFD "firstdayofthemonth"
          var amounttimes = 0
          if(this.firstMonthPayperiodsSinceLastReport && this.firstMonthPayperiodsSinceLastReport.length >= 0){
            amounttimes = this.firstMonthPayperiodsSinceLastReport.length
          }
          var amount = acc.payrollBankAccuralRate * amounttimes
          bank.accural = amount
          bank.newTotal = Number.parseFloat(bank.prev.replace(',', '')) + Number.parseFloat(amount)
          holdBank = bank
      } else if(acc && acc.payrollBankAccuralType == 'firstdayofthemonth' && !this.siteconfig.payroll.importPayBanks){  /// need to do a firstofthemonthsetup for EFD "firstdayofthemonth"
          var amounttimes = 0
         
          if(this.firstOfTheMonthsSinceLastReport && this.firstOfTheMonthsSinceLastReport >= 0){
            amounttimes = this.firstOfTheMonthsSinceLastReport
          }
          var amount = acc.payrollBankAccuralRate * amounttimes
          bank.accural = amount
          bank.newTotal = Number.parseFloat(bank.prev) + Number.parseFloat(amount)
          holdBank = bank
      }  else if(this.siteconfig.payroll.importPayBanks){
       // console.log(bank)
           bank.accrual = "N/A"
           bank.newTotal =  parseFloat(this.parseNumber(newBank.newTotal))
       //    console.log(bank)
           holdBank = bank
        } else {
          holdBank = bank
      }
          } else {
            holdBank = bank
          }
      } else {
        holdBank = bank
      }
      if(mods.length > 0){
        if(modIds.indexOf(bank.id) != -1){
          var modsbank = mods.filter(p => p.bankid == bank.id && moment(p.payrollModDatetime).isAfter(moment(this.previousDataReco.payrollBankRecoDate)))
          modsbank = _.orderBy(modsbank, ['payrollModDatetime'], ['asc'])
          if(modsbank.length > 0){
            for(var m in modsbank){
            //  console.log(modsbank[m].payrollModDatetime)
            //  console.log(this.previousDataReco.payrollBankRecoDate)
            //  console.log( moment(modsbank[m].payrollModDatetime).isAfter(moment(this.previousDataReco.payrollBankRecoDate)))
              if(modsbank[m].mod == 'addTo'){
                holdBank.mods = parseFloat(holdBank.mods) + parseFloat(modsbank[m].payrollModHours)
                holdBank.modsAttached.push(modsbank[m])
                holdBank.newTotal = parseFloat(this.parseNumber(newBank.newTotal)) + parseFloat(modsbank[m].payrollModHours)
                holdBank.newTotal = this.parseNumber(holdBank.newTotal).toFixed(2)
                this.payrollRecoObjectIds.push(modsbank[m].id)
              } else if(modsbank[m].mod == 'subFrom'){
                holdBank.mods = parseFloat(holdBank.mods) - parseFloat(modsbank[m].payrollModHours)
                holdBank.modsAttached.push(modsbank[m])
            //    holdBank.newTotal = parseFloat(this.parseNumber(newBank.newTotal)) - parseFloat(modsbank[m].payrollModHours)
                holdBank.newTotal = parseFloat(holdBank.newTotal) - parseFloat(modsbank[m].payrollModHours)
                holdBank.newTotal = this.parseNumber(holdBank.newTotal).toFixed(2)
                this.payrollRecoObjectIds.push(modsbank[m].id)
              }
            }

          }
        }

        return holdBank
      } else {
      return holdBank
      }
    },
  

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
