<template>
  <div class="hello">
      <div class="headerSpan">Contacts<span class="floatRight"><b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminUsers') > -1)" size="sm" @click="showAdmin = !showAdmin" :variant="checkState">Admin</b-btn></span></div>
        <employeeadmin v-if="showAdmin" />
        <div v-if="!showAdmin">
        <b-card no-body border-variant="light">
          <b-tabs card>
        <b-tab title="Personnel" active>
          <br>
          <b-container>
          <b-row><b-col>
          <b-form-input type="text" v-model="userSearchBox" @input.native="currentPage = 1" :placeholder="getPlaceHolder" />
          </b-col>
          <b-col cols="*" v-if="false">
            <b-form-checkbox class="mt-2" v-model="currentlyActive" :value="true" :unchecked-value="false">
              <h5><b-badge variant="success">Currently Active ({{activeUsersUpdated.length}})</b-badge></h5></b-form-checkbox>
          </b-col>
          </b-row>
        </b-container>
          <br>
        <b-card-group columns class="userContactContainer">
          <b-card no-body v-for="user in filterDepartmentSearch" class="contactCard" :class="checkForUserClass(user.shiftAssignment)" :key="user.uid">
              <b-row class="cardRow">
              <span :class="user.field_shift_assignment" class="imageColumn"><img :src="userPhotoLink(user.picture)" /></span>
              <span  class="userDataSpan">

              </span> 
                <div class="iconDiv">

                  <a :href="'mailto:' + user.email">
                    <img class="phoneIcons" src="/images/siteicons/email-user.png" v-b-tooltip.hover :title="user.email" />
                  </a>
                  <a v-if="user.personalMobile" :href="'tel:' + user.personalMobile">
                  <img class="phoneIcons" src="/images/siteicons/call-user.png" v-b-tooltip.hover :title="user.personalMobile" />
                  </a>
                  <a v-if="user.personalMobile == ''" disabled>
                  <img class="phoneIcons noPhone" src="/images/siteicons/call-user.png" />
                  </a>
                   <a v-show="false">
                    <img @click="echoUserForMessage(user)" class="phoneIcons" src="/images/siteicons/message-icon-black.png" />
                  </a>
              </div>

              </b-row>
              <div class="userDataWrap">
              <span class="spanClassNameAssign">{{ user.firstName }} {{ user.lastName }}</span>
              <span class="spanClassNameAssign">({{ user.stationAssignment }})</span></div>
              <div class="clear"></div>
              <div class="userRank"><h6> {{ user.deptRank }} <span v-if="checkIfUserActive(user.id, true)" :title="checkIfUserActive(user.id)" class="activeOnline">Active</span></h6></div>
              <div slot=footer>

                      <div class="userDataWrap">
                      <span>
                    {{ getYearsService(user.hireDate)}}</span>
                    <span v-if="user.deptSpecialties && user.deptSpecialties.length > 0">
                      <span v-for="sp in user.deptSpecialties" :key="sp.index"><span class="specialH" :title="sp" v-if="sp">{{ sp }}</span></span>
                      </span>
                  </div>
            </div>
            </b-card>
        </b-card-group>
            <b-row align-h="center" v-show="deptSearchLength > pageDisplayCount">
            <b-col cols="auto" align="right">
            <b-pagination align="center" size="md" :total-rows="deptSearchLength" v-model="currentPage" @change.native="filterDepartmentSearch()" :per-page="pageDisplayCount"></b-pagination>
          </b-col>
          <b-col cols="3" align="left">
            <b-form-select v-model="pageDisplayCount" :options="pageCountOptions" />
          </b-col >
        </b-row>
      </b-tab>
      <b-tab v-if="userData.role != 'user'" title="Last Login">
        <lastLogin />
        </b-tab>
        <b-tab v-if="filterApparatusData && !siteconfig.hideApparatusMainLink" title="Apparatus">
            <apparatusContact :filterApparatusData="filterApparatusData" />
          </b-tab>
          <b-tab v-if="stationsData" :title="siteconfig.calendarHideFire ? 'Departments' : 'Stations'">
            <stationContact />
          </b-tab>
    </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from 'moment'
import { mapState } from 'vuex'
import Collapse from './collapse.vue'
import employeeAdmin from '../admin/adminEmployees.vue'
import _ from 'lodash'
import lastLogin from './contact/lastLogin.vue'
import apparatusContact from './contact/apparatusContact.vue'
import stationContact from '../admin/adminStations.vue'
import axios from 'axios'
import { bus } from '../../main'
export default {
  name: 'hello', 
  components:{
    'collapse' : Collapse,
    'employeeadmin' : employeeAdmin,
    'lastLogin' : lastLogin,
    'apparatusContact' : apparatusContact,
    'stationContact' : stationContact,
  },
  data () {
    return {
      stringData: '',
      deptPhoneList: [],
      phoneNumberList: [],
      userSearchBox: '',
      showAdmin: false,
      currentPage: 1,
      pageDisplayCount: 6,
      imagePicture: '',
      deptSearchLength: '',
      shiftSearch: '',
      stationSearch: '',
      sortOrder: 'desc',
      previousLoginAttempt: null,
      currentlyActive: false,
      pageCountOptions:[
          { text: "6 Per Page", value:6},
          { text: "12 Per Page", value:12},
          { text: "24 Per Page", value:24},
          { text: "48 Per Page", value:48},
      ]
    } 
  },
  created(){
    this.getActiveUsers()

    
  },
  computed: {
    ...mapState([
      'deptContactData',
      'userData',
      'apparatusData',
      'stationsData',
      'getSessionData',
      'activeUsers',
      'siteconfig'
    ]),
    getPlaceHolder(){
      if(this.siteconfig.calendarHideFire){
        return "User Search (search by name or department)"
      } return "User Search (search by name, rank, station)"
    },
    pageCountOptionsMod(){
      var options = []
      var opt = this.pageCountOptions
      for(var i in opt){
        if(opt[i].value < (this.deptSearchLength + opt[i].value)){
          options.push(opt[i])
        }
      }
      return options
    },
    filterApparatusData(){
      return this.apparatusData.filter(p => {
        if(p.apparatusPhone){
          return p
        }
      })
    },
    filterStationsData(){

    },
    activeUsersUpdated(){
    //  console.log(this.activeUsers)
      return this.activeUsers
    },
    filterDepartmentSearch(){
      var page = this.currentPage
      var firstPage = (page - 1) * this.pageDisplayCount
      var secondPage = page * this.pageDisplayCount
      var deptContact = this.deptContactData
      var contacts = deptContact.filter(p => {
        if(p.firstName && p.lastName && p.stationAssignment && p.deptRank){
        return p.firstName.toLowerCase().match(this.userSearchBox.toLowerCase())
                  || p.lastName.toLowerCase().match(this.userSearchBox.toLowerCase())
                  || p.stationAssignment.toLowerCase().match(this.userSearchBox.toLowerCase())
                  || p.deptRank.toLowerCase().match(this.userSearchBox.toLowerCase())
        }
      })
      if(this.currentlyActive){
        var activeIds = this.activeUsersUpdated.map(p => p.chatUser)
        contacts = contacts.filter(p => {
          var userobj = activeIds.indexOf(p.id)
          if(userobj >= 0){
            return p
          }
        })
      }
      this.deptSearchLength = contacts.length
      return contacts.slice(firstPage, secondPage)

    },
      checkState(){
    if(this.showAdmin == true){
      return 'light'
    } else {
      return 'outline-light'
    }
  }


  },
  watcher: {

  },
  methods: {
    checkForUserClass(shift){
      if(this.siteconfig.payroll && this.siteconfig.payroll.shifts){
        var shifts = this.siteconfig.payroll.shifts
        var obj = shifts.find(p => p.shift == shift)
        if(obj){
          return obj.color + "ROW"
        } return 'no-shiftROW'
      } return shift + "ROW"
    },
    getLoginData(data){
      if(data){
      return moment(data).format('MM/DD/YY HH:mm')
      }
    },
    checkIfUserActive(id, boo){
      var userobj = this.activeUsers.find(p => p.chatUser == id)
      if(userobj){
        if(boo){
          return true
        }
        else {
          return moment(userobj.updatedAt).format('MM/DD/YY HH:mm')
        }
      } else {
        return false
      }
    },
    echoUserForMessage(user){
      bus.$emit('sendUserMessage', user)
    },
    getYearsService(data){
    var asdays = moment().diff(data, 'years')
    if(asdays > 1){
      return asdays + ' years'
    } else if (asdays == 1){
      return "1 year"
    } else {
      if(this.siteconfig.calendarHideFire){
        return '<1 year'
      }
      return 'Probationary'
    }
    },
    userPhotoLink(fileName){
    fileName = fileName ? fileName : 'files/default_images/Blank_User_Big.png'
    if(fileName.charAt(0) == '/'){
      return this.siteconfig.deptStore + fileName.replace('/', '')
        } else {
          return  this.siteconfig.deptStore + fileName
        }
    },
    userSpecialities(newData){
      this.specialitesArray = newData.split(', ')
    },
    parseDataField(){
    var dataParse = this.papa.parse(this.stringData, {header: true})
    this.phoneNumberList = dataParse
    },
    getActiveUsers(){
      var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': this.getSessionData,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
      var payload = {headers: headers}
      this.$store.dispatch('LOAD_ACTIVE_USERS', payload)
    },
    filteredDeptPhoneList(parent){
      return this.deptPhoneList.filter(p => {
        return p.Location.match(parent)
      })
    },
  }

}
</script>
<style>


</style>
<style scoped>
@media screen and (max-width: 1200px){
.userContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px){
.userContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px){
.userContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px){
.userContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
.activeOnline {
      font-size: small;
    background-color: green;
    border-radius: 5px;
    color: white;
    padding: 2px 4px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.floatRight {
  float: right;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer{
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {

  border-radius: 5px;
}
.Red-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Yellow-shiftROW{
  border: 1px solid #ffc107;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Blue-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Green-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.no-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.A-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.B-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.C-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Day-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.A-shift{
  background: #e2070d;
  background: -moz-linear-gradient(left, #e2070d 0%, #e2070d 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift{
  background: #201B9F;
  background: -moz-linear-gradient(left, #201B9F 0%, #201B9F 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
.C-shift{
  background: #0FB906;
  background: -moz-linear-gradient(left, #0FB906 0%, #0FB906 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
      border-radius: 20px 20px 0 0;
}
.Day-shift{
  background: #E4CB07;
  background: -moz-linear-gradient(left, #E4CB07 0%, #E4CB07 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
@media screen and (max-width: 850px){
   .phoneIcons {margin: 20px 5px;
      height: 40px;
      width: 40px;
      border: 0;
      border-radius: 0;
      background: none;
      }
}
@media screen and (max-width: 750px){
.phoneIcons {
    margin: 20px 2px;
    height: 35px;
    width: 35px;
    border: 0;
    border-radius: 0;
    background: none;
}
}
</style>
