<template>
  <div class="hello"> 
         <div title="Import New Report">
          <b-textarea v-model="uploadedJsonReport" @input="fixDataReport()" placeholder="Paste Excel Data Here" />
            <b-textarea v-if="false" v-model="uploadedJsonReportMonthly" @input="fixDataReportMonthly()" placeholder="Paste Excel Data Here" />
        <b-textarea v-model="uploadedJsonReportUser" @input="fixDataReportUser()" placeholder="Paste Excel Data Here" />
        <b-file accept="csv"  placeholder="Choose a file or drop it here..." @change="fixDataFromFile($event)" />
        <b-btn v-if="dataForDownload" @click="FileProcess">Download Accred Data</b-btn>
      </div>
          <download-excel  class="ml-2"
    v-if="datesArray && fixDataExcel.length > 0"
    :name="'TEST'"
    :data="fixDataExcel">
    <b-btn variant="primary">Full Excel</b-btn>
          </download-excel>
    <download-excel  class="ml-2"
    v-if="datesArray && excelReport.length > 0"
    :name="'Medic Call List'"
    :data="excelReport">
    <b-btn variant="primary">Call List</b-btn>
    </download-excel>
  <b-container v-if="datesArray">
    <b-card v-for="(date, index) in datesArray" :key="index" header-tag="header">
      <div slot="header">
       <h5> {{ date.date }} </h5>
        <span v-if="date.totalEMSCalls"> {{ 'TOTAL EMS CALLS: ' + date.totalEMSCalls.length + ' (' + Math.floor((date.dailyRuns.length / date.totalEMSCalls.length) * 100) + '%RRV)'}} </span>
        <span v-if="date.dailyRuns && false"> {{ 'TOTAL CALLS: ' + date.dailyRuns.length }} </span>
        <span v-if="date.medicOnly && false"> {{ 'Medic Only: ' + date.medicOnly.length }} </span>
        <span v-if="date.medicRescueTransport && false"> {{ 'Med&Res TransPort: ' + date.medicRescueTransport.length }} </span>
        <span v-if="date.medicRescueNonTransportMedic && false"> {{ 'Med&Res NTM: ' + date.medicRescueNonTransportMedic.length }} </span>
        <span v-if="date.medicRescueNonTransportResc && false"> {{ 'Med&Res NTR: ' + date.medicRescueNonTransportResc.length }} </span>
      </div>
      <b-table :fields="['Unit', 'Calls', 'MedicOnly', 'MedResTrans', 'MedResNTM', 'MedResNTR']" :items="getReportTableData(date)" />
    </b-card>
  </b-container>
</div>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import PapaParse from 'papaparse'
import JsonExcel from 'vue-json-excel';
export default {
  components: {
    'download-excel' : JsonExcel,
  },
  
  name: 'rescue-report',
  props: [],
  data () {
    return {
      uploadedJsonReport: '',
      uploadedJsonReportMonthly: '',
      uploadedJsonReportUser: '',
      medOnly: null,
      dataForDownload: false,
      medRescueTransport: null,
      nonTransportMedic: null,
      nonTransportRescue: null,
      datesArray: null,
      totalCalls: null,
      medicUnits: null,
      excelReport: [],
    }
  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
       fixDataExcel(){
          var array = _.cloneDeep(this.datesArray)
          var hold = []
          var fields = ['TotalEMS','TotalRRV','MedicOnly','MRT','MRNTM','MRNTR', 'Medic 1', 'Medic 2', 'Medic 3', 'Medic 4']
          var dateMap = array.map(p => p.date)
          for(var f in fields){
            var obj = {field: fields[f]}
            for(var d in dateMap){
              if(fields[f] == 'TotalEMS'){
                var date = array.find(p => p.date == dateMap[d])
                if(date){
                var value = date.totalEMSCalls.length
                } else {
                  var value = ''
                }
              }
              if(fields[f] == 'TotalRRV'){
                var date = array.find(p => p.date == dateMap[d])
                if(date){
                var value = date.dailyRuns.length
                } else {
                  var value = ''
                }
              }
           if(fields[f] == 'MedicOnly'){
                var date = array.find(p => p.date == dateMap[d])
                if(date){
                var value = date.medicOnly.length
                } else {
                  var value = ''
                }
              }
              if(fields[f] == 'MRT'){
                var date = array.find(p => p.date == dateMap[d])
                if(date){
                var value = date.medicRescueTransport.length
                } else {
                  var value = ''
                }
              }
             if(fields[f] == 'MRNTM'){
                var date = array.find(p => p.date == dateMap[d])
                if(date){
                var value = date.medicRescueNonTransportMedic.length
                } else {
                  var value = ''
                }
              }
              if(fields[f] == 'MRNTR'){
                var date = array.find(p => p.date == dateMap[d])
                if(date){
                var value = date.medicRescueNonTransportResc.length
                } else {
                  var value = ''
                }
              }
              if(fields[f] == 'Medic 1' || fields[f] == 'Medic 2' || fields[f] == 'Medic 3' || fields[f] == 'Medic 4'){
                var date = array.find(p => p.date == dateMap[d])
                if(date){
                  var value = this.filterRunsByMedic(date.dailyRuns, fields[f]).length
                } else {
                  value = [f]
                }
              }
              obj[dateMap[d]] = value
            }
            hold.push(obj)
          }
          return hold
        },
 
       
  },
  mounted(){
    //let recaptchaScript = document.createElement('script')
    //recaptchaScript.setAttribute('src', 'https://meet.jit.si/external_api.js')
   // document.head.appendChild(recaptchaScript)
   // this.getIFrame()
  },
  watch: {


  },
  methods: {
    fixDataFromFile(data){
    var files = data.target.files
    if(files && files[0]){
        this.readDataWithPapa(files[0])
      }
    },
    readDataWithPapa(data){
          var vm = this
          PapaParse.parse(data, {
                  delimiter: ",",
                  newline: "",
                  header: true,
                  dynamicTyping: true,
                  complete: (result) => {
                  //  console.log(result.data)
                    vm.filterForLastTime(result.data)
                  }
                });
    },
    filterForLastTime(data){
      var incidentMap = [...new Set(data.map(p => p['Incident Number']))]
      incidentMap = incidentMap.map(p => {
          var obj = {}
          obj['id'] = p
          var testObj = data.find(x => x['Incident Number'] == p)
          if(testObj && testObj.ERFRequirement){
            obj['erf'] = testObj.ERFRequirement
            return obj
          } else {
            obj['erf'] = false
            return obj
          }
      })
      incidentMap = incidentMap.filter(p => p.erf)
      incidentMap = incidentMap.map(p => {
              var obj = p
              obj['lastArrived'] = this.getLastArrived(p, data)
              return obj
      })
      this.dataForDownload = incidentMap
    },
    getLastArrived(p, data){
      var dataArray = data.filter(x => x['Incident Number'] == p.id)
      dataArray = _.orderBy(dataArray, ['calcArrived'], ['asc'])
      var lastTime = false
      var sumTotal = 0
      for(var i in dataArray){
          var people = dataArray[i].People
       //   console.log(people, p.erf)
          if((people + sumTotal) >= p.erf){
            lastTime = dataArray[i].calcArrived
            return lastTime
          } 
          sumTotal = sumTotal + dataArray[i].People
      }
      return lastTime

    },
    FileProcess(){
        var json_data = _.cloneDeep(this.dataForDownload)
        const fileName = 'accredData-' + moment().format('YYYY-MM-DD-HHmm') + '.csv';
        const data = PapaParse.unparse(json_data)
        var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
        var csvURL =  null;
        if (navigator.msSaveBlob)
        {
            csvURL = navigator.msSaveBlob(csvData, fileName);
        }
        else
        {
            csvURL = window.URL.createObjectURL(csvData);
        }
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = csvURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      },
    getIFrame(){
            var domain = "videochat.statportals.com/"
            var options = {}
            const api = new window.JitsiMeetExternalAPI(domain, options);
      },
      getTotalCalls(date, incoming){
        var data = _.cloneDeep(incoming)
        var filter = data.filter(p => {
            if(p.runs && p.runs[0]){
            if(moment(p.runs[0].notified, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YYYY') == date){
              return p
            }
            }
        })
       filter = filter.map(item => {
                var obj = {}
                obj['incident'] = item.incident
                obj['runs'] = _.orderBy(item.runs, ['notified'], ['desc'])
                return obj
        })
        filter = filter.map(item => {
                var obj = {}
                obj['incident'] = item.incident
                obj['runs'] = this.getTrueMedic(item.runs)
                return obj
        })
        return filter
      },
      getTrueMedic(runs){
        var runsHold = []
        var medicYet = false
        for(var r in runs){
            var unit = runs[r].unit
            if(unit.match('Medic') && medicYet == false){
              runsHold.push(runs[r])
              medicYet = true
            } else if(!unit.match('Medic')){
              runsHold.push(runs[r])
            }
        }
        return runsHold
      },
      getReportTableData(incoming){
        var data = _.cloneDeep(incoming)
       // console.log(incoming)
        var map = this.medicUnits.map(item => {
              var obj = {}
              obj['Unit'] = item
              if(item != 'Total'){
                var calls = this.filterRunsByMedic(data.dailyRuns, item)
                obj['callHold'] = calls
                obj['Calls'] = calls.length
                var medOn = this.filterRunsByMedic(data.medicOnly, item).length
                obj['MedicOnly'] = medOn + ' (' + Math.floor(( medOn / calls.length) * 100) + '%)'
                var medResT = this.filterRunsByMedic(data.medicRescueTransport, item).length
                obj['MedResTrans'] = medResT + ' (' + Math.floor(( medResT / (calls.length - medOn)) * 100) + '%)'
                var medResNTM = this.filterRunsByMedic(data.medicRescueNonTransportMedic, item).length
                obj['MedResNTM'] = medResNTM + ' (' + Math.floor(( medResNTM / (calls.length - medOn)) * 100) + '%)' 
                var medResNTR = this.filterRunsByMedic(data.medicRescueNonTransportResc, item).length
                obj['MedResNTR'] = medResNTR + ' (' + Math.floor(( medResNTR / (calls.length - medOn)) * 100) + '%)' 
              } else {
                obj['Calls'] = data.dailyRuns.length
                obj['MedicOnly'] = data.medicOnly.length + ' (' + Math.floor(( data.medicOnly.length / data.dailyRuns.length) * 100) + '%)'
                obj['MedResTrans'] = data.medicRescueTransport.length + ' (' + Math.floor(( data.medicRescueTransport.length / (data.dailyRuns.length - data.medicOnly.length)) * 100) + '%)' 
                obj['MedResNTM'] = data.medicRescueNonTransportMedic.length + ' (' + Math.floor(( data.medicRescueNonTransportMedic.length / (data.dailyRuns.length - data.medicOnly.length)) * 100) + '%)' 
                obj['MedResNTR'] = data.medicRescueNonTransportResc.length + ' (' + Math.floor(( data.medicRescueNonTransportResc.length / (data.dailyRuns.length - data.medicOnly.length)) * 100) + '%)' 
        }
              return obj
        })
      //  console.log(incoming.date)
      //  console.log(map)
        return map
      },
      filterRunsByMedic(runs, medic){
        var data = runs
        data = data.filter(p => {
            var runs = p.runs.filter(r => {
                return r.unit == medic
            })
            if(runs && runs.length > 0){
              return p
            }
        })
        return data
      },
      fixDataReportMonthly(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJsonReportMonthly
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.fixDataApplyMonthly(results.data)
            }
        })
      },
      fixDataReportUser(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJsonReportUser
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.fixDataApplyUser(results.data)
            }
        })
      },
      fixDataReport(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJsonReport
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.fixDataApply(results.data)
            }
        })
      },
      fixDataApplyMonthly(hold){
        var data = _.cloneDeep(hold)
        var dates = [...new Set(data.map(p => p.date))]
        var obj = {}
        for(var d in dates){
          var filterD = data.filter(p => p.date == dates[d])
          var total = [...new Set(filterD.map(p => p.incident))]
          obj[dates[d]] = total.length
        }
      //  console.log(obj)
      },
      fixDataApplyUser(hold){
        var data = _.cloneDeep(hold)
        console.log(data)
        var dates = Object.keys(data[0]).filter(p => p != 'value')
        var vHold = [... new Set(data.map(p => p.value))]
        vHold = vHold.filter(p => p)
        dates = dates.map(item => {
              var obj = {}
              obj['date'] = item
              for(var v in vHold){
                var vObj = data.find(p => p.value == vHold[v])
                if(vObj){
                  obj[vHold[v]] = vObj[item]
                }
              }
              return obj
        })
        var medics = ['one', 'two', 'three', 'four']
        if(true){
          medics = medics.filter(p => p != 'one')
        }
        var mHold = []
        for(var d in dates){
          var dData = dates[d]
          for(var m in medics){
            var obj = {}
            obj['medic'] = medics[m]
            obj['date'] = dData.date
            obj['calls'] = dData[medics[m]]
            var filterD = Object.keys(dData).filter(p => p.match(medics[m]) && p != medics[m])
            for(var f in filterD){
              var testV = dData[filterD[f]]
              if(testV == 'EMT'){
                obj['dMedic'] = false
              } else if (testV == 'PIC'){
                obj['dMedic'] = true
              } else {
                obj['charge'] = testV
              }
            }
            mHold.push(obj)
          }
        }
        console.log(mHold)
        this.getUserTotals(mHold)
        this.getCountTotals(mHold)
        return 
      //  console.log(obj)
      },
      getUserTotals(mHold){
        var users = [... new Set(mHold.map(p => p.charge))]
        users = users.map(item => {
              var obj = {}
              obj['charge'] = item
              var shifts = mHold.filter(p => p.charge == item)
              obj['shifts'] = shifts.length
              obj['shiftsHold'] = shifts
              var calls = 0
              for(var d in shifts){
                calls = calls + parseInt(shifts[d].calls)
              }
              obj['calls'] = calls
              obj['average'] = calls / shifts.length
              return obj
        })
        users = _.orderBy(users, ['average'], ['desc'])
        console.log(users)
      },
      getCountTotals(mHold){
        var dMedic = mHold.filter(p => p.dMedic)
        var ndMedic = mHold.filter(p => !p.dMedic)
        var dMedicCount = 0
        var ndMedicCount = 0
        for(var d in dMedic){
          dMedicCount = dMedicCount + parseInt(dMedic[d].calls)
        }
        for(var d in ndMedic){
          ndMedicCount = ndMedicCount + parseInt(ndMedic[d].calls)
        }

        console.log(dMedicCount/dMedic.length + ' ' + ndMedicCount/ndMedic.length)
      },
      fixDataApply(hold){
        var data = _.cloneDeep(hold)
        var dates = this.getDailys(data)

        var medicCalls = data.filter(p => p.unit && p.unit.match('Medic'))
        var emsCalls = data.filter(p => p.unit && (p.unit.match('Medic') || p.unit.match('Rescue')))
      //  console.log('EMS CALLS')
        var emsCallsInc = [...new Set(emsCalls.map(p => p.incident))]
        var emsCallsDataNorm = emsCallsInc.map(p => {
              var obj = {}
              var runs = emsCalls.filter(d => d.incident == p)
              obj['incident'] = p
              obj['runs'] = runs
              return obj  
        })
        var units = [...new Set(medicCalls.map(p => p.unit))]
        units = _.sortBy(units)
        units.push('Total')
        this.medicUnits = units
    //    medicCalls = medicCalls.filter(p => p.onscene)
        var medicRuns = medicCalls.map(p => p.incident)
        var medicDataNorm = medicRuns.map(p => {
              var obj = {}
              var runs = data.filter(d => d.incident == p)
              obj['incident'] = p
              obj['runs'] = runs
              return obj  
        })
        this.totalCalls = medicDataNorm
        var datesObj = dates.map(item => {
                var obj = {}
                obj['date'] = item
                obj['totalEMSCalls'] = this.getTotalCalls(item, emsCallsDataNorm)
                obj['dailyRuns'] = this.getTotalCalls(item, medicDataNorm)
                obj['medicOnly'] = this.getMedicOnly(obj.dailyRuns)
                obj['medicRescueTransport'] = this.getMedRescueTransport(obj.dailyRuns, obj.medicOnly)
                obj['medicRescueNonTransportMedic'] = this.getMedRescueNonTranMed(obj.dailyRuns, obj.medicOnly)
                obj['medicRescueNonTransportResc'] = this.getMedRescueNonTranRes(obj.dailyRuns, obj.medicOnly)
                return obj 
        })

        this.datesArray = datesObj
     //   this.setMedicExcelReport(datesObj)
       // this.getMedicOnly(medicDataNorm)
      },
      setMedicExcelReport(datesObj){
          for(var i in datesObj){
            console.log(datesObj[i])
            var newObj = this.getReportTableData(datesObj[i])
            this.getMedicDataForExport(datesObj[i].date, newObj)
          }
         // this.getMedicDataForExport(incoming.date, map)
      },
      getMedicDataForExport(dateIn, dataIn){
        var date = _.cloneDeep(dateIn)
        var medics = _.cloneDeep(dataIn)
        //console.log(date)
        //console.log(data)
        for(var m in medics){
          if(medics[m].Unit != 'Total'){
          var runs = medics[m].callHold
          for(var r in runs){
            var sUnits = ''
            var sDispo = ''
            var units = runs[r].runs.map(p => p.unit)
            for(var u in units){
              if(units[u]){
                sUnits = sUnits + units[u] + ' '
              }
            }
            var disposition = runs[r].runs.map(p => p.disposition)
            for(var d in disposition){
              if(units[d]){
                sDispo = sDispo + disposition[d] + ' '
              }
            }
            var obj = {}
            obj = {date: date, medic: medics[m].Unit, run_number: runs[r].incident, units: sUnits, disposition: sDispo }
            this.excelReport.push(obj)

          }
          }
        }
        console.log(this.excelReport)

        //this.excelReport
       /** 
        var report = []
        for(var d in hold){
        var runs = ''
        var medics = ['Medic 1', 'Medic 2', 'Medic 3', 'Medic 4']
        for(var m in medics){
          runs = this.filterRunsByMedic(hold[d].dailyRuns, medics[m])
          console.log(medics[m] + ' ' + runs.length) 
          for(var r in runs){
            var sUnits = ''
            var sDispo = ''
            var units = runs[r].runs.map(p => p.unit)
            for(var u in units){
              if(units[u]){
                sUnits = sUnits + units[u] + ' '
              }
            }
            var disposition = runs[r].runs.map(p => p.disposition)
            for(var d in disposition){
              if(units[d]){
                sDispo = sDispo + disposition[d] + ' '
              }
            }
            if(hold[d]){
            var obj = {}
            obj = {date: hold[d].date, medic: medics[m], run_number: runs[r].incident, units: sUnits, disposition: sDispo }
            report.push(obj)
            } else {
             // console.log(hold[d])
            }
          }
        }
        }
        report = _.orderBy(report, ['date', 'medic', ['run_number']], ['asc'])
        }
        */
      },
      getDailys(incoming){
        var data = _.cloneDeep(incoming)
        data = data.map(p => p.notified)
        data = data.map(p => moment(p, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YYYY'))
        var dates = [...new Set(data.map(p => p))]
        dates = dates.filter(p => p != 'Invalid date')
        dates = _.sortBy(dates)
        return dates
      },
      getMedicOnly(incoming){
        var data = _.cloneDeep(incoming)
        var medOnly = data.filter(p => p.runs && p.runs.length == 1)
        return medOnly
    //    this.medOnly = medOnly
        console.log('MEDIC ONLY | Total: ' + incoming.length + ', MedicOnly: ' + medOnly.length )
        this.getMedRescueTransport(incoming)
        this.getMedRescueNonTranMed(incoming)
        this.getMedRescueNonTranRes(incoming) 
      },
      getMedRescueTransport(incoming, medonly){
        var data = _.cloneDeep(incoming)
        var transport = data.filter(p => {
            var data = p.runs.filter(x => x.destination)
            if(data && data.length > 0){
              return p
            }
        })
       
        var length = medonly ? incoming.length - medonly : incoming.length
        console.log('TRANSPORT | Total Calls: ' + length + ', Transports: ' + transport.length )    
        return transport
      },
      getMedRescueNonTranMed(incoming, medonly){
        var data = _.cloneDeep(incoming)
        var nontransres = data.filter(p => {
            var data = p.runs.filter(x => x.destination)
            if(data.length == 0){
               var med = p.runs.filter(x => {
                    if(x.unit.match('Medic') && x.disposition){
                      return x
                    }
               })
               if(med && med.length > 0){
                 return p
               }
            }
        })
        var length = medonly ? incoming.length - medonly.length : incoming.length
        console.log('Non Transport Med Total: ' + length + ', Medic Non-Transport: ' + nontransres.length )    
        return nontransres
      },
      getMedRescueNonTranRes(incoming, medonly){
        var data = _.cloneDeep(incoming)
        var nontransres = data.filter(p => {
            var data = p.runs.filter(x => x.destination)
            if(data.length == 0){
               var med = p.runs.filter(x => {
                    if(!x.unit.match('Medic') && x.disposition){
                      return x
                    }
               })
               if(med && med.length > 0){
                 return p
               }
            }
        })
        var length = medonly ? incoming.length - medonly : incoming.length
       
        console.log('Non Transport Res Total: ' + length + ', Res Non-Transport: ' + nontransres.length )    
         return nontransres
      }



  
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
