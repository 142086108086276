<template>
<div>
  <b-card no-body> 
    <b-tabs card>
      <b-tab title="Training Modules" :active="mainForm">
            <trainingModuleTable v-if="trainingModules" :trainingModules="trainingModules" :credentialRequirement="credentialRequirement" :credentialsData="credentialsData" @viewTrainingModule="showTrainingModule($event)" />
      </b-tab>
      <b-tab title="New Module" v-if="dataReset">
          <trainingModuleForm :trainingModules="trainingModules" :credentialRequirement="credentialRequirement" :credentialsData="credentialsData" @updateTrainingTable="getTrainingModules" />
        </b-tab>
        <template v-if="previousTrainingMods && updateReset" v-for="mod in previousTrainingMods">
        <b-tab :title="mod.trainingModuleName" :key="mod.id" active>
          <b-container>
             <b-btn @click="editTrainingModule(mod.id)" variant="edit" v-if="checkModForEdit(mod.id)">Edit</b-btn>
             <b-btn @click="removeTrainingModule(mod.id)" variant="danger" v-if="checkModForEdit(mod.id)">Close</b-btn>
             <span class="float-left"  v-if="!checkModForEdit(mod.id)" >Last Updated: {{ mod.updatedAt }} by {{ mod.trainingModuleAuthorName }}</span>
          <b-btn @click="removeTrainingModule(mod.id, 'cancel')" class="align-right" variant="danger" v-if="!checkModForEdit(mod.id)">Cancel</b-btn>
          </b-container>
            <trainingModuleForm v-if="!checkModForEdit(mod.id)" :previousForm="mod" :credentialRequirement="credentialRequirement"  :credentialsData="credentialsData" @updateTrainingTable="getTrainingModules" />
          <trainingModuleViewPrevious v-if="checkModForEdit(mod.id)" :trainingModule="mod" :credentialRequirement="credentialRequirement"  :credentialsData="credentialsData" @closeTrainingModule="removeTrainingModule($event)" />
          </b-tab>
      </template>
      </b-tabs>

      </b-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import trainingModuleTable from './trainingModule/trainingModuleTable.vue'
import trainingModuleForm from './trainingModule/trainingModuleForm.vue'
import trainingModuleViewPrevious from './trainingModule/trainingModuleViewPrevious.vue'
import moment from 'moment'
export default {
  components: {
    trainingModuleTable,
    trainingModuleForm,
    trainingModuleViewPrevious
  },
  name: 'training-module',
  data () {
    return {
        currentPage: 1,
        showAll: true,
        edit: true,
        editGroupId: '',
        showGroup: '',
        dataReset: true,
        mainForm: true,
        updateReset: true,
        editingMods: [],
        trainingModule: [
          {
            id: '100',
            groupName: 'Module Tracking Information',
            groupVariant: 'primary',
            groupType: 'defined',
            groupFields: [
              {objKey: 'review918810391', itemType: 'review', type: 'textarea', value: 'Please Utilize this section to set the basic module info', size: "12"},
              {objKey: 'trainingModuleName', itemType: 'form', type: 'text', vmodel: "obj", label: "Module Name", saveData: true, size: "8"},
              {objKey: 'trainingModuleType', itemType: 'form', type: 'select', vmodel: "obj", label: "Module Type", saveData: true, size: "4", selectOptions: [{text: 'None', value: ''},{text: 'Assigned', value: 'assigned'}, {text: 'Scheduled', value: 'scheduled'}, {text: 'User Assigned', value: 'user'}]},
              {objKey: 'trainingModuleCompletionDate', itemType: 'form', type: 'date', vmodel: "obj", label: "Module Completion/Due Date", saveData: true, size: "4", visibility: [{variable: 'trainingModuleType', type: 'isOneOf', values: ['scheduled', 'assigned']}]},
              {objKey: 'trainingModuleCredential', itemType: 'form', type: 'dataRef', vmodel: "obj", label: "Module Credentials", refType: 'multiselect', refItem: 'credentialRequirement', refValue: 'id', refKey: 'credentialRequirementName', saveData: true, size: "8"},
            ]
          },
          {
            id: '101',
            groupName: 'Module Form Items',
            groupVariant: 'info',
            groupType: 'userDefined',
            dataStore: 'trainingModuleFormData',
            groupFields: [
              {objKey: 'formData0391', itemType: 'form', type: 'html', vmodel: "obj", label: "Form Data", saveData: true, size: "12"}
              ]
          },
          {
            id: '102',
            groupName: 'Module Assigned Users',
            groupVariant: 'secondary',
            groupType: 'defined',
            groupVisibility: [{variable: 'trainingModuleType', type: 'isOneOf', values: ['assigned']}],
            groupFields: [
              {objKey: 'trainingModuleAssignedUsers', itemType: 'array', type: 'userBuilderArray', label: "Assigned Users", saveData: true, size: "12"}
            ]
          },
          {
            id: '200',
            groupName: 'Training Module Submission Permissions',
            groupVariant: 'success',
            groupType: 'userDefined',
            dataStore: 'trainingModuleSubmissionVisibility',
            groupFields: [
              {objKey: 'role', itemType: 'array', type: 'submissionBuilder', label: "Submission Builder", saveData: true, size: "12"}
              ]
          }
        ],
       trainingModuleSubmissionRequirements: [
           {type: 'isNotBlank', variable: 'trainingModuleName'},
           {type: 'isNotBlank', variable: 'role'},
           {type: 'ifEqualsThen', ifVar: 'trainingModuleType', ifVal: ['assigned', 'scheduled'], variable: 'trainingModuleCompletionDate', thenCon: 'isNotBlank'},
           {type: 'ifEqualsThen', ifVar: 'trainingModuleType', ifVal: ['assigned'], variable: 'trainingModuleAssignedUsers', thenCon: 'isNotBlank'}
       ],
      credentialsData: [],
      credentialRequirement: [],
      trainingModules: [],
      setGroupUsersForTraining: [],
      previousTrainingMods: [],
      searchData: '',

    }
  },
  created(){
    this.getTrainingModules()
    this.getCredentials()
    this.getCredentialRequirements()
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),

  
  },
  mounted(){

  },
  watch: {
  },
  methods: {
    checkModForEdit(id){
      var index = this.editingMods.indexOf(id)
      if(index < 0){
        return true
      } else {
        return false
      }
    },
    formReset(){
      this.dataReset = false
      this.$nextTick(function(){
        this.dataReset = true
        this.getTrainingModules
      })
    },
    getTrainingModules(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingmodule/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.updateReset = false
        this.mainForm = false
        this.trainingModules = response.data
        if(this.previousTrainingMods){
          var array = response.data
          var holdIds = this.previousTrainingMods.map(p => p.id)
          this.previousTrainingMods = []
          for(var i in holdIds){
            var index = array.map(p => p.id).indexOf(holdIds[i])
            if(index != -1){
              this.previousTrainingMods.push(array[index])
            }
          }
        }
      this.$nextTick(function(){
          this.updateReset = true
          this.mainForm = true
        })
      })
    },
    showTrainingModule(id){
      var obj = this.trainingModules.find(p => p.id == id)
      if(obj){
        this.previousTrainingMods.push(obj)
      }
    },
    removeTrainingModule(id, cancel){
      if(cancel){
       var result = confirm('Are You Sure You Cancel, you will lose all unsaved changes?')
         if (result) {
           this.removeTrainingModule(id)
         }
      } else {
      var index = this.previousTrainingMods.map(p => p.id).indexOf(id)
      if(index != -1){
        this.previousTrainingMods.splice(index, 1)
      }
      var indexEdit = this.editingMods.indexOf(id)
      if(index != -1){
        this.editingMods.splice(index, 1)
      }
      }
    },
    editTrainingModule(id){
      this.editingMods.push(id)
    },
    getCredentials(){
      axios({
        method: 'get',
        url: '/api/v1/training/credential/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialsData = response.data
      })
    },
    getCredentialRequirements(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialRequirement = response.data
      })
    },



}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.requirement-error{
  border: 1px solid red;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
