<template>
	<div>
		<b-modal
		v-model="loginStatusToggle"
		:no-close-on-backdrop='true'
		:no-close-on-esc='true'
		header-class="mt-0 pt-0"
		 >
			<template  slot="modal-header">
				  <b-img  class="mt-0 px-5" src="https://statportals-static.nyc3.digitaloceanspaces.com/images/statportals.png" />
			</template>
				<b-container>

					<h4 class="pt-0 mt-0"> Please Login </h4>

					<b-row  v-if="errorResponseMessage != ''">
						<b-col align="center">
					<b-alert align="center" variant="danger" class="errorMessage"  show>
						<h6>{{ errorResponseMessage.message }}</h6>
					</b-alert>
					<b-btn variant="danger" v-if="passwordSubmission == false" @click="passwordSubmission = !passwordSubmission, authorizedUseAgreement = false">Forgot Password?</b-btn>
					<b-btn variant="primary" v-if="passwordSubmission == true" @click="passwordSubmission = !passwordSubmission, errorResponseMessage = ''">Return to Login?</b-btn>
				</b-col>
				</b-row>
				<form autocomplete="on">
				<h5>User Name:</h5>
				<b-form-input @keyup.native.enter="postSelect" type="email" name="email" autocomplete="email" v-model="nameInput" />
				<h5 v-if="!passwordSubmission">Password:</h5>
				<b-form-input v-if="!passwordSubmission" @keyup.native.enter="postSelect"  type="password" name="password" autocomplete="current-password" v-model="passwordInput" />
				<h5 v-if="passwordSubmission">Please enter your account pin</h5>
				<b-form-input v-if="passwordSubmission" @keyup.native.enter="postSelect" type="number" name="resetpin" autocomplete="no" v-model="pinInput" />				
				</form>
				<b-form-checkbox v-show="false" v-model="storeDataPersist" :value="true" :uncheck-value="false">Auto Login</b-form-checkbox><br>
				<em v-show="storeDataPersist">This should only be used on personal devices, data will be automatically removed if the user clicks the logout button<br></em> 
				<b-row><b-col align="right" class="pr-0" cols="auto">
				<b-form-checkbox v-model="authorizedUseAgreement">I agree to the</b-form-checkbox></b-col>
				<b-col align="left" class="pl-0" cols="auto">
				<b-btn class="mt-0 pt-0 pl-1" align="left" variant="link" target="_blank" href="https://statportals.com/authorized-use-policy">Authorized Use Policy</b-btn></b-col>
				</b-row>
				<b-btn variant="primary" @keyup.enter="postSelect" @click="postSelect" :disabled="!authorizedUseAgreement">Submit</b-btn>
			</b-container>
			<template slot="modal-footer">
				<div class="socialmediaWrap">
					<socialmedia />
				</div>
			</template>

		</b-modal>
	  </div>
</template>
<script>
import axios from 'axios'
import socialMedia from '../secondarymenu/socialmediaicons.vue'
export default {
	props: ["loginStatusToggle"],
	components: {
		'socialmedia': socialMedia
	},
  data: function() {
    return {
			nameInput: '',
			passwordInput: '',
			storeDataPersist: '',
			authorizedUseAgreement: true,
			pinInput: '',
			errorResponseMessage: [],
			dataBool: true,
			passwordSubmission: false,
			taxonomyPull: ['station', 'apparatus', 'blogtype', 'vehicletype', 'checkdetails', 'checkitems', 'deptrank', 'deptspec', 'documenttype', 'eventtype', 
			'medication', 'workgroup', 'repairstatus', 'shiftrotation', 'tutorial', 'trainingref', 'logisticasset'], 
    }
  },
	created(){
		this.nameInput = ''
		this.passwordInput = ''
		this.pinInput = ''
	},
	watch: {

	},
	computed: {
	nameState() {
		if(this.nameInput.match('@')){
			return false;
		}
		else {
			return null
		}
	},
},
	methods: {
		postSelect(){
			if(this.passwordSubmission == true){
				return this.resetSubmit()
			} else {
				this.submitPost()
			}
		},
		resetSubmit(){
			this.errorResponseMessage = []
			var data = {
				'email': this.nameInput.toLowerCase(),
				'resetPin': this.pinInput
			}
			axios.post('/api/v1/auth/password-reset', data).then( response => {
				this.errorResponseMessage = { message: response.data }
					this.nameInput = ''
					this.passwordInput = ''
					this.pinInput = ''	
			}).catch( error => {
				this.errorResponseMessage = { message: error.response.data.message }
					this.nameInput = ''
					this.passwordInput = ''
					this.pinInput = ''
					this.passwordSubmission = false	
  });
						
		},
		submitPost(){
			this.errorResponseMessage = []
			this.$cookie.remove('userToken')
			var data = {
						'email': this.nameInput.toLowerCase(),
						'password': this.passwordInput
					}
			this.$store.dispatch('LOAD_USER_DATA_STORE', data).then(
										response => {
														this.$store.dispatch('LOAD_SESSION_TOKEN', response.token)
														if(this.storeDataPersist == true){
														var email = response.user.email
														var refreshToken = response.token.refreshToken
														this.$store.dispatch('LOAD_LOGIN_PERSIST', {email: email, refreshToken: refreshToken})
														}	
														var token = 'bearer ' + response.token.accessToken

														var headers = { headers: { 'content-type': 'application/json', 'Authorization': token,  'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
														             this.$store.dispatch('CLEAR_USER_MESSAGES')
													 for(var i = 0; i < this.taxonomyPull.length; i++){
																var payload = { taxonomy: this.taxonomyPull[i], headers: headers}
																this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
															}         
															this.$store.dispatch('LOAD_CONTACT_DATA_EXP', headers)
															this.nameInput = ''
															this.passwordInput = ''											
									 			},
										response => {
											this.errorResponseMessage = response
										})


				}
			}
/**  
 *  Password Reset Flow: Enter Email and Click Submit
 *  											Click Link in email which provides (email/authToken)
 * 												Fill out form with double password Check & re-entering Email
 * 												Check Email / Token for correct user
 * 												Save Password
 * 												Return to login Screen on Success
 * 												User Logs In
 */												
}
</script>
<style scoped>
.socialmediaWrap{
	width: 100%;
}
.errorMessage {
	font-weight: bold;
}
img {
width: 100%;
height: auto;
margin-top: 20px;
}
.container {
	width: 80%;
	margin: 0 auto 0;

}
 h4 {
	padding-top: 30px;
}
.btn {
	margin-top: 10px;
}


	/* Smartphones (portrait and landscape) */
@media only screen and (min-width : 320px) and (max-width : 480px) {
/* Styles */
}

/* Smartphones (landscape) */
@media only screen and (min-width : 321px) {
	.modal-body-sig {
		margin: 10px auto 0;


  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }

}

/* Smartphones (portrait) */
@media only screen and (max-width : 320px) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }

}

/* iPads (portrait and landscape) */
@media only screen and (min-width : 768px) and (max-width : 1024px) {
/* Styles */
}

/* iPads (landscape) */
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) */
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
/* Styles */
}

/* Desktops and laptops */
@media only screen and (min-width : 1224px) {
	.modal-mask-sig {
	  position: fixed;
	  z-index: 9998;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(0, 0, 0, .5);
	  display: table;
	  transition: opacity .3s ease;
	}

	.modal-wrapper-sig {
	  display: table-cell;
	  vertical-align: middle;
	}

	.modal-container-sig {
	  width: auto;
	  min-width: 350px;
	  max-width: 600px;
	  max-height: 500px;
	  min-height: 300px;

	  background-color: #fff;
	  border-radius: 2px;
	  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	  transition: all .3s ease;
	  font-family: Helvetica, Arial, sans-serif;

	}
	.modal-footer {
	  padding: 20px;
	}
	.modal-header h3 {
	  margin-top: 0;
	  color: #42b983;
	}

	.modal-body-sig {
	  margin: 10% 0;
	  margin-left: 20px;
	  text-align: left;
	}

	.modal-default-button {
	  float: right;
	}

	/*
	 * The following styles are auto-applied to elements with
	 * transition="modal" when their visibility is toggled
	 * by Vue.js.
	 *
	 * You can easily play with the modal transition by editing
	 * these styles.
	 */

	.modal-enter {
	  opacity: 0;
	}

	.modal-leave-active {
	  opacity: 0;
	}

	.modal-enter .modal-container-sig,
	.modal-leave-active .modal-container-sig {
	  -webkit-transform: scale(1.1);
	  transform: scale(1.1);
	}
}

/* Large screens */
@media only screen and (min-width : 1824px) {
	.modal-mask-sig {
	  position: fixed;
	  z-index: 9998;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(0, 0, 0, .5);
	  display: table;
	  transition: opacity .3s ease;
	}

	.modal-wrapper-sig {
	  display: table-cell;
	  vertical-align: middle;
	}

	.modal-container-sig {
	  width: auto;
	  min-width: 350px;
	  max-width: 600px;
	  max-height: 500px;
	  min-height: 300px;

	  background-color: #fff;
	  border-radius: 2px;
	  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	  transition: all .3s ease;
	  font-family: Helvetica, Arial, sans-serif;

	}
	.modal-footer {
	  padding: 20px;
	}
	.modal-header h3 {
	  margin-top: 0;
	  color: #42b983;
	}

	.modal-body-sig {
	  margin: 10% 0;
	  margin-left: 20px;
	  text-align: left;
	}

	.modal-default-button {
	  float: right;
	}

	/*
	 * The following styles are auto-applied to elements with
	 * transition="modal" when their visibility is toggled
	 * by Vue.js.
	 *
	 * You can easily play with the modal transition by editing
	 * these styles.
	 */

	.modal-enter {
	  opacity: 0;
	}

	.modal-leave-active {
	  opacity: 0;
	}

	.modal-enter .modal-container-sig,
	.modal-leave-active .modal-container-sig {
	  -webkit-transform: scale(1.1);
	  transform: scale(1.1);

}
}
/* iPhone 4. Comma can be used to apply same rules to multiple media queries. */
@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
/* Styles */
}

/*iPhone 6 Portrait*/
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation : portrait) {
	.modal-body-sig {
    margin: 10px auto 0;

  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }
}

/*iPhone 6 landscape*/
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation : landscape) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }
}

/*iPhone 6+ Portrait*/
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation : portrait) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}

/*iPhone 6+ landscape*/
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation : landscape) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}

/*iPhone 6 and iPhone 6+ portrait and landscape*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px){
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}

}

/*iPhone 6 and iPhone 6+ portrait*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px) and (orientation : portrait){
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}

/*iPhone 6 and iPhone 6+ landscape*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px) and (orientation : landscape){
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}
	</style>
