<template>
<div>
  <b-container>
    <b-container v-if="form && form.id" class="mb-2">
    <b-btn v-if="submissionView != false" @click="submissionView = false" size="sm" variant="info">Edit</b-btn>
     <b-btn v-if="submissionView == false"  @click="updateForm" size="sm" variant="success">Save</b-btn>
    </b-container>
       
    <b-form-input v-if="submissionView == false" :state="formName == '' ? false : true" v-model="formName" placeholder="Form Name" />
    <b-row class="mt-2"><b-col>
        <b-form-select class="shiftSelectInput" @change="formCat = null" text-field="documenttypeName" value-field="documenttypeName" v-model="formParentCat" :options="documentCats">
          <option :value="null" disabled> Please Select Form Category</option>
        </b-form-select></b-col>
        <b-col>
            <b-form-select :disabled="formParentCat == null" class="shiftSelectInput" text-field="documenttypeName" value-field="documenttypeName" v-model="formCat" :options="documentSub(formParentCat)">
              <option :value="null" disabled> Please Select Form Sub-Category</option>
            </b-form-select></b-col>
            <b-col cols="*" class="py-2"><b-form-checkbox v-model="formActive">Form Active</b-form-checkbox></b-col>
    </b-row>
    <b-btn  v-if="submissionView == false" class="mt-2" block variant="primary" @click="showNewFieldModal = !showNewFieldModal, currentEditFieldObject = ''">Add New Form Field</b-btn>
  </b-container>
<b-modal v-if="showNewFieldModal" v-model="showNewFieldModal" size="lg" :hide-footer="true" :hide-header="true">
  <formFieldModal 
  :newField="true"
  :field="currentEditFieldObject"
  @saveModalHoldObj="saveModalHoldObj($event, false)" 
  @saveModalHoldObjKeep="saveModalHoldObj($event, true)" 
  @removeModalHoldObj="showFieldModelDelete($event)"
  />
</b-modal>
<b-container>
<b-card no-body>
  <b-tabs card>
    <b-tab title="Form Fields">
        <h6 class="text-danger" v-if="formFields && formFields.length == 0">Form Fields Required for Submission</h6>
    <formField v-if="formFields && formFields.length > 0 && !formReset"  
        :group="returnGroupData" 
        @editField="showFieldModelEdit($event)" 
        :submissionView="submissionView"
        @updateObjectValue="updateFormDataHold"
        :vmodelHoldDefault="vmodelHold" 
        :formValues="formValues"
       />
    </b-tab>
    <b-tab title="Visibility Rules"  v-if="form && submissionView == false">
    <b-form-group label="User Roles that are allowed to create new submissions">
      <b-form-checkbox-group
        :id="'checkbox-group-1-vis-create' + formholdId"
        v-model="formSubmission.visibilityCreateRoles"
        :options="siteRoles"
        name="vis-create"
      ></b-form-checkbox-group>
          </b-form-group>
    <b-form-group label="User Roles that are allowed to see previous submissions">
      <b-form-checkbox-group
        :id="'checkbox-group-1-vis-sub' + formholdId"
        v-model="formSubmission.visibilitySubmittedRoles"
        :options="siteRoles"
        name="vis-sub"
      ></b-form-checkbox-group>
    </b-form-group>
    <h6 class="text-danger">If a user is tagged and emailed a submission, they will be able to view that single submission regardless if their role is not tagged above</h6>
    </b-tab>
    <b-tab title="Submission/Email"  v-if="form && submissionView == false">
        <b-container>
          <b-row><b-col><b-form-checkbox v-model="formSubmission.emailAuthorFlag">Email Author On Submission</b-form-checkbox></b-col>
          <b-col><b-form-checkbox v-model="formSubmission.emailChooseEmail">Allow Author to Select Users to Email</b-form-checkbox></b-col></b-row>
        <b-row class="my-2"><b-col ><b-form-checkbox  class="mt-2" v-model="formSubmission.emailSelectFlag">Email Specific Users On Submission</b-form-checkbox></b-col>
        <b-col>
                <Multiselect      :disabled="!formSubmission.emailSelectFlag"
                                  v-model="formSubmission.emailSelect"
                                  :options="deptContactData"
                                  :multiple="true" 
                                  :close-on-select="false" :clear-on-select="true" 
                                  :hide-selected="true" :preserve-search="true" 
                                  placeholder="Available Users To Select" 
                                  label="lastName" track-by="id">
                    <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                  </template>
                </Multiselect>

        </b-col>
        </b-row>
        <b-row v-if="fieldsAvailableForTag.length > 0"  class="my-2"><b-col><b-form-checkbox class="mt-2" v-model="formSubmission.emailTaggedFlag">Email Users Tagged in Submission</b-form-checkbox></b-col>
        <b-col>
                    <Multiselect  :disabled="!formSubmission.emailTaggedFlag"
                                  v-model="formSubmission.emailTagged"
                                  :options="fieldsAvailableForTag"
                                  :multiple="true" 
                                  :close-on-select="false" :clear-on-select="true" 
                                  :hide-selected="true" :preserve-search="true" 
                                  placeholder="Available Fields To Track" 
                                  label="label" track-by="id">
                     </Multiselect>
        </b-col>
        </b-row>
      <b-form-group label="Email to Specific Site Roles on Submission">
      <b-form-checkbox-group
        :id="'checkbox-group-1-email-roles' + formholdId"
        v-model="formSubmission.emailRoles"
        :options="siteRoles"
        name="vis-create"
      ></b-form-checkbox-group>
          </b-form-group>
        </b-container>
    </b-tab>
    <b-tab title="Edit Rules"  v-if="form && submissionView == false">
        <b-container>
         <b-form-checkbox v-model="formSubmission.editAuthor">Allow Author to Edit Submission</b-form-checkbox>
        <b-row class="my-2"><b-col ><b-form-checkbox  class="mt-2" v-model="formSubmission.editSelectFlag">Allow Specific Users to Edit Submission</b-form-checkbox></b-col>
        <b-col>
                <Multiselect      :disabled="!formSubmission.editSelectFlag"
                                  v-model="formSubmission.editSelect"
                                  :options="deptContactData"
                                  :multiple="true" 
                                  :close-on-select="false" :clear-on-select="true" 
                                  :hide-selected="true" :preserve-search="true" 
                                  placeholder="Available Users To Select" 
                                  label="lastName" track-by="id">
                    <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                  </template>
                </Multiselect>

        </b-col>
        </b-row>
        <b-row v-if="fieldsAvailableForTag.length > 0"  class="my-2">
          <b-col><b-form-checkbox class="mt-2" v-model="formSubmission.editTaggedFlag">Allow Users Tagged in Submission to Edit</b-form-checkbox></b-col>
        <b-col>
                    <Multiselect  :disabled="!formSubmission.editTaggedFlag"
                                  v-model="formSubmission.editTagged"
                                  :options="fieldsAvailableForTag"
                                  :multiple="true" 
                                  :close-on-select="false" :clear-on-select="true" 
                                  :hide-selected="true" :preserve-search="true" 
                                  placeholder="Available Fields To Track" 
                                  label="label" track-by="id">
                     </Multiselect>
        </b-col>
        </b-row>
      <b-form-group label="Allow Specific Site Roles to Edit Submission">
      <b-form-checkbox-group
        :id="'checkbox-group-1-edit-roles' + formholdId"
        v-model="formSubmission.editRoles"
        :options="siteRoles"
        name="edit-roles"
      ></b-form-checkbox-group>
          </b-form-group>
        </b-container>
    <h6 class="text-danger">Once a submission is locked, it can not be edited prior to being unlocked, regardless of these permissions</h6>
    </b-tab>
    <b-tab title="Lock Rules" v-if="form && submissionView == false">
       <b-container>
         <b-form-checkbox v-model="formSubmission.lockSubmission">Auto Lock on Submission?</b-form-checkbox>
 <b-form-group label="User Roles that are allowed to Lock Submissions">
      <b-form-checkbox-group
        :id="'checkbox-group-1-lock-roles' + formholdId"
        v-model="formSubmission.lockRoles"
        :options="siteRoles"
        name="lock-roles"
      ></b-form-checkbox-group>
          </b-form-group>
           <b-form-group label="User Roles that are allowed to Unlock Previously Locked Submissions">
      <b-form-checkbox-group
        :id="'checkbox-group-1-unlock-roles' + formholdId"
        v-model="formSubmission.unlockRoles"
        :options="siteRoles"
        name="unlock-roles"
      ></b-form-checkbox-group>
          </b-form-group>
          
       </b-container>
    </b-tab>
    <b-tab title="Submission Table Options"  v-if="form && submissionView == false">
      <h5>Select a Question from the Dropdown and if desired modify the column name</h5>
      <b-row><b-col>
      <b-form-group>
      <b-form-select
        :id="'checkbox-group-1-submission-table' + formholdId"
        v-model="submissionTableSelection"
        :options="submssionTableOptions"
        @change="defaultSubmissionTableHeader"
        name="submissionTable"
      ></b-form-select>
          </b-form-group>
          </b-col><b-col><b-form-input v-model="submissionTableHeader" /></b-col>
          <b-col cols="*"><b-btn variant="info" size="sm" @click="addTableSelections" :disabled="submissionTableHeader == ''">Add</b-btn></b-col>
      </b-row>
      <h6 v-if="submissionTableSelections && submissionTableSelections.length > 0">Remove or Re-order table columns by Drag/Drop</h6>
      <draggable v-model="submissionTableSelections">
        <b-badge class="mr-2" v-for="(item, index) in submissionTableSelections" variant="info" :key="index">{{ item.label }} <span @click="submissionTableSelections.splice(index, 1)">X</span></b-badge>
      </draggable>
      <b-table :fields="tableFieldsReturned" :items="[]" />
    </b-tab>
  </b-tabs>

</b-card>
<b-btn variant="success" @click="saveForm" v-if="!formReset && !form" :disabled="!(formFields && formFields.length > 0 && formName != '')" >Save Form for Submission Options/Visibility</b-btn>
</b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import formFieldModal from './newDigitalFormsFieldsModal'
import formField from './newDigitalFormsFields'
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable'
export default {
  components: {
    formFieldModal,
    formField,
    Multiselect,
    draggable
  },
  name: 'new-digital-forms',
  props: ['form'],
  data () {
    return {
       formFields: [],
       formName: '',
       formActive: false,
       formSubmissionOptions: '',
       currentEditFieldObject: '',
       showNewFieldModal: false,
       formReset: false,
       vmodelHold: {},
       formValues: {},
       submissionView: false,
       formParentCat: null,
       formCat: null,
       formSubmission: {
         emailAuthorFlag: true,
         emailChooseEmail: false,
         emailSelectFlag: false,
         emailTaggedFlag: false,
         emailSelect: [],
         emailTagged: [],
         emailRoles: [],
         editAuthor: false,
         editSelectFlag: false,
         editTaggedFlag: false,
         editSelect: [],
         editTagged: [],
         editRoles: ['admin'],
         visibilityCreateRoles: ['admin', 'adminchief', 'fireops', 'emsops', 'logisticsops', 'trainingops', 'shopops', 'supervisor', 'user'],
         visibilitySubmittedRoles: ['admin', 'adminchief', 'fireops', 'emsops', 'logisticsops', 'trainingops', 'shopops', 'supervisor'],
         lockSubmission: false,
         lockRoles: ['admin', 'adminchief'],
         unlockRoles: ['admin', 'adminchief']
       },
       submissionTableSelection: '',
       submissionTableHeader: '',
       submissionTableSelections: [],
       formholdId: '',
       siteRoles: [
          { text: 'Admin', value: 'adminchief'},
          { text: 'Fire Ops' , value: 'fireops'},
          { text: 'EMS Ops', value: 'emsops'},
          { text: 'Training Ops', value: 'trainingops'},
          { text: 'Shop Ops', value: 'shopops'},
          { text: 'Logistics Ops', value: 'logisticsops'},
          { text: 'Supervisor', value: 'supervisor'},
          { text: 'User', value: 'user'},
      ],
    }
  },
  created(){
    if(this.form){
      this.formholdId = this.form.id
      this.formName = this.form.formName
      this.formFields = this.form.formFields
      this.formActive = this.form.formActive
      this.submissionView = false //true
      if(this.form.formParentCat){
        this.formParentCat = this.form.formParentCat
      }
      if(this.form.formCat){
        this.formCat = this.form.formCat
      }
      if(this.form.formTableCols){
        this.submissionTableSelections = this.form.formTableCols
      }
      if(this.form.formSubmission){
        this.formSubmission = this.returnFormSubmission(this.form.formSubmission)
      }
    } else {
      this.formholdId = Math.random().toString(36).substr(2, 15)
    }
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData',
          'documentTags'
        ]),
       documentCats(){
          var newArray = [... new Set(this.documentTags.filter(p => p.documenttypeParent == '')
          )]
          return _.orderBy(newArray, ['documenttypeName'], ['asc'])
        },
        returnGroupData(){
          var obj = {
            id: "100",
            groupName: "Module Tracking Information",
            groupVariant: "primary",
            groupType: "defined",
            groupFields: this.formFields
          }
          this.buildFormData([obj])
          return obj
        },
        fieldsAvailableForTag(){
          return this.formFields.filter(p => {
              return p.type == 'dataRef' && p.refItem == 'deptContactData'
          })
          
        },
        submssionTableOptions(){
          var array = []
          var preMap = this.submissionTableSelections
          var mapHold = preMap.map(p => p.key)
          var field = this.formFields.filter(p => {
                var index = mapHold.indexOf(p.objKey)
                if(p.type != 'textarea' && index == -1){
                  return p
                }
          })
          for(var i in field){
            var obj = {key: field[i].objKey, formLabel: field[i].label}
            var hold = {value: obj, text: field[i].label }
            array.push(hold)
          }
          return array
        },
        tableFieldsReturned(){
          var array = ['updatedAt', 'formAuthor']
          var select = this.submissionTableSelections
          for(var o in select){
            array.push(select[o])
          }
          array.push('locked')
          array.push('view')
          return array
        },
        formSubmissionReturned(){
        var formSubmission = {
         emailAuthorFlag: this.formSubmission.emailAuthorFlag,
         emailChooseEmail: this.formSubmission.emailChooseEmail,
         emailSelectFlag: this.formSubmission.emailSelectFlag,
         emailTaggedFlag: this.formSubmission.emailTaggedFlag,
         emailTagged: this.formSubmission.emailTaggedFlag ? this.formSubmission.emailTagged.map(p => p.objKey) : [],
         emailSelect: this.formSubmission.emailSelectFlag ? this.formSubmission.emailSelect.map(p => p.email) : [],
         emailRoles: this.formSubmission.emailRoles ? this.formSubmission.emailRoles : [],
         editAuthor: this.formSubmission.editAuthor,
         editSelectFlag: this.formSubmission.editSelectFlag,
         editTaggedFlag: this.formSubmission.editTaggedFlag,
         editSelect: this.formSubmission.editSelectFlag ? this.formSubmission.editSelect.map(p => p.id) : [],
         editTagged: this.formSubmission.editTaggedFlag ? this.formSubmission.editTagged.map(p => p.objKey) : [],
         editRoles: this.formSubmission.editRoles ? this.formSubmission.editRoles : ['admin'],
         visibilityCreateRoles: this.formSubmission.visibilityCreateRoles ? this.formSubmission.visibilityCreateRoles : ['admin'],
         visibilitySubmittedRoles: this.formSubmission.visibilitySubmittedRoles ? this.formSubmission.visibilitySubmittedRoles : ['admin'],
         lockSubmission: this.formSubmission.lockSubmission,
         lockRoles: this.formSubmission.lockRoles ? this.formSubmission.lockRoles : ['admin'],
         unlockRoles: this.formSubmission.unlockRoles ? this.formSubmission.unlockRoles : ['admin'],

       }
       // console.log(formSubmission) 
        return formSubmission
      }
  
  },
  mounted(){

  },
  watch: {

  },
  methods: {
    addTableSelections(){
     var obj = this.submissionTableSelection
     obj['label'] = this.submissionTableHeader
     this.submissionTableSelections.push(obj)
     this.$nextTick(function(){
       this.submissionTableSelection = ''
       this.submissionTableHeader = ''
     })
    },
    documentSub(data){
      var returnData = this.documentTags.filter(p => {
        return p.documenttypeParent.match(data)
      })
      return _.orderBy(returnData, ['documenttypeName'], ['asc'])
    },
    returnFormSubmission(data){
      var obj = _.cloneDeep(data)
        obj['emailTagged'] = data.emailTagged && data.emailTagged.length > 0 ? data.emailTagged.map(item => this.getFormObj(item)) : []
        obj['emailSelect'] = data.emailSelect && data.emailSelect.length > 0 ? data.emailSelect.map(item => this.getUserObj(item, 'email')) : []
        obj['editSelect'] = data.editSelect && data.editSelect.length > 0 ? data.editSelect.map(item => this.getUserObj(item, 'id')) : []
        obj['editTagged'] = data.editTagged && data.editTagged.length > 0 ? data.editTagged.map(item => this.getFormObj(item)) : []

       return obj
    },
    getUserObj(value, key){
      var obj = this.deptContactData.find(p => p[key] == value)

      if(obj){
        return obj
      } return {}
    },
    getFormObj(value){
      var obj = this.form.formFields.find(p => p.objKey == value)
      if(obj){
        return obj
      } return {}
    },
    defaultSubmissionTableHeader(data){
      if(data && data.formLabel){
          this.submissionTableHeader = _.truncate(data.formLabel, {length: 20})
      }
    },
    showFieldModelEdit(id){
      var obj = this.formFields.find(p => p.id == id)
      if(obj){
        this.currentEditFieldObject = obj
        this.showNewFieldModal = true
      }
    },
      updateFormDataHold(data){
       // console.log(data)
        var objHold = data.objKey
        delete data.objKey
        this.formValues[objHold] = data
    },
    showFieldModelDelete(id){
       var index = this.formFields.map(p => p.id).indexOf(id)
      if(index != -1){
        this.formFields.splice(index, 1)
        this.showNewFieldModal = false
        this.currentEditFieldObject = ''
      }
    },
    saveModalHoldObj(data, boo){
      var objkeyhod = Math.random().toString(36).substr(2, 15)
      var labelId = data.label.replace(/ /g,'')
      labelId = _.truncate(labelId, {length: 20})
      labelId = labelId.replace(/\./g, '')
      var id = objkeyhod + labelId
      var obj = data
      if(!obj.id){
      data.id = id
      data.objKey = labelId + '_' + objkeyhod
      this.formFields.push(data)
      } else {
        var index = this.formFields.map(p => p.id).indexOf(data.id)
        this.formFields[index] = data
      }
      this.formReset = true
     this.$nextTick(function(){
       this.formReset = false
     })
      if(!boo){
        this.showNewFieldModal = false
      } else {
        this.currentEditFieldObject = data
      }
    },
    buildFormData(data){
      var obj = {}
      var vmodelHold = {}
      for(var i in data){
        var fields = data[i].groupFields
        for(var f in fields){
          if(fields[f].itemType == 'form')
          var value = ''
          if(fields[f].type == 'select'){
            var obj = fields[f].selectOptions.find(p => p.default == true)
            if(obj){
              value = obj.value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            }
          }
          if(fields[f].type == 'checkbox'){
            var obj = fields[f].selectOptions.filter(p => p.default == true)
            if(obj){
              value = obj.map(p => p.value)
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            }
          }
          if(fields[f].type == 'boolean'){
              value = fields[f].value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            
          }
          if(fields[f].type == 'upload'){
            value = []
          }
          if(fields[f].type == 'selectmulti'){
            var obj = fields[f].selectOptions.filter(p => p.default == true)
            if(obj){
              value = obj
                var array = []
                var arrayStore = []
                for(var v in value){
                    array.push(value[v].value)
                } 
                for(var v in value){
                  arrayStore.push(value[v].text)
                }
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: arrayStore, searchvalue: array}
            } else {
              value = []
            }
          }
          if(fields[f].type == 'date' || fields[f].type == 'time' || fields[f].type == 'datetime'){
            value = {'date': '', 'time': ''}
          }
          vmodelHold[fields[f].objKey] = value
        }
      }

      this.vmodelHold = vmodelHold
    //  this.formValues = vmodelHold
    },
    updateForm(){
      axios({
        method: 'patch',
        url: '/api/v1/taxonomy/digitalforms/' + this.form.id,
        data: {
          formName: this.formName,
          formFields: this.formFields,
          formSubmission: this.formSubmissionReturned,
          formParentCat: this.formParentCat ? this.formParentCat : null,
          formActive: this.formActive,
          formCat: this.formCat ? this.formCat : null,
          formAuthor: this.userData.firstName + ' ' + this.userData.lastName,
          formTableCols: this.submissionTableSelections && this.submissionTableSelections.length > 0 ? this.submissionTableSelections : []
        },
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        if(response.data && response.data.id){
        this.$emit('closeFormAndReOpen', response.data.id)
        }
      })
    },
    saveForm(){
      axios({
        method: 'post',
        url: '/api/v1/taxonomy/digitalforms/',
        data: {
          formName: this.formName,
          formFields: this.formFields,
          formAuthor: this.userData.firstName + ' ' + this.userData.lastName,
          formParentCat: this.formParentCat ? this.formParentCat : null,
          formActive: this.formActive,
          formCat: this.formCat ? this.formCat : null
        },
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.formName = ''
        this.formFields = []
        this.formActive = false,
        this.formParentCat = null,
        this.formCat = null
        if(response.data && response.data[0]){
        this.$emit('showNewFormSubmission', response.data[0].id)
        }
      })
    },



}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.requirement-error{
  border: 1px solid red;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
