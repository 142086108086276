<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
     <b-navbar type="dark" variant="dark">
      <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Roster Profile Assigned Date:</b-navbar-brand>
       <b-form-input type="date" v-model="selectedDate" />
        <b-btn @click="addNewModal()">Add New Profile</b-btn>
        <b-form-checkbox v-if="userData.role == 'admin' || userData.role == 'adminchief'" v-model="adminFlag" class="text-light ml-2">Show All?</b-form-checkbox>
      </b-nav-form>
    </b-navbar>
      <b-card no-body>
       <b-tabs card  :lazy="true">
         <b-tab v-for="parent in rosterParentsData" :key="parent.id" :title="parent.stationName"  :lazy="true" @click="currentParent = parent.id">
       <h4 slot="header" class="clickPointer text-light text-left">{{ parent.stationName }}</h4>
        <b-card v-if="currentParent == parent.id" class="mb-2" no-body v-show="parent.showStation" v-for="station in stationsFromParents(parent.id)" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1">
          <h4 slot="header" class="clickPointer text-left">{{ station.stationName }}</h4>
            <b-card no-body v-for="roster in returnRosterSpots(parent.id, station.id)" :key="roster.id">
             <h5 class="text-left pl-5 mt-2">{{ roster.apparatusName }}</h5>
             <div v-for="(rosterSpot, indexp) in roster.rosterPositions" :key="indexp">
             <b-container v-if="rosterProfilesFind(roster.id, rosterSpot.position).length > 0">
              <b-card  no-body v-for="profile in rosterProfilesFind(roster.id, rosterSpot.position, roster)" :key="profile.id" border-variant="light">
                  <b-row><b-col lg="1">
                    {{ profile.rosterPosition }}</b-col>
                    <b-col lg="4">
                    {{ getUser(profile.userId) }} 
                    </b-col>
                    <b-col lg="4">
                             {{ getRosterProfileName(profile.scheduledShift) }}
                      </b-col>
                    <b-col lg="2">
                     Start: {{ getDateFormated(profile.rosterAssignmentStart) }}<br>
                      End: {{ getDateFormated(profile.rosterAssignmentEnd) }}
                    </b-col>
                    <b-col lg="1">
                      <b-btn variant="info" size="sm" @click="editProfile(profile)">Edit</b-btn>
                      <b-btn v-if="(userData.role == 'admin' || userData.role == 'adminchief') && getUser(profile.userId) == 'Unknown' && profile.rosterAssignmentEnd" variant="danger" size="sm" @click="deleteProfile(profile.id)">Delete</b-btn>
                    </b-col>
                    </b-row>
                </b-card>
             </b-container>
            <b-container v-else>
              <b-card  no-body border-variant="light">
                  <b-row><b-col lg="1" class="text-danger">
                    {{ rosterSpot.position }}</b-col>
                    <b-col lg="4">
                     <h6 class="text-danger">No Assignments</h6>
                    </b-col>
                    <b-col lg="2" class="text-danger">{{ rosterSpot.type }} 
                    </b-col>
                    <b-col lg="2">
                    
                    </b-col>
                    <b-col lg="2" class="text-danger">
                        {{ profile.scheduledShift }}
                      </b-col>
                    <b-col lg="1">
                      <b-btn variant="success" size="sm" @click="fillVacancyProfile(roster, rosterSpot)">Fill</b-btn>
                    </b-col>
                    </b-row>
                </b-card>
                </b-container>
             </div>
            <b-container v-if="rosterProfilesFindWithoutPosition(roster.id, roster.rosterPositions, roster).length > 0">
            <b-card  no-body v-for="profile in rosterProfilesFindWithoutPosition(roster.id, roster.rosterPositions)" :key="profile.id" border-variant="light">
                  <b-row><b-col lg="1" class="text-info font-weight-bold" title="OFF ROSTER - Position not on roster template">
                    {{ profile.rosterPosition }}*</b-col>
                    <b-col lg="4">
                    {{ getUser(profile.userId) }} 
                    </b-col>
                    <b-col lg="4">
                        {{ getRosterProfileName(profile.scheduledShift) }}
                      </b-col>
                    <b-col lg="2">
                     Start: {{ getDateFormated(profile.rosterAssignmentStart) }}<br>
                      End: {{ getDateFormated(profile.rosterAssignmentEnd) }}
                    </b-col>
                    <b-col lg="1">
                      <b-btn variant="info" size="sm" @click="editProfile(profile)">Edit</b-btn>
                      <b-btn v-if="(userData.role == 'admin' || userData.role == 'adminchief') && getUser(profile.userId) == 'Unknown' && profile.rosterAssignmentEnd" variant="danger" size="sm" @click="deleteProfile(profile.id)">Delete</b-btn>
                    </b-col>
                    </b-row>
                </b-card>
             </b-container>
          </b-card>
          </b-card>
    </b-tab>
    <b-tab v-if="userData.role == 'admin'" :disabled="true" title="Fixer">
      <b-card v-for="parent in rosterParentsData" :key="parent.id" :header="parent.stationName">
        <b-row v-for="(station, index) in stationsFromParentsFixer(parent.id)" :key="index">
          <b-card :header="station.rosterName">
            <b-btn variant="danger" @click="deleteRoster(station.id)">Delete Roster</b-btn>
          </b-card>
        </b-row>
      </b-card>
    </b-tab>
      </b-tabs>
    </b-card>
</div>
<b-modal v-model="showModal" size="lg" hide-footer>
  <rosterprofileView 
  v-if="showModal"
  :rosterData="rosterData" 
  :rosterProfiles="rosterProfiles" 
  :rosterShifts="rosterShifts" 
  :rosterSpotsFilter="rosterSpotsFilter" 
  :deptContactDataFiltered="deptContactDataFiltered"
  :previous="previousProfile"
  @updateProfileSubmit="updateProfileSubmit"
  @newProfileSubmit="newProfileSubmit" />
  </b-modal>
</div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import rosterprofileView from './rosterprofileView.vue'
export default {
  components: {
    multiselect,
    rosterprofileView
  },
  name: 'roster-admin',
    props: ['rosterData', 'rosterProfiles', 'scheduleData'],
  data () {
    return {
      showModal: false,
      apparatusSelectValue: null,
      stationSelectValue: '',
      stationParentSelectValue: '',
      previousProfile: null,
      rosterUserId: '',
      rosterId: '',
      rosterSpotType: 'permanent',
      rosterPosition: '',
      rosterScheduleShift: '',
      currentShift: '',
      currentParent: '',
      adminFlag: false,
      rosterKellyCycleNumber: '',
      rosterAssignmentStart: '',
      rosterNote: '',
      updateMultiSelect: true,
      rosterAssignmentEnd: '',
      bulkHoldObject: [],
      rosterStartTime: '08:00:00',
      rosterShiftLength: 24,
      selectedDate: moment().format('YYYY-MM-DD'),
      rosterProfileTableFields: [
        'userId',
        'rosterSpotType',
        'rosterAssignmentStart',
        'rosterAssignmentEnd'
      ],
      scheduledWorkDaysOptions:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      scheduledWorkDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      rosterPositionOpt: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      rosterParentsData: [],
      rosterSpotTypeOptions: ['permanent', 'float', 'temporary'],
      rosterFields: [
        'apparatusName',

      ],
    }
  },
  created(){
 this.rosterParents(this.rosterData)
  },
  mounted(){
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'deptContactData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        // user_id, roster_id, roster_spot_type, roster_position, roster_note, scheduled_shift, scheduled_kelly_cycle_number, roster_assignment_start, roster_assignment_end
        rosterShifts(){
          if(this.siteconfig.payroll.flexibleSchedule){
            var shifts = _.cloneDeep(this.scheduleData)
            shifts = shifts.filter(p => p.active)
         //   console.log(shifts)
            this.currentShift = shifts[0].id
            return _.orderBy(shifts.map(p => {
                var obj = {}
                obj['value'] = p.id
                obj['text'] = p.scheduleName
                return obj
            }), ['text'], ['asc'])
    
          }
          var shifts = this.siteconfig.payroll.shifts
          if(shifts){
            var names = shifts.map(p => p.shift)
            var shifts = _.sortBy(names)
            this.currentShift = shifts[0]
            shifts.push('Daily')
            return shifts
          }
        },
        checkForParentView(){
          return this.showRosterParents
        },
        rosterSpotsFilter(){
          var array = _.cloneDeep(this.rosterData)
         console.log(array)
          var indexArray = array.map(p => p.apparatusId)
          console.log(indexArray)
          var apparatusId = this.apparatusData.map(p => p.id)
          var apparatus = this.apparatusData.filter(p => {
            var index = indexArray.indexOf(p.id)
            if(index != -1){
              return p
            } 
          })
          var holdOvers = indexArray.filter(p => {
            var index = apparatusId.indexOf(p)
            if(index == -1){
              return p
            }
          })
          console.log(holdOvers)
          for(var p in holdOvers){
              var obj = this.rosterData.find(x => x.apparatusId == holdOvers[p])
              var name = obj.rosterName ? obj.rosterName : 'Unknown Variable'
              var objHold = {apparatusId: holdOvers[p], 'apparatusName': name, id: obj.id }
              apparatus.push(objHold)
          }
         // console.log(apparatus)
          return _.orderBy(apparatus, ['apparatusName'], ['asc'])
        },
        deptContactDataFiltered(){
          var open = this.rosterProfiles.filter(p => {
            return p.rosterAssignmentEnd == null
          })
          var indexArray = open.map(p => p.userId)
          var unassigned = this.deptContactData.filter(p => {
            var index = indexArray.indexOf(p.id)
            if(index == -1){
              return p
            }
          })
     var date = moment().format('YYYY-MM-DD')
     var type = 'permanent'
     if(unassigned){
       var array = []
     for(var i in unassigned){
       var obj = {}
       obj['id'] = unassigned[i].id
       obj['shift'] = unassigned[i].shiftAssignment
       obj['stationAssignment'] = unassigned[i].stationAssignment
       obj['rosterStartDate'] = date
       obj['rosterSpotType'] = type
       obj['rosterId'] = ''
       obj['rosterSpotHold'] = ''
       obj['rosterPosition'] = ''
       array.push(obj)
     }
     this.bulkHoldObject = array
    // console.log(this.bulkHoldObject)
     return unassigned
     } 
        },
     profilesFilteredByDate(){
      if(!this.adminFlag){
        var array = this.rosterProfiles.filter(p => {
          var start = moment(p.rosterAssignmentStart).isBefore(this.selectedDate)
           var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate) || moment(p.rosterAssignmentEnd).isSame(p.rosterAssignmentStart) : true
            if(start && end){
              return p
            }
    
    })
    return array
    } 
    return this.rosterProfiles
     
     }
  },
  mounted(){

  },
  watch: {
    rosterUserId(newData){
     // console.log(newData)
      if(newData && newData.shiftAssignment){
      var shifts = this.rosterShifts
      var userShift = newData.shiftAssignment
      var index = shifts.indexOf(userShift)
      if(index != -1){
        this.rosterScheduleShift = shifts[index]
      } 
      }
    },
    apparatusSelectValue(newData){
      if(newData.apparatusLocation){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusLocation)
        if(station){
          this.stationSelectValue = station
        }
      }
    if(newData.apparatusParent){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusParent)
        if(station){
          this.stationParentSelectValue = station
        }
      }
    }
  },
  methods: {
    setupCurrentShift(shift){
      if(shift && shift.id){
        this.currentShift = shift.id
      } else {
        this.currentShift = shift
      }
    },
    addNewModal(){
     // console.log('New')
      this.showModal = !this.showModal
    },
    getRosterProfileName(data){
      if(data){
        var obj = this.rosterShifts.find(p => p.value == data)
        if(obj && obj.text){
          return obj.text
        } "error"
      } return "error"
    },
    fillVacancyProfile(roster, spot){
      var obj = {
        rosterAssignmentStart: moment().toDate(),
        rosterAssignementEnd: null,
        rosterId: roster.id,
        rosterPosition: spot.position,
        rosterShiftLength: spot.duration,
        rosterStartTime: spot.start,
        scheduledShift: null,
        scheduledWorkDays: null,
        scheduledKellyCycleNumber: null,
      }
      this.previousProfile = obj
      this.showModal = !this.showModal
    },
    profileDeleteTest(date){
      return moment(date).isBefore(moment())
    },
    updateMultiSelectValue(data, id){
         // console.log(this.bulkHoldObject)
    this.bulkHoldObject.find(p => p.id == id).rosterId = this.getRosterIdFromApp(data.id)
    this.updateMultiSelect = false
    this.$nextTick(function(){
      this.updateMultiSelect = true
    })
    //console.log(this.bulkHoldObject[id])
    },
    unassignedUsersThatMeetRequirement(station, shift){
     return this.bulkHoldObject.filter(p => {
       return p.stationAssignment == station && p.shift == shift
     })


    },
    getUser(id){
   
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName + ' (' + user.deptRank + ') #' + user.deptSpecialties
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getDateFormated(date){
      if(date){
        return moment(date).format('MM/DD/YYYY')
      } else {
       return 'continuous'
      }
    },
    rosterProfilesFind(id, position, parent){
      var rosters = this.profilesFilteredByDate.filter(p => {
        return p.rosterId == id  && p.rosterPosition == position // && p.scheduledShift == shiftTest
            //   && moment(p.rosterAssignmentStart).isBefore(this.selectedDate, 'YYYY-MM-DD') &&
           //    p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate, 'YYYY-MM-DD') : true
      })
      if(rosters){
        return _.orderBy(rosters, ['rosterPosition'], ['asc'])
      } else {
        return []
      }

    },
    rosterProfilesFindWithoutPosition(id, positions){
      var arrayPos = positions ? positions.map(p => p.position) : []
      var rosters = this.profilesFilteredByDate.filter(p => {
        return p.rosterId == id && arrayPos.indexOf(p.rosterPosition) == -1 // && p.scheduledShift == shiftTest
            //   && moment(p.rosterAssignmentStart).isBefore(this.selectedDate, 'YYYY-MM-DD') &&
           //    p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate, 'YYYY-MM-DD') : true
      })
      return _.orderBy(rosters, ['rosterPosition'], ['asc'])
    },
    rosterParents(data){
    //  console.log('RosterParents')
    //  console.log(data)
           var array = data
           var parents = [...new Set(array.map(p => p.stationParent))]
           var parentsObj = parents.map(item => item = this.stationsData.find(p => p.id == item))
          // parentsObj.forEach(item => item['showStation'] = true)
           var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
           this.currentParent = parentsSort[0] ? parentsSort[0].id : ""
           this.rosterParentsData = parentsSort
        },
    stationsFromParentsFixer(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      return array
    },
    stationsFromParents(id){
     // console.log(id)
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort

    },
    returnRosterSpots(pid, sid){
      var roster = _.cloneDeep(this.rosterData)
     // console.log(roster)
      var array = roster.filter(p => { 
        return p.stationParent == pid && 
               p.stationId == sid 
        })
      array.forEach(item => item.rosterGroupType && item.rosterGroupType == 'variable' ? item['apparatusName'] = item.rosterName : item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId).apparatusName)
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])   
      return apparatusSort
    },
    getRosterIdFromApp(id){
      var roster = this.rosterData.find(p => p.apparatusId == id)
      if(roster){
        return roster.id
      } else {
        return null
      }
    },
    editProfile(data){
    //  console.log(this.rosterSpotsFilter)
      this.previousProfile = _.cloneDeep(data)
      this.showModal = !this.showModal

     
    },
    updateUserBulkUser(){
        var roster = this.bulkHoldObject.filter(p => {
          return p.rosterId != ''
        })
        for(var i in roster){
        axios({
          method: 'post',
          url: '/api/v1/staffing/rosterprofile/',
          data: {
            userId: roster[i].id,
            rosterId: roster[i].rosterId,
            rosterSpotType: roster[i].rosterSpotType,
            rosterPosition: roster[i].rosterPosition,
            scheduledShift: roster[i].shift,
            scheduledKellyCycleNumber: roster[i].rosterKellyCycleNumber ? roster[i].rosterKellyCycleNumber : null,
            rosterAssignmentStart: moment(roster[i].rosterStartDate, 'YYYY-MM-DD').toDate(),
            rosterShiftLength: this.rosterShiftLength,
            rosterStartTime: this.rosterStartTime,
            scheduledWorkDays: roster[i].shift == 'Daily' ? this.scheduledWorkDays : null


            },
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
      //    console.log(response.data)
        })
        }
        this.$nextTick(function(){
          bus.$emit('getRosterProfile')
        })
    },
    updateProfileSubmit(data){
    axios({
     method: 'patch',
     url: '/api/v1/staffing/rosterprofile/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
      this.showModal = false
      this.previousProfile = null
   })

    },
    deleteRoster(id){
     
    axios({
     method: 'delete',
     url: '/api/v1/staffing/roster/' + id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
   })
      
    },
    deleteProfile(id){
           var result = confirm('Are You Sure You Want to delete this profile? It is bad practice to remove historical payroll data, and this should only be done during setup or cleanup after initial setup. Even if the user is no longer employeed and shows as Unknown.')
      if (result) {
    axios({
     method: 'delete',
     url: '/api/v1/staffing/rosterprofile/' + id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
   })
   }
    },
    newProfileSubmit(data){
    axios({
     method: 'post',
     url: '/api/v1/staffing/rosterprofile/',
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
      this.showModal = false
      this.previousProfile = null
   })

    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
