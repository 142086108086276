<template>
    <b-container fluid class="p-1 m-1">
  <b-navbar type="dark" variant="dark">
           <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable" >Search:</b-navbar-brand>
         <b-form-input type="text" size="sm" v-model="searchData" placeholder="Search User Name" />
               <b-pagination v-if="adjustForAccurals" size="sm" class="mb-0 mx-3" :total-rows="adjustForAccurals.length" v-model="currentPage" :per-page="10" />
        <b-navbar-brand class="bootstrapTable" v-if="!siteconfig.payroll.importPayBanks">Report Date:</b-navbar-brand>
        <b-form-input type="date" v-model="payrollBankRecoDate" @input="updateBankDate" v-if="previous == '' && !siteconfig.payroll.importPayBanks" /> 
        <download-excel 
    class="ml-2"
    v-if="fixDataExcelCombined.length > 0"
    :name="'Reco Report Export ' + payrollBankRecoDate"
    :data="fixDataExcelCombined">
    <b-btn variant="success" size="sm">Excel Export</b-btn>
    </download-excel>
      </b-nav-form>
          <b-nav-form v-if="previous" class="ml-4">
            <b-form-input size="sm" type="date" v-model="modReportDate" />
            <b-btn variant="info" size="sm" @click="getPayrollModifiersInRange">Project Mods</b-btn>
    </b-nav-form>
    </b-navbar>
     <b-table :id="previous ? previous + '_table' : 'new_table'" :fields="fieldsAdjusted" :items="adjustForAccuralsPage"  v-if="recoreportDate" :responsive="true">
       <template v-for="bank in bankIds" :slot="bank.key" slot-scope="data">
         <span :class="bank.import ? 'font-weight-bold text-primary' : ''"
         v-if="data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)]"
         :key="bank.key + data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id + previous" 
         :id="'popoveruser-'+ data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id + previous">
               
         <span v-if="!importReport && !nonumbercoversion">{{ Math.floor(getValueFromThousands(data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].newTotal)) }}</span>
         <span v-else :class="data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].newTotal < 0 ? 'text-danger' : ''">{{ data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].newTotal }}</span>
          <span v-if="data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].mods" :class="getClassColor(data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].mods, data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].prev)">
           ({{ data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].mods }})
           </span>
         </span>
         <b-popover
           v-if="data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)]"
          :key="'popover-' + bank.key + data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id + previous"
          :target="'popoveruser-'+ data.item.id + data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)].id + previous"
          placement="right"
          :title="bank.label"
          triggers="hover focus"
          content="test"
        >
        <b-container>
          {{ data.item.lastName }} ({{ data.item.employeeId }})
          <div  v-for="(items, key, index) in data.item.banks[data.item.banks.map(p => p.id).indexOf(bank.key)]" :key="index">
         <b-row  v-if="key != 'id' && key != 'name' && key != 'modsAttached'">
           <b-col>{{ key }}</b-col>
           <b-col v-if="key != 'modsAttached'">{{ items }}</b-col>
           </b-row>
           <b-card v-if="key == 'modsAttached' && items.length > 0" title="Mods Attached" class="p-0">
              <b-table :fields="modfields" :items="items" class="m-0">
                  <template slot="payrollModCode" slot-scope="data">
                    <span :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
                  </template>
                  <template slot="payrollModDatetime" slot-scope="data">
                    {{ getFormatedDateTime(data.item.payrollModDatetime)}}
                  </template>
                  <template slot="payrollModHours" slot-scope="data">
                    <span :class="data.item.mod == 'addTo' ? 'text-success' : 'text-danger'" >{{ data.item.mod == 'addTo' ? "+" : "-" }}{{ data.item.payrollModHours }}</span>
                  </template>

                </b-table>
             </b-card>
           </div>
          </b-container></b-popover>
       </template>
       </b-table>
      </b-container>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import JsonExcel from 'vue-json-excel';
export default {
  components: {
    multiselect,
    userCard,
  'download-excel' : JsonExcel
  },
  name: 'roster-admin',
  props: ['recoreportDate', 'fields', 'bankIds', 'previous', 'payrollBankRecoDatePrev', 'importReport', 'nonumbercoversion', 'previousDate', 'payrollCodesData'],
  data () {
    return {
      currentPage: 1,
      searchData: '',
      payrollBankRecoDate: '',
      modReportDate: '',
      modsForReport: [],
      fieldsReturned: '',
      visibleIds: [],
      modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'},
    ],
    }
  },
  created(){
    this.payrollBankRecoDatePrev ? this.payrollBankRecoDate = this.payrollBankRecoDatePrev : this.payrollBankRecoDate = moment().format('YYYY-MM-DD')
    this.modReportDate = moment().add(1, 'year').format('YYYY-MM-DD')
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        deptContactDataReturn(){
          return _.cloneDeep(this.deptContactData)
        },
        fieldsAdjusted(){
          var fields = _.cloneDeep(this.fields)
          var arrayStandard = ['firstName', 'lastName', 'employeeId', 'view']
          var vm = this
          if(true){ //need toggle
            return fields.filter(p => {
                if(p.key){
                  if(vm.visibleIds.indexOf(p.key) != -1){
                    return p
                  } return
                } return p 
            })
          } return fields
        },
        fixDataExcelCombined(){
          if(!this.importReport){
          var array = _.cloneDeep(this.recoreportDate)
          } else {
            var importData = _.cloneDeep(this.payrollBankRecoDatePrev)
            var array = importData.map(item => {
                var obj = {}
                obj = _.cloneDeep(this.deptContactDataReturn.find(p => p.id == item.payrollUserId))
                if(obj){
                obj['payrollUserId'] = item.payrollUserId
                obj['banks'] = item.banks
                return obj
                }
            })
          }
          array = _.orderBy(array, ['employeeId'], ['asc'])
          var hold = []
          for(var i in array){
            var banks = array[i].banks
            banks = banks.filter(p => p.newTotal != 0 || p.prev != 0)
            banks = _.orderBy(banks, ['name'], ['asc'])
            for(var b in banks){
              var sobj = { employeeId: array[i].employeeId, 
                           employeeName: array[i].lastName + ', ' + array[i].firstName, 
                           leaveBankId: banks[b].name, 
                           balance: banks[b].newTotal, 
                           }
              hold.push(sobj)
            }

          }
          return hold
        },
        adjustForAccurals(){
          if(!this.importReport){
          var array = _.cloneDeep(this.recoreportDate)
          var mods = this.modsForReport
          array = array.filter(p => p.lastName && p.id)
          if(mods && mods.length > 0){
           // console.log('MODS')
            array = this.updateMods(array, mods)
          }
          array = array.filter(p => {
            return p.lastName.toLowerCase().match(this.searchData.toLowerCase()) || p.id.match(this.searchData)
          })

          } else {
            var importData = _.cloneDeep(this.payrollBankRecoDatePrev)
            importData = importData.filter(p => p.lastName && p.id)
            var array = importData.map(item => {
                var holdItem = _.cloneDeep(item)
                var obj = {}
                obj = _.cloneDeep(this.deptContactDataReturn.find(p => p.id == holdItem.payrollUserId))
                obj['payrollUserId'] = holdItem.payrollUserId
                obj['banks'] = holdItem.banks
                return obj
            })
            array = array.filter(p => {
            return p.lastName.toLowerCase().match(this.searchData.toLowerCase()) || p.id.match(this.searchData)
          })
          }
          this.setHiddenFields(array)
          return array
        },
        adjustForAccuralsPage(){
          var array = _.cloneDeep(this.adjustForAccurals)
          return array.slice((this.currentPage - 1) * 10, (this.currentPage) * 10)
        },
       
  },
  mounted(){

  },
  watch: {


  },
  methods: {
      getClassColor(mod, total){
    if(mod < 0){
      if((mod * -1) > total){
        return 'text-danger'
      } return 'text-success'
    } else if (total < 0){
      return 'text-success'
    }return ''
  },
    setHiddenFields(data){
      var newData = _.cloneDeep(data)
      var mapBanks = newData.map(p => p.banks)
      var arrayBanks = []
      for(var i in mapBanks){
        arrayBanks = arrayBanks.concat(mapBanks[i])
      }
      var arrayIds = [...new Set(arrayBanks.map(p => p.id))]
      this.visibleIds = arrayIds
    },
    updateBankDate(){
      this.$emit('updateBankData', this.payrollBankRecoDate)
    },
    getValueFromThousands(data){
      return data
    },
        getFormatedDateTime(date){
      if(date){
        return moment(date).format('MM/DD/YY')
      } else {
        return 'Unknown'
      }
    },
    modsAdjustedForBanks(mods){
      var codes = _.cloneDeep(this.payrollCodesData)
      var returnArray = []
          for(var i in mods){
              var bank = codes.find(p => p.id == mods[i].payrollModCode)
              if(bank){
              var adj = bank.payrollCodeBankMod
              for(var b in adj){
                var obj = _.cloneDeep(mods[i])
                obj['mod'] = adj[b].mod
                obj['bankid'] = adj[b].id
                returnArray.push(obj)
              }
              }
          }   

          return returnArray
        },
    updateMods(data, mods){
      var newMods = this.modsAdjustedForBanks(mods)
     // console.log(data)
    //  console.log(newMods)
      var newData = data.map(p => {
          var item = p
          item.banks = p.banks.map(b => {
              var userMods = newMods.filter(m => m.payrollModTargetUserId == p.payrollUserId)
              var bank = this.updateAccuralTimes(b, userMods, userMods.map(p => p.bankid))
              return bank
          })
          return item

      })
    //  console.log(newData)
      return newData
    },
    parseNumber(value) {
    //  console.log(value)
      var example = Intl.NumberFormat('en-US', {minimumFractionDigits: 2, useGrouping: false}).format(value);
      var returnExp = parseFloat(example)
      if(isNaN(example) || isNaN(returnExp)){
          const cleaned = value.replace(/,(?=\d{3})/g, '');
          return parseFloat(cleaned)
      } return returnExp
    },
    updateAccuralTimes(bank, mods, modIds){
      var updateBank = bank
      var holdBank = updateBank
      var newBank = updateBank
      holdBank.prev = holdBank.newTotal
      holdBank.accural = "N/A"
      if(mods.length > 0){
        if(modIds.indexOf(bank.id) != -1){
          var modsbank = mods.filter(p => p.bankid == bank.id)
          modsbank = _.orderBy(modsbank, ['payrollModDatetime'], ['asc'])
          if(modsbank.length > 0){
            for(var m in modsbank){
              if(modsbank[m].mod == 'addTo'){
                holdBank.mods = parseFloat(holdBank.mods) + parseFloat(modsbank[m].payrollModHours)
                holdBank.modsAttached.push(modsbank[m])
                holdBank.newTotal = parseFloat(this.parseNumber(newBank.newTotal)) + parseFloat(modsbank[m].payrollModHours)
                holdBank.newTotal = holdBank.newTotal.toFixed(2)
              //  this.payrollRecoObjectIds.push(modsbank[m].id)
              } else if(modsbank[m].mod == 'subFrom'){
                holdBank.mods = parseFloat(holdBank.mods) - parseFloat(modsbank[m].payrollModHours)
                holdBank.modsAttached.push(modsbank[m])
                holdBank.newTotal = parseFloat(this.parseNumber(newBank.newTotal)) - parseFloat(modsbank[m].payrollModHours)
                holdBank.newTotal = holdBank.newTotal.toFixed(2)
              //  this.payrollRecoObjectIds.push(modsbank[m].id)
              }
            }

          }
        }

        return holdBank
      } else {
      return holdBank
      }
    },
    getPayrollModifiersInRange(){
          var start = this.previousDate
          var end = moment(this.modReportDate, "YYYY-MM-DD").toISOString()
          axios({
            method: 'get',
            url: '/api/v1/staffing/payrollmod/daily/' + start + '/' + end,
            headers: {
                'Authorization': this.getSessionData,
                'Content-Type' : 'application/json',
              }
          }).then( response => {
            this.modsForReport = response.data.filter(p => p.payrollModApproved)
          })
    },



  
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
