<template>
  <div id="shiftList">
    <h2 v-if="datePanel" class="shiftListHeader">{{ currentSelectedDate }}</h2>
    <h2 v-if="typePanel">Searching Previous:</h2>
      <div v-if="datePanel" :class="shiftDateCalcClass">
        <b-container id="shiftDateSelectedDiv">
          <b-row>
            <b-col align="right">
            <span v-if="datePanel" id="shift-date-selected">{{ shiftDateCalc }} </span>
          </b-col>
          <b-col align="left">
            <div  @click.stop="showModalSwitch"  >
                <img class="calendarIcon" :src="'../images/siteicons/calendar-plus-icon.png'" title="Calendar">
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="typePanel" class="logTypePanel">

  </div>



    <div id="dateListPanel" v-if="datePanel">
         
      <stationlogbooks v-if="calendarAppType == 'station'" :filterDate="filterDate" :vehicleChecks="vehicleChecks" :showPost="true"
              :stationVarId="stationVarId"
              :stationRepair="stationRepair" :stationLogbooks="stationLogbooks" :journal="true" :keepJournalOpen="keepJournalOpen" />
        <events v-if="calendarEvents" :filterDate="filterDate" :calendarEvents='calendarEvents' ></events>
      <div v-if="userData.role != 'shopops'">
      <apparatus v-if="calendarAppType == 'apparatus'" :searchShift="searchShift"
          :filterAuthor="filterAuthor" :searchText="searchText"
          :filterApparatus="filterApparatus" :shiftDateCalc="shiftDateCalc" :filterDate="filterDate"></apparatus>
          </div>
      <vehiclechecks :filterDate="filterDate" :vehicleChecks="vehicleChecks" :stationCalInventorySub="stationCalInventorySub"></vehiclechecks>
      <stationrepair :shiftDateCalc="shiftDateCalc" :stationRepair="stationRepair" :openRepairView="true" :filterDate="filterDate"></stationrepair>

      <calendarpostmodal
      v-if="showModal"
        :showModal="showModal"
        :filterDate='filterDate'
        :stationTag='stationVarId'
      ></calendarpostmodal>

  </div>
    <div id="listPanel" v-if="typePanel">
    <vehiclechecks
        v-if="sortVehicle"
        :userData="userData"
        :vehicleChecks="vehicleChecks"
        :stationCalInventorySub="stationCalInventorySub"
        :searchText="searchText"
        :searchShift="searchShift"
        :filterAuthor="filterAuthor"
        :filterStation="filterStation"
        :filterApparatus="filterApparatus"
    ></vehiclechecks>
    <stationlogbooks
        v-show="sortLogbook" v-if="calendarAppType == 'station'"
        :stationLogbooks="stationLogbooks"
        :vehicleChecks="vehicleChecks"
        :stationRepair="stationRepair"
        :searchText="searchText"
        :searchShift="searchShift"
        :filterAuthor="filterAuthor"
        :filterStation="filterStation"
        ></stationlogbooks>
    <apparatus
    v-show="sortLogbook"
    v-if="calendarAppType == 'apparatus'"
    :searchText="searchText"
    :searchShift="searchShift"
    :filterAuthor="filterAuthor"
    :filterApparatus="filterApparatus"
    ></apparatus>
    <stationrepair
        v-if="sortRepair"
        :stationRepair="stationRepair"
        :searchText="searchText"
        :searchShift="searchShift"
        :filterAuthor="filterAuthor"
        :filterStation="filterStation"
        :filterApparatus="filterApparatus"
        ></stationrepair>
  </div>
  </div>
</template>
<script>
import Events from '../tracking/events/eventsfilter.vue'
import moment from 'moment'
import VehicleChecks from '../tracking/vehiclechecks/vehiclecheckfilter.vue'
import StationLogbooks from '../tracking/stationlogbook/stationlogbookfilter.vue'
import StationRepair from '../tracking/repairs/stationrepairfilter.vue'
import CalendarPostModal from '../addNew/newCalendarPostFull.vue'
import Apparatus from '../tracking/narclog/Apparatus.vue'
import dailyNarctrack from '../tracking/dailynarctrack/Apparatus.vue'
import { bus } from '../../main'
import { mapState } from 'vuex'

export default {
  props:["filterDate", "calendarEvents", "typePanel",
  "datePanel", "sortVehicle", "sortLogbook", "sortRepair", "dailyLogs", "dailyEvents", "keepJournalOpen",
  "searchShift", "searchText", "currentLogType", "filterAuthor", "filterStation", "filterApparatus", "calendarAppType", "stationVarId"],
components:{
      'events': Events,
      'vehiclechecks' : VehicleChecks,
      'stationlogbooks' : StationLogbooks,
      'stationrepair' : StationRepair,
      'calendarpostmodal' : CalendarPostModal,
      'apparatus' : Apparatus,
      'dailyNarctrack': dailyNarctrack
      
},
  data () {
    return {

        showModal: false,
        showMedModal: false,


    }
  },
  created(){
    bus.$on("showModalSwitchFullCalendar", this.showModalSwitch);
    bus.$on("showModalSwitchMed", this.toggleMontlyMedModal);
  //  console.log(this.stationLogbooks)
  /*  bus.$on('showModalSwitchCalendarwithFalse', this.showModalSetFalse) */
  },
  methods:{
      showModalSwitch (event) {
      this.showModal = !this.showModal
      },
      toggleMontlyMedModal(event){
        this.showMedModal = !this.showMedModal
      },
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'eventTags',
          'apparatusData',
          'userData',
      ]),
    ...mapState({
      vehicleChecks:'stationCalVehicleChecks',
      stationLogbooks:'stationCalLogbooks',
      stationRepair:'stationCalRepair',
      stationImages:'stationCalImages',
      stationStatistics:'stationCalStatistics',
      apparatusAppNarcs: 'apparatusAppNarcs',
      stationCalInventorySub: 'stationCalInventorySub',
      apparatusDailyAppNarcs: 'apparatusDailyAppNarcs',
      siteconfig: 'siteconfig'
    }),
    checkDateForFirst(){
      var date = moment(this.filterDate, "MM/DD/YYYY").format("DD")
      if(date == "01"){
        return true
      } else {
        return false
      }
    },
    dateDifference(){
      var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
      var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
      var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
      var dateDiffCalNumber = 100*(dateDiffCal/3);
      var dateTenDigit = dateDiffCalNumber % 10;
      return Math.round(dateTenDigit);
    },
    shiftDateCalcBack(){
      if (this.dateDifference == 0)
      return "B-shift";
      if (this.dateDifference == 7)
      return "A-shift";
      if (this.dateDifference == 3)
      return "C-shift";
    },
    shiftDateCalc(){
               var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
    },
    shiftDateCalcClass(){
      var shift = this.shiftDateCalc
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shift)
      if(obj){
        return obj.color
      } return shift
    },
    currentSelectedDate(){
      var date = moment(this.filterDate, "MM/DD/YYYY")
      //console.log(date)
      return moment(date).format('ddd: MMM Do YYYY')
    }
  }
}
</script>

<style scoped>
img {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.4) );
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.4));
}

.calendarIcon {
  cursor: pointer;
  max-height: 40px;
  width: auto;
}
.addNewEvent:hover {
  background: grey;
}
#clear {clear:both;}
#shiftList h2 { border: 1px solid #ccc; padding: 6px 0px 5px; border-bottom: 0; margin: 0px; border-radius: 5px 5px 0 0;}
#shiftList h2 button {font-size: 16px; border: 1px solid; border-radius: 8px; cursor: pointer; padding:0px; margin:0px;}
#shift-date-selected {
  font-weight: bold;
  font-size: 26px;
  margin-left: 10px;
  color: #FFF;
  text-shadow: 1px 2px #000;
}
#labelDate {
  color: #fff;
}
#labelAll {
  color: #fff;
}
#filterByDiv {
  display: inline-table ;
  margin-left: 10px;
}

#listPanel {
  text-align: center;
  width: 100%;
  margin-top: 0px;
  color: #000;
  height: auto;
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid;
  padding-left: 12px;
}
#dateListPanel {
  text-align: center;
  width: 100%;
  margin-top: 0px;
  color: #000;
  height: auto;
  max-height: 800px;
  overflow-y: scroll;
  padding-left: 12px;
}
button{padding:8px 10px; border:1px solid; border-radius:8px;cursor: pointer;}
button:hover {background:#999;}
.logTypePanel {
  background: grey;
  height: 1px;
}
.A-shift{
  background-color: #e2070d;
  min-height: 30px;
  height: auto;
  padding: 10px 0px;

}
.B-shift{
  background-color: #007bff;
  min-height: 30px;
  padding: 10px 0px;
}
.C-shift{
  background-color: #0FB906;
  min-height: 30px;
  padding: 10px 0px;
}
.Red-shift{
  background-color: #e2070d;
  min-height: 30px;
  height: auto;
  padding: 10px 0px;

}
.Yellow-shift{
  min-height: 30px;
  padding: 10px 0px;
  background-color: #ffc107;
}
.Blue-shift{
  background-color: #007bff;
  min-height: 30px;
  padding: 10px 0px;
}
.Green-shift{
  background-color: #0FB906;
  min-height: 30px;
  padding: 10px 0px;
}
@media screen and (min-width: 769px){
  h2.shiftListHeader {
    display: block !important;
    padding: 0.75rem 1.25rem;
margin-bottom: 0;
background-color: rgba(0, 0, 0, 0.03);
border-bottom: 1px solid rgba(0, 0, 0, 0.125);
border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
}
@media screen and (max-width: 768px) {
  .Red-shift{
    border-radius: 5px 5px 0 0;
  }
  .Yellow-shift{
    border-radius: 5px 5px 0 0;
  }
  .Blue-shift{
    border-radius: 5px 5px 0 0;
  }
  .Green-shift{
    border-radius: 5px 5px 0 0;
  }
  .A-shift{
    border-radius: 5px 5px 0 0;
  }
  .B-shift{
    border-radius: 5px 5px 0 0;
  }
  .C-shift{
    border-radius: 5px 5px 0 0;
  }
#shiftListDiv {
  display: block;
  width: 100%; }
}
h2.shiftListHeader {
  display: none;
}
@media only screen and (max-width: 576px) {
  #listPanel {
    text-align: center;
    width: 100%;
    margin-top: 0px;
    color: #000;
    height: auto;
    overflow-y: none;
    overflow-x: none;
    border-bottom: 1px solid;
    padding-left: 12px;
  }
  #dateListPanel {
    text-align: center;
    width: 100%;
    margin-top: 0px;
    color: #000;
    height: auto;
    overflow-y: none;
    padding-left: 12px;
  }
}

</style>
