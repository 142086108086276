<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>

      <div  v-if="userData.role == 'adminchief'
      || userData.role == 'admin' || userData.role == 'emsops' || userData.role == 'fireops'">
<b-container>
  <b-btn v-if="userData.role == 'admin'" block variant="primary" @click="FileProcess">Download JSON</b-btn>
       <input  v-if="userData.role == 'admin'" type="file" @change="jsonFileUpload($event)" />
       <b-btn v-if="dataForBulkUpload && dataForBulkUpload.length > 0 && userData.role == 'admin'" @click="submitBulkUpload">Bulk Upload</b-btn>
<b-table hover striped  :fields="fields" :items="departmentBlogs">
  <template slot="updatedAt" slot-scope="data">
    {{ blogTimeAdjust(data.item.updatedAt) }}
  </template>
  <template slot="View" slot-scope="data">
    <b-btn variant="link" @click="showBlogData(data.item)">View</b-btn>
  </template>
    <template slot="Remove" slot-scope="data">
    <b-btn variant="danger" size="sm" @click="deleteBlog(data.item.id)">Delete</b-btn>
  </template>
</b-table>
</b-container>


  </div>
<b-modal v-if="showModal" ok-only v-model="showModal" size="lg" :title="blog.title">
  <b-card >
  <div><img v-if="blog.blogAuthorId" class="blog-img" :src="imageSrcUrl(blog.blogAuthorId)" /></div>
  <div class="pictureHouse" v-if="blog.title">
  <span class="blogSubData"> {{ blog.blogAuthor }} | {{ blog.blogType }}</span>
  <h6 class="blogSubData">{{ blogTimeAdjust(blog.created) }}</h6>
  </div>
   <span class="rightAlign" v-if="blog.blogAuthorId == userData.id || userData.role == 'admin' || userData.role == 'admin chief'"><b-btn variant="danger" @click="deleteBlog(blog.id)" size="sm">Delete</b-btn></span>
  </b-card>
  <b-card bg-variant="light">
  <div class="blogBody" v-if="blog.blogBody">
  <div v-html="blog.blogBody"></div></div>
  <div v-if="blog.blogPicture.length > 0">
    <b-carousel id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                  controls
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template v-for="(pic, index) in blog.blogPicture">
                        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" >
                            
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
  </div>
  <div v-if="false"><div v-for="img in blogFile" :key="img.index"><a target="_blank" :href="imageSrcUrl(img.url)">{{ img.name }}</a></div></div>
  <div v-if="showModal">
  <div v-if="blog.blogVideo.length > 0">
        <b-carousel id="carousel2"
                                 style="text-shadow: 1px 1px 2px #333;"
                                
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide2"
                               >
                        <template  v-for="(video, index) in blog.blogVideo">
                        <b-carousel-slide :key="index">
                            <div slot="img" class="videoWrapper">
                              <iframe  v-if="video" class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
 </div>
       <b-card v-if="showFile">
      <b-btn variant="primary" @click="showFile = false, iframePdf = ''">Hide Form</b-btn>
      <b-btn variant="info" :href="iframePdf" target="_blank">Open in New Tab (mobile)</b-btn>
      <b-embed v-show="true" :src="iframePdf" />
      </b-card>
       <b-table v-if="blog.blogFile.length > 0" stacked="md"  hover striped :items="blog.blogFile">
                 <template slot="url" slot-scope="data">
          <b-btn class="m-0" size="sm" variant="link" @click="viewFileIframe(data.item.url)">view</b-btn>
        </template>
       </b-table>
  </div>
  </b-card>
</b-modal>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import axios from 'axios'
import UserTable from './usertable/userTable.vue'
import JsonExcel from 'vue-json-excel';
import PapaParse from 'papaparse';
export default {
  name: 'hello',
  components: {
    'usertable': UserTable,
  'download-excel' : JsonExcel,
  },
  data () {
    return {
      uploadedJson: [

      ],
      fields: [
      { key: 'updatedAt', label: 'Date', sortable: true },
      'title',
      { key: 'blogType', label: 'Category'},
      { key: 'blogTypeParent', label: 'Parent'},
      { key: 'blogAuthor', label: 'Author'},
      'View'
    ],
      dataReset: true,
      dataForBulkUpload: [],
      addNewDocument: false,
      postNid: '',
      blog: '',
      showModal: false,
      searchData: '',
      updateFile: '',
      vehicleTypes: [],
      parentGroupings: [],
      stationsData: [],
      departmentRanks: [],
      departmentSpecialties: [],
      fileDocumentId: '',
      shifts: [],
      fileUpload: '',
      blogs: [],
      iframePdf: '',
      showFile: '',
      uploadedJsonReport: '',
    }
  },
  created(){
     if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
       this.fields.push('Remove')
     }
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'getSessionData',
        'deptContactData',
        'departmentBlogs',
        'siteconfig'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin' || this.userData.role == 'emsops' || this.userData.role == 'fireops'){
            return false
          } else {
            return true
          }
      },
      departmentBlogsReturned(){
          var array = _.cloneDeep(this.departmentBlogs)
          var holdArray = []
          for(var a in array){
            var insert = array[a]
            var newObj = {}
          for(var i in insert){
                var key = i.replace(/([A-Z])/g, function($1){return "_"+$1.toLowerCase();})
                newObj[key] = insert[i]
          }
          holdArray.push(newObj)
          }
          return _.orderBy(holdArray)
        }
  },
  watch: {
 
},
  methods: {
    FileProcess(){
        const json_data = this.departmentBlogsReturned
        const fileName = "jsonDataBlogs.json";
        const data = new Blob([JSON.stringify(json_data)], { type: "text/json" });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      },
      jsonFileUpload(e){
        var vm = this
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = (e) => {
          console.log("e.target.result", e.target.result);
          const data = JSON.parse(e.target.result);
          vm.fixDataApply(data)
        };
    },
    fixDataApply(data){
      console.log(data)
      this.dataForBulkUpload = data
    },
    getBlogs(){
        axios({
              method: 'get',
              url: '/api/v1/content/blog', 
              headers: {
                'content-type': 'application/json',
                'authorization': this.getSessionData
              }}
              ).
          then((response) => { this.getBlogsData(response.data) })
    },
    getBlogsData(data){
      var dataArray = data

      this.blogs = dataArray

    },
    showBlogData(data){
            console.log(data)
     this.blog = data
           this.showModal = true
    },    
    imageSrcUrl(data){
      var user = this.deptContactData.filter( p => 
      { return p.id == data})
      var picture = this.userPhotoLink(user[0].picture)
      return picture
    },
    blogTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY - HH:mm')
    },
      userPhotoLink(fileName){
    if(fileName.charAt(0) == '/'){
  return this.siteconfig.deptStore + fileName.replace('/', '')
    } else {
      return  this.siteconfig.deptStore + fileName
    }
  },
    viewFileIframe(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              this.iframePdf = response.data.signedRequest
              this.showFile = true

       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
       getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
      },
    submitBulkUpload(){
     axios({
      method: 'post',
      url: '/api/v1/content/blog/bulk',
      data: this.dataForBulkUpload,
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
     // this.blog = response.data
      this.getBlogs()
      
      })
    },
    submitAsRead(data){
     axios({
      method: 'patch',
      url: '/api/v1/content/blog/adminread/' + this.blog.id + '/' + data,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      this.blog = response.data
      this.getBlogs()
      
      })
    },
    deleteBlog(data){
    var result = confirm('Are You Sure You Want to Delete this blog? It will be removed for everyone!!!')
    if(result){
     axios({
      method: 'Delete',
      url: '/api/v1/content/blog/' + data,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      var token = this.getSessionData
      var headers = { headers: { 
          'content-type': 'application/json', 
          'Authorization': token,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
       var payload = { id: this.userData.id, headers: headers }
      this.$store.dispatch('LOAD_DEPT_BLOG_EXP', payload)
      
      })
    }
    },

 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}

.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}
</style>
