// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueCookie from 'vue-cookies'
import 'babel-polyfill'
import BootstrapVue from 'bootstrap-vue'
import App from './app.vue'
import router from './router'
import axios from 'axios'
import store from './store/index.js'
import PapaParse from 'papaparse'
import Vue2TouchEvents from 'vue2-touch-events'
import Notifications from 'vue-notification'

/** import configJson from '../config.json'
import moment from 'moment-timezone'
if(configJson && configJson.timeZoneInformation){
  var timeZoneHold = configJson.timeZoneInformation
} else {
  var timeZoneHold = 'America/New_York'
}
console.log(timeZoneHold)
timeZoneHold.toString()
moment.tz.setDefault(timeZoneHold) */

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Notifications)
Vue.use(BootstrapVue)
Vue.use(PapaParse)
Vue.use(VueCookie)
Vue.use(Vue2TouchEvents)

export const bus = new Vue()
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.papa = PapaParse
Vue.prototype.$cookie = VueCookie

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        if (!event.target.dataset.clickout || event.target.dataset.clickout !== 'stop') {
          vnode.context[binding.expression](event)
        }
      }
    }
    document.body.addEventListener('click', el.event)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.event)
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#page-wrapper',
  store,
  BootstrapVue,
  router,
  template: '<app/>',
  components: { App }
})
