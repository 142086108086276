<template>
<div>
  <appFull v-if="!isTv" />
  <div id="tv-page-wrapper" v-if="isTv">
    <tvApp />
  </div>
</div>
</template>

<script>
import appFull from './appFull.vue'
import tvApp from './components/tv/tv.vue'
export default {
  name: 'app',
  components: { 
    appFull,
    tvApp
  },
  data(){
  return {
    isTv: false,

  }
  },
  created(){
    this.isTv = this.$route.name == 'Tv' ? true : false

  },
  mounted(){

  },
  computed: {

  },
  watch: {


  },
  methods: {

  }
}
</script>
<style scoped>


</style>
