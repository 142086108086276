<template>
<div>
<div>
  <h4 id="filterHeading">Repair Reports</h4>
  <div class="bodyData" >
      <h4 class="errorText" v-if="filteredRepair.length == 0">No New Repairs Reported</h4>
    <div  v-if="stationRepair.length > 0">
<div  v-for="repair in filteredRepair" :key="repair.id">
  <repairFilter :repair="repair" />
</div>
<h4  v-if="openRepairView" class="errorText borderTop" @click="showUnresolvedRepairs = !showUnresolvedRepairs">Unresolved Repairs ({{ filteredRepairOpen.length }})</h4>
<div v-if="openRepairView" v-for="repair in filteredRepairOpen" :key="repair.id">
  <div v-show="showUnresolvedRepairs">
  <repairFilter :repair="repair" />
  </div>
  </div>
</div>
</div>
</div>
</div>
</template>
<script>

import moment from 'moment'
import RepairFilterData from './stationrepairfilterdata.vue'
export default {
  props:{
        filterDate: {
            type: String,
            default: ''
        },
        stationRepair: {
            type: Array,
            default: () => [],
        },
        searchShift: {
            type: String,
            default: '',
        },
        searchText: {
            type: String,
            default: '',
        },
        filterAuthor: {
            type: String,
            default: '',
        },
        filterApparatus: {
          type: String,
          default: '',
        },
        openRepairView: {
          type: Boolean,
          default: false,
        },
      },
components:{
  'repairFilter': RepairFilterData,

},
  data () {
    return {
      showUnresolvedRepairs: false,
    }
  },
  computed:{
    filteredRepair(){
      var isTrue = true
      var array = this.stationRepair.filter((repair) => {
        return repair.title.toLowerCase().includes(this.searchShift != undefined ? this.searchText.toLowerCase() : '')
        && repair.repairformShift.match(this.searchShift != undefined ? this.searchShift : '')
        && repair.repairformAuthor.match(this.filterAuthor != undefined ? this.filterAuthor : '')
        && moment(repair.repairformDate).format('MM/DD/YYYY').match(this.filterDate)
        if(repair.repairformApparatusId != undefined){
          repair.repairformApparatusId.match(this.filterApparatus != undefined ? this.filterApparatus : '')
        }
      })
      return _.orderBy(array, ['repairformDate'], ['desc'])
    },
    filteredRepairOpen(){
      var isTrue = true
      var repairs = this.stationRepair.filter((repair) => {
        return repair.repairformStatus != 'Resolved' && repair.repairformStatus != 'Replaced'
      })
      repairs = _.orderBy(repairs, ['repairformDate'], ['asc'])
      return _.cloneDeep(repairs)
    }
  }
}
</script>

<style scoped>
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.borderTop {
  border-top: 1px solid #777;
  width: 80%;
  margin: 0 auto 0;
  padding-top: 10px;
  cursor: pointer;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
  padding-bottom: 10px;
}
h4.errorText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #e2070d;
  font-style: italic;
}
a {
  text-decoration: none;
}
.A-shift{
  color: #e2070d;
}
.B-shift{
  color: blue;
}
.C-shift{
  color: green;
}
</style>
