<template>
<div>
  <b-modal
        size="lg"
       v-model="showModal"
       :no-close-on-backdrop='true'
       :no-close-on-esc='true'
       :ok-only="true"
       :hide-header="true"
       :hide-footer="true"
       title="Print Report"
         >
         <div class="modal-body" id="pringJsForm">
             <b-btn variant="primary" @click="executePrintJS">Print</b-btn>
             <b-btn variant="danger" @click="closeModelEmit">Close</b-btn>
             <h4 class="headerClass"><center>{{ repairPrintType }}</center></h4>
             <center>
             <b-container v-if="printType == 'station'">
              <b-row>
                     <b-col><div><strong>STA: </strong>{{ stationPull.stationName }}</div></b-col>
                 </b-row>
                 <b-row class="my-1">
                     <b-col><strong>PARENT: </strong>{{ stationPull.stationParent }}</b-col>
                 </b-row> 
                 </b-container>
             <b-container v-if="printType == 'apparatus'">
                 <b-row>
                     <b-col><div><strong>Unit: </strong>{{ app.apparatusName }}</div></b-col>
                     <b-col><div><strong>STA: </strong>{{ app.apparatusLocation }}|{{ app.apparatusParent }}</div></b-col>
                 </b-row>
                 <b-row class="my-1">
                     <b-col><strong>Asset#: </strong>{{ app.apparatusAssetNumber }}</b-col>
                     <b-col><strong>Tag: </strong>{{ app.apparatusTag }}</b-col>
                 </b-row>                 
                 <b-row>
                     
                     <b-col><strong>PM Due: </strong>{{ app.apparatusNextPm }} {{ app.apparatusPMCheckType }}</b-col>
                      <b-col><strong>Vin: </strong>{{ app.apparatusVin }}</b-col>
                 </b-row>
             </b-container>
             </center>
            <h4 class="headerClass"><center>***Outstanding Repairs***</center></h4>
            <div v-for="repair in repairDataSet" :key="repair.id">
                <center>{{ repair.title }}</center>
                <div><center><strong>Item: </strong>{{ repair.repairformItem }}  |  
                <strong>Status: </strong> {{repair.repairformStatus}} </center></div>
                <p><center><strong>Description: </strong>{{ repair.repairformDescription }} </center></p>
                <center><div><strong> *Repair Tracking Notes*</strong></div>
                <div v-for="note in repair.repairformNotes" :key="note.index">
                    <p><strong>Note: </strong>{{ note.note }} </p>
                    <em>{{ note.author}} | {{ note.time }}</em>
                </div>
                <div v-for="pic in repair.repairformPicture" :key="pic.index">
                <img width="350px" height="auto"  :src="siteconfig.deptStore + pic" />
                </div>
                    </center>
             </div>
              </div>

    </b-modal>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { bus } from '../../../main'
import axios from 'axios';
export default {
  props:["showModal", "apparatus", "repairData", "station", "printType"],
components:{
},
  data () {
    return {
        repairPrintType: '',


    }
  },
  created(){

      if(this.apparatus != null){
          this.repairPrintType = '***Vehicle Repair Info***'
      } else if (this.station != null){
          this.repairPrintType = '***Station Repair Info***'
      } else {
          this.repairPrintType = 'Unknown Type Repair Info'
      }
  },
  mounted(){


  },
  computed:{
    ...mapState([
          'getSessionData',
          'userData',
          'apparatusData',
          'stationsData',
          'siteconfig'
        ]),
        app(){

            var apparatus = this.apparatusData.filter( p => {
                return p.id == this.apparatus
            })
            return apparatus[0]
        },
        stationPull(){
            var station = this.stationsData.filter(p => {
                return p.id == this.station
            })
    
            return station[0]
        },
        repairDataSet(){
            return this.repairData
        }

    },
    watch: {

      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
      closeModelEmit(){
          this.$emit('closeModal')
          this.$emit('close')
      },
     executePrintJS(){
    var printId = 'pringJsForm'
    var string = 'Report |' + this.repairPrintType + ' | ' + moment().format('MM/DD/YYYY - HH:mm')
    return printJS({ printable: printId, type: 'html', header: string})
  },

  }
}
</script>

<style scoped>

.headerClass {
    background: #ddd;
    padding: 3px;
    margin: 15px 0;
    border-radius: 5px;
}


</style>
