<template>

          <div >
            <div v-if="editOption ? !editOption : true">
             <h5 @click="changeDate = !changeDate" v-show="!changeDate" id="todaysDateDisplay">Event Date: {{ selectedDate }} (click to edit)</h5>
             <datepicker v-if="changeDate" v-model="selectedDate"></datepicker>
             <h5>Event Time: <timepicker v-model="timeValueSet" /></h5></div>
          <b-card no-body>
            <b-tabs card>
              <b-tab title="Event Details">
          <b-form-input :class="{errorClass: titleDescription == ''}" type='text' v-model="titleDescription" placeholder="Event Title"/>



                <b-form-select class="mt-3" :class="{errorClass: eventCatSelect == null}"  text-field="eventtypeName" value-field="eventtypeName" v-model="eventCatSelect" :options="eventCat">
                  <option :value="null" disabled> Please select Event Category</option>
                </b-form-select>
                <b-form-select class="mt-3" :disabled="eventCatSelect == null" :class="{errorClass: eventTagSubSelect == null}" text-field="eventtypeName" value-field="eventtypeName" v-model="eventTagSubSelect" :options="eventTagSub(eventCatSelect)">
                  <option :value="null" disabled> Please select Type or Location</option>
                </b-form-select>
              <b-form-textarea class="mt-3" :rows='4' v-model="detailedTextArea" placeholder="Detailed Description of the Event"/>
              </b-tab>
              <b-tab title="Event Tags" :active="trainingPage ? true : false">
          <b-container fluid class="p-0 m-0" >
            <b-btn v-for="(select, index) in multiSelectArray" :disabled="viewAll" :key="index" :variant="viewGroup == select.btn ? select.variant : 'outline-' + select.variant" @click="viewGroup = select.btn">{{ select.btn }}</b-btn>
              <b-form-checkbox v-model="viewAll" :value="true" :unchecked-value="false">View All</b-form-checkbox>
          <b-container v-if="dataReset" fluid class="p-0 m-0" v-for="(select, index2) in multiSelectArray" :key="'test' + index2" v-show="viewAll || viewGroup == select.btn">
              <b-row class="mt-3 mb-1" ><b-col  class="pr-1">
                <multiselect :class="'bordered border-' + select.variant"  v-model="holdValues[select.vmodel]" :options="getOptions(select.options)" @select="checkforStationAdd(select.vmodel, $event)" :limit="select.viewLimit" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" :placeholder="select.placeholder" :label="select.label" :track-by="select.track">
                <template slot="tag" slot-scope="props"><span :class="'custom__tag ' + 'bg-' + select.variant">
                  <span>
                    <template v-for="propssets in select.propsset">{{ props.option[propssets] }} </template></span>
                  <span class="custom__remove" @click="props.remove(props.option)">X</span></span>
                  </template>
                <template v-if="select.selectOptions" slot="option" slot-scope="props">
                      <span class="option__title">
                        <template v-for="optsets in select.selectOptions">{{ props.option[optsets] }} </template></span>
                    </template>
                
                </multiselect>
                </b-col>
                <b-col  class="pl-0" cols="2">
                  <b-btn :disabled="!viewAll" :variant="select.filterView == false ? 'outline-' + select.variant : select.variant" @click="select.filterView = !select.filterView">Multi</b-btn>
                  <b-btn v-if="holdValues[select.vmodel] && holdValues[select.vmodel].length > 6"
                        variant="outline-info" 
                         @click="select.viewLimit == 6 ? select.viewLimit = 999 : select.viewLimit = 6">{{ select.viewLimit == 6 ? 'View' : 'Hide'}}</b-btn>
                  <b-btn v-if="holdValues[select.vmodel] && holdValues[select.vmodel].length > 6" variant="outline-danger" @click="updateValues(select.vmodel, 'clear')">Clear</b-btn>
                </b-col>
                
                </b-row>
                <b-row v-if="select.filterView || !viewAll">
                  <inputFilter v-if="select.filterType == 'inputFilter'" :variant="select.variant" :dataArray="select.options" :filters="select.filterItems" @AddValues="updateValues(select.vmodel, $event)" />
                  </b-row>            
          </b-container>
          </b-container>


              </b-tab>
              <b-tab title="Repeat" v-if="!defaultData && userData.role != 'user'">
               <b-form-radio-group v-model="selected"
                          :options="options"
                          name="radioInlineForRepeat">
              </b-form-radio-group>
              <repeatDate v-if="selected == 'repeat'" :selectedDate="formatedDateExport" @repeatDateArray="setRepeatedDatesArray" />
              <div v-if="selected == 'repeatdate'">
                    <b-row class="pl-5">
                    <datepicker id="datepickerAddDay" :inline='true' @selected="setRepeatedDatesArrayAdd"></datepicker>
                    </b-row>
                    <b-container v-show="true"><b-row class="my-2" v-for="(data, index) in repeatedDatesArray" :key="index">
                      <b-col align="right">{{ getFormatedDate(data) }}</b-col>
                      <b-col align="left"><b-btn v-if="data != formatedDateExport" variant="danger" size="sm" @click="removedFromDatesArray(index)">Remove</b-btn></b-col>
                      </b-row></b-container>
                </div>
                </b-tab>
            </b-tabs>
          </b-card>
          <div v-show="false">{{ updatedData }}</div>
         </div>

</template>

<script>

import { bus } from '../../../main'
import { mapState } from 'vuex'
import DatePicker from '../../inputfields/Datepickermod.vue'
import axios from 'axios';
import TimePicker from '../../inputfields/timepicker.vue'
import inputFilter from '../../inputfields/inputFilter.vue'
import repeatDate from '../../inputfields/repeatDate.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
export default {
props:["filterDate", "defaultEvent", "defaultSubEvent", "stationTag", "defaultData", "editOption", "userHoldArray", "trainingPage"],
components:{
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker': DatePicker,
      inputFilter,
      repeatDate
},
  data () {
    return {
      trueBoo: true,
        titleDescription: '',
        dataReset: true,
        title:'',
        viewAll: true,
        selected: 'norepeat',
        options: [
                    { text: 'Single', value: 'norepeat' },
                    { text: 'Re-occuring', value: 'repeat' },
                      { text: 'Add Dates', value: 'repeatdate' }
        ],
        viewGroup: 'Apparatus',
        showModalConfirmation: false,
        timeValueSet: {
          HH: "12",
          mm: "00",
        },
        multiSelectArray: [
                  {vmodel: 'apparatusSelectValue', btn: 'Apparatus', variant: 'info', options: 'apparatusData', propsset: ['apparatusName'], placeholder: 'Apparatus Select/Tag', viewLimit: 6, label: 'apparatusName', track: 'apparatusName', filterView: false, filterType: 'inputFilter', filterItems: [{name: 'apparatusMedicalType', type: 'single'}, {name: 'apparatusVehicleType', type: 'single'}, {name: 'apparatusParent', type: 'single'}]},
                  {vmodel: 'userSelectValue', btn: 'Users', variant: 'success', options: 'deptContactData', propsset: ['lastName'], selectOptions: ['firstName', 'lastName', 'employeeId'], placeholder: 'User Select/Tag', viewLimit: 6, label: 'lastName', track: 'id', filterView: false, filterType: 'inputFilter', filterItems: [{name: 'deptRank', type: 'single'}, {name: 'deptSpecialties', type: 'multi'}, {name: 'stationAssignment', type: 'single'}, {name: 'shiftAssignment', type: 'single'}]},
                  {vmodel: 'stationSelectValue', btn: 'Stations', variant: 'primary', options: 'stationsData', propsset: ['stationName'], placeholder: 'Station Select/Tag', viewLimit: 6, label: 'stationName', track: 'stationName', filterView: false, filterType: 'inputFilter', filterItems: [{name: 'stationParent', type: 'single'}]}
        ],
        holdValues: {appartusSelectValue: [], userSelectValue: [], stationSelectValue: []},
        selectedAppData: [],
        stationFilterview: false,
        testValue: null,
        changeDate: false,
        detailedTextArea: '',
        eventPrivate: false,
        eventTagsSelectValue:[],
        stationSelectValue:[],
        apparatusSelectValue:[],
        userSelectValue: [],
        selectedDate: '',
        selectedDateReOccuring: '',
        eventCatSelect: null,
        eventTagSubSelect: null,
        postData: {},
        repeatedDatesArray: [],
        repeatedDatesArrayDay: [],
    } 
  },
  created(){
    if(this.defaultEvent && this.defaultEvent == ''){
       this.setDefaultEvent(this.defaultEvent)
      }
    if(this.stationTag && this.stationTag != ''){
      this.setStationDefault(this.stationTag)
    }
    if(this.defaultData){
      this.setUpdateData(this.defaultData)
    }
  },
  mounted(){
    if(!(this.defaultData)){
    this.selectedDate = this.filterDate
    }
  },
  watch: {
    userHoldArray(newData){
      this.checkForUserAdd(newData)
    },
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'eventTags',
          'apparatusData',
          'deptContactData',
          'userData',
          'workgroupData'
      ]),
      formatedDateExportback() {
      var dateString = moment(this.selectedDate, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" +  this.timeValueSet.HH + ':' + this.timeValueSet.mm + ':' + '00';
      var offSet = moment().utcOffset();
      var hourOffSet = offSet * -1 / 60;
      var timeStringForm = moment(timeString).add(hourOffSet, "hours");
      var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
      return timeStringAdd + "Z";
      },
      formatedDateExport() {
      var dateString = moment(this.selectedDate, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" +  this.timeValueSet.HH + ':' + this.timeValueSet.mm + ':' + '00';
      return moment(timeString).toISOString();
      },
      updatedData(){
        var obj = {
        titleDescription: this.titleDescription,
        holdValues: this.holdValues,
        detailedTextArea: this.detailedTextArea,
        eventTagsSelectValue:this.eventTagsSelectValue,
        selectedDate: this.selectedDate,
        selected: this.selected,
        eventCatSelect: this.eventCatSelect,
        eventTagSubSelect: this.eventTagSubSelect,
        repeatedDatesArray: this.repeatedDatesArray,
        timeValueSet: this.timeValueSet
        } 
        this.$emit('updateData', obj)
        return obj
      },
      eventCat(){
        if(this.eventTags != undefined){
        var array = [... new Set(this.eventTags.map(p => { if(p.eventtypeParent != '' && p.eventtypeParent != undefined){ 
          return p.eventtypeParent
        }
        }))]
       var index = array.indexOf(undefined)
       if(index >= 0){
         array.splice(index, 1)
       }
       return _.sortBy(array)
        }
      },
    },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
               getFormatedDate(data){
               return moment(data).format('MM/DD/YY HH:mm')
           },
    setUpdateData(data){
      this.titleDescription = data.titleDescription
      this.title = data.title
      this.selectedDate = data.selectedDate
      this.holdValues['stationSelectValue'] = data.stationSelectValue
      this.holdValues['apparatusSelectValue'] = data.apparatusSelectValue
      this.holdValues['userSelectValue'] = data.userSelectValue
      this.detailedTextArea = data.detailedTextArea
      this.eventTagsSelectValue = data.eventTagsSelectValue
      this.timeValueSet = data.timeValueSet
      this.eventCatSelect = data.eventCatSelect
      this.eventTagSubSelect = data.eventTagSubSelect
    },
    setRepeatedDatesArray(data){
      this.repeatedDatesArray = data
    },
    removedFromDatesArray(index){
      this.repeatedDatesArray.splice(index, 1)
    },
    setRepeatedDatesArrayAdd(data){
      var newDate = moment(data).format('YYYY-MM-DD')
      var split = this.formatedDateExport.split('T')
      var newDate = newDate + 'T' + split[1]
      var index = this.repeatedDatesArray.indexOf(this.formatedDateExport)
      if(index == -1){
        this.repeatedDatesArray.push(this.formatedDateExport)
      }
      this.repeatedDatesArray.push(moment(newDate).toISOString())
      this.repeatedDatesArray = [... new Set(this.repeatedDatesArray.map(p => p))]
      this.repeatedDatesArray = _.sortBy(this.repeatedDatesArray)
  /*    var array = _.cloneDeep(this.repeatedDatesArrayDay)
      this.repeatedDatesArrayDay = []
      var index = array.indexOf(this.formatedDateExport)
      if(index == -1){
        array.push(this.formatedDateExport)
      }
      var index2 = array.indexOf(newDate)
      console.log(index2)
      if(index == -1){
        array.push(newDate)
      }
      this.$nextTick(function(){
   this.repeatedDatesArrayDay = array 
      }) */
   
    },
    checkforStationAdd(data, value){
      if(data == 'apparatusSelectValue' && value.apparatusLocation){
        var index = this.holdValues.stationSelectValue.map(p => p.stationName).indexOf(value.apparatusLocation)
        if(index < 0){
          var obj = this.stationsData.find(p => p.stationName == value.apparatusLocation)
          if(obj){
            this.holdValues.stationSelectValue.push(obj)
            
          }
        }
      } 
      if(data == 'userSelectValue' && value.stationAssignment){
        var index = this.holdValues.stationSelectValue.map(p => p.stationName).indexOf(value.stationAssignment)
        if(index < 0){
          var obj = this.stationsData.find(p => p.stationName == value.stationAssignment)
          if(obj){
            this.holdValues.stationSelectValue.push(obj)
            
          }
        }
      }
    },
    checkForUserAdd(newData){
      var array = this.holdValues.userSelectValue
      var filter = newData.filter(p => {
        return array.map(p => p.id).indexOf(p.id) == -1
      })
      for(var f in filter){
        this.holdValues.userSelectValue.push(filter[f])
        this.checkforStationAdd('userSelectValue', filter[f])
      }
      console.log(this.holdValues)
    },
    updateValues(hold, values){
      this.dataReset = false
      if(values == 'clear'){
        this.holdValues[hold] = []
      } else if(this.holdValues[hold] == undefined){
        this.holdValues[hold] = values
        for(var i in values){
           this.checkforStationAdd(hold, values[i])
        }
      } else {
        var index = this.holdValues[hold].map(p => p.id)
        for(var i in values){
         this.checkforStationAdd(hold, values[i])
          if(index.indexOf(values[i].id) < 0){
        
             this.holdValues[hold].push(values[i])
          }
        }
      }
      this.$nextTick(function(){
        this.dataReset = true
      })
    },
    getOptions(data){
      return this[data]
    },
    setStationDefault(data){
       this.dataReset = false
      var station = this.stationsData.filter( p => {
        return p.stationName.toLowerCase() == data.toLowerCase()
      })
      this.holdValues['stationSelectValue'] = station
      this.$nextTick(function(){
      this.dataReset = true
      })
    },
    setDefaultEvent(data){
      var defaultVar = this.eventCat.filter( (p) => {
        return p.name.toLowerCase().match(data.toLowerCase())
      })
      this.eventCatSelect = defaultVar[0].tid
      if(defaultVar[0].name.toLowerCase() == 'maintenance'){
        var defaultSubVar = this.eventTags.filter( (p) => {
          return p.name.toLowerCase().match('apparatus') && p.name_1.toLowerCase().match('maintenance')
        })
        this.eventTagSubSelect = defaultSubVar[0].tid
      }
      
    },
    getStationParents(data){
      var array = []
      for(var i = 0; i < data.length; i++){
        var string = data[i].stationParent
        var index = array.indexOf(string)
          if(string != '' && string != null && string != undefined && index < 0){
          array.push(string)
          }
        }
      return array
    },
    eventTagSub(data){
      var array = [... new Set(this.eventTags.filter(p => p.eventtypeParent.match(data)))]
      if(data == 'Other Dept Activities' && this.workgroupData.length > 0){
        var work = this.workgroupData.filter( p => { return p.workgroupCalendar == true})
        for(var i = 0; i < work.length; i++){
          var string = { eventtypeName: work[i].workgroupName }
          array.push(string)
        }
      } return array
    },



},


}
</script>
<style :src="'./customcss/vue-multiselect.min.css'">

</style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>

<style scoped>
.errorClass {
  border: 1px solid red
}
.bordered {
  border: 1px solid;
  border-radius: 5px;
}
</style>
