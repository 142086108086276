<template>
    <b-container fluid>
  <b-navbar type="dark" variant="dark">
    <b-nav-form v-if="!exportReport.newReport">
    <download-excel  class="ml-2"
    v-if="fixDataExcel.length > 0"
    :name="exportReport.title"
    :data="fixDataExcel">
    <b-btn variant="primary">Full Excel</b-btn>
    </download-excel>
   <download-excel 
    class="ml-2"
    v-if="fixDataExcelCombined.length > 0"
    :name="exportReport.title"
    :data="fixDataExcelCombined">
    <b-btn variant="success">Combined Excel</b-btn>
    </download-excel>
    <download-excel
    class="ml-2"
    v-if="fixDataExcelCustom.length > 0 && false"
    :name="exportReport.title"
    :data="fixDataExcelCustom">
    <b-btn variant="info">Custom Excel</b-btn>
    </download-excel>
    <b-btn variant="primary" class="ml-2" v-if="siteconfig.payroll.customExport && fixDataExcelCustom.length > 0" @click="downloadAsCsv">Custom CSV</b-btn>
      </b-nav-form>
    <b-nav-form v-if="exportReport.newReport">
        <b-navbar-brand class="bootstrapTable">Report Options: </b-navbar-brand>
        <b-form-checkbox class="text-light" v-if="!timecard" v-model="holdReport.requestVerification" @change="checkTurnOff($event)">Request User Verification?</b-form-checkbox>
        <b-form-checkbox class="text-light ml-2"  v-if="!timecard" :disabled="!holdReport.requestVerification" v-model="selectAllUsers" @change="checkSelectAll($event)">Select All Users?</b-form-checkbox>
         <b-form-checkbox class="text-light ml-2" v-model="completeReportBoolean" >Report is Complete?</b-form-checkbox>
         <b-btn variant="success" class="ml-2" :disabled="!completeReportBoolean" @click.once="submitReport">Submit</b-btn>
    </b-nav-form>
    </b-navbar>
    <b-container>
      <b-card no-body class="my-2">
        <b-row class="my-2">
          <b-col><h5>Report Author</h5><h6>{{ holdReport.exportAuthor }}</h6></b-col>
          <b-col><h5>Report Date</h5><h6>{{  getReportDate(holdReport.createdAt) }}</h6></b-col>
          <b-col><h5>User Total</h5><h6>{{ reportData.length }}</h6></b-col>
          <b-col v-if="timecard"><h5>User Approved<h6>{{ getReportApprovals(reportData) }}</h6></h5></b-col>
          <b-col v-if="timecard"><h5>Supervisor Approved</h5><h6>{{ getReportApprovals(reportData, true) }}</h6></b-col>
          <b-col  v-if="!timecard"><h5>Notification Total</h5><h6>{{ nofiticationTotal.length }}</h6></b-col>
        </b-row>
      </b-card>
      <b-table :items="!exportReport.newReport && exportReport.id ? reportDataDetails : reportData" :fields="fields" >
          <template slot="user" slot-scope="data">
            {{ data.item.lastName + ', ' + data.item.firstName + ' (' + data.item.employeeId + ')' }}
            </template>
            <template slot="payroll" slot-scope="row">
              <b-btn v-if="false" size="sm" @click="consoleLogData(row.item.shifts)">Console</b-btn>
              <b-btn :disabled="!row.item.shifts || row.item.shifts.length <= 0" size="sm" variant="info" @click.stop="row.toggleDetails">Items ({{ row.item.shifts ? row.item.shifts.length : '?'}})</b-btn>
            </template>
            <template slot="requestVerification" slot-scope="data">
              <b-form-checkbox v-if="exportReport.newReport" v-model="data.item.verification" :disabled="!holdReport.requestVerification" />
              
            </template>
            <template slot="userVerified" slot-scope="data">
              <span v-if="exportReportReviews && false" v-html="checkIfUserVerified(data.item.id)"></span>
              <span v-if="exportReportReviews" v-html="data.item.userVerified"></span>
              </template>
              <template slot="verify" slot-scope="data">
                <b-btn disabled size="sm" variant="primary" @click="verifyPayrollRecord(data.item)">Verify</b-btn>
              </template>
       <template slot="row-details" slot-scope="row">
        <b-table id="payrollReportTable" :items="row.item.shifts" :fields="['payrollModDatetime', 'payrollModExport', 'payrollModCode', 'payrollModType', 'payrollModHours']">
          <template slot="payrollModDatetime" slot-scope="data">
            {{ getDateTime(data.item.payrollModDatetime) }}
            </template>

          </b-table>
       </template>
        </b-table>
      </b-container>
      </b-container>
</template>

<script>
import { bus } from '../../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import Papa from 'papaparse'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../../tracking/personnel/personnelDataFilter.vue'
import Blob from 'blob'
import customJs from '../../../../../customjs.js'
import JsonExcel from 'vue-json-excel';
export default {
  components: {
    multiselect,
    userCard,
    'download-excel' : JsonExcel
  },
  
  name: 'roster-admin',
  props:  ['payrollAccuralData', 'payrollBanksData', 'rosterProfiles', 'rosterData', 'payrollAccuralAssignedData', 'payrollCodesData', 'exportReport', 'timecard'],
  data () {
    return {
      currentPage: 0,
      searchData: '',
      selectAllUsers: false,
      payrollBankRecoDate: '',
      cycleType: 'Pay Period',
      currentYear: '',
      payrollRecoMods: [],
      viewReports: [],
      holdReport: {},
      fields: ['user', 'payroll', 'requestVerification'],
      modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'}, 
     ],
     exportReportReviews: null,
     reportData: [],
     completeReportBoolean: false,
      createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      ],
    }
  },
  created(){
    this.holdReport = _.cloneDeep(this.exportReport)
    this.setReportData(this.holdReport)
    if(!this.exportReport.newReport && this.exportReport.id){
      this.fields = ['user', 'shiftAssignment', 'stationAssignment', 'payroll', {key: 'userVerified', sortable: true}],
      this.getExportReviews(this.exportReport.id)
      if(this.userData.role == 'admin'){
        this.fields.push('verify')
      }
    }
  },
  mounted(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        fixDataExcel(){
          var array = _.cloneDeep(this.reportData)
          var hold = []
          for(var i in array){
            if(obj){
            var obj = { name: array[i].lastName + ', ' + array[i].firstName, employeeId: array[i].employeeId,  entryDate: '', entryCode: '', entryName: '', entryType: '', entryHours: ''}
            hold.push(obj)
            var shifts = array[i].shifts
            for(var s in shifts){
              var sobj = { name: '', employeeId: array[i].employeeId,  entryDate: shifts[s].payrollModDatetime, entryCode: shifts[s].payrollModExport, entryName: shifts[s].payrollModCode, entryType: shifts[s].payrollModType, entryHours: shifts[s].payrollModHours }
              hold.push(sobj)
            }
            }
          }
          return hold
        },
        fixDataExcelCombined(){
          var array = _.cloneDeep(this.reportData)
          var hold = []
          for(var i in array){
            if(array[i]){
            var codes = [...new Set(array[i].shifts.map(p => p.payrollModExport))]
            var shifts = array[i].shifts
            for(var c in codes){
              var filter = shifts.filter(p => p.payrollModExport == codes[c])
              var totalHours = 0
              for(var f in filter){
                totalHours = totalHours + Number.parseFloat(filter[f].payrollModHours)
              }
              var sobj = { employeeId: array[i].employeeId, 
                           firstName: array[i].firstName, 
                           lastName: array[i].lastName, 
                           range: this.exportReport.title,
                           entryCode: codes[c], 
                           //entryName: shifts[s].payrollModCode, 
                           //entryType: shifts[s].payrollModType, 
                           entryHours: totalHours }
              hold.push(sobj)
            }
            }


          }
          return hold
        },
        nofiticationTotal(){
          var data = _.cloneDeep(this.reportData)
          data = data.filter(p => {
             if(p && p.verification){
               return p
             }
          })
          var hold = data.map(p => p.id)
          return hold
        },
        reportDataReturned(){
          var data = _.cloneDeep(this.reportData)
          data = data.filter(p => p)
          // console.log(data)
          return data.map(item => {
              var obj = {}
          obj['userid'] = item.id
            if(!this.timecard){
              obj['shifts'] = item.shifts
              obj['verification'] = true
            } else {
              obj['shifts'] = item.shifts
              obj['userApproved'] = item.userApproved
              obj['supervisorApproved'] = item.supervisorApproved
              obj['export'] = item.export
            }
              return obj
          })
        },
        reportDataDetails(){
          var data = _.cloneDeep(this.reportData)
          if(this.exportReportReviews){
            data = data.map(item => {
                if(item){
                var obj = item
                obj['userVerified'] = this.checkIfUserVerified(item.id)
                return obj
                }
            })
            return data.filter(p => p)
          } return data
        },
        fixDataExcelCustom(){
          var array = _.cloneDeep(this.reportData)
          array = array.filter(p => p)
          var hold = customJs.custom_payroll(array)
          if(hold){
          return hold
          } else {
            return []
          }
        },
       
  },
  mounted(){

  },
  watch: {
    
  },
  methods: {
    consoleLogData(data){
      console.log(data)
    },
    getReportApprovals(data, boo){
      if(boo){
        return data.filter(p => p.supervisorApproved).length
      }   return data.filter(p => p.userApproved).length
    },
    checkIfUserVerified(id){
      if(id){
        var user = this.exportReportReviews.find(p => p.exportReviewAuthorId == id)
        if(user){
          var comment = user.payrollExportReviewComment ? user.payrollExportReviewComment : "No Comment"
          if(user && user.requestApproved && user.createdAt){
            return '<span class="text-success">Approved ' + this.getDateTime(user.createdAt) + '</span>'
          } else if (user && user.requestApproved == false && user.createdAt){
            return '<span class="text-danger" title="' + comment + '">Denied ' + this.getDateTime(user.createdAt) + '</span>'
          } else {
            return '<span class="font-weight-bold">Unknown ' + this.getDateTime(user.createdAt) + '</span>'
          }
        } return '<span>No Verification</span>'
      } return '<span>No Verification</span>' 
    },
    checkTurnOff(data){
      if(!data){
        this.selectAllUsers = false
        this.checkSelectAll(false)
      }
    },
    getReportDate(date){
      if(date){
        return moment(date).format('MM/DD/YYYY')
      } return moment().format('MM/DD/YYYY')
    },
    checkSelectAll(data){
      var switchData = _.cloneDeep(this.reportData)
      var newMap = switchData.map(item => {
            var obj = item
            obj['verification'] = data
            return obj
      })
      this.reportData = switchData
    },
    setReportData(data){
      var payroll = data.payrollData
      var payMap = payroll.map(item => {
          var user = this.deptContactData.find(p => p.id == item.userid)
          if(user){
            user['shifts'] = item.shifts
            if(!this.timecard){
            user['verification'] = item.verification ? item.verification : false
            user['show_details'] = false
            } else {
            user['userApproved'] = item.userApproved
            user['supervisorApproved'] = item.supervisorApproved
            user['export'] = item.export
            }
            return user
          }
      })
      payMap = payMap.filter(p => p)
      this.reportData = _.cloneDeep(payMap)
    },
      downloadAsCsv(){
       var title = this.exportReport.title
       var data = Papa.unparse(this.fixDataExcelCustom, {header: false})
       const blob = new Blob([data], { type: 'text/csv' })
      // console.log(data)
       const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", title);
        link.click();
      },
      getDateTime(data){
        if(data){
          return moment(data).format('MM/DD/YYYY HH:mm:ss')
        } return 'Null Date'
      },
    getPayrollModifiersInRange(start, end){
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrollmod/daily/' + start + '/' + end,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     this.payrollRecoMods = response.data.filter(p => p.payrollModType != 'roster mod' && moment(p.payrollModDatetime).isBefore(end))

        // this.rosterReload = true
      })
    },
    getExportReviews(id){
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrollexportreview/reports/' + id,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     this.exportReportReviews = response.data

        // this.rosterReload = true
      })
    },
    verifyPayrollRecord(data){
      var report = this.exportReport
      var payroll = {}
      var user = data // this.userData.
      payroll['exportReviewAuthor'] = user.firstName + ' ' + user.lastName
      payroll['exportReviewAuthorId'] = user.id
      payroll['requestApproved'] = false
      payroll['payrollData'] = data.shifts
      payroll['payrollExportReportId'] = report.id
      payroll['title'] = report.title
      axios({
        method: 'post',
        url: '/api/v1/staffing/payrollexportreview',
        data: payroll,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
        console.log(response.data)

        // this.rosterReload = true
      })
      // console.log(data)
    },
    submitReport(){
      var data = this.holdReport
      data['payrollData'] = this.reportDataReturned
      if(!this.timecard){
        data['verificationUserIds'] = this.nofiticationTotal
      }
      delete data.newReport
      axios({
        method: 'post',
        url: '/api/v1/staffing/payrollexport',
        data: data,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
        this.$emit('refreshExportReports', this.holdReport.id)

        // this.rosterReload = true
      })
    },
   


  }



  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
