<template>
<div>
  <b-row ><b-col align="right">
  <b-btn size="sm" variant="danger" v-if="!showModal" @click.stop="cancelBottle">Cancel</b-btn></b-col></b-row>
  <b-card v-if="bottleArray != ''" v-for="bottle in bottleArray" :key="bottle.index" header-tag="header">
    <b-row slot="header"><b-col><h4> {{ bottle.type }}</h4></b-col><b-col cols="auto"><b-btn size="sm" variant="danger" @click="removeBottle(bottle)">x</b-btn></b-col></b-row>
  <b-input-group v-if="false" size="lg" class="sliderClass">
    <b-form-input  v-model="bottle.pressure" type="range" min="0" max="4500" step="100" />
  <b-input-group-addon class="bottlePressure" :class="{bottleFull: bottle.pressure >= 4000 }"><p >{{ bottle.pressure }}</p>
  </b-input-group-addon>
  </b-input-group>
  <b-row><b-col >
  <b-form-select v-model="bottle.id" :options="numberGet" :state="idState(bottle.id)"  />
  </b-col><b-col md="auto" sm="12">
  <b-btn  class="desktopView" variant="outline-danger" @click="bottle.pressure = parseInt(bottle.pressure) - 100">-100</b-btn>
  <b-btn class="mobileView"  variant="outline-danger" @click="bottle.pressure = parseInt(bottle.pressure) - 100">-</b-btn>
  <b-btn :variant="bottle.pressure >= 4000 ? 'success' : 'danger'">{{  bottle.pressure + ' PSI' }}</b-btn>
  <b-btn class="desktopView" variant="outline-success" @click="bottle.pressure = parseInt(bottle.pressure) + 100">+100</b-btn>
   <b-btn class="mobileView" variant="outline-success" @click="bottle.pressure = parseInt(bottle.pressure) + 100">+</b-btn>
  </b-col></b-row>
  </b-card>
  <br>
  <b-btn variant="primary" @click="addAirPack">Add AirPack</b-btn>
  <b-btn variant="secondary" @click="addSpare">Add SpareBottle</b-btn>

  <b-btn variant="primary" @click="addRICPack">Add RICPack</b-btn>
  <b-btn variant="secondary" @click="addAriel">Add Aerial Bottle</b-btn>

<br>
<br>
<b-btn variant="success" v-if="bottleArrayPreviousData == undefined" @click="submitcomment(bottleArray)">Submit Bottle Check</b-btn>
<b-btn variant="success" v-if="bottleArrayPreviousData != undefined" @click="submitUpdatedComment(bottleArray)">Submit Updated Check</b-btn>
<br>


</div>
</template>

<script>
    import { bus } from './../../main.js'
    import axios from 'axios'
    import { mapState } from 'vuex'
    export default {
      components:{
      },
       props: {
         dailyvehicle: {
           type: Object,
         },
         showModal: {
           type: Boolean,
           default: false,
         },
         bottleArrayPreviousData: {
           type: Array,
           default: () => [],
         },
         bottleEdit: {
           type: Boolean,
           default: false,
         },
          bottleIndex: {
           type: Number,
    
         }

       },
       data () {
           return {
             dataRange: '',
             inputid: null,
             packCount: 1,
             spareCount: 1,
             ricCount: 1,
             arielCount: 1,
             bottleArray: [],

           }
       },
       created () {
         if(this.bottleArrayPreviousData != undefined){
         this.bottleArray = this.bottleArrayPreviousData
       }
       },
       beforeDestroy () {

       },
       watch: {

       },
       computed: {
         ...mapState([
           'userData',
           'getSessionData'
         ]),
         numberGet(){
           var options = []
           options.push({ value: null, text: 'ID'})
           for(var i = 0; i < 20; i++ ){
             var id = i + 1
             var string = { value: id, text: id }
             options.push(string)
           }
           return options
         }

       },
       methods: {
         removeBottle(data){
           var index = this.bottleArray.indexOf(data)
           this.bottleArray.splice(index, 1)
         },
         cancelBottle(event){
           bus.$emit('hideBottle')
         },
         idState(data){
           if(data == null){
           return false}
         },
      addAirPack(){
        var id = this.packCount
        this.packCount = this.packCount + 1
        this.bottleArray.push({id: id, type: 'Pack', pressure: '4000'})
      },
      addSpare(){
        var id = this.spareCount
        this.spareCount = this.spareCount + 1
        this.bottleArray.push({id: id, type: 'Spare', pressure: '4000'})
      },
        addRICPack(){
        var id = this.ricCount
        this.ricCount = this.ricCount + 1
        this.bottleArray.push({id: id, type: 'RIC Pack', pressure: '4000'})
      },
     addAriel(){
        var id = this.arielCount
        this.arielCount = this.arielCount + 1
        this.bottleArray.push({id: id, type: 'Ariel Bottle', pressure: '4000'})
      },
      submitUpdatedComment(data){
            if(this.bottleIndex == null){
              this.submitcomment()
            } else {
            var id = this.dailyvehicle.id
            var prevBottle = this.dailyvehicle.dailyvehicleBottleData
            if(!prevBottle){
              prevBottle = []
            }
            prevBottle[this.bottleIndex].bottleDataArray = this.bottleArray
             axios({
            method: 'post',
            url: '/api/v1/content/dailyvehicle/bottle/' + id,
            data: {
                  dailyvehicleBottleData : prevBottle,
                  },
            headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',}
                }).then( response => {
                      bus.$emit('refreshDomEvent')
                      bus.$emit('refresh-calendar-list')
                     
                   }),
            this.bottleArray = []
              this.cancelBottle()
            }
        },
       submitcomment(data){
            var id = this.dailyvehicle.id
            var prevBottle = this.dailyvehicle.dailyvehicleBottleData
                        if(!prevBottle){
              prevBottle = []
            }
            prevBottle.push({ bottleAuthor: this.userData.firstName + ' ' + this.userData.lastName, bottleAuthorId: this.userData.id, bottleDataArray: this.bottleArray})
             axios({
            method: 'post',
            url: '/api/v1/content/dailyvehicle/bottle/' + id,
            data: {
                  dailyvehicleBottleData : prevBottle,
                  },
            headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',}
                }).then( response => {
                      bus.$emit('refreshDomEvent')
                      bus.$emit('refresh-calendar-list')
                  
                   }),
            this.bottleArray = []
              this.cancelBottle()
        },

       }
    };
</script>

<style scoped>
.sliderClass {
  border: 1px solid;
  border-radius: 5px;
}
.bottlePressure {
  background: #dc3545;
  font-weight: bold;
  color: #fff;
}
.bottleFull {
  background: #28a745;
}
.desktopView {
  display: inline;
}
.mobileView{
  display: none;
}
@media screen and (max-width: 400px) {
  .desktopView{
    display: none;
  }
  .mobileView{
    display: inline;
  }
}

</style>
