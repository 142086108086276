<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
    <b-card>
      <b-row>
        <b-col lg="4">
          <h4>Unassigned Users</h4>
        <multiselect v-model="rosterUserId" 
        :options="previous && previous.id ? deptContactData : deptContactDataFiltered" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </b-col>
        <b-col lg="4">
          <h4>Scheduled Shift</h4>
        <b-form-select v-model="rosterScheduleShift" :options="rosterShifts" />
        </b-col>
         <b-col lg="4">
          <h4>Roster Spot</h4>
        <multiselect
         v-model="rosterId" 
        :options="rosterSpotsFilter" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Roster Spot" label="apparatusName" 
        track-by="id"></multiselect>
        </b-col>
        </b-row>
        <b-row class="mt-3"><b-col lg="3">
          <h4>Position</h4>
          <b-form-select :disabled="!rosterId" v-model="rosterPosition" :options="rosterPositionOpt" />
          </b-col>
          <b-col lg="3" v-if="siteconfig.payroll.departmentCoding">
            <b-form-checkbox v-model="departmentCoding">Request Department Code</b-form-checkbox>
          </b-col>
          <b-col lg="3" v-if="!siteconfig.payroll.flexibleSchedule">
          <h4>Roster Type</h4>
          <b-form-select v-model="rosterSpotType" :options="rosterSpotTypeOptions" />
          </b-col>
          <b-col lg="6" v-if="!siteconfig.payroll.flexibleSchedule">
           <h4>Roster Notes</h4>
            <b-form-input v-model="rosterNote" placeholder="Short Description if needed" />
          </b-col>
        </b-row>
         <b-row class="mt-3"><b-col lg="3">
          <h4>Roster Start Date</h4>
          <b-form-input type="date" v-model="rosterAssignmentStart" />
          </b-col>
          <b-col lg="3">
          <h4>Roster End Date</h4>
          <b-form-input type="date" v-model="rosterAssignmentEnd" />
          <em>leave blank if continuous</em>
          </b-col>
          <b-col v-if="siteconfig.payroll.kellyDayPattern">
           <h4>Kelly Position</h4>
            <b-form-select v-model="rosterKellyCycleNumber" :options="siteconfig.payroll.kellyDayPattern" />
          </b-col>
          <b-col lg="6" v-if="rosterScheduleShift == 'Daily'">
            <h4>Work Days</h4>
          <b-form-checkbox-group v-model="scheduledWorkDays" :options="scheduledWorkDaysOptions" /> 
          </b-col>
          <b-col lg="3" v-if="!siteconfig.payroll.flexibleSchedule">
            <h4>Shift Start Time</h4>
          <b-form-input type="time" v-model="rosterStartTime" />
            </b-col>
            <b-col lg="3" v-if="!siteconfig.payroll.flexibleSchedule">
           <h4>Shift Length</h4>
          <b-form-input type="number" v-model="rosterShiftLength" />
            </b-col>
        </b-row>
        <b-row v-if="siteconfig.payroll.lunchOptions">
            <b-col>
              <h4>Lunch Length (Hrs)</h4>
              <b-form-input type="number" v-model="lunchLength" />
            </b-col>  
            <b-col>
              <h4>Auto Lunch</h4>
              <b-form-checkbox v-model="autoCalculateLunch" @input="updateGenerateOrLunch(true, $event)">Auto</b-form-checkbox>  
            </b-col>
            <b-col >
              <h4>Lunch Threshold</h4>
              <b-form-input type="number" v-model="autoCalculateLunchThreshold" />
            </b-col>  
            <b-col>
              <h4>Auto Generate</h4>
              <b-form-checkbox v-model="autoGenerate" @input="updateGenerateOrLunch(false, $event)">Auto</b-form-checkbox>  
            </b-col>
        </b-row>
        <b-row>
         <b-col align="right" class="pt-5">
            <b-btn @click="newProfileSubmit" 
            :disabled="rosterAssignmentStart == '' || rosterUserId == ''|| rosterScheduleShift == '' || rosterId == '' || rosterPosition == ''">Submit</b-btn>
            </b-col></b-row>

    </b-card>
    
</div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
export default {
  components: {
    multiselect
  },
  name: 'roster-admin',
    props: ['rosterData', 'rosterProfiles', 'rosterShifts', 'rosterSpotsFilter', 'deptContactDataFiltered', 'previous'],
  data () {
    return {
      stationSelectValue: '',
      stationParentSelectValue: '',
      rosterUserId: '',
      rosterId: '',
      rosterSpotType: 'permanent',
      rosterPosition: '',
      rosterScheduleShift: '',
      rosterKellyCycleNumber: '',
      rosterAssignmentStart: '',
      rosterNote: '',
      updateMultiSelect: true,
      lunchLength: 0,
      autoCalculateLunch: false,
      departmentCoding: false,
      autoCalculateLunchThreshold: 0,
      autoGenerate: false,
      rosterAssignmentEnd: '',
      bulkHoldObject: [],
      rosterStartTime: '08:00:00',
      rosterShiftLength: 24,
      rosterProfileTableFields: [
        'userId',
        'rosterSpotType',
        'rosterAssignmentStart',
        'rosterAssignmentEnd'
      ],
      scheduledWorkDaysOptions:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      scheduledWorkDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      rosterPositionOpt: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      rosterParentsData: [],
      rosterSpotTypeOptions: ['permanent', 'float', 'temporary'],
      rosterFields: [
        'apparatusName',

      ],
    }
  },
  created(){
  // console.log('VIEW')
      if(this.previous && this.previous.id){
    //   console.log(this.previous)
    //   console.log(this.rosterSpotsFilter)
       this.rosterUserId = this.deptContactData.find(p => p.id == this.previous.userId)
       this.rosterId = this.previous.rosterId ? this.rosterSpotsFilter.find(p => p.id == this.previous.rosterId) : null
    //   console.log(this.previous.rosterId)
    //   console.log(this.rosterId)
       this.rosterSpotType = this.previous.rosterSpotType
       this.rosterPosition = this.previous.rosterPosition
       this.rosterScheduleShift = this.previous.scheduledShift
       this.rosterKellyCycleNumber = this.previous.scheduledKellyCycleNumber >= 0 && this.siteconfig.payroll.kellyDayPattern ? this.siteconfig.payroll.kellyDayPattern[this.previous.scheduledKellyCycleNumber] : null
       this.rosterAssignmentStart = moment(this.previous.rosterAssignmentStart).format('YYYY-MM-DD')
       this.rosterAssignmentEnd = this.previous.rosterAssignmentEnd ? moment(this.previous.rosterAssignmentEnd).format('YYYY-MM-DD') : null
       this.rosterNote = this.previous.rosterNote,
       this.rosterShiftLength = this.previous.rosterShiftLength,
       this.rosterStartTime = this.previous.rosterStartTime,
       this.scheduledWorkDays = this.previous.scheduledWorkDays,
       this.lunchLength = this.previous.rosterSpotLunchLength ? this.previous.rosterSpotLunchLength : 0,
       this.autoCalculateLunch = this.previous.rosterSpotAutoLunch ? this.previous.rosterSpotAutoLunch : false,
       this.autoCalculateLunchThreshold = this.previous.rosterSpotAutoLunchThreshold ? this.previous.rosterSpotAutoLunchThreshold : 0,
       this.autoGenerate = this.previous.autoGenerate ? this.previous.autoGenerate : false
       this.departmentCoding = this.previous.rosterSpotDepartmentCoding ? this.previous.rosterSpotDepartmentCoding : false
      } else if (this.previous){
        console.log('OTHER PREVIOUS')
        console.log(this.previous)
        this.rosterAssignmentStart = moment(this.previous.rosterAssignmentStart).startOf('month').format('YYYY-MM-DD')
         this.rosterId = this.previous.rosterId ? this.rosterSpotsFilter.find(p => p.id == this.previous.rosterId) : null
         this.rosterPosition = this.previous.rosterPosition
         this.rosterScheduleShift = this.previous.scheduledShift
         this.rosterStartTime = this.previous.rosterStartTime
         this.rosterShiftLength = this.previous.rosterShiftLength
      }
  },
  mounted(){
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'deptContactData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        // user_id, roster_id, roster_spot_type, roster_position, roster_note, scheduled_shift, scheduled_kelly_cycle_number, roster_assignment_start, roster_assignment_end
        kellyDayCycleCalc(){
          var cycle = this.siteconfig.payroll.cycle
          if(cycle){
            var array = []
            for(var i = 0; i < cycle; i++){
              array.push(i + 1)
            }
            return array
          } else {
            return null
          }
        },
  },
  mounted(){

  },
  watch: {
    rosterUserId(oldData, newData){
     if(newData != oldData && oldData != ''){
      if(newData && newData.shiftAssignment){
      var shifts = this.rosterShifts
      var userShift = newData.shiftAssignment
      var index = shifts.indexOf(userShift)
      if(index != -1){
        this.rosterScheduleShift = shifts[index]
      } 
      }
     }
    },
  },
  methods: {
    updateGenerateOrLunch(boolean, value){
      if(boolean && value){
        this.autoGenerate = false
      } else if (!boolean && value) {
        this.autoCalculateLunch = false
        this.autoCalculateLunchThreshold = 0
      }
    },  
    getDateFormated(date){
      if(date){
        return moment(date).format('MM/DD/YYYY')
      } else {
       return 'continuous'
      }
    },
    getRosterIdFromApp(id){
      var roster = this.rosterData.find(p => p.apparatusId == id)
      if(roster){
        return roster.id
      } else {
        return null
      }
    },
    newProfileSubmit(){
     var data = {
       userId: this.rosterUserId.id,
       rosterId: this.rosterId.id,
       rosterSpotType: this.rosterSpotType,
       rosterPosition: this.rosterPosition,
       scheduledShift: this.rosterScheduleShift,
       scheduledKellyCycleNumber: this.rosterKellyCycleNumber && this.siteconfig.payroll.kellyDayPattern.indexOf(this.rosterKellyCycleNumber) >= 0 ? this.siteconfig.payroll.kellyDayPattern.indexOf(this.rosterKellyCycleNumber) : null,
       rosterAssignmentStart: moment(this.rosterAssignmentStart + 'T' + this.rosterStartTime, 'YYYY-MM-DDTHH:mm:ss').toDate(),
       rosterAssignmentEnd:  moment(this.rosterAssignmentEnd + 'T' + this.rosterStartTime, 'YYYY-MM-DDTHH:mm:ss').toDate(),
       rosterNote: this.rosterNote,
       rosterShiftLength: this.rosterShiftLength,
       rosterStartTime: this.rosterStartTime,
       rosterSpotAutoLunch: this.autoCalculateLunch ? this.autoCalculateLunch : null,
       rosterSpotLunchLength: this.lunchLength && this.lunchLength > 0 ? this.lunchLength : null,
       scheduledWorkDays: this.rosterScheduleShift == 'Daily' ? this.scheduledWorkDays : null,
       rosterSpotAutoLunchThreshold: this.autoCalculateLunchThreshold ? this.autoCalculateLunchThreshold : null,
       rosterSpotAutoGenerateTimecard: this.autoGenerate ? this.autoGenerate : null,
       rosterSpotDepartmentCoding: this.departmentCoding ? this.departmentCoding : null
      }
      if(this.previous && this.previous.id){
        data['id'] = this.previous.id
        console.log(data)
        this.$emit('updateProfileSubmit', data)
      } else {
      this.$emit('newProfileSubmit', data)
      }
    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
