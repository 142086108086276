<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Training Activities">
          <trainingModule />
          </b-tab>
        <b-tab  title="Credentials">
          <credentialTraining />
        </b-tab>
        </b-tabs>
        </b-card>
</div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import credentialTraining from './training/credential.vue'
import trainingModule from './training/trainingModule.vue'
import formDataBuilder from '../inputfields/formDataBuilder.vue'
import moment from 'moment'
export default {
  components: {
    credentialTraining,
    formDataBuilder,
    trainingModule
  },
  name: 'training-admin',
  data () {
    return {
      
    }
  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
        ]),
       
  },
  mounted(){

  },
  watch: {
  },
  methods: {
    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
