<template>
<div v-if="fieldHold">
                      <b-card no-body>
                        <b-tabs card>
                          <b-tab title="Field Info">
                            <b-row><b-col>
                     <b-form-input type="text" v-model="fieldHold.label" placeholder="Provide a name for the field label"  />
                     </b-col><b-col cols="*" class="py-2"><b-form-checkbox class="mx-4" v-model="fieldHold.required">Required?</b-form-checkbox></b-col>
                            </b-row>
                    <b-container v-if="fieldHold.objKey == 'new' && fieldHold.type == ''">
                      <b-radio-group v-model="newInputOptionsSelector" :options="newInputOptionsSelectorOpts" v-if="false">
                        </b-radio-group>
                        <span v-for="(btn, index) in btnFilterGroup" :key="index">
                          <b-dropdown v-if="btn.group" :text="btn.label" :variant="btn.variant">
                          <b-dropdown-item v-for="(opt, index) in btn.options" :key="index" @click="setFieldModelType(opt.value, opt.type)">{{ opt.label }}</b-dropdown-item>
                          </b-dropdown>
                        <b-btn 
                        v-if="!btn.group"
                        :variant="btn.variant" 
                         @click="setFieldModelType(btn.value, btn.type)">{{ btn.label }}</b-btn></span>
                      </b-container>
                      <b-row class="mt-3" v-if="fieldHold.objKey == 'new' && fieldHold.type != ''"><b-col align="right">
                        <b-btn size="sm" variant="outline-danger" @click="resetFieldInput">Back To Input Select</b-btn>
                        </b-col></b-row>
                      <b-row class="mt-3">
                        <b-col md="4" v-if="fieldHold.type != '' && fieldHold.type != 'html' && fieldHold.type != 'picture' && fieldHold.type != 'video'">
                        <h6>Field Size:</h6><b-form-select v-model="fieldHold.size" :options="colSizeOptions" /></b-col>
                         <!--  Entries related to dataRef  -->
                         <b-col md="4" v-if="fieldHold.type == 'dataRef'"><h6>Type of Reference</h6><b-form-select v-model="fieldHold.refItem" @change="addFieldReference" :options="referenceOptions" /></b-col>
                         <b-col md="4" v-if="fieldHold.type == 'dataRef'"><h6>Type of Select</h6>
                                    <b-radio-group v-model="fieldHold.refType" :options="['single', 'multiselect']" /></b-col>
                        <!-- entries related to single multi check select -->
                        <b-col md="6" v-if="fieldHold.type == 'select'"><h6>Select Type</h6><b-radio-group v-model="fieldHold.selectType" :options="['radio', 'menu']" /></b-col>
                         <b-col md="12" v-if="(fieldHold.type == 'select' ||  fieldHold.type == 'selectmulti' || fieldHold.type == 'checkbox') && selectOptionsReset"><h6>Select Options</h6>
                                      <b-row><b-col>Option Text</b-col><b-col>Option Value</b-col><b-col>Default?</b-col><b-col  v-if="fieldHold.type == 'select'">Disabled?</b-col></b-row>
                                      <b-row v-for="(opt, index2) in fieldHold.selectOptions" :key="index2">
                                        <b-col><b-form-input v-model="opt.text" /></b-col>
                                        <b-col><b-form-input v-model="opt.value" /></b-col>
                                        <b-col><b-checkbox v-model="opt.default" @input="fieldHold.type == 'select' ? checkOtherValues($event, 'default', index2) : ''" :check-value="true" :uncheckvalue="false" /></b-col>
                                        <b-col v-if="fieldHold.type == 'select'"><b-checkbox v-model="opt.disabled" @input="checkOtherValues($event, 'disabled', index2)" :check-value="true" :uncheckvalue="false" /></b-col></b-row>
                                        <b-btn variant="primary" @click="addNewSelectOption">Add New Option</b-btn></b-col>
                        <!-- entries related to boolean -->
                        <b-col md="8" v-if="fieldHold.type == 'boolean'">
                        <b-form-checkbox v-model="fieldHold.value" :check-value="true" :uncheck-value="false">Confirmation Checkbox Default</b-form-checkbox>
                        <label>Text to display next to the checkbox:</label>
                        <b-form-input v-model="fieldHold.displayText"  />
                        </b-col>
                         <!-- entries related to photo select -->
                            <b-col md="6" v-if="fieldHold.type == 'photo'">
                              <h5>Add Photo Files</h5>
                              <div v-if="false" class="image-upload">
                                <label for="file-input">
                                  <img :src="'../static/images/siteicons/help-icon-right-align.png'"/>
                                  </label>
                                <input id="file-input" type="file"  v-if="imageLoadReset" class="pictureInputButton" @change="postImage($event)" />
                              </div>
                                  <input v-if="imageLoadReset" class="pictureInputButton" type="file" @change="postImage($event)">
                
                            <h4 v-if="loadingFile"> ... Loading File </h4>


                        </b-col>
                            <b-col md="8" v-if="fieldHold.type == 'file'">

                                  <b-form-input class="w-50 mx-auto my-2" type="text" placeholder="Add a display name to upload file" v-model="shorttitle" />
                                 
                                      <input  v-if="filePosting == false" :disabled="shorttitle == ''" type="file"  @change="postDocumentFile($event, shorttitle)" />
                                    <b-row v-if="filePosting == true"><b-col align="right" cols="auto" class="pt-1">
                                      <span div class="spinner">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>      
                                    </span></b-col></b-row>
                              </b-col>

                            </b-row>
                             <b-table small v-if="fieldHold.type == 'file' && fieldHold.value.length > 0" :fields="['url', 'remove']" :items="fieldHold.value">
                            <template slot="url" slot-scope="data">
                              <b-btn variant="link" @click="viewFile(data.item.url)">{{ data.item.title }}</b-btn>
                              </template>
                              <template slot="remove" slot-scope="data">
                                <b-btn variant="danger" size="sm" @click="fieldHold.value.splice(data.index, 1)">X</b-btn>
                                </template>
                                 </b-table>
                            <!-- entries related to photo -->
                                                        <b-container v-if="carouselToggle && fieldHold.type == 'photo' && fieldHold.value.length > 0" class="mt-3">
                                  <b-carousel id="carousel1"
                                            style="text-shadow: 1px 1px 2px #333;"
                                              controls
                                            indicators
                                            interval="10000"
                                            background="#ababab"
                                            v-model="slide"
                                          >
                                    <template v-for="(pic, index) in fieldHold.value">
                                    <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" >
                                            <div class="closeButtonCarousel" @click="removeImage(index)"> &#10006; </div>
                                    </b-carousel-slide>
                                    </template>
                                  </b-carousel>
                          </b-container>
                            <!-- entries related to link -->
                        <itemBuilder v-if="fieldHold.type == 'link'" :item="{type: fieldHold.type}" @addValue="addFieldValueFromBuilder" />
                          <h6 v-if="fieldHold.type == 'link'" v-for="(link, index) in fieldHold.value" :key="index">
                            <b-btn variant="link" @click="viewFile(link.url)" >{{ link.title }}</b-btn><b-btn size="sm" variant="danger" @click="removeItemFromList(index)">X</b-btn></h6> 
                          <!-- entries related to video -->

                        <b-container v-if="fieldHold.type == 'video'">
                          <b-form-input type="url" v-if="selectOptionsReset" class="itemDescription" v-model="videoLink" placeholder="Paste Youtube/Vimeo Link to add a Video" />
                          <b-container  v-if="fieldHold.value.length > 0" >
                            <b-row v-for="(video, index) in fieldHold.value" :key="index"><b-col>
                            <div >
                             
                            <div class="iframeWrap">
                            <iframe class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                            
                            </div>
                            </div></b-col><b-col cols="*"> <b-btn size="sm" variant="danger" @click="removeVideo(index)"> &#10006; </b-btn></b-col></b-row>
                            </b-container>
                        </b-container>

                          <!-- entries related to Html -->
                         <vue-editor v-if="fieldHold.type == 'html'" :editorToolbar="customToolbar"  v-model="fieldHold.value"   ></vue-editor>
                      
                          </b-tab>
                          <b-tab title="Requirements" :disabled="fieldHold.objKey == 'new'" v-if="false">
                          
                            <b-card v-if="selectOptionsReset && fieldHold.requirements && fieldHold.requirements.length > 0" no-body>
                             <b-container v-if="selectOptionsReset"  v-for="(requirements, index) in fieldHold.requirements" :key="index">
                               <h5 class="pt-1">{{ index > 0 ? 'OR This Field Will Error If...' : 'This Field Will Error If...' }}</h5>
                              <b-row class="m-2"><b-col cols="*" class="pt-2">Error Message to Display:</b-col>
                              <b-col><b-form-input v-model="requirements.errorText" placeholder="Error Message" /></b-col></b-row>
                              <b-table :fields="['modifier', 'variable', 'type', 'typeVar', 'edit']"  :items="requirements.requirements" >
                                  <template slot="modifier" slot-scope="data">
                                    <span v-if="data.item.modifier != null" ><h5 class="pt-1">{{ data.item.modifier }}</h5></span>
                                    </template>
                                  <template slot="variable" slot-scope="data">
                                    <b-form-select :disabled="data.item.modifier == null" v-model="data.item.variable" :options="formVariables" @input="checkIfDataRef(data.item)" />
                                    </template>
                                  <template slot="type" slot-scope="data">
                                    <b-form-select :disabled="data.item.modifier == null && !data.item.variable" v-model="data.item.type" :options="requirementTypeFilter(data.item.variable)" />
                                    </template>
                                  <template slot="typeVar" slot-scope="data" v-if="!(data.item.type == 'isBlank' || data.item.type == 'isNotBlank')">
                                      <b-form-select v-if="data.item.type == 'equals' || data.item.type == 'doesNotEqual'" v-model="data.item.typeVar" :options="typeVarOptions(data.item.variable, data.item.type)" />
                                      <Multiselect  v-if="data.item.type == 'isOneOf' || data.item.type == 'isNoneOf'" 
                                       v-model="data.item.typeVar" 
                                      :options="typeVarOptions(data.item.variable, data.item.type)"
                                      :multiple="true" 
                                      :close-on-select="false" :clear-on-select="true" 
                                      :hide-selected="true" :preserve-search="true" 
                                      placeholder="Menu Options" 
                                          >
                                     </Multiselect> 
                                    </template>
                                    <template slot="edit" slot-scope="data">
                                      <b-btn class="mt-1" size="sm" variant="danger" v-if="data.item.modifier != null" @click="modifyRequirementCondition(index, 'removeCondition', data.index)">X</b-btn>
                                      </template>
                                </b-table>
                                <b-btn variant="outline-primary" @click="modifyRequirementCondition(index, 'add')">Add Another Condition</b-btn>
                                <b-btn variant="outline-danger" @click="modifyRequirementCondition(index, 'remove')">Remove Requirement Group</b-btn>
                                </b-container>
                                </b-card>
                               
                                  <b-btn variant="outline-danger" @click="addNewRequirement" class="mt-2">Add New Requirement Group</b-btn>
                          </b-tab>
                          <b-tab title="Visibility" :disabled="fieldHold.objKey == 'new'" v-if="false">

                           </b-tab> 
                          </b-tabs></b-card>
             <b-container class="mt-2">
               <b-btn v-if="fieldHold.objKey != 'new'" size="sm" class="float-left" variant="danger" @click="removeModalHold()">Delete</b-btn>
                <b-btn size="sm" class="float-right" variant="success" @click="saveModalHoldObj()">
                  Save and Close
                </b-btn>
                <b-btn size="sm" class="float-right" variant="primary" @click="saveModalHoldObjKeep()">
                  Save
                </b-btn>
             </b-container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { VueEditor } from 'vue2-editor'
import userSelector from '../../inputfields/userfilter.vue'
import itemBuilder from '../../inputfields/formBuilderComponents/formComponentBuilder.vue'
import ImageCompressor from 'image-compressor.js'
import moment from 'moment'
export default {
  components: {
    userSelector,
    Multiselect,
    VueEditor,
    itemBuilder
  },
  props: ['field', 'newField', 'formData' ],
  name: 'training-module-edit-module',
  data () {
    return {
        currentPage: 1,
        shorttitle: '',
        filePosting: false,
        showAll: true,
        edit: true,
        editGroupId: '',
        imageLoadReset: true,
        showGroup: '',
        dataReset: true,
        fieldModelTypeSet: false,
        selectOptionsReset: true,
        resetMultiSelectOptions: true,
        deptRoles: ['user', 'supervisor', 'trainingops', 'emsops', 'fireops', 'adminchief'],
        fieldHold: {objKey: 'new', itemType: '', type: '', label: "", size: "12", value: ''},
        videoLink: '',
      searchData: '',
      refValueOptions: [],
      newInputOptionsSelector: 'form',
      requirementType: [
        {text: 'Is Blank', value: 'isBlank' , types: ['text', 'number', 'textarea', 'select', 'selectmulti', 'dataRef', 'date', 'datetime', 'time', 'boolean']},
        {text: 'Is Not Blank', value: 'isNotBlank', types: ['text', 'number', 'textarea', 'select', 'selectmulti', 'dataRef']},
        {text: 'Equals', value: 'equals', types: ['select', 'selectmulti', 'dataRef']},
        {text: 'Does Not Equal', value: 'doesNotEqual', types: ['select', 'selectmulti', 'dataRef']},
        {text: 'Is One Of', value: 'isOneOf', types: ['select', 'selectmulti', 'dataRef']},
        {text: 'Is None Of', value: 'isNoneOf', types: ['select', 'selectmulti', 'dataRef']}
      ],
      modifierOpt: [
        {text: 'And', value: 'and'},
        {text: 'Or', value: 'or'},
      ],
      fieldsRefArray: [],
      fileUploadArray: [],
      slide: 0,
      loadingFile: false,
      carouselToggle: true,
      colSizeOptions: [
        {text: '25%', value: '3'},
        {text: '33%', value: '4'},
        {text: '50%', value: '6'},
        {text: '66%', value: '8'},
        {text: '75%', value: '9'},
        {text: '100%', value: '12'}
      ],
      newInputOptionsSelectorOpts: [
       {text: 'Informational Fields', value: 'review'},
        {text: 'User Input Fields', value: 'form'}
      ],
      referenceOptions: [
        {text: 'Stations', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'Users', value: 'deptContactData'}
      ],
      fieldTypeOptions: [
         {type: 'review', variant: 'outline-success', label: 'Saved Document', value: 'link'},
         {type: 'review', variant: 'outline-secondary', label: 'Photo', value: 'photo'},
         {type: 'review', variant: 'outline-info', label: 'Video', value: 'video'},
         {type: 'review', variant: 'outline-primary', label: 'Upload File', value: 'file'},
         {type: 'form', group: 'Text/Number', variant: 'outline-info', label: 'Text/Number', options: [
                {type: 'form', variant: 'outline-info', label: 'Display Formated Text', value: 'html'},
                {type: 'form', variant: 'outline-primary', label: 'Display Basic Text', value: 'textarea'},
                {type: 'form', variant: 'outline-info', label: 'Text Input Field', value: 'text'},
                {type: 'form', variant: 'outline-primary', label: 'Number Input Box', value: 'number'},
                {type: 'form', variant: 'outline-info', label: 'Textarea Input Box', value: 'textarea'}
                ]},
         {type: 'form', group: 'Date/Time', variant: 'outline-primary', label: 'Date/Time', options: [
                {type: 'form', variant: 'outline-info', label: 'Date Only', value: 'date'},
                {type: 'form', variant: 'outline-primary', label: 'Time Only', value: 'time'},
                {type: 'form', variant: 'outline-info', label: 'Date & Time', value: 'datetime'}
                ]},
        {type: 'form', group: 'Select', variant: 'outline-success', label: 'Selects', options: [
                {type: 'form', variant: 'outline-success', label: 'Single Select', value: 'select'},
                {type: 'form', variant: 'outline-secondary', label: 'Multi Select', value: 'selectmulti'},
                {type: 'form', variant: 'outline-info', label: 'Check Boxes', value: 'checkbox'},
                {type: 'form', variant: 'outline-info', label: 'Confirmation', value: 'boolean'},
                ]},
         {type: 'form', variant: 'outline-info', label: 'Advanced Tag', value: 'dataRef'},
         {type: 'form', variant: 'outline-primary', label: 'Request File', value: 'upload'}

      ],
      customToolbar: [
          ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
          ['blockquote', 'code-block', 'link', { 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
    }
  },
  created(){
  
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),
        formVariables(){
          var array = []
          var refArray = []
          var data = this.formData
          for(var i in data){
            var fields = data[i].groupFields
            for(var f in fields){
              if(fields[f].itemType == 'form'){
                array.push({text: fields[f].label + ' (' + data[i].btnName + ')', value: fields[f].objKey})
                refArray.push(fields[f])
              }
            }
          }
          this.fieldsRefArray = refArray
          return array
        },
        btnFilterGroup(){
          return this.fieldTypeOptions.filter(p => {
            return p.type == this.newInputOptionsSelector
          })
        },
       deptContactDataCheck(){
         var data = this.deptContactData
         data.forEach(item => item['addCredential'] = false)
         return data
       },
       setGroupUsersForTrainingFilter(){
         return this.setGroupUsersForTraining.filter(p => {
           return p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase())
         })
       },
  
  },
  mounted(){
    if(this.field && this.field.id){
      this.fieldHold = _.cloneDeep(this.field)
      if(this.field.required == undefined){
        this.fieldHold['required'] = false
      }
    } else {
      this.fieldHold = {objKey: 'new', itemType: '', type: '', label: "", size: "12", value: '', required: false}
    }
  },
  watch: {
          videoLink(newData){
        if(newData != ''){
        var string = newData
        this.fieldHold.value.push(string)
        this.videoLink = ''
        this.selectOptionsReset = false
        this.$nextTick(function(){
          this.selectOptionsReset = true
        })
        } else {
          return
        }
      },
      newField(newData){
        console.log(newData)
        if(newData == false && this.field){
          this.fieldHold = this.field
         if(this.field.refItem){
            this.addFieldReference(this.field.refItem)
            }
        }
      }
  },
  methods: {
    updateResetMultiselect(){
      this.resetMultiSelectOptions = false
      this.$nextTick(function(){
        this.resetMultiSelectOptions = true
      })
    },
    resetFieldInput(){
       this.fieldHold = {objKey: 'new', itemType: '', type: '', label: "", size: "12", value: ''}
    },
    consoleLog(data){
      console.log(data)
      console.log(this.fieldHold.refMenuProps)
    },
    addNewRequirement(){
      this.selectOptionsReset = false
      if(!this.fieldHold.requirements){
      this.fieldHold.requirements = []
         var obj = {modifier: null, variable: this.fieldHold.objKey, type: 'isBlank', typeVar: []}
         var newRequirement = {errorText: 'Error', requirements: [obj]}
      } else {
         var obj = {modifier: null, variable: this.fieldHold.objKey, type: 'isBlank', typeVar: []}
         var newRequirement = {errorText: 'Error', requirements: [obj]}
      }  
      this.fieldHold.requirements.push(newRequirement)
      this.$nextTick(function(){
          console.log('TRUE')
          console.log(this.fieldHold.requirements)
          this.selectOptionsReset = true
        })
    },
    modifyRequirementCondition(index, mod, item){
      if(mod == 'remove'){
        this.fieldHold.requirements.splice(index, 1)
                      this.selectOptionsReset = false
        this.$nextTick(function(){
          this.selectOptionsReset = true
        })
      } else if(mod == 'add'){
        var obj = {modifier: 'and', variable: '', type: '', typeVar: []}
        this.fieldHold.requirements[index].requirements.push(obj)
                      this.selectOptionsReset = false
        this.$nextTick(function(){
          this.selectOptionsReset = true
        })
      } else if (mod == 'removeCondition'){
        this.fieldHold.requirements[index].requirements.splice(item, 1)
                      this.selectOptionsReset = false
        this.$nextTick(function(){
          this.selectOptionsReset = true
        })
      }
    },
    checkIfDataRef(data){
     var obj = this.fieldsRefArray.find(p => p.objKey == data.variable)
     if(obj){
       if(obj.refKey){
         data['refKey'] = obj.refKey
       } else if (data.refKey){
         delete data.refKey
       }
     }
    },
    requirementTypeFilter(id){
      var field = this.fieldsRefArray.find(p => p.objKey == id)
      if(field){
        var type = field.type
        return this.requirementType.filter(p => {
            var index = p.types.indexOf(type)
            if(index != -1){
              return p
            }
        })
      } else {
        return []
      }
    },
    typeVarOptions(id, type){
      if(type == 'equals' || type == 'doesNotEqual'){
        var field = this.fieldsRefArray.find(p => p.objKey == id)
          if(field.selectOptions){
            return field.selectOptions.filter(p => {
              return p.disabled != true
            })
        }
      }
      if(type == 'isOneOf' || type == 'isNoneOf'){
         var field = this.fieldsRefArray.find(p => p.objKey == id)
          if(field.selectOptions){
            var array = field.selectOptions.filter(p => {
              return p.disabled != true
            })
            return [... new Set(array.map(p => p.value))]
          } else if (field.type == 'dataRef'){
            var data = this[field.refItem]
            return [... new Set(data.map(p => p[field.refKey]))]
        } else {
          console.log(field)
        }
      }
    },
    setFieldModelType(data, type){
      this.fieldHold.type = data
      this.fieldHold.itemType = type
      if(data == 'dataRef'){
      this.fieldHold['refValue'] = 'id'
      this.fieldHold['refType'] = 'single'
      }
      if(data == 'select'){
        this.fieldHold['selectType'] = 'radio'
        this.fieldHold['selectOptions'] = []
      }
      if(data == 'selectmulti' || data == 'checkbox'){
        this.fieldHold['selectOptions'] = []
        this.fieldHold['refKey'] = 'value'
      }
      if(data == 'boolean'){
        this.fieldHold['value'] = false
        this.fieldHold['displayText'] = 'Check box to confirm'
        console.log(this.fieldHold)
      }
      if(data == 'link' || data == 'video' || data == 'photo' || data == 'file'){
        this.fieldHold['value'] = []
      }
    },
    getSearchValueRef(data, item){
     return this[data][0][item]
    },
    updateRefMenuDisplay(data){
      this.fieldHold.refMenuProps = data
    },
    addFieldValueFromBuilder(data){
      this.fieldHold.value.push(data)
    },
    removeItemFromList(index){
      console.log(index)
          this.fieldHold.value.splice(index, 1)
    },
    addFieldReference(data){
      if(data != this.field.refItem){
      this.fieldHold['refValue'] = 'id'
      this.fieldHold['refStore'] = []
      this.fieldHold['refMenuProps'] = []
      }
      this.refValueOptions = Object.keys(this[data][0])
    },
    addNewSelectOption(){
      this.selectOptionsReset = false
      this.fieldHold.selectOptions.push({text: '', value: '', disabled: false, default: false})
      this.$nextTick(function(){
        this.selectOptionsReset = true
      })
    },
    checkOtherValues(data, objkey, index){
           this.selectOptionsReset = false
      if(data){
      var array = this.fieldHold.selectOptions
      for(var i in array){
        array[i][objkey] = false
      }
      array[index][objkey] = true
      }
            this.$nextTick(function(){
        this.selectOptionsReset = true
      })
    },
    saveModalHoldObj(){
      this.$emit('saveModalHoldObj',  this.fieldHold)
      this.fieldHold = {}
    },
    saveModalHoldObjKeep(){
      console.log(this.fieldHold)
      this.$emit('saveModalHoldObjKeep',  this.fieldHold)
    },
    removeModalHold(){
      var result = confirm('Are You Sure You Want to Delete This Field?')
      if (result) {
        this.$emit('removeModalHoldObj', this.fieldHold.id)
        this.fieldHold = {}
      }
    },
    updateFormData(){
      var data = this.fieldHold
      if(data.objKey != 'new'){
      this.$emit('updateFieldHold', data)
      } else if(data.objKey == 'new') {
        console.log(data)
      }
      
    },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
        postImage(e){
      console.log('IMAGE POST')
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result)
        }
        })
      
     },
     postImageAxios(result){
        var data = new FormData();
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/trainingmodules/' + this.userData.lastName + this.userData.EmployeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fieldHold.value.push(response.data);
          this.carouselToggle = true
          this.loadingFile = false
          this.imageLoadReset = true
        });
     },
    removeVideo(idx){
      this.fieldHold.value.splice(idx, 1)
    },
    removeImage(idx){
      this.fileUploadArray.splice(idx, 1)
    },
    getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
   /*
        var videoLink = data.split('=')
          if(videoLink.length == 1){
            var videoLink = data.split('.be/')
          }
        return videoLink[1] */
    },
        postDocumentFile(e, title){
      this.filePosting = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/trainingmodules/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
      this.fieldHold.value.push({ title: title, url: response.data})

      this.$nextTick(function(){
        this.filePosting = false
        this.shorttitle = ''
      })
        });
    },
    submitTrainingModule(data){
      this.$emit('updateFormInformation', data)
  },

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.closeButtonCarousel {
    width: 20px;
    background: red;
    padding: 0 4px;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto 0;
    cursor: pointer;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.requirement-error{
  border: 1px solid red;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
