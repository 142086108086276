
<template>
	<b-modal
	id="SigModal"
	ref="SigLogModal"
	v-model="showModalSignature"
	size="lg"
	:noCloseOnBackdrop="true"
	:noCloseOnEsc="true"
	:cancel-disabled="true"
	:ok-disabled="true"
	>
	<template slot="modal-header" class="signModal">
				
				<b-btn @click="saveData" variant="success">Save</b-btn>
				<b-btn @click="fitScreen" variant="primary">Re-Size</b-btn>
				<b-btn @click="clearToggle" variant="danger">Clear</b-btn>
				<b-btn @click="closeModal" variant="danger">Close</b-btn>
	</template>
		<div v-if="windowWidth < windowHeight && windowWidth < 600"><h3>Please Turn Your Device to Sign in Landscape Mode.</h3></div>
			<div id="modalContainer"  class="modal-container-sig" ref="modalContainer">

			<div id="widthHeightData">

				<h4 v-show="false">{{ windowWidth }} and {{ windowHeight }}</h4>
			<h4 v-show="false">{{ canvasWidth }} and {{ canvasHeight }}</h4></div>
		<div v-show="windowWidth > windowHeight || windowWidth > 600">
	  	<canvas class='canvasPad' :name="name" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
		</div>
		  <input  type="hidden" v-model="signature">


		</div>
		<template slot="modal-footer">
			<div></div>
		</template>
	</b-modal>
</template>
<script>
import SignaturePad from 'signature_pad'
export default {
	props: ['name', 'witness', 'showModalSignature'],
	components: {
	},
  data: function() {
    return {
      signature: null,
			canvasHeight: '100px',
			canvasWidth: '200px',
			windowHeight: '',
			windowWidth: '',
			clearDrawing: true,
    }
  },

  created() {
	  this.$drawing = null
  	  window.addEventListener("resize", this.getWindowWidth)
	  window.addEventListener("orientationchange", this.fitScreen)
	  this.$nextTick(function(){
		  this.getWindowWidth()
	  })
	  window.addEventListener("touchmove", function(event) {
	
		if (event.target.classList.contains('canvasPad')) {
			
			// no more scrolling
			event.preventDefault();
		}
		}, { passive: false });

  },

  mounted() {
    var self = this;
    this.$drawing = new SignaturePad(this.$refs.canvas, {
      onEnd: function() {
        self.signature = self.$drawing.toDataURL();

      }
	}
	);

},
beforeDestroy (){
	window.removeEventListener("resize", this.getWindowWidth);
	window.removeEventListener("orientationchange", this.fitScreen);

},
	watch: {
	},
  computed: {
	canvasWidthSet(){
		var width = document.documentElement.clientWidth;
		this.windowWidth = width
		return width
	},
	canvasHeightSet(){
		var height = document.documentElement.clientHeight;
		this.windowHeight = height
		return height
	}  
  },
  methods: {
		clearToggle(){
			this.windowWidth = 0
			this.windowHeight = 0
			this.canvasWidth = 0
			this.canvasHeight = 0
			this.$nextTick(function(){
			this.getWindowWidth()
			})
		},
		getWindowWidth() {
			this.windowWidth = 0
			this.windowHeight = 0
			this.canvasWidth = 0
			this.canvasHeight = 0
			this.$nextTick(function(){
		this.windowWidth = document.documentElement.clientWidth
		this.windowHeight = document.documentElement.clientHeight
		this.resizeWindow() })
	},
	/*testOrientation() {
  document.getElementById('block_land').style.display = (this.windowWidth>this.windowHeight) ? 'none' : 'block';
}, */
		clearThing() {
		this.signature = null
		},
		fitScreen (){
			this.$nextTick(function(){
			this.windowWidth = 0
			this.windowHeight = 0
			this.canvasWidth = 0
			this.canvasHeight = 0
			this.getWindowWidth()})
		},
		saveData(){
			this.$emit('signatureCapture', this.signature)
		},
		closeModal(){
			this.$emit('closeModalCancel')
		},
    resizeWindow() {
	setTimeout(() => {
	  var ratio =  Math.max(window.devicePixelRatio || 1, 1);
     this.canvasWidth = document.getElementById('modalContainer').offsetWidth;
      this.canvasHeight = document.getElementById('modalContainer').offsetWidth / 2;
		}, 200)
      this.clearThing();
}
}

}
</script>
<style>
div#SigModal > div.modal-dialog {
    width: 95%;
		max-width: 800px;
    margin: 0 auto 0;
}
</style>
<style scoped>

#block_land{position:absolute; top:0; left:0; text-align:center; background:white; width:100%; height:100%; z-index: 9999}
#block_land h3 {
	margin-top: 30%;
	vertical-align: middle;
}
#widthHeightData{
	position: absolute;
}

canvas {
  background: white;
}
.canvasPad {
border: 1px solid;

}
.signature {

  padding: 0;
  font-family: Arial, sans-serif;
}


	/* Smartphones (portrait and landscape) */
@media only screen and (min-width : 320px) and (max-width : 480px) {
/* Styles */
}

/* Smartphones (landscape) */
@media only screen and (min-width : 321px) {


}

/* Smartphones (portrait) */
@media only screen and (max-width : 320px) {


}

/* iPads (portrait and landscape) */
@media only screen and (min-width : 768px) and (max-width : 1024px) {
/* Styles */
#block_land {
	display: none;
	background-color: black;
}
}

/* iPads (landscape) */
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {
/* Styles */
#block_land {
	display: none;
	background-color: black;
}
}

/* iPads (portrait) */
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
/* Styles */
#block_land {
	display: none;
	background-color: black;
}
}

/* Desktops and laptops */
@media only screen and (min-width : 1025px) and (max-width : 1823px) {
	#block_land {
		display: none;
		background-color: black;
	}


}

/* Large screens */
@media only screen and (min-width : 1824px) {
	#block_land {
		display: none;
		background-color: black;
	}

}
/* iPhone 4. Comma can be used to apply same rules to multiple media queries. */
@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
/* Styles */
}

/*iPhone 6 Portrait*/
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation : portrait) {

}

/*iPhone 6 landscape*/
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation : landscape) {
	#block_land {
		display: none;
		background-color: black;
	}
	#SigModal.modal-dialog {
		max-width: 725px;
	}
}

/*iPhone 6+ Portrait*/
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation : portrait) {

}

/*iPhone 6+ landscape*/
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation : landscape) {
	#block_land {
		display: none;
		background-color: black;
	}

}

/*iPhone 6 and iPhone 6+ portrait and landscape*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px){


}

/*iPhone 6 and iPhone 6+ portrait*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px) and (orientation : portrait){

}

/*iPhone 6 and iPhone 6+ landscape*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px) and (orientation : landscape){


}
	</style>
