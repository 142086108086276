<template>
<div>
                <b-container fluid :class="responseView ? 'm-0 p-0': 'm-0 p-0'">
 
                  <b-navbar  class="blogSpanHeader" type="dark" variant="dark" v-if="tempView == true ? false : true">
                     
                    <b-nav-form>
                    
                        <b-navbar-brand class="bootstrapTable">Map</b-navbar-brand>
                        </b-nav-form>
                         <b-navbar-nav class="ml-auto">
                             <b-btn class="mt-1" size="sm"  variant="outline-success" @click="imageType == 'ImageryTransportation' ? imageType = 'Streets' : imageType = 'ImageryTransportation', viewMapBuild()">Type/Refresh</b-btn>
                         
                         </b-navbar-nav>
                         <b-navbar-nav class="ml-auto" v-if="responseView">
                             
                           <b-btn   class="mt-1"  @click="toggleBuildingDataBar">Show Building Data</b-btn>
                         </b-navbar-nav>

                  </b-navbar>
  
                <b-card border-variant="dark" v-if="addMapData" no-body>
                <b-tabs card>
                
                
                  <b-tab title="Hydrants">
                    <b-container v-if="edit">
                    <b-btn @click="getHydrantData" variant="outline-primary" :disabled="!hydrantSearch">Search for Hydrants (Preferred)</b-btn>
                    <b-btn @click="hydrantManual = !hydrantManual, mapExtras()" variant="outline-secondary">Add Hydrants or Drafting Sites Manually</b-btn><br>
                    <span v-if="hydrantPointData.length > 0"> Hydrants Available for Tagging : {{ hydrantPointData.length }}</span>
                    <b-card v-if="hydrantManual">
                        <b-row><b-col lg="6">
                        <b-form-radio-group id="radios1" v-model="hydrantManualObj.type" >
                             <b-form-radio value="primaryHydrant"><img class="iconAddMapp" title="Primary Hydrant" src="/images/preplanicons/hydrant-red-star.png" /></b-form-radio>
                             <b-form-radio value="hydrant"><img class="iconAddMapp" title="Hydrant" src="/images/preplanicons/hydrant-red.png" /></b-form-radio>
                              <b-form-radio value="primaryDraftingSite"><img class="iconAddMapp" title="Drafting Site" src="/images/preplanicons/primary-drafting-site.png" /></b-form-radio>
                             <b-form-radio value="draftingSite"><img class="iconAddMapp" title="Drafting Site" src="/images/preplanicons/draft-site.png" /></b-form-radio>
                        </b-form-radio-group></b-col>
                        <b-col lg="4">
                        <b-form-input v-model="hydrantManualObj.properties.serviceaddress" type="text" label="Location" placeholder="Address or Intersection" /></b-col>
                        <b-col lg="2">
                        <b-form-input v-model="hydrantManualObj.properties.capcolor"  type="text" label="Cap Color" placeholder="Cap Color" /></b-col></b-row>
                         <b-container v-if="!selectedLocation" class="text-danger">Click on the Map Where the Hydrant is Located</b-container>
                         <b-btn @click="saveManualHydrantData" variant="success" size="sm">Save Hydrant</b-btn>
                     </b-card>
                    </b-container>
                     <b-card v-if="hydrantFeatures.length > 0" no-body header="Hydrants Tagged" header-bg-variant="danger" header-text-variant="white" header-class="p-1">
                     <b-table striped small  :fields="hydrantFields" :items="hydrantFeatures" class="mb-0"  >
                       <template slot="address" slot-scope="data">
                         {{ data.item.properties.serviceaddress }}
                       </template>
                      <template slot="cap" slot-scope="data">
                         {{ data.item.properties.capcolor }}
                       </template>
                      <template slot="primary" slot-scope="data">
                       <span v-if="data.item.type == 'primaryHydrant' && !edit" class="text-primary">Yes</span>
                       <span v-if="data.item.type == 'primaryDraftingSite' && edit" class="text-primary">Yes</span>
                       <b-form-checkbox v-if="(data.item.type == 'hydrant' || data.item.type == 'primaryHydrant') && edit" v-model="data.item.type" value="primaryHydrant" unchecked-value="hydrant" @input="viewMap">Make Primary</b-form-checkbox>
                       <b-form-checkbox v-if="(data.item.type == 'draftingSite' || data.item.type == 'primaryDraftingSite') && edit" v-model="data.item.type" value="primaryDraftingSite" unchecked-value="draftingSite" @input="viewMap">Make Primary</b-form-checkbox>
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         <b-btn v-if="!data.item.photoUrl" v-show="edit"  size="sm" variant="outline-primary" @click="openPhotoSelectorModel(data.item.id, 'hydrantFeatures')">Tag a Reference</b-btn>

                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-secondary" size="sm" v-show="!(fpEditId == data.item.id)"  @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'hydrantFeatures')) : fpEditId = null">Edit</b-btn>
                               <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="hydrantFeatures.splice(hydrantFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                              <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove(), viewMap()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                  </b-tab>


                  <b-tab title="Fire Sys">
                        <b-container v-if="edit">
                        <b-row><b-col>
                        <b-form-radio-group id="radios1" v-model="fireProtectionObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in fireProtectionOptions" :key="index" :value="item.value"><img class="iconAddMapp" :title="item.title" :src="item.src" /></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
               <b-container v-if="!selectedLocation && fireProtectionObj.type" class="text-danger">Click on the Map Where the Item is Located</b-container>
                        <b-row align-h="center"><b-col lg="6" align="right"><b-form-input v-model="fireProtectionObj.properties.serviceaddress" type="text" label="Location" placeholder="Relative Location to Building/Interior and/or Information on the item" /></b-col>
                        <b-col lg="2" ><b-btn @click="saveFireProtectionInformation('fireProtectionObj', 'fireprotectionFeatures')" variant="success" size="sm">Save Data</b-btn></b-col>
                        </b-row>
                                          </b-container>
                   <b-card v-if="fireprotectionFeatures.length > 0" no-body header="Fire Protection Info Tagged " header-bg-variant="danger" header-text-variant="white" header-class="p-1">
                  <span v-if="fpEditId" class="text-danger">Change the Data Required, and click on the map to reposition the icon</span>

                   <b-table striped small  :fields="fireProtectionFields" :items="fireprotectionFeatures" class="mb-0" >
                      <template slot="type" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)"> {{ data.item.type }}</span>
                         <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.type">
                            <option v-for="(item, index) in fireProtectionOptions" :key="index" :value="item.value">{{ item.value }}</option>
                           </b-form-select>
                       </template>
                       <template slot="address" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)">{{ data.item.properties.serviceaddress }}</span>
                         <b-form-input v-if="fpEditId == data.item.id" v-model="data.item.properties.serviceaddress" type="text" />
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         <b-btn v-if="!data.item.photoUrl" v-show="edit" variant="outline-primary" size="sm" @click="openPhotoSelectorModel(data.item.id, 'fireprotectionFeatures')">Tag a Reference</b-btn>

                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-danger" size="sm" v-show="!(fpEditId == data.item.id)" :disabled="fpEditId" @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'fireprotectionFeatures')) : fpEditId = null">Edit</b-btn>
                         <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="fireprotectionFeatures.splice(fireprotectionFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                         <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                  </b-tab>


                  <b-tab title="Tactics">
                        <b-container v-if="edit">
                              <b-row><b-col>
                        <b-form-radio-group id="radios12" v-model="tacticsObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in buildingTacticsOptions" :key="index" :value="item.value"><img class="iconAddMapp" :title="item.title" :src="item.src" /></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
                        <b-row><b-col>buildingTacticsOptions
                        <b-form-radio-group id="radios1" v-model="tacticsObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in tacticsOptions" :key="index" :value="item.value"><img class="iconAddMapp" :title="item.title" :src="item.src" /></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
                                               <b-container v-if="!selectedLocation && tacticsObj.type" class="text-danger">Click on the Map Where the Item is Located</b-container>
                        <b-row align-h="center"><b-col lg="6" align="right"><b-form-input v-model="tacticsObj.properties.serviceaddress" type="text" label="Location" placeholder="Location and/or Information on the item" /></b-col>
                        <b-col lg="2" ><b-btn @click="saveFireProtectionInformation('tacticsObj', 'tacticsFeatures')" variant="success" size="sm">Save Data</b-btn></b-col>
                        </b-row>
                                          </b-container>
                  <b-card v-if="tacticsFeatures.length > 0" no-body header="Tactics Info Tagged " header-bg-variant="warning" header-text-variant="white" header-class="p-1">
                  <span v-if="fpEditId" class="text-danger">Change the Data Required, and click on the map to reposition the icon</span>

                   <b-table striped small  :fields="fireProtectionFields" :items="tacticsFeatures" class="mb-0" >
                      <template slot="type" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)"> {{ data.item.type }}</span>
                         <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.type">
                            <option v-for="(item, index) in tacticsOptions" :key="index" :value="item.value">{{ item.value }}</option>
                           </b-form-select>
                       </template>
                       <template slot="address" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)">{{ data.item.properties.serviceaddress }}</span>
                         <b-form-input v-if="fpEditId == data.item.id" v-model="data.item.properties.serviceaddress" type="text" />
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         <b-btn v-if="!data.item.photoUrl" v-show="edit" variant="outline-primary" @click="openPhotoSelectorModel(data.item.id, 'tacticsFeatures')">Tag a Reference</b-btn>

                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-danger" size="sm" v-show="!(fpEditId == data.item.id)" :disabled="fpEditId" @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'tacticsFeatures')) : fpEditId = null">Edit</b-btn>
                         <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="tacticsFeatures.splice(tacticsFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                         <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                  </b-tab>


                <b-tab title="Utilities">
                  <b-container v-if="edit">
                        <b-row><b-col>
                        <b-form-radio-group id="radios1" v-model="utilitiesObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in utilitiesOptions" :key="index" :value="item.value"><img class="iconAddMapp" :title="item.title" :src="item.src" /></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
                                               <b-container v-if="!selectedLocation && utilitiesObj.type" class="text-danger">Click on the Map Where the Utility is Located</b-container>
                        <b-row align-h="center"><b-col lg="6" align="right"><b-form-input v-model="utilitiesObj.properties.serviceaddress" type="text" label="Location" placeholder="Relative Location to Building or Interior" /></b-col>
                        <b-col lg="2" ><b-btn @click="saveFireProtectionInformation('utilitiesObj', 'utilitiesFeatures')" variant="success" size="sm">Save Data</b-btn></b-col>
                        </b-row>
                  </b-container>
                   <b-card v-if="utilitiesFeatures.length > 0" no-body header="Utilities Info Tagged " header-bg-variant="secondary" header-text-variant="white" header-class="p-1">
                  <span v-if="fpEditId" class="text-danger">Change the Data Required, and click on the map to reposition the icon</span>
                   <b-table striped small  :fields="utilitiesFields" :items="utilitiesFeatures" class="mb-0" >
                      <template slot="type" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)"> {{ data.item.type }}</span>
                         <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.type">
                            <option v-for="(item, index) in utilitiesOptions" :key="index" :value="item.value">{{ item.title }}</option>
                           </b-form-select>
                       </template>
                       <template slot="address" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)">{{ data.item.properties.serviceaddress }}</span>
                         <b-form-input v-if="fpEditId == data.item.id" v-model="data.item.properties.serviceaddress" type="text" />
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         
                         <b-btn v-if="!data.item.photoUrl" v-show="edit" variant="outline-primary" @click="openPhotoSelectorModel(data.item.id, 'utilitiesFeatures')">Tag a Reference</b-btn>

                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-secondary" size="sm" v-show="!(fpEditId == data.item.id)" :disabled="fpEditId" @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'utilitiesFeatures')) : fpEditId = null">Edit</b-btn>
                              <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="utilitiesFeatures.splice(utilitiesFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                              <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove(), viewMap()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                    
                  </b-tab>
                </b-tabs>
                </b-card>
      <div id="weathermap"></div></b-container>
      <b-modal v-if="mapModalFeature" v-model="preplanModalShow" :size="modalSize" :hide-footer="true">
          <template slot="modal-header">
          <b-container fluid><b-row><b-col>
            <h5>{{ mapModalFeature.businessName }}</h5>
           {{ mapModalFeature.streetNumberStreetStreetSuffix }}</b-col>
           <b-col cols="auto">
         <b-btn align="right" size="sm" variant="primary" @click="addBuildingDetails(mapModalFeature)">Add Preplan Items to Map</b-btn>
         <b-btn size="sm" variant="danger" @click="preplanModalShow = !preplanModalShow">Close</b-btn></b-col></b-row></b-container>
         </template>
          <b-table stacked :fields="['businessName', 'streetNumberStreetStreetSuffix','updatedAt', 'preplanAuthor', 'primaryStation', 'gridNumber']" :items="[mapModalFeature]" />
      </b-modal>
       <b-modal v-if="mapModalFeature && modalShow" v-model="modalShow" :size="modalSize" :hide-footer="true">
                  <template slot="modal-header">
                    <b-container fluid><b-row><b-col>
           {{ mapModalFeature.properties.serviceaddress }}</b-col>
           <b-col cols="auto">
         <b-btn align="right" size="sm" variant="primary" @click="modalSize == '' ? modalSize = 'lg' : modalSize = ''">{{ modalSize == '' ? 'Maximize' : 'Minimize' }}</b-btn>
         <b-btn size="sm" variant="danger" @click="modalShow = !modalShow">Close</b-btn></b-col></b-row></b-container>
         </template>
               <span class="text-danger" v-if="!mapModalFeature.photoUrl">No Photo Tagged to this item</span>
               <b-btn v-if="edit" @click="removeRefrencePhoto(mapModalFeature)" size="sm" variant="outline-danger">Remove Reference Photo</b-btn>
               <b-img v-if="mapModalFeature.photoUrl" fluid :src="siteconfig.deptStore + mapModalFeature.photoUrl" />
        </b-modal>
        <b-modal v-model="photoModelShow" >
          <b-container>
            <b-card no-body>
              <b-tabs card>
                <b-tab title="unTagged">
            <b-row v-if="refPhotos" v-for="(pic, index) in getRefPhotos(refPhotos, false)" :key="index">
              <b-col cols="auto">
                <b-btn variant="primary" @click="savePhotoReference(pic.url, true)">Save</b-btn>
                </b-col>
                <b-col>
                  <b-img fluid :src="siteconfig.deptStore + pic.url" />
                </b-col>
                </b-row>
                </b-tab>
                <b-tab title="Tagged">
            <b-row v-if="refPhotos" v-for="(pic, index) in getRefPhotos(refPhotos, true)" :key="index">
              <b-col cols="auto">
                <b-btn variant="primary" @click="savePhotoReference(pic.url, true)">Save</b-btn>
                </b-col>
                <b-col>
                                        <p v-for="(details, index2) in pic.type" :key="index2" v-if="details.type">
                            <strong>{{ details.type }}:</strong> {{ details.location }}</p>
                  <b-img fluid :src="siteconfig.deptStore + pic.url" />

                </b-col>
                </b-row>
                  </b-tab>
                  </b-tabs>
                  </b-card>
          </b-container>
        </b-modal>
        <span v-if="edit && dataReady" v-show="false">{{ watchFireProtection }}{{ watchHydrants }}{{ watchUtilities}}{{ watchTactics }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import * as Geocoding from "esri-leaflet-geocoder";
import axios from 'axios'


export default {
  props:["buildingData", 'edit', 'refPhotos', 'primarySketch', 'tempView', 'responseView', 'availablePreplans'], 
  data () {
    return {
        imageType: 'ImageryTransportation',
        mapViewOptions: [
          {text: 'Ariel', value: 'ImageryTransportation'},
          {text: '2D', value: 'Streets'}
        ],
        addMapData: false,
        hydrantManual: false,
        btnVisibilityGroup: [
          {item: 'Hydrants', variant: 'danger', boolean: true, autoOn: false},
          {item: 'Fire Sys', variant: 'danger', boolean: null, autoOn: false},
          {item: 'Tactics', variant: 'warning', boolean: null, autoOn: false},
          {item: 'Sides', variant: 'warning', boolean: true, autoOn: false},
          {item: 'Utilities', variant: 'secondary', boolean: null, autoOn: false}
        ],
        fireProtectionObj: {
          type: '',
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        },
        tacticsObj: {
          type: '',
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        },
        utilitiesObj: {
          type: '',
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        },
        hydrantManualObj: {
          geometry: {type: "Point", coordinates: []},
          properties: {capcolor: '', serviceaddress: ''}
        },
        fireProtectionOptions: [
          {value: 'facp', title: 'Fire Alarm Control Panel', src: './images/preplanicons/facp.png'},
          {value: 'fdc', title: 'Fire Department Connection', src: './images/preplanicons/fdc.png'},
          {value: 'firepump', title: 'Fire Pump', src: './images/preplanicons/fp.png'},
          {value: 'knox', title: 'Knox Box', src: './images/preplanicons/kbk.png'},
          {value: 'standpipe', title: 'Stand Pipe Connection', src: './images/preplanicons/standpipe.png'},
          {value: 'sprinklerriser', title: 'Sprinkler Riser', src: './images/preplanicons/sprinkler-riser.png'}
        ],
        buildingTacticsOptions: [
          {value: 'alpha', title: 'Alpha Side', src: './images/preplanicons/Alpha-Side.png'},
          {value: 'bravo', title: 'Bravo Side', src: './images/preplanicons/Bravo-Side.png'},
          {value: 'charlie', title: 'Charlie Side', src: './images/preplanicons/Charlie-Side.png'},
          {value: 'delta', title: 'Delta Side', src: './images/preplanicons/Delta-Side.png'},
        ],
        tacticsOptions: [
          {value: 'hazmatTank', title: 'Hazmat Tank', src: './images/preplanicons/gas-hazmat.png'},
          {value: 'hazmatLiquid', title: 'Hazmat Liquid', src: './images/preplanicons/hazmat-water.png'},
          {value: 'hazmatBio', title: 'Hazmat Bio Waste', src: './images/preplanicons/hazmat-bio.png'},
          {value: 'egress', title: 'Means of Egress', src: './images/preplanicons/egress.png'},
          {value: 'stairwell', title: 'Stairwell', src: './images/preplanicons/stairs.png'},
          {value: 'stairwellRoofAccess', title: 'Stairwell with Roof Access', src: './images/preplanicons/roof-stairs.png'},
          {value: 'roofAccess', title: 'Roof Access', src: './images/preplanicons/roof.png'}
        ],
        utilitiesOptions: [
          {value: 'electrical', title: 'Electrical Shutoff/Panel', src: './images/preplanicons/electric-shutoff.png'},
          {value: 'gas', title: 'Gas Shut-off', src: './images/preplanicons/gas-shutoff.png'},
          {value: 'water', title: 'Water Shutoff', src: './images/preplanicons/water-shutoff.png'}
        ],
        tempPhotoHoldId: '',
        tempPhotoHoldUrl: '',
        mapModalFeature: '',
        modalShow: false,
        preplanModalShow: false,
        fpEditId: null,
        fireprotectionFeatures: [],
        tacticsFeatures: [],
        utilitiesFeatures: [],
        hydrantSearch: true,
        holdMapObj: {},
        addressData: [],
        modalSize: '',
        photoModelShow: false,
        hydrantFeatures: [],
        map: null,
        hydrantPointData: [],
        mapHold: {},
        layerTempId: '',
        utilitiesLayerGroup: '',
        fireProtectionLayerGroup: '',
        preplansMarkerGroupLayer: '',
        tacticsLayerGroup: '',
        hydrantLayerGroup: '',
        selectedLocation: '',
        dataReady: false,
    }
  },
  created(){
    //console.log(this.buildingData)
  },
  mounted(){
    this.checkBuildingData()

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'preplanStatus',
          'siteconfig'
        ]),
        hydrantFields(){
          var fields = ['address','cap','primary','photo']
          if(this.edit){ fields.push('edit')}
          return fields
         },
        fireProtectionFields(){
          var fields = ['type','address','photo']
          if(this.edit){ fields.push('edit')}
          return fields
        },
        utilitiesFields(){
          var fields = ['type','address','photo']
          if(this.edit){ fields.push('edit')}
          return fields
        },
        watchHydrants(){
          var object = {type: 'hydrants', data: this.hydrantFeatures}
          this.$emit('updateFeatureData', object)
          return this.hydrantFeatures
        },
       watchTactics(){
          var object = {type: 'tactics', data: this.tacticsFeatures}
          this.$emit('updateFeatureData', object)
          return this.tacticsFeatures
        },
        watchFireProtection(){
          var object = {type: 'fireProtection', data: this.fireprotectionFeatures}
          this.$emit('updateFeatureData', object)
          return this.fireprotectionFeatures
        },
        watchUtilities(){
          var object = {type: 'utilities', data: this.utilitiesFeatures}
          this.$emit('updateFeatureData', object)
          return this.utilitiesFeatures
        }
    },
    watch: {
      availablePreplans(newData){
        this.viewMap()
      }
      },
  beforeDestroy(){

  },
  methods:{
    addBuildingDetails(data){
      axios({
        method: "get",
        url: "/api/v1/content/preplan/" + data.id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.addMapFeatures(response.data)
      });
    },
    addMapFeatures(data){
      var features = data[0].preplanBuildingData
      if(features){
        var array = [{data: 'hydrants', local: 'hydrantFeatures'}, {data: 'fireProtection', local: 'fireprotectionFeatures'}, {data: 'tactics', local: 'tacticsFeatures'}, {data: 'utilities', local: 'utilitiesFeatures'}]
        for(var i in array){
        if(features[array[i].data]){
          var items = features[array[i].data]
          for(var p in items){
            var index = this[array[i].local].indexOf(items[p].id)
            if(index < 0){
              this[array[i].local].push(items[p])
            }
          }
        }
        }
      }
      this.viewMap()
    },
    toggleBuildingDataBar(){
      this.$emit('toggleBuildingDataBar')
    },
    checkBuildingData(){

      this.viewMapBuild()
      this.dataReady = true
  
    
    },
    saveFireProtectionInformation(obj, featureArray){
    var fpdata = this[obj]
    var data = this.selectedLocation
    var id = fpdata.type
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    fpdata['id'] = id
    fpdata.geometry.coordinates = [data.lng,data.lat]
    this[featureArray].push(fpdata)
    this.$nextTick(function(){
      this[obj] = {
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        }
      var map = this.mapHold
      map.removeLayer(this.layerTempId)
      map.off('click')
      this.selectedLocation = ''
      this.viewMap()
    })

    },
    saveManualHydrantData(){
    var hydrant = this.hydrantManualObj
    var data = this.selectedLocation
    hydrant.geometry.coordinates = [data.lng,data.lat]
    this.hydrantFeatures.push(hydrant)
    this.$nextTick(function(){
      this.hydrantManualObj = {
          geometry: {type: "Point", coordinates: []},
          properties: {capcolor: '', serviceaddress: ''}
        }
      var map = this.mapHold
      map.removeLayer(this.layerTempId)
      map.off('click')
      this.selectedLocation = ''
      this.hydrantManual = false
      this.viewMap()
    })

    },
    mouseClickEvent(data){
    this.selectedLocation = data
    var map = this.mapHold
    if(this.layerTempId){
          map.removeLayer(this.layerTempId)
    }      
    var markerGroup = Leaf.layerGroup().addTo(map)
    var layerId = Leaf.marker([data.lat,data.lng]).addTo(markerGroup)
    this.layerTempId = markerGroup
    },
    setBuildingData(data){
      this.buildingData = data
    },
    getHydrantData(){
      var data = this.buildingData.geometry
      var point = this.buildingData
      this.hydrantPointData = []
      this.hydrantSearch = false
       axios({
         method: 'get',
         url: 'https://ags3.scgov.net/server/rest/services/Hosted/wHydrant/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&distance=400&units=esriSRUnit_Meter&outSR=4326&f=geojson',
       //  url: 'https://ags3.scgov.net/server/rest/services/Hosted/wHydrant/FeatureServer/0/query?returnGeometry=true&where=1%3D1&outSr=4326&outFields=*&inSr=4326&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometryPrecision=6&distance=300&unit=meters&SR=4326&resultRecordCount=100&f=geojson',
         headers: {
          'Content-Type' : 'application/json',
        } 
       }).then((response) => {
         var availableHydrants = response.data.features
         this.getMuncipleHydrants(availableHydrants)

    })
    },
    getMuncipleHydrants(county){
      var data = this.buildingData.geometry
            var point = this.buildingData
        axios({
         method: 'get',
         url: 'https://ags3.scgov.net/server/rest/services/Hosted/wMunicipalHydrant/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&distance=300&units=esriSRUnit_Meter&outSR=4326&f=geojson',
         //url: 'https://ags3.scgov.net/server/rest/services/Hosted/wMunicipalHydrant/FeatureServer/0/query?returnGeometry=true&where=1%3D1&outSr=4326&outFields=*&inSr=4326&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometryPrecision=6&distance=300&unit=meters&SR=4326&resultRecordCount=100&f=geojson',
         headers: {
          'Content-Type' : 'application/json',
        } 
       }).then((response) => {
         var availableHydrants = response.data.features

         if(this.hydrantFeatures){
           var arrayofids = this.hydrantFeatures.map(p => p.id)

           for(var i in arrayofids){
             var index = availableHydrants.map(p => p.id).indexOf(arrayofids[i])

             var index2 = county.map(p => p.id).indexOf(arrayofids[i])

             if(index >= 0){
               availableHydrants.splice(index, 1)
             }
             if(index2 >= 0){
               county.splice(index, 1)
             }
           }
           this.hydrantPointData = county.concat(availableHydrants)
         } else {
         this.hydrantPointData = county.concat(availableHydrants)
         }
         this.hydrantSearch = true
                this.viewMap(data, point, this.mapHold)
       })
    },
    viewMapBuild(data, point){
      this.showMap = true
      this.$nextTick(function(){
        this.setMap(data, point)
      })
    },
    setMap(data, point){
         if(data || point){
        this.holdMapObj = {data: data, point: point}
        var zoom = 18
        } else if(this.holdMapObj.data) {
          var data = this.holdMapObj.data
          var point = this.holdMapObj.point
          var zoom = 18
        } else if (this.siteconfig.preplans && this.siteconfig.preplans.centermaplocation) { 
          var data = this.siteconfig.preplans.centermaplocation
          var point = data
          var zoom = 14
        } else {
          var data = {x: -82.50774997963934, y: 27.29843404770804}
          var point = data
          var zoom = 14
        }
          var center = [data.y, data.x]

        var imageType = this.imageType
        if(this.responseView){
        document.getElementById('weathermap').style.height = document.documentElement.clientHeight - 63 + 'px'
        } else {
        document.getElementById('weathermap').style.height = '700px'
        }
        document.getElementById('weathermap').innerHTML = "<div id='mapSpot' style='width: 100%; height: 100%;'></div>";
        var map = Leaf.map('mapSpot').setView(center, zoom);

        /* if(imageType == 'ImageryTransportation'){
          var imageType = 'Imagery'
          var overlay = 'ImageryTransportation'
           esri.basemapLayer(imageType).addTo(map);
           esri.basemapLayer('ImageryTransportation').addTo(map);
        } else {
            esri.basemapLayer(imageType).addTo(map);
        }      
        esri.basemapLayer(imageType).addTo(map); */
        if(imageType == 'ImageryTransportation'){
        esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer',
            maxZoom: 24,
            maxNativeZoom: 20
        }).addTo(map)
            esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Transportation/MapServer',
            maxZoom: 24,
            maxNativeZoom: 19
        }).addTo(map)
        } else {
        esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer',
            maxZoom: 24,
            maxNativeZoom: 19
        }).addTo(map)
        }
        this.mapHold = map
        this.viewMap(data, point, map)
    },
    viewMap(data, point, map){
        if(!data || !point || !map){
          var data = this.holdMapObj.data
          var point = this.holdMapObj.point
          var map = this.mapHold
        }
        map.off('zoomend')
        var primaryBuilding = Leaf.icon({ iconUrl: './images/preplanicons/red-fire-icon.png', iconSize: [70, 70]})
        var greyHydrant = Leaf.icon({ iconUrl: './images/preplanicons/hydrant-grey.png', iconSize: [40, 40]})
        var highlightIcon = Leaf.divIcon({className: 'css-icon', html: '<div class="gps_ring"></div>', iconSize: [40, 40]});
        var hydrant = Leaf.icon({ iconUrl: './images/preplanicons/hydrant-red.png', iconSize: [40, 40]})
        var primaryHydrant = Leaf.icon({ iconUrl: './images/preplanicons/hydrant-red-star.png', iconSize: [40, 40]})
        var draftingSite = Leaf.icon({ iconUrl: './images/preplanicons/draft-site.png', iconSize: [40, 40]})
        var primaryDraftingSite = Leaf.icon({ iconUrl: './images/preplanicons/primary-drafting-site.png', iconSize: [40, 40]})
        var facp = Leaf.icon({ iconUrl: './images/preplanicons/facp.png', iconSize: [40, 40]})
        var fdc = Leaf.icon({ iconUrl: './images/preplanicons/fdc.png', iconSize: [40, 40]})
        var firepump = Leaf.icon({ iconUrl: './images/preplanicons/fp.png', iconSize: [40, 40]})
        var knox = Leaf.icon({ iconUrl: './images/preplanicons/kbk.png', iconSize: [40, 40]})
        var standpipe = Leaf.icon({ iconUrl: './images/preplanicons/standpipe.png', iconSize: [40, 40]})
        var sprinklerriser = Leaf.icon({ iconUrl: './images/preplanicons/sprinkler-riser.png', iconSize: [40, 40]})
        var electrical = Leaf.icon({ iconUrl: './images/preplanicons/electric-shutoff.png', iconSize: [40, 40]})
        var gas = Leaf.icon({ iconUrl: './images/preplanicons/gas-shutoff.png', iconSize: [40, 40]})
        var water = Leaf.icon({ iconUrl: './images/preplanicons/water-shutoff.png', iconSize: [40, 40]})               
        var hazmatTank = Leaf.icon({ iconUrl: './images/preplanicons/gas-hazmat.png', iconSize: [40, 40]})
        var hazmatLiquid = Leaf.icon({ iconUrl: './images/preplanicons/hazmat-water.png', iconSize: [40, 40]})
        var hazmatBio = Leaf.icon({ iconUrl: './images/preplanicons/hazmat-bio.png', iconSize: [40, 40]})
        var egress = Leaf.icon({ iconUrl: './images/preplanicons/egress.png', iconSize: [40, 40]})
        var stairwell = Leaf.icon({ iconUrl: './images/preplanicons/stairs.png', iconSize: [40, 40]})
        var stairwellRoofAccess = Leaf.icon({ iconUrl: './images/preplanicons/roof-stairs.png', iconSize: [40, 40]})
        var roofAccess = Leaf.icon({ iconUrl: './images/preplanicons/roof.png', iconSize: [40, 40]})
        var alpha = Leaf.icon({ iconUrl: './images/preplanicons/Alpha-Side.png', iconSize: [80, 31]})
        var bravo = Leaf.icon({ iconUrl: './images/preplanicons/Bravo-Side.png', iconSize: [80, 31]})
        var charlie = Leaf.icon({ iconUrl: './images/preplanicons/Charlie-Side.png', iconSize: [80, 31]})
        var delta = Leaf.icon({ iconUrl: './images/preplanicons/Delta-Side.png', iconSize: [80, 31]})
            // create the geocoding control and add it to the map
       /*     var geo = Geocoding.geosearch()
            var searchControl = esri.geo.addTo(map);

            // create an empty layer group to store the results and add it to the map
            var results = Leaf.layerGroup().addTo(map);

            // listen for the results event and add every result to the map
            searchControl.on("results", function(data) {
                results.clearLayers();
                for (var i = data.results.length - 1; i >= 0; i--) {
                    results.addLayer(Leaf.marker(data.results[i].latlng));
                }}) */
            
        if(this.hydrantPointData){
          var vm = this
          var hyAvail = this.hydrantPointData
          var markerArray = []
          var highlightArray = []
          for(var i in hyAvail){
             markerArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: greyHydrant}))
             highlightArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { icon: highlightIcon, zIndexOffset: -200}))
          }
          Leaf.featureGroup(markerArray).on('click', function (layer) {
              vm.hydrantPush(layer.propagatedFrom.options.feature, data, point, map)
              }).addTo(map)
          Leaf.featureGroup(highlightArray).addTo(map)
          
        }
        if(this.fireprotectionFeatures){
          var vm = this
          var hyAvail = this.fireprotectionFeatures
          var fpArray = []
          if(this.fireProtectionLayerGroup){
            map.removeLayer(this.fireProtectionLayerGroup)
          }
          for(var i in hyAvail){
             fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
          }
          var fpResponseGroup = new Leaf.featureGroup(fpArray)
          this.fireProtectionLayerGroup = fpResponseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Fire Sys')
          if(this.addMapData){
            fpResponseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, fpResponseGroup, map, 'fireprotectionFeatures')
          }
        }
        if(this.tacticsFeatures){
          var vm = this
          var hyAvail = this.tacticsFeatures
          var fpArray = []
          if(this.tacticsLayerGroup){
            map.removeLayer(this.tacticsLayerGroup)
          }
          for(var i in hyAvail){
             if(hyAvail[i].type != 'alpha' || hyAvail[i].type != 'bravo' || hyAvail[i].type != 'charlie' || hyAvail[i].type != 'delta'){
             fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
             }
          }
          var fpResponseGroup = new Leaf.featureGroup(fpArray)
          this.tacticsLayerGroup = fpResponseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Tactics')
          if(this.addMapData){
            fpResponseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, fpResponseGroup, map, 'tacticsFeatures')
          }
        }
        if(this.tacticsFeatures){
          var vm = this
          var hyAvail = this.tacticsFeatures
          var fpArray = []
          if(this.tacticsBuildingLayerGroup){
            map.removeLayer(this.tacticsBuildingLayerGroup)
          }
          for(var i in hyAvail){
             if(hyAvail[i].type == 'alpha' || hyAvail[i].type == 'bravo' || hyAvail[i].type == 'charlie' || hyAvail[i].type == 'delta'){
             fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
             }
          }
          var fpResponseGroup = new Leaf.featureGroup(fpArray)
          this.tacticsBuildingLayerGroup = fpResponseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Sides')
          if(this.addMapData){
            fpResponseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, fpResponseGroup, map, 'tacticsBuildingSidesFeatures')
          }
        }
        if(this.utilitiesFeatures){
          var vm = this
          var hyAvail = this.utilitiesFeatures
          var utilitieArray = []
          if(this.utilitiesLayerGroup){
            map.removeLayer(this.utilitiesLayerGroup)
          }
          for(var i in hyAvail){
             utilitieArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
          }
          var responseGroup = new Leaf.featureGroup(utilitieArray)
          this.utilitiesLayerGroup = responseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Utilities')
          if(this.addMapData){
            responseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, responseGroup, map, 'utilitiesFeatures')
          }
        }
        if(this.hydrantFeatures){
          var vm = this
          var hyAvail = this.hydrantFeatures
         if(this.hydrantLayerGroup){
            map.removeLayer(this.hydrantLayerGroup)
          }
          var markerArray = []
          for(var i in hyAvail){
             markerArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
          }
          var hydrantGroup = new Leaf.featureGroup(markerArray)
          this.hydrantLayerGroup = hydrantGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Hydrants')
          if(this.addMapData){
          hydrantGroup.on('click', function (layer) {
              vm.hydrantRemove(layer.propagatedFrom.options.feature, data, point, map)
              }).addTo(map)
          } else {
             this.mapIconBuildOut(boolean, hydrantGroup, map, 'hydrantFeatures')
          }
        }
        if(this.availablePreplans){
          if(this.preplansMarkerGroupLayer){
            map.removeLayer(this.preplansMarkerGroupLayer)
          }
          var avail = this.availablePreplans
          var preplans = []
          var myRender = Leaf.canvas({ padding: 0.5})
          for(var i = 0; i < avail.length; i++){
            if(avail[i].map){
              var color = avail[i].transport ? '#007bff' : '#e2070d'
              preplans.push(Leaf.circleMarker(avail[i].map, { feature: avail[i], renderer: myRender, color: color, radius: 4}))
            } else {
             /* var holdMapData = new Leaf.LatLng(
                  southWest.lat + latSpan * Math.random(),
                  southWest.lng + lngSpan * Math.random());
                   preplans.push(Leaf.marker(holdMapData, { feature: avail[i]})) */
            }
     
            // 
          }
          var preplansMarkerGroup = new Leaf.featureGroup(preplans)
          this.preplansMarkerGroupLayer = preplansMarkerGroup
          preplansMarkerGroup.addTo(map)
          preplansMarkerGroup.on('click', function (layer) {
              var obj = layer.propagatedFrom.options.feature
              vm.modalSize = 'lg'
              vm.mapModalFeature = obj
              vm.preplanModalShow = true
        })
        }
        this.$nextTick(function(){
      /*  var primaryMarker = []
     primaryMarker.push(Leaf.marker([data.y,data.x], { icon: primaryBuilding, zIndexOffset: 1000 }))
        var primaryMarkerGroup = new Leaf.featureGroup(primaryMarker)
        if(this.primarySketch){
              primaryMarkerGroup.on('click', function (layer) {
              var obj = {}
              obj['properties'] = {}
              obj.properties['serviceaddress'] = 'PRIMARY BUILDING SKETCH'
              obj['photoUrl'] = vm.primarySketch
              obj['large'] = true
              vm.mapModalFeature = obj
              vm.modalShow = true
              })
        } //<latitude>27.298434</latitude><longitude>-82.50775</longitude><latitude>27.340114</latitude><longitude>-82.49708</longitude>
        primaryMarkerGroup.addTo(map) 
        */
       /*
        DISPATCH CIRCLE 
       var currentZoom = map.getZoom()
                  var times = 25 - currentZoom
          var radiusSet = 1
          for(var i = 0; i < times; i++){
            radiusSet = radiusSet * 2
          }
        var dispatchCircle = Leaf.circle([27.298434, -82.50775], {radius: radiusSet, fill: false, color: '#dc3545'}).addTo(map)
        map.on('zoomend', function(e){
          var currentZoom = map.getZoom()
          var times = 25 - currentZoom
          var radiusSet = 1
          for(var i = 0; i < times; i++){
            radiusSet = radiusSet * 2
          }
          dispatchCircle.setRadius(radiusSet)
        })
        */
        }) 

    },
    mapIconBuildOut(boolean, fpResponseGroup, map, feature){
          var vm = this
          var zoom = map.getZoom()
       //   console.log(boolean.item + ' Zoom ' + zoom)
          if(boolean.boolean){
              if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
             fpResponseGroup.addTo(map)
             boolean.autoOn = false
          } else if(boolean.boolean == false){
             boolean.autoOn = false
          
              map.removeLayer(fpResponseGroup)
          } else if (boolean.boolean == null){
      //     console.log(boolean.item + ' Null ' + zoom)
          if(map.getZoom() >= 20){
        //    console.log(boolean.item + ' Map ' + zoom)
          if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
            fpResponseGroup.addTo(map);
          }
          map.on('zoomend', function() {
          if (map.getZoom() <20 && !boolean.boolean){
                    boolean.autoOn = false
                
                    map.removeLayer(fpResponseGroup);
       //              console.log(boolean.item + ' Click 1 ' + zoom)
            }
          else if (map.getZoom() >=20 && boolean.boolean && !boolean.autoOn){
        //        console.log(boolean.item + ' Click 2 ' + zoom)
                      if(!this.edit){
                    fpResponseGroup.on('click', function (layer) {
                    vm.mapModalFeature = layer.propagatedFrom.options.feature
                    vm.modalShow = true
                    })}
                   fpResponseGroup.addTo(map);
          } else if (map.getZoom() >=20 && (boolean.boolean == null)){
  //    console.log(boolean.item + ' Click 3 ' + zoom)
                  boolean.autoOn = true
                  if(!this.edit){
                    fpResponseGroup.on('click', function (layer) {
                    vm.mapModalFeature = layer.propagatedFrom.options.feature
                    vm.modalShow = true
                    })}
                  fpResponseGroup.addTo(map);
          } else if (map.getZoom() <20 && boolean.boolean){
              if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
            //           console.log(boolean.item + ' Click 4 ' + zoom)
             fpResponseGroup.addTo(map);
            }else {
        //    console.log('ELSE')
          }
        });
        }
    },
    hydrantPush(hydr, mapdata, point, map){
      var data = hydr
      data['type'] = 'hydrant'
      this.hydrantFeatures.push(data)
      this.hydrantPointData.splice(this.hydrantPointData.map(p => p.id).indexOf(hydr.id), 1)
      this.viewMap(mapdata, point, map)
    },
    hydrantRemove(hydr, mapdata, point, map){
      var data = hydr
      data['type'] = 'feature'
      this.hydrantPointData.push(data)
      this.hydrantFeatures.splice(this.hydrantFeatures.map(p => p.id).indexOf(hydr.id), 1)
      this.viewMap(mapdata, point, map)
    },
    updateCoordinates(data, type, id){
      var ref = this[type]
      var item = ref[id]
      item.geometry.coordinates = [data.lng,data.lat]
      this.viewMap()
    },
    mapEditRemove(){
      var map = this.mapHold
         map.off('click')
    },
    getRefPhotos(data, type){
      
      if(type && data){
        return data.filter(p => {
          if(p.type && p.type.length > 0){
            return p
          }
        })
      } else if(data) {
        return data.filter(p => {
          if(p.type && p.type.length <= 0){
            return p
          } else if (!p.type){
            return p
          }
        })
      }

    },
    removeRefrencePhoto(data){
      var photoIndex = this.refPhotos.map(p => p.url).indexOf(data.photoUrl)
      var emitObj = {item: data, photoIndex: photoIndex}
        this.$emit('photoArrayUpdate', emitObj)
        data.photoUrl = ''
        this.mapModalFeature = ''
        this.modalShow = false
    },
    openPhotoSelectorModel(data, type){
      this.tempPhotoHoldId = {id: data, type: type}
      this.photoModelShow = true
    },
    savePhotoReference(url, add){
      var obj = this.tempPhotoHoldId
      var ref = this[obj.type]
      var item = ref.find(p => p.id == obj.id)
      item['photoUrl'] = url
      var emitObj = {item: item, photoIndex: this.refPhotos.map(p => p.url).indexOf(url)}
      if(add){
        emitObj['add'] = true
      }
      this.$emit('photoArrayUpdate', emitObj)
       this.photoModelShow = false
    },
    mapEdit(data, type){
        var ref = this[type]
        var index = ref.map(p => p.id).indexOf(data)
        var map = this.mapHold
        var vm = this
        map.on('click', function(ev) {
          vm.updateCoordinates(ev.latlng, type, index); // ev is an event object (MouseEvent in this case)
        });
    },

    mapExtras(){
    
          var map = this.mapHold
        var vm = this
        map.on('click', function(ev) {
            vm.mouseClickEvent(ev.latlng); // ev is an event object (MouseEvent in this case)
        });

  },
   

}
}

</script>
<style>
	.css-icon {

	}

	.gps_ring {	
		border: 3px solid #e2070d;
		 -webkit-border-radius: 60px;
		 height: 40px;
		 width: 40px;		
	    -webkit-animation: pulsate 1s ease-out;
	    -webkit-animation-iteration-count: infinite; 
	    /*opacity: 0.0*/
	}
	
	@-webkit-keyframes pulsate {
		    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
		    50% {opacity: 1.0;}
		    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
	}
</style>
<style scoped>
.leaflet-marker-icon.pulse {
      box-shadow: 1px 1px white;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>

.iconAddMapp {
  width: 40px;
  height: auto;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
