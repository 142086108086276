<template>
<div>
                <b-container fluid :class="responseView ? 'm-0 p-0': 'px-1 px-lg-4'">
 
                  <b-navbar   toggleable="lg" class="blogSpanHeader" type="dark" variant="dark" v-if="tempView == true ? false : true">
                                             <b-navbar-brand class="bootstrapTable" href="#">Map</b-navbar-brand>
                        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                        <b-collapse id="nav-collapse" is-nav>
                    <b-nav-form>
                    

                        <b-btn class="mt-1" size="sm" @click="edit ? (addMapData = !addMapData, viewMap()) :  addMapData = !addMapData" :variant="!addMapData ? 'outline-light' : 'light'">
                          {{ !addMapData && edit ? 'Add Data (off)' : addMapData && edit ? 'Add Data (on)' : !addMapData ? 'Details (off)' : 'Details (on)' }}</b-btn>
                        <b-btn class="ml-3 mt-1" 
                               size="sm" v-for="(item, index) in btnVisibilityGroup" 
                               :disabled="addMapData"
                               v-show="!item.editOnly ? true : item.editOnly && !edit ? false : true"
                               @click="item.boolean == null ? item.boolean = true : item.boolean == false ? (item.boolean = null, autoOn = false ): item.boolean = false, viewMap()" :variant="item.boolean ? item.variant : !item.boolean && !item.autoOn ? 'outline-' + item.variant : item.variant" :key="index"
                               >{{ item.boolean == null ? item.item + ' (auto)' : item.boolean ? item.item + ' (on)' : item.item + ' (off)' }}</b-btn>
                    
                        </b-nav-form>
                         <b-navbar-nav class="ml-auto">
                             <b-btn v-if="edit && (userData.role == 'supervisor' || userData.role == 'fireops' || userData.role == 'adminchief' || userData.role == 'admin')" class="mt-1" size="sm" variant="outline-danger" @click="updateBuildingLocation">Move Building Loc</b-btn>
                             <b-btn class="mt-1" size="sm" variant="outline-success" @click="imageType == 'ImageryTransportation' ? imageType = 'Streets' : imageType = 'ImageryTransportation', viewMapBuild()">Type/Refresh</b-btn>
                         
                         </b-navbar-nav>
                         <b-navbar-nav class="ml-auto" v-if="responseView">
                             
                           <b-btn   class="mt-1" size="sm"  @click="toggleBuildingDataBar">Building Data (off)</b-btn>
                         </b-navbar-nav>
                          </b-collapse>
                  </b-navbar>
  
                <b-card border-variant="dark" v-if="addMapData" no-body>
                <b-tabs card>
                  <b-tab title="Hydrants">
                    <b-container v-if="edit">
                    <b-btn @click="getHydrantData" v-if="hydrantPointData.length == 0" variant="outline-primary" :disabled="!hydrantSearch">Search for Hydrants (Preferred)</b-btn>
                   <b-btn @click="clearHydrantSearchData" v-if="hydrantPointData.length > 0" variant="outline-danger" :disabled="!hydrantSearch">Clear Unused Hydrants</b-btn>
                    <b-btn @click="hydrantManual = !hydrantManual, mapExtras()" variant="outline-secondary">Add Hydrants or Drafting Sites Manually</b-btn><br>
                    <span v-if="hydrantPointData.length > 0"> Hydrants Available for Tagging : {{ hydrantPointData.length }}</span>
                    <b-card v-if="hydrantManual">
                        <b-row><b-col lg="6">
                        <b-form-radio-group id="radios1" v-model="hydrantManualObj.type" >
                             <b-form-radio value="primaryHydrant"><img class="iconAddMapp" title="Primary Hydrant" src="/images/preplanicons/hydrant-red-star.png" /></b-form-radio>
                             <b-form-radio value="hydrant"><img class="iconAddMapp" title="Hydrant" src="/images/preplanicons/hydrant-red.png" /></b-form-radio>
                              <b-form-radio value="primaryDraftingSite"><img class="iconAddMapp" title="Drafting Site" src="/images/preplanicons/primary-drafting-site.png" /></b-form-radio>
                             <b-form-radio value="draftingSite"><img class="iconAddMapp" title="Drafting Site" src="/images/preplanicons/draft-site.png" /></b-form-radio>
                        </b-form-radio-group></b-col>
                        <b-col lg="4">
                        <b-form-input v-model="hydrantManualObj.properties.serviceaddress" type="text" label="Location" placeholder="Address or Intersection" /></b-col>
                        <b-col lg="2">
                        <b-form-input v-model="hydrantManualObj.properties.capcolor"  type="text" label="Cap Color" placeholder="Cap Color" /></b-col></b-row>
                         <b-container v-if="!selectedLocation" class="text-danger">Click on the Map Where the Hydrant is Located</b-container>
                         <b-btn :disabled="!selectedLocation ? true : false" @click="saveManualHydrantData" variant="success" size="sm">Save Hydrant</b-btn>
                     </b-card>
                    </b-container>
                     <b-card v-if="hydrantFeatures.length > 0" no-body header="Hydrants Tagged" header-bg-variant="danger" header-text-variant="white" header-class="p-1">
                     <b-table striped small  :fields="hydrantFields" :items="hydrantFeatures" class="mb-0"  stacked="md" >
                       <template slot="address" slot-scope="data">
                         {{ data.item.properties.serviceaddress }}
                       </template>
                      <template slot="cap" slot-scope="data">
                        <span v-if="fpEditId != data.item.id" > {{ data.item.properties.capcolor }} </span>
                        <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.properties.capcolor" :options="capcolorOptions" />
                       </template>
                      <template slot="primary" slot-scope="data">
                       <span v-if="data.item.type == 'primaryHydrant' && !edit" class="text-primary">Yes</span>
                       <span v-if="data.item.type == 'primaryDraftingSite' && edit" class="text-primary">Yes</span>
                       <b-form-checkbox v-if="(data.item.type == 'hydrant' || data.item.type == 'primaryHydrant') && edit" v-model="data.item.type" value="primaryHydrant" unchecked-value="hydrant" @input="viewMap">Make Primary</b-form-checkbox>
                       <b-form-checkbox v-if="(data.item.type == 'draftingSite' || data.item.type == 'primaryDraftingSite') && edit" v-model="data.item.type" value="primaryDraftingSite" unchecked-value="draftingSite" @input="viewMap">Make Primary</b-form-checkbox>
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         <b-btn v-if="!data.item.photoUrl" v-show="edit"  size="sm" variant="outline-primary" @click="openPhotoSelectorModel(data.item.id, 'hydrantFeatures')">Tag a Reference</b-btn>
                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-secondary" size="sm" v-show="!(fpEditId == data.item.id)"  @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'hydrantFeatures')) : fpEditId = null">Edit</b-btn>
                               <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="hydrantFeatures.splice(hydrantFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                              <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove(), viewMap()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                  </b-tab>


                  <b-tab title="Fire Sys">
                        <b-container v-if="edit">
                        <b-row><b-col>
                        <b-form-radio-group id="radios1" v-model="fireProtectionObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in fireProtectionOptions" :key="index" :value="item.value">
                               <img v-if="item.src" class="iconAddMapp" :title="item.title" :src="item.src" />
                               <div v-if="item.value == 'fireProtectionLabel'" class="fireProtectionLabel optionLabelSelect">
                                  Text
                               </div>
                               </b-form-radio>
                        </b-form-radio-group></b-col></b-row>
               <b-container v-if="!selectedLocation && fireProtectionObj.type" class="text-danger">Click on the Map Where the Item is Located</b-container>
                        <b-row align-h="center"><b-col lg="6" align="right"><b-form-input v-model="fireProtectionObj.properties.serviceaddress" type="text" label="Location" placeholder="Relative Location to Building/Interior and/or Information on the item" /></b-col>
                        <b-col lg="2" ><b-btn :disabled="!selectedLocation ? true : false" @click="saveFireProtectionInformation('fireProtectionObj', 'fireprotectionFeatures')" variant="success" size="sm">Save Data</b-btn></b-col>
                        </b-row>
                                          </b-container>
                   <b-card v-if="fireprotectionFeatures.length > 0" no-body header="Fire Protection Info Tagged " header-bg-variant="danger" header-text-variant="white" header-class="p-1">
                  <span v-if="fpEditId" class="text-danger">Change the Data Required, and click on the map to reposition the icon</span>

                   <b-table striped small  :fields="fireProtectionFields" :items="fireprotectionFeatures" class="mb-0" stacked="md">
                      <template slot="type" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)"> {{ data.item.type }}</span>
                         <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.type">
                            <option v-for="(item, index) in fireProtectionOptions" :key="index" :value="item.value">{{ item.value }}</option>
                           </b-form-select>
                       </template>
                       <template slot="address" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)">{{ data.item.properties.serviceaddress }}</span>
                         <b-form-input v-if="fpEditId == data.item.id" v-model="data.item.properties.serviceaddress" type="text" />
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         <b-btn v-if="!data.item.photoUrl" v-show="edit" variant="outline-primary" size="sm" @click="openPhotoSelectorModel(data.item.id, 'fireprotectionFeatures')">Tag a Reference</b-btn>
                      </template>
                      <template slot="business" slot-scope="data">
                         <b-btn v-if="data.item.businessIds" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="openBusinessSelectorModel(data.item.id, 'fireprotectionFeatures')" >View Business</b-btn>
                         <b-btn v-if="!data.item.businessIds" v-show="edit" variant="outline-primary" size="sm" @click="openBusinessSelectorModel(data.item.id, 'fireprotectionFeatures')">Tag Business</b-btn>
                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-danger" size="sm" v-show="!(fpEditId == data.item.id)" :disabled="fpEditId == null ? false : true" @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'fireprotectionFeatures')) : fpEditId = null">Edit</b-btn>
                         <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="fireprotectionFeatures.splice(fireprotectionFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                         <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove(), viewMap()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                  </b-tab>


                  <b-tab title="Tactics">
                        <b-container v-if="edit">
                              <b-row><b-col>
                        <b-form-radio-group id="radios12" v-model="tacticsObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in buildingTacticsOptions" :key="index" :value="item.value">
                              <img v-if="item.src" class="iconAddMapp" :title="item.title" :src="item.src" />
                               </b-form-radio>
                        </b-form-radio-group></b-col></b-row>
                        <b-row><b-col>
                        <b-form-radio-group id="radios1" v-model="tacticsObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in tacticsOptions" :key="index" :value="item.value">
                               <img v-if="item.src" class="iconAddMapp" :title="item.title" :src="item.src" />
                              <div v-if="item.value == 'hazmatLabel' || item.value == 'locationLabel'" :class="item.value + ' optionLabelSelect'">
                                  Text
                               </div></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
                         <b-container v-if="!selectedLocation && tacticsObj.type" class="text-danger">Click on the Map Where the Item is Located</b-container>
                        <b-row align-h="center"><b-col lg="6" align="right"><b-form-input v-model="tacticsObj.properties.serviceaddress" type="text" label="Location" placeholder="Location and/or Information on the item" /></b-col>
                        <b-col lg="2" ><b-btn :disabled="!selectedLocation ? true : false" @click="saveFireProtectionInformation('tacticsObj', 'tacticsFeatures'), viewMap(), fpEditId = null" variant="success" size="sm">Save Data</b-btn></b-col>
                        </b-row>
                                          </b-container>
                  <b-card v-if="tacticsFeatures.length > 0" no-body header="Tactics Info Tagged " header-bg-variant="warning" header-text-variant="white" header-class="p-1">
                  <span v-if="fpEditId" class="text-danger">Change the Data Required, and click on the map to reposition the icon</span>

                   <b-table striped small  :fields="fireProtectionFields" :items="tacticsFeatures" class="mb-0"  stacked="md">
                      <template slot="type" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)"> {{ data.item.type }}</span>
                         <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.type">
                            <option v-for="(item, index) in tacticsOptions" :key="index" :value="item.value">{{ item.value }}</option>
                           </b-form-select>
                       </template>
                       <template slot="address" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)">{{ data.item.properties.serviceaddress }}</span>
                         <b-form-input v-if="fpEditId == data.item.id" v-model="data.item.properties.serviceaddress" type="text" />
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         <b-btn v-if="!data.item.photoUrl" v-show="edit" variant="outline-primary" @click="openPhotoSelectorModel(data.item.id, 'tacticsFeatures')">Tag a Reference</b-btn>

                      </template>
                      <template slot="business" slot-scope="data">
                         <b-btn v-if="data.item.businessIds" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="openBusinessSelectorModel(data.item.id, 'tacticsFeatures')" >View Business</b-btn>
                         <b-btn v-if="!data.item.businessIds" v-show="edit" variant="outline-primary" size="sm" @click="openBusinessSelectorModel(data.item.id, 'tacticsFeatures')">Tag Business</b-btn>
                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-danger" size="sm" v-show="!(fpEditId == data.item.id)" :disabled="fpEditId == null ? false : true" @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'tacticsFeatures')) : fpEditId = null">Edit</b-btn>
                         <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="tacticsFeatures.splice(tacticsFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                         <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove(), viewMap()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                  </b-tab>


                <b-tab title="Utilities">
                  <b-container v-if="edit">
                        <b-row><b-col>
                        <b-form-radio-group id="radios1" v-model="utilitiesObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in utilitiesOptions" :key="index" :value="item.value">
                               <img v-if="item.src" class="iconAddMapp" :title="item.title" :src="item.src" />
                              <div v-if="item.value == 'utilitiesLabel'" :class="item.value + ' optionLabelSelect'">
                                  Text
                               </div></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
                        <b-container v-if="!selectedLocation && utilitiesObj.type" class="text-danger">Click on the Map Where the Utility is Located</b-container>
                        <b-row align-h="center"><b-col lg="6" align="right"><b-form-input v-model="utilitiesObj.properties.serviceaddress" type="text" label="Location" placeholder="Relative Location to Building or Interior" /></b-col>
                        <b-col lg="2" ><b-btn :disabled="!selectedLocation ? true : false" @click="saveFireProtectionInformation('utilitiesObj', 'utilitiesFeatures')" variant="success" size="sm">Save Data</b-btn></b-col>
                        </b-row>
                  </b-container>
                   <b-card v-if="utilitiesFeatures.length > 0" no-body header="Utilities Info Tagged " header-bg-variant="secondary" header-text-variant="white" header-class="p-1">
                  <span v-if="fpEditId" class="text-danger">Change the Data Required, and click on the map to reposition the icon</span>
                   <b-table striped small  :fields="utilitiesFields" :items="utilitiesFeatures" class="mb-0"   stacked="md">
                      <template slot="type" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)"> {{ data.item.type }}</span>
                         <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.type">
                            <option v-for="(item, index) in utilitiesOptions" :key="index" :value="item.value">{{ item.title }}</option>
                           </b-form-select>
                       </template>
                       <template slot="address" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)">{{ data.item.properties.serviceaddress }}</span>
                         <b-form-input v-if="fpEditId == data.item.id" v-model="data.item.properties.serviceaddress" type="text" />
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         
                         <b-btn v-if="!data.item.photoUrl" v-show="edit" variant="outline-primary" @click="openPhotoSelectorModel(data.item.id, 'utilitiesFeatures')">Tag a Reference</b-btn>

                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-secondary" size="sm" v-show="!(fpEditId == data.item.id)" :disabled="fpEditId" @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'utilitiesFeatures')) : fpEditId = null">Edit</b-btn>
                              <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="utilitiesFeatures.splice(utilitiesFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                              <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove(), viewMap()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                    
                  </b-tab>
                  <b-tab title="Roof/Floor Type" v-if="edit">
                  <b-row><b-col>
                        <b-form-radio-group id="radios1truss" v-model="roofFloorOptionsValue" @change="updateRoofOptions($event)">
                             <b-form-radio v-for="(item, index) in roofFloorOptions" :key="index" :value="item.value">
                               <img class="iconAddMapp" :title="item.title" :src="item.src" /></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
                    
                  </b-tab>
                </b-tabs>
                </b-card>
      <div :id="uniqueId"></div></b-container>
        <b-modal v-if="mapModalBusinessFeature" v-model="businessModalShow" :size="modalSize" :hide-footer="true">
          <template slot="modal-header">
          <b-container fluid><b-row><b-col>
            <h5>{{ mapModalBusinessFeature.businessName }}</h5>
           {{ mapModalBusinessFeature.streetNumberStreetStreetSuffix }}
           <h6>{{ mapModalBusinessFeature.primaryStation }}</h6>
           </b-col>
           <b-col cols="auto">
         <b-btn align="right" size="sm" variant="primary" @click="addBuildingDetails(mapModalBusinessFeature)">View Preplan</b-btn>
         <b-btn size="sm" variant="danger" @click="businessModalShow = !businessModalShow">Close</b-btn></b-col></b-row></b-container>
         </template>
          <b-table stacked :fields="['businessName', 'streetNumberStreetStreetSuffix','updatedAt', 'preplanAuthor']" :items="[mapModalBusinessFeature]" />
      </b-modal>
       <b-modal v-if="mapModalFeature" v-model="modalShow" :size="modalSize" :hide-footer="true">
          <template slot="modal-header">
                    <b-container fluid><b-row><b-col>
           {{ mapModalFeature.properties.serviceaddress }}</b-col>
          <b-col cols="auto">
         <b-btn align="right" size="sm" variant="primary" @click="modalSize == '' ? modalSize = 'lg' : modalSize = ''">{{ modalSize == '' ? 'Maximize' : 'Minimize' }}</b-btn>
         <b-btn size="sm" variant="danger" @click="modalShow = !modalShow">Close</b-btn></b-col></b-row></b-container>
         </template>
               <span class="text-danger" v-if="!mapModalFeature.photoUrl">No Photo Tagged to this item</span>
               <b-btn v-if="edit && mapModalFeature.photoUrl" @click="removeRefrencePhoto(mapModalFeature)" size="sm" variant="outline-danger">Remove Reference Photo</b-btn>
               <b-img v-if="mapModalFeature.photoUrl" fluid :src="mapModalFeature.photoUrl" />
          <div v-if="additionalPrimarySketchPhotos && mapModalFeature.floorPlan">
            <b-btn size="lg" variant="info" class="float-left" :disabled="primaryPhotoIndex == 0" @click="primaryPhotoIndexChange(false)">Previous</b-btn>
            <b-btn size="lg" variant="info" class="float-right" :disabled="primaryPhotoIndex == additionalPrimarySketchPhotos.length" @click="primaryPhotoIndexChange(true)">Next</b-btn>
          </div>
        </b-modal>
        <b-modal v-model="businessModelShow" v-if="businessModelShow">
          <b-table :fields="[{key: 'title', label: 'Business'}, 'Status','Action']" :items="getBusinessTagArray"  stacked="md">
            <template slot="Status" slot-scope="data">
              <span v-if="data.item.Status == true">Tagged</span>
            </template>
            <template slot="Action" slot-scope="data">
              <b-btn :variant="data.item.Status ? 'danger' : 'primary'" size="sm" @click="tagBusinessToItem(data.item.id, !data.item.Status)">{{ data.item.Status ? ' X ' : 'Tag' }}</b-btn>
              </template>
            </b-table>
        </b-modal>
        <b-modal v-model="photoModelShow" >
          <b-container>
            <b-card no-body>
              <b-tabs card>
            <b-tab title="unTagged">
            <b-row v-if="refPhotos" v-for="(pic, index) in getRefPhotos(refPhotos, false)" :key="index">
              <b-col cols="auto">
                <b-btn variant="primary" @click="savePhotoReference(pic.url, true)">Save</b-btn>
                </b-col>
                <b-col>
                  <b-img fluid :src="pic.url" />
                </b-col>
                </b-row>
                </b-tab>
            <b-tab title="Tagged">
            <b-row v-if="refPhotos" v-for="(pic, index) in getRefPhotos(refPhotos, true)" :key="index">
              <b-col cols="auto">
                <b-btn variant="primary" @click="savePhotoReference(pic.url, true)">Save</b-btn>
                </b-col>
                <b-col>
                                        <p v-for="(details, index2) in pic.type" :key="index2" v-if="details.type">
                            <strong>{{ details.type }}:</strong> {{ details.location }}</p>
                  <b-img fluid :src="pic.url" />

                </b-col>
                </b-row>
                  </b-tab>
                  </b-tabs>
                  </b-card>
          </b-container>
        </b-modal>
        <span v-if="edit && dataReady" v-show="false">{{ watchFireProtection }}{{ watchHydrants }}{{ watchUtilities}}{{ watchTactics }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main';
import Leaf from 'leaflet'
import * as esri from "esri-leaflet"
import axios from 'axios'
export default {
  props:["buildingData", 'edit', 'refPhotos', 'primarySketch', 'tempView', 'responseView', 'attachedBusinessData', 'activePreplans'], 
  data () {
    return {
        imageType: 'ImageryTransportation',
        mapViewOptions: [
          {text: 'Ariel', value: 'ImageryTransportation'},
          {text: '2D', value: 'Streets'}
        ],
        addMapData: false,
        hydrantManual: false,
        resetBusinessTable: true,
        btnVisibilityGroup: [
          {item: 'Hydrants', variant: 'danger', boolean: true, autoOn: false},
          {item: 'Fire Sys', variant: 'danger', boolean: null, autoOn: false},
          {item: 'Tactics', variant: 'warning', boolean: null, autoOn: false},
          {item: 'Sides', variant: 'warning', boolean: true, autoOn: false},
          {item: 'Utilities', variant: 'secondary', boolean: null, autoOn: false}
        ],
        fireProtectionObj: {
          type: '',
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        },
        tacticsObj: {
          type: '',
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        },
        utilitiesObj: {
          type: '',
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        },
        hydrantManualObj: {
          geometry: {type: "Point", coordinates: []},
          properties: {capcolor: '', serviceaddress: ''}
        },
        capcolorOptions: [
            {text: 'blu', value: 'blu', long: 'blue', color: 'hydrantBlue' },
            {text: 'org', value: 'org', long: 'orange', color: 'hydrantOrange' },
            {text: 'grn', value: 'grn', long: 'green', color: 'hydrantGreen' },
            {text: 'red', value: 'red', long: 'red', color: 'hydrant' },
          ],
        roofFloorOptionsValue: null,
        fireProtectionOptions: [
          {value: 'facp', title: 'Fire Alarm Control Panel', src: '/images/preplanicons/facp.png'},
          {value: 'fdc', title: 'Fire Department Connection', src: '/images/preplanicons/fdc.png'},
          {value: 'firepump', title: 'Fire Pump', src: '/images/preplanicons/fp.png'},
          {value: 'knox', title: 'Knox Box', src: '/images/preplanicons/kbk.png'},
          {value: 'standpipe', title: 'Stand Pipe Connection', src: '/images/preplanicons/standpipe.png'},
          {value: 'sprinklerriser', title: 'Sprinkler Riser', src: '/images/preplanicons/sprinkler-riser.png'},
          {value: 'sprinklershutoff', title: 'Sprinkler Shut Off', src: '/images/preplanicons/sprinkler-shutoff.png'},
          {value: 'osandy', title: 'Outside Stem & Yoke', src: '/images/preplanicons/osy.png'},
          {value: 'fireProtectionLabel', title: 'Fire Protection Label'}
        ],
        buildingTacticsOptions: [
          {value: 'alpha', title: 'Alpha Side', src: '/images/preplanicons/Alpha-Side.png'},
          {value: 'bravo', title: 'Bravo Side', src: '/images/preplanicons/Bravo-Side.png'},
          {value: 'charlie', title: 'Charlie Side', src: '/images/preplanicons/Charlie-Side.png'},
          {value: 'delta', title: 'Delta Side', src: '/images/preplanicons/Delta-Side.png'},
        ],
        tacticsOptions: [
          {value: 'hazmatTank', title: 'Hazmat Tank', src: '/images/preplanicons/gas-hazmat.png'},
          {value: 'hazmatLiquid', title: 'Hazmat Liquid', src: '/images/preplanicons/hazmat-water.png'},
          {value: 'hazmatBio', title: 'Hazmat Bio Waste', src: '/images/preplanicons/hazmat-bio.png'},
          {value: 'hazmatLabel', title: 'Hazmat Label'},
          {value: 'egress', title: 'Means of Egress', src: '/images/preplanicons/egress.png'},
          {value: 'stairwell', title: 'Stairwell', src: '/images/preplanicons/stairs.png'},
          {value: 'stairwellRoofAccess', title: 'Stairwell with Roof Access', src: '/images/preplanicons/roof-stairs.png'},
          {value: 'roofAccess', title: 'Roof Access', src: '/images/preplanicons/roof.png'},
          {value: 'locationLabel', title: 'Location Label'},
        ],
        utilitiesOptions: [
          {value: 'electrical', title: 'Electrical Shutoff/Panel', src: '/images/preplanicons/electric-shutoff.png'},
          {value: 'gas', title: 'Gas Shut-off', src: '/images/preplanicons/gas-shutoff.png'},
          {value: 'water', title: 'Water Shutoff', src: '/images/preplanicons/water-shutoff.png'},
          {value: 'elevatorcontrol', title: 'Elevator Control Room', src: '/images/preplanicons/elevator-control-room.png'},
          {value: 'utilitiesLabel', title: 'Utilities Label'}
        ],
        roofFloorOptions: [
          {value: null, title: 'None', src: '/images/preplanicons/red-fire-icon.png' },
          {value: '/images/preplanicons/lwr-truss.png', title: 'Light Weight Roof', src: '/images/preplanicons/lwr-truss.png'},
          {value: '/images/preplanicons/lwf-truss.png', title: 'Light Weight Floor', src: '/images/preplanicons/lwf-truss.png'},
          {value: '/images/preplanicons/lwrf-truss.png', title: 'Light Weight Roof/Floor', src: '/images/preplanicons/lwrf-truss.png'}
        ],
        primaryPhotoIndex: 0,
        tempPhotoHoldId: '',
        tempPhotoHoldUrl: '',
        tempBuildingMarker: '',
        mapModalFeature: '',
        modalShow: false,
        fpEditId: null,
        fireprotectionFeatures: [],
        tacticsFeatures: [],
        utilitiesFeatures: [],
        hydrantSearch: true,
        holdMapObj: {},
        addressData: [],
        modalSize: '',
        photoModelShow: false,
        businessModelShow: false,
        hydrantFeatures: [],
        businessModalShow: false,
        map: null,
        hydrantPointData: [],
        mapHold: {},
        layerTempId: '',
        utilitiesLayerGroup: '',
        fireProtectionLayerGroup: '',
        tacticsLayerGroup: '',
        hydrantLayerGroup: '',
        selectedLocation: '',
        mapModalFeatureBuildingSketch: '',
        mapModalBusinessFeature: {},
        activePreplansGroupLayer: '',
        dataReady: false,
        uniqueId: '',
    }
  },
  created(){
    //console.log(this.buildingData)
    this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 16);

  },
  updated(){
//    this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 16);
  },
  mounted(){
    this.checkBuildingData()

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'preplanStatus',
          'siteconfig'
        ]),
        additionalPrimarySketchPhotos(){
          if(this.refPhotos){
            var array = this.refPhotos.filter(p => p.type && p.type.type && p.type.type == 'buildingAdditional')
            if(array && array.length > 0){
              return array
            } return null
          } return null
        },
        hydrantFields(){
          var fields = ['address','cap','primary','photo']
          if(this.edit){ fields.push('edit')}
          return fields
         },
        fireProtectionFields(){
          var fields = ['type',{key: 'address', label: 'location/info' },'photo','business']
          if(this.edit){ fields.push('edit')}
          return fields
        },
        utilitiesFields(){
          var fields = ['type',{key: 'address', label: 'location/info' },'photo','business']
          if(this.edit){ fields.push('edit')}
          return fields
        },
        watchHydrants(){
          var object = {type: 'hydrants', data: this.hydrantFeatures}
          this.$emit('updateFeatureData', object)
          return this.hydrantFeatures
        },
       watchTactics(){
          var object = {type: 'tactics', data: this.tacticsFeatures}
          this.$emit('updateFeatureData', object)
          return this.tacticsFeatures
        },
        watchFireProtection(){
          var object = {type: 'fireProtection', data: this.fireprotectionFeatures}
          this.$emit('updateFeatureData', object)
          return this.fireprotectionFeatures
        },
        watchUtilities(){
          var object = {type: 'utilities', data: this.utilitiesFeatures}
          this.$emit('updateFeatureData', object)
          return this.utilitiesFeatures
        },
        getBusinessTagArray(){
          if(this.resetBusinessTable){
          var array = this[this.tempPhotoHoldId.type]
          var obj = array.find(p => p.id == this.tempPhotoHoldId.id)
          var businessData = this.attachedBusinessData
          if(obj && obj.businessIds){
            return businessData.map(item => {
              var hobj = item
              var index = obj.businessIds.indexOf(hobj.id)
              hobj['Status'] = obj.businessIds.indexOf(hobj.id) != -1 ? true : false
              return hobj
            })
          } return businessData.map(item => {
              var hobj = item
              hobj['Status'] = false
              return hobj
          })
          } else {
            return []
          }
        }
    },
    watch: {

      },
  beforeDestroy(){

  },
  methods:{
    addBuildingDetails(data){
      console.log('Bus Emit')
        bus.$emit('openPreplan', data)
        this.businessModalShow = false
    },
    primaryPhotoIndexChange(boo){
      var current = this.primaryPhotoIndex
      var potential = this.additionalPrimarySketchPhotos.length
      if(boo){
        if(current >= potential){
          return
        } else {
          this.primaryPhotoIndex = current + 1
          var obj = this.additionalPrimarySketchPhotos[current]
          if(obj && obj.url && obj.type && obj.type.id){
            var newmapobj = {}
              newmapobj['properties'] = {}
              newmapobj.properties['serviceaddress'] = obj.type.id
              newmapobj['photoUrl'] = obj.url
              newmapobj['floorPlan'] = true
              this.mapModalFeature = newmapobj
          
          }
        }

      } else{
        var number = current - 1
        if(number - 1 < 0){
          this.primaryPhotoIndex = 0
          this.mapModalFeature = this.mapModalFeatureBuildingSketch
        } else {
          this.primaryPhotoIndex = number
          var obj = this.additionalPrimarySketchPhotos[number - 1]
          if(obj && obj.url && obj.type && obj.type.id){
            var newmapobj = {}
              newmapobj['properties'] = {}
              newmapobj.properties['serviceaddress'] = obj.type.id
              newmapobj['photoUrl'] = obj.url
              newmapobj['floorPlan'] = true
              this.mapModalFeature = newmapobj
          
          }
        }
      }
    },
    updateRoofOptions(data){
      console.log(data)
      this.$emit('updateRoofOptions', data)
    },
    toggleBuildingDataBar(){
      this.$emit('toggleBuildingDataBar')
    },
    updateBuildingLocation(){
      this.$emit('updateBuildingLocation')
    },
    checkBuildingData(){
        if(this.buildingData){
        var data = this.buildingData
        if(data.hydrants){
          this.hydrantFeatures = data.hydrants
        }
        if(data.fireProtection){
          this.fireprotectionFeatures = data.fireProtection
        }
        if(data.utilities){
          this.utilitiesFeatures = data.utilities
        }
        if(data.tactics){
          this.tacticsFeatures = data.tactics
        }
        if(data.utilites){
          this.utilitiesFeatures = data.utilites
        }
        this.$nextTick(function(){
    this.viewMapBuild(data, data)
    this.dataReady = true
        })
    
    } else {
      this.$nextTick(function(){
      this.dataReady = true
      })
    }
    },
    saveFireProtectionInformation(obj, featureArray){
    var fpdata = this[obj]
    var data = this.selectedLocation
    var id = fpdata.type
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    fpdata['id'] = id
    fpdata.geometry.coordinates = [data.lng,data.lat]
    this[featureArray].push(fpdata)
    this.$nextTick(function(){
      this[obj] = {
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        }
      var map = this.mapHold
      map.removeLayer(this.layerTempId)
      map.off('click')
      this.selectedLocation = ''
      this.viewMap()
    })

    },
    saveManualHydrantData(){
    var hydrant = this.hydrantManualObj
    var data = this.selectedLocation
    hydrant.geometry.coordinates = [data.lng,data.lat]
    this.hydrantFeatures.push(hydrant)
    this.$nextTick(function(){
      this.hydrantManualObj = {
          geometry: {type: "Point", coordinates: []},
          properties: {capcolor: '', serviceaddress: ''}
        }
      var map = this.mapHold
      map.removeLayer(this.layerTempId)
      map.off('click')
      this.selectedLocation = ''
      this.hydrantManual = false
      this.viewMap()
    })

    },
    mouseClickEvent(data){
    this.selectedLocation = data
    var map = this.mapHold
    if(this.layerTempId){
          map.removeLayer(this.layerTempId)
    }      
    var markerGroup = Leaf.layerGroup().addTo(map)
    var layerId = Leaf.marker([data.lat,data.lng]).addTo(markerGroup)
    this.layerTempId = markerGroup
    },
    setBuildingData(data){
      this.buildingData = data
    },
    getHydrantData(){
      var data = this.buildingData.latlng
      var point = this.buildingData
      this.hydrantPointData = []
      this.hydrantSearch = false
       axios({
         method: 'get',
         url: 'https://ags3.scgov.net/server/rest/services/Hosted/wHydrant/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=' + data.lng + ',' + data.lat + '&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&distance=400&units=esriSRUnit_Meter&outSR=4326&f=geojson',
       //  url: 'https://ags3.scgov.net/server/rest/services/Hosted/wHydrant/FeatureServer/0/query?returnGeometry=true&where=1%3D1&outSr=4326&outFields=*&inSr=4326&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometryPrecision=6&distance=300&unit=meters&SR=4326&resultRecordCount=100&f=geojson',
         headers: {
          'Content-Type' : 'application/json',
        } 
       }).then((response) => {
         var availableHydrants = response.data.features
         this.getMuncipleHydrants(availableHydrants)

    })
    },
    getMuncipleHydrants(county){
      var data = this.buildingData.latlng
            var point = this.buildingData
        axios({
         method: 'get',
         url: 'https://ags3.scgov.net/server/rest/services/Hosted/wMunicipalHydrant/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=' + data.lng + ',' + data.lat + '&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&distance=300&units=esriSRUnit_Meter&outSR=4326&f=geojson',
         //url: 'https://ags3.scgov.net/server/rest/services/Hosted/wMunicipalHydrant/FeatureServer/0/query?returnGeometry=true&where=1%3D1&outSr=4326&outFields=*&inSr=4326&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometryPrecision=6&distance=300&unit=meters&SR=4326&resultRecordCount=100&f=geojson',
         headers: {
          'Content-Type' : 'application/json',
        } 
       }).then((response) => {
         var availableHydrants = response.data.features

         if(this.hydrantFeatures){
           var arrayofids = this.hydrantFeatures.map(p => p.id)

           for(var i in arrayofids){
             var index = availableHydrants.map(p => p.id).indexOf(arrayofids[i])

             var index2 = county.map(p => p.id).indexOf(arrayofids[i])

             if(index >= 0){
               availableHydrants.splice(index, 1)
             }
             if(index2 >= 0){
               county.splice(index, 1)
             }
           }
           this.hydrantPointData = county.concat(availableHydrants)
         } else {
         this.hydrantPointData = county.concat(availableHydrants)
         }
         this.hydrantSearch = true
                this.viewMap(data, point, this.mapHold)
       })
    },
    viewMapBuild(data, point){
      this.showMap = true
      this.$nextTick(function(){

        this.setMap(data, point)
        
      })
    },
    clearHydrantSearchData(){
      this.hydrantPointData = []
      
      this.setMap()
    },
    setMap(data, point){
      //  console.log(data)
         if(data || point){
        this.holdMapObj = {data: data, point: point}
        } else {
          var data = this.holdMapObj.data
          var point = this.holdMapObj.point
        }
          var center = data.latlng
          var zoom = 18
        var imageType = this.imageType
        if(this.responseView){
        document.getElementById(this.uniqueId).style.height = document.documentElement.clientHeight - 63 + 'px'
        } else {
        document.getElementById(this.uniqueId).style.height = '700px'
        }
        var string = "<div id='mapSpot-" + this.uniqueId + "' style='width: 100%; height: 100%;'></div>"
        document.getElementById(this.uniqueId).innerHTML = string
        var map = Leaf.map('mapSpot-' + this.uniqueId).setView(center, zoom);

        /* if(imageType == 'ImageryTransportation'){
          var imageType = 'Imagery'
          var overlay = 'ImageryTransportation'
           esri.basemapLayer(imageType).addTo(map);
           esri.basemapLayer('ImageryTransportation').addTo(map);
        } else {
            esri.basemapLayer(imageType).addTo(map);
        }      
        esri.basemapLayer(imageType).addTo(map); */
        if(imageType == 'ImageryTransportation'){
        esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer',
            maxZoom: 24,
            maxNativeZoom: 20
        }).addTo(map)
            esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Transportation/MapServer',
            maxZoom: 24,
            maxNativeZoom: 19
        }).addTo(map)
        } else {
        esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer',
            maxZoom: 24,
            maxNativeZoom: 19
        }).addTo(map)
        }
        this.mapHold = map
        if(!this.tempView){
        this.viewMap(data, point, map)
        } else if (this.tempView){
          this.mapSetBuilding()
        }
    },
    viewMap(data, point, map){
        if(!data || !point || !map){
          var data = this.holdMapObj.data
          var point = this.holdMapObj.point
          var map = this.mapHold
        }
        map.off('zoomend')
        if(this.buildingData.rooffloortype){
          console.log(this.buildingData)
          var primaryBuilding = Leaf.icon({ iconUrl: this.buildingData.rooffloortype, iconSize: [50, 50]})
        } else {
        var primaryBuilding = Leaf.icon({ iconUrl: '/images/preplanicons/red-fire-icon.png', iconSize: [70, 70]})
        }
        var greyHydrant = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-grey.png', iconSize: [40, 40]})
        var highlightIcon = Leaf.divIcon({className: 'css-icon', html: '<div class="gps_ring"></div>', iconSize: [40, 40]});
        var hydrant = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-red.png', iconSize: [40, 40]})
        var primaryHydrant = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-red-star.png', iconSize: [40, 40]})
        var hydrantprimary = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-red-star.png', iconSize: [40, 40]})
        var hydrantBlue = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-blue.png', iconSize: [40, 40]})
        var hydrantBlueprimary = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-blue-star.png', iconSize: [40, 40]})
        var hydrantGreen = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-green.png', iconSize: [40, 40]})
        var hydrantGreenprimary = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-green-star.png', iconSize: [40, 40]})
        var hydrantOrange = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-orange.png', iconSize: [40, 40]})
        var hydrantOrangeprimary = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-orange-star.png', iconSize: [40, 40]})
        var draftingSite = Leaf.icon({ iconUrl: '/images/preplanicons/draft-site.png', iconSize: [40, 40]})
        var primaryDraftingSite = Leaf.icon({ iconUrl: '/images/preplanicons/primary-drafting-site.png', iconSize: [40, 40]})
        var osandy = Leaf.icon({ iconUrl: '/images/preplanicons/osy.png', iconSize: [40, 40]})
        var sprinklershutoff = Leaf.icon({ iconUrl: '/images/preplanicons/sprinkler-shutoff.png', iconSize: [40, 40]})
        var elevatorcontrol = Leaf.icon({ iconUrl: '/images/preplanicons/elevator-control-room.png', iconSize: [40, 40]})
        var facp = Leaf.icon({ iconUrl: '/images/preplanicons/facp.png', iconSize: [40, 40]})
        var fdc = Leaf.icon({ iconUrl: '/images/preplanicons/fdc.png', iconSize: [40, 40]})
        var firepump = Leaf.icon({ iconUrl: '/images/preplanicons/fp.png', iconSize: [40, 40]})
        var knox = Leaf.icon({ iconUrl: '/images/preplanicons/kbk.png', iconSize: [40, 40]})
        var standpipe = Leaf.icon({ iconUrl: '/images/preplanicons/standpipe.png', iconSize: [40, 40]})
        var sprinklerriser = Leaf.icon({ iconUrl: '/images/preplanicons/sprinkler-riser.png', iconSize: [40, 40]})
        var electrical = Leaf.icon({ iconUrl: '/images/preplanicons/electric-shutoff.png', iconSize: [40, 40]})
        var gas = Leaf.icon({ iconUrl: '/images/preplanicons/gas-shutoff.png', iconSize: [40, 40]})
        var water = Leaf.icon({ iconUrl: '/images/preplanicons/water-shutoff.png', iconSize: [40, 40]})               
        var hazmatTank = Leaf.icon({ iconUrl: '/images/preplanicons/gas-hazmat.png', iconSize: [40, 40]})
        var hazmatLiquid = Leaf.icon({ iconUrl: '/images/preplanicons/hazmat-water.png', iconSize: [40, 40]})
        var hazmatBio = Leaf.icon({ iconUrl: '/images/preplanicons/hazmat-bio.png', iconSize: [40, 40]})
        var egress = Leaf.icon({ iconUrl: '/images/preplanicons/egress.png', iconSize: [40, 40]})
        var stairwell = Leaf.icon({ iconUrl: '/images/preplanicons/stairs.png', iconSize: [40, 40]})
        var stairwellRoofAccess = Leaf.icon({ iconUrl: '/images/preplanicons/roof-stairs.png', iconSize: [40, 40]})
        var roofAccess = Leaf.icon({ iconUrl: '/images/preplanicons/roof.png', iconSize: [40, 40]})
        var alpha = Leaf.icon({ iconUrl: '/images/preplanicons/Alpha-Side.png', iconSize: [80, 31]})
        var bravo = Leaf.icon({ iconUrl: '/images/preplanicons/Bravo-Side.png', iconSize: [80, 31]})
        var charlie = Leaf.icon({ iconUrl: '/images/preplanicons/Charlie-Side.png', iconSize: [80, 31]})
        var delta = Leaf.icon({ iconUrl: '/images/preplanicons/Delta-Side.png', iconSize: [80, 31]})
        if(this.hydrantPointData){
          var vm = this
          var hyAvail = this.hydrantPointData
          var markerArray = []
          var highlightArray = []
          for(var i in hyAvail){
             markerArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: greyHydrant}))
             highlightArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { icon: highlightIcon, zIndexOffset: -200}))
          }
          Leaf.featureGroup(markerArray).on('click', function (layer) {
              vm.hydrantPush(layer.propagatedFrom.options.feature, data, point, map)
              }).addTo(map)
          Leaf.featureGroup(highlightArray).addTo(map)
          
        }
        if(this.fireprotectionFeatures){
          var vm = this
          var hyAvail = this.fireprotectionFeatures
          var fpArray = []
          if(this.fireProtectionLayerGroup){
            map.removeLayer(this.fireProtectionLayerGroup)
          }
          for(var i in hyAvail){
             if(hyAvail[i].type != 'fireProtectionLabel'){
               fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
             }
             if(hyAvail[i].type == 'fireProtectionLabel'){
               var myIcon = Leaf.divIcon({iconSize: null, html: '<div class="map-label"><div class="map-label-content fireProtectionLabel">' + hyAvail[i].properties.serviceaddress + '</div></div>'});
               fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: myIcon}))
             }
            
          }
          var fpResponseGroup = new Leaf.featureGroup(fpArray)
          this.fireProtectionLayerGroup = fpResponseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Fire Sys')
          if(this.addMapData){
            fpResponseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, fpResponseGroup, map, 'fireprotectionFeatures')
          }
        }
        if(this.tacticsFeatures){
          var vm = this
          var hyAvail = this.tacticsFeatures
          var fpArray = []
          if(this.tacticsLayerGroup){
            map.removeLayer(this.tacticsLayerGroup)
          }
          for(var i in hyAvail){
            if(!(hyAvail[i].type == 'alpha' || hyAvail[i].type == 'bravo' || hyAvail[i].type == 'charlie' || hyAvail[i].type == 'delta' || hyAvail[i].type == 'hazmatLabel' ||  hyAvail[i].type == 'locationLabel')){
               fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
             }
            if(!(hyAvail[i].type == 'alpha' || hyAvail[i].type == 'bravo' || hyAvail[i].type == 'charlie' || hyAvail[i].type == 'delta') &&  (hyAvail[i].type == 'hazmatLabel' ||  hyAvail[i].type == 'locationLabel')){
               var myIcon = Leaf.divIcon({iconSize: null, html: '<div class="map-label"><div class="map-label-content ' + hyAvail[i].type + '">' + hyAvail[i].properties.serviceaddress + '</div></div>'});
               fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: myIcon}))
            }
          }
          var fpResponseGroup = new Leaf.featureGroup(fpArray)
          this.tacticsLayerGroup = fpResponseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Tactics')
          if(this.addMapData){
            fpResponseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, fpResponseGroup, map, 'tacticsFeatures')
          }
        }
        if(this.tacticsFeatures){
          var vm = this
          var hyAvail = this.tacticsFeatures
          var fpArray = []
          if(this.tacticsBuildingLayerGroup){
            map.removeLayer(this.tacticsBuildingLayerGroup)
          }
          for(var i in hyAvail){
             if(hyAvail[i].type == 'alpha' || hyAvail[i].type == 'bravo' || hyAvail[i].type == 'charlie' || hyAvail[i].type == 'delta'){
             fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
             }
          }
          var fpResponseGroup = new Leaf.featureGroup(fpArray)
          this.tacticsBuildingLayerGroup = fpResponseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Sides')
          if(this.addMapData){
            fpResponseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, fpResponseGroup, map, 'tacticsBuildingSidesFeatures')
          }
        }
        if(this.utilitiesFeatures){
          var vm = this
          var hyAvail = this.utilitiesFeatures
          var utilitieArray = []
          if(this.utilitiesLayerGroup){
            map.removeLayer(this.utilitiesLayerGroup)
          }
          for(var i in hyAvail){
             if(hyAvail[i].type != 'utilitiesLabel'){
               utilitieArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
             }
             if(hyAvail[i].type == 'utilitiesLabel'){
               var myIcon = Leaf.divIcon({iconSize: null, html: '<div class="map-label"><div class="map-label-content utilitiesLabel">' + hyAvail[i].properties.serviceaddress + '</div></div>'});
               utilitieArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: myIcon}))
             }
            
          
          }
          var responseGroup = new Leaf.featureGroup(utilitieArray)
          this.utilitiesLayerGroup = responseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Utilities')
          if(this.addMapData){
            responseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, responseGroup, map, 'utilitiesFeatures')
          }
        }
        if(this.hydrantFeatures){
          var vm = this
          var hyAvail = this.hydrantFeatures
         if(this.hydrantLayerGroup){
            map.removeLayer(this.hydrantLayerGroup)
          }
          var markerArray = []
          var capColorArray = this.capcolorOptions
          for(var i in hyAvail){
            var feature = hyAvail[i].properties
            if(feature && feature.capcolor){
              var icon = capColorArray.find(p => p.text == feature.capcolor.toLowerCase()) ? 
                        capColorArray.find(p => p.text == feature.capcolor.toLowerCase()).color :
                        capColorArray.find(p => p.long == feature.capcolor.toLowerCase()) ? 
                        capColorArray.find(p => p.long == feature.capcolor.toLowerCase()).color :
                        'hydrant'
            } else {
            //  var capcolorMiss = true
              var icon = 'hydrant'
            }
             if(hyAvail[i].type == 'primary' || hyAvail[i].type == 'primaryHydrant'){
               icon = icon + 'primary'
             }
             markerArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(icon)}))
          }
          var hydrantGroup = new Leaf.featureGroup(markerArray)
          this.hydrantLayerGroup = hydrantGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Hydrants')
          if(this.addMapData){
          hydrantGroup.on('click', function (layer) {
              vm.hydrantRemove(layer.propagatedFrom.options.feature, data, point, map)
              }).addTo(map)
          } else {
             this.mapIconBuildOut(boolean, hydrantGroup, map, 'hydrantFeatures')
          }
        }
        if(this.activePreplans){
          console.log('THIS ACTIVE PREPLANS')
          if(this.activePreplansGroupLayer){
            map.removeLayer(this.activePreplansGroupLayer)
          }
          var avail = this.activePreplans
          var preplans = []
          var myRender = Leaf.canvas({ padding: 0.5})
          for(var i = 0; i < avail.length; i++){
            if(avail[i].preplanTableData){
            var obj = avail[i].preplanTableData.find(p => p.key == 'map')
            if(obj && obj.obj){
              var allData = avail[i]
              var holdData = avail[i].preplanTableData
              for(var h in holdData){
                allData[holdData[h].key] = holdData[h].value
              }
              if(allData.businessName){
                var string = allData.businessName
              } else {
                var string = 'Missing Name'
              }
              preplans.push(Leaf.circleMarker(obj.obj, { feature: allData, renderer: myRender, color: '#e2070d', radius: 7}).bindTooltip(string, { interactive: true, direction: 'bottom', offset: [0, 10]  }).openTooltip())
            }
            }
          }
          var preplansMarkerGroup = new Leaf.featureGroup(preplans)
          this.activePreplansGroupLayer = preplansMarkerGroup
          preplansMarkerGroup.addTo(map)
          preplansMarkerGroup.on('click', function (layer) {
              var obj = layer.propagatedFrom.options.feature
              vm.modalSize = 'lg'
              vm.mapModalBusinessFeature = obj
              vm.businessModalShow = true
        })
        }
        this.$nextTick(function(){
        var primaryMarker = []
        primaryMarker.push(Leaf.marker(data.latlng, { icon: primaryBuilding, zIndexOffset: 1000 }))
        var primaryMarkerGroup = new Leaf.featureGroup(primaryMarker)
        if(this.primarySketch && !this.edit){
              primaryMarkerGroup.on('click', function (layer) {
              var obj = {}
              obj['properties'] = {}
              obj.properties['serviceaddress'] = 'PRIMARY BUILDING SKETCH'
              obj['photoUrl'] = vm.primarySketch
              obj['large'] = true
              obj['floorPlan'] = true
              vm.mapModalFeature = obj
              vm.mapModalFeatureBuildingSketch = obj
              vm.modalShow = true
              vm.primaryPhotoIndex = 0
              })
        }
        if(this.edit){
              primaryMarkerGroup.on('click', function (layer) {
              var obj = {}
              obj['properties'] = {}
              obj.properties['serviceaddress'] = 'PRIMARY BUILDING SKETCH'
              obj['photoUrl'] = vm.primarySketch
              obj['large'] = true
              vm.mapModalFeature = obj
              vm.modalShow = true
              })
        } //<latitude>27.298434</latitude><longitude>-82.50775</longitude><latitude>27.340114</latitude><longitude>-82.49708</longitude>
        primaryMarkerGroup.addTo(map)
        if(this.measureControl){
          map.removeControl(this.measureControl)
        }
        var options = { primaryAreaUnit: 'sqfeet',
                        secondaryAreaUnit: 'acres',
                        position: 'topleft'}
        var measureControl = new Leaf.Control.Measure(options);
        this.measureControl = measureControl
        measureControl.addTo(map);
        map.on('measurefinish', function(data){
          console.log(data)
        })
       /*
        DISPATCH CIRCLE 
       var currentZoom = map.getZoom()
                  var times = 25 - currentZoom
          var radiusSet = 1
          for(var i = 0; i < times; i++){
            radiusSet = radiusSet * 2
          }
        var dispatchCircle = Leaf.circle([27.298434, -82.50775], {radius: radiusSet, fill: false, color: '#dc3545'}).addTo(map)
        map.on('zoomend', function(e){
          var currentZoom = map.getZoom()
          var times = 25 - currentZoom
          var radiusSet = 1
          for(var i = 0; i < times; i++){
            radiusSet = radiusSet * 2
          }
          dispatchCircle.setRadius(radiusSet)
        })
        */
        }) 

    },
    mapIconBuildOut(boolean, fpResponseGroup, map, feature){
          var vm = this
          var zoom = map.getZoom()
       //   console.log(boolean.item + ' Zoom ' + zoom)
          if(boolean.boolean){
              if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
             fpResponseGroup.addTo(map)
             boolean.autoOn = false
          } else if(boolean.boolean == false){
             boolean.autoOn = false
          
              map.removeLayer(fpResponseGroup)
          } else if (boolean.boolean == null){
      //     console.log(boolean.item + ' Null ' + zoom)
          if(map.getZoom() >= 20){
        //    console.log(boolean.item + ' Map ' + zoom)
          if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
            fpResponseGroup.addTo(map);
          }
          map.on('zoomend', function() {
          if (map.getZoom() <20 && !boolean.boolean){
                    boolean.autoOn = false
                
                    map.removeLayer(fpResponseGroup);
       //              console.log(boolean.item + ' Click 1 ' + zoom)
            }
          else if (map.getZoom() >=20 && boolean.boolean && !boolean.autoOn){
        //        console.log(boolean.item + ' Click 2 ' + zoom)
                      if(!this.edit){
                    fpResponseGroup.on('click', function (layer) {
                    vm.mapModalFeature = layer.propagatedFrom.options.feature
                    vm.modalShow = true
                    })}
                   fpResponseGroup.addTo(map);
          } else if (map.getZoom() >=20 && (boolean.boolean == null)){
  //    console.log(boolean.item + ' Click 3 ' + zoom)
                  boolean.autoOn = true
                  if(!this.edit){
                    fpResponseGroup.on('click', function (layer) {
                    vm.mapModalFeature = layer.propagatedFrom.options.feature
                    vm.modalShow = true
                    })}
                  fpResponseGroup.addTo(map);
          } else if (map.getZoom() <20 && boolean.boolean){
              if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
            //           console.log(boolean.item + ' Click 4 ' + zoom)
             fpResponseGroup.addTo(map);
            }else {
        //    console.log('ELSE')
          }
        });
        }
    },
    hydrantPush(hydr, mapdata, point, map){
      var data = hydr
      data['type'] = 'hydrant'
      this.hydrantFeatures.push(data)
      this.hydrantPointData.splice(this.hydrantPointData.map(p => p.id).indexOf(hydr.id), 1)
      this.viewMap(mapdata, point, map)
    },
    hydrantRemove(hydr, mapdata, point, map){
      var data = hydr
      data['type'] = 'feature'
      this.hydrantPointData.push(data)
      this.hydrantFeatures.splice(this.hydrantFeatures.map(p => p.id).indexOf(hydr.id), 1)
      this.viewMap(mapdata, point, map)
    },
    updateCoordinates(data, type, id){
      var ref = this[type]
      var item = ref[id]
      item.geometry.coordinates = [data.lng,data.lat]
      this.viewMap()
    },
    mapEditRemove(){
      var map = this.mapHold
         map.off('click')
    },
    getRefPhotos(data, type){
      
      if(type && data){
        return data.filter(p => {
          if(p.type && p.type.length > 0){
            return p
          }
        })
      } else if(data) {
        return data.filter(p => {
          if(p.type && p.type.length <= 0){
            return p
          } else if (!p.type){
            return p
          }
        })
      }

    },
    removeRefrencePhoto(data){
      var photoIndex = this.refPhotos.map(p => p.url).indexOf(data.photoUrl)
      var emitObj = {item: data, photoIndex: photoIndex}
        this.$emit('photoArrayUpdate', emitObj)
        data.photoUrl = ''
        this.mapModalFeature = ''
        this.modalShow = false
    },
    openPhotoSelectorModel(data, type){
      this.tempPhotoHoldId = {id: data, type: type}
      this.photoModelShow = true
    },
    openBusinessSelectorModel(data, type){
      this.tempPhotoHoldId = {id: data, type: type}
      console.log(data)
      console.log(type)
      console.log(this[type])
      this.businessModelShow = true
    },
    tagBusinessToItem(id, status){
         this.resetBusinessTable = false
      var obj = this.tempPhotoHoldId
      var ref = this[obj.type]
      var item = ref.find(p => p.id == obj.id)
      if(item.businessIds){
        if(status){
        item.businessIds.push(id)
        } else {
          var index = item.businessIds.indexOf(id)
          if(index > -1){
          item.businessIds.splice(index, 1)
          }
        }
      } else {
        if(status){
          item['businessIds'] = []
          item.businessIds.push(id)
        }
      }
      this.$nextTick(function(){
        this.resetBusinessTable = true
      })
    },
    savePhotoReference(url, add){
      var obj = this.tempPhotoHoldId
      var ref = this[obj.type]
      var item = ref.find(p => p.id == obj.id)
      item['photoUrl'] = url
      var emitObj = {item: item, photoIndex: this.refPhotos.map(p => p.url).indexOf(url)}
      if(add){
        emitObj['add'] = true
      }
      this.$emit('photoArrayUpdate', emitObj)
       this.photoModelShow = false
    },
    mapSetBuilding(){
      var map = this.mapHold
       var vm = this
        map.on('click', function(ev) {
          vm.$emit('mapBuildingLatLng', ev.latlng)
          if(vm.tempBuildingMarker){
            map.removeLayer(vm.tempBuildingMarker)
          }
          var tempBuildingMarkerArray = []
          tempBuildingMarkerArray.push(Leaf.marker(ev.latlng))
          var tempBuildingMarker = new Leaf.featureGroup(tempBuildingMarkerArray)
          vm.tempBuildingMarker = tempBuildingMarker
          tempBuildingMarker.addTo(map)
       //   console.log(ev.latlng); // ev is an event object (MouseEvent in this case)
        });
                if(this.activePreplans){
          console.log('THIS ACTIVE PREPLANS')
          if(this.activePreplansGroupLayer){
            map.removeLayer(this.activePreplansGroupLayer)
          }
          var avail = this.activePreplans
          var preplans = []
          var myRender = Leaf.canvas({ padding: 0.5})
          for(var i = 0; i < avail.length; i++){
            if(avail[i].preplanTableData){
            var obj = avail[i].preplanTableData.find(p => p.key == 'map')
            if(obj && obj.obj){
              var allData = avail[i]
              var holdData = avail[i].preplanTableData
              for(var h in holdData){
                allData[holdData[h].key] = holdData[h].value
              }
              if(allData.businessName){
                var string = allData.businessName
              } else {
                var string = 'Missing Name'
              }
              preplans.push(Leaf.circleMarker(obj.obj, { feature: allData, renderer: myRender, color: '#e2070d', radius: 7}).bindTooltip(string, {permanent: true, interactive: true, direction: 'bottom', offset: [0, 10]  }).openTooltip())
            }
            }
          }
          var preplansMarkerGroup = new Leaf.featureGroup(preplans)
          this.activePreplansGroupLayer = preplansMarkerGroup
          preplansMarkerGroup.addTo(map)
          preplansMarkerGroup.on('click', function (layer) {
              var obj = layer.propagatedFrom.options.feature
              vm.modalSize = 'lg'
              vm.mapModalBusinessFeature = obj
              vm.businessModalShow = true
        })
        }
    },
    mapEdit(data, type){
        var ref = this[type]
        var index = ref.map(p => p.id).indexOf(data)
        var map = this.mapHold
        var vm = this
        map.on('click', function(ev) {
          vm.updateCoordinates(ev.latlng, type, index); // ev is an event object (MouseEvent in this case)
        });
    },

    mapExtras(){
    
          var map = this.mapHold
        var vm = this
        map.on('click', function(ev) {
            vm.mouseClickEvent(ev.latlng); // ev is an event object (MouseEvent in this case)
        });

  },
   

}
}

</script>
<style src='leaflet-measure\dist\leaflet-measure.css'>
    /* global styles */
</style> 
<style>
.optionLabelSelect {
  padding: 0px 4px 0px 2px;
  border: #000 solid 2px;
  border-radius: 5px;
}
.map-label {
  position: absolute;
  bottom: 0;left: -50%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/*Wrap the content of the divicon (text) in this class*/
.map-label-content {
  order: 1;
  position: relative; left: -50%;
  background-color: #fff;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #000;
  padding: 3px;
  white-space: nowrap;
}
.fireProtectionLabel {
    background: #dc3646;
    color: #000;
    font-weight: bold;
}
.hazmatLabel {
    background: #ffc10e;
    color: #000;
    font-weight: bold;
}
.locationLabel {
    background: #fff;
    color: #000;
    font-weight: bold;
}
.utilitiesLabel {
    background: #27a74a;
    color: #000;
    font-weight: bold;
}
.startprompt h3 {
    font-size: 16px;
  }
  .js-measuringprompt h3 {
   display: none;
  }
	.gps_ring {	
		border: 3px solid #e2070d;
		 -webkit-border-radius: 60px;
		 height: 40px;
		 width: 40px;		
	    -webkit-animation: pulsate 1s ease-out;
	    -webkit-animation-iteration-count: infinite; 
	    /*opacity: 0.0*/
	}
	
	@-webkit-keyframes pulsate {
		    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
		    50% {opacity: 1.0;}
		    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
	}
</style>
<style scoped>

.leaflet-marker-icon.pulse {
      box-shadow: 1px 1px white;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>

.iconAddMapp {
  width: 40px;
  height: auto;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
