<template>
  <div class="hello">
    <b-container class="pb-2">
    <b-row><b-col><b-form-select v-model="parentSelect" :options="parentSearch" >
        <option value="">Filter By Parent</option></b-form-select>
    </b-col>
    <b-col><b-form-select v-model="stationSelect" :options="stationSearch" >
        <option value="">Filter By Station</option></b-form-select></b-col>
    <b-col><b-form-select v-model="shiftSelect" :options="shiftSearch" >
        <option value="">Filter By Shift</option></b-form-select></b-col>
        <b-col class="pt-1" cols="*">
            <b-btn variant="danger" size="sm" @click="shiftSelect = '', stationSelect = '', parentSelect = ''">Clear</b-btn></b-col></b-row></b-container>
    <b-table striped hover :fields="fields" :items="filteredUsers">
        <template v-if="clearFromList == true" slot="Remove" slot-scope="data">
            <b-btn :disabled="!(userData.role == 'admin' || userData.role == 'adminchief')" variant="danger" size="sm" @click="removeFromList(data.item.id)">Mark As Read</b-btn>
        </template>
        <template slot="readDate" slot-scope="data">
            {{ notificationTimeAdjust(data.item.readDate) }}
        </template>
        </b-table>
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['users', 'clearFromList'],
  name: 'hello',
  components: {

  },
  data () {
    return {
        fields: [
            'firstName',
            { key: 'lastName', sortable: true } ,
            'employeeId',
            { key: 'stationAssignment', sortable: true},
            'parentStation',
            'shiftAssignment'
        ],
        stationSearch: [],
        parentSearch: [],
        shiftSearch: [],
        stationSelect: '',
        parentSelect: '',
        shiftSelect: '',
    }
  },
  created(){
      if(this.clearFromList == true){
          this.fields.push('Remove')
      }
    
  },
  computed: {
      ...mapState([
        'userData',
        'deptContactData',
        'stationsData'
      ]),
      deptUsersFilter(){

      },
      filteredUsers(){
          return this.loadedUsers.filter(p => {
              return p.stationAssignment.match(this.stationSelect)
                && p.parentStation.match(this.parentSelect)
                && p.shiftAssignment.match(this.shiftSelect)
          })
      },
      loadedUsers(){
          if(this.users[0].id != undefined){
              var users = [... new Set(this.users.map(p => p.id))]
              var testVal = true
              this.fields.push({key: 'readDate', sortable: true})
          }
          else {
              var users = this.users
              var testVal = false
          }
          var data = this.deptContactData.filter(p => {
              if(p.id != undefined){
              var index = users.indexOf(p.id)
              if(index >= 0){
                  return p
              }
              }
          })
          data.forEach(item => item.parentStation = this.getItemParent(item.stationAssignment))
          if(testVal == true){
              data.forEach(item => item.readDate = this.getReadDate(item.id))
          }
          var data = _.orderBy(data, ['stationAssignment'], ['asc'])
          this.getSearchFields(data)
          return data
      },
  },
  watch: {

},
  methods: {
      getReadDate(data){
          var date = this.users.filter(p => {
              return p.id == data
          })
          if(date[0].dateRead != undefined){
              var date = date[0].dateRead
          } else {
              date = ''
          }
          return date
      },
      getSearchFields(data){
          this.stationSearch = [...new Set(data.map(p => p.stationAssignment))]
          var parent =  [...new Set(data.map(p => p.parentStation ))]
          this.parentSearch =  _.sortBy(parent)
          var shift = [... new Set(data.map(p => p.shiftAssignment))]
          this.shiftSearch = _.sortBy(shift)
      },
      getItemParent(station){
          var parent = this.stationsData.find(p => {
              return p.stationName == station
          })
          if(parent){
          var parent = parent.stationParent
          if(parent == undefined || parent == null || parent == '' ){
              return station
          } else {
              return parent
          }
          } return 'UNDEFINED'

      },
      removeFromList(data){
          this.$emit('updateReadList', data)
      },
    notificationTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY - HH:mm')
    },
  
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}
</style>
