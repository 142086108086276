<template>
<b-container fluid>
     <b-navbar>
        <b-nav-form class="w-100">
            <b-container fluid>
            <b-row>
            <b-col :xl="calendarType != null ? '12' : calendarSmall ? '6' : '2'" >
          <b-navbar-brand class="title">{{title}}</b-navbar-brand>
          </b-col>
          <b-col :xl="calendarSmall ? '6' : '3'" cols="6" class="pt-1"  v-if="calendarType == null">
            <div class="btn-group">
                <b-btn variant="primary" size="sm" @click.stop="goPrev">&lArr;
                    <span>Prev</span>
                </b-btn>

                <b-btn variant="secondary" size="sm" @click.stop="goToday">&dArr;

                    <span>Today</span>
                </b-btn>

                <b-btn variant="primary" size="sm"  @click.stop="goNext">
                    <span>Next</span>
                    &rArr;
                </b-btn>
            </div>
            </b-col>
        <b-col :xl="calendarSmall ? '12' : '7'" v-if="calendarType == null">
        <b-container fluid><b-row align-h="center"><b-col cols="4">
                <multiselect v-model="stationSelectValue" @input="setData('stationSelectValue', $event)" :options="stationsDataFilter" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Filter by Station" selectLabel="select" label="stationName" track-by="stationName">
                  <template slot="option" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span></span></template>
                </multiselect></b-col>
                 <b-col cols="5">
                <multiselect v-if="resetInput" v-model="filterSelectValue" @input="setDataFilter($event)" :options="getFilterEvents" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Filter Options" selectLabel="select" label="title" track-by="title">
                  <template slot="option" slot-scope="props"><span class="custom__tag"><span>{{ props.option.title }}</span></span></template>
                </multiselect></b-col>
                <b-col cols="*"><b-form-checkbox :check-value="true" :uncheck-value="false" v-model="dayselectboo" @change="setDayDisplay">Group?</b-form-checkbox><br>
                <b-form-checkbox :check-value="true" :uncheck-value="false" v-model="shiftSelectBoo" @input="setShiftDisplay">Shifts?</b-form-checkbox></b-col>
                </b-row></b-container>
        </b-col>
        </b-row>
            </b-container>
        </b-nav-form>
      </b-navbar>
    <b-row class="pt-2" align-v="center">

        </b-row>
    </b-container>
</template>
<script>
    import moment from 'moment';
    import {CHANGE_MONTH} from '../actions';
    import { mapState } from 'vuex'
    import Multiselect from 'vue-multiselect'
    import { bus } from '../../../main'
    import _ from 'lodash'
    export default {
        props : {
            calendarSmall : {
                type: Boolean,
                default: false
            },
            currentMonth : {},
            locale       : {
                type: String
            },
            allEvents : {},
            daydisplay: {
                type: Boolean,
                default: true
            },
            calendarType: {
                type: String,
                default: null,
            }
        },
        components: {
            'multiselect': Multiselect
        },
        data (){
            return {
                localeSelect:'en',
                stationSelectValue: '',
                privateOnly: false,
                userOnly: false,
                resetInput: true,
                dayselectboo: false,
                shiftSelectBoo: false,
                filterSelectValue: '',
                filterOptions: [
                    {data: 'privateOnly', title: 'Private Only'},
                    {data: 'userOnly', title: 'User Only'}
                ]
            }
        },
        created(){
        //    console.log('this display = ' + this.daydisplay)
            if(this.calendaryType == 'payroll'){
                this.$emit('setShiftDisplay', true)
            }
       
        },
        mounted(){
                 this.dayselectboo = !this.daydisplay
        },
        computed: {
            ...mapState([
                'getSessionData',
                'stationsData',
                'userData'
            ]),
            title () {
                if (!this.currentMonth) return;
                return this.currentMonth.locale(this.locale).format('MMM YYYY')
            },
            stationsDataFilter(){
                var array = [{id: '01', stationName: 'View All', stationParent: ''}]
                var stations = this.stationsData
                for(var i in stations){
                    array.push(stations[i])
                }
                return array
            },
            getFilterEvents(){
                var options = this.filterOptions
                var events = _.cloneDeep(this.allEvents)
             //   console.log(events)
                if(events){
                    for(var e in events){
                        var subevents = events[e].description
                        var index = options.map(p => p.value).indexOf(events[e].title)
                        if(index == -1){
                          options.push({data: 'eventTagParent', value: events[e].title, title: events[e].title + ' (Any)'})}
                        for(var s in subevents){
                                var indexsub = options.map(p => p.title).indexOf(subevents[s].eventTag + ' (' + events[e].title + ')')
                        if(indexsub == -1){
                            options.push({data: 'eventTag', parent: events[e].title, value: subevents[s].eventTag, title: subevents[s].eventTag + ' (' + events[e].title + ')'})
                        }
                        }
                    }
                }
                return options
            },
        },

        methods : {
            setDayDisplay(){
                this.$emit('setDayDisplay', this.dayselectboo)
            },
            setShiftDisplay(){
                this.$emit('setShiftDisplay', this.shiftSelectBoo)
            },
            setData(data, value){
                if(value.stationName && value.stationName == 'View All'){
                    this.stationSelectValue = ''
                } else {
                this.$emit('setData', {data: data, value: value})
                }
            },
            setDataFilter(option){
               // console.log(this.filterSelectValue)
                this.$emit('setDataGroup', this.filterSelectValue)
            },

            clearData(){
                this.stationSelectValue = ''
                this.filterSelectValue = ''
                this.resetInput = false
                this.$nextTick(function(){
                    this.resetInput = true
              
                this.$emit('setData', {data: 'stationSelectValue', value: ''})
                 this.$emit('setDataGroup', this.filterSelectValue)
                   })
            },
            goPrev () {
                let payload = moment(this.currentMonth).subtract(1, 'months').startOf('month');
                bus.$emit('CHANGE_MONTH', payload);
            },

            goNext () {
                let payload = moment(this.currentMonth).add(1, 'months').startOf('month');
                bus.$root.$emit('CHANGE_MONTH', payload);
            },

            goToday () {
                bus.$emit('CHANGE_MONTH', moment().startOf('month'));
            },

        /*    setLocale () {
                if(i18n) {
                    i18n.locale = this.localeSelect;
                }
                else {
                    console.warn('Please define global vue locale object named "i18n"!');
                }
            } */
        }
    }
</script>
<style scoped>
    .full-calendar-header{
        display: flex;
        align-items: center;
    }
    .header-center{
        flex:3;
        text-align:center;
    }
    .title {
        font-size: 1.5em;
        font-weight: bolder;
    }
    .language-select {
        display: inline-block;
        width: 50%;
    }
</style>
