<template>
<div id="homelanding">
<b-container fluid class="px-0">
  <b-textarea v-model="uploadedJson" @input="fixDataReport()" />
<b-card v-if="getInventoryItemsList">
<b-table :items="getInventoryItemsList"  />
</b-card>
</b-container>
<b-btn @click="submitBulkInventory">Submit</b-btn>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import PapaParse from 'papaparse'
export default {
components: {
  'multiselect' : Multiselect,

},
data() {
    return {
      showAdmin: false,
      showModal: false,
      inventoryItemsData: [],
      searchData: '',
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      inventoryTemplateData: [],
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
}
},
created () {

},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  getInventoryItemsList(){
    var array = _.cloneDeep(this.inventorySheetData)
   // console.log(array)
    array = array.map(item => {
        var obj = item
        obj.active = true //item.active == 'TRUE' ? true : false
      //  obj.code_fee = item.code_fee == '' ? null : false
        return obj
    })
  //  console.log(array)
    return array
  }

},
mounted(){

},
watch:{

},
methods: {
  addListofItemsToCM(data){
    data = data.map(p => {
        var obj = p
        if(p.active == "TRUE"){
          obj.active = true
        }
        return obj
    })
    console.log(data)
    this.inventorySheetData = data
  },
  fixDataReport(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJson
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.addListofItemsToCM(results.data)
            }
        })
      },
    submitBulkInventory(){
    axios({ 
     method: 'post',
     url: '/api/v1/preplan/inspectioncodes/bulk',
     data: this.getInventoryItemsList,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     this.$emit('getInventoryItems')
   })
    },
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
