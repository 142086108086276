<template>
<div id="homelanding">
  <b-container v-if="watchItems">
    <b-card v-for="par in parentLocationObjs" :key="par.item_loc_01" no-body class="pb-2" border-variant="info" header-tag="header" header-bg-variant="info" header-text-variant="light">
      <b-row slot="header"><b-col>
     <span class="font-weight-bold">{{ par.item_loc_01 }} </span> </b-col>
     <b-col align="right" cols="2"><b-btn v-if="showSecondarys != par.item_loc_01" variant="outline-light" size="sm" @click="showSecondarys = par.item_loc_01">Open</b-btn>

     <b-btn v-if="showSecondarys == par.item_loc_01"  variant="danger" size="sm" @click="showSecondarys = ''">Close</b-btn></b-col>
     </b-row>
     <b-container v-if="showSecondarys == par.item_loc_01" class="pt-2">
        <b-card v-for="item in par.item_loc_01_items" :key="item.holdid" no-body class="pl-4 pt-2 mb-2">
          <b-row>
          <b-col cols="7" align="left">{{ item.item_name }}</b-col>
          <b-col cols="*" >
          <b-btn  size="sm" variant="danger inline-block" @click="item.item_quantity >= 0 ? item.item_quantity = item.item_quantity - 1 : ''">-</b-btn></b-col>
          <b-col class="p-0 m-0" cols="2">
          <b-form-input size="sm" type="number" v-model="item.item_quantity" />
          </b-col><b-col cols="*">
                    <b-btn variant="success" size="sm" @click="item.item_quantity = item.item_quantity + 1">+</b-btn></b-col>
          
          </b-row>
          <b-row class="mt-2">
          <b-col cols="3" class="mb-3">
        <b-form-select size="sm" v-model="item.item_loc_01" :options="getParentLocations" @input="item.item_loc_02 = 'NULL', item.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
      <b-form-select size="sm" v-if="getSecondaryLocations(item.item_loc_01)" v-model="item.item_loc_02" :options="getSecondaryLocations(item.item_loc_01)" @input="item.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
       <b-form-select size="sm" v-if="getThirdLocations(item.item_loc_01, item.item_loc_02)" v-model="item.item_loc_03" :options="getThirdLocations(item.item_loc_01, item.item_loc_02)" />
    </b-col>
          <b-col cols="1"><b-btn variant="primary" size="sm" :disabled="checkDisabled(item)" @click="setUpEditData(item)">Move</b-btn></b-col>
            </b-row>
          </b-card>
    
      <b-card v-for="sec in par.item_loc_02" :key="sec.item_loc_02" no-body class="mt-2 mr-0 pb-2"  border-variant="primary" header-tag="header" header-bg-variant="primary" header-text-variant="light">
        <b-row slot="header"><b-col>
     <span class="font-weight-bold">{{ sec.item_loc_02 }} </span></b-col>
     <b-col alight="right" cols="2"><b-btn v-if="showSecondarysCat != sec.item_loc_02" variant="outline-light" size="sm" @click="showSecondarysCat = sec.item_loc_02">Open</b-btn><b-btn v-if="showSecondarysCat == sec.item_loc_02"  variant="danger" size="sm" @click="showSecondarysCat = ''">Close</b-btn></b-col>
     </b-row>
          <b-container v-if="showSecondarysCat == sec.item_loc_02" class="pr-0">
       <b-container>
         
       </b-container>
    
        <b-card v-for="secitem in sec.item_loc_02_items" :key="secitem.holdid">
          <b-row>
          <b-col cols="7" align="left">{{ secitem.item_name }}</b-col>
          <b-col cols="*" >
          <b-btn  size="sm" variant="danger inline-block" @click="secitem.item_quantity >= 0 ? secitem.item_quantity = secitem.item_quantity - 1 : ''">-</b-btn></b-col>
          <b-col class="p-0 m-0" cols="2">
          <b-form-input size="sm" type="number" v-model="secitem.item_quantity" />
          </b-col><b-col cols="*">
                    <b-btn variant="success" size="sm" @click="secitem.item_quantity = secitem.item_quantity + 1">+</b-btn></b-col>
          
          </b-row>
          <b-row class="mt-2">
          <b-col cols="3" class="mb-3">
        <b-form-select size="sm" v-model="secitem.item_loc_01" :options="getParentLocations" @input="secitem.item_loc_02 = 'NULL', secitem.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
      <b-form-select size="sm" v-if="getSecondaryLocations(secitem.item_loc_01)" v-model="secitem.item_loc_02" :options="getSecondaryLocations(secitem.item_loc_01)" @input="secitem.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
       <b-form-select size="sm" v-if="getThirdLocations(secitem.item_loc_01, secitem.item_loc_02)" v-model="secitem.item_loc_03" :options="getThirdLocations(secitem.item_loc_01, secitem.item_loc_02)" />
    </b-col>
          <b-col cols="1"><b-btn variant="primary" size="sm" :disabled="checkDisabled(secitem)" @click="setUpEditData(secitem)">Move</b-btn></b-col>
            </b-row>
          </b-card>
       

      <b-card v-for="third in sec.item_loc_03" :key="third.item_loc_03" class="mt-2 mr-0"  border-variant="success" header-tag="header" header-bg-variant="success" header-text-variant="light">
        <b-row slot="header"><b-col>
     <span class="font-weight-bold">{{ third.item_loc_03 }} </span></b-col>
     <b-col alight="right" cols="2"><b-btn v-if="showThirdCat != third.item_loc_03" variant="outline-light" size="sm" @click="showThirdCat = third.item_loc_03">Open</b-btn><b-btn v-if="showThirdCat == third.item_loc_03"  variant="danger" size="sm" @click="showThirdCat = ''">Close</b-btn></b-col>
     </b-row>
      <b-container v-if="showThirdCat == third.item_loc_03" class="mr-0">
     
        <b-card v-for="thiritem in third.item_loc_03_items" :key="thiritem.holdid" class="mt-1">
          <b-row>
          <b-col cols="7" align="left">{{ thiritem.item_name }}</b-col>
          <b-col cols="*" >
          <b-btn  size="sm" variant="danger inline-block" @click="thiritem.item_quantity >= 0 ? thiritem.item_quantity = thiritem.item_quantity - 1 : ''">-</b-btn></b-col>
          <b-col class="p-0 m-0" cols="2">
          <b-form-input size="sm" type="number" v-model="thiritem.item_quantity" />
          </b-col><b-col cols="*">
                    <b-btn variant="success" size="sm" @click="thiritem.item_quantity = thiritem.item_quantity + 1">+</b-btn></b-col>
          
          </b-row>
          <b-row class="mt-2">
          <b-col cols="3" class="mb-3">
        <b-form-select size="sm" v-model="thiritem.item_loc_01" :options="getParentLocations" @input="thiritem.item_loc_02 = 'NULL', thiritem.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
      <b-form-select size="sm" v-if="getSecondaryLocations(thiritem.item_loc_01)" v-model="thiritem.item_loc_02" :options="getSecondaryLocations(thiritem.item_loc_01)" @input="thiritem.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
       <b-form-select size="sm" v-if="getThirdLocations(thiritem.item_loc_01, thiritem.item_loc_02)" v-model="thiritem.item_loc_03" :options="getThirdLocations(thiritem.item_loc_01, thiritem.item_loc_02)" />
    </b-col>
          <b-col cols="1"><b-btn variant="primary" size="sm" :disabled="checkDisabled(thiritem)" @click="setUpEditData(thiritem)">Move</b-btn></b-col>
            </b-row>
          </b-card>
 
          </b-container>
        </b-card>
 
        </b-container>
        </b-card>
      
        </b-container>
      </b-card>
  
    
    </b-container>


</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import { bus } from '../../../main';
import draggable from 'vuedraggable';
export default {
components: {
  'multiselect' : Multiselect,
  draggable
},
props: ['newInventoryForm', 'inventoryItemsData', 'reserveform', 'reserve', 'reserveTabToggle'],
data() {
    return {
      searchData: '',
      fields: [{key: 'item_name', field: 'item_name'}, 'item_count', 'tracking', 'note'],
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      inventoryTemplateData: [],
      parentLocationObjs: [],
      editItemHoldId: '',
      editItemObj: '',
      newSubNameHold: '',
      watchItems: true,
      addNewSub: false,
      listOfObjects: [],
      showModal: false,
      filterSupplySearch: '',
      beforeEditItem: '',
      taxonomyTags: [
        {text: 'User', value: 'deptContactData'},
        {text: 'Station', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'Asset', value: 'logisticAssetData'}
      ],
      frequencyOptions: [
        {text: 'Daily', value: 'daily'},
        {text: 'Weekly', value: 'week'},
        {text: 'Bi-weekly', value: 'biweekly'},
        {text: 'Monthly', value: 'monthly'},
        {text: 'Quartly', value: 'quartly'},
        {text: 'Annually', value: 'annually'},
        {text: 'None', value: null}
      ],
      itemTypeOpts: [
        {text: 'Supply Items', value: 'supply'},
        {text: 'Check Item', value: 'check'},
        {text: 'Asset Item', value: 'asset'}
      ],
      showSecondarys: '',
      dataResetSecond: true,
      dataResetThird: true,
      showSecondarysCat: '',
      showThirdCat: '',
      invetoryLastCheckData: [],
      holdValues: {},
      firstLoad: null,
      resetField: '',
      selectedInventoryForm: '',
      databaseIDTag: '',
      dataObj: '',
      sheetTaxonomyTag: null,
      checkHideList: {primary: [], secondary: [], third: []},
}
},
created () {
  this.getSheet(this.newInventoryForm)
  this.dataObj =  _.cloneDeep(this.newInventoryForm)
  if(this.dataObj.inventoryDbid){
    var obj = this[this.dataObj.inventoryType].find(p => p.id == this.dataObj.inventoryDbid)
    if(obj){
      this.dataObj.inventoryDbid = obj
    }
  }
  this.newInventoryForm.latest ? this.invetoryLastCheckData = this.newInventoryForm.latest.inventorySubData : ''
},
beforeDestroy(){

},
watch: {
  reserveTabToggle(newData){
    this.watchItems = false
    this.$nextTick(function(){
      console.log(this.newInventoryForm)
      this.getSheet(this.newInventoryForm)
      this.watchItems = true
    })
  }
},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'logisticAssetData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  filterListOfSupplies(){
    var array = this.inventoryItemsData.filter(p => {
      return p.itemName.toLowerCase().includes(this.filterSupplySearch.toLowerCase())
    })
    var sort = _.orderBy(array, ['itemName'], ['asc'])
    return sort.map(item => {
      var obj = {text: item.itemName, value: item.id, name: item.itemName, qty: item.itemDefault}
      return obj
    })
  },
  parentLoc(){
    var array = this.parentLocationObjs
    return array
  },
  getParentLocations(){
    var array = [...new Set(_.cloneDeep(this.reserveform.inventoryItems).map(p => p.item_loc_01))]
    console.log(array)
    return array
  },
},
mounted(){

},
methods: {
  checkDisabled(item){
    var index1 = this.getParentLocations.indexOf(item.item_loc_01)
    console.log(index1)
    if(index1 != -1){
      return false
    } return true

  },
    getOptionsFromSelection(data){
    if(data){
     // console.log(data)
    return this[data]
    } else {
      return []
    }
  },
  setUpEditData(data){
    this.$emit('addItemToReserve', data)
  },
  getSecondaryLocations(par){
    this.dataResetSecond = false
    var array = _.cloneDeep(this.reserveform.inventoryItems)
    var start = array.filter(p => p.item_loc_01 == par)
    var holdarray = [...new Set(start.map(p => p.item_loc_02))]
    var hold = holdarray.filter(p => {
      return p != 'NULL' && p
    })
    hold.push({text: 'None', value: 'NULL'})
    this.dataResetSecond = true
    return hold
  },
  getThirdLocations(par, sec){
    this.dataResetThird = false
    var array = _.cloneDeep(this.reserveform.inventoryItems)
    var start = array.filter(p => p.item_loc_01 == par && p.item_loc_02 == sec)
    var holdarray = [...new Set(start.map(p => p.item_loc_03))]
        var hold = holdarray.filter(p => {
      return p != 'NULL' && p })
    holdarray.push({text: 'None', value: 'NULL'})
    this.dataResetThird = true
    return holdarray
  },
  getSheet(data){
    if(data.latest.id){
    axios({ 
     method: 'get',
     url: '/api/v1/content/inventorysub/' + data.latest.id,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     if(response.data[0]){
          this.setParents(response.data[0])
     }
   })
    } else {
      this.setParents(data.latest)
    }
  },
  setParents(data){
    var obj1 = _.cloneDeep(data.inventorySubData) 
    var par = [... new Set(obj1.map(p => p.item_loc_01))]
    var hold = par.map(item => {
        var obj = {}
        obj.item_loc_01 = item
        obj.item_loc_01_items = this.getItemsFilter(item, 'NULL', 'NULL', data)
        obj.item_loc_02 = this.getSecondaryCats(item, data)
        return obj
    })
    this.parentLocationObjs = hold
  },
  viewPreviousSheet(data){
    axios({ 
     method: 'get',
     url: '/api/v1/content/inventorysub/' + data.id,
     headers: {
         'Authorization': this.getSessionData,
         'Content-Type' : 'application/json',
     }
   }).then( response => {
     if(response.data[0]){
           this.previousInventoryData.push(response.data[0])
     }
   })
    },
  getSecondaryCats(par, data){
    var hold = _.cloneDeep(data.inventorySubData)
    var obj = hold.filter(p => {
      return p.item_loc_01 == par
    })
    var par2 = [... new Set(obj.map(p => p.item_loc_02))]
    var sec = par2.filter(p => {
      return p != 'NULL' && p
    })
    var map = sec.map(item => {
        var obj = {}
        obj.item_loc_02 = item
        obj.item_loc_02_items = this.getItemsFilter(par, item, 'NULL', data)
        obj.item_loc_03 = this.getThirdCats(par, item, data)
        return obj
    })
    return map
  },
  getThirdCats(par2, sec2, data){
    var hold = _.cloneDeep(data.inventorySubData)
    var obj = hold.filter(p => {
      return p.item_loc_01 == par2 && p.item_loc_02 == sec2
    })
    var par = [... new Set(obj.map(p => p.item_loc_03))]
    var sec = par.filter(p => {
      return p != 'NULL' && p
    })
    var map = sec.map(item => {
        var obj = {}
        obj.item_loc_03 = item
        obj.item_loc_03_items = this.getItemsFilter(par2, sec2, item, data)
        return obj
    })
    return map
  },
  getItemsFilter(one, two, three, data){
    var hold = _.cloneDeep(data.inventorySubData)
    var obj = hold.filter(p => {
      return p.item_loc_01 == one && p.item_loc_02 == two && p.item_loc_03 == three
    })
    var map = obj.map(item => {
      var newobj = item
      if(!item.holdid){
      newobj['holdid'] =  parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
      }
      return newobj
    })
    return map
  },

  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
