<template>
  <div>
<b-card nobody  v-if="!showSinglePost">
  <b-btn block @click="showVideoPan = !showVideoPan">{{ tut.tutorialName }}</b-btn>
  <div v-if="showVideoPan" class="videoWrapper">
  <iframe :src="videoLinkSetup(tut.tutorialUrl)" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
</div>
</b-card>
<div v-if="showSinglePost" class="videoWrapper">
  <iframe :src="tutorial_video" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
props:["tut", "singlePost"],
components:{

},
  data () {
    return {
      showVideoPan: false,
      showSinglePost: false,

    }
  },
  created(){
    if(this.singlePost != ''){
      this.showSinglePost = true
    }
  },
  computed:{
    ...mapState([
      'departmentTutorials'
    ]),
    tutorial_video(){
      if(this.singlePost != ''){
        var videoSort = this.departmentTutorials.filter( (p) => {
          return p.tutorialTag.match(this.singlePost)
        })
        var videoSortLink = videoSort[0].tutorialUrl
        var fileSetup = this.videoLinkSetup(videoSortLink)
        this.showSinglePost = true
        return fileSetup
    } else {
      return ''
    }
  }
  },
   methods:{
     videoLinkSetup(data){
       var baselink = 'https://player.vimeo.com/video/'
       var initial = data.split('.com/')
       var number = initial[1]
       return baselink + number
     }

  },
}
</script>

<style scoped>
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
