<template>
  <div class="apparatus">
    <h4 id="filterHeading">Narcotic Logs</h4>
    <div class="bodyData">
        <h4 class="errorText" v-if="filteredApparatusNarcs == 0 && filteredApparatusDailyNarcs == 0">No NarcLogs Entered</h4>
          <div v-if="(filteredApparatusNarcs != 0 || filteredApparatusDailyNarcs != 0 )">
    <div v-for="(narcs, index) in filteredApparatusNarcs" :key="index" class="narcContainer">
      <narcdata :narcs="narcs" />
    </div>
    <div v-for="(narcs, index) in filteredApparatusDailyNarcs" :key="index + '_daily'" class="narcContainer">
      <dailyNarctrack :narcs="narcs" />
    </div>
  </div>
     <div v-if="narcArray">
    <div v-for="(narcs, index) in narcArray" :key="index" class="narcContainer">
      <narcdata :narcs="narcs" />
    </div>
  </div>
</div>
  </div>

</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import narcData from './apparatusFilterData'
import dailyNarctrack from '../dailynarctrack/apparatusFilterData'
export default {
  props: ["searchShift", "searchText", "filterAuthor", "filterDate", "filterApparatus", "narcArray"],
  name: 'apparatus',
  components: {
    'narcdata' : narcData,
    dailyNarctrack
  },
  data () {
    return {
      items: [],
      apparatusAppNarcsReturned: [],
      apparatusDailyAppNarcsReturned: []
    }
  },
created(){
//  console.log('TEST CONSOLES')
  //    console.log(this.apparatusDailyAppNarcs)
  },
mounted(){

},
computed: {
  ...mapState([
        'apparatusAppNarcs',
        'apparatusDailyAppNarcs'
    ]),
    apparatusDailyAppNarcsTest(){
      if(this.apparatusDailyAppNarcs){
        return this.apparatusDailyAppNarcs
      } else {
        return []
      }
    },
    filteredApparatusDailyNarcs(){
      if(!this.narcArray){
        var array = this.apparatusDailyAppNarcsTest
      } else {
        var array = this.apparatusDailyAppNarcsReturned
      }
    //  console.log(array)
       var narc = array.filter(check => {
       if(this.filterDate){
       var date = this.narcAppDate(check.narctrackDate)
       var momTest = moment(date, 'MM/DD/YYYY').isSame(this.filterDate, 'MM/DD/YYYY')
       if(momTest == true){
        return check
      }      
       } else {
         return check
       }  
        /* check.title.toLowerCase().match(this.searchText.toLowerCase())
        && check.narctrackShift.match(this.searchShift)
        && check.narctrackAuthor.match(this.filterAuthor)
        && check.narcstrackApparatusId.match(this.filterApparatus) 
        && */ 
      })
      if(this.filterDate){
        var sort = 'asc'
      } else {
        var sort = 'desc'
      }
      return _.orderBy(narc, ['narctrackDate'], [sort])
    },
    filteredApparatusNarcs(){
      if(!this.narcArray){
     //   console.log('Correct x2')
        this.apparatusAppNarcsReturned = this.apparatusAppNarcs
      }
       var narc = this.apparatusAppNarcsReturned.filter((check) => {
       if(this.filterDate){
       var date = this.narcAppDate(check.narctrackDate)
       var momTest = moment(date, 'MM/DD/YYYY').isSame(this.filterDate, 'MM/DD/YYYY')
       if(momTest == true){
        return check
      }      
       } else {
         return check
       }  
        /* check.title.toLowerCase().match(this.searchText.toLowerCase())
        && check.narctrackShift.match(this.searchShift)
        && check.narctrackAuthor.match(this.filterAuthor)
        && check.narcstrackApparatusId.match(this.filterApparatus) 
        && */ 
      })
      if(this.filterDate){
        var sort = 'asc'
      } else {
        var sort = 'desc'
      }
      return _.orderBy(narc, ['narctrackDate'], [sort])
    }
  },
methods: {
  narcAppDate(data){
    var date = moment(data).format('MM/DD/YYYY')
    return date
  },

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 5px;
  border-radius: 0 0 5px 5px;
}
h4.errorText {
  text-align: center;
  margin-top: 10px;
  color: #e2070d;
  font-style: italic;
}
</style>
