<template>
  <div class="hello">
   <b-table :fields="fields" :items="tableData">
     <template slot="narctrackDate" slot-scope="data">
       {{ getDateTime(data.item.narctrackDate )}}
    </template>
     <template slot="narctrackEntryType" slot-scope="data">
       <b-badge :variant="cardVariant[data.item.narctrackEntryType]">{{ data.item.narctrackEntryType }}</b-badge>
     </template>
     <template slot="narctrackAuthor" slot-scope="data">
       <span :class="!data.item.narctrackAuthorSignatureBoo ? 'text-danger font-weight-bold' : 'text-success'">{{ data.item.narctrackAuthor }}</span>
       </template>
      <template slot="narctrackWitness" slot-scope="data">
       <span :class="!data.item.narctrackWitnessSignatureBoo ? 'text-danger font-weight-bold' : 'text-success'">{{ data.item.narctrackWitness }}</span>
       </template>
       <template slot="Card" slot-scope="data">
         <b-btn size="sm" :variant="cardVariant[data.item.narctrackEntryType]" @click.stop="showNarcCard = !showNarcCard, setNarcCardData(data.item)">View</b-btn>
        </template>
     </b-table>
     <b-modal v-if="narcCardData" v-model="showNarcCard" :title="narcCardData.title">
       <narcCard :narcs="narcCardData" :showPost="true" />
       </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import narcCard from '../../tracking/dailynarctrack/apparatusFilterData.vue'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'hello',
  props: ['tableData', 'medsNarctrack'],
  components: {
    narcCard
  },
  data () {
    return {
      fields: [
        {key: 'narctrackDate', label: 'DateTime'},
        {key: 'narctrackEntryType', label: 'Type'},
        {key: 'narctrackTagId', label: 'Tag'},
        {key: 'narctrackAuthor', label: 'Author'},
        {key: 'narctrackWitness', label: 'Witness'},
        {key: 'narctrackRunNumber', label: 'Secondary Tag'},
        'Card'
      ],
      showNarcCard: false,
      narcCardData: '',
      cardVariant: {
            verification: 'success',
            use: 'warning',
            restock: 'primary',
            distribution: 'info',
            destroy: 'danger',
            quarantine: 'danger',
            initial: 'secondary'
      }
    }
  },
  created(){
    if(this.userData.role == 'admin'){
      this.fields.push('admin')
    }
      
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'getSessionData',
        'documentsApi'
      ]),
    
    

  },
  watch: {
    
  },
  methods: {
    getDateTime(date){
      var data = date ? moment(date).format('MM/DD/YY HH:mm') : 'Unknown'
      return data
    },
    setNarcCardData(data){
       axios({
        method: 'get',
        url: '/api/v1/content/dailynarctrack/' + data.id,
        headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
      this.narcCardData = response.data
    })
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
