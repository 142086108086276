<template>
<div>
<div v-if="watchForChange">
<h4 id="filterHeading">{{ workgroupData.length > 0 ? workgroupreturned + ' (Work Group)' : 'No WorkGroups Added' }} <span class="floatRight"><b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief'" size="sm" @click="showAdmin = !showAdmin" :variant="checkState">Admin</b-btn></span></h4>
<div v-if="showAdmin">
  <taxonomy :defaultTax="'workgroup'" />
</div>
<div class="bodyData" v-if="!showAdmin && workgroupData.length > 0">
  <b-container fluid>
    <b-row v-if="userEditCheck"><b-col align="right"><b-btn size="sm" @click="editUserList = !editUserList" variant="warning">Edit Users</b-btn></b-col></b-row>
    <b-container  v-if="editUserList"><b-row><b-col>
      
             <h4>Users Assigned to the Group</h4>
        <em>will show up as a group participant</em>
        <multiselect v-model="userEditList" :options="deptContactData" :multiple="true" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer User">
           <template slot="option" slot-scope="props">
             <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
            </template>
        </multiselect>
      </b-col><b-col cols="*">
      
      <b-btn size="sm" @click="saveChanges">Save Changes</b-btn>
      </b-col></b-row></b-container>
    <b-row>
      <b-col xl="4" v-if="currentWorkgroup.workgroupCalendar == true">
        <div class="hello">
           <div class="headerSpan">Group Events</div>
          <div v-for="event in workgroupEventData" :key="event.index">
            <h4>{{ dateFormat(event.eventDate) }}</h4>
        <events :eventdata="event" />
        </div>
        </div>
      </b-col>
      <b-col xl="5" v-if="currentWorkgroup.workgroupBlog == true">
        <div class="hello">
          <div class="headerSpan">Group Blogs</div>
        <b-card v-for="blogs in workgroupBlogData" :key="blogs.index">
          <blog :blog="blogs" />
        </b-card>
        </div>
      </b-col>
      <b-col xl>
        <personnel :personnelId="currentWorkgroup.workgroupUsers" />
      </b-col>
      
    </b-row>
  </b-container>
     
  </div>
</div>

</div>



</template>
<script>
/*
<div id="dvcBodyDiv">
<h5 :class="shiftDateCalc">{{ check.title }}</h5>
<span><h6>Current:{{ check.currentHrs }}hrs | Till Pm:{{ check.pmHrs }}hrs | KnoxKeys:{{ check.knoxBox }} </h6></span>
</div>
*/

import { mapState } from 'vuex'
import Personnel from '../personnel/personnelData.vue'
import Blog from '../blogs/blogsPage.vue'
import Events from '../events/eventsfilterdata.vue'
import Admin from '../../admin/adminTaxonomy.vue'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
  props:['wgName'],
components:{
    'personnel': Personnel,
    'blog' : Blog,
    'events' : Events,
    'taxonomy' : Admin,
    'multiselect': Multiselect,
},
  data () {
    return {
      currentWorkgroup: '',
      workgroupBlogData: [],
      workgroupEventData: [],
      watchForChange: true,
      editUserList: false,
      showAdmin: false,
      userEditCheck: false,
      userEditList: '',
     workGroupId: '', 
    }
  },
  create(){

  },
  watch: {
    wgName(newData){
          this.editUserList = false
    }
  },
  computed:{
    ...mapState([
          'getSessionData',
          'userData',
          'workgroupData',
          'deptContactData'
      ]),
      workgroupreturned(){
        if(this.wgName){
        this.userEditCheck = false
        var wg = this.workgroupData.filter( p => {
          return p.workgroupName.toLowerCase() == this.wgName.replace(/-/g, ' ')
        })
        this.currentWorkgroup = wg[0]
        if(wg[0].workgroupBlog == true){
          this.getWorkGroupBlog(wg[0].workgroupName)
        }
        if(wg[0].workgroupCalendar == true){
          this.getWorkGroupEvent(wg[0].workgroupName)
        }
        var index = wg[0].workgroupAdmins.indexOf(this.userData.id)
        if(index >= 0){
          this.userEditCheck = true
          var array = []
          var data = wg[0].workgroupUsers
        for(var i = 0; i < data.length; i++){
            var user = this.deptContactData.filter( p => {
            return p.id == data[i]
            })
            if(user.length > 0){
        array.push(user[0])
            }
          }
          this.userEditList = array
          this.workGroupId = wg[0].id
        }
        return wg[0].workgroupName
        } else {
          return 'No Work Group Selected'
        }
      },
      checkState(){
      if(this.showAdmin == true){
      return 'light'
       } else {
      return 'outline-light'
     }
    }
      

  },
  methods: {
    dateFormat(data){
      return moment(data).format('dddd, MMMM Do YYYY')
    },
    getWorkGroupEvent(data){
      axios({
        method: 'get',
        url: '/api/v1/content/event/eventsub/' + data,
        headers: {
          'Authorization': this.getSessionData,
          'content-type': 'application/json'
        },
      }).then( response => {
        this.workgroupEventData = response.data
      })
    },
    getWorkGroupBlog(data){
      axios({
        method: 'get',
        url: '/api/v1/content/blog/blogsub/' + data,
        headers: {
          'Authorization': this.getSessionData,
          'content-type': 'application/json'
        },
      }).then( response => {
        this.workgroupBlogData = response.data
      })
    },
   saveChanges(){
    this.watchForChange = false
    var users = this.userEditList
    var users = users.map(p => p.id)
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'workgroup', headers: headers}
    axios({
      method: 'patch',
      url: '/api/v1/taxonomy/workgroup/' + this.workGroupId,
      data: {
        'workgroupUsers': users,
      },
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
          this.watchForChange = true
  
      })
    
  },
  }
}
</script>

<style scoped>
.floatRight {
  float: right;
}
.hello{
  min-width: 300px;
  padding-bottom: 10px;
  margin: 20px 0 0 10px;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
}
.headerSpan {
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
}
div.headerSpan {
  background: #777;
  width: 100%;
  border-radius: 5px 5px 0 0;
  margin-bottom: 10px;
}
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 10px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  min-height: 10px;
  margin-top: 20px;
  margin-bottom: 10px;

}
h4.errorText {
  text-align: center;
  margin-top: 10px;
  color: #e2070d;
  font-style: italic;
}
a {
  text-decoration: none;
}
h4 {
  margin-bottom: 5px;
}
#dvcBodyDiv {
  text-align: center;
}


</style>
