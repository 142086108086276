<template>
<div>
  <b-card header-tag="header" footer-tab header-bg-variant="primary" no-body>
    <div slot="header">
      <span class="text-light">
        Payroll Modification
        </span>
    </div>
    <b-container fluid class="py-3">
      <b-row class="mb-2"><b-col :md="dataObj.payrollModType == 'exchange' ? 6 : 12" class="pt-2">
        <span>Target: </span>
        <span v-if="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief')">{{ dataObj.payrollModTargetUserId.lastName }}</span>
        <multiselect 
        v-if="userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief'"
        v-model="dataObj.payrollModTargetUserId" 
        :disabled="submitAsOccupied"
        :options="deptContactDataOrdered" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </b-col><b-col md="6"  v-if="dataObj.payrollModType == 'exchange'" class="pt-2">
          <span>Exchange User: </span>
           <span v-if="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id">{{ dataObj.payrollModExchangeUserId.lastName }}</span>
           <multiselect 
           v-if="(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') || !dataObj.id"
           v-model="dataObj.payrollModExchangeUserId" 
          :options="deptContactDataOrdered" 
          :multiple="false" :close-on-select="true" :clear-on-select="true" 
          :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
          track-by="id"><template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </b-col></b-row>
        <b-row class="mb-2"><b-col md="6">
          <span>Category</span>
          <b-form-select 
          :disabled="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') 
          && dataObj.id != undefined" 
          v-model="dataObj.payrollModType" 
          :options="payrollCodeTypes" />
          </b-col><b-col md="6">
            <span>Type</span>
       <b-form-select :disabled="(dataObj.payrollModType == 'exchange' || dataObj.payrollModType == '') || (!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') 
          && dataObj.id != undefined)"
                      v-model="dataObj.payrollModCode"
                      @change="testPayrollMod(dataObj.payrollModCode) ? (preApprovedPayroll = false, dataObj.payrollModApproved = null, firstTestFlag = true) : false"
                      :options="filterPayroleCodes(dataObj.payrollModType)" />
      </b-col></b-row>
            <b-row class="mb-2 pt-2" v-if="(dataObj.payrollModType == 'roster mod' || dataObj.payrollModType == 'overtime')  && !siteconfig.payroll.flexibleSchedule">
              <b-col :md="dataObj.payrollModType == 'overtime' ? '2' : '4'">
                <b-form-select :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" v-model="dataObj.payrollModRosterPosition" 
                :disabled="(!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined) || offRosterFlag" />
                </b-col>
                <b-col :md="dataObj.payrollModType == 'overtime' ? '6' : '8'">
               <multiselect
                v-model="rosterId" 
                :options="rosterDataReturn" 
                :disabled="offRosterFlag || (!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined)"
                :multiple="false" :close-on-select="true" :clear-on-select="true" 
                :hide-selected="false" :preserve-search="true" placeholder="Roster Spot" 
                label="apparatusName"
                :custom-label="nameWithLang"
                track-by="id"></multiselect>
        </b-col>
        <b-col md="4" v-if="dataObj.payrollModType == 'overtime' && !siteconfig.payroll.flexibleSchedule">
          <b-form-checkbox v-model="offRosterFlag" :value="true" :uncheck-value="false" 
          @input="offRosterFlag == true ? (rosterId = '', dataObj.payrollModRosterPosition = null) : ''">Off Roster?</b-form-checkbox>
          </b-col>
            </b-row>
            <b-row v-if="dataObj.payrollModType == 'scheduled leave' && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user')) && !siteconfig.payroll.flexibleSchedule">
             <b-col>
              <b-form-checkbox v-model="submitAsOccupied" :value="true" :unchecked-value="false" 
               @input="submitAsOccupied == true ? (dataObj.payrollModTargetUserId = null, dataObj.payrollModApproved = true) : (preApprovedPayroll = false, dataObj.payrollModApproved = null)">Show as unavailable leave slot on scheduled leave calendar</b-form-checkbox>
               </b-col> 
              </b-row>
            <b-row class="mb-2 pt-2">
              <b-col md="4">
                <span>Date</span>
                <b-form-input :disabled="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined" type="date" :state="checkState(selectedDate, 'date')" v-model="selectedDate" />
                </b-col>
                <b-col md="4">
                <span>Time</span>
                <b-form-input :disabled="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined" type="time" :state="checkState(selectedTime, 'time')" v-model="selectedTime" />
                </b-col>
                <b-col md="4">
                <span>{{ testCodeForDollars(dataObj.payrollModCode) ? 'Amount' : 'Duration' }}</span>
                 <b-input-group :prepend="testCodeForDollars(dataObj.payrollModCode) ? '$' : ''" :append="testCodeForDollars(dataObj.payrollModCode)  ? '' : 'Hrs'">
                <b-form-input append="Hrs" :disabled="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined" :state="checkState(dataObj.payrollModHours, 'hours')" type="number" v-model="dataObj.payrollModHours" />
                   </b-input-group>
                </b-col>
                </b-row>
                <template  v-if="dataObj.payrollModType == 'scheduled leave'">
              <b-row class="mb-2 pt-2" v-for="(days, index) in additionalLeaveDays" :key="index">
              <b-col md="4">
                <span>Date</span>
                <b-form-input :disabled="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined" type="date" :state="checkState(selectedDate, 'date')" v-model="days.selectedDate" />
                </b-col>
                <b-col md="4">
                <span>Time</span>
                <b-form-input :disabled="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined" type="time" :state="checkState(selectedTime, 'time')" v-model="days.selectedTime" />
                </b-col>
                <b-col md="3">
                <span>{{ testCodeForDollars(dataObj.payrollModCode) ? 'Amount' : 'Duration' }}</span>
                 <b-input-group :prepend="testCodeForDollars(dataObj.payrollModCode) ? '$' : ''" :append="testCodeForDollars(dataObj.payrollModCode)  ? '' : 'Hrs'">
                <b-form-input append="Hrs" :disabled="!(userData.role == 'admin' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') || userData.role == 'adminchief') && dataObj.id != undefined" :state="checkState(dataObj.payrollModHours, 'hours')" type="number" v-model="days.payrollModHours" />
                   </b-input-group>
                </b-col>
                <b-col md="1">
                 
                  <b-btn class="mt-4" size="sm" variant="danger" @click="removeAdditionalDay(days.selectedDate)">X</b-btn>
                </b-col>
                </b-row>
                </template>
                <b-row v-if="dataObj.payrollModType == 'scheduled leave' && !previousMod.id">
                  <b-col><b-btn variant="info" block size="sm" @click="addAdditionalLeaveDays" :disabled="additionalLeaveDays && additionalLeaveDays.length >= 4">Add Additional Leave Day</b-btn></b-col>
                </b-row>
      <b-row class="mb-2 pt-2">
        <b-col md="12"><span>Notes</span>
        <b-textarea type="text" :state="(dataObj.payrollModApproved == false || submitAsOccupied || noteRequiredFlag) && dataObj.payrollModNote == '' ? false : null" v-model="dataObj.payrollModNote" />
        </b-col>
      
      </b-row>
      <b-btn v-if="dataObj.payrollModHistory" block variant="info" v-model="showModificationHistory" @click="showModificationHistory = !showModificationHistory">View Modification History</b-btn>
    <b-container v-if="showModificationHistory">
    <b-row v-for="(history, index) of dataObj.payrollModHistory" :key="index">
      <b-col cols="3">
        Changed by <br>
        {{ getUserInfo(history.changedBy) }} <br>
        on <br> {{ getFormatedDateTime(history.date) }}
        </b-col>
        <b-col>
          Previous Entry
          <b-table v-if="history.previous" :fields="fields" :items="[history.previous]" stacked>
            <template slot="updatedAt" slot-scope="data">
              <span v-if="data.item.updatedAt">{{ getFormatedDateTime(data.item.updatedAt) }}</span>
            </template>
             <template slot="payrollModDatetime" slot-scope="data">
              <span v-if="data.item.payrollModDatetime">{{ getFormatedDateTime(data.item.payrollModDatetime) }}</span>
            </template>
            <template slot="payrollModCode" slot-scope="data">
               <span v-if="data.item.payrollModCode" :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
            </template>
            <template slot="payrollModApproved" slot-scope="data">
                <b-badge v-if="data.item.payrollModApproved" :variant="getApprovalStatusColor(data.item.payrollModApproved)"><h6 class="mb-0">{{ getApprovalStatus(data.item.payrollModApproved) }}</h6></b-badge>
             </template>
            <template slot="payrollModTargetUserId" slot-scope="data">
              <span v-if="data.item.payrollModTargetUserId">{{ getUserInfo(data.item.payrollModTargetUserId) }}</span>
            </template>
            <template slot="payrollModExchangeUserId" slot-scope="data">
              <span v-if="data.item.payrollModExchangeUserId">{{ getUserInfo(data.item.payrollModExchangeUserId) }}</span>
            </template>
            <template slot="payrollModApprovalUserId" slot-scope="data">
              <span v-if="data.item.payrollModApprovalUserId">{{ getUserInfo(data.item.payrollModApprovalUserId) }}</span>
            </template>
            </b-table>
          </b-col>
      </b-row>
      </b-container>
    </b-container>  
        <div slot="footer">
          <b-container fluid>
            <b-row  v-if="dataObj.payrollModCode">
          <b-col v-if="dataObj.id && checkPermissionsRequired && checkForDeletePermissions">
          <b-form-checkbox
          id="deleteCheckbox"
          v-model="deleteBoolean" :value="true" :uncheck-value="false">Delete Payroll Modification</b-form-checkbox><br>
          <b-btn :disabled="!deleteBoolean" @click="deletePayrollModification(dataObj.id)" variant="danger">Delete</b-btn>
          </b-col>
          <b-col  v-if="(dataObj.payrollModApproved == null) 
             && dataObj.payrollModExchangeUserId == '' && testCodeForEmailRequest(dataObj.payrollModCode)" >
        <div :class="testCodeForEmailRequest(dataObj.payrollModCode) == 'required' ? 'inputError' : ''">
        <multiselect v-model="messageOtherUserObj" 
        :options="deptContactDataReturn" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Send Request Notification to:" label="lastName" 
        track-by="id">                    
          <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName }} {{ props.option.lastName }} | {{ props.option.stationAssignment }}</span></template>
        <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
          </b-col>
          <b-col v-else-if="dataObj.id && !checkForDeletePermissions">
              <h6 class="text-danger">Unable to Remove (past date)</h6>
          </b-col>  
          <b-col v-else>
          </b-col>  
          <b-col>
          <b-container v-if="(dataObj.id != undefined && dataObj.payrollModExchangeUserId.id == userData.id) || ((userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user')) && dataObj.payrollModExchangeUserId && dataObj.payrollModExchangeApproved == null)">
         This submission determines if {{ dataObj.payrollModExchangeUserId.lastName }} agrees to work for {{  dataObj.payrollModTargetUserId.firstName  }} {{ dataObj.payrollModTargetUserId.lastName }} on the Above Date 
         <br> <b-form-checkbox id="trueCheckBox"
              v-model="dataObj.payrollModExchangeApproved" :check-value="true" :unchecked-value="false"
            >{{ dataObj.payrollModExchangeApproved == false || dataObj.payrollModExchangeApproved == null ? 'Check to Approve:' : 'Uncheck to Deny'}}</b-form-checkbox><br>
            I {{ dataObj.payrollModExchangeApproved == false ? 'DO NOT' : ''}} agree to the Exchange
          </b-container>
        
          <span @click="checkFlag = 'trueFlag'">
          <b-form-checkbox
                      v-if="dataObj.payrollModCode && !preApprovedPayroll && !checkPayrollApprovalRequirement"
                      :disabled="checkPayrollApprovalRequirement" 
                      v-model="dataObj.payrollModApproved" 
                      id="approveCheckbox"
                      :value="true" 
                      :uncheck-value="null"
                  
                      @change="changePayrollValueTrue($event), checkNotificationAndNote(dataObj.payrollModCode), deniedHold = null"
                      >Payroll Modification Approved</b-form-checkbox>
                      </span><span @click="checkFlag = 'falseFlag'">
                    <b-form-checkbox
                    id="deniedCheckbox"
                    v-if="dataObj.payrollModCode && !preApprovedPayroll && !checkPayrollApprovalRequirement"
                      v-model="deniedHold"                      
                      :value="true" 
                      :uncheck-value="null"
                      
                      @change="changePayrollValue($event), checkNotificationAndNote(dataObj.payrollModCode)">Payroll Modification Denied</b-form-checkbox>
                  <b-form-checkbox
                    id="deniedCheckbox2"
                    v-if="previousMod && previousMod.payrollModCode && previousMod.payrollModApproved == null && previousMod.payrollModTargetUserId == userData.id"
                      v-model="deniedHold"                      
                      :value="true" 
                      :uncheck-value="null"
                      
                      @change="changePayrollValue($event), checkNotificationAndNote(dataObj.payrollModCode)">Deny Payroll Request</b-form-checkbox>
            </span>
              <b-form-checkbox v-model="messageUser" v-if="payrollApprovalFlag != null && dataObj.payrollModTargetUserId.id != userData.id">Notify User?</b-form-checkbox>
              <b-form-checkbox v-model="messageOtherUser" v-if="siteconfig.payroll.approvalNofitications && userData.id == dataObj.payrollModTargetUserId.id">Notify Approver?</b-form-checkbox>
               <multiselect 
        v-if="messageOtherUser"
        v-model="messageOtherUserObj" 
        :options="deptContactDataOrdered" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
          <b-btn 
              v-if="dataObj.payrollModApproved == null 
              && dataObj.payrollModExchangeUserId != '' 
              && dataObj.payrollModExchangeApproved == null 
              && dataObj.payrollModExchangeUserId.id != userData.id"  
              @click="submitNewPayrollMod(dataObj)" 
              :disabled="!dataObj.payrollModTargetUserId || !checkState(selectedDate, 'date') || !checkState(selectedTime, 'time') || !checkState(dataObj.payrollModHours, 'hours')">Submit Request</b-btn>
          <b-btn 
             v-if="(dataObj.payrollModApproved == null) 
             && dataObj.payrollModExchangeUserId == '' && previousModCheck" 
             variant="primary" 
             @click="submitNewPayrollMod(dataObj)"
             :disabled="!dataObj.payrollModTargetUserId  || !checkState(selectedDate, 'date') || !checkState(selectedTime, 'time') || !checkState(dataObj.payrollModHours, 'hours') || (testCodeForEmailRequest(dataObj.payrollModCode) == 'required' && !messageOtherUserObj)">Submit for Approval</b-btn>
          <b-btn 
             v-if="(dataObj.payrollModApproved == null) 
             && dataObj.payrollModExchangeUserId != '' 
             && dataObj.payrollModExchangeApproved != null" 
             :variant="dataObj.payrollModExchangeApproved ? 'primary' : 'danger'"
             :disabled="!dataObj.payrollModTargetUserId  || !checkState(selectedDate, 'date') || !checkState(selectedTime, 'time') || !checkState(dataObj.payrollModHours, 'hours')" 
             @click="submitNewPayrollMod(dataObj)">{{ dataObj.payrollModExchangeApproved ? 'Submit for Approval' : 'Deny Request' }}</b-btn>
          <b-btn 
             v-if="(dataObj.payrollModApproved == true 
             || dataObj.payrollModApproved == false) && !submitAsOccupied" 
             :disabled="(dataObj.payrollModApproved == false && dataObj.payrollModNote == '' ? true : false) 
                          || !checkState(selectedDate, 'date') 
                          || !checkState(selectedTime, 'time') 
                          || !checkState(dataObj.payrollModHours, 'hours') 
                          || (noteRequiredFlag && dataObj.payrollModNote == '' ? true : false) 
                          || !dataObj.payrollModTargetUserId 
                          || ((dataObj.payrollModType == 'roster mod' 
                          || (dataObj.payrollModType == 'overtime'
                          && !offRosterFlag)) 
                          && (!rosterId || !dataObj.payrollModRosterPosition))"  
                          @click="submitNewPayrollMod(dataObj)" 
                          :variant="dataObj.payrollModApproved == true ? 'success' : 'danger'">Submit As {{ dataObj.payrollModApproved == true ? 'Approved' : 'Denied' }}</b-btn>
             <b-btn 
             v-if="(dataObj.payrollModApproved == true 
             || dataObj.payrollModApproved == false) && submitAsOccupied" 
              :disabled="dataObj.payrollModNote == ''"
              @click="submitNewPayrollMod(dataObj)" variant="success">Submit Leave Note</b-btn>
          </b-col>
            </b-row>
            <b-row><b-col><em>Submission Author: {{ getUserInfo(dataObj.payrollModAuthorId) }}</em></b-col></b-row>
            <b-row v-if="dataObj.payrollModApprovalUserId"><b-col><em>Approved by: {{ getUserInfo(dataObj.payrollModApprovalUserId) }}</em></b-col></b-row>
          </b-container>
        </div>
    </b-card> 
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios'
import multiselect from 'vue-multiselect'
import moment from 'moment'
export default {
  props:['payrollCodesData', 'previousMod', 'rosterData', 'payrollPermsData'], 
components:{
      multiselect
},
  data () {
    return {
      selectedDate: '',
      selectedTime: '',
      preApprovedPayroll: false,
      previousData: false,
      showModificationHistory: false,
      deleteBoolean: false,
      deniedHold: null,
      offRosterFlag: false,
      approvalAuthority: false,
      rosterId: '',
      messageOtherUser: false,
      messageOtherUserObj: '',
      submitAsOccupied: false,
      firstTestFlag: true,
      checkFlag: '',
      payrollApprovalFlag: null,
      messageUser: false,
      noteRequiredFlag: false,
      emailUserSelected: null,
      additionalLeaveDays: [],
      fields: [
      'updatedAt',
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModCode', label: 'Code'},
      {key: 'payrollModApproved', label: 'Status'},
      {key: 'payrollModHours', label: 'Hours'},
      {key: 'payrollModRosterId', label: 'Roster Spot'},
      {key: 'payrollModTargetUserId', label: 'Target'},
      {key: 'payrollModExchangeUserId', label: 'ExOn'},
      {key: 'payrollModExchangeApproved', label: 'ExApproved'},
      {key: 'payrollModApprovalUserId', label: 'Approved Id'},
      {key: 'payrollModNote', label: 'Note'}
    ],
      dataObj: {  payrollModType: '',
                  payrollModCode: '', 
                  payrollModRosterId: '', 
                  payrollModTargetUserId: '', 
                  payrollModExchangeUserId: '',
                  payrollModExchangeApproved: null, 
                  payrollModDatetime: '', 
                  payrollModHours: 24.0, 
                  payrollModAuthorId: '',
                  payrollModApprovalUserId: '',
                  payrollModRosterPosition: null,
                  payrollModApproved: null,
                  payrollModNote: '' }
    }
  },
  created(){
    if(this.previousMod){
    //  console.log(this.rosterData)
    //  console.log(this.previousMod)
      this.dataObj = _.cloneDeep(this.previousMod)
      this.selectedDate = moment(this.previousMod.payrollModDatetime).format('YYYY-MM-DD')
      this.selectedTime = moment(this.previousMod.payrollModDatetime).format('HH:mm:ss')
      this.rosterId = this.getRosterDataFromPrevious(this.previousMod.payrollModRosterId)
      if(this.previousMod.payrollModRosterId == "offroster" ){
        this.rosterId = '', 
        this.payrollModRosterPosition = null
        this.offRosterFlag = true
      }
      this.dataObj.payrollModTargetUserId = this.getUserFromPrevious(this.previousMod.payrollModTargetUserId) 
      this.dataObj.payrollModExchangeUserId = this.getUserFromPrevious(this.previousMod.payrollModExchangeUserId)
      if(this.previousMod.payrollModExchangeUserId && this.previousMod.payrollModExchangeUserId == this.userData.id && this.previousMod.payrollModExchangeApproved == null){
        this.dataObj.payrollModExchangeApproved = false
      }
      this.previousData = true
    } else {
      this.selectedDate = moment().format('YYYY-MM-DD')
      this.selectedTime = '08:00:00'
      this.previousData = false
      this.dataObj.payrollModTargetUserId = this.userData
      this.dataObj.payrollModAuthorId = this.userData.id
    }
    if(this.siteconfig.payroll.flexibleSchedule){
        this.rosterId = '', 
        this.payrollModRosterPosition = null
        this.offRosterFlag = true
    } 
    if(this.siteconfig.payroll.paymodClearTimeDurations && !this.previousMod.id){
      this.dataObj.payrollModHours = 0
      this.selectedTime = ''
    }
    if(this.siteconfig.payroll.shiftStartTime && !this.previousMod.id && !this.siteconfig.payroll.flexibleSchedule){
      this.selectedTime = this.siteconfig.payroll.shiftStartTime
    }
  },
  mounted(){

      
  },
  computed:{
        ...mapState([
          'getSessionData',
          'userData',
          'deptContactData',
          'siteconfig',
          'apparatusData'
        ]),
        deptContactDataReturn(){
          var data = _.clone(this.deptContactData)
          
          if(this.dataObj.payrollModTargetUserId && this.dataObj.payrollModTargetUserId.id == this.userData.id){
            if(this.payrollPermsData){
              var permsMan = _.cloneDeep(this.payrollPermsData)
              permsMan = permsMan.filter(p => {
                  if(p.payrollManageUsers){
                    var pfilter = p.payrollManageUsers.filter(f => f == this.dataObj.payrollModTargetUserId.id)
                    if(pfilter && pfilter.length > 0){
                      return p
                    }
                  }
              })
              if(permsMan && permsMan.length > 0){
                return data.filter(p => {
                    var index = permsMan.map(m => m.payrollUserId).indexOf(p.id)
                    if(index >= 0){
                      return p
                    }
                })
              }
            } return data
          } return data
        },
        previousModCheck(){
          if(this.previousMod && this.previousMod.payrollModApproved != null){
            return false
          } return true
        },
        rosterDataReturn(){
          var rosterData = this.rosterData
          var apparatus = this.rosterData.map(p => p.apparatusName)
          var index = apparatus.indexOf(undefined)
        //  console.log('index', index)
          if(index >= 0){
            rosterData.forEach(item => item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId) ? this.apparatusData.find(p => p.id == item.apparatusId).apparatusName : 'Unknown')
            rosterData.forEach(item => item['apparatusName'] = item.apparatusName == 'Unknown' && item.rosterName ? item.rosterName : 'Unknown')
            var apparatusSort = _.orderBy(rosterData, ['apparatusName'], ['asc'])
            return apparatusSort
          } return _.orderBy(rosterData, ['apparatusName'], ['asc'])
        },
        deptContactDataOrdered(){
          var array = _.cloneDeep(this.deptContactData)
          if(this.siteconfig.payroll.customPerms){
            var permsData = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
              if(permsData && permsData.payrollManageUsers){
                return array.filter(p => permsData.payrollManageUsers.indexOf(p.id) >= 0)
              } return array
            } 
          return _.orderBy(array, ['lastName'], ['asc'])
        },
        checkPermissionsRequired(){
          if(this.previousMod){
            if(this.previousMod.payrollModType == 'overtime availability' && (this.userData.id == this.previousMod.payrollModApprovalUserId || this.userData.id == this.previousMod.payrollModAuthorId)){
              return true
            } else if(this.userData.role == 'admin' || this.userData.role == 'adminchief' || this.userData.role == 'fireops' || (this.siteconfig.payroll.staffingFireOpsNonUser && this.userData.role != 'user')){
            return true
          }
          } else if(this.userData.role == 'admin' || this.userData.role == 'adminchief' || this.userData.role == 'fireops' || (this.siteconfig.payroll.staffingFireOpsNonUser && this.userData.role != 'user')){
            return true
          }
        },
        checkForDeletePermissions(){
          if(this.userData.role == 'admin' && false){
            return true
          }
          if(this.previousMod.payrollModDatetime && this.siteconfig && this.siteconfig.payroll && this.siteconfig.payroll.shiftStartTime){
          var dateOfMod = this.dataObj.payrollModDatetime
          dateOfMod = moment(dateOfMod).format('YYYY-MM-DD')
          var payroll = this.siteconfig.payroll
          var startTime = payroll.shiftStartTime
          var string = dateOfMod + 'T' + startTime
          var modString = string
          var currentShift = moment().format('YYYY-MM-DD') + 'T' + payroll.shiftStartTime
          var boo = moment(modString).isSameOrAfter(currentShift)
          return boo
         } return false
        },
        payrollCodeTypes(){
          var array = this.payrollCodesData
          var user = this.dataObj.payrollModTargetUserId
          if(!(this.userData.role == 'admin' || this.userData.role == 'fireops' || (this.siteconfig.payroll.staffingFireOpsNonUser && this.userData.role != 'user') || this.userData.role == 'adminchief') 
          && this.dataObj.id != undefined){
            var rolefil = array
          }  else if (this.userData.role == 'admin'){
            var rolefil = array
            } else {
          var rolefil = array.filter(p => {
            if(p.payrollCodeVisibility){
            return p.payrollCodeVisibility.indexOf(this.userData.role) != -1
            }
          })
          if(this.siteconfig.payroll.filterCodesByRank && user){ // Filter Codes based on Target User *****
           rolefil = rolefil.filter(p => {
            if(p.payrollCodeRankVisibility && p.payrollCodeRankVisibility.length > 0){
              return p.payrollCodeRankVisibility.indexOf(user.deptRank) != -1
            } return p
          })
          }
          }
          var options = [... new Set(rolefil.map(p => p.payrollCodeCategory))]
          options = options.filter(p => {
            return p != null && p != undefined && p != 'none'
          })
      
          var overtimeIndex = options.indexOf('overtime')
          if(overtimeIndex != -1){
            options[overtimeIndex] = {text: 'additions/overtime', value: 'overtime'}
          }
          options = _.sortBy(options)
          return options
        },
        checkPayrollApprovalRequirement(){
          var modid = this.dataObj.payrollModCode
       //   console.log('Running Permissions')
          this.approvalAuthority = false
          if(this.firstTestFlag && !this.deniedHold){
         // console.log('first test flag')
          this.dataObj.payrollModApproved = null
          this.preApprovedPayroll = false
          this.firstTestFlag = false
          }
          if(this.payrollPermsData){
            var permsData = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
          } else {
            var permsData = false
          }
          if(modid && !this.submitAsOccupied){
            var modcode = this.payrollCodesData.find(p => p.id == modid)
            if(modcode){
                if(modcode.requireApproval == true && this.dataObj.payrollModTargetUserId){
                  if(this.dataObj.payrollModTargetUserId.id == this.userData.id && (modcode.selfApprove == true || (permsData && permsData.payrollModSelfApprove))){
                    this.approvalAuthority = true
                    return false
                  } else if (this.dataObj.payrollModTargetUserId.id != this.userData.id){
                        var index = modcode.payrollCodeApprovalRole.indexOf(this.userData.role)
                    //    console.log(index)
                        if(index != -1){
                          return false
                        } else {
                          return true
                        }
                  }
                  else {
                    return true
                  }
                } else {
                //  console.log('PRE APPROVAL CRAP')
                  this.payrollApprovalFlag = true
                  this.dataObj.payrollModApproved = true
                  this.preApprovedPayroll = true
                  this.checkNotificationAndNote(modcode.id)
                  return true
                }
            } else {
              return false
            }
          } else {
            if(this.submitAsOccupied){
           //   console.log('pre approval crap')
               this.preApprovedPayroll = true
               this.dataObj.payrollModApproved = true
            }
            return true
          }
        },
      
    },
    watch: {

      },
  beforeDestroy(){
    
  },
  methods:{
    nameWithLang (obj) {
      return obj.apparatusName && obj.apparatusName != 'Unknown' ? obj.apparatusName : obj.rosterName ? obj.rosterName : 'Unknown'
    },
    removeAdditionalDay(date){
      var index = this.additionalLeaveDays.map(p => p.selectedDate).indexOf(date)
      this.additionalLeaveDays.splice(index, 1)
    },
    addAdditionalLeaveDays(){
      if(this.additionalLeaveDays && this.additionalLeaveDays.length == 0){
        var days = this.siteconfig.payroll.flexibleSchedule ? 1 : 3
        var startDate = moment(this.selectedDate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD')
        this.additionalLeaveDays.push({selectedDate: startDate, selectedTime: this.selectedTime, payrollModHours: this.dataObj.payrollModHours })
      } else if (this.additionalLeaveDays && this.additionalLeaveDays.length < 4 && this.additionalLeaveDays.length > 0){
        var data = _.orderBy(this.additionalLeaveDays, ['selectedDate'], ['desc'])
        var obj = _.cloneDeep(data[0])
        var days = this.siteconfig.payroll.flexibleSchedule ? 1 : 3
        var startDate = moment(obj.selectedDate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD')
        this.additionalLeaveDays.push({selectedDate: startDate, selectedTime: obj.selectedTime, payrollModHours: obj.payrollModHours })
      }
    },
    checkState(value, type){
      if(type == 'date' || type == 'time'){
        if(value){
          return true
        } return false
      } if(value > 0){
        return true
      } return false
    },
    testCodeForEmailRequest(id){
      if(id){
        var code = this.payrollCodesData.find(p => p.id == id)
        if(code && code.payrollCodeEmail){
          return code.payrollCodeEmail
        }
      } return false
    },
    testCodeForDollars(id){
      if(id){
        var code = this.payrollCodesData.find(p => p.id == id)
        if(code && code.payrollCodeDollar){
          return true
        } return false
      } return false
    },
    checkNotificationAndNote(id){
      var modcode =  this.payrollCodesData.find(p => p.id == id)
      if(modcode){
        if(modcode.payrollCodeNotification){
          this.messageUser = modcode.payrollCodeNotification
        }
        if(modcode.payrollCodeNoteRequired){
          this.noteRequiredFlag = modcode.payrollCodeNoteRequired
        }
      }
    },
    testPayrollMod(modid){
        var modcode = this.payrollCodesData.find(p => p.id == modid)
        if(modcode){
            if(modcode.requireApproval == true){
            return true
        } return false
      } else {
        return false }
    },
        getFormatedDateTime(date, seconds){
      if(date){
        if(seconds){
        return moment(date).format('MM/DD/YY HH:mm:ss')
        } else {
           return moment(date).format('MM/DD/YY HH:mm')
        }
      } else {
        return 'Unknown'
      }
    },
   changePayrollValueTrue(checked){
     //console.log(this.checkFlag)
    if(this.checkFlag == 'trueFlag'){
    //console.log('TRUE')
     //console.log(checked)

    checked === true ? this.dataObj.payrollModApproved = true : this.dataObj.payrollModApproved = null
    this.payrollApprovalFlag = checked === true ? true : null
         }
    },
    changePayrollValue(checked){
         //  console.log(this.checkFlag)
    if(this.checkFlag == 'falseFlag'){
    //  console.log('FALSE')
    if(checked === true){
        this.dataObj.payrollModApproved = false
    } else {
        this.dataObj.payrollModApproved = null
    }
    this.payrollApprovalFlag = checked === true ? false : null
    }
    },
    getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    getApprovalStatus(data){
        return data == true ? 'Approved' : data == false ? 'Denied' : data == null ? 'Needs Attn' : ''
    },
    getApprovalStatusColor(data){
       return data == true ? 'success' : data == false ? 'danger' : data == null ? 'warning' : ''
    },
    getUserInfo(id){
      var user = this.deptContactData.find(p => p.id == id)
      if(user){
        return user.firstName + ' ' + user.lastName
      } else {
        return 'Author Not Found'
      }
    },
    getUserFromPrevious(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    getRosterDataFromPrevious(id){
  //    console.log(id)
  //    console.log(this.rosterData)
      if(id){
        var user = this.rosterDataReturn.find(p => p.id == id)
        // console.log(user)
        if(user){
          return user
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    filterPayroleCodes(cat){
          var user = this.dataObj.payrollModTargetUserId
          var array = this.payrollCodesData
           if(cat == 'exchange'){
            var pay = array.find(p => p.payrollCode.toLowerCase() == 'exoff')
            if(pay){
              this.dataObj.payrollModCode = pay.id
            }
          }
          if(!(this.userData.role == 'admin' || this.userData.role == 'fireops' || (this.siteconfig.payroll.staffingFireOpsNonUser && this.userData.role != 'user') || this.userData.role == 'adminchief') 
          && this.dataObj.id != undefined){
            var rolefil = array
          } else if (this.userData.role == 'admin'){
            var rolefil = array
            } else {
          var rolefil = array.filter(p => {
            if(p.payrollCodeVisibility){
            return p.payrollCodeVisibility.indexOf(this.userData.role) != -1 && p.payrollCodeCategory == cat
            }
          }) 
          if(this.siteconfig.payroll.filterCodesByRank && user){
          rolefil = rolefil.filter(p => {
            if(p.payrollCodeRankVisibility && p.payrollCodeRankVisibility.length > 0){
              return p.payrollCodeRankVisibility.indexOf(user.deptRank) != -1
            } return p
          })
          }
          }
          var opt = rolefil.map(item => {
            var obj = {}
            obj['text'] = item.payrollCodeName
            obj['value'] = item.id
            return obj
          })
          return _.orderBy(opt, ['text'], ['asc'])

    },
    deletePayrollModification(){
     axios({
     method: 'Delete',
     url: '/api/v1/staffing/payrollmod/' + this.dataObj.id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
          }
        }).then( response => {
     bus.$emit('newPayrollModification')
    //   console.log(response.data)
      }) 
    },
    submitNewPayrollModsMulti(data){
      var datetime = this.selectedDate + 'T' + this.selectedTime
      data.payrollModDatetime = moment(datetime).toDate()
      var phone = data.payrollModTargetUserId ? data.payrollModTargetUserId.personalMobile : null
      var email = data.payrollModTargetUserId ? data.payrollModTargetUserId.email : null
      var emailcc = data.payrollModExchangeUserId ? data.payrollModExchangeUserId.email : null
      data.payrollModTargetUserId = data.payrollModTargetUserId ? data.payrollModTargetUserId.id : null
      data.payrollModRosterId = this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? this.rosterId.id : this.offRosterFlag ? 'offroster' : ''
      data.payrollModExchangeUserId = data.payrollModExchangeUserId ? data.payrollModExchangeUserId.id : ''
      data.payrollModRosterPosition = data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? data.payrollModRosterPosition : null
      data.payrollModApprovalUserId =  this.payrollApprovalFlag != null ? this.userData.id : data.payrollModApprovalUserId ? data.payrollModApprovalUserId : ''
      data.payrollModAuthorId = data.payrollModAuthorId ? data.payrollModAuthorId : this.userData.id
      data.payrollModHours =  this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeMultiplier != null ? Number.parseFloat(data.payrollModHours) * Number.parseFloat(this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeMultiplier) : Number.parseFloat(data.payrollModHours)
      data.payrollModApproved = this.payrollApprovalFlag
      if(this.messageUser && phone && false){ // need to flag if a company is paying for text messages
        data.message = phone 
        data.phoneContact = true
        var string = ''
        data.payrollModType ? string = string + data.payrollModType.toUpperCase() + '| ' : ''
        data.payrollModCode ? string = string + this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeName + ': ' : ''
        this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + this.rosterId.apparatusName + ' ' : this.offRosterFlag ? 'offroster' : ''
        data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + 'Pos:' + data.payrollModRosterPosition + ' Start: ' : ''
        string = string + moment(datetime).format('MM/DD/YY HH:mm') + ' For: ' + Number.parseFloat(data.payrollModHours) + 'Hrs'
        data.messageBody = string
      }
      if(this.messageOtherUserObj && this.messageOtherUserObj.email){
        data.message = this.messageOtherUserObj.email
        data.emailContact = true
        data.emailCC = this.userData.email
        var string = 'REQUEST FOR APPROVAL: '
        data.payrollModType ? string = string + data.payrollModType.toUpperCase() + ' | ' : ''
        data.payrollModCode ? string = string + this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeName + ':' : ''
        this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + this.rosterId.apparatusName + ' ' : this.offRosterFlag ? 'offroster' : ''
        data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + 'Pos:' + data.payrollModRosterPosition + ' Start: ' : ''
        string = string + ' at Date/Time ' + moment(datetime).format('MM/DD/YY HH:mm') + ' For: ' + Number.parseFloat(data.payrollModHours) + 'Hrs ' + 'and ' + this.additionalLeaveDays.length + ' days'
        data.payrollModApproved == true ? string = string + ' APPROVED by ' + this.userData.firstName + ' ' + this.userData.lastName : data.payrollModApproved == false ? string = string + ' DENIED by ' + this.userData.firstName + ' '  + this.userData.lastName + ' Note: ' + data.payrollModNote + '' : ''
        data.messageBody = string
      }
      if(this.messageUser && email){ // need to flag if a company is paying for text messages
        data.message = email 
        data.emailContact = true
        data.emailCC = emailcc
        var string = ''
        data.payrollModType ? string = string + data.payrollModType.toUpperCase() + '| ' : ''
        data.payrollModCode ? string = string + this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeName + ': ' : ''
        this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + this.rosterId.apparatusName + ' ' : this.offRosterFlag ? 'offroster' : ''
        data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + 'Pos:' + data.payrollModRosterPosition + ' Start: ' : ''
        string = string + ' at Date/Time ' + moment(datetime).format('MM/DD/YY HH:mm') + ' For: ' + Number.parseFloat(data.payrollModHours) + 'Hrs'
        data.payrollModApproved == true ? string = string + ' APPROVED by ' + this.userData.firstName + ' ' + this.userData.lastName : data.payrollModApproved == false ? string = string + ' DENIED by ' + this.userData.firstName + ' '  + this.userData.lastName + ' Note: ' + data.payrollModNote + '' : ''
        data.messageBody = string
      }
      delete data.rosterUpdateData
      var array = [data]
      var additionalDays = this.additionalLeaveDays
      for(var a in additionalDays){
        var obj = _.cloneDeep(data)
        var day = additionalDays[a]
        delete obj.message
        delete obj.messageBody
        delete obj.emailCC
        delete obj.emailContact
        var datetime = day.selectedDate + 'T' + day.selectedTime
         obj['payrollModDatetime'] = moment(datetime).toDate()
         obj['payrollModHours'] = day.payrollModHours
         array.push(obj)
      }
      var count = 0
      for(var d in array){
        count++
    axios({
     method: 'post',
     url: '/api/v1/staffing/payrollmod/',
     data: array[d],
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
        console.log(response.data)
        if(count == array.length){
        bus.$emit('newPayrollModification')
        }
 
   })
      }

    },
    submitNewPayrollMod(data){
    if(!data.id && data.payrollModType == 'scheduled leave' && this.additionalLeaveDays && this.additionalLeaveDays.length > 0){
      this.submitNewPayrollModsMulti(data)
      return
    }
    //console.log(data)
   // console.log(this.messageOtherUserObj)
     // console.log(this.payrollApprovalFlag)
 //   var data = this.dataObj
  //  console.log(this.dataObj.payrollModApproved)
    var datetime = this.selectedDate + 'T' + this.selectedTime
    data.payrollModDatetime = moment(datetime).toDate()
    var phone = data.payrollModTargetUserId ? data.payrollModTargetUserId.personalMobile : null
    var email = data.payrollModTargetUserId ? data.payrollModTargetUserId.email : null
    var emailcc = data.payrollModExchangeUserId ? data.payrollModExchangeUserId.email : null
    data.payrollModTargetUserId = data.payrollModTargetUserId ? data.payrollModTargetUserId.id : null
    data.payrollModRosterId = this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? this.rosterId.id : this.offRosterFlag ? 'offroster' : ''
    data.payrollModExchangeUserId = data.payrollModExchangeUserId ? data.payrollModExchangeUserId.id : ''
    data.payrollModRosterPosition = data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? data.payrollModRosterPosition : null
    data.payrollModApprovalUserId =  this.payrollApprovalFlag != null ? this.userData.id : data.payrollModApprovalUserId ? data.payrollModApprovalUserId : ''
    data.payrollModAuthorId = data.payrollModAuthorId ? data.payrollModAuthorId : this.userData.id
    data.payrollModHours =  this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeMultiplier != null ? Number.parseFloat(data.payrollModHours) * Number.parseFloat(this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeMultiplier) : Number.parseFloat(data.payrollModHours)
    data.payrollModApproved = this.payrollApprovalFlag
    if(this.messageUser && phone && false){ // need to flag if a company is paying for text messages
      data.message = phone 
      data.phoneContact = true
      var string = ''
      data.payrollModType ? string = string + data.payrollModType.toUpperCase() + '| ' : ''
      data.payrollModCode ? string = string + this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeName + ': ' : ''
      this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + this.rosterId.apparatusName + ' ' : this.offRosterFlag ? 'offroster' : ''
      data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + 'Pos:' + data.payrollModRosterPosition + ' Start: ' : ''
      string = string + moment(datetime).format('MM/DD/YY HH:mm') + ' For: ' + Number.parseFloat(data.payrollModHours) + 'Hrs'
      data.messageBody = string
    }
    if(this.messageOtherUserObj && this.messageOtherUserObj.email){
      data.message = this.messageOtherUserObj.email
      data.emailContact = true
      data.emailCC = this.userData.email
      var string = 'REQUEST FOR APPROVAL: '
      data.payrollModType ? string = string + data.payrollModType.toUpperCase() + '| ' : ''
      data.payrollModCode ? string = string + this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeName + ': ' : ''
      this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + this.rosterId.apparatusName + ' ' : this.offRosterFlag ? 'offroster' : ''
      data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + 'Pos:' + data.payrollModRosterPosition + ' Start: ' : ''
      string = string + ' at Date/Time ' + moment(datetime).format('MM/DD/YY HH:mm') + ' For: ' + Number.parseFloat(data.payrollModHours) + 'Hrs'
      data.payrollModApproved == true ? string = string + ' APPROVED by ' + this.userData.firstName + ' ' + this.userData.lastName : data.payrollModApproved == false ? string = string + ' DENIED by ' + this.userData.firstName + ' '  + this.userData.lastName + ' Note: ' + data.payrollModNote + '' : ''
      data.messageBody = string
    }
    if(this.messageUser && email){ // need to flag if a company is paying for text messages
      data.message = email 
      data.emailContact = true
      data.emailCC = emailcc
      var string = ''
      data.payrollModType ? string = string + data.payrollModType.toUpperCase() + '| ' : ''
      data.payrollModCode ? string = string + this.payrollCodesData.find(p => p.id == data.payrollModCode).payrollCodeName + ': ' : ''
      this.rosterId && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + this.rosterId.apparatusName + ' ' : this.offRosterFlag ? 'offroster' : ''
      data.payrollModRosterPosition && (data.payrollModType == 'roster mod' || data.payrollModType == 'overtime' || data.payrollModType == 'payaddative') ? string = string + 'Pos:' + data.payrollModRosterPosition + ' Start: ' : ''
      string = string + ' at Date/Time ' + moment(datetime).format('MM/DD/YY HH:mm') + ' For: ' + Number.parseFloat(data.payrollModHours) + 'Hrs'
      data.payrollModApproved == true ? string = string + ' APPROVED by ' + this.userData.firstName + ' ' + this.userData.lastName : data.payrollModApproved == false ? string = string + ' DENIED by ' + this.userData.firstName + ' '  + this.userData.lastName + ' Note: ' + data.payrollModNote + '' : ''
      data.messageBody = string
    }
    if(this.previousMod && data.id){
    if(data.payrollModHistory){
    data.payrollModHistory.push({date: moment().toISOString(), previous: this.previousMod, changedBy: this.userData.id})
    } else {
      data.payrollModHistory = [{date: moment().toISOString(), previous: this.previousMod, changedBy: this.userData.id}]
    }
    }
    if(data.id){
      var method = 'patch'
      var url = '/api/v1/staffing/payrollmod/' + data.id
      if(this.approvalAuthority){
        data['approvalAuthority'] = true
      }
    } else {
      var method = 'post'
      var url = '/api/v1/staffing/payrollmod/'
    }
    delete data.rosterUpdateData
    //console.log(data)
    axios({
     method: method,
     url: url,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     bus.$emit('newPayrollModification')
     //  console.log(response.data)
   })

    },
   
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.inputError {
  border: 1px solid red;
  border-radius: 5px;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.text-align-clear {
  text-align: left;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
