<template>
  <b-container class="hello" fluid>
  <b-card no-body v-if="!showAdmin" >
    <b-tabs card>
      <b-tab title="Hydrant Search" Active class="px-0 pt-3"  @click="showActiveId = ''">
  <b-container fluid>
    <HydrantTable v-if="submittedHydrant.length > 0 && dataReset" @showhydrant="addHydrant($event)" @getHydrants="getHydrant" @deletehydrant="deleteHydrant($event)" :formGroups="formGroups" :formTableStyle="formTableStyle" :submittedHydrant="submittedHydrant" />
      
  </b-container>
      </b-tab>
    <b-tab title="Add Hydrant" class="p-0"  @click="showActiveId = ''">
      <b-row class="m-0 p-0"><b-col align="right">
      <b-btn class="mt-2 mr-5" @click="resetNewHydrantData" variant="danger" size="sm">Clear</b-btn>
      </b-col></b-row>
      <b-container class="pt-0 mt-0">
        <addNewHydrant v-if="resetNewHydrant"  :formGroups="formGroups" :formTableStyles="formTableStyle" @savedHydrantDataEmit="addHydrantList" />
</b-container>  

      </b-tab>
      <template v-for="Hydrant in editthisPlan" >
      <b-tab :key="Hydrant.id" :title="getTabTitle(Hydrant.hydrantData, formTabTitle)" :active="showActiveId == Hydrant.id">
         <addNewHydrant :savedHydrantData="Hydrant" @savedHydrantDataEmit="addHydrantList" :viewItem="true" :formGroups="formGroups" @closeHydrant="removeHydrantTab" @duplicateHydrant="duplicateHydrant" :formTableStyles="formTableStyle" />
        </b-tab>
      </template>
      <b-tab title="Bulk Add" v-if="userData.role == 'admin'">
        <bulkNewHydrant :formGroups="formGroups" :formTableStyles="formTableStyle" :submittedHydrant="submittedHydrant" @savedHydrantDataEmit="getHydrant" />
        </b-tab>
    </b-tabs></b-card>

    </b-container>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from "moment";
import { mapState } from "vuex";
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import axios from "axios";
import HydrantSearch from "./../../tracking/preplans/hydrantSearch.vue";
import HydrantTable from "./../../tracking/preplans/hydrantTable.vue";
import addNewHydrant from "./../../addNew/newHydrantForm.vue";
import bulkNewHydrant from "./../../addNew/bulkNewHydrantForm.vue";
import { bus } from '../../../main.js';

export default {
  name: "hello",
  components: {
    addNewHydrant,
    HydrantSearch,
    HydrantTable,
    bulkNewHydrant
  },
  data() {
    return {
      stringData: "",
      userSearchBox: "",
      searchData: "",
      currentPage: 1,
      showAdmin: false,
      pageDisplayCount: 6,
      showActiveId: '',
      dataReset: true,
      editthisPlan: [],
      editHydrantData: false,
      pageCountOptions: [
        { text: "6 Per Page", value: 6 },
        { text: "12 Per Page", value: 12 },
        { text: "24 Per Page", value: 24 },
        { text: "48 Per Page", value: 48 }
      ],
      addressData: [],
      submittedHydrant: [],
      oldHydrant: [],
      resetNewHydrant: true,
      formTabTitle: "hydrantLocalIdasdffa",
      formTableStyle: [
        {
          values: [{vmodel: "hydrantLocalIdasdffa"}],
          label: "Hydrant Local Id",
          key: "hydrantLocalId",
          itemTitle: true,
        },
        {
           values: [{vmodel: "StreetNumber3f1myd54"}, {vmodel: "Streetbn8ry055sdfa" }, 
            {vmodel: "CrossStreetrtum4856sd", prefix: 'Cross ST: ', postfix: ','}, 
           // {vmodel: "Subdivisionswlefk", prefix: 'SubDiv:', postfix: ',' }, 
           //{vmodel: "City7xgpvk57", postfix: ','}, 
           //{vmodel: "State5lm01g58"}, 
           //{vmodel: "Ziplmanxr59"}
           ],
          label: "Address",
          key: "streetNumberStreetStreetSuffix"
        }
      ],
      formGroups: [
        {
          group: "hydrant",
          label: "Hydrant Location",
          variant: "dark",
          header: "Hydrant Location Info",
          displayOrder: [
            { type: "table", order: "1" },
            { type: "table", order: "2" },
          ],
          fields: [
            {
              label: "Hydrant Local Id:",
              idtag: "HydrantLocalId",
              vmodel: "hydrantLocalIdasdffa",
              size: "2",
              type: "text",
              order: "1",
              searchIndex: true,
              preplanData: false,
            },
            {
              label: "Street Number:",
              idtag: "StreetNumber",
              vmodel: "StreetNumber3f1myd54",
              size: "2",
              type: "text",
              order: "2",
              cad: true,
              cadValue: 'addnumber',
              preplanData: true,
            },
            {
              label: "Street:",
              idtag: "Street",
              vmodel: "Streetbn8ry055sdfa",
              size: "4",
              type: "text",
              order: "2",
              cad: true,
              cadValue: 'streetname',
              preplanData: true,
            },
            {
              label: "Cross Street:",
              idtag: "Suffix",
              vmodel: "CrossStreetrtum4856sd",
              size: "4",
              type: "text",
              cad: false,
              cadValue: 'crossStreet',
              order: "2",
              preplanData: true,
            },
            {
              label: "Subdivision:",
              idtag: "Subdivision",
              vmodel: "Subdivisionswlefk",
              size: "6",
              type: "text",
              order: "2",
              cad: false,
              cadOpt: false,
              cadValue: 'subDiv',
              preplanData: true,
            },
            {
              label: "Relative Location:",
              idtag: "RelativeLocation",
              vmodel: "relativeLocation4556",
              cad: false,
              cadValue: 'relativeLoc',
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "City:",
              idtag: "City",
              vmodel: "City7xgsdpvk57",
              size: "5",
              cad: true,
              cadValue: 'city',
              type: "text",
              order: "2",
              preplanData: false,
            },
            {
              label: "State:",
              idtag: "State",
              vmodel: "State5lm01gsd58",
              size: "3",
              cad: true,
              cadValue: 'state',
              type: "text",
              order: "2",
              preplanData: false,
            },
            {
              label: "Zip Code:",
              idtag: "Zip",
              vmodel: "Ziplmanxsdr59",
              cad: true,
              cadValue: 'zipcode',
              size: "4",
              type: "text",
              order: "2",
              preplanData: false,
            },
                         {
              label: "Manufacture Year",
              idtag: "manufactureYear",
              vmodel: "manufactureYearxs7zqc50",
              cad: false,
              size: "3",
              type: "text",
              order: "3",
              preplanData: false,
            },
            {
              label: "Manufacture Brand:",
              idtag: "manufactureBrand",
              vmodel: "manufactureBrandxssd7zqc50",
              size: "3",
              cad: false,
              type: "text",
              order: "3",
              preplanData: false,
            },
            {
              label: "Manufacture Model:",
              idtag: "manufactureModel",
              vmodel: "manufactureModel5fbb51",
              size: "3",
              cad: false,
              type: "text",
              order: "1",
              preplanData: false,
            },
            {
              label: "Hydrant Owner:",
              idtag: "hydrantOwner",
              vmodel: "hydrantOwnerl5fbb51",
              size: "3",
              cad: false,
              type: "text",
              order: "1",
              preplanData: false,
            },
          ]
        },
      ]
    };
  },
  created() {
    bus.$on('showHydrantFromBuild', this.addHydrant)
    this.getHydrant();


  },
  mounted() {
    bus.$emit('emitHydrantFormGroups', this.formGroups)
    bus.$emit('emitHydrantFormTableStyle', this.formTableStyle)
  },
  computed: {
    ...mapState([
      "deptContactData",
      "departmentBlogs",
      "getSessionData",
      "userData"
    ])
  },
  watcher: {},
  methods: {
    getTabTitle(data, title, parent) {
      var obj = data.find(p => p.vmodel == title);
      var string = obj.value
      if(parent){
        string = string + ' *Sub'
      }
      return string;
    },
    duplicateHydrant(obj){
      this.showActiveId = ''
      obj['duplicate'] = true
      obj.id = obj.id + Math.random()
              .toString(36)
              .substr(2, 10);
       this.editthisPlan.push(obj)
       this.showActiveId = obj.id
    },
    addHydrant(event) {
      this.showActiveId = ''
      if(event.id){
        var id = event.id
      } else {
        var id = event
      }
      if(this.editthisPlan.map(p => p.id).indexOf(id) == -1){
      axios({
        method: "get",
        url: "/api/v1/preplan/Hydrant/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.showActiveId = id
        this.editthisPlan.push(response.data[0])
      });
      } else {
        this.showActiveId = id
      }
    },
    addHydrantList($event){
      console.log('Hydrant ADDED')
      this.resetNewHydrant = false
      this.getHydrant()
      this.editthisPlan.push($event)
      this.$nextTick(function(){
        this.resetNewHydrant = true
      })
    },
    resetNewHydrantData(){
      this.resetNewHydrant = false
      this.$nextTick(function(){
        this.resetNewHydrant = true
      })
    },
    removeHydrantTab(data){
      var index = this.editthisPlan.map(p => p.id).indexOf(data)
      this.editthisPlan.splice(index, 1)
            this.getHydrant()

    },
    setFormGoups(data) {
      var forms = _.cloneDeep(data);
      for (var i in data) {
        var fields = data[i].fields;
        for (var p in fields) {
          var id = fields[p].idtag;
          var length = 6;
          var id =
            id +
            Math.random()
              .toString(36)
              .substr(2, length);
          var id = _.uniqueId(id);
          fields[p].vmodel = id;
        }
      } 
    },
    getHydrant() {
      this.dataReset = false
      axios({
        method: "get",
        url: "/api/v1/preplan/hydrant",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.submittedHydrant = response.data;
        this.$nextTick(function(){
       this.dataReset = true
        })
 
      });
    },
    deleteHydrant(id) {
      console.log(id)
      axios({
        method: "delete",
        url: "/api/v1/preplan/hydrant/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"

        }
      }).then(response => {
       this.getHydrant()
      });
    },
  }
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.blogCardClass {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space: nowrap;
}
.userDataWrap {
  text-align: center;
}
.userRank {
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer {
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: 0.4;
}
.headerContactRow {
  border-radius: 5px;
}
.A-shiftROW {
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.B-shiftROW {
  border: 1px solid #201b9f;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.C-shiftROW {
  border: 1px solid #0fb906;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.Day-shiftROW {
  border: 1px solid #e4cb07;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.A-shift {
  background: #e2070d;
  background: -moz-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift {
  background: #201b9f;
  background: -moz-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.C-shift {
  background: #0fb906;
  background: -moz-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.Day-shift {
  background: #e4cb07;
  background: -moz-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;
}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138, 138, 138, 1);
  color: #fff;
  border-radius: 10px;
}
h1,
h2 {
  font-weight: normal;
}

@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
