<template>
<div>
                <b-container fluid :class="responseView ? 'm-0 p-0': ''">
 
                  <b-navbar  class="blogSpanHeader" type="dark" variant="dark" v-if="tempView == true ? false : true">
                     
                    <b-nav-form>
                    
                        <b-navbar-brand class="bootstrapTable">Map</b-navbar-brand>
                      
                        <b-btn class="mt-1" size="sm" @click="edit ? (addMapData = !addMapData, viewMap()) :  addMapData = !addMapData" :variant="!addMapData ? 'outline-light' : 'light'">
                          {{ !addMapData && edit ? 'Add Data (off)' : addMapData && edit ? 'Add Data (on)' : !addMapData ? 'Details (off)' : 'Details (on)' }}</b-btn>
                        <b-btn class="sml-3 mt-1" 
                               size="sm" v-for="(item, index) in btnVisibilityGroup" 
                               :disabled="addMapData"
                               v-show="!item.editOnly ? true : item.editOnly && !edit ? false : true"
                               @click="item.boolean == null ? item.boolean = true : item.boolean == false ? (item.boolean = null, autoOn = false ): item.boolean = false, viewMap()" :variant="item.boolean ? item.variant : !item.boolean && !item.autoOn ? 'outline-' + item.variant : item.variant" :key="index"
                               >{{ item.boolean == null ? item.item + ' (auto)' : item.boolean ? item.item + ' (on)' : item.item + ' (off)' }}</b-btn>
                    
                        </b-nav-form>
                         <b-navbar-nav class="ml-auto">
                             <b-btn v-if="edit && (userData.role == 'supervisor' || userData.role == 'fireops' || userData.role == 'adminchief' || userData.role == 'admin')" class="mt-1" size="sm" variant="outline-danger" @click="updateBuildingLocation">Move Building Loc</b-btn>
                             <b-btn class="mt-1" size="sm" variant="outline-success" @click="imageType == 'ImageryTransportation' ? imageType = 'Streets' : imageType = 'ImageryTransportation', viewMapBuild()">Type/Refresh</b-btn>
                         
                         </b-navbar-nav>
                         <b-navbar-nav class="ml-auto" v-if="responseView">
                             
                           <b-btn   class="mt-1" size="sm"  @click="toggleBuildingDataBar">Building Data (off)</b-btn>
                         </b-navbar-nav>

                  </b-navbar>
  
                <b-card border-variant="dark" v-if="addMapData" no-body>
                <b-tabs card> 
                  <b-tab title="Valve Location">
                        <b-container v-if="edit">
                        <b-row><b-col>
                        <b-form-radio-group id="radios1" v-model="fireProtectionObj.type" @change="mapExtras">
                             <b-form-radio v-for="(item, index) in fireProtectionOptions" :key="index" :value="item.value"><img class="iconAddMapp" :title="item.title" :src="item.src" /></b-form-radio>
                        </b-form-radio-group></b-col></b-row>
               <b-container v-if="!selectedLocation && fireProtectionObj.type" class="text-danger">Click on the Map Where the Item is Located</b-container>
                        <b-row align-h="center"><b-col lg="6" align="right"><b-form-input v-model="fireProtectionObj.properties.serviceaddress" type="text" label="Location" placeholder="Relative Location to Building/Interior and/or Information on the item" /></b-col>
                        <b-col lg="2" ><b-btn @click="saveFireProtectionInformation('fireProtectionObj', 'fireprotectionFeatures')" variant="success" size="sm">Save Data</b-btn></b-col>
                        </b-row>
                                          </b-container>
                   <b-card v-if="fireprotectionFeatures.length > 0" no-body header="Fire Protection Info Tagged " header-bg-variant="danger" header-text-variant="white" header-class="p-1">
                  <span v-if="fpEditId" class="text-danger">Change the Data Required, and click on the map to reposition the icon</span>

                   <b-table striped small  :fields="fireProtectionFields" :items="fireprotectionFeatures" class="mb-0" >
                      <template slot="type" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)"> {{ data.item.type }}</span>
                         <b-form-select v-if="fpEditId == data.item.id" v-model="data.item.type">
                            <option v-for="(item, index) in fireProtectionOptions" :key="index" :value="item.value">{{ item.value }}</option>
                           </b-form-select>
                       </template>
                       <template slot="address" slot-scope="data">
                         <span v-if="!(fpEditId == data.item.id)">{{ data.item.properties.serviceaddress }}</span>
                         <b-form-input v-if="fpEditId == data.item.id" v-model="data.item.properties.serviceaddress" type="text" />
                       </template>
                       <template slot="photo" slot-scope="data">
                         <b-btn v-if="data.item.photoUrl" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="mapModalFeature = data.item, modalShow = true" >View Reference</b-btn>
                         <b-btn v-if="!data.item.photoUrl" v-show="edit" variant="outline-primary" size="sm" @click="openPhotoSelectorModel(data.item.id, 'fireprotectionFeatures')">Tag a Reference</b-btn>
                      </template>
                      <template slot="business" slot-scope="data">
                         <b-btn v-if="data.item.businessIds" :class="edit ? '' : 'p-0'" :variant="edit ? 'outline-info' : 'link'"  size="sm" @click="openBusinessSelectorModel(data.item.id, 'fireprotectionFeatures')" >View Business</b-btn>
                         <b-btn v-if="!data.item.businessIds" v-show="edit" variant="outline-primary" size="sm" @click="openBusinessSelectorModel(data.item.id, 'fireprotectionFeatures')">Tag Business</b-btn>
                      </template>
                       <template slot="edit" slot-scope="data">
                         <b-btn variant="outline-danger" size="sm" v-show="!(fpEditId == data.item.id)" :disabled="fpEditId" @click="!fpEditId ? (fpEditId = data.item.id, mapEdit(data.item.id, 'fireprotectionFeatures')) : fpEditId = null">Edit</b-btn>
                         <b-btn variant="outline-danger" size="sm" v-if="fpEditId == data.item.id" @click="fireprotectionFeatures.splice(fireprotectionFeatures.map(p => p.id).indexOf(data.item.id), 1), fpEditId = null, mapEditRemove(), viewMap()">Delete</b-btn>
                         <b-btn variant="outline-success" size="sm" v-if="fpEditId == data.item.id" @click="fpEditId = null, mapEditRemove()">Save</b-btn>
                       </template>
                       </b-table>
                       </b-card>
                  </b-tab>
                </b-tabs>
                </b-card>
      <div :id="uniqueId"></div></b-container>
       <b-modal v-if="mapModalFeature" v-model="modalShow" :size="modalSize" :hide-footer="true">
                  <template slot="modal-header">
                    <b-container fluid><b-row><b-col>
           {{ mapModalFeature.properties.serviceaddress }}</b-col>
           <b-col cols="auto">
         <b-btn align="right" size="sm" variant="primary" @click="modalSize == '' ? modalSize = 'lg' : modalSize = ''">{{ modalSize == '' ? 'Maximize' : 'Minimize' }}</b-btn>
         <b-btn size="sm" variant="danger" @click="modalShow = !modalShow">Close</b-btn></b-col></b-row></b-container>
         </template>
               <span class="text-danger" v-if="!mapModalFeature.photoUrl">No Photo Tagged to this item</span>
               <b-btn v-if="edit && mapModalFeature.photoUrl" @click="removeRefrencePhoto(mapModalFeature)" size="sm" variant="outline-danger">Remove Reference Photo</b-btn>
               <b-img v-if="mapModalFeature.photoUrl" fluid :src="mapModalFeature.photoUrl" />
        </b-modal>
        <b-modal v-model="businessModelShow" v-if="businessModelShow">
          <b-table :fields="[{key: 'title', label: 'Business'}, 'Status','Action']" :items="getBusinessTagArray">
            <template slot="Status" slot-scope="data">
              <span v-if="data.item.Status == true">Tagged</span>
            </template>
            <template slot="Action" slot-scope="data">
              <b-btn :variant="data.item.Status ? 'danger' : 'primary'" size="sm" @click="tagBusinessToItem(data.item.id, !data.item.Status)">{{ data.item.Status ? ' X ' : 'Tag' }}</b-btn>
              </template>
            </b-table>
        </b-modal>
        <b-modal v-model="photoModelShow" >
          <b-container>
            <b-card no-body>
              <b-tabs card>
            <b-tab title="unTagged">
            <b-row v-if="refPhotos" v-for="(pic, index) in getRefPhotos(refPhotos, false)" :key="index">
              <b-col cols="auto">
                <b-btn variant="primary" @click="savePhotoReference(pic.url, true)">Save</b-btn>
                </b-col>
                <b-col>
                  <b-img fluid :src="pic.url" />
                </b-col>
                </b-row>
                </b-tab>
            <b-tab title="Tagged">
            <b-row v-if="refPhotos" v-for="(pic, index) in getRefPhotos(refPhotos, true)" :key="index">
              <b-col cols="auto">
                <b-btn variant="primary" @click="savePhotoReference(pic.url, true)">Save</b-btn>
                </b-col>
                <b-col>
                                        <p v-for="(details, index2) in pic.type" :key="index2" v-if="details.type">
                            <strong>{{ details.type }}:</strong> {{ details.location }}</p>
                  <b-img fluid :src="pic.url" />

                </b-col>
                </b-row>
                  </b-tab>
                  </b-tabs>
                  </b-card>
          </b-container>
        </b-modal>
        <span v-if="edit && dataReady" v-show="false">{{ watchFireProtection }}{{ watchHydrants }}{{ watchUtilities}}{{ watchTactics }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Leaf from 'leaflet'
import * as esri from "esri-leaflet"
import Measure from 'leaflet-measure'
import axios from 'axios'


export default {
  props:["buildingData", 'edit', 'refPhotos', 'primarySketch', 'tempView', 'responseView', 'attachedBusinessData'], 
  data () {
    return {
        imageType: 'ImageryTransportation',
        mapViewOptions: [
          {text: 'Ariel', value: 'ImageryTransportation'},
          {text: '2D', value: 'Streets'}
        ],
        addMapData: false,
        hydrantManual: false,
        resetBusinessTable: true,
        btnVisibilityGroup: [
          {item: 'Valve Location', variant: 'danger', boolean: true, autoOn: true},
        ],
        fireProtectionObj: {
          type: '',
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        },
        roofFloorOptionsValue: null,
        fireProtectionOptions: [
          {value: 'valve', title: 'Fire Alarm Control Panel', src: '/images/preplanicons/facp.png'},
        ],
        tempPhotoHoldId: '',
        tempPhotoHoldUrl: '',
        tempBuildingMarker: '',
        mapModalFeature: '',
        modalShow: false,
        fpEditId: null,
        fireprotectionFeatures: [],
        tacticsFeatures: [],
        utilitiesFeatures: [],
        hydrantSearch: true,
        holdMapObj: {},
        addressData: [],
        modalSize: '',
        photoModelShow: false,
        businessModelShow: false,
        hydrantFeatures: [],
        map: null,
        hydrantPointData: [],
        mapHold: {},
        layerTempId: '',
        utilitiesLayerGroup: '',
        fireProtectionLayerGroup: '',
        tacticsLayerGroup: '',
        hydrantLayerGroup: '',
        selectedLocation: '',
        dataReady: false,
        uniqueId: '',
    }
  },
  created(){
    //console.log(this.buildingData)
    this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 16);

  },
  updated(){
//    this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 16);
  },
  mounted(){
    this.checkBuildingData()

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'preplanStatus',
          'siteconfig'
        ]),
        hydrantFields(){
          var fields = ['address','cap','primary','photo']
          if(this.edit){ fields.push('edit')}
          return fields
         },
        fireProtectionFields(){
          var fields = ['type','address','photo','business']
          if(this.edit){ fields.push('edit')}
          return fields
        },
        utilitiesFields(){
          var fields = ['type','address','photo','business']
          if(this.edit){ fields.push('edit')}
          return fields
        },
        watchHydrants(){
          var object = {type: 'hydrants', data: this.hydrantFeatures}
          this.$emit('updateFeatureData', object)
          return this.hydrantFeatures
        },
       watchTactics(){
          var object = {type: 'tactics', data: this.tacticsFeatures}
          this.$emit('updateFeatureData', object)
          return this.tacticsFeatures
        },
        watchFireProtection(){
          var object = {type: 'fireProtection', data: this.fireprotectionFeatures}
          this.$emit('updateFeatureData', object)
          return this.fireprotectionFeatures
        },
        watchUtilities(){
          var object = {type: 'utilities', data: this.utilitiesFeatures}
          this.$emit('updateFeatureData', object)
          return this.utilitiesFeatures
        },
        getBusinessTagArray(){
          if(this.resetBusinessTable){
          var array = this[this.tempPhotoHoldId.type]
          var obj = array.find(p => p.id == this.tempPhotoHoldId.id)
          var businessData = this.attachedBusinessData
          if(obj && obj.businessIds){
            return businessData.map(item => {
              var hobj = item
              var index = obj.businessIds.indexOf(hobj.id)
              hobj['Status'] = obj.businessIds.indexOf(hobj.id) != -1 ? true : false
              return hobj
            })
          } return businessData.map(item => {
              var hobj = item
              hobj['Status'] = false
              return hobj
          })
          } else {
            return []
          }
        }
    },
    watch: {

      },
  beforeDestroy(){

  },
  methods:{
    updateRoofOptions(data){
      console.log(data)
      this.$emit('updateRoofOptions', data)
    },
    toggleBuildingDataBar(){
      this.$emit('toggleBuildingDataBar')
    },
    updateBuildingLocation(){
      this.$emit('updateBuildingLocation')
    },
    checkBuildingData(){
        if(this.buildingData){
        var data = this.buildingData
        if(data.hydrants){
          this.hydrantFeatures = data.hydrants
        }
        if(data.fireProtection){
          this.fireprotectionFeatures = data.fireProtection
        }
        if(data.utilities){
          this.utilitiesFeatures = data.utilities
        }
        if(data.tactics){
          this.tacticsFeatures = data.tactics
        }
        if(data.utilites){
          this.utilitiesFeatures = data.utilites
        }
        this.$nextTick(function(){
    this.viewMapBuild(data, data)
    this.dataReady = true
        })
    
    } else {
      this.$nextTick(function(){
      this.dataReady = true
      })
    }
    },
    saveFireProtectionInformation(obj, featureArray){
    var fpdata = this[obj]
    var data = this.selectedLocation
    var id = fpdata.type
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    fpdata['id'] = id
    fpdata.geometry.coordinates = [data.lng,data.lat]
    this[featureArray].push(fpdata)
    this.$nextTick(function(){
      this[obj] = {
          geometry: {type: "Point", coordinates: []},
          properties: {serviceaddress: ''}
        }
      var map = this.mapHold
      map.removeLayer(this.layerTempId)
      map.off('click')
      this.selectedLocation = ''
      this.viewMap()
    })

    },
    saveManualHydrantData(){
    var hydrant = this.hydrantManualObj
    var data = this.selectedLocation
    hydrant.geometry.coordinates = [data.lng,data.lat]
    this.hydrantFeatures.push(hydrant)
    this.$nextTick(function(){
      this.hydrantManualObj = {
          geometry: {type: "Point", coordinates: []},
          properties: {capcolor: '', serviceaddress: ''}
        }
      var map = this.mapHold
      map.removeLayer(this.layerTempId)
      map.off('click')
      this.selectedLocation = ''
      this.hydrantManual = false
      this.viewMap()
    })

    },
    mouseClickEvent(data){
    this.selectedLocation = data
    var map = this.mapHold
    if(this.layerTempId){
          map.removeLayer(this.layerTempId)
    }      
    var markerGroup = Leaf.layerGroup().addTo(map)
    var layerId = Leaf.marker([data.lat,data.lng]).addTo(markerGroup)
    this.layerTempId = markerGroup
    },
    setBuildingData(data){
      this.buildingData = data
    },
    getHydrantData(){
      var data = this.buildingData.latlng
      var point = this.buildingData
      this.hydrantPointData = []
      this.hydrantSearch = false
       axios({
         method: 'get',
         url: 'https://ags3.scgov.net/server/rest/services/Hosted/wHydrant/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=' + data.lng + ',' + data.lat + '&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&distance=400&units=esriSRUnit_Meter&outSR=4326&f=geojson',
       //  url: 'https://ags3.scgov.net/server/rest/services/Hosted/wHydrant/FeatureServer/0/query?returnGeometry=true&where=1%3D1&outSr=4326&outFields=*&inSr=4326&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometryPrecision=6&distance=300&unit=meters&SR=4326&resultRecordCount=100&f=geojson',
         headers: {
          'Content-Type' : 'application/json',
        } 
       }).then((response) => {
         var availableHydrants = response.data.features
         this.getMuncipleHydrants(availableHydrants)

    })
    },
    getMuncipleHydrants(county){
      var data = this.buildingData.latlng
            var point = this.buildingData
        axios({
         method: 'get',
         url: 'https://ags3.scgov.net/server/rest/services/Hosted/wMunicipalHydrant/FeatureServer/0/query?where=1%3D1&outFields=*&geometry=' + data.lng + ',' + data.lat + '&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&distance=300&units=esriSRUnit_Meter&outSR=4326&f=geojson',
         //url: 'https://ags3.scgov.net/server/rest/services/Hosted/wMunicipalHydrant/FeatureServer/0/query?returnGeometry=true&where=1%3D1&outSr=4326&outFields=*&inSr=4326&geometry=' + data.x + ',' + data.y + '&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometryPrecision=6&distance=300&unit=meters&SR=4326&resultRecordCount=100&f=geojson',
         headers: {
          'Content-Type' : 'application/json',
        } 
       }).then((response) => {
         var availableHydrants = response.data.features

         if(this.hydrantFeatures){
           var arrayofids = this.hydrantFeatures.map(p => p.id)

           for(var i in arrayofids){
             var index = availableHydrants.map(p => p.id).indexOf(arrayofids[i])

             var index2 = county.map(p => p.id).indexOf(arrayofids[i])

             if(index >= 0){
               availableHydrants.splice(index, 1)
             }
             if(index2 >= 0){
               county.splice(index, 1)
             }
           }
           this.hydrantPointData = county.concat(availableHydrants)
         } else {
         this.hydrantPointData = county.concat(availableHydrants)
         }
         this.hydrantSearch = true
                this.viewMap(data, point, this.mapHold)
       })
    },
    viewMapBuild(data, point){
      this.showMap = true
      this.$nextTick(function(){

        this.setMap(data, point)
        
      })
    },
    clearHydrantSearchData(){
      this.hydrantPointData = []
      
      this.setMap()
    },
    setMap(data, point){
      //  console.log(data)
         if(data || point){
        this.holdMapObj = {data: data, point: point}
        } else {
          var data = this.holdMapObj.data
          var point = this.holdMapObj.point
        }
          var center = data.latlng
          var zoom = 18
        var imageType = this.imageType
        if(this.responseView){
        document.getElementById(this.uniqueId).style.height = document.documentElement.clientHeight - 63 + 'px'
        } else {
        document.getElementById(this.uniqueId).style.height = '700px'
        }
        var string = "<div id='mapSpot-" + this.uniqueId + "' style='width: 100%; height: 100%;'></div>"
        document.getElementById(this.uniqueId).innerHTML = string
        var map = Leaf.map('mapSpot-' + this.uniqueId).setView(center, zoom);

        /* if(imageType == 'ImageryTransportation'){
          var imageType = 'Imagery'
          var overlay = 'ImageryTransportation'
           esri.basemapLayer(imageType).addTo(map);
           esri.basemapLayer('ImageryTransportation').addTo(map);
        } else {
            esri.basemapLayer(imageType).addTo(map);
        }      
        esri.basemapLayer(imageType).addTo(map); */
        if(imageType == 'ImageryTransportation'){
        esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer',
            maxZoom: 24,
            maxNativeZoom: 20
        }).addTo(map)
            esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Transportation/MapServer',
            maxZoom: 24,
            maxNativeZoom: 19
        }).addTo(map)
        } else {
        esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer',
            maxZoom: 24,
            maxNativeZoom: 19
        }).addTo(map)
        }
        this.mapHold = map
        if(!this.tempView){
        this.viewMap(data, point, map)
        } else if (this.tempView){
          this.mapSetBuilding()
        }
    },
    viewMap(data, point, map){
        if(!data || !point || !map){
          var data = this.holdMapObj.data
          var point = this.holdMapObj.point
          var map = this.mapHold
        }
        map.off('zoomend')
        if(this.buildingData.rooffloortype){
          console.log(this.buildingData)
          var primaryBuilding = Leaf.icon({ iconUrl: this.buildingData.rooffloortype, iconSize: [50, 50]})
        } else {
              var primaryBuilding = Leaf.icon({ iconUrl: '/images/preplanicons/hydrant-grey.png', iconSize: [40, 40]})
        }
        var valve = Leaf.icon({ iconUrl: '/images/preplanicons/facp.png', iconSize: [40, 40]})
        if(this.fireprotectionFeatures){
          var vm = this
          var hyAvail = this.fireprotectionFeatures
          var fpArray = []
          if(this.fireProtectionLayerGroup){
            map.removeLayer(this.fireProtectionLayerGroup)
          }
          for(var i in hyAvail){
             fpArray.push(Leaf.marker([hyAvail[i].geometry.coordinates[1], hyAvail[i].geometry.coordinates[0]],  { feature: hyAvail[i], icon: eval(hyAvail[i].type)}))
          }
          var fpResponseGroup = new Leaf.featureGroup(fpArray)
          this.fireProtectionLayerGroup = fpResponseGroup
          var boolean = this.btnVisibilityGroup.find(p => p.item == 'Valve Location')
          if(this.addMapData){
            fpResponseGroup.addTo(map)
          } else {
          this.mapIconBuildOut(boolean, fpResponseGroup, map, 'fireprotectionFeatures')
          }
        }
        this.$nextTick(function(){
        var primaryMarker = []
        primaryMarker.push(Leaf.marker(data.latlng, { icon: primaryBuilding, zIndexOffset: 1000 }))
        var primaryMarkerGroup = new Leaf.featureGroup(primaryMarker)
        if(this.primarySketch && !this.edit){
              primaryMarkerGroup.on('click', function (layer) {
              var obj = {}
              obj['properties'] = {}
              obj.properties['serviceaddress'] = 'PRIMARY BUILDING SKETCH'
              obj['photoUrl'] = vm.primarySketch
              obj['large'] = true
              vm.mapModalFeature = obj
              vm.modalShow = true
              })
        }
        if(this.edit){
              primaryMarkerGroup.on('click', function (layer) {
              var obj = {}
              obj['properties'] = {}
              obj.properties['serviceaddress'] = 'PRIMARY BUILDING SKETCH'
              obj['photoUrl'] = vm.primarySketch
              obj['large'] = true
              vm.mapModalFeature = obj
              vm.modalShow = true
              })
        } //<latitude>27.298434</latitude><longitude>-82.50775</longitude><latitude>27.340114</latitude><longitude>-82.49708</longitude>
        primaryMarkerGroup.addTo(map)
        if(this.measureControl){
          map.removeControl(this.measureControl)
        }
        var options = { primaryAreaUnit: 'sqfeet',
                        secondaryAreaUnit: 'acres',
                        position: 'topleft'}
        var measureControl = new Leaf.Control.Measure(options);
        this.measureControl = measureControl
        measureControl.addTo(map);
        map.on('measurefinish', function(data){
          console.log(data)
        })
       /*

        */
        }) 

    },
    mapIconBuildOut(boolean, fpResponseGroup, map, feature){
          var vm = this
          var zoom = map.getZoom()
       //   console.log(boolean.item + ' Zoom ' + zoom)
          if(boolean.boolean){
              if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
             fpResponseGroup.addTo(map)
             boolean.autoOn = false
          } else if(boolean.boolean == false){
             boolean.autoOn = false
          
              map.removeLayer(fpResponseGroup)
          } else if (boolean.boolean == null){
      //     console.log(boolean.item + ' Null ' + zoom)
          if(map.getZoom() >= 20){
        //    console.log(boolean.item + ' Map ' + zoom)
          if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
            fpResponseGroup.addTo(map);
          }
          map.on('zoomend', function() {
          if (map.getZoom() <20 && !boolean.boolean){
                    boolean.autoOn = false
                
                    map.removeLayer(fpResponseGroup);
       //              console.log(boolean.item + ' Click 1 ' + zoom)
            }
          else if (map.getZoom() >=20 && boolean.boolean && !boolean.autoOn){
        //        console.log(boolean.item + ' Click 2 ' + zoom)
                      if(!this.edit){
                    fpResponseGroup.on('click', function (layer) {
                    vm.mapModalFeature = layer.propagatedFrom.options.feature
                    vm.modalShow = true
                    })}
                   fpResponseGroup.addTo(map);
          } else if (map.getZoom() >=20 && (boolean.boolean == null)){
  //    console.log(boolean.item + ' Click 3 ' + zoom)
                  boolean.autoOn = true
                  if(!this.edit){
                    fpResponseGroup.on('click', function (layer) {
                    vm.mapModalFeature = layer.propagatedFrom.options.feature
                    vm.modalShow = true
                    })}
                  fpResponseGroup.addTo(map);
          } else if (map.getZoom() <20 && boolean.boolean){
              if(!this.edit){
              fpResponseGroup.on('click', function (layer) {
              vm.mapModalFeature = layer.propagatedFrom.options.feature
              vm.modalShow = true
              })}
            //           console.log(boolean.item + ' Click 4 ' + zoom)
             fpResponseGroup.addTo(map);
            }else {
        //    console.log('ELSE')
          }
        });
        }
    },
    updateCoordinates(data, type, id){
      var ref = this[type]
      var item = ref[id]
      item.geometry.coordinates = [data.lng,data.lat]
      this.viewMap()
    },
    mapEditRemove(){
      var map = this.mapHold
         map.off('click')
    },
    getRefPhotos(data, type){
      
      if(type && data){
        return data.filter(p => {
          if(p.type && p.type.length > 0){
            return p
          }
        })
      } else if(data) {
        return data.filter(p => {
          if(p.type && p.type.length <= 0){
            return p
          } else if (!p.type){
            return p
          }
        })
      }

    },
    removeRefrencePhoto(data){
      var photoIndex = this.refPhotos.map(p => p.url).indexOf(data.photoUrl)
      var emitObj = {item: data, photoIndex: photoIndex}
        this.$emit('photoArrayUpdate', emitObj)
        data.photoUrl = ''
        this.mapModalFeature = ''
        this.modalShow = false
    },
    openPhotoSelectorModel(data, type){
      this.tempPhotoHoldId = {id: data, type: type}
      this.photoModelShow = true
    },
    openBusinessSelectorModel(data, type){
      this.tempPhotoHoldId = {id: data, type: type}
      console.log(data)
      console.log(type)
      console.log(this[type])
      this.businessModelShow = true
    },
    tagBusinessToItem(id, status){
         this.resetBusinessTable = false
      var obj = this.tempPhotoHoldId
      var ref = this[obj.type]
      var item = ref.find(p => p.id == obj.id)
      if(item.businessIds){
        if(status){
        item.businessIds.push(id)
        } else {
          var index = item.businessIds.indexOf(id)
          if(index > -1){
          item.businessIds.splice(index, 1)
          }
        }
      } else {
        if(status){
          item['businessIds'] = []
          item.businessIds.push(id)
        }
      }
      this.$nextTick(function(){
        this.resetBusinessTable = true
      })
    },
    savePhotoReference(url, add){
      var obj = this.tempPhotoHoldId
      var ref = this[obj.type]
      var item = ref.find(p => p.id == obj.id)
      item['photoUrl'] = url
      var emitObj = {item: item, photoIndex: this.refPhotos.map(p => p.url).indexOf(url)}
      if(add){
        emitObj['add'] = true
      }
      this.$emit('photoArrayUpdate', emitObj)
       this.photoModelShow = false
    },
    mapSetBuilding(){
      var map = this.mapHold
       var vm = this
        map.on('click', function(ev) {
          vm.$emit('mapBuildingLatLng', ev.latlng)
          if(vm.tempBuildingMarker){
            map.removeLayer(vm.tempBuildingMarker)
          }
          var tempBuildingMarkerArray = []
          tempBuildingMarkerArray.push(Leaf.marker(ev.latlng))
          var tempBuildingMarker = new Leaf.featureGroup(tempBuildingMarkerArray)
          vm.tempBuildingMarker = tempBuildingMarker
          tempBuildingMarker.addTo(map)
       //   console.log(ev.latlng); // ev is an event object (MouseEvent in this case)
        });
    },
    mapEdit(data, type){
        var ref = this[type]
        var index = ref.map(p => p.id).indexOf(data)
        var map = this.mapHold
        var vm = this
        map.on('click', function(ev) {
          vm.updateCoordinates(ev.latlng, type, index); // ev is an event object (MouseEvent in this case)
        });
    },

    mapExtras(){
    
          var map = this.mapHold
        var vm = this
        map.on('click', function(ev) {
            vm.mouseClickEvent(ev.latlng); // ev is an event object (MouseEvent in this case)
        });

  },
   

}
}

</script>
<style src='leaflet-measure\dist\leaflet-measure.css'>
    /* global styles */
</style> 
<style>
  .startprompt h3 {
    font-size: 16px;
  }
  .js-measuringprompt h3 {
   display: none;
  }
	.gps_ring {	
		border: 3px solid #e2070d;
		 -webkit-border-radius: 60px;
		 height: 40px;
		 width: 40px;		
	    -webkit-animation: pulsate 1s ease-out;
	    -webkit-animation-iteration-count: infinite; 
	    /*opacity: 0.0*/
	}
	
	@-webkit-keyframes pulsate {
		    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
		    50% {opacity: 1.0;}
		    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
	}
</style>
<style scoped>
.leaflet-marker-icon.pulse {
      box-shadow: 1px 1px white;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>

.iconAddMapp {
  width: 40px;
  height: auto;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
