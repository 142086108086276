<template>
<div>
        <b-navbar class="blogSpanHeader" toggleable="lg" type="dark" variant="dark" v-if="formatedTableValuesPreFilter && siteconfig.deptAbbr == 'DEMO'">
                     <b-navbar-brand class="ml-2">Runs ({{ formatedTableValues.length }})</b-navbar-brand>
                     <b-btn v-if="dataStoreReturn && false" @click="saveToFile" >Save New Data</b-btn>
              <b-navbar-nav>
        <b-nav-form class="ml-2">
        </b-nav-form>
              </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-btn variant="outline-success"  size="sm" @click="dataViewMode == 'Map' ? (dataViewMode = 'Table', disableMapStatus = false, mapStatus = 'both') : (dataViewMode = 'Map', disableMapStatus = true, mapStatus = true)">{{ dataViewMode == 'Map' ? 'Table' : 'Map' }}</b-btn>
            </b-navbar-nav>

      </b-navbar>
      <b-btn @click="unitsFilterTemp = []">Clear Units</b-btn><b-btn @click="unitsFilterTemp = unitsFilterOptions.map(p => p.value)">Select All Units</b-btn>
      <b-form-checkbox-group id="check-box-unit" v-model="unitsFilterTemp" :options="unitsFilterOptions" />
      <b-btn @click="beatFilterTemp = []">Clear Beat</b-btn><b-btn @click="beatFilterTemp = beatFilterOptions.map(p => p.value)">Select All Units</b-btn>
      <b-form-checkbox-group id="check-box-beat" v-model="beatFilterTemp" :options="beatFilterOptions" />
      <b-btn block variant="success" @click="beatFilter = beatFilterTemp, unitsFilter = unitsFilterTemp">Apply Filter</b-btn>
       <b-form-file v-if="false" v-model="uploadedCSVFile" @change="convertCSVData" placeholder="Paste Excel Data Here" />
      <b-card no-body v-if="dataViewMode == 'Table' && siteconfig.deptAbbr == 'DEMO'">
        <b-tabs card>
          <b-tab title="Table">
     <b-table  :fields="fields" :items="formatedTableValues" :per-page="10" :current-page="currentPage">

              </b-table>
       <b-pagination class="pt-2" align="center" size="md" v-if="formatedTableValues && dataViewMode == 'Table'" :total-rows="formatedTableValues.length" v-model="currentPage" :per-page="10" />
          </b-tab>
          <b-tab title="Beat" v-if="formatedTableValues">
            <b-table :fields="['beat', 'total', 'transport', 'nonTransport', 'percentageNon', 'beatPercentage', 'countyTotalPercentage']" :items="getCallsByBeatReturn" >
              
            </b-table>
          </b-tab>
          <b-tab title="Apparatus" v-if="formatedTableValues">
             <b-table :fields="['unit', 'total', 'transport', 'nonTransport', 'percentageNon', 'beatPercentage', 'countyTotalPercentage']" :items="getCallsByUnitReturn" >
              
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <preplanMap v-if="dataViewMode == 'Map'" :responseView="false" :availablePreplans="formatedTableValues" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import printJS from 'print-js'
import DatePicker from '../../inputfields/Datepickermod.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import Leaf from 'leaflet'
import preplanMap from './runMaps.vue'
import Multiselect from 'vue-multiselect'
import convert from 'xml-js'
import PapaParse from 'papaparse'
// import jsonDataHold from '../../../../jsondataRunHold.json'
export default {
  props:[], 
components:{
      'preplanMap' : preplanMap,
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,
 
},
  data () {
    return {
        fields: ['date', 'runNumber', 'units', 'beat', 'transport'],
        dataViewMode: 'Table',
        sortBySelected: true,
        uploadedCSVFile: null,
        dataStore: null,
        currentPage: 1,
        unitsFilterOptions: [],
        beatFilterOptions: [],
        unitsFilter: [],
        beatFilter: [],
        unitsFilterTemp: [],
        beatFilterTemp: [],
    }
  },
  created(){
  
  },
  mounted(){
      if(this.siteconfig.deptAbbr == 'DEMO'){
       this.dataStore = [] // jsonDataHold
      }
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'preplanStatus',
          'siteconfig'
        ]),
        formatedTableValuesPreFilter(){
         return this.formatedTableValues
        },
        getCallsByBeatReturn(){
          var array = this.formatedTableValues
          return this.getCallsByBeat(array)
        },
        getCallsByUnitReturn(){
          var array = this.formatedTableValues
          return this.getCallsByUnit(array)
        },
        formatedTableValues(){
          console.log('FORMATED TABLE VALUES')
          var array = _.cloneDeep(this.dataStoreReturn)
          var unit = this.unitsFilter
          var beat = this.beatFilter
          if(array && unit){
          array = array.filter(p => {
              var units = p.units.split(' ')
              var returnUnit = false
              var returnBeat = false
              for(var u in units){
                if(unit.indexOf(units[u]) != -1){
                  returnUnit = true
                }
              }
              if(beat.indexOf(p.beat) != -1){
                  returnBeat = true
              }
              if(returnUnit && returnBeat){
              return p
              }
          })
          }
          return array
        },
        dataStoreReturn(){
          var array = _.cloneDeep(this.dataStore)
          console.log('RUNNING DATA STORE')
          var unitHold = []
          var beatHold = []
          if(array){
            array = array.map(item => {
                var obj = item
                if(item.attached && item.attached[0]){
                  beatHold.push(item.attached[0].beat)
                  obj['beat'] = item.attached[0].beat
                  obj['date'] = item.attached[0].createdAt
                  var transport = item.attached.filter(p => p.transport && p.transport != '')
                  var units = [...new Set(item.attached.map(p => p.unit))]
                  if(units){
                    var string = ''
                    for(var u in units){
                      unitHold.push(units[u])
                      string = string + units[u] + ' '
                    }
                    obj['units'] = string
                  }
                  if(transport.length > 0){
                    obj['transport'] = true
                  }

                  }
                  return obj
            

            })
            var unitsFilter = [...new Set(unitHold.map(p => p))]
            var beatFilter = [...new Set(beatHold.map(p => p))]
            beatFilter = _.sortBy(beatFilter)
            unitsFilter = _.sortBy(unitsFilter)
            this.setBeatUnits(unitsFilter, beatFilter)

          //  console.log(unitsFilter)
          //  console.log(beatFilter)
         //   console.log(array)
            return array
          }
          return array
        },
  

    }, 
  methods:{
    setBeatUnits(unitsFilter, beatFilter){
            this.unitsFilter = unitsFilter
            this.unitsFilterTemp = unitsFilter
            this.unitsFilterOptions = unitsFilter.map(p => p = {text: p, value: p})
            this.beatFilter = beatFilter
            this.beatFilterTemp = beatFilter
            this.beatFilterOptions = beatFilter.map(p => p = {text: p, value: p})
    },
    getCallsByUnit(data){
      var unit = _.cloneDeep(this.unitsFilter)
      console.log('RUNNING CALLS BY Unit')
      var totalLength = data.length
      console.log(totalLength)
      unit = unit.map(item => {
          var obj = { unit: item }
          obj['calls'] = data.filter(p => p.units.split(' ').indexOf(item) != -1)
          obj['total'] = obj.calls.length
          obj['transport'] = obj.calls.filter(p => p.transport).length
          obj['nonTransport'] = obj.total - obj.transport
          obj['percentageNon'] = ((obj.nonTransport / obj.total) * 100).toFixed(2) + '%'
          obj['beatPercentage'] = ((obj.total / totalLength) * 100).toFixed(2) + '%'
          obj['countyTotalPercentage'] = ((obj.total / this.dataStoreReturn.length) * 100).toFixed(2) + '%'
          return obj
      })
      unit = unit.filter(p => p.total != 0)
      // console.log(unit)
      return unit
    },
    getCallsByBeat(data){
      var beat = _.cloneDeep(this.beatFilter)
      console.log('RUNNING CALLS BY BEAT')
      var totalLength = data.length
      beat = beat.map(item => {
          var obj = { beat: item }
          obj['calls'] = data.filter(p => p.beat == item)
          obj['total'] = obj.calls.length
          obj['transport'] = obj.calls.filter(p => p.transport).length
          obj['nonTransport'] = obj.total - obj.transport
          obj['percentageNon'] = ((obj.nonTransport / obj.total) * 100).toFixed(2) + '%'
          obj['beatPercentage'] = ((obj.total / totalLength) * 100).toFixed(2) + '%'
          obj['countyTotalPercentage'] = ((obj.total / this.dataStoreReturn.length) * 100).toFixed(2) + '%'
          return obj
      })
      beat = beat.filter(p => p.calls && p.calls.length > 2)
      return beat
    },
    convertCSVData(e){
      var file = e.target.files || e.dataTransfer.files
      console.log(file)
      var vm = this
     PapaParse.parse(file[0], {
      header: true,
      complete: function(results) {
        console.log("Finished:");
        vm.parseTableData(results.data)
      }
});
    },
    parseTableData(data){
      var set = [...new Set(data.map(p => p['runNumber']))]
      set = set.filter(p => p && p.match('F'))
      set = set.map(item => {
        var obj = {}
        obj['runNumber'] = item
        var map = data.find(p => p.runNumber == item)
        if(map){
          obj['map'] = {lat: map.lat, lng: map.lng}
        }
        obj['attached'] = data.filter(p => p.runNumber == item)
        return obj
      })
      console.log(set)
      this.dataStore = set
    },
    saveToFile() {
      var jsonData = this.dataStore
      jsonData = JSON.stringify(jsonData)
      // var data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
      var filename = 'jsondataRunHold'
      let blob = new Blob([jsonData], { type: 'text/json;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        let link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
    }
  }
}


}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.numberInputSize {
  width: 70px;
}
.textInputSize {
  width: 140px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
