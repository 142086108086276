<template>
<div @click="addModalOpen">
  <b-modal
       id="repairModal"
       ref="repairModal"
       v-model="showModal"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
         >
         <template slot="modal-header" v-if="!showModalConfirmation">
           <span>
           Create New Station or Apparatus Repair Entry
           <img src="/images/siteicons/help-icon-right-align.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo"></span>
           <b-btn align="right" size="sm" variant="danger" @click="showModalswitchEmitVehicle">x</b-btn>
         </template>
         <div class="modal-body" v-if="!showModalConfirmation">
                      <tutdata v-if="showTutVideo" singlePost="newrepair" />
           <b-container>
          <b-row align-h="center">
          <h5 :class="shiftDateCalcClass" @click="changeDate = !changeDate" v-show="!changeDate" id="todaysDateDisplay">Discovered: {{ currentDateSelected }} (edit)</h5>
          <datepicker v-if="changeDate" @input="setCurrentDate" v-model="currentDateSelectedChange"></datepicker>
        </b-row><br>
        <b-row><b-col class="mr-2" cols="*">
              <timepicker class="timeDesk" v-if="disctimeValueSet != ''" @change="updateTimePicker" v-model="disctimeValueSet"></timepicker>
              <input class="timeMobile" @change="updateTimeInput" type="time" v-model="timeData" /></b-col><b-col cols="*" align="left">
              <b-btn size="sm" variant="primary" @click="setTimes">Now</b-btn></b-col><b-col>
              <h5 :class="shiftDateCalcClass">Shift: {{ shiftDateCalc }} </h5></b-col></b-row>
                          <b-row><b-col>
              <b-form-radio-group id="radios2" v-model="formTypeSelected" name="radioSubComponent" @change="apparatusSelectValue = ''">
                <b-form-radio value="apparatus">Apparatus</b-form-radio>
               <b-form-radio value="facilities" >Facilities</b-form-radio>
               <b-form-radio value="asset" v-if="logisticAssetData">Asset</b-form-radio>
        </b-form-radio-group>
            </b-col></b-row></b-container>
            <b-card no-body>
              <b-tabs card>
                <b-tab title="Item Info">
                  <b-container class="pt-3" >
            <b-form-input v-if="formTypeSelected == 'asset' && !assetFlag" v-model="assetSearch" placeholder="Enter Serial Number or Asset Id to Search" />
            <b-form-checkbox v-if="formTypeSelected == 'asset'" v-model="assetFlag">This is a non-asset item</b-form-checkbox>
            <b-container fluid v-if="assetReturnList && formTypeSelected == 'asset' && !assetFlag" class="mt-2">
            <b-row v-for="asset in assetReturnList" :key="asset.id" class="mb-2">
              <b-col align="left">
                <p class="mb-0"><span v-if="taggedAsset == asset.id" class="text-success">&#10004; </span>{{ asset.assetDescription }} (id: {{ asset.assetId }})</p>
                  </b-col>
                  <b-col cols="2" align="left">
                    <b-btn variant="primary" @click="taggedAsset = asset.id" size="sm">Tag</b-btn>
                    </b-col>
              </b-row>
              </b-container>
            <b-row class="my-1 text-danger" v-if="formTypeSelected == 'asset' && !assetReturnList && !assetFlag"><b-col>Too Many Search Results</b-col></b-row>
            <multiselect v-if="formTypeSelected == 'apparatus'"  v-model="apparatusSelectValue" :options="apparatusData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Select Apparatus (Only if Apparatus Related)" label="apparatusName" track-by="apparatusName"></multiselect>
            <multiselect v-model="stationSelectValue" :options="stationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Select Parent Station" label="stationName" track-by="stationName"></multiselect>
            <b-form-select class="shiftSelectInput mb-3" v-model="repairStatusPriority" :options="repairStatusPriorityData">
            </b-form-select>
            <b-form-select class="shiftSelectInput" text-field="repairstatusName" value-field="repairstatusName" v-model="repairStatusSelect" :options="repairStatus">
            </b-form-select>
            <input type="text" class="itemDescription" v-model="itemShort" placeholder="Short Item Description" />
            <textarea class="textAreaDescription" rows="4" v-model="detailedDescription" placeholder="Detailed Item Description, including specifics about the required repair. Please refer to your departments directives. This will be your Email body if you choose to forward this repair to other users"></textarea>
                  </b-container>
               </b-tab>
                <b-tab title="Photos">
              
              <h5 class="sub-section-heading">Optional Photo(s) of Item:</h5>
                <b-container>
                      <b-form-file ref="fileinput" @change="postImage" v-model="pictureUpload" />
   
                      <br>
                </b-container>
                <b-container>
                      <b-carousel v-if="carouselToggle" id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                  controls
                                 indicators
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template v-for="(pic, index) in pictureFileArray">
                        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" />
                        </template>
                      </b-carousel>
                      </b-container>
                </b-tab>
                <b-tab title="Repair Notes">
            <b-container  id="tablePassDown"><b-row>
              <b-col align="center"><h5 class="sub-section-heading">Repair Notes/Tracking Updates</h5></b-col></b-row>

                                       <b-container v-for="(post, index) in field_repair_notes" :key="index">
                                             <b-row>
                                                
                                                    <b-col align="left"><h5>{{ post.author }}</h5></b-col>
                                                    <b-col cols="2" align="right"><b-btn variant="danger" size="sm" @click.stop="removeCurrentRow(post)">x</b-btn>
                                                  </b-col>
                                              </b-row>
                                              <b-row>
                                                <b-col align="left">
                                                  <em>{{ post.timestamp }}</em>
                                                </b-col>
                                              </b-row>



                                            <b-form-textarea :rows="3" :max-rows="6" v-model="post.note" />


                                      </b-container>
                                               <b-btn variant="primary" size="sm"  @click.stop="addNewRow">Add New</b-btn>


        </b-container>
                </b-tab>
              </b-tabs>
            </b-card>
              </div>
         <template slot="modal-footer" v-if="!showModalConfirmation">
             <b-btn variant="primary" v-if="formTypeSelected != 'asset'" @click.stop="postNewRepair" 
             :disabled="stationSelectValue == '' || repairStatus == '' || itemShort == '' || detailedDescription == ''"
             >Submit</b-btn>
             <b-btn variant="primary" v-if="formTypeSelected == 'asset'" @click.stop="postNewRepair"
             :disabled="stationSelectValue == '' || repairStatus == '' || itemShort == '' || detailedDescription == '' || (taggedAsset == '' && !assetFlag)">
             Submit</b-btn>
           </template>


      <template slot="modal-header" v-if="showModalConfirmation">
             Post Confirmation
      </template>
      <div class="modal-body"  v-if="showModalConfirmation">
             <div v-if="postData == ''">
                   ...Submitting post
                   <br>
               <img src="/images/siteicons/MalteseLoadingGif.gif">
             </div>
             <div v-if="postData != ''">
                   Your Post has been submitted!
             </div>
             <repair v-if="postData != ''" :repair="postData" :showPost="true" />
           </div>
             <template v-if="showModalConfirmation" slot="modal-footer">
                   <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                     Close Message
                   </b-btn>
            </template>
      </b-modal>
  </div>
</template>

<script>
 const configGet = {
  headers: {"content-type": "application/json", "cache-control": "no-cache", "postman-token": "0bff497b-0ac5-79c8-b530-5f2b6fedbdda",}
};
import repair from '../tracking/repairs/stationrepairfilterdata.vue'
import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios'
import TutData from '../tracking/deptTutorialsdata.vue'
import DatePicker from '../inputfields/Datepickermod.vue'
import TimePicker from '../inputfields/timepicker.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
  props:["showModal", "filterDate"], 
components:{
      'tutdata' : TutData,
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,
      'repair' : repair,
},
  data () {
    return {
        showTutVideo: false,
        pictureUpload: '',
        trueBoo: true,
        imageLoadReset: true,
        slide: 0,
        sliding: false,
        assetSearch: '',
        itemShort: '',
        titleDescription: '',
        showModalConfirmation: false,
        field_repair_notes:[],
        currentDateSelected: this.todaysDate,
        testValue: null,
        detailedDescription: '',
        stationSelectValue:'',
        apparatusSelectValue:'',
        repairStatusSelect: 'Open',
        changeDate: false,
        LogbookFormat: 'hh:mm',
        editButton: 'edit',
        showEditView: false,
        newDataUUIDL: '',
        imageFileName: '',
        imageFileFID: '',
        carouselToggle: false,
        newImageFileData: '',
        fileName: '',
        fileType: '',
        textPost: true,
        imagePost: false,
        imageResponseData: [],
        currentTime: '',
        disctimeValueSet: '',
        postData: '',
        timeData: '',
        imageSrc: '',
        pictureFileArray: [],
        imageLoader: '',
        taggedAsset: '',
        formTypeSelected: 'apparatus',
        assetFlag: false,
        repairStatusPriority: '',
        repairStatusPriorityData: [
          { text: 'Priority 01 - Non Critical', value: 'Priority 01 - '},
          { text: 'Priority 02 - Preventative Maint.', value: 'Priority 02 - '},
          { text: 'Priority 03 - Urgent/Conservation Request', value: 'Priority 03 - '},
          { text: 'Priority 04 - Emergency Work Order', value: 'Priority 04 - '},
          {text: 'Priority if required', value: ''}
        ]


    }
  },
  created(){
    this.currentDateSelected = moment().format('MM/DD/YYYY');
    this.currentDateSelectedChange = this.currentDateSelected
    this.setTimes()
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'repairStatus',
          'siteconfig',
          'logisticAssetData',
          'siteconfig'
        ]),
      shiftDateCalc(){
               var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
    },
    shiftDateCalcClass(){
      var shift = this.shiftDateCalc
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shift)
      if(obj){
        return obj.color
      } return shift
    },
        assetReturnList(){
          var array = this.logisticAssetData.filter(p => {
            return p.assetSerialNumber.toLowerCase().match(this.assetSearch.toLowerCase()) || p.assetId.toLowerCase().match(this.assetSearch.toLowerCase())
          })
          if(array.length < 5){
            return array
          } else {
            return null
          }
        },
        getRepairFormDataBaseId(){
          if(this.formTypeSelected == 'apparatus'){
            return this.apparatusSelectValue.id
          } else if(this.formTypeSelected == 'facilities') {
            return this.stationSelectValue.id
          } else if (this.formTypeSelected == 'asset' && !this.assetFlag){
            return this.taggedAsset
            } else {
            return null
          }
        },
        getRepairFormDataAssetId(){
          return null
        },
      formatedDateExport () {
        var dateString = moment(this.currentDateSelected, 'MM/DD/YYYY').format("YYYY-MM-DD")
        var timeString = dateString + 'T' + this.currentTime
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
      },
      todaysDate() {
        this.currentDateSelected = moment().format('MM/DD/YYYY');
        return this.currentDateSelected
      },
      userPostName(){
        return this.userData.map(function(data) {
          return data.name;
        })},
      getTitleData(){
        if(this.apparatusSelectValue != ''){
          return this.currentDateSelected + ' | ' + this.apparatusSelectValue.apparatusName + ' | ' + this.stationSelectValue.stationName + ' | '  + this.userData.firstName + ' ' + this.userData.lastName
        } else {
          return this.currentDateSelected + ' | ' + this.stationSelectValue.stationName + ' | '  + this.userData.firstName + ' ' + this.userData.lastName
        }
      },
        getStationParent(){
          if(this.stationSelectValue){
            var parent = this.stationSelectValue.stationParent
            if(parent){
              return parent
            } else {
              return this.stationSelectValue.stationName
            }
          } else {
            return ''
          }
        }
    

    },
    watch: {
      imageSrc(data){
        console.log(data)
      },
      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    getFiles(obj){
      this.postImage(obj)
    },
    clearFiles () {
      this.$refs.fileinput.reset();
    },
    addModalOpen(){
        document.body.classList.add('modal-open');

    },
    setTimes(){
      this.currentTime = moment().format('HH:mm')
      var data = this.currentTime
      var timeSet = data.split(':')
      var timeString = { HH: timeSet[0], mm: timeSet[1] }
      this.disctimeValueSet = timeString
      this.timeData = data
    },
    updateTimePicker(event){
      this.currentTime = event.data.HH + ':' + event.data.mm
      this.timeData = event.data.HH + ':' + event.data.mm
    },
    updateTimeInput(event){
      var timeSet = this.timeData.split(':')
      var timeString = { HH: timeSet[0], mm: timeSet[1] }
      this.disctimeValueSet = timeString
      this.currentTime = this.timeData
    },
    setCurrentDate(){
      this.currentDateSelected = moment(this.currentDateSelectedChange).format('MM/DD/YYYY')
    },
    addNewRow(){
      var stamp = moment().format('MM/DD/YY HH:mm')
      var user = this.userData.firstName + ' ' + this.userData.lastName
      var string = { author: user, timestamp: stamp, note: ''}
      this.field_repair_notes.push(string)
    },
    removeCurrentRow(post){
      var idx = this.field_repair_notes.indexOf(post)
      this.field_repair_notes.splice(idx, 1)
    },
    timeEdit(post){
      var timeFormat = post.timeSplit.HH  + ':' + post.timeSplit.mm
      post.time = timeFormat
    },
    timeEditPick(post){
      var timeSet = post.time.split(':')
      var timeString = { HH: timeSet[0], mm: timeSet[1] }
      post.timeSplit = timeString
    },
    timeEditNow(post){
      var timeFormat = moment().format('HH:mm')
      post.time = timeFormat
      var timeSet = timeFormat.split(':')
      var timeString = { HH: timeSet[0], mm: timeSet[1] }
      post.timeSplit = timeString
    },

    showModalswitchEmitVehicle () {
      this.apparatusSelectValue = ''
      this.stationSelectValue = ''
      this.field_check_details = []
      this.shiftSelect = ''
      this.checkNumber = []
      this.currentDivSelection = 1
      bus.$emit('switchEmitRepair');
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      bus.$emit('refreshDomEvent', this.stationSelectValue.name)
      this.$store.dispatch('LOAD_USER_POSTS', this.getSessionData)
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalswitchEmitVehicle()
    },
    postImage(e) {
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      if(this.apparatusSelectValue != ''){
        var id = this.apparatusSelectValue.apparatusName
      }
      else if (this.stationSelectValue != ''){
        var id = this.stationSelectValue.stationName
      } else {
        var id = 'untagged'
      }
       var id = id.replace(/ /g, '-')
      var data = new FormData();
      data.append('uploadFile', files[0])
    /*  var reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    }, */
       axios({
        method: 'post',
        url: '/api/v1/upload/files/repairforms/' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.pictureFileArray.push(response.data);
          this.clearFiles()
          this.carouselToggle = true
        });

  
     },
     postNewRepair(){
       axios({
        method: 'post',
        url: '/api/v1/content/repairform',
        data: {
                "title": this.getTitleData,
                "repairformDate": this.formatedDateExport,
                "repairformStation": this.stationSelectValue.stationName,
                "repairformApparatusId": this.apparatusSelectValue.apparatusName,
                "repairformType": this.formTypeSelected,
                "repairformDbid": this.getRepairFormDataBaseId,
                "repairformShift": this.shiftDateCalc,
                "repairformStatus": this.repairStatusSelect,
                "repairformItem": this.repairStatusPriority + this.itemShort,
                "repairformDescription": this.detailedDescription,
                "repairformNotes":this.field_repair_notes,
                "repairformPicture": this.pictureFileArray,
                "repairformAuthor": this.userData.firstName + ' ' + this.userData.lastName,
                "repairformAuthorId": this.userData.id,
                "repairformStationParent": this.getStationParent,
              },
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {

         this.postData = response.data[0]
                 bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
        })
       bus.$emit('refreshUserPosts')
          this.showModalConfirmation = !this.showModalConfirmation;
    },

}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.Red-shift {
    background: #fff;
  color: #e2070d;
}
.Blue-shift {
    background: #fff;
  color: #201B9F;
}
.Yellow-shift{
  background: #ffffff;
  color: #ffc107;
}
.Green-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
