<template>
<div>
    
    <b-row class="mt-4"><b-col cols="4">
    <userPanel :user="userReport" :specialitiesArray="userReport.deptSpecialties" :defaultShow="true" :addShiftAndStation="true" />
    </b-col><b-col>
    <b-card no-body>
        <b-tabs card>
            <b-tab v-for="cred in userReportCredentials" :key="cred.id" :title="cred.credentialRequirementName">
                <b-row v-if="fitlerRecords(cred.id, 'header')">
                    <b-col v-if="fitlerRecords(cred.id, 'header').Compliance">
                        <h5>Complaince</h5>
                         <h6>{{ fitlerRecords(cred.id, 'header').Compliance }}%</h6>
                    </b-col>
                    <b-col v-if="fitlerRecords(cred.id, 'header').Completed">
                        <h5>Completed</h5>
                         <h6>{{fitlerRecords(cred.id, 'header').Completed }}Hrs</h6>
                    </b-col>
                    <b-col v-if="fitlerRecords(cred.id, 'header').array">
                        <h5>Total Modules</h5>
                         <h6>{{ fitlerRecords(cred.id, 'header').array.length }}</h6>
                    </b-col>
                    </b-row>
                <b-card>
                  <h4 class="text-danger" v-if="!fitlerRecords(cred.id, 'comp') && !fitlerRecords(cred.id, 'roll')">No Training Submitted for the Category</h4>
                  <b-tabs pills>
                    <b-tab title="Completed" v-if="fitlerRecords(cred.id, 'comp')">

                <b-table v-if="resetPage" :fields="fieldsRecords" :items="fitlerRecords(cred.id, 'comp')" :per-page="10" :current-page="currentPage[cred.id]">
                  <template slot="View Record" slot-scope="data">
                    <b-btn variant="info" @click="viewTrainingRecordSet(data.item)">View</b-btn>
                  </template>
                </b-table>
                <b-pagination v-if="fitlerRecords(cred.id, 'comp').length > 10"
               align="center" size="md" :total-rows="fitlerRecords(cred.id, 'comp').length" v-model="currentPage[cred.id]" @change="updatePage($event, cred.id)" :per-page="10">
                 </b-pagination>
                    </b-tab>
                    <b-tab title="Rollover" v-if="fitlerRecords(cred.id, 'roll')">
                  <b-table :fields="fieldsRollover" :items="fitlerRecords(cred.id, 'roll')">
                      <template slot="rolloverid" slot-scope="data">
                        {{ getRolloverHours(data.item.rolloverid) }}
                      </template>
                  </b-table>
                    </b-tab>
                  </b-tabs>
                </b-card> 
            </b-tab>
        </b-tabs>
        </b-card>
    </b-col>
    </b-row>
    <b-modal v-if="viewTrainingRecordModal" v-model="viewTrainingRecordModal" size="lg">
          <trainingRecordView :training="viewTrainingRecord" />
    </b-modal>
</div>
</template>
<script>

import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
import userPanel from '../../personnel/personnelDataFilter.vue'
import trainingRecordView from './../trainingView/trainingRecordView.vue'
export default {
  props:['userReport', 'userReportCredentials', 'trainingDrills'],
components:{
    userPanel,
    trainingRecordView
},
  data () {
    return {
        currentPage: {},
        viewTrainingRecordModal: false,
        viewTrainingRecord: {},
        resetPage: true,
        fieldsRecords: [
            {key: 'trainingRecordModuleName', label: 'Name'},
            {key: 'trainingRecordCompletionDate', label: 'Date'},
             {key: 'trainingRecordHours', label: 'Hours'},
              {key: 'trainingRecordAuthor', label: 'Author'},
              'View Record'
        ],
        fieldsRollover: [
           {key: 'rolloverid', label: 'Rollover From Credential Requirement'},
           {key: 'hours'}
        ]
    }
  },
  created(){
    for(var i in this.userReportCredentials){
      this.currentPage[this.userReportCredentials[i].id] = 1
    }

  },
  watch: {
   
  },
  computed:{
    ...mapState([
    'getSessionData',
    'deptContactData',
    'stationsData',
    'userData',
    'config',
    'userPosts'
    ]),


    },
    methods: {
        getRolloverHours(id){
          var index = this.userReportCredentials.map(p => p.id).indexOf(id)
          if(index != -1){
            var item = this.userReportCredentials[index]
            return item.credentialRequirementName
          } else {
            return 'Unknown Category'
          }
        },
        updatePage(data, id){
          this.currentPage[id] = data
          this.resetPage = false
          var el = document.documentElement.scrollTop || document.body.scrollTop
          this.$nextTick(function(){
            this.resetPage = true
            this.$nextTick(function(){
                document.documentElement.scrollTop = document.body.scrollTop = document.documentElement.scrollHeight
            })
            
          })

        },
        fitlerRecords(id, type){
            var index = this.userReport.trainingSort.map(p => p.id).indexOf(id)
            if(index != -1){
              var records = this.userReport.trainingSort[index]
            if(type == 'comp'){
            if(records.array && records.array.length > 0){
                return records.array
            } else {
                return null
            }
            } else if(type == 'header'){
            if(records){
                return records
            } else {
                return null
            }
            } else if(type == 'roll'){
            if(records.AttachedRollover && records.AttachedRollover.length > 0){
                return records.AttachedRollover
            } else {
                return null
            }
            } else {
              return null
            }
            } else {
              return null
            }
        },
        viewTrainingRecordSet(item){
          this.viewTrainingRecord = item
          this.viewTrainingRecordModal = true
        }



},

}
</script>

<style scoped>
.credential-table {
  text-align: center;
}
.changeWidth {
  width: 80%;
  margin: 0 auto 0;
  text-align: center;
}
.userPostIcon{
  position: relative;
  margin: 0px 0 -80px 0px;
  width:32px;
  height:32px;
  opacity: 0.3;
  
}
.blogadjust {
  text-align: center;
    width: 80%;
    margin: 0 auto 0;
}
.userPostForms{
  width:80%;
  padding-top: 10px;
  margin: 0 auto 0;
  border-bottom: 1px solid #aaa;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}



#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {
  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
@media screen and (max-width: 768px) {
.userPostIcon{
    display: none;
  
}
}

</style>
