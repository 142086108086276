<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin'">
    <b-textarea v-model="uploadedJsonReport" @input="fixDataReport()" placeholder="Paste Excel Data Here" />
    <download-excel
    class="ml-2"
    v-if="payrollCodesDataWithEdit.length > 0"
    name="payroll code export"
    :data="payrollCodesDataWithEdit">
    <b-btn block variant="info">Download Codes</b-btn>
    </download-excel>
    <b-btn v-if="uploadHold" @click="addBulkCodes">Add Bulk</b-btn>
    <b-btn v-if="updateHold" @click="updateCodesData">Update Codes</b-btn>
    <b-table :fields="fields" :items="payrollCodesDataWithEdit">
      <template slot="payrollCodeName" slot-scope="data" class="text-left">
        <div class="text-left nameWraper"  v-if="editItemId != data.item.id">
               <span>{{ data.item.payrollCodeName }} </span><br>
        <span v-if="editItemId != data.item.id" :class="'payroll-' + data.item.payrollCodeColor" class="mr-4">{{ data.item.payrollCode }}</span>
          <span v-if="editItemId != data.item.id" class="mr-4">{{ data.item.payrollCodeExport }}</span>
          </div>
          <b-form-input type="text" v-if="editItemId == data.item.id" v-model="data.item.payrollCodeName" />
        <b-row class="mt-2"><b-col>
        <b-form-input v-if="editItemId == data.item.id" v-model="data.item.payrollCode" type="text" />
            <b-form-input v-if="editItemId == data.item.id" v-model="data.item.payrollCodeExport" type="text" placeholder="Export Code" />
        </b-col><b-col>
          <b-dropdown v-if="editItemId == data.item.id" variant="colorSelect">
            <template slot="button-content">
              <span :class="'payroll-' + data.item.payrollCodeColor">Item Color</span>
            </template>
            <b-dropdown-item v-for="(opt, index) in payrollColorOptions"  :key="index" :value="opt" @click="data.item.payrollCodeColor = opt">
              <span :class="'payroll-' + opt">{{ opt.toUpperCase() }}</span>
            </b-dropdown-item>
          </b-dropdown>
          </b-col>
          <b-container v-if="editItemId == data.item.id" >
                  Hours Multiplier? Leave Blank for None. 
        <b-form-input v-model="data.item.payrollCodeMultiplier" type="number" />
        </b-container>
          </b-row>
      </template>
      <template slot="payrollCodeCategory" slot-scope="data">
        <span v-if="editItemId != data.item.id">{{ data.item.payrollCodeCategory == 'overtime' ? 'additions/overtime' : data.item.payrollCodeCategory }}</span>
        <b-form-select v-if="editItemId == data.item.id" v-model="data.item.payrollCodeCategory" :options="codeTypeOptions" />
        </template>
      <template slot="payrollCodeBankMod" slot-scope="data">
          <b-container fluid v-if="editItemId != data.item.id">
          <b-row v-for="(pb, indexpb) in data.item.payrollCodeBankMod" :key="indexpb">
            <b-col>{{ pb.mod }} {{ getBankName(pb.id) }} <span v-if="pb.multiplier">x{{ pb.multiplier }}</span></b-col>
            </b-row>
          </b-container>
        <b-container fluid v-if="editItemId == data.item.id">
          <b-row v-for="(pb, indexpb) in data.item.payrollCodeBankMod" :key="indexpb">
            <b-col>{{ pb.mod }} {{ getBankName(pb.id) }} <span v-if="pb.multiplier">x{{ pb.multiplier }}</span></b-col><b-col cols="2"><b-btn variant="danger" size="sm" @click="data.item.payrollCodeBankMod.splice(indexpb, 1)">X</b-btn></b-col>
            </b-row>
          </b-container>
        <b-card no-body border-variant="light" v-if="editItemId == data.item.id">
        <b-row class="pt-2">
          <b-col class="pl-4"> 
              <b-row><b-col class="mr-0 pr-1 pt-2" cols="*"><h6>Type:</h6></b-col>
              <b-col class="ml-0 pl-0"><b-form-select v-model="bankModType" :options="['addTo', 'subFrom']" /></b-col></b-row>
              <b-row><b-col class="mr-0 pr-1 pt-2" cols="*"><h6>Bank:</h6></b-col>
              <b-col><b-form-select v-model="bankModId" :options="bankModOptions" /></b-col></b-row>
              <b-row><b-col class="mr-0 pr-1 pt-2" cols="*"><h6>Multiplier:</h6></b-col>
              <b-col><b-form-input type="number" v-model="bankMultiplier" /></b-col></b-row>
              </b-col>
         
          </b-row> 
           <b-btn variant="primary" size="sm" @click="data.item.payrollCodeBankMod.push({mod: bankModType, id: bankModId, multiplier: bankMultiplier})">Add</b-btn>
           </b-card>                    
      </template>
        <template slot="Approval" slot-scope="data">
          <b-form-checkbox :disabled="editItemId != data.item.id" v-model="data.item.requireApproval" :check-value="true" :uncheck-value="false">Required?</b-form-checkbox><br>
            <b-form-checkbox :disabled="editItemId != data.item.id" v-model="data.item.selfApprove" :check-value="true" :uncheck-value="false">Self?</b-form-checkbox>
        </template>
        <template slot="row-details" slot-scope="row">
          <b-container fluid v-if="row.item.payrollCodeVisibility && editItemId != row.item.id">
            
            <b-row>
               <b-col><b-form-checkbox :disabled="editItemId != row.item.id" v-model="row.item.payrollCodeNoteRequired" :check-value="true" :uncheck-value="false">Required Note?</b-form-checkbox></b-col>
                 <b-col><b-form-checkbox  :disabled="editItemId != row.item.id" v-model="row.item.payrollCodeNotification" :check-value="true" :uncheck-value="false">Default Nofitication?</b-form-checkbox></b-col>
                    <b-col align="right" class="font-weight-bold">Visibility:</b-col><b-col cols="*" align="left"><span> {{ row.item.payrollCodeVisibility.length -1 }}</span></b-col>
                   <b-col align="right" class="font-weight-bold">Approval:</b-col><b-col  cols="*" align="left"><span>{{ row.item.payrollCodeApprovalRole.length -1 }}</span></b-col>
            </b-row>
            <b-row v-if="siteconfig.payroll.payrollLists && row.item.payrollCodeListId && row.item.payrollCodeListId.length > 0">
              <b-row class="mt-2"><span class="font-weight-bold mr-2">Show In List: </span><span class="mr-2" v-for="list in row.item.payrollCodeListId">{{ list }} </span></b-row>
            </b-row>
          </b-container>
          <b-container fluid v-if="row.item.payrollCodeVisibility && editItemId == row.item.id">
                         <b-row class="mb-4"><b-col><b-form-checkbox v-model="row.item.payrollCodeNoteRequired" :check-value="true" :uncheck-value="false">Required Note?</b-form-checkbox></b-col>
                 <b-col><b-form-checkbox  v-model="row.item.payrollCodeNotification" :check-value="true" :uncheck-value="false">Default Nofitication?</b-form-checkbox></b-col>
                 </b-row>
             <b-row><b-col align="right" cols="*" class="font-weight-bold">Visibility:</b-col><b-col align="left">    
          <b-form-group>
              <b-form-checkbox-group :id="'payrollCodeVis' + row.item.id"  v-model="row.item.payrollCodeVisibility" :options="payrollCodeVisibilityOptions">
                </b-form-checkbox-group>
         </b-form-group>
         </b-col>
             </b-row>
             <b-row><b-col align="right" cols="*" class="font-weight-bold">Approval:</b-col><b-col align="left">
          <b-form-group>
              <b-form-checkbox-group :id="'payrollCodeAppro' + row.item.id"  v-model="row.item.payrollCodeApprovalRole" :options="payrollCodeVisibilityOptions">
                </b-form-checkbox-group>
         </b-form-group>
         </b-col>
             </b-row>
             <b-row><b-col align="right" cols="*" class="font-weight-bold">Show in List:</b-col><b-col align="left">
        <b-form-group v-if="siteconfig.payroll.payrollLists">
              <b-form-checkbox-group :id="'payrollList' + row.item.id"  v-model="row.item.payrollCodeListId" :options="siteconfig.payroll.payrollLists">
                </b-form-checkbox-group>
         </b-form-group>
                  </b-col>
             </b-row>
          </b-container>
          </template>
        <template slot="edit" slot-scope="data">
          <b-btn v-if="editItemId != data.item.id" @click="editItemId = data.item.id" size="sm" variant="warning">Edit</b-btn>
            <b-btn v-if="editItemId == data.item.id" @click="editItemId = ''" size="sm" variant="danger">Cancel</b-btn>
              <b-btn v-if="editItemId == data.item.id" @click="updatePayrollCode(data.item)" size="sm" variant="info">Update</b-btn>
              <b-btn v-if="editItemId == data.item.id" @click="deletePayrollCode(data.item.id)" size="sm" variant="danger">Delete</b-btn>
        </template>
      </b-table>
</div>
</div>

</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import JsonExcel from 'vue-json-excel';
import PapaParse from 'papaparse'
export default {
  components: {
    multiselect,
  'download-excel' : JsonExcel,
  },
  name: 'roster-admin',
  props: ['payrollCodesData', 'payrollBanksData'],
  data () {
    return {
      apparatusSelectValue: null,
      stationSelectValue: '',
      editItemId: '',
      stationParentSelectValue: '',
      bankModId: '',
      bankMultiplier: 1,
      payrollColorOptions: ['default', 'Yellow', 'black', 'bright-blue', 'blue', 'bright-green', 'green', 'bright-orange', 'orange', 'bright-red', 'red', 'bright-purple', 'purple'],
      payrollCodeVisibilityOptions: [{text: 'admin', value: 'adminchief'}, 'fireops', 'emsops', 'shopops', 'trainingops', 'logisticsops', 'supervisor', 'user' ],
      fields: [
        'payrollCodeName',
        'payrollCodeCategory',
        'payrollCodeBankMod',
        'Approval',
        'edit'
      ],
      updateHold: null,
      addNew: false,
      dataObj: [{ payrollCode: "",
                  payrollCodeExport: "",
                  payrollCodeApprovalRole: ['admin'],
                  payrollCodeBankMod: [],
                  payrollCodeCategory: null,
                  payrollCodeColor: "",
                  payrollCodeName: "",
                  payrollCodeVisibility: ['admin'],
                  requireApproval: true,
                  selfApprove: false,
                  _showDetails: true,
                  payrollCodeNotification: false,
                  payrollCodeNoteRequired: false,
                  payrollCodeListId: null
                  }],
      bankModType: 'addTo',
      uploadedJsonReport: '',
      uploadHold: null,
      codeTypeOptions: [
        'bank modification',
        'exchange',
        'roster mod',
        'scheduled leave',
        'unscheduled leave',
        'modified leave',
        'payaddative',
        {value: 'overtime', text: 'additions/overtime'},
        'overtime availability',
        'unpaid leave',
        {text: 'none', value: null}
      ]
    }
  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        fixDataExcelCustom(){
          var array = _.cloneDeep(this.payrollCodesData)
          console.log(array)
          return array
        },
        bankModOptions(){
          var options = this.payrollBanksData.map(item => {
            var obj = {}
            obj['text'] = item.payrollBankName
            obj['value'] = item.id
            return obj
          })
      //    console.log(options)
          return options
        },
        payrollCodesDataWithEdit(){
          var array = this.payrollCodesData.map(p => {
            var obj = p
            obj['_showDetails'] = true
            if(obj.payrollCodeBankMod == null){
              obj.payrollCodeBankMod = []
            }
            if(obj.payrollCodeExport == null || obj.payrollCodeExport == undefined){
              obj.payrollCodeExport = obj.payrollCode
            }
            if(obj.payrollCodeNoteRequired == null || obj.payrollCodeNoteRequired == undefined){
              obj.payrollCodeNoteRequired = false
            }
            if(obj.payrollCodeNotification == null || obj.payrollCodeNotification == undefined){
              obj.payrollCodeNotification = false
            }
            if(obj.payrollCodeVisibility == null || obj.payrollCodeVisibility == undefined){
              obj.payrollCodeVisibility = []
            }
            obj['edit'] = false
            if(obj.payrollCodeVisibility == null || obj.payrollCodeVisibility == undefined || obj.payrollCodeVisibility.length == 0){
              obj.payrollCodeVisibility = ['admin', 'adminchief', 'fireops']
            }
            if(obj.payrollCodeApprovalRole == null || obj.payrollCodeApprovalRole == undefined || obj.payrollCodeApprovalRole.length == 0){
              obj.payrollCodeApprovalRole = ['admin', 'adminchief', 'fireops']
            }
            return obj
          })
          return _.orderBy(array, ['payrollCodeName'], ['asc'])
        }
       
  },
  mounted(){

  },
  watch: {

  },
  methods: {
          fixDataReport(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJsonReport
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.fixDataApply(results.data)
            }
        })
      },
      fixDataApplyUpdate(hold){
        var string = "Salary,Hourly,Parttime,Hourly PD,SalaryHourly,Hourly Tennis,Volunteer,Parttime Tennis,Hourly Water/Wastewater"
        var array = string.split(',')
        console.log(array)
        var data = hold.map(p => {
            var item = {}
            item.id = p.id
            item.payrollCodeRankVisibility = array
            return item
        })
        this.updateHold = data
      },
      fixDataApply(hold){
        var data = hold.filter(p => {
            if(p.payroll_code_name != ""){
              return p
            }
        })
        data = data.map(item => {
            var obj = item
            obj['self_approve'] = item.self_approve == 'TRUE' ? true : false
            obj['require_approval'] = item.require_approval == 'TRUE' ? true : false
            obj['payroll_code_approval_role'] = ['admin', 'fireops', 'adminchief']
            obj['payroll_code_visibility'] = ['admin', 'fireops', 'adminchief']
            return obj
        })
        console.log(data)
        this.uploadHold = data

      },
    checkForDisabled(data){
        if(data.payrollCode == "" || data.payrollCodeColor == '' || data.payrollCodeName == ''){
          return true
        } else {
          return false
        }
    },
    resetDataObj(){
      this.dataObj = [{ payrollCode: "",
                  payrollCodeExport: '',
                  payrollCodeApprovalRole: ['admin'],
                  payrollCodeBankMod: [],
                  payrollCodeCategory: null,
                  payrollCodeColor: "",
                  payrollCodeName: "",
                  payrollCodeVisibility: ['admin'],
                  requireApproval: true,
                  selfApprove: false,
                  _showDetails: true,
                  payrollCodeNotification: false,
                  payrollCodeNoteRequired: false,
                  payrollCodeListId: null
                  }]
    },
    getBankName(id){
   
      var obj = this.payrollBanksData.find(p => p.id == id)
      if(obj && obj.payrollBankName){
        return obj.payrollBankName
      } else {
        return 'Name Not Found'
      }
    },
    updateCodesData(){
      var codes = this.updateHold
      for(var i in codes){
        this.updatePayrollCode(codes[i])
      }
    },
    updatePayrollCode(data){
     // console.log(data)
      delete data.edit
      delete data.updatedAt
      delete data.createdAt
      delete data._showDetails
    axios({
     method: 'patch',
     url: '/api/v1/staffing/payrollcode/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
    console.log(response.data)
   //  bus.$emit('getPayrollCodes')
   //  this.editItemId = ''
   })

    },
    deletePayrollCode(data){
    var result = confirm('Are You Sure You Want to Delete this PayrollCode? No Going Back!')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/staffing/payrollcode/' + data,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
    this.$nextTick(function(){
     bus.$emit('getPayrollCodes')
     this.editItemId = ''
    })
  }) 
      }
    },
    addBulkCodes(){
    axios({
     method: 'post',
     url: '/api/v1/staffing/payrollcode/bulk',
     data: this.uploadHold,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
       bus.$emit('getPayrollCodes')
       this.resetDataObj()
       this.addNew = !this.addNew
       this.$nextTick(function(){
         this.addNew = !this.addNew
       })
   })
    },
    addNewPayrollCode(data){
      delete data._showDetails
    axios({
     method: 'post',
     url: '/api/v1/staffing/payrollcode',
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       bus.$emit('getPayrollCodes')
       this.resetDataObj()
       this.addNew = !this.addNew
       this.$nextTick(function(){
         this.addNew = !this.addNew
       })
   })

    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
