<template>
  <div class="hello">
      <div class="headerSpan"  v-if="stationName != undefined">Personnel</div>
      <div class="headerSpan"  v-if="personnelId != undefined">Group Members</div>
        <b-card no-body border-variant="light" v-if="stationName != undefined">
          <b-tabs pills v-if="filterDepartmentSearch != undefined" >
        <b-tab v-for="dataGroup in tabDataPull" :key="dataGroup.index" :title="dataGroup" :title-link-class="dataGroup" :active="dataGroup == shiftDateCalc">
        <b-card-group columns class="StationContactContainer">
          <b-card v-if="parentGrouped" no-body v-for="(station, index) in parentGroupingsPrev" :key="index" header-tag="header" header-class="my-0 pb-0 pt-1">
           <h4 slot="header" class="clickPointer" @click="stationSelected == station ? stationSelected = null : stationSelected = station">{{ station }}</h4>
          </b-card>
          <b-card v-if="stationSelected" v-for="user in shiftFilterDepartmentSearch(dataGroup, stationSelected)" :key="user.employeeId" :class="user.shiftAssignment + 'ROW'">
              <personnelfilter  :user="user" :specialitiesArray="user.deptSpecialties"   />
          </b-card>
          <b-card v-if="stationSelected" no-body v-for="station in parentGroupingsPost" :key="station.index" header-tag="header"  header-class="my-0 pb-0 pt-1">
           <h4 slot="header"  @click="stationSelected == station ? stationSelected = null : stationSelected = station">{{ station }}</h4>
          </b-card>
          <b-card v-if="!parentGrouped" no-body v-for="user in shiftFilterDepartmentSearch(dataGroup, stationSelected)" :key="user.employeeId" :class="user.shiftAssignement + 'ROW'">
              <personnelfilter :user="user" :specialitiesArray="user.deptSpecialties" />
            </b-card>
        </b-card-group>
      </b-tab>
    </b-tabs>
      </b-card>
      <b-card no-body border-variant="light" v-if="personnelId != undefined">
        <b-card-group columns class="StationContactContainer">
          <b-card no-body v-for="user in filterDepartmentSearch" :key="user.employeeId" :class="user.shiftAssignement + 'ROW'">
              <personnelfilter :user="user" :specialitiesArray="user.deptSpecialties" />
            </b-card>
        </b-card-group>
      </b-card>
    </div>
</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from 'moment'
import { mapState } from 'vuex'
import PersonnelFilter from './personnelDataFilter.vue'
import _ from 'lodash'
export default {
  name: 'hello',
  props:[ 'stationName','filterDate', 'personnelId'],
  components:{
    'personnelfilter': PersonnelFilter,
  },
  data () {
    return {
      stringData: '',
      deptPhoneList: [],
      phoneNumberList: [],
      userSearchBox: '',
      parentGroupings: [],
      stationSelected: null,
      parentGrouped: false,
    }
  },
  created(){

  },
  computed: {
    ...mapState([
      'deptContactData',
      'departmentRanks',
      'stationsData'
    ]),
    parentGroupingsPrev(){
      if(this.stationSelected){
        var index = this.parentGroupings.indexOf(this.stationSelected)
        var array = this.parentGroupings
      return  array.slice(0, index + 1)
      
      } else {
          return this.parentGroupings
      }
    },
    parentGroupingsPost(){
      if(this.stationSelected){
        var index = this.parentGroupings.indexOf(this.stationSelected)
        var array = this.parentGroupings
        var length = this.parentGroupings.length
        return array.slice(index + 1, length)
      } else {
          return this.parentGroupings
      }
    },
    phoneContactCat(){
      return [...new Set(this.deptPhoneList.map(p => p.Location))]
    },
    filterDepartmentSearch(){
      if(this.stationsData && this.stationName != '' && this.stationName != undefined){
      var stationTest = this.stationsData.find(p => p.stationName.toLowerCase() == this.stationName.replace('-', ' ').toLowerCase())
      if(stationTest.stationParent){
      var array = this.deptContactData.filter(p => {
        if(p.stationAssignment && p.deptRank){
        var stationName = this.stationName.replace('-', ' ')
        return p.stationAssignment.toLowerCase().match(stationName.toLowerCase())
        }
      })
      array.forEach(item => item['sortOrder'] = this.getUserRankOrder(item))
      var newarray =  _.orderBy(array, ['sortOrder'], ['asc'])
  //  console.log(newarray)
      return newarray
      } else {
        this.parentGrouped = true
        var stationsArray = this.stationsData.filter(p => p.stationParent == stationTest.stationName).map(p => p.stationName)
        var array = this.deptContactData.filter(p => {
        if(p.stationAssignment && p.deptRank){
          var index = stationsArray.indexOf(p.stationAssignment)
          if(index >= 0){
            return p
          }
        }
      })
      array.forEach(item => item['sortOrder'] = this.getUserRankOrder(item))
            var holdStations = [... new Set(array.map(p => p.stationAssignment))]
      this.parentGroupings = _.sortBy(holdStations)
      return _.orderBy(array, ['sortOrder'], ['asc'])
      }
      } 
      else if (this.personnelId.length > 0){
      var array = []
      var ids = this.personnelId
      for(var i = 0; i < ids.length; i++){
        var user = this.deptContactData.filter(p => { return p.id == ids[i]})
        array.push(user[0])
      }
      array.forEach(item => item['sortOrder'] = this.getUserRankOrder(item))
      var newarray = _.orderBy(array, ['sortOrder'], ['asc'])
    //  console.log(newarray)
      return newarray
      }
    },

    tabDataPull(){
      var setOfShifts = [... new Set(this.filterDepartmentSearch.map(p => p.shiftAssignment))]
      return setOfShifts.sort()
    },
    dateDifference(){
      var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
      var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
      var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
      var dateDiffCalNumber = 100*(dateDiffCal/3);
      var dateTenDigit = dateDiffCalNumber % 10;
      return Math.round(dateTenDigit);
    },
    shiftDateCalc(){
      if (this.dateDifference == 0)
      return "B-shift";
      if (this.dateDifference == 7)
      return "A-shift";
      if (this.dateDifference == 3)
      return "C-shift";
    },

  },
  watcher: {


  },
  methods: {
    getUserRankOrder(rank){
      if(rank.deptRank){
      var rank = rank.deptRank
      var rankHold = this.departmentRanks.find(p => p.deptrankName == rank)
      if(rankHold != undefined && rankHold.deptrankSortOrder != undefined){
        return rankHold.deptrankSortOrder
      } else {
        return 100
      }
      } else {
        return 100
      }
    },
    shiftFilterDepartmentSearch(data, station){
      var shift = this.filterDepartmentSearch.filter(p => {
        return p.shiftAssignment.match(data)
      })
      if(station){
        return shift.filter(p => {
          return p.stationAssignment == station
        })
      } else {
        return shift
      }
    },
    getYearsService(data){
      var dataSlice = data.slice(0, -5)
      return moment(dataSlice).fromNow(true)
    },
    specialAbr(sp){
      if(sp == 'Paramedic-in-Charge'){
        return 'PIC'
      }
      if(sp == 'Paramedic'){
        return 'PM'
      }

      if(sp == 'Field Training Officer'){
        return 'FTO'
      }
      if(sp == 'Special Operations'){
        return 'SO'
      }
      if(sp == 'Ladder Operator'){
        return 'LO'
      }
      else {
        return sp
      }

    },
    userPhotoLink(link){
      return 'https://firerescuedev.com' + link
    },
    userSpecialities(newData){
      return newData.split(', ')
    },
    parseDataField(){
    var dataParse = this.papa.parse(this.stringData, {header: true})
    this.phoneNumberList = dataParse
    },
    deptPhoneListData(){
       this. $http('/static/phonelist').then( response => {
        this.deptPhoneList = response.data
      })
    },
    filteredDeptPhoneList(parent){
      return this.deptPhoneList.filter(p => {
        return p.Location.match(parent)
      })
    },
  }

}
</script>
<style>

</style>
<style scoped>
.StationContactContainer.card-columns {
  -webkit-column-count: 1;
  column-count: 1;
  -webkit-column-gap: 1.25rem;
  column-gap: 1.25rem;
  padding: 0 5% 0 5%;
}
@media screen and (max-width: 1200px){
.StationContactContainer.card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 980px){
.StationContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 620px){
.StationContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px){
.StationContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
.clickPointer {
  cursor: pointer;
}
.hello{
  min-width: 300px;
  margin: 20px 0 0 10px;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
}
.headerSpan {
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
}
div.headerSpan {
  background: #777;
  width: 100%;
  border-radius: 5px 5px 0 0;
  margin-bottom: 10px;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.StationContactContainer {
  padding-bottom: 10px;
  margin-top: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {

  border-radius: 5px;
}
.A-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.B-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.C-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Day-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
a.nav-link.active.A-shift {
  background-color: red;
  display: none;
}
.A-shift{
  background: #e2070d;
  background: -moz-linear-gradient(left, #e2070d 0%, #e2070d 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift{
  background: #201B9F;
  background: -moz-linear-gradient(left, #201B9F 0%, #201B9F 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
.C-shift{
  background: #0FB906;
  background: -moz-linear-gradient(left, #0FB906 0%, #0FB906 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
      border-radius: 20px 20px 0 0;
}
.Day-shift{
  background: #E4CB07;
  background: -moz-linear-gradient(left, #E4CB07 0%, #E4CB07 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}

@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
