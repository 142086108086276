<template>
  <div class="fullScreenSetup">
    <div v-if="siteconfig.tvView">
     <tvView v-if="apiKey" :apiKeyToken="apiKey" />
     <b-modal v-if="!apiKey" v-model="passwordModel"
          :no-close-on-backdrop='true'
          :no-close-on-esc='true'
          :hide-header="true"
          :hide-footer="true"
          centered 
          >
          <div v-if="!failedKey">
          <b-form-input type="password" placeholder="Please Enter Your TV Key" v-model="apiSub" />
          <b-btn block :disabled="!apiSub" @click="getTVkey" variant="primary">Submit</b-btn>
          </div>
          <div v-if="failedKey">
            <b-btn block variant="danger" @click="failedKey = !failedKey">Failed Key - Please Try Again</b-btn>
          </div>

     </b-modal>
    </div>
    <b-modal v-if="!siteconfig.tvView" v-model="missingTvModule"		
          :no-close-on-backdrop='true'
          centered 
          :no-close-on-esc='true'
          header-class="mt-0 pt-0"
		 >
			<template  slot="modal-header">
				  <b-img  class="mt-0 px-5" src="/statportals.png" />
			</template>
      <h5 class="text-danger" align="center">Tv view has not been configured for you department, please contact your StatPortals representive to inquire about turning on this feature.</h5>
      			<template slot="modal-footer">
              <b-btn @click="clearCacheData" variant="primary">Return to Home</b-btn>
      			</template>
    </b-modal>
  </div>
 
</template>

<script>
import { mapState } from 'vuex'
import tvView from './tvView.vue'
import axios from 'axios'
//import siteconfigimport from './../../../config.json'
export default {
  name: 'tvview',
  components: {
    tvView
  },
  data () {
      return {
        apiKey: null,
        apiSub: null,
        failedKey: false
    }
  },
  created(){
     this.$store.dispatch('LOAD_SITE_CONFIG')
   
  },
  mounted(){

  },
  computed: {
      ...mapState([
        'siteconfig' 
    ]),
    passwordModel(){
      if(!this.apiKey){
        return true
      } return false
    },
    missingTvModule(){
      if(!this.siteconfig.tvView){
        return true
      } return false
    }
  },
  methods: {

    clearCacheData(){
        this.$store.dispatch('DELETE_LOCAL_DATA')
        this.$nextTick(function(){
          window.location.reload(true)
          //window.location.href = window.location.href
        })
      },
    getTVkey(){
      this.failedKey = false
      var url = '/api/v1/auth/tv/'
      axios({
        method: 'post',
        url: url,
        headers: {
            'content-type': 'application/json',
            'authorization': this.apiSub,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      })
        .then((response) => {
          console.log(response.data)
            if(response.data){
              this.apiKey = response.data
            } else {
              this.apiKey = null
              this.failedKey = true
            }
        })
    },


    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h2 {
  font-weight: normal;
}
.paddingTopTwenty {
  padding-top: 20px;
}
.deptName {
    height: 100px;
    font-size: 2.2em;
    margin-top: -20px;
    height: auto;
    color: #fff;
    text-align: center;
    padding: 0px 0px;
}
.currentTime {
    height: 100px;
    position: absolute !important;
    top: 20px;
    left: 0;
    height: auto;
    color: #fff;
    background: #333;
    text-align: center;
    padding: 0px 40px;
}
.currentTime:after {
    content: '';
    line-height: 0;
    font-size: 0;
    width: 0;
    height: 0;
    border-top: 80px solid #333333;
    border-bottom: 50px solid transparent;
    border-right: 50px solid transparent;
    border-left: 0px solid transparent;
    position: absolute;
    right: -50px;
    top: 0;
}
.stationName {
    height: 100px;
    position: absolute !important;
    top: 20px;
    right: 0;
    background: #333333;
    height: auto;
    color: #fff;
    text-align: center;
    padding: 0px 40px;
}
.stationName:before {
    content: '';
    line-height: 0;
    font-size: 0;
    width: 0;
    height: 0;
    border-top: 80px solid #333333;
    border-bottom: 50px solid transparent;
    border-left: 50px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    left: -50px;
}
.fullScreenSetup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8000;
    background:url('/images/MailBG.jpg') center top no-repeat; 
    background-size: cover;
    margin-top: -20px;
}
</style>
