<template>
<div>
<div>
<h4 id="filterHeading">Checks & Inventories</h4>
<div class="bodyData">
  <h4 class="errorText" v-if="filteredCheck.length == 0 && filteredInventories.length == 0">No Checks or Inventories Posted</h4>
  <div v-if="vehicleChecks.length > 0">
    <div v-if="vehicleChecks" v-for="(check, index) in filteredCheck" :key="index">
      <vehiclecheckfilterdata :dailyvehicle="check" />
     </div>
  </div>
  <div v-if="stationCalInventorySub.length > 0">
    <div v-if="stationCalInventorySub" v-for="(check, index) in filteredInventories" :key="index">
        <inventoryfilterdata :inventoryData="check" />
     </div>
  </div>
</div>
</div>

</div>



</template>
<script>
/*
<div id="dvcBodyDiv">
<h5 :class="shiftDateCalc">{{ check.title }}</h5>
<span><h6>Current:{{ check.currentHrs }}hrs | Till Pm:{{ check.pmHrs }}hrs | KnoxKeys:{{ check.knoxBox }} </h6></span>
</div>
*/
import vehicleCheckData from './vehiclecheckfilterdata.vue'
import inventoryfilterdata from './inventoryFilterdata'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  props:{
        filterDate: {
            type: String
        },
        vehicleChecks: {
            type: Array,
            default: () => []
        },
        stationCalInventorySub: {
            type: Array,
            default: () => []
        },
        searchShift: {
            type: String,
            default: '',
        },
        searchText: {
            type: String,
            default: '',
        },
        filterAuthor: {
            type: String,
            default: '',
        },
        filterApparatus: {
            type: String,
            default: '',
        },
      },
components:{
  'vehiclecheckfilterdata': vehicleCheckData,
  inventoryfilterdata,
},
  data () {
    return {
      currentPage: 0,
      pageCount: 10,
    }
  },
  watch: {
  },
  computed:{
    ...mapState([
          'getSessionData',
          'userData',
      ]),
    ...mapState({

    }),
    filteredCheck(){
      if(this.vehicleChecks != undefined){
      var array = this.vehicleChecks.filter((check) => {
        return check.title.toLowerCase().includes(this.searchText.toLowerCase())
        && check.dailyvehicleShift.match(this.searchShift)
        && check.dailyvehicleAuthor.match(this.filterAuthor)
        && check.dailyvehicleApparatusId.match(this.filterApparatus)
        && moment(check.dailyvehicleDate).format('MM/DD/YYYY').match(this.filterDate);
      })
      return _.orderBy(array, ['dailyvehicleDate'], ['desc'])
    } 
    },
    filteredInventories(){
      if(this.stationCalInventorySub != undefined){
      var array = this.stationCalInventorySub.filter((check) => {
        return moment(check.inventorySubDate).format('MM/DD/YYYY').match(this.filterDate);
      })
      return _.orderBy(array, ['inventorySubDate'], ['desc'])
    } 
    }

  },
  methods: {
    checkFilterElements(check){
        if(this.searchText == '' && this.searchShift == '' && this.filterAuthor == '' && this.filterApparatus == ''){
          return true
        }
        else if(check.title.toLowerCase().includes(this.searchText.toLowerCase())
        && check.dailyvehicleShift.includes(this.searchShift)
        && check.dailyvehicleAuthor.match(this.filterAuthor)
        && check.dailyvehicleApparatusId.match(this.filterApparatus)
        && moment(check.dailyvehicleDate).format('MM/DD/YYYY').match(this.filterDate)
        ){
          return true
        } else {
          return false
        }
    }
  },
}
</script>

<style scoped>
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
}
h4.errorText {
  text-align: center;
  margin-top: 10px;
  color: #e2070d;
  font-style: italic;
}
a {
  text-decoration: none;
}
h4 {
  margin-bottom: 5px;
}
#dvcBodyDiv {
  text-align: center;
}


</style>
