<template>
<div>
    <b-container><b-row><b-col align="right"><b-form-input type="text" v-model="newFormGroupName" placeholder="Give The Form Group A Name" /></b-col>
    <b-col><b-form-select v-model="newFormGroupColor" :options="formGroupColorOptions" /></b-col>
    <b-col align="left" cols="*">
    <b-btn :variant="newFormGroupColor" @click="createNewFormGroup(newFormGroupName, newFormGroupColor)">Add Form Group</b-btn></b-col></b-row></b-container>
                  <b-btn v-if="edit" v-for="btn in formData" :disabled="showAll" :variant="showGroup == btn.id ? btn.groupVariant : 'outline-' + btn.groupVariant" @click="showGroup = btn.id" :key="btn.id">{{ btn.groupName }}</b-btn>
                  <b-form-checkbox v-if="edit" v-model="showAll" :value="true" :unchecked-value="false">View All</b-form-checkbox>
                  <b-container class="pt-3" v-if="edit == true"> 
                    <b-card v-for="(group, index) in formData" :key="index"
                      :border-variant="group.groupVariant" v-show="showAll == true ? true : showAll == false && showGroup == group.id ? true : false"
                       header-tag="header"
                      :header-bg-variant="group.groupVariant"
                      :header-text-variant="group.groupVariant == 'waring' || group.groupVariant == 'default' ? 'dark' : 'white'">
                  <div slot="header"><span v-if="group.id != editGroupId" >{{ group.groupName }}</span>
                    <b-row v-if="group.id == editGroupId"><b-col align="right"><b-form-input type="text" v-model="group.groupName" placeholder="Give The Form Group A Name" /></b-col>
                        <b-col><b-form-select v-model="group.groupVariant" :options="formGroupColorOptions" /></b-col>
                    <b-col align="left" cols="*">
                      <b-btn size="sm" class="float-right" variant="outline-dark" @click="editGroupId = ''">Save</b-btn></b-col></b-row>      
                    <b-btn  v-if="group.id != editGroupId" size="sm" class="float-right" variant="dark"  @click="editGroupId = group.id">Edit</b-btn>
                  </div>
                  <b-btn variant="dark">Show Form Items</b-btn>
                  <b-btn v-for="(formbtn, index) in formOptions" :key="index"></b-btn>
                   <b-form-group breakpoint="lg"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                    <b-row class="my-2">
                      <b-col v-for="(field, index) in group.groupFields" :key="index" class="mt-2" :lg="field.size" :label-for="field.idtag"><h6>{{ field.label }}</h6>

                      </b-col>
                      </b-row>   
                    </b-form-group>
    
                    <br><br>
    
                    </b-card>
                  </b-container>
                  
      <br><br>
        {{ formData }}
    </div>
  
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  components: {},
  name: "form-data-builder",
  data() {
    return {
      edit: true,
      showAll: false,
      showGroup: "",
      editGroupId: "",
      newFormGroupName: "",
      newFormGroupColor: "default",
      formOptions: [],
      formGroupColorOptions: [
        { value: "info", text: "Teal" },
        { value: "primary", text: "Blue" },
        { value: "success", text: "Green" },
        { value: "danger", text: "Red" },
        { value: "warning", text: "Yellow" },
        { value: "secondary", text: "Grey" },
        { value: "default", text: "Default" }
      ],
      formData: [
        {
          id: "Objective Onetvq2f7i6lz",
          groupName: "Objective One",
          groupVariant: "default",
          groupFields: []
        },
        {
          id: "Objective Twoa550m5jt1xp",
          groupName: "Objective Two",
          groupVariant: "primary",
          groupFields: []
        },
        {
          id: "Objective Threev0npqa0yv2",
          groupName: "Objective Three",
          groupVariant: "success",
          groupFields: []
        }
      ]
    };
  },
  created() {},
  computed: {
    ...mapState([
      "getSessionData",
      "apparatusData",
      "stationsData",
      "userData",
      "deptContactData"
    ])
  },
  mounted() {},
  watch: {},
  methods: {
    createNewFormGroup(name, color) {
      var id =
        name +
        Math.random()
          .toString(36)
          .substr(2, 15);
      this.formData.push({ id: id, groupName: name, groupVariant: color });
      this.newFormGroupName = "";
      this.newFormGroupColor = "default";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1,
h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
