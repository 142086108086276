<template>
  <div id="eventsListdiv" >
  <b-card header-tag="header" 
  :header-class="editEvent ? 'pt-2 pb-1' : !showBodyAdjust ? 'py-1' :'pt-2 pb-1'" 
  class="mt-2"
  body-class="pb-1" 
  :class="!editEvent ? 'mx-4' : 'mx-1'" 
  :no-body="!showBodyAdjust" 
  :header-border-variant="getVariantColor(eventdata.eventTagParent)"
  :header-bg-variant="!showBodyAdjust ? getVariantColor(eventdata.eventTagParent) : ''"
  :border-variant="getVariantColor(eventdata.eventTagParent)"
  >
    <div slot="header"  @click="!editEvent ? showBodyAdjust = !showBodyAdjust : ''">
      <b-row><b-col class="pt-1 pr-1">
      <span id="eventTimeNoBody" v-if="eventdata.eventDate != ''"   :class="!showBodyAdjust && getVariantColor(eventdata.eventTagParent) != 'default' ? 'text-light' : 'dark'" >{{ eventTimeStamp(eventdata.eventDate) }} | </span> 
      <span id="eventTitle" :class="!showBodyAdjust && getVariantColor(eventdata.eventTagParent) != 'default' ? 'text-light' : 'dark'">{{ eventdata.eventTitleDescription }}</span>
      </b-col><b-col cols="auto" v-if="showBodyAdjust && editPermissionsCheck(eventdata) && !tvView"  align="right">
                    <b-btn v-if="!editEvent" size="sm" @click.stop="editEventDataSet(eventdata)" :disabled="showModalConfirmation"  variant="outline-primary" title="Edit Form">	&#x270e;</b-btn>
              <b-btn v-if="editEvent"  size="sm" @click.stop="cancelEdit" variant="outline-danger">X</b-btn>
        </b-col></b-row>
      
      
      </div>
      <div v-show="showBodyAdjust">
        <div>   
          <b-row>
            <b-col >
            <b-form-checkbox v-if="eventdata.eventGroupId && editEvent && !trainingPage" v-model="editOption" :value="true" :unchecked-value="false">Edit All {{ eventGroup.length }} Events?</b-form-checkbox>
              </b-col>    
          </b-row>
          </div>
      <div v-if="!editEvent">
        
      <p class="eventHeading" :class="eventdata.eventTagParent" v-if="!tvView"><strong>{{ eventdata.eventTagParent }}: </strong>{{ eventdata.eventTag }}</p>
      <div v-if="eventdata.eventContent"><router-link :to="'/portal/training/'+eventdata.eventContent">Training Event Info</router-link></div>
      <p v-if="eventdata.eventApparatus && eventdata.eventApparatus != ''">Apparatus:
        <span v-if="eventdata.eventApparatus.length >= 6" @click="showApparatusTagged = !showApparatusTagged" class="custom__tag_multi border-info text-info">{{ eventdata.eventApparatus.length }} Apparatus {{ showApparatusTagged == true ? '(Hide)' : '(Show)'}} </span> 
        <span v-if="eventdata.eventApparatus.length < 6 || showApparatusTagged == true" v-for="item in eventdata.eventApparatus" :key="item.index" class="custom__tag bg-info" >{{ item }}</span></p>
      <p v-if="eventdata.eventUser && eventdata.eventUser != ''">Users:
      <span v-if="eventdata.eventUser.length >= 6" @click="showUserTagged = !showUserTagged" class="custom__tag_multi border-success text-success">{{ eventdata.eventUser.length }} Users {{ showUserTagged == true ? '(Hide)' : '(Show)'}} </span> 
        <span  v-if="eventdata.eventUser.length < 6 || showUserTagged == true"  v-for="item in eventdata.eventUser" :key="item.index" class="custom__tag bg-success" :title="getUserNameInfo(item)">{{ getUserName(item) }}</span></p>
       <p v-if="eventdata.eventStation && eventdata.eventStation != undefined">Stations:
         <span v-if="eventdata.eventStation.length >= 6" @click="showStationTagged = !showStationTagged" class="custom__tag_multi border-primary text-primary">{{ eventdata.eventStation.length }} Stations {{ showStationTagged == true ? '(Hide)' : '(Show)'}} </span> 
        <span v-if="eventdata.eventStation.length < 6 || showStationTagged == true" v-for="item in eventdata.eventStation" :key="item.index" class="custom__tag bg-primary">{{ item }}</span></p>
        <b-form-textarea         rows="1"
        max-rows="16" plaintext class="mt-3 px-3 mx-1 detailedDescription" v-if="eventdata.eventDescription && showBodyTv" v-model="eventdata.eventDescription[0]" />
      </div>
       <div class="modal-body" v-if="editEvent">
         <editBody :defaultData="editObj" @updateData="setUpdateData" :editOption="editOption" :userHoldArray="returnUserHoldArray" :trainingPage="trainingPage" />
         </div>
        <b-container class="pt-1">
        <b-row v-if="!editEvent && eventdata.eventContentUrl">
          <b-row v-for="(link, index) in eventdata.eventContentUrl" :key="index">
          <b-btn variant="link" :to="link.url">{{ link.text }}</b-btn>
          </b-row>
        </b-row>
        <b-row v-if="!editEvent && eventdata.eventContentId">
              <b-btn variant="link" :to="getContentUrl(eventdata.eventContentId)">{{ getContentText(eventdata.eventContentId) }}</b-btn>
          </b-row> 
        <b-row v-if="editEvent && trainingDrillsReturned.length > 1 && checkForTrainingCat" class="mb-2 px-2 ml-2">
          <b-col cols="*" class="pt-1">Tag Training:</b-col><b-col>
          <b-form-select v-model="eventContentId" :options="trainingDrillsReturned" :disabled="trainingPage" /></b-col>
          </b-row>
        <b-row v-if="!tvView">
        <b-col v-if="!editEvent" align="left" cols="auto"><p class="authorInfo">{{ eventdata.eventAuthor }} | {{ formatCreateDate(eventdata.updatedAt) }}</p>
            
        </b-col>
        <b-col v-if="editEvent">
          <h6 v-if="eventdata.eventGroupId && !editOption && !trainingPage" class="text-danger" >This Will Remove the Event From the Group</h6>
          </b-col>
        <b-col  align="right"><img v-if="!editEvent && eventdata.eventGroupId" class="eventImageClass" title="Duplicate Event In A Series" src="/images/siteicons/duplicate.png" />
       <img v-if="!editEvent && eventdata.eventPrivate && eventdata.eventPrivate == true" class="eventImageClass" title="Private Event" src="/images/siteicons/lock-icon.png" /></b-col>
        <b-col cols="auto" v-if="editPermissionsCheck(eventdata) && editEvent" align="right">
          <b-btn v-if="editEvent && !editOption" size="sm" variant="success" @click="submitArticl(eventdata)">Update {{ eventdata.eventGroupId ? 'Single' : ''}} Event</b-btn>
           <b-btn v-if="editEvent && editOption" size="sm" variant="success" @click="submitMultipleArticles(eventGroup)">Update {{ eventGroup.length }} Events</b-btn>
        </b-col>
        </b-row>
        <b-row><b-col v-if="editEvent && !trainingPage">
                        <b-form-checkbox v-model="eventPrivate" :value="true" :unchecked-value="false">Set Event To Private</b-form-checkbox>
          </b-col><b-col cols="auto" align="right">
      <b-btn v-if="editEvent && editOption && !trainingPage" size="sm" variant="danger" @click="deleteMultipleArticles(eventGroup)">Delete {{ eventGroup.length }} Events</b-btn>
           <b-btn v-if="editEvent && !editOption  && !trainingPage" size="sm" variant="danger" @click="deleteArticl(eventdata.id)">Delete {{ eventdata.eventGroupId ? 'Single' : ''}} Event</b-btn>
          </b-col>
          </b-row>
          <b-container fluid v-if="showModalConfirmation"><h5 v-if="groupPostData">{{ groupPostData }} Number of Events Have Been Updated</h5>
                <b-progress  v-if="groupPostData" :value="groupPostData" :max="eventGroup.length" show-progress animated>
                
                </b-progress></b-container>
        </b-container>
      </div>
  </b-card>
</div>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import DatePicker from '../../inputfields/Datepickermod.vue'
import axios from 'axios';
import editBody from './eventsEditBody.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
export default {
props:{
    eventdata: {
        type: Object
    },
    showBody: {
        type: Boolean,
        default: true,
    },
    editId: {
        type: String
    },
    tvView: {
        type: Boolean,
        default: false,
    },
    showBodyTv: {
        type: Boolean,
        default: true,
    },
    trainingPage: {
        type: Boolean,
        default: false,
    },
    userHoldArray: {
        type: Array,
        default: function () {
                    return [];
          }
    }
},
components:{
  'multiselect' : Multiselect,
  'timepicker' : TimePicker,
  'datepicker' : DatePicker,
  editBody
},
  data () {
    return {
        calendarEventsUnique: [],
        search:"",
        editEvent: false,
        timeValueSet: {
          HH: "12",
          mm: "00",
        },
        editOption: false,
        showModalConfirmation: false,
        showApparatusTagged: false,
        showStationTagged: false,
        showUserTagged: false,
        selectedDate: '',
        showBodyAdjust: true,
        eventTagsSelectValue:[],
        stationSelectValue:[],
        apparatusSelectValue:[],
        eventCatSelect: null,
        eventTagSubSelect: null,
        titleDescription: '',
        detailedTextArea: '',
        changeDate: false,
        userSelectValue: [],
        eventGroup: [],
        editObj: {},
        groupPostData: '',
        eventPrivate: false,
        eventContentId: null

            }
  },
  created(){
    this.showBodyAdjust = this.showBody
  },
  watch: {
    editId(newData){
      if(newData && newData != this.eventdata.id){
        this.closeEditOption() 
      }
    },
  },
  computed:{
    ...mapState([
      'userData',
      'deptContactData',
      'stationsData',
      'apparatusData',
      'eventTags',
      'getSessionData',
      'trainingDrills'
    ]),
    returnUserHoldArray(){
      return this.userHoldArray
    },
    checkForTrainingCat(){
      var eventid = this.eventCatSelect
      if(eventid){
        if(eventid.toLowerCase().match('training')){
          return true
        }
      } return false
    },
    trainingDrillsReturned(){
      var hold = [{text: 'None', value: null}]
      var array = this.trainingDrills.filter(p => {
        return p.trainingModuleType == 'scheduled' && p.trainingModuleActive
      })
      var array = array.map(item => {
        var obj = {}
        obj['text'] = item.trainingModuleName
        obj['value'] = item.id
        return obj
      })
      for(var i in array){
        hold.push(array[i])
      }
      return hold
    },
    eventCat(){
        return [... new Set(this.eventTags.map(p => { if(p.eventtypeParent != ''){ return p.eventtypeParent}
        }))]
      },
      formatedDateExportBack() {
      var dateString = moment(this.selectedDate, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" +  this.timeValueSet.HH + ':' + this.timeValueSet.mm + ':' + '00';
      var offSet = moment().utcOffset();
      var hourOffSet = offSet * -1 / 60;
      var timeStringForm = moment(timeString).add(hourOffSet, "hours");
      var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
      return timeStringAdd + "Z";
    },
          formatedDateExport() {
      var dateString = moment(this.selectedDate, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" +  this.timeValueSet.HH + ':' + this.timeValueSet.mm + ':' + '00';
      return moment(timeString).toISOString();
      },
    returnedTargetIdsStations () {
        var array = []
        var station = this.stationSelectValue
        for(var i = 0; i < station.length; i++){
          var string = station[i].stationName
          array.push(string)
        }
        return array
      },
      returnedTargetIdsApparatus () {
        var array = []
        var station = this.apparatusSelectValue
        for(var i = 0; i < station.length; i++){
          var string = station[i].apparatusName
          array.push(string)
        }
        return array
      },
      userSelectedFormatExport(){
        if(this.userSelectValue.length > 0){
          var users = [... new Set(this.userSelectValue.map(p => p.id))]
          return users
        } else {
          return []
        }
      }
  },
methods:{
  getContentUrl(id){
    if(id){
      var training = this.trainingDrills.find(p => p.id == id)
      if(training){
        return '/training/' + id
      } return null
    } return null
  },
    getContentText(id){
    if(id){
      var training = this.trainingDrills.find(p => p.id == id)
      if(training){
        return training.trainingModuleName
      } return null
    } return null
  },
  getUserName(data){
    var user = this.deptContactData.filter( p => {
      return p.id.match(data)
    })
    if(user != '' && user != undefined){
    return user[0].lastName
    }
  },
    getVariantColor(data){
    var obj = this.eventTags.find(p => p.eventtypeName == data)
    if(obj && obj.eventVariantColor){
      return obj.eventVariantColor
    } else {
      return 'default'
    }
  },
  getUserNameInfo(data){
    var user = this.deptContactData.find( p => p.id == data)

    if(user != '' && user != undefined){
    return user.firstName + ' ' + user.lastName + ' (' + user.employeeId + ') ' + user.stationAssignment
    }
  },
  closeEditOption(event){
     this.cancelEdit('Close')
  },
  cancelEdit(event){
    if(event == 'Close'){
     return this.editEvent = false
    }
    this.$emit('editIdChange', null)
    return this.editEvent = false
  },
  setUpdateData(data){
      this.titleDescription = data.titleDescription
      this.title = data.title
      this.selected = data.selected
      this.holdValues = data.holdValues
      this.detailedTextArea = data.detailedTextArea
      this.eventTagsSelectValue = data.eventTagsSelectValue
      this.selectedDate = data.selectedDate
      this.eventCatSelect = data.eventCatSelect
      this.eventTagSubSelect = data.eventTagSubSelect
      this.repeatedDatesArray = data.repeatedDatesArray
      this.timeValueSet = data.timeValueSet
      this.contentid = data.eventContentId
      this.eventContentUrl = data.eventContentUrl
    },
  editEventDataSet(data){
    this.$emit('editIdChange', this.eventdata.id)
    if(data.eventGroupId){
      this.getEventGroup(data.eventGroupId)
    }
    if(data.eventPrivate){
      this.eventPrivate = data.eventPrivate
    }
    this.eventContentId = data.eventContentId
    var date = moment(data.eventDate).format('MM/DD/YYYY')
    var time = moment(data.eventDate).format('HH')
    var timeM = moment(data.eventDate).format('mm')
    var timeObj = { HH: time, mm: timeM, }
    var editObj = {
    eventCatSelect: data.eventTagParent,
    eventTagSubSelect: data.eventTag,
    stationSelectValue: data.eventStation ? this.returnStationSelectObjects(data.eventStation) : this.returnStationSelectObjects([]),
    apparatusSelectValue: data.eventApparatus ? this.returnApparatusSelectObjects(data.eventApparatus) :  this.returnApparatusSelectObjects([]),
    userSelectValue: data.eventUser ? this.returnUserSelectObjects(data.eventUser) : this.returnUserSelectObjects([]),
    titleDescription: data.eventTitleDescription,
    detailedTextArea: data.eventDescription[0],
    selectedDate: date,
    timeValueSet: timeObj
    }
    this.editObj = editObj
    this.editEvent = true
  },
  getEventGroup(id){
    axios({
      method: 'get',
      url: 'api/v1/content/event/group/' + id,
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
        }).then( response => {
          this.eventGroup = response.data
    })
  },
  returnUserSelectObjects(data){
    var array = []
    for(var i in data){
      var users = this.deptContactData.find( p => p.id == data[i] )
      if(users){
        array.push(users)
      }
    }
    return array
  },
  returnStationSelectObjects(data){
    var array = []
    for(var i = 0; i < data.length; i++){
      var station = this.stationsData.filter( p => {
         if(data[i] != null){
        return p.stationName.toLowerCase() == data[i].toLowerCase()
         }
      })
      if(station != '' && station != undefined){
        array.push(station[0])
      }
    }
    return array
  },
  returnApparatusSelectObjects(data){
    var array = []
    for(var i = 0; i < data.length; i++){
      var apparatus = this.apparatusData.filter( p => {
        if(data[i] != null){
        return p.apparatusName.toLowerCase() == data[i].toLowerCase()
        }
      })
      if(apparatus != '' && apparatus != undefined){
        array.push(apparatus[0])
      }
    }
    return array
  },
  eventTagSub(data){
      return [... new Set(this.eventTags.filter(p => p.eventtypeParent.match(data)))]
  },
  editPermissionsCheck(data){
    var author = data.eventAuthorId
    var currentUser = this.userData
    if(author == currentUser.id){
      return true
    } else {
      if(currentUser.role == 'admin' || currentUser.role == 'adminchief' || currentUser.role == 'fireops' || currentUser.role == 'emsops' || currentUser.role == 'trainingops'){
        return true
      } else {
    
          return false
        }
      }

    
  },
  getStationParents(data){
      var array = []
      for(var i = 0; i < data.length; i++){
        var string = data[i].stationParent
        var parent = data[i].stationName
        var index = array.indexOf(string)
        var pindex = array.indexOf(parent)
          if(string != '' && index < 0){
          array.push(string)
          } 
          if(string == '' && pindex < 0){
          array.push(parent)
          } 
        }
      return array
    },
  formatCreateDate(data){
    return moment(data).format('MM/DD/YY HH:mm')
  },
  splitItems(data){
    if(data != undefined){
    return data.split(', ')}
    else{
      return
    }
  },
  splitItemsUsers(data){

    if(data != undefined){
    var userIDS = data.split(', ')}
    else{
      return
    }

    var userNames = []
    for(var i = 0; i < userIDS.length; i++){
      var index = this.deptContactData.filter( (p) => {
        return p.uid.match(userIDS[i]) })
      userNames.push(index[0].name)
    }
    return userNames
  },
  eventTimeStamp(data){
    var today = moment()
    var date = moment(data).format('MM/DD/YYYY')
    var test = moment(date, 'MM/DD/YYYY').isSame(today, 'day')
    if(test == true){
    return moment(data).format('HH:mm')
    } else {
      return moment(data).format('MM/DD/YY HH:mm')
    }
  },
  submitArticl(data){
      var eventNid = data.id
     var keys = Object.keys(this.holdValues)
     for(var i in keys){
      this[keys[i]] = this.holdValues[keys[i]]
     }
      axios({
      method: 'patch',
      url: '/api/v1/content/event/' + eventNid,
      data: {
              "title": this.titleDescription + ' | ' + this.formatedDateExport,
              "eventStation":this.returnedTargetIdsStations,
              "eventDate":this.formatedDateExport,
              "eventDescription": [this.detailedTextArea],
              "eventApparatus":this.returnedTargetIdsApparatus,
              "eventTag": this.eventTagSubSelect,
              "eventGroupId": '',
              "eventPrivate": this.eventPrivate,
              "eventTitleDescription": this.titleDescription,
              "eventTagParent":this.eventCatSelect,
              "eventUser": this.userSelectedFormatExport,
              "eventStationParent": this.getStationParents(this.stationSelectValue),
              "eventAuthor": this.userData.firstName + ' ' + this.userData.lastName,
              "eventAuthorId": this.userData.id,
              "eventContentId": this.eventContentId
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
        }).then( response => {
      
               this.editEvent = false
               bus.$emit('refresh-calendar-list')
               bus.$emit('refresh-full-calendar')
               bus.$emit('emitCalendarEvent')
               bus.$emit('updateTrainingData')
        })
     },
     submitMultipleArticles(group){
     var keys = Object.keys(this.holdValues)
     for(var i in keys){
       this[keys[i]] = this.holdValues[keys[i]]
     }
     var responseData = 0
     for(var d in group){
     axios({
      method: 'patch',
      url: '/api/v1/content/event/' + group[d].id,
      data: {
              "title": this.titleDescription + ' | ' + group[d].eventDate,
              "eventStation":this.returnedTargetIdsStations,
              "eventDescription": [this.detailedTextArea],
              "eventApparatus":this.returnedTargetIdsApparatus,
              "eventPrivate": this.eventPrivate,
              "eventTag": this.eventTagSubSelect,
              "eventTitleDescription": this.titleDescription,
              "eventTagParent":this.eventCatSelect,
              "eventUser": this.userSelectedFormatExport,
              "eventStationParent": this.getStationParents(this.stationSelectValue),
              "eventAuthor": this.userData.firstName + ' ' + this.userData.lastName,
              "eventAuthorId": this.userData.id,
              "eventContentId": this.eventContentId
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
        }).then( response => {
          responseData++
          if(this.showModalConfirmation == false){
            this.editEvent = false
          this.showModalConfirmation = !this.showModalConfirmation
          }
         this.groupPostData = responseData
           if(responseData == this.eventGroup.length){
           this.$nextTick(function(){
           bus.$emit('refreshUserPosts')
           bus.$emit('refresh-calendar-list')
           bus.$emit('refresh-full-calendar')
                 bus.$emit('updateTrainingData')
                bus.$emit('emitCalendarEvent')
           setTimeout(() => { this.showModalConfirmation = false }, 2000)

         })
        }
      })
      }
      

  },
   deleteArticl(id){
    var result = confirm('Are You Sure You Want to Delete this Event?')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/content/event/' + id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
           bus.$emit('refreshUserPosts')
           bus.$emit('refresh-calendar-list')
           bus.$emit('refresh-full-calendar')
           bus.$emit('emitCalendarEvent')

  }) 
      }
},
 deleteMultipleArticles(group){
    var result = confirm('Are You Sure You Want to Delete these Events?')
      if (result) {
  var mapidarr = group.map(p => p.id)
  for(var i in group){
   axios({
     method: 'delete',
     url: '/api/v1/content/event/' + group[i].id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {

    if(mapidarr.indexOf(response.data.id) == group.length - 1){
    this.$nextTick(function(){  
        bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
        bus.$emit('refresh-full-calendar')
        bus.$emit('emitCalendarEvent')
      })
      
    }
  }) 
  }

  }
},

  },

}
</script>

<style scoped>
.eventImageClass {
      width: 20px;
    height: auto;
}

h4.errorText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #e2070d;
  font-style: italic;
}
.authorInfo {
  font-style: italic;
  color: #777;
  font-size: 14px;
}
.detailedDescription {
  border: 1px solid #aaa;
  border-radius: 5px;

}
.eventHeading {
  width: 80%;
  margin: 0 auto 0;
  border-radius: 5px;
}
.EMS {
background: #007bff;
color: #fff;
}
.Fire {
  background: #dc3545;
  color: #fff;
}
.Special {
  background: orange;
  color: #fff;
}
.Maintenance {
  background: #868e96;
  color: #fff;
}
.Public {
  background: #28a745;
  color: #fff;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__tag_multi {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  border: 1px solid;
  margin-right: 10px;
  line-height: 1;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  margin-top: 0;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
  padding-bottom: 10px;
}
#eventHeading{
  padding: 5px;
  width: 80%;
  background: #eee;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #777;
  border-radius: 5px 5px 0 0;
  text-align: center;
   color: red;
}
#eventHeadingNoBody{
  cursor: pointer;
  padding: 5px;
  width: 60%;
  margin: 15px auto 0;
  text-align: left;
  color: red;
}
#eventsListdiv h5{
  align-items: center;
  width: 100%;
  margin-top: 0px;
  color: #000;
}
#eventsListdiv p{
  margin-top: 5px;
  margin-bottom: 5px;
}
#eventTime{
  font-weight: bold;
}
#eventTitle{
  font-weight: bold;
  text-align: center;
}
#eventBody p {
  text-align: center;
}
#eventBody {
  border: 1px solid #777;
  width: 80%;
  margin: 0 auto 0;
  border-radius: 0 0 5px 5px;
}
@media only screen and (max-width: 576px) {
  #eventHeading{
    padding: 5px;
    width: 97%;
    background: #eee;
    margin: 20px auto 0;
    border: 1px solid #777;
    border-radius: 5px 5px 0 0;
    text-align: center;
  }
  #eventBody {
    border: 1px solid #777;
    width: 97%;
    margin: 0 auto 0;
    border-radius: 0 0 5px 5px;
  }
  #eventHeadingNoBody{
    cursor: pointer;
    padding: 5px;
    width: 97%;
    margin: 15px auto 0;
    text-align: left;
  }
}
</style>
