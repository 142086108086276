<template>
  <div class="hello">
    <div class="headerSpan">Department Preplans & Inspections  <span v-if="showAdmin">Admin</span><span class="floatRight">
          <b-btn v-if="false && userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops' || userData.role == 'fireops'"
           size="sm" @click="showAdmin = !showAdmin" :variant="showAdmin == true ? 'light' : 'outline-light'">Admin</b-btn></span></div>
  <b-card no-body>
    <b-tabs card>
      <b-tab title="Pre-plans"  class="px-0 pt-3" :active="activeId == 'preplan' ? true : false">
        <preplan  :submittedPrePlans="submittedPrePlans" />
        </b-tab>
      <b-tab title="Businesses"  class="px-0 pt-3">
        <business :submittedPrePlans="submittedPrePlans" />
        </b-tab>
      <b-tab title="Inspections"  class="px-0 pt-3" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || userData.role == 'supervisor'">
        <inspection />
        </b-tab>
        <b-tab title="Hydrants"  class="px-0 pt-3" v-if="(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || userData.role == 'supervisor') && siteconfig.preplans && siteconfig.preplans.hydrants">
        <hydrant />
        </b-tab>
        <b-tab title="Reports"   class="px-0 pt-3" v-if="userData.role == 'admin'">
          <reports />
        </b-tab>
    </b-tabs></b-card>
    </div>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from "moment";
import { mapState } from "vuex";
import { bus } from './../../main'
import preplan from "./preplans/preplan.vue";
import business from "./preplans/business.vue";
import inspection from "./preplans/inspection.vue";
import hydrant from "./preplans/hydrants.vue";
import reports from "./preplans/reports.vue";
import axios from "axios";
export default {
  name: "hello",
  components: {
    preplan,
    business,
    inspection,
    hydrant,
    reports
  },
  data() {
    return {
            showAdmin: false,
            submittedPrePlans: [],
            activeId: 'preplan'
    }
  },
  created() {
    this.getPreplans();
    bus.$on('openPreplan', this.switchActive)
    bus.$on('createNewPreplan', this.switchActive)
    bus.$on('getPreplans', this.getPreplans)
  },
  mounted() {
 //       this.getAddress();
  },
  computed: {
    ...mapState([
      "deptContactData",
      "departmentBlogs",
      "getSessionData",
      "userData",
      "siteconfig"
    ])
  },
  watcher: {},
  methods: {
    switchActive(){
      this.activeId = false
      this.$nextTick(function(){
        this.activeId = 'preplan'
      })
    },
    getPreplans() {
      axios({
        method: "get",
        url: "/api/v1/preplan/preplan",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.submittedPrePlans = response.data;
      });
    },
  }
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.blogCardClass {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space: nowrap;
}
.userDataWrap {
  text-align: center;
}
.userRank {
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer {
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: 0.4;
}
.headerContactRow {
  border-radius: 5px;
}
.A-shiftROW {
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.B-shiftROW {
  border: 1px solid #201b9f;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.C-shiftROW {
  border: 1px solid #0fb906;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.Day-shiftROW {
  border: 1px solid #e4cb07;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.A-shift {
  background: #e2070d;
  background: -moz-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift {
  background: #201b9f;
  background: -moz-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.C-shift {
  background: #0fb906;
  background: -moz-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.Day-shift {
  background: #e4cb07;
  background: -moz-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;
}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138, 138, 138, 1);
  color: #fff;
  border-radius: 10px;
}
h1,
h2 {
  font-weight: normal;
}

@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
