<template>
  <div id="eventsListdiv" >
  <div >
<h4 id="filterHeading">Events</h4>
  <div class="bodyData">
  <h4 class="errorText" v-if="filteredEvents.length == 0">No Events Scheduled</h4>
    <div v-for="event in filteredEvents" :key="event.id" v-if="dataReset" >
        <eventdata :eventdata="event" :showBody="showBody" v-show="checkfordeleted(event.id)" @switchEmit="updateEvents" />
    </div>
    </div>
  </div>
</div>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import eventData from './eventsfilterdata.vue'
import moment from 'moment'
export default {
props:{
    calendarEvents: {
        type: Array,
        default: () => []
    },
    filterDate: {
        type: String,
        default: '',
    },
    showBody: {
      type: Boolean,
      default: false,
    }
},
components:{
  'eventdata' : eventData
},
  data () {
    return {
        calendarEventsUnique: [],
        search:"",
        editEvent: false,
        deleted: [],
        timeValueSet: {
          HH: "12",
          mm: "00",
        },
        selectedDate: '',
        eventTagsSelectValue:[],
        stationSelectValue:[],
        apparatusSelectValue:[],
        eventCatSelect: null,
        eventTagSubSelect: null,
        titleDescription: '',
        detailedTextArea: '',
        changeDate: false,
        dataReset: true,

            }
  },
  computed:{
    ...mapState([
      'userData',
      'deptContactData',
      'stationsData',
      'apparatusData',
      'eventTags',
      'getSessionData'
    ]),
    filteredEvents(){
      var momdate = moment(this.filterDate, 'MM/DD/YYYY')
      if(this.calendarEvents == "[]"){
        return []
      }
      else {
      var arrayHold = this.calendarEvents.filter((event) => {
        var date = moment(event.eventDate).format('MM/DD/YYYY')
        var momTest = moment(date, 'MM/DD/YYYY').isSame(momdate, 'day')
        if(momTest == true){
          return event
        }
        if(this.filterDate == ''){
          return event
        }
      })
      return _.orderBy(arrayHold, ['eventDate'], ['asc'])
    }
    },
},
methods:{
  updateEvents(id){
    this.deleted.push(id)
    this.dataReset = false
    this.$nextTick(function(){
      this.dataReset = true
    })
  },
  checkfordeleted(id){
    var index = this.deleted.indexOf(id)
    if(index < 0){
      return true
    } else {
      return false
    }
  },
}
}
</script>

<style scoped>
h4.errorText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #e2070d;
  font-style: italic;
}
.authorInfo {
  font-style: italic;
  color: #777;
  font-size: 14px;
}
.detailedDescription {
  border: 1px solid #aaa;
  width: 80%;
  margin: 0 auto 0;
  border-radius: 5px;

}
.eventHeading {
  width: 80%;
  margin: 0 auto 0;
  border-radius: 5px;
}
.EMS {
background: #007bff;
color: #fff;
}
.Fire {
  background: #dc3545;
  color: #fff;
}
.Special {
  background: orange;
  color: #fff;
}
.Maintenance {
  background: #868e96;
  color: #fff;
}
.Public {
  background: #28a745;
  color: #fff;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  margin-top: 0;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
  padding-bottom: 10px;
}
#eventHeading{
  padding: 5px;
  width: 80%;
  background: #eee;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #777;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
#eventsListdiv h5{
  align-items: center;
  width: 100%;
  margin-top: 0px;
  color: #000;
}
#eventsListdiv p{
  margin-top: 5px;
  margin-bottom: 5px;
}
#eventTime{
  font-weight: bold;
}
#eventTitle{
  font-weight: bold;
  color: red;
  text-align: center;
}
#eventBody p {
  text-align: center;
}
#eventBody {
  border: 1px solid #777;
  width: 80%;
  margin: 0 auto 0;
  border-radius: 0 0 5px 5px;
}
</style>
