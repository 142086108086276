<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
     <b-navbar type="dark" variant="dark">
           <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable">User Data:</b-navbar-brand>
         <b-form-input type="text" v-model="searchData" placeholder="Search User Name" />
         <b-pagination class="mb-0" size="md" :total-rows="userAccuralIds.length" v-model="currentPage" :per-page="10" />
      </b-nav-form>
    </b-navbar>
    <b-container>
     
      <b-card v-for="user in userAccuralIdsPaged" :key="user.id">
        <b-row><b-col  cols="4">
             <userCard :user="user" :specialitiesArray="user.deptSpecialties" :defaultShow="true" />
          </b-col>
          <b-col>
            <b-card no-body>
              <b-tabs card>
                <b-tab title="Profile">
                <b-card  no-body v-for="profile in  getRosterProfiles(user.id)" :key="profile.id" border-variant="light">
                  <b-row>
                      <b-col lg="3">
                      {{ getApparatusData(profile.rosterId) }} (pos: {{ profile.rosterPosition }})
                      </b-col>
                    <b-col lg="2">{{ profile.rosterSpotType }} 
                    </b-col>
                    <b-col lg="2">
                     Start: {{ getDateFormated(profile.rosterAssignmentStart) }}
                     
                    </b-col>
                    <b-col lg="2">
                     End: {{ getDateFormated(profile.rosterAssignmentEnd) }}
                      </b-col>
                    <b-col lg="1" v-if="profile.scheduledKellyCycleNumber">
                      {{ getFormatedKellyDay(profile.scheduledKellyCycleNumber) }}
                    </b-col>
                    <b-col lg="2">
                      {{ profile.rosterStartTime }}<br>
                      {{ profile.rosterShiftLength }} Hrs
                      </b-col>
                    </b-row>
                </b-card>
               
                  </b-tab>
                  <b-tab title="Accurals">
            <b-btn v-if="addNewUser == ''" variant="primary" block @click="addNewUser = user.id">Add New Accural</b-btn>
            <b-table v-if="addNewUser == user.id" :fields="['payrollAccuralId', 'payrollAccuralStart', 'payrollAccuralEnd', 'Edit']" :items="addNewAccural">
              <template slot="payrollAccuralId" slot-scope="data">
                <b-form-select  v-model="data.item.payrollAccuralId" :options="accuralOptions" />
                </template>
                <template slot="payrollAccuralStart" slot-scope="data">
              <b-form-input type="date" v-model="data.item.start" />
                  </template>
                  <template slot="payrollAccuralEnd" slot-scope="data">
          
                 <b-form-input type="date" v-model="data.item.end" />
                  </template>
                  <template slot="Edit" slot-scope="data">
                    <b-btn @click="addNewUser = ''" variant="danger" size="sm" >Cancel</b-btn>
                    <b-btn @click="addNewUserAccurals(data.item, user.id)" variant="info" size="sm" >Add</b-btn>
                  </template>
              </b-table>
            <b-table :fields="['payrollAccuralId', 'payrollAccuralStart', 'payrollAccuralEnd', 'Edit']" :items="getUserAccurals(user.id)">
              <template slot="payrollAccuralId" slot-scope="data">
                <span v-if="!(editAccural == data.item.id && editUser == user.id)">{{ getPayrollAccuralName(data.item.payrollAccuralId) }}</span>
                <b-form-select v-if="editAccural == data.item.id && editUser == user.id" v-model="data.item.payrollAccuralId" :options="accuralOptions" />
                </template>
                <template slot="payrollAccuralStart" slot-scope="data">
                 <span v-if="!(editAccural == data.item.id && editUser == user.id)"> {{ getDateFormated(data.item.payrollAccuralStart) }}</span>
              <b-form-input type="date" v-if="editAccural == data.item.id && editUser == user.id" v-model="data.item.start" />
                  </template>
                  <template slot="payrollAccuralEnd" slot-scope="data">
                <span v-if="!(editAccural == data.item.id && editUser == user.id)">{{ getDateFormated(data.item.payrollAccuralEnd) }}</span>
                  <b-form-input type="date" v-if="editAccural == data.item.id && editUser == user.id" v-model="data.item.end" />
                  </template>
                  <template slot="Edit" slot-scope="data">
                    <b-btn  v-if="!(editAccural == data.item.id && editUser == user.id)" @click="editUser = user.id, editAccural = data.item.id" variant="warning" size="sm" >Edit</b-btn>
                    <b-btn  v-if="editAccural == data.item.id && editUser == user.id" @click="editUser = '', editAccural = ''" variant="danger" size="sm" >Cancel</b-btn>
                    <b-btn  v-if="editAccural == data.item.id && editUser == user.id" @click="updateUserAccurals(data.item, user.id)" variant="info" size="sm" >Update</b-btn>
                  </template>
              </b-table>
              </b-tab>
              </b-tabs>
              </b-card>
            </b-col>
            </b-row>

        </b-card>
      </b-container>

</div>
</div>

</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
export default {
  components: {
    multiselect,
    userCard
  },
  name: 'roster-admin',
  props: ['payrollAccuralData', 'payrollBanksData', 'rosterProfiles', 'rosterData', 'payrollAccuralAssignedData'],
  data () {
    return {
      apparatusSelectValue: null,
      stationSelectValue: '',
      currentPage: 1,
      editItemId: '',
      editAccural: '',
      editUser: '',
      addNewUser: '',
      addNewAccural: [{payrollAccuralId: '', payrollAccuralStart: '', payrollAccuralEnd: ''}],
      stationParentSelectValue: '',
      bankModId: '',
      searchData: '',
      dataObj: {
          payrollAccuralName: '',
          payrollBankId: '',
          payrollBankRollover: 0,
          payrollBankRolloverEnd: 'payperiod'
      },
      payrollBankAccuralTypeOpt: [
        {text: 'Every Paycycle', value: 'paycycle'},
        {text: 'Every ShiftCycle', value: 'shiftcycle'},
        {text: 'Last Paycycle', value: 'lastpaycyle'},
        {text: 'First Paycycle', value: 'firstpaycycle'}
      ],
      payrollColorOptions: ['default', 'Yellow', 'black', 'bright-blue', 'blue', 'bright-green', 'green', 'bright-orange', 'orange', 'bright-red', 'red', 'bright-purple', 'purple'],
      payrollCodeVisibilityOptions: [{text: 'admin', value: 'adminchief'}, 'fireops', 'emsops', 'shopops', 'trainingops', 'logisticsops', 'supervisor', 'user' ],
      fields: [
        //'id',
          'payrollAccuralName',
          'payrollBankId',
          'payrollBankAccuralRate',
          'payrollBankAccuralType'
        //  'payrollBankRolloverEnd'
      ],
      bankModType: 'addTo',
      codeTypeOptions: [
        'bank modification',
        'exchange',
        'roster mod',
        'scheduled leave',
        'unscheduled leave',
        'modified leave',
        'payaddative',
        'overtime',
        'overtime availability',
        'unpaid leave',
        'none'
      ]
    }
  },
  created(){
    this.createUserAccuralList()
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        accuralOptions(){
          var options = this.payrollAccuralData.map(item => {
            var obj = {}
            obj['text'] = item.payrollAccuralName
            obj['value'] = item.id
            return obj
          })
         // console.log(options)
          return options
        },
        userAccuralIdsPaged(){
          var array = _.cloneDeep(this.userAccuralIds)
          return array.slice((this.currentPage - 1) * 10, (this.currentPage) * 10)
        },
        userAccuralIds(){
          var filter = this.deptContactData.filter(p => {
            return p.lastName.toLowerCase().match(this.searchData.toLowerCase()) // || p.lastName.toLowerCase().includes(this.searchData.toLowerCase())
          })
          return _.orderBy(filter, ['lastName'], ['asc'])
        },
       
  },
  mounted(){

  },
  watch: {

    apparatusSelectValue(newData){
      if(newData.apparatusLocation){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusLocation)
        if(station){
          this.stationSelectValue = station
        }
      }
    if(newData.apparatusParent){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusParent)
        if(station){
          this.stationParentSelectValue = station
        }
      }
    }
  },
  methods: {
    getFormatedKellyDay(kelly){
      if(kelly){
         var kellyPattern = this.siteconfig.payroll.kellyDayPattern
         if(kellyPattern && kellyPattern[kelly]){
              return kellyPattern[kelly]
         } return ''
      } return ''
    },
    getApparatusData(id){
      var roster = this.rosterData.find(p => p.id == id)
      if(roster.rosterGroupType == 'variable'){
        return roster.rosterName
      } else {
        return roster.apparatusName
      }
      
    },
    getDateFormated(date){
      if(date){
        return moment(date).format('MM/DD/YYYY')
      } else {
       return 'continuous'
      }
    },
    getDateFormatedArray(date){
      if(date){
        return moment(date).format('YYYY-MM-DD')
      } else {
       return ''
      }
    },
    getRosterProfiles(id){
      var array = this.rosterProfiles.filter(p => p.userId == id)
      return array
    },
    createUserAccuralList(){

    },
    getUserAccurals(id){
      var array = this.payrollAccuralAssignedData.filter(p => {
        return p.payrollUserId == id
      })
      return array.map(item => {
        var obj = item
        obj['start'] = this.getDateFormatedArray(item.payrollAccuralStart)
        obj['end'] = this.getDateFormatedArray(item.payrollAccuralEnd)
        return obj
      })
    },
    getPayrollAccuralName(id){

      var obj = this.payrollAccuralData.find(p => p.id == id)
      if(obj && obj.payrollAccuralName){
        return obj.payrollAccuralName
      } else {
        return 'Name Not Found'
      }
    },
    getBankName(id){
   
      var obj = this.payrollBanksData.find(p => p.id == id)
      if(obj && obj.payrollBankName){
        return obj.payrollBankName
      } else {
        return 'Name Not Found'
      }
    },
    rosterParents(data){
           var array = data
           var parents = [...new Set(array.map(p => p.stationParent))]
           var parentsObj = parents.map(item => item = this.stationsData.find(p => p.id == item))
           parentsObj.forEach(item => item['showStation'] = true)
           var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
           this.rosterParentsData = parentsSort
        },
    stationsFromParents(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort

    },
    returnRosterSpots(pid, sid){
      var array = this.rosterData.filter(p => p.stationParent == pid && p.stationId == sid)
      array.forEach(item => item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId).apparatusName)
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])   
      return apparatusSort
    },
    deleteRosterSpot(id){
    axios({
     method: 'delete',
     url: '/api/v1/staffing/roster/' + id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {

   })

    },
    getModifiedAccuralTimes(data){
      if(data){
        return moment(data + 'T' + this.siteconfig.payroll.shiftStartTime).toISOString()
      } else {
        return null
      }
    },
    updateUserAccurals(data){
      var dataObj = _.cloneDeep(data) 
      dataObj.payrollAccuralStart = this.getModifiedAccuralTimes(data.start)
      dataObj.payrollAccuralEnd = this.getModifiedAccuralTimes(data.end)
      delete dataObj.start
      delete dataObj.end
      delete dataObj.createdAt
      delete dataObj.updatedAt
    axios({
     method: 'patch',
     url: '/api/v1/staffing/payrollaccuralassigned/' + data.id,
     data: dataObj,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.$emit('getAccuralAssigned')
     this.editAccural = ''
     this.editUser = ''
   })
    },
    addNewUserAccurals(data, userid){
      var dataObj = _.cloneDeep(data) 
      dataObj.payrollAccuralStart = this.getModifiedAccuralTimes(data.start)
      dataObj.payrollAccuralEnd = this.getModifiedAccuralTimes(data.end)
      dataObj.payrollUserId = userid
      delete dataObj.start
      delete dataObj.end
    axios({
     method: 'post',
     url: '/api/v1/staffing/payrollaccuralassigned/',
     data: dataObj,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.$emit('getAccuralAssigned')
     this.addNewUser = ''
     this.addNewAccural = [{payrollAccuralId: '', payrollAccuralStart: '', payrollAccuralEnd: ''}]
   })
    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
