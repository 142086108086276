<template>
<div >
  <div :id="'gantt-'+ spot.id" class="mainView">
  <div class="gantt-container" v-if="spot && spot.id" ref="barContainer">
	<div class="gantt" v-if="clientWidth">
		<div class="gantt__row gantt__row--months bg-dark">
			<div class="gantt__row-first bg-dark"><span class="selectTableDate">{{ selectedDate }}</span></div>
        <span v-for="(hr, index) in hours" :key="index">{{ hr }}</span>
		</div>
		<div class="gantt__row gantt__row--lines" data-month="5">
        <span v-for="(hr, index) in hours" :key="index" class="ma"></span>
		</div>
    <template v-if="refreshData">
  		<div class="gantt__row" v-for="row in rows" :key="row.userId"> 
			<div class="gantt__row-first">
				<span class="">{{ row.label }}</span>
			</div>
			<ul class="gantt__row-bars" >
        <template  v-for="(bar, index) in row.bars" >
			      <li :style="'width:' +  bar.width + 'px; left:' + bar.left + 'px; border-radius:' + bar.radius" :class="bar.class ? 'payroll-' + bar.class : 'bg-secondary'" :key="index + 'li'" :title="bar.label">
              <span v-if="bar.width > 150" :title="bar.label">{{ bar.label }}</span>
              <span v-else-if="bar.width > 75" :title="bar.label">{{ bar.labelShort }}</span>
            </li>
        </template>
			</ul>
		</div>
    </template>
	</div>
  </div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,
},
props: ['spot', 'profiles', 'rosterProfiles', 'mods', 'payrollCodesData', 'selectedDate', 'payaddativemods', 'onDutyFilter', 'printOn', 'userSelected', 'payrollModDailyRosterData', 'tvView', 'payrollHolidaysData'],
data() {
    return {
      varWidth: '100%',
      myChartStart: "",
      myChartEnd: "",
      clientWidth: null,
      tryToBuildWidth: 0,
      offRosterMods: [
          'bank modification',
          'exchange',
          'overtime availability',      
          'none'
      ],
      removeRoster: [
        'scheduled leave',
        'unscheduled leave',
        'modified leave',
        'upaid leave',
      ],
      nonSpotModification: [
        'payaddative',
      ],
      spotModification: [
        'scheduled leave',
        'unscheduled leave',
        'modified leave',
        'unpaid leave',
        'roster mod',
        'overtime',
      ],
      emittedData: [],
      rows: [],
      refreshData: true,
  }
},
created(){
  this.myChartStart = moment(this.selectedDate).startOf('day')
  this.myChartEnd = moment(this.selectedDate).endOf('day')

}, 
mounted() { 
    this.onWindowResize()
    window.addEventListener('resize', this.onWindowResize)
},
updated(){
//  console.log('updated')
},
beforeDestroy(){
  	
},
watch: {
  mods(newData){
    if(newData){
      this.onWindowResize()
    }

  }

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  hours(){
    var diff = this.myChartEnd.diff(this.myChartStart, 'hours')
    var hours = []
    for(var i = 0; i < (diff + 1); i++){
      var hour = this.myChartStart.clone().add(i, 'hours').format('HH')
      hours.push(hour)
    }
    return hours
  },
  rosterCodesForOffRoster(){
    var array = this.payrollCodesData.filter(p => {
      return p.payrollCode == 'EXOFF' || this.removeRoster.indexOf(p.payrollCodeCategory) != -1
    })
    return array.map(p => p.id)
  },
  profilesAdjusted(){
    var profiles = this.profiles
    return profiles
  }
},
watcher:{

},
methods: {
  onWindowResize(){
    var width = this.$refs.barContainer
   // console.log(width)
    if(width  && false){
      console.log(this.tryToBuildWidth)
      console.log(this.tryToBuildWidth < 100)
      console.log(width.clientWidth == 0)
      console.log(width.clientWidth)
    }
    if(width){
      if(width.clientWidth == 0 && this.tryToBuildWidth < 100){
      //  console.log('RESIZE')
        this.tryToBuildWidth = this.tryToBuildWidth + 1
        this.$nextTick(function(){
          this.onWindowResize()
        })
      }
    }
    if(width && width.clientWidth){
      this.$nextTick(function(){
      this.rowsBuild(width.clientWidth)
   //   console.log(width.clientWidth)
      this.clientWidth = width.clientWidth
      })

    }
  },
  checkForHolidays(roster){
    //  console.log('holidays gnatt')
    //  console.log(roster)
      var holidays = this.payrollHolidaysData.filter(p => {
          return moment(p.holidayDate).isSame(moment(this.selectedDate, 'YYYY-MM-DD'), 'day')
      })
      if(holidays && holidays.length > 0 && roster && roster[0] && roster[0].userId){
      var user = this.deptContactData.find(p => p.id == roster[0].userId)
      if(user){
          var holiday = this.payrollHolidaysData.find(p => {
          return moment(p.holidayDate).isSame(moment(this.selectedDate, 'YYYY-MM-DD'), 'day') && p.holidayPaytype.indexOf(user.deptRank) >= 0
          })
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Holiday')
          if(holiday && payrollCode){
            var startTime = roster[0].startTime
            var endTime = moment(roster[0].startTime).add(holiday.holidayHours, 'hours').toISOString()
            var newObj = {
                        payrollModType: payrollCode.payrollCodeCategory,
                        payrollModCode: payrollCode.id,
                        payrollModTargetUserId: roster[0].userId,
                        payrollModDatetime: startTime,
                        payrollModStartDatetime: startTime,
                        payrollModHours: holiday.holidayHours,
                        payrollModAuthorId: "system",
                        payrollModApprovalUserId: "system",
                        payrollModApproved: true,
                        payrollModNote: holiday.holidayName,
                        startTime: startTime,
                        endTime: endTime,
                        systemCreated: true
              }
              newObj['payrollModData'] = newObj
              return newObj
            } return false
      } return false
      } else {
      //  console.log('NO HOLIDAYS')
        return false
      }


      
    },
  rowsBuild(width){
    if(width){
    //  console.log(this.profiles)
    var profiles = this.profiles.filter(p => {
      return p.rosterId == this.spot.id
    })
    profiles = _.orderBy(profiles, ['rosterPosition'], ['asc'])
    var userIds = [...new Set(profiles.map(p => p.userId))]
    var holdMap = userIds.map(id => {
        var obj = {}
        obj.label = this.getUserDataShort(id)
        obj.userId = id
        obj.bars = this.getBars(profiles.filter(p => p.userId == id), width, id)
        return obj
    })
    this.rows = holdMap.filter(p => p.bars && p.bars.length > 0)
    } else {
      this.rows = []
    }
  }, 
  getLeftSide(startTime, width){
    var hours = moment(startTime).diff(this.myChartStart, 'hours', true)
    if(hours > 0){
      return {rightRad: true, left: (hours * width)}
    } return {rightRad: false, left: "0px"} 
  },
  getTotalWidth(startTime, endTime, width){
    var start = moment(startTime).diff(this.myChartStart, 'hours', true)
    var end = moment(this.myChartEnd).diff(endTime, 'hours', true) 
    if(start <= 0){
      var hours = moment(endTime).diff(this.myChartStart, 'hours', true)
      return {leftRad: true, width: (hours * width)} 
    } else if(end <= 0 && start > 0){
      var hours = moment(this.myChartEnd).diff(startTime, 'hours', true)
      return {leftRad: false, width: (hours * width)} 
    } else {
      var hours = moment(endTime).diff(startTime, 'hours', true)
      return {leftRad: true, width: (hours * width)} 
    }
  },
  getBars(profiles, width, userId){
   //console.log('GET BARS')
   //console.log(profiles)
   // console.log(width)
   // console.log(userId)
    var eachSize = ((width - 150) / 24)
    var holdArray = profiles
    var mods = this.mods.filter(p => p.userId == userId)
   // console.log(mods)
    if(this.siteconfig.payroll.holidayTable && this.payrollHolidaysData){
         var holidays = this.checkForHolidays(profiles)
         if(holidays){
         //   console.log('HOLIDAY PASS')
         //   console.log(holidays)
            mods.push(holidays)
         }
    }
    if(mods && mods.length > 0){
      holdArray = holdArray.concat(mods)
    }
    if(holdArray){
    var holdProfiles = holdArray.map(item => {
        if(item && item.payrollModData && item.payrollModData.payrollModCode){
          var payrollModCode = this.getCodeObj(item.payrollModData.payrollModCode)
        //  console.log(payrollModCode)
        }
        var leftObj = this.getLeftSide(item.startTime, eachSize)
        var widthObj = this.getTotalWidth(item.startTime, item.endTime, eachSize)
        var obj = {
              startTime: item.startTime,
              endTime: item.endTime,
              left: leftObj.left,
              width: widthObj.width,
              radius: leftObj.rightRad && widthObj.leftRad ? "20px" : leftObj.rightRad ? "20px 0 0 20px" : widthObj.leftRad ? "0 20px 20px 0" : "0 0 0 0",
              class: payrollModCode && payrollModCode.payrollCodeColor ? payrollModCode.payrollCodeColor : null,
              label: payrollModCode && payrollModCode.payrollCodeName ? payrollModCode.payrollCodeName : 'Regular Schedule',
              labelShort: payrollModCode && payrollModCode.payrollCode ? payrollModCode.payrollCode : 'Reg',
              dollar: payrollModCode && payrollModCode.payrollCodeDollar ? true : false
        }
        return obj
    })
    holdProfiles = holdProfiles.filter(p => p.width && p.width > 0 && !p.dollar)
  //  console.log(holdProfiles)
    if(holdProfiles && holdProfiles.length > 1){
      var start = _.orderBy(holdProfiles, ['startTime'], ['asc'])
   //   console.log(start)
      var running = 0
      for(var s = 1; s < start.length;  s++){
        var previous = start[s-1]
        if(previous && previous.width){
          if(previous.left == start[s].left && previous.width == start[s].left){
          //  console.log('SAME WIDTH AND LENGTH')
          } else {
          start[s].left = start[s].left - (previous.width + running)
          running = running + previous.width
          }
        }
      }
   //   console.log(start)
      return start
    }
    return holdProfiles
    } else {
        return []
    }
  },
  consoleLogObj(data){
  //  console.log(data)
  },
  checkForUserSwap(obj){
    if(obj.spotData && obj.spotData.userId && obj.spotData.payrollModData && obj.spotData.payrollModData.payrollModType){
      if(obj.spotData.payrollModData.payrollModType != 'exchange'){
        var exOn = this.payrollModDailyRosterData.find(p => p.payrollModExchangeUserId == obj.spotData.userId)
        if(exOn){
          return 'ExOn for ' + this.getUserDataShort(exOn.payrollModTargetUserId)
        } return null
      } return null
    } return null
  },
  getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    checkForPayAddative(spot){
      if(spot && spot.spotData){
      var payAdd = this.payaddativemods.find(p => p.payrollModRosterId == spot.rosterId && p.payrollModTargetUserId == spot.spotData.userId && p.payrollModDatetime == spot.startTime)
      if(payAdd){
          return payAdd
        }  else {
      return false
      }
      } else {
        return false
      }
    },
  addPayrollMod(data, type){
     var dataObj = {  payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: data.rosterId, 
                      payrollModTargetUserId: '', 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: moment(data.startTime), 
                      payrollModHours: moment(data.endTime).diff(data.startTime, 'hours', true), 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: data.position,
                      payrollModApproved: null,
                      payrollModNote: '' }
      bus.$emit('updatePayrollMod', dataObj)
  },
  getUserDataShort(id){
   // console.log(id)
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName[0] + '. ' + user.lastName
        } else {
          return 'Unknown'
        }
      } else {
        return ''
      }
    },
    getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          var first = user.firstName + ' '
          var short = user.lastName + ' (' + user.deptRank + ')'
          if(this.tvView){
            var first = first.charAt(0) + '. '
            return first + short
          }
          return first + short + ' #' + user.deptSpecialties
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getUserNameOnly(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
        getPayrollCode(code){
      var obj = this.payrollCodesData.find(p => p.payrollCode == code)
      if(obj){
        return obj.id
      } else {
        return 'Code Not Found'
      }
    },
    updatePayrollModAdd(data){
    //  console.log(data)
      bus.$emit('updatePayrollMod', data)
    },

    updatePayrollMod(data, type){
      if((!data.spotData.payrollModData || type) && data.spotData){

          var dataobj = {       payrollModType: type && type == 'add' ? 'payaddative' : data.spotData.payrollModType ? data.spotData.payrollModType : '',
                                payrollModCode: data.spotData.payrollModCode ? data.spotData.payrollModCode : '', 
                                payrollModRosterId: data.rosterId, 
                                payrollModTargetUserId: data.spotData.userId ? data.spotData.userId : data.spotData.payrollModTargetUserId ? data.spotData.payrollModTargetUserId : null, 
                                payrollModExchangeUserId: '',
                                payrollModExchangeApproved: null, 
                                payrollModDatetime: data.startTime, 
                                payrollModHours: data.spotData.rosterShiftLength ? data.spotData.rosterShiftLength : data.spotData.payrollModHours ? data.spotData.payrollModHours : null, 
                                payrollModAuthorId: '', 
                                payrollModApprovalUserId: '',
                                payrollModRosterPosition: data.position,
                                payrollModApproved: null,
                                payrollModNote: '' }
          if(data.spotData.id && type != 'add'){
            dataobj['id'] = data.spotData.id
          }
      } else {
 
        var dataobj = data.spotData.payrollModData
      }
      bus.$emit('updatePayrollMod', dataobj)
    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.selectTableDate{
  font-size: 16px !important;
}
.mainView {
  display: block;
}
.wrapper {
  max-width: 1200px;
  min-width: 700px;
  margin: 0 auto;
  padding: 40px;
}
.gantt {
  display: grid;
  border: 0;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 75px 125px -57px #7e8f94;
}
.gantt__row {
  display: grid;
  grid-template-columns: 150px 1fr;
  background-color: #fff;
}
.gantt__row:nth-child(odd) {
  background-color: #f5f5f5;
}
.gantt__row:nth-child(odd) .gantt__row-first {
  background-color: #f5f5f5;
}
.gantt__row:nth-child(3) .gantt__row-bars {
  border-top: 0;
}
.gantt__row:nth-child(3) .gantt__row-first {
  border-top: 0;
}
.gantt__row--empty .gantt__row-first {
  border-width: 1px 1px 0 0;
}
.gantt__row--lines {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  grid-template-columns: 150px repeat(24, 1fr);
}
.gantt__row--lines span {
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.gantt__row--lines span.marker {
  background-color: rgba(10, 52, 68, 0.13);
  z-index: 2;
}
.gantt__row--lines:after {
  grid-row: 1;
  grid-column: 0;
  background-color: #1688b345;
  z-index: 2;
  height: 100%;
}
.gantt__row--months {
  color: #fff;
  background-color: #0a3444;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  grid-template-columns: 150px repeat(24, 1fr);
}
.gantt__row--months .gantt__row-first {
  border-top: 0 !important;
  padding: 10px;
  background-color: #0a3444;
}
.gantt__row--months span {
  text-align: left;
  font-size: 13px;
  align-self: end;
  font-weight: bold;
  padding: 2px 0 0 3px;
}
.gantt__row-first {
  background-color: #fff;
  border-width: 1px 0 0 0;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  padding: 15px 0;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.gantt__row-bars {
  list-style: none;
  display: grid;
  padding: 9px 0;
  margin: 0;
  grid-template-columns: repeat(24, 1fr);
  grid-gap: 8px 0;
  border-top: 1px solid rgba(221, 221, 221, 0.8);
}
.gantt__row-bars li {
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  min-height: 15px;
  background-color: #55de84;
  padding: 5px 12px;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.gantt__row-bars li.stripes {
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.1) 5px, rgba(255, 255, 255, 0.1) 12px);
}
.gantt__row-bars li:before, .gantt__row-bars li:after {
  content: "";
  height: 100%;
  top: 0;
  z-index: 4;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}
.gantt__row-bars li:before {
  left: 0;
}
.gantt__row-bars li:after {
  right: 0;
}
.tvViewFont {
      font-size: 20px;
}
.pointer {
  cursor: pointer;
}
.vacancy-danger {
  border: 1px solid red;
    border-radius: 5px;
    color: red;
    font-weight: bold;
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff !important;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b !important;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f !important;
    padding: 4px 5px 2px 5px;
    color: black !important;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3 !important;
    padding: 4px 5px 2px 5px;
    color: black !important;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039 !important;
    padding: 4px 5px 2px 5px;
    color: black !important;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400 !important;
    padding: 4px 5px 2px 5px;
    color: black !important;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff !important;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b !important;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906 !important;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622 !important;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000 !important;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000 !important;
    padding: 4px 5px 2px 5px;
    color: white !important;
    border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width: 992px){
  .borderBottomStacked {
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
  }
}

</style>
