<template>
  <b-container class="hello" fluid>
  <b-card no-body v-if="!showAdmin" >
    <b-tabs card>
      <b-tab title="Business Search" Active class="px-0 pt-3"  @click="showActiveId = ''">
  <b-container fluid>
    <BusinessTable v-if="submittedBusiness.length > 0 && dataReset" @showbusiness="addBusiness($event)" @deletebusiness="deleteBusiness($event)" :formGroups="formGroups" :formTableStyle="formTableStyle" :submittedBusiness="submittedBusiness" :submittedPrePlans="submittedPrePlans" />
      
  </b-container>
      </b-tab>
    <b-tab title="Add Business" class="p-0"  @click="showActiveId = ''">
      <b-row class="m-0 p-0"><b-col align="right">
      <b-btn class="mt-2 mr-5" @click="resetNewBusinessData" variant="danger" size="sm">Clear</b-btn>
      </b-col></b-row>
      <b-container class="pt-0 mt-0">
        <addNewBusiness v-if="resetNewBusiness"  :formGroups="formGroups" :formTableStyles="formTableStyle" @savedBusinessDataEmit="addBusinessList" />
</b-container>  

      </b-tab>
      <template v-for="Business in editthisPlan" >
      <b-tab :key="Business.id" :title="getTabTitle(Business.businessData, formTabTitle, Business.businessParent)" :active="showActiveId == Business.id">
         <addNewBusiness :savedBusinessData="Business" @savedBusinessDataEmit="addBusinessList" :viewItem="true" :formGroups="formGroups" @closeBusiness="removeBusinessTab" @duplicateBusiness="duplicateBusiness" :formTableStyles="formTableStyle" />
        </b-tab>
      </template>
      <b-tab title="Bulk Add" v-if="userData.role == 'admin'">
        <bulkNewBusiness :formGroups="formGroups" :formTableStyles="formTableStyle" :submittedBusiness="submittedBusiness" @savedBusinessDataEmit="getBusiness" />
        </b-tab>
    </b-tabs></b-card>

    </b-container>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from "moment";
import { mapState } from "vuex";
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import axios from "axios";
import BusinessSearch from "./../../tracking/preplans/businessSearch.vue";
import BusinessTable from "./../../tracking/preplans/businessTable.vue";
import addNewBusiness from "./../../addNew/newBusinessForm.vue";
import bulkNewBusiness from "./../../addNew/bulkNewBusinessForm.vue";
import { bus } from '../../../main.js';

export default {
  name: "hello",
  props: ['submittedPrePlans'],
  components: {
    addNewBusiness,
    BusinessSearch,
    BusinessTable,
    bulkNewBusiness
  },
  data() {
    return {
      stringData: "",
      userSearchBox: "",
      searchData: "",
      currentPage: 1,
      showAdmin: false,
      pageDisplayCount: 6,
      showActiveId: '',
      dataReset: true,
      editthisPlan: [],
      editBusinessData: false,
      pageCountOptions: [
        { text: "6 Per Page", value: 6 },
        { text: "12 Per Page", value: 12 },
        { text: "24 Per Page", value: 24 },
        { text: "48 Per Page", value: 48 }
      ],
      addressData: [],
      submittedBusiness: [],
      oldBusiness: [],
      resetNewBusiness: true,
      formTabTitle: "businessName5ic3rl49",
      formTableStyle: [
        {
          values: [{vmodel: "businessName5ic3rl49"}],
          label: "Business Name",
          key: "businessName",
          itemTitle: true,
        },
        {
          values: [{vmodel: "StreetNumber3f1myd54"}, {vmodel: "Streetbn8ry055" }, {vmodel: "Suffixrtum4856"}, {vmodel: "Unitrtum4556", prefix: 'unit:'}, {vmodel: "Building32238ry055", prefix: 'bldg:', postfix: ',' }, {vmodel: "City7xgpvk57", postfix: ','}, {vmodel: "State5lm01g58"}, {vmodel: "Ziplmanxr59"}],
          label: "Address",
          key: "streetNumberStreetStreetSuffix"
        },
        {
          values: [{vmodel: "Floor3233nxr59"}],
          label: "Floor",
          key: 'floor',
          itemSort: true,
          tableDisplay: false,
        },
        {
              values: [{vmodel: "billingAdd38548r59"}],
              label: "Business Billing Address:",
              key: "billingAdd",
            }
      ],
      formGroups: [
        {
          group: "business",
          label: "Business",
          variant: "dark",
          header: "Business Info",
          displayOrder: [
            { type: "table", order: "1" },
            { type: "table", order: "2" },
          ],
          fields: [
            {
              label: "Business Name:",
              idtag: "businessName",
              vmodel: "businessName5ic3rl49",
              size: "12",
              type: "text",
              order: "1",
              searchIndex: true,
              preplanData: false,
            },
            {
              label: "Business/Key Holder Name:",
              idtag: "keyholderBusinessName",
              vmodel: "keyholderBusinessNamexs7zqc50",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Business/Key Holder Email:",
              idtag: "keyholderBusinessEmail",
              vmodel: "keyholderBusinessEmailxssd7zqc50",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Business/Key Holder Number:",
              idtag: "keyholderBusinessNumber",
              vmodel: "keyholderBusinessNumberua5fbb51",
              displayType: "phone",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Business Secondary Contact:",
              idtag: "businessBusinessOwner",
              vmodel: "businessBusinessOwners3w3xs52",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Business Secondary Email:",
              idtag: "businessBusinessEmail",
              vmodel: "businessBusinessEmails3sdaws52",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Business Secondary Phone:",
              idtag: "ownerBusinessNumber",
              vmodel: "ownerBusinessNumbered2nc253",
              displayType: "phone",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Street Number:",
              idtag: "StreetNumber",
              vmodel: "StreetNumber3f1myd54",
              size: "2",
              type: "text",
              order: "2",
              cad: true,
              cadValue: 'addnumber',
              preplanData: true,
            },
            {
              label: "Street Name:",
              idtag: "Street",
              vmodel: "Streetbn8ry055",
              size: "4",
              type: "text",
              order: "2",
              cad: true,
              cadValue: 'streetname',
              preplanData: true,
            },
            {
              label: "Street Suffix:",
              idtag: "Suffix",
              vmodel: "Suffixrtum4856",
              size: "2",
              type: "text",
              cad: true,
              cadValue: 'streetSuffix',
              order: "2",
              preplanData: true,
            },
            {
              label: "Building:",
              idtag: "Building",
              vmodel: "Building32238ry055",
              size: "2",
              type: "text",
              order: "2",
              cad: true,
              cadOpt: true,
              cadValue: 'bldg',
              preplanData: true,
            },
            {
              label: "Unit:",
              idtag: "Unit",
              vmodel: "Unitrtum4556",
              cad: true,
              cadOpt: true,
              cadValue: 'unit',
              size: "2",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "City:",
              idtag: "City",
              vmodel: "City7xgpvk57",
              size: "4",
              cad: true,
              cadValue: 'city',
              type: "text",
              order: "2",
              preplanData: false,
            },
            {
              label: "State:",
              idtag: "State",
              vmodel: "State5lm01g58",
              size: "2",
              cad: true,
              cadValue: 'state',
              type: "text",
              order: "2",
              preplanData: false,
            },
            {
              label: "Zip Code:",
              idtag: "Zip",
              vmodel: "Ziplmanxr59",
              cad: true,
              cadValue: 'zipcode',
              size: "4",
              type: "text",
              order: "2",
              preplanData: false,
            },
            {
              label: "Floor Location:",
              idtag: "Floor",
              vmodel: "Floor3233nxr59",
              size: "2",
              type: "number",
              cad: true,
              default: 1,
              order: "2",
              preplanData: true,
            },
            {
              label: "Business Billing Address:",
              idtag: "billingAdd",
              vmodel: "billingAdd38548r59",
              size: "12",
              type: "text",
              cad: true,
              default: '',
              order: "2",
              preplanData: false,
            }
          ]
        },
        {
          group: "tactical",
          label: "Tactical/Hazards",
          variant: "warning",
          header: "Business Tactical or Hazard Considerations",
          displayOrder: [{ type: "textarea", order: "1" }],
          fields: [
            {
              label: "Business Tactical Info",
              idtag: "tacticalBusinessInfo",
              vmodel: "tacticalBusinessInfo8zpa0n64",
              size: "12",
              type: "textarea",
              rows: "6",
              order: "1",
              preplanData: true,
            }
          ]
        },
        {
          group: "fireprotection",
          label: "Fire Protection",
          variant: "danger",
          header: "Fire Protection Info",
          displayOrder: [
            { type: "table", order: "1" },
            { type: "table", order: "2" }
          ],
          fields: [
            {
              label: "Sprinkers",
              idtag: "sprinklers",
              vmodel: "sprinklerswt1ebo65",
              size: "2",
              type: "boolean",
              order: "1",
              preplanData: true,
            },
            {
              label: "FDC Location",
              idtag: "fdcLocation",
              vmodel: "fdcLocationag4h4f67",
              size: "5",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Sprinkler Control Valve Location",
              idtag: "spcvLocation",
              vmodel: "spcvLocation4dsaff67",
              size: "5",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Standpipe",
              idtag: "standpipe",
              vmodel: "standpipea7gsuw66",
              size: "2",
              type: "boolean",
              order: "1",
              preplanData: true,
            },
            {
              label: "Standpipe/Riser Location",
              idtag: "standpipeRiserLocation",
              vmodel: "standpipeRiserLocationa7gsuw66",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Fire Pump",
              idtag: "firepump",
              vmodel: "firepumpktgv1368",
              size: "2",
              type: "boolean",
              order: "1",
              preplanData: true,
            },
            {
              label: "Fire Pump Location",
              idtag: "firepumpLocation",
              vmodel: "firepumpLocation3wa8pw69",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Knox Box",
              idtag: "knoxbox",
              vmodel: "knoxboxlalrkx70",
              size: "2",
              type: "boolean",
              order: "2",
              preplanData: true,
            },
            {
              label: "Knox Box Location",
              idtag: "knoxBoxLocation",
              vmodel: "knoxBoxLocation5jfr0971",
              size: "4",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "FACP",
              idtag: "facp",
              vmodel: "facpbyzc4z72",
              size: "2",
              type: "boolean",
              order: "2",
              preplanData: true,
            },
            {
              label: "FACP Location",
              idtag: "facpLocation",
              vmodel: "facpLocationlmdgul73",
              size: "4",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "FACP Annunciator Location",
              idtag: "facpAnnunciatorLocation",
              vmodel: "facpAnnunciatorLocationlmdgul73",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "FACP Monitor Company/Info",
              idtag: "facpContact",
              vmodel: "facpContactlmdgul73",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "FACP Maintenance Company/Info",
              idtag: "facpMaintenanceContact",
              vmodel: "facpMaintenanceContactlmdgul73",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
             {
              label: "Last Maintenance Date",
              idtag: "facpLastMaintenance",
              vmodel: "facpLastMaintenancelmdgul73",
              size: "6",
              type: "date",
              order: "2",
              preplanData: true,
            }
          ]
        },
        {
          group: "utilities",
          label: "Utilites",
          variant: "success",
          header: "Utilities Info",
          displayOrder: [
            { type: "table", order: "1" },
            { type: "table", order: "2" }
          ],
          fields: [
            {
              label: "Electrical Meter Location",
              idtag: "elecmeterLoc",
              vmodel: "elecmeterLoc9qt01d74",
              size: "6",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Electrical Panel Location",
              idtag: "elecPanelLoc",
              vmodel: "elecPanelLoci4dv0x75",
              size: "6",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Gas On-site",
              idtag: "gasOnSite",
              vmodel: "gasOnSiteujx69h76",
              size: "2",
              type: "boolean",
              order: "1",
              preplanData: true,
            },
            {
              label: "Gas Type",
              idtag: "gastype",
              vmodel: "gastype9cv6ah77",
              size: "4",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Gas Shut Off Location",
              idtag: "gasShutOffLoc",
              vmodel: "gasShutOffLoce5flns78",
              size: "6",
              type: "text",
              order: "1",
              preplanData: true,
            },
            {
              label: "Water Shut Off Location",
              idtag: "waterShutOffLoc",
              vmodel: "waterShutOffLocglpbkc79",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "Elevator Control Room Location",
              idtag: "utiElevatorCRL",
              vmodel: "utiElevatorCRL3sdfp80",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },            
            {
              label: "Other:",
              idtag: "utiOther",
              vmodel: "utiOtherj137ip80",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            }
          ]
        },
        {
          group: "features",
          label: "Business Features",
          variant: "dark",
          header: "Business Features Info",
          displayOrder: [
            { type: "table", order: "1" },
            { type: "table", order: "2" },
            { type: "table", order: "3" }
          ],
          fields: [
            {
              label: "Type of Occupancy",
              idtag: "typeBusinessOccupancy",
              vmodel: "typeBusinessOccupancyd24c1l81",
              size: "6",
              type: "select",
              options: "occupancyOptions",
              order: "1",
              preplanData: true,
            },
            {
              label: "Type of Construction",
              idtag: "typeOfBusinessConstruction",
              vmodel: "typeOfBusinessConstruction395he182",
              size: "6",
              type: "select",
              options: "constructionOptions",
              order: "1",
              preplanData: true,
            },
            {
              label: "Roof Type",
              idtag: "roofType",
              vmodel: "roofTypeBusiness9zy3sw83",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "Roof Cover",
              idtag: "roofCover",
              vmodel: "roofCoverBusinesssp4n8r84",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "Roof Load",
              idtag: "roofLoad",
              vmodel: "roofLoadBusinesstelzu485",
              size: "6",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "Attic Access",
              idtag: "atticAccess",
              vmodel: "atticAccessBusinessvjtagb86",
              size: "2",
              type: "boolean",
              order: "2",
              preplanData: true,
            },
            {
              label: "Location",
              idtag: "atticAccessLocations",
              vmodel: "atticAccessLocationBusinesssae23kp87",
              size: "4",
              type: "text",
              order: "2",
              preplanData: true,
            },
            {
              label: "Business Width:",
              idtag: "demWidthBusiness",
              vmodel: "demWidthBusiness9sgyfu88",
              size: "2",
              type: "number",
              order: "3",
              preplanData: true,
            },
            {
              label: "Business Length:",
              idtag: "demLengthBusiness",
              vmodel: "demLengthBusinessmcoq0889",
              size: "2",
              type: "number",
              order: "3",
              preplanData: true,
            },
            {
              label: "Business Height:",
              idtag: "demHeightBusiness",
              vmodel: "demHeightBusinesspswjh990",
              size: "2",
              type: "number",
              order: "3",
              preplanData: true,
            },
            {
              label: "Business Cubic Feet:",
              idtag: "Cubic Ft",
              vmodel: "CubicFtBusinessozfz4s91",
              size: "2",
              type: "computed",
              order: "3",
              ref: [
                "demHeightBusinesspswjh990",
                "demWidthBusiness9sgyfu88",
                "demLengthBusinessmcoq0889"
              ],
              reftype: "multiply",
              preplanData: true,
            },
                        {
              label: "Business Square Ft",
              idtag: "Square Ft",
              vmodel: "squarefoot39fmdki3",
              size: "2",
              type: "text",
              order: "4",
              preplanData: true,
            }
          ]
        },
      ]
    };
  },
  created() {
    bus.$on('showBusinessFromBuild', this.addBusiness)
    this.getBusiness();


  },
  mounted() {
    bus.$emit('emitBusinessFormGroups', this.formGroups)
    bus.$emit('emitBusinessFormTableStyle', this.formTableStyle)
  },
  computed: {
    ...mapState([
      "deptContactData",
      "departmentBlogs",
      "getSessionData",
      "userData"
    ])
  },
  watcher: {},
  methods: {
    getTabTitle(data, title, parent) {
      var obj = data.find(p => p.vmodel == title);
      var string = obj.value
      if(parent){
        string = string + ' *Sub'
      }
      return string;
    },
    duplicateBusiness(obj){
      this.showActiveId = ''
      obj['duplicate'] = true
      obj.id = obj.id + Math.random()
              .toString(36)
              .substr(2, 10);
       this.editthisPlan.push(obj)
       this.showActiveId = obj.id
    },
    addBusiness(event) {
      this.showActiveId = ''
      if(event.id){
        var id = event.id
      } else {
        var id = event
      }
      if(this.editthisPlan.map(p => p.id).indexOf(id) == -1){
      axios({
        method: "get",
        url: "/api/v1/preplan/business/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.showActiveId = id
        this.editthisPlan.push(response.data[0])
      });
      } else {
        this.showActiveId = id
      }
    },
    addBusinessList($event){
      console.log('BUSINESS ADDED')
      this.resetNewBusiness = false
      this.getBusiness()
      this.editthisPlan.push($event)
      this.$nextTick(function(){
        this.resetNewBusiness = true
      })
    },
    resetNewBusinessData(){
      this.resetNewBusiness = false
      this.$nextTick(function(){
        this.resetNewBusiness = true
      })
    },
    removeBusinessTab(data){
      var index = this.editthisPlan.map(p => p.id).indexOf(data)
      this.editthisPlan.splice(index, 1)
    },
    setFormGoups(data) {
      var forms = _.cloneDeep(data);
      for (var i in data) {
        var fields = data[i].fields;
        for (var p in fields) {
          var id = fields[p].idtag;
          var length = 6;
          var id =
            id +
            Math.random()
              .toString(36)
              .substr(2, length);
          var id = _.uniqueId(id);
          fields[p].vmodel = id;
        }
      }
    },
    getBusiness() {
      this.dataReset = false
      axios({
        method: "get",
        url: "/api/v1/preplan/business",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.submittedBusiness = response.data;
        this.$nextTick(function(){
       this.dataReset = true
        })
 
      });
    },
    deleteBusiness(id) {
      console.log(id)
      axios({
        method: "delete",
        url: "/api/v1/preplan/business/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"

        }
      }).then(response => {
       this.getBusiness()
      });
    },
  }
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.blogCardClass {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space: nowrap;
}
.userDataWrap {
  text-align: center;
}
.userRank {
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer {
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: 0.4;
}
.headerContactRow {
  border-radius: 5px;
}
.A-shiftROW {
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.B-shiftROW {
  border: 1px solid #201b9f;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.C-shiftROW {
  border: 1px solid #0fb906;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.Day-shiftROW {
  border: 1px solid #e4cb07;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.A-shift {
  background: #e2070d;
  background: -moz-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift {
  background: #201b9f;
  background: -moz-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.C-shift {
  background: #0fb906;
  background: -moz-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.Day-shift {
  background: #e4cb07;
  background: -moz-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;
}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138, 138, 138, 1);
  color: #fff;
  border-radius: 10px;
}
h1,
h2 {
  font-weight: normal;
}

@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
