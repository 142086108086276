<template>
<div id="homelanding">
<b-container fluid class="px-0">
  <b-textarea v-model="uploadedJson" @input="fixData()" />
<b-card v-if="getInventoryItemsList">
<b-table :items="getInventoryItemsList">
  <template slot="asset_dbid" slot-scope="data">
    {{ getTaggedTypeDisplay(data.item.asset_type, data.item.asset_dbid) }}
    </template>
  </b-table>
</b-card>
</b-container>
<b-btn @click="submitBulkInventory">Submit</b-btn>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,

},
data() {
    return {
      showAdmin: false,
      showModal: false,
      inventoryItemsData: [],
      searchData: '',
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      inventoryTemplateData: [],
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
}
},
created () {

},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'userData',
    'getSessionData',
    'logisticAssetData',
  ]),
  getInventoryItemsList(){
  return this.inventorySheetData
  }

},
mounted(){

},
watch:{

},
methods: {
  fixData(){
    //console.log(this.uploadedJson)
    var data = JSON.parse(this.uploadedJson)
    var array = _.cloneDeep(data).map(item => {
        var obj = _.cloneDeep(item)
        obj['asset_manufacture_date'] = item.asset_manufacture_date ? moment(item.asset_manufacture_date, 'M/YYYY').toISOString() : null
        delete obj.userLast
        delete obj.userFirst
        obj['asset_serial_number'] = item.asset_serial_number ? item.asset_serial_number.toString() : null
        obj['asset_dbid'] = this.getTaggedType(item.asset_type, item.userFirst, item.userLast, item.asset_dbid)
        obj['asset_price'] = item.asset_price ? item.asset_price : null
        return obj
    })
    //console.log(array)
    this.inventorySheetData = array
  },
      getTaggedTypeDisplay(type, id){
      var array = this[type]
     // console.log(array)
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : obj.firstName + ' ' + obj.lastName
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
    getTaggedType(type, first, last, db){
      var array = this[type]
      console.log(type)
      console.log(db)
      if(array && type == 'deptContactData'){
      var obj = array.find(p => p.firstName.toLowerCase().match(first.toLowerCase()) && p.lastName.toLowerCase().match(last.toLowerCase()))
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : obj.id
      } else {
        return 'Unknown'
      }
      } else if(array && type == 'stationsData'){
        var obj = array.find(p => p.stationName.replace(/ /g,'').toLowerCase() == db.replace(/ /g,'').toLowerCase())
        console.log(obj)
        if(obj){
          return obj.id
        }
      } else {
        return 'Unknown Type'
      }
    },
    modalHideEvent(){
      this.showModal = false
    },
    submitBulkInventory(){
    axios({ 
     method: 'post',
     url: '/api/v1/taxonomy/logisticasset/bulk',
     data: this.getInventoryItemsList,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'logisticasset', headers: headers, expire: true}
     this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
   })
    },
    
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
