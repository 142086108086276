<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>

      <div  v-if="userData.role == 'admin'">
<b-container fluid>
<b-table hover striped :fields="['sortOrder', 'tutorialName', 'tutorialTag', 'tutorialUrl','Add',]" :items="newTutorialAdd">
     <template slot="sortOrder" slot-scope="data">
    <b-form-input size="6" v-model="data.item.sortOrder" />
  </template>
  <template slot="tutorialName" slot-scope="data">
    <b-form-input v-model="data.item.tutorialName" />
  </template>
  <template slot="tutorialTag" slot-scope="data">
    <b-form-input v-model="data.item.tutorialTag" />
  </template>
<template slot="tutorialUrl" slot-scope="data">
    <b-form-input v-model="data.item.tutorialUrl" />
  </template>
    <template slot="Add" slot-scope="data">
    <b-btn variant="primary" @click="addNew(data.item)">Add New</b-btn>
  </template>
</b-table>
<b-table v-if="tableReset" hover striped :fields="['sortOrder', 'tutorialName', 'tutorialTag', 'tutorialUrl', 'View', 'Edit', 'Remove']" :items="departmentTutorialsSort">
   <template slot="sortOrder" slot-scope="data">
    <b-form-input size="6" v-model="data.item.sortOrder" />
  </template>
  <template slot="tutorialName" slot-scope="data">
    <b-form-input v-model="data.item.tutorialName" />
  </template>
  <template slot="tutorialTag" slot-scope="data">
    <b-form-input v-model="data.item.tutorialTag" />
  </template>
<template slot="tutorialUrl" slot-scope="data">
    <b-form-input v-model="data.item.tutorialUrl" />
  </template>
  <template slot="View" slot-scope="data">
    <b-btn variant="link" @click="showBlogData(data.item)">View</b-btn>
  </template>
    <template slot="Edit" slot-scope="data">
    <b-btn variant="primary" @click="updatedTutorials(data.item)">Update</b-btn>
  </template>
    <template slot="Remove" slot-scope="data">
    <b-btn variant="danger" size="sm" @click="deleteBlog(data.item.id)">Delete</b-btn>
  </template>
</b-table>
</b-container>


  </div>
<b-modal v-if="showModal" ok-only v-model="showModal" size="lg" :title="tutorialView.tutorialName + ' | ' + tutorialView.tutorialTag">
  <tutData :tut="tutorialView" />
</b-modal>
<span v-show="false">{{ departmentTutorialsWatch }}</span>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import axios from 'axios'
import tutData from '../tracking/deptTutorialsdata.vue'
export default {
  name: 'hello',
  components: {
    tutData

  },
  data () {
    return {
      showModal: false,
      tableReset: true,
      tutorialView: '',
      departmentTutorialsSort: [],
      newTutorialAdd: [
        {
          tutorialName: '',
          tutorialTag: '',
          tutorialUrl: '',
          sortOrder: '1'
        }
      ]
    }
  },
  created(){
   this.departmentTutorialsSort = _.orderBy(this.departmentTutorials, ['sortOrder'], ['asc'])
  },
  computed: {
      ...mapState([
      'departmentTutorials',
      'userData',
      'getSessionData'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin' || this.userData.role == 'emsops' || this.userData.role == 'fireops'){
            return false
          } else {
            return true
          }
      },
      departmentTutorialsWatch(){
        var array = _.cloneDeep(this.departmentTutorials)
        this.departmentTutorialsSort = _.orderBy(array, ['sortOrder'], ['asc'])
        return this.departmentTutorials
      }

  },
  watcher: {
 
},
  methods: {
    showBlogData(data){
         console.log(data)
     this.tutorialView = data
           this.showModal = true
    },    
    blogTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY - HH:mm')
    },
    submitAsRead(data){
     axios({
      method: 'patch',
      url: '/api/v1/content/blog/adminread/' + this.blog.id + '/' + data,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      this.blog = response.data
      this.getBlogs()
      
      })
    },
        addNew(data){
      
     axios({
      method: 'post',
      url: '/api/v1/taxonomy/tutorial/',
      data: data,
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      var token = this.getSessionData
      var headers = { headers: { 
          'content-type': 'application/json', 
          'Authorization': token,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
        var payload = { taxonomy: 'tutorial', headers: headers, expire: true}
         this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)

      })
    },
    updatedTutorials(data){
     axios({
      method: 'patch',
      url: '/api/v1/taxonomy/tutorial/' + data.id,
      data: data,
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      var token = this.getSessionData
      var headers = { headers: { 
          'content-type': 'application/json', 
          'Authorization': token,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
        var payload = { taxonomy: 'tutorial', headers: headers, expire: true}
         this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
      
      })
    },
    deleteBlog(data){
     axios({
      method: 'Delete',
      url: '/api/v1/taxonomy/tutorial/' + data,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      var token = this.getSessionData
      var headers = { headers: { 
          'content-type': 'application/json', 
          'Authorization': token,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
                  var payload = { taxonomy: 'tutorial', headers: headers}
                  this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
      
      })
    },

 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}

.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}
</style>
