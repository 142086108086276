<template>
    <b-container fluid>
  <b-card no-body>
    <b-tabs card>
    <b-tab title="Export">
  <b-navbar type="dark" variant="dark">
           <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Timecard:</b-navbar-brand>
         <b-form-select v-model="currentYear" :options="yearsDropDown" />
         <b-navbar-brand class="bootstrapTable ml-2">
           <b-form-select v-model="cycleType" :disabled="disabledCycleType" :options="cycleTypeOptions" @change="getCurrentPayPeriod" />
         </b-navbar-brand>
         <b-form-select v-if="cycleType == 'Pay Period'" v-model="currentView" :options="previousPayrolls" />
         <b-form-select v-if="cycleType == 'Work Cycle'" v-model="currentView" :options="previousWorkCycles" />
         <b-form-select v-if="cycleType == 'Work Week'" v-model="currentView" :options="previousWorkWeeks" />
         <b-form-select v-if="cycleType == 'Kelly Cycle'" v-model="currentView" :options="previousKellyCycles" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectStart" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectEnd" />
         <b-btn variant="info" v-if="cycleType == 'Select Range'" @click="runRangeDate">Run</b-btn>
       <b-pagination v-if="false" class="mb-0 mx-3" size="md" :total-rows="adjustForAccurals.length" v-model="currentPage" :per-page="10" />
      
        <b-form-input type="date" v-model="payrollBankRecoDate" @input="updateBankDate" v-if="false" /> 
    <download-excel  class="ml-2"
    v-if="fixDataExcel.length > 0"
    :name="cycleType + ' ' + currentView.start + 'till ' + currentView.end"
    :data="fixDataExcel">
    <b-btn variant="primary">Full Excel</b-btn>
    </download-excel>
   <download-excel 
    class="ml-2"
    v-if="fixDataExcelCombined.length > 0"
    :name="cycleType + ' Combined ' + currentView.start + 'till ' + currentView.end"
    :data="fixDataExcelCombined">
    <b-btn variant="success">Combined Excel</b-btn>
    </download-excel>
    <download-excel
    class="ml-2"
    v-if="fixDataExcelCustom.length > 0 && false"
    :name="cycleType + ' Custom Export ' + currentView.start + 'till ' + currentView.end"
    :data="fixDataExcelCustom">
    <b-btn variant="info">Custom Excel</b-btn>
    </download-excel>
    <b-btn variant="primary" class="ml-2" v-if="siteconfig.payroll.customExport && fixDataExcelCustom.length > 0" @click="downloadAsCsv">Custom CSV</b-btn>
    <b-btn variant="info" class="ml-2" @click="generateNewReport(rosterProfilesFilterdByPeriodFilter)">Lock {{ cycleType }}</b-btn>
      </b-nav-form>
      <b-btn class="ml-2" variant="primary" @click="showFilter = !showFilter">Filter</b-btn>
    </b-navbar>
    <b-row>
      <b-col cols="3" v-if="showFilter" align="left">
          <b-form-group label="Select Export Codes to Hide" class="mt-2">
            <b-form-checkbox-group
              v-model="selectedFilter"
              :options="filterProfileModOptions"
              name="flavour-2a"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-checkbox v-model="selectAllBoo" @change="selectAllEventMods($event, filterProfileModOptions)">Hide All Export Codes?</b-form-checkbox>
          <b-form-checkbox v-model="hideUsersWithNone">Hide Users w/ No Mods?</b-form-checkbox>
          <b-form-group label="Select Pay Types to Hide" class="mt-2">
          <b-form-checkbox-group
            v-model="selectedRankFilter"
            :options="departmentRanksName"
            name="falvour-3a"
            stacked></b-form-checkbox-group>
          </b-form-group>
          <b-form-group label="Missing Approvals" class="mt-2">
          <b-form-checkbox-group
            v-model="selectApprovalFilter"
            :options="['User', 'Supervisor']"
            name="falvour-3ab"
            stacked></b-form-checkbox-group>
          </b-form-group>
          <b-form-group label="Missing Timecards (Overrides Above)" class="mt-2">
          <b-form-checkbox-group
            v-model="selectApprovalFilter"
            :options="['Unapproved']"
            name="falvour-3abd"
            stacked></b-form-checkbox-group>
          </b-form-group>
          <b-form-group label="Missing Approved (Overrides Above)" class="mt-2">
          <b-form-checkbox-group
            v-model="selectApprovalFilter"
            :options="['Unapproved Mods']"
            name="falvour-3abd"
            stacked></b-form-checkbox-group>
          </b-form-group>
      </b-col>
      <b-col>
    <b-container v-if="currentView">
      <h6  v-if="cycleType == 'Select Range'"  class="text-danger">Be Advised if your date range for "Select Range" is extensive, there will be a delay as the data is loaded</h6>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Approved TimeCards">
      <b-card v-for="user in rosterProfilesFilterdByPeriodFilter" :key="user.userid">
        <div slot="header"><h5>{{ user.lastName + ', ' + user.firstName + ' (' + user.employeeId + ')' }}
          </h5></div>
          <b-row><b-col>
               <b-badge class="mr-2" 
            :variant="user.export && user.export.userApprovedId && user.export && user.export.supervisorApprovedId ? 'success' : 
            (user.export && user.export.userApprovedId) || (user.export && user.export.supervisorApprovedId) ? 'warning' : 
            'danger'">Approved:</b-badge>
          <b-badge class="mr-2" :variant="user.export && user.export.userApprovedId ? 'success' : 'danger'">User</b-badge>
          <b-badge :variant="user.export && user.export.supervisorApprovedId ? 'success' : 'danger'">Supervisor</b-badge>
          </b-col>
          <b-col><div v-if="payrollRecoMods && payrollRecoMods.length > 0 && user.export">
            <b-badge class="mr-2" :variant="getModCound(user).approved == 0 ? 'success' : 'danger'">
              Missing Approved {{ getModCound(user).approved }}</b-badge>
              <b-badge :variant="getModCound(user).awaiting == 0 ? 'success' : 'warning'">
                Awaiting Approval {{ getModCound(user).awaiting }} </b-badge></div>
            </b-col></b-row>
        <b-table :items="user.shifts" :fields="['payrollModDatetime', 'payrollModExport', 'payrollModCode', 'payrollModType', 'payrollModHours']">
          <template slot="payrollModDatetime" slot-scope="data">
            {{ getDateTime(data.item.payrollModDatetime) }}
            </template>

          </b-table>
        </b-card>
          </b-tab>
          <b-tab title="Denied TimeCards">
      <b-card v-for="user in showTimeCardsDenied" :key="user.userid + user.export.id">
        <div slot="header"><h5>
          <b-badge variant="danger" v-if="user.export && user.export.timecardDeniedId">Denied: {{ getUserName(user.export.timecardDeniedId) }}</b-badge>
          {{ user.lastName + ', ' + user.firstName + ' (' + user.employeeId + ')' }}
          <b-badge class="mr-2" 
            :variant="user.export && user.export.userApprovedId && user.export && user.export.supervisorApprovedId ? 'success' : 
            (user.export && user.export.userApprovedId) || (user.export && user.export.supervisorApprovedId) ? 'warning' : 
            'danger'">Approved:</b-badge>
          <b-badge class="mr-2" :variant="user.export && user.export.userApprovedId ? 'success' : 'danger'">User</b-badge>
          <b-badge :variant="user.export && user.export.supervisorApprovedId ? 'success' : 'danger'">Supervisor</b-badge>
          
          </h5></div>
        <b-table :items="user.shifts" :fields="['payrollModDatetime', 'payrollModExport', 'payrollModCode', 'payrollModType', 'payrollModHours']">
          <template slot="payrollModDatetime" slot-scope="data">
            {{ getDateTime(data.item.payrollModDatetime) }}
            </template>

          </b-table>
        </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
      </b-container>
      </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Previous Locked Reports" @click="getPreviousPayrollExports">
         <b-table :items="payrollReports" :fields="['createdAt', 'title', 'exportAuthor', 'view', 'delete lock']">
           <template slot="createdAt" slot-scope="data">
             {{ getDateTime(data.item.createdAt) }}
             </template>
             <template slot="view" slot-scope="data">
               <b-btn variant="link" @click="viewPreviousReportData(data.item.id)">View</b-btn>
             </template>
             <template slot="delete lock" slot-scope="data">
               <b-btn variant="danger" size="sm" @click="deletePreviousReport(data.item.id)">Delete</b-btn>
             </template>
           </b-table>
      </b-tab>
      <template v-for="report in viewReports">
        <b-tab :key="report.id" active>
              <template slot="title">
                 <b-badge variant="success" v-if="report.newReport">Unsaved</b-badge> {{ report.title }}  <b-badge variant="danger" @click="removeReport(report.id)">X</b-badge>
            </template>
            <exportReport @refreshExportReports="refreshExportReports($event)" :exportReport="report" :timecard="true" :payrollBanksData="payrollBanksData" :payrollCodesData="payrollCodesData" :payrollAccuralAssignedData="payrollAccuralAssignedData" :payrollAccuralData="payrollAccuralData" :rosterProfiles="rosterProfiles"  :rosterData="rosterData" />
          </b-tab>
        </template>
      </b-tabs>
      </b-card>
      </b-container>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import Papa from 'papaparse'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import JsonExcel from 'vue-json-excel';
import Blob from 'blob'
import customJs from '../../../../customjs.js';
import exportReport from './payrollExport/payrollExportReport.vue'
export default {
  components: {
    multiselect,
    userCard,
    'download-excel' : JsonExcel,
    exportReport
  },
  name: 'roster-admin',
  props:  ['payrollAccuralData', 'payrollBanksData', 'rosterProfiles', 'rosterData', 'payrollAccuralAssignedData', 'payrollCodesData'],
  data () {
    return {
      currentPage: 0,
      searchData: '',
      payrollBankRecoDate: '',
      currentView: null,
      showFilter: false,
      hideUsersWithNone: false,
      selectAllBoo: false,
      cycleType: 'Pay Period',
      disabledCycleType: false,
      currentYear: '',
      selectedFilter: [],
      selectedRankFilter: [],
      approvedTimeCardsData: [],
      selectApprovalFilter: [],
      payrollRecoMods: [],
      payrollReports: [],
      viewReports: [],
      modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'}, 
     ],
      selectStart: '',
      selectEnd: '',
      createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      ],
    }
  },
  created(){
    this.selectStart = moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD')
    this.selectEnd = moment().startOf('month').format('YYYY-MM-DD')
    if(this.siteconfig.payroll.approvalCycle){
      this.cycleType = this.siteconfig.payroll.approvalCycle
      this.disabledCycleType = true
      this.getCurrentPayPeriod()
    } else {
      this.getCurrentPayPeriod()
    }
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'departmentRanks',
          'siteconfig'
        ]),
        departmentRanksName(){
          // console.log(this.departmentRanks)
          var nameMap = this.departmentRanks.map(p => p.deptrankName)
          return nameMap 
        },
        cycleTypeOptions(){
        var array = ['Pay Period', 'Work Cycle', 'Work Week']
        if(this.siteconfig.payroll.kellyCycleDate){
          array.push('Kelly Cycle')
        }
        array.push('Select Range')
        return array
        },
        fixDataExcel(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
         // return array
          var hold = []
          for(var i in array){
            var obj = { name: array[i].lastName + ', ' + array[i].firstName, employeeId: array[i].employeeId,  entryDate: '', entryCode: '', entryName: '', entryType: '', entryHours: ''}
            hold.push(obj)
            var shifts = array[i].shifts
            for(var s in shifts){
              var sobj = { name: '', employeeId: array[i].employeeId,  entryDate: moment(shifts[s].payrollModDatetime).format('MM/DD/YYYY HH:mm'), entryCode: shifts[s].payrollModExport, entryName: shifts[s].payrollModCode, entryType: shifts[s].payrollModType, entryHours: shifts[s].payrollModHours }
              hold.push(sobj)
            }

          }
          return hold
        },
        fixDataExcelCombined(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
          var hold = []
          for(var i in array){
            var codes = [...new Set(array[i].shifts.map(p => p.payrollModExport))]
            var shifts = array[i].shifts
            for(var c in codes){
              var filter = shifts.filter(p => p.payrollModExport == codes[c])
              var totalHours = 0
              for(var f in filter){
                totalHours = totalHours + Number.parseFloat(filter[f].payrollModHours)
              }
              var sobj = { employeeId: array[i].employeeId, 
                           firstName: array[i].firstName, 
                           lastName: array[i].lastName, 
                           startDate: moment(this.currentView.start).format('MM/DD/YYYY'), 
                           endDate: moment(this.currentView.end).format('MM/DD/YYYY'), 
                           entryCode: codes[c], 
                           //entryName: shifts[s].payrollModCode, 
                           //entryType: shifts[s].payrollModType, 
                           entryHours: totalHours }
              hold.push(sobj)
            }

          }
          return hold
        },
        fixDataExcelCustom(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
         // console.log(array)
          if(this.siteconfig.deptUrlSub == 'lbkfr' || this.siteconfig.deptUrlSub == 'efd' || this.siteconfig.deptUrlSub == 'lbk' || this.siteconfig.deptUrlSub == 'lbkpd'){
            var hold = customJs.custom_payroll_lbkfr(array, this.stationsData)
          } else {
            var hold = customJs.custom_payroll(array)
          }
          if(hold){
          return hold
          } else {
            return []
          }
        },
        previousPayrolls(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
              if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*2, 'weeks').format("YYYY-MM-DD")
                   var startSub = payroll.endAtMidnight ? 13 : 14
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(startSub, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(14, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(14, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            }
          } return []
        },
        previousWorkWeeks(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
          } return []
        },
        previousKellyCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'd').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
        previousWorkCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.cycle)
                   var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle - 1, 'days').format("YYYY-MM-DD")
                   if(payroll.workCycleMainExport){
                     endDate = moment(endDate, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD")
                   }
                   array.push({start: moment(startDate).add(payroll.cycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.cycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
        yearsDropDown(){
           var years = []
           var payroll = this.siteconfig.payroll
           var today = moment().format('YYYY')
           var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD").format('YYYY')
           var diff = moment(today).diff(presetVarDate, 'years')
           for(var i = 0; i < diff; i++){
              if(Number.parseInt(presetVarDate) + i < Number.parseInt(today)){
                years.push(Number.parseInt(presetVarDate) + i)
              }
           }
           years.push(Number.parseInt(today))
           var hold = []
           for(var i in years){
             hold.push({value: years[i].toString(), text: years[i]})
           }
           this.currentYear = Number.parseInt(today)
           return _.orderBy(hold, ['text'], ['desc'])
        },
        filterProfileModOptions(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriod)
          var hold = array.map(item => item.shifts)
          var merged = [].concat.apply([], hold);
          var options = [...new Set(merged.map(p => p.payrollModCode))]
          return options
        },
        rosterProfilesFilterdByPeriodFilter(){
          var start = _.cloneDeep(this.rosterProfilesFilterdByPeriod)
          if(this.selectedFilter && this.selectedFilter.length > 0){
              var mods = []
              for(var p in start){
                var obj = start[p]
                obj.shifts = start[p].shifts.filter(x => {
                  var index = this.selectedFilter.indexOf(x.payrollModCode)
                  if(index < 0){
                    return x
                  }
              })
                mods.push(obj)
            
            }
            
          } else {
            var mods = start
          }
          if(this.hideUsersWithNone){
            var filter = mods.filter(p => {
                if(p.shifts && p.shifts.length > 0){
                    return p
                }
            })
          } else {
            var filter = mods
          }
          if(this.selectedRankFilter && this.selectedRankFilter.length > 0){
              var rFilter = []
              for(var p in filter){
                var obj = filter[p]
                var index = this.selectedRankFilter.indexOf(obj.deptRank)
                if(index == -1){
                  rFilter.push(obj)
                }
            }
            
          } else {
            var rFilter = filter
          }
          if(this.selectApprovalFilter && this.selectApprovalFilter.length > 0){
            var userTest = this.selectApprovalFilter.indexOf('User') >= 0 ? true : false
            var supTest = this.selectApprovalFilter.indexOf('Supervisor') >= 0 ? true : false
            var both = this.selectApprovalFilter.indexOf('Unapproved') >= 0 ? true : false
            var modsA = this.selectApprovalFilter.indexOf('Unapproved Mods') >= 0 ? true : false
           // console.log('TEST', userTest, supTest)
            var aFilter = rFilter.filter(p => {
                if(modsA){
                  console.log(p)
                  return this.getModCound(p).approved > 0
                }
                if(both){
                   return !p.export
                }
                if(userTest && supTest && p.export){
                  return !p.export.supervisorApprovedId || !p.userApprovedId
                } else if (userTest && p.export){
                  return !p.export.userApprovedId
                } else if (supTest && p.export){
                  return !p.export.supervisorApprovedId
                }
            })
            
          } else {
            var aFilter = rFilter
          }         
          return aFilter
        },
        showTimeCardsDenied(){
          var users = _.cloneDeep(this.deptContactData)
          var data =  this.approvedTimeCardsData.filter(p => p.timecardDenied)
          var userIds = data.map(p => p.timecardUserId)
          var array = userIds.map(p => {
              var obj = users.find(u => u.id == p)
              return obj
          })
                    var hold = array.map(item => {
            var obj = item
            if(obj && obj.id){
            obj['userid'] = obj.id
            obj['shifts'] = this.getUserShiftsAndMods(item, data)
            obj['scheduled'] = this.getArrayData(item, data, 'scheduledWorkDays')
            obj['export'] = data.find(p => p.timecardUserId == obj.id)
            obj['multiCheck'] = data.filter(p => p.timecardUserId == obj.id)
            return obj
            } return null
          })
          hold = hold.filter(p => {
              if(p){
                return p
              }
          })
     //     console.log(data)
     //     console.log(hold)
          return _.orderBy(hold, ['lastName'], ['dsc'])
        },
        rosterProfilesFilterdByPeriod(){
          var array = _.cloneDeep(this.deptContactData)
          var data = this.approvedTimeCardsData.filter(p => !p.timecardDenied && p.supervisorApprovedId)
        //  var users = [...new Set(array.map(p => p.id))]
          var hold = array.map(item => {
            var obj = item
            if(obj && obj.id){
            obj['userid'] = obj.id
            obj['shifts'] = this.getUserShiftsAndMods(item, data)
            obj['scheduled'] = this.getArrayData(item, data, 'scheduledWorkDays')
            obj['export'] = data.find(p => p.timecardUserId == obj.id)
            obj['multiCheck'] = data.filter(p => p.timecardUserId == obj.id)
            return obj
            } return null
          })
          hold = hold.filter(p => {
              if(p){
                return p
              }
          })
     //     console.log(data)
     //     console.log(hold)
          return _.orderBy(hold, ['lastName'], ['dsc'])
        }
       
  },
  mounted(){

  },
  watch: {
    currentView(newData){
     //  console.log(newData)
       this.getPayrollModifiersInRange(newData)
    }

  },
  methods: {
    getModCound(data){
      var obj = {approved: 0, awaiting: 0}
      var mods = this.payrollRecoMods.filter(p => {
          return p.payrollModTargetUserId == data.userid && p.payrollModApproved != false
      })
      if(mods && mods.length > 0){
      var modsapproved = mods.filter(p => p.payrollModApproved == true).map(p => p.id)
      var modsawaiting = mods.filter(p => p.payrollModApproved == null).map(p => p.id)
      if(data.lastName == 'FIGE' && false){
      console.log(data)
      console.log(mods)
      console.log(modsapproved)
      console.log(modsawaiting)
      console.log(data.export)
      console.log(data.export.approvedPayrollmods)
      }
      if(data.export && data.export.approvedPayrollmods){
        var userMods = data.export.approvedPayrollmods.map(p => p.id)
        var modsApprovedFilter = modsapproved.filter(p => {
              var index = userMods.indexOf(p)
              if(index < 0){
                return p
              }
        }) 
        return {approved: modsApprovedFilter.length, awaiting: modsawaiting.length}
      } return {approved: modsapproved.length, awaiting: modsawaiting.length}
      } return obj
    },
    getUserName(id){
      var user = this.deptContactData.find(p => p.id == id)
      if(user){
        return user.firstName + ' ' + user.lastName
      } return 'User Not Found'
    },
    getUserShiftsAndMods(user, data){
      var userFilter = data.find(p => p.timecardUserId == user.id)
      var holdArray = []
      if(userFilter && userFilter['approvedTimecards'] && userFilter['approvedTimecards'].length > 0){
        var tcArray = userFilter['approvedTimecards']
        for(var t in tcArray){
          var obj = {}
          obj['cardData'] = tcArray[t]
          obj['payrollModDatetime'] = moment(tcArray[t].clockInTime)
          obj['payrollModType'] = 'normal',
          obj['payrollModExport'] = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCodeExport ? this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCode : 'No Export'
          obj['payrollModCode'] = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCodeExport ? this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCode : 'No Export'
          obj['payrollModHours'] = moment(tcArray[t].clockOutTime).diff(moment(tcArray[t].clockInTime), 'hour', true)
          obj['timecardDepartment'] = tcArray[t].timecardDepartment
          holdArray.push(obj) 
        }
      }
      if(userFilter && userFilter['approvedPayrollmods'] && userFilter['approvedPayrollmods'].length > 0){
        var tcArray = userFilter['approvedPayrollmods']
        for(var t in tcArray){
          var obj = {}
          var payrollCode = this.payrollCodesData.find(p => p.id == tcArray[t].payrollModCode)
          obj['cardData'] = tcArray[t]
          obj['payrollModDatetime'] = moment(tcArray[t].payrollModDatetime)
          obj['payrollModType'] = payrollCode && payrollCode.payrollCodeCategory ? payrollCode.payrollCodeCategory : 'No Type',
          obj['payrollModCode'] = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'No Export',
          obj['payrollModExport'] = payrollCode && payrollCode.payrollCodeExport ? payrollCode.payrollCodeExport : 'No Export',
          obj['payrollModHours'] = tcArray[t].payrollModHours
          obj['payrollModDollar'] = payrollCode && payrollCode.payrollCodeDollar ? true : false
          holdArray.push(obj)
        }
      }
      return _.orderBy(holdArray, 'payrollModDatetime', ['asc'])
    },
    getArrayData(user, data, field){
      var userFilter = data.find(p => p.timecardUserId == user.id)
      if(userFilter && userFilter[field] && userFilter[field].length > 0){
     //   console.log(userFilter[field])
        return userFilter[field]
      } return []

    },
    runRangeDate(){
      this.currentView = {start: this.selectStart, end: this.selectEnd }
    },
    selectAllEventMods(boo, data){
      if(boo){
        this.selectedFilter = data
      } else {
        this.selectedFilter = []
      }
    },
    refreshExportReports(id){
      this.removeReport(id)
      this.getPreviousPayrollExports()
    },
    removeReport(id){
      var index = this.viewReports.map(p => p.id).indexOf(id)
      this.viewReports.splice(index, 1)
    },
    deletePreviousReport(id){
        axios({
              method: 'delete',
              url: '/api/v1/staffing/payrollexport/' + id,
              headers: {
                  'Authorization': this.getSessionData,
                  'Content-Type' : 'application/json',
                }
            }).then( response => {
                console.log(response.data)
                this.getPreviousPayrollExports()
            })
    },
    viewPreviousReportData(id){
        axios({
              method: 'get',
              url: '/api/v1/staffing/payrollexport/' + id,
              headers: {
                  'Authorization': this.getSessionData,
                  'Content-Type' : 'application/json',
                }
            }).then( response => {
              if(response.data && response.data[0]){
          this.viewReports.push(response.data[0])
              }
            })
    },
    generateNewReport(data){
        var report = {}
        var payrollData = data.map(item => {
              var rdata = {}
              rdata['userid'] = item.id
              rdata['shifts'] = item.shifts
              rdata['export'] = item.export ? item.export : false
              rdata['userApproved'] = item.export && item.export.timecardUserId ? true : false
              rdata['supervisorApproved'] = item.export && item.export.supervisorApprovedId ? true : false
              return rdata
        })
        report['title'] = this.currentView.start + ' till ' + this.currentView.end
        report['exportAuthor'] = this.userData.firstName + ' ' + this.userData.lastName
        report['exportAuthorId'] = this.userData.id
        report['requestVerification'] = false
        report['payrollData'] = payrollData
        report['newReport'] = true
        this.viewReports.push(report)
      },
      downloadAsCsv(){
       var fileExtension = this.siteconfig.payroll.customExportFileType ? this.siteconfig.payroll.customExportFileType : '.csv'
       var title = this.cycleType + ' Custom Export ' + this.currentView.start + ' till ' + this.currentView.end + fileExtension
     //  console.log(title)
       var header = this.siteconfig.payroll.customExportHeader ? true : false
       var data = Papa.unparse(this.fixDataExcelCustom, {header: header})
      // console.log(data)
       const blob = new Blob([data], { type: 'text/csv' })
      // console.log(data)
       const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", title);
        link.click();
      },
      getDateTime(data){
        if(data){
          return moment(data).format('MM/DD/YYYY HH:mm:ss')
        } return 'Null Date'
      },
      sortModifications(reg, mods, user, employeeId){
        var array = reg
        var modsA = []
        var adj = []
        for(var i in mods){
          var obj = {}
          obj = _.cloneDeep(mods[i])
          obj['payrollModCodeDb'] = mods[i].payrollModCode
          var payrollMod = this.payrollCodesData.find(p => p.id == mods[i].payrollModCode)
          var exchange = mods[i].payrollModExchangeUserId == user
          var exOn = ''
          if(exchange){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXON')
          }
          if (mods[i].payrollModType == 'exchange' && mods[i].payrollModExchangeUserId != user){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXOFF')
          }
          obj['startTime'] == mods[i].payrollModDatetime
          obj['endTime'] = moment(mods[i].payrollModDatetime).add(mods[i].payrollModHours, 'hours').toISOString()
          obj['payrollModExport'] = payrollMod && payrollMod.payrollCodeExport ? payrollMod.payrollCodeExport : exOn && exOn.payrollCodeExport ? exOn.payrollCodeExport : 'No Export'
          obj['payrollModCode'] =  payrollMod && payrollMod.payrollCode ? payrollMod.payrollCode : exOn && exOn.payrollCode ? exOn.payrollCode : 'No Code'
          if(this.createVacancy.indexOf(mods[i].payrollModType) != -1 || obj.payrollModCode == 'EXOFF'){
            adj.push(obj)
          } else {
          modsA.push(obj)
          }
        }
        var fixed =  this.checkForUserMods(array, adj)
        for(var m in modsA){
          fixed.push(modsA[m])
        }
      //  console.log(fixed)
        return _.orderBy(fixed, ['payrollModDatetime'], ['asc'])
      },
      getFixedSwap(array, mods){
       if(false){
         console.log('Get Fixed Swap')
         console.log(array)
         console.log(mods)
        }
       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
          var mod = mods.filter(p => moment(p.payrollModDatetime).isSameOrAfter(array[a].payrollModDatetime) && moment(p.endTime).isSameOrBefore(array[a].endTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].payrollModDatetime).isSame(array[a].payrollModDatetime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].payrollModDatetime).isAfter(array[a].payrollModDatetime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.payrollModDatetime = array[a].payrollModDatetime
                  obj.endTime = mod[m].payrollModDatetime
                  obj['payrollModHours'] = moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].payrollModDatetime).isSame(array[a].payrollModDatetime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.payrollModDatetime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  obj['payrollModHours'] =  moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].payrollModDatetime).isAfter(array[a].payrollModDatetime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.payrollModDatetime = array[a].payrollModDatetime
                  obj.endTime = mod[m].payrollModDatetime
                  obj['payrollModHours'] =  moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  objTwo.payrollModDatetime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  objTwo['payrollModHours'] =  moment(objTwo.endTime).diff(objTwo.payrollModDatetime, 'hours', true)
                  array.push(objTwo)
                  array[a] = obj
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
      checkForUserMods(dates, adjust){
          var array = []
          var allMods = _.cloneDeep(adjust)
          for(var u in dates){
              var mods = adjust.filter(p => {
              return moment(p.payrollModDatetime).isBefore(dates[u].endTime) && moment(dates[u].payrollModDatetime).isBefore(p.endTime)
              })
              mods = _.orderBy(mods, ['payrollModDatetime'], ['asc'])
              if(mods.length > 0){
                var base = _.cloneDeep(dates[u])
                var obj = [base]
                var basesplit = this.getFixedSwap(obj, mods)
              if(basesplit.length > 0){
                 // console.log('BASESPLIT')
                 // console.log(basesplit)
                  for(var b in basesplit){
                    array.push(basesplit[b])
                  }
                }
              } else {
              array.push(dates[u])
              } 
        }  
        if(allMods.length > 0){
         var nonSwap = allMods //.filter(p => p.payrollModType != 'exchange')
         if(nonSwap.length > 0){
           for(var i in nonSwap){
             array.push(nonSwap[i])
           }
         }
        }    
        return array
      },
      getCurrentPayPeriod(){
        if(this.cycleType == 'Pay Period'){
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14)
                   var endDate = moment(presetVarDate).add(end*2, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(14, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today, 'YYYY-MM-DD').diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else {
                    return false
                }
        } else if (this.cycleType == 'Work Week') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else if (this.cycleType == 'Kelly Cycle') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                var dateDiffCal = moment(today).diff(presetVarDate, "days")
                var end = Math.floor(dateDiffCal/payroll.cycle)
                var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle -1, 'days').format("YYYY-MM-DD")
                if(payroll.workCycleMainExport){
                  endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                }
                this.currentView = {start: startDate, end: endDate}
        //        console.log(this.currentView)
        }
                
      }, 
    userProfileBuildShifts(data){ 
    var events = []
    for(var i in data){
      if(data[i].scheduledShift != 'Daily'){
        var indexColor = this.siteconfig.payroll.shifts.map(p => p.shift).indexOf(data[i].scheduledShift)
        if(indexColor != -1){
          var holdcolor = this.siteconfig.payroll.shifts[indexColor].bootstrap
        } else {
          var holdcolor = 'default'
        }
        var startDate = moment(data[i].rosterAssignmentStart).isBefore(this.currentView.start) ? this.currentView.start : moment(data[i].rosterAssignmentStart).format("YYYY-MM-DD")
        var shiftStartDate = this.findFirstAvailableShifts(startDate, data[i].scheduledShift, data[i].userId)
        if(shiftStartDate.isBefore(data[i].scheduledShift)){
          shiftStartDate = shiftStartDate.add(this.siteconfig.payroll.shiftRotation, 'd')
        }
        var endDate = data[i].rosterAssignmentEnd && moment(data[i].rosterAssignmentEnd).isBefore(this.currentView.end) ? data[i].rosterAssignmentEnd : this.currentView.end
        var lengthCalc = moment(endDate).diff(shiftStartDate, 'days')
        var length = Math.floor(lengthCalc/this.siteconfig.payroll.shiftRotation)
        if(length == 0){
        //  console.log(endDate)
          length = 1
        }
        for(var x = 0; x < length; x++){
          var adv = x * this.siteconfig.payroll.shiftRotation
          var date = moment(shiftStartDate).add(adv, 'd').format('YYYY-MM-DD')
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var obj = {}
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty')
          // console.log(payrollCode)
          obj['color'] = 'text-' + holdcolor
          obj['payrollModType'] = 'normal',
          obj['payrollModCode'] = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'No Export',
          obj['payrollModExport'] = payrollCode && payrollCode.payrollCodeExport ? payrollCode.payrollCodeExport : 'No Export',
          obj['payrollModDatetime'] = startTime, 
          obj['endTime'] = endTime,
          obj['payrollModHours'] = data[i].rosterShiftLength
            if(moment(startTime).isSameOrBefore(this.currentView.end)){
            events.push(obj)
            }
        if(x == length - 1){
          var holdCheck = moment(endDate, 'YYYY-MM-DD').diff(moment(endTime).format('YYYY-MM-DD'), 'days')
          if(holdCheck >= this.siteconfig.payroll.shiftRotation){
          var adv = (x + 1) * this.siteconfig.payroll.shiftRotation
          var date = moment(shiftStartDate).add(adv, 'd').format('YYYY-MM-DD')
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var newobj = {}
          newobj['color'] = 'text-' + holdcolor
          newobj['payrollModType'] = 'normal',
          newobj['payrollModCode'] = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'No Export',
          newobj['payrollModExport'] = payrollCode && payrollCode.payrollCodeExport ? payrollCode.payrollCodeExport : 'No Export',
          newobj['payrollModDatetime'] = startTime, 
          newobj['endTime'] = endTime
          newobj['payrollModHours'] = data[i].rosterShiftLength
              if(moment(startTime).isSameOrBefore(this.currentView.end)){
        events.push(newobj)        
              }
          }
        }
        }
        } else if (data[i].scheduledShift == 'Daily') {
          
          var indexColor = this.siteconfig.payroll.shifts.map(p => p.shift).indexOf(data[i].scheduledShift)
         if(indexColor != -1){
          var holdcolor = this.siteconfig.payroll.shifts[indexColor].bootstrap
         } else {
          var holdcolor = 'default'
        }
        var startDate = moment(data[i].rosterAssignmentStart).isBefore(this.currentView.start) ? this.currentView.start : moment(data[i].rosterAssignmentStart).format("YYYY-MM-DD")
        var endDate = data[i].rosterAssignmentEnd && moment(data[i].rosterAssignmentEnd).isBefore(this.currentView.end) ? data[i].rosterAssignmentEnd : this.currentView.end
        var days = data[i].scheduledWorkDays
        var length = moment(endDate, 'YYYY-MM-DD').diff(startDate, 'days')
          for(var x = 0; x < length; x++){
          var date = moment(startDate).add(x, 'd').format('YYYY-MM-DD')
          var testDay = days.indexOf(moment(date, 'YYYY-MM-DD').format('ddd'))
          if(testDay != -1){
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var obj = {}
          obj['color'] = 'text-' + holdcolor
          obj['payrollModType'] = 'normal',
          obj['payrollModCode'] = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCodeExport ? this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCode : 'No Export',
          obj['payrollModExport'] = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCodeExport ? this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty').payrollCodeExport : 'No Export',
          obj['payrollModDatetime'] = startTime, 
          obj['endTime'] = endTime
          obj['payrollModHours'] = data[i].rosterShiftLength,
        events.push(obj)
          }
          }
       }
    }
    return events
  },
    findFirstAvailableShifts(date, profileshift, id){
              var payroll = this.siteconfig.payroll
              var indexOfDate = payroll.shifts.map(p => p.shift).indexOf(profileshift)
              var selectedVarDate = moment(date, "YYYY-MM-DD");
              var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var advance = indexOfDate - roundedDated
              var testDate = selectedVarDate.add(advance, 'd')
              if(testDate.isBefore(date)){
                testDate = testDate.add(payroll.shiftRotation, 'd')
              } 
              return testDate
  },
  getPreviousPayrollExports(){
  axios({
        method: 'get',
        url: '/api/v1/staffing/payrollexport/short',
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     this.payrollReports = _.orderBy(response.data, ['createdAt'], ['desc'])
      })
  },
    getPayrollMods(data){
      this.disableGenerateReport = true
      this.payrollRecoMods = []
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrollmod/daily/' + data.start + '/' + data.end,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
        if(response.data){
          var array = response.data.filter(p => {
              if(p.payrollModDatetime && p.payrollModHours > 0 && p.payrollModHours != null){
                return p.payrollModType != 'roster mod' && moment(p.payrollModDatetime).isBefore(data.end)
              }
              })
              this.payrollRecoMods = array
        } 

        // this.rosterReload = true
      })
    },
  getPayrollModifiersInRange(data){
      this.approvedTimeCardsData = []
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrolltimecardapproval/submitted/' + data.start + 'till' + data.end,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
         this.approvedTimeCardsData = response.data
         this.getPayrollMods(data)
      })
    },
   


  }



  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
