<template>
  <b-container fluid class="px-1 px-md-4 hello">
  <b-card no-body v-if="!showAdmin" >
    <b-tabs card>
      <b-tab title="Pre-Plans" Active class="px-0 pt-3">
  <b-container fluid>
    <b-btn v-if="oldPreplans.length > 0 && userData.role == 'admin'" @click="submitOldPreplans">Submit Old Preplans</b-btn>
    <preplanTable v-if="submittedPrePlans.length > 0 && dataReset" @showPreplan="addPrePlan($event)" @deletePreplan="deletePreplan($event)" :formGroups="formGroups" :formTableStyle="formTableStyle" :submittedPrePlans="submittedPrePlans" />
  </b-container>
      </b-tab>
    <b-tab title="Site Visit Tracking">
     <sitevisittracking :sitevisitsData="sitevisitData" @getsitevisits="getSiteVisits" />
    </b-tab>
    <b-tab title="New Pre-Plan" class="p-0" :active="createNewPreplan">
      <b-row class="m-0 p-0"><b-col align="right">
      <b-btn class="mt-2 mr-5" @click="resetNewPreplanData" variant="danger" size="sm">Clear</b-btn>
      </b-col></b-row>
      <b-container class="pt-0 mt-0">
        <addNewPreplan v-if="resetNewPreplan" :createNewPreplanData="createNewPreplanData" :formGroups="formGroups" :formTableStyles="formTableStyle" :businessFormGroups="businessFormGroups" :businessFormTableStyle="businessFormTableStyle" @savedPrePlanData="addPrePlanList" />
</b-container>  

      </b-tab>
      <template v-for="preplan in editthisPlan" >
      <b-tab :key="preplan.id" active :title="getTabTitle(preplan.preplanData, formTabTitle)" class="px-1 px-md-4">
         <addNewPreplan 
         :savedPreplanData="preplan" 
         :viewItem="true" 
         :formGroups="formGroups" 
         :businessFormGroups="businessFormGroups" 
         :businessFormTableStyle="businessFormTableStyle" 
         @reloadPreplan="reloadPreplan" 
         @reloadPreplanGis="reloadPreplanGis" 
         @closePreplan="removePreplanTab"
         :responseViewHold="responseView" 
         :formTableStyles="formTableStyle" />
        </b-tab>
      </template>
    </b-tabs></b-card>
    </b-container>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from "moment";
import { mapState } from "vuex";
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import axios from "axios";
import preplanSearch from "./../../tracking/preplans/preplanSearch.vue";
import preplanTable from "./../../tracking/preplans/preplanTable.vue";
import addNewPreplan from "./../../addNew/newPreplanForm.vue";
import { bus } from '../../../main.js';
import sitevisittracking from "../../tracking/preplans/sitevisitTracking.vue";
export default {
  name: "hello",
  props: ['viewpreplan','submittedPrePlans'],
  components: {
    addNewPreplan,
    preplanSearch,
    preplanTable,
    sitevisittracking
  },
  data() {
    return {
      stringData: "",
      userSearchBox: "",
      searchData: "",
      currentPage: 1,
      showAdmin: false,
      pageDisplayCount: 6,
      dataReset: true,
      editthisPlan: [],
      responseView: false,
      editPrePlanData: false,
      createNewPreplan: false,
      createNewPreplanData: {},
      pageCountOptions: [
        { text: "6 Per Page", value: 6 },
        { text: "12 Per Page", value: 12 },
        { text: "24 Per Page", value: 24 },
        { text: "48 Per Page", value: 48 }
      ],
      addressData: [],
      oldPreplans: [],
      resetNewPreplan: true,
      formTabTitle: "businessName5ic3rl49",
      formTableStyle: [
        {
          values: ["gridNumberc6gjfr92"],
          label: "Grid Number",
          key: "gridNumber"
        },
        {
          values: ["businessName5ic3rl49"],
          label: "Business Name",
          key: "businessName"
        },
        {
          values: ["StreetNumber3f1myd54", "Streetbn8ry055", "Suffixrtum4856", "Building32238ry055", "Unitrtum4556", "City7xgpvk57", "State5lm01g58", "Ziplmanxr59"],
          label: "Address",
          key: "streetNumberStreetStreetSuffix"
        },
        {
          values: ["primaryStationResponseArea1pfxwq93"],
          label: "Primary Station",
          key: "primaryStation"
        }
      ],
      formGroups: [
        {
          group: "building",
          label: "Building",
          variant: "info",
          header: "Building Info",
          displayOrder: [
            { type: "table", order: "1" },
            { type: "table", order: "2" },
            { type: "table", order: "3" }
          ],
          fields: [
            {
              label: "Building Name or Description:",
              idtag: "buildingName",
              vmodel: "businessName5ic3rl49",
              size: "4",
              type: "text",
              order: "1",
              searchIndex: true
            },
            {
              label: "Complex Name:",
              idtag: "complexName",
              vmodel: "complexNameyzxral48",
              size: "4",
              type: "text",
              order: "1"
            },
            {
              label: "Gate Code:",
              idtag: "gateCode",
              vmodel: "gateCode987l48",
              size: "2",
              type: "text",
              order: "1"
            },
            {
              label: "Door Code:",
              idtag: "doorCode",
              vmodel: "doorCodeyzasdxral34",
              size: "2",
              type: "text",
              order: "1"
            },                        
            {
              label: "Building/Key Holder Name:",
              idtag: "keyholderName",
              vmodel: "keyholderNamexs7zqc50",
              size: "8",
              type: "text",
              order: "2"
            },
            {
              label: "Building/Key Holder Number:",
              idtag: "keyholderNumber",
              vmodel: "keyholderNumberua5fbb51",
              size: "4",
              type: "text",
              order: "2"
            },
            {
              label: "Secondary Contact:",
              idtag: "secondaryContact",
              vmodel: "secondaryContact3w3xs52",
              size: "8",
              type: "text",
              order: "2"
            },
            {
              label: "Secondary Contact Phone:",
              idtag: "secondaryNumber",
              vmodel: "secondaryNumber2nc253",
              size: "4",
              type: "text",
              order: "2"
            },
            {
              label: "Street Number:",
              idtag: "StreetNumber",
              vmodel: "StreetNumber3f1myd54",
              size: "2",
              type: "text",
              order: "3",
              cad: true,
              cadValue: 'addnumber'
            },
            {
              label: "Street Name:",
              idtag: "Street",
              vmodel: "Streetbn8ry055",
              size: "4",
              type: "text",
              order: "3",
              cad: true,
              cadValue: 'streetname'
            },
            {
              label: "Street Suffix:",
              idtag: "Suffix",
              vmodel: "Suffixrtum4856",
              size: "2",
              type: "text",
              cad: true,
              cadValue: 'streetSuffix',
              order: "3"
            },
            {
              label: "Building:",
              idtag: "Building",
              vmodel: "Building32238ry055",
              size: "2",
              type: "text",
              order: "3",
              cad: true,
              cadOpt: true,
              cadValue: 'bldg'
            },
            {
              label: "Unit:",
              idtag: "Unit",
              vmodel: "Unitrtum4556",
              cad: true,
              cadOpt: true,
              cadValue: 'unit',
              size: "2",
              type: "text",
              order: "3"
            },
            {
              label: "City:",
              idtag: "City",
              vmodel: "City7xgpvk57",
              size: "6",
              cad: true,
              cadValue: 'city',
              type: "text",
              order: "3"
            },
            {
              label: "State:",
              idtag: "State",
              vmodel: "State5lm01g58",
              size: "2",
              cad: true,
              cadValue: 'state',
              type: "text",
              order: "3"
            },
            {
              label: "Zip Code:",
              idtag: "Zip",
              vmodel: "Ziplmanxr59",
              cad: true,
              cadValue: 'zipcode',
              size: "4",
              type: "text",
              order: "3"
            }
          ]
        },
        {
          group: "business",
          label: "Business",
          variant: "dark",
          header: "Business Info",
        },
        {
          group: "watersupply",
          label: "Water Supply",
          variant: "primary",
          header: "Water Supply Additional Information",
          displayOrder: [{ type: 'table', order: "1"},
                        { type: "textarea", order: "2" }],
          fields: [
                {
              label: "Primary Location",
              idtag: "primaryLocation",
              vmodel: "primaryLocationh3yswk60",
              size: "9",
              type: "text",
              order: "1"
            },
            {
              label: "GPM-P",
              idtag: "primaryGpm",
              vmodel: "primaryGpmb5xxua61",
              size: "3",
              type: "number",
              order: "1"
            },            
            {
              label: "Secondary Location",
              idtag: "secondaryLocation",
              vmodel: "secondaryLocationzdu6nd62",
              size: "9",
              type: "text",
              order: "1"
            },
            {
              label: "GPM-S",
              idtag: "secondaryGPM",
              vmodel: "secondaryGPMh5u6cm63",
              size: "3",
              type: "number",
              order: "1"
            },
            {
              label: "Water Supply Tactical Info",
              idtag: "waterSupplyTacticalInfo",
              vmodel: "waterSupplyTacticalInfoh3yswk60",
              size: "12",
              type: "textarea",
              rows: "6",
              order: "2"
            }
          ]
        },
        {
          group: "tactical",
          label: "Tactical",
          variant: "warning",
          header: "Tactical Considerations Additional Info",
          displayOrder: [{ type: "textarea", order: "1" }],
          fields: [
            {
              label: "Tactical Info",
              idtag: "tacticalInfo",
              vmodel: "tacticalInfo8zpa0n64",
              size: "12",
              type: "textarea",
              rows: "6",
              order: "1"
            }
          ]
        },
        {
          group: "fireprotection",
          label: "Fire Protection",
          variant: "danger",
          header: "Fire Protection Additional Info",
          displayOrder: [
            { type: "textarea", order: "1" },
          ],
          fields: [
            {
              label: "Fire Protection Additional Info",
              idtag: "fireProtectionAdditionalInfo",
              vmodel: "fireProtectionAdditionalInfo8zpa0n64",
              size: "12",
              type: "textarea",
              rows: "6",
              order: "1"
            }
          ]
        },
        {
          group: "utilities",
          label: "Utilites",
          variant: "success",
          header: "Utilities Additional Info",
          displayOrder: [
            { type: "textarea", order: "1" },
          ],
          fields: [
             {
              label: "Utilities Additional Info",
              idtag: "utilitiesAdditionalInfo",
              vmodel: "utilitiesAdditionalInfo8zpa0n64",
              size: "12",
              type: "textarea",
              rows: "6",
              order: "1"
            }
          ]
        },
        {
          group: "features",
          label: "Building Features",
          variant: "dark",
          header: "Building Features Info",
          displayOrder: [
            { type: "table", order: "1" },
            { type: "table", order: "2" },
            { type: "table", order: "3" }
          ],
          fields: [
            {
              label: "Type of Occupancy",
              idtag: "typeOccupancy",
              vmodel: "typeOccupancyd24c1l81",
              size: "6",
              type: "select",
              options: "occupancyOptions",
              order: "1"
            },
            {
              label: "Type of Construction",
              idtag: "typeOfConstruction",
              vmodel: "typeOfConstruction395he182",
              size: "6",
              type: "select",
              options: "constructionOptions",
              order: "1"
            },
            {
              label: "Roof Type",
              idtag: "roofType",
              vmodel: "roofType9zy3sw83",
              size: "4",
              type: "text",
              order: "2"
            },
            {
              label: "Roof Cover",
              idtag: "roofCover",
              vmodel: "roofCoversp4n8r84",
              size: "4",
              type: "text",
              order: "2"
            },
            {
              label: "Roof Load",
              idtag: "roofLoad",
              vmodel: "roofLoadtelzu485",
              size: "4",
              type: "text",
              order: "2"
            },
            {
              label: "Stories Above Ground",
              idtag: "numberStories",
              vmodel: "numberStoriesu4ds85",
              size: "4",
              default: 1,
              type: "number",
              order: "2"
            },
            {
              label: "Attic Access",
              idtag: "atticAccess",
              vmodel: "atticAccessvjtagb86",
              size: "4",
              type: "boolean",
              order: "2"
            },
            {
              label: "Location",
              idtag: "atticAccessLocations",
              vmodel: "atticAccessLocationsae23kp87",
              size: "4",
              type: "text",
              order: "2"
            },
            {
              label: "Building Width:",
              idtag: "demWidth",
              vmodel: "demWidth9sgyfu88",
              size: "3",
              type: "number",
              order: "3"
            },
            {
              label: "Building Length:",
              idtag: "demLenth",
              vmodel: "demLenthmcoq0889",
              size: "3",
              type: "number",
              order: "3"
            },
            {
              label: "Building Height:",
              idtag: "demHeight",
              vmodel: "demHeightpswjh990",
              size: "3",
              type: "number",
              order: "3"
            },
            {
              label: "Cubic Feet:",
              idtag: "Cubic Ft",
              vmodel: "Cubic Ftozfz4s91",
              size: "3",
              type: "computed",
              order: "3",
              ref: [
                "demHeightpswjh990",
                "demWidth9sgyfu88",
                "demLenthmcoq0889"
              ],
              reftype: "multiply"
            }
          ]
        },
        {
          group: "track",
          label: "Track",
          variant: "secondary",
          header: "Preplan Tracking Info",
          displayOrder: [{ type: "table", order: "1" }],
          fields: [
            {
              label: "Grid Number",
              idtag: "gridNumber",
              vmodel: "gridNumberc6gjfr92",
              size: "4",
              type: "number",
              order: "1",
              searchIndex: true
            },
            {
              label: "Primary Station Response Area",
              idtag: "primaryStationResponseArea",
              vmodel: "primaryStationResponseArea1pfxwq93",
              size: "8",
              refBy: ["primaryBattalionResponseAreaq7oeel94"],
              type: "taxonomySingleSelect",
              tax: "station",
              taxitem: "stationName",
              order: "1",
              searchIndex: true
            },
            {
              label: "Primary Station Battalion Area",
              idtag: "primaryBattalionResponseArea",
              vmodel: "primaryBattalionResponseAreaq7oeel94",
              size: "8",
              type: "reference",
              reference: "primaryStationResponseArea1pfxwq93",
              refitem: "stationParent",
              order: "1",
              searchIndex: true
            }
          ]
        }
      ],
      businessFormGroups: [],
      businessFormTableStyle: [],
      sitevisitData: [],
    };
  },
  created() {
    if(this.viewpreplan){
      this.responseView = true
      this.editthisPlan.push(this.viewpreplan)
    } else {
       // this.getPreplans();
        this.getSiteVisits();
    }
    bus.$on('emitBusinessFormGroups', this.setBusinessFormGroups)
    bus.$on('emitBusinessFormTableStyle', this.setbusinessFormTableStyle)
    bus.$on('openPreplan', this.addPrePlan)
    bus.$on('getSiteVisits', this.getSiteVisits)
    bus.$on('createNewPreplan', this.createNewPreplanFunction)
  //  this.getOldPreplans();
  },
  mounted() {
 //       this.getAddress();
  },
  computed: {
    ...mapState([
      "deptContactData",
      "departmentBlogs",
      "getSessionData",
      "userData"
    ]),
  },
  watcher: {},
  methods: {
    createNewPreplanFunction(data){
      this.createNewPreplan = false
      this.resetNewPreplan = false
      this.$nextTick(function(){
        this.createNewPreplan = true
        this.createNewPreplanData = data
       this.$nextTick(function(){
         this.resetNewPreplan = true
       })
      })
    },
    setBusinessFormGroups(data){
      this.businessFormGroups = data
    },
    setbusinessFormTableStyle(data){
      this.businessFormTableStyle = data
    },
    getTabTitle(data, title) {
      if(data && title){
      var obj = data.find(p => p.vmodel == title);
      if(obj){
      return obj.value;
      } return 'Missing Value'

      } return 'Missing Value'

    },
    addPrePlan($event) {
      axios({
        method: "get",
        url: "/api/v1/preplan/preplan/" + $event.id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.editthisPlan.push(response.data[0])
      });
    },
    reloadPreplanGis(id){
            bus.$emit('getPreplans')
      this.getSiteVisits()
      this.editthisPlan.splice(this.editthisPlan.map(p => p.id).indexOf(id), 1)
      var vm = this
        setTimeout(function(){
           vm.addPrePlan({id: id})
          }, 5000);
    },
    reloadPreplan(id){
      bus.$emit('getPreplans')
      this.getSiteVisits()
      this.editthisPlan.splice(this.editthisPlan.map(p => p.id).indexOf(id), 1)
      this.addPrePlan({id: id})
    },
    addPrePlanList($event){
      this.resetNewPreplan = false
      bus.$emit('getPreplans')
      this.addPrePlan($event)
      this.$nextTick(function(){
        this.resetNewPreplan = true
      })
    },
    resetNewPreplanData(){
      this.resetNewPreplan = false
      this.createNewPreplanData = {}
      this.$nextTick(function(){
        this.resetNewPreplan = true
      })
    },
    removePreplanTab(data){
      
      var index = this.editthisPlan.map(p => p.id).indexOf(data)
      this.editthisPlan.splice(index, 1)
    },
    setFormGoups(data) {
      var forms = _.cloneDeep(data);
      for (var i in data) {
        var fields = data[i].fields;
        for (var p in fields) {
          var id = fields[p].idtag;
          var length = 6;
          var id =
            id +
            Math.random()
              .toString(36)
              .substr(2, length);
          var id = _.uniqueId(id);
          fields[p].vmodel = id;
        }
      }
    },
    getSiteVisits() {
      axios({
        method: "get",
        url: "/api/v1/preplan/sitevisit/scheduled",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.sitevisitData = response.data;
      });
    },
    submitOldPreplans(){
      var array = this.oldPreplans
      for(var i in array){
        var obj = _.cloneDeep(array[i])
      //  delete obj.id
        axios({
        method: "post",
        url: "/api/v1/preplan/preplan",
        data: obj,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json", 
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
           response.data
      });
      }
    },
    getOldPreplans() {
      this.dataReset = false
      axios({
        method: "get",
        url: "/api/v1/content/preplan",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json", 
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
       this.oldPreplans = response.data
       // this.submittedPrePlans = response.data;
       // this.dataReset = true
      });
    },
    deletePreplan(id) {
      axios({
        method: "delete",
        url: "/api/v1/preplan/preplan/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"

        }
      }).then(response => {
             bus.$emit('getPreplans')
      });
    },
  }
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.blogCardClass {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space: nowrap;
}
.userDataWrap {
  text-align: center;
}
.userRank {
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer {
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: 0.4;
}
.headerContactRow {
  border-radius: 5px;
}
.A-shiftROW {
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.B-shiftROW {
  border: 1px solid #201b9f;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.C-shiftROW {
  border: 1px solid #0fb906;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.Day-shiftROW {
  border: 1px solid #e4cb07;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.A-shift {
  background: #e2070d;
  background: -moz-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift {
  background: #201b9f;
  background: -moz-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.C-shift {
  background: #0fb906;
  background: -moz-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.Day-shift {
  background: #e4cb07;
  background: -moz-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;
}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138, 138, 138, 1);
  color: #fff;
  border-radius: 10px;
}
h1,
h2 {
  font-weight: normal;
}

@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
