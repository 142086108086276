<template>
<div>
            <b-card no-body>

              <b-container class="mt-2">
                <b-btn variant="outline-primary" @click="addItemToItemArray('html')">HTML</b-btn>
                <b-btn variant="outline-info" @click="addItemToItemArray('text')" >TEXT</b-btn>
                <b-btn variant="outline-success"   @click="addItemToItemArray('video')">VIDEO</b-btn>
                <b-btn variant="outline-info" @click="addItemToItemArray('photo')">PHOTO</b-btn>
                <b-btn variant="outline-primary"  @click="addItemToItemArray('link')">Link Doc</b-btn>
                <b-btn variant="outline-info"  @click="addItemToItemArray('file')">Upload File</b-btn>

              </b-container>
              <p>Click the button to add the corresponding item type. Items can be dragged to be re-arraged</p>
              <b-card v-if="itemDataArray.length == 0" class="border-danger w-50 my-3 mx-auto">
                <h3>No Items Added</h3>
                </b-card>
             <draggable v-model="itemDataArray">
            <div v-for="(item, index) in itemDataArray" v-if="arrayChangeSet" :key="index">
            <b-card :title="item.type">
            <div class="m-2" v-if="item.type == 'html'">
             <vue-editor v-if="item.showEdit == true" class="textAreaDescription"  :editorToolbar="customToolbar"  v-model="item.value"></vue-editor>
              <div v-if="item.showEdit == false" v-html="item.value"></div>
              <b-row class="mt-2">
                <b-col class="mx-2" align="left"><b-btn variant="danger" size="sm" @click="deleteItemFromArray(index)">Delete</b-btn></b-col>
                                <b-col  v-if="item.showEdit == true"><b-card no-body class="border-danger">***Unsaved Data***</b-card></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == true"><b-btn variant= "primary" size="sm" @click="item.showEdit = false">Save</b-btn></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == false"><b-btn variant= "info" size="sm" @click="item.showEdit = true">Edit</b-btn></b-col>
                </b-row>
              </div>
            <div class="m-2" v-if="item.type == 'text'">
             <b-textarea placeholder="Enter text here" v-if="item.showEdit == true" v-model="item.value"></b-textarea>
              <div v-if="item.showEdit == false">{{ item.value }}</div>
               <b-row class="mt-2">
                <b-col class="mx-2" align="left"><b-btn variant="danger" size="sm" @click="deleteItemFromArray(index)">Delete</b-btn></b-col>
                                <b-col  v-if="item.showEdit == true"><b-card no-body class="border-danger">***Unsaved Data***</b-card></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == true"><b-btn variant= "primary" size="sm" @click="item.showEdit = false">Save</b-btn></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == false"><b-btn variant= "info" size="sm" @click="item.showEdit = true">Edit</b-btn></b-col>
                </b-row>
              </div>            
            <div class="m-2" v-if="item.type == 'video'">
                <input type="link"  v-if="item.showEdit == true" class="itemDescription" v-model="item.value" placeholder="Paste Youtube Link to add a Video" />
                               <div  v-if="item.showEdit == false" class="videoWrapper">
                   <iframe :src="'https://www.youtube.com/embed/' + getVideoId(item.value)" frameborder="0" allowfullscreen></iframe>
                   </div>
              <b-row class="mt-2">
                <b-col class="mx-2" align="left"><b-btn variant="danger" size="sm" @click="deleteItemFromArray(index)">Delete</b-btn></b-col>
                <b-col  v-if="item.showEdit == true"><b-card no-body class="border-danger">***Unsaved Data***</b-card></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == true"><b-btn variant= "primary" size="sm" @click="item.showEdit = false">Save</b-btn></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == false"><b-btn variant= "info" size="sm" @click="item.showEdit = true">Edit</b-btn></b-col>
                </b-row>
              </div>            
            <div class="m-2" v-if="item.type == 'photo'">
                <div class="image-upload" v-if="item.showEdit == true">
                  <label for="file-input">
                      <img :src="'./images/siteicons/picture-attach.png'"/>
                       </label>
                     <input id="file-input" type="file" class="pictureInputButton" @change="postImage($event, index)" />
                  </div>
                    <b-row v-if="item.showEdit == null"><b-col align="right" cols="auto" class="pt-1">
                     <span div class="spinner">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>      
                   </span></b-col><b-col align="left"><h4><strong>{{ item.value }}</strong></h4></b-col></b-row>
                   <b-img class="p-5" v-if="item.showEdit == false" fluid-grow :src="siteconfig.deptStore + item.value" />
       
               <b-row class="mt-2">
                <b-col class="mx-2" align="left"><b-btn variant="danger" size="sm" @click="deleteItemFromArray(index)">Delete</b-btn></b-col>
                <b-col  v-if="item.showEdit == true || item.showEdit == null"><b-card no-body class="border-danger">***Unsaved Data***</b-card></b-col>
                <b-col class="mx-2" align="right"></b-col>
                </b-row>
              </div>
              <div class="m-2" v-if="item.type == 'link'">
                <b-row v-show="item.showEdit == true">
                  <b-col v-show="selectedDocumentChild == ''">
                      <div>
                        <b-form-select :select-size="documentParents.length" v-model="selectedDocumentParent" :options="documentParents" class="mb-3">
                        </b-form-select>
                      </div>
                  </b-col>
                  <b-col>
                      <div>
                        
                        <b-form-select v-if="selectedDocumentParent != ''" :select-size="documentChild.length" v-model="selectedDocumentChild" :options="documentChild" class="mb-3">
                        </b-form-select>
                        <b-btn v-show="selectedDocumentChild != ''" size="sm" variant="primary" @click="selectedDocumentChild = ''">Back to Parent Options</b-btn>
                      </div>
                  </b-col>
                  <b-col v-show="selectedDocumentChild != ''">
                      <div>
                        <b-form-select v-if="selectedDocumentChild != ''" :select-size="6" v-model="item.value" :options="documentSelections" class="mb-3">
                        </b-form-select>
                      </div>
                  </b-col>
                </b-row>
                
                   <b-btn variant="link" v-if="item.showEdit == false" @click="viewFile(item.value.url)" >{{ item.value.title }}</b-btn>
       
               <b-row class="mt-2">
                <b-col class="mx-2" align="left"><b-btn variant="danger" size="sm" @click="deleteItemFromArray(index)">Delete</b-btn></b-col>
                <b-col  v-if="item.showEdit == true || item.showEdit == null"><b-card no-body class="border-danger">***Unsaved Data***</b-card></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == true"><b-btn variant= "primary" size="sm" @click="item.showEdit = false">Save</b-btn></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == false"><b-btn variant= "info" size="sm" @click="item.showEdit = true">Edit</b-btn></b-col>
                </b-row>
              </div> 
               <div class="m-2" v-if="item.type == 'file'">
                 <b-form-input v-if="item.showEdit == true" class="w-50 mx-auto my-2" type="text" placeholder="Add a display name to upload file" v-model="shorttitle" />
                  <div class="image-upload" v-if="item.showEdit == true">
                  <label for="file-input">
                      <img :src="'./images/siteicons/pdf-attach.png'"/>
                       </label>
                     <input :disabled="shorttitle == ''" id="file-input" type="file" class="pictureInputButton" @change="postDocumentFile($event, index, shorttitle)" />
                  </div>
                   <b-row v-if="item.showEdit == null"><b-col align="right" cols="auto" class="pt-1">
                     <span div class="spinner">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>      
                   </span></b-col><b-col align="left"><h4><strong>{{ item.value }}</strong></h4></b-col></b-row>
                   <b-btn variant="link" v-if="item.showEdit == false" @click="viewFile(item.value.url)" >{{ item.value.title }}</b-btn>
       
               <b-row class="mt-2">
                <b-col class="mx-2" align="left"><b-btn variant="danger" size="sm" @click="deleteItemFromArray(index)">Delete</b-btn></b-col>
                <b-col  v-if="item.showEdit == true || item.showEdit == null"><b-card no-body class="border-danger">***Unsaved Data***</b-card></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == true"></b-col>
                <b-col class="mx-2" align="right" v-if="item.showEdit == false"></b-col>
                </b-row>
              </div> </b-card>
                        </div>
             </draggable>
  
              </b-card>
              <b-btn v-if="itemDataArray.length > 0" block variant="info" class="w-50 mx-auto my-3" @click="saveData()">Save Body for Submission</b-btn>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import { VueEditor } from 'vue2-editor'
import axios from 'axios'
import draggable from 'vuedraggable'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import ImageCompressor from 'image-compressor.js'
export default {
  props:[],
components:{
    'multiselect' : Multiselect,
    VueEditor,
   'draggable' : draggable,
},
  data () {
    return {
        itemDataArray: [],
        loadingFile: false,
        selectedDocumentParent: '',
        shorttitle: '',
        selectedDocumentChild: '',
        selectedDocument: '',
        carouselToggle: false,
        trueBoo: true,
        imageLoadReset: true,
        itemShort: '',
        titleDescription: '',
        detailedDescription: '',
        linkedFile: true,
        newDataUUIDL: '',
        blogAuthor: [],
        postData: '',
        timeData: '',
        videoLink: '',
        videoLinkArray: [],
        fileUploadArray: [],
        addedFileArray: [],
        arrayChangeSet: true,
        customToolbar: [
          ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
          ['blockquote', 'code-block', 'link', { 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
    }
  },
  created(){

  },
  mounted(){
    this.setBlogAuthor()
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'deptContactData',
          'userData',
          'shiftsData',
          'repairStatus',
          'blogType',
          'workgroupData',
          'documentsApi',
          'siteconfig'
        ]),
        blogTypeParent(){
          var newArray = [... new Set(this.blogType.filter(p => p.blogtypeParent == '')
          )]
          var workgroupArray = [... new Set(this.workgroupData.filter(p => p.workgroupBlog == true))]
          if(workgroupArray.length > 0){
            newArray.push('Work Groups')
          }
          return newArray
        },
        formatedDateExport () {
        var timeString = moment().format()
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
      },
      documentParents(){
        var parents = [... new Set(this.documentsApi.map(p => p.documentTagParent))]
        var parents = parents.filter(p => {
          if(p != '' && p != undefined && p != null){
            return p
          }
        })
        return _.sortBy(parents, ['asc'])
      },
      documentChild(){
        var child = this.documentsApi.filter( p => {
          return p.documentTagParent == this.selectedDocumentParent
        })
        var child = [... new Set(child.map(p => p.documentTag))]
        return child
      },
      documentSelections(){
        var doc = this.documentsApi.filter( p => {
          return p.documentTag == this.selectedDocumentChild
        })
        var doc = _.orderBy(doc, ['documentName'], ['asc'])
        var array = []
        for(var i = 0; i < doc.length; i++){
          var string = { text: doc[i].documentName, value: { title: doc[i].documentName, url: doc[i].documentFile}}
          array.push(string)
        }
        return array
      }
    
    },
    watch: {
      itemDataArray(newData){
        this.$emit('itemData', this.itemDataArray)
      },
      videoLink(newData){
        if(newData != ''){
          console.log(newData)
        var string = newData
        this.videoLinkArray.push(string)
        this.videoLink = ''
        } else {
          return
        }
      },
      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    setItemValue(value){
      console.log(value)
    },
    saveData(){
      this.$emit('itemDataView', this.itemDataArray)
    },
    addItemToItemArray(data){
      this.itemDataArray.push({ type: data, value: '', showEdit: true })
    },
    setBlogAuthor(){
      var user = this.deptContactData.filter( p => {
        return p.id == this.userData.id } )

      console.log(user)
      this.blogAuthor = user[0]
    },
    removeVideo(idx){
      this.videoLinkArray.splice(idx, 1)
    },
    removeImage(idx){
      this.fileUploadArray.splice(idx, 1)
    },
    getVideoId(data){
           var videoLink = data.split('=')
        return videoLink[1]
      },
    blogTypeSub(data){
      var parentName = data
      if(data == 'Work Groups'){
        var subSet = []
        var workgroup = this.workgroupData.filter( p => {
          return p.workgroupBlog == true
        })
        for(var i = 0; i < workgroup.length; i++){
          var string = { blogtypeName: workgroup[i].workgroupName }
          subSet.push(string)
        }
      } else {
         var subSet = [... new Set(this.blogType.filter(p => p.blogtypeParent == parentName))]
      }
      return subSet
    },
    removeFromArray(data){
      var newArrayIds = [...new Set(this.addedFileArray.map(p => p.uuid[0].value))]
      var index = newArrayIds.indexOf(data.uuid[0].value)
      return this.addedFileArray.splice(index, 1)
    },
    addModalOpen(){
      document.body.className += 'modal-open';
    },
    showModalswitchEmitVehicle () {
      bus.$emit('switchEmitBlog');
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalswitchEmitVehicle()
    },
    deleteItemFromArray(index){
      this.itemDataArray.splice(index, 1)
    },
    postImage(e, index){
      this.arrayChangeSet = false
      this.itemDataArray[index] = { type: 'photo', value: 'Compressing', showEdit: null}
      this.$nextTick(function(){
        this.arrayChangeSet = true
      })
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result, index)
        }
        })
      
     },
    postDocumentFile(e, index, title){
      this.arrayChangeSet = false
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
      this.itemDataArray[index] = { type: 'file', value: { title: title, url: response.data}, showEdit: false}

      this.$nextTick(function(){
        this.arrayChangeSet = true
        this.shorttitle = ''
      })
        });

  
     },
     postImageAxios(result, index){
      this.arrayChangeSet = false
       this.itemDataArray[index] = { type: 'photo', value: 'Loading', showEdit: null}
      this.$nextTick(function(){
        this.arrayChangeSet = true
      })
    
        var data = new FormData();
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/blogposts/' + this.userData.lastName + this.userData.EmployeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
              this.arrayChangeSet = false
            this.itemDataArray[index] = {type: 'photo', value: response.data, showEdit: false}
      this.$nextTick(function(){
        this.arrayChangeSet = true
      })
        });
     },
    submitPost(){
     axios({
      method: 'post',
      url: '/api/v1/content/blog',
      data: {

          'title': this.itemShort, 
          'blogDate': this.formatedDateExport, 
          'blogBody': this.detailedDescription, 
          'blogType': this.blogTypeSelected, 
          'blogTypeParent': this.blogTypeSelectedParent, 
          'blogPicture': this.fileUploadArray, 
          'blogVideo': this.videoLinkArray, 
          'blogAuthor': this.blogAuthor.firstName + ' ' + this.blogAuthor.lastName, 
          'blogAuthorId': this.blogAuthor.id

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      
       this.postData = response.data
      
      })
       bus.$emit('refreshUserPosts')
        this.showModalConfirmation = !this.showModalConfirmation;
  }
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>
<style scoped>
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > input
{
    display: none;
}
.closeButtonCarousel {
    width: 20px;
    background: red;
    padding: 0 4px;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto 0;
    cursor: pointer;
}
.closeButton {
  position: relative;
  width: 20px;
  background: red;
  float: left;
  padding: 0 4px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.videoRemove {

}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
