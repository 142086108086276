<template>
  <div class="hello" v-if="resetComponent">

        <b-navbar class="lastLogin" type="dark" variant="dark">
        <b-nav-form>
          <b-navbar-brand >{{ siteconfig.calendarHideFire ? 'Departments' : 'Stations' }} ({{ filterOriginal.length }})</b-navbar-brand>
          <b-form-select v-model="assignedSearch" class="ml-2" :options="getListAssigned" >
                      <option :value='null'>Assigned Filter</option>
          </b-form-select>
          <b-nav-form v-if="userData.role == 'admin'">
          <b-btn class="mx-2" variant="success" :disabled="editTable" @click="addNewStation = !addNewStation">Add New Station</b-btn>
          <b-btn variant="danger" :disabled="addNewStation" @click="editTable = !editTable, addNewStation = false">{{ editTable == true ? 'Turn Off Editing' : 'Turn On Editing' }}</b-btn>
          </b-nav-form>
        </b-nav-form>
        <b-nav-form class="ml-auto">
        </b-nav-form>
      </b-navbar>
       <b-table stacked="md" v-if="addNewStation" class="bootstrapTable" :fields="fieldsEdit" :items="addNewStationData">
        <template slot="stationName" slot-scope="data">
          <b-form-input type="text" v-model="data.item.stationName" />
        </template>
        <template slot="stationParent" slot-scope="data">
          <b-form-select :options="getListAssignedEdit" v-model="data.item.stationParent" />
        </template>
        <template slot="stationPhone" slot-scope="data">
        <b-form-input type="number" v-model="data.item.stationPhone" />
          </template>
        <template slot="stationAddress" slot-scope="data">
          <b-form-input type="text" v-model="data.item.stationAddress" />
          </template>
          <template slot="stationCity" slot-scope="data">
          <b-form-input  type="text" v-model="data.item.stationCity" />
          </template>
                    <template slot="stationState" slot-scope="data">
          <b-form-select :options="stateList" v-model="data.item.stationState" />
          </template>
                    <template slot="stationZip" slot-scope="data">
          <b-form-input type="number" v-model="data.item.stationZip" />
          </template>
          <template slot="stationPayCode" slot-scope="data">
          <b-form-input  type="text" v-model="data.item.stationPayCode" />
          </template>
          <template slot="Action" slot-scope="data">
            <b-btn size="sm" variant="info" @click="newStationSubmit(data.item)">Save</b-btn>
          </template>  
    </b-table>
    <b-table stacked="md" v-if="stationsData" class="bootstrapTable" :fields="!editTable ? fields : fieldsEdit" :items="filterOriginal">
        <template slot="stationName" slot-scope="data">
          <b-form-input v-if="editTable" type="text" v-model="data.item.stationName" />
          <div  v-if="!editTable">{{ data.item.stationName }}</div>
        </template>
        <template slot="stationParent" slot-scope="data">
          <b-form-select :options="getListAssignedEdit" v-model="data.item.stationParent" />
        </template>
        <template slot="stationPhone" slot-scope="data">
            <b-btn class="m-0 p-0" v-if="data.item.stationPhone && !editTable" variant="link" :href="'tel:' + data.item.stationPhone">{{ data.item.stationPhone }}</b-btn>
        <b-form-input v-if="editTable" type="number" v-model="data.item.stationPhone" />
          </template>
        <template slot="stationAddress" slot-scope="data">
            <b-btn :disabled="!data.item.stationAddress" class="m-0 p-0" v-if="data.item.stationAddress && !editTable" variant="link" target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=' + getMapData(data.item)">{{ data.item.stationAddress }}</b-btn>
          <b-form-input v-if="editTable" type="text" v-model="data.item.stationAddress" />
          </template>
          <template slot="stationCity" slot-scope="data">
           <div  v-if="!editTable">{{ data.item.stationCity }}</div>
          <b-form-input v-if="editTable" type="text" v-model="data.item.stationCity" />
          </template>
          <template slot="stationState" slot-scope="data">
           <div  v-if="!editTable">{{ data.item.stationState }}</div>
          <b-form-select v-if="editTable" :options="stateList" v-model="data.item.stationState" />
          </template>
           <template slot="stationZip" slot-scope="data">
           <div  v-if="!editTable">{{ data.item.stationZip }}</div>
          <b-form-input v-if="editTable" type="number" v-model="data.item.stationZip" />
          </template>
          <template slot="stationPayCode" slot-scope="data">
           <div  v-if="!editTable">{{ data.item.stationPayCode }}</div>
          <b-form-input v-if="editTable" type="text" v-model="data.item.stationPayCode" />
          </template>
          <template slot="Action" slot-scope="data">
            <b-btn size="sm" variant="danger" v-if="userData.role == 'admin' && !checkForChanges(data.item)" @click="removeStation(data.item)">Delete</b-btn>
            <b-btn size="sm" variant="info" v-if="checkForChanges(data.item)" @click="submitStationEdit(data.item)">Save</b-btn>
          </template>  
    </b-table>
  </div>
</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from 'moment'
import { mapState } from 'vuex'
import _ from 'lodash'
import axios from 'axios'
export default {
  name: 'station-admin', 
  props: [],
  components:{
  },
  data () {
    return {
      assignedSearch: null,
      stationSearch: '',
      resetComponent: true,
      typeSearch: '',
      editTable: false,
      sortOrder: 'desc',
      previousLoginAttempt: null,
      stateList: ["AK","AL","AR","AZ","CA","CO","CT","DC","DE","FL","GA","GU","HI","IA","ID", "IL","IN","KS","KY","LA","MA","MD","ME","MH","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY", "OH","OK","OR","PA","PR","PW","RI","SC","SD","TN","TX","UT","VA","VI","VT","WA","WI","WV","WY"],
      addNewStation: false,
      addNewStationData: [
        {
          stationName: '',
          stationParent: null,
          stationPhone: '',
          stationAddress: '',
          stationCity: '',
          stationState: '',
          stationZip: '',
          stationPayCode: null,
        }
      ],
      fields: [
        'stationName',
        {key: 'stationPhone', label: 'Phone'},
        {key: 'stationAddress', label: 'Address'},
        {key: 'stationCity', label: 'City'},
        {key: 'stationState', label: 'State'},
        {key: 'stationZip', label: 'Zip'},
      ],
      fieldsEdit: [
        'stationName',
        'stationParent',
        {key: 'stationPhone', label: 'Phone'},
        {key: 'stationAddress', label: 'Address'},
        {key: 'stationCity', label: 'City'},
        {key: 'stationState', label: 'State'},
        {key: 'stationZip', label: 'Zip'},
        {key: 'stationPayCode', label: "Code"},
        'Action'
      ],
    } 
  },
  created(){
          var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'station', headers: headers, expire: true}
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
  },
  computed: {
    ...mapState([
      'deptContactData',
      'userData',
      'stationsData',
      'getSessionData',
      'siteconfig'
    ]),
    getListStations(){
     return []
    },
    getListAssigned(){
      var array = [... new Set(this.stationsData.map(p => p.stationParent))]
      var index = array.indexOf(null)
      if(index >= 0){
        array.splice(index, 1)
      }
      array.push('Parents Only')
      return _.sortBy(array)
    },
    getListAssignedEdit(){
      var array = this.stationsData.filter(p => {
              if(!p.stationParent){
                return p
              }
      })
      var holdArray = []
      for(var i in array){
        holdArray.push({text: array[i].stationName, value: array[i].stationName})
      }
      holdArray.push({text: 'Is Parent', value: null})
      return _.orderBy(holdArray, ['value'], ['desc'])
    },
    getListType(){
      var array = [... new Set(this.filterApparatusData.map(p => p.apparatusVehicleType))]
      return _.sortBy(array)
    },
    filterOriginal(){
      if(this.assignedSearch != 'Parents Only'){
      var array = this.originalStationsData.filter(p => {
        if(this.assignedSearch){
        if(p.stationParent){        
          return p.stationParent.match(this.assignedSearch) }
        } else {
          return p
        }
      })
      return array
      } else {
        return this.originalStationsData.filter(p => {
          return (!p.stationParent)
        })
      }
    },
    originalStationsData(){
      var array = _.cloneDeep(this.stationsData)
      var holdArray = []
      var parents = array.filter(p => {
        if(!p.stationParent){
          return p
        }
      })
      parents = _.sortBy(parents)
      for(var i in parents){
        var obj = parents[i]
        obj['_rowVariant'] = 'info'
        holdArray.push(obj)
        var child = array.filter(p => {
          if(p.stationParent){
          return p.stationParent == parents[i].stationName
          }
        })
        child = _.sortBy(child)
        for(var p in child){
          holdArray.push(child[p])
        }
      }
     var arrayNeg = []
     for(var i in holdArray){
       if(holdArray[i].stationPhone || holdArray[i].stationAddress){
         arrayNeg.push(holdArray[i])
       }
     }
     return holdArray 
    },


  },
  watcher: {


  },
  methods: {
    getMapData(data){
      var query = ''
      if(data.stationAddress){
        query = query + data.stationAddress
      }
      if(data.stationCity){
        query = query + ' + ' + data.stationCity
      }
      if(data.stationState){
        query = query + ' + ' + data.stationState
      }
      if(data.stationZip){
        query = query + ' + ' + data.stationZip
      }
      return query
    },
    checkForChanges(data){
      var saved = this.stationsData.find(p => p.id == data.id)
      if(saved){
        if(saved.stationName != data.stationName ||
           saved.stationParent != data.stationParent ||
           saved.stationPhone != data.stationPhone ||
           saved.stationAddress != data.stationAddress ||
           saved.stationCity != data.stationCity ||
           saved.stationState != data.stationState ||
           saved.stationZip != data.stationZip ||
           saved.stationPayCode != data.stationPayCode
        ){
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
      newStationSubmit(data){
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'station', headers: headers, expire: true}
     axios({
      method: 'post',
      url: '/api/v1/taxonomy/station/',
      data: data,
      headers: headers.headers,
    }).then( response => {
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
              this.addNewStationData = [
        {
          stationName: '',
          stationParent: data.stationParent,
          stationPhone: '',
          stationAddress: '',
          stationCity: data.stationCity,
          stationState: data.stationState,
          stationZip: '',
          stationPayCode: null
        }
      ],
           this.resetComponent = false
      this.$nextTick(function(){
        this.resetComponent = true
      })
      
    })
  },
    submitStationEdit(data){
     delete data._rowVariant
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'station', headers: headers, expire: true}
     axios({
      method: 'patch',
      url: '/api/v1/taxonomy/station/' + data.id,
      data: data,
      headers: headers.headers,
    }).then( response => {
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
      this.resetComponent = false
      this.$nextTick(function(){
        this.resetComponent = true
      })

      
    })
  },
     removeStation(data){
    var result = confirm('Are You Sure You Want to Delete this Station?')
      if (result) {
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'station', headers: headers, expire: true}
   axios({
     method: 'delete',
     url: '/api/v1/taxonomy/station/' + data.id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)

      
    })
  }
   }
  }

}
</script>
<style>


</style>
<style scoped>
@media screen and (max-width: 1200px){
.userContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px){
.userContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px){
.userContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px){
.userContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
.activeOnline {
      font-size: small;
    background-color: green;
    border-radius: 5px;
    color: white;
    padding: 2px 4px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.floatRight {
  float: right;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer{
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {

  border-radius: 5px;
}
.A-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.B-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.C-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Day-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.A-shift{
  background: #e2070d;
  background: -moz-linear-gradient(left, #e2070d 0%, #e2070d 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift{
  background: #201B9F;
  background: -moz-linear-gradient(left, #201B9F 0%, #201B9F 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
.C-shift{
  background: #0FB906;
  background: -moz-linear-gradient(left, #0FB906 0%, #0FB906 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
      border-radius: 20px 20px 0 0;
}
.Day-shift{
  background: #E4CB07;
  background: -moz-linear-gradient(left, #E4CB07 0%, #E4CB07 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
@media screen and (max-width: 850px){
   .phoneIcons {margin: 20px 5px;
      height: 40px;
      width: 40px;
      border: 0;
      border-radius: 0;
      background: none;
      }
}
@media screen and (max-width: 750px){
.phoneIcons {
    margin: 20px 2px;
    height: 35px;
    width: 35px;
    border: 0;
    border-radius: 0;
    background: none;
}
}
</style>
