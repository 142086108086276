<template>
    <b-container fluid>
  <b-card no-body>
    <b-tabs card>
    <b-tab title="Export">
  <b-navbar type="dark" variant="dark">
           <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Options:</b-navbar-brand>
         <b-form-select v-model="currentYear" :options="yearsDropDown" />
         <b-navbar-brand class="bootstrapTable ml-2">
           <b-form-select v-model="cycleType" :options="cycleTypeOptions" @change="getCurrentPayPeriod" />
         </b-navbar-brand>
         <b-form-select v-if="cycleType == 'Pay Period'" v-model="currentView" :options="previousPayrolls" />
         <b-form-select v-if="cycleType == 'Work Cycle'" v-model="currentView" :options="previousWorkCycles" />
         <b-form-select v-if="cycleType == 'Work Week'" v-model="currentView" :options="previousWorkWeeks" />
         <b-form-select v-if="cycleType == 'Kelly Cycle'" v-model="currentView" :options="previousKellyCycles" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectStart" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectEnd" />
         <b-btn variant="info" v-if="cycleType == 'Select Range'" @click="runRangeDate">Run</b-btn>
       <b-pagination v-if="false" class="mb-0 mx-3" size="md" :total-rows="adjustForAccurals.length" v-model="currentPage" :per-page="10" />
      
        <b-form-input type="date" v-model="payrollBankRecoDate" @input="updateBankDate" v-if="false" /> 
    <download-excel  class="ml-2"
    v-if="fixDataExcel.length > 0 && siteconfig.deptUrlSub != 'scfd'"
    :name="cycleType + ' ' + currentView.start + 'till ' + currentView.end"
    :data="fixDataExcel">
    <b-btn variant="primary">Full Excel</b-btn>
    </download-excel>
    <b-btn variant="primary" class="ml-2" v-if="siteconfig.deptUrlSub == 'scfd' && fixDataExcel.length > 0" @click="downloadAsCsvFull">Full CSV</b-btn>
   <download-excel 
    class="ml-2"
    v-if="fixDataExcelCombined.length > 0"
    :name="cycleType + ' Combined ' + currentView.start + 'till ' + currentView.end"
    :data="fixDataExcelCombined">
    <b-btn variant="success">Combined Excel</b-btn>
    </download-excel>
    <download-excel
    class="ml-2"
    v-if="fixDataExcelCustom.length > 0 && false"
    :name="cycleType + ' Custom Export ' + currentView.start + 'till ' + currentView.end"
    :data="fixDataExcelCustom">
    <b-btn variant="info">Custom Excel</b-btn>
    </download-excel>
    <b-btn variant="primary" class="ml-2" v-if="siteconfig.payroll.customExport && fixDataExcelCustom.length > 0" @click="downloadAsCsv">Custom CSV</b-btn>
    <b-btn variant="info" class="ml-2" :disabled="disableGenerateReport" @click="generateNewReport(rosterProfilesFilterdByPeriodFilter)">Generate Report</b-btn>
      </b-nav-form>
      <b-btn class="ml-2" variant="primary" @click="showFilter = !showFilter">Filter</b-btn>
    </b-navbar>
    <b-row>
      <b-col cols="3" v-if="showFilter" align="left">
          <b-form-group label="Select Export Codes to Hide" class="mt-2">
            <b-form-checkbox-group
              v-model="selectedFilter"
              :options="filterProfileModOptions"
              name="flavour-2a"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-checkbox v-model="selectAllBoo" @change="selectAllEventMods($event, filterProfileModOptions)">Hide All Export Codes?</b-form-checkbox>
          <b-form-checkbox v-model="hideUsersWithNone">Hide Users w/ No Mods?</b-form-checkbox>

          <b-form-group label="Filter by Shift" class="mt-2">
            <b-form-checkbox-group
              v-model="selectedShift"
              :options="filterProfileShiftOptions"
              name="flavour-2a"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
      </b-col>
      <b-col>
    <b-container v-if="currentView">
      <h6  v-if="cycleType == 'Select Range'"  class="text-danger">Be Advised if your date range for "Select Range" is extensive, there will be a delay as the data is loaded</h6>
      <b-card v-for="user in rosterProfilesFilterdByPeriodFilter" :key="user.userid"  :title="user.lastName + ', ' + user.firstName + ' (' + user.employeeId + ')'">
        <b-table :items="user.shifts" :fields="['payrollModDatetime', 'payrollModExport', 'payrollModCode', 'payrollModType', 'payrollModHours']">
          <template slot="payrollModDatetime" slot-scope="data">
            {{ getDateTime(data.item.payrollModDatetime) }}
            </template>

          </b-table>
        </b-card>
      </b-container>
      </b-col>
      </b-row>
    </b-tab>
    <b-tab title="Previous Reports" @click="getPreviousPayrollExports">
         <b-table :items="payrollReports" :fields="['createdAt', 'title', 'exportAuthor', 'requestVerification', 'view']">
           <template slot="createdAt" slot-scope="data">
             {{ getDateTime(data.item.createdAt) }}
             </template>
             <template slot="view" slot-scope="data">
               <b-btn variant="link" @click="viewPreviousReportData(data.item.id)">View</b-btn>
             </template>
           </b-table>
      </b-tab>
      <template v-for="report in viewReports">
        <b-tab :key="report.id" active>
              <template slot="title">
                 <b-badge variant="success" v-if="report.newReport">Unsaved</b-badge> {{ report.title }}  <b-badge variant="danger" @click="removeReport(report.id)">X</b-badge>
            </template>
            <exportReport @refreshExportReports="refreshExportReports($event)" :exportReport="report" :payrollBanksData="payrollBanksData" :payrollCodesData="payrollCodesData" :payrollAccuralAssignedData="payrollAccuralAssignedData" :payrollAccuralData="payrollAccuralData" :rosterProfiles="rosterProfiles"  :rosterData="rosterData" />
          </b-tab>
        </template>
      </b-tabs>
      </b-card>
      </b-container>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import Papa from 'papaparse'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import JsonExcel from 'vue-json-excel';
import Blob from 'blob'
import customJs from '../../../../customjs.js';
import exportReport from './payrollExport/payrollExportReport.vue'
export default {
  components: {
    multiselect,
    userCard,
    'download-excel' : JsonExcel,
    exportReport
  },
  
  name: 'roster-admin',
  props:  ['payrollAccuralData', 'payrollBanksData', 'rosterProfiles', 'rosterData', 'payrollAccuralAssignedData', 'payrollCodesData'],
  data () {
    return {
      currentPage: 0,
      searchData: '',
      payrollBankRecoDate: '',
      currentView: null,
      showFilter: false,
      hideUsersWithNone: false,
      selectAllBoo: false,
      cycleType: 'Pay Period',
      currentYear: '',
      selectedFilter: [],
      selectedShift: [],
      payrollRecoMods: [],
      payrollReports: [],
      viewReports: [],
      modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'}, 
     ],
      selectStart: '',
      selectEnd: '',
      disableGenerateReport: true,
      createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      ],
    }
  },
  created(){
    this.getCurrentPayPeriod()
    this.selectStart = moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD')
    this.selectEnd = moment().startOf('month').format('YYYY-MM-DD')
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'fullDeptContactData',
          'userData',
          'siteconfig'
        ]),
        cycleTypeOptions(){
        var array = ['Pay Period', 'Work Cycle', 'Work Week']
        if(this.siteconfig.payroll.kellyCycleDate){
          array.push('Kelly Cycle')
        }
        array.push('Select Range')
        return array
        },
        fixDataExcel(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
          var hold = []
          if(this.siteconfig.deptUrlSub != 'scfd'){
          for(var i in array){
            var obj = { name: array[i].lastName + ', ' + array[i].firstName, employeeId: array[i].employeeId,  entryDate: '', entryCode: '', entryName: '', entryType: '', entryHours: ''}
            hold.push(obj)
            var shifts = array[i].shifts
            var totalHours = 0
            for(var s in shifts){
              var sobj = { name: '', employeeId: array[i].employeeId,  entryDate: shifts[s].payrollModDatetime, entryCode: shifts[s].payrollModExport, entryName: shifts[s].payrollModCode, entryType: shifts[s].payrollModType, entryHours: shifts[s].payrollModHours }
              totalHours = totalHours + Number.parseFloat(shifts[s].payrollModHours)
              hold.push(sobj)
            }
            var obj = { name: array[i].lastName + ', ' + array[i].firstName, employeeId: array[i].employeeId,  entryDate: '', entryCode: '', entryName: '', entryType: 'Total', entryHours: totalHours}
            hold.push(obj)
          }
          } else {
            for(var i in array){
            var obj = { name: array[i].lastName + ', ' + array[i].firstName, employeeId: array[i].employeeId,  Start_DTS: '', End_DTS: '', entryCode: '', entryName: '', entryType: '', entryHours: ''}
            hold.push(obj)
            var shifts = array[i].shifts
            for(var s in shifts){
              var sobj = { name: '', employeeId: array[i].employeeId,  Start_DTS: moment(shifts[s].payrollModDatetime).format('MM/DD/YYYY hh:mm:ss A').toString(), End_DTS: moment(shifts[s].payrollModDatetime).add(shifts[s].payrollModHours, 'hours').format('MM/DD/YYYY hh:mm:ss A').toString(), entryCode: shifts[s].payrollModExport, entryName: shifts[s].payrollModCode, entryType: shifts[s].payrollModType, entryHours: shifts[s].payrollModHours }
              hold.push(sobj)
            }

          }
          }

          return hold
        },
        fixDataExcelCombined(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
          var hold = []
          for(var i in array){
            var codes = [...new Set(array[i].shifts.map(p => p.payrollModExport))]
            if(array[i].lastName == 'Bell'){
              console.log('Codes', codes)
              console.log('Shifts', shifts)
            }

            var shifts = array[i].shifts
            for(var c in codes){
              var filter = shifts.filter(p => p.payrollModExport == codes[c])
              var totalHours = 0
              for(var f in filter){
                totalHours = totalHours + Number.parseFloat(filter[f].payrollModHours)
              }
              var sobj = { employeeId: array[i].employeeId, 
                           firstName: array[i].firstName, 
                           lastName: array[i].lastName, 
                           startDate: moment(this.currentView.start).format('MM/DD/YYYY'), 
                           endDate: moment(this.currentView.end).format('MM/DD/YYYY'), 
                           entryCode: codes[c], 
                           //entryName: shifts[s].payrollModCode, 
                           //entryType: shifts[s].payrollModType, 
                           entryHours: totalHours }
              hold.push(sobj)
            }

          }
          return hold
        },
        fixDataExcelCustom(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
          if(this.siteconfig.deptUrlSub == 'lbkfr' || this.siteconfig.deptUrlSub == 'efd' || this.siteconfig.deptUrlSub == 'lbkpd'){
            var hold = customJs.custom_payroll_lbkfr(array)
          } else if(this.siteconfig.deptUrlSub == 'scfd') {
             var hold = customJs.custom_payroll_scfd(array)
          } else if(this.siteconfig.deptUrlSub == 'gfr'){
           // console.log('CusstomEport')
            var hold = customJs.custom_payroll_gfr(array, this.currentView.start)

          } else {
            var hold = customJs.custom_payroll(array)
          }
          if(hold){
          return hold
          } else {
            return []
          }
        },
        kellyDayObject(){
            // console.log('KELLY PATTERN')
              if(this.siteconfig.payroll.kellyDayPattern){
              var shifts = this.siteconfig.payroll.shifts
              var kellyPattern = this.siteconfig.payroll.kellyDayPattern
              var build = []
              var kellyArray = []
              for(var k in kellyPattern){
                  for(var s in shifts){
                  build.push({shift: shifts[s].shift, kelly: this.siteconfig.payroll.kellyDayPattern.indexOf(kellyPattern[k])})
                }
              }
            // console.log(this.currentView.start)
              var today = moment(this.currentView.start, 'YYYY-MM-DD').clone()
              var startDate = today.clone()
            // console.log(today)
              var subDays = 0
            // console.log('START WHILE')
              while (true) {
                var days = moment(startDate).diff(this.siteconfig.payroll.cycleStartDate, 'days') // 21
                var mathhold = (days / 21)
                if(mathhold == Math.floor(mathhold)){
              //   console.log('BREAK')
                  break
                }
                startDate = startDate.subtract(1, 'day')
                subDays++;
              } 
              var start = moment(this.currentView.start, 'YYYY-MM-DD').clone().subtract(subDays, 'days')
              //console.log('START')
              //console.log(start)
              var arrayI = 0
              // console.log(start)
              while (true) {
                var obj = _.cloneDeep(build[arrayI])
              //  console.log(obj)
                if(!obj){
                  arrayI = 0
                  obj = build[arrayI]
                }
                var date = start.clone().format('YYYY-MM-DD')
                // console.log(date)
                obj['date'] = date
                kellyArray.push(obj)
                if(moment(start).isSameOrAfter(moment(this.currentView.end))){
                  // console.log('BREAK WHILE')
                  break
                }
                start = start.clone().add(1, 'day')
                arrayI++;

              }
              // var patternRun = moment(today.clone()).diff(start, 'days')
              // console.log(patternRun)
              // var index = build[patternRun]
              // console.log(build) 
              return kellyArray
              } return null
        },
        previousPayrolls(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
              if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*2, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(14, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(14, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(14, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            }
          } return []
        },
        previousWorkWeeks(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
          } return []
        },
        previousKellyCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'd').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                 //  console.log(array)
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
        previousWorkCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.cycle)
                   var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle - 1, 'days').format("YYYY-MM-DD")
                   if(payroll.workCycleMainExport){
                     endDate = moment(endDate, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD")
                   }
                   array.push({start: moment(startDate).add(payroll.cycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.cycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      if(this.siteconfig.deptUrlSub == 'scfd'){
                        var endDay = moment(endDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      } else {
                      var endDay = moment(endDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      }
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
        yearsDropDown(){
           var years = []
           var payroll = this.siteconfig.payroll
           var today = moment().format('YYYY')
           var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD").format('YYYY')
           var diff = moment(today).diff(presetVarDate, 'years')
           for(var i = 0; i < diff; i++){
              if(Number.parseInt(presetVarDate) + i < Number.parseInt(today)){
                years.push(Number.parseInt(presetVarDate) + i)
              }
           }
           years.push(Number.parseInt(today))
           var hold = []
           for(var i in years){
             hold.push({value: years[i].toString(), text: years[i]})
           }
           this.currentYear = Number.parseInt(today)
           return _.orderBy(hold, ['text'], ['desc'])
        },
        filterProfileShiftOptions(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriod)
          var options = [...new Set(array.map(item => item.shiftAssignment))]
          return _.sortBy(options)
        },
        filterProfileModOptions(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriod)
          var hold = array.map(item => item.shifts)
          var merged = [].concat.apply([], hold);
          var options = [...new Set(merged.map(p => p.payrollModCode))]
          return _.sortBy(options)
        },
        rosterProfilesFilterdByPeriodFilter(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriod)
          if(this.selectedShift && this.selectedShift.length > 0){
            array = array.filter(p => {
              return this.selectedShift.indexOf(p.shiftAssignment) >= 0
            })
          }
          if(this.selectedFilter && this.selectedFilter.length > 0){
              var filter = []
              for(var p in array){
                var obj = array[p]
                obj.shifts = array[p].shifts.filter(x => {
                  var index = this.selectedFilter.indexOf(x.payrollModCode)
                  if(index < 0){
                    return x
                  }
              })
        if(!this.hideUsersWithNone){
              filter.push(obj)
              } else if (this.hideUsersWithNone && obj.shifts && obj.shifts.length > 0){
                filter.push(obj)
              }
            
            }
            return filter
          }
          return array
        },
        rosterProfilesFilterdByPeriod(){
          var array = _.cloneDeep(this.rosterProfiles)
          var rosters = array.filter(p => {
            var start = moment(p.rosterAssignmentStart).isBefore(this.currentView.end)
            var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.currentView.start) : p.rosterAssignmentEnd == null ? true : false
            if(start && end){
                return p
              }
          })
          var users = [...new Set(rosters.map(p => p.userId))]
          var hold = users.map(id => {
            var obj = this.deptContactData.find(p => p.id == id)
            if(obj){
            obj['userid'] = id
            var rosterFil = rosters.filter(p => p.userId == id)
            obj['shifts'] = this.userProfileBuildShifts(rosterFil)
            return obj
            } else {
              var obj = this.fullDeptContactData.find(p => p.id == id)
              if(obj){
                obj['userid'] = id
                var rosterFil = rosters.filter(p => p.userId == id)
                obj['shifts'] = this.userProfileBuildShifts(rosterFil)
                return obj
              }
            }
          })
          hold = hold.filter(p => {
              if(p){
                return p
              }
          })
          var mods = hold.map(item => {
            var obj = item
            var paymods = this.payrollRecoMods.filter(p => p.payrollModTargetUserId == item.id || p.payrollModExchangeUserId == item.id)
            if(paymods){
              obj.shifts = this.sortModifications(item.shifts, paymods, item.userid, item.employeeId)
              return obj
            } else {
              return obj
            }
          })
        // console.log(mods)
          return _.orderBy(mods, ['lastName'], ['dsc'])
        },

       
  },
  mounted(){

  },
  watch: {
    currentView(newData){
       console.log(newData)
       this.getPayrollModifiersInRange(moment(newData.start + ' ' + this.siteconfig.payroll.shiftStartTime, "YYYY-MM-DD HH:mm:ss").toISOString(), moment(newData.end + ' ' + this.siteconfig.payroll.shiftStartTime, "YYYY-MM-DD HH:mm:ss").toISOString())
    }

  },
  methods: {
    runRangeDate(){
      this.currentView = {start: this.selectStart, end: this.selectEnd }
    },
    selectAllEventMods(boo, data){
      if(boo){
        this.selectedFilter = data
      } else {
        this.selectedFilter = []
      }
    },
    refreshExportReports(id){
      this.removeReport(id)
      this.getPreviousPayrollExports()
    },
    removeReport(id){
      var index = this.viewReports.map(p => p.id).indexOf(id)
      this.viewReports.splice(index, 1)
    },
    viewPreviousReportData(id){
        axios({
              method: 'get',
              url: '/api/v1/staffing/payrollexport/' + id,
              headers: {
                  'Authorization': this.getSessionData,
                  'Content-Type' : 'application/json',
                }
            }).then( response => {
              if(response.data && response.data[0]){
          this.viewReports.push(response.data[0])
              }
            })
    },
    generateNewReport(data){
        var report = {}
        var payrollData = data.map(item => {
              var rdata = {}
              rdata['userid'] = item.id
              rdata['shifts'] = item.shifts
              rdata['verification'] = false
              return rdata
        })
        report['title'] = this.cycleType + ' ' + this.currentView.start + ' till ' + this.currentView.end
        report['exportAuthor'] = this.userData.firstName + ' ' + this.userData.lastName
        report['exportAuthorId'] = this.userData.id
        report['requestVerification'] = false
        report['payrollData'] = payrollData
        report['newReport'] = true
        this.viewReports.push(report)
      },
      downloadAsCsvFull(){
       var fileExtension = this.siteconfig.payroll.customExportFileType ? this.siteconfig.payroll.customExportFileType : '.csv'
       var title = this.cycleType + ' Custom Export ' + this.currentView.start + ' till ' + this.currentView.end + fileExtension
     //  console.log(title)
       var header = this.siteconfig.payroll.customExportHeader ? true : false
       var data = Papa.unparse(this.fixDataExcel, {header: header})
      // console.log(data)
       const blob = new Blob([data], { type: 'text/csv' })
      // console.log(data)
       const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", title);
        link.click();
      },
      downloadAsCsv(){
       var fileExtension = this.siteconfig.payroll.customExportFileType ? this.siteconfig.payroll.customExportFileType : '.csv'
       var title = this.cycleType + ' Custom Export ' + this.currentView.start + ' till ' + this.currentView.end + fileExtension
     //  console.log(title)
       var header = this.siteconfig.payroll.customExportHeader ? true : false
       var data = Papa.unparse(this.fixDataExcelCustom, {header: header})
      // console.log(data)
       const blob = new Blob([data], { type: 'text/csv' })
      // console.log(data)
       const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", title);
        link.click();
      },
      getDateTime(data){
        if(data){
          return moment(data).format('MM/DD/YYYY HH:mm:ss')
        } return 'Null Date'
      },
      sortModifications(reg, mods, user, employeeId){
      //  console.log(user)
        var array = reg
        var modsA = []
        var adj = []
        for(var i in mods){
          var obj = {}
          obj = _.cloneDeep(mods[i])
          obj['payrollModCodeDb'] = mods[i].payrollModCode
          var payrollMod = this.payrollCodesData.find(p => p.id == mods[i].payrollModCode)
          var exchange = mods[i].payrollModExchangeUserId == user
          var exOn = ''
          if(payrollMod && payrollMod.payrollCodeCategory && payrollMod.payrollCodeCategory == 'overtime availability'){
            var code = payrollMod.payrollCode
            var index = this.selectedFilter.indexOf(code)
            if(index < 0){
              this.selectedFilter.push(code)
            }
          }
          if(exchange){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXON')
          }
          if (mods[i].payrollModType == 'exchange' && mods[i].payrollModExchangeUserId != user){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXOFF')
          }
          obj['startTime'] == mods[i].payrollModDatetime
          obj['endTime'] = moment(mods[i].payrollModDatetime).add(mods[i].payrollModHours, 'hours').toISOString()
          obj['payrollModExport'] = payrollMod && payrollMod.payrollCodeExport ? payrollMod.payrollCodeExport : exOn && exOn.payrollCodeExport ? exOn.payrollCodeExport : 'No Export'
          obj['payrollModCode'] =  payrollMod && payrollMod.payrollCode ? payrollMod.payrollCode : exOn && exOn.payrollCode ? exOn.payrollCode : 'No Code'
          if(this.createVacancy.indexOf(mods[i].payrollModType) != -1 || obj.payrollModCode == 'EXOFF'){
            adj.push(obj)
          } else {
          modsA.push(obj)
          }
        }
        var fixed =  this.checkForUserMods(array, adj)
        for(var m in modsA){
          fixed.push(modsA[m])
        }
        if(this.kellyDayObject){
          var kellyDays = array.filter(p => p.payrollModExport == "KD")
          for(var k in kellyDays){
            var index = fixed.filter(p => p.payrollModExport == "KD").map(p => p.payrollModDatetime).indexOf(kellyDays[k].payrollModDatetime)
            if(index < 0){
              fixed.push(kellyDays[k])
            }
          }
        }
        if(employeeId == '109581' && false){
          console.log('BARKEMA')
          console.log(fixed)
          console.log(kellyDays)
        }
      //  console.log(fixed)
        return _.orderBy(fixed, ['payrollModDatetime'], ['asc'])
      },
      getFixedSwap(array, mods){
       if(false){
         console.log('Get Fixed Swap')
         console.log(array)
         console.log(mods)
        }
       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
          var mod = mods.filter(p => moment(p.payrollModDatetime).isSameOrAfter(array[a].payrollModDatetime) && moment(p.endTime).isSameOrBefore(array[a].endTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].payrollModDatetime).isSame(array[a].payrollModDatetime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].payrollModDatetime).isAfter(array[a].payrollModDatetime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.payrollModDatetime = array[a].payrollModDatetime
                  obj.endTime = mod[m].payrollModDatetime
                  obj['payrollModHours'] = moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].payrollModDatetime).isSame(array[a].payrollModDatetime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.payrollModDatetime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  obj['payrollModHours'] =  moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].payrollModDatetime).isAfter(array[a].payrollModDatetime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.payrollModDatetime = array[a].payrollModDatetime
                  obj.endTime = mod[m].payrollModDatetime
                  obj['payrollModHours'] =  moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  objTwo.payrollModDatetime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  objTwo['payrollModHours'] =  moment(objTwo.endTime).diff(objTwo.payrollModDatetime, 'hours', true)
                  array.push(objTwo)
                  array[a] = obj
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
      checkForUserMods(dates, adjust){
          var array = []
          var allMods = _.cloneDeep(adjust)
          for(var u in dates){
              var mods = adjust.filter(p => {
              return moment(p.payrollModDatetime).isBefore(dates[u].endTime) && moment(dates[u].payrollModDatetime).isBefore(p.endTime)
              })
              mods = _.orderBy(mods, ['payrollModDatetime'], ['asc'])
              if(mods.length > 0){
                var base = _.cloneDeep(dates[u])
                var obj = [base]
                var basesplit = this.getFixedSwap(obj, mods)
              if(basesplit.length > 0){
                 // console.log('BASESPLIT')
                 // console.log(basesplit)
                  for(var b in basesplit){
                    array.push(basesplit[b])
                  }
                }
              } else {
              array.push(dates[u])
              } 
        }  
        if(allMods.length > 0){
         var nonSwap = allMods //.filter(p => p.payrollModType != 'exchange')
         if(nonSwap.length > 0){
           for(var i in nonSwap){
             array.push(nonSwap[i])
           }
         }
        }    
        return array
      },
      getCurrentPayPeriod(){
        if(this.cycleType == 'Pay Period'){
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14)
                   var endDate = moment(presetVarDate).add(end*2, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(14, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today, 'YYYY-MM-DD').diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else {
                    return false
                }
        } else if (this.cycleType == 'Work Week') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else if (this.cycleType == 'Kelly Cycle') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                var dateDiffCal = moment(today).diff(presetVarDate, "days")
                var end = Math.floor(dateDiffCal/payroll.cycle)
                var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle -1, 'days').format("YYYY-MM-DD")
                if(payroll.workCycleMainExport){
                  endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                }
                this.currentView = {start: startDate, end: endDate}
        //        console.log(this.currentView)
        }
                
      }, 
    userProfileBuildShifts(data){ 
    var events = []
    for(var i in data){
      if(data[i].scheduledShift != 'Daily'){
        var indexColor = this.siteconfig.payroll.shifts.map(p => p.shift).indexOf(data[i].scheduledShift)
        if(indexColor != -1){
          var holdcolor = this.siteconfig.payroll.shifts[indexColor].bootstrap
        } else {
          var holdcolor = 'default'
        }
        var startDate = moment(data[i].rosterAssignmentStart).isBefore(this.currentView.start) ? this.currentView.start : moment(data[i].rosterAssignmentStart).format("YYYY-MM-DD")
        var shiftStartDate = this.findFirstAvailableShifts(startDate, data[i].scheduledShift, data[i].userId)
        if(shiftStartDate.isBefore(data[i].scheduledShift)){
          shiftStartDate = shiftStartDate.add(this.siteconfig.payroll.shiftRotation, 'd')
        }
        var endDate = data[i].rosterAssignmentEnd && moment(data[i].rosterAssignmentEnd).isBefore(this.currentView.end) ? data[i].rosterAssignmentEnd : this.currentView.end
        var lengthCalc = moment(endDate).diff(shiftStartDate, 'days')
        var length = Math.floor(lengthCalc/this.siteconfig.payroll.shiftRotation)
        if(length == 0){
        //  console.log(endDate)
          length = 1
        }
        for(var x = 0; x < length; x++){
          var adv = x * this.siteconfig.payroll.shiftRotation
          var date = moment(shiftStartDate).add(adv, 'd').format('YYYY-MM-DD')
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var obj = {}
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty')
          if(this.kellyDayObject){
            var kellyFilter = this.kellyDayObject.find(p => {
                  return moment(p.date).isSame(moment(date), 'day') && p.shift == data[i].scheduledShift && p.kelly == data[i].scheduledKellyCycleNumber
            })
            if(kellyFilter){
        /**    console.log('DATE')
            console.log(date)
            console.log(data[i]) */
            var kellyDay = true
              payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'KD')
            } else {
             var kellyDay = false
            }

          }
          //console.log('payrollBuild', payrollCode)
          if(payrollCode.payrollCodeExport == '*' && data[i].userId){
           // console.log('Wild Card')
            payrollCode = customJs.custom_export_gfr(this.fullDeptContactData.find(p => p.id == data[i].userId), _.cloneDeep(payrollCode))
          }
          // console.log(payrollCode)
          obj['color'] = 'text-' + holdcolor
          obj['payrollModType'] = kellyDay ? 'Kelly Day' : 'normal',
          obj['payrollModCode'] = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'No Export',
          obj['payrollModExport'] = payrollCode && payrollCode.payrollCodeExport ? payrollCode.payrollCodeExport : 'No Export',
          obj['payrollModDatetime'] = startTime, 
          obj['endTime'] = endTime,
          obj['payrollModHours'] = data[i].rosterShiftLength
            if(moment(startTime).isSameOrBefore(this.currentView.end)){
            events.push(obj)
            }
        if(x == length - 1){
          var holdCheck = moment(endDate, 'YYYY-MM-DD').diff(moment(endTime).format('YYYY-MM-DD'), 'days')
          if(holdCheck >= this.siteconfig.payroll.shiftRotation){
          var adv = (x + 1) * this.siteconfig.payroll.shiftRotation
          var date = moment(shiftStartDate).add(adv, 'd').format('YYYY-MM-DD')
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var newobj = {}
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty')
          if(payrollCode.payrollCodeExport == '*' && data[i].userId){
           // console.log('Wild Card')
            payrollCode = customJs.custom_export_gfr(this.fullDeptContactData.find(p => p.id == data[i].userId), _.cloneDeep(payrollCode))
          }
          if(this.kellyDayObject){
            var kellyFilter = this.kellyDayObject.find(p => {
                  return moment(p.date).isSame(moment(date), 'day') && p.shift == data[i].scheduledShift && p.kelly == data[i].scheduledKellyCycleNumber
            })
            if(kellyFilter){
        /**    console.log('DATE')
            console.log(date)
            console.log(data[i]) */
            var kellyDay = true
              payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'KD')
            } else {
             var kellyDay = false
            }

          }
          newobj['color'] = 'text-' + holdcolor
          newobj['payrollModType'] = 'normal',
          newobj['payrollModCode'] = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'No Export',
          newobj['payrollModExport'] = payrollCode && payrollCode.payrollCodeExport ? payrollCode.payrollCodeExport : 'No Export',
          newobj['payrollModDatetime'] = startTime, 
          newobj['endTime'] = endTime
          newobj['payrollModHours'] = data[i].rosterShiftLength
              if(moment(startTime).isSameOrBefore(this.currentView.end)){
        events.push(newobj)        
              }
          }
        }
        }
        } else if (data[i].scheduledShift == 'Daily') {
          
          var indexColor = this.siteconfig.payroll.shifts.map(p => p.shift).indexOf(data[i].scheduledShift)
         if(indexColor != -1){
          var holdcolor = this.siteconfig.payroll.shifts[indexColor].bootstrap
         } else {
          var holdcolor = 'default'
        }
        var startDate = moment(data[i].rosterAssignmentStart).isBefore(this.currentView.start) ? this.currentView.start : moment(data[i].rosterAssignmentStart).format("YYYY-MM-DD")
        var endDate = data[i].rosterAssignmentEnd && moment(data[i].rosterAssignmentEnd).isBefore(this.currentView.end) ? data[i].rosterAssignmentEnd : this.currentView.end
        var days = data[i].scheduledWorkDays
        var length = moment(endDate, 'YYYY-MM-DD').diff(startDate, 'days')
          for(var x = 0; x < length; x++){
          var date = moment(startDate).add(x, 'd').format('YYYY-MM-DD')
          var testDay = days.indexOf(moment(date, 'YYYY-MM-DD').format('ddd'))
          if(testDay != -1){
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var obj = {}
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty')
          if(payrollCode.payrollCodeExport == '*' && data[i].userId){
           // console.log('Wild Card')
            payrollCode = customJs.custom_export_gfr(this.fullDeptContactData.find(p => p.id == data[i].userId), _.cloneDeep(payrollCode), true)
          }
          obj['color'] = 'text-' + holdcolor
          obj['payrollModType'] = 'normal',
          obj['payrollModCode'] = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'No Export',
          obj['payrollModExport'] = payrollCode && payrollCode.payrollCodeExport ? payrollCode.payrollCodeExport : 'No Export',
          obj['payrollModDatetime'] = startTime, 
          obj['endTime'] = endTime
          obj['payrollModHours'] = data[i].rosterShiftLength,
          obj['daily'] = true
        events.push(obj)
          }
          }
       }
    }
    return events
  },
    findFirstAvailableShifts(date, profileshift, id){
              var payroll = this.siteconfig.payroll
              var indexOfDate = payroll.shifts.map(p => p.shift).indexOf(profileshift)
              var selectedVarDate = moment(date, "YYYY-MM-DD");
              var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var advance = indexOfDate - roundedDated
              var testDate = selectedVarDate.add(advance, 'd')
              if(testDate.isBefore(date)){
                testDate = testDate.add(payroll.shiftRotation, 'd')
              } 
              return testDate
  },
  getPreviousPayrollExports(){
  axios({
        method: 'get',
        url: '/api/v1/staffing/payrollexport/short',
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     this.payrollReports = _.orderBy(response.data, ['createdAt'], ['desc'])
      })
  },
  getPayrollModifiersInRange(start, end){
      this.disableGenerateReport = true
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrollmod/daily/' + start + '/' + end,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
        if(response.data){
          var array = response.data.filter(p => {
              if(p.payrollModDatetime && p.payrollModHours > 0 && p.payrollModHours != null){
                return p.payrollModType != 'roster mod' && moment(p.payrollModDatetime).isBefore(end)
              }
              })
              this.payrollRecoMods = array
              this.$nextTick(function(){
                this.disableGenerateReport = false
              })
        } 

        // this.rosterReload = true
      })
    },
   


  }



  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
