<template>
  <div >
    <div v-if="!showConvo" @click.stop="convoToggle(convo.message.conversationId, convo)">
    <div v-if="!showConvo" class="convoList">
            <div class="deleteMessage">
        <b-btn variant="outline-danger" size="sm" @click.stop="deleteConvo(convo.participants.length == 2 ? 'Delete' : 'Patch', convo)">{{ convo.participants.length == 2 ? 'Delete' : 'Unsubscribe' }}</b-btn>
      </div>
      <div class="headerSingle">
        <span v-if="!checkMessageStatus(convo.message.id)" >
        <b-badge v-show="checkReadByFullPreview(fullConvo[fullConvo.length - 1])" variant="danger" v-if="fullConvo.length > 1">New </b-badge></span>
        <span v-if="checkMessageStatus(convo.message.id)">
         <b-badge v-show="checkReadByFullPreview(convo.message)" variant="danger" v-if="fullConvo">New</b-badge></span>
         <span  v-for="(users, index) in filterUserArray" v-if="deptContactData.length > 0 && index < 4" :key="index" class="messagePictureTitle"><strong>{{ users.firstName }} {{ users.lastName }}<span v-if="index < (filterUserArray.length - 1)">, </span>
         <span v-if="convo.participants.length > 3 && index == 3"> ... and {{ convo.participants.length }} Other Users </span></strong></span>   
      </div>

    <b-container fluid class="pl-4 pr-4 mr-1">
      <b-row v-if="checkMessageStatus(convo.message.id)" >
      <b-col v-if="getCardVariant(convo.message.author) == 'authorChat'"></b-col>
      <b-col cols="*" no-body :class="getCardVariant(convo.message.author) == 'authorChat' ? 'authorChat ml-5 mr-2' : 'nonAuthorChat mr-5'">
      <div>
      <span><strong><em v-if="convo.message.author != userData.id" v-show="convo.participants.length > 2">{{ getAuthorLastName(convo.message.author) }}:</em></strong></span>
      </div>
      {{ convo.message.body }}
        <div v-if="convo.message.video">
                   <iframe class="videoIframe" :src="convo.message.video" frameborder="0" allowfullscreen></iframe>  
        </div>
        <b-img fluid v-if="convo.message.photo" :src="siteconfig.deptStore + convo.message.photo" />
                 <div v-if="convo.message.file">
             <b-btn variant="light" @click="viewFile(convo.message.file)"><b-img class="ml-0" width="auto" height="24px" src="/images/siteicons/pdf-attach.png" /> View Attached File</b-btn>
           </div>
           
      </b-col>
      
      </b-row>
      <b-row v-if="!checkMessageStatus(convo.message.id)">
          <b-col v-if="getCardVariant(fullConvo[fullConvo.length - 1].author) == 'authorChat'"></b-col>
         <b-col cols="*" :class="getCardVariant(fullConvo[fullConvo.length - 1].author)">
           <div>
             <span><strong>
          <em v-show="convo.participants.length > 2">{{ getAuthorLastName(fullConvo[fullConvo.length - 1].author) }}:</em></strong></span>
                
           </div>
          {{ fullConvo[fullConvo.length - 1].body }}
          <div v-if="fullConvo[fullConvo.length - 1].video">
                   <iframe class="videoIframe" :src="fullConvo[fullConvo.length - 1].video" frameborder="0" allowfullscreen></iframe>  
         </div>
         <b-img fluid v-if="fullConvo[fullConvo.length - 1].photo" :src="siteconfig.deptStore + fullConvo[fullConvo.length - 1].photo" />
         <div v-if="fullConvo[fullConvo.length - 1].file">
              <b-btn variant="light" @click="viewFile(fullConvo[fullConvo.length - 1].file)"><img width="auto" height="32px" src="/images/siteicons/pdf-attach.png" />View Attached File</b-btn>
           </div>
          </b-col>
                     <b-col v-if="getCardVariant(fullConvo[fullConvo.length - 1].author) == 'nonAuthorChat'"></b-col>       
          </b-row>
           <div class="dateClass">{{ formatMessageTime(convo.message.createdAt) }}</div>
      </b-container>
    </div>
    </div>
    <b-card no-body header-tag="header" footer-tag="footer" header-class="headerBox" footer-class="footerResponse" v-if="showConvo">
    <div slot="header" id="headerBoxId" >
      <b-container class="pr-5"><b-row><b-col cols="*">
        <span @click="returnToMessageList" class="arrowLeft">           <img src="/images/siteicons/message-icon-close.png"
           title="Messages" height="32" width="32" @click="displayToggle = ''"></span>
           </b-col>
         <b-col v-if="filterUserArray.length >= 4" class="pt-2">
         <b-btn size="sm" variant="outline-primary" @click="showUsersInConvo = !showUsersInConvo"> {{ filterUserArray.length }} Users in Chat </b-btn>

         </b-col>
         <b-col v-if="filterUserArray.length < 4" v-for="(users, index) in filterUserArray" :key="index">           
         <b-container class="messagePictureTitle pt-2">
           <b-row ><b-col align="center" >
          <img v-if="true" class="messagePicture" :src="userPhotoLink(users.picture)" :title="users.firstName + ' ' + users.lastName" /></b-col></b-row>
          <b-row ><b-col align="center" >
           <b-badge 
           :variant="users.shiftAssignment == 'Day-shift' ? 'warning' : users.shiftAssignment == 'A-shift' ? 'warning' : 
           users.shiftAssignment == 'B-shift' ? 'primary' : users.shiftAssignment == 'C-shift' ?
           'success' : '' ">{{ users.lastName }}</b-badge></b-col></b-row></b-container> 
        </b-col>
         </b-row></b-container>
     </div> 

    <div class="convoFull" id="scroll" ref="messageDisplay"  >
      <div class="chatConvo" >
        <h6 v-if="!dataReady"> ... Loading Chat </h6>
          <b-container fluid v-if="fullConvo"  v-on="checkReadByFull(fullConvo)">
          <b-row class="messages" v-if="dataReady" v-for="message in fullConvo" :key="message.id"   @mouseout="hoverDate = 'false', hoverDateMessage = ''">
            <b-container fluid v-show="hoverDateMessage == message.id">
            <b-row><b-col align="center" class="mr-2"> 
             <span class="dateClass">{{ formatMessageTime(message.createdAt) }}</span></b-col></b-row></b-container>
             <b-container>
            <b-row>
              <b-col v-if="getCardVariant(message.author) == 'authorChat'"></b-col>
          <b-col  cols="*" :class="getCardVariant(message.author) == 'authorChat' ? 'authorChat ml-5 mr-2' : 'nonAuthorChat mr-5'" @mouseover="setHoverTimeout(message.id)"> 
                  <div>
              <span><strong><em v-if="message.author != userData.id" v-show="convo.participants.length > 2">{{ getAuthorLastName(message.author) }}:</em></strong></span>
           </div>
            {{ message.body }} 
            <div v-if="message.video">
               <iframe class="videoIframe" :src="message.video" frameborder="0" allowfullscreen></iframe>  
            </div>
              <b-img fluid v-if="message.photo" :src="siteconfig.deptStore + message.photo" />
            <div v-if="message.file">
              <b-btn variant="light" @click="viewFile(message.file)"><img width="auto" height="32px" src="/images/siteicons/pdf-attach.png" />View Attached File</b-btn>
            </div>
            </b-col>
            <b-col v-if="getCardVariant(message.author) == 'nonAuthorChat'"></b-col>
            </b-row>
             </b-container>
          </b-row>
                              <b-row v-if="userTypingSet.length > 0 && typingReset" class="ml-2">
               <em v-for="(user, index) in userTypingSet" :key="index">{{ user.user }}&nbsp;</em>
               <em>{{ 'typing...'}}</em></b-row>
        </b-container>

      </div>
  <div class="userBoxForLarge" v-if="showUsersInConvo">
 <span  v-for="(users, index) in filterUserArray" :key="index" class="messagePictureTitle"><em>{{ users.firstName }} {{ users.lastName }}<span v-if="index < (filterUserArray.length - 1)">, </span></em></span> 
    </div>
    </div>
    <div v-if="showConvo" slot="footer" class="newFooter" id="footerBoxID">
      <message :showConvo="showConvo" :convo="convo.message.conversationId" :messageIdType="'chat'" @newReply="socketReply" :sock="sock" />
    </div>
    
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { bus } from '../../../main'
import ImageCompressor from 'image-compressor.js'
import Message from './message.vue'


export default {
  
props:["convo", "sock", "deleteShow"],
components:{
  'message' : Message,
},
  data () {
    return {
        showConvo: false,
        hoverover: false,
        fullConvo: [],
        newMessage: '',
        showUsersInConvo: false,
        unreadMessages: [],
        fileUpload: '',
        imageLoadReset: true,
        documentFile: '',
        hoverDateMessage: '',
        hoverDate: false,
        hoverHold: '',
        userTyping: [],
        typingReset: true,
    }
  },
    computed:{
      ...mapState([
        'deptContactData',
        'userData',
        'getSessionData',
        'siteconfig'
      ]),
      userTypingSet(){
        if(this.userTyping.length < 3){
          var array = this.userTyping.filter(p => {
            return p.id != this.userData.id
          })
          return _.sortBy(array)
        } else if(this.userTyping.length >= 3) {
          return [{id: 103130, user: this.userTyping.length + ' people are'}]
        } else {
          return []
        }
      },
      dataReady(){
        if(this.fullConvo.length > 0){
          return true
        } else {
          return false
        }
      },
      setRows(){
        if(this.newMessage == ''){
          return 1
        } else {
          return 3
        }
      },
      filterUserArray(){
        var data = this.convo.participants
        var nonAuthor = data.filter(p => {
         if(p != this.userData.id){
           return p
         }
       })
  
       var user = []
       for(var i in nonAuthor){
       var userData = this.deptContactData.find(p => {
            return p.id.match(nonAuthor[i])
       })
       if(userData){
       user.push(userData)
       }
       } 
       return user
     },

  },
  created(){

  },
  updated(){
    if(this.showConvo == true && this.hoverDate == false){
    var messageDisplay = this.$refs.messageDisplay;
    messageDisplay.scrollTop = messageDisplay.scrollHeight;
    }
  },
  mounted(){
    // this.sock.open()
     this.sock.emit('enter conversation chat', this.convo.message.conversationId)
     this.sock.on('refresh messages chat', (conversation) => {
    if(this.convo.message.conversationId == conversation.id){
     this.getFullMessageList(conversation.id) 
      if(this.showConvo == false){
      this.playSound()
      this.$notify({
          group: 'notesAlert',
          type: 'warning',
          title: 'New Reply',
          text: '<strong>From:' + conversation.author + '</strong><br>' + conversation.msg
          });
      }
     }
      })
    this.sock.on('userTypingEmit', (conversation) => {
      this.addTypingUser(conversation.user)
    })

  },
  beforeDestroy(){
    this.sock.off('refresh messages chat')
  },
   methods:{
     addTypingUser(user){
       this.userTyping.push(user)
       this.setTimeOutOnUser(user.id)
     },
     setTimeOutOnUser(id){
       setTimeout(() => {
         this.typingReset = false
         var index = this.userTyping.map(p => p.id).indexOf(id)
         if(index != -1){
         this.userTyping.splice(index, 1)}
         this.$nextTick(function(){
           this.typingReset = true
         })
       }, 3000)
     },
     getAuthorLastName(id){
       var author = this.deptContactData.find(p => p.id == id)
       if(author){
         return author.lastName
      } else {
        return 'Unknown User'
      }
     },
     setHoverTimeout(data){
       this.hoverDateMessage = ''
       if(data != ''){
         this.hoverHold = data
         this.hoverDate = true
       setTimeout(() => {
         if(this.hoverDate == true && this.hoverHold == data){ 
         this.hoverDateMessage = data
         } else {
           this.hoverDateMessage = ''
         } 
         }, 1000)
       } else {
         this.hoverDate = false
         this.hoverDateMessage = ''
       }
     },
     formatMessageTime(data){
       var today = moment().format('DD')
       var messageData = moment(data).format('DD')
       var time = moment(data).format('HH:mm')
       if(today == messageData){
         return  time
       } else {
         var date = moment(data).format('MM/DD/YY')
         return date + ' ' + time
       }
     },
      viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
      playSound() {
      var sound = './sounds/out.mp3'
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
      }
      },
     checkReadByFullPreview(message){
       if(message.readBy != undefined && message.readBy.length > 0){
      var index = message.readBy.indexOf(this.userData.id)
      if(index <  0 && message.author != this.userData.id){
       var indexUnread = this.unreadMessages.indexOf(message)
       if(indexUnread < 0){
       this.$store.dispatch('NEW_MESSAGE_TRACKING', message)
       this.unreadMessages.push(message)

       return true
       } else {
        return true
       }
      } else {
        return false
      }
    } else {
     
      return true
    }
     },
    checkReadByFull(data){
     for(var i = 0; i < data.length; i++){
      if(data[i].readBy != undefined && data[i].readBy.length > 0){
      var index = data[i].readBy.indexOf(this.userData.id)
        if(index <  0){
          if(data[i].id){
        this.setMessageAsRead(data[i].id)
          } else {
            this.setMessageAsRead(data[i].id)
          }
        }
        if(i == data.length){
          this.$nextTick(function(){
            this.getFullMessageList(this.convo.message.conversationId)
          })
        }
    }
    }
    },
     getCardVariant(id){
       if(id == this.userData.id){
         return 'authorChat'
       } else {
         return 'nonAuthorChat'
       }
     },
     checkMessageStatus(id){
       var full = [...new Set(this.fullConvo.map(p => p.id))]
       var index = full.indexOf(id) + 1
       if(this.fullConvo.length == 0){
         return true
       }
       if(this.fullConvo.length > 0 && index < this.fullConvo.length){
         return false
       }
       else {
         return true
       }
     },

     returnToMessageList(event){
       var array = ''
       this.showConvo = false
       this.newMessageTag = false
       bus.$emit('toggleSingleViewChat', array)
     },
     convoToggle(id, convo){   
       this.showConvo = true
       bus.$emit('toggleSingleViewChat', id)   
       if(this.fullConvo.length == 0){
       this.getFullMessageList(id, convo)
       }

     },
     getFullMessageList(id){
     axios({
         method: 'get',
         url: '/api/v1/chat/' + id,
         headers: {
           'content-type': 'application/json', 
           'Authorization': this.getSessionData
         }
       }).then( response => {
         this.fullConvo = response.data
         this.$nextTick(function(){

        var messageDisplay = this.$refs.messageDisplay;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
         })
       })
     },
     deleteConvo(type, data){
    //   console.log(data)
    var obj = data
    var users = data.participants
    users.splice(users.indexOf(this.userData.id), 1)
    obj.participants = users
    delete obj.message
    axios({
         method: type,
         url: '/api/v1/chat/convo/' + data.id,
         data: type == 'Patch' ? obj : {},
         headers: {
           'content-type': 'application/json', 
           'Authorization': this.getSessionData
         }
       }).then( response => {
         bus.$emit('refresh user message list')
         console.log(response.data)
     //   this.$store.dispatch('REMOVE_MESSAGE_TRACKING', id)
       })
     },
   socketReply(value){
     this.getFullMessageList(value.id)
     this.sock.emit('new message chat', value)
  
   },
    setMessageAsRead(id){
      axios({
         method: 'post',
         url: '/api/v1/chat/read/' + id,
         headers: {
           'content-type': 'application/json', 
           'Authorization': this.getSessionData
         }
       }).then( response => {
        this.$store.dispatch('REMOVE_MESSAGE_TRACKING', id)
       })
     },
      userPhotoLink(fileName){
        if(fileName.charAt(0) == '/'){
      return this.siteconfig.deptStore + fileName.replace('/', '')
        } else {
          return  this.siteconfig.deptStore + fileName
        }
      },


   }
}
</script>

<style scoped>
.dateClass {
  text-align: right;
    font-size: 12px;
    font-style: italic;
    margin-top: 2px;
    color:#999;
    margin-right: -10px;
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > input
{
    display: none;
}
.userBoxForLarge {
  margin: 10px;
}
.arrowLeft {
  padding: 10px;
  margin-left: 5px;
  image-orientation: flip;
  float: left;
  cursor: pointer;
}
#headerBoxId {
  height: 100%;
  width: 360px;
  
}
.deleteMessage {
    position: relative;
    margin-bottom: -50px;
    width: 100px;
    z-index: 10000;
    float: right;
    background: white;
    padding: 5px 0 0 0;
}
.headerBox {
    height: 70px;
    width: 100%;
    position: fixed; 
    margin: -1px 0 0 0;
    padding: 0 0;
    background: #eee;
    z-index: 1;
}
.headerSingle {
  width: 100%;
  text-align: left;
  padding: 10px;
}
.convoList {
    margin-bottom: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 0 5px 5px 0;
    box-shadow: 0px 1px 4px #868e96;
}
.messageWrapper {
    width: 70px;
    margin: 0;
    float: left;
    font-size: 14px;
}
.wordWrap {
  border: 1px solid rgb(119, 119, 119);
  border-radius: 5px;
  padding: 5px;
  width: 290px;
  overflow-wrap: break-word;
}
.authorChat {
  border-radius: 15px;
  padding: 1px 8px;
  background: #e2070d;
  color: #fff;
  font-size: 14px;
  text-align: right;
}
.nonAuthorChat {
  border-radius: 15px;
  padding: 1px 8px;
  color: #000;
  background: #ddd;
  font-size: 14px;
  text-align: left;
}
.chatConvo {
  width: 350px;
}
.messages {
  margin: 5px;
}
#footerBoxId {
  height: 100%;
  padding-top: 5px;
}
.footerResponse {
    position: fixed;
    bottom: 0;
 /*  height: 150px; */
    width: 350px;
    background-color: #fefefe;
    padding: 5px 0 0 0;
}
.clear {
  clear: both;
}
img.messagePicture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
}
.convoSingle {
  text-align: left;
  margin-left: 5px;
}
.convoFull {
  height: 70vh;
  max-height: 70vh;
  margin-right: -15px;
  padding: 70px 0 160px 0;
  text-align: left;
  overflow-y: scroll;
  overflow-x: hidden;
}
.messagePictureTitle {
    font-size: 12px;
   
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media screen and (max-width: 768px) {
.footerResponse {
    position: fixed;
    bottom: 50px;
    height: 150px;
   
    background-color: #eee;
    padding: 5px 0 0 0;
}

}
@media screen and (max-width: 480px) {
.footerResponse {
    position: fixed;
    bottom: 50px;
    height: 170px;
    width: 98%;
    background-color: #eee;
    padding: 5px 0 0 0;
}
.convoFull {
  height: 90vh;
  max-height: 90vh;
  margin-right: -15px;
  padding: 70px 0 190px 0;
  text-align: left;
  overflow-y: scroll;
  overflow-x: hidden;
}
}
</style>
