import { render, staticRenderFns } from "./newCalendarPostFull.vue?vue&type=template&id=2b665a26&scoped=true"
import script from "./newCalendarPostFull.vue?vue&type=script&lang=js"
export * from "./newCalendarPostFull.vue?vue&type=script&lang=js"
import style1 from "./newCalendarPostFull.vue?vue&type=style&index=1&id=2b665a26&prod&lang=css"
import style2 from "./newCalendarPostFull.vue?vue&type=style&index=2&id=2b665a26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b665a26",
  null
  
)

export default component.exports