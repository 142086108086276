<template>
<div @click="addModalOpen">

  <b-modal 
       id="narcLogModal"
       ref="narcLogModal"
       v-model="showModalNarc"
       :noCloseOnBackdrop="true"
       :noCloseOnEsc="true"
         >
         <template slot="modal-header"  v-if="!showModalConfirmation">
          <span> Create New Narcotic Entry
                     <img class="imgNarcIcon" src="/images/siteicons/help-icon-right-align.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo"></span>
           <b-btn align="right" size="sm" variant="danger" @click="showModalswitchEmitNarc">x</b-btn>
         </template>
         <div class="modal-body"  v-if="!showModalConfirmation">
           <slot name="body">
              <tutdata v-if="showTutVideo" singlePost="newnarc" />
              <div>
              <b-btn v-if="siteconfig.craddleToGraveFlag" variant="link" class="text-success" @click="switchToCradleToGrave">Switch To Cradle To Grave</b-btn>
            <b-container>
              <h5>Date/Time the Below Log Took Place</h5>
            <b-row>
              <b-col align="left"><h5 @click="changeDate = !changeDate" v-show="!changeDate" id="todaysDateDisplay">{{ currentDateSelected }}(click to Edit)</h5>
                <datepicker  :disabledPicker="newImageFileDataWitness != ''" v-if="changeDate"  @input="setCurrentDate(currentDateSelected)"  v-model="currentDateSelected"></datepicker></b-col>
      
              </b-row><br>
                <b-row>
                  <b-col sm="5">
                  <b-input-group>
                  <timepicker  :disabled="newImageFileDataWitness != ''" class="timeDesk" v-if="disctimeValueSet != ''" @change="updateTimePicker" v-model="disctimeValueSet"></timepicker>
                  <input   :disabled="newImageFileDataWitness != ''" class="timeMobile" @change="updateTimeInput" type="time" v-model="timeData" />
              <b-btn class="mt-0" variant="primary" @click="setTimes">Now</b-btn>
                          </b-input-group>
            </b-col>
            <b-col sm="3">
              <h5 :class="shiftSet">{{ shiftSet }} </h5>
            </b-col>


            </b-row>
            <b-row v-if="timeDifference > 60">
              <b-col>
                <textarea placeholder="***Delayed Entry Explanation Required***" />
              </b-col>
            </b-row>
            <b-row><b-col>
              <b-form-radio-group id="radios2" v-model="formTypeSelected" name="radioSubComponent">
                <b-form-radio value="new">New</b-form-radio>
               <b-form-radio value="attach">Or Offline Attach</b-form-radio>
        </b-form-radio-group>
            </b-col>
            </b-row>
            <b-row class="desktopView">
              <b-col>
                <div :class="{errorClass: apparatusSelectValueName == ''}">
                <multiselect   :disabled="newImageFileDataWitness != ''" v-model="apparatusSelectValue" :options="filteredApparatusData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select an Apparatus" label="apparatusName" track-by="apparatusName"></multiselect>
              </div>
              </b-col>
            </b-row>
            <b-form-select class="mobileView" :class="{errorClass: apparatusSelectValueName == ''}" v-model="apparatusSelectValueMobile" text-field="apparatusName" value-field="apparatusName"  :options="filteredApparatusData" >
              <option value="">Please Select an Apparatus</option>
              </b-form-select>

            <b-row v-show="!apparatusSelectValue == ''"><b-col class="genRight">
              <div >
              <b-form-select  :disabled="newImageFileDataWitness != ''" :class="{errorClass: statusSelect == ''}" class="selectStatusInput" v-model="statusSelect" :options="status" ></b-form-select>
            </div>
            </b-col>
            </b-row>

            <b-row v-if="formTypeSelected == 'new'">
              <b-col v-if="statusSelect == 'Daily Narc Transfer'">
                <div :class="{errorClass: witnessOrOffGoing == ''}">
                <multiselect  :disabled="newImageFileDataWitness != ''" v-model="witnessOrOffGoing" :options="userTagListFiltered" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer User (On-Coming)">
                 <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span></template>
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                  </template>
                </multiselect></div></b-col>
                <b-col v-if="statusSelect == 'Daily Narc Restock'">
                    <div :class="{errorClass: witnessOrOffGoing == ''}">
                  <multiselect  :disabled="newImageFileDataWitness != ''" v-model="witnessOrOffGoing" :options="deptContactDataFiltered" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Use/Restock Witness">
                                    <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span></template>
                    <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                    </template>
                  </multiselect>
                </div></b-col>
                <b-col v-if="statusSelect == 'Daily Narc Use'">
                  <b-form-input :class="{errorClass: witnessOrOffGoing == ''}" type="text"  :disabled="newImageFileDataWitness != ''" placeholder="Waste Witness Name and Title" v-model="witnessOrOffGoing" />
                </b-col>

          </b-row>
            <b-row v-if="formTypeSelected == 'attach'">
              <b-col v-show="!apparatusSelectValue == ''">
                <div :class="{errorClass: witnessOrOffGoing == '' || witnessOrOffGoing.role != 'emsops'}">
                <multiselect  :disabled="newImageFileDataWitness != ''" v-model="witnessOrOffGoing" :options="emsOpsTagListFiltered" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select EMS OP User">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
                </multiselect></div></b-col>
          

          </b-row>
            <b-row class='my-1' v-if="statusSelect == 'Daily Narc Distribution'"><b-col><b-form-select v-model="distributionPlaceHold"><option value="" disabled>Please Select Distribution Type</option><option value="Quarintine">Moved to Quarintine</option><option value="Apparatus">Distributed to Apparatus</option><option value="Station">Distributed to Station</option></b-form-select></b-col></b-row>
            <b-row class='my-1' v-if="statusSelect == 'Daily Narc Total'"><b-col><b-form-select v-model="reportRunNumber"><option value="" disabled>Please Select Location</option><option value="Stored">Stored</option><option value="Quarintine">Quarintine</option></b-form-select></b-col></b-row>
            <b-row class="my-2" v-if="statusSelect == 'Daily Narc Use'"><b-col class="genCenter" colspan="2"><b-form-input   :disabled="newImageFileDataWitness != ''" placeholder="Run Number" :class="{errorClass: reportRunNumber == ''}" v-model="reportRunNumber" /></b-col></b-row>
            <div class="my-2" v-if="distributionPlaceHold == 'Apparatus'"><b-row class="desktopView">
              <b-col>
                <div :class="{errorClass: reportRunNumber == ''}">
                <multiselect   :disabled="newImageFileDataWitness != ''" v-model="reportRunNumberDesk" :options="filteredApparatusData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select an Apparatus" label="apparatusName" track-by="apparatusName"></multiselect>
              </div>
              </b-col>
            </b-row>
            <b-form-select class="mobileView" :class="{errorClass: reportRunNumber == ''}" v-model="reportRunNumberMobile" text-field="apparatusName" value-field="apparatusName"  :options="filteredApparatusData" >
              <option value="">Please Select an Apparatus</option>
              </b-form-select></div>
                        <div class="my-2" v-if="distributionPlaceHold == 'Station'"><b-row class="desktopView">
              <b-col>
                <div :class="{errorClass: reportRunNumber == ''}">
                <multiselect   :disabled="newImageFileDataWitness != ''" v-model="reportRunNumberStaDesk" :options="stationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select a Station" label="stationName" track-by="stationName"></multiselect>
              </div>
              </b-col>
            </b-row>
            <b-form-select class="mobileView" :class="{errorClass: reportRunNumber == ''}" v-model="reportRunNumberStaMobile" text-field="stationName" value-field="stationName"  :options="stationsData" >
              <option value="">Please Select a Station</option>
              </b-form-select></div>
                           <b-col  v-if="distributionPlaceHold == 'Apparatus'">
                    <div :class="{errorClass: witnessOrOffGoing == ''}">
                  <multiselect  :disabled="newImageFileDataWitness != ''" v-model="witnessOrOffGoing" :options="deptContactData" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Witness Use/Restock User">
                    <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                    </template>
                  </multiselect>
                </div></b-col>
           
           
           
            <b-row v-if="testValue"><b-col class="genCenter" colspan="2"><textarea rows="6" v-model="detailedDescription"></textarea></b-col></b-row>


            </b-container>
            <b-container  id="tablePassDown" v-if="apparatusSelectValue != ''">
                        <b-card no-body >
                          <b-tabs card class="medtableCard">
                            <b-tab  title="Medications" active>
                         <b-container class="medContainer" v-if="statusSelect != 'Daily Narc Use'" v-for="(post, index) in field_medication_tracking" :key="index" track-by="$index">
                         <b-row><b-col cols="9">
                            <b-form-select v-if="resetInput" :disabled="newImageFileDataWitness != ''" v-model="post.med" :options="medication" @change="updateMedicationSelection(index, $event)" ></b-form-select>
                              </b-col>
                            <b-col><b-btn size="sm" variant="danger" @click.stop="removeCurrentRow(post)"  :disabled="newImageFileDataWitness != ''" >x</b-btn></b-col>
                               </b-row>
                          <b-row>
                              <b-col v-if="post.requireId != undefined" class="inputColumn pl-2" align="center"><h6>ID</h6><b-form-input  :disabled="newImageFileDataWitness != ''"  type="text"  v-model="post.id"  /></b-col>
                              <b-col v-if="post.count != undefined"  class="inputColumn pl-2" align="center"><h6>Count</h6><b-form-input  :disabled="newImageFileDataWitness != ''" type="number" size="5" inputmode="numeric" pattern="[0-9]*" v-model="post.count"  /></b-col>
                              <b-col v-if="post.inDate != undefined" cols="3" class="dateSeal inputColumn" align="center"><h6>In Date?</h6>
                              <b-form-checkbox  v-if="post.inDate != undefined" :id="'checkbox_date_med_' + index" :disabled="newImageFileDataWitness != ''" v-model="post.inDate" :value="true" :unchecked-value="false"></b-form-checkbox>
                               </b-col>
                              <b-col  v-if="post.tamperSealed != undefined"  cols="3" class="dateSeal inputColumn" align="center"><h6>Sealed?</h6>
                            <b-form-checkbox  v-if="post.tamperSealed != undefined" :id="'checkbox_sealed_med_' + index" :disabled="newImageFileDataWitness != ''" v-model="post.tamperSealed" :value="true" :unchecked-value="false"></b-form-checkbox>
                               </b-col>
                               </b-row>
                               <b-row class="text-danger mt-2" v-if="checkPreviousCard(post)">
                                 <b-col><h6>Warning: Does Not Match Previous Card</h6>
                                      <ul v-if="checkPreviousCard(post) && checkPreviousCard(post).length > 0" class="mx-5 px-5">
                                        <li v-for="(item, indexL) in checkPreviousCard(post)" :key="indexL" >
                                          {{ item }}
                                          </li>
                                          </ul>
                                 </b-col>
                                 </b-row>
                          </b-container>
                          <b-container class="medContainer"  v-if="statusSelect == 'Daily Narc Use'" v-for="post in field_medication_tracking" :key="post.index" track-by="$index">
                         <b-row><b-col cols="9">
                                 <b-form-select  :class="{errorClass: !post.med}" :disabled="newImageFileDataWitness != ''" v-model="post.med" :options="medication" ></b-form-select>
                                  </b-col>
                                  <b-col><b-btn :disabled="newImageFileDataWitness != ''" size="sm" variant="danger" @click.stop="removeCurrentRow(post)">x</b-btn></b-col>
                               </b-row>
                               <b-row v-if="post.med">
                                 <b-col class="inputColumn px-2" align="center"><h6>Amount Used</h6>
                                  <b-form-input :disabled="newImageFileDataWitness != ''" size="5" type="text" v-model="post.used"  /></b-col>
                                 <b-col class="inputColumn px-2" align="center"><h6>Amount Wasted</h6>
                                 <b-form-input :disabled="newImageFileDataWitness != ''" size="5" type="text" v-model="post.waste"  /></b-col>
                               <b-col sm="4"  class="inputColumn pl-2 pb-1" align="center"><h6>Concentration</h6>
                                 <multiselect  :disabled="newImageFileDataWitness != ''" v-model="post.wconn" :options="concentration" label="value" track-by="value" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select"></multiselect>
                                                           </b-col></b-row>
                          </b-container>
                               <b-row><b-col v-show="statusSelect != 'Daily Narc Use'"><b-btn variant="primary" @click.stop="addNewRow">Add Medication</b-btn></b-col>
                               <b-col v-show="statusSelect == 'Daily Narc Use'" class="mb-2">
                                 <h6 class="text-danger mt-2" v-if="reportRunNumber == '' || witnessOrOffGoing == ''">Waste Name/Run Number Required</h6>
                                 <b-btn variant="primary" @click.stop="addNewRow" v-show="field_medication_tracking.length == 0" :disabled="reportRunNumber == '' || witnessOrOffGoing == ''">Add One Medication</b-btn>
                                 </b-col>
                               </b-row>
                             </b-tab>
                          <b-tab  title="Items">
                         <b-container class="medContainer" v-if="statusSelect != 'Daily Narc Use'" v-for="(post, index) in field_item_tracking" :key="index" track-by="$index">
                         <b-row><b-col cols="9">
                            <b-form-select v-if="resetInput" :disabled="newImageFileDataWitness != ''" v-model="post.med" :options="itemData" @change="updateMedicationSelection(index, $event)" ></b-form-select>
                              </b-col>
                            <b-col><b-btn size="sm" variant="danger" @click.stop="removeCurrentRowItem(post)"  :disabled="newImageFileDataWitness != ''" >x</b-btn></b-col>
                               </b-row>
                          <b-row>
                              <b-col v-if="post.requireId != undefined" class="inputColumn" align="center"><h6>ID</h6><b-form-input  :disabled="newImageFileDataWitness != ''"  size="7" type="text"  v-model="post.id"  /></b-col>
                              <b-col v-if="post.count != undefined" class="inputColumn" align="center"><h6>Count</h6><b-form-input  :disabled="newImageFileDataWitness != ''"  size="5" type="number" inputmode="numeric" pattern="[0-9]*" v-model="post.count"  /></b-col>
                              <b-col v-if="post.inDate != undefined" class="dateSeal inputColumn" align="center"><h6>In Date?</h6>
                              <b-form-checkbox   v-if="post.inDate != undefined" :id="'checkbox_date_item_' + index" :disabled="newImageFileDataWitness != ''" v-model="post.inDate" :value="true" :unchecked-value="false"></b-form-checkbox>
                               </b-col>
                              <b-col  v-if="post.tamperSealed != undefined" class="dateSeal inputColumn" align="center"><h6>Sealed?</h6>
                            <b-form-checkbox  v-if="post.tamperSealed != undefined"  :id="'checkbox_sealed_item_' + index" :disabled="newImageFileDataWitness != ''" v-model="post.tamperSealed" :value="true" :unchecked-value="false"></b-form-checkbox>
                               </b-col>
                               </b-row>
                          </b-container>
                          <b-container class="medContainer"  v-if="statusSelect == 'Daily Narc Use'" v-for="post in field_item_tracking" :key="post.index" track-by="$index">
                         <b-row><b-col cols="9">
                                 <b-form-select :disabled="newImageFileDataWitness != ''" v-model="post.med" :options="itemData" ></b-form-select>
                                  </b-col>
                                  <b-col><b-btn :disabled="newImageFileDataWitness != ''" size="sm" variant="danger" @click.stop="removeCurrentRow(post)">x</b-btn></b-col>
                               </b-row><b-row><b-col class="inputColumn" align="center"><h6>Amount Used</h6>
                               <b-form-input :disabled="newImageFileDataWitness != ''"  size="5" type="number" step="0.01"  inputmode="numeric" pattern="[0-9]*" v-model="post.used"  /></b-col>
                                <b-col class="inputColumn px-1" align="center"><h6>Amount Wasted</h6><b-form-input :disabled="newImageFileDataWitness != ''" size="5" type="number" step="0.01" inputmode="numeric" pattern="[0-9]*" v-model="post.waste"  /></b-col>
                               <b-col sm="4" align="center"><h6>Concentration</h6>
                                 <multiselect  :disabled="newImageFileDataWitness != ''" v-model="post.wconn" :options="concentration" label="value" track-by="value" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select"></multiselect>
                                                           </b-col></b-row>
                          </b-container>
                               <b-row><b-col v-show="statusSelect != 'Daily Narc Use'"><b-btn variant="primary" @click.stop="addNewRowItem">Add Item</b-btn></b-col>
                               </b-row>
                             </b-tab>
                            <b-tab title="Signatures" v-if="statusSelect != ''">

                               <div>
                                 <br>
                                 <h4> Signature Panel </h4>
                               <b-container class="medContainer" v-if="field_medication_tracking != ''">
                                 <b-row v-if="statusSelect != ''"  >
                                   <b-col >
                                     <b-btn variant="primary" v-if="newImageFileData == ''" @click.prevent="userSign = !userSign" >Add {{ userData.lastName }}'s Signature</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileData != ''" v-show="authorSignatureView" @click.stop="authorSignatureView = !authorSignatureView">Hide</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileData != ''" v-show="!authorSignatureView" @click.stop="authorSignatureView = !authorSignatureView">Show</b-btn>
                                       <b-btn  size="sm" variant="danger" v-if="newImageFileData != ''" @click.stop="userSign = !userSign">Edit ({{ userData.lastName }}'s) Signature</b-btn>
                                   </b-col></b-row>
                                   <b-row><b-col  v-if="previewImageFileData != ''" v-show="authorSignatureView"><img type="image" :src="previewImageFileData" />
                                   </b-col></b-row>
                                 <b-row v-if="statusSelect == 'Daily Narc Use' || statusSelect == 'Daily Narc Restock' || emailWitness == false">
                                   <b-col cols="3" v-if="false"><h6>Witness</h6></b-col> <b-col cols="3"  v-if="false"><h6 v-if="statusSelect != 'Daily Narc Use'">{{ witnessOrOffGoing.lastName }}</h6>
                                                        <h6 v-if="statusSelect == 'Daily Narc Use'" v-show="formTypeSelected == 'new'">{{ witnessOrOffGoing }}</h6>
                                                        <h6 v-if="statusSelect == 'Daily Narc Use'" v-show="formTypeSelected == 'attach'">{{ witnessOrOffGoing.lastName }}</h6>
                                                        </b-col><b-col>
                                     <b-btn variant="primarys" v-if="newImageFileDataWitness == ''" @click.stop="witnessSign = !witnessSign" v-show="formTypeSelected == 'new'">Add {{statusSelect != 'Daily Narc Use' ? witnessOrOffGoing.lastName : formTypeSelected == 'new' ? witnessOrOffGoing : witnessOrOffGoing.lastName }}'s Signature</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileDataWitness != ''" v-show="witnessSignatureView" @click.stop="witnessSignatureView = !witnessSignatureView">Hide</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileDataWitness != ''" v-show="!witnessSignatureView" @click.stop="witnessSignatureView = !witnessSignatureView">Show</b-btn>
                                       <b-btn  size="sm" variant="danger" v-if="newImageFileDataWitness != ''" @click.stop="userSign = !userSign">Edit {{statusSelect != 'Daily Narc Use' ? witnessOrOffGoing.lastName : formTypeSelected == 'new' ? witnessOrOffGoing : witnessOrOffGoing.lastName }}'s Signature</b-btn>
                                       <b-btn  size="sm" variant="danger" v-if="newImageFileDataWitness != ''" @click.stop="clearWitnessData">Delete</b-btn>
                                   </b-col></b-row>
                                   <b-row><b-col  v-if="previewImageFileDataWitness != ''" v-show="witnessSignatureView"><img type="image" :src="previewImageFileDataWitness" />
                                   </b-col></b-row>
                                  <b-row v-if="statusSelect =='Daily Narc Transfer'" v-show="newImageFileDataWitness == ''">
                                    <b-col>
                                  <b-form-checkbox id="checkbox1"
                                      v-if="formTypeSelected == 'new'"
                                      v-model="emailWitness"
                                      :value='true'
                                     :unchecked-value='false'>
                                       Transfer User unavailable to sign (explanation required)
                                         </b-form-checkbox>
                                      </b-col>
                                  </b-row>
                                  <b-form-file v-if="formTypeSelected == 'attach'" @change="formFileBufferType($event)" />
                                  <em v-if="formTypeSelected == 'attach'">file type should be png or jpg</em>
                                  <div v-if="fileAttached">
                                  <img v-if="previewSettings == 'image' || previewSettings == 'link'" :src="fileForAttach" />
                                  <b-btn variant="link" v-if="previewSettings == 'link'" target="_blank" @click="fixAttachURI(fileForAttach)">Preview</b-btn>
                                  </div>
                                  <b-row v-if="emailWitness == true">
                                  <b-col>
                                    <b-textarea v-model="narcDescription" :row="4" placeholder="Please provide an explanation for procedure deviation" />
                                  </b-col>
                                  </b-row>
                               </b-container>
                             </div>
                             </b-tab>
                             </b-tabs>
                         </b-card>
                          <h6 v-if="newImageFileDataWitness != ''">Narc Card Details are Locked Due to Witness Signature</h6>
                             </b-container>
              </div>




           </slot>
         </div>

         <template slot="modal-footer" v-if="!showModalConfirmation">
           <b-card  bg-variant="danger" no-body class="p-2"
                text-variant="white" v-if="!onlineStatus">Not Connected to the Internet, Unable to Save</b-card>
           <div v-if="formTypeSelected == 'new'">
            <b-btn disabled v-if="statusSelect == ''">Submit</b-btn>
             <b-btn @click.stop="submitNarcLog" v-if="statusSelect == 'Daily Narc Transfer'"
             :disabled="newImageFileData == '' || field_medication_tracking == '' || apparatusSelectValue == '' || shiftSelect == '' || !onlineStatus || disableSubmit"
             >Submit</b-btn>
             <b-btn @click.stop="submitNarcLog" v-if="statusSelect == 'Daily Narc Use' || statusSelect == 'Daily Narc Restock'"
             :disabled="newImageFileData == '' || newImageFileDataWitness == '' || field_medication_tracking == '' || apparatusSelectValue == '' || shiftSelect == '' || !onlineStatus || disableSubmit"
             >Submit</b-btn>
            <b-btn @click.stop="submitNarcLog" v-if="statusSelect == 'Daily Narc Total'"
             :disabled="newImageFileData == '' || field_medication_tracking == '' || shiftSelect == '' || !onlineStatus"
             >Submit</b-btn>
             <div  v-if="statusSelect == 'Daily Narc Distribution'">
          <b-btn @click.stop="submitNarcLog" v-if="distributionPlaceHold == 'Apparatus'"
             :disabled="newImageFileData == '' || newImageFileDataWitness == '' || field_medication_tracking == '' || shiftSelect == '' || !onlineStatus || disableSubmit"
             >Submit</b-btn> 
        <b-btn @click.stop="submitNarcLog" v-if="distributionPlaceHold == 'Station'"
             :disabled="newImageFileData == '' || field_medication_tracking == '' || shiftSelect == '' || !onlineStatus || disableSubmit"
             >Submit</b-btn>
        <b-btn @click.stop="submitNarcLog" v-if="distributionPlaceHold == 'Quarintine'"
             :disabled="newImageFileData == '' || field_medication_tracking == '' || shiftSelect == '' || !onlineStatus || disableSubmit"
             >Submit</b-btn>
             </div>
             </div>
             <div v-if="formTypeSelected == 'attach'">
        <b-btn @click.stop="submitOfflineNarc" 
             :disabled="newImageFileData == '' || field_medication_tracking == '' || shiftSelect == '' || fileForAttach == '' || witnessOrOffGoing == '' || !onlineStatus || disableSubmit"
             >Submit</b-btn> 
             </div>
         </template>


         <template slot="modal-header" v-if="showModalConfirmation">
                Post Confirmation
         </template>
         <div class="modal-body" v-if="showModalConfirmation">
                <div v-if="postData == ''">
                      ...Submitting post
                      <br>
                  <img class="imageGif" src="/images/siteicons/MalteseLoadingGif.gif">
                </div>
                <div v-if="postData != ''">
                      Your Post has been submitted!
                </div>
              </div>
                <narcdata v-if="postData != ''" :narcs="postData" :showPost="true" />
                <template slot="modal-footer" v-if="showModalConfirmation">
                      <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                        Close Message
                      </b-btn>
               </template>
         </b-modal>

                  <signature v-if="userSign" :showModalSignature="userSign" :name="userData.lastName" @signatureCapture="signatureMethodUser($event)" @closeModalCancel="signatureClose"></signature>
                  <signature v-if="witnessSign" :showModalSignature="witnessSign" :name="witnessOrOffGoing.lastName" :witness="witness" @signatureCapture="signatureMethodWitness($event)" @closeModalCancel="signatureClose"></signature>


  </div>

</template>

<script>
const configGet = {
  headers: {
    "content-type": "application/json",
    "cache-control": "no-cache",
    "postman-token": "0bff497b-0ac5-79c8-b530-5f2b6fedbdda"
  }
};
import { mapState } from "vuex";
import { bus } from "../../main";
import axios from "axios";
import TutData from "../tracking/deptTutorialsdata.vue";
import narcData from "../tracking/narclog/apparatusFilterData.vue";
import signature from "../inputfields/signaturepad.vue";
import DatePicker from "../inputfields/Datepickermod.vue";
import TimePicker from "../inputfields/timepicker.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
export default {
  props: ["showModal", "filterDate"],
  components: {
    tutdata: TutData,
    timepicker: TimePicker,
    multiselect: Multiselect,
    datepicker: DatePicker,
    signature: signature,
    narcdata: narcData
  },
  data() {
    return {
      emailWitness: false,
      showTutVideo: false,
      trueBoo: true,
      witness: "Witness",
      imageLoadReset: true,
      showModalConfirmation: false,
      field_medication_tracking: [],
      distributionPlaceHold: '',
      field_item_tracking: [],
      formTypeSelected: 'new',
      currentDateSelected: "",
      testValue: false,
      disableSubmit: false,
      detailedDescription: "",
      apparatusSelectValue: "",
      apparatusSelectValueMobile: '',
      reportRunNumberDesk: '',
      reportRunNumberMobile: '',
      reportRunNumberStaDesk: '',
      reportRunNumberStaMobile: '',
      apparatusSelectValueName: '',
      shiftSelect: null,
      fileAttached: false,
      witnessOrOffGoing: "",
      narcDescription: "",
      medication: [],
      itemData: [],
      selectYesOrNo: [{ value: "Yes" }, { value: "No" }],
      concentration: [{ value: "mg" }, { value: "mcg" }, {value: "min"}],
      statusSelect: 0,
      changeDate: false,
      editButton: "edit",
      showEditView: false,
      newDataUUID: "",
      newDataUUIDWitness: "",
      imageFileName: "",
      imageFileNameWitness: "",
      imageFileFID: "",
      imageFileFIDWitness: "",
      newImageFileData: "",
      newImageFileDataWitness: "",
      fileName: "",
      fileNameWitness: "",
      fileType: "data:image/png",
      fileTypeWitness: "data:image/png",
      imageResponseData: [],
      imageResponseDataWitness: [],
      onDutySignature: "",
      userSign: false,
      previousMedsLogged: [],
      resetInput: true,
      witnessSign: false,
      previewImageFileData: "",
      previewImageFileDataWitness: "",
      authorSignatureView: true,
      witnessSignatureView: true,
      replaceValueOther: "",
      reportRunNumber: "",
      currentTime: "",
      disctimeValueSet: "",
      stationSelectValue: "",
      postData: "",
      timeData: "",
      showModalNarc: false,
      medicationItemArray: [],
      fileForAttach: '',
      previewSettings: '',
      onlineStatus: navigator.onLine,
    };
  },
   beforeDestroy(){ /*
    window.removeEventListener('online',  function(){
              this.updateIndicator(true)});
    window.removeEventListener('offline', function(){
              this.updateIndicator(false)}); */
     
      },

  created() {
    this.showModalNarc = this.showModal;
    this.currentDateSelected = moment().format("MM/DD/YYYY");
    this.currentDateSelectedChange = this.currentDateSelected;
    this.medicationItemArray = this.filteredMedicationsItems;
    this.setTimes();
    window.addEventListener('online', () => this.updateConnectionStatus());
    window.addEventListener('offline', () => this.updateConnectionStatus());
    this.checkUserPosts(this.userPosts)
  },
  mounted() {},
  computed: {
    ...mapState([
      "getSessionData",
      "stationsData",
      "apparatusData",
      "userData",
      "userTagList",
      "deptContactData",
      "shiftsData",
      "userPosts",
      "medicationData",
      "siteconfig"
    ]),
    onlineStatusConnect(){
      var status = navigator.onLine
      return status
    },
    status(){
    var status = [
        { text: "Daily Narc Transfer", value: "Daily Narc Transfer" },
        { text: "Daily Narc Use", value: "Daily Narc Use" },
        { text: "Daily Narc Restock", value: "Daily Narc Restock" },
        { text: "Please Select Type of Log", value: 0, disabled: true }
      ]
    if(this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin'){
      status.push({ text: "Daily Narc Distribution", value: "Daily Narc Distribution" })
      status.push({ text: "Daily Narc Total", value: "Daily Narc Total" })
    }
    return status
    },
    filteredApparatusData() {
      var filterUser = this.apparatusData.filter( p => {
      var index = p.apparatusMenuVisibility.indexOf(this.userData.role)
      if(index >= 0){
        return p
      }
    })
      var apparatus = filterUser.filter(p => {
        if (
          p.apparatusParent != "Reserve"
        ) {
          return p.apparatusMedicalType == "ALS"
        }
      });
    return apparatus
    },
    filteredMedicationsItems() {
      var meditem = this.medicationData.filter(p => {
        return p.narcoticTrack == true;
      });
      for (var i = 0; i < meditem.length; i++) {
        if (meditem[i].alsSupply == false) {
          var string = {
            text: meditem[i].medicationName,
            value: meditem[i].medicationName,
            id: meditem[i].narcoticTrackId,
            date: meditem[i].narcoticTrackDate,
            ct: meditem[i].narcoticTrackCt,
          };
          this.medication.push(string);
        } else {
          var string = {
            text: meditem[i].medicationName,
            value: meditem[i].medicationName,
            id: meditem[i].narcoticTrackId,
            date: meditem[i].narcoticTrackDate,
            ct: meditem[i].narcoticTrackCt
          };
          this.itemData.push(string);
        }
      }
      return meditem;
    },
    userTagListFiltered() {
      var options = this.deptContactData.filter(p => {
        var spec = p.deptSpecialties.indexOf("PIC");
        if (spec >= 0) {
          return p;
        }
      });
      return _.orderBy(options, ['lastName'], ['asc'])
    },
    deptContactDataFiltered(){
      return _.orderBy(this.deptContactData, ['lastName'], ['asc'])
    },
    emsOpsTagListFiltered(){
        return this.deptContactData.filter(p => {
        return p.role == 'emsops'
        })
    },
    shiftSet() {
      var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
      var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
      var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
      var dateDiffCalNumber = 100 * (dateDiffCal / 3);
      var dateTenDigit = dateDiffCalNumber % 10;
      var roundedDated = Math.round(dateTenDigit);
      if (roundedDated == 0) return "B-shift";
      if (roundedDated == 7) return "A-shift";
      if (roundedDated == 3) return "C-shift";
    },
    timeDifference() {
      var hours = this.disctimeValueSet.HH;
      var mins = this.disctimeValueSet.mm;
      var set = hours + ":" + mins;
      var setTime = moment(set, "HH:mm");
      var timeNow = moment().format("HH:mm");
      var currentTime = moment(timeNow, "HH:mm");
      return moment(currentTime).diff(setTime, "minutes");
    },
    formatedDateExport() {
      var dateString = moment(this.currentDateSelected, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" + this.currentTime;
      var offSet = moment().utcOffset();
      var hourOffSet = offSet * -1 / 60;
      var timeStringForm = moment(timeString).add(hourOffSet, "hours");
      var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
      return timeStringAdd + "Z";
    }
  },
  watch: {
    apparatusSelectValue(newData){
      this.apparatusSelectValueName = newData.apparatusName
      this.apparatusSelectValueMobile = newData.apparatusName
    },
    reportRunNumberDesk(newData){
      this.reportRunNumber = newData.apparatusName
      this.reportRunNumberMobile = newData.apparatusName
    },
    reportRunNumberStaDesk(newData){
   
      this.reportRunNumber = newData.stationName
      this.reportRunNumberStaMobile = newData.stationName
    },
    apparatusSelectValueMobile(newData){
      if(this.apparatusSelectValueName != newData)
      this.apparatusSelectValueName = newData
      var apparatus = this.filteredApparatusData.filter( p => {
        return p.apparatusName == newData
      })
      this.apparatusSelectValue = apparatus[0]
    },
    reportRunNumberMobile(newData){
      if(this.reportRunNumber != newData)
      this.reportRunNumber = newData
      var apparatus = this.filteredApparatusData.filter( p => {
        return p.apparatusName == newData
      })
      this.reportRunNumberStaDesk = apparatus[0]
    },
    reportRunNumberStaMobile(newData){
      if(this.reportRunNumber != newData)
      this.reportRunNumber = newData
      var apparatus = this.stationsData.filter( p => {
        return p.stationName == newData
      })
      this.reportRunNumberDesk = apparatus[0]
    },
    imageResponseData(newData) {
      this.imageUUID(newData);
      this.imageFileSRC(newData);
      this.imageFileFIDData(newData);
    },
    imageFileFID(newData) {
      this.sendNewPicturePost();
    },
    imageResponseDataWitness(newData) {
      this.imageUUIDWitness(newData);
      this.imageFileSRCWitness(newData);
      this.imageFileFIDDataWitness(newData);
    },
    imageFileFIDWitness(newData) {
      this.postImage();
    },
    statusSelect(newData) {
      var apparatus = this.apparatusSelectValue;
      this.getStationSelectValue(apparatus);
      this.buildMedicationAndItemList(apparatus, newData);
    }
  },
  methods: {
    checkUserPosts(data){
      if(data){
        var narcs = _.orderBy(data.filter(p => p.contentType == 'narctrack' && p.narctrackWitnessId == this.userData.id), ['narctrackDate'], ['desc'])
        if(narcs[0]){
          var app = this.apparatusData.find(p => p.apparatusName == narcs[0].narctrackApparatusId)
          this.previousMedsLogged = narcs[0].narctrackMeds
          if(app){
            this.apparatusSelectValue = app
          }
        }
      }
    },
    checkPreviousCard(data){
      var prev = this.previousMedsLogged.find(p => p.med == data.med)
      if(prev && this.statusSelect == 'Daily Narc Transfer'){
        var hold = []
        var keys = Object.keys(prev)
        for(var k in keys){
          if(prev[keys[k]] && prev[keys[k]] != data[keys[k]]){
            hold.push('Previous ' + keys[k].toUpperCase() + ' was ' + prev[keys[k]])
          } 
        }
        if(hold.length > 0){
       //   console.log(hold)
          return hold
        } return false
      }
      return false
    },
    updateConnectionStatus() {
      console.log('FUNCTION FIRED')
        this.onlineStatus = navigator.onLine
      },
    fixAttachURI(data){
    var w = window.open('about:blank');

    setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
        w.document.body.appendChild(w.document.createElement('iframe'))
            .src = data;
    }, 0);

    },
    formFileBufferType(e){
     var file = e.target.files || e.dataTransfer.files; 
     var file = file[0]
     var type = file.type
     var typeSet = type.split('/')
     if(typeSet[0] == 'image'){
        this.previewSettings = 'image'
     } else {
        this.previewSettings = 'link'
     }
      var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var fileData = reader.result
          var fileData = fileData.split('base64,')
          vm.fileForAttach = fileData[1]
          vm.$nextTick(function(){
              vm.fileAttached = true
          })
    
       };
         reader.onerror = function (error) {
        console.log('Error: ', error);
         };
    },
    addModalOpen(){
      document.body.classList.add('modal-open')
    },
    clearWitnessData(){
      this.previewImageFileDataWitness = ''
      this.newImageFileDataWitness = ''
    },
    setCurrentDate(value) {

      this.currentDateSelected = moment(value).format("MM/DD/YYYY");
    },
    getStationSelectValue(newData) {
      var station = this.stationsData.filter(p => {
        return p.stationName == newData.apparatusLocation;
      });
      this.stationSelectValue = station[0];
    },
    shiftId(data) {
      var shiftObj = this.shiftsData.filter(p => p.name.match(data));
      return shiftObj[0].tid;
    },
    setTimes() {
      this.currentTime = moment().format("HH:mm");
      var data = this.currentTime;
      var timeSet = data.split(":");
      var timeString = { HH: timeSet[0], mm: timeSet[1] };
      this.disctimeValueSet = timeString;
      this.timeData = data;
    },
    updateTimePicker(event) {
      this.currentTime = event.data.HH + ":" + event.data.mm;
      this.timeData = event.data.HH + ":" + event.data.mm;
    },
    updateTimeInput(event) {
      var timeSet = this.timeData.split(":");
      var timeString = { HH: timeSet[0], mm: timeSet[1] };
      this.disctimeValueSet = timeString;
      this.currentTime = this.timeData;
    },
    customLabel({ field_first_name, field_last_name }) {
      return `${field_first_name} – ${field_last_name}`;
    },
    toggleUserSign(event) {
      this.userSign = !this.userSign;
    },
    toggleWitnessSign(event) {
      this.witnessSign = !this.witnessSign;
    },
    signatureMethodUser(data) {
      this.toggleUserSign();
      this.showModalNarc = true;
      this.processFileUriForUpload(data);
    },
    signatureMethodWitness(data) {
      this.toggleWitnessSign();
      this.showModalNarc = true;
      this.processFileUriForUploadWitness(data);
    },
    signatureClose() {
      this.userSign = false;
      this.witnessSign = false;
      this.showModalNarc = true;
    },
    updateMedicationSelection(data, value){
      this.resetInput = false
      var newMed = this.medicationItemArray.find(p => p.medicationName == value)
      var type = this.apparatusSelectValue.apparatusVehicleType.toLowerCase()
      var count = type + "Count";
      var obj = newMed
      var value = obj[count]
      if(!value){
        value = 0
      }
      var string = { med: newMed.medicationName }
      if(newMed.narcoticTrackCt){
        if(this.statusSelect != 'Daily Narc Restock'){
        string['count'] = value
        } else {
          string['count'] = 1
        }
        }
      if(newMed.narcoticTrackDate){
         string['inDate'] = true
        }
      if(newMed.narcoticTrackId){
          string['id'] = ''
          string['requireId'] = true
      }
      if(newMed.narcoticTrackTamper){
         string['tamperSealed'] = true
          }
      if(newMed.alsSupply){
        this.field_item_tracking[data] = string
      } else {
        this.field_medication_tracking[data] = string
      }
      this.$nextTick(function(){
        this.resetInput = true
      })
    },
    buildMedicationAndItemList(app, newData) {
      this.field_medication_tracking = [];
      this.field_item_tracking = [];
      var type = app.apparatusVehicleType.toLowerCase();
      var meditem = this.medicationItemArray;
      if (newData == "Daily Narc Transfer") {
        for (var i = 0; i < meditem.length; i++) {
          if (meditem[i].alsSupply == false) {
            var count = type + "Count"
            var obj = meditem[i]
            var value = obj[count] ? obj[count] : 0
            var string = {
              med: meditem[i].medicationName,
            }
            string['medId'] = meditem[i].id
            if(meditem[i].narcoticTrackCt){
              string['count'] = value
            }
            if(meditem[i].narcoticTrackDate){
              string['inDate'] = true
            }
            if(meditem[i].narcoticTrackId){
              string['id'] = ''
              string['requireId'] = true
            }
            if(meditem[i].narcoticTrackTamper){
              string['tamperSealed'] = true
            }
            this.field_medication_tracking.push(string);
          } else {
            var count = type + "Count"
            var obj = meditem[i]
            var value = obj[count] ? obj[count] : 0
            var string = {
              med: meditem[i].medicationName,
            }
                   string['medId'] = meditem[i].id
            if(meditem[i].narcoticTrackCt){
              string['count'] = value
            }
            if(meditem[i].narcoticTrackDate){
              string['inDate'] = true
            }
            if(meditem[i].narcoticTrackId){
              string['id'] = ''
               string['requireId'] = true
            }
            if(meditem[i].narcoticTrackTamper){
              string['tamperSealed'] = true
            }
            this.field_item_tracking.push(string);
          }
        }
      } else {
        return;
      }

  
    },
    removeCurrentRow(post) {
      var idx = this.field_medication_tracking.indexOf(post);
      this.field_medication_tracking.splice(idx, 1);
    },
    removeCurrentRowItem(post) {
      var idx = this.field_item_tracking.indexOf(post);
      this.field_item_tracking.splice(idx, 1);
    },
    addNewRow(logbook) {
      if (this.statusSelect == "Daily Narc Use") {
        var setString = {
          med: "",
          used: "",
          waste: "",
          wconn: { value: "mg" }
        };
      } else {
      var setString = {
        med: "",
        count: "",
        inDate: { value: "Yes" },
        tamperSealed: { value: "Yes" }
      };
      }
      this.field_medication_tracking.push({ value: setString });
    },
    addNewRowItem(logbook) {
      if (this.field_item_tracking.length == 1) {
        var setString =
          "med:Child Probe || count:2 || inDate:Yes || tamperSeal:Yes";
      } else {
        var setString =
          "med:Adult Probe || count:1 || inDate:Yes || tamperSeal:Yes";
      }
      this.field_item_tracking.push({ value: setString });
    },
    statusName(data) {
      var obj = this.status.filter(p => {
        return p.value.toString().match(data.toString());
      });
      return obj[0].text;
    },
    /* image */
    imageUUID(newData) {
      this.newDataUUID = newData.uuid[0].value;
    },
    imageFileSRC(newData) {
      this.imageFileName = newData.filename[0].value;
    },
    imageFileFIDData(newData) {
      this.imageFileFID = 0; /* newData.fid[0].value; */
    },
    imageUUIDWitness(newData) {
      this.newDataUUIDWitness = newData.uuid[0].value;
    },
    imageFileSRCWitness(newData) {
      this.imageFileNameWitness = newData.filename[0].value;
    },
    imageFileFIDDataWitness(newData) {
      this.imageFileFIDWitness = 0; /* newData.fid[0].value; */
    },
    processFileUriForUpload(file) {
      var dataSplice = file;
      this.previewImageFileData = file;
      var newplacedData = dataSplice.split(",");
      this.newImageFileData = newplacedData[1];
    },
    processFileUriForUploadWitness(file) {
      var dataSplice = file;
      this.previewImageFileDataWitness = file;
      var newplacedData = dataSplice.split(",");
      this.newImageFileDataWitness = newplacedData[1];
      /* Image End */
    },
    switchToCradleToGrave(){
      bus.$emit("switchEmitDailyNarcLegacy")
    },
    showModalswitchEmitNarc() {
      this.apparatusSelectValue = "";
      this.field_medication_tracking = [];
      this.shiftSelect = "";
      this.checkNumber = [];
      bus.$emit("switchEmitNarc");
    },
    checkWitnessValue() {
      if (this.witnessOrOffGoing.id == undefined) {
        return this.witnessOrOffGoing;
      } else {
        return (
          this.witnessOrOffGoing.firstName +
          " " +
          this.witnessOrOffGoing.lastName
        );
      }
    },
    showModalConfirmationSwitch(event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet(event) {
      this.disableSubmit = false
      this.showModalConfirmation = !this.showModalConfirmation;
       bus.$emit("refreshUserPosts")
      this.showModalswitchEmitNarc();
    },
    submitOfflineNarc() {
      this.disableSubmit = true
      axios({
        method: "post",
        url: "/api/v1/content/narctrack",
        data: {
          title:
            moment().format("MM/DD/YYYY-HH:mm") +
            " | " +
            this.apparatusSelectValueName +
            " | " +
            this.statusSelect +
            " | " +
            this.userData.lastName,
          narctrackEntryType: this.statusSelect,
          narctrackDate: this.formatedDateExport,
          narctrackRunNumber: this.reportRunNumber,
          narctrackApparatusId: this.apparatusSelectValueName,
          narctrackMeds: this.field_medication_tracking,
          narctrackItems: this.field_item_tracking,
          narctrackShift: this.shiftSet,
          narctrackStation: this.stationSelectValue.stationName,
          narctrackStationParent: this.stationSelectValue.stationParent,
          narctrackAuthor:
            this.userData.firstName + " " + this.userData.lastName,
          narctrackAuthorId: this.userData.id,
          narctrackAuthorSignature: this.newImageFileData,
          narctrackWitness: this.checkWitnessValue(),
          narctrackWitnessId: this.witnessOrOffGoing.id,
          narctrackWitnessSignature: this.newImageFileDataWitness,
          narctrackEntryDetails: this.narcDescription,
          narctrackEmailWitness: true,
          narctrackOfflineAttach: this.fileForAttach,
        },
        headers: {
          'Authorization': this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.postData = response.data;
      });
      this.showModalConfirmation = !this.showModalConfirmation;
      bus.$emit("refreshUserPosts");
    },
    submitNarcLog() {
      this.disableSubmit = true
      axios({
        method: "post",
        url: "/api/v1/content/narctrack",
        data: {
          title:
            moment().format("MM/DD/YYYY-HH:mm") +
            " | " +
            this.apparatusSelectValueName +
            " | " +
            this.statusSelect +
            " | " +
            this.userData.lastName,
          narctrackEntryType: this.statusSelect,
          narctrackDate: this.formatedDateExport,
          narctrackRunNumber: this.reportRunNumber,
          narctrackApparatusId: this.apparatusSelectValueName,
          narctrackMeds: this.field_medication_tracking,
          narctrackItems: this.field_item_tracking,
          narctrackShift: this.shiftSet,
          narctrackStation: this.stationSelectValue.stationName,
          narctrackStationParent: this.stationSelectValue.stationParent,
          narctrackAuthor:
            this.userData.firstName + " " + this.userData.lastName,
          narctrackAuthorId: this.userData.id,
          narctrackAuthorSignature: this.newImageFileData,
          narctrackWitness: this.checkWitnessValue(),
          narctrackWitnessId: this.witnessOrOffGoing.id,
          narctrackWitnessSignature: this.newImageFileDataWitness,
          narctrackEntryDetails: this.narcDescription,
          narctrackEmailWitness: this.emailWitness
        },
        headers: {
          'Authorization': this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.postData = response.data;
      this.showModalConfirmation = !this.showModalConfirmation
      
      this.$nextTick(function(){
          this.addModalOpen
      })
      })
    }
    
  }
};
</script>
<style :src="'./customcss/vue-multiselect.min.css'" >

</style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.errorClass {
  border: 1px solid red;
  border-radius: 5px;
}
.modal-body {
  padding: 0;
}
.A-shift {
  background: #fff;
  color: #e2070d;
}
.B-shift {
  background: #fff;
  color: #201b9f;
}
.C-shift {
  background: #fff;
  color: #0fb906;
}
.tab-content.card-body {
  padding: 0;
}
.selectStatusInput {
  margin-top: 10px;
  margin-bottom: 10px;
}
img {
  width: 150px;
  height: auto;
}
img.imageGif {
  width: 100px;
  height: auto;
}
img.imgNarcIcon {
  width: 24px;
  height: 24px;
}
#tablePassDown.container {
  padding: 0;
}
#tablePassDown .card-body {
  padding: 0;
}
.numberInput {
  width: 30px;
}
.medtableCard.card-body {
  padding: 0;
}
.medContainer {
  margin-top: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.medContainer h5 {
  margin-top: 5px;
}
.btn {
  margin-top: 5px;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.dateSeal {
  width: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
  padding: 5px;
}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
button {
  border-style: none;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  font-weight: bold;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.timeMobile {
  display: none;
}
.mobileView {
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk {
    display: none;
  }
  .timeMobile {
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
  .mobileView {
    display: block;
  }
  .desktopView {
    display: none;
  }
}
@media screen and (max-width: 460px) {
  .col.inputColumn {
    padding: 0;
  }
}
</style>
