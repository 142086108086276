<template>
  <div :class="{panelCheck: show}" v-if="apparatusData.length > 0">

      <h5 @click="show = !show" id="dailyvehicleTitle" :class="'text-' + dateDifference(inventoryData.inventorySubDate).bootstrap">
        {{ getDate(inventoryData.inventorySubDate) }} | {{ checkForDBId(inventoryData.inventorySubDbid) }} | 
        {{ checkForInventoryId(inventoryData.inventorySubId) != checkForDBId(inventoryData.inventorySubDbid) ? checkForInventoryId(inventoryData.inventorySubId) + ' | ' : '' }} 
        <span v-if="dateDifference(inventoryData.inventorySubDate) && dateDifference(inventoryData.inventorySubDate).shift"> {{ dateDifference(inventoryData.inventorySubDate).shift }}</span>
        <b-badge v-if="getNotesData.length > 0" variant="danger ml-1">{{ getNotesData.length }}</b-badge> 
        <b-badge v-if="getNotesWarning.length > 0" variant="warning ml-1">{{ getNotesWarning.length }}</b-badge> 
        </h5>
        <span 
        v-if=" 
        inventoryTemplateObj && 
        inventoryTemplateObj.inventoryHead && 
        inventoryTemplateObj.inventoryHead.getpm">
        <h6>Current: {{ checkForHours(inventoryData.inventoryHeadData) ? checkForHours(inventoryData.inventoryHeadData) : 'Missing '  }} 
          {{ checkVehicleType(inventoryData.inventorySubDbid)}} 
          | Till Pm:{{ getTillPm(inventoryData.inventorySubDbid, checkForHours(inventoryData.inventoryHeadData))}} 
          </h6></span>
        <span 
        v-if=" 
        inventoryTemplateObj && 
        inventoryTemplateObj.inventoryHead && 
        inventoryTemplateObj.inventoryHead.getBothpm">
        {{ checkForBothHours(inventoryData.inventoryHeadData, inventoryData.inventorySubDbid) }} 
        </span>
       <span v-if="inventoryData.inventorySubAuthor">Author: {{ inventoryData.inventorySubAuthor }}</span>
       <div><b-btn v-if="show" class="mt-0 pt-0" variant="link" :to="{ path: '/portal/logistics', query: { inventorysub: inventoryData.id } }">View Full Check</b-btn></div>

      <div v-if="show && getNotesData.length > 0" class="show-background">
        <b-badge variant="danger">Critical Info</b-badge>
        <b-row v-for="(error, index) in getNotesData" :key="index" class="mb-2">
          <b-col align="left" class="pl-4">
          <h5 class="mb-0">{{ error.item_name }}</h5>
          <b-container><em v-if="error.item_loc_01"> {{ error.item_loc_01 }}</em><em v-if="error.item_loc_02 != 'NULL'">, {{ error.item_loc_02 }} </em><em v-if="error.item_loc_03 != 'NULL'">, {{ error.item_loc_03 }} </em>
          </b-container>
          <b-container v-if="checkErrorIssues(error).length > 0">
            <b-row v-for="(sub, index) in checkErrorIssues(error)" :key="index">
            <span class="ml-3 mr-1 font-weight-bold text-danger">{{ sub.type }}: </span> {{ sub.message }}
            </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
            <div v-if="show && getNotesWarning.length > 0" class="show-background">
          <b-badge variant="warning">Warning Info</b-badge>
        <b-row v-for="(error, index) in getNotesWarning" :key="index" class="mb-2">
          <b-col align="left" class="pl-4">
          <h5 class="mb-0">{{ error.item_name }}</h5>
          <b-container><em v-if="error.item_loc_01"> {{ error.item_loc_01 }}</em><em v-if="error.item_loc_02 != 'NULL'">, {{ error.item_loc_02 }} </em><em v-if="error.item_loc_03 != 'NULL'">, {{ error.item_loc_03 }} </em>
          </b-container>
          <b-container v-if="checkErrorIssues(error).length > 0">
            <b-row v-for="(sub, index) in checkErrorIssues(error)" :key="index">
            <span class="ml-3 mr-1 font-weight-bold text-warning">{{ sub.type }}: </span> {{ sub.message }}
            </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
</div>
</template>


<script>
/*

*/
import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import { bus } from '../../../main.js'
export default {
  props:{
      inventoryData: {
          type: Object
      },
      showPost: {
          type: Boolean,
          default: false,
      },
  },
components:{
},
  data () {
    return {
      trueBoo: false,
      editPost: false,
      show : false,
      nextPM: '',
      inventoryTemplateObj: null,
    }
  },
  created(){
    this.show = this.showPost
  },
  watcher: {

  },
  computed: {
    ...mapState([
          'getSessionData',
          'userData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'inventoryTemplateData',
          'siteconfig'
      ]),
      getNotesData(){
        if(this.inventoryData && this.inventoryData.inventorySubData){
          var errors = this.inventoryData.inventorySubData.filter(p => {
            if(p.item_type != 'asset'){
            return p.notes != '' 
                  || p.item_count < p.item_quantity
                  || (p.expDate && moment(p.expire_date).diff(moment(), "days") < 1 ? true : false)
            } else {
              return p.notes != '' 
            }
          })
            return errors
        } else if (this.inventoryData && this.inventoryData.inventoryNotifications && this.inventoryData.inventoryNotifications.critical){
          return this.inventoryData.inventoryNotifications.critical
        } return []
        },
        getNotesWarning(){
        if(this.inventoryData && this.inventoryData.inventorySubData){
          var errors = this.inventoryData.inventorySubData.filter(p => {
            if(p.item_type != 'asset'){
              var day = p.expDate && moment(p.expire_date).diff(moment(), "days")
              if(day > 0 && day < 30){

              return  p
            }
            }
          })
          return errors
        } else if (this.inventoryData && this.inventoryData.inventoryNotifications && this.inventoryData.inventoryNotifications.warnings){
          return this.inventoryData.inventoryNotifications.warnings
        } return []
  },

  },
  methods: {
    checkErrorIssues(error){
      var errorHold = []
      if(error != 'asset'){
      if(error.item_count < error.item_quantity){
        errorHold.push({type: 'Levels', message: ' Need ' + (error.item_quantity - error.item_count) + ' more'})
      }
      if(error.expDate && moment(error.expire_date).diff(moment(), "days") < 30 ? true : false){
        errorHold.push({type: 'Expires', message: ' Expires in ' + moment(error.expire_date).diff(moment(), "days")  + ' days'})
      }
      }
      if(error.notes){
        errorHold.push({type: 'Note', message: ' ' + error.notes})
      }
      return errorHold
    },
    checkForHours(data){
      if(data && data.currentHoursMiles){
        return data.currentHoursMiles
      } return false
    },
    checkForBothHours(data, id){
      var type = this.checkVehicleType(id)
      if(type){
        if(type == 'Hours'){
          var currentH = data.currentHoursBoth ? data.currentHoursBoth : 'Missing Hours'
          var holdH = currentH != 'Missing Hours' ? currentH : null
          var tillH = this.getTillPm(id, holdH)
          var currentM = data.currentMilesBoth ? data.currentMilesBoth : 'Missing Miles'
          return 'Hours: '  + currentH + ' | TillPm: ' + tillH + ' | Miles: ' + currentM
        } else if (type == 'Miles'){
          var currentM = data.currentMilesBoth ? data.currentMilesBoth : 'Missing Miles'
          var holdM = currentM != 'Missing Miles' ? currentM : null
          var tillH = this.getTillPm(id, holdM)
          var currentH = data.currentHoursBoth ? data.currentHoursBoth : 'Missing Hours'
          return 'Miles: '  + currentM + ' | TillPm: ' + tillH + ' | Hours: ' + currentH
        } else {
          return 'PM Error Unable to Load'
        }
      } return 'PM Error Missing Data'

    },
    getTillPm(id, hours){
       var veh = this.apparatusData.find(p => p.id == id)
       if(veh && hours){
          var vehHours = veh.apparatusNextPm - hours
          vehHours = Math.floor(vehHours)
          var holdType = veh.apparatusPMCheckType
          return vehHours + ' ' + holdType
       } else { 
         return ' Missing Data'
       }
    },
    dateDifference(data){
        var payroll = this.siteconfig.payroll
        var selectedVarDate = moment(data);
        var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
        var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
        var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
        var dateTenDigit = dateDiffCalNumber % 10;
        var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
        var shift = payroll.shifts[roundedDated]
        return shift
    },
    checkVehicleType(data){
      var veh = this.apparatusData.find(p => p.id == data)
      if(veh){
          var holdType = veh.apparatusPMCheckType
          return holdType
       } else {
         return ''
       }
    },
    checkForInventoryId(id){
      if(id){
        var obj = this.inventoryTemplateData.find(p => p.id == id)
        if(obj){
          this.inventoryTemplateObj = obj
          return obj.inventoryName
        } return 'Unable to Locate Template'
      } return 'Unable to Locate Template'
    },
    checkForDBId(id){
      if(id){
        var apparatus = this.apparatusData.find(p => p.id == id)
        if(apparatus){
          this.inventoryType == 'apparatus'
          return apparatus.apparatusName
        }
        var station = this.stationsData.find(p => p.id == id)
        if(station){
          this.inventoryType == 'station'
          return station.stationName
        }
      } return 'No Tagged ID'
    },
    getDate(data){
      return moment(data).format('MM/DD/YYYY')
    },
    getStationParent(data){
        if(data != undefined && data != ''){
        var parent = this.stationsData.filter(p => {
            return p.stationName == data
        })
        if(parent[0].stationParent != ''){
        return parent[0].stationParent }
        else {
          return parent[0].stationName
        }
        } else {
           return ''
        }
      },
},


}
</script>

<style scoped>
.flagWarning {
  color: red;
}
input.big {

    padding-left: 4px;
    border-radius: 5px;
  width: 100px;
}
input.small {
  margin: 0;
  padding-left: 4px;
  border-radius: 5px;
  width: 70px;
}
select {
  padding-left: 4px;
  border-radius: 5px;
}
#confirmationTable {
  width: 100%;
  margin: 0 auto 0;
  text-align: center;
}
.headerContainer {
  border-radius: 10px 10px 0 0;
  padding-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.A-shift-header {
  background: #E2070D;
}
.B-shift-header {
  background: #007bff;
}
.C-shift-header {
  background: #0FB906;
}
.headerContainer h3 {
  color: #eee;
  font-weight: bold;
  text-shadow: 2px 1px #333;
}
.headerContainer h5 {
  color: #eee;
  padding-top: 4px;
  font-weight: bold;
  text-shadow: 2px 1px #333;
}
.headerRows h6 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #aaa;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}
.subHeaderRows h6 {
  margin-top: -2px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  
}
.panelCheck{
  border: 1px solid #777;
  background-color: #f7f6f6;
  border-radius: 0 0 5px 5px;
  padding-bottom: 20px;
}
.checkDetails{
  text-align: center;
}
.checkDetailsDiv {
  text-align: left;
  margin-left: 20px;
}
.checkDetailsWrap {
  border: 1px solid #aaa;
  border-radius: 6px;
}
.checkDetails h5 {
  background: #aaa;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px 5px 0 0;
}
#details {
  background: #fff;
}

p.defDescrip {
  border: 1px solid #E2070D;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: #0FB906;
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
span.numberDetail {
  background: rgb(90, 90, 90);
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
span.No {
  background: #E2070D;
  color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
}
span h6 {
  margin-top: 0;
  padding-bottom: 5px;
  text-align: center;
  width: 80%;
  margin: 0 auto 0;

}
.editRowValue {
  margin-top: 20px;
}
.input-group{
  margin-bottom: 10px;
}
#tablePassDown{
  width:80%;
  padding: 5px;
}

.headingRow {
  color: #777;
  border-top: 1px solid grey;
  margin-bottom: 5px;
}
.headingRow  h5{
  margin-bottom: 0;
}
#dailyvehicleTitle {
  text-align: center;
  cursor: pointer;
  width: 80%;
  background: #fff;
  margin: 0 auto 0;
  width: 100%;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 5px;

}
#dailyvehicleTitle:hover {
  background: #ddd;
  border-radius: 5px;

}
 #dailyvehicleUrl {
   text-align: center;
 }
 a {
   color: #E2070D;
   text-decoration: none;
 }
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
text-align: center;
margin-top: 10px;
}
.show-background {
width: 90%;
background: #fff;
margin: 0 auto 0;
padding-bottom: 10px;
margin-bottom: 0;
border-radius: 0 0 10px 10px;
}
.A-shift{
color: #e2070d;

}
.B-shift{
color: #007bff;
}
.C-shift{
color: #0FB906;
}
@media screen and (max-width: 768px) {
  #dailyvehicleTitle  {
    width: 95%;

  }
  span h6 {
    width: 95%;
  }
}
  @media screen and (max-width: 460px) {
    .show-background {
    width: 100%;
    }
  .checkDetails.container {
    padding-left: 0;
    padding-right: 0;
  }
}


</style>
