<template>
  <div class="hello">
   <b-card>   
  <b-container><b-row><b-col v-if="notification.notificationAuthorId" cols="*"><img class="notification-img" :src="imageSrcUrl(notification.notificationAuthorId)" /></b-col><b-col>

      <h4>{{ notification.title }}</h4>
  <span class="notificationSubData"> {{ notification.notificationAuthor }} | {{ notificationTimeAdjust(notification.created) }}</span>
  </b-col></b-row></b-container></b-card>
  <b-card class="mt-2"><b-container>
  <div class="notificationBody" v-if="notification.notificationBody">
  <div v-html="notification.notificationBody"></div></div>
  <div v-if="notification.notificationPicture.length > 0">
    <b-carousel id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                  controls
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template v-for="(pic, index) in notification.notificationPicture">
                        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" >
                            
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
  </div>
  <div v-if="false"><div v-for="img in notificationFile" :key="img.index"><a target="_blank" :href="imageSrcUrl(img.url)">{{ img.name }}</a></div></div>
  <div v-if="notification.notificationVideo.length > 0">
        <b-carousel id="carousel2"
                                 style="text-shadow: 1px 1px 2px #333;"
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template  v-for="(video, index) in notification.notificationVideo">
                        <b-carousel-slide :key="index">
                            <div slot="img" class="videoWrapper">
                              <iframe  v-if="video" class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
 
  </div>
  <b-container>
   <b-card v-if="showFile">
      <b-btn variant="primary" @click="showFile = false, iframePdf = ''">Hide Form</b-btn>
      <b-btn variant="info" :href="iframePdf" target="_blank">Open in New Tab (mobile)</b-btn>
      <b-embed v-show="true" :src="iframePdf" />
      </b-card>
       <b-table v-if="notification.notificationFile.length > 0" stacked="md"  hover striped :items="notification.notificationFile">
                 <template slot="url" slot-scope="data">
          <b-btn class="m-0" size="sm" variant="link" @click="viewFileIframe(data.item.url)">view</b-btn>
        </template>
       </b-table>
  </b-container>
  </b-container>
         </b-card>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'hello',
  props: ['notification'],
  components: {
  },
  data () {
    return {

      iframePdf: '',
      showFile: '',
    }
  },
  created(){
    console.log(this.notification)
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'getSessionData',
        'deptContactData',
        'siteconfig'
      ]),


  },
  watch: {
 
},
  methods: {
    imageSrcUrl(data){
      var user = this.deptContactData.filter( p => 
      { return p.id == data})
      if(user != undefined && user.length > 0 && user[0].picture){
      var picture = this.userPhotoLink(user[0].picture)
      return picture
      } else {
        return this.siteconfig.deptStore + 'files/default_images/user.png'
      }
    },
    notificationTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY - HH:mm')
    },
      userPhotoLink(fileName){
    if(fileName.charAt(0) == '/'){
  return this.siteconfig.deptStore + fileName.replace('/', '')
    } else {
      return  this.siteconfig.deptStore + fileName
    }
  },
    viewFileIframe(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              this.iframePdf = response.data.signedRequest
              this.showFile = true

       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
       getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
      },



 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.notification-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}

.notificationTitle {
  color: red;
}
.notificationSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.notificationSubData {
  font-size: 16px;
  color: #777;
}
.notificationBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}
</style>
