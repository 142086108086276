<template>
  <b-card no-body header-tag="header" class="cardClassForChat">
    <div  slot="header"  v-if="singleViewToggle">
                   <div v-if="displayToggle == 'newChat' || displayToggle == 'newGroup'" class="float-left notification-updates views secondary-user-menu-toolbar mobileOnlyIcon" @mouseover="hoverover = 'clear'" @mouseout="hoverover = ''"  :class="hoverover == 'clear' ? 'hoverClass' : ''">
           <img src="/images/siteicons/message-icon-close.png" class="float-left"
           title="Messages" height="32" width="32" @click="displayToggle = '', hoverover = ''">


             </div>
             <div v-if="false" class="notification-updates views secondary-user-menu-toolbar mobileOnlyIcon"  :class="hoverover == 'message' ? 'hoverClass' : ''">
                 <div  v-if="unreadMessages.length > 0 || unreadRoomMessages.length > 0" class="notificationWrapperMobile" >
           <b-badge class="messageNotifications" v-if="unreadMessages.length > 0" pill variant="light"> {{ unreadMessages.length }} </b-badge>
                       
                                 </div>
           <img src="/images/siteicons/message-icon-blue.png" :class="mouseover == 'message' ? 'mouseOverClass' : ''"
               @mouseover="hoverover = 'message'" @mouseout="hoverover = ''"
           title="Messages" height="32" width="32" @click="toggle('showMessages')">


       </div>
                    <div v-if="false" class="notification-updates views secondary-user-menu-toolbar mobileOnlyIcon" :class="hoverover == 'room' ? 'hoverClass' : ''">
                 <div  v-if="unreadRoomMessages.length > 0" class="notificationWrapperMobile" >
           <b-badge class="messageNotifications" v-if="unreadMessages.length > 0" pill variant="light"> {{ unreadRoomMessages.length }} </b-badge>
                       
                                 </div>
           <img v-if="false" src="/images/siteicons/message-icon-room.png" :class="mouseover == 'room' ? 'mouseOverClass' : ''"
               @mouseover="hoverover = 'room'" @mouseout="hoverover = ''"
           title="Rooms" height="32" width="32" @click="toggle('showRooms')">


       </div>
                    <div v-if="false" class="notification-updates views secondary-user-menu-toolbar mobileOnlyIcon" :class="hoverover == 'group' ? 'hoverClass' : ''">
                 <div  v-if="unreadGroupMessages.length > 0" class="notificationWrapperMobile" >
           <b-badge class="messageNotifications" v-if="unreadMessages.length > 0" pill variant="light"> {{ unreadGroupMessages.length }} </b-badge>
                       
                                 </div>
           <img src="/images/siteicons/message-icon-group.png" :class="mouseover == 'group' ? 'mouseOverClass' : ''"
               @mouseover="hoverover = 'group'" @mouseout="hoverover = ''"
           title="Groups" height="32" width="32" @click="toggle('showGroups')">


       </div>
      <div v-if="false" class="notification-updates views secondary-user-menu-toolbar mobileOnlyIcon" :class="hoverover == 'board' ? 'hoverClass' : ''">
                 <div  v-if="unreadGroupMessages.length > 0" class="notificationWrapperMobile" >
           <b-badge class="messageNotifications" v-if="unreadMessages.length > 0" pill variant="light"> {{ unreadGroupMessages.length }} </b-badge>
                       
                                 </div>
           <img src="/images/siteicons/message-icon-board.png" :class="mouseover == 'board' ? 'mouseOverClass' : ''"
               @mouseover="hoverover = 'board'" @mouseout="hoverover = ''"
           title="Boards" height="32" width="32" @click="toggle('showBoards')">


       </div>
         <b-dropdown class="float-right" id="ddown1" text="New" size="sm" variant="dark">
               <b-dropdown-item-button @click="toggle('newChat')"><img class="menuIcon" src="/images/siteicons/message-icon-blue.png" />New Message</b-dropdown-item-button>
                <b-dropdown-item-button v-if="false" @click="toggle('newRoom')"><img class="menuIcon" src="/images/siteicons/message-icon-room.png" />Create Room</b-dropdown-item-button>
               <b-dropdown-item-button @click="toggle('newGroup')"><img class="menuIcon" src="/images/siteicons/message-icon-group.png" />Create Group</b-dropdown-item-button>
                <b-dropdown-item-button v-if="false" @click="toggle('newRoom')"><img class="menuIcon" src="/images/siteicons/message-icon-board.png" />New Board</b-dropdown-item-button>          
          </b-dropdown>
        </div>
     <div>
       
    <b-container  v-show="!(displayToggle == 'newChat' || displayToggle == 'newRoom' || displayToggle == 'newGroup')" fluid class="px-0 chatWrapper">
    <div v-if="allMessageArray.length > 0 && dataReset" 
          v-for="(convo, index) in allMessageArray" 
          :key="index" 
          :class="'colorBefore-' + convo.messageType" 
          :id="'exPopover1-'+index"
          @mouseover="mouseover = index" 
          @mouseout="mouseover = null"
        
          >

      <chatdata  v-if="convo.messageType == 'message'" v-show="checkConvoId(convo.message.conversationId)" :convo="convo" :sock="sock" @removeUserFromConvo="removeUserFromConvo(convo, $event)" :deleteShow="mouseover == index ? true : false" />
    </div>
    </b-container>
    <div v-show="displayToggle == 'newChat' || displayToggle == 'newRoom' || displayToggle == 'newGroup'" class="my-5">
        <message :showConvo="true" :convo="displayToggle" @newReply="socketRefresh" :sock="sock" />
    </div> 
    </div>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import { bus } from '../../../main'
import ChatData from './chatdata.vue'
import Message from './message.vue'
/* const sock = socketio('/user-connections', {  autoConnect: false }) */

export default {
props:['messagePanelStatus', 'sock'],
components:{
  'chatdata' : ChatData,
  'message' : Message,


},
  data () {
    return {
        showModal: false,
        dataReset: true,
        search:"",
        slide: 0,
        slide2: 0,
        newChat: false,
        messageUsers: [],
        newMessage: '',
        hoverover: '',
        mouseover: null,
        singleView: '',
        singleViewRoom: '',
        singleViewGroup: '',
        singleViewToggle: true,
        displayToggle: '',
        sockConnect: false,
    }
  },
  created(){

    bus.$on('toggleSingleViewChat', this.setSingleView)
    bus.$on('toggleSingleViewRoom', this.setSingleViewRoom)
    bus.$on('toggleSingleViewGroup', this.setSingleViewGroup)
    bus.$on('refresh user message list', this.refreshMessages)
    bus.$on('refreshRoomData', this.refreshRooms)
    bus.$on('sendUserMessage', this.sendUserMessage)
    bus.$on('resetGroupPosts', this.switchGroupPosts)
    bus.$on('LoggingOutUserSocket', this.socketDisconnection)
    bus.$on('emitCalendarEvent', this.socketCalendarRefresh)
  },
  mounted(){
   //  this.sock.open();
   //  this.sock.emit('enter conversation', this.userData.id)
      this.sock.on('checkconnection', () => {
        //  console.log(this.sock)
          //this.sock.emit('enter conversation', this.userData.id)
        this.sock.emit('connectiongood', { data: this.userData.firstName + ' ' + this.userData.lastName + ' is Responding', id: this.userData.id})
      })
     this.sock.on('refreshUserList', () => {
            var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': this.getSessionData,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
      var payload = {headers: headers}
      this.$nextTick(function(){
      this.$store.dispatch('LOAD_ACTIVE_USERS', payload)
      })

    })
     this.sock.on('error', function(err){
       console.log(err)
     })
     this.sock.on('userLeft', () => {
       console.log('USER LEFT')
       this.refreshMessages()
     });
      this.sock.on('refresh messages user', (conversation) => {
          this.$notify({
          group: 'notesAlert',
          type: 'warning',
          title: 'New Message',
          text: '<strong>From:' + conversation.author + '</strong><br>' + conversation.msg
          });
          this.refreshMessages()
      })
    this.sock.on('refresh group messages user', (conversation) => {
          this.$notify({
          group: 'notesAlert',
          type: 'warning',
          title: 'New Group Message',
          text: '<strong>From:' + conversation.author + '</strong><br>' + conversation.msg
          });
          this.refreshGroups()
      })
    this.sock.on('refresh room data', () => {
      this.refreshRooms()
    })
    this.sock.on('preplanPull', (data) => {
      console.log('PREPLAN Viewed from Bridge')
      console.log(data)
      var vm = this
      var headers = {
                      'content-type': 'application/json', 
                      'Authorization': this.getSessionData,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} 
      if(this.userData.id == data.userid){
      axios({
        method: 'get',
        url: '/api/v1/preplan/response/activepreplans',
        headers: headers
      }).then(response => {
        var preplans = response.data
        if(preplans && preplans.length > 0){
          console.log('POSTIVE PRE PLANS')
          var preplan = preplans.find(p => p.id == data.preplanid)
          console.log(preplan)
          vm.$emit('viewPreplan', preplan)
        }
      })
      }
      
    })
    this.sock.on('refreshCalendar', () => {
      console.log('REFRESH CALENDAR')
       bus.$emit('refresh-full-calendar')
       bus.$emit('refresh-calendar-list')
    })
    
  },
  
  watcher: {
    statusOfPanel(data){
      if(data == false){
        this.displayToggle = ''
        this.singleView = ''
        this.singleViewRoom = ''
        this.singleViewGroup = ''
        this.singleViewToggle = true
        this.dataReset = false
        this.$nextTick(function(){
          this.dataReset = true
        })
      }
    }
  },
    computed:{
      ...mapState([
        'deptContactData',
        'userMessages',
        'getSessionData',
        'userData',
        'unreadMessages',
        'roomData',
        'groupData',
        'unreadRoomMessages',
        'unreadGroupMessages',
      ]),
      
      statusOfPanel(){
        return this.messagePanelStatus
      },
      allMessageArray(){
        var array = null
        array = []
        if(this.userMessages != undefined && this.userMessages.conversations != undefined){
          var messages = _.cloneDeep(this.userMessages.conversations)
                  for(var i = 0; i < messages.length; i++){
        array.push(messages[i])
        }
        } 
        if(this.roomData != undefined && this.roomData.rooms != undefined){
        var rooms = _.cloneDeep(this.roomData.rooms)
     
        for(var i = 0; i < rooms.length; i++){
        array.push(rooms[i])
        }
        }
        if(this.groupData != undefined && this.groupData.groups != undefined){
        var groups = _.cloneDeep(this.groupData.groups)

        for(var i = 0; i < groups.length; i++){
        array.push(groups[i])
        }
        }
        var newArray = _.orderBy(array, ['updatedAt'], ['desc'])
        return newArray
      },
      userMessagesArray(){
        var array = []
        var array = this.userMessages.conversations
        return _.orderBy(array, function(e) { if(e.message != undefined){ return e.message.createdAt }}, ['desc'])
      },
      setRows(){
        if(this.newMessage == ''){
          return 1
        } else {
          return 4
        }
      },
      checkForCompletion(){
       if(this.displayToggle == 'newRoom' && (this.roomTitle == '' || this.newMessage == '')){

         return true
       } 
      if(this.displayToggle == 'newChat' && (this.messageUsers.length == 0 || this.newMessage == '')){

         return true
       } 
       else {
         return false
       }
     },
  },
   methods:{
     swipeHandlers(direction, event){
   //    console.log(direction)
     //  console.log(event)
       if(direction == 'left'){
         this.mouseover = event
       } else {
         this.mouseover = ''
       }
     },
     socketDisconnection(){
       console.log('SOCKET CLOSED')
      // console.log(this.sock)
       this.sock.emit('disconnect')
       
       this.sock.close()
 //     this.$nextTick(function(){
  //      this.sock = {}
   //   }) 
     },
     hideMessagePanel(){
       bus.$emit('toggleMessagePanel')
     },
     socketCalendarRefresh(){
       this.sock.emit('refreshCalendarEvents')
     },
     switchGroupPosts(){
       this.singleViewGroup = ''
       this.singleViewToggle = true
     },
      sendUserMessage(data){
        this.displayToggle = 'newChat'
       
      },
      playSound() {
      var sound = './sounds/pull-out.mp3'
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
      }
      },
     checkConvoId(id){
       if(this.singleView == '' && this.singleViewRoom == '' && this.singleViewGroup == '' && this.displayToggle == ''){
         return true
       } else if (this.singleView == '' && this.singleViewRoom == '' && this.singleViewGroup == '' && this.displayToggle == 'showMessages'){
        return true
       }
       if(this.singleView == id){
         return true
       } else {
         return false
       }
     },
     toggle(item){
       this.displayToggle = item
     },
     setSingleView(value){
       if(value != undefined && value != ''){
       this.singleView = value
       this.singleViewToggle = false
       } else {
        this.singleView = ''
       this.singleViewToggle = true
       this.$store.dispatch('NEW_MESSAGE_TRACKING', 'REFRESH')
        this.refreshMessages()
       }
     },
     setSingleViewRoom(value){
       if(value != undefined && value != ''){
       this.singleViewRoom = value
       this.singleViewToggle = false
       } else {
        this.singleViewRoom = ''
       this.singleViewToggle = true
        this.refreshRooms()
       }
     },
    setSingleViewGroup(value){
       if(value != undefined && value != ''){
       this.singleViewGroup = value
       this.singleViewToggle = false
       } else {
        this.singleViewGroup = ''
       this.singleViewToggle = true
        this.refreshGroups()
       }
     },
     removeUserFromConvo(convo, user){
       if(convo.participants.length > 2){
        axios({
         method: 'patch',
         url: '/api/v1/chat/convo/remove/' + convo.id,
         headers: {
           'content-type': 'application/json', 
           'Authorization': this.getSessionData
         }
       }).then( response => {
        console.log(response.data)
        this.sock.emit('leave conversation chat', convo.id)
        this.refreshMessages()
      
       })
         //console.log('Unsubscribe')
       } else {
           axios({
         method: 'delete',
         url: '/api/v1/chat/convo/' + convo.id,
         headers: {
           'content-type': 'application/json', 
           'Authorization': this.getSessionData
         }
       }).then( response => {
      //  console.log(response.data)
        this.sock.emit('leave conversation chat', convo.id)
        this.refreshMessages()
      
       })
        // console.log('Delete')
       }
   //    console.log(convo)
     //  console.log(user)
     },
     refreshMessages(){
     //  console.log('REFRESH MESSAGES GETTING CALLED')
    //   console.log(this.getSessionData)
       this.dataReset = false
       var headers = { headers: {                       
                      'content-type': 'application/json', 
                      'Authorization': this.getSessionData,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
       var payload = { headers: headers }
       
       this.$store.dispatch('LOAD_USER_MESSAGES_EXP', payload)
       this.$nextTick(function(){
         this.dataReset = true
       })
     },
     refreshRooms(){
      //    console.log(this.getSessionData)
       var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData} }
       var payload = { headers: headers }
       this.$store.dispatch('LOAD_ROOMS_EXP', payload)
     },
    refreshGroups(){
     //    console.log(this.getSessionData)
       var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData} }
       var payload = { headers: headers }
       this.$store.dispatch('LOAD_GROUP_MESSAGES_EXP', payload)
     },
     socketRefresh(value){
         if(value.id == 'newChat'){
         for(var i = 0; i < value.userTags.length; i ++){
          var convo = { id: value.userTags[i], author: this.userData.firstName + ' ' + this.userData.lastName, msg: this.newMessage }
          this.sock.emit('new message user', convo)
         } 
         this.refreshMessages()
         this.displayToggle = 'showMessages'
         }         
     
   }
}
   
}
</script>

<style scoped>
.mouseOverClass {
    -webkit-box-shadow: 0 0 10px #838383;
    box-shadow: 0 0  10px #838383;
    border-radius: 50%;
}

.hoverClass img {
  width: 48px;
  height: auto;
}
.unHoverClass {
  width: 28px;
  height: auto;
}
.colorBefore-message {
  border-left: 4px solid #007bff;
}
.colorBefore-room {
  border-left: 4px solid #28a745;
}
.colorBefore-group {
  border-left: 4px solid #007bff;
}
.colorBefore-board {
  border-left: 4px solid #17a2b8;
}
.notification-updates {
  margin-right: 10px;
  margin-bottom: 5px;
}
div.hoverClass {
  margin-top: -6px;
  margin-left: -3px;
  margin-right: -3px;
  margin-bottom: -7px;
}
.mobileSecondaryAddTool{
  display: block;
}
.mobileOnlyIcon {
  display: inline-table;
}
.notificationWrapperMobile {
  position: absolute;
  margin: -4px 26px;
  font-size: 10px;
}
.menuIcon {
  max-width: 32px;
    margin-right: 10px;
    opacity: 1.0;
}
.chatWrapper {
  width: 365px;;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
  padding-right: 17px;
  
}
.chatFullHeader{
  padding-top: 10px;
  background: #ddd;
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-right: -3px;
  border-right: 1px solid rgba(119, 119, 119, 0.288);
  position: fixed;
    margin-top: -52px;
    overflow: hidden;
    width: 362px;
    z-index: 41;
}
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media screen and (max-width: 480px) {
  .deleteMessage {
position: fixed;
    right: 25px;
    background: white;
    padding: 5px 0 0 0;
}
.chatWrapper {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  padding-bottom: 100px;
}
}
</style>
