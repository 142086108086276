<template>
<div id="homelanding">
  <b-container fluid class="px-0 px-lg-5">
  <b-row>
  <b-col xl="8" lg="12" order="3" order-sm="3" order-lg="1" class="pt-2" v-if="!fullScreenStaffingToggle">
  <b-carousel
              class="desktopDisplay"
              v-if="bannerImagesSort != 0 && !siteconfig.hideBannerImages"
              id="carousel1"
              style="text-shadow: 1px 1px 2px #333;"
              controls
              indicators
              background="#ababab"
              :interval="4000"
              img-width="1024"
              img-height="480"
              v-model="slide"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
  >

    <!-- Text slides with image -->
    <template v-for="image in bannerImagesSort">
    <b-carousel-slide :key="image.index" 
                      :caption="image.bannerItem"
                      :text="image.bannerDescription"
                      :img-src="siteconfig.deptStore + image.bannerPicture"
    ></b-carousel-slide>
  </template>
  </b-carousel>
  <b-carousel
                class="mobileDisplay"
              v-if="bannerImagesSort != 0 && !siteconfig.hideBannerImages"
              id="carousel1"
              style="text-shadow: 1px 1px 2px #333;"
              controls
              indicators
              background="#ababab"
              :interval="4000"
              img-width="1024"
              img-height="480"
              v-model="slide2"
              @sliding-start="onSlideStart2"
              @sliding-end="onSlideEnd2"
  >

    <!-- Text slides with image -->
    <template v-for="image in bannerImagesSort">
    <b-carousel-slide :key="image.index"  
                      :img-src="siteconfig.deptStore + image.bannerPicture"
    ></b-carousel-slide>
  </template>
  </b-carousel>
  <div class="carouselAdd" v-if="!siteconfig.hideBannerImages">
  <b-btn @click="toggleAddBanner" v-if="userData.role == 'admin' || userData.role == 'adminchief'" variant="outline-light">Add</b-btn>
  <b-btn :to="'/admin/banner'" v-if="userData.role == 'admin' || userData.role == 'adminchief'"  variant="outline-dark">Edit</b-btn>
  </div>
</b-col>
<b-col xl="4" lg="12" order="2" order-sm="2" order-lg="2" class="pb-2 pt-4 mb-3" v-if="!fullScreenStaffingToggle && !siteconfig.hideBlogs">
  <b-btn variant="primary" v-if="false" size="sm" href="mailto:support@statportals.com">Email for Technical Support</b-btn>
  <b-container fluid>
    <b-row class="m-0 p-0">
  <b-col xl="12" lg="4" md="4" sm="12" v-if="departmentBlogs.length > 0" v-for="blog in filtereddepartmentBlogs" :key="blog.id">

    <blogs v-if="blog" :blog="blog" class="mt-2" />
  </b-col>
  </b-row>
  </b-container>
    <b-pagination align="center" v-if="departmentBlogs.length > 0" size="md" :total-rows="departmentBlogs.length" v-model="currentPage" :per-page="3">
    </b-pagination>
</b-col>
<b-col order="1" order-sm="1" order-lg="3" lg="12" class="pb-2 mb-3">
<calendar class="calendarClass" :all-events="events" :calendarHeader="calendarHeader" :displayWeekNumber="false" v-if="showCalendar && !siteconfig.staffingMain"></calendar>
      <staffingPortal v-if="showCalendar && siteconfig.staffingMain" :class="fullScreenStaffingToggle ? 'fullScreenStaffing' : ''" />
  </b-col>
</b-row>
</b-container>
<devmodal v-if="showDevModal" :showModal="showDevModal" />
<banner v-if="addBanner" :showModal="addBanner" />


</div>
</template>

<script>
import Calendar from './calendarFull/Calendar.vue'
import devModal from './addNew/newDevReport.vue'
import { mapState } from 'vuex'
import { bus } from '../main.js'
import axios from 'axios'
import moment from 'moment'
import Blogs from './tracking/blogs/blogsFront.vue'
import CalendarAddModal from './addNew/newCalendarPostFull.vue'
import Banner from './addNew/newBanner.vue'
import staffingPortal from './portals/staffingPortal.vue'

export default {
  name: 'appLoaded',
  components: {
  'blogs' : Blogs,
  'banner': Banner,
  'calendar' : Calendar,
  'devmodal' : devModal,
  'calendaraddmodal' : CalendarAddModal,
  staffingPortal
  },
  data() {
    return {
  slide: 0,
  sliding: null,
  slide2: 0,
  sliding2: null,
  dataTrimmed: true,
  currentPage: 1,
  dataRange: 2500,
  flagOnce: true,
  events: [],
  showCalendar: true,
  calendarHeader: 'Department Wide',
  showDevModal: false,
  betaTestData: [],
  showTestModal: false,
  sendData: '',
  addBanner: false,
  fullScreenStaffingToggle: false,
}
},
created () {
    bus.$on('closeDev', this.showDev)
    bus.$on('switchEmitBanner', this.toggleAddBanner) 
    bus.$on('toggleFullScreenStaffing', this.toggleFullScreenStaffing)
},
watch: {
  fullCalendarEvents(newData){
    this.setNewEvents(newData, this.fullCalendarEventsPrivate)
  },
  fullCalendarEventsPrivate(newData){
    this.setNewEvents(this.fullCalendarEvents, newData)
  },
  eventTags(newData){
    this.setNewEvents(this.fullCalendarEvents, this.fullCalendarEventsPrivate)
  },
},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'fullCalendarEvents',
    'fullCalendarEventsPrivate',
    'getSessionData',
    'userData',
    'eventTags',
    'siteconfig'
  ]),
  filtereddepartmentBlogs(){
    var first = (this.currentPage - 1) * 3 // 1 = 0 2 = 3 3 = 6 4 = 9
    var second = this.currentPage * 3
    if(this.departmentBlogs.length > 3){
      var array = _.orderBy(this.departmentBlogs, ['blogDate'], ['desc'])
    return [... new Set(array.slice(first, second))]
    } else {
      return _.orderBy(this.departmentBlogs, ['blogDate'], ['desc'])
    }
  },
  bannerImagesSort(){
    var banners = _.orderBy(this.bannerImages, ['updatedAt'], ['desc'])
    return banners.slice(0, 9)
  }

},
mounted(){

},
methods: {
  toggleFullScreenStaffing(){
    this.fullScreenStaffingToggle = !this.fullScreenStaffingToggle
  },
  showDev(event){
    this.showDevModal = !this.showDevModal
  },
  setNewEvents(data, dataSec){
    var data = data.concat(dataSec)
    var map = new Map
    var newArr = [];
    for(var i = 0; i < data.length; i ++ ){
      var obj = data[i];
      var day = moment(data[i].eventDate).format('YYYY-MM-DD')
      if(map[day] === undefined || map[obj.eventTagParent] === null){
      map[day] = [];
      map[day].push(obj.eventTagParent);
      newArr.push({ day: day, tag: obj.eventTagParent });
  }else if(!map[day].includes(obj.eventTagParent)){
      map[day].push(obj.eventTagParent);
      newArr.push({ day: day, tag: obj.eventTagParent });
    }
  }
  var filteredCalEve = []
  for(var i = 0; i < newArr.length; i ++ ){
    var descriptionBody = [...new Set(data.filter( p => {
      return moment(p.eventDate).format('YYYY-MM-DD').match(newArr[i].day)
    }))]
    var descriptionTag = [...new Set(descriptionBody.filter(p => {
      return p.eventTagParent.includes(newArr[i].tag)
    }))]
    var newEvent = {
        id:i+1,
        title: newArr[i].tag,
        description: descriptionTag,
        color: this.getEventTagsVariant(newArr[i].tag),
        date: newArr[i].day,
    }
    filteredCalEve.push(newEvent)
  }
  return this.events = filteredCalEve
  },
  getEventTagsVariant(data){
    var obj = this.eventTags.find(p => p.eventtypeName == data)
    if(obj && obj.eventVariantColor){
      return obj.eventVariantColor
    } else if (obj == undefined && this.flagOnce){
       var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
       var payload = { taxonomy: 'eventtype', headers: headers, expire: true}
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
       this.flagOnce = false
      return 'default'
    } else {
      return 'default'
    }
  },
  onSlideStart(slide) {
      this.sliding = true;
  },
  onSlideEnd(slide) {
      this.sliding = false;
  },
  onSlideStart2(slide) {
      this.sliding2 = true;
  },
  onSlideEnd2(slide) {
      this.sliding2 = false;
  },
  toggleAddBanner(){
    this.addBanner = !this.addBanner
  }
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.fullScreenStaffing {
    position: fixed;
    top: 30px;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 999;
    overflow: auto;
}
.carouselAdd {
  position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 20px;
    z-index: 2;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
.mobileDisplay {
  display: none;
}
@media screen and (max-width: 768px) {
  .desktopDisplay {
    display: none;
  }
  .mobileDisplay {
  display: block;
}
}
</style>
