<template>
<div id="homelanding">
<b-container fluid class="px-0">
  <b-form-input v-model="uploadedJson" @input="fixData()" />
<b-card v-if="getInventoryItemsList">
<b-table :items="getInventoryItemsList"  />
</b-card>
</b-container>
<b-btn @click="submitBulkInventory">Submit</b-btn>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,

},
data() {
    return {
      showAdmin: false,
      showModal: false,
      inventoryItemsData: [],
      searchData: '',
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      inventoryTemplateData: [],
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
}
},
created () {

},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  inventoryItemsDataReturn(){
    return this.inventoryItemsData.filter(p => {
      return p.itemName.toLowerCase().includes(this.searchData.toLowerCase())
    })
  },
  getOptionsFromSelection(){
    return this[this.sheetTaxonomyTag]
  },
  inventorySheetDataParent(){
    var array = _.cloneDeep(this.inventorySheetData)
    return array.map(p => p.inventoryName)
  },
  getInventoryItemsList(){
    return this.inventorySheetData
  },

},
mounted(){

},
watch:{

},
methods: {
  fixData(){
    var data = JSON.parse(this.uploadedJson)
    var hold = _.cloneDeep(data)
    var duplicated = []
    for(var i in hold){
      if(duplicated.map(p => p.item_name).indexOf(hold[i].item_name) == -1){
        duplicated.push(hold[i])
      }
    }
    console.log(duplicated)
    this.inventorySheetData = duplicated
  },
  getItemId(name){
    var item = this.inventoryItemsData.find(p => p.itemName.toLowerCase().replace(/,/g, '').replace(/ /g, '') === name.toLowerCase().replace(/,/g, '').replace(/ /g, ''))
    if(item){
    return item.id
    } else {
      console.log(name.toLowerCase().replace(/,/g, '').replace(/ /g, ''))
      this.missingInventoryItems.push({item_name: name})
     // console.log(name)
      return ''
    }
    },
    getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : "Unknown"
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
    modalHideEvent(){
      this.showModal = false
    },
    submitBulkInventory(){
    axios({ 
     method: 'post',
     url: '/api/v1/taxonomy/inventoryitem/bulk',
     data: this.getInventoryItemsList,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     this.$emit('getInventoryItems')
   })
    },
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
