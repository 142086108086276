<template>
<div class="stationPanelUserData">
 <groupInputSelector v-if="battalionData != ''" :filterDate="filterDate" :battalionData="battalionData" :groupedDateLabels="stationParents" @updateParent="BattalionDataPullRequest" />
  <b-container id="hideShowButtons" class="showAndHidePanelButtons">
    <b-btn-group>
  <b-btn   v-show="dailyEvents" @click="toggleDailyEventsShow">Events</b-btn>
    <b-btn  variant="outline-secondary" v-show="!dailyEvents" @click="toggleDailyEventsShow">Events</b-btn>
    <b-btn    v-show="dailyLogs" @click="toggleDailyLogsShow">Logbooks</b-btn>
    <b-btn  variant="outline-secondary" v-show="!dailyLogs" @click="toggleDailyLogsShow">Logbooks</b-btn>
    <b-btn   v-show="dailyChecks" @click="toggleDailyChecksShow">Vehicle</b-btn>
    <b-btn  variant="outline-secondary" v-show="!dailyChecks" @click="toggleDailyChecksShow">Vehicle</b-btn>
    <b-btn   v-show="dailyRepair" @click="toggleDailyRepairShow">Repair</b-btn>
    <b-btn  variant="outline-secondary" v-show="!dailyRepair" @click="toggleDailyRepairShow">Repair</b-btn>
    <b-btn   v-if="siteconfig.narcBattView" v-show="dailyNarcs" @click="toggleDailyNarcShow">Narcs</b-btn>
    <b-btn   v-if="siteconfig.narcBattView" v-show="!dailyNarcs" variant="outline-secondary"  @click="toggleDailyNarcShow">Narcs</b-btn>
  </b-btn-group>
  </b-container><div id="dataWrapper">
      <div v-if="dailyEvents">
            <events  v-if="calendarEvents" :filterDate='filterDate'  :calendarEvents='calendarEvents'></events>
      </div>
      <div>
            <stationlogbookfilter  v-if="dailyLogs" :userData="userData" :filterDate="filterDate" :stationLogbooks="stationLogbooks" :vehicleChecks="vehicleChecks" :stationRepair="stationRepair"  :getSessionData="getSessionData"></stationlogbookfilter>
            <vehiclecheckfilter v-if="dailyChecks" :vehicleChecks="vehicleChecks" :filterDate="filterDate"></vehiclecheckfilter>
            <stationrepairfilter v-if="dailyRepair" :stationRepair="stationRepair" :filterDate="filterDate"></stationrepairfilter>
            <narcPageBatt v-if="dailyNarcs" /> 
        </div>
      </div>
      <router-link to="/portal/deptdemo" v-if="userData.role == 'admin' && false">Ipad Demo Link</router-link>
    </div>

</template>
<script>
import { mapState } from "vuex";
import { bus } from "../../main.js";
import moment from "moment";
import VehicleCheckFilter from "../tracking/vehiclechecks/vehiclecheckfilter.vue";
import StationLogbookFilter from "../tracking/stationlogbook/stationlogbookfilter.vue";
import StationRepairFilter from "../tracking/repairs/stationrepairfilter.vue";
import groupInputSelector from "../inputfields/groupInputSelector.vue";
import narcPageBatt from "./emsqapanelbatt.vue"
import Events from "../tracking/events/eventsfilter.vue";
export default {
  props: ['stationParents'],
  components: {
    events: Events,
    vehiclecheckfilter: VehicleCheckFilter,
    stationlogbookfilter: StationLogbookFilter,
    stationrepairfilter: StationRepairFilter,
    groupInputSelector: groupInputSelector,
    narcPageBatt
  },
  data() {
    return {
      dailyEvents: true,
      dailyLogs: false,
      dailyChecks: false,
      dailyRepair: false,
      dailyNarcs: false,
      currentSet: "",
      currentSetLessOne: false,
      panelHideStatus: true,
      groupedDateLabels: [],
      battalionData: '',
      awaitData: true,
    };
  },
  created() {
      var userSta = this.userData.stationAssignment
      var userStaPare = this.stationsData.filter( p => {
        return p.stationName == userSta
      })
      if(parentVar){
      var parentVar = userStaPare[0].stationParent
            this.battalionData = parentVar
      this.BattalionDataPullRequest(parentVar)
      } else {
        var parents = [... new Set(this.stationsData.map(p => p.stationParent))]
        var hold = parents.filter(p => {
          if(p){
            return p
          }
        })
        if(hold.length > 0){
        var parentVar = hold[0];
        this.battalionData = parentVar
         this.BattalionDataPullRequest(parentVar)
        }
      }


  },
  computed: {
    ...mapState([
      "getSessionData",
      "stationsData",
      "userData",
      "vehicleChecks",
      "stationLogbooks",
      "calendarEvents",
      "stationRepair",
      "siteconfig"
    ]),
    filterDate() {
      var now = moment().format("MM/DD/YYYY");
      var hour = moment().format('HH')
      if(hour < 8){
        var now = moment(now).subtract(1, 'd').format('MM/DD/YYYY')

      }
      return now
    },
    filterDateFormated() {
      var formated = moment().format("ddd, MMM Do YYYY");
      return formated;
    },
    dateDifference() {
      var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
      var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
      var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
      var dateDiffCalNumber = 100 * (dateDiffCal / 3);
      var dateTenDigit = dateDiffCalNumber % 10;
      return Math.round(dateTenDigit);
    },
    shiftDateCalc(){
               var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
    },
    shiftDateCalcClass(){
      var shift = this.shiftDateCalc
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shift)
      if(obj){
        return obj.color
      } return shift
    },
    uniqueCalendarData() {
      if (this.calendarEvents > 0) {
        const unique = {};
        const filter = this.calendarEvents.filter(calevents => {
          if (unique[calevents.uuid]) {
            return false;
          }
          unique[calevents.uuid] = true;
          return true;
        });
        return filter;
      } else {
        return;
      }
    },
   
  },
  watch: {
    calendarEvents(newData) {
      this.dailyEvents = false;
      this.$nextTick(function() {
        this.dailyEvents = true;
      });
    }
  },
  methods: {
    BattalionDataPullRequest(data) {
      var parentVar = data
      if(data == undefined || data == '' || data == null){
        var stationList = this.stationsData.filter(p => {
          if(p.stationParent != ''){
            return p
          }
        })
        var stationParent = [...new Set(stationList.map(p => p.stationParent))]
        stationParent.splice(stationParent.indexOf(undefined), 1)
        var stationParent = _.sortBy(stationParent, ['asc'])
        var parentVar = stationParent[0]
        if(this.battalionData == '' || this.battalionData == undefined || this.battalionData == null){
          this.battalionData = parentVar
        }
      }
      if (this.userData.role == "fireops" || this.userData.role == 'admin' || this.userData.role == 'adminchief') {
        var dataPull = ["event", "logbook", "dailyvehicle", "repairform"];
        var urlPlace = "parent";
      var secondDay = moment(this.filterDate, 'MM/DD/YYYY').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
      var firstDay = moment(secondDay).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
      var query = '/' + firstDay + 'Z/' + secondDay + 'Z'
      var token = this.getSessionData
      var headers = {
        headers: {
          "content-type": "application/json",
          'Authorization': token,
            'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT' }}
      for (var i = 0; i < dataPull.length; i++) {
        var payload = {
          contentType: dataPull[i],
          place: urlPlace,
          stationVar: parentVar,
          headers: headers,
          query: query
        };
        this.$store.dispatch("LOAD_SEC_DATA_EXP", payload);
      } } else {
        return
      }
    },
    /*
    stationDataPullRequest(parentOpt) {
      console.log("stationDataPullRequest");
      console.log(this.userData.role);
      if (this.userData.role == "admin" || this.userData.role == "adminchief") {
        var dataPull = ["event", "logbook", "dailyvehicle", "narctrack"];
        var urlPlace = "parent";
        var parentVar = parentOpt[0];
        console.log(parentVar);
      }
      if (this.userData.role == "emsops") {
        var dataPull = ["narctrack"];
        var urlPlace = "parent";
        var parentVar = this.stationParents[0];
      }
      if (this.userData.role == "fireops") {
        var dataPull = ["event", "logbook", "dailyvehicle"];
        var urlPlace = "parent";
        var parentVar = this.userData.stationParent;
        this.battalionData = parentVar;
      } else {
        var dataPull = ["event", "logbook", "dailyvehicle"];
        var urlPlace = "station";
        var parentVar = this.userData.stationAssignment;
      }
      var token = this.$cookie.get("userToken");
      var headers = {
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + token
        }
      };
      for (var i = 0; i < dataPull.length; i++) {
        var payload = {
          contentType: dataPull[i],
          place: urlPlace,
          stationVar: parentVar,
          headers: headers
        };
        console.log(payload);
        this.$store.dispatch("LOAD_SEC_DATA_EXP", payload);
      }
    }, */
    panelHide() {
      this.panelHideStatus = !this.panelHideStatus;
    },
    toggleDailyEventsShow() {
      this.dailyLogs = false;
      this.dailyChecks = false;
      this.dailyRepair = false;
      this.dailyNarcs = false;
      this.dailyEvents = true;
    },
    toggleDailyLogsShow() {
      this.dailyChecks = false;
      this.dailyRepair = false;
      this.dailyEvents = false;
      this.dailyNarcs = false;
      this.dailyLogs = true;
    },
    toggleDailyChecksShow() {
      this.dailyRepair = false;
      this.dailyEvents = false;
      this.dailyLogs = false;
      this.dailyNarcs = false;
      this.dailyChecks = true;
    },
    toggleDailyNarcShow(){
      this.dailyEvents = false;
      this.dailyLogs = false;
      this.dailyChecks = false;
      this.dailyRepair = false,
      this.dailyNarcs = true;
    },
    toggleDailyRepairShow() {
      this.dailyEvents = false;
      this.dailyLogs = false;
      this.dailyChecks = false;
      this.dailyNarcs = false;
      this.dailyRepair = true;
    }
  }
};
</script>

<style scoped>
#dataWrapper {
  width: 80%;
  margin: 0 auto 0;
}

#stationPanelHeading {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}
#stationPanelHeading h3 {
  padding: 5px;
}

a {
  text-decoration: none;
  color: #e2070d;
}
.A-shift {
  background: #e2070d;
}
.B-shift {
  background: blue;
}
.C-shift {
  background: green;
}
.A-shifttext {
  color: #e2070d;
}
.B-shifttext {
  color: blue;
}
.C-shifttext {
  color: green;
}
.showAndHidePanelButtons {
  text-align: center;
}
.stationSelector {
  text-align: center;
  width: 64%;
  margin: 0 auto 15px;
}
.stationSelector {
  font-size: 30px;
}
#stat-header-data span {
  cursor: pointer;
}
.stationSelector button {
  background: #ddd;
  border-style: none;
  width: 15%;
  height: 40px;
  color: #777;
  font-weight: bold;
  font-size: 20px;
  padding: 0px 0px 4px;
  margin: 0;
  line-height: 5px;
}
.stationIndicator {
  display: none;
}
.previousStation {
  float: left;
}
.nextStation {
  float: right;
}
@media screen and (max-width: 460px) {
  #dataWrapper {
    width: 100%;
  }
}
</style>
