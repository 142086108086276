<template>
<div @click="addModalOpen" v-if="showModal">
   <b-modal
        id="calendarModalFull"
        class="modalClass"
        ref="calendarModalFull"
        title="Create New Calendar Event"
        @hidden="onHide"
        v-model="showModal"
        :no-close-on-backdrop="trueBoo"
        :no-close-on-esc="trueBoo"
          >
          <template slot="modal-header"     v-if="!showModalConfirmation">
            Create New calendar Event <b-btn size="sm" variant="danger" align="right" @click.stop="showModalswitchEmitVehicle"> x </b-btn>
          </template>
          <div   v-if="!showModalConfirmation" class="modal-body">
            <editBody @updateData="setUpdateData" :filterDate="filterDate"  :defaultEvent="defaultEvent" :defaultSubEvent="defaultSubEvent" :stationTag="stationTag" />
            </div>
            <b-row v-if="trainingDrillsReturned.length > 1 && checkForTrainingCat" class="mb-2 px-2 ml-2">
          <b-col cols="*" class="pt-1">Tag Training:</b-col><b-col>
          <b-form-select v-model="eventContentId" :options="trainingDrillsReturned" :disabled="trainingPage" /></b-col>
          </b-row>
           <template slot="modal-footer"     v-if="!showModalConfirmation">
             <b-row>
             <b-col>
             <h6 v-show="!(holdValues.stationSelectValue) || holdValues.stationSelectValue.length <= 0" class="text-danger">Events Require a Title, Cateogry, Type, & Station Tag</h6>
              <b-form-checkbox  v-show="holdValues.stationSelectValue && holdValues.stationSelectValue.length > 0" v-model="eventPrivate" :value="true" :unchecked-value="false">Set Event To Private</b-form-checkbox>
            </b-col><b-col cols="auto" align="right">
             <b-btn v-show="holdValues.stationSelectValue && holdValues.stationSelectValue.length > 0 && !(selected == 'repeat' || selected == 'repeatdate')" :disabled="titleDescription == '' || eventCatSelect == null || eventTagSubSelect == null"
             @click="submitArticl" variant="primary">Submit Event</b-btn>
            <b-btn v-show="holdValues.stationSelectValue && holdValues.stationSelectValue.length > 0 && (selected == 'repeat' || selected == 'repeatdate')" :disabled="titleDescription == '' || eventCatSelect == null || eventTagSubSelect == null"
             @click="submitMultipleArticles" variant="primary">Submit {{ repeatedDatesArray.length }} Events</b-btn>
           </b-col>
           </b-row>
           </template>


        <template slot="modal-header"   v-if="showModalConfirmation">
          Calendar Submission Confirmation
        </template>
        <div class='modal-body'  v-if="showModalConfirmation">

                <h5 v-if="groupPostData">{{ groupPostData }} Number of Events Have Been Submitted</h5>
                <b-progress  v-if="groupPostData" :value="groupPostData" :max="repeatedDatesArray.length" show-progress animated>
                
                </b-progress>
             

               <eventData v-if="!groupPostData" :eventdata="postData" />
              </div>
          <template slot="modal-footer"  v-if="showModalConfirmation">
                <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet">
                  Close Message
                </b-btn>
              </template>

          </b-modal>
  </div>
</template>

<script>

import { bus } from '../../main'
import { mapState } from 'vuex'
import DatePicker from '../inputfields/Datepickermod.vue'
import axios from 'axios';
import TimePicker from '../inputfields/timepicker.vue'
import inputFilter from '../inputfields/inputFilter.vue'
import repeatDate from '../inputfields/repeatDate.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import eventData from '../tracking/events/eventsfilterdata.vue'
import editBody from '../tracking/events/eventsEditBody.vue'
export default {
  props:["showModal", "filterDate", "defaultEvent", "defaultSubEvent", "stationTag"],
components:{
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker': DatePicker,
      inputFilter,
      eventData,
      repeatDate,
      editBody
},
  data () {
    return {
      trueBoo: true,
        titleDescription: '',
        dataReset: true,
        title:'',
        selected: '',
        trainingPage: false,
        eventContentId: null,
        timeValueSet: {},
        showModalConfirmation: false,
        holdValues: {appartusSelectValue: [], userSelectValue: [], stationSelectValue: []},
        selectedAppData: [],
        detailedTextArea: '',
        eventPrivate: false,
        eventTagsSelectValue:[],
        stationSelectValue:[],
        apparatusSelectValue:[],
        userSelectValue: [],
        selectedDate: '',
        eventCatSelect: null,
        trainingDrills: [],
        eventTagSubSelect: null,
        postData: {},
        groupPostData: '',
        repeatedDatesArray: []
    } 
  },
  created(){
    this.getTrainingModules()
  },
  mounted(){

  },
  watcher: {

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'eventTags',
          'apparatusData',
          'deptContactData',
          'userData',
          'workgroupData'
      ]),
      checkForTrainingCat(){
      var eventid = this.eventCatSelect
      if(eventid){
        if(eventid.toLowerCase().match('training')){
          return true
        }
      } return false
    },
    trainingDrillsReturned(){
      var hold = [{text: 'None', value: null}]
      var array = this.trainingDrills.filter(p => {
        return p.trainingModuleType == 'scheduled' && p.trainingModuleActive
      })
      var array = array.map(item => {
        var obj = {}
        obj['text'] = item.trainingModuleName
        obj['value'] = item.id
        return obj
      })
      for(var i in array){
        hold.push(array[i])
      }
      return hold
    },
      formatedDateExportback() {
      var dateString = moment(this.selectedDate, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" +  this.timeValueSet.HH + ':' + this.timeValueSet.mm + ':' + '00';
      var offSet = moment().utcOffset();
      var hourOffSet = offSet * -1 / 60;
      var timeStringForm = moment(timeString).add(hourOffSet, "hours");
      var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
      return timeStringAdd + "Z";
      },
      formatedDateExport() {
      var dateString = moment(this.selectedDate, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" +  this.timeValueSet.HH + ':' + this.timeValueSet.mm + ':' + '00';
      return moment(timeString).toISOString();
      },
            returnedTargetIdsStations () {
        var array = []
        var station = this.stationSelectValue
        for(var i = 0; i < station.length; i++){
          var string = station[i].stationName
          array.push(string)
        }
        return array
      },
      returnedTargetIdsApparatus () {
        var array = []
        var station = this.apparatusSelectValue
        for(var i = 0; i < station.length; i++){
          var string = station[i].apparatusName
          array.push(string)
        }
        return array
      },
      userSelectedFormatExport(){
        if(this.userSelectValue.length > 0){
          var users = [... new Set(this.userSelectValue.map(p => p.id))]
          return users
        }
      }
    },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    onHide(){
      console.log('Remove Modal Open')
         document.body.classList.remove('modal-open');
    },
    setUpdateData(data){
      //console.log(data.selected)
      this.titleDescription = data.titleDescription
      this.title = data.title
      this.selected = data.selected
      this.holdValues = data.holdValues
      this.detailedTextArea = data.detailedTextArea
      this.eventTagsSelectValue = data.eventTagsSelectValue
      this.selectedDate = data.selectedDate
      this.eventCatSelect = data.eventCatSelect
      this.eventTagSubSelect = data.eventTagSubSelect
      this.repeatedDatesArray = data.repeatedDatesArray
      this.timeValueSet = data.timeValueSet
    },
    getStationParents(data){
      var array = []
      for(var i = 0; i < data.length; i++){
        var string = data[i].stationParent
        var index = array.indexOf(string)
          if(string != '' && string != null && string != undefined && index < 0){
          array.push(string)
          }
        }
      return array
    }, 
    addModalOpen(){
     // console.log('MODAL OPEN')
        document.body.classList.add('modal-open');

    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      bus.$emit('refresh-full-calendar');
      bus.$emit('emitCalendarEvent')
      this.showModalConfirmation = !this.showModalConfirmation
      this.titleDescription = ''
      this.timeValueSet = { HH: "12", mm: "00", }
      this.eventCatSelect = null,
      this.eventTagSubSelect = null,
      this.stationSelectValue = []
      this.apparatusSelectValue = []
      this.detailedTextArea = ''
      bus.$emit('showModalSwitchFullCalendar')
    },
    showModalswitchEmitVehicle (event){
    //  document.body.classList.remove('modal-open');
      this.titleDescription = ''
      this.timeValueSet = { HH: "12", mm: "00", }
      this.eventCatSelect = null,
      this.eventTagSubSelect = null,
      this.stationSelectValue = []
      this.apparatusSelectValue = []
      this.detailedTextArea = ''
      bus.$emit('showModalSwitchFullCalendar')
    },
    getTrainingModules(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingmodule/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.trainingDrills = response.data

      })
    },
    submitArticl(){
     var keys = Object.keys(this.holdValues)
     for(var i in keys){
      this[keys[i]] = this.holdValues[keys[i]]
     }
     axios({
      method: 'post',
      url: '/api/v1/content/event',
      data: {
              "title": this.titleDescription + ' | ' + this.formatedDateExport,
              "eventStation":this.returnedTargetIdsStations,
              "eventDate":this.formatedDateExport,
              "eventDescription": [this.detailedTextArea],
              "eventPrivate": this.eventPrivate,
              "eventApparatus":this.returnedTargetIdsApparatus,
              "eventTag": this.eventTagSubSelect,
              "eventTitleDescription": this.titleDescription,
              "eventTagParent":this.eventCatSelect,
              "eventUser": this.userSelectedFormatExport,
              "eventStationParent": this.getStationParents(this.stationSelectValue),
              "eventAuthor": this.userData.firstName + ' ' + this.userData.lastName,
              "eventAuthorId": this.userData.id,
              "eventContentId": this.eventContentId
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
          }
        }).then( response => {
         this.postData = response.data
        })

          this.$emit('switchEmit');
          this.showModalConfirmation = !this.showModalConfirmation; 
          bus.$emit('refreshUserPosts')
          bus.$emit('refresh-calendar-list')
  
     },
    submitMultipleArticles(){
     var keys = Object.keys(this.holdValues)
     for(var i in keys){
       this[keys[i]] = this.holdValues[keys[i]]
     }
      var name = this.userData.firstName + this.userData.lastName
      var id = name.replace(/ /g,'-')
      var length = 15
      var id = id + Math.random().toString(36).substr(2, length)
      var id = _.uniqueId(id)
     var dates = this.repeatedDatesArray
     var responseData = 0
     for(var d in dates){
     axios({
      method: 'post',
      url: '/api/v1/content/event',
      data: {
              "title": this.titleDescription + ' | ' + dates[d],
              "eventStation":this.returnedTargetIdsStations,
              "eventDate":dates[d],
              "eventGroupId": id,
             "eventDescription": [this.detailedTextArea],
              "eventApparatus":this.returnedTargetIdsApparatus,
              "eventPrivate": this.eventPrivate,
              "eventTag": this.eventTagSubSelect,
              "eventTitleDescription": this.titleDescription,
              "eventTagParent":this.eventCatSelect,
              "eventUser": this.userSelectedFormatExport,
              "eventStationParent": this.getStationParents(this.stationSelectValue),
              "eventAuthor": this.userData.firstName + ' ' + this.userData.lastName,
              "eventAuthorId": this.userData.id,
              "eventContentId": this.eventContentId
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
        }).then( response => {
          responseData++
        if(this.showModalConfirmation == false){
         this.showModalConfirmation = !this.showModalConfirmation
        }
         this.groupPostData = responseData
           if(responseData == this.repeatedDatesArray.length - 1){
         this.$nextTick(function(){
           bus.$emit('refreshUserPosts')
           bus.$emit('refresh-calendar-list')

         })
        }
      })
      }

  }
},


}
</script> 
<style :src="'./customcss/vue-multiselect.min.css'">

</style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>

<style scoped>
.errorClass {
  border: 1px solid red
}
.bordered {
  border: 1px solid;
  border-radius: 5px;
}
</style>
