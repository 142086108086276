<template>
<div>
  <b-modal v-model="scheduleModal" :title="scheduleType + ' Scheduler'" @hidden="emitClosedModal">
   Schedule {{ getSelectedBusinesses.length }} locations for {{ scheduleType }}
   <b-form-select v-model="taxonomyScheduleType" :options="taxonomyTags" class="mb-2" />
             <multiselect
              class="mb-2"
              v-model="taxonomyScheduleId" 
              :disabled="!taxonomyScheduleType"
              :options="getOptionsFromSelection(taxonomyScheduleType)" 
              :multiple="false" 
              :close-on-select="true" 
              :clear-on-select="true" 
              :hide-selected="true" 
              :preserve-search="true" 
              :placeholder="taxonomyScheduleType == 'stationsData' ? 'Search Station Name' : taxonomyScheduleType == 'deptContactData' ? 'Search User Last Name' : taxonomyScheduleType == 'apparatusData' ? 'Search Apparatus Name' : taxonomyScheduleType == 'logisticAssetData' ? 'Search Asset Id' : 'Please Select a Type'"
              :label="taxonomyScheduleType == 'stationsData' ? 'stationName' : taxonomyScheduleType == 'deptContactData' ? 'lastName' : taxonomyScheduleType == 'apparatusData' ? 'apparatusName' : taxonomyScheduleType == 'logisticAssetData' ? 'assetId' : ''" 
              :track-by="taxonomyScheduleType == 'stationsData' ? 'stationName' : taxonomyScheduleType == 'deptContactData' ? 'lastName' : taxonomyScheduleType == 'apparatusData' ? 'apparatusName' : taxonomyScheduleType == 'logisticAssetData' ? 'assetId' : ''">

   <template slot="option" slot-scope="props">
      <div class="option__title" v-if="taxonomyScheduleType == 'stationsData'">{{ props.option.stationName }}</div>
 <div class="option__title" v-if="taxonomyScheduleType == 'apparatusData'">{{ props.option.apparatusName }}</div>
                    <div class="option__title" v-if="taxonomyScheduleType == 'logisticAssetData'">{{ props.option.assetDescription }} #ID:{{ props.option.assetId }} #SN:{{ props.option.assetSerialNumber }}</div>
                    <div class="option__title" v-if="taxonomyScheduleType == 'deptContactData'">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                   <div class="option__body" v-if="taxonomyScheduleType == 'deptContactData'">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
   </template>

</multiselect>
          <b-container >
                    <b-row :align-h="documentWidth ? 'center' : 'start'">
                      <b-col cols="*">
                  <datepicker
                    class="datepickervue"
                    v-model="dateSelected"
                    :inline='documentWidth'
                    name="inlineCal">
                  </datepicker>
                      </b-col></b-row>
                  </b-container>
                  <template slot="modal-footer">
                    <b-btn variant="primary" v-if="scheduleType == 'Inspection'" :disabled="!taxonomyScheduleId" @click="scheduleInspection(getSelectedBusinesses)">Schedule</b-btn>
                     <b-btn variant="primary" v-if="scheduleType == 'Site Visit'" :disabled="!taxonomyScheduleId" @click="scheduleSitevisit(getSelectedBusinesses)">Schedule</b-btn>
                  </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import DatePicker from '../../inputfields/Datepickermod.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import querystring  from 'querystring';
import Multiselect from 'vue-multiselect'
export default {
  props:['formatedTableValues', 'scheduleType'], 
components:{
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,

},
  data () {
    return {
        dateSelected: moment().format("MM/DD/YYYY"),
        taxonomyScheduleType: null,
        taxonomyScheduleId: '',
        taxonomyTags: [
        {text: 'User', value: 'deptContactData'},
        {text: 'Station', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'None', value: null }
        ],
        scheduleModal: true,

    }
  },
  created(){
  this.getWindowWidth()
  window.addEventListener('resize', this.getWindowWidth);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  mounted(){

  
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'Businesstatus',
          'deptContactData'
        ]),
        documentWidth(){
          if(this.windowWidth > 992){
            var boo = true
          } else {
             var boo = false
          }
          return boo
        },
        getSelectedBusinesses(){
          console.log(this.formatedTableValues)
          return this.formatedTableValues
        },
    

    },
  methods:{
    emitClosedModal(){
      this.$emit('ClosedModal')
    },
    getWindowWidth(event) {
        this.windowWidth = document.documentElement.clientWidth;
    },
    getOptionsFromSelection(data){
    if(data){
     // console.log(data)
    return this[data]
    } else {
      return []
    }
    },
    scheduleSitevisit(data){
      var array = data
      var count = 0
      for(var i in array){
      var data = {
               sitevisitPreplanId: array[i].id,
               sitevisitName: array[i].businessName,
               sitevisitAddress: array[i].streetNumberStreetStreetSuffix,
               sitevisitDate: moment(this.dateSelected, 'MM-DD-YYYY').toISOString(), 
               sitevisitAuthor: this.userData.firstName + ' ' + this.userData.lastName, 
               sitevisitAuthorId: this.userData.id,
               sitevisitAssignedType: this.taxonomyScheduleType, 
               sitevisitAssignedId: this.taxonomyScheduleId ? this.taxonomyScheduleId.id : null,
       }
      axios({
        method: "post",
        url: "/api/v1/preplan/sitevisit",
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }
      }).then(response => {
         count = count + 1
         if(count == array.length){
         bus.$emit('getSiteVisits')
         this.scheduleModal = false
         }
        })
      }
 
     },
    scheduleInspection(data){
      var array = data
      var count = 0
      for(var i in array){
      var data = {
               inspectionBusinessId: array[i],
               inspectionDate: moment(this.dateSelected, 'MM-DD-YYYY').toISOString(), 
               inspectionAuthor: this.userData.firstName + ' ' + this.userData.lastName, 
               inspectionAuthorId: this.userData.id,
               inspectionAssignedType: this.taxonomyScheduleType, 
               inspectionAssignedId: this.taxonomyScheduleId ? this.taxonomyScheduleId.id : null,
       }
      axios({
        method: "post",
        url: "/api/v1/preplan/inspection",
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }
      }).then(response => {
         count = count + 1
         if(count == array.length){
         this.$emit('getInspections')
         this.scheduleModal = false
         }
        })
      }
 
     },


}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.datepickervue {
  width: 300px;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.numberInputSize {
  width: 70px;
}
.textInputSize {
  width: 140px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
.datePickerClassMobile {
  display: none;
}
@media screen and (max-width: 992px) {
  .time-picker.timeDesk{
    display: none;
  }
    .datePickerClass {
    display: none;
  }
  .datePickerClassMobile {
    display: block;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
