<template>
<div>
  <div class="maxFormWidth" v-if="returnedForms.length > 0">
       <div class="headerSpan">{{ returnedForms[0].formName }}</div>
    <b-table :fields="fields" :items="returnedFormsTracked" >
    <template slot="updatedAt" slot-scope="data">
      {{ getformatedDate(data.value) }}
      </template>
    <template v-if="signatureIds.length > 0" v-for="(ids, index) in signatureIds" :slot="ids" slot-scope="data">
      <b-img v-if="data.value" :key="index" width="150px" :src='"data:image/png;base64," + data.value.image' />
      <h5 :key="index" v-if="data.value == '' || data.value == undefined">Missing</h5>
      </template>
      <template slot="View" slot-scope="data">
        <b-btn size="sm" variant="primary" :to="'/formsubmission/' + data.item.id">View</b-btn>
        </template>
        <template slot="Delete" slot-scope="data">
          <b-btn size="sm" variant="danger" @click="removeForm(data.item.id)">X</b-btn>
          </template>
      </b-table>
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import formSub from '../../admin/forms/formsub.vue'
import axios from 'axios'
export default {
  props:['formId'],
components:{
     'formsub' : formSub,
},
  data () {
    return {
      returnedForms: [],
      fields: [
        {key: 'updatedAt', label: 'Date'},
      ],
      dataPoints: [],
      returnedFormsTracked: [],
      signatureIds: [],
    }
  },
  created(){
    this.getFormData(this.formId)

  },
  computed:{
    ...mapState([
      'userData',
      'getSessionData',
      'formtempData'
    ]),

  },
  methods: {
    getformatedDate(data){
    return moment(data).format('MM/DD/YY HH:mm')
    },
    getFormTrackedFields(data){
      for(var p = 0; p < data.length; p++){
      var newData = data[p]
      if(newData.tableview != undefined){
        var td = newData.tableview
        for(var i = 0; i < td.length; i++){
          this.fields.push({key: td[i].id, label: td[i].item})
        if(td[i].type == 'signature'){
          this.signatureIds.push(td[i].id)
        }
        if(td[i].type == 'stationsData' ||td[i].type == 'apparatusData' || td[i].type == 'deptContactData'){
         newData[td[i].id] = this.getItemDetails(td[i], td[i].type)
        } else {
        newData[td[i].id] = td[i].value
        }
        }
        this.returnedFormsTracked.push(newData)
      } else {
        this.returnedFormsTracked.push(newData)
      }
      }
      this.fields.push('View')
      if(this.userData.role == 'admin'){
        this.fields.push('Delete')
      }
    },
    getItemDetails(item, filter){
      if(Array.isArray(item.value)){
      var newArray = []
      for(var i = 0; i < item.length; i++){
      if(arrayData[i] != null){
      if(item.type == 'stationsData'){
        newArray.push(arrayData[i].stationName)
      } else if (item.type == 'apparatusData') {
        newArray.push(arrayData[i].apparatusName)
      } else if (item.type == 'deptContactData'){
        newArray.push(arrayData[i].firstName + ' ' + arrayData[i].lastName)
      } else {
        return 
      }} else {
        return
      }
      }
      return newArray
    } else {
    if(item.value != undefined){
      if(item.type == 'stationsData'){
        return item.value.stationName
      } else if (item.type == 'apparatusData') {
        return item.value.apparatusName
      } else if (item.type == 'deptContactData'){
        return item.value.firstName + ' ' + item.value.lastName
      } else {
        return ''
      }
    } else {
      return ''
    } 
    }
  },
    getFormData(data){
     var status = this.$route.query.status
    axios({
        method: 'get',
        url: '/api/v1/forms/submissions/' + status + '/' + data + '?deptContactData=[1,2,3,4]&data=2017/02/05',
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {

        this.returnedForms = response.data
        this.getFormTrackedFields(response.data)
        })
    },
    removeForm(id){
       axios({
        method: 'delete',
        url: '/api/v1/forms/submissions/' + id,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
        this.returnedForms = []
        this.$nextTick(function(){
          this.getFormData(this.formId)
        })
        
        })
    },
    
  }
}
</script>

<style scoped>
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 5px;
  border-radius: 0 0 5px 5px;
}
a {
  text-decoration: none;
}
.A-shift{
  color: red;
}
.B-shift{
  color: blue;
}
.C-shift{
  color: green;
}
</style>
