<template>
<div>
  <div v-if="postData != ''">
         <img v-if="post.contentType == 'dailyvehicle' && !showPost" class="userPostIcon" src="/images/siteicons/rescue.png" title="Daily Vehicle" >
          <vehiclechecks v-if="post.contentType == 'dailyvehicle'" :dailyvehicle="postData" :showPost="showPost"></vehiclechecks>

          <img v-if="post.contentType == 'logbook' && !showPost" class="userPostIcon" src="/images/siteicons/logbook.png" title="Logbook" >
          <logbook v-if="post.contentType == 'logbook'" :logbook="postData" :showPost="showPost"></logbook>

          <img  v-if="post.contentType == 'repairform' && !showPost" class="userPostIcon" src="/images/siteicons/repair.png" title="Narcotics Log" >
          <repair v-if="post.contentType == 'repairform'" :repair="postData" :showPost="showPost" :userPanel="true"></repair>

           <img v-if="post.contentType == 'narctrack' && !showPost"  class="userPostIcon" src="/images/siteicons/narcs.png" title="Narcotics Log" >
          <narc v-if="post.contentType == 'narctrack'" :narcs="postData" :showPost="showPost" />

           <img v-if="post.contentType == 'dailynarctrack' && !showPost"  class="userPostIcon" src="/images/siteicons/narcs.png" title="Narcotics Log" >
          <dailyNarc v-if="post.contentType == 'dailynarctrack'" :narcs="postData" :showPost="showPost" />


           <img v-if="post.contentType == 'event' && !showPost"  class="userPostIcon" src="/images/siteicons/calendar.png" title="Calendar Event" >
          <event v-if="post.contentType == 'event'" :showBody='showPost' :eventdata="postData" class="changeWidth" />

          <img v-if="post.contentType == 'blog' && !showPost"  class="userPostIcon" src="/images/siteicons/blog-post-icon.png" title="Blog Post" >
          <blog v-if="post.contentType == 'blog'" class="blogadjust" :showBody='showPost' :blog="postData"  />
          
          <div  class="userPostForms"></div>
  </div>
</div>
</template>
<script>
import VehicleChecks from '../../tracking/vehiclechecks/vehiclecheckfilterdata.vue'
import Logbook from '../../tracking/stationlogbook/stationlogbookfilterdata.vue'
import Repair from '../../tracking/repairs/stationrepairfilterdata.vue'
import NarcFilter from '../../tracking/narclog/apparatusFilterData.vue'
import dailyNarc from '../../tracking/dailynarctrack/apparatusFilterData.vue'
import Events from '../../tracking/events/eventsfilterdata.vue'
import Blog from '../../tracking/blogs/blogsFront.vue'
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  props:['post', 'showPost'],
components:{
  'vehiclechecks': VehicleChecks,
  'logbook' : Logbook,
  'repair' : Repair,
  'narc' :  NarcFilter,
  'event' : Events,
  'blog' : Blog,
  dailyNarc
},
  data () {
    return {
      postData: ''
    }
  },
  created(){
   // console.log('CREATED')
    this.getData()
  },
  watcher: {

  },
  computed:{
    ...mapState([
    'getSessionData',
    'stationsData',
    'userData',
    'config',
    'userPosts'
    ]),
    },
    methods: {
    getData(){
    //  console.log('getData')
    //  console.log(this.post)
      this.postData = this.post
 
          }
    


    



},

}
</script>

<style scoped>
.changeWidth {
  width: 80%;
  margin: 0 auto 0;
  text-align: center;
}
.userPostIcon{
  position: relative;
  margin: 0px 0 -80px 0px;
  width:32px;
  height:32px;
  opacity: 0.3;
  
}
.blogadjust {
  text-align: center;
    width: 80%;
    margin: 0 auto 0;
}
.userPostForms{
  width:80%;
  padding-top: 10px;
  margin: 0 auto 0;
  border-bottom: 1px solid #aaa;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}



#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {
  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
@media screen and (max-width: 768px) {
.userPostIcon{
    display: none;
  
}
}

</style>
