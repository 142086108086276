<template>
  <div class="hello">
     <b-btn v-if="userData.role == 'admin'" block variant="primary" @click="FileProcess">Download JSON</b-btn>
            <input  v-if="userData.role == 'admin'" type="file" @change="jsonFileUpload($event)" />
      <b-card no-body :border-variant="checkForChanges == false ? 'danger' : ''">
       <b-tabs card>
         <b-tab title="Form Templates" :active="activeid == 'table'" @click="activeid = 'table'">
           <b-table :fields="fields" :items="formTemplates">
             <template slot="view" slot-scope="data">
               <b-btn variant="primary" size="sm" @click="viewFormTemplate(data.item.id)">View</b-btn>
             </template>
             <template slot="delete" slot-scope="data">
                  <b-btn variant="danger" size="sm" @click="deleteFormTemplate(data.item.id)">Delete</b-btn>
             </template>
           </b-table>
         </b-tab>
         <b-tab v-if="dataForUpload && dataForUpload.length > 0" title="Upload Data">
           <b-table :fields="fields" :items="dataForUpload">
             <template slot="view" slot-scope="data">
               <b-btn variant="primary" size="sm" @click="viewFormTemplate(data.item.id)">View</b-btn>
             </template>
            <template slot="delete" slot-scope="data">
                  <b-btn variant="danger" size="sm" @click="UploadForm(data.item)">Upload</b-btn>
             </template>
           </b-table>
         </b-tab>
         <b-tab title="New Form">
           <newForm @showNewFormSubmission="showNewFormSubmission" />
         </b-tab>
         <template v-for="form in previousForms">

           <b-tab :key="form.id" :active="activeid == form.id">
                          <template slot="title">
              {{ form.formName }} <b-btn variant="danger" class="px-1 py-0" size="sm" @click="closeFormTemplate(form.id)">X</b-btn>
      </template>
             <newForm :form="form" @closeFormAndReOpen="showNewFormSubmission" />
           </b-tab>
         </template>
       </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import newForm from '../admin/digitalforms/newDigitalForms.vue'
export default {
components: {
  newForm,
},
props: ['formTemplates'],
data() {
    return {
      checkForChanges: true,
      fields: [
        'formName',
        'updatedAt',
        'formAuthor',
        'view'
      ],
      showNewFormId: null,
      activeid: '',
      previousForms: [],
      dataForUpload: [],

}
},
created () {
  if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
    this.fields.push('delete')
  }
},
beforeDestroy(){
  
},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData',
    'stationsData',
    'apparatusData',
    'deptContactData',
    'formtempData'
  ]),


},
mounted(){

  

},
watch:{
    formTemplates(newData){
      if(this.showNewFormId){
        this.viewFormTemplate(this.showNewFormId)
        this.showNewFormId = null
      }
    }
  
},
methods: {
      FileProcess(){
        const json_data = this.formTemplates
        const fileName = "jsonDataBlogs.json";
        const data = new Blob([JSON.stringify(json_data)], { type: "text/json" });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      },
    jsonFileUpload(e){
        var vm = this
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = (e) => {
          console.log("e.target.result", e.target.result);
          const data = JSON.parse(e.target.result);
          vm.fixDataApply(data)
        };
    },
    fixDataApply(data){
      var uploadHold = []
      console.log(data)
      for(var d in data){
        var index = this.formTemplates.map(p => p.id).indexOf(data[d].id)
        if(index == -1){
            uploadHold.push(data[d])
        }
      }
      this.dataForUpload = uploadHold
    },
  viewFormTemplate(id){
    this.activeid = null
    var obj = this.formTemplates.find(p => p.id == id)
    var index = this.previousForms.map(p => p.id).indexOf(id)
    if(obj && index == -1){
      this.previousForms.push(obj)
      this.activeid = id  
    } else {
      this.activeid = id  
    }

  },
  closeFormTemplate(id){
     var index = this.previousForms.map(p => p.id).indexOf(id)
     if(index != -1){
       this.previousForms.splice(index, 1)
     }
    this.activeid = null
    this.$nextTick(function(){
      this.activeid = 'table'
    })
  },
  showNewFormSubmission(id){
    this.$emit('getFormTemplates')
    var index = this.previousForms.map(p => p.id).indexOf(id)
    console.log(index)
    if(index != -1){
       this.previousForms.splice(index, 1)
     }
    this.showNewFormId = id
  },
  UploadForm(data){
      axios({
        method: 'post',
        url: '/api/v1/taxonomy/digitalforms/',
        data: data,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        if(response.data && response.data[0]){
        this.$emit('showNewFormSubmission', response.data[0].id)
        }
      })
    },
  deleteFormTemplate(id){
      var result = confirm('Are You Sure You Want to Delete this form? It will be removed for everyone!!!')
    if(result){
    axios({ 
     method: 'delete',
     url: '/api/v1/taxonomy/digitalforms/' + id,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       this.$emit('getFormTemplates')
      })
  }},
  
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
