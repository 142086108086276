<template>
<div id="homelanding">
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Expiration Range:</b-navbar-brand>
      <b-form-input v-model="startDate" type="date"  />
      <b-form-input v-model="endDate" type="date" />
      <b-pagination
      class="mb-0"
      v-model="currentPage"
      :total-rows="inventorysNonGeneric.length"
      :per-page="10"
      aria-controls="my-table"
    ></b-pagination>
      </b-nav-form>
    </b-navbar>
  <b-table v-if="reset" :items="inventorysNonGeneric" :fields="['itemName', 'expireLength', 'units']" :current-page="currentPage" :per-page="10">
    <template slot="units" slot-scope="data">
      {{ getUnitsRequired(data.item.expireHold) }}
    </template>
  </b-table>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import JsonExcel from 'vue-json-excel';
export default {
components: {
  'multiselect' : Multiselect,
  'download-excel' : JsonExcel

},
props: ['inventoryItemsData', 'inventoryTemplateData', 'fullLatestArray'],
data() {
    return {
          searchData: '',
          filterCategory: false,
          filterTagType: null,
          reset: true,
          resetField: '',
          currentPage: 1,
          getCurrentDate: 'New Supply Order ' + moment().toISOString() + '.xls',
          showCartModel: false,
          orderAmountSort: false,
          resetList: true,
          holdValuesCheck: {},
          filterDataBaseId: null,
          orderArray: [],
          startDate: '',
          endDate: ''
          
}
},
created () {
  this.startDate = moment().add(1, 'months').format('YYYY-MM-DD')
  this.endDate = moment().add(2, 'months').format('YYYY-MM-DD')
},
beforeDestroy(){

},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'logisticAssetData'
  ]),
  fields(){
      var array = ['itemName', 'desiredTotal', 'totalStock', 'orderAmount' ]
      if(this.userData.role != 'user'){
        array.push('add')
      }
      return array
  },
  supplyItemCheck(){
    var items = _.cloneDeep(this.inventoryItemsData)
    var nongeneric = this.inventorysNonGeneric
    var array = items.map(p => {
      var obj = p
      obj['formMentions'] = this.itemsFromTemplates.filter(x => x.id == p.id)
      obj['desiredTotal'] = this.getDesiredTotal(obj.formMentions)
      obj['totalStock'] = this.getTotalStock(nongeneric, p.id)
      obj['orderAmount'] = obj.desiredTotal - obj.totalStock >= 0 ? obj.desiredTotal - obj.totalStock : 0
      this.holdValuesCheck[obj.id] = {count: obj.orderAmount}
      return obj
    }).filter(p => {
      return p.itemName.toLowerCase().match(this.searchData.toLowerCase())
    })
    var nameSort = _.orderBy(array, ['itemName'], ['asc'])
    if(this.orderAmountSort){
      return _.orderBy(nameSort, ['orderAmount'], ['desc'])
    }
    return nameSort
  },
  inventorysNonGeneric(){
    var array = _.cloneDeep(this.fullLatestArray)
    var start = this.startDate
    var end = this.endDate
    var hold = []
    var items = array.map(p => {
        var obj = p.inventorySubData
     //   obj['templateId'] = p.inventorySubId
        return obj
    })
    var newArray = hold.concat.apply(hold, items)
    newArray = newArray.filter(p => {
        if(p.expire_date){
          return moment(p.expire_date).isAfter(start) && moment(p.expire_date).isBefore(end)
        }
    })
    var holdIds = [...new Set(newArray.map(p => p.id))]
    holdIds = holdIds.map(p => {
      var item = this.inventoryItemsData.find(x => x.id == p)
      var expireHold = newArray.filter(x => x.id == p)
      item['expireHold'] = expireHold
      item['expireLength'] = expireHold.length
      return item 
    })
    var returnArray = _.orderBy(holdIds, ['expireLength'], ['desc'])
    return returnArray
  },
  itemsFromTemplates(){
    var templates = this.inventoryTemplateData.filter(p => p.latest).map(p => p.inventoryItems)
    var hold = []
    var arrays = hold.concat.apply(hold, templates)
    return arrays.filter(p => p.item_type == 'supply')
  },
  fullLatestArrayReturned(){
    var array = this.fullLatestArray
    return array
  },
},
mounted(){

},
watch:{
  inventorysNonGeneric(newData){
    this.resetList = false
    this.$nextTick(function(){
      this.resetList = true
    })
  }
},
methods: {
  getUnitsRequired(data){
    var units = [...new Set(data.map(p => p.template))]
    return _.sortBy(units)
  }


  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
