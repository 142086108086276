<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
    <b-card>
      <b-row>
        <b-col lg="4">
          <h4  v-if="!siteconfig.payroll.flexibleSchedule">Roster Group</h4>
           <h4  v-if="siteconfig.payroll.flexibleSchedule">Schedule Group</h4>
        <b-form-select :options="rosterGroupTypeOpt" v-model="rosterGroupType" :disabled="siteconfig.payroll.flexibleSchedule" />
        <multiselect 
        v-if="rosterGroupType == 'apparatusData'"
        v-model="apparatusSelectValue" 
        :options="apparatusData" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Select Vehicle ID" label="apparatusName" 
        track-by="id"></multiselect>
        </b-col>
        <b-col lg="4">
          <h4  v-if="!siteconfig.payroll.flexibleSchedule">Group Location</h4>
          <h4  v-if="siteconfig.payroll.flexibleSchedule">Schedule Division</h4>
        <multiselect v-model="stationSelectValue" 
        :disabled="!apparatusSelectValue"
        :options="stationsData" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Select Station ID" label="stationName" 
        track-by="id"></multiselect>
        </b-col>
         <b-col lg="4">
          <h4  v-if="!siteconfig.payroll.flexibleSchedule">Group Location Parent</h4>
          <h4  v-if="siteconfig.payroll.flexibleSchedule">Schedule Department</h4>
        <multiselect v-model="stationParentSelectValue" 
        :disabled="!apparatusSelectValue"
        :options="stationsDataParent" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Select Station Parent ID" label="stationName" 
        track-by="id"></multiselect>
        </b-col>
        </b-row>
        <b-btn @click="newRosterSpot">Submit</b-btn><b-btn @click="runUpdate(updateAllPositionArray)" v-if="userData.role == 'admin'">Update All</b-btn>
    </b-card>
    <b-card no-body v-if="resetData">
      <b-tabs card>
    <b-tab v-for="parent in rosterParentsData" :key="parent.id" :title="parent.stationName" @click="currentParent = parent.id">
        <b-card v-if="currentParent == parent.id" class="mb-2" no-body v-for="station in stationsFromParents(parent.id)" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1">
          <h4 slot="header" class="clickPointer">{{ station.stationName }}</h4> 
            <b-table small :fields="rosterFields" :items="returnRosterSpots(parent.id, station.id)">
              <template slot="apparatusName" slot-scope="data">
                <h6><span v-if="data.item.rosterGroupType && data.item.rosterGroupType == 'variable'">
                  {{ data.item.rosterName }}</span>
                  <span v-else>{{ data.item.apparatusName }} </span>
                  <b-btn size="sm" variant="outline-info" class="ml-2" v-if="editRosterSpotId != data.item.id" @click="editRosterSpotId = data.item.id, setupValuesMain(data.item)">Edit</b-btn>
                  <b-btn variant="danger" size="sm" v-if="!(editRosterSpotId == data.item.id) && !checkForRosterSpotProfiles(data.item.id)" @click="deleteRosterSpot(data.item.id)">Delete</b-btn>
                  <b-btn size="sm" variant="danger" v-if="editRosterSpotId == data.item.id" @click="editRosterSpotId = ''">Cancel</b-btn>
                </h6>
                <b-form-select  v-if="editRosterSpotId == data.item.id && data.item.rosterGroupType && !siteconfig.payroll.flexibleSchedule" :options="rosterGroupTypeOpt" v-model="rosterGroupTypeEdit" />
                <b-form-input v-if="editRosterSpotId == data.item.id && data.item.rosterGroupType && rosterGroupTypeEdit == 'variable'" v-model="rosterNameEdit" />
                <multiselect
                v-if="editRosterSpotId == data.item.id && !(data.item.rosterGroupType && rosterGroupTypeEdit == 'variable')"
                v-model="apparatusIdObj"
                :options="apparatusData" 
                :multiple="false" :close-on-select="true" :clear-on-select="true" 
                :hide-selected="false" :preserve-search="true" placeholder="Select Vehicle ID" label="apparatusName" 
                track-by="id"></multiselect>
                <multiselect
                v-if="editRosterSpotId == data.item.id"
                v-model="stationIdObj" 
                :options="stationsData" 
                :multiple="false" :close-on-select="true" :clear-on-select="true" 
                :hide-selected="false" :preserve-search="true" placeholder="Select Station ID" label="stationName" 
                track-by="id"></multiselect>
                <multiselect 
                v-if="editRosterSpotId == data.item.id"
                v-model="stationParentObj" 
                :options="stationsData" 
                :multiple="false" :close-on-select="true" :clear-on-select="true" 
                :hide-selected="false" :preserve-search="true" placeholder="Select Station Parent ID" label="stationName" 
                track-by="id"></multiselect>
                <b-btn block variant="primary"
                @click="updateRosterTemplateAssigned(data.item.id)"
                v-if="editRosterSpotId == data.item.id">
                Update</b-btn>
              </template>
              <template slot="rosterPositions" slot-scope="data" v-if="!siteconfig.payroll.flexibleSchedule">
                    <b-btn variant="outline-info" size="sm" v-if="editRosterId != data.item.id" @click="editRosterId  = data.item.id">Edit</b-btn>
                   <b-btn variant="info" size="sm" v-if="editRosterId == data.item.id" @click="updateRosterSpot(data.item)">Update</b-btn>
                  <b-btn variant="danger" size="sm" v-if="editRosterId == data.item.id" @click="editRosterId = ''">Cancel</b-btn>
                <b-btn variant="danger" size="sm" @click="deleteRosterSpot(data.item.id)"  v-if="!checkForRosterSpotProfiles(data.item.id)">Delete</b-btn>
              <b-table :fields="['position', 'type', 'start', 'duration']" :items="data.item.rosterPositions" v-if="editRosterId != data.item.id" />
              <div v-if="editRosterId == data.item.id">
                <b-row v-for="(rp, indexrp) in data.item.rosterPositions" :key="indexrp">
                  <b-col>Pos: {{ rp.position }}</b-col><b-col>Required: {{ rp.type == 'required' ? true : false }}</b-col><b-col>{{ rp.start }}</b-col><b-col>{{ rp.duration }}</b-col><b-col>{{ rp.cert }}</b-col>
                  <b-col><b-btn variant="danger" size="sm" @click="data.item.rosterPositions = updateDataRosterPostions(indexrp, data.item.rosterPositions)">X</b-btn>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col><b-form-select v-model="positionTypeSelect" :options="positionTypeSelectOpts" /></b-col>
                  <b-col><b-form-input type="time" v-model="postionStartTime" /></b-col>
                  <b-col><b-form-input type="number" v-model="positionDuration" /></b-col>
                  <b-col v-if="false"><b-form-select v-model="positionCert" :options="positionCertOptions" /></b-col>
                  <b-col><b-btn size="sm" variant="primary" 
                                @click="data.item.rosterPositions.push({position: data.item.rosterPositions.length + 1, type: positionTypeSelect, start: postionStartTime, duration: positionDuration, cert: positionCert })">Add</b-btn></b-col>
                </b-row>
                </div>
              </template>
              <template slot="edit" slot-scope="data">

              </template>
          </b-table>
          </b-card>
    </b-tab>
      </b-tabs>
    </b-card>
</div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
export default {
  components: {
    multiselect
  },
  props: ['rosterData', 'rosterProfiles'],
  name: 'roster-admin',
  data () {
    return {
      rosterGroupType: '',
      rosterGroupTypeOpt: [
        { text: 'Apparatus', value: 'apparatusData' },
        { text: 'Variable', value: 'variable' }
      ],
      apparatusSelectValue: null,
      stationSelectValue: '',
      stationParentSelectValue: '', 
      rosterParentsData: [],
      currentParent: '',
      editRosterSpotId: '',
      updateAllPositionArray: [],
      resetData: true,
      editRosterId: '',
      rosterFields: [
        {key: 'apparatusName', label: 'Name'},
        {key: 'rosterPositions', label: 'Positions'},
        //'edit',
      ],
      postionStartTime: '',
      positionDuration: 24,
      positionTypeSelect: 'required',
      positionCert: null,
      positionTypeSelectOpts: ['required', {text: 'non-required', value: 'alternate'}, {text: 'hide on empty', value: 'hidden'}],
      apparatusIdObj: '',
      stationIdObj: '',
      stationParentObj: '',
      rosterGroupTypeEdit: '',
      rosterNameEdit: '',
    }
  },
  created(){
    this.rosterParents(this.rosterData)
    if(this.siteconfig.payroll.flexibleSchedule){
      this.rosterGroupType = 'variable'
      this.rosterFields.splice(1, 1)
    }
    this.postionStartTime = this.siteconfig.payroll.startTime ? this.siteconfig.payroll.startTime : '08:00:00'
    bus.$on('refeshRosterData', this.updateRosterData)
  },
  mounted(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'siteconfig',
          'userData',
          'departmentSpecialties'
        ]),
        rosterProfileIds(){
          return this.rosterProfiles.map(p => p.rosterId)
        },
        stationsDataParent(){
          return this.stationsData.filter(p => {
              return !p.stationParent 
          })
        },
        rosterStations(){
           var array = this.rosterData
           var stations = [...new Set(array.map(p => p.stationId))]
           var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
           var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
           return stationsSort
        },
        positionCertOptions(){
          var array = _.cloneDeep(this.departmentSpecialties)
          var hold = [{text: "None", value: null}]
          for(var i in array){
           hold.push(array[i].deptspecName)
          }
          return hold
        },
        rosterDataReturn(){
          var array = this.rosterData
          var newArray = []
          var parents = [...new Set(array.map(p => p.stationParent))]
          var stations = [...new Set(array.map(p => p.stationId))]
          var parentsObj = parents.map(item => item = this.stationsData.find(p => p.id == item))
          var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
          var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
          var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])
          for(var p in parentsSort){
            var obj = {}
            obj['apparatusId'] = parentsSort[p].stationName
            obj['isParent'] = true
            obj['_rowVariant'] = 'primary'
            newArray.push(obj)
            var filterStation = stationsSort.filter(i => i.stationParent == parentsSort[p].stationName)
            for(var s in filterStation){
              var statObj = {}
              statObj['apparatusId'] = filterStation[s].stationName
              statObj['isSubParent'] = true
              statObj['_rowVariant'] = 'info'
              newArray.push(statObj)
              var filterRost = array.filter(x => x.stationId ==  filterStation[s].id)
              var rostObj = filterRost.map(item => {
                var obj = this.apparatusData.find(p => p.id == item)
                return obj
               })
              var sortRost = _.orderBy(rostObj, ['apparatusName'], ['asc'])
              for(var r in filterRost){
                newArray.push(filterRost[r])
              }
            }
          }
       //   console.log(newArray)
          return newArray
        },
       
  },
  mounted(){

  },
  watch: {
    rosterGroupType(newData){
      if(newData == 'variable'){
        this.apparatusSelectValue = parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
      } else {
        this.apparatusSelectValue = null
      }
    },
    apparatusSelectValue(newData){
      if(newData.apparatusLocation){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusLocation)
        if(station){
          this.stationSelectValue = station
        }
      }
    if(newData.apparatusParent){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusParent)
        if(station){
          this.stationParentSelectValue = station
        }
      }
    }
  },
  methods: {
    updateRosterData(data){
    //  console.log('Updated')
      // console.log(this.rosterData)
    //  console.log(data)
      this.rosterParents(data)
    },
    setupValuesMain(data){
     // console.log(data.rosterGroupType)
      if(data.rosterGroupType && data.rosterGroupType == 'variable'){
      //  console.log('YES')
        this.apparatusIdObj = data.apparatusIdObj
        this.rosterGroupTypeEdit = data.rosterGroupType
        this.rosterNameEdit = data.rosterName
      }
      this.$nextTick(function(){
      this.stationParentObj = data.stationParentObj
      this.stationIdObj = data.stationIdObj
      })
    },
    checkForRosterSpotProfiles(id){ 
     var index = this.rosterProfileIds.indexOf(id)
      if(index != -1){
        if(this.userData.role == 'admin' && true){
         // console.log(this.rosterProfiles.filter(p => p.rosterId == id))
        }
        return true
      } else {
        return false
      }
    },
    rosterParents(data){
          //console.log(data)
          this.resetData = false
          this.rosterParentsData = []
         // this.currentParent = ''
           var array = data
           this.updateAllPosition(data)
           var parents = [...new Set(array.map(p => p.stationParent))]
           var parentsObj = parents.map(item => item = this.stationsData.find(p => p.id == item))
           parentsObj.forEach(item => item['showStation'] = true)
           var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
           this.currentParent = this.currentParent ? this.currentParent : parentsSort[0] ? parentsSort[0].id : ''
           this.rosterParentsData = parentsSort
           this.resetData = true
      },
    stationsFromParents(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort

    },
    updateDataRosterPostions(index, array){
      array.splice(index, 1)
      var order = _.orderBy(array, ['position'], ['asc'])
     // console.log(order)
      var newArray = []
      for(var i = 0; i < order.length; i++){
        newArray.push({position: i + 1, type: order[i].type, start: order[i].start, duration: order[i].duration, cert: order[i].cert })
      }
      return newArray
    },
    returnRosterSpots(pid, sid){
      var array = this.rosterData.filter(p => p.stationParent == pid && p.stationId == sid)
      array.forEach(item => item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId) ? this.apparatusData.find(p => p.id == item.apparatusId).apparatusName : 'Unknown')
      array = array.map(p => {
        var obj = p
        if(obj.rosterPositions == undefined){
          obj['rosterPositions'] = []
        }
        else if(obj.rosterPositions == null){
           obj.rosterPositions = []
        }
        
        var appObj = this.apparatusData.find(x => x.id == p.apparatusId) 
        if(appObj){
          obj['apparatusIdObj'] = appObj
        } else {
           obj['apparatusIdObj'] = p.apparatusId
        }
        obj['stationIdObj'] = this.stationsData.find(x => x.id == p.stationId)
        obj['stationParentObj'] = this.stationsData.find(x => x.id == p.stationParent)
        return obj
      })
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])   
   //  console.log(apparatusSort)
      return apparatusSort
    },
    updateAllPosition(array){
      var data = _.cloneDeep(array)
      var holdarray = []
      for(var i in data){
        var obj = data[i]
      //  console.log(obj)
        var rosarr = obj.rosterPositions
        var newRosArr =  [] 
        for(var r in rosarr){
          var rp = rosarr[r]
          rp['start'] = '08:00:00'
          rp['duration'] = 24
          newRosArr.push(rp)
        }
      obj.rosterPositions = newRosArr
      holdarray.push(obj)
      }
    //  console.log(holdarray)
      this.updateAllPositionArray = holdarray
    },
    runUpdate(data){
      for(var i in data){
        this.updateRosterSpot(data[i])
      }
    },
    deleteRosterSpot(id){
    axios({
     method: 'delete',
     url: '/api/v1/staffing/roster/' + id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
          bus.$emit('getRoster', true)
   })

    },
    updateRosterTemplateAssigned(id){
      if(this.stationIdObj && this.stationParentObj){
        var obj = {
          'stationId': this.stationIdObj.id,
          'stationParentId': this.stationParentObj.id
        }
        if(this.rosterGroupTypeEdit == 'apparatusData'){
          obj['apparatusId'] = this.apparatusIdObj.id
          obj['rosterGroupType'] = 'apparatusData'
      } else {
          obj['apparatusId'] = this.apparatusIdObj
          obj['rosterGroupType'] = 'variable'
          obj['rosterName'] = this.rosterNameEdit
        }
      }
      axios({
        method: 'patch',
        url: '/api/v1/staffing/roster/' + id,
        data: obj,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
          this.editRosterSpotId = ''
          this.apparatusIdObj = ''
          this.stationIdObj = ''
          this.stationParentObj = ''
          this.rosterSpotTypeEdit = ''
          this.rosterNameEdit = ''
          bus.$emit('getRoster', true)
      })

    },
    deleteRoster(parent, data){

    },
    updateRosterSpot(data){
      delete data.updatedAt 
      delete data.createdAt 
      delete data.stationParent
      delete data.apparatusName
      delete data.apparatusIdObj
      delete data.stationIdObj
      delete data.stationParentObj
      delete data.rosterSpots
    axios({
     method: 'patch',
     url: '/api/v1/staffing/roster/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
                bus.$emit('getRoster', true)
   })
    },
    newRosterSpot(){
     var obj = {
          'stationId': this.stationSelectValue.id,
          'stationParentId': this.stationParentSelectValue.id
      }
     if(this.rosterGroupType == 'apparatusData'){
       if(this.apparatusSelectValue)
        obj['apparatusId'] = this.apparatusSelectValue.id
        } else {
        obj['rosterName'] = 'New Group'
        obj['rosterGroupType'] = 'variable'
        obj['apparatusId'] = parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
      }
     // console.log(obj)
    axios({
     method: 'post',
     url: '/api/v1/staffing/roster/',
     data: obj,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
            bus.$emit('getRoster', true)
   })
    
    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
