<template>
  <div class="hello">
     <div v-if="compResetForm">
    <b-container>
      <h4 class="font-weight-bold">{{ postData.formName }}</h4>
      <em>{{ postData.formDescription }}</em>
    </b-container>
    <b-btn v-show="resetComponent" variant="secondary" @click="showPostDataArray = !showPostDataArray">Show Post Data Array</b-btn><div v-show="showPostDataArray"> {{ postData }}</div>
    <b-btn v-show="resetComponent" variant="danger" @click="postData = {}"> Clear Test Save Data</b-btn>
   

    <div v-if="postData != ''" v-for="(post, index) in postData.formData" :key="index">
    <b-card bg-variant="light"
                :header="post.label"
                class="text-center">
    <div v-if="post.edit != true">
    <b-table stacked="lg" responsive outlined class="table-light" small :items="tableDataGet(post.data)">
     <template slot="signature" slot-scope="data">
      <b-img v-if="data.value != ''" width="200px" :src='"data:image/png;base64," + data.value.image' /><br>
      <em class="smallSignedData">{{ data.value.track }}</em>
      <h5 :key="index" v-if="data.value == ''">Missing</h5>
      </template>
      </b-table>
    <div v-for="(text, index) in textDataGet(post.data)" :key="index">
      <p><strong>{{  text.label }}: </strong>
        {{ text.value }}
        </p>
      </div>
    </div>
    <div v-if="post.edit == true">
            <b-card >
              <formgroups :formcomp="post" :formTemp="formSetup" @NewData="formDataChanges($event)" />
      </b-card>
      </div>
      </b-card>
      </div>

      
        <b-btn variant="primary" v-if="postData != ''" :disabled="formChanges" @click="saveFormData(postData, 'save')">Save</b-btn>
    <b-btn variant="success" v-if="postData != ''" :disabled="checkForCompletion(postData)" @click="saveFormData(postData, 'completed')">Submit</b-btn>
    <b-btn varient="danger" v-if="preview == true" @click="createNewForm(formData)">Reset</b-btn>
        <div v-if="true">{{ postData }}</div>
        </div>
  </div>
</template>
 
<script>

import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import formGroups from './formComp/formgroups.vue'
export default {
props: ['formData', 'preview'],
components: {
        'multiselect' : Multiselect,
        'formgroups' : formGroups,
},
data() {
    return {
      postData: '',
      tableData: [],
      formSetup: [],
      textData: [],
      tableDataFields: [],
      showPostDataArray: false,
      showComponentArray: false,
      resetComponent: false,
      formChanges: true,
      compResetForm: true,
      saveForm: {},
      signatureIds: [],
}
},
created () {
  if(this.formData.formId == undefined){
  this.formSetup = _.cloneDeep(this.formData)
  this.createNewForm(this.formSetup)
  } else {
    this.setupPreviousForm(this.formData)
    
  }
},
beforeDestroy(){
  
},
watch: {
 
},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData',
    'stationsData',
    'formtempData',
    'apparatusData',
    'deptContactData'
  ]),

},
mounted(){

  

},
methods: {
  setupPreviousForm(data){
    this.postData = data
    var temp = this.formtempData.filter(p => {
      return p.id == data.formId
    })
    this.formSetup = _.cloneDeep(temp[0])
  },
  formDataChanges(data){
    var index = this.postData.formData.map(p => p.id).indexOf(data.id)
    var dataArr = this.postData.formData[index]
    var itemI = dataArr.data.map(p => p.id).indexOf(data.value.id)
    dataArr.data[itemI].value = data.value.value
    this.formChanges = false
  },
  checkForCompletion(comp){
    console.log(comp)
    if(comp.formCompleted != undefined && comp.formCompleted == true){
     // console.log(comp.formCompleted)
     // console.log(comp)
      return true
    } else {
    var comp = comp.formData
    if(comp != undefined){
    var array = []
    for(var i = 0; i < comp.length; i++){
      if(comp[i].edit == true){
        array.push('false')
      }
    }
    var index = array.indexOf('false')
    if(index < 0){
      return false
    } else {
      return true
    }
    } else {
      return true
    }
    }
  },
  clearFormData(){
    var array = this.formSetup.formtempComponentData
    for(var i = 0; i < array.length; i++){
      var subs = array[i].groupComponents
      for(var p = 0; p < subs.length; p++){
        if(subs[p].defaultValue != '' && subs[p].defaultValue != undefined){
          subs[p].value = subs[p].defaultValue
        } else if (subs[p].type == 'dateTime'){
          subs[p].value = {date: '', time: ''}
          } else {
        subs[p].value = ''
        }
      }
    }
  },
    textDataGet(data){
      if(data != false){
    return data.filter(p => {
      return p.type == 'textarea'
    })
      }
  
  },
  tableDataGet(data){
    var obj = {}
    var textObj = []
    for(var i = 0; i < data.length; i++){
      var key = data[i].label
      if(data[i].type == 'textarea'){
        
      } else if (data[i].type == 'stationsData' ||data[i].type == 'apparatusData' || data[i].type == 'deptContactData'){
        obj[key] = this.getItemDetails(data[i])
      } else if (data[i].type == 'signature'){
        obj['signature'] = data[i].value
        
        } else {
      obj[key] = data[i].value }
    }
    textObj.push(obj)

    return textObj
  },
  getItemDetails(item, filter){
      if(Array.isArray(item.value)){
      var newArray = []
      for(var i = 0; i < item.length; i++){
      if(arrayData[i] != null){
      if(item.type == 'stationsData'){
        newArray.push(arrayData[i].stationName)
      } else if (item.type == 'apparatusData') {
        newArray.push(arrayData[i].apparatusName)
      } else if (item.type == 'deptContactData'){
        newArray.push(arrayData[i].firstName + ' ' + arrayData[i].lastName)
      } else {
        return 
      }} else {
        return
      }
      }
      return newArray
    } else {
    if(item.value != undefined){
      if(item.type == 'stationsData'){
        return item.value.stationName
      } else if (item.type == 'apparatusData') {
        return item.value.apparatusName
      } else if (item.type == 'deptContactData'){
        return item.value.firstName + ' ' + item.value.lastName
      } else {
        return ''
      }
    } else {
      return ''
    } 
    }
  },
  saveFormData(data, formStatus){
  this.compResetForm = false
  var tabledata = this.postData.tableview
  var indexData = this.postData.indexValue
  this.postData = ''
   var data = _.cloneDeep(data)
   var postData = data
    var array = []
    var item = data.formData
    for(var i = 0; i < item.length; i++){
 
      if(item[i].edit == true){
      var subString = []
      var edit = this.checkForNewCompletion(item[i].data)
      var comp = item[i].data
      for(var p = 0; p < comp.length; p++){
      var value = this.getReturnValueData(item[i].data, comp[p], item[i])
      if(comp[p].type == 'dateTime' || comp[p].type == 'date'){
        if(value.date != undefined && value.date != ''){
          var done = true
        } else {
          var done = false
        }
      } else if(value != ''){
        var done = true
      } else {
        var done = false
      }
      var string = { id: comp[p].id, type: comp[p].type, label: comp[p].label, value: value, completed: done }
      if(comp[p].type == 'ref'){
        string['options'] = comp[p].options
      }
      subString.push(string)

      }
      var aString = { id: item[i].id, label: item[i].label, data: subString, edit: edit} 
      array.push(aString)
      } else {
      array.push(item[i])
      }

    }
    postData['formData'] = array
    this.postData = postData
    var item = this.postData.formData
    if(indexData != undefined && indexData.length > 0){
    for(var i = 0; i < item.length; i++){
    var comp = item[i].data
    for(var p = 0; p < comp.length; p++){
      var index = indexData.map(p => p.id).indexOf(comp[p].id)
        if(index >= 0){
          if(comp[p].type == 'deptContactData' || comp[p].type == 'stationsData' || comp[p].type == 'apparatusData'){
            if(comp[p].value.id != undefined){
              indexData[index].value = comp[p].value.id
            } else {
               indexData[index].value = comp[p].value
            }
          } else {
          indexData[index].value = comp[p].value
          }
        }
    }
    }
    this.postData['indexValue'] = indexData
    }

    if(tabledata != undefined && tabledata.length > 0){
    for(var i = 0; i < item.length; i++){
    var comp = item[i].data
    for(var p = 0; p < comp.length; p++){
        var index = tabledata.map(p => p.id).indexOf(comp[p].id)
        if(index >= 0){
          tabledata[index].value = comp[p].value
        }
    }
    }
    this.postData['tableview'] = tabledata
    }
    
    var vm = this
    this.$nextTick(function(){
         vm.compResetForm = true
    })
    if(this.preview != undefined && this.preview == false && formStatus == 'save'){
      this.$emit('submitForm', this.postData)
    } else if (this.preview != undefined && this.preview == false && formStatus == 'completed'){
      this.$emit('completeForm', this.postData)
    }
    if(this.preview != undefined && this.preview == true){
      this.$emit('previewSubmission', this.postData)
    }
    this.formChanges = true
    
  },
  checkIfItemTracked(data){
    var comps = this.formSetup.formtempComponentData
    var obj = false
    var comps = comps.filter(p => { return p.tracked == true})
    for(var i = 0; i < comps.length; i++){
      var groups = comps[i].groupComponents
      var item = groups.filter(p => { return p.id == data.data[0].id })
      if(item[0] != undefined){
        obj = item[0]
      } 
    }
    return _.cloneDeep(obj)
  },
  createNewForm(data){
    var postData = {
      formId: data.id,
      formName: data.formtempName,
      formDescription: data.formtempDescription,
      formCategory: data.formtempCategory,
      formParentCategory: data.formtempParentCategory,
      formData: []
      
    }
    var array = []
    var item = data.formtempComponentData
    for(var i = 0; i < item.length; i++){
      var subString = []

      var edit = this.checkForNewCompletion(item[i].groupComponents)
      var comp = item[i].groupComponents
      for(var p = 0; p < comp.length; p++){
      var value = this.getReturnValueData(item[i].groupComponents, comp[p], item[i])
      var string = { id: comp[p].id, type: comp[p].type, label: comp[p].label, value: value}
       if(comp[p].type == 'ref'){
        string['options'] = comp[p].options
      }
      subString.push(string)
      if(comp[p].tableView != undefined && comp[p].tableView == true){
          if(postData['tableview'] == undefined){
            postData['tableview'] = []
            postData['tableview'].push({item: comp[p].label, value: '', id: comp[p].id, type: comp[p].type})
          } else {
            var length = postData['tableview'].length
            postData['tableview'].push({item: comp[p].label, value: '', id: comp[p].id, type: comp[p].type})
          }
        } 
      if(comp[p].indexValue != undefined && comp[p].indexValue == true){
          if(postData['indexValue'] == undefined){
            postData['indexValue'] = []
            postData['indexValue'].push({id: comp[p].id, value: ''})
          } else {
             postData['indexValue'].push({id: comp[p].id, value: ''})
          }
        } 
      }
      var aString = { id: item[i].id, label: item[i].groupLabel, data: subString, edit: edit} 
      array.push(aString)
    }
    postData['formData'] = array

    this.postData = postData
  },
    checkForNewCompletion(comp){
    if(comp != undefined){
    var array = comp
    var check = []
    for(var i = 0; i < array.length; i++){
      if(array[i].value == '' || array[i].value == null || array[i].value == undefined){
        if(array[i].type != 'ref' && array[i].defaultValueLoggedUser != true){
        check.push('true')
        }
      }
    }
    var index = check.indexOf('true')
    if(index < 0){
      return false

    } else {
    return true
    }
    } else {
      return true
    }
  },
  getReturnValueData(data, compData, group){
        if(compData.type == 'stationsData' || compData.type == 'apparatusData' || compData.type == 'deptContactData'){
          var value = compData.value
          if(value != undefined && value != null){
           
          if(value.length != undefined && value.length != null && value != ''){
          
          var secArray = []
          for(var x = 0; x < value.length; x++){
            secArray.push(value[x])
          } var value = secArray
          } else {
         
          if(value != undefined && value != null && compData.defaultValueLoggedUser != true){
                var value = value
                } else if (compData.defaultValueLoggedUser == true){
              var value = this.userData } 
              else {
                 var value = ''
                }
          }
          } else {
           
              var value = ''
            
          }
        } else if (compData.type == 'ref') {
   
          if(compData.value != undefined && compData.value != null && compData.value != ''){
            var value = compData.value
          } else {
          var value = this.getRefValue(data, compData.options, group)

          }
        } else if (compData.type == 'dateTime'){
          if(compData.value.date != '' && compData.value.time != ''){
          var value = this.formatedDateExport(compData.value.date, compData.value.time)
          
          } else {
            var value = {data: '', time: ''}
          }
          } else if (compData.type == 'date'){
            if(compData.value != ''){
              var value = this.formatedDateExport(compData.value, null)
            } else {
              var value = ''
            }
            } else {
          var value = compData.value
         
        }
        return value
  },
  formatedDateExport(date, time) {
      if(time == null){
        var time = '00:00:00'
      } else {
        var time = moment(time, 'hh:mm').format('HH:mm:ss')
      }
      var timeString = date + "T" + time;
      var offSet = moment().utcOffset();
      var hourOffSet = offSet * -1 / 60;
      var timeStringForm = moment(timeString).add(hourOffSet, "hours");
      var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
      return timeStringAdd + "Z";
    },
  getRefValue(data, ref, group){
    if(group != undefined && group.tracked == true){
      var isDefault = group.groupComponents.map(p => p.defaultValueLoggedUser).indexOf(true)
      if(isDefault >= 0){
        return this.userData[ref.refItem]
      } else {
        var item = data
        var data = item.filter(p => {
          return p.id == ref.itemId
        })
        if(data[0].value != undefined && data[0].value != ''){
          var datai = data[0].value
          return datai[ref.refItem]
        } 
      }
    } else {
    var item = data
    var data = item.filter(p => {
      return p.id == ref.itemId
    })
    if(data[0].value != undefined && data[0].value != ''){
      var datai = data[0].value
      return datai[ref.refItem]
    } 
    }
  },
  getDataFormReturn(data, filter){
    var array = this[data]
    if(filter != undefined && filter != null && filter.length > 0){
      for(var i = 0; i < filter.length; i++){
        if(filter[i].multiple == true){
            var array = array.filter( p => {
            var arrayTest = p[filter[i].filterKey]
            if(Array.isArray(arrayTest)){
              var userArray = arrayTest
              let found = userArray.some(r=> filter[i].filterValue.indexOf(r) >= 0)
              if(found){
                return p
              }
            } else {
              var indexR = filter[i].filterValue.indexOf(p[filter[i].filterKey])
              if(indexR >= 0){
                return p
              }
            }
          })

        } else {
          var array = array.filter( p => {
            var arrayTest = p[filter[i].filterKey]
            if(Array.isArray(arrayTest)){
              var indexOfArr = arrayTest.indexOf(filter[i].filterValue)
              if(indexOfArr >= 0){
                return p
              }
            } else {
            return p[filter[i].filterKey] == filter[i].filterValue
            }
          })

         }
       } return array    
    } else {
    return array
    }
  },
  getLabel(data){
    if(data == 'stationsData'){
      return 'stationName'
    } else if ( data == 'apparatusData') {
      return 'apparatusName'
    } else {
      return 'lastName'
    }
  },
 
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.smallSignedData{
  font-size: 12px;
}
.errorClass {
  border: 1px solid red;
  border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
