<template>
    <div>
      
        <div class="card event" v-if="!daydisplay && calendarType == null" :class="'bg-' + event.color"  @click="showEventDetails">
            <div class="card-header event-title" :class="{'clickable-event':isDaySelected}">
                <div :class="event.color != 'default' ? 'text-light' : 'text-dark'">{{event.title}} ({{event.description.length}})</div>
                </div>
        </div>
        <div class="card event" v-if="!daydisplay && calendarType == 'payroll'"   @click="showEventDetails">
            <div :class="'text-' + event.color">
                <div :class="event.color != 'default' ? 'text-light' : 'text-dark'">{{event.title}} ({{event.description.length}})</div>
                </div>
        </div>
        <div v-if="daydisplay">
            <div v-for="single in event.description" class="text-left" :class="'text-' + event.color" :key="single.id" @click="calendarType == null ? showEventDetails : calendarType == 'payroll' ? showPayrollModification(event) : ''">
                <p class="noPaddingBottom">{{ getTime(single.eventDate) }} | {{ single.eventTitleDescription }}</p>
                <em v-if="single.currentStatus" :class="single.currentStatus == 'Awaiting Approval' || single.currentStatus == 'Available' ? 'text-Yellow' : single.currentStatus == 'Approved' ? 'text-bright-green' : 'text-text-danger'">{{ single.currentStatus }}</em>
                </div>
        </div>
        <EventDetails :show.sync="displayEventDetails" :event="event" :calendarType="calendarType" :canDeleteEvent="canDeleteEvent" @eventDeleted="eventDeleted"></EventDetails>
    </div>
</template>

<script>
    import {EVENT_DELETED, SHOW_DETAILS_EVENT_MODAL} from '../actions';
    import EventDetails from './EventDetails.vue'
    import moment from 'moment'
    import { bus } from '../../../main'
    export default {
        components: {
            'EventDetails': EventDetails,
        },

        props: {
            event: {
                type: Object
            },

            isDaySelected: {
                type: Boolean
            },

            canDeleteEvent: {
                type: Boolean,
            },
            daydisplay: {
                type: Boolean,
                default: false,
            },
            calendarType: {
                type: String,
                default: null
            },
        },

        data() {
            return {
                displayEventDetails:  false,
            }
        },

        methods: {
            getTime(data){
                if(data){
                    return moment(data).format('HH:mm')
                } else {
                    return ''
                }
            },
            showEventDetails() {
                if(this.isDaySelected){
                    this.displayEventDetails = true;
                }
            },
            showPayrollModification(event){
                if(event.payrollMod){
                 bus.$emit('updatePayrollMod', event.payrollMod)
                }
            },
            eventDeleted(event) {
                this.$emit(EVENT_DELETED, event);
            }
        },
    }
</script>
<style>
    .card.EMS {
    background: #007bff;
    color: #fff;
    }
    .noPaddingBottom{
        margin-bottom: 0;
    },
    .card.Fire {
      background: #dc3545;
      color: #fff;
    }
    .card.Special {
      background: orange;
      color: #fff;
    }
    .card.Maintenance {
      background: #868e96;
      color: #fff;
    }
    .card.Public {
      background: #28a745;
      color: #fff;
    }
    .event {
        margin-bottom: 5px;
    }
    .event > .event-title {
        padding: 0px 5px;
        font-size: 12px
    }
    .clickable-event {
    }
        .text-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-text-primary {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-text-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-text-success {
        background-color: #28a745;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-text-danger {
    background-color: #dc3545;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
    @media screen and (max-width: 768px) {
    .event > .event-title {
      padding: 5px 5px;
      font-size: 16px;
    }
    }
</style>
