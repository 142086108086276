<template>
    <b-container fluid>
  <b-navbar type="dark" variant="dark">
           <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Options:</b-navbar-brand>
         <b-form-select v-model="currentYear" :options="yearsDropDown" />
         <b-navbar-brand class="bootstrapTable ml-2">
           <b-form-select v-model="cycleType" :options="cycleTypeOptions" @change="getCurrentPayPeriod" />
         </b-navbar-brand>
         <b-form-select v-if="cycleType == 'Pay Period'" v-model="currentView" :options="previousPayrolls" />
         <b-form-select v-if="cycleType == 'Work Cycle'" v-model="currentView" :options="previousWorkCycles" />
         <b-form-select v-if="cycleType == 'Work Week'" v-model="currentView" :options="previousWorkWeeks" />
         <b-form-select v-if="cycleType == 'Kelly Cycle'" v-model="currentView" :options="previousKellyCycles" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectStart" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectEnd" />
         <b-btn variant="info" v-if="cycleType == 'Select Range'" @click="runRangeDate">Run</b-btn>
      </b-nav-form>
      <b-nav-form class="ml-2" v-if="siteconfig.payroll.approvalCycle && siteconfig.payroll.approvalCycle == cycleType">
         <b-btn variant="primary" v-if="!checkForLocked(cycleType, currentView) || (userData.role == 'admin' || userData.role == 'adminchief')"  @click="approveTimeCardView = !approveTimeCardView">{{ approvedUserTimecard ? 'Edit' : 'Approve' }} TimeCard Export</b-btn>
         <b-btn v-if="siteconfig.payroll.approvalCycle && siteconfig.payroll.approvalCycle == cycleType && checkForLocked(cycleType, currentView)"
        :title="cycleType + ' locked on ' + getDateFormat(lockedReport.createdAt, true) + ' by ' + lockedReport.exportAuthor"
        :disabled="true"
        variant="danger"
        class="ml-2"
         >{{ cycleType }} Locked</b-btn>
         <b-btn :variant="!printView ? 'info' : 'danger'" @click="printView = !printView" class="ml-2">{{ !printView ? 'Open Print View' : 'Close Print View' }}</b-btn>
          <b-btn v-if="printView" class="ml-2 d-none d-lg-block" variant="primary" @click="executePrintJS">Print</b-btn>
      </b-nav-form>
    </b-navbar>
     <div :id="printTabId"  :class="printReportOn ? 'printReport' : ''">
      <h4 v-if="printView && userSelected && userSelected.id">Timecard for: {{ getUserData(userSelected.id) }}</h4>
      <h5 v-if="printView">{{ cycleType }}: {{ currentView.start }} till {{ currentView.end }}</h5>
    <b-card v-if="siteconfig.payroll.approvalCycle && siteconfig.payroll.approvalCycle == cycleType" no-body>
      <b-container v-if="approvedUserTimecard">
        <b-row>
          <b-col class="font-weight-bold">User Approval</b-col>
          <b-col class="font-weight-bold">Supervisor Approval</b-col>
        </b-row>
        <b-row>
          <b-col><span class="font-weight-bold">Date: </span>{{ getDateFormat(approvedUserTimecard.userApprovedDate, true) }}</b-col>
          <b-col><span class="font-weight-bold">Date: </span>{{ getDateFormat(approvedUserTimecard.supervisorApprovedDate, true) }}</b-col>
        </b-row>
         <b-row>
          <b-col><span class="font-weight-bold">By User: </span>{{ getUserData(approvedUserTimecard.userApprovedId) }}</b-col>
          <b-col><span class="font-weight-bold">By User: </span>{{ getUserData(approvedUserTimecard.supervisorApprovedId) }}</b-col>
        </b-row>
      </b-container>
      <b-container v-if="approveTimeCardView && !approvedUserTimecard">
        <h5 class="text-danger">No Approvals have been submitted for this pay period</h5>
      </b-container>
        <b-card v-if="approveTimeCardView && !checkForLocked(cycleType, currentView)">
          <h5 class="text-danger" v-if="timeCardOverviewDataPreApproved.scheduledMissing != 0">The Scheduled employee hours do not match the sum of Timecard Hours and Leave Hours (Difference: {{-1 * roundNumber(timeCardOverviewDataPreApproved.scheduledMissing)}})</h5>
          <b-form-checkbox v-if="false" v-model="userApproveCheck" :disabled="(!(userData.id == userSelected.id || userData.role == 'admin') || (!(approvedUserTimecard && approvedUserTimecard.supervisorApprovedId) && !(userPerms && userPerms.payrollTimecardSelfApprove)))">I approve my timecard for export</b-form-checkbox>
           <b-form-checkbox v-if="true" v-model="userApproveCheck" :disabled="(!(userData.id == userSelected.id || userData.role == 'admin'))">I approve my timecard for export</b-form-checkbox>
          <b-form-checkbox v-if="userData.id != userSelected.id" v-model="supervisorApproveCheck" :disabled="!(userData.id != userSelected.id && checkForUserPermissions && checkForUnapprovedPayrollMods)">I approve this users timecard for export</b-form-checkbox>
          <h5 class="text-danger" v-if="!checkForUnapprovedPayrollMods && userData.id != userSelected.id">There are payroll modifications that need attention, please approve or deny to approve this timecard for export</h5>
          <b-form-checkbox v-if="approvedUserTimecard && approvedUserTimecard.id" v-model="denyCheck">Deny this Timecard</b-form-checkbox>
          <b-btn v-if="userApproveCheck" variant="success" class="ml-2" @click="approveTimeCard(datesFromCurrentView, false, approvedUserTimecard)">Submit</b-btn>
          <b-btn v-if="supervisorApproveCheck" variant="success" class="ml-2" @click="approveTimeCard(datesFromCurrentView, true, approvedUserTimecard)">Submit</b-btn>
          <b-btn v-if="denyCheck" variant="danger" @click="disapproveTimecard(approvedUserTimecard)">Deny</b-btn>
    </b-card>
      </b-card>
   
    <b-card  v-if="userShifts && currentView" no-body  bg-variant="dark" text-variant="light" class="font-weight-bold p-1 m-2">
      <b-row v-if="!siteconfig.payroll.autoTimeCards">
        <b-col>Scheduled Hours: {{ timeCardOverviewData.scheduledHours }}</b-col>
        <b-col>TimeCard Hours: {{ roundNumber(timeCardOverviewData.timeCardHours) }} Hrs</b-col>
        <b-col>Approved Hours: {{ roundNumber(timeCardOverviewData.timeCardApprovedHours) }} Hrs</b-col>
        <b-col>Payroll Mod Hours: {{ timeCardOverviewData.payrollModHours }}</b-col>
      </b-row>
      <b-row v-if="siteconfig.payroll.autoTimeCards">
         <b-col>Scheduled: {{ timeCardOverviewDataPreApproved.scheduledHours }} <span title="Timecards Hours Awaiting Approval" v-if="timeCardOverviewDataPreApproved.scheduledMissing != 0" class="text-danger">({{ -1 * roundNumber(timeCardOverviewDataPreApproved.scheduledMissing) }})</span></b-col>
        <b-col>Timecard: {{ timeCardOverviewDataPreApproved.timeCardHours }} <span title="Timecards Hours Awaiting Approval" v-if="timeCardOverviewDataPreApproved.timeCardNeedsAttn && timeCardOverviewDataPreApproved.timeCardNeedsAttn > 0" class="text-warning">({{ roundNumber(timeCardOverviewDataPreApproved.timeCardNeedsAttn) }})</span> </b-col>
        <b-col>Leave: {{ roundNumber(timeCardOverviewDataPreApproved.leaveMods) }} <span title="Payroll Mods Awaiting Approval" v-if="timeCardOverviewDataPreApproved.leaveModsNeedsAttn && timeCardOverviewDataPreApproved.leaveModsNeedsAttn > 0" class="text-warning">({{ roundNumber(timeCardOverviewDataPreApproved.leaveModsNeedsAttn) }})</span> </b-col>
        <b-col>Add/OT: {{ roundNumber(timeCardOverviewDataPreApproved.addMods) }} <span title="Addition or Overtime Mods Awaiting Approval" v-if="timeCardOverviewDataPreApproved.addModsNeedsAttn && timeCardOverviewDataPreApproved.addModsNeedsAttn > 0" class="text-warning">({{ roundNumber(timeCardOverviewDataPreApproved.addModsNeedsAttn) }})</span> </b-col>
        <b-col>Total: {{ roundNumber(timeCardOverviewDataPreApproved.total) }} <span title="Total Hours that are Awaiting Approval" v-if="timeCardOverviewDataPreApproved.totalNeedsAttn && timeCardOverviewDataPreApproved.totalNeedsAttn > 0" class="text-warning">({{ roundNumber(timeCardOverviewDataPreApproved.totalNeedsAttn) }})</span>  </b-col>
      </b-row>
    </b-card>
    <b-container v-if="userShifts && currentView && printView">
        <b-table small :items="getTimeCardTableFormat(datesFromCurrentView)" />
    </b-container>
    </div>
    <b-container v-if="userShifts && currentView && !printView">
      <b-row v-for="(d, index) in datesFromCurrentView" :key="index" class="mt-2">
        <b-col cols="2"><h4 ><b-badge class="mt-0" :variant="d.workDays ? 'secondary' : 'light'">{{ getDateFormat(d.date) }}</b-badge>
        <b-badge variant="primary" :disabled="checkForDisabled" @click="addNewPayrollMod(d.workDays, d)">+</b-badge></h4>
        </b-col>
        <b-col>
          <b-card no-body v-if="d.workDays" bg-variant="secondary" text-variant="light" class="font-weight-bold p-1">
            <b-row  v-for="(w, indexw) in d.workDays" :key="indexw">
               <b-col>Scheduled Start: {{ getTimeFormated(w.startTime) }}</b-col>
               <b-col>Scheduled End: {{ getWorkDayEndTime(w) }}</b-col>
               <b-col>On Clock: {{ w.length }} Hrs</b-col>
            </b-row>
          </b-card>
          <b-card no-body v-else bg-variant="light" text-variant="dark" class="font-weight-bold p-1">
          Not Scheduled
            </b-card>
          <b-card v-if="d.timeCards" border-variant="secondary" class="m-2" :no-body="timeCardShowHideHold[d.date.toISOString()].showAll">
              <h6 v-if="approvedUserTimecard && checkForIdIncludedMultiple(d.timeCards, 'approvedTimecards') && timeCardShowHideHold[d.date.toISOString()].showAll" class="text-danger mt-1">Timecard is not included in the approved timecards, please re-submit to include</h6>
                <b-row slot="header"> 
                  <b-col>
                        <b-badge v-if="timeCardShowHideHold[d.date.toISOString()].approvedAll" variant="success"><h6 class="mb-0">&#10003;</h6></b-badge>
                        <b-badge v-if="!timeCardShowHideHold[d.date.toISOString()].approvedAll" variant="warning"><h6 class="mb-0">&#9888;</h6></b-badge>
                  </b-col>
                   <b-col cols="1"><b-badge variant="warning" class="cursor" v-if="checkForTimeCardNotes(d.timeCards)" :title="checkForTimeCardNotes(d.timeCards)"><h6 class="mb-0">N</h6></b-badge></b-col>
                  <b-col>TimeCards: {{ d.timeCards.length }}</b-col>
                  <b-col>Hours: {{ roundNumber(timeCardHeadersData(d.timeCards)) }} Hrs</b-col>
                  <b-col>Approved: {{ roundNumber(timeCardHeadersData(d.timeCards, 'approved')) }} Hrs</b-col>
                  <b-col><b-btn size="sm" variant="secondary" @click="timeCardShowHideHold[d.date.toISOString()].showAll = !timeCardShowHideHold[d.date.toISOString()].showAll">View</b-btn></b-col>
                  </b-row>
                <b-card v-for="(t, indext) in d.timeCards" :key="indext" v-show="!timeCardShowHideHold[d.date.toISOString()].showAll" >
                  <h6 v-if="approvedUserTimecard && checkForIdIncluded(t.id, 'approvedTimecards')" class="text-danger">This Timecard is not included in the approved timecards, please re-submit to include</h6>
                <b-row slot="header">
                  
                  </b-row>
                  <b-row>
                    <b-col align="right">Clock In: <span>&#128205;</span></b-col>
                    <b-col><b-btn variant="secondary" size="sm" @click="showHoursId == t.id ? showHoursId = '' : (showHoursId = t.id, setHoursTime(t))">{{ showHoursId == t.id ? 'Time Mode' : 'Hours Mode' }}</b-btn></b-col>
                    <b-col align="left">Clock Out: <span>&#128205;</span></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col><b-form-input :disabled="checkForDisabled" type="date" v-model="timeCardHold[t.id].clockInDate" :title="getDateTimeFormat(timeCardHold[t.id].clockInActual, 'date')" /></b-col>
                    <b-col><b-form-input :disabled="checkForDisabled" type="time" v-model="timeCardHold[t.id].clockInTime"  :title="getDateTimeFormat(timeCardHold[t.id].clockInActual, 'time')" /></b-col>
                    <b-col v-if="showHoursId != t.id"><b-form-input :disabled="checkForDisabled" type="date" v-model="timeCardHold[t.id].clockOutDate" :title="getDateTimeFormat(timeCardHold[t.id].clockOutActual, 'date')" /></b-col>
                    <b-col v-if="showHoursId != t.id"><b-form-input :disabled="checkForDisabled" type="time" v-model="timeCardHold[t.id].clockOutTime" :title="getDateTimeFormat(timeCardHold[t.id].clockOutActual, 'time')" /></b-col>
                    <b-col v-if="showHoursId == t.id"><b-form-input :disabled="checkForDisabled" type="number" v-model="tempTimeCardHours" /></b-col>
                    <b-col v-if="showHoursId == t.id"><b-btn size="sm" variant="info" @click="updateHoursTime(t)">Set Clockout</b-btn></b-col>
                  </b-row>
                  <b-row v-if="departmentCodingRequired">
                    <b-col>Department Assigned</b-col><b-col><b-form-select :state="timeCardHold[t.id].timecardDepartment ? true : false" :options="stationsDataChildren" v-model="timeCardHold[t.id].timecardDepartment" /></b-col>
                  </b-row>
                  <b-row slot="footer" class="mt-2">
                    <b-col><b-badge v-if="!timeCardHold[t.id].timecardApproved" variant="warning"><h6 class="mb-0">Needs Attn:</h6></b-badge>
                    <b-btn v-if="userData.role != 'user' && !t.id.includes('AUTOBUILD_')" :disabled="checkForDisabled" size="sm" variant="danger" @click="deleteTimeCard(t.id)">Delete</b-btn>
                    </b-col>
                    <b-col><b-form-checkbox  :disabled="checkForDisabled" v-model="timeCardHold[t.id].timecardApproved">Approved</b-form-checkbox></b-col>
                    <b-col><b-btn size="sm" @click="timeCardCommentModal = !timeCardCommentModal, setTimeCardCommentModal(t.id)" :variant="timeCardHold[t.id].timecardComment ? 'warning' : 'secondary'">Note</b-btn></b-col>
                    <b-col><b-btn size="sm" v-if="false">History</b-btn></b-col>
                    <b-col>
                     <b-btn :disabled="checkForDisabled" @click="updateTimeCardAuto(d.timeCards)" size="sm" variant="primary" v-if="siteconfig.payroll.autoTimeCards && !timeCardHold[t.id].additional && false">Update</b-btn>
                     <b-btn :disabled="checkForDisabled" @click="updateTimeCardAuto(d.timeCards)" size="sm" variant="info" v-if="siteconfig.payroll.autoTimeCards && !checkIfEqual(t)">Save</b-btn>
                    <b-btn :disabled="checkForDisabled" @click="updateTimeCard(timeCardHold[t.id])" size="sm" variant="primary" v-if="!siteconfig.payroll.autoTimeCards">Update</b-btn>
                    </b-col>
                  </b-row>
                <span v-show="false">{{ timeCardHold[t.id] }}</span>
    
                </b-card>
              <b-btn v-if="!timeCardShowHideHold[d.date.toISOString()].showAll && siteconfig.payroll.autoTimeCards" block variant="success" @click="addNewTimeCard(d.date.toISOString())">Add Time Card</b-btn>
              <b-btn v-if="!timeCardShowHideHold[d.date.toISOString()].showAll && !siteconfig.payroll.autoTimeCards" block variant="success" @click="openTimeCard(d.workDays)">Add Time Card</b-btn>
            </b-card>
            <b-card v-if="d.payrollMods">
                <b-card no-body class="m-2 p-3" v-for="(p, indexp) in d.payrollMods" :key="indexp">
                  <h6 v-if="approvedUserTimecard && checkForIdIncluded(p.id, 'approvedPayrollmods')" class="text-danger">This Payroll Mod is not included in the approved timecard, please re-submit to include</h6>
                  <b-row >
                    <b-col cols="2"><b-badge :variant="getApprovalStatusColor(p.payrollModApproved)"><h6 class="mb-0">{{ getApprovalStatus(p.payrollModApproved) }}</h6></b-badge></b-col>
                    <b-col cols="1"><b-badge variant="warning" class="cursor" v-if="p.payrollModNote" :title="p.payrollModNote"><h6 class="mb-0">N</h6></b-badge></b-col>
                    <b-col cols="3"><span :class="'payroll-' + getCodeObj(p.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(p.payrollModCode).payrollCode }}</span></b-col>
                    <b-col><span>{{ getDateTimeFormat(p.payrollModDatetime, 'date') }} at {{ getDateTimeFormat(p.payrollModDatetime, 'time') }} for {{ p.payrollModHours }} Hrs</span></b-col>
                    <b-col cols="2">
                      <b-btn v-show="!p.systemCreated" :disabled="checkForDisabled" size="sm" variant="primary" @click="updatePayrollMod(p, d)">Update</b-btn></b-col>
                  </b-row>
                </b-card>
            </b-card>
            <b-card v-if="d.workDays && !d.payrollMods && !d.timeCards">
              <b-row>
                <b-col><b-btn variant="success" :disabled="checkForDisabled" size="sm" @click="openTimeCard(d.workDays)">Add TimeCard</b-btn></b-col>
                <b-col><b-btn variant="primary" :disabled="checkForDisabled" size="sm" @click="addNewPayrollMod(d.workDays, d)">Add PayrollMod</b-btn></b-col>
              </b-row>
              </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal v-model="timeCardCommentModal" title="TimeCard Note" :hide-footer="true" :hide-header="false" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <b-textarea v-model="timeCardCommentModalValue" />
      <b-btn v-if="approvedUserTimecard || checkForDisabled" @click="updateTimeCardComment(true)">Close</b-btn>
      <b-btn v-if="!checkForDisabled" @click="updateTimeCardComment(false)">Update Comment</b-btn>
    </b-modal>
        </b-container>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import Papa from 'papaparse'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import JsonExcel from 'vue-json-excel';
import html2canvas from 'html2canvas';
import printJS from 'print-js'
export default {
  components: {
    multiselect,
    'download-excel' : JsonExcel,
  },
  
  name: 'time-card-verification',
  props:  ['rosterUserProfiles', 'scheduleData', 'userSelected', 'payrollCodesData', 'payrollHolidaysData', 'holdState', 'payrollPermsData'],
  data () {
    return {
      currentPage: 0,
      searchData: '',
      printView: false,
      payrollBankRecoDate: '',
      currentView: null,
      showFilter: false,
      denyCheck: false,
      approveTimeCardView: false,
      hideUsersWithNone: false,
      userApproveCheck: false,
      approvedUserTimecard: null,
      supervisorApproveCheck: false,
      selectAllBoo: false,
      userShifts: false,
      tempTimeCardHours: 0,
      showHoursId: '',
      timeCardCommentModal: false,
      timeCardCommentModalValue: '',
      timeCardCommentModalId: '',
      cycleType: 'Work Cycle',
      currentYear: '',
      printTabId: '',
      printReportOn: false,
      selectedFilter: [],
      payrollRecoMods: [],
      payrollReports: [],
      additionalTimeCards: [],
      timeCardHold: {},
      viewReports: [],
      modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'}, 
     ],
     timeCardShowHideHold: {},
      selectStart: '',
      selectEnd: '',
      departmentCodingRequired: true,
      departmentCodeDefault: '',
      userTimeCards: [],
      userPayrollMods: [],
      cycleTypeOptions: [],
      lockedReport: null,
      leaveModArray: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'upaid leave',
      ],
      addModArray: [
       'payaddative',
       'overtime'
      ]
    }
  },
  created(){
    if(this.holdState){
     // console.log('HoldState')
     // console.log(this.holdState)
      this.currentView = this.holdState.currentView
      this.currentYear = this.holdState.currentYear
      this.cycleType = this.holdState.cycleType
    } else {
      this.getCurrentPayPeriod()
    }
    this.printTabId = 'print-' + this.userSelected.id
    this.getCycleTypeOptions()
    this.selectStart = moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD')
    this.selectEnd = moment().startOf('month').format('YYYY-MM-DD')
    bus.$on('newPayrollModification', this.updateScheduleView)
    bus.$on('closeTimeCard', this.updateScheduleView)


  },
  computed: {
    ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        userPerms(){
         // return false
         var userPerms = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
         console.log('userperms', userPerms)
          return userPerms
        },
        stationsDataChildren(){
          var children = _.cloneDeep(this.stationsData)
          var parents = children.map(p => p.stationParent)
          var child = children.filter(p => {
                var index = parents.indexOf(p.stationName)
                if(index == -1){
                  return p
                }
          })
          var sort = child.map(p => p.stationName)
          return _.sortBy(sort, ['asc'])
        },
        typicalStartTime(){
          if(this.rosterUserProfiles && this.rosterUserProfiles[0] && this.scheduleData){
            var schDataObj = this.scheduleData.find(p => p.id == this.rosterUserProfiles[0].scheduledShift)
            if(schDataObj && schDataObj.schedulePattern){
             var times = schDataObj.schedulePattern.map(p => p.interval.map(x => x.startTime));
             var overAllTimes = []
             times.forEach((time) => {
                time.forEach((start) => {
                  if(start){
                    overAllTimes.push(start)
                  }
                })
             })
             var baseSet = [...new Set(overAllTimes.map(p => p))]
             var rank = []
             baseSet.forEach((time) => {
                  var amount = overAllTimes.filter(p => p == time).length
                  rank.push({time: time, amount: amount})
             })
             rank = _.orderBy(rank, ['amount'], ['desc'])
             if(rank && rank[0]){
               return rank[0].time
              } return this.siteconfig.payroll.shiftStartTime
            } return this.siteconfig.payroll.shiftStartTime
          } return this.siteconfig.payroll.shiftStartTime
        },
        checkForDisabled(){
          if(this.approvedUserTimecard && this.approvedUserTimecard.supervisorApprovedId){ //have to add a permissions based check as well
       //   console.log(this.approvedUserTimecard)
            return true
          }
          if(this.userSelected.id == this.userData.id){
            return false
          }
          if(this.userData.role != 'user'){
            return false
          }
          return false
        },
        checkForUserPermissions(){
               return true
        },
        timeCardOverviewDataPreApproved(){
          var days = this.datesFromCurrentView
          if(days){
            var obj = { 
                        timeCardHours: 0,
                        timeCardNeedsAttn: 0,
                        leaveMods: 0,
                        leaveModsNeedsAttn: 0,
                        addMods: 0,
                        addModsNeedsAttn: 0,
                        scheduledHours: 0,
                        total: 0,
                        totalNeedsAttn: 0
                        }
            var array = days.filter(p => p.workDays)
            for(var h in array){
              var workday = array[h].workDays
              for(var w in workday){
                if(workday[w] && workday[w].length){
                   obj.scheduledHours = obj.scheduledHours + Number.parseFloat(workday[w].length)
                }
              }
            }
            var mods = days.filter(p => p.payrollMods)
            for(var h in mods){
              var payrollMods = mods[h].payrollMods
              for(var w in payrollMods){
                
                if(payrollMods[w] && payrollMods[w].payrollModHours && payrollMods[w].payrollModType){
                   if(this.leaveModArray.indexOf(payrollMods[w].payrollModType) >= 0){
                    obj.leaveMods = obj.leaveMods + Number.parseFloat(payrollMods[w].payrollModHours)
                    if(payrollMods[w].payrollModApproved == null){
                      obj.leaveModsNeedsAttn = obj.leaveModsNeedsAttn + Number.parseFloat(payrollMods[w].payrollModHours)
                    }
                   }
                   if(this.addModArray.indexOf(payrollMods[w].payrollModType) >= 0){
                    obj.addMods = obj.addMods + Number.parseFloat(payrollMods[w].payrollModHours)
                    if(payrollMods[w].payrollModApproved == null){
                      obj.addModsNeedsAttn = obj.addModsNeedsAttn + Number.parseFloat(payrollMods[w].payrollModHours)
                    }
                   }
                   
                }
              }
            }
            var tc = this.timeCardHold
           // console.log(tc)
            for(var i in tc){
              if(tc[i].clockInTime && tc[i].clockOutTime){
                var start = moment(tc[i].clockInDate + " " + tc[i].clockInTime, "YYYY-MM-DD HH:mm:ss")
                var end = moment(tc[i].clockOutDate + " " + tc[i].clockOutTime, "YYYY-MM-DD HH:mm:ss")
                var diff = end.diff(start, 'hours', true)
                obj.timeCardHours = diff + obj.timeCardHours
                if(!tc[i].timecardApproved){
                   obj.timeCardNeedsAttn = diff + obj.timeCardNeedsAttn
                }
              }
            }
            obj['total'] = obj.timeCardHours + obj.leaveMods + obj.addMods
            obj['totalNeedsAttn'] = obj.timeCardNeedsAttn + obj.leaveModsNeedsAttn + obj.addModsNeedsAttn
            obj['scheduledMissing'] = obj.scheduledHours - (obj.timeCardNeedsAttn + obj.leaveModsNeedsAttn +  obj.timeCardHours + obj.leaveMods)
            return obj
          } return null
        },
        timeCardOverviewData(){
          var days = this.datesFromCurrentView
          if(days){
            var obj = { scheduledHours: 0,
                        timeCardHours: 0,
                        timeCardApprovedHours: 0,
                        payrollModHours: 0
                        }
            var array = days.filter(p => p.workDays)
            for(var h in array){
              var workday = array[h].workDays
              for(var w in workday){
                if(workday[w] && workday[w].length){
                   obj.scheduledHours = obj.scheduledHours + Number.parseFloat(workday[w].length)
                }
              }
            }
            var mods = days.filter(p => p.payrollMods)
            for(var h in mods){
              var payrollMods = mods[h].payrollMods
              for(var w in payrollMods){
                if(payrollMods[w] && payrollMods[w].payrollModHours){
                   obj.payrollModHours = obj.payrollModHours + Number.parseFloat(payrollMods[w].payrollModHours)
                }
              }
            }
            var tc = this.timeCardHold
            for(var i in tc){
              if(tc[i].clockInTime && tc[i].clockOutTime){
                var start = moment(tc[i].clockInDate + " " + tc[i].clockInTime, "YYYY-MM-DD HH:mm:ss")
                var end = moment(tc[i].clockOutDate + " " + tc[i].clockOutTime, "YYYY-MM-DD HH:mm:ss")
                var diff = end.diff(start, 'hours', true)
                obj.timeCardHours = diff + obj.timeCardHours
                if(tc[i].timecardApproved){
                   obj.timeCardApprovedHours = diff + obj.timeCardApprovedHours
                }
              }
            }
            return obj
          } return null
        },
        datesFromCurrentView(){
          var additionalTimeCards = this.additionalTimeCards
          var currDate = moment(this.currentView.start).startOf('day');
          var lastDate = moment(this.currentView.end).startOf('day');
          var dates = [currDate.clone()]
          while(currDate.add(1, 'days').diff(lastDate) < 0) {
            // console.log(currDate.toDate());
              dates.push(currDate.clone().toDate());
          }
          dates.push(lastDate)
          dates = dates.map(item => {
              var obj = {}
              obj['date'] = item
              obj['workDays'] = this.checkForWorkDate(item)
              obj['payrollMods'] = this.checkForPayrollMods(item, obj.workDays)
              obj['timeCards'] = this.checkForUserTimeCards(item)
              return obj
          })
          if(this.siteconfig.payroll.autoTimeCards){
           dates = this.buildUserTimeCards(dates, additionalTimeCards)
          }
          this.buildOutUserTimeCardApprovalObj(dates)
          this.buildOutShowHideObject(dates)
        //  console.log("DATES CONSOLE")
        // console.log(dates)
          return dates;
        },
        previousPayrolls(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
              if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*2, 'weeks').format("YYYY-MM-DD")
                   var startSub = payroll.endAtMidnight ? 13 : 14
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(startSub, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(14, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(14, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            }
          } return []
        },
        previousWorkWeeks(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
          } return []
        },
        previousKellyCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'd').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
        previousWorkCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.cycle)
                   var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle - 1, 'days').format("YYYY-MM-DD")
                   if(payroll.workCycleMainExport){
                     endDate = moment(endDate, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD")
                   }
                   array.push({start: moment(startDate).add(payroll.cycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.cycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
       checkForUnapprovedPayrollMods(){
         var data = _.cloneDeep(this.datesFromCurrentView)
         for(var d in data){
           if(data[d].payrollMods && data[d].payrollMods.length > 0){
             var mods = data[d].payrollMods
              for(var m in mods){
               if(mods[m].payrollModApproved == null){
                 return false
               }
             }
           }
         }
         return true
       },
        yearsDropDown(){
           var years = []
           var payroll = this.siteconfig.payroll
           var today = moment().format('YYYY')
           var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD").format('YYYY')
           var diff = moment(today).diff(presetVarDate, 'years')
           for(var i = 0; i < diff; i++){
              if(Number.parseInt(presetVarDate) + i < Number.parseInt(today)){
                years.push(Number.parseInt(presetVarDate) + i)
              }
           }
           years.push(Number.parseInt(today))
           var hold = []
           for(var i in years){
             hold.push({value: years[i].toString(), text: years[i]})
           }
           if(!this.holdState){
           this.currentYear = Number.parseInt(today)
           }
           return _.orderBy(hold, ['text'], ['desc'])
        },
        filterProfileModOptions(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriod)
          var hold = array.map(item => item.shifts)
          var merged = [].concat.apply([], hold);
          var options = [...new Set(merged.map(p => p.payrollModCode))]
          return options
        },
       
  },
  mounted(){

  },
  watch: {
    currentView(newData){
        // console.log(newData)
         this.buildScheduleView(this.rosterUserProfiles, newData)
         this.checkForDepartmentCoding(this.rosterUserProfiles)
         if(this.siteconfig.payroll.approvalCycle && this.cycleType == this.siteconfig.payroll.approvalCycle){
           this.getSubmittedTimeCards()
         }
    }

  },
  methods: {
      executePrintJS(){
   //console.log('EXECUTEPRINTJS')
     //     console.log(this.printTabId)
      var printId = '#' + this.printTabId
    this.printReportOn = true
      this.$nextTick(function(){
      html2canvas(document.querySelector(printId)).then(canvas => {
       var image = canvas.toDataURL("image/png")
  //     console.log(image)
       return printJS({
              printable: image,
              type: 'image',
              documentTitle: 'Timecard Export Print Report',
              style: 'img { width:1200px !important; }',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
            })
   // 
    });
    this.$nextTick(function(){
        this.printReportOn = false
    })
      })

  },
  getTimeCardTableFormat(data){
      var forData = _.clone(data)
      forData = forData.filter(p => (p.payrollMods && p.payrollMods.length > 0) || (p.timeCards && p.timeCards.length > 0))
      var array = []
      for(var d in forData){
          if(forData[d].payrollMods && forData[d].payrollMods.length > 0){
            var mods = forData[d].payrollMods
            for(var m in mods){
              var payrollMod = this.payrollCodesData.find(p => p.id == mods[m].payrollModCode)
              var obj = {
                Day: moment(mods[m].payrollModDatetime).format('ddd'),
                Date: moment(mods[m].payrollModDatetime).format('MM/DD/YYYY'),
                Start:  moment(mods[m].payrollModDatetime).format('HH:mm:ss'),
                Dept: '',
                Type: mods[m].payrollModType,
                Export:  payrollMod && payrollMod.payrollCodeExport ? payrollMod.payrollCodeExport : '',
                "Total Time":  mods[m].payrollModHours,
                Note: mods[m].payrollModNote ? mods[m].payrollModNote : ''
              }
              array.push(obj)
            }
          }
          if(forData[d].timeCards && forData[d].timeCards.length > 0){
            var timecards = forData[d].timeCards
            for(var t in timecards){
            //  console.log(timecards[t])
              var payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'Regular')
              var obj = {
                Day: moment(timecards[t].clockInTime).format('ddd'),
                Date: moment(timecards[t].clockInTime).format('MM/DD/YYYY'),
                Start:  moment(timecards[t].clockInTime).format('HH:mm:ss'),
                Type: 'Regular',
                Dept: timecards[t].timecardDepartment ? timecards[t].timecardDepartment : '',
                Export:  payrollMod && payrollMod.payrollCodeExport ? payrollMod.payrollCodeExport : '',
                "Total Time":   moment(timecards[t].clockOutTime).diff(timecards[t].clockInTime, 'hours', true),
                Note: timecards[t].timecardComment ? timecards[t].timecardComment : ''
              }
              if(obj['Total Time'] > 0){
                  array.push(obj)
              }
              
            }
          }
      }
     // console.log(array)
      return array
    },
    checkForTimeCardNotes(timeCards){
      var notes = timeCards.filter(p => p.timecardComment && p.timecardComent != '')
      if(notes && notes.length > 0){
        var string = ''
        for(var n in notes){
          var index = timeCards.map(p => p.id).indexOf(notes[n].id) + 1
          var end = index == notes.length ? '' : ' | '
          string = string + "Timecard " + index + ': ' + notes[n].timecardComment + end
        } return string
      } return false
    },
    setHoursTime(time){
    //  console.log(time)
      var hours = moment(time.clockOutTime).diff(time.clockInTime, 'hours', true)
      if(hours && hours >= 0){
        this.tempTimeCardHours = hours
      } else {
        this.tempTimeCardHours = 0
      }
    },
    updateHoursTime(time){
      var time = this.timeCardHold[time.id]
      var date = moment(time.clockInDate + 'T' + time.clockInTime, 'YYYY-MM-DDTHH:mm').toISOString()
      date = moment(date).add(this.tempTimeCardHours, 'hours')
      this.timeCardHold[time.id].clockOutTime = moment(date).format('HH:mm')
      this.timeCardHold[time.id].clockOutDate = moment(date).format('YYYY-MM-DD')
      this.showHoursId = ''
    },
    roundNumber(num){
      if(num == 0){
        return 0
      }
      if(num){
        return (Math.round(num * 100) / 100).toFixed(2)
      } return ''
    },
    checkIfEqual(t){
      var test = _.cloneDeep(this.timeCardHold[t.id])
      test['clockInTime'] =  moment(test.clockInDate + 'T' + test.clockInTime, 'YYYY-MM-DDTHH:mm').toISOString()
      test['clockOutTime'] =  moment(test.clockOutDate + 'T' + test.clockOutTime, 'YYYY-MM-DDTHH:mm').toISOString()
      delete test.clockInDate
      delete test.clockOutDate
      return _.isEqual(t, test)
    },
    checkForLocked(cycleType, value){
      
      if(cycleType == 'Pay Period'){
        var cycleSelected = this.previousPayrolls.find(p => _.isEqual(p.value, value))
        if(cycleSelected && cycleSelected.text){
          var reportFind = this.payrollReports.find(p => p.title == cycleSelected.text)
          if(reportFind){
            this.lockedReport = reportFind
          //  console.log(reportFind)
            return true
          } 
          this.lockedReport = null
          return false
        } 
        this.lockedReport = null
        return false
      } this.lockedReport = null
        return true
    },
    addNewTimeCard(date){
          this.additionalTimeCards = []
          if(this.typicalStartTime){
              var dateHold = moment(moment(date).format('YYYY-MM-DD') + ' ' + this.typicalStartTime, 'YYYY-MM-DD HH:mm').toISOString()
          } else {
              var dateHold = date
          }
          var obj = {}
          obj['clockInTime'] = dateHold
          obj['clockOutTime'] = dateHold
          obj['id'] = 'AUTOBUILD_' + dateHold + '_' + this.userData.id
          obj['timecardApproved'] = true
          obj['additional'] = true
          this.additionalTimeCards.push(obj)
        //  console.log(date)
        //  console.log(this.timeCardShowHideHold)
          this.$nextTick(function(){
            this.timeCardShowHideHold[date].showAll = false
       //    console.log(this.timeCardShowHideHold)
          })
    },
    getCycleTypeOptions(){
        if(this.siteconfig.payroll.hidePayrollCycleOption){
        var array = ['Work Cycle', 'Work Week']
        } else if(this.siteconfig.payroll.hideWorkCycle){
          var array = ['Work Week', 'Pay Period']
          this.cycleType = 'Pay Period'
          if(!this.holdState){
            this.getCurrentPayPeriod()
          }
        } else {
        var array = ['Pay Period', 'Work Cycle', 'Work Week']
        }
        if(this.siteconfig.payroll.kellyCycleDate){
          array.push('Kelly Cycle')
        }
        array.push('Select Range')
        this.cycleTypeOptions = array
    },
    checkForDepartmentCoding(data){
      var boo = data.filter(p => p.rosterSpotDepartmentCoding)
      if(boo && boo.length > 0){
        this.departmentCodingRequired = true
        this.departmentCodeDefault = this.userSelected ? this.userSelected.stationAssignment : this.userData.stationAssignment
      } else {
        this.departmentCodingRequired = false
      }
    },
    checkForIdIncludedMultiple(array, field){
      var boo = false
      for(var i in array){
        var check = this.checkForIdIncluded(array[i].id, field)
     //   console.log(check)
        if(check){
          boo = check
        }
      }
   //   console.log(boo)
      return boo
    },
    checkForIdIncluded(id, field){
     // console.log(field)
      var data = this.approvedUserTimecard
      if(id && data[field] && data[field].length > 0){
        var index = data[field].map(p => p.id).indexOf(id)
      //  console.log(index)
        if(index < 0){
          return true
        } return false
      } else if(!id){
        return false
      } return true
    },
    getUserData(id){
      var obj = this.deptContactData.find(p => p.id == id)
      if(obj){
        return obj.firstName + ' ' + obj.lastName + '(' + obj.employeeId + ')'
      } return "Missing User"
    },
    disapproveTimecard(timecard){
      var data = {
        timecardDenied: true,
        timecardDeniedId: this.userData.id
      }
      axios({
        method: 'patch',
        url: '/api/v1/staffing/payrolltimecardapproval/' + timecard.id,
        data: data,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {
         this.approveTimeCardView = false
         this.denyCheck = false
         this.getSubmittedTimeCards();
      })
    },
    approveTimeCard(dates, supervisor, timecard){
     // console.log(dates)
      // need to pull in previous timeCardData as well
    //  console.log('TIMECARD')
    //  console.log(timecard)
    if(supervisor) {
        var data = {}
        if(timecard){
          data = _.cloneDeep(timecard)
          if(timecard.timecardApprovalHistory){
            data['timecardApprovalHistory'].push(_.cloneDeep(timecard))
          } else {
            data['timecardApprovalHistory'] = [_.cloneDeep(timecard)]
          }
        }
        data['selected_workcycle'] = this.currentView.start + 'till' + this.currentView.end
        data['timecardUserId'] = this.userSelected ? this.userSelected.id : this.userData.id    
        var timeCards = []
        var payrollMods = []
        var workDays = []
        for(var d in dates){
          if(dates[d].timeCards && dates[d].timeCards.length > 0){
            var approvedTC = dates[d].timeCards.filter(p => {
                    var time = moment(p.clockOutTime).diff(p.clockInTime)
                    //console.log(hours)
                    if(p.timecardApproved && time > 0){
                      return p
                    }

            })
            timeCards = timeCards.concat(approvedTC)
          }
          if(dates[d].payrollMods && dates[d].payrollMods.length > 0){
            var approvedPayrollMods = dates[d].payrollMods.filter(p => p.payrollModApproved)
            payrollMods = payrollMods.concat(approvedPayrollMods)
          }
          if(dates[d].workDays && dates[d].workDays.length > 0){
            workDays = workDays.concat(dates[d].workDays)
          }
        }
        data['approvedTimecards'] = timeCards
        data['approvedPayrollmods'] = payrollMods
        data['scheduledWorkDays'] = workDays
        data['supervisorApprovedId'] = this.userData.id
        data['supervisorApprovedDate'] = moment().toISOString()
      } else if(!supervisor && !timecard) {
        var data = {}
        data['selected_workcycle'] = this.currentView.start + 'till' + this.currentView.end
        data['timecardUserId'] = this.userSelected ? this.userSelected.id : this.userData.id    
        var timeCards = []
        var payrollMods = []
        var workDays = []
        for(var d in dates){
          if(dates[d].timeCards && dates[d].timeCards.length > 0){
            var approvedTC = dates[d].timeCards.filter(p => {
                    var time = moment(p.clockOutTime).diff(p.clockInTime)
                    //console.log(hours)
                    if(p.timecardApproved && time > 0){
                      return p
                    }

            })
            timeCards = timeCards.concat(approvedTC)
          }
          if(dates[d].payrollMods && dates[d].payrollMods.length > 0){
            var approvedPayrollMods = dates[d].payrollMods.filter(p => p.payrollModApproved != false)
            payrollMods = payrollMods.concat(approvedPayrollMods)
          }
          if(dates[d].workDays && dates[d].workDays.length > 0){
            workDays = workDays.concat(dates[d].workDays)
          }
        }
        data['approvedTimecards'] = timeCards
        data['approvedPayrollmods'] = payrollMods
        data['scheduledWorkDays'] = workDays
        data['userApprovedId'] = this.userData.id
        data['userApprovedDate'] = moment().toISOString()
      } else {
        var data = timecard
        data['userApprovedId'] = this.userData.id
        data['userApprovedDate'] = moment().toISOString()
      }
      if(this.userPerms && this.userPerms.payrollTimecardSelfApprove){
        data['supervisorApprovedId'] = this.userData.id
        data['supervisorApprovedDate'] = moment().toISOString()
      }
      delete data.updatedAt
      delete data.createdAt
      data['timecard_approval_submitted_user_id'] = this.userData.id
      
      this.submitTimecardApproval(data)

    },
    approveTimeCardBack(dates, supervisor, timecard){
     // console.log(dates)
      // need to pull in previous timeCardData as well
    //  console.log('TIMECARD')
    //  console.log(timecard)
      if(timecard){
        var data = timecard
      } else {
        var data = {}
        data['selected_workcycle'] = this.currentView.start + 'till' + this.currentView.end
        data['timecardUserId'] = this.userSelected ? this.userSelected.id : this.userData.id    
        var timeCards = []
        var payrollMods = []
        var workDays = []
        for(var d in dates){
          if(dates[d].timeCards && dates[d].timeCards.length > 0){
            var approvedTC = dates[d].timeCards.filter(p => {
                    var time = moment(p.clockOutTime).diff(p.clockInTime)
                    //console.log(hours)
                    if(p.timecardApproved && time > 0){
                      return p
                    }

            })
            timeCards = timeCards.concat(approvedTC)
          }
          if(dates[d].payrollMods && dates[d].payrollMods.length > 0){
            var approvedPayrollMods = dates[d].payrollMods.filter(p => p.payrollModApproved)
            payrollMods = payrollMods.concat(approvedPayrollMods)
          }
          if(dates[d].workDays && dates[d].workDays.length > 0){
            workDays = workDays.concat(dates[d].workDays)
          }
        }
        data['approvedTimecards'] = timeCards
        data['approvedPayrollmods'] = payrollMods
        data['scheduledWorkDays'] = workDays
      }
      delete data.updatedAt
      delete data.createdAt
      if(!supervisor){
      data['userApprovedId'] = this.userData.id
      data['userApprovedDate'] = moment().toISOString()
      }
      if(supervisor){
      data['supervisorApprovedId'] = this.userData.id
      data['supervisorApprovedDate'] = moment().toISOString()
      }
      this.submitTimecardApproval(data)

    },
    submitTimecardApproval(data){
      var url = '/api/v1/staffing/payrolltimecardapproval/'
      if(data.id){
        var method = 'patch'
        url = url + data.id
      } else {
        var method = 'post'
      }
      axios({
        method: method,
        url: url,
        data: data,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {
         this.approveTimeCardView = false
         this.getSubmittedTimeCards();
      })
    },
    getSubmittedTimeCards(){
      var userId =  this.userSelected ? this.userSelected.id : this.userData.id
      this.approvedUserTimecard = null
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrolltimecardapproval/userhistory/' +  this.currentView.start + 'till' + this.currentView.end + '/' + userId   ,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {
        var array = _.orderBy(response.data, 'updatedAt', ['desc'])
        array = array.filter(p => !p.timecardDenied)
        if(array && array[0]){
        this.approvedUserTimecard = array[0]
        }
      })
    },
    openTimeCard(data){
      bus.$emit('openTimeCard', data)
    },
    updateScheduleView(){
        this.buildScheduleView( this.rosterUserProfiles, this.currentView)
    },
    timeCardHeadersData(data, type){
            var tc = data
            var obj = { 
                        timeCardHours: 0,
                        timeCardApprovedHours: 0,
                        }
            for(var i in tc){
              if(tc[i].clockInTime && tc[i].clockOutTime){
                var start = moment(tc[i].clockInDate + " " + tc[i].clockInTime, "YYYY-MM-DD HH:mm:ss")
                var end = moment(tc[i].clockOutDate + " " + tc[i].clockOutTime, "YYYY-MM-DD HH:mm:ss")
                var diff = end.diff(start, 'hours', true)
                obj.timeCardHours = diff + obj.timeCardHours
                if(tc[i].timecardApproved){
                   obj.timeCardApprovedHours = diff + obj.timeCardApprovedHours
                }
              }
            }
        if(type == 'approved'){
          return obj.timeCardApprovedHours
        } return obj.timeCardHours
      },
      getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    getApprovalStatus(data){
        return data == true ? 'Approved' : data == false ? 'Denied' : data == null ? 'Needs Attn' : ''
    },
    getApprovalStatusColor(data){
       return data == true ? 'success' : data == false ? 'danger' : data == null ? 'warning' : ''
    },
    setTimeCardCommentModal(id){
      var prevComment = this.timeCardHold[id].timecardComment
     // console.log(prevComment)
      if(prevComment){
      this.timeCardCommentModalValue = prevComment
      }
      this.timeCardCommentModalId = id
    },
    updateTimeCardComment(boo){
      if(!boo){
      var id = this.timeCardCommentModalId
      var comment = this.timeCardCommentModalValue
      this.timeCardHold[id].timecardComment = comment
      }
      this.$nextTick(function(){
        this.timeCardCommentModalValue = ''
        this.timeCardCommentModalId = ''
        this.timeCardCommentModal = false
      })
    },
    getDateTimeFormat(date, type){
      if(date && type){
        if(type == 'date'){
          return moment(date).format('MM/DD/YY')
        } return moment(date).format('hh:mm a')
      } return 'Missing Information'
    },
    buildOutShowHideObject(days){
      var TCobj = {}
      for(var d in days){
        if(days[d].timeCards){
            var timeCards = days[d].timeCards
            var approvedAll = true
            var showAll = true
            for(var t in timeCards){
              var modifiedObj = _.cloneDeep(timeCards[t])
              if(!modifiedObj.timecardApproved){
                approvedAll = false
                showAll = false
              }
            }
          TCobj[days[d].date.toISOString()] = { approvedAll: approvedAll, showAll: showAll }
        }
      }
      this.timeCardShowHideHold = TCobj
    },
    buildOutUserTimeCardApprovalObj(days){
      /* need to create and object to get imputs for
        {
          id_clock_in_hold
          id_clock_out_hold
        }
      */
      var TCobj = {}
      for(var d in days){
        if(days[d].timeCards){
            var timeCards = days[d].timeCards
            for(var t in timeCards){
              var modifiedObj = _.cloneDeep(timeCards[t])
              modifiedObj.clockInTime =  timeCards[t].clockInTime ?  moment(timeCards[t].clockInTime).format('HH:mm') : null
              modifiedObj['clockInDate'] = timeCards[t].clockInTime ?  moment(timeCards[t].clockInTime).format('YYYY-MM-DD') : null
              modifiedObj.clockOutTime = timeCards[t].clockOutTime ? moment(timeCards[t].clockOutTime).format('HH:mm') : null
              modifiedObj['clockOutDate'] = timeCards[t].clockOutTime ?  moment(timeCards[t].clockOutTime).format('YYYY-MM-DD') : null
              TCobj[timeCards[t].id] = modifiedObj
            }
        }
      }
      this.timeCardHold = TCobj
      
    },
    getTimeFormated(time){
      if(time){
       if(this.siteconfig.payroll.standardTimes){
          return moment(time, 'HH:mm').format('hh:mm a')
       } return time
      } return ''

    },
    getWorkDayEndTime(w){
      if(w && w.startTime && w.scheduledLength){
        if(this.siteconfig.payroll.standardTimes){
          return moment(w.startTime, 'HH:mm').add(w.scheduledLength, 'hours').format('hh:mm a')
        } return moment(w.startTime, 'HH:mm').add(w.scheduledLength, 'hours').format('HH:mm')
      }
    },
    getDateFormat(day, boo){
      if(day && !boo){
        return moment(day).format('ddd MMM DD')
      } else if (day && boo){ 
        return moment(day).format('MMM DD, YY HH:mm')
      }return 'None'
    },
    checkForPayrollMods(day, workDays){
     //  console.log(this.userPayrollMods)
      var work = []
      if(day){
      work = this.userPayrollMods.filter(p => {
          if(p.payrollModDatetime){
          var dateEnd = moment(moment(day).format('YYYY-MM-DD') + ' ' + this.siteconfig.payroll.shiftStartTime, 'YYYY-MM-DD HH:mm:ss').add(1, 'day').toISOString()
          var dateStart = moment(dateEnd).subtract(1, 'day').toISOString()
          var date = p.payrollModDatetime
         // console.log(day, dateStart, date, dateEnd)
          var test = moment(date).isBetween(dateStart, dateEnd, undefined, '[)')
          if(test){
           // console.log(dateStart, date, dateEnd)
            return test
          }
          }

      })
          if(work && work.length > 0){
          //  console.log(dateStart, date, dateEnd)
         //   console.log(work)
          }
      if(day && this.siteconfig.payroll.holidayTable && this.payrollHolidaysData && workDays.length > 0){
        var holiday = this.payrollHolidaysData.find(p => {
          var userRank = this.userSelected ? this.userSelected.deptRank : this.userData.deptRank
          return moment(p.holidayDate).isSame(day, 'day') && p.holidayPaytype.indexOf(userRank) >= 0
        })
        var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Holiday')
        if(holiday && workDays[0] && payrollCode){
        var workDate = workDays[0]
        var startTime = moment(moment(day).format('YYYY-MM-DD') + ' ' + this.typicalStartTime, 'YYYY-MM-DD HH:mm:ss')
        var endTime = moment(moment(day).format('YYYY-MM-DD') + ' ' + this.typicalStartTime, 'YYYY-MM-DD HH:mm:ss').add(holiday.holidayHours, 'hours').toISOString()
        var newObj = {
                    payrollModType: payrollCode.payrollCodeCategory,
                    payrollModCode: payrollCode.id,
                    payrollModTargetUserId: this.userData.id,
                    payrollModDatetime: startTime,
                    payrollModHours: holiday.holidayHours,
                    payrollModAuthorId: "system",
                    payrollModApprovalUserId: "system",
                    payrollModApproved: true,
                    payrollModNote: holiday.holidayName,
                    startTime: startTime,
                    endTime: endTime,
                    systemCreated: true
        }
        work.push(newObj)
        }

      }
      if(work && work.length > 0){
           //    console.log('Mods')
           //    console.log(work)
          return _.orderBy(work, ['payrollModDatetime'], ['asc'])
      } return false
      } return false
    },
    buildUserTimeCards(dates){
    // console.log('Build User TimeCards')
    //  HOLIDAY DATA
    // console.log(dates)
    //  console.log(dates)
      var returnArray = []
      for(var d in dates){
        var obj = dates[d]
        if(obj.workDays && !obj.timeCards){
         if(obj.payrollMods){
           obj.timeCards = this.getTimeCardsFromWorkdayWithMods(obj.workDays, obj.payrollMods)
          var additionalDates = this.additionalTimeCards.find(p => moment(obj.date._i).isSame(p.clockInTime, 'day') || moment(obj.date).isSame(p.clockInTime, 'day'))
          if(additionalDates && obj.timeCards.map(p => p.id).indexOf(additionalDates.id) == -1){
           obj.timeCards.push(additionalDates)
          } 
         } else {
            obj.timeCards = this.getTimeCardsFromWorkday(obj.workDays)
          var additionalDates = this.additionalTimeCards.find(p => moment(obj.date._i).isSame(p.clockInTime, 'day') || moment(obj.date).isSame(p.clockInTime, 'day'))
            if(additionalDates && obj.timeCards.map(p => p.id).indexOf(additionalDates.id) == -1){
           obj.timeCards.push(additionalDates)
        }
         }
        }
          var additionalDates = this.additionalTimeCards.find(p => moment(obj.date._i).isSame(p.clockInTime, 'day') || moment(obj.date).isSame(p.clockInTime, 'day'))
             if(additionalDates && obj.timeCards && obj.timeCards.map(p => p.id).indexOf(additionalDates.id) == -1){
           obj.timeCards.push(additionalDates)
          } else if (additionalDates){
            obj.timeCards = [additionalDates]
          }
          obj.timeCards = _.orderBy(obj.timeCards, ['clockInTime'], ['asc'])
          returnArray.push(obj)
      }
     // console.log(returnArray)
      return returnArray
    },
    getTimeCardsFromWorkdayWithMods(array, mods){
     // console.log('WORK DAYS AND MODS')
     // console.log(array)
     // console.log(mods)
      if(mods && mods.filter(p => (p.payrollModType == 'overtime' || p.payrollModType == 'payaddative')).length > 0 && mods.filter(p => p.payrollModApprovalUserId == 'system') == 0 && mods.filter(p => p.payrollModType == 'scheduled leave') == 0){
       // console.log(mods)
        return this.getTimeCardsFromWorkday(array)
      } else {
        return []
      }
      // changes to below
      array = array.map(item => { 
            var obj = item
            obj['clockInTime'] = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').toISOString()
            obj['clockOutTime'] = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').add(item.scheduledLength, 'hours').toISOString()
            obj['id'] = 'AUTOBUILD_' + obj.clockInTime + '_' + obj.clockOutTime
            obj['timecardApproved'] = true
            return obj
      })
      mods = mods.map(item => {
            var obj = item
            obj['startTime'] = item.payrollModDatetime
            obj['endTime'] = moment(item.payrollModDatetime).add(item.payrollModHours, 'hours').toISOString()
            return obj
      })
      var FixedSwapArray = this.getFixedSwap(array, mods)
      console.log(FixedSwapArray)
      return FixedSwapArray
      array = FixedSwapArray
      var returnArray = []
      for(var i in array){
          var item = array[i]
          var obj = item
          if(item.lunch && false){
          var obj2 = _.cloneDeep(item)
          var workLength = (item.scheduledLength - item.lunch) / 2
          var startTimeOne = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').toISOString()
          var endTimeOne = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').add(workLength, 'hours').toISOString()
          var startTimeTwo = moment(endTimeOne).clone().add(item.lunch, 'hours').toISOString()
          var endTimeTwo = moment(startTimeTwo).clone().add(workLength, 'hours').toISOString()
          obj['clockInTime'] = startTimeOne
          obj['clockOutTime'] = endTimeOne
          obj['id'] = 'AUTOBUILD_' + startTimeOne + '_' + endTimeOne
          obj['autoBuild'] = true
          obj['timecardApproved'] = true
          obj2['clockInTime'] = startTimeTwo
          obj2['clockOutTime'] = endTimeTwo
          obj2['id'] = 'AUTOBUILD_' + startTimeTwo + '_' + endTimeTwo
          obj2['timecardApproved'] = true
          obj2['autoBuild'] = true
          returnArray.push(obj)
          returnArray.push(obj2)

          } else {
          obj['clockInTime'] = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').toISOString()
          obj['clockOutTime'] = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').add(item.length, 'hours').toISOString()
          obj['id'] = 'AUTOBUILD_' + obj.clockInTime + '_' + obj.clockOutTime
          obj['timecardApproved'] = true
          returnArray.push(obj)
          }
         // obj['timecardDepartment'] = this.departmentCodeDefault ? this.departmentCodeDefault : null
      }
      return returnArray
    },
    getTimeCardsFromWorkday(array){
    //  console.log('WORK DAYS NO MODS')
    //  console.log(array)
      var returnArray = []
      for(var i in array){
          var item = array[i]
          var obj = item
          if(item.lunch){
          var obj2 = _.cloneDeep(item)
          var workLength = (item.scheduledLength - item.lunch) / 2
          var startTimeOne = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').toISOString()
          var endTimeOne = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').add(workLength, 'hours').toISOString()
          var startTimeTwo = moment(endTimeOne).clone().add(item.lunch, 'hours').toISOString()
          var endTimeTwo = moment(startTimeTwo).clone().add(workLength, 'hours').toISOString()
          obj['clockInTime'] = startTimeOne
          obj['clockOutTime'] = endTimeOne
          obj['id'] = 'AUTOBUILD_' + startTimeOne + '_' + endTimeOne
          obj['autoBuild'] = true
          obj['timecardApproved'] = true
          obj2['clockInTime'] = startTimeTwo
          obj2['clockOutTime'] = endTimeTwo
          obj2['id'] = 'AUTOBUILD_' + startTimeTwo + '_' + endTimeTwo
          obj2['timecardApproved'] = true
          obj2['autoBuild'] = true
          returnArray.push(obj)
          returnArray.push(obj2)

          } else {
          obj['clockInTime'] = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').toISOString()
          obj['clockOutTime'] = moment(item.date + 'T' + item.startTime, 'YYYY-MM-DDTHH:mm').add(item.length, 'hours').toISOString()
          obj['id'] = 'AUTOBUILD_' + obj.clockInTime + '_' + obj.clockOutTime
          obj['timecardApproved'] = true
          returnArray.push(obj)
          }
         // obj['timecardDepartment'] = this.departmentCodeDefault ? this.departmentCodeDefault : null
      }
      return returnArray
    },
    getFixedSwap(array, mods){
      if(false){
      console.log('Get Fixed Swap')
      console.log(array)
      console.log(mods)
      }
      var lunchOnce = true
       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
         //  console.log(lunchOnce)
          var mod = mods.filter(p => moment(p.startTime).isSameOrAfter(array[a].clockInTime) && moment(p.endTime).isSameOrBefore(array[a].clockOutTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].startTime).isSame(array[a].clockInTime) && moment(mod[m].endTime).isSame(array[a].clockOutTime)){
                  array.splice(a, 1)
                  return(true);
                }
                if(array[a].lunch && moment(mod[m].startTime).isSame(array[a].clockInTime) && moment(mod[m].endTime).isSame(moment(array[a].clockOutTime).subtract(array[a].lunch, 'hours') && lunchOnce)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].startTime).isAfter(array[a].clockInTime) && moment(mod[m].endTime).isSame(array[a].clockOutTime)){
                  var obj = _.cloneDeep(array[a])
                  if(array[a].lunch && lunchOnce){
                    var holdTime = moment(mod[m].startTime).subtract(array[a].lunch, 'hours').toISOString()
                    lunchOnce = false
                  } else {
                    var holdTime  =  mod[m].startTime
                  }
                  obj.clockInTime = array[a].clockInTime
                  obj.clockOutTime = holdTime
                  obj.id = 'AUTOBUILD_MOD_' + array[a].clockInTime + "_" + mod[m].startTime
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].startTime).isSame(array[a].clockInTime) && moment(mod[m].endTime).isBefore(array[a].clockOutTime)){
                  var obj = _.cloneDeep(array[a])
                  if(array[a].lunch && lunchOnce){
                    var holdTime = moment(mod[m].endTime).add(array[a].lunch, 'hours').toISOString()
                    lunchOnce = false
                  } else {
                    var holdTime  =  mod[m].endTime
                  }
                  obj.clockInTime =  holdTime
                  obj.clockOutTime = array[a].clockOutTime
                  obj.id = 'AUTOBUILD_MOD_' + mod[m].endTime + "_" + array[a].clockOutTime
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].startTime).isAfter(array[a].clockInTime) && moment(mod[m].endTime).isBefore(array[a].clockOutTime)){
                //  console.log('FIRST LAST CATCH')
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.clockInTime = array[a].clockInTime
                  obj.clockOutTime = mod[m].startTime
                  obj.id = 'AUTOBUILD_MOD_' + array[a].clockInTime + "_" + mod[m].startTime
                  objTwo.clockInTime = mod[m].endTime
                  objTwo.clockOutTime = array[a].clockOutTime
                  objTwo.id = 'AUTOBUILD_MOD_' + mod[m].endTime + "_" + array[a].clockOutTime
                  array.push(objTwo)
                  array[a] = obj
                }
                if(moment(mod[m].startTime).isSame(array[a].clockInTime) && moment(mod[m].endTime).isAfter(array[a].clockOutTime)){
                  array.splice(a, 1)
                  return(true);
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
    checkForUserTimeCards(day){
      if(day){
      var work = this.userTimeCards.filter(p => {
          if(p.clockInTime){
          return moment(p.clockInTime).isSame(day, 'day')
          }
      })
      if(work && work.length > 0){
       // console.log('TIME CARDS')
          return work
      } return false
      } return false
    },
    checkForWorkDate(day){
      if(day){
      var work = this.userShifts.filter(p => {
          if(p.date){
          return moment(p.date).isSame(day, 'day')
          }
      })
      if(work && work.length > 0){
          return work
      } return false
      } return false
    },
    enumerateDaysBetweenDates(startDate, endDate){
    var now = _.cloneDeep(startDate)
    var end = endDate
    var dates = []
    while (moment(now).isBefore(end)) {
        dates.push(now);
        now = moment(now, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    }
    return dates;
    },
    processDaysWithPattern(patterns, dates, logpat, profile){
      var processed = []
      var pattern = _.cloneDeep(patterns)
      for(var i = 0; i < pattern.length; i++){
        var pat = pattern[i]
        if(pat.startTime != null){
          var length = profile.rosterSpotLunchLength ? pat.length - profile.rosterSpotLunchLength : pat.length
          processed.push({date: dates[i], startTime: pat.startTime, lunch: profile.rosterSpotLunchLength, auto: profile.rosterSpotAutoLunch, scheduledLength: pat.length, length: length, work: true, pat: logpat, departmentCoding: profile.rosterSpotDepartmentCoding})
        } else {
         // processed.push({date: dates[i], pat: logpat})
        }
      }
      return processed
    },
  addNewPayrollMod(data, d){
    var stateObj = {currentYear: this.currentYear, currentView: this.currentView, cycleType: this.cycleType}
    bus.$emit('holdState', stateObj)
    if(data && data[0]){
      var startDate = moment(data[0].date + ' ' + data[0].startTime, 'YYYY-MM-DD HH:mm').toISOString()
      var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: this.userSelected.id, 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: data[0].length, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '' }
                 bus.$emit('updatePayrollMod', dataObj)
      return
    }
    if(data == false && this.typicalStartTime && d && d.date){
      var startDate = moment(moment(d.date).format('YYYY-MM-DD') + ' ' + this.typicalStartTime, 'YYYY-MM-DD HH:mm').toISOString()
      var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: this.userSelected.id, 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: 8, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '' }
                 bus.$emit('updatePayrollMod', dataObj)
      return
    }
    if(data == false && d.date){
      var startDate = moment(d.date).add(8, 'hours').toISOString()
      var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: this.userSelected.id, 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: 8, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '' }
                 bus.$emit('updatePayrollMod', dataObj)
      return
    }  
  },
  buildScheduleView(schedulesUn, currentView){
   // console.log('SCHEDULE')
     var schedules = _.orderBy(schedulesUn, ['rosterAssignmentStart'], ['asc'])
   // console.log(schedulesUn)
   // console.log(schedules)
   // console.log(this.scheduleData)
      var holdDatesArray = []
      for(var s in schedules){
      var schObj = schedules[s]
      var schDataObj = this.scheduleData.find(p => p.id == schedules[s].scheduledShift)
     // console.log(schDataObj)
      var schedule = {
        patternType: schDataObj.patternType,
        schedulePattern: schDataObj.schedulePattern,
        rosterProfile: schedules[s]
      }
      var startDate = schObj.rosterAssignmentStart // comes from their profile
      var start = moment(startDate, 'YYYY-MM-DD')
      var end = moment(currentView.end).clone().endOf('month')
   //   console.log(schObj.rosterAssignmentEnd)
    //  console.log(schObj.rosterAssignmentEnd && moment(schObj.rosterAssignmentEnd).isBefore(end))
      if(schObj.rosterAssignmentEnd && moment(schObj.rosterAssignmentEnd).isBefore(end)){
        end =  moment(schObj.rosterAssignmentEnd).clone() // comes from their profile
      }
      var pattern = schedule.patternType
      var momentDiff = pattern == 'weekly' ? 'week' : 'day'
      var momentEnd = pattern == 'weekly' ? 7 : 1
      var startOfMonth =  moment(currentView.start).clone().startOf('month')
      var startOfMonthClone = _.cloneDeep(startOfMonth)
      var patIterations = startOfMonthClone.clone().diff(start.clone(), 'day')
      var patternArray = schedule.schedulePattern
      var dayTotal = 0
      var patHold = []
      for(var i in patternArray){
        var patTotal = 0
        var length = patternArray[i].interval.length
        var repeat = patternArray[i].repeat
        patTotal = length * repeat
        dayTotal = dayTotal + patTotal
      }
      if(patIterations > (dayTotal * 2)){
              var daysFromStart = 0
              var patIntCalc = _.cloneDeep(patIterations)
              while(Math.floor((patIntCalc/dayTotal)) != (patIntCalc/dayTotal)){
                  daysFromStart++;
                  patIntCalc = patIntCalc - 1
              }
       //       console.log(daysFromStart)
                  var newStart =  moment(currentView.start).clone().startOf('month').subtract(daysFromStart, 'day')
                  var interations = end.clone().diff(newStart, momentDiff, true)
                  var pattern = 0 // patternStart +1
                  var totalIterations = 0
                  var startHold = newStart //startOfMonth
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
              } else {
                  var interations = end.clone().diff(start, momentDiff, true)
                  var pattern = 0
                  var totalIterations = 0
                  var startHold = start
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
      }
      if(true){
        var patternBuild = []
        var vm = this
        function doTheLoop() {
        for(var i = 0; totalIterations < interations; i++){
            var currentP = patternArray[pattern]
            if(!currentP){
              pattern = 0
              currentP = patternArray[pattern]
            }
            var lastDate = startHold.clone().add(momentEnd, 'days')
               //  console.log("Schedule Before End Data")
               //  console.log(end)
            if(end && moment(end).isBefore(lastDate)){
              //   console.log("Scheduled End Date")
                 lastDate =  moment(end).add(1, 'day')
             }
            if (currentP.repeatType == "PatternInterations") {
                  if(currentP.repeat > repeatPat){
                    repeatPat = repeatPat + 1
                    var daysList = vm.enumerateDaysBetweenDates(startHold.format('YYYY-MM-DD'), lastDate.format('YYYY-MM-DD'))
                    var processDays = vm.processDaysWithPattern(currentP.interval, daysList, pattern, schedule.rosterProfile)
                    patternBuild = patternBuild.concat(processDays)
                    totalIterations = totalIterations + 1
                    startHold = lastDate.clone()
                    if(end && startHold.isSameOrAfter(moment(end))){
                    //  console.log('END MADNESS')
                    //  console.log(startHold)
                    //  console.log(moment(vm.scheduleEndDate))
                       return (false)
                       totalIterations = interations
                    }
                  }  else {
                 //    console.log('Repeat TRIGGERED')
                      repeatPat = 0
                      pattern = pattern + 1
                  }
            } else {
              pattern = pattern + 1
              totalIterations = totalIterations + 1
            }
            if(totalIterations < interations){
              return (true)
            } else {
              return (false)
            }

        }
      }
      while (doTheLoop()) {}
      // console.log(patternBuild)
  }
 //   console.log(patternBuild)
    var datesHold = patternBuild.filter(p => p.date)
   // console.log(datesHold)
    holdDatesArray = holdDatesArray.concat(datesHold)
   // console.log(holdDatesArray)
  } 
  var filterHoldDates = holdDatesArray.filter(p => {
      return moment(p.date).isSameOrAfter(currentView.start, 'day') && moment(p.date).isSameOrBefore(currentView.end, 'day')
  })
  this.userShifts = filterHoldDates
 // console.log(filterHoldDates)
  this.getUserTimeCards(this.userSelected)
  this.getUserMods(this.userSelected)
  this.getPreviousPayrollExports()
  },
    runRangeDate(){
      this.currentView = {start: this.selectStart, end: this.selectEnd }
    },
      getDateTime(data){
        if(data){
          return moment(data).format('MM/DD/YYYY HH:mm:ss')
        } return 'Null Date'
      },
      getCurrentPayPeriod(){
        if(this.cycleType == 'Pay Period'){
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14)
                   var endDate = moment(presetVarDate).add((end + 1)*2, 'weeks').format("YYYY-MM-DD") // ADDED 1 to move date range to current pay period
                   var startSub = payroll.endAtMidnight ? 13 : 14
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(startSub, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today, 'YYYY-MM-DD').diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else {
                    return false
                }
        } else if (this.cycleType == 'Work Week') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else if (this.cycleType == 'Kelly Cycle') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                var dateDiffCal = moment(today).diff(presetVarDate, "days")
                var end = Math.floor(dateDiffCal/payroll.cycle)
                var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle -1, 'days').format("YYYY-MM-DD")
                if(payroll.workCycleMainExport){
                  endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                }
                this.currentView = {start: startDate, end: endDate}
        //        console.log(this.currentView)
        }
                
      }, 
  updateTimeCardAuto(array){
    for(var i in array){
      var data = array[i]
      this.updateTimeCard(this.timeCardHold[data.id])
    }
    
  },
  deleteTimeCard(id){
    var result = confirm('Are You Sure You Want to Delete This TimeCard?')
    if(result){
    axios({
      method: 'delete',
      url: '/api/v1/staffing/payrolltimecard/' + id,
      headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
    }).then(response => {
    //  console.log(response.data)
       this.getUserTimeCards(this.userSelected)
    })

    }

  },
  updateTimeCard(data){
    // console.log(data)

    this.additionalTimeCards = []
    delete data.additional
    var aurl = '/api/v1/staffing/payrolltimecard/' + data.id
    var atype = "patch"
    var previousTimeCardData = this.userTimeCards.find(p => p.id == data.id)
    var comment = this.timeCardHold[data.id]
   // console.log(comment)
    if(!previousTimeCardData && data && data.id && data.id.includes('AUTOBUILD_')){
      aurl = '/api/v1/staffing/payrolltimecard/'
      atype = 'post'
      data['addUser'] = true
      previousTimeCardData = data
    }
    if(previousTimeCardData.timecardHistory){
      var historyHoldArray = previousTimeCardData.timecardHistory
    } else {
      var historyHoldArray = []
    }
    var adata = {
            clockOutTime: moment(data.clockOutDate + " " + data.clockOutTime, "YYYY-MM-DD HH:mm:ss").toISOString(),
            clockInTime: moment(data.clockInDate + " " + data.clockInTime, "YYYY-MM-DD HH:mm:ss").toISOString(),
            timecardApproved: data.timecardApproved,
            timecardComment: data.timecardComment
    }
    if(previousTimeCardData.addUser){
      adata['timecardUserId'] = this.userSelected ? this.userSelected.id : this.userData.id
    }
   adata['timecardDepartment'] = data.timecardDepartment ? data.timecardDepartment : null
   var historyHold = _.cloneDeep(adata)
   historyHold['userSubId'] = this.userData.id
   historyHold['subDate'] = moment().toISOString()
   historyHoldArray.push(historyHold)
   adata['timecardHistory'] = historyHoldArray
      axios({
        method: atype,
        url: aurl,
        data: adata,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {
          this.getUserTimeCards(this.userSelected)
        //  console.log(response.data)
      })
  },
  getUserMods(user){
      var user = this.userSelected ? this.userSelected.id : this.userData.id
      var start = moment(this.currentView.start, "YYYY-MM-DD").toISOString()
      var end = moment(this.currentView.end, "YYYY-MM-DD").add(1, 'day').endOf('day').toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/usermonthview/' + start + '/' + end + '/' + user,
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
    this.userPayrollMods = response.data.filter(p => p.payrollModApproved != false)

   })

  },
  getUserTimeCards(user){
    if(user && user.id){
      var start = moment(this.currentView.start, "YYYY-MM-DD").toISOString()
      var end = moment(this.currentView.end, "YYYY-MM-DD").add(1, 'day').endOf('day').toISOString()
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrolltimecard/usermonthview/' + start + '/' + end + '/' + user.id,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {
          this.userTimeCards = response.data
      })
    }
  },
  getPreviousPayrollExports(){
  axios({
        method: 'get',
        url: '/api/v1/staffing/payrollexport/short',
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     this.payrollReports = _.orderBy(response.data, ['createdAt'], ['desc'])
      })
  },
  updatePayrollMod(data){
    var stateObj = {currentYear: this.currentYear, currentView: this.currentView, cycleType: this.cycleType}
    bus.$emit('holdState', stateObj)
     // console.log(data)
        delete data.TargetUser
        delete data.TargetShift 
        delete data.TargetParent 
        delete data.ExchangeUser 
        delete data.ApprovalUser 
        delete data.startTime
        delete data.endTime
      bus.$emit('updatePayrollMod', data)
  },

   


  }



  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.printReport {
  margin: 0;
  padding: 0;
  top:0;
  position:fixed;
  width: 1200px;
}
.cursor {
  cursor: pointer;
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
