<template>
<div>
<b-container fluid>
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">RepairForms {{ repairFormTableReturnFilter.length }}</b-navbar-brand>
        <b-form-input class="bootstrapTable mr-sm-2" type="text" v-model="searchData" placeholder="Search"></b-form-input>
      </b-nav-form>
      <b-nav-form class="ml-auto">
  
         <b-form-input type="date" v-model="startDate" />
         <b-form-input class="ml-2" type="date" v-model="endDate" />
         <b-btn variant="primary" class="mt-1 ml-2" @click="searchRepairForms">Search</b-btn>
      </b-nav-form>
    </b-navbar>
        <b-navbar type="dark" variant="dark" v-if="repairFormTable && repairFormTable.length > 0">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Filter Options</b-navbar-brand>
        <b-form-select :options="typeFilterOptions" v-model="typeFilter" />
      </b-nav-form>
      <b-nav-form class="ml-auto">
      </b-nav-form>
    </b-navbar>
            <b-navbar type="dark" variant="dark" v-if="repairFormTable && repairFormTable.length > 0">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Status</b-navbar-brand>
        <b-form-checkbox-group
        id="checkbox-group-1"
        class="text-light"
        v-model="typeStatus"
        :options="typeStatusOptions"
        name="station-options"
      ></b-form-checkbox-group>
      </b-nav-form>
      <b-nav-form class="ml-auto">
      </b-nav-form>
    </b-navbar>
  </b-container>
  <b-container fluid>
  <b-table :fields="fields" :items="repairFormTableReturnFilter">
    <template slot="repairformDate" slot-scope="data">
      {{ getFormatedDate(data.item.repairformDate)}}
    </template>
    <template slot="repairformType" slot-scope="data">
     <span v-if="data.item.repairformType"> {{ data.item.repairformType.toUpperCase()}} </span>
    </template>
    <template slot="repairformItem" slot-scope="data">
      <b-btn variant="link" :disabled="!data.item.repairformDescription" @click="showDescription = true, repairDescriptionData = data.item">{{ data.item.repairformItem }}</b-btn>
    </template>
    <template slot="Type" slot-scope="data">
      <span v-if="data.item.repairformType && data.item.repairformType == 'apparatus'">
        Vehicle
      </span>
      <span v-if="data.item.repairformType && data.item.repairformType == 'facilities'">
        Facility
      </span>
     <span v-if="data.item.repairformType && data.item.repairformType == 'asset'">
       Asset
       </span>
    </template>
    <template slot="Assigned" slot-scope="data">
      <span v-if="data.item.repairformType && data.item.repairformType == 'apparatus'">
        {{ data.item.repairformApparatusId }}
      </span>
      <span v-if="data.item.repairformType && data.item.repairformType == 'facilities'">
        {{ data.item.repairformStation }}
      </span>
      <span v-if="data.item.repairformType && data.item.repairformType == 'asset'">
        {{ data.item.repairformStation }}
      </span>
    </template>
    <template slot="Parts" slot-scope="data">
      <span v-if="data.item.repairCost && data.item.repairCost.parts">{{ data.item.repairCost.parts }}</span>
    </template>
    <template slot="Labor" slot-scope="data">
      <span v-if="data.item.repairCost && data.item.repairCost.labor">{{ data.item.repairCost.labor }}</span>
    </template>
  </b-table>
  </b-container>
<b-modal v-if="showDescription" v-model="showDescription" size="lg">
  <stationRepair :repair="repairDescriptionData" :showPost="true" :userPanel="true" />
</b-modal>
</div>
</template>
<script>

import moment from 'moment'
import axios from 'axios'
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import stationRepair from './stationrepairfilterdata'
export default {
  props: [],
components:{
  stationRepair

  },
  data () {
    return {
      searchData: '',
      repairFormTable: [],
      startDate: '',
      endDate: '',
      showDescription: false,
      repairDescriptionData: '',
      typeFilterOptions: [{text: 'Filter Type', value: null}],
      typeFilter: null,
      typeStatusOptions: [],
      typeStatus: [],
      fields: [
          {key: 'repairformDate', label: 'Date'},
          {key: 'repairformType', label: 'Category'},
          {key: 'repairformItem', label: 'Item'},
          {key: 'repairformStatus', label: 'Status'},
          'Type',
          'Assigned',
          {key: 'repairformAuthor', label: 'Author'},
           'Parts',
           'Labor'
           
      ],
    }
  },
  created(){
    
      this.endDate = moment().format('YYYY-MM-DD')
      this.startDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
      this.searchRepairForms()
  },
  computed:{
    ...mapState([
        'getSessionData',
        'userData',
        'deptContactData',
        'repairStatus',
        'apparatusData',
        'stationsData'
  ]),
      startDateSearch(){
          var date = moment(this.startDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss')
          return date + 'Z'
      },
      endDateSearch(){
          return this.endDate + 'T23:59:59Z'
      },
      repairFormTableReturn(){
        var array = _.cloneDeep(this.repairFormTable)
        var options = [...new Set(array.map(p => p.repairformType))]
        var status = [...new Set(array.map(p => p.repairformStatus))]
        for(var o in options){
            if(options[o]){
              var index = this.typeFilterOptions.indexOf(options[o])
              if(index == -1){
              this.typeFilterOptions.push(options[o])
              }
            }
        }
        for(var o in status){
            if(status[o]){
              var index = this.typeStatusOptions.indexOf(status[o])
              if(index == -1){
              this.typeStatusOptions.push(status[o])
              }
            }
        }
        return array
      },
      repairFormTableReturnFilter(){
        var array = _.cloneDeep(this.repairFormTableReturn)
        if(this.typeFilter){
          array = array.filter(p => {
              return p.repairformType == this.typeFilter
          })
        }
        if(this.typeStatus && this.typeStatus.length > 0){
          array = array.filter(p => {
              var index = this.typeStatus.indexOf(p.repairformStatus)
              if(index != -1){
                return p
              }
          })
        }
        return array
      },
  },
  watch: {
      startDate(date){
        
      }
  },
  methods: {
    getFormatedDate(date){
      return moment(date).format('MM/DD/YYYY')
    },
    searchRepairForms(){
    
     axios({
      method: 'get',
      url: '/api/v1/content/repairform/repairtrackall/' + this.startDateSearch + '/' + this.endDateSearch,
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
       
        this.repairFormTable = _.orderBy(response.data, ['repairformDate'], ['desc'])
    })


    },

  }
}
</script>

<style scoped>
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.borderTop {
  border-top: 1px solid #777;
  width: 80%;
  margin: 0 auto 0;
  padding-top: 10px;
  cursor: pointer;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
  padding-bottom: 10px;
}
h4.errorText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #e2070d;
  font-style: italic;
}
a {
  text-decoration: none;
}
.A-shift{
  color: #e2070d;
}
.B-shift{
  color: blue;
}
.C-shift{
  color: green;
}
</style>
