<template>
  <div class="apparatus" v-if="apparatusData.length > 0">
      <div @click="narcLogShow = !narcLogShow" id="logbookTitle" class="titleBodyWrap" :class="narcsData.narctrackShift + '-title'" >
       <h5><b-badge :class="typeOfEntry(narcsData.narctrackEntryType)">{{ typeOfEntry(narcsData.narctrackEntryType) }}</b-badge> 
       {{ narcDateFormatDate(narcsData.narctrackDate) }} {{ narcDateFormatTime(narcsData.narctrackDate) }} | {{ apparatusRadio(narcsData.narctrackApparatusId) }}
       | {{ returnAuthorLastName(narcsData.narctrackAuthorId) }} |
        <span v-if="narcsData.narctrackEntryType != 'Daily Narc Use'">{{ returnWitnessLastName(narcsData) }}</span>
        <span v-if="narcsData.narctrackEntryType == 'Daily Narc Use'">{{ narcsData.narctrackWitness }}</span>
         <span v-if="narcsData.narctrackWitness != ''"><span v-if="narcsData.narctrackWitnessSignature == 'Signature Required'" class="flagWarning">&#9873; </span></span></h5>
        </div>
    <b-container v-if="narcLogShow" class="narcContainer">
    
      <b-row class="narcbox-topbar-house">
        <b-col class="narcbox-topbar-col"><div class="narcbox-topbar" :class="typeOfEntry(narcsData.narctrackEntryType)" ><span class="narcbox-topbar-span">{{ typeOfEntry(narcsData.narctrackEntryType) }}</span>
                <div class="rightborder" :class="typeOfEntry(narcsData.narctrackEntryType) + '-border'"></div></div></b-col>
        <b-col class="narcbox-topbar-col"><div><span class="narcbox-topbar-span">{{ narcsData.narctrackApparatusId }}</span></div></b-col>
        <b-col class="narcbox-topbar-col"><div  class="narcbox-topbar-right" :class="narcsData.narctrackShift"><div class="leftborder" :class="narcsData.narctrackShift + '-border'"></div>
          <span class="narcbox-topbar-span">{{ narcsData.narctrackShift }}</span></div></b-col>
      </b-row>
    <b-row v-if="editTimeDifference || userData.role == 'admin'"><b-col align="right">
              <b-btn v-if="!editNarcLog" @click="editNarcLogSwitch" class="mr-2" size="sm" variant="danger">edit</b-btn>
              <b-btn v-if="editNarcLog" @click="cancelEditNarcLogSwitch" class="mr-2" size="sm" variant="danger">cancel</b-btn>
              </b-col></b-row>
    <b-row>
      <b-col><label><h5 class="labelHeaders">Date: </h5></label> {{ narcDateFormatDateCard(narcsData.narctrackDate) }}</b-col>
      <b-col><label><h5 class="labelHeaders">Time: </h5></label> {{ narcDateFormatTime(narcsData.narctrackDate) }}</b-col>
    </b-row>
    <div  v-if="!editNarcLog">
     <b-container>
      <b-container v-if="narcsData.narctrackEntryType != 'Daily Narc Use'"  id="medContainers" class="medsLayout" v-for="meds in narcsData.narctrackMeds" :key="meds.index">
        <b-row> <b-col><h5 class="medNameHeader">{{ meds.med }}</h5></b-col></b-row>
        <b-row id="medLabelHeaders"><b-col v-if="meds.id">Id Tag:</b-col><b-col v-if="meds.count">Count</b-col><b-col v-if="meds.inDate">In Date?</b-col><b-col v-if="meds.tamperSealed">Sealed?</b-col></b-row>
          <b-row><b-col v-if="meds.id">{{ meds.id }}</b-col><b-col v-if="meds.count">{{ meds.count }}</b-col><b-col v-if="meds.inDate">{{ meds.inDate}}</b-col><b-col v-if="meds.tamperSealed">{{ meds.tamperSealed }}</b-col></b-row>
        </b-container>
    <b-container v-if="narcsData.narctrackEntryType == 'Daily Narc Use'"  id="medContainers" class="medsLayout" v-for="meds in narcsData.narctrackMeds" :key="meds.index">
        <b-row> <b-col><h5 class="medNameHeader">{{ meds.med }}</h5></b-col></b-row>
        <b-row id="medLabelHeaders"><b-col v-if="meds.used && meds.wconn.value">Amount Used</b-col><b-col v-if="meds.waste && meds.wconn.value">Amount Wasted</b-col></b-row>
          <b-row><b-col v-if="meds.used && meds.wconn.value">{{ meds.used }}{{ meds.wconn.value }}</b-col><b-col v-if="meds.waste && meds.wconn.value">{{ meds.waste }}{{ meds.wconn.value }}</b-col></b-row>
        </b-container>
    </b-container>
    <b-container >
      <b-container  id="medContainers" class="medsLayout" v-for="meds in narcsData.narctrackItems" :key="meds.index">
        <b-row> <b-col><h5 class="medNameHeader">{{ meds.med }}</h5></b-col></b-row>
        <b-row id="medLabelHeaders"><b-col >Count</b-col><b-col>In Date?</b-col><b-col>Sealed?</b-col></b-row>
          <b-row><b-col>{{ meds.count }}</b-col><b-col>{{ meds.inDate }}</b-col><b-col>{{ meds.tamperSealed }}</b-col></b-row>
        </b-container>
    </b-container>
     <b-card v-if="narcsData.narctrackEntryDetails" border-variant="danger" class="mx-3" no-body>
          <p><span class="font-weight-bold"> Entry Details:</span> {{ narcsData.narctrackEntryDetails }}</p>
          </b-card>
                <b-card class="mx-3" no-body>
      <b-btn v-if="userData.role == 'emsops' || userData.role == 'admin' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1)" 
             block 
             variant="info" 
             small 
             @click="!adminNoteOn ? adminNoteOn = !adminNoteOn : adminNoteOn && narcSubNote ? submitNarcNote() : adminNoteOn = !adminNoteOn">{{ narcsData.narctrackSubmissionNote && !adminNoteOn ? 'Edit Admin Note' : narcSubNote && adminNoteOn ? 'Save Note' : 'Add Admin Note' }}</b-btn>
      <b-textarea v-if="adminNoteOn" :disabled="!adminNoteOn" v-model="narcSubNote" />
      <p v-if="narcSubNote && !adminNoteOn">Admin Note: {{ narcSubNote }}</p>
      </b-card>
      <b-row id="userNamesLabels"><b-col><label><h5 class="labelHeaders">Author </h5></label> </b-col>
        <b-col><label><h5 class="labelHeaders">Witness </h5></label> </b-col>
      </b-row>
      <b-row id="userNames"><b-col>{{ narcsData.narctrackAuthor }}</b-col><b-col>{{ narcsData.narctrackWitness }}</b-col></b-row>
      <b-row>
 <b-col v-if="narcsData.narctrackAuthorSignature.data && narcsData.narctrackAuthorSignature != 'Signature Saved'">
          <img class="test" width="150px" :src='"data:image/png;base64," + fixImage(narcsData.narctrackAuthorSignature.data)' /></b-col>
  <b-col v-if="narcsData.narctrackAuthorSignature == 'Signature Saved' || narcsData.narctrackAuthorSignature == 'Signature Required'">
        <b-btn 
        :variant='narcsData.narctrackAuthorSignature == "Signature Saved" ? "success" : "danger"'
        @click="narcsData.narctrackAuthorSignature == 'Signature Saved' ? getSignatures(narcsData.id) : launchSignaturePanel(narcsData)"
        >{{ narcsData.narctrackAuthorSignature }}</b-btn>
        </b-col>
        <b-col v-if="narcsData.narctrackWitnessSignature.data" ><img width="150px" :src='"data:image/png;base64," + fixImage(narcsData.narctrackWitnessSignature.data)' /></b-col>
        <b-col v-if="narcsData.narctrackWitnessSignature == 'Signature Saved' || narcsData.narctrackWitnessSignature == 'Signature Required'">{{ narcsData.narctrackWitnessSignature }}
        <b-btn size="sm" :disabled="narcsData.narctrackWitnessId != userData.id" variant="danger" @click="launchSignaturePanel(narcsData)" v-if="narcsData.narctrackWitnessSignature == 'Signature Required'">Add Signature</b-btn>
        </b-col>
        </b-row>
        <b-img fluid v-if="narcsData.narctrackOfflineAttach" :src='"data:image/png;base64," + fixImage(narcsData.narctrackOfflineAttach.data)' />
       
        <b-row v-if="userData.role == 'admin' || userData.role == 'emsops' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1)"><b-col>
          <b-form-checkbox v-model="deleteNarcFlag" :check-value="true" :uncheck-value="false">Activate Delete Button</b-form-checkbox></b-col>
          <b-col><b-btn @click="removeNarcLog(narcsData.id)" variant="danger" :disabled="!deleteNarcFlag" size="sm">Delete</b-btn></b-col></b-row>
      </div>

    <div v-if="editNarcLog">
      <b-container> <b-textarea rows="3" v-model="editReason" placeholder="*** Please Provide the reason for editing this narc log" /> </b-container>
        <b-container v-if="medicationArrayData.length > 0">
            <b-container class="medContainer" v-if="narcsData.narctrackEntryType != 'Daily Narc Use'" v-for="(post, index) in medicationArrayData" :key="index" track-by="$index">
                         <b-row><b-col cols="9">
                            <b-form-select v-if="resetInput" v-model="post.med" :options="medication" @change="updateMedicationSelection(index, $event)" ></b-form-select>
                              </b-col>
                            <b-col><b-btn size="sm" variant="danger" @click.stop="removeCurrentRow(post)" >x</b-btn></b-col>
                               </b-row>
                          <b-row>
                              <b-col v-if="post.requireId != undefined" class="inputColumn pl-2" align="center"><h6>ID</h6><b-form-input type="text"  v-model="post.id"  /></b-col>
                              <b-col v-if="post.count != undefined"  class="inputColumn pl-2" align="center"><h6>Count</h6><b-form-input type="number" size="5" inputmode="numeric" pattern="[0-9]*" v-model="post.count"  /></b-col>
                              <b-col v-if="post.inDate != undefined" cols="3" class="dateSeal inputColumn" align="center"><h6>In Date?</h6>
                              <b-form-checkbox  v-if="post.inDate != undefined" :id="'checkbox_date_med_' + index" v-model="post.inDate" :value="true" :unchecked-value="false"></b-form-checkbox>
                               </b-col>
                              <b-col  v-if="post.tamperSealed != undefined"  cols="3" class="dateSeal inputColumn" align="center"><h6>Sealed?</h6>
                            <b-form-checkbox  v-if="post.tamperSealed != undefined" :id="'checkbox_sealed_med_' + index" v-model="post.tamperSealed" :value="true" :unchecked-value="false"></b-form-checkbox>
                               </b-col>
                               </b-row>
                          </b-container>
                          <b-container class="medContainer"  v-if="narcsData.narctrackEntryType == 'Daily Narc Use'" v-for="post in medicationArrayData" :key="post.index" track-by="$index">
                         <b-row><b-col cols="9">
                                 <b-form-select v-model="post.med" :options="medication" ></b-form-select>
                                  </b-col>
                                  <b-col><b-btn size="sm" variant="danger" @click.stop="removeCurrentRow(post)">x</b-btn></b-col>
                               </b-row><b-row><b-col class="inputColumn px-2" align="center"><h6>Amount Used</h6>
                               <b-form-input size="5" type="text" v-model="post.used"  /></b-col>
                                <b-col class="inputColumn px-2" align="center"><h6>Amount Wasted</h6><b-form-input size="5" type="text" v-model="post.waste"  /></b-col>
                               <b-col sm="4"  class="inputColumn pl-2 pb-1" align="center"><h6>Concentration</h6>
                                 <multiselect v-model="post.wconn" :options="concentration" label="value" track-by="value" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select"></multiselect>
                                                           </b-col></b-row>
                          </b-container>
        </b-container>
      <b-container v-if="itemArrayData.length > 0">
        <b-row><b-col><h5>Tracked Items</h5></b-col></b-row>
          <b-container  id="medContainers" class="medsLayout" v-for="meds in itemArrayData" :key="meds.index">
            <b-row> <b-col><b-form-select v-model="meds.medname" :options="itemData" ></b-form-select></b-col></b-row>
            <b-row id="medLabelHeaders"><b-col >Count</b-col><b-col>In Date?</b-col><b-col>Sealed?</b-col></b-row>
            <b-row><b-col><b-form-input size="5" type="number" inputmode="numeric" pattern="[0-9]*" v-model="meds.count" /></b-col>
              <b-col><b-form-select v-model="meds.inDate" :options="selectYesOrNo" /></b-col>
              <b-col><b-form-select v-model="meds.tamperSealed" :options="selectYesOrNo" /></b-col></b-row>
          </b-container>
      </b-container>
      <b-btn @click="checkSaveData(medicationArrayData)">Save</b-btn>
    </div>
    
  </b-container>
   
  </div>

</template>

<script>
import moment from "moment";
import axios from 'axios';
import { mapState } from "vuex";
import signature from '../../inputfields/signaturepad.vue';
import { bus } from '../../../main'
import Multiselect from "vue-multiselect";
export default {
  props: ["narcs", "showPost"],
  components: {
    'signature': signature,
     multiselect: Multiselect,
  },
  name: "apparatus",
  data() {
    return {
      items: [],
      narcLogShow: false,
      editNarcLog: false,
      resetInput: true,
      medicationArrayData: [],
      medicationItemArray: [],
      itemArrayData: [],
      witnessSign: false,
      deleteNarcFlag: false,
      adminNoteOn: false,
      narcSubNote: '',
      medication: [],
      concentration: [{ value: "mg" }, { value: "mcg" }, {value: "min"}],
      selectYesOrNo: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      itemData: [
        { value: "Adult Probe", text: "Adult Probe" },
        { value: "Child Probe", text: "Child Probe" },
        { value: "Replacement(other)", text: "Replacement(other)" }
      ],
      editReason: "",
      returnData: null,
    };
  },
  created() {
    if(this.showPost == true){
      this.narcLogShow = true
    }
    this.medicationArray(this.narcsData);
    this.itemArray(this.narcsData);
    this.medicationItemArray = this.filteredMedicationsItems;
  },
  computed: {
    ...mapState(["userData", "apparatusData", "deptContactData", "getSessionData", "medicationData"]),
    editTimeDifference() {
      if (this.userData.id == this.narcsData.narctrackAuthorid) {
        var logTime = this.narcsData.createdAt;
        var logTime = moment(logTime).unix();
        var nowTime = moment().unix();
        var diff = nowTime - logTime;
        if (diff < 1800) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
     },
     narcsData(){
       if(!this.returnData){
       var narcObj = this.narcs 
      this.narcSubNote  = narcObj.narctrackSubmissionNote
       } else {
       var narcObj = this.returnData
       this.narcSubNote = narcObj.narctrackSubmissionNote
       }
       return narcObj
     },
     filteredMedicationsItems() {
      var meditem = this.medicationData.filter(p => {
        return p.narcoticTrack == true;
      });
      for (var i = 0; i < meditem.length; i++) {
        if (meditem[i].alsSupply == false) {
          var string = {
            text: meditem[i].medicationName,
            value: meditem[i].medicationName,
            id: meditem[i].narcoticTrackId,
            date: meditem[i].narcoticTrackDate,
            ct: meditem[i].narcoticTrackCt,
          };
          this.medication.push(string);
        } else {
          var string = {
            text: meditem[i].medicationName,
            value: meditem[i].medicationName,
            id: meditem[i].narcoticTrackId,
            date: meditem[i].narcoticTrackDate,
            ct: meditem[i].narcoticTrackCt
          };
          this.itemData.push(string);
        }
      }
      return meditem;
    },
  },
  watch: {},
  methods: {
    
    launchSignaturePanel(narcsData){
      bus.$emit('LaunchSignaturePanel', narcsData)
    },
    narcsWitnessTest(narcsData){
      if(narcsData.narctrackWitnessSignature.data == '' && narcsData.narctrackWitnessId == this.userData.id){
        return true
      } else {
        return false
      }
    },
    returnAuthorLastName(data){
      
      var user = this.deptContactData.filter( p => {
        return p.id.match(data)
      })
      if(user != undefined && user.length > 0){
      return user[0].lastName
      }
    },
    returnWitnessLastName(data){

      if(data.narctrackWitnessId != undefined && data.narctrackWitnessId != null && data.narctrackWitnessId != ''){
          var user = this.deptContactData.filter( p => {
            return p.id == data.narctrackWitnessId
          })
          if(user != undefined && user.length > 0){
          return user[0].lastName
       }
      } else if (data.narctrackRunNumber != undefined && data.narctrackRunNumber != null && data.narctrackRunNumber != '') {
        return data.narctrackRunNumber

      } else {
        return ''
      }

    },
    apparatusRadio(data){
      var app = this.apparatusData.filter( p => {
        return p.apparatusName.match(data)
      })
      return app[0].apparatusRadioId
    },
    fixImage(data) {
      var string = new Buffer(data, "base64").toString("utf8");

      return string;
    },
    editNarcLogSwitch(event) {
      this.editNarcLog = !this.editNarcLog;
      this.medicationArrayData = this.narcsData.narctrackMeds
    },
    cancelEditNarcLogSwitch(event) {
      this.editNarcLog = !this.editNarcLog;
      this.medicationArray(this.narcsData);
      this.itemArray(this.narcsData);
    },
    
    checkSaveData(data){
       axios({
        method: 'patch',
        url: '/api/v1/content/narctrack/adminpatch/' + this.narcsData.id,
        data: {
                'narctrackMeds': data,
              },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
        console.log(response.data)
      })
    },
    getSignatures(id){
      axios({
        method: 'get',
        url: '/api/v2/content/narctrack/signatures/' + id,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
        }).then(response => {
          this.returnData = response.data
      })
    },
    narcAppDate(data) {
      return moment(data).format("MM/DD/YYYY");
    },
    typeOfEntry(data) {
      var namespace = data.split("Daily Narc");
      var name = namespace[1].replace(/\s+/g, "");
      return name;
    },
    shortAppName(data) {
      if (data == "") {
        return "?";
      }
      var appParts = data.split(" ");
      var firstLetter = appParts[0].substring(0, 1);
      return firstLetter + appParts[1];
    },
    narcDateFormatDate(data) {
      var date = moment(data).format("MM/DD/YY");
      var current = moment().format("MM/DD/YY");
      if (date == current) {
        return;
      } else {
        return date + " | ";
      }
    },
        updateMedicationSelection(data, value){
      this.resetInput = false
      var newMed = this.medicationItemArray.find(p => p.medicationName == value)
      var type = this.apparatusSelectValue.apparatusVehicleType.toLowerCase()
      var count = type + "Count";
      var obj = newMed
      var value = obj[count]
      if(!value){
        value = 0
      }
      var string = { med: newMed.medicationName }
      if(newMed.narcoticTrackCt){
        if(this.statusSelect != 'Daily Narc Restock'){
        string['count'] = value
        } else {
          string['count'] = 1
        }
        }
      if(newMed.narcoticTrackDate){
         string['inDate'] = true
        }
      if(newMed.narcoticTrackId){
          string['id'] = ''
          string['requireId'] = true
      }
      if(newMed.narcoticTrackTamper){
         string['tamperSealed'] = true
          }
      if(newMed.alsSupply){
        this.medicationArrayData[data] = string
      } else {
        this.medicationArrayData[data] = string
      }
      this.$nextTick(function(){
        this.resetInput = true
      })
    },
    medicationArray(data) {
      return data.narctrackMeds
    },
    itemArray(data) {
      return data.narctrackItems
    },
    narcDateFormatTime(data) {
      return moment(data).format("HH:mm");
    },
    narcDateFormatDateCard(data) {
      return moment(data).format("MM/DD/YY");
    },
    removeNarcLog(data){
    var result = confirm('Are You Sure You Want to Delete this Narclog? No Going Back!')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/content/narctrack/' + data,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
    this.$nextTick(function(){
        bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
        bus.$emit('emsPullRequestChange', undefined)
    })
  }) 
      }
    },
        submitNarcNote(){
       axios({
        method: 'patch',
        url: '/api/v1/content/narctrack/' + this.narcsData.id,
        data: {
                "narctrackSubmissionNote": this.narcSubNote
              },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
        console.log(response)
        if(response && response.data[0] && response.data[0]){
      this.returnData = response.data[0]
      this.adminNoteOn = false
        }

      })
    },
    submitNarcLog(){
       axios({
        method: 'post',
        url: '/api/v1/content/narctrack',
        data: {
                "title": moment().format('MM/DD/YYYY-HH:mm') + ' | ' + this.apparatusSelectValue.apparatusName + ' | ' + this.statusSelect + ' | ' + this.userData.lastName,
                'narctrackEntryType': this.statusSelect,
                'narctrackDate': this.formatedDateExport,
                'narctrackRunNumber': this.reportRunNumber,
                'narctrackApparatusId': this.apparatusSelectValue.apparatusName,
                'narctrackMeds': this.field_medication_tracking,
                'narctrackItems': this.field_item_tracking, 
                'narctrackShift': this.shiftSet, 
                'narctrackStation': this.stationSelectValue.stationName, 
                'narctrackStationParent': this.stationSelectValue.stationParent, 
                'narctrackAuthor': this.userData.firstName + ' ' + this.userData.lastName, 
                'narctrackAuthorId': this.userData.id, 
                'narctrackAuthorSignature': this.newImageFileData, 
                'narctrackWitness': this.witnessOrOffGoing.firstName + ' ' + this.witnessOrOffGoing.lastName, 
                'narctrackWitnessId': this.witnessOrOffGoing.id, 
                'narctrackWitnessSignature': this.newImageFileDataWitness, 
                'narctrackEntryDetails': this.narcDescription, 
                'narctrackEmailWitness': this.emailWitness
              },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
      this.postData = response.data
        })
        this.showModalConfirmation = !this.showModalConfirmation;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flagWarning {
  color: red;
}
.narcbox-house {
  margin: 0 auto 0;

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #fff;
}
.narcbox-topbar-house {
  height: 41px;
  width: 100%;
  border-bottom: 1px solid #777;
  border-radius: 10px 10px 0px 0px;
  margin: 0 auto 0;
  padding-top: 0 !important;
}
.narcbox-topbar {
  width: 100%;
  height: 40px;
  border-radius: 10px 10px 0 0;
}
.narcbox-topbar-right {
  width: 100%;
  height: 40px;
  border-radius: 0 10px 0 0;
}
.narcbox-topbar-span {
  display: inline-block;
  padding-top: 8px;
  margin-left: -15px;
  margin-right: -15px;
}

.rightborder {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid #ccc;
  height: 0;
  width: 0;
}
.leftborder {
  float: left;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid #fff;
  height: 0;
  width: 0;
}
.narcbox-topbar-col {
  padding: 0 !important;
}

.apparatus {
  border-radius: 10px;
  text-align: center;
}
#userNamesLabels.row {
  padding-top: 10px;
}
#userNames.row {
  padding-top: 0;
}
.A-shift {
  background: red;
  color: #fff;
}
.B-shift {
  background: blue;
  color: #fff;
}
.C-shift {
  background: green;
  color: #fff;
}
.apparatusTitleBar {
  color: #fff;
}
.row {
  padding-top: 10px;
  padding-bottom: 0;
}
#medLabelHeaders .col {
  font-weight: bold;
}
.medsLayout {
  border: 1px solid #aaa;
  margin-bottom: 5px;
}
h5.medNameHeader {
  font-weight: bold;
  background: #eee;
  padding: 2px;
  padding-top: 0;
}
h5.labelHeaders {
  font-weight: bold;
}
.narcContainer {
  width: auto;
  border: 1px solid #777;
  margin: 10px;
  border-radius: 10px;
  overflow-x: hidden;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Transfercontainer {
  border: 1px solid rgba(0, 191, 0, 0.8);
  margin-bottom: 15px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Distributioncontainer {
  border: 1px solid rgba(190, 0, 0, 0.8);
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Restockcontainer {
  border: 1px solid rgba(255, 96, 0, 0.8);
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Usecontainer {
  border: 1px solid rgba(255, 96, 0, 0.8);
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Transfer {
  background-color: green;
  color: #fff;
}
.Distribution {
  background-color: red;
  color: #fff;
}
.Restock {
  background-color: blue;
  color: #fff;
}
.Total {
  background-color: #333;
  color: #fff;
}
.Use {
  background-color: orange;
  color: #fff;
}
.Usecontainer {
  border: 1px solid rgba(255, 96, 0, 0.8);
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Use-border {
  border-left: 10px solid orange;
  float: right;
  background: #fff;
}
.Restock-border {
  border-left: 10px solid blue;
  float: right;
  background: #fff;
}
.Transfer-border {
  border-left: 10px solid green;
  float: right;
  background: #fff;
}
.Distribution-border {
  border-left: 10px solid red;
  float: right;
  background: #fff;
}
.Total-border {
  border-left: 10px solid #333;
  float: right;
  background: #fff;
}
#medContainers .col {
  padding: 0;
  margin: 0;
}
#medContainers .row {
  padding: 0;
}
h1,
h2 {
  font-weight: normal;
}
.titleBodyWrap {
  margin: 0 auto 0;
  text-align: left;
  width: 80%;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 5px;
}
.A-shift-title {
  color: #e2070d;
}
.B-shift-title {
  color: #201b9f;
}
.C-shift-title {
  color: #0fb906;
}

@media screen and (max-width: 768px) {
  .titleBodyWrap {
    margin: 0 auto 0;
    width: 95%;
    padding: 8px;
    margin-bottom: 0;
    margin-top: 5px;
  }
}
</style>
