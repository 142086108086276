<script>
import { Bar } from 'vue-chartjs'
 
export default {
  extends: Bar,
   props: ['dataSent', 'optionsSent'],
   data () {
    return {
        data: {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [
                    {
                    label: 'GitHub Commits',
                    backgroundColor: '#f87979',
                    data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    }
            ]
    },
    options: {},
    }
  },
  created(){
      if(this.dataSent){
          this.data = this.dataSent
      }
      if(this.optionsSent){
          this.options = this.optionsSent
      }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  }
}
</script>