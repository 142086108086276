<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>

      <div  v-if="userData.role == 'adminchief'
      || userData.role == 'admin' || userData.role == 'emsops' || userData.role == 'fireops'">
<b-container v-if="notification == ''">
  <b-btn block v-if="userData.role == 'admin'" @click="getAllNotifications">Get All Notifications</b-btn>
  <b-btn v-if="notificationsReturned.length > 0 && userData.role == 'admin'" block variant="primary" @click="FileProcess">Download JSON ({{ notificationsReturned.length }})</b-btn>
       <input v-if="userData.role == 'admin'" type="file" @change="jsonFileUpload($event)" />
       <b-btn v-if="dataForBulkUpload && dataForBulkUpload.length > 0 && userData.role == 'admin'" @click="submitBulkUpload">Bulk Upload</b-btn>
       <b-btn block v-if="saveDataForBulk && saveDataForBulk.length > 0" @click="consoleLogBulk">Preview</b-btn>
    
  <multiselect v-if="notificationsReturned.length > 0 && userData.role == 'admin'" v-model="userSelectSearch" :options="deptContactData" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select User for Unread">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
  </multiselect>
  <b-btn v-if="userSelectSearch && userSelectSearch.id" variant="danger" @click="userSelectSearch = {}">Clear User Search</b-btn>
  <b-btn v-if="notificationsReturned.length > 0 && userSelectSearch && userSelectSearch.id" @click="submitAsReadUser">Submit as Read ({{ notificationsReturned.length }})</b-btn>
<b-table v-if="notificationsReturned.length > 0 && userData.role == 'admin'" hover striped  :fields="fields" :items="notifications">
  <template slot="notificationDate" slot-scope="data">
    {{ notificationTimeAdjust(data.item.notificationDate) }}
  </template>
  <template slot="View" slot-scope="data">
    <b-btn variant="link" @click="showNotificationData(data.item)">View</b-btn>
  </template>
    <template slot="Remove" slot-scope="data">
    <b-btn variant="danger" size="sm" @click="deleteNotification(data.item.id)">Delete</b-btn>
  </template>
</b-table>
<b-table v-if="notificationsReturned.length == 0" hover striped  :fields="fields" :items="notifications">
  <template slot="notificationDate" slot-scope="data">
    {{ notificationTimeAdjust(data.item.notificationDate) }}
  </template>
  <template slot="View" slot-scope="data">
    <b-btn variant="link" @click="showNotificationData(data.item)">View</b-btn>
  </template>
    <template slot="Remove" slot-scope="data">
    <b-btn variant="danger" size="sm" @click="deleteNotification(data.item.id)">Delete</b-btn>
  </template>
</b-table>
</b-container>
<b-container class="mt-4" v-if="notification != ''">
  <b-btn variant="danger" size="sm" @click="notification = ''">Return to Table</b-btn>
      <download-excel  class="ml-2 float-right"
    v-if="fixDataExcel.length > 0"
    :name="notification.title + ' added on ' + getFormatedDate(notification.createdAt) + ' with ' + (notification.notificationUsers.length / notification.notificationUsersCount * 100).toFixed(0) + '%' + ' compliance'"
    :data="fixDataExcel">
    <b-btn variant="primary" size="sm">Download As Excel</b-btn>
    </download-excel>
<b-card no-body>
  <b-tabs card>
    <b-tab title="Notification View">
  
       <h5>{{  (notification.notificationUsersCount / (notification.notificationUsers.length + notification.notificationUsersCount) * 100).toFixed(2) }}% Compliance</h5>
      <notificationCard :notification="notification" />
    </b-tab>
    <b-tab v-if="notification.notificationUsers.length > 0" :title="'Unread (' + notification.notificationUsers.length + ')'" >
      <usertable  :users="notification.notificationUsers" :clearFromList="true" @updateReadList="submitAsRead($event)" />
    </b-tab>
    <b-tab :title="'Read (' + notification.notificationNotifiedUsers.length + ')'">
      <usertable :users="notification.notificationNotifiedUsers" :clearFromList="false" />
      </b-tab>
  </b-tabs>
</b-card>
</b-container>

  </div>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import axios from 'axios'
import UserTable from './usertable/userTable.vue'
import JsonExcel from 'vue-json-excel';
import NotificationCard from '../tracking/blogs/notificationCard.vue'
import multiselect from 'vue-multiselect'
import PapaParse from 'papaparse';
export default {
  name: 'hello',
  components: {
    'usertable': UserTable,
    'notificationCard' : NotificationCard,
      'download-excel' : JsonExcel,
          multiselect
  },
  data () {
    return {
      uploadedJson: [

      ],
      fields: [
      { key: 'notificationDate', label: 'Date', sortable: true },
      'title',
      { key: 'notificationAuthor', label: 'Author'},
      'View'
    ],
      dataReset: true,
      userSelectSearch: {},
      addNewDocument: false,
      postNid: '',
      notification: '',
      searchData: '',
      updateFile: '',
      vehicleTypes: [],
      parentGroupings: [],
      stationsData: [],
      departmentRanks: [],
      departmentSpecialties: [],
      fileDocumentId: '',
      shifts: [],
      fileUpload: '',
      notifications: [],
      allNotificationsData: [],
      saveDataForBulk: [],
      dataForBulkUpload: [],
      iframePdf: '',
      showFile: '',
    }
  },
  created(){
     this.getNotifications()
     if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
       this.fields.push('Remove')
     }
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'getSessionData',
        'deptContactData'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin' || this.userData.role == 'emsops' || this.userData.role == 'fireops'){
            return false
          } else {
            return true
          }
      },
      fixDataExcel(){ 
          var notification = _.cloneDeep(this.notification)
          var tagged = notification.notificationUsers
          var array = tagged.map(item => {
              return this.deptContactData.find(p => p.id == item)
          })
          var complete = notification.notificationNotifiedUsers
          complete = complete.map(item => {
                    var obj = this.deptContactData.find(p => p.id == item.id)
                    if(obj){
                    obj['dateRead'] = item.dateRead
                    return obj
                    }
          })
          array = array.filter(p => p != undefined)
          complete = complete.filter(p => p != undefined)
          array = _.orderBy(array, ['lastName'], ['asc'])
          complete = _.orderBy(complete, ['lastName'], ['asc'])
          var hold = []
          for(var i in array){
            var obj = { name: array[i].lastName + ', ' + array[i].firstName, employeeId: array[i].employeeId, station: array[i].stationAssignment, shift: array[i].shiftAssignment, completed: 'FALSE', completedDate: '' }
            hold.push(obj)
          }
          for(var i in complete){
            var obj = { name: complete[i].lastName + ', ' + complete[i].firstName, employeeId: complete[i].employeeId, station: complete[i].stationAssignment, shift: complete[i].shiftAssignment, completed: 'TRUE', completedDate: complete[i].dateRead }
            hold.push(obj)
          }
          return hold
        },
        notificationsReturned(){
          var array = _.cloneDeep(this.allNotificationsData)
          var holdArray = []
          for(var a in array){
            var insert = array[a]
            var newObj = {}
          for(var i in insert){
                var key = i.replace(/([A-Z])/g, function($1){return "_"+$1.toLowerCase();})
                   newObj[key] = insert[i] 

          }
          holdArray.push(newObj)
          }
          if(this.userSelectSearch && this.userSelectSearch.id){
            console.log(this.userSelectSearch)
            console.log('USER SELECTED')
          holdArray =  array.filter(p => {
                  var index = p.notificationUsers.indexOf(this.userSelectSearch.id)
                  if(index >= 0){
                    return p
                  }
            })
            console.log(holdArray)
          holdArray = holdArray.filter(p => {
                  var test = p.notificationNotifiedUsers.map(i => i.id)
                  var index = test.indexOf(this.userSelectSearch.id) >= 0
                  if(index >= 0){
                    return p
                  }
          })
          }
          return _.orderBy(holdArray)
        }

  },
  watch: {
 
},
  methods: {
    FileProcess(){
        const json_data = this.notificationsReturned
        const fileName = "jsonDataNotifications.json";
        const data = new Blob([JSON.stringify(json_data)], { type: "text/json" });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      },
      jsonFileUpload(e){
        var vm = this
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = (e) => {
          console.log("e.target.result", e.target.result);
          const data = JSON.parse(e.target.result);
          vm.fixDataApply(data)
        };
    },
    fixDataApply(data){
      console.log(data)
            this.dataForBulkUpload = data
    },
  modifyandsaveData(data){
    var obj = data
    console.log(obj)
   // obj['notification_notified_users'] = JSON.parse(data.notification_notified_users)
    this.saveDataForBulk.push(obj)
  },
  submitBulkUpload(){
     axios({
      method: 'post',
      url: '/api/v1/content/notification/bulk',
      data: this.dataForBulkUpload,
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
     // this.blog = response.data
      this.getNotifications()
      
      })
    },
  getFormatedDate(date){
      return moment(date).format('MM DD YYYY')
    },
    getAllNotifications(){
        axios({
              method: 'get',
              url: '/api/v1/content/notification/', 
              headers: {
                'content-type': 'application/json',
                'authorization': this.getSessionData
              }}
              ).
          then((response) => {
            console.log(response.data.length)
            this.allNotificationsData = response.data 
            })
    },
    getNotifications(){
        axios({
              method: 'get',
              url: '/api/v1/content/notification/minimal', 
              headers: {
                'content-type': 'application/json',
                'authorization': this.getSessionData
              }}
              ).
          then((response) => { this.getNotificationsData(response.data) })
    },
    getNotificationsData(data){
      var dataArray = data
   //   console.log(data)
      this.notifications = _.orderBy(dataArray, ['notificationDate'], ['desc'])
    },
    showNotificationData(data){
             axios({
              method: 'get',
              url: '/api/v1/content/notification/' + data.id, 
              headers: {
                'content-type': 'application/json',
                'authorization': this.getSessionData
              }}
              ).
          then((response) => { 
            this.notification = response.data 
            })
    },    
    imageSrcUrl(data){
      var user = this.deptContactData.filter( p => 
      { return p.id == data})
      var picture = this.userPhotoLink(user[0].picture)
      return picture
    },
    notificationTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY - HH:mm')
    },
      userPhotoLink(fileName){
    if(fileName.charAt(0) == '/'){
  return this.siteconfig.deptStore + fileName.replace('/', '')
    } else {
      return  this.siteconfig.deptStore + fileName
    }
  },
    viewFileIframe(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              this.iframePdf = response.data.signedRequest
              this.showFile = true

       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
       getVideoId(data){
           var videoLink = data.split('=')
          if(videoLink.length == 1){
            var videoLink = data.split('.be/')
          }
        return videoLink[1]
      },
      submitAsReadUser(){
        var arrayIds = _.cloneDeep(this.notificationsReturned)
        arrayIds = arrayIds.map(p => p.id)
        for(var i in arrayIds){
          this.submitAsRead(this.userSelectSearch.id, arrayIds[i])
        }
      },
    submitAsRead(data, noId){
      if(noId){
        var id = noId
      } else {
        var id = this.notification.id
      }
     axios({
      method: 'patch',
      url: '/api/v1/content/notification/adminread/' + id + '/' + data,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      if(!noId){
      this.notification = response.data
      this.getNotifications()
      
  
      }
    })
    },
    deleteNotification(data){
        var result = confirm('Are You Sure You Want to Delete this notification? It will be removed for everyone!!!')
    if(result){
     axios({
      method: 'Delete',
      url: '/api/v1/content/notification/' + data,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      this.getNotifications()
      
      })
    }
    },

 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.notification-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}

.notificationTitle {
  color: red;
}
.notificationSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.notificationSubData {
  font-size: 16px;
  color: #777;
}
.notificationBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}
</style>
