<template>
<div>
  <b-container>
    <newTrainingSubmission :trainingModule="trainingModule" :credentialRequirement="credentialRequirement" />
  </b-container>
    
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import newTrainingSubmission from '../../../addNew/newTrainingSubmission.vue'
import moment from 'moment'
export default {
  components: {
  newTrainingSubmission
  },
  props: ['trainingModule', "credentialRequirement"],
  name: 'training-module',
  data () {
    return {
    
    }

  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),

  
  },
  mounted(){

  },
  watch: {
    
  },
  methods: {
    closeTrainingModule(id){
      this.$emit('closeTrainingModule', id)
    }


}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.requirement-error{
  border: 1px solid red;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
