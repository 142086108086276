<template>
<div><span v-show="false">{{ refreshCheck }}</span>
    <b-table class="credential-table" :fields="fields" :items="credentialsDataModified">
      <template slot="row-details" slot-scope="row">
        <b-table :on="getCredentialRequirementsData(row.item.credentialRequirements, row.item.credentialStartDate, row.item.credentialEndDate, row.item)" 
        :id="'secondarRowTable-' + row.item.id" 
        small outlined inverse
         :fields="fieldsSub" 
         :items="requirementHoldObj[row.item.id]">
          <template slot="Completed" slot-scope="data">
         <span v-if="data.item.CompletedExplained">*</span>{{ data.item.Completed }} ({{ Math.floor((data.item.Completed / parseInt(data.item.credentialRequirementHours) * 100)) + '%'}})
          </template>
          </b-table>
      </template>
      <template slot="credentialStartDate" slot-scope="data">
        {{ getDateFormat(data.item.credentialStartDate)}}
      </template>
            <template slot="credentialEndDate" slot-scope="data">
        {{ getDateFormat(data.item.credentialEndDate)}}
      </template>
      </b-table>
</div>
</template>
<script>

import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  props:['refresh', 'userId'],
components:{

},
  data () {
    return {
      credentialsData: [],
      credentialRequirement: [],
      requirementHoldObj: {},
      usersTraining: [],
      fields: [
        {key: 'credentialName', label: 'Name' },
        {key: 'credentialStartDate', label: 'Start'},
        {key: 'credentialEndDate', label: 'End'}
      ],
      fieldsSub: [
        {key: 'credentialRequirementName', label: 'Requirement'},
        {key: 'credentialRequirementHours', label: 'Hours'},
        'Completed'
      ],
      checkId: '',
      checkIfLoaded: false,
    }
  },
  created(){
    if(this.userId){
      this.checkId = this.userId
    } else {
      this.checkId = this.userData.id
    }
    this.getUsersTraining()
    this.getCredentialRequirements()
    this.getCredentials()
  },
  watch: {
   
  },
  computed:{
    ...mapState([
    'getSessionData',
    'stationsData',
    'userData',
    'config',
    'userPosts'
    ]),
    refreshCheck(){
    //  console.log(this.checkIfLoaded)
      if(this.checkIfLoaded){
     // console.log(this.refresh)
      this.getUsersTraining()
      this.getCredentials()
      }
      return this.refresh
    },
    credentialsDataModified(){
      return this.credentialsData
    },
    },
    methods: {
      getCredentialRequirementsData(data, start, end, item){
        var includes = data
        var array = this.credentialRequirement.filter(p => {
            var index = includes.indexOf(p.id)
            if(index != -1){
              return p
            }
        })
        var holdarray = []
        for(var i in array){
          var obj = array[i]
        obj['start'] = start
         obj['end'] = end
         holdarray.push(obj)
        }
        var completedArray = []
        var rollover = []
        for(var i in holdarray){
          var obj = holdarray[i]
          var completed = this.getCompletedTraining(obj)
         
          if(completed){
        // console.log(completed)
            if(parseInt(obj.credentialRequirementHours) >= completed){
              obj['Completed'] = completed
              completedArray.push(obj)
            } else {
              var sub = completed - parseInt(obj.credentialRequirementHours)
              obj['Completed'] = parseInt(obj.credentialRequirementHours)
              completedArray.push(obj)
              if(holdarray[i].credentialRequirementRolloverId){
                  var rollobj = {id: holdarray[i].credentialRequirementRolloverId, rolloverid: holdarray[i].id, hours: sub}
                  rollover.push(rollobj)
              }
            }
          } else {
            obj['Completed'] = 0
            completedArray.push(obj)
          }
        }

        var rolloverArray = []
        for(var i in completedArray){
          var obj = completedArray[i]
          var filter = rollover.filter(p => p.id == obj.id)
          if(filter.length > 0){
            var prevroll = obj.Completed
            var rollval = obj.Completed
            for(var v in filter){
              rollval = rollval + filter[v].hours
            }
            obj.Completed = rollval
            obj['CompletedExplained'] = filter
            obj.CompletedExplained.push({id: obj.id, rolloverid: obj.id, hours: prevroll})
            rolloverArray.push(obj)
          } else {
            rolloverArray.push(obj)
          }
        }
       // console.log(rolloverArray)
       this.requirementHoldObj[item.id] = rolloverArray
       // return rolloverArray
      },
      getCompletedTraining(data){
        var training = this.usersTraining.filter(p => {
          var index = p.trainingRecordCredentials.indexOf(data.id)
          if(index != -1){
            return p
          }
        })
        if(training && training.length > 0){
          var array = []
          for(var t in training){
            var after = moment(training[t].trainingRecordCompletionDate).isAfter(data.start)
            var before = moment(training[t].trainingRecordCompletionDate).isBefore(data.end)
            if(after && before){
              array.push(training[t])
            }
          }
          if(array && array.length > 0){
            var hours = array.map(p => p.trainingRecordHours)
            var amount = 0
            for(var h in hours){
              amount = amount + parseInt(hours[h])
            }
            return amount
          } else {
            return null
          }
        } else {
          return null
        }
      },
      getDateFormat(data){
        if(data){
        return moment(data).format('MM/DD/YY')
        } else {
          return ''
        }
      },
      getCredentials(){
      axios({
        method: 'get',
        url: '/api/v1/training/credential/user/' + this.checkId,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.setupCredentialTable(response.data)
      })
    
    },
    setupCredentialTable(data){
      var date = moment()
      var array = []
      var parents = [... new Set(data.map(p => p.credentialParentName))]
      for(var i in parents){
        var obj = {}
        obj['credentialName'] = parents[i]
        obj['_rowVariant'] = 'info'
        obj['_showDetails'] = false
        array.push(obj)
        var child = data.filter(p => {
           if(p.credentialParentName == parents[i]){
             var start = moment(date).isAfter(p.credentialStartDate)
             var end = moment(date).isBefore(p.credentialEndDate)
         if(start && end){   // if you allow more then one credential requirement duplicate, it fcks up the hours
               return p
             }
           }
        })
        for(var c in child){
          var cobj = child[c]
          cobj['_showDetails'] = true
          array.push(cobj)
        }
      }
        this.checkIfLoaded = true
      this.credentialsData = array
    },
    getCredentialRequirements(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialRequirement = response.data
      })
    },
    getUsersTraining(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingrecord/users/' + this.checkId,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.usersTraining = response.data
      })
    },

    



},

}
</script>

<style scoped>
.credential-table {
  text-align: center;
}
.changeWidth {
  width: 80%;
  margin: 0 auto 0;
  text-align: center;
}
.userPostIcon{
  position: relative;
  margin: 0px 0 -80px 0px;
  width:32px;
  height:32px;
  opacity: 0.3;
  
}
.blogadjust {
  text-align: center;
    width: 80%;
    margin: 0 auto 0;
}
.userPostForms{
  width:80%;
  padding-top: 10px;
  margin: 0 auto 0;
  border-bottom: 1px solid #aaa;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}



#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {
  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
@media screen and (max-width: 768px) {
.userPostIcon{
    display: none;
  
}
}

</style>
