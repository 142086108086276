<template>
<div id="homelanding" v-if="stationsData && apparatusData">
    <b-navbar type="dark" variant="dark" v-if="!tvView">
      <b-nav-form>   
      <b-navbar-brand class="bootstrapTable d-none d-md-block">Roster Date:</b-navbar-brand>
       <b-btn variant="info" @click="moveDateRange(true)">Prev</b-btn>
       <b-form-input type="date" class="d-none d-md-block" v-model="selectedDate" @change="emitSelectedDate()" />
       <b-form-input type="date" style="min-height: 43px" class="d-md-none w-50" v-model="selectedDate" @change="emitSelectedDate()" />
        <b-btn variant="info" @click="moveDateRange(false)">Next</b-btn>
        <div style="width: 250px" class="ml-2 d-none d-md-block" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
        <multiselect v-model="userSelected" 
        :options="deptContactData" 
        @input="getUserProfile"
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
        <div class="pl-1 d-md-none w-50"  v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
        <multiselect v-model="userSelected" 
        :options="deptContactData" 
        @input="getUserProfile"
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
        <b-btn class="mx-2 d-none d-md-block" @click="addNewPayrollMod" variant="info">Add New Payroll Mod</b-btn>
        <b-btn class="d-md-none mt-2 mr-2" @click="addNewPayrollMod" variant="info">Add New Payroll Mod</b-btn>
        <b-btn class="mr-2 d-none d-lg-block" :variant="printOn ? 'success' : 'danger'" @click="printOn = !printOn">{{ printOn ? 'Turn Off Print View' : 'Turn On Print View' }}</b-btn>
         <b-btn v-if="printOn" class="mr-2 d-none d-lg-block" variant="primary" @click="executePrintJS" >Print</b-btn>
         <b-btn v-if="printOn" class="mr-2 d-none d-lg-block" variant="info" @click="emailAddressMenu = !emailAddressMenu, userSelectedEmail.push(userData)">Email</b-btn>
        <b-btn variant="secondary" class="ml-2" @click="showFilter = !showFilter">Filter</b-btn>
        <b-btn  v-if="showFilter" variant="danger" class="ml-2" @click="resetFilterData">Reset Filter</b-btn>
              </b-nav-form>
    </b-navbar>
    <b-navbar v-if="showFilter">
        <b-form-checkbox class="mr-3" v-model="onDutyFilter">On Duty Only</b-form-checkbox>
        <b-form-select class="ml-2" v-model="filterByRank" multiple :options="ranksReturned" />
        <b-form-select class="ml-2" v-model="filterBySpecial" multiple :options="specialtiesReturned" />
        <b-form-select class="ml-2" v-model="filterModType" multiple :options="modTypesReturn" />
    </b-navbar>
    <b-btn v-if="vacancyFilter" block variant="danger" @click="fillVacancyView(false)">Cancel Vacancy Fill </b-btn>
    <b-container v-if="emailAddressMenu" class="py-2"><b-row>
      <b-col cols="*" class="font-weight-bold pt-2">Select Users to Email</b-col>
      <b-col>
    <multiselect 
         v-model="userSelectedEmail" 
        :options="deptContactData"
        multiple
        :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
                  </b-col>
        <b-col cols="*"><b-btn variant="success" size="sm" class="mt-2" :disabled="!(userSelectedEmail && userSelectedEmail.length > 0)" @click="executePrintJSEmail">Send</b-btn></b-col>          
        </b-row></b-container>
     <b-card v-if="tvView" v-for="parent in rosterBuildOut" :key="parent.id" no-body>
        <b-card class="mb-2" no-body v-show="parent.showStation" v-for="station in parent.stations.filter(p => p.id == stationVar)" :key="station.id">
            <b-card no-body v-for="roster in station.rosterSpots" :key="roster.id">
             <h5 class="text-left mt-2" :class="!tvView ?  'pl-5' : 'pl-2 font-weight-bold'" v-if="editNameId != roster.id">{{ roster.rosterName ? roster.rosterName : roster.apparatusName }} <b-btn  v-if="!printOn && !tvView" @click="editNameId = roster.id, roster.rosterName ? holdName = roster.rosterName : holdName = roster.apparatusName" variant="light" size="sm">Edit</b-btn></h5>
             <b-row v-if="editNameId == roster.id">
               <b-col><b-form-input v-model="holdName" /></b-col>
              <b-col align="left" class="mt-1"><b-btn size="sm" variant="info" :disabled="holdName == roster.apparatusName" @click="updateRosterName(editNameId, holdName)">Save</b-btn>
              <b-btn size="sm" variant="danger" v-if="holdName == roster.apparatusName" @click="editNameId = '', holdName = ''">Cancel</b-btn>
              <b-btn size="sm" variant="danger" v-if="roster.rosterName" @click="updateRosterName(editNameId, null)">Return name to {{ roster.apparatusName }}</b-btn></b-col>
              </b-row>
             <b-container fluid>
               <rosterSpot v-if="rosterReset" :tvView="tvView" :spot="roster" :vacancyFilter="vacancyFilter"  :vacancyFilterUser="vacancyFilterUser" :onDutyFilter="onDutyFilter" :printOn="printOn" :payaddativemods="payaddativemods" :selectedDate="selectedDate" :profilesPreDateFiltered="profilesPreDateFiltered" :profiles="profilesFilteredByDate" :kellyDayObject="kellyDayObject" :payrollCodesData="payrollCodesData" :mods="modifications" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" />
             </b-container>
          </b-card>
          </b-card>
    </b-card>
    <b-card no-body v-if="!tvView && rosterReset">
      <b-tabs card>
        <b-tab title="All" @click="printTabId = 'print-allview'" id="print-allview" class="mobileView" v-if="stationsData && rosterData && rosterBuildOut" :class="printReportOn ? 'printReport' : ''">
      <b-container v-if="printOn" class="mb-4" fluid>
      <b-row><b-col cols="5">
      <img :src="departmentLogoForPrint"  v-if="userData.role != 'admin'" /></b-col>
      <b-col cols="7" class="pt-2">
        <h3>SHIFT COMMAND ROSTER</h3>
        <b-row><b-col>
          <h4><span class="font-weight-bold">Duty Shift: </span><span>{{ currentShift }}</span></h4>
          </b-col>
          <b-col>
               <h4><span class="font-weight-bold">Date: </span><span>{{ getTodaysDateForPrint }}</span></h4>
            </b-col>
            <b-col>
                 <h4><span class="font-weight-bold">Time: </span><span>{{ getCurrentTimeForPrint }}</span></h4>
              </b-col>
              </b-row>
              <em>Completed by {{ userData.deptRank }} {{ userData.firstName }} {{ userData.lastName }}</em>
        </b-col>
        </b-row>
      </b-container>
    <b-card v-for="parent in rosterBuildOut" :key="parent.id" body-class="mobileView" header-tag="header" header-class="my-0 pb-0 pt-1" bg-variant="dark">
       <h4 slot="header" class="clickPointer text-light text-left">{{ parent.stationName }}</h4>
        <b-card class="mb-2" no-body v-show="parent.showStation" v-for="station in parent.stations" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1">
          <h4 slot="header" class="clickPointer text-left">{{ station.stationName }}</h4>
            <b-card no-body v-for="roster in station.rosterSpots" :key="roster.id">
             <h5 class="text-left pl-5 mt-2" v-if="editNameId != roster.id">{{ roster.rosterName ? roster.rosterName : roster.apparatusName }} <b-btn  v-if="!printOn" @click="editNameId = roster.id, roster.rosterName ? holdName = roster.rosterName : holdName = roster.apparatusName" variant="light" size="sm">Edit</b-btn></h5>
             <b-row v-if="editNameId == roster.id">
               <b-col><b-form-input v-model="holdName" /></b-col>
              <b-col align="left" class="mt-1"><b-btn size="sm" variant="info" :disabled="holdName == roster.apparatusName" @click="updateRosterName(editNameId, holdName)">Save</b-btn>
              <b-btn size="sm" variant="danger" v-if="holdName == roster.apparatusName" @click="editNameId = '', holdName = ''">Cancel</b-btn>
              <b-btn size="sm" variant="danger" v-if="roster.rosterName" @click="updateRosterName(editNameId, null)">Return name to {{ roster.apparatusName }}</b-btn></b-col>
              </b-row>
             <b-container fluid>
               <rosterSpot v-if="rosterReset" :filterArray="{filterByRank: filterByRank, filterBySpecial: filterBySpecial, filterModType: filterModType}" :spot="roster" :onDutyFilter="onDutyFilter" :vacancyFilter="vacancyFilter" :vacancyFilterUser="vacancyFilterUser"  :printOn="printOn" :payaddativemods="payaddativemods" :selectedDate="selectedDate" :profiles="profilesFilteredByDate" :profilesPreDateFiltered="profilesPreDateFiltered"  :kellyDayObject="kellyDayObject" :payrollCodesData="payrollCodesData" :mods="modifications" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" />
             </b-container>
          </b-card>
          </b-card>
    </b-card>
    <b-card header-tag="header" header-class="my-0 pb-0 pt-1" bg-variant="dark" v-if="!tvView">
       <h4 slot="header" class="clickPointer text-light text-left">Off Roster</h4>
        <b-card class="mb-2" no-body header-tag="header" header-class="my-0 pb-0 pt-1">
             <rosterSpotOffroster  :spot="offRosterPayrollMods" :filterArray="{filterByRank: filterByRank, filterBySpecial: filterBySpecial, filterModType: filterModType}" :onDutyFilter="onDutyFilter" :printOn="printOn" :payaddativemods="payaddativemods" :profilesPreDateFiltered="profilesPreDateFiltered" :selectedDate="selectedDate" :overtimeModification="overtimeModification" :profiles="profilesFilterWithVacancies" :payrollCodesData="payrollCodesData" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" />
            </b-card>
          </b-card>
        <b-container v-if="printOn" fluid class="pt-2">
      <h5>Commander Notes (Info for Print Only)</h5>
          <b-textarea :rows="5" v-model="printNotes" />
      </b-container>
      </b-tab>
    <b-tab v-if="stationsData && rosterParentsData.length > 1 && rosterData && rosterBuildOut" v-for="rparent in rosterParentsData" :key="rparent.id" :title="rparent.stationName" @click="printTabId = 'print-' + rparent.id" :id="'print-' + rparent.id"  :class="printReportOn ? 'printReport' : ''" :active="checkActive(rparent)">
    <b-container v-if="printOn" class="mb-4" fluid>
      <b-row><b-col cols="5">
      <img :src="departmentLogoForPrint" v-if="userData.role != 'admin'" /></b-col>
      <b-col cols="7" class="pt-2">
        <h3>SHIFT COMMAND ROSTER</h3>
        <b-row><b-col>
          <h4><span class="font-weight-bold">Duty Shift: </span><span>{{ currentShift }}</span></h4>
          </b-col>
          <b-col>
               <h4><span class="font-weight-bold">Date: </span><span>{{ getTodaysDateForPrint }}</span></h4>
            </b-col>
            <b-col>
                 <h4><span class="font-weight-bold">Time: </span><span>{{ getCurrentTimeForPrint }}</span></h4>
              </b-col>
              </b-row>
              <em>Completed by {{ userData.deptRank }} {{ userData.firstName }} {{ userData.lastName }}</em>
        </b-col>
        </b-row>
      </b-container>
    <div>
    <b-card v-for="parent in rosterBuildOut.filter(p => p.id == rparent.id)" :key="parent.id" header-tag="header" header-class="my-0 pb-0 pt-1" :bg-variant="!printOn ? 'dark' : ''" >
       <h4 slot="header" class="clickPointer text-left" :class="!printOn ? 'text-light' : ''">{{ parent.stationName }}</h4>
        <b-card class="mb-2" no-body v-show="parent.showStation" v-for="station in parent.stations" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1">
          <h4 slot="header" class="clickPointer text-left">{{ station.stationName }}</h4>
            <b-card no-body v-for="roster in station.rosterSpots" :key="roster.id">
             <h5 class="text-left pl-5 mt-2">{{ roster.rosterName ? roster.rosterName : roster.apparatusName }} <b-btn v-if="!printOn" variant="light" size="sm">Edit</b-btn></h5>
             <b-container fluid>
            <rosterSpot v-if="rosterReset" :spot="roster" :filterArray="{filterByRank: filterByRank, filterBySpecial: filterBySpecial, filterModType: filterModType}" :onDutyFilter="onDutyFilter" :vacancyFilter="vacancyFilter" :vacancyFilterUser="vacancyFilterUser" :profiles="profilesFilteredByDate" :profilesPreDateFiltered="profilesPreDateFiltered" :kellyDayObject="kellyDayObject" :selectedDate="selectedDate" :printOn="printOn" :payrollCodesData="payrollCodesData" :mods="modifications" :payaddativemods="payaddativemods" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" />
             </b-container>
          </b-card>
          </b-card>
    </b-card>
    </div>
        <b-container v-if="printOn" fluid class="pt-2">
           <h5>Commander Notes (Info for Print Only)</h5>
          <b-textarea :rows="5" v-model="printNotes" />
      </b-container>
    </b-tab>
      </b-tabs>
    </b-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import rosterSpot from './rosterspotNew.vue';
import rosterSpotOffroster from './rosterspotOffroster.vue';
import html2canvas from 'html2canvas';
import printJS from 'print-js'
export default {
components: {
  'multiselect' : Multiselect,
  rosterSpot,
  rosterSpotOffroster,
},
props: ['rosterData', 'payrollCodesData', 'rosterProfiles', 'payrollModDailyRosterData', 'selectedDateHold', 'tvView', 'stationVar'],
data() {
    return {
    editNameId: '',
    holdName: '',
    vacancyPositions: [],
    rosterDataHoldArray: [],
    filterByRank: [],
    filterModType: [],
    filterBySpecial: [],
    userSelected: '',
    selectedDate: '',
    showFilter: false,
    onDutyFilter: false,
    vacancyFilter: false,
    vacancyFilterUser: null,
    rosterReset: true,
    printTabId: 'print-allview',
    printOn: false,
    printReportOn: false,
    emailAddressMenu: false,
    userSelectedEmail: [],
    printNotes: '',
    departmentLogoForPrint: '',
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    nonSpotModification: [
       'payaddative',
    ],
    createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
    ],
    fillVacancy: [
      'overtime',
      'roster mod',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'exchange',
      'unpaid leave',
      'roster mod',
      'overtime',
    ],
    filteredSpots: [],
  }
},
created () {
  this.selectedDate = moment().format('YYYY-MM-DD')
  bus.$on('showRosterData', this.showRosterData)
  bus.$on('vacancyFilterUpdate', this.fillVacancyView)
  if(this.tvView){
    this.onDutyFilter = true
  }
 this.onLoadImage(this.siteconfig.staticHost + 'banners/' + this.siteconfig.deptUrlSub + '/banner.png')
 if(this.siteconfig.payroll.kellyDayPattern){
   //console.log(this.kellyDayObject)
 }
  //console.log()
},
beforeDestroy(){
  	
},
watch: {
  selectedDateHold(newData){
    if(newData != this.selectedDate){
      //console.log(newData)
      this.selectedDate = this.selectedDateHold
    }
  },
  onDutyFilter(data){
   //  console.log('WATCHER')
      this.rosterReset = false
      this.$emit('updateSelectedDate', this.selectedDate)
      this.$nextTick(function(){
        this.rosterReset = true
      })
  },
},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  getTodaysDateForPrint(){
  return moment(this.selectedDate, 'YYYY-MM-DD').format('MM/DD/YY')
  },
  getCurrentTimeForPrint(){
    return moment().format('HH:mm')
  },
  modTypesReturn(){
                var array = _.cloneDeep(this.payrollCodesData)
                return _.sortBy([... new Set(array.map(p => p.payrollCodeCategory))])
          },
  ranksReturned(){
                var array = _.cloneDeep(this.deptContactData)
                return _.sortBy([... new Set(array.map(p => p.deptRank))])
  },
  specialtiesReturned(){
                var array = _.cloneDeep(this.deptContactData)
                var specials = []
                for(var i in array){
                  var spec = array[i].deptSpecialties
                  for(var s in spec){
                    var index = specials.indexOf(spec[s])
                    if(index == -1){
                      specials.push(spec[s])
                    }
                  }
                }
            //   console.log(specials)
                return _.sortBy(specials)
  },
  kellyDayObject(){
   // console.log('KELLY PATTERN')
    if(this.siteconfig.payroll.kellyDayPattern){
    var shifts = this.siteconfig.payroll.shifts
    var kellyPattern = this.siteconfig.payroll.kellyDayPattern
    var build = []
    for(var k in kellyPattern){
        for(var s in shifts){
        build.push({shift: shifts[s].shift, kelly: this.siteconfig.payroll.kellyDayPattern.indexOf(kellyPattern[k])})
      }
    }
    var today = moment(this.selectedDate, 'YYYY-MM-DD').clone()
    var startDate = today.clone()
   // console.log(today)
    var subDays = 0
   // console.log('START WHILE')
    while (true) {
      var days = moment(startDate).diff(this.siteconfig.payroll.cycleStartDate, 'days') // 21
      var mathhold = (days / 21)
      if(mathhold == Math.floor(mathhold)){
     //   console.log('BREAK')
        break
      }
      startDate = startDate.subtract(1, 'day')
      subDays++;
    } 
     var start = moment(today).subtract(subDays, 'days') 
     var patternRun = moment(today.clone()).diff(start, 'days')
    // console.log(patternRun)
     var index = build[patternRun]
    // console.log(build) 
     return index
    } return null
  },
  offRosterPayrollMods(){
    var array = this.profilesPreDateFiltered.map(p => p.userId)
    var exchange = this.payrollModDailyRosterData.map(p => p.payrollModExchangeUserId)
    var holdArray = this.payrollModDailyRosterData.filter(p => {
      return p.payrollModRosterId == 'offroster'
             || (array.indexOf(p.payrollModTargetUserId) == -1 && exchange.indexOf(p.payrollModTargetUserId) == -1 && !p.payrollModRosterId)
    })
    return holdArray.filter(p => p.payrollModType != 'overtime availability')
  },
  rosterParentsData(){
           var array = this.rosterData
           var station = _.cloneDeep(this.stationsData)
           var parents = [...new Set(array.map(p => p.stationParent))]
           var parentsObj = parents.map(item => item = station.find(p => p.id == item))
           parentsObj.forEach(item => item['showStation'] = true)
           var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
           return parentsSort
    },
  rosterSpotsFilter(){
          var array = this.rosterData
          var indexArray = this.rosterData.map(p => p.apparatusId)
          var apparatus = this.apparatusData.filter(p => {
            var index = indexArray.indexOf(p.id)
            if(index != -1){
              return p
            }
          })
          return _.orderBy(apparatus, ['apparatusName'], ['asc'])
  },
  overtimeModification(){
      var overtime = this.modifications.filter(p => {
      return p.payrollModType == 'overtime'
    })
    var available = overtime.map(item => item = this.getPayrollModObj(item, item))
    return available
  },
  currentShift(){
              var payroll = this.siteconfig.payroll
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = moment(this.selectedDate).diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
  },
  profilesPreDateFiltered(){
    var array = _.cloneDeep(this.rosterProfiles)
     //console.log(this.rosterProfiles)
    // console.log(this.selectedDate)
     array = array.filter(p => {
      var start = moment(p.rosterAssignmentStart).isSameOrBefore(this.selectedDate, 'd')
      var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate) : true
      if(start == false){
     //   console.log(p)
      }
      if(start && end){
        return p
      }
    })
  //  console.log(array)
    var profiles = array.filter(p => {
        if(p.scheduledShift == this.currentShift){
          return p
        } else if (p.scheduledShift == 'Daily'){
          var index = p.scheduledWorkDays.indexOf(moment(this.selectedDate).format('ddd'))
          if(index != -1){
            return p
          }
        }
    })
 //   console.log('PROFILES')
  //  console.log(profiles)
    return profiles
  },
  profilesFilteredByDate(){ 
    var profiles = this.profilesPreDateFiltered
       // KELLY DAY LOGIC????
       // Adjusts Profiles for Swaps, and Swap Profile
    if(this.kellyDayObject){
      profiles = this.checkForKellyDays(profiles)
    }
    var swaps = this.checkForUserSwaps(profiles)
    if(false){
    console.log('Swaps')
    console.table(swaps)
    }
      // Adjusts PRofiles for Mods (Mods get added on RosterProfile)
    var mods = this.checkForUserMods(swaps)
    if(false){
    console.log('Mods')
    console.log(mods)
    }
    return mods
  },
  profilesFilterWithVacancies(){
    return []
   
  },
  rosterBuildOut(){
    var parents = this.rosterParentsData
    var buildingArray = parents.map(item => {
        var obj = item
        obj['stations'] = this.stationsFromParentsMap(item.id)
        return obj
    })
    if(this.filteredSpots.length > 0){
      var index = this.filteredSpots.map(p => p.rosterId)
      var array = buildingArray.map(p => {
          var obj = _.cloneDeep(p)
          obj.stations = []
          var station = p.stations
          var stationArray = []
          for(var s in station){
          var staObj = station[s]
          var spots = []
          var rosterIds = station[s].rosterSpots
          for(var i in rosterIds){
            if(index.indexOf(rosterIds[i].id) != -1){
              spots.push(rosterIds[i])
            }
          }
          if(spots.length > 0){
            
            staObj.rosterSpots = spots
            stationArray.push(staObj)
          }
          if(stationArray.length > 0){
           
            obj.stations = stationArray
            return obj
          }
          }
      })
      var undArray = array.filter(p => {
          return p != undefined
      })
      return undArray
    } else {
     // console.log(buildingArray)
     return buildingArray
    }
  },
  swapModifications(){
    return this.payrollModDailyRosterData.filter(p => {
      return p.payrollModExchangeUserId != '' && p.payrollModExchangeUserId != null && p.payrollModExchangeUserId != undefined
    })
  },
  payaddativemods(){
    if(this.payrollModDailyRosterData){
    var array = this.payrollModDailyRosterData.filter(p => {
             return p.payrollModType == 'payaddative'})
    return array
    } else {
      return []
    }
  },
  modifications(){
    var array = this.payrollModDailyRosterData.filter(p => {
              var index = this.spotModification.indexOf(p.payrollModType)
              if(index != -1){
                return p
              }
    })
    array = array.map(item => {
        var obj = this.setupPayrollModification(item)
        return obj
    })
    return array
  },
  modificationUsers(){
    var array = this.payrollModDailyRosterData.filter(p => {
              var index = this.spotModification.indexOf(p.payrollModType)
              if(index != -1){
                return p
              }
    })
    return array.map(p => p.payrollModTargetUserId)
  },
  
},
mounted(){

},
methods: {
  resetFilterData(){
    this.filterByRank = []
    this.filterBySpecial = []
    this.filterModType = []
    this.onDutyFilter = false
  },
  checkActive(rparent){
    var index = rparent.stations.map(p => p.stationName).indexOf(this.userData.stationAssignment)
    if(index >= 0){
      this.printTabId = 'print-' + rparent.id
      return true
    }
  },
  fillVacancyView(data){
   if(data){
    // console.log(data)
     this.vacancyFilterUser = {user: data, roster: _.cloneDeep(this.rosterData)}
     this.vacancyFilter = true
   }  else {
     this.vacancyFilter = false
     this.vacancyFilterUser = null
   }

  },
    showRosterData(data){
    this.selectedDate = moment(data.date._d).format('YYYY-MM-DD')
  },
  onLoadImage(url){
      var vm = this
    //  console.log('NEW IMAGE')
    //  console.log('image loaded')
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        vm.departmentLogoForPrint = dataURL // .replace(/^data:image\/(png|jpg);base64,/, "")
      };
    },
  getUserProfile(){
     this.rosterReset = false
     this.rosterDataHoldArray = []
      this.$nextTick(function(){
        this.rosterReset = true
      })
  },
  resetRosterCheck(data){
    if(!data || true){
      this.rosterReset = false
      this.$emit('updateSelectedDate', this.selectedDate)
      this.$nextTick(function(){
        this.rosterReset = true
      })
    }
  },
  executePrintJS(){
   //console.log('EXECUTEPRINTJS')
          console.log(this.printTabId)
      var printId = '#' + this.printTabId
    this.printReportOn = true
      this.$nextTick(function(){
      html2canvas(document.querySelector(printId)).then(canvas => {
       var image = canvas.toDataURL("image/jpeg")
  //     console.log(image)
       return printJS({
              printable: image,
              type: 'image',
              style: 'img { width:1200px !important; }',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
            })
   // 
    });
    this.$nextTick(function(){
        this.printReportOn = false
    })
      })

  },
  executePrintJSEmail(){
        this.printReportOn = true
        var printId = '#' + this.printTabId
        var documentHold = document.querySelector(printId)

        html2canvas(documentHold).then(canvas => {
      /*  var image = canvas.toDataURL("image/jpeg")
        return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              imageStyle: 'margin-bottom:20px;'
            }) */
       var hm = this
        canvas.toBlob(function(blob) {
        hm.postImageAxiosEmail(blob) 
        }); 
      });
      this.$nextTick(function(){
        this.printReportOn = false
      })
    },
    postImageAxiosEmail(result){
        var data = new FormData();
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/staffingrosters/email/' + moment().format('YYYY-MM-DD-HH-MM-SS'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        this.sendEmailData(response.data)

      })
     },
     sendEmailData(url){
       var obj = {
         email: this.userSelectedEmail.map(p => p.email),
         url: url
       }
           axios({
     method: 'post',
     url: '/api/v1/staffing/roster/email',
     data: obj,
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
     }
   }).then( response => {
      this.emailAddressMenu = false
      this.printOn = false
      this.onDutyFilter = false
      this.userSelectedEmail = []
   })

     },
  addNewPayrollMod(){
    var startDate = moment(this.selectedDate, 'YYYY-MM-DD').add(8, 'hours').toISOString()
     var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: '', 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: 24, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '',
                      rosterUpdateData: true, 
                      }
     //  dataObj['rosterupdate'] = true
       if(!(this.userData.role == 'admin' || this.userData.role == 'fireops' || this.userData.role == 'adminchief')){
                    dataObj['payrollModTargetUserId'] = this.userData.id
       }
     //  console.log(dataObj)
                 bus.$emit('updatePayrollMod', dataObj)
      
  },
  emitSelectedDate(){
   // console.error(this.selectedDate)
  // console.log('EMITTED DATE')
    this.$emit('updateSelectedDate', this.selectedDate)
  },
  moveDateRange(boo){
    var rotation = this.siteconfig.payroll.shiftRotation
    if(boo){
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').subtract(rotation, 'days').format('YYYY-MM-DD')
    } else {
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').add(rotation, 'days').format('YYYY-MM-DD')
    }
    this.emitSelectedDate()
  },
  getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName + ' (' + user.deptRank + ') #' + user.deptSpecialties
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getPayrollCode(code){
      var obj = this.payrollCodesData.find(p => p.payrollCode == code)
      if(obj){
        return obj.id
      } else {
        return 'Code Not Found'
      }
    },
    getFixedSwap(array, mods){
      if(false){
      console.log('Get Fixed Swap')
      console.log(array)
      console.log(mods)
      }
       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
          var mod = mods.filter(p => moment(p.startTime).isSameOrAfter(array[a].startTime) && moment(p.endTime).isSameOrBefore(array[a].endTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  objTwo.startTime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  array.push(objTwo)
                  array[a] = obj
                }
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isAfter(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
    checkForKellyDays(profiles){
      //console.log('CHECK FOR KELLY DAYS')
      //console.log(profiles)
      var kelly = _.cloneDeep(profiles)
      kelly = kelly.map(item => {
          var obj = item
          if(item.scheduledKellyCycleNumber == this.kellyDayObject.kelly && item.scheduledShift == this.kellyDayObject.shift){
          obj['kellyDay'] = true
          }
          return obj
      })
      /** var filter = kelly.filter(p => p.kellyDay)
      var users = filter.map(p => {
            var obj = p
            obj['user'] = this.deptContactData.find(x => x.id == p.userId)
            return obj
      })
      var justUsers = users.map(p => p.user)
      console.log(justUsers)
      var justNames = justUsers.map(item => {
          return item.firstName + ' ' + item.lastName
      })
      console.log(justNames) */
      return kelly
    },
    checkForUserSwaps(users){
    var profiles = []
    for(var u in users){
      var obj = users[u]
      obj['startTime'] = moment(this.selectedDate + 'T' + obj.rosterStartTime, "YYYY-MM-DDTHH:mm:ss").toISOString()
      obj['endTime'] = moment(obj.startTime).add(obj.rosterShiftLength, 'hours').toISOString()
      var array = this.swapModifications.filter(p => p.payrollModTargetUserId == users[u].userId)
      if(array.length > 0){
       var profile = [obj]
       var mods = []
       for(var s in array){
         if(array[s]){ //array[s].payrollModExchangeApproved
         var swap = _.cloneDeep(users[u])
         swap['id'] = obj.id + array[s].id
         swap['payrollId'] = obj.id
         swap['payrollSwap'] = this.getPayrollCode('EXON')
         swap['payrollModData'] = array[s]
         swap['payrollCode'] = array[s].payrollModCode
         swap['startTime'] = array[s].payrollModDatetime
         swap['endTime'] = moment(swap.startTime).add(array[s].payrollModHours, 'hours').toISOString()
         swap['targetUser'] = obj.userId
         swap['userId'] = array[s].payrollModExchangeUserId
         swap['payrollModStartDateTime'] = array[s].payrollModDatetime
         swap['rosterShiftLength'] = Number.parseFloat(array[s].payrollModHours)
         mods.push(swap)
         }
       }
       var fixedProfile = this.getFixedSwap(profile, mods)
        for(var f in fixedProfile){
          profiles.push(fixedProfile[f])
        }
        for(var m in mods){
          profiles.push(mods[m])
        }
    } else {
      profiles.push(obj)
    }
    }
    return profiles
  },
  checkForUserMods(users){
    var profiles = []
    for(var u in users){
     var obj = _.cloneDeep(users[u])
     var array = this.modifications.filter(p => p.userId == users[u].userId)
     if(users[u].kellyDay){
        var roster = [users[u]]
        var payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'KD')
        if(!payrollCode){
          var payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'OnDuty')
        }
        var startTime = roster[0].startTime
        var endTime = moment(roster[0].startTime).add(24, 'hours').toISOString()
        var newObj = {
                        payrollModType: payrollCode ? payrollCode.payrollCodeCategory : 'Missing Mod',
                        payrollModCode: payrollCode ? payrollCode.id : 'Missing Code',
                        payrollModTargetUserId: roster[0].userId,
                        payrollModDatetime: startTime,
                        payrollModStartDatetime: startTime,
                        payrollModHours: 24,
                      //  payrollModAuthorId: "system-kd",
                      //  payrollModApprovalUserId: "system-kd",
                        payrollModApproved: true,
                        payrollModNote: "",
                        startTime: startTime,
                        endTime: endTime,
                       // systemCreated: true
              }
        //console.log(newObj)
        array.push(newObj)
     }
      if(array.length > 0){
       array = _.orderBy(array, function(e){ return Number.parseInt(e.rosterShiftLength)}, ['desc'])
       var profile = [obj]
       var mods = []
       for(var s in array){
         if(array[s]){ 
         var swap = _.cloneDeep(array[s])
         mods.push(swap)
         }
       }
       var fixedProfile = this.getFixedSwap(profile, mods)
        for(var f in fixedProfile){
          profiles.push(fixedProfile[f])
        }
    } else {
      profiles.push(users[u])
    }
    }
    return profiles
  },
  updateRosterName(id, data){
    axios({
     method: 'patch',
     url: '/api/v1/staffing/roster/name/' + id,
     data: {rosterName: data},
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.editNameId = ''
     this.holdItemId = ''
     bus.$emit('getRoster')
   })

    },
    setupPayrollModification(array){
         var allMods = _.cloneDeep(this.payrollModDailyRosterData)
         var vacancyFill = array.payrollModRosterPosition && array.payrollModRosterId ? true : false
         var position = null
         var rosterid = null
         if(!vacancyFill){
           var profile = this.profilesPreDateFiltered.find(p => p.userId == array.payrollModTargetUserId)

           if(profile){
             position = profile.rosterPosition ? profile.rosterPosition : null
             rosterid = profile.rosterId ? profile.rosterId : null
           } else {
             var mod = allMods.find(p => p.payrollModExchangeUserId == array.payrollModTargetUserId)
             if(mod){
               profile = this.profilesPreDateFiltered.find(p => p.userId == mod.payrollModTargetUserId)
               if(profile){
                position = profile.rosterPosition ? profile.rosterPosition : null
                rosterid = profile.rosterId ? profile.rosterId : null
             }
           }
          }
         }
         var swap = {}
         swap['id'] = array.id
         swap['vacancyFill'] = vacancyFill
         swap['payrollId'] = array.payrollModTargetUserId
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : rosterid ? rosterid : ''
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : position ? position : null
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['userId'] = array.payrollModTargetUserId
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
    },
  getPayrollModObj(array, users){
         var index = this.createVacancy.indexOf(array.payrollModType)
         var index2 = this.fillVacancy.indexOf(array.payrollModType)
         var oldRosterId = users.rosterId
         var swap = _.cloneDeep(users)
         swap['id'] = users.id + array.id
         swap['payrollId'] = users.id
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : users.rosterId
         swap['vacancyTest'] = index2 == -1 ? true : false
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : users.rosterPosition
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
  },
  // BUILDS SPOTS IN THE INTIAL ROSTER
    stationsFromParentsMap(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])
      var stationMap = stationsSort.map(item => {
        var obj = item
        obj['rosterSpots'] = this.returnRosterSpots(id, item.id)
        return obj
      })
      //console.log(stationMap)
      return stationMap
    },
    stationsFromParents(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort
    },
    returnRosterSpots(pid, sid){
      var array = this.rosterData.filter(p => p.stationParent == pid && p.stationId == sid)
      array.forEach(item => item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId) ? this.apparatusData.find(p => p.id == item.apparatusId).apparatusName : 'Unknown')
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])
      //console.log(apparatusSort) 
      return apparatusSort
    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.printReport {
  margin: 0;
  padding: 0;
  top:0;
  position:fixed;
  width: 1200px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
  
}
@media only screen and (max-width: 600px){
  .mobileView {
    padding: 0;
  }
}

</style>
