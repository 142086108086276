<template>

    <b-container fluid>
   <b-card class="pb-0" :border-variant="variant != undefined ? variant: ''">
    <div v-for="(item, index) in filters" :key="index">
    <multiselect v-if="item.type == 'single'"  v-model="holdValues[item.name]" :options="getOptions(item.name)" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" :placeholder="'Filter by ' + getStringValue(item.name)">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
    </multiselect>
    <b-card v-if="item.type == 'multi'" no-body class="p-1"> 
    <multiselect  v-model="holdValues[item.name]" :options="getOptionsMulti(item.name)" :multiple="true" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" :placeholder="'Filter by ' + getStringValue(item.name)">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
    </multiselect>
    <b-form-group class="mt-2">
      <b-form-radio-group v-model="selected"
                          :options="options"
                          name="radioInline">
      </b-form-radio-group>
    </b-form-group>

    </b-card>
   </div>
    <b-btn align="right" class="mt-3" :variant="variant != undefined ? 'outline-' + variant : 'outline-primary'" @click="addValuesToParent">Add {{ holdArray.length == getOriginalLength(dataArray) ? 'All' : holdArray.length }} Values</b-btn>
   </b-card>
    </b-container>

</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
    export default {
      components:{
          'multiselect' : Multiselect,
      },
       props: ['dataArray','filters', 'variant'],
       data () {
           return {
               holdValues: {},
               selected: 'and',
               options: [
                    { text: 'Exact Match', value: 'and' },
                    { text: 'Match Any', value: 'or' },
                ]

           }
       },
       created () {

       },
       computed: {
           ...mapState([
               'deptContactData',
               'departmentRanks',
               'departmentSpecialties',
               'stationsData',
               'apparatusData',
               'shiftsData'
           ]),
           holdArray(){
               var data = this[this.dataArray]
               var keys = Object.keys(this.holdValues)
               var array = data
               for(var i in keys){
                   var type = this.filters.find(p => p.name == keys[i])
                   var values = this.holdValues[keys[i]]
                   if(type.type && type.type == 'single'){
                   var array = array.filter(x => {
                           var index = values.indexOf(x[keys[i]])
                           if(index >= 0){
                               return x
                           }
                        
                       }) 
                   } else if(type.type && type.type == 'multi') {
                   if(this.selected == 'and'){
                           var array = array.filter(x => {
                           var userVal = x[keys[i]]
                           var holdIndex = true
                           for(var v in values){
                            var index = userVal.indexOf(values[v])
                            if(index < 0){
                                holdIndex = false
                            }
                           
                           }
                           if(holdIndex == true){
                               return x
                           }

                        
                       }) 

                   } else if (this.selected == 'or') {
                           var array = array.filter(x => {
                           var userVal = x[keys[i]]
                           for(var u in userVal){
                            var index = values.indexOf(userVal[u])
                                    if(index >= 0){
                                        return x
                                    }
                           }

                        
                       }) 
                   }
                }
               }
               return array
           }
           
       }, 
       methods: {
           getStringValue(stringValue){
               var result = stringValue.replace( /([A-Z])/g, " $1" );
                return result.charAt(0).toUpperCase() + result.slice(1)
           },
           getOriginalLength(data){
               return this[data].length
           },
           addValuesToParent(){
               this.$emit('AddValues', this.holdArray)
           },
           getOptions(item){
               var holdArray = this.dataArray
               var array = this[holdArray]
               var data = [... new Set(array.map(p => p[item]))]
               var data = data.filter(p => {
                   if(p){
                       return p
                   }
               })
               var sorted = _.sortBy(data)
               
               return sorted
               /* var arrayData = []
               for(var i in sorted){
                   arrayData.push(name:)
               } */
           },
           getOptionsMulti(item){
               var holdArray = this.dataArray
               var array = this[holdArray]
               var data = [... new Set(array.map(p => p[item]))]
               var data = _.flatten(data)
               var data = _.uniq(data)
               var data = data.filter(p => {
                   if(p){
                       return p
                   }
               })
               var sorted = _.sortBy(data)             
               return sorted
           }

       }
}
</script>
<style :src="'./customcss/vue-multiselect.min.css'"></style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>

</style>
