<template>
  <div class="fullScreenSetup" v-if="siteconfig.demoIcon">
    <b-container fluid class="m-0 p-0" v-if="siteconfig.demoIcon">
      <b-row class="mt-5">
        <b-col><img @click="videoplay = null" class="logoHeader" src="/persite/demo/logo.svg"></b-col>
      </b-row>
      <b-btn class="closeVideoButton" v-if="videoplay" variant="outline-danger" @click="videoplay = null">Return to Options</b-btn>
      <video class="videoClass" v-if="videoplay" autoplay="true" muted="true">
        <source :src="'/persite/demo/' + videoplay + '.mp4'" type="video/mp4">
    </video>
    <b-row v-if="!videoplay" class="row-margin-up">
      <b-col cols="4" class="pr-5">
        <em v-if="!videoplay">Click Icon for Video</em>
        <b-row v-for="icons in iconlist1" class="mt-3">
          <b-col>
              <img  class="imageWide" :src="'/persite/demo/' + icons.img + '.png'" @click="videoplay = icons.video">
              <h6 class="font-weight-bold">{{ icons.text }}</h6>
            </b-col>
        </b-row>
      </b-col>
      <b-col >
        <h3 class="mt-5 pt-5" v-if="!showConfirmationMessage">For More Information</h3>
        <h3 class="mt-5 pt-5 text-success" v-if="showConfirmationMessage">Submitted!</h3> 
        <b-container>
        <b-form-group
            id="fieldset-1"
            label="First Name"
            label-for="input-1"
            label-class="label-class"
            label-align="left"
            :state="form.firstname == '' ? false : true"
          >
            <b-form-input id="input-1" v-model="form.firstname" :state="form.firstname == '' ? false : true" ></b-form-input>
                      </b-form-group>
                    <b-form-group
            id="fieldset-2"
            label="Last Name"
            label-for="input-6"
                        label-class="label-class"
            label-align="left"
               :state="form.lastname == '' ? false : true"
          >
            <b-form-input id="input-6" v-model="form.lastname" :state="form.lastname == '' ? false : true" ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-2"
            label="Email Address"
            label-for="input-2"
                        label-class="label-class"
            label-align="left"
            :state="form.email == '' ? false : true"
          >
            <b-form-input id="input-2" v-model="form.email" :state="form.email == '' ? false : true" ></b-form-input>
          </b-form-group>
                    <b-form-group
            id="fieldset-3"
            label="Department / Agency Name"
            label-for="input-3"
                        label-class="label-class"
            label-align="left"
            :state="form.dept == '' ? false : true"
          >
            <b-form-input id="input-3" v-model="form.dept" :state="form.dept == '' ? false : true" ></b-form-input>
          </b-form-group>
                    <b-form-group
            id="fieldset-2"
            label="Current Position / Rank"
            label-for="input-4"
                        label-class="label-class"
            label-align="left"
          >
            <b-form-input id="input-2" v-model="form.position" ></b-form-input>
          </b-form-group>
                    <b-form-group
            id="fieldset-2"
            label="Contact Number"
            label-for="input-5"
                        label-class="label-class"
            label-align="left"
          >
            <b-form-input id="input-5" type="text" v-model="form.phone"></b-form-input>
          </b-form-group>
          <b-btn class="mt-2" block variant="primary" v-if="!(form.phone == '2003' || form.phone == '0401')" :disabled="form.name == '' || form.email == '' || form.dept == ''" @click="submitNewForm">Submit Info</b-btn>
           <b-btn @click="getPageRefresh" v-if="form.phone == '0401'" block variant="info">Refresh</b-btn>
           <b-btn to="/" v-if="form.phone == '2003'" block variant="info">Close Ipad Demo</b-btn>
        </b-container>
      </b-col>
      <b-col  cols="4" class="pl-5">
        <em v-if="!videoplay">Click Icon for Video</em>
        <b-row v-for="icons in iconlist2" class="mt-3">
          <b-col>
              <img  class="imageWide" :src="'/persite/demo/' + icons.img + '.png'" @click="videoplay = icons.video">
              <h6 class="font-weight-bold">{{ icons.text }}</h6>
            </b-col>
        </b-row>
      </b-col>
    </b-row>

    </b-container>
 </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import tutData from '../tracking/deptTutorialsdata.vue'
import tutAdmin from '../admin/adminTutorials'
export default {
props:[],
components:{
  'tutdata' : tutData,
  tutAdmin
},
  data () {
    return {
      showAdmin: false,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        dept: '',
        position: '',
        phone: '',
        deptSize: '',
      },
      search: '',
      tutorials: [],
      selectedVideoId: null,
      viewTutorialInfo: {},
      previousTutorialData: {},
      videoplay: null,
      showConfirmationMessage: false,
      iconlist1: [{img: 'staffing', text: 'Staffing Solutions', video: 'Staffing'}, 
                  {img: 'pre-plans', text: 'Pre-Plans / Inspections', video: 'Preplans'},
                  {img: 'training', text: 'Training Tracking', video: 'Training'},
                  {img: 'narcs', text: 'Narcotic Management', video: 'Narcs'},
                  {img: 'calendar', text: 'Custom Calendars', video: 'Calendars'}
                  ],
      iconlist2: [{img: 'truck-checks', text: 'Apparatus Checks', video: 'TruckChecks'}, 
                  {img: 'logbook', text: 'Station Logbooks', video: 'Logbook'},
                  {img: 'supply-chain', text: 'Supply Chain Management', video: 'SupplyChain'},
                  {img: 'assets', text: 'Asset Management', video: 'Assets'},
                  {img: 'repairs', text: 'Maintenance Requests', video: 'Repairs'}
                  ]
    }
  },
  created(){
  },
  computed:{
    ...mapState([
      'departmentTutorials',
      'userData',
      'siteconfig'
    ]),
    currentVideoSelected(){
      if(this.selectedVideoId){
       return this.departmentTutorialsSort.find(p => p.id == this.selectedVideoId)
      } else {
        return this.departmentTutorialsSort[0]
      }
    },
    departmentTutorialsSort(){
      return _.orderBy(this.departmentTutorials, ['sortOrder'], ['asc']) 
    },
           tutorialParents(){
         var array = [... new Set(this.tutorials.map(p => p.tutorialParent))]
         var order = _.sortBy(array)
         if(order[0] && !this.viewTutorialInfo.parent){
           this.viewTutorialInfo['parent'] = order[0]
         }
         return order
         
       },
       currentChildData(){
      //   console.log(this.viewTutorialInfo)
         if(this.viewTutorialInfo){
           var view = this.viewTutorialInfo
           var array = this.tutorials.filter(p => {
             return p.tutorialParent == view.parent && p.tutorialSub == view.child
           })
           return _.orderBy(array, ['tutorialSortOrder'], ['asc'])
           } else {
             return []
           }
         }
  },
   methods:{
    getPageRefresh(){
      location.reload(true);
    },
     videoLinkSetup(data){
       var baselink = 'https://player.vimeo.com/video/'
       var initial = data.split('.com/')
       var number = initial[1]
       return baselink + number
     },
     showConfirmationMessageReset(){
          var vm = this
          setTimeout(function () { 
            vm.showConfirmationMessage = false }, 4000)
     },
     submitNewForm(){
       var sub = this.form
  var data = { properties: [
    {
      "property": "email",
      "value": sub.email
    },
    {
      "property": "firstname",
      "value": sub.firstname
    },
    {
      "property": "lastname",
      "value": sub.lastname
    },
    {
      "property": "company",
      "value": sub.dept
    },
    {
      "property": "phone",
      "value": sub.phone
    },
    {
      "property": "jobtitle",
      "value": sub.position
    },
    {
      "property": "industry",
      "value": "FRE2020"
    }
    ] }
    axios({
            method: "post",
            url: '/api/v1/hubspotsub',
            data: data,
            headers: {
              "Content-Type": "application/json",
            }
          }).then(response => {
      this.form = {
        firstname: '',
        lastname: '',
        email: '',
        dept: '',
        position: '',
        phone: '',
        deptSize: '',
            } 
          });
          this.showConfirmationMessage = true
          this.showConfirmationMessageReset()
        },
                getChild(parent){
          var array = this.tutorials.filter(p => {
            return p.tutorialParent == parent
          }) 
          var child = [...new Set(array.map(p => p.tutorialSub))]
          var order = _.sortBy(child)
          if(this.viewTutorialInfo.parent && this.viewTutorialInfo.parent == parent && !this.viewTutorialInfo.child && order[0]){
            this.viewTutorialInfo['child'] = order[0]
          }
          return order
        },
        getVideoId(data){
            data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
            if (RegExp.$3.indexOf('youtu') > -1) {
              return 'https://www.youtube.com/embed/' + RegExp.$6
              } else if (RegExp.$3.indexOf('vimeo') > -1) {
              return 'https://player.vimeo.com/video/' + RegExp.$6
              }
        /*
              var videoLink = data.split('=')
                if(videoLink.length == 1){
                  var videoLink = data.split('.be/')
                }
              return videoLink[1] */
          },

  },
}
</script>
<style>
.row-margin-up {
  margin-top: -45px;
}
.label-class {
  font-weight: bold;
  margin-left: 20px !important;
  margin-bottom: 1px !important;
}
</style>
<style scoped>
.closeVideoButton {
      position: absolute;
    top: 66px;
    right: 50px;
}
.wrapClass {
    white-space:normal !important;
    word-wrap: break-word; 
    cursor: pointer;
    color: #17a2b8 !important;

}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.videoClass {
  width: 100%;
  margin-top: 20px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.logoHeader {
    width: 400px;
  height: auto;
}
img.imageWide {
  width: 80px;
  height: auto;
}
.fullScreenSetup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8000;
    background-color: #fff;
    margin-top: -20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
