<template>
  <div>
  <div class="blogBodyToggle" @click="showModal = !showModal">
  <div v-if="deptContactData.length > 0"><img v-if="blog.blogAuthorId" class="blog-img" :src="imageSrcUrl(blog.blogAuthorId)" /></div>
  <div class="pictureHouse" v-if="blog.title">
  <h5 class="blogTitle">{{ blog.title }} </h5>
  <span class="blogSubData">by {{ blog.blogAuthor }}</span>
  <p class="blogSubData">{{ blogTimeAdjust(blog.createdAt) }} | {{ blog.blogType }}</p>
  <p class="blogSubData"></p>
  </div>
</div>
<b-modal ok-only v-model="showModal" size="lg" :title="blog.title" v-if="showModal">
  <b-card >
  <div><img v-if="blog.blogAuthorId" class="blog-img" :src="imageSrcUrl(blog.blogAuthorId)" /></div>
  <div class="pictureHouse" v-if="blog.title">
  <span class="blogSubData"> {{ blog.blogAuthor }} | {{ blog.blogType }}</span>
  <h6 class="blogSubData">{{ blogTimeAdjust(blog.createdAt) }}</h6>
  </div>
   <span class="rightAlign" v-if="blog.blogAuthorId == userData.id || userData.role == 'admin' || userData.role == 'admin chief'"><b-btn variant="danger" @click="deleteBlog(blog.id)" size="sm">Delete</b-btn></span>
  </b-card>
  <b-card bg-variant="light">
  <div class="blogBody" v-if="blog.blogBody">
  <div v-html="blog.blogBody"></div></div>
  <div v-if="blog.blogPicture.length > 0">
    <b-carousel id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                  controls
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template v-for="(pic, index) in blog.blogPicture">
                        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" >
                            
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
  </div>
  <div v-if="false"><div v-for="img in blogFile" :key="img.index"><a target="_blank" :href="imageSrcUrl(img.url)">{{ img.name }}</a></div></div>
  <div v-if="showModal">
  <div v-if="blog.blogVideo.length > 0">
        <b-carousel id="carousel2"
                                 style="text-shadow: 1px 1px 2px #333;"
                                
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide2"
                               >
                        <template  v-for="(video, index) in blog.blogVideo">
                        <b-carousel-slide :key="index">
                            <div slot="img" class="videoWrapper">
                              <iframe  v-if="video" class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
 </div>
       <b-card v-if="showFile">
      <b-btn variant="primary" @click="showFile = false, iframePdf = ''">Hide Form</b-btn>
      <b-btn variant="info" :href="iframePdf" target="_blank">Open in New Tab (mobile)</b-btn>
      <b-embed v-show="true" :src="iframePdf" />
      </b-card>
       <b-table v-if="blog.blogFile.length > 0" stacked="md"  hover striped :items="blog.blogFile">
                 <template slot="url" slot-scope="data">
          <b-btn class="m-0" size="sm" variant="link" @click="viewFileIframe(data.item.url)">view</b-btn>
        </template>
       </b-table>
  </div>
  </b-card>
</b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { bus } from '../../../main.js'
export default {
props:["blog", "calendarEvents"],
components:{

},
  data () {
    return {
        showModal: false,
        search:"",
        slide: 0,
        slide2: 0,
        showFile: false,
        iframePdf: '',
    }
  },
    computed:{
      ...mapState([
        'deptContactData',
        'userData',
        'getSessionData',
        'siteconfig'
      ]),
    filteredEvents:function(){
      return this.calendarEvents.filter((event) => {
        return moment(event.field_date_of_event).format('MM/DD/YYYY').match(this.filterDate);
      })
    }
  },
   methods:{
     deleteBlog(id){
     axios({
      method: 'delete',
      url: '/api/v1/content/blog/' + id,
      data: {
/*
          'title': this.itemShort, 
          'blogDate': this.formatedDateExport, 
          'blogBody': this.detailedDescription, 
          'blogType': this.blogTypeSelected, 
          'blogTypeParent': this.blogTypeSelectedParent, 
          'blogPicture': this.fileUploadArray, 
          'blogVideo': this.videoLinkArray, 
          'blogAuthor': this.blogAuthor.firstName + ' ' + this.blogAuthor.lastName, 
          'blogAuthorId': this.blogAuthor.id
*/
            }, 
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json', 
      }
    }).then( response => {
          var token = this.getSessionData
      var headers = { headers: { 
          'content-type': 'application/json', 
          'Authorization': token,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
       var payload = { id: this.userData.id, headers: headers }
      this.$store.dispatch('LOAD_DEPT_BLOG_EXP', payload)
      })
       bus.$emit('refreshUserPosts')
       
     },
      userPhotoLink(fileName){
        if(fileName.charAt(0) == '/'){
      return this.siteconfig.deptStore + fileName.replace('/', '')
        } else {
          return  this.siteconfig.deptStore + fileName
        }
      },
     showModalToggle(){
       if(this.showModal == false){
         document.body.class = 'modal-open';
       }
       else{
         document.body.classList.remove('modal-open');
       }
       this.showModal = !this.showModal
     },
    imageSrcUrl(data){
      var user = this.deptContactData.filter( p => 
      { return p.id == data})
      if(user != undefined && user.length > 0 && user[0].picture){
      var picture = this.userPhotoLink(user[0].picture)
      return picture
      } else {
        return this.siteconfig.deptStore + 'files/default_images/user.png'
      }
    },
    viewFileIframe(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              this.iframePdf = response.data.signedRequest
              this.showFile = true

       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
    blogTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY')
    },
       getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
      },
    attachedImages(data){
      return data.split(',')
    }
  },

  created(){

  },

}
</script>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 70px;
width: 70px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {

  color: red;
}
span.blogSubData {
  font-size: 16px;
  color: #444;
}
p.blogSubData {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
</style>
