<template>
  <div>
    <div v-if="logbook">

      <h5 @click="toggleShow(logbook)" id="logbookTitle" class="titleBodyWrap" :class="logbookEdit.logbookShift" >{{ logbookEdit.title }}</h5>

      <div v-if="show" class="show-background">
                <b-container class="headerContainer" :class='getShiftColor(logbookEdit.logbookShift) + "-header"'>
                <b-row>
                  <b-col align="left">
                    <h3 v-if="!showEditView && logbookEdit.logbookAuthorId == userData.id">{{ logbookEdit.logbookStation }}</h3>
              <multiselect v-if="showEditView && logbookEdit.logbookAuthorId == userData.id" v-model="stationSelectValue" :options="filterStationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please select a Station" label="stationName" track-by="stationName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
                </multiselect>
                  </b-col>
                  <b-col align="right">
                         <h5>{{ logbookEdit.logbookAuthor }}</h5>
                         </b-col>

                </b-row>
                </b-container>
        <b-container  v-if="editPermissionCheck(logbook)">
        <b-row>
          <b-col align="left">
            <b-btn v-show="showEditView" v-if="logbookEdit.logbookAuthorId == userData.id" variant="danger" size="sm" align="left" @click.stop="deleteLogbook(logbook)">Delete Logbook</b-btn>
            
          </b-col>
          <b-col align="right">
            <b-btn v-if="!showEditView" size="sm" @click="switchEditView" variant="outline-primary" title="Edit Form">	&#x270e;</b-btn>
              <b-btn v-if="showEditView"  size="sm" @click="switchEditView" variant="outline-danger">X</b-btn>
      </b-col></b-row>
      </b-container>
      <div>       
      <b-container v-if="check.length > 0">
        <h5 class="sub-section-heading" :class="{toggleNo: !vehicleTableShow}" @click="vehicleTableShow = !vehicleTableShow">Vehicle Checks Reference <b-badge variant="light">{{ check.length }}</b-badge></h5>

          <b-table v-show="vehicleTableShow" :responsive="true" striped hover :items="vehicleCheckTableOptions(check)" />

      </b-container>
      <b-container class="logbookPassDownContainer" v-if="dailyNarcCheck.length > 0">
        <h5 class="sub-section-heading" :class="{toggleNo: !narcTableShow}" @click="narcTableShow = !narcTableShow">Narc Entry Logs <b-badge variant="light">{{ narcTableOptions(dailyNarcCheck).length }}</b-badge></h5>
        <div class="tableClassDiv" v-show="narcTableShow">
          <b-table  :responsive="true" striped hover :fields="narcTableFields" :items="narcTableOptions(dailyNarcCheck)" @row-clicked="narcLogView" />
        </div>
      </b-container>
      <b-container class="logbookPassDownContainer" v-if="repair.length > 0">
        <h5 class="sub-section-heading" :class="{toggleNo: !repairTableShow}" @click="repairTableShow = !repairTableShow">Repair Entry Reference <b-badge variant="light">{{ repair.length }}</b-badge></h5>
        <div class="tableClassDiv" v-show="repairTableShow">
          <b-table  :responsive="true" striped hover :items="repairTableOptions(repair)" />
        </div>
      </b-container>
      </div>
          <b-container class="logbookPassDownContainer">
            <h5 class="sub-section-heading">Logbook Passdown</h5>
            <div class="tableClassDiv">
          <b-table v-show="!showEditView || (showEditView && !(logbookEdit.logbookAuthorId == userData.id))" :fields="fields" striped hover :items="logbookEdit.logbookPassdown">
            <template slot="note" slot-scope="data">
              {{ data.item.note }}
              <p class="my-0 py-0" v-if="data.item.author"><em>by {{ data.item.author }}</em></p>
              <b-btn variant="danger" size="sm" v-if="data.item.authorid && data.item.authorid == userData.id && showEditView" @click.stop="removeAdminNote(data.item)">Remove</b-btn>
            </template>
          </b-table>
        </div>
        </b-container>
            <b-container v-if="showEditView  && logbookEdit.logbookAuthorId == userData.id">
             <vuedraggable v-model="arrayOfPassDowns">
               <b-container v-for="(post, index) in arrayOfPassDowns" id="logbookPassDown" :key="index">
                    <b-row class="editRowValue"><b-col align="left">
                      <timepicker :disabledBoo="post.authorid && post.authorid != userData.id ? true : false" class="timeDesk" @change="timeEdit(timeValueReturned, post)" :v-on="timeValueSet(post.time)"  v-model="timeValueReturned" format="HH:mm"></timepicker>
                      <input :disabled="post.authorid && post.authorid != userData.id ? true : false" class="timeMobile" type="time" v-model="post.time" />
                      </b-col>
                      <b-col><h5 class="postPosition" v-if="!post.authorid">Item {{ index + 1 }}</h5>
                            <h5 class="postPosition" v-if="post.author">By {{ post.author }}</h5>
                      </b-col>
                        <b-col align="right"><b-btn :disabled="post.authorid && post.authorid != userData.id ? true : false"  size="sm" @click.stop="removeCurrentRow(post)" variant="danger">X</b-btn></b-col></b-row>
                    <b-form-textarea :disabled="post.authorid && post.authorid != userData.id ? true : false" :rows="3" :max-rows="6" v-model="post.note" @change.native="noteEdit(post)" />
                  </b-container>
             </vuedraggable>
              <b-container>
                   <b-btn class="mt-2" block  @click.stop="addNewRow" size="md" variant="outline-primary">Add New Item</b-btn>
                  <b-row class="mt-2" >
                  <b-col align="right">
                     <b-btn @click.stop="updateLogbookPost(logbookEdit)" size="sm" variant="success">Save Changes</b-btn>
                  </b-col>
                </b-row>
                </b-container>
         </b-container>
         <b-container v-if="showEditView && !(logbookEdit.logbookAuthorId == userData.id)">
          <timepicker class="timeDesk float-left mt-2" v-model="superVisorReturn"  format="HH:mm"></timepicker>
           <b-form-textarea class="mt-2" v-model="supervisorNote" placeholder="Add Note Here" />
           <b-btn class="mt-2" block @click.stop="addSuperVisorNote(supervisorNote)" variant="success">Save Changes</b-btn>
         </b-container>


      </div>
      </div>
  <b-modal v-model="viewNarcModal">
    <dailyNarcView v-if="postData && postData.type == 'dailyNarctrack' && viewNarcModal" :narcs="postData" :showPost="showPost" />
    <narc v-if="postData && postData.type == 'narctrack' && viewNarcModal" :narcs="postData" :showPost="showPost" />
  </b-modal>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import VueTimePicker from '../../inputfields/timepicker.vue'
import dailyNarcView from '../dailynarctrack/apparatusFilterData.vue'
import narc from '../narclog/apparatusFilterData.vue'
import { bus } from '../../../main.js'
import vuedraggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
export default {
        props:{
            logbook: {
                type: Object
            },
            showPost: {
                type: Boolean,
                default: false,
            },
            check: {
              type: Array,
              default: () => [],
            },
            repair: {
              type: Array,
              default: () => [],
            }
        },
components:{
    'timepicker' : VueTimePicker,
    'vuedraggable' : vuedraggable,
    'multiselect': Multiselect,
    dailyNarcView,
    narc
},
  data () {
    return {
      show : false,
      showUrl : false,
      LogbookFormat: 'hh:mm',
      editButton: 'edit',
      showEditView: false,
      arrayOfPassDowns: [],
      dailyNarcCheck: [],
      stationSelectValue: '',
      currentTime: '',
      vehicleTableShow: false,
      repairTableShow: false,
      narcTableShow: false,
      supervisorNote: '',
      fields: ['time', 'note'],
      superVisorReturn: { HH: moment().format('HH'), mm:  moment().format('mm')},
      logbookEdit: {},
      viewNarcModal: false,
      postData: '',
      narcTableFields: [
        'Date',
        'Vehicle',
        'Type',
        'Author',
        'Witness'
      ]


    }
  },
  created(){
    
    this.currentTime = moment().format('"HH:mm"')
    if(this.showPost == true){
            this.checkForNarcData(this.logbook)
      this.show = !this.show
    }
    this.logbookEdit = _.cloneDeep(this.logbook)
    this.arrayOfPassDowns =  _.cloneDeep(this.logbook.logbookPassdown)
  },
  computed: {
    ...mapState([
      'userData',
      'getSessionData',
      'apparatusData',
      'shiftsData',
      'stationsData',
      'config',
      'siteconfig'
    ]),
    filterStationsData(){
        var stations = this.stationsData.filter((p) => {
          if(p.stationName != '' && (this.userData.role == 'fireops' || this.userData.role == 'admin' || this.userData.role == 'adminchief')){
            return p
          }
          else{
            return p.stationName.match("Station")
          }
        })
        return _.orderBy(stations, ['stationName'], ['asc'])

      },
  },
  mounted(){

  },
  methods: {
    getShiftColor(shiftdata){
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shiftdata)
      if(obj){
        return obj.color
      } return shift
    },
    narcLogView(data){
      if(data && data.id){
        var log = this.dailyNarcCheck.find(p => p.id == data.id)
        if(log){
          this.postData = log
           this.viewNarcModal = true
        }
      }
     

    },
    checkForNarcData(data){
      this.dailyNarcCheck = []
      var date = data.logbookDate
      var start = moment(date).subtract(1, 'd').toISOString()
      var end = moment(date).add(1, 'd').toISOString()
      var station = data.logbookStation
      axios({
        method: 'get',
        url: '/api/v1/content/narctrack/station/' + station + '/' + start + '/' + end,
        headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
           var respData = response.data
          for(var r in respData){
            var obj = respData[r]
            obj['type'] = 'narctrack'
            this.dailyNarcCheck.push(obj)
          }
        })
      axios({
        method: 'get',
        url: '/api/v1/content/dailynarctrack/station/' + station + '/' + start + '/' + end,
        headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
           var respData = response.data
          for(var r in respData){
            var obj = respData[r]
            obj['type'] = 'dailyNarctrack'
            this.dailyNarcCheck.push(obj)
          }
       
        })
      
 
    },
    vehicleCheckTableOptions(data){
      var arraySet = []
      for(var i = 0; i < data.length; i++){
        var veh = this.apparatusData.find(p => p.id == data[i].dailyvehicleApparatusDbid)
        if(veh){
          var vehHours = veh.apparatusNextPm - data[i].dailyvehicleHoursMiles
          var holdType = veh.apparatusPMCheckType
        } else {
          var holdHours = 'No PM Data'
          var holdType = ''
        }
        var vehId = data[i].dailyvehicleApparatusId
        //var vehHours = data[i].dailyvehicleHoursMiles
        var authorName = data[i].dailyvehicleAuthor
        var string = {Vehicle: vehId, TillPm: vehHours + ' ' + holdType, Author: authorName}
        arraySet.push(string)
      }
      return arraySet
    },
    repairTableOptions(data){
      var arraySet = []
      for(var i = 0; i < data.length; i++){
        if(data[i].repairformApparatusId){
        var vehId = 'Apparatus'
        } else {
        var vehId = 'Station'
        }
        var repairItem = data[i].repairformItem
        var authorName = data[i].repairformAuthor
        var string = {Type: vehId, Item: repairItem, Author: authorName}
        arraySet.push(string)
      }
      return arraySet
    },
    narcTableOptions(data){
      var arraySet = []
      var date = moment(this.logbook.logbookDate).format('YYYY-MM-DD')
      //console.log(this.logbook.logbookDate)
      var edate = moment(this.logbook.logbookDate).add(1, 'd').format('YYYY-MM-DD')
      var start = date + 'T06:00:00'
      var end = edate + 'T10:00:00'
      //var start = moment(start).format('YYYY-MM-DDTHH:mm:ss')
      //var end = moment(end).format('YYYY-MM-DDTHH:mm:ss')
      for(var i = 0; i < data.length; i++){
        var narcData = moment(data[i].narctrackDate).format('YYYY-MM-DDTHH:mm:ss')
        if(moment(narcData).isAfter(start) && moment(narcData).isBefore(end)){
        var vehId = data[i].narctrackApparatusId ? data[i].narctrackApparatusId : data[i].narctrackTagId ? data[i].narctrackTagId : 'Unknown'
        var logTypeFull = data[i].narctrackEntryType
        var narcTableDate = moment(data[i].narctrackDate).format('MM/DD HH:mm')
        var logType = logTypeFull.replace('Daily Narc ', '')
        var authorName = data[i].narctrackAuthor
        var witnessVar = data[i].narctrackWitness
        var string = {Date: narcTableDate, Vehicle: vehId, Type: logType, Author: authorName, Witness: witnessVar, id: data[i].id}
        if(data[i].narctrackWitnessSignature && data[i].narctrackWitnessSignature == 'Signature Required'){
          string['_rowVariant'] = 'danger'
        }
        if(data[i].narctrackWitnessSignature && data[i].narctrackWitnessSignature == 'Signature Saved'){
          string['_rowVariant'] = 'success'
        }

        arraySet.push(string)
        }
      }
      return arraySet
    },
    switchEditView(){
      this.currentTimeUpdate()
     if(this.logbookEdit.logbookStation){
        var stationObj = this.stationsData.find(p => p.stationName == this.logbookEdit.logbookStation)
        if(stationObj){
          this.stationSelectValue = stationObj
        }
      }
     // console.log(this.arrayOfPassDowns)
      this.showEditView = !this.showEditView
      bus.$emit('hidePanel')
      if(this.editButton == 'edit'){
        this.editButton = 'cancel'
      }
      else {
        this.editButton = 'edit' }
    },
    currentTimeUpdate(){
      this.currentTime = moment().format('"HH:mm"')
    },
    addNewRow(logbook){
      var currentTime = this.currentTime.replace(/"/g,'')
      this.arrayOfPassDowns.push({ time: currentTime, note: 'new' })
    },
    removeCurrentRow(post){
      var result = confirm('Are You Sure You Want to Delete This Entry?')
      if(result){
      var idx = this.arrayOfPassDowns.indexOf(post)
      this.arrayOfPassDowns.splice(idx, 1)
      }
    },
    noteEdit(post){
/*
      var idx = this.arrayOfPassDowns.indexOf(post)
      this.$set(this.arrayOfPassDowns, idx, {value:newValue}) */
    },
    timeEdit(eventData, post){
      var NewTimeValue = eventData.HH + ':' + eventData.mm
      var idx = this.arrayOfPassDowns.indexOf(post)
      this.$set(this.arrayOfPassDowns, idx, { time: NewTimeValue, note: post.note, author: post.author, authorid: post.authorid })
    },
    timeValueSet(value){
      var valueSplit = value.split(':')
      var valueObj = { HH: valueSplit[0], mm: valueSplit[1], }
      this.timeValueReturned = valueObj
    },
    toggleShow(logbook){
      this.checkForNarcData(this.logbook)
      this.show = !this.show 
    },
    editPermissionCheck(logbook){
      var currentDateTime = moment().unix()
      var logbookDateTime = moment(logbook.logbookDate).unix()
      if((logbook.logbookAuthorId == this.userData.id)
            && (moment(currentDateTime).subtract(logbookDateTime) < 172800 ))
        {
        return true
        }
      if(this.userData.role == 'admin' || this.userData.role == 'adminchief' || this.userData.role == 'fireops' || this.siteconfig.deptUrlSub == "srqarff"){
        return true
      }
      else
        {
        return false
        }
    },
 deleteLogbook(logbook){
    var result = confirm('Are You Sure You Want to Delete this Logbook?')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/content/logbook/' + logbook.id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
    this.switchEditView()
    this.$nextTick(function(){
        bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
    })
  }) 
      }
},
getStationParent(data){
        if(data != undefined && data != ''){
        var parent = this.stationsData.filter(p => {
            return p.stationName == data
        })
        if(parent[0].stationParent != ''){
        return parent[0].stationParent }
        else {
          return parent[0].stationName
        }
        } else {
           return ''
        }
      },
      removeAdminNote(data){
           var result = confirm('Are You Sure You Want to Delete your entry?')
      if (result) {
        var indexObj = this.arrayOfPassDowns.find(p => p.note == data.note && p.authorid == data.authorid && p.time == data.time)
        if(indexObj){
        var  index = this.arrayOfPassDowns.indexOf(indexObj)
        var array = _.cloneDeep(this.arrayOfPassDowns)
        array.splice(index, 1)
      axios({
          method: 'patch',
          url: '/api/v1/content/logbook/' + this.logbook.id,
          data: {
              'logbookPassdown' : array,
          },
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',}
          }).then( response => {
          this.switchEditView()
          this.$nextTick(function(){
              bus.$emit('refreshUserPosts')
              bus.$emit('refresh-calendar-list')
          })
        }) 
        }
      }
      },
 addSuperVisorNote(data){
   var id = this.logbook.id
   var obj = {time: this.superVisorReturn ? this.superVisorReturn.HH + ':' + this.superVisorReturn.mm : moment().format('HH:mm'), note: data, author: this.userData.firstName + ' ' + this.userData.lastName, authorid: this.userData.id }
   var array = _.cloneDeep(this.arrayOfPassDowns)
   array.push(obj)
   axios({
    method: 'patch',
    url: '/api/v1/content/logbook/' + id,
    data: {
              'logbookPassdown' : array,
          },
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
  }).then( response => {
    this.switchEditView()
    this.supervisorNote = ''
                  bus.$emit('refreshUserPosts')
              bus.$emit('refresh-calendar-list')
  }) 
 },
 updateLogbookPost(logbook){
   var id = this.logbook.id
   axios({
    method: 'patch',
    url: '/api/v1/content/logbook/' + id,
    data: {
              'title': moment(this.logbook.logbookDate).format('MM/DD/YYYY') + ' | ' + this.stationSelectValue.stationName + ' | ' + this.logbookEdit.logbookShift + ' | ' + this.userData.lastName,
              'logbookStation' : this.stationSelectValue.stationName,
              'logbookStationParent' : this.getStationParent(this.stationSelectValue.stationName),
              'logbookPassdown' : this.arrayOfPassDowns,
          },
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
  }).then( response => {
    this.switchEditView()
    this.$nextTick(function(){
        bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
    })
  }) 
  }
},


}
</script>

<style scoped>
.postPosition {
  cursor: pointer;
}
#confirmationTable {
  width: 100%;
  margin: 0 auto 0;
  text-align: center;
}
.headerRows h6 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #aaa;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}
.subHeaderRows h6 {
  margin-top: -2px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  border: 1px solid #aaa;
}
.headerContainer {
  border-radius: 10px 10px 0 0;
  padding-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.Red-shift-header {
  background: #E2070D;
}
.Yellow-shift-header {
  background: #ffc107;
}
.Blue-shift-header {
  background: #007bff;
}
.Green-shift-header {
  background: #0FB906;
}
.headerContainer h3 {
  color: #eee;
  font-weight: bold;
  text-shadow: 2px 1px #333;
}
.headerContainer h5 {
  color: #eee;
  padding-top: 4px;
  font-weight: bold;
  text-shadow: 2px 1px #333;
}
.editRowValue {
  margin-top: 20px;
}
.input-group{
  margin-bottom: 10px;
}
#tablePassDown{
  width:80%;
  padding: 5px;
}

.headingRow {
  color: #777;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin-bottom: 5px;
}
.headingRow  h4{
  margin-bottom: 0;
}
.timeCell {
  color: red;
}
.timepicker {
  width: 5em !important;
}

#logbookTitle {
  text-align: center;
  cursor: pointer;
  background: #fff;
}
#logbookTitle:hover {
  background: #ddd;
}
 #logbookUrl {
   text-align: center;
 }
 a {
   color: red;
   text-decoration: none;
 }
.sub-section-heading {
padding:5px;
font-weight: bold;
background: #999;
width: 100%;
text-align: center;
margin-top: 10px;
margin-bottom: 0;
border-radius: 5px 5px 0 0;
}
.toggleNo {
  border-radius: 5px;
}
.tableClassDiv {
  border: 1px solid #999;
  border-radius: 0 0 5px 5px;
}
.show-background {
border: 1px solid #777;
width: 90%;
margin: 0 auto 0;
border-radius: 10px;
padding-bottom: 10px;
margin-bottom: 0;

}
.titleBodyWrap{
  margin: 0 auto 0;
  width: 80%;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 5px;
}
.A-shift{
color: #e2070d;
}
.B-shift{
color: #007bff;
}
.C-shift{
color: #0FB906;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    width: 100px;
  }
}
@media screen and (max-width: 768px) {
.titleBodyWrap {

  margin: 0 auto 0;
  width: 95%;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 5px;
  }
}
  @media screen and (max-width: 460px) {
    .show-background {
    width: 100%;
    }
  .checkDetails.container {
    padding-left: 0;
    padding-right: 0;
  }
}


</style>
