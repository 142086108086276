<template>
  <b-container fluid class="hello px-1 px-lg-4"> 
  <b-card no-body v-if="!showAdmin" >
    <b-tabs card>
      <b-tab title="Inspections Overview" :active="showActiveId == 'overview'" class="px-0 pt-3"  @click="showActiveId = 'overview'">
  <b-container fluid>
    <inspectionTable v-if="submittedBusiness.length > 0 && dataReset" @createNewInspection="addBusiness($event)" :viewBusiness="viewBusiness" @getInspections="getInspections" @openInspectionForm="openInspectionForm($event)" @deletebusiness="deleteBusiness($event)" :formTableStyle="formTableStyle" :submittedBusiness="submittedBusiness" />
      
  </b-container>
      </b-tab>
     <b-tab title="Inspections Scheduled" class="p-0" @click="loadInspectionTracking = true">
      <inspectionTracking v-if="submittedBusiness.length > 0 && inspectionsData.length > 0 && dataReset && loadInspectionTracking" @createScheduledInspection="addInspectionBusiness($event)" @getInspections="getInspections" @getInspectionsView="getInspections($event, 'editthisPlan')" :formTableStyle="formTableStyle" :submittedBusiness="submittedBusiness" :inspectionsData="inspectionsData" />
      </b-tab>
     <b-tab title="Inspections Completed" class="p-0" @click="loadPreviousInspections = true">
      <inspectionsCompleted v-if="allInspectionsData.length > 0 && loadPreviousInspections" :formTableStyle="formTableStyle" :submittedBusiness="submittedBusiness" :inspectionsData="allInspectionsData" />
    </b-tab>
    <b-tab title="Code Violations" class="p-0">
      <codeviolations v-if="inspectionCodes" :inspectionCodes="inspectionCodes" @updateInspectionCodes="getCodeViolations" />
      </b-tab>
      <template v-for="Business in editthisPlan" >
      <b-tab :key="Business.id" :active="showActiveId == Business.id" class="px-0 px-md-2">
          <template slot="title">
                  {{ getTabTitle(Business.businessData, formTabTitle) }} 
                  <b-badge variant="danger" @click="closeInspection(Business.id)">X</b-badge>
            </template>
         <newinspectionform v-if="inspectionCodes" :inspectionCodes="inspectionCodes" @updateInspectionCodes="getCodeViolations" :business="Business" @getInspections="getInspections(Business.id, 'editthisPlan')" />
        </b-tab>
      </template>
      <template v-for="Business in scheduledInspections" >
      <b-tab :key="Business.id" :active="showActiveId == Business.id" class="px-0 px-md-2">
                   <template slot="title">
                  {{ getTabTitle(Business.business.businessTableData, 'businessName' , true) }} 
                  <b-badge variant="danger" @click="closeInspection(Business.id, true)">X</b-badge>
            </template>
         <newinspectionform v-if="inspectionCodes" :inspectionCodes="inspectionCodes"  @getInspections="getInspections(Business.inspectionBusinessId, 'scheduledInspections')" @updateInspectionCodes="getCodeViolations" :business="Business.business" :scheduled="true" :previous="Business" />
        </b-tab>
      </template>
    </b-tabs></b-card>

    </b-container>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from "moment";
import { mapState } from "vuex";
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import axios from "axios";
import inspectionSearch from "./../../tracking/preplans/inspectionSearch.vue";
import inspectionTable from "./../../tracking/preplans/inspectionTable.vue";
import inspectionTracking from "./../../tracking/preplans/inspectionTracking.vue";
import inspectionsCompleted from "./../../tracking/preplans/inspectionsCompleted";
import codeviolations from "./codeviolations.vue";
import addNewBusiness from "./../../addNew/newBusinessForm.vue";
import newinspectionform from "./../../addNew/newInspection.vue";
import { bus } from '../../../main.js';
export default {
  name: "hello",
  components: {
    addNewBusiness,
    inspectionSearch,
    inspectionTable,
    inspectionTracking,
    codeviolations,
    newinspectionform,
    inspectionsCompleted
  },
  data() {
    return {
      stringData: "",
      userSearchBox: "",
      viewBusiness: '',
      searchData: "",
      currentPage: 1,
      showAdmin: false,
      showBulkLoad: false,
      pageDisplayCount: 6,
      showActiveId: 'overview',
      dataReset: true,
      editthisPlan: [],
      scheduledInspections: [],
      allInspectionsData: [],
      editBusinessData: false,
      loadPreviousInspections: false,
      loadInspectionTracking: false,
      pageCountOptions: [
        { text: "6 Per Page", value: 6 },
        { text: "12 Per Page", value: 12 },
        { text: "24 Per Page", value: 24 },
        { text: "48 Per Page", value: 48 }
      ],
      addressData: [],
      submittedBusiness: [],
      oldBusiness: [],
      inspectionCodes: null,
      resetNewBusiness: true,
      formTabTitle: "businessName5ic3rl49",
      formTableStyle: [
        {
          values: ["businessName5ic3rl49"],
          label: "Business Name",
          key: "businessName",
          itemTitle: true,
        },
        {
          values: ["StreetNumber3f1myd54", "Streetbn8ry055", "Suffixrtum4856", "Building32238ry055", "Unitrtum4556", "City7xgpvk57", "State5lm01g58", "Ziplmanxr59"],
          label: "Address",
          key: "streetNumberStreetStreetSuffix"
        },
        {
          values: ["billingAdd38548r59"],
          label: "Billing Add",
          key: "billingAdd",
          itemTitle: true,
        }
      ],
      inspectionsData: [],
    };
  },
  created() {
    bus.$on('showBusinessFromBuild', this.addBusiness)
    this.getBusiness();
    this.getCodeViolations();
    this.getInspections();
    this.getAllInspections();

  },
  mounted() {
    bus.$emit('emitBusinessFormTableStyle', this.formTableStyle)
  },
  computed: {
    ...mapState([
      "deptContactData",
      "departmentBlogs",
      "getSessionData",
      "userData"
    ])
  },
  watcher: {},
  methods: {
    getTabTitle(data, title, key) {
      if(key){
        var obj = data.find(p => p.key == title);
        return obj.value;
      }
      var obj = data.find(p => p.vmodel == title);
      return obj.value;
    },
    addInspectionBusiness(event) {
      this.showActiveId = ''
      var business = event.business
       axios({
        method: "get",
        url: "/api/v1/preplan/inspection/" + event.id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.showActiveId = event.id
        var obj = response.data[0]
        obj['business'] = business
     //   console.log(obj)
        this.scheduledInspections.push(obj)
      });
    },
    openInspectionForm(data){
      this.showActiveId = data.id
      this.scheduledInspections.push(data)
    },
    closeInspection(id, boo){
      if(id && boo){
        this.scheduledInspections.splice(this.scheduledInspections.map(p => p.id).indexOf(id), 1)
      }
      if(id && !boo){
        this.editthisPlan.splice(this.editthisPlan.map(p => p.id).indexOf(id), 1)
      }
    },
    addBusiness(event) {
      this.showActiveId = ''
      if(event.id){
        var id = event.id
      } else {
        var id = event
      }
      if(this.editthisPlan.map(p => p.id).indexOf(id) == -1){
      axios({
        method: "get",
        url: "/api/v1/preplan/business/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.showActiveId = id
        this.editthisPlan.push(response.data[0])
      });
      } else {
        this.showActiveId = id
      }
    },
    addBusinessList($event){
   //   console.log('BUSINESS ADDED')
      this.resetNewBusiness = false
      this.getBusiness()
      this.editthisPlan.push($event)
      this.$nextTick(function(){
        this.resetNewBusiness = true
      })
    },
    resetNewBusinessData(){
      this.resetNewBusiness = false
      this.$nextTick(function(){
        this.resetNewBusiness = true
      })
    },
    removeBusinessTab(data){
      var index = this.editthisPlan.map(p => p.id).indexOf(data)
      this.editthisPlan.splice(index, 1)
    },
    setFormGoups(data) {
      var forms = _.cloneDeep(data);
      for (var i in data) {
        var fields = data[i].fields;
        for (var p in fields) {
          var id = fields[p].idtag;
          var length = 6;
          var id =
            id +
            Math.random()
              .toString(36)
              .substr(2, length);
          var id = _.uniqueId(id);
          fields[p].vmodel = id;
        }
      }
    },
    closeTab(id, arrayHold){
      this.showActiveId = false
      var index = this[arrayHold].map(p => p.id).indexOf(id)
    //  console.log(index + ' Index')
      this[arrayHold].splice(index, 1)
      this.$nextTick(function(){
        this.showActiveId = 'overview'
      })

    },
    getAllInspections() {
      axios({
        method: "get",
        url: "/api/v1/preplan/inspection/completed",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.allInspectionsData = response.data;
      });
    },
    getInspections(id, arrayHold) {
      if(id && arrayHold){
        this.closeTab(id, arrayHold)
    //    console.log(id)
        this.viewBusiness = id
      }
     this.dataReset = false
      axios({
        method: "get",
        url: "/api/v1/preplan/inspection/scheduled",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.inspectionsData = response.data;
        this.$nextTick(function(){
       this.dataReset = true
    // console.log(id)
       
        })
 
      });
    },
    getBusiness() {
      this.dataReset = false
      axios({
        method: "get",
        url: "/api/v1/preplan/business",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.submittedBusiness = response.data;
        this.$nextTick(function(){
       this.dataReset = true
        })
 
      });
    },
    getCodeViolations(){
      this.inspectionCodes = null
      axios({
        method: "get",
        url: "/api/v1/preplan/inspectioncodes",
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
        this.inspectionCodes = response.data;
 
      });
    },
    deleteBusiness(id) {
    //  console.log(id)
      axios({
        method: "delete",
        url: "/api/v1/preplan/business/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"

        }
      }).then(response => {
       this.getBusiness()
      });
    },
  }
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.blogCardClass {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space: nowrap;
}
.userDataWrap {
  text-align: center;
}
.userRank {
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer {
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: 0.4;
}
.headerContactRow {
  border-radius: 5px;
}
.A-shiftROW {
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.B-shiftROW {
  border: 1px solid #201b9f;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.C-shiftROW {
  border: 1px solid #0fb906;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.Day-shiftROW {
  border: 1px solid #e4cb07;
  border-radius: 22px;
  text-align: left;

  padding: 0;
}
.A-shift {
  background: #e2070d;
  background: -moz-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e2070d 0%,
    #e2070d 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift {
  background: #201b9f;
  background: -moz-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #201b9f 0%,
    #201b9f 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.C-shift {
  background: #0fb906;
  background: -moz-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #0fb906 0%,
    #0fb906 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.Day-shift {
  background: #e4cb07;
  background: -moz-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: -webkit-linear-gradient(
    left,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  background: linear-gradient(
    to right,
    #e4cb07 0%,
    #e4cb07 50%,
    #ffffff 51%,
    #ffffff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;
}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138, 138, 138, 1);
  color: #fff;
  border-radius: 10px;
}
h1,
h2 {
  font-weight: normal;
}

@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
