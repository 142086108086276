<template>
  <div class="hello">
       <b-navbar type="dark" variant="dark">
      <b-nav-form>
         <b-navbar-brand class="bootstrapTable">Medication Admin:</b-navbar-brand>
        <b-form-input type="text" placeholder="Search Control/Lot/NDC" v-model="controlNumberSearch" />
        <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief'" :variant="!addNew ? 'primary' : 'danger'" class="ml-2" @click="addNew = !addNew">{{ !addNew ? 'Add Medications' : 'Cancel Add New' }}</b-btn>
        <b-btn variant="success" class="ml-2" @click="switchEmitDailyNarc(null)">Add Narc Entry</b-btn>medsNarctrackSort
        <b-btn variant="danger" @click="viewMedicationId = null" v-if="viewMedicationId">Back To Table</b-btn>
        <b-btn variant="info" class="ml-2" v-if="medsNarctrackSelect.length > 0 && (userData.role == 'admin' || userData.role == 'adminchief')" @click="switchEmitDailyNarc(medsNarctrackSelect)">Destroy {{ medsNarctrackSelect.length }} Meds</b-btn>
      </b-nav-form>
    </b-navbar>
    <b-card v-if="!addNew && !viewMedicationId">
      <b-row>
        <b-col>
          Filter Control Number Range
          <b-form-input type="text" v-model="sortNumberLow" />
          <b-form-input type="text" v-model="sortNumberHigh" />
          </b-col>
          <b-col>
            Filter Medication Type
            <b-form-select :options="medicationNames" v-model="sortName" />
            <b-form-select :options="medicationAssigned" v-model="sortAssigned" />
            </b-col>
          <b-col>
            <b-form-checkbox v-model="selectAll" @change="updateSelectValueAll($event)">Select All</b-form-checkbox>
            </b-col>
            </b-row>
      <b-btn v-if="destroyMedicationsData && destroyMedicationsData.length > 0" variant="dark" block @click="showDestroyed = !showDestroyed">Show Destroyed</b-btn>
      <b-table :fields="medsFields" :items="medsNarctrackSort">
          <template slot="createdAt" slot-scope="data">
            {{ getFormatedDate(data.item.createdAt) }}
          </template>
           <template slot="expDate" slot-scope="data">
            {{ getFormatedDate(data.item.expDate) }}
          </template>
          <template slot="pdfAttach" slot-scope="data">
             <b-btn variant="link" v-if="data.item.pdfAttach" @click="viewFileIframe(data.item.pdfAttach)">View</b-btn>
          </template>
          <template slot="action" slot-scope="data">
          <b-btn variant="danger" size="sm" v-if="(userData.role == 'admin' || userData.role == 'adminchief') && !data.item.initial" @click="deleteMedication(data.item.id)">Delete</b-btn>
           <b-btn v-if="data.item.initial" variant="info" size="sm" @click="viewMedicationCard(data.item.id)">View</b-btn>
          </template>
          <template slot="select" slot-scope="data">
          <b-form-checkbox :id="data.item.id + '_select_box'" :disabled="showDestroyed" :checked="getCheckedValue(data.item.id)"  @change="updateSelectValue($event, data.item.id)" />
         
          </template>
        </b-table>
    </b-card>
    <b-card v-if="viewMedicationId">
      <b-row>
        <b-col cols="4">
          <h4>Viewing Control Substance</h4>
          <b-table stacked :fields="['medication', 'createdAt', 'controlNumber', 'NdcNumber', 'LotNumber', 'pdfAttach']" :items="[getMedicationData(viewMedicationId)]">
          <template slot="createdAt" slot-scope="data">
            {{ getFormatedDate(data.item.createdAt) }}
          </template>
          <template slot="pdfAttach" slot-scope="data">
             <b-btn variant="link" v-if="data.item.pdfAttach" @click="viewFileIframe(data.item.pdfAttach)">View</b-btn>
          </template>
            </b-table>
          </b-col>
          <b-col cols="8">
            <narcTable :tableData="viewMedicationCardData" :medsNarctrack="medsNarctrack" />
            </b-col>
          </b-row>
    </b-card>
    <b-card v-if="addNew" no-body>
      <b-tabs card>
        <b-tab title="Bulk Create">
          <b-container fluid>
            <b-row>
              <b-col cols="4"  class="mb-2">
                <h5>Medication Name</h5>
                <b-form-input type="text" v-model="bulkName" placeholder="ie Versed/Fentanyl" />
                </b-col>
                <b-col cols="4" class="mb-2">
                <h5>Control Start (8 Digit Max)</h5>
                <b-form-input type="number" v-model="bulkControlStart" />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>Control End  (8 Digit Max)</h5>
                <b-form-input type="number" v-model="bulkControlEnd" />
                </b-col>
                              <b-col cols="4"  class="mb-2">
                <h5>Medication Exp Date</h5>
                <b-form-input type="date" v-model="bulkExpDate" />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>NDC Number</h5>
                <b-form-input type="text" v-model="bulkNdcNumber"  />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>Lot Number</h5>
                <b-form-input type="text" v-model="bulkLotNumber"  />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>Medication File Attach</h5>
                 <input v-if="resetFileUploadBoo" :disabled="bulkName == '' || bulkFileAttach != ''" type="file" @change="postImage($event, bulkName, 'bulk')" />
                 <b-btn class="ml-2" v-if="bulkFileAttach != ''" @click="bulkFileAttach = '', resetFileUpload" variant="danger" size="sm">Clear</b-btn>
                </b-col>
                </b-row>
              </b-container>
              <b-table :items="bulkMedicationUploads">
                                <template slot="control_number" slot-scope="data">
                  <span v-if="data.item._rowVariant != undefined">{{ data.item.control_number }} * Duplicate</span>
                  <span v-else>{{ data.item.control_number }}</span>
                </template>
                <template slot="pdf_attach" slot-scope="data">
                  <b-btn variant="link" v-if="data.item.pdf_attach" @click="viewFileIframe(data.item.pdf_attach)">View</b-btn>
                  </template>
                </b-table>
              <b-btn variant="primary" v-if="bulkMedicationUploads.length > 0" @click="bulkAddMedications(bulkMedicationUploads)">Create ({{ bulkMedicationUploads.length }} Meds)</b-btn>
          </b-tab>
          <b-tab title="Add Individual">
          <b-container fluid>
            <b-row v-if="individualForm">
              <b-col cols="4"  class="mb-2">
                <h5>Medication Name</h5>
                <b-form-input type="text" v-model="individual.individualName" placeholder="ie Versed/Fentanyl" />
                </b-col>
                <b-col cols="4" class="mb-2">
                <h5>Control Start (8 Digit Max)</h5>
                <b-form-input type="number" v-model="individual.individualControl" />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>Medication Exp Date</h5>
                <b-form-input type="date" v-model="individual.individualExpDate" />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>NDC Number</h5>
                <b-form-input type="text" v-model="individual.individualNdcNumber"  />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>Lot Number</h5>
                <b-form-input type="text" v-model="individual.individualLotNumber"  />
                </b-col>
                <b-col cols="4"  class="mb-2">
                <h5>Medication File Attach</h5>
                 <input v-if="resetFileUploadBoo" :disabled="individual.individualName == '' || individual.individualFileAttach != ''" type="file" @change="postImage($event, individual.individualName, 'individual')" />
                 <b-btn class="ml-2" v-if="individual.individualFileAttach != ''" @click="individual.individualFileAttach = '', resetFileUpload" variant="danger" size="sm">Clear</b-btn>
                </b-col>
                </b-row>
                <h6 class="text-danger" v-if="individualGroup.length > 0">*** Unsaved Date *** Be sure to Submit before leaving the page</h6>
                <b-btn variant="primary" v-if="individual.individualName != '' && individual.individualControl != 0 && individual.individualExpDate != ''" @click="addToIndividualGroup(individual, false)">Add and Continue</b-btn>
                <b-btn variant="success" v-if="individual.individualName != '' && individual.individualControl != 0 && individual.individualExpDate != ''" @click="addToIndividualGroup(individual, true)">Add and Submit</b-btn>
              </b-container>
              <b-table :items="individualGroupReturned">
                <template slot="control_number" slot-scope="data">
                  <span v-if="data.item._rowVariant != undefined">{{ data.item.control_number }} * Duplicate</span>
                  <span v-else>{{ data.item.control_number }}</span>
                </template>
                <template slot="pdf_attach" slot-scope="data">
                  <b-btn variant="link" v-if="data.item.pdf_attach" @click="viewFileIframe(data.item.pdf_attach)">View</b-btn>
                  </template>
                </b-table>
                       <b-btn variant="primary" v-if="individualGroupReturned.length > 0" @click="bulkAddMedications(individualGroupReturned)">Add Table Only ({{ individualGroupReturned.length }} Meds)</b-btn>
          </b-tab>
      </b-tabs>
      </b-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
import narcTable from './narcoticTable.vue'
export default {
  name: 'hello',
  props: [ 'medicationCardsInitial', 'quarMedicationsData', 'destroyMedicationsData'],
  components: {
    narcTable
  },
  data () {
    return {
      controlNumberSearch: '',
      addNew: false,
      bulkName: '',
      bulkControlStart: 0,
      bulkControlEnd: 0,
      bulkExpDate: '',
      bulkNdcNumber: '',
      bulkLotNumber: '',
      showDestroyed: false,
      bulkFileAttach: '',
      individual: {
          individualName: '',
          individualControl: 0,
          individualExpDate: '',
          individualNdcNumber: '',
          individualLotNumber: '',
          individualFileAttach: '',
      },
      medsNarctrackSelect: [],
      individualForm: true,
      individualGroup: [],
      selectAll: false,
      sortNumberLow: '',
      blockNumberLow: '',
      viewMedicationId: null,
      viewMedicationCardData: [],
      sortNumberHigh: '',
      blockNumberHigh: '',
      resetFileUploadBoo: true,
      sortName: '',
      sortAssigned: '',
      medsFields: [
        {key: 'createdAt', label: 'Added'},
        'medication',
        'controlNumber',
        'expDate',
        'NdcNumber',
        'LotNumber',
        'pdfAttach',
        'action',
        'select'
      ]
  
    }
  },
  created(){
      
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'medsNarctrack',
        'getSessionData',
        'documentsApi'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin' || this.userData.role == 'emsops'){
            return false
          } else {
            return true
          }
      },
      returnInitialArray(){
        var array = _.cloneDeep(this.medicationCardsInitial)
        var holdvalues = []
        for(var i in array){
          var meds = array[i].narctrackMeds
          for(var m in meds){
          holdvalues.push({id: array[i].id, med: meds[m]})
          }
        }
        return holdvalues
      },
      medsNarctrackReturned(){
        var array = _.cloneDeep(this.medsNarctrack)
        var sort = _.cloneDeep(array)
        if(sort){
          var low = _.orderBy(sort, ['controlNumber'], ['asc'])
        //  console.log('Low')
       //   console.log(low)

          var high = _.orderBy(sort, ['controlNumber'], ['desc'])
         //           console.log('High')
        //  console.log(high)
          if(low[0] && low[0].controlNumber){
            this.blockNumberLow = low[0].controlNumber
            this.sortNumberLow = low[0].controlNumber
          }
          if(high[0] && high[0].controlNumber){
            this.blockNumberHigh = high[0].controlNumber
            this.sortNumberHigh = high[0].controlNumber
            var number = Number.parseInt(high[0].controlNumber) + 1
            var numberHigh = number + 100
            number =  "000000000" + number;
            numberHigh = "000000000" + numberHigh
            number = number.substr(number.length - 8);
            numberHigh = numberHigh.substr(numberHigh.length - 8);
            this.bulkControlStart = number
            this.bulkControlEnd = numberHigh
            if(this.individual.individualControl == 0){
            this.individual.individualControl = number
            }
            
          }
        }
        array = _.orderBy(array, ['controlNumber'], ['asc'])
        return array.map(item => {
            var obj = _.cloneDeep(item)
            var initial = this.returnInitialArray.find(p => p.med == item.id)
            if(initial){
            obj['select'] = initial.id
            obj['initial'] = true
            } else {
            obj['select'] = ''
            obj['initial'] = false
            }
            return obj
        })
      },
      medicationNames(){
        var array = [{value: '', text: 'All Names'}]
        var options = _.cloneDeep(this.medsNarctrack)
        var medication = [... new Set(options.map(p => p.medication))]
        for(var i in medication){
          array.push(medication[i])
        }
        return array
      },
      medicationAssigned(){
        var array = [
          {value: '', text: 'Both Assigned/Unassigned'},
          {value: true, text: 'Assigned Only'},
          {value: 'unassigned', text: 'Unassigned Only'}
          ]
          return array
      },
      individualGroupReturned(){
        var array = _.cloneDeep(this.individualGroup)
        return array.map(item => {
            var obj = item
            var index = this.medsNarctrack.map(p => p.controlNumber).indexOf(item.control_number)
            console.log(index)
            if(index != -1){
              console.log('Duplicate')
              obj['_rowVariant'] = 'danger'
            }
            return obj
        })
      },
      medsNarctrackSort(){
        var preSortHold = _.cloneDeep(this.medsNarctrackReturned)
        var filterquar = this.quarMedicationsData.map(p => p.narctrackMeds)
        var merged = [].concat.apply([], filterquar);
        var preSort = merged.map(id => {
            var obj = preSortHold.find(p => p.id == id)
            return obj
        })
        if(this.controlNumberSearch != ''){
          var text = this.controlNumberSearch
          var search = preSort.filter(p => {
            var control = p.controlNumber ? p.controlNumber.toLowerCase() : ''
            var ndc = p.NdcNumber ? p.NdcNumber.toLowerCase() : ''
            var lot = p.LotNumber ? p.LotNumber.toLowerCase() : ''
            return control.match(text.toLowerCase()) 
                || ndc.match(text.toLowerCase()) 
                || lot.match(text.toLowerCase()) 
          })
        } else {
          var search = preSort
        }
        var low = this.sortNumberLow
        var high = this.sortNumberHigh
        var sort = search.filter(p => {
          return Number.parseInt(p.controlNumber) >= Number.parseInt(low)
              && Number.parseInt(p.controlNumber) <= Number.parseInt(high)
        })
        if(this.sortName != ''){
          var name = sort.filter(p => {
            return p.medication == this.sortName
          })
        } else {
          var name = sort
        }
        if(this.sortAssigned != ''){
          var assign = name.filter(p => {
            if(this.sortAssigned == true){
              return p.initial == true
            } return p.initial == false
          })
        } else {
          var assign = name
        }
        if(this.destroyMedicationsData){
        var filterdest = this.destroyMedicationsData.map(p => p.narctrackMeds)
        var destroyed = [].concat.apply([], filterdest);
        if(destroyed && destroyed.length > 0){
          var destroyRet = []
          for(var a in assign){
            var index = destroyed.indexOf(assign[a].id)
            if(index >= 0){
              var obj = assign[a]
              obj['destroyed'] = true
              destroyRet.push(obj)
            } else {
              destroyRet.push(assign[a])
            }
          }
          if(!this.showDestroyed){ 
            return destroyRet.filter(p => !p.destroyed)
          } return destroyRet.filter(p => p.destroyed)
        }
        }
        return assign
      },
      bulkMedicationUploads(){
        var array = []
        if(this.bulkControlEnd - this.bulkControlStart > 0 && this.bulkName){
          var length = this.bulkControlEnd - this.bulkControlStart
          var date = this.bulkExpDate ? this.bulkExpDate : null
          var file = this.bulkFileAttach ? this.bulkFileAttach : null
          var ndcNumber = this.bulkNdcNumber ? this.bulkNdcNumber : null
          var lotNumber = this.bulkLotNumber ? this.bulkLotNumber : null
          for(var i = 0; i <= length; i++){
            var obj = {}
            var number = Number.parseInt(this.bulkControlStart) + i
            number =  "000000000" + number;
            number = number.substr(number.length - 8);
            var index = this.medsNarctrack.map(p => p.controlNumber).indexOf(number)
            if(index != -1){
              obj['_rowVariant'] = 'danger'
            }
            obj['medication'] = this.bulkName
            obj['control_number'] = number
            obj['exp_date'] = date
            obj['ndc_number'] = ndcNumber
            obj['lot_number'] = lotNumber
            obj['pdf_attach'] = file
            array.push(obj)
          }
          return array
        } return array
      },
    

  },
  watch: {
    
  },
  methods: {
    getCheckedValue(id){
      return this.medsNarctrackSelect.indexOf(id) == -1 ? false : true
    },
    updateSelectValueAll(value){
      if(value){
        var array = this.medsNarctrackSort
        this.medsNarctrackSelect =  array.map(p => p.id)
      } else {
        this.medsNarctrackSelect = []
      }
    },
    updateSelectValue(value, id){
      var index = this.medsNarctrackSelect.indexOf(id)
      if(value && index == -1){
        this.medsNarctrackSelect.push(id)
      }
      if(!value && index != -1){
        this.medsNarctrackSelect.splice(index, 1)
      }
    },
    resetFileUpload(){
      this.resetFileUploadBoo = false
      this.$nextTick(function (){
        this.resetFileUploadBoo = true
      })
    },
    viewFileIframe(fileurlName){
       axios({
            method: 'get',
            url: '/api/v1/upload/' + fileurlName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
               Object.assign(document.createElement('a'), { target: '_blank', href: response.data.signedRequest}).click();
            }) 
       },
    getMedicationData(id){
        var obj = this.medsNarctrack.find(p => p.id == id)
        return obj
    },
    refreshData(data){
      var array = this.medsNarctrackSelect
    },
  getFormatedDate(date){
    var data = date ? moment(date).format('MM/DD/YY') : 'Unknown'
    return data
  },
  switchEmitDailyNarc(data){

    bus.$emit('switchEmitDailyNarc', data, true)
  },
  postImage(e, id, type){
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + 'narcupload-' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        if(type == 'bulk'){
          this.bulkFileAttach = response.data
        } else {
          this.individual.individualFileAttach = response.data
        }

        });

  
     },
     addToIndividualGroup(individual, submit){
            var number = Number.parseInt(individual.individualControl)
            number =  "000000000" + number;
            number = number.substr(number.length - 8);
            var obj = {}
            obj['medication'] = individual.individualName
            obj['control_number'] = number
            obj['exp_date'] = individual.individualExpDate
            obj['ndc_number'] = individual.individualNdcNumber
            obj['lot_number'] = individual.individualLotNumber
            obj['pdf_attach'] = individual.individualFileAttach
      this.individualGroup.push(obj)
      this.individualForm = false
      this.$nextTick(function(){
            var newNumber = Number.parseInt(obj.control_number) + 1
            newNumber =  "000000000" + newNumber;
            newNumber = newNumber.substr(newNumber.length - 8);
      this.individual = {
          individualName: individual.individualName,
          individualControl: newNumber,
          individualExpDate: individual.individualExpDate,
          individualNdcNumber: individual.individualNdcNumber,
          individualLotNumber: individual.individualLotNumber,
          individualFileAttach: individual.individualFileAttach }
          this.individualForm = true
          if(submit){
            this.bulkAddMedications(this.individualGroup)
          }
      })

     },
     bulkAddMedications(array){
       var date = moment().toISOString()
       var data = array.filter(p => {
         return p._rowVariant == undefined
       })
       data = data.map(item => {
         var obj = _.cloneDeep(item)
         obj['med_author'] = this.userData.firstName + ' ' + this.userData.lastName
         obj['med_author_id'] = this.userData.id
         obj['active'] = true
         obj['created_at'] = date
         obj['updated_at'] = date
         obj['exp_date'] = moment(item.exp_date).toISOString()
         return obj
       })
       axios({
         method: 'post',
         url: '/api/v1/taxonomy/mednarctrack/bulk',
         data: data,
         headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
         }
       }).then( response => {
          this.$emit('updateMedications')
          this.addNew = false
          this.bulkName = ''
          this.bulkControlStart = 0
          this.bulkControlEnd = 0
          this.bulkExpDate = '' 
          this.bulkNdcNumber = ''
          this.bulkLotNumber = ''
          this.bulkFileAttach = ''
          this.individualGroup = []
          this.individual = {
          individualName: '',
          individualControl: 0,
          individualExpDate: '',
          individualNdcNumber: '',
          individualLotNumber: '',
          individualFileAttach: '' }
        })
     },
     deleteMedication(id){
       axios({
         method: 'delete',
         url: '/api/v1/taxonomy/mednarctrack/' + id,
         headers: {
              'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
          console.log(response.data)
          this.$emit('updateMedications')
        })
     },
     viewMedicationCard(id){
       this.viewMedicationId = id
       axios({
         method: 'get',
         url: '/api/v1/content/dailynarctrack/medid/' + id,
        headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.viewMedicationCardData = _.orderBy(response.data, ['narctrackDate', 'createdAt'], ['asc', 'asc'])
     console.log(response.data)
     
   })

     },
    updateDocument(id){
    axios({
     method: 'patch',
     url: '/api/v1/content/document/' + id,
     data: {
     'documentName': this.detailedDescription,
     'documentTag': this.documentTag,
      'documentTagParent': this.docPar,
       'documentId': this.itemShort,
      'documentFile': this.fileUpload

           },
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     this.resetComponent()
   })

 }
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
