<template>
<div>
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Credential Requirements Admin</b-navbar-brand>
          <b-btn size="sm" :disabled="editUserTable" variant="success" @click="addNewCredential = !addNewCredential">Add New Credential Requirement</b-btn>
            <b-btn class="bootstrapTable" size="sm" :disabled="addNewCredential" :variant="editUserTable ? 'danger' : 'outline-danger'" @click.stop="editUserTable = !editUserTable">{{ editUserTable ? 'Turn Off Editing' : 'Turn On Editing' }}</b-btn>
      </b-nav-form>
    </b-navbar>
       <b-table v-if="addNewCredential" :fields="fieldsEdit" :items="newCredentialRequirement">
         <template slot="credentialRequirementName" slot-scope="data">
           <b-form-input type="text" v-model="data.item.credentialRequirementName" />
           </template>
           <template slot="credentialRequirementHours" slot-scope="data">
           <b-form-input type="number" v-model="data.item.credentialRequirementHours" />
           </template>
             <template slot="credentialRequirementRolloverId" slot-scope="data">
           <b-form-select type="" v-model="data.item.credentialRequirementRolloverId" :options="credentialSelectOptions">
            
             </b-form-select>
           </template>
      <template slot="Action" slot-scope="data">
        <b-btn :disabled="data.item.credentialRequirementName && data.item.credentialRequirementHours ? false : true" @click="credentialNewSubmit(data.item)" variant="primary">Save</b-btn>
      </template>
    </b-table>
    <b-table  :fields="!editUserTable ? fields : fieldsEdit" :items="credentialRequirementData">
      <template slot="credentialName" slot-scope="data">
         <span v-if="!editUserTable">{{ data.item.credentialRequirementName }}</span>
           <b-form-input v-if="editUserTable"  v-model="data.item.credentialRequirementName" />
        </template>
           <template slot="credentialRequirementHours" slot-scope="data">
                    <span v-if="!editUserTable">{{ data.item.credentialRequirementHours }}</span>
           <b-form-input v-if="editUserTable" type="number" v-model="data.item.credentialRequirementHours" />
           </template>
             <template slot="credentialRequirementRolloverId" slot-scope="data">
       <span v-if="!editUserTable">{{ getCredentialRollover(data.item.credentialRequirementRolloverId) }}</span>
           <b-form-select  v-if="editUserTable"  type="" v-model="data.item.credentialRequirementRolloverId" :options="credentialSelectOptions">
            
             </b-form-select>
           </template>
        <template slot="Action" slot-scope="data">
            <b-btn @click="credentialEditSubmit(data.item)" >Save</b-btn>
            </template>
    </b-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
export default {
  components: {

  },
  props: ['credentialsData'],
  name: 'training-credential',
  data () {
    return {
      currentPage: 1,
      credentialRequirementData: [],
      newCredentialGroupName: '',
      newCredentialGroup: false,
      currentlyViewing: null,
      editing: false,
      originalStationsData: [],
      newCredentialRequirement: [{
                  credentialRequirementName: '',
                  credentialRequirementHours: '',
                  credentialRequirementRolloverId: null,
      
      }],
      addNewCredential: false,
      newStationData: {},
      resetComponent: true,
      editUserTable: false,
      searchData: '',
      fields: [
        'credentialRequirementName',
        'credentialRequirementHours',
        'credentialRequirementRolloverId',
        'viewUtilization'
      ],
      fieldsEdit: [
        'credentialRequirementName',
        'credentialRequirementHours',
        'credentialRequirementRolloverId',
        'Action'
      ]
    }
  },
  created(){
    this.getCredentialRequirements()
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
        ]),
        parentGroupingsOverview(){
          if(this.credentialsData){
            return this.credentialsData.filter(p => {
              if(!p.credentialParent){
                return p
              }
            })
          } else {
            return []
          }
       },
        credentialSelectOptions(){
          if(this.credentialRequirementData){
            var array = this.credentialRequirementData
            var optionsArray = []
            for(var i in array){
              optionsArray.push({value: array[i].id, text: array[i].credentialRequirementName})
            }
            optionsArray.push({value: null, text: 'None'})
            return optionsArray
          } else {
            return []
          }
       },
       getCurrentlyViewing(){
         if(this.currentlyViewing && this.credentialsData){
           var array = this.credentialsData.filter(p => {
             return p.credentialParent == this.currentlyViewing
           })
           if(this.editUserTable){
             array.forEach(item => item.credentialStartDate = moment(item.credentialStartDate).format('YYYY-MM-DD'))
             array.forEach(item => item.credentialEndDate = moment(item.credentialEndDate).format('YYYY-MM-DD')) 
             return array
           } else {
             return array
           }
         } else {
           return []
         }
       },
  },
  mounted(){

  },
  watch: {
  },
  methods: {
    checkDateCompliance(start, end){
      if(start && end){
        return moment(end).isAfter(start)
      } else {
        return false
      }

    },
    getCredentialRollover(id){
      var obj = this.credentialRequirementData.find(p => p.id == id)
      if(obj){
        return obj.credentialRequirementName
      } else {
        return 'None'
      }
    },
    noteDataFormat(data){
      if(data){
        return moment(data).format('MM/DD/YY')
      }
    },

    checkChanges(data){
      var currentUserFilter = this.stationsData.filter( (p) => {
        return p.id.match(data.id)
      })
      var currentUser = currentUserFilter[0]
      if( currentUser.stationName != data.stationName ||
          currentUser.stationParent != data.stationParent ||
          currentUser.stationPhone != data.stationPhone ||
          currentUser.stationState != data.stationState ||
          currentUser.stationAddress != data.stationAddress ||
          currentUser.stationCity != data.stationCity ||
          currentUser.stationZip != data.stationZip
          ){
        return true
      }
      else {
      return false
    }
    },
    testData(data){
      console.log(data)
    },
    toggleEdit(data){
      if(this.editing == data){
        this.editing = false;
      } else {
        this.editing = data;
      }
    },
    toggleEditUserTable(event){
      this.editUserTable = !this.editUserTable
    },
    toggleBodyShow(data){
      if(this.bodyShow == data){
        this.bodyShow = false;
      } else {
        this.bodyShow = data;
      }
    },
    getCredentialRequirements(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialRequirementData = response.data
      })
    },
    credentialNewSubmit(data){
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
      data['credentialRequirementAuthorId'] = this.userData.id
      data['credentialRequirementAuthorName'] = this.userData.firstName + ' ' + this.userData.lastName
     axios({
      method: 'post',
      url: '/api/v1/training/credentialrequirement/',
      data: data,
      headers: headers.headers,
    }).then( response => {
      this.getCredentialRequirements()
          this.$emit('updateCrentialRequires')
      this.newCredentialRequirement = [{
                  credentialRequirementName: '',
                  credentialRequirementHours: '',
                  credentialRequirementRolloverId: null,
      
      }]
      
    })
  },
    credentialEditSubmit(data){
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     axios({
      method: 'patch',
      url: '/api/v1/training/credentialrequirement/' + data.id,
      data: data,
      headers: headers.headers,
    }).then( response => {
          this.getCredentialRequirements()
              this.$emit('updateCrentialRequires')
       this.resetComponent = false
        this.$nextTick(function(){
          this.resetComponent = true
      })

      
    })
  },
  credentialDeleteSubmit(data){
    var result = confirm('Are You Sure You Want to Delete this Credential?')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/training/credentialrequirement/' + data.id,
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json'}
     }).then( response => {
         this.getCredentialRequirements()
         this.$emit('updateCrentialRequires')

      
    })
  }
   }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
