<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>

      <div v-if="userData[0].roles_target_id == 'Administrative Chief'
      || userData[0].roles_target_id == 'Administrator'">
          <h4>Admin Panel</h4>


          <div v-for="data in filteredShift" :key="data.nid"><span v-for="(value, key) in data" :key="value.nid">{{ key }} : {{ value.length }} <b-progress :variant="findVariant(key)" :value="findLength(value.length)" class="mb-3"></b-progress></span></div>
          <div v-for="data in filteredStation" :key="data.nid"><span v-for="(value, key) in data" :key="value.nid">{{ key }} : {{ value.length }}</span></div>
          
  </div>

  </div>

</template>

<script>
/*   <b-form-select multiple :select-size="3" text-field="name" value-field="name"  v-model="data.item.field_department_specialties" :options="departmentSpecialties">
    </b-form-select> */
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'hello',
  components: {

  },
  data () {
    return {
        betaTestData: [],
        largestLength: '',
    }
  },
  created(){
    this.betaTestPull()
  },
  computed: {
        testUserRights(){
          if(this.userData[0].roles_target_id == 'Administrative Chief'
            || this.userData[0].roles_target_id == 'Administrator'){
              return false
            } else {
              return true
            }
        },
      ...mapState([
        'apparatusData',
        'userData',
        'deptContactData',
        'getSessionData',
        'shiftsData',
        'stationsData',
        'departmentRanks',
        'departmentSpecialties',
      ]),
      filteredShift(){
        var firstArray = []
        for(var i = 0; i < this.shiftsData.length; i++){
          var Testkey = this.shiftsData[i].name
          var filteredContents = this.betaTestData.filter( (p) => {
            return p.term_node_tid.match(this.shiftsData[i].name) })
            if(filteredContents.length > this.largestLength){
              this.largestLength = filteredContents.length
            }
            if(filteredContents.length > 0){
           firstArray.push({[Testkey]: filteredContents})
         }
         }
        return firstArray
      },
      filteredStation(){
        var firstArray = []
        for(var i = 0; i < this.stationsData.length; i++){
          var Testkey = this.stationsData[i].name
          var stationVar = Testkey.replace("Station ", '')
          var filteredContents = this.betaTestData.filter( (p) => {
            return p.term_node_tid.match(stationVar) })
            if(filteredContents.length > 5){
           firstArray.push({[Testkey]: filteredContents})
         }
         }
        return firstArray
      },





      filterAndCombin2e(){
        var newArray = this.shiftSplit.map( (p) => {
           {
            return this.betaTestData.filter( (e) => {
             return e.term_node_tid.match(p.name)
          })
        }
      })
      console.log(newArray)
      return newArray
    },

  },
  watch: {

  },
  methods: {
    findLength(data){
      return ((data/this.largestLength) * 100)
    },
    findVariant(data){
      if(data == 'A-shift'){
        return "danger"
      }
      if(data == 'B-shift'){
        return "primary"
      }
      if(data == 'C-shift'){
        return "success"
      }

    },
    dataFilter(data){
      var dataFilter = this.betaTestData.filter( (p) => {
        return p.term_node_tid.match(data)
      })
      return dataFilter.length
    },
    betaTestPull(){
      var headers = {headers: {
        'X-CSRF-TOKEN': this.getSessionData,
        'Content-Type' : 'application/hal+json', }}
      axios.get('https://firerescuedev.com/drupalBack/web/betatestapi', headers).then( response => {
        this.betaTestData = response.data
      })
    },



 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style scoped>
div#checkbox1 {
  max-width: 350px;
}
h1, h2 {
  font-weight: normal;
}

</style>
