<template>
  <div class="hello">
    <div class="headerSpan" v-if="apparatusData.length > 0">
    <h4 class="headerSpan">{{ filterApparatusData[0].apparatusName }} Info</h4></div>
   <b-container class="pb-1">
         <b-row>
      <b-col  align="left"><h5>
          <strong>Radio ID:</strong> {{ filterApparatusData[0].apparatusRadioId }}
      </h5></b-col>
    </b-row>
    <b-row>
      <b-col  align="left"><h5>
          <strong>Next PM:</strong> {{ filterApparatusData[0].apparatusNextPm }}
      </h5></b-col>
    </b-row>
    <b-row>
      <b-col align="left"><h5>
      <strong>Veh Tag:</strong> {{ filterApparatusData[0].apparatusTag }}
      </h5></b-col>
    </b-row>
     <b-row>
      <b-col align="left"><h5>
        <strong>Vin:</strong> {{ filterApparatusData[0].apparatusVin }}
      </h5></b-col>
    </b-row>
         <b-row>
      <b-col align="left"><h5>
        <strong>Asset:</strong> {{ filterApparatusData[0].apparatusAssetNumber }}
      </h5></b-col>
    </b-row>
    <b-row>
      <b-col align="left"><h5>
        <strong>Assigned:</strong> {{ filterApparatusData[0].apparatusParent }}
      </h5></b-col>
    </b-row>
        <b-row>
      <b-col align="left"><h5>
        <strong>Medical:</strong> {{ filterApparatusData[0].apparatusMedicalType }}
      </h5></b-col>
    </b-row>
       <b-row>
      <b-col align="left"><h5>
       <strong>Location:</strong> {{ filterApparatusData[0].apparatusLocation }}
      </h5></b-col>
    </b-row>
    <b-card class="my-3 border-primary p-2" v-if="userData.role != 'user'">
      <h5>Update Vehicle PM</h5>
      <b-form-input type="number" v-model="vehicleHoursUpdate" />
      <b-col align="right">
        <b-btn class="mt-1" :disabled="vehicleHoursUpdate == filterApparatusData[0].apparatusNextPm" variant="primary" size="sm" @click="submitPmChanges(filterApparatusData[0].id, vehicleHoursUpdate)">Update</b-btn>
        </b-col>
    </b-card>
    </b-container>

  </div>
</template>

<script>
/*  v-if="userData.role == 'supervisor' || userData.role == 'admin' || userData.role == 'adminChief' || userData.role == 'fireops' || userData.role == 'emsops' || userData.role == 'trainingops' || userData.role == 'shopops'"
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'hello',
  props:[ 'apparatusName'],
  components:{
    
  },
  data () {
    return {
      vehicleHoursUpdate: '',
    }
  },
  created(){

  },
  computed: {
    ...mapState([
      'apparatusData',
      'userData',
      'getSessionData'
    ]),
    filterApparatusData(){
      var app = this.apparatusData.filter(p => {
        var apparatusName = this.apparatusName.replace(/-/g, ' ')
        var appone = p.apparatusName.toLowerCase().match(apparatusName.toLowerCase())
        if(appone){
          return appone
        } else {
          var apptest = p.apparatusName.toLowerCase().match(this.apparatusName.toLowerCase())
          return apptest
        } 
      })
      this.vehicleHoursUpdate = app[0].apparatusNextPm
      return app
    },
  },
  methods: {
    submitPmChanges(vehId, newPm){
     axios({
      method: 'patch',
      url: '/api/v1/taxonomy/apparatus/' + vehId,
      data: {
          
                "apparatusNextPm": newPm,
        },
      headers: {
       'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      var headers = { headers: { "content-type": "application/json", 'Authorization': this.getSessionData, }}
      var payload = { taxonomy: 'APPARATUS',  expire: true, headers: headers}
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
/*       this.renderedVehicleData = []
       this.dataCount = 0
       this.$nextTick(function(){
       this.getRecentVehicleCheckData()
       this.getRecentRepairTrackingData()
       })
 */
         
       })
    }

  },
  watcher: {


  },

}
</script>
<style>

</style>
<style scoped>
.StationContactContainer.card-columns {
  -webkit-column-count: 1;
  column-count: 1;
  -webkit-column-gap: 1.25rem;
  column-gap: 1.25rem;
  padding: 0 5% 0 5%;
}
@media screen and (max-width: 1200px){
.StationContactContainer.card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 980px){
.StationContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 620px){
.StationContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px){
.StationContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
.hello{
  min-width: 300px;
  border-radius: 5px;
  margin: 20px 0 0 10px;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
}
.headerSpan {
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
}
div.headerSpan {
  background: #777;
  width: 100%;
  border-radius: 5px 5px 0 0;
  margin-bottom: 10px;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.StationContactContainer {
  padding-bottom: 10px;
  margin-top: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {

  border-radius: 5px;
}
.A-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.B-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.C-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Day-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
a.nav-link.active.A-shift {
  background-color: red;
  display: none;
}
.A-shift{
  background: #e2070d;
  background: -moz-linear-gradient(left, #e2070d 0%, #e2070d 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift{
  background: #201B9F;
  background: -moz-linear-gradient(left, #201B9F 0%, #201B9F 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
.C-shift{
  background: #0FB906;
  background: -moz-linear-gradient(left, #0FB906 0%, #0FB906 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
      border-radius: 20px 20px 0 0;
}
.Day-shift{
  background: #E4CB07;
  background: -moz-linear-gradient(left, #E4CB07 0%, #E4CB07 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}

@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
