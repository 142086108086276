<template>
<div>
  <b-card header-tag="header" footer-tab header-bg-variant="primary" no-body v-if="trainingModule">
    <div slot="header">
      <span class="text-light">
        <h4>{{ trainingModule.trainingModuleName }}</h4>
        <em v-if="trainingModule.trainingModuleType"> Training Type: {{ trainingModule.trainingModuleType.toUpperCase() }}</em>
        </span>
    </div>

      <b-card no-body>
            <b-card v-for="(group, indexg) in trainingModule.trainingModuleFormData" :key="indexg"
                      :border-variant="group.groupVariant"
                       header-tag="header"
                      :header-bg-variant="group.groupVariant"
                      :header-text-variant="group.groupVariant == 'warning' || group.groupVariant == 'default' ? 'dark' : 'white'">
                  <div slot="header"><span>{{ group.groupName }}</span>
                  </div>
                <b-row class="mb-2">
                      <b-col v-for="(field, index) in group.groupFields" :key="index" class="mt-2" :lg="field.size" :label-for="field.objKey" v-if="field.itemType == 'form'">
                        <b-row class="p-0 m-0">
                        <b-col class="p-0 m-0 pr-1" :align="field.size == '13' ? '' : 'right'"><span class="font-weight-bold">{{ field.label ? field.label : '' }}:</span></b-col>
                        <b-col  class="p-0 m-0 pl-1" :align="field.size == '13' ? '' : 'left'">
                           <span v-if="field.type == 'text' || field.type == 'number' || field.type == 'select'" >{{ getTrainingFormData(field).value.value }}</span>
                           <span v-else-if="field.type == 'textarea'" >{{ getTrainingFormData(field).value.value }}</span>
                           <span v-else-if="field.type == 'upload'"><b-btn v-for="(link, indexL) in getTrainingFormData(field).value.value" variant="link" size="sm" :key="indexL" @click="viewFile(link.url)">{{ link.title }}</b-btn></span>
                           <span v-else-if="field.type == 'boolean'"><b-form-checkbox class="mr-0, float-left" disabled v-model="getTrainingFormData(field).value.value" />{{ field.displayText }}</span>
                           <span v-else-if="field.type == 'datetime' || field.type == 'date' || field.type == 'time'">{{ formatDate(getTrainingFormData(field).value.value) }}</span>
                           <span v-else-if="field.type == 'dataRef'">{{ getRefItem(field, getTrainingFormData(field).value.value) }}</span>
                           <span v-else-if="field.type == 'signature'"><b-img :src="getTrainingFormData(field).value.value" /></span>
                           <span v-else class="text-danger">No Training Form Items</span>
                          </b-col></b-row>
                      </b-col>
                      </b-row>
                                          
                      </b-card>
            
            </b-card>
          <b-container class="my-4">
            <h5>Users Tagged as Completed</h5>
              <multiselect v-if="usersAssigned" v-model="usersAssigned" :disabled="checkForSubmissionPerm" :options="deptContactData" label="lastName" track-by="lastName" :multiple="true" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Assigned Filter by User">
                    <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.firstName }} {{ option.lastName }} ({{ option.employeeId }}) | {{ option.stationAssignment }}</span><span class="custom__remove" @click="remove(option)">X</span></span></template>
                    <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }} - {{ props.option.shiftAssignment }}</span>
                    </template>
             </multiselect>
          </b-container>
           <b-container class="pr-5" >
             <b-row><b-col align="right"></b-col>
           <b-col v-if="training.trainingRecordCredentials" cols="*" align="right" class="pl-0 ml-0">Credential Application: 
      <b-badge variant="danger" class="mr-1" v-for="(cred, index) in training.trainingRecordCredentials" :key="index">{{ getCredentialName(cred).credentialRequirementName }}</b-badge>
            </b-col>
            </b-row>
        </b-container>
          <div slot="footer">
             <p> Submitted by {{ training.trainingRecordAuthor }} on {{ training.trainingRecordCompletionDate }} for {{ training.trainingRecordHours }} Hours </p>
          <b-card title="Validation Required" v-if="trainingRequiresValidation">
                <p v-if="checkForValidationRequirements">Verify the user completed the module as required and update as validation</p>
                <p v-if="!checkForValidationRequirements">Sorry, you are unable to validate this training per the validation requirements</p>
                <b-form-checkbox v-if="checkForValidationRequirements" v-model="validationConfirmation" :check-value="true" :uncheck-value="false">This training module meets the requirements for validation</b-form-checkbox>
                <b-btn v-if="checkForValidationRequirements" variant="success" :disabled="!validationConfirmation" @click="submitValidatedTraining(training)">Validate</b-btn>
          </b-card>
          <b-card title="Modify Submission" v-if="(training.trainingRecordValidationId == null || !trainingRequiresValidation || training.trainingRecordValidationId == userData.id) && !checkForSubmissionPerm">
          <b-row v-if="!checkForSubmissionPerm">
            <b-col>
            <b-checkbox class="mt-2" v-model="trainingCompletionConfirm" :check-value="true" :uncheck-value="false">I affirm the training above was completed per directive on the date listed</b-checkbox>
               <b-checkbox class="mt-2" v-model="trainingCompletionConfirmDelete" :check-value="true" :uncheck-value="false">I wish to Delete this record</b-checkbox>
            </b-col>
            <b-col cols="*" align="right">
              <b-btn class="mt-1" size="sm" variant="success" 
              :disabled="usersAssigned.length > 0 && trainingCompletionConfirm ? false : true"
              @click="updateTraining(training)"
              >Update</b-btn>
              <b-btn class="mt-1" size="sm" variant="danger"
              @click="deleteTraining(training.id)"
              v-if="trainingCompletionConfirmDelete"
              >Delete</b-btn>
      
            </b-col>
          </b-row>
            </b-card>
        </div>
        </b-card>
       
</div>
</template>
<script>

import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
  props:['training'],
components:{
 'multiselect' : Multiselect,
},
  data () {
    return {
      trainingModule: false,
      trainingDrills: [],
      trainingRequiresValidation: false,
      validationConfirmation: false,
      credentialRequirementsData: [],
      usersAssigned: [],
      validationRequired: false,
      trainingCompletionConfirm: false,
      trainingCompletionConfirmDelete: false

    }
  },
  created(){
    this.getTrainingModules()
    this.getCredentials()

  },
  watch: {
   
  },
  computed:{
    ...mapState([
    'getSessionData',
    'deptContactData',
    'stationsData',
    'userData',
    'config',
    'userPosts'
    ]),
    checkForValidationRequirements(){
      var validation = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'validation')
      if(this.training.trainingModuleValidationId == this.userData.id){
        return true
      } else if (validation.perms && validation.perms.type == 'user'){
        var userf = validation.perms.user.find(p => p.id == this.userData.id)
          if(userF){
                 return true
                } else {
                  return false
           }
      } else if (validation.perms && validation.perms.type == 'role'){
         var indexR = validation.perms.role.indexOf(this.userData.role)
          if(indexR != -1){
                 return true
                } else {
                  return false
           }
      } else {
        return false
      }
    },
 checkForSubmissionPerm(){
          if(this.trainingModule.trainingModuleSubmissionVisibility){
            var role = this.userData.role
            var self = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'self')
            var validation = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'validation').perms
            if(self){
              var index = self.perms.indexOf(role)
              if(index != -1 || this.userData.role == 'admin'){
                if(validation.boo == false){
                     this.validationRequired = false 
                     return false
                     } else {
                    this.validationRequired = true
                    if(validation.type == 'role'){
                      var indexR = validation.role.indexOf(role)
                      if(index != -1){
                         this.validationRequired = false
                        return false
                  
                      } else {
                        return true
                      }
                    } else if(validation.type == 'user'){
                      var userf = validation.user.find(p => p.id == this.userData.id)
                        if(userF){
                           this.validationRequired = false
                          return false
                        } else {
                          return true
                        }
                      } else {
                        return true
                      }
                    }
                  
              } else {
                return true
              }
            } else {
              return false
            }
          } else {
              return false
          }
         
        },

    },
    methods: {
      getCredentialName(id){
        if(id){
          var obj = this.credentialRequirementsData.find(p => p.id == id)
          if(obj){
            return obj
          } return {credentialRequirementName: 'Unknown'}
        }
        return {credentialRequirementName: 'Unknown'}
      },
      formatDate(data){
        var string = ''
        if(data){
        if(data.date){
           var string = moment(data.date, 'YYYY-MM-DD').format('MM/DD/YYYY')
        }
        if(data.time){
          if(data.date){
            var pre = ' at '
          } else {
            var pre = ''
          }
          var string = string + pre + data.time
        }
        return string
        } return 'Invalid Date'

      },
      viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
               var a = document.createElement('a')
                    window.open(response.data.signedRequest, '_blank')
       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
      getRefItem(field, data){
      
        var ret = ''
        for(var i = 0; i < data.length; i++){
          ret = ret + data[i][field.refKey]
          if(i < data.length - 1){
            ret = ret + ', '
          }
        }
        return ret
      },
      getTrainingFormData(field){
     
        var training = this.training.trainingRecordFormData
        var data = training[field.objKey]
        if(data){
          return data
        } else {
          return 'None'
        }
      },
      getTrainingModules(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingmodule/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.trainingDrills = response.data
        this.setUpForm(response.data, this.training)

      })
    },
    setUpForm(drills, training){
        var drill = drills.find(p => p.id == training.trainingRecordModuleId)
        var users = []
        var assigned = training.trainingRecordUsers
        for(var a in assigned){
          var user = this.deptContactData.find(p => p.id == assigned[a])
          users.push(user)
        }
        this.usersAssigned = users
        if(drill){
         // console.log(training)
          this.trainingModule = drill 
        if(training.trainingRecordValidationId && training.trainingRecordComplete == false){
            var validation = drill.trainingModuleSubmissionVisibility.find(p => p.permtype == 'validation')
            if(validation.perms && validation.perms.boo == true){
              this.trainingRequiresValidation = true
            }
        }
        } else {
          this.trainingModule = false
        }
      
      },
      deleteTraining(id){
          axios({
          method: 'delete',
          url: '/api/v1/training/trainingrecord/' + id,
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
            }
            }).then( response => {
               this.$emit('CloseTrainingModal')
         })
      },
      updateTraining(training){
    //     var form = training.trainingRecordFormData
    //     form['validationData'] = { user: this.userData, date: moment().toDate() }
        axios({
          method: 'patch',
          url: '/api/v1/training/trainingrecord/' + training.id,
          data: {
            "trainingRecordUsers": this.usersAssigned.map(p => p.id),
            "trainingRecordComplete": true, 
          //  "trainingRecordFormData": form, 
          },
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
            }
            }).then( response => {
              this.trainingRequiresValidation = false
              this.setUpForm(this.trainingDrills, response.data)
              this.$emit('CloseTrainingModal')
         })
    }, 
      submitValidatedTraining(training){
         var form = training.trainingRecordFormData
         form['validationData'] = { user: this.userData, date: moment().toDate() }
        axios({
          method: 'patch',
          url: '/api/v1/training/trainingrecord/' + training.id,
          data: {
            "trainingRecordUsers": this.usersAssigned.map(p => p.id),
            "trainingRecordComplete": true, 
            "trainingRecordFormData": form, 
          },
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
            }
           }).then( response => {
        this.trainingRequiresValidation = false
        this.setUpForm(this.trainingDrills, response.data)
              this.$emit('CloseTrainingModal')
       })
      }, 
      getCredentials(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialRequirementsData = response.data
      })
    
    },


},

}
</script>

<style scoped>
.credential-table {
  text-align: center;
}
.changeWidth {
  width: 80%;
  margin: 0 auto 0;
  text-align: center;
}
.userPostIcon{
  position: relative;
  margin: 0px 0 -80px 0px;
  width:32px;
  height:32px;
  opacity: 0.3;
  
}
.blogadjust {
  text-align: center;
    width: 80%;
    margin: 0 auto 0;
}
.userPostForms{
  width:80%;
  padding-top: 10px;
  margin: 0 auto 0;
  border-bottom: 1px solid #aaa;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}



#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {
  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
@media screen and (max-width: 768px) {
.userPostIcon{
    display: none;
  
}
}

</style>
