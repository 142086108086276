<template>
<div>
        <b-navbar class="blogSpanHeader" toggleable="lg" type="dark" variant="dark" v-if="formatedTableValuesPreFilter">
           <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                     <b-navbar-brand class="ml-2">Scheduled ({{ formatedTableValues.length }})</b-navbar-brand>
            <b-collapse is-nav id="nav_collapse">
              <b-navbar-nav>
        <b-nav-form class="ml-2">
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchBusiness" placeholder="Business Name"></b-form-input>
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchAddress" placeholder="Address"></b-form-input>
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchAssigned" placeholder="Assigned"></b-form-input>
          <b-form-select class="ml-2" size="sm"  v-model="sortOrder" v-if="false">
            <option value="desc">Newest</option>
            <option value="asc">Oldest</option>
          </b-form-select>
      
      <b-form-radio-group  class="ml-3" size="sm"  id="radios2" v-model="sortBySelected" name="radioSubComponent">
        <b-form-radio :value="false" class="text-light">Exact</b-form-radio>
        <b-form-radio :value="true" class="text-light">Contains</b-form-radio>
      </b-form-radio-group>
        </b-nav-form>
              </b-navbar-nav>
                    </b-collapse>
          <b-navbar-nav class="ml-auto">
            <b-btn variant="outline-success"  size="sm" @click="dataViewMode == 'Map' ? (dataViewMode = 'Table', disableMapStatus = false, mapStatus = 'both') : (dataViewMode = 'Map', disableMapStatus = true, mapStatus = true)">{{ dataViewMode == 'Map' ? 'Table' : 'Map' }}</b-btn>
            </b-navbar-nav>

      </b-navbar>
     <b-table v-if="dataViewMode == 'Table'" :fields="fields" :items="formatedTableValues" :per-page="10" :current-page="currentPage" stacked="lg">
        <template slot="select" slot-scope="data">
          <b-form-checkbox v-model="data.item.select" />
        </template>
        <template slot="inspectionDate" slot-scope="data">
          <b-badge :title="getDateFormatTitle(data.item.inspectionDate, data.item.inspectionAuthor)" :variant="getBadgeVariant(data.item)">{{ getDateFormat(data.item.inspectionDate)}}</b-badge>
        </template>
        <template slot="complete" slot-scope="data">
          <b-btn variant="outline-info" size="sm" @click='emitNewInspection(data.item)'>Complete</b-btn>
        </template>
        <template slot="View Previous" slot-scope="data">
          <b-btn variant="outline-primary" size="sm" @click='getInspectionsView(data.item.inspectionBusinessId)'>View</b-btn>
        </template>
        <template slot="reschedule" slot-scope="data">
          <b-btn variant="outline-dark" size="sm" @click='reschedule(data.item)'>Update</b-btn>
        </template>
        <template slot="Remove" slot-scope="data">
          <b-btn variant="outline-danger" size="sm" @click='deleteItem(data)'>Delete</b-btn>
        </template>
      </b-table>
       <b-pagination class="pt-2" align="center" size="md" v-if="formatedTableValues && dataViewMode == 'Table'" :total-rows="formatedTableValues.length" v-model="currentPage" :per-page="10" />
      <businessMap v-if="dataViewMode == 'Map'" :responseView="false" :availableBusiness="formatedTableValues" @showBusiness="emitItem($event)" />
  <b-modal v-model="scheduleModal" title="Inspection Scheduler">
   <b-form-select v-model="updateInspection.taxonomyScheduleType" :options="taxonomyTags" class="mb-2" />
             <multiselect
              class="mb-2"
              v-model="updateInspection.taxonomyScheduleId" 
              :disabled="!updateInspection.taxonomyScheduleType"
              :options="getOptionsFromSelection(updateInspection.taxonomyScheduleType)" 
              :multiple="false" 
              :close-on-select="true" 
              :clear-on-select="true" 
              :hide-selected="true" 
              :preserve-search="true" 
               :placeholder="updateInspection.taxonomyScheduleType == 'stationsData' ? 'Search Station Name' : updateInspection.taxonomyScheduleType == 'deptContactData' ? 'Search User Last Name' : updateInspection.taxonomyScheduleType == 'apparatusData' ? 'Search Apparatus Name' : updateInspection.taxonomyScheduleType == 'logisticAssetData' ? 'Search Asset Id' : 'Please Select a Type'"
              :label="updateInspection.taxonomyScheduleType == 'stationsData' ? 'stationName' : updateInspection.taxonomyScheduleType == 'deptContactData' ? 'lastName' : updateInspection.taxonomyScheduleType == 'apparatusData' ? 'apparatusName' : updateInspection.taxonomyScheduleType == 'logisticAssetData' ? 'assetId' : ''" 
              :track-by="updateInspection.taxonomyScheduleType == 'stationsData' ? 'stationName' : updateInspection.taxonomyScheduleType == 'deptContactData' ? 'lastName' : updateInspection.taxonomyScheduleType == 'apparatusData' ? 'apparatusName' : updateInspection.taxonomyScheduleType == 'logisticAssetData' ? 'assetId' : ''">

   <template slot="option" slot-scope="props">
      <div class="option__title" v-if="updateInspection.taxonomyScheduleType == 'stationsData'">{{ props.option.stationName }}</div>
 <div class="option__title" v-if="updateInspection.taxonomyScheduleType == 'apparatusData'">{{ props.option.apparatusName }}</div>
                    <div class="option__title" v-if="updateInspection.taxonomyScheduleType == 'logisticAssetData'">{{ props.option.assetDescription }} #ID:{{ props.option.assetId }} #SN:{{ props.option.assetSerialNumber }}</div>
                    <div class="option__title" v-if="updateInspection.taxonomyScheduleType == 'deptContactData'">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                   <div class="option__body" v-if="updateInspection.taxonomyScheduleType == 'deptContactData'">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
   </template>

</multiselect>
          <b-container >
                    <b-row :align-h="documentWidth ? 'center' : 'start'">
                      <b-col cols="*">
                  <datepicker
                    class="datepickervue"
                    v-model="updateInspection.dateSelected"
                    :inline='documentWidth'
                    name="inlineCal">
                  </datepicker>
                      </b-col></b-row>
                  </b-container>
                  <template slot="modal-footer">
        <b-btn variant="primary" :disabled="!updateInspection.taxonomyScheduleId" @click="scheduleInspection(updateInspection)">Schedule</b-btn>
                  </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import printJS from 'print-js'
import DatePicker from '../../inputfields/Datepickermod.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import Leaf from 'leaflet'

import businessMap from './inspectionMap.vue'
import querystring  from 'querystring';
import Multiselect from 'vue-multiselect'
import arrayData from '../../../../files/dispatchReport.js'
import convert from 'xml-js'
export default {
  props:["formTableStyle", "submittedBusiness", "inspectionsData"], 
components:{
      'businessMap' : businessMap,
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,

},
  data () {
    return {
        showTutVideo: false,
        edit: true,
        imageResponseData: [],
        updateInspection: {},
        postData: '',
        timeData: '',
        imageSrc: '',
        searchGrid: '',
        scheduleModal: false,
        searchBusiness: '',
        searchStation: '',
        searchAddress: '',
        searchAssigned: '',
        currentPage: 1,
        dateSelected: moment().format("MM/DD/YYYY"),
        bootstrapDate: moment().format("YYYY-MM-DD"),
        taxonomyScheduleType: null,
        taxonomyTags: [
        {text: 'User', value: 'deptContactData'},
        {text: 'Station', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'None', value: null }
        ],
        stationSelectValue: '',
        showGroup: 'business',
        showModalConfirmation: false,
        pictureUpload: '',
        searchData: '',
        sortOrder: 'asc',
        carouselToggle: '',
        pictureFileArray: [],
        imageLoader: '',
        holdValues: {},
        taxonomyScheduleId: null,
        patchData: false,
        completedLoad: false,
        ppObj: {},
        fields: [],
        tableRows: [],
        formatedTableValuesPreFilter: [],
        jsonArray: [],
        buildData: [],
        mapStatus: 'both',
        disableMapStatus: false,
        dataViewMode: 'Table',
        sortBySelected: true,
        windowWidth: '',
        previouslyCompletedInspections: [],

    }
  },
  created(){
  var data = arrayData.arrayData
  this.getWindowWidth()
  window.addEventListener('resize', this.getWindowWidth);
  var jsonArray = []
  for(var i in data){
    var result = convert.xml2json(data[i], {compact: true, spaces: 0})
    var parsed = JSON.parse(result)
    jsonArray.push(parsed)
  }
    this.buildData = []
   this.jsonArray = jsonArray
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  mounted(){
    var data = _.cloneDeep(this.submittedBusiness)
    var inspections = _.cloneDeep(this.inspectionsData)
    this.buildTableData(this.formTableStyle, data)
    this.setupTableValues(this.formTableStyle, inspections, data)
  
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'Businesstatus',
          'deptContactData'
        ]),
        documentWidth(){
          if(this.windowWidth > 992){
            var boo = true
          } else {
             var boo = false
          }
          return boo
        },
        formatedTableValues(){
          if(this.searchGrid){
          var grid = this.formatedTableValuesPreFilter.filter(p => {
                  if(this.searchGrid){ 
                  return p.gridNumber.toString().match(this.searchGrid.toString())
                  } else {
                    return p
                  }
          })
        } else { var grid = this.formatedTableValuesPreFilter }
        if(this.searchStation){
          var station = grid.filter(p => {
            return  p.primaryStation.includes(this.searchStation)
          })
         } else { var station = grid }
          if(this.searchBusiness){
           var business = station.filter(p => {
             if(this.sortBySelected){
               var searchOpt = this.searchBusiness.toLowerCase().split(' ')
               var searchResult = true
               for(var i in searchOpt){
                 var index = p.businessName.toLowerCase().includes(searchOpt[i])
                   if(!index){
                     searchResult = false
                   }
                 }
                 if(searchResult){
                   return p
                 }
               
             } else {
                return p.businessName.toLowerCase().includes(this.searchBusiness.toLowerCase()) 
             }
           })
         } else { var business = station   }
         var map = business.filter(p => {
                 if(this.mapStatus == false){

                    if(!p.map){
                      return p
                    }
                  } else if (this.mapStatus == true){
                    if(p.map){
                      return p
                    }
                  } else {
                    return p
                  }
         })
         if(this.searchAddress){
           var address = map.filter(p => {
             if(this.sortBySelected){
               var searchOpt = this.searchAddress.toLowerCase().split(' ')
               var searchResult = true
               for(var i in searchOpt){
                 var index = p.streetNumberStreetStreetSuffix.toLowerCase().includes(searchOpt[i])
                   if(!index){
                     searchResult = false
                   }
                 }
                 if(searchResult){
                   return p
                 }
               
             } else {
                return p.streetNumberStreetStreetSuffix.toLowerCase().includes(this.searchAddress.toLowerCase()) 
             }
           })
         } else {
           var address = map
         }
        if(this.searchAssigned){
           var assigned = address.filter(p => {
             if(p.assigned){
            return  p.assigned.toLowerCase().includes(this.searchAssigned.toLowerCase())
             }
           })
         } else {
           var assigned = address
         }
         return _.orderBy(assigned, ['inspectionDate'], ['asc'])
        },
    
    

    },
  methods:{
    getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : type == 'deptContactData' ? obj.firstName + ' ' + obj.lastName : "Unknown"
      } else {
        return null
      }
      } else {
        return null
      }
    },
    reschedule(data){
      console.log(data)
      var item = _.cloneDeep(data)
      if(item.inspectionAssignedType && item.inspectionAssignedId){
        var array = this.getOptionsFromSelection(item.inspectionAssignedType)
        var objHold = array.find(p => p.id == item.inspectionAssignedId)
      }
      var obj = {
        id: item.id,
        dateSelected: moment(item.inspectionDate).format('MM-DD-YYYY'),
        taxonomyScheduleType: item.inspectionAssignedType,
        taxonomyScheduleId: objHold
      }
      this.updateInspection = obj
      this.scheduleModal = true
    },
    updateDate(date, event) {
      this.dateSelected = moment(date).format("MM/DD/YYYY");
      this.bootstrapDate = moment(date).format("YYYY-MM-DD");
    },
    getWindowWidth(event) {
        this.windowWidth = document.documentElement.clientWidth;
    },
    emitNewInspection(data){
      if(data){
        this.$emit('createScheduledInspection', data)
      }
    },
    getOptionsFromSelection(data){
    if(data){
     // console.log(data)
    return this[data]
    } else {
      return []
    }
    },
    getDateFormat(data){
      if(data){
        return moment(data).fromNow()
      } else {
        return 'invalid'
      }
    },
    getDateFormatTitle(data, author){
      if(data){
        var time = moment(data).format('MM/DD/YYYY HH:mm')
        return 'Scheduled for ' + time + ' by ' + author
      } else {
        return 'invalid'
      }
    },
    buildTableData(table, data){
      for(var i in table){
        var obj = {key: table[i].key, label: table[i].label, include: true }
        this.fields.push(obj)
      }
      this.fields.push({key: 'inspectionDate', label: 'Scheduled'})
      this.fields.push({key: 'assigned', label: 'Assigned To'})
      this.fields.push('complete')
      this.fields.push('View Previous')
      if(this.userData.role == 'adminchief' || this.userData.role == 'admin'){
        this.fields.push('reschedule')
      }
      if(this.userData.role == 'admin' && false){
        this.fields.push('Remove')
      }
     
    },
    getInspectionsView(id){
      this.$emit('getInspectionsView', id)
    },
    setupTableValuesLoop(obj, vals){
        for(var p in vals){
          if(vals[p].key != 'map'){
          obj[vals[p].key] = vals[p].value
          } else if (vals[p].key == 'map'){
            obj[vals[p].key] = vals[p]
          }
        }  
      return obj
    },
    setupTableValues(table, inspections, dataArray){
      var keys = this.fields.filter(p => p.include)
      keys = keys.map(p => p.key)
      var inspectionMap = inspections.map(item => {
              var obj = item
              obj['business'] = dataArray.find(p => p.id == item.inspectionBusinessId)
              var tableData = obj.business && obj.business.businessTableData ? obj.business.businessTableData : []
              obj['assigned'] =  this.getTaggedType(item.inspectionAssignedType,item.inspectionAssignedId)
              for(var k in keys){
                var value = tableData.find(p => p.key == keys[k])
                obj[keys[k]] = value && value.value ? value.value : 'Unknown' 
              }
              return obj

      })
      this.getPreviousInspectionData(inspectionMap)

      this.formatedTableValuesPreFilter = inspectionMap
    },
    getItemMap(item){
      if(!item){
          var southWest= {lat: 27.128813241669747, lng: -82.99415588378908}
          var northEast = {lat: 27.54480631775389, lng: -82.03765869140626}
          var lngSpan = northEast.lng - southWest.lng
          var latSpan = northEast.lat - southWest.lat
          return {obj: {y: southWest.lat + latSpan * Math.random(), x: southWest.lng + lngSpan * Math.random()}}
      } else {
        return item
      }
    },
    getPreviousInspectionData(data){
   //   console.log('MAP')
   //   console.log(data)
      var holdMap = data
      var ids = holdMap.map(p => p.inspectionPreviousId)
      ids = ids.filter(p => p)
      if(ids && ids.length > 0){
        for(var i in ids){
            this.axiosPullLoop(ids[i])
        }
  
      }
    },
    axiosPullLoop(id){
      axios({
        method: "get",
        url: "/api/v1/preplan/inspection/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }
      }).then(response => {
           if(response.data && response.data[0]){
             var obj = response.data[0]
             var index = this.previouslyCompletedInspections.indexOf(obj.id)
             if(index == -1){
               this.previouslyCompletedInspections.push(obj)
             }
             if(obj.inspectionPreviousId){
               this.axiosPullLoop(obj.inspectionPreviousId)
             }
           }
        })
    //    console.log(this.previouslyCompletedInspections)
    },
    getBadgeVariant(obj){
      if(obj){
      if(!obj.inspectionPreviousId){
        return 'success'
      }
      var newObj = obj
      var array = []
      do {
        var newObj = this.previouslyCompletedInspections.find(p => p.id == newObj.inspectionPreviousId)
        if(newObj){
          array.push(newObj)
        }
      }
      while (newObj && newObj.inspectionPreviousId);
      if(array.length > 0 && array.length < 3){
        return 'warning'
      }
      if(array.length >= 3){
        return 'danger'
      } else {
        return 'success'
      }
      }
    },
    emitItem(data){
   //   console.log('data')
      var obj = this.submittedBusiness.find(p => p.id == data)
      this.$emit('showbusiness', obj)
    },
    deleteItem(data){
   //   console.log(data)
  
            axios({
        method: "delete",
        url: "/api/v1/preplan/inspection/" + data.item.id,
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }
      }).then(response => {
           this.$emit('getInspections')
        })
    },
    getTableItems(display, fields){
      var fieldSort = fields.filter(p => p.order == display)
       var obj = {}
      for(var i in fieldSort){
        var key = fieldSort[i].idtag
        var value = this.holdValues[fieldSort[i].vmodel].value
        if(value == undefined){
          value = 'Not Entered'
        }
        if(fieldSort[i].type == 'computed'){
          value = this.getComputedValues(fieldSort[i])
        }
        if(fieldSort[i].type == 'taxonomySingleSelect'){
          value = this.holdValues[fieldSort[i].vmodel].value[fieldSort[i].taxitem]
        }
        if(fieldSort[i].type == 'reference'){
          value = this.holdValues[fieldSort[i].reference].value[fieldSort[i].refitem]
        }
        obj[key] = value
      }
    var arr = []
    arr.push(obj)
    return arr
    },
    getComputedValues(data){
      var array = []
      for(var i in data.ref){
        var hold = this.holdValues[data.ref[i]].value
        if(hold != undefined && hold != null && hold != ''){
          array.push(hold)
        }
      }
      var operator = function(a, b){ return a * b}
      var holdValue = 0
      for(var i in array){
        if(i == 0){
          var item = operator(array[i], array[1])

          holdValue = item
        } else if(i > 1) {
           var item = operator(holdValue, array[i]) 
        }
      }
      return holdValue
    },
    getDescendantProp(obj, desc) {
    var arr = desc.split(".");
    while(arr.length && (obj = obj[arr.shift()]));
    return obj;
    },
    scheduleInspection(){
      var data = {
               inspectionDate: moment(this.updateInspection.dateSelected, 'MM-DD-YYYY').toISOString(), 
               inspectionAuthor: this.userData.firstName + ' ' + this.userData.lastName, 
               inspectionAuthorId: this.userData.id,
               inspectionAssignedType: this.updateInspection.taxonomyScheduleType, 
               inspectionAssignedId: this.updateInspection.taxonomyScheduleId ? this.updateInspection.taxonomyScheduleId.id : null,
       }
      axios({
        method: "patch",
        url: "/api/v1/preplan/inspection/" + this.updateInspection.id,
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }
      }).then(response => {
         this.$emit('getInspections')
         this.scheduleModal = false

        })

 
     },


}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.datepickervue {
  width: 300px;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.numberInputSize {
  width: 70px;
}
.textInputSize {
  width: 140px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
.datePickerClassMobile {
  display: none;
}
@media screen and (max-width: 992px) {
  .time-picker.timeDesk{
    display: none;
  }
    .datePickerClass {
    display: none;
  }
  .datePickerClassMobile {
    display: block;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
