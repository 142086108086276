<template>
  <div>
<b-container>
  <h4>{{ blog.title }}</h4>
<div><img v-if="blog.blogAuthorId" class="blog-img" :src="imageSrcUrl(blog.blogAuthorId)" /></div>
  <div class="pictureHouse" v-if="blog.title">
  <span class="blogSubData"> {{ blog.blogAuthor }} | {{ blog.blogType }}</span>
  <h6 class="blogSubData">{{ blogTimeAdjust(blog.createdAt) }}</h6>
  </div>
  <div class="blogBody" v-if="blog.blogBody">
  <div v-html="blog.blogBody"></div></div>
  <div v-if="blog.blogPicture.length > 0">
    <b-carousel id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                  controls
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template v-for="(pic, index) in blog.blogPicture">
                        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" >
                            
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
  </div>
  <div v-if="false"><div v-for="img in blogFile" :key="img.index"><a target="_blank" :href="imageSrcUrl(img.url)">{{ img.name }}</a></div></div>
  <div v-if="blog.blogVideo.length > 0">
        <b-carousel id="carousel2"
                                 style="text-shadow: 1px 1px 2px #333;"
                                 indicators
                                 :interval="0"
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template  v-for="(video, index) in blog.blogVideo">
                        <b-carousel-slide :key="index">
                            <div slot="img" class="videoWrapper">
                              <iframe  v-if="video" class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
 
  </div>
</b-container>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
props:["blog", "calendarEvents"],
components:{

},
  data () {
    return {
        showModal: false,
        search:"",
        slide: 0,
        sliding: false,
    }
  },
  computed: {
    ...mapState([
      'deptContactData',
      'siteconfig'
    ])
  },
   methods:{
     userPhotoLink(fileName){
        if(fileName.charAt(0) == '/'){
      return this.siteconfig.deptStore + fileName.replace('/', '')
        } else {
          return  this.siteconfig.deptStore + fileName
        }
      },
     showModalToggle(){
       if(this.showModal == false){
         document.body.class = 'modal-open';
       }
       else{
         document.body.classList.remove('modal-open');
       }
       this.showModal = !this.showModal
     },
    imageSrcUrl(data){
      var user = this.deptContactData.filter( p => 
      { return p.id == data})
      if(user != undefined && user.length > 0 && user[0].picture){
      var picture = this.userPhotoLink(user[0].picture)
      return picture
      } else {
        return this.siteconfig.deptStore + 'files/default_images/user.png'
      }
    },
    blogTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY - HH:mm')
    },
       getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
      },
  
  },
}
</script>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}

.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
</style>
