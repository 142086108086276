<template>
<div id="homelanding">
  <h4 v-if="form && form.formName">{{ form.formName }}</h4>
  <b-container class="mb-2 px-1 px-md-4">
    <b-row>
      <b-col cols='*' class="pt-2 font-weight-bold">Start Date</b-col>
      <b-col><b-form-input type="date" v-model="startDate" /></b-col>
      <b-col cols="*" class="pt-2 font-weight-bold">End Date</b-col>
      <b-col><b-form-input  type="date" v-model="endDate" /></b-col>
      <b-col cols="*"><b-btn variant="primary" @click="getPreviousFormIds(startDate, endDate)">Search</b-btn></b-col>
    </b-row>
  </b-container>
  <b-container v-if="previousFormsReturnedFilterOptions">
    <b-row>
      <b-col cols="3" v-for="(option, index) in previousFormsReturnedFilterOptions" :key="index">
        <h5>{{ option.text }}</h5>
        <b-form-select :disabled="!(option.options)" :options="option.options" v-model="formOptionHold[option.key]" @input="updateValue($event)" />
      </b-col>
           <b-col cols="3">
             <b-btn class="mt-4" @click="exportDataToExcel" variant="info">Export</b-btn>
             <b-btn class="mt-4" variant="outline-danger" @click="formOptionHoldFilter = null, setNullValuesToFormOptionHold()">Reset</b-btn>
           </b-col>
    </b-row>
  </b-container>
  <b-table :items="previousFormsReturnedFiltered" :fields="fields">
    <template slot="createdAt" slot-scope="data">
      {{ getDateTime(data.item.createdAt) }}
    </template>
    <template slot="formSubLocked" slot-scope="data">
         <b-badge variant="success" v-if="data.item.formSubLocked">&#10004;</b-badge>
    </template>
    <template slot="view" slot-scope="data">
      <b-btn variant="info" size="sm" @click="viewPreviousFormEntry(data.item)">View</b-btn>
    </template>
    <template slot="delete" slot-scope="data">
      <b-btn variant="danger" size="sm" @click="removeDigitalForm(data.item.id)">Del</b-btn>
    </template>
  </b-table>

</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import Papa from 'papaparse'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,

},
props: ['previousFormId', 'form'],
data() {
    return {
          searchData: '',
          previousFormSubData: [],
          startDate: '',
          endDate: '',
          formOptionHold: {},
          formOptionHoldFilter: null,
          
}
},
created () {
},
beforeDestroy(){

},
mounted(){
  this.endDate = moment().add(1, 'd').format('YYYY-MM-DD')
  this.startDate = moment().subtract(3, 'months').format('YYYY-MM-DD')
  this.getPreviousFormIds(this.startDate, this.endDate)
},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'logisticAssetData'
  ]),
  fields(){
    var array = [
      'createdAt',
      {key: 'formSubAuthor', label: 'Author'},
    ]
    if(this.form && this.form.formTableCols){
      var ftc = this.form.formTableCols
      for(var f in ftc){
        array.push({key: ftc[f].key, label: ftc[f].label, 'sortable': true})
      }
    }
    array.push({key: 'formSubLocked', label: 'Locked', 'sortable': true})
    array.push('view')
    if(this.userData.role == 'admin'){
      array.push('delete')
    }
    return array
  },
  previousFormsReturnedFilterOptions(){
    var optionValues = _.cloneDeep(this.previousFormSubDataReturned)
    var options = [
      {key: 'formSubAuthor', text: 'Author', options: this.getColumnOptionValues('formSubAuthor', optionValues)}
    ]
    var columnOpt = this.getTableColumns(this.form.formFields, this.form.formTableCols)
    for(var c in columnOpt){
      var obj = {}
      obj['key'] = columnOpt[c].key
      obj['text'] = columnOpt[c].label
      obj['options'] = this.getColumnOptionValues(columnOpt[c].key, optionValues)
      options.push(obj)
    }
    options.push({key: 'formSubLocked', text: 'Locked', options: [true, false]})
    if(this.formOptionHold){
    for(var o in options){
      this.formOptionHold[options[o].key] = null
    }
    }
    return options
  },
  previousFormsReturnedFiltered(){
      var array = _.cloneDeep(this.previousFormSubDataReturned)
      if(this.formOptionHoldFilter){
      var options = this.formOptionHoldFilter
      var keys = Object.keys(options)
      keys = keys.map(p => {
          var obj = {}
          obj['id'] = p
          obj['value'] = options[p]
          return obj
      })
      var optionF = keys.filter(p => p.value != null)
      if(optionF && optionF.length > 0){
        return array.filter(p => {
              var value = true
              for(var o in optionF){
                if(p[optionF[o].id] != optionF[o].value){
                  value = false
                }
              }
              if(value){
                return p
              }
        })
      } return array
      } return array
  },
    previousFormSubDataReturnedAllData(){
    var array = _.cloneDeep(this.previousFormsReturnedFiltered)
    if(array && array[0] && array[0].formFields){
        var fields = array[0].formFields.filter(p => p.itemType == "form")
    }
    if(this.form && this.form.formTableCols){
      var holdArray = []
      var cols = this.form.formFields.filter(p => p.itemType == 'form')
     // console.log(cols)
      for(var i in array){
        var data = array[i]
        data['export'] = {}
        for(var c in cols){
          data.export[cols[c].label] = this.getValueForTableColForExport(cols[c].objKey, data.formSubData, data.formFields)
        }
        holdArray.push(data)
      }
      return _.orderBy(holdArray, ['createdAt'], ['desc'])
  } return _.orderBy(array, ['createdAt'], ['desc'])
  },
  previousFormSubDataReturned(){
    var array = _.cloneDeep(this.previousFormSubData)
    if(this.form && this.form.formTableCols){
      var holdArray = []
      var cols = this.form.formTableCols
      for(var i in array){
        var data = array[i]
        for(var c in cols){
          data[cols[c].key] = this.getValueForTableCol(cols[c].key, data.formSubData, data.formFields)
        }
        holdArray.push(data)
      }
      return _.orderBy(holdArray, ['createdAt'], ['desc'])
    } return _.orderBy(array, ['createdAt'], ['desc'])
  },

},
methods: {
    exportDataToExcel(){
      var array = _.cloneDeep(this.previousFormSubDataReturnedAllData)
      array = array.map(p => p.export)
      var title = this.form.formName + ' ' + this.startDate + ' till ' + this.endDate
     //  console.log(title)
       var data = Papa.unparse(array, {header: true})
      // console.log(data)
       const blob = new Blob([data], { type: 'text/csv' })
      // console.log(data)
       const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", title);
        link.click();
      return
    },
    updateValue(val){
      this.formOptionHoldFilter = null
      this.$nextTick(function(){
        this.formOptionHoldFilter = this.formOptionHold
      })
    },
    setNullValuesToFormOptionHold(){
      var keys = Object.keys(this.formOptionHold)
      for(var k in keys){
        this.formOptionHold[keys[k]] = null
      }
    },
    viewPreviousFormEntry(data){
      this.$emit('viewPreviousFormEntry', data)
    },
    getTableColumns(form, col){
      var columns = _.cloneDeep(form)
      columns = columns.filter(p => p.type != 'date' && p.type != 'datetime'  && p.type != 'time')
      var indexTest = columns.map(p => p.objKey)
      //console.log(columns)
      return col.filter(p => {
          var index = indexTest.indexOf(p.key)
          if(index != -1){
            return p
          }
      })

    },
    getColumnOptionValues(key, data){
      var options = [...new Set(data.map(p => p[key]))]
      if(options){
        return _.sortBy(options)
      } else {
        return null
      }
    },
    getPreviousFormIds(start, end){
    axios({ 
     method: 'get',
     url: '/api/v1/content/digitalformsub/quicktable/' + this.previousFormId + '/' + moment(start, 'YYYY-MM-DD').toISOString() + '/' +  moment(end, 'YYYY-MM-DD').toISOString(),
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      this.previousFormSubData = response.data
      })
    },
    getValueForTableColForExport(key, data, fields){
      var vObj = data[key]
      var fObj = fields.find(p => p.objKey == key)
      if(vObj && vObj.value && fObj && fObj.type){
        var type = fObj.type
        var value = vObj.value
        if(type == 'date' || type == 'datetime' || type == 'time' || type == 'textarea'){
          var string = 'missing'
          if(value.date){
            string = string == 'missing' ? this.getDate(value.date) : string + this.getDate(value.date)
          }
          if(value.time){
            string = string == 'missing' ? value.time : string + value.time
          }
          return string
        }
        if(type == 'select' || type == 'text' || type == 'number'){
           if(value){
             return value
           } return 'missing'
        }
        if(type == 'checkbox' || type == 'selectmulti'){
          var string = ''
          if(value.length > 0){
              var vArray = value
              for(var i in vArray){
                string = string + vArray[i]
                if(i + 1 < vArray.length){
                string = string + ', '
                }
              }
          }
          return string
        }
        if(type == 'dataRef'){
          var string = ''
          if(fObj.refItem && value.length > 0){
            for(var r = 0; r < value.length; r++){
              var obj = this[fObj.refItem].find(p => p.id == value[r])
              if(obj && fObj.refItem == 'deptContactData'){
                string = string + obj.firstName + ' ' + obj.lastName
                if(r < value.length - 1){
                  string = string + ', '
                }
              }
             if(obj && fObj.refItem == 'stationsData'){
                string = string + obj.stationName
                if(r < value.length - 1){
                  string = string + ', '
                }
              }
              if(obj && fObj.refItem == 'apparatusData'){
                string = string + obj.apparatusName
                if(r < value.length - 1){
                  string = string + ', '
                }
              }
            }
          }
          return string
        }
      }
      return ''
    },
    getValueForTableCol(key, data, fields){
      var vObj = data[key]
      var fObj = fields.find(p => p.objKey == key)
      if(vObj && vObj.value && fObj && fObj.type){
        var type = fObj.type
        var value = vObj.value
        if(type == 'date' || type == 'datetime' || type == 'time'){
          var string = 'missing'
          if(value.date){
            string = string == 'missing' ? this.getDate(value.date) : string + this.getDate(value.date)
          }
          if(value.time){
            string = string == 'missing' ? value.time : string + value.time
          }
          return string
        }
        if(type == 'select' || type == 'text' || type == 'number' ){
           if(value){
             return value
           } return 'missing'
        }
        if(type == 'checkbox'  || type == 'selectmulti'){
          var string = ''
          if(value.length > 0){
              var vArray = value
              for(var i in vArray){
                string = string + vArray[i]
                if(i + 1 < vArray.length){
                string = string + ', '
                }
              }
          }
          return string
        }
        if(type == 'dataRef'){
          var string = ''
          if(fObj.refItem && value.length > 0){
            for(var r = 0; r < value.length; r++){
              var obj = this[fObj.refItem].find(p => p.id == value[r])
              if(obj && fObj.refItem == 'deptContactData'){
                string = string + obj.firstName + ' ' + obj.lastName
                if(r < value.length - 1){
                  string = string + ', '
                }
              }
             if(obj && fObj.refItem == 'stationsData'){
                string = string + obj.stationName
                if(r < value.length - 1){
                  string = string + ', '
                }
              }
              if(obj && fObj.refItem == 'apparatusData'){
                string = string + obj.apparatusName
                if(r < value.length - 1){
                  string = string + ', '
                }
              }
            }
          }
          return string
        }
      }
      return 'missing'
    },
    getDateTime(date){
        return date ? moment(date).format('MM/DD/YY HH:mm') : '?'
    },
    getDate(data){
      return data ? moment(data, "YYYY-MM-DD").format('MM/DD/YY') : '?'
    },
    deleteInventorySheet(data){
        this.$emit('deletePreviousSheet', data)
      },
    removeDigitalForm(id){
      var result = confirm('Are You Sure You Want to Delete your entry?')
      if(result){
        axios({ 
        method: 'Delete',
        url: '/api/v1/content/digitalformsub/' + id,
        headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
          this.getPreviousFormIds(this.startDate, this.endDate)
          })
        }
    },
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
