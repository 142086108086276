<template>
  <div class="hello">
    <b-container fluid v-if="apiInfo.length > 0 && userData.role == 'admin'">
      <b-card title="Stat Portals Server Api">
      <b-row v-if="apiReturned.length > 0">
        <b-col cols="3">
          <b-card header="Api Groups">
            <b-btn variant="link" @click="panelView = 'generalinfo'">General Info</b-btn>
            <div v-for="(group, index) in groupLinks" no-body no-border :key="index">
               <b-btn :variant="panelView != group ? 'link' : 'primary'" @click="panelView = group, panelFilter = ''">{{ group.toUpperCase() }}</b-btn>
               <div v-if="panelView == group" v-for="(filter, indexF) in getSubGroups(group)" :key="indexF">
                  <b-btn 
                    :variant="panelFilter != filter ? 'link text-success' : 'outline-success'"
                    :class="panelFilter != filter ? '' : 'py-0 my-2'"
                    @click="panelFilter = filter">{{ filter.toLowerCase() }}</b-btn>
                 </div>
              </div>
          </b-card>
          </b-col>
          <b-col cols="9">
            <b-card header="Info Panel" v-if="panelView == 'generalinfo'">
              "GENERAL INFO"
            </b-card>
            <b-card v-else-if="panelFilter == ''" :header="panelView">
              
              </b-card>
          </b-col>
          </b-row>
          </b-card>
          </b-container>
          
  </div>

</template>

<script>
import { mapState } from 'vuex'
import autho from './apicomponents/auth.json'
import content from './apicomponents/content.json'
import taxonomy from './apicomponents/taxonomy.json'
export default {
  name: 'Api_Docus',
  components: {

  },
  data () {
      return {
        apiInfo: [


        ],
        groupLinks: [

        ],
        panelView: 'generalinfo',
        panelFilter: '',
    }
  },
  created(){
    this.apiInfo = this.apiInfo.concat(content)
   this.apiInfo = this.apiInfo.concat(taxonomy)
  },
  mounted(){
    
  },
  computed: {
    ...mapState([
        'userData',

    ]),
    apiReturned(){
      var array = this.apiInfo
      var groups = [...new Set(array.map(p => p.group))]
      groups = _.sortBy(groups)
      this.groupLinks = groups
      return groups.map(item => {
          var obj = {}
          obj['group'] = item
          var routes = array.filter(p => p.group == item)
          obj['routes'] = _.orderBy(routes, ['api'], ['asc']) 
          return obj
      })
    }

  },
  methods: {
    getSubGroups(group){
      var filter = this.apiReturned.find(p => p.group == group)
      if(filter.routes){
        var routes = [...new Set(filter.routes.map(p => p.api))]
        return _.sortBy(routes)
      } return []
    },
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}

</style>
