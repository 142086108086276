<template>
<div id="homelanding">
  <b-container class="mb-2">
    <b-row>
      <b-col cols='*' class="pt-2 font-weight-bold">Start Date</b-col>
      <b-col><b-form-input type="date" v-model="startDate" /></b-col>
      <b-col cols="*" class="pt-2 font-weight-bold">End Date</b-col>
      <b-col><b-form-input  type="date" v-model="endDate" /></b-col>
      <b-col cols="*"><b-btn variant="primary" @click="getPreviousSheets(startDate, endDate)">Search</b-btn></b-col>
    </b-row>
  </b-container>
      <b-table v-if="currentInventory" :fields="['Tagged', 'Entry Info', 'View', 'Delete']" :items="inventorySubsData" stacked="md">
      <template slot="Tagged" slot-scope="data">
            <span v-if="data.item.inventorySubDbid">{{ getTaggedType(data.item.inventorySubDbid) }}</span>
        </template>
        <template slot="Entry Info" slot-scope="data">
            <span>{{ getTime(data.item.inventorySubDate) }} | {{ data.item.inventorySubAuthor }}</span>
          </template>
            <template slot="View" slot-scope="data">
            <b-btn variant="info" size="sm" @click="viewInventorySheet(data.item)">View</b-btn>
        </template>
        <template slot="Delete" slot-scope="data">
          <b-btn v-if="userData.role != 'user'" variant="danger" @click="deleteInventorySheet(data.item)">Delete</b-btn>
          </template>
        </b-table>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,

},
props: ['previousSheetsReturned', 'inventoryTemplateData', 'viewPreviousSheetsId'],
data() {
    return {
          searchData: '',
          inventorySubsData: [],
          startDate: '',
          endDate: '',
          
}
},
created () {

},
beforeDestroy(){

},
mounted(){
  if(this.currentInventory && this.currentInventory.latest && this.currentInventory.latest.inventorySubDate){
   this.endDate = moment(this.currentInventory.latest.inventorySubDate).add(1, 'd').format('YYYY-MM-DD')
   this.startDate = moment(this.endDate, 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD')
  } else {
  this.endDate = moment().add(1, 'd').format('YYYY-MM-DD')
  this.startDate = moment().subtract(3, 'months').format('YYYY-MM-DD')
  }
  this.getPreviousSheets(this.startDate, this.endDate)

},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'logisticAssetData'
  ]),
  currentInventory(){
    var obj = this.inventoryTemplateData.find(p => p.id == this.viewPreviousSheetsId)
    if(obj){
     // console.log(obj)
      return obj
    } return null
  },

},
methods: {
      viewInventorySheet(data){
        this.$emit('viewPreviousSheet', data)
      },
    getPreviousSheets(start, end){

    axios({ 
     method: 'get',
     url: '/api/v1/content/inventorysub/quicktable/' + this.viewPreviousSheetsId + '/' + moment(start, 'YYYY-MM-DD').toISOString() + '/' +  moment(end, 'YYYY-MM-DD').toISOString(),
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     if(response.data && response.data.length > 0){
     this.inventorySubsData = _.orderBy(response.data, ['inventorySubDate'], ['desc'])
     }
   })
    },
      getTime(date){
        return date ? moment(date).format('MM/DD/YY HH:mm') : '?'
      },
      deleteInventorySheet(data){
        this.$emit('deletePreviousSheet', data)
      },
      getTaggedType(id){
      var array = this[this.currentInventory.inventoryType]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return this.currentInventory.inventoryType == 'apparatusData' ? obj.apparatusName : this.currentInventory.inventoryType == 'stationsData' ? obj.stationName : "Unknown"
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
