<template>
<div id="homelanding">

  <div class="headerSpanStaffing">Department Logistics <span v-if="showAdmin">Admin</span>
<span class="float-right">
      <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief'" size="sm" @click="showAdmin = !showAdmin" :variant="showAdmin ? 'light' : 'outline-light'">Admin</b-btn>
    </span>

  </div>
<adminInventory v-if="showAdmin" :logisticAssetData="logisticAssetData" :inventoryTemplateData="inventoryTemplateDataReturned" :inventorySubsDataSorted="inventorySubsDataSorted" />
<b-container v-if="!showAdmin" fluid class="mt-5 px-0 px-md-4">
  <b-card no-body body-class="px-0">
    <b-tabs card >
    <b-tab title="Forms" class="px-1 px-md-4">
    <b-card no-body>
      <b-tabs card  class="px-0">
        <b-tab title="Templates"  class="px-0">
    <b-table v-if="!viewPreviousSheetsId" :fields="['inventoryName', 'Tagged', {key: 'inventoryFrequency', label: 'Frequency'}, 'Last Entry Info', 'Previous', 'Add']" :items="inventoryTemplateDataReturned" stacked="md">
      <template slot="Tagged" slot-scope="data">
            <span v-if="data.item.inventoryType && data.item.inventoryDbid">{{ getTaggedType(data.item.inventoryType, data.item.inventoryDbid) }}</span>
        </template>
      <template slot="inventoryFrequency" slot-scope="data">
            <h5 v-if="data.item.latest && data.item.inventoryFrequency"><b-badge  :variant="getVariantForFrequency(data.item.inventoryFrequency, data.item.latest)">{{ data.item.inventoryFrequency }}</b-badge></h5>
            <h5 v-else-if="data.item.inventoryFrequency"><b-badge variant="secondary">{{ data.item.inventoryFrequency }}</b-badge></h5>
        </template>
                <template slot="Last Entry Info" slot-scope="data">
            <span v-if="data.item.latest">{{ getTime(data.item.latest.inventorySubDate) }} | {{ data.item.latest.inventorySubAuthor }}</span>
          </template>
         <template slot="Previous" slot-scope="data">
            <b-btn variant="primary" size="sm" :disabled="!data.item.latest" @click="viewPreviousSheetsId = data.item.id">View</b-btn>
        </template>
            <template slot="Add" slot-scope="data">
            <b-btn :variant="!data.item.latest ? 'warning' : 'info'" :disabled="checkReportRun" size="sm" @click="addNewInventory(data.item)">New</b-btn>
        </template>

        </b-table>
        <b-btn block variant="danger" size="sm" @click="viewPreviousSheetsId = ''" v-if="viewPreviousSheetsId">Back to Template List</b-btn>
        <previousInvSheet v-if="viewPreviousSheetsId" 
        :viewPreviousSheetsId="viewPreviousSheetsId" 
        :previousSheetsReturned="previousSheetsReturned" 
        :inventoryTemplateData="inventoryTemplateDataReturned" 
            @viewPreviousSheet="viewPreviousSheet($event)" 
            @deletePreviousSheet="deleteInventorySheet($event)" 
            />
                      <b-form-checkbox v-if="userData.role == 'adminchief' || userData.role == 'admin'" class="mt-0 pt-0" v-model="showDisabled">Show Disabled Forms?</b-form-checkbox>
        </b-tab>
      <b-tab v-for="newInv in addNewInventoryArray"  :key="newInv.id" active>
              <template slot="title">
                 <b-badge variant="success">NEW</b-badge> {{ newInv.inventoryName }} 
                  <b-badge variant="danger" @click="closeInventory(newInv.id)">X</b-badge>
            </template>
          <invSheetPrevious :newInventoryForm="newInv" @getInventorySubs="getInventorySubs" :inventoryTemplateData="inventoryTemplateDataReturned" :previousData="false" @closeInventoryShowTable="closeInventoryShowTable($event)"  />
      </b-tab>
       <b-tab v-for="newInv in previousInventoryData"  :key="newInv.id" active>
              <template slot="title">
                {{ getInventorySub(newInv.inventorySubId).inventoryName }} <b-badge variant="danger" @click="closeInventoryPrevious(newInv.id)">x</b-badge>
            </template>

          <invSheetPrevious :newInventoryForm="newInv" @getInventorySubs="getInventorySubs" :inventoryTemplateData="inventoryTemplateDataReturned" @closeInventoryShowTable="closeInventoryPrevious($event)" :previousData="true" />
      </b-tab>
        </b-tabs>
        </b-card>
    </b-tab>
    <b-tab title="Assets">
      <assetTable />
    </b-tab>
    <b-tab title="Supplies" v-if="resetList">
      <logisticSupply :inventoryItemsData="inventoryItemsData" :inventoryTemplateData="inventoryTemplateData" />
    </b-tab>
      </b-tabs>
    </b-card>

  </b-container>

</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import adminInventory from '../admin/adminlogisticsInvPortal'
// import invSheet from './logistics/InvSheet.vue'
import invSheetPrevious from './logistics/InvSheetPrevious.vue'
import assetTable from './logistics/logisticAsset.vue'
import logisticSupply from './logistics/logisticSupply.vue'
import previousInvSheet from './logistics/previousInvSheets.vue'
export default {
components: {
  'multiselect' : Multiselect,
  adminInventory,
  // invSheet,
  assetTable,
  logisticSupply,
  previousInvSheet,
  invSheetPrevious

},
data() {
    return {
      showAdmin: false,
      resetList: true,
      showDisabled: false,
      showModal: false,
      checkReportRun: true,
      inventoryItemsData: [],
      searchData: '',
      fields: [{key: 'item_name', field: 'item_name'}, {key: 'item_quantity', label: 'Desired Qty'}, 'item_count', 'tracking'],
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      viewPreviousSheetsId: '',
      previousInventoryData: [],
      invetoryLastCheckData: [],
      inventorySubsData: [],
      addNewInventoryArray: [],
}
},
created () {
  this.getInventoryItems()
  bus.$on('getInventoryTemplates', this.getInventoryTemplates)
  if(this.$route.query){
    if(this.$route.query.inventorysub){
      this.viewPreviousSheet({id: this.$route.query.inventorysub})
    }
  }
},
beforeDestroy(){

},
mounted(){
  this.getInventorySubs(this.inventoryTemplateData)
},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags',
    'logisticAssetData',
    'inventoryTemplateData'
  ]),
  previousSheetsReturned(){
    if(this.viewPreviousSheetsId){
      var array = this.inventorySubsDataSorted.filter(p => p.inventorySubId == this.viewPreviousSheetsId)
      return array
    } return []
  },
  inventorySubsDataSorted(){
    return _.orderBy(this.inventorySubsData, ['inventorySubDate'], ['desc'])
  },
  inventoryTemplateDataReturned(){
    var array = this.inventoryTemplateData
    var data = array.map(item => {
      var obj = item
      obj['latest'] = this.getNewestCompletions(item.id)
      return obj
    })
    data = data.filter(p => {
        if(this.showDisabled){
          return p.inventoryFrequency == 'disabled'
        } else {
          return p.inventoryFrequency != 'disabled'
        }
    })
    this.resetList = false
    this.$nextTick(function(){
      this.resetList = true
    })
    return _.orderBy(data, ['inventoryName'], ['asc'])
  },

},
methods: {
      getTime(date){
        return date ? moment(date).format('MM/DD/YY HH:mm') : '?'
      },
      getVariantForFrequency(freq, latest){
        if(freq && latest.inventorySubDate){
        if(freq == 'daily'){
          if(moment().format('DD') == moment(latest.inventorySubDate).format('DD')){
            return 'success'
          } else {
            return 'danger'
          }
        } else if (freq == 'week'){
          if(moment().diff(latest.inventorySubDate, 'day') < 7){
            return 'success'
          } else {
            return 'danger'
          }
        } else if (freq == 'biweekly'){
          if(moment().diff(moment(latest.inventorySubDate), 'day') < 14){
            return 'success'
          } else {
            return 'danger'
          }
        } else if (freq == 'monthly') {
          if(moment().diff(latest.inventorySubDate, 'day') < 30){
            return 'success'
          } else {
            return 'danger'
          }
        } else if (freq == 'quartly') {
          if(moment().diff(latest.inventorySubDate, 'day') < 90){
            return 'success'
          } else {
            return 'danger'
          }
        } else if (freq == 'annually') {
            if(moment().diff(latest.inventorySubDate, 'day') > 365){
            return 'success'
          } else {
            return 'danger'
          }
        } else {
          return 'secondary'
        }
        } return 'secondary'
      },
      addNewInventory(obj){
        var holdObj = obj
        if(obj.latest && obj.latest.id){
        axios({ 
          method: 'get',
          url: '/api/v1/content/inventorysub/' + obj.latest.id,
          headers: {
              'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
          if(response.data[0]){
            holdObj['latest'] = response.data[0]
            this.addNewInventoryArray.push(holdObj)
          } else {
             this.addNewInventoryArray.push(obj)
          }
        })
        } else {
        this.addNewInventoryArray.push(obj)
        }
      },
      closeInventory(id){
        this.addNewInventoryArray.splice(this.addNewInventoryArray.map(p => p.id).indexOf(id), 1)
      },
      closeInventoryPrevious(id){
        this.previousInventoryData.splice(this.previousInventoryData.map(p => p.id).indexOf(id), 1)
      },
      closeInventoryShowTable(id){
        console.log(id)
        this.closeInventory(id)
        this.viewPreviousSheetsId = id
      },
      getInventorySub(id){
        var inv = this.inventoryTemplateData.find(p => p.id == id)
        if(inv){
          return inv
        } return {inventoryName: 'Not Found'}
      },
      getNewestCompletions(id){
        var obj = this.inventorySubsDataSorted.find(p => p.inventorySubId == id)
        return obj
      },
      getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : "Unknown"
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
    deleteInventorySheet(data){
    var result = confirm('Are You Sure You Want to Delete this Vehicle Check?')
      if (result) {
        this.viewPreviousSheetsId = ''
   axios({
     method: 'delete',
     url: '/api/v1/content/inventorysub/' + data.id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
       this.getInventorySubs()
       this.$nextTick(function(){
         this.viewPreviousSheetsId = data.id
       })
    })
    }

      
    },
    viewPreviousSheet(data){
    axios({ 
     method: 'get',
     url: '/api/v1/content/inventorysub/' + data.id,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     if(response.data[0]){
           this.previousInventoryData.push(response.data[0])
     }
   })
    },
  getInventorySubs(array){
   // console.log(array)
   // console.log('ARRAY')
   // var latest =
    var ids =  array.map(p => p.id)
     axios({  
          method: 'get',
          url: '/api/v1/content/inventorysub/groupnew/' + ids,
          headers: {
              'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
         // console.log(response.data)
          this.inventorySubsData = response.data
               this.$nextTick(function(){
       this.checkReportRun = false
     })
        })
  },
  getInventorySubsb(){
    axios({ 
     method: 'get',
     url: '/api/v1/content/inventorysub/quick',
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.inventorySubsData = response.data
     this.$nextTick(function(){
       this.checkReportRun = false
     })
   })
    },
  getInventoryTemplates(){
      var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': this.getSessionData,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
  var payload = { headers: headers }
   this.$store.dispatch('LOAD_INVENTORY_TEMPLATES', payload)
    },
    modalHideEvent(){
      this.showModal = false
    },
    modalHideEvent(){
      this.showModal = false
    },
  getInventoryItems(){
    axios({ 
     method: 'get',
     url: '/api/v1/taxonomy/inventoryitem/',
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.inventoryItemsData = response.data
   })
    },
    modalHideEvent(){
      this.showModal = false
    },

    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
