<template>
<div >
    <b-table :fields="fields" :items="userMonthViewModsOrig" stacked="lg">
      <template slot="payrollModCode" slot-scope="data">
        <span :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
      </template>
      <template slot="payrollModDatetime" slot-scope="data">
        {{ getFormatedDateTime(data.item.payrollModDatetime)}}
      </template>
      <template slot="payrollModTargetUserId" slot-scope="data">
        {{ getUserData(data.item.payrollModTargetUserId) }}
      </template>
      <template slot="payrollModExchangeUserId" slot-scope="data">
        {{ getUserData(data.item.payrollModExchangeUserId) }}
      </template>
      <template slot="payrollModApprovalUserId" slot-scope="data">
        {{ getUserData(data.item.payrollModApprovalUserId) }}
      </template>
      <template slot="payrollModApproved" slot-scope="data">
        {{ data.item.payrollModApproved == null ? 'Awaiting' : data.item.payrollModApproved == false ? 'Denied' : 'Approved'}}
      </template>
      <template slot="Edit" slot-scope="data">
        <b-btn size="sm" variant="primary" @click="updatePayrollMod(data.item)">Update</b-btn>
        <b-btn size="sm" variant="danger" @click="deletePayrollMod(data.item)">Delete</b-btn>
      </template>
    </b-table>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import rosterSpot from './rosterspotNew.vue'
export default {
components: {
  'multiselect' : Multiselect,
  rosterSpot
},
props: ['payrollCodesData', 'userMonthViewModsOrig', 'userSelected'],
data() {
    return {
    start: {name: 'base', startTime: 1, endTime: 10},
    sortMods: [
      {name: 'firstSwap', startTime: 2, endTime: 3},
      {name: 'secondSwap', startTime: 4, endTime: 6},
      {name: 'thirdSwap', startTime: 8, endTime: 9}
    ],
    fields: [
      {key: 'payrollModCode', label: 'Code'},
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'},
      {key: 'payrollModTargetUserId', label: 'Target'},
      {key: 'payrollModExchangeUserId', label: 'Swap User'},
      {key: 'payrollModApproved', label: 'Status'},
      {key: 'payrollModApprovalUserId', label: 'Admin'},
    ],
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    nonSpotModification: [
       'payaddative',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
      'overtime',
    ]
  }
},
created () {
  if((this.userData.role == 'admin' || this.userData.role == 'fireops' || this.userData.role == 'adminchief') && this.userSelected && this.userSelected.id != this.userData.id ){
    this.fields.push('Edit')
  }

},
beforeDestroy(){
  	
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  startArrayItems(){
    var base = _.cloneDeep(this.start)
    var array = []
    var mods = this.sortMods
    for(var i = 0; i < mods.length; i++){
      if(i == 0){
        if(base.startTime < mods[i].startTime){
        var obj = {name: 'Base_' + i, startTime: base.startTime, endTime: mods[i].startTime }
        array.push(obj)
        array.push(mods[i])
        } else {
          array.push(mods[i])
        }
      } else if (i < mods.length){
        if(mods[i - 1].endTime < mods[i].startTime){
         var obj = {name: 'Base_' + i, startTime: mods[i-1].endTime, endTime: mods[i].startTime }
            array.push(obj)
            array.push(mods[i])
        } else {
             array.push(mods[i])
        }
      }
      if(i + 1 == mods.length){
          if(base.endTime > mods[i].endTime){
           var obj = {name: 'Base_' + (i + 1), startTime: mods[i].endTime, endTime: base.endTime } 
           array.push(obj)   
      } 
      }
    }
    return array
  },
},  
mounted(){

},
watcher:{

},
methods: {
  getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName + ' (' + user.employeeId + ')'
        } else {
          return 'Unknown'
        }
      } else {
        return ''
      }
    },
    getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    getFormatedDateTime(date){
      if(date){
        return moment(date).format('MM/DD/YY HH:mm')
      } else {
        return 'Unknown'
      }
    },
    deletePayrollMod(data){
    axios({
     method: 'delete',
     url: '/api/v1/staffing/payrollmod/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     bus.$emit('newPayrollModification')
       console.log(response.data)
   })

    },
    updatePayrollMod(data){
     // console.log(data)
      bus.$emit('updatePayrollMod', data)
    },
  

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
