<template>
<div @click="addModalOpen">
  <b-modal
      size="lg"
       id="repairModal"
       ref="repairModal"
       v-model="showModal"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
         >
         <template slot="modal-header">
           <span>Add Training Item
             <img src="/images/siteicons/help-icon-right-align.png" title="Dept Info" height="24" width="24" @click="showTutVideo = !showTutVideo"></span><b-btn align="right" size="sm" variant="danger" @click="showModalswitchEmitVehicle">x</b-btn>
         </template>
         <div class="modal-body">
           <tutdata v-if="showTutVideo" singlePost="newlogbook" />
            <b-form-input  id="input-item-short" type="text" class="itemDescription" v-model="itemShort"   :state="nameState" placeholder="Training Item Title" />
            <b-form-invalid-feedback id="input-item-short">
                  Enter at least 3 letters
                </b-form-invalid-feedback>
            <itemmultibuild v-show="editBlogBody" @itemData="saveItemData" @itemDataView="saveItemDataAndDisplay" />
            <b-btn v-if="itemDataArray.length > 0" block variant="info" v-show="!editBlogBody"  class="w-50 mx-auto my-3" @click="editBlogBody = !editBlogBody">Edit Body Data</b-btn>
            <div v-for="(item, index) in itemDataArray" v-if="!editBlogBody" :key="index">
            <div class="m-2" v-if="item.type == 'html'">
              <div v-html="item.value"></div>
              </div>
            <div class="m-2" v-if="item.type == 'text'">
              <div>{{ item.value }}</div>
              </div>            
            <div class="m-2" v-if="item.type == 'video'">
              <div class="videoWrapper">
                   <iframe :src="'https://www.youtube.com/embed/' + getVideoId(item.value)" frameborder="0" allowfullscreen></iframe>
                   </div>
              </div>            
            <div class="m-2" v-if="item.type == 'photo'">
                   <b-img class="p-5" fluid-grow :src="siteconfig.deptStore + item.value" />
              </div>      
                          <div v-if="item.type == 'link' || item.type == 'file'">
                           <b-btn variant="link" v-if="item.showEdit == false" @click="viewFile(item.value.url)" >{{ item.value.title }}</b-btn>
            </div>    
                        </div>
            <b-form-select text-field="trainingrefName" value-field="trainingrefName" :state="trainingRefParentSelected ? true : false" v-model="trainingRefParentSelected" :options="trainingRefTypeParent">
              <option :value="null" disabled> Please select Training Category</option>
            </b-form-select>
            <b-form-select v-if="trainingRefParentSelected != null" text-field="trainingrefName" :state="trainingRefSelected ? true : false" value-field="trainingrefName" v-model="trainingRefSelected" :options="trainingRefTypeSub(trainingRefParentSelected)">
              <option :value="null" disabled> Please select Training Sub-Category</option>
            </b-form-select>
            
              </div>
         <template slot="modal-footer">
             <multiselect v-if="userData.role == 'admin'" v-model="blogAuthor" :options="deptContactData" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Blog Author">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
                </multiselect>
             <b-btn variant="primary" @click.stop="submitPost"
             :disabled="itemShort == '' || itemDataArray == '' || blogAuthor == '' || !trainingRefTypeParent || !trainingRefSelected || editBlogBody"
             >Submit</b-btn>

           </template>
    </b-modal>
    <b-modal v-model="showModalConfirmation"
    :no-close-on-backdrop="trueBoo"
    :no-close-on-esc="trueBoo">
      <template slot="modal-header">
             Post Confirmation
      </template>
             <div v-if="postData == ''">
                   ...Submitting post
                   <br>
               <img src="/images/siteicons/MalteseLoadingGif.gif">
             </div>
             <div v-if="postData != ''">
                   Your Post has been submitted!
             </div>
             <blog v-if="postData != ''" :blog="postData" :showPost="true" />
             <template slot="modal-footer">
                   <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                     Close Message
                   </b-btn>
            </template>
      </b-modal>
  </div>
</template>

<script>
import blog from '../tracking/blogs/blogsFront.vue'
import { mapState } from 'vuex'
import { bus } from '../../main'
import { VueEditor } from 'vue2-editor'
import TutData from '../tracking/deptTutorialsdata.vue'
import axios from 'axios';
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import ImageCompressor from 'image-compressor.js'
import ItemMultiBuild from '../inputfields/multiItemEditor.vue'
export default {
  props:["showModal", "filterDate"],
components:{
    'itemmultibuild' : ItemMultiBuild,
    'tutdata' : TutData,
    'multiselect' : Multiselect,
    blog,
    VueEditor,
},
  data () {
    return {
        loadingFile: false,
        showTutVideo: false,
        carouselToggle: false,
        trueBoo: true,
        itemDataArray: [],
        imageLoadReset: true,
        itemShort: '',
        titleDescription: '',
        showModalConfirmation: false,
        testValue: null,
        detailedDescription: '',
        editBlogBody: true,
        editButton: 'edit',
        showEditView: false,
        trainingRefSelected: null,
        trainingRefParentSelected: null,
        newDataUUIDL: '',
        blogAuthor: '',
        imageFileName: '',
        slide: 0,
        sliding: false,
        imageFileFID: '',
        newImageFileData: '',
        fileName: '',
        fileType: '',
        textPost: true,
        imagePost: false,
        imageResponseData: [],
        currentTime: '',
        disctimeValueSet: '',
        postData: '',
        timeData: '',
        videoLink: '',
        videoLinkArray: [],
        fileUploadArray: [],
        addedFileArray: [],
        customToolbar: [
          ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
          ['blockquote', 'code-block', 'link', { 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
    }
  },
  created(){
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
     this.$store.dispatch('LOAD_DOCUMENTS_API_EXP', headers)
  },
  mounted(){
    this.setBlogAuthor()
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'deptContactData',
          'userData',
          'shiftsData',
          'repairStatus',
          'trainingrefData',
          'workgroupData',
          'siteconfig'
        ]),
        nameState() {
        return this.itemShort.length > 2 ? true : false
        },
        trainingRefTypeParent(){
          var newArray = [... new Set(this.trainingrefData.filter(p => p.trainingrefParent == '')
          )]
          return newArray
        },
        formatedDateExport () {
        var timeString = moment().format()
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
      },
    },
    watch: {

      videoLink(newData){
        if(newData != ''){
        
        var string = newData
        this.videoLinkArray.push(string)
        this.videoLink = ''
        } else {
          return
        }
      },
      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
        viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
    saveItemData(data){
    this.itemDataArray = data
    },
    saveItemDataAndDisplay(data){
      this.itemDataArray = data
      this.editBlogBody = false
    },
    setBlogAuthor(){
      console.log(this.userData.id)
      console.log(this.deptContactData)
      var user = this.deptContactData.filter( p => {
        return p.id.match(this.userData.id) } )

      console.log(user)
      this.blogAuthor = user[0]
    },
    removeVideo(idx){
      this.videoLinkArray.splice(idx, 1)
    },
    removeImage(idx){
      this.fileUploadArray.splice(idx, 1)
    },
       getVideoId(data){
           var videoLink = data.split('=')
          if(videoLink.length == 1){
            var videoLink = data.split('.be/')
          }
        return videoLink[1]
      },
    trainingRefTypeSub(data){
      var parentName = data
      var subSet = [... new Set(this.trainingrefData.filter(p => p.trainingrefParent == parentName))]
      return subSet
    },
    removeFromArray(data){
      var newArrayIds = [...new Set(this.addedFileArray.map(p => p.uuid[0].value))]
      var index = newArrayIds.indexOf(data.uuid[0].value)
      return this.addedFileArray.splice(index, 1)
    },
    addModalOpen(){
      document.body.className += ' modal-open';
    },
    showModalswitchEmitVehicle () {
    bus.$emit('addNewTrainingItem');
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalswitchEmitVehicle()
    },
    postImage(e){
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result)
        }
        })
      
     },
     postImageAxios(result){
        console.log(result)
        var data = new FormData();
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/blogposts/' + this.userData.lastName + this.userData.EmployeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fileUploadArray.push(response.data);
          this.carouselToggle = true
          this.loadingFile = false
          this.imageLoadReset = true
        });
     },
    submitPost(){
     axios({
      method: 'post',
      url: '/api/v1/content/trainingitem',
      data: {

          'title': this.itemShort, 
          'trainingitemType': this.trainingRefSelected, 
          'trainingitemTypeParent': this.trainingRefParentSelected, 
          'trainingitemData': this.itemDataArray, 
          'trainingitemAuthor': this.blogAuthor.firstName + ' ' + this.blogAuthor.lastName, 
          'trainingitemAuthorId': this.blogAuthor.id

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {

       this.postData = response.data
            bus.$emit('refreshTrainingRefItems')
      })
 
        this.showModalConfirmation = !this.showModalConfirmation;
  }
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>
<style scoped>
.image-upload > label {
  cursor: pointer;
}
.image-upload > input
{
    display: none;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.closeButtonCarousel {
    width: 20px;
    background: red;
    padding: 0 4px;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto 0;
    cursor: pointer;
}
.closeButton {
  position: relative;
  width: 20px;
  background: red;
  float: left;
  padding: 0 4px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.videoRemove {

}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
