<template>
<div id="homelanding">

  <div class="headerSpan">Department Training  <span v-if="showAdmin">Admin</span>
<span class="float-right">
      <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'trainingops'" size="sm" @click="showAdmin = !showAdmin" :variant="showAdmin ? 'light' : 'outline-light'">Admin</b-btn>
    </span>

  </div>
<adminTraining v-if="showAdmin" />
<b-container fluid v-if="!showAdmin">
  <b-row>
    <b-col xl="9">
<b-card no-body>
  <b-tabs card>
    <b-tab no-body title="Calendar">
<calendar class="calendarClass" :all-events="events" :daydisplayboo="true" :calendarHeader="calendarHeader" :displayWeekNumber="false" :calendarSmall="true" v-if="showCalendar"></calendar>
    </b-tab>
    <b-tab no-body title="Training Tracking" :active="activeTab">
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Training Tracking</b-navbar-brand>
        <b-btn size="sm" class="mr-2" :variant="trainingViewType == 'Scheduled' ? 'info' : 'outline-info'" @click="trainingViewType = 'Scheduled'">Scheduled</b-btn>
        <b-btn size="sm" class="mr-2" :variant="trainingViewType == 'Assigned' ? 'primary' : 'outline-primary'" @click="trainingViewType = 'Assigned'">Assigned</b-btn>
        <b-btn size="sm" class="mr-2" :variant="trainingViewType == 'User' ? 'success' : 'outline-success'" @click="trainingViewType = 'User'">User Defined</b-btn>
        <b-btn size="sm" class="mr-2" variant="outline-danger"  :to="'/report/training'">Audit</b-btn>
        <b-nav-form class="mr-auto">
        <multiselect v-if="trainingViewType == 'Assigned' && userData.rank != 'Apprentice'" v-model="assignedTrainingUser" :options="deptContactDataFilterRanks" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Assigned Filter by User">
                    <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                    </template>
          </multiselect>
        </b-nav-form>
      </b-nav-form>
    </b-navbar>
     <b-table v-if="filteredTrainingDrills.length > 0" :fields="fields" :items="filteredTrainingDrills">
       <template slot="trainingModuleCompletionDate" slot-scope="data">
         {{ getFormatedDate(data.item.trainingModuleCompletionDate) }}
         </template>
       <template slot="trainingModuleAssignedUsers" slot-scope="data">
         <span v-if="data.item.trainingModuleAssignedUsers">{{ data.item.trainingModuleAssignedUsers.length }}</span>
         </template>
         <template slot="Quick View" slot-scope="data">
           <b-btn size="sm" variant="outline-info" @click="setViewTrainingModule(data.item)">View</b-btn>
         </template>
        <template slot="View" slot-scope="data">
           <b-btn size="sm" variant="outline-primary" :to="'/training/' + data.item.id">Go to Page</b-btn>
         </template>
       </b-table>
       <h4 class="text-danger mt-4" v-if="filteredTrainingDrills.length == 0">No Training Drills of Type {{ trainingViewType }} Found</h4>
            </b-tab>
    <b-tab no-body title="Training References">
      
      <b-card no-body>
         <b-btn size="sm" variant="info" v-show="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'trainingops'" @click="toggleTrainingItemModal">Add New Training Item</b-btn>
        <b-tabs card pills>
          <b-tab v-for="parent in trainingRefParents" :title="parent" :key="parent.index">
            <b-card no-body>
        <b-tabs pills card :vertical="verticalView">
           <b-tab v-for="child in filterTrainingRef(parent)" :key="child.index" :title="child">
             <b-container v-for="item in filterTrainingItems(child, parent)" :key="item.index">

              <itemdata :item="item" ></itemdata>
               </b-container>
            </b-tab>
    </b-tabs>
</b-card>
            </b-tab>

        </b-tabs>

      </b-card>
      </b-tab>
      <b-tab v-if="trainingValidationData && trainingValidationData.length > 0" title="Pending Validations" :active="validationActive">
           <validationTable :trainingValidationData="trainingValidationData" :trainingDrills="trainingDrills" @updateValidation="getTrainingValidation" />
          </b-tab>
    </b-tabs>
    </b-card>
    </b-col>
    <b-col>
      <b-card no-body>
  <b-tabs card>
    <b-tab title="Blogs" class="pt-2">
           <b-container v-for="blog in filtereddepartmentBlogsReturn" :key="blog.id" class="my-3">
                <blogs :blog="blog" />
           </b-container>
               <b-pagination align="center" v-if="filtereddepartmentBlogs.length > 0" size="md" :total-rows="filtereddepartmentBlogs.length" v-model="currentPage" :per-page="6">
    </b-pagination>
    </b-tab>
    
    </b-tabs>
    </b-card>
    </b-col>
  </b-row>
</b-container>
<b-modal v-if="showModal" v-model="showModal" size="lg" :hide-footer="true" body-class="m-0 p-0">
  <viewTraining :credentialRequirement="credentialRequirement" :trainingModule="viewTrainingModule" @closeTrainingModal='showModal = false' />
</b-modal>
</div>
</template>

<script>
import Calendar from '../calendarFull/Calendar.vue'
import { mapState } from 'vuex'
import { bus } from '../../main.js' 
import axios from 'axios'
import moment from 'moment'
import itemData from '../tracking/trainingitems/trainingitemsdata.vue'
import validationTable from '../tracking/training/trainingValidationTable.vue'
import viewTraining from '../addNew/newTrainingSubmission.vue'
import adminTraining from '../admin/adminTraining.vue'
import Blogs from '../tracking/blogs/blogsFront.vue'
import Multiselect from "vue-multiselect";
export default {
components: {
  'blogs' : Blogs,
  'calendar' : Calendar,
  'itemdata' : itemData,
  adminTraining,
  validationTable,
  'multiselect' : Multiselect,
  viewTraining
},
data() {
    return {
  slide: 0,
  verticalView: true,
  showAdmin: false,
  sliding: null,
  showModal: false,
  viewTrainingModule: null,
  validationActive: false,
  dataTrimmed: true,
  assignedTrainingUser: '',
  dataRange: 2500,
  events: [],
  roles: ['admin', 'adminchief', 'trainignops', 'fireops', 'emsops', 'supervisor', 'user'],
  trainingViewType: 'Scheduled',
  trainingDrills: [],
  trainingTabs: [],
  credentialRequirement: [],
  trainingValidationData: [],
  flagOnce: true,
  showCalendar: true,
  calendarHeader: 'Training Calendar',
  activeTab: null,
  currentPage: 1,

}
},
created () {
  if(this.$route.query.set == 'validation'){
    this.validationActive = true
  }
  this.pullDocuments()
  this.getTrainingItems()
  this.getTrainingModules()
  this.getCredentialRequirements()
  this.getTrainingValidation()
  this.assignedTrainingUser = this.userData
  window.addEventListener("resize", this.checkWidth)
  bus.$on('refreshTrainingRefItems', this.getTrainingItems)
  bus.$on('updateTrainingModules', this.getTrainingModules)
  if(this.$route.query.active && this.$route.query.active == 'trainingtrack'){
    this.activeTab = null
    this.$nextTick(function(){
      this.activeTab = 'trainingtrack'
    })
  }
},
beforeDestroy(){
  	window.removeEventListener("resize", this.checkWidth);
},
watch: {
  fullCalendarEvents(newData){
    this.filterTrainingData(newData)
  }
},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'departmentRanks',
    'trainingrefData',
    'trainingItemData',
    'eventTags'
  ]),
  deptContactDataFilterRanks(){
    if(this.userData.role == 'admin'){
    return this.deptContactData
    } else if(!this.userData.deptRank){
      return [this.userData]
      } else {
      var rank = this.userData.deptRank
      var role = this.roles.indexOf(this.userData.role)
      var order = this.departmentRanks.find(p => p.deptrankName == rank)
      if(order){
        var array = this.deptContactData.filter(p => {
          var rank = this.departmentRanks.find(r => r.deptrankName == p.deptRank)
          var rolei =  this.roles.indexOf(p.role)
          if(rank && rolei != -1 && (rank.deptrankSortOrder > order.deptrankSortOrder ||  rolei > role) && rank.deptrankName != 'Support Personnel'){
            return p
          }
        })
        var index = array.indexOf(this.userData.id)
        if(index == -1){
          array.push(this.userData)
        }
        return array
      }
    }

  },
  fields(){
    var array = [
      {key: 'trainingModuleName', label: 'Module Name'},
    ]
    if(this.trainingViewType == 'Assigned' || this.trainingViewType == 'Scheduled'){
      array.push({key: 'trainingModuleCompletionDate', label: 'Due Date'})
    }
    if(this.trainingViewType == 'Assigned'){
      array.push({key: 'completionStatus', label: 'Completed?'})
    }
    array.push('Quick View')
    array.push('View')
    return array
  },
  filteredTrainingDrills(){
    var byDrills = this.trainingDrills.filter(p => p.trainingModuleType.toLowerCase() == this.trainingViewType.toLowerCase() && p.trainingModuleActive)
    //console.log(this.trainingViewType)
    if(this.trainingViewType == 'Assigned'){
      //console.log('Assigned FILTER')
      var byUser = byDrills.filter(p => {
        if(this.assignedTrainingUser){
        var index = p.trainingModuleAssignedUsers.indexOf(this.assignedTrainingUser.id)
        //console.log(index)
        } else {
          var index = p.trainingModuleAssignedUsers.indexOf(this.userData.id)
        }
        if(index != -1){
          return p
        }
      })
    } else {
      //console.log('ELSE')
      var byUser = byDrills
    }
    return byUser
  },
  filtereddepartmentBlogsReturn(){
    var first = (this.currentPage - 1) * 6 // 1 = 0 2 = 3 3 = 6 4 = 9
    var second = this.currentPage * 6
    if(this.filtereddepartmentBlogs.length > 6){
      var array = _.orderBy(this.filtereddepartmentBlogs, ['blogDate'], ['desc'])
    return [... new Set(array.slice(first, second))]
    } else {
      return _.orderBy(this.filtereddepartmentBlogs, ['blogDate'], ['desc'])
    }
  },
  filtereddepartmentBlogs(){
    var blogs = this.departmentBlogs.filter(p => {
      return p.blogType.match('Training')
    })
    return _.orderBy(blogs, ['blogDate'], ['desc'])
  },
  trainingRefParents(){
    var parent = [... new Set(this.trainingItemData.map(p => p.trainingitemTypeParent))]
    console.log(parent.filter(p => p))
    return _.sortBy(parent.filter(p => p))
  }

},
mounted(){

  this.returnDocumentsApi()
},
watcher:{
  documentsApi(newData){
    if(newData.length > 0){
    this.returnDocumentsApi()
    }

  }
},
methods: {
  getFormatedDate(date){
  //  console.log(date)
    if(date){
      return moment(date).format('MM/DD/YY')
    } return 'Unknown Date'
  },
  setViewTrainingModule(data){
  //  console.log(data)
    this.showModal = true
    this.viewTrainingModule = data
  },
  checkWidth(){
        var width = document.documentElement.clientWidth;
        if(width < 750){
          this.verticalView = false
        } else { this.verticalView = true }
  },
  deleteTrainingRefItem(id){
  //  console.log(id)
  },
  filterTrainingItems(data, parent){
   // console.log(this.trainingItemData)
    return this.trainingItemData.filter(p => {
      if(p.trainingitemType && p.trainingitemTypeParent){
          return p.trainingitemType.match(data) && p.trainingitemTypeParent.match(parent)
      }
    })
  },
  toggleTrainingItemModal(){
    bus.$emit('addNewTrainingItem')
  },
  filterTrainingRef(data){
    var child = this.trainingItemData.filter( p => {
      if(p.trainingitemTypeParent){
          return p.trainingitemTypeParent.match(data)
      }
    })
    return [... new Set(child.map(p => p.trainingitemType))]
  },
  getTrainingModules(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingmodule/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.trainingDrills = response.data

      })
    },
    getTrainingValidation(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingrecord/validation-id/' + this.userData.id,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.trainingValidationData = response.data

      })
    },
  pullDocuments(){
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
     this.$store.dispatch('LOAD_DOCUMENTS_API_EXP', headers)
    },
  getTrainingItems(){
    var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
     this.$store.dispatch('LOAD_TRAINING_ITEM_API_EXP', headers)
    },
  returnDocumentsApi(){
    var documents = this.documentsApi.filter( p => {
      return p.documentTagParent.match('Training Refrences')
    })
    var sub = [... new Set(documents.map( p => p.documentTag))]
    for(var i = 0; i < sub.length; i++){
      var title = sub[i]
      var data = this.documentsApi.filter(p => {
        return p.documentTag.match(sub[i])
      })

      var string = { title: title, data: data, type: 'data' }
      this.trainingTabs.push(string)
    }
  },
  showDev(event){
    this.showDevModal = !this.showDevModal
  },
  filterTrainingData(data){
    var filteredData = data.filter( (p) => {
      return p.eventTagParent.toLowerCase().includes('training')
    //    || p.eventTagParent.match('Fire Training')
    })
    this.setNewEvents(filteredData)
  },
  setNewEvents(data){
    var map = new Map
    var newArr = [];
    for(var i = 0; i < data.length; i ++ ){
      var obj = data[i];
      var day = moment(data[i].eventDate).format('YYYY-MM-DD')
      if(map[day] === undefined || map[obj.eventTagParent] === null){
      map[day] = [];
      map[day].push(obj.eventTagParent);
      newArr.push({ day: day, tag: obj.eventTagParent });
  }else if(!map[day].includes(obj.eventTagParent)){
      map[day].push(obj.eventTagParent);
      newArr.push({ day: day, tag: obj.eventTagParent });
    }
  }
  var filteredCalEve = []
  for(var i = 0; i < newArr.length; i ++ ){
    var descriptionBody = [...new Set(data.filter( p => {
      return moment(p.eventDate).format('YYYY-MM-DD').match(newArr[i].day)
    }))]
    var descriptionTag = [...new Set(descriptionBody.filter(p => {
      return p.eventTagParent.includes(newArr[i].tag)
    }))]
    var newEvent = {
        id:i+1,
        title: newArr[i].tag,
        description: descriptionTag,
        color: this.getEventTagsVariant(newArr[i].tag),
        date: newArr[i].day,
    }
    filteredCalEve.push(newEvent)
  }
  return this.events = filteredCalEve
  },
  getEventTagsVariant(data){
    var obj = this.eventTags.find(p => p.eventtypeName == data)
    if(obj && obj.eventVariantColor){
      return obj.eventVariantColor
    } else if (obj == undefined && this.flagOnce){
       var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
       var payload = { taxonomy: 'eventtype', headers: headers, expire: true}
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
       this.flagOnce = false
      return 'default'
    } else {
      return 'default'
    }
  },
  getCredentialRequirements(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialRequirement = response.data
      })
    },


},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
  min-height: 400px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
