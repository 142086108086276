<template>
<b-container>
	<b-img  class="mt-0 px-5" src="/statportals.png" />
    <b-btn @click="resetCprData">Reset</b-btn>
        <h3 class="timerPosition" v-if="resetTimeClock">{{ currentTime }}</h3> 
  <b-row>
    <b-col cols="1" align="right">Stamp</b-col>
    <b-col cols="2" align="right">Since Last</b-col>    
    <b-col cols="2" align="left">Since Start</b-col>
    <b-col align="left">Information</b-col>
  </b-row>
  <b-row v-for="(data, index) in cprData" :key="index" class="mb-2">
    <b-col cols="1" align="right">{{ data.time }}</b-col>
    <b-col cols="2" align="right">{{ getSinceLast(data.time, index) }}</b-col>    
    <b-col cols="2" align="left">{{ getSinceStart(data.time) }}</b-col>
    <b-col align="left"><b-textarea v-model="data.text" rows="1" /></b-col>
  </b-row>
  <b-textarea placehold="type something to log" v-model="textData" @keyup.enter.native="addData" />
</b-container>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  props:[],
components:{
  },
  data () {
    return {
      currentDate: moment().format('YYYY-MM-DD'),
      edit: false,
      cprData: [],
      currentTime: '',
      resetTimeClock: true,
      textData: '',
}
  },
  created(){
     setInterval(this.getTime, 1000);
  },
  watch: {

  },
  computed:{
        ...mapState([
    'userData',
    ]),
    timeStart(){
      if(this.cprData && this.cprData[0]){
        var start = _.orderBy(this.cprData, ['time'], ['asc'])
        var first = start[0].time
        return first
      } return null
    },
    versionHistorySorted(){
      return _.orderBy(this.versionHistory, ['date'], ['desc'])
    }
  },
  methods: {
        getTime(){
      this.currentTime = moment().format('HH:mm:ss')
    },
    resetCprData(){
      this.cprData = []
    },
    getSinceStart(time){
      if(this.timeStart){
        var since = moment(time, 'HH:mm:ss').diff(moment(this.timeStart, 'HH:mm:ss'), 'seconds')
        if(since < 60){
          return since + 'secs'
        } else {
          return moment("2015-01-01").startOf('day')
                .seconds(since)
                .format('mm:ss') + 'min'
        }
        return since
      } return ''
    },
    getSinceLast(time, index){
      if(index > 0){
        var prev = index - 1
        var prevT = this.cprData[prev].time
        var since = moment(time, 'HH:mm:ss').diff(moment(prevT, 'HH:mm:ss'), 'seconds')
        if(since < 60){
          return since + 'secs'
        } else {
          return moment("2015-01-01").startOf('day')
                .seconds(since)
                .format('mm:ss')  + 'min'
        }
      } return ''
    },
    addData(){
      this.resetTimeClock = false
      var obj = { time: this.currentTime, text: this.textData }
      this.cprData.push(obj)
      this.$nextTick(function(){
        this.textData = ''
        this.resetTimeClock = true
      })
    },
     newVersionHistory(){
        this.versionHistory.push({title: 'Version 1.2',
          id: parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000),
          date: this.currentDate,
          features: [],
          bugs: []
          });
     },
     removeVersionItem(id){
        var result = confirm('Are You Sure You Want to Delete this version?')
       if(id && result){
         var index = this.versionHistory.map(p => p.id).indexOf(id)
         if(index != -1){
           this.versionHistory.splice(index, 1)
         }
       }
     },
     getFormatedDate(date){
       if(date){
         return moment(date, 'YYYY-MM-DD').format('MMMM Do YYYY')
       }
     },
     copyToClipboard(e) {
        console.log('Copied')
          var aux = document.createElement("input");
  // Get the text from the element passed into the input
  aux.setAttribute('Value', JSON.stringify(this.versionHistory));

  // Append the aux input to the body
  document.body.appendChild(aux);

  // Highlight the content
  aux.select();

  // Execute the copy command
  document.execCommand("copy");

  // Remove the input from the body
  document.body.removeChild(aux);
}





},

}
</script>
<style scoped>
.timerPosition {
      position: absolute;
    bottom: 0;
    left: 20px;
}


</style>
