<template>
  <div class="hello" v-if="userData.role == 'admin'">
    <b-form-input type="text" v-model="uploadedJson" />
    <b-form-select v-model="urlAdd" :options="urlOptions" /> 
    <b-btn @click="fixJson">Fix</b-btn>
    <b-btn @click="submitData">Submit</b-btn>
    
    <b-container>
      <b-card v-for="data in fixedData" :title="data.checkdetails_title + ' | ' + data.checkdetails_name">
        <p v-for="items in data.checkdetails_details">{{ items }}</p>
        </b-card>
      </b-container>
    <b-container>
      <h4>PG Model</h4>
      <b-form-input type="text" v-model="locationText" />
      <b-form-input type="text" v-model="copyPasteOptions" />
        const location = '{{ locationText }}'<br>
  {{ copyPasteOptionsFormat }}<br>
        const model = "CREATE TABLE {{ locationText }} (id uuid NOT NULL, created_at timestamptz, updated_at timestamptz, {{ locationPlaces }});"

      <h4>Object Creation</h4>
      obj: { {{ locationObjText }} }
      </b-container>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'hello',
  data () {
      return {
      uploadedJson: [],
      fixedData: [],
      copyPasteOptions: '',
      locationText: '',
      locationObjText: '',
      locationPlaces: '',
      urlOptions: [
        'users/users/bulk',
        'taxonomy/station/bulk',
        'taxonomy/apparatus/bulk',
        'staffing/payrollcode/bulk',
        'staffing/payrollbank/bulk',
        'staffing/payrollaccural/bulk',
        'taxonomy/inventoryitem/bulk',
        'taxonomy/checkdetails/bulk',
        'taxonomy/logisticasset/bulk'
      ],
      urlAdd: '',

    }
  },
  created(){
     
    
  },
  mounted(){

  },
  computed: {
    ...mapState([
        'getSessionData',
        'userData',
        'apparatusData',
        'stationsData',
        'deptContactData'
    ]),
  // const location = 'staffing.roster'
  // const fields = { "id": "id", "created_at": "createdAt", "updated_at": "updatedAt", "apparatus_id": "apparatusId", "station_id": "stationId", "station_parent_id": "stationParent", "roster_positions": "rosterPositions", "roster_rules": "rosterRules" } 
 //  const model = "CREATE TABLE staffing.roster (id uuid NOT NULL, created_at timestamptz, updated_at timestamptz, apparatus_id text NOT NULL, station_id text NOT NULL, station_parent_id text NOT NULL, rosterPositions json[], roster_rules json[]);" 
    copyPasteOptionsFormat(){
      if(this.copyPasteOptions != ''){
        var string = this.copyPasteOptions
        console.log(string)
         var array = string.split("\t")
         console.log(array)
      var string = 'const fields = { "id": "id", "created_at": "createdAt", "updated_at": "updatedAt", '
      var location = ''
      var locationObj = ''
      for(var i = 0; i < array.length; i++){
           var newKey = array[i].replace(/_([a-z])/g, function($1){return $1.toUpperCase().replace(/_/, "");});
           string = string + '"' + array[i] + '": ' + '"' + newKey + '"'
           location = location + array[i] + ' FIELDTYPE'
           locationObj = locationObj + newKey + ": '', "
           if(i == array.length - 1){
             string = string + ' }'
           } else {
             string = string + ', '
             location = location + ', '
           }
      }

      this.locationObjText = locationObj
      this.locationPlaces = location
      return string
      }
    },

  },
  methods: {
    fixJson(){ //fix for asset tagging
      var array = JSON.parse(this.uploadedJson)
      this.fixedData = array
    },
    getObjectDataId(type, value){
      var array = this[type]
      if(type == 'apparatusData'){
        var obj = array.find(p => p.apparatusName.toLowerCase() == value.toLowerCase())
        if(obj){
          return obj.id
        } console.error(value + ' Not Found in Database of type ' + type)
          return null
      } else if (type == 'stationsData'){
        var obj = array.find(p => p.stationName.toLowerCase() == value.toLowerCase())
        if(obj){
          return obj.id
        } console.error(value + ' Not Found in Database of type ' + type)
          return null
      } else if (type == 'deptContactData'){
        var obj = array.find(p => p.employeeId.toLowerCase() == value.toLowerCase())
        if(obj){
          return obj.id
        } console.error(value + ' Not Found in Database of type ' + type)
          return null
      } 
      console.error(value + ' Not Found in Database of type ' + type)
      return null
    },
    fixJson2(){
      var array = JSON.parse(this.uploadedJson)
      var parents = array.map(item => {
          var obj = {checkdetails_title: item.checkdetails_title,  checkdetails_name : item.checkdetails_name }
          return obj
      })
      var newArray = []
      for(var i in parents){
        var index1 = newArray.map(p => p.checkdetails_title).indexOf(parents[i].checkdetails_title)
        var index2 = newArray.map(p => p.checkdetails_name).indexOf(parents[i].checkdetails_name)
        if(index1 == -1 && index2 == -1){
          newArray.push(parents[i])
        }
      }
      var complete = newArray.map(item => { 
          var obj = item
          var holdarray = array.filter(p => {
            return p.checkdetails_title = item.checkdetails_title && p.checkdetails_name == item.checkdetails_name
          })
          obj['checkdetails_details'] = holdarray.map(p => p.checkdetails_details)
          return obj
      })
      console.log(complete)
      this.fixedData = complete
    },
    dataSub(){
     axios({
      method: 'post',
      url: '/api/v1' + data,
      data: post,
        headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        },
    }).then( response => {
      this.$nextTick(function(){
      console.log(response.data)

      })
    })
    
  },
  sanatizeUserData(data){
    var newArray = []
    for(var i in data){
      var obj = data[i]
      obj['password'] = Math.random().toString(36)
      obj.deactivated = false
      obj.blog_access = obj.blog_access.toLowerCase() == 'true' ? true : false
      obj.dept_specialties = obj.dept_specialties.split(',')
      obj.hire_date = moment(obj.hire_date).toDate()
      obj['picture'] = '/files/default_images/Blank_User_Big.png'
      newArray.push(obj)
    }
    return newArray
  },
      submitDataDuplicate(){
      var data = JSON.parse(this.uploadedJson)
      var newArray = []
      for(var i in data){
        if(newArray.map(p => p.item_name.toLowerCase()).indexOf(data[i].item_name.toLowerCase()) == -1){
          newArray.push(data[i])
        }
      } 
      axios({
        method: 'post',
        url: '/api/v1/' + this.urlAdd,
        data: newArray,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',  
        },
      }).then( response => {
        console.log(response.data)
      }) 
      
    },
    submitfixedData(){
      axios({
        method: 'post',
        url: '/api/v1/' + this.urlAdd,
        data: this.fixedData,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',  
        },
      }).then( response => {
        console.log(response.data)
      }) 
      
    

    },
    submitData(){
      var data = JSON.parse(this.uploadedJson)
      if(this.urlAdd == 'users/users/bulk'){
        data = this.sanatizeUserData(data)
        console.log(data)
      }
      axios({
        method: 'post',
        url: '/api/v1/' + this.urlAdd,
        data: data,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',  
        },
      }).then( response => {
        console.log(response.data)
      }) 
      
    }

    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}

</style>
