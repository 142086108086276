<template>
<div @click="addModalOpen">


  <b-modal
  class="mainPost"
       id="logbookModal"
       ref="logbookModal"
       size="lg"
       v-model="showModal"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
         >
         <template slot="modal-header"  v-if="!showModalConfirmation">
           Monthly Medication Tracking  <b-btn size="sm" variant="danger" align="right" @click="showModalswitchEmitVehicle">x</b-btn>
         </template>
         <div class="modal-body"  v-if="!showModalConfirmation">
          <b-col align="left">
          <h5>Current Date:</h5>
          </b-col>
          <h5 :class="shiftSet" @click="changeDate = !changeDate" v-show="!changeDate" id="todaysDateDisplay">Today: {{ currentDateSelected }} (click to edit)</h5>
          <datepicker v-if="changeDate" @input="setCurrentDate" v-model="currentDateSelectedChange"></datepicker>
            <div id="logbookBody">
            <h5 :class="shiftSet">Shift: {{ shiftSet }} </h5>
            <timepicker class="timeDesk" v-if="disctimeValueSet != ''" @change="updateTimePicker" v-model="disctimeValueSet"></timepicker>
            <input class="timeMobile" @change="updateTimeInput" type="time" v-model="timeData" />
              <multiselect v-model="apparatusSelectValue" :options="filteredApparatusData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select an Apparatus" label="name" track-by="name"></multiselect>
                <multiselect v-model="stationSelectValue" :options="filterStationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please select a Station" label="name" track-by="name">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
                </multiselect>
                <div v-if="apparatusSelectValue != ''">
                <b-btn v-if="field_form_response_data == ''" variant="primary" @click="setupMonthlyMedicationTracking">Build Medication List</b-btn>
                <b-btn v-if="field_form_response_data != ''" variant="danger" @click="setupMonthlyMedicationTracking">Reset to Default</b-btn>
              </div>
                <b-table hover striped :items="field_form_response_data" :fields="fields" :total-rows="field_form_response_data.length" :per-page="perPage" :current-page="currentPage">
                  <template slot="onhand" slot-scope="data">
                    <input style="width: 5em" type="number" v-model="data.item.onhand" />
                  </template>
                  <template slot="exp30" slot-scope="data">
                    <input style="width: 5em" type="number" v-model="data.item.exp30" />
                  </template>

                </b-table>
                <b-pagination v-if="field_form_response_data != ''" :total-rows="field_form_response_data.length" :per-page="perPage" v-model="currentPage" />
         </div>
       </div>

         <template slot="modal-footer"  v-if="!showModalConfirmation"><h4 v-show="stationSelectValue == ''">Please Select a Station</h4>
            <div v-show="stationSelectValue != ''">
              <h4 v-show="field_form_response_data == ''">Please add an item</h4>
             <b-btn v-show="field_form_response_data != ''" :disabled="pageCheck" variant="primary" @click="submitArticl">Submit</b-btn>
           </div>

       </template>

       <template slot="modal-header"  v-if="showModalConfirmation">
              Post Confirmation
       </template>
       <div  v-if="showModalConfirmation" class="modal-body">
              <div v-if="postData == ''">
                    ...Submitting post
                    <br>
                <img src="https://firerescuedev.com/drupalBack/web/sites/default/files/siteicons/MalteseLoadingGif.gif">
              </div>
              <div v-if="postData != ''">
                    Your Post has been submitted!
              </div>


            </div>
              <template slot="modal-footer"  v-if="showModalConfirmation">
                    <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                      Close Message
                    </b-btn>
             </template>
       </b-modal>
   </div>
</template>

<script>
/* <b-form-select class="shiftSelectInput" text-field="name" value-field="tid" v-model="shiftSelect" :options="shiftsData">
  <option :value="null" disabled> Please select a Shift</option>
</b-form-select>
*/

import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios';
import DatePicker from '../inputfields/Datepickermod.vue'
import TimePicker from '../inputfields/timepicker.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
export default {
  props:["showModal", "filterDate"],
components:{
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,

},
  data () {
    return {
        trueBoo: true,
        titleDescription: '',
        title:'',
        showModalConfirmation: false,
        timeValueSet: {
          HH: "12",
          mm: "00",
        },
        fields: [
        { key: 'medname', label: 'Med'},
        { key: 'desired', label: 'Desired '},
        { key: 'onhand', label: 'On-Hand'},
        { key: 'exp30', label: 'Exp 30Days'},
        ],
        currentPage: 1,
        perPage: 10,
        currentDateSelected: '',
        currentDateSelectedChange: '',
        stationSelectValue:'',
        apparatusSelectValue:[],
        changeDate: false,
        field_form_response_data:[],
        editButton: 'edit',
        showEditView: false,
        shiftSelect: null,
        postData: '',
        currentTime: '',
        disctimeValueSet: '',
        selectedForm: 502,
    }
  },
  created(){
        this.currentDateSelected = moment().format('MM/DD/YYYY');
        this.currentDateSelectedChange = this.currentDateSelected
        this.setTimes()

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'eventTags',
          'apparatusData',
          'userData',
          'userPosts',
          'departmentFeed',
          'shiftsData',
          'medicationData'
        ]),
        pageCheck(){
          var pages = this.field_form_response_data.length / this.perPage
          var rounded = Math.ceil(pages)
          if(this.currentPage == rounded){
            return false
          } else {
            return true
          }
        },
        filteredApparatusData(){
          return this.apparatusData.filter((p) => {
            if(p.field_assignment_filter != 'Reserve' && p.name != 'EMS 01' && p.name != 'EMS 02'){
            return p.field_medical_type.match('ALS')}
          })
        },
      filterStationsData(){
        return this.stationsData.filter((p) => {
          if(p.name_1 != '' && this.userData[0].roles_target_id == 'Battalion Chief'){
            return p
          }
          else{
            return p.name.match("Station")
          }
        })

      },
      timeDifference(){
        var hours = this.disctimeValueSet.HH
        var mins = this.disctimeValueSet.mm
        var set = hours + ':' + mins
        var setTime = moment(set, 'HH:mm')
        var currentTime = moment(this.currentTime, 'HH:mm')
        return moment(currentTime).diff(setTime, 'minutes')
      },
        formatedDateExport () {
          var dateString = moment(this.currentDateSelected, 'MM/DD/YYYY').format("YYYY-MM-DD")
          var timeString = dateString + 'T' + this.currentTime
          var offSet = moment().utcOffset()
          var hourOffSet = ((offSet * -1)/60)
          var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
          var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
          return timeStringAdd
        },
      shiftSet(){
        var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
        var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
        var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
        var dateDiffCalNumber = 100*(dateDiffCal/3);
        var dateTenDigit = dateDiffCalNumber % 10;
        var roundedDated = Math.round(dateTenDigit)
        if (roundedDated == 0)
        return "B-shift";
        if (roundedDated == 7)
        return "A-shift";
        if (roundedDated == 3)
        return "C-shift";
      },


    },
 beforeDestroy(){
          document.body.classList.remove('modal-open');
      },
  methods:{
    shiftId(data){
      var shiftObj = this.shiftsData.filter(p => p.name.match(data))
      return shiftObj[0].tid
    },
    setTimes(){
      this.currentTime = moment().format('HH:mm')
      var data = this.currentTime
      var timeSet = data.split(':')
      var timeString = { HH: timeSet[0], mm: timeSet[1] }
      this.disctimeValueSet = timeString
      this.timeData = data
    },
    updateTimePicker(event){
      this.currentTime = event.data.HH + ':' + event.data.mm
      this.timeData = event.data.HH + ':' + event.data.mm
    },
    updateTimeInput(event){
      var timeSet = this.timeData.split(':')
      var timeString = { HH: timeSet[0], mm: timeSet[1] }
      this.disctimeValueSet = timeString
      this.currentTime = this.timeData
    },
    addModalOpen(){
      document.body.className += 'modal-open';
    },
    indexPosition(post){
        var idx = this.field_form_response_data.indexOf(post)
        return idx + 1
    },
    showModalSwitchEmit () {
      bus.$emit('showModalSwitchMed');
    },
    showModalswitchEmitVehicle () {
      this.apparatusSelectValue = ''
      this.stationSelectValue = ''
      this.field_form_response_data = []
      this.shiftSelect = ''
      bus.$emit('showModalSwitchMed');
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalSwitchEmit()
      this.eventTagsSelectValue = ''
      this.stationSelectValue = ''
      this.field_form_response_data = []
      this.shiftSelect = ''
    },
    setupMonthlyMedicationTracking(){
      this.field_form_response_data = []
      for(var i = 0; i < this.medicationData.length; i++) {
        if(this.apparatusSelectValue.field_vehicle_type == 'ENGINE'){
          var setString = {medname: this.medicationData[i].medicationName,
                            desired: this.medicationData[i].engineCount,
                            onhand:  this.medicationData[i].engineCount,
                            exp30: "0" }
        this.field_form_response_data.push(setString)
      } else {
        var setString = {medname: this.medicationData[i].medicationName,
                          desired: this.medicationData[i].rescueCount,
                          onhand:  this.medicationData[i].rescueCount,
                          exp30: "0" }
      this.field_form_response_data.push(setString)
    }
    }

    },
    buildArrayData(data){
      var dataArray = []
      for(var i = 0; i < data.length; i++){
        var string = "medname:" + data[i].medname + " || desired:" + data[i].desired + " || onhand:" + data[i].onhand + " || exp30:" + data[i].exp30
        dataArray.push({value:string})
      }
      return dataArray
    },
    submitArticl(){
     axios({
      method: 'post',
      url: 'https://firerescuedev.com/drupalBack/web/entity/node?_format=hal_json',
      data: {'_links': {
                      'type': {
                          'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/node/forms'
                        }},
              "title":[{"value": this.currentDateSelected + ' | ' + this.apparatusSelectValue.name + ' | ' + this.shiftSet + ' | MonthlyMed' }],
              "type":[{"target_id":"forms"}],
              "field_form_completion_date": [{"value": this.formatedDateExport}],
              "field_station_location":[{"target_id": this.stationSelectValue.tid}],
              "field_apparatus_id":[{"target_id": this.apparatusSelectValue.tid}],
              "field_current_shift":[{"target_id":this.shiftId(this.shiftSet)}],
              "field_type_of_form":[{"target_id":this.selectedForm}],
              "field_form_response_data":this.buildArrayData(this.field_form_response_data),
            },
      headers: {
        'X-CSRF-TOKEN': this.getSessionData,
        'Content-Type' : 'application/hal+json',
      },

    }).then( response => {
      console.log(response.data)
      this.postData = response.data
        })


  this.showModalConfirmation = !this.showModalConfirmation;

  }
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>

<style scoped>
.A-shift {
  color: #e2070d;
}
.B-shift {
  color: #201B9F;
}
.C-shift {
  color: #0FB906;
}
#tablePassDown .container {
  padding-left: 0;
  padding-right: 0;
}
.shiftSelectInput{
  margin-bottom: 10px;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
text-align: center;
margin-top: 20px;
}
h4.sub-section-heading{
  padding: 5px;
}
.addNewButton {
  margin-top: 10px;
}
.editRowValue {
  margin-top: 20px;
  margin-bottom: 10px;
}
.input-group{
  margin-bottom: 10px;
}
input {
  border-radius: 5px;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 15px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;
}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
