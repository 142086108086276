<template>
  <div>
    <b-btn block variant="outline-primary" @click="showBody = !showBody">{{ item.title }}</b-btn>
       <b-btn v-show="showBody" size="sm" variant="danger" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'trainingops'" @click="deleteTrainingRefItem(item.id)">Delete</b-btn>
    <div v-if="showBody" v-for="(item, index) in item.trainingitemData" :key="index">
                  
            <div class="m-2" v-if="item.type == 'html'">
              <div v-html="item.value"></div>
              </div>
            <div class="m-2" v-if="item.type == 'text'">
              <div>{{ item.value }}</div>
              </div>            
            <div class="m-2" v-if="item.type == 'video'">
              <div class="videoWrapper">
                   <iframe :src="'https://www.youtube.com/embed/' + getVideoId(item.value)" frameborder="0" allowfullscreen></iframe>
                   </div>
              </div>            
            <div class="m-2" v-if="item.type == 'photo'">
                   <b-img class="p-5" fluid-grow :src="siteconfig.deptStore + item.value" />
              </div>  
            <div v-if="item.type == 'link' || item.type == 'file'">
                           <b-btn variant="link" v-if="item.showEdit == false" @click="viewFile(item.value.url)" >{{ item.value.title }}</b-btn>
            </div>    
                        </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
props:["item"],
components:{

},
  data () {
    return {
      showBody: false,
    }
  },
  computed: {
    ...mapState([
      'userData',
      'getSessionData',
      'siteconfig'
    ])
  },
   methods:{
     deleteTrainingRefItem(id){

        axios({
            method: 'delete',
            url: '/api/v1/content/trainingitem/' + id,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
             console.log(response.data)
            }) 

     },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
       getVideoId(data){
           var videoLink = data.split('=')
      
          if(videoLink.length == 1){
            var videoLink = data.split('.be/')
          }
     
        return videoLink[1]
      },
  },
}
</script>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}

.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
</style>
