<template>
<div v-if="(form && form.id) || previousForm">
<b-container fluid class="px-1 px-md-4">
<b-container fluid class="px-1 px-md-4">
  <b-card header-tag="header" body-class="px-1 px-md-4">
       <div slot="header">
         <span class="font-weight-bold">Form Submission <b-badge v-if="previousForm && previousForm.formSubLocked" variant="success" class="char">locked</b-badge></span>
         <b-btn class="float-right" v-if="previousForm && previousForm.formSubLocked && userCanUnlock(previousForm.formSubmission)" size="sm" variant="danger" @click="unlockForm(previousForm.id)">Unlock</b-btn>
         <b-btn 
         size="sm" class="float-right" 
         v-if="previousForm && !previousForm.formSubLocked && userCanEdit(previousForm.formSubmission)" 
         :variant="editOff ? 'outline-secondary' : 'danger'" @click="editOff = !editOff" >{{ editOff ? 'Edit' : 'Cancel' }}</b-btn>
       </div>
     <formField v-if="formFields && formFields.length > 0 && !formReset"  
        :group="returnGroupData"  
        :submissionView="submissionView"
        @updateObjectValue="updateFormDataHold"
        :vmodelHoldDefault="vmodelHold"
        :previousForm="previousForm ? true : false"
        :editOff="editOff"
        :formValues="formValues"
        @arrayrequired="arrayrequired"
       />
    <b-card 
        v-if="form && submissionView"
        header-tag="header"
        :header-bg-variant="arrayRequiredData && arrayRequiredData.length > 0 ? 'danger' : 'success'"
        :header-text-variant="arrayRequiredData && arrayRequiredData.length > 0 ? 'light' : 'light'"
        :no-body="arrayRequiredData && arrayRequiredData.length > 0 ? true : false"
    >
    <div slot="header">
      <h5>{{ 'Submission ' + '(required fields ' + arrayRequiredData.length + ')' }}</h5></div>
    <b-container fluid v-if="arrayRequiredData && arrayRequiredData.length > 0 ? false : true">
    <b-container  v-if="form.formSubmission && form.formSubmission.emailChooseEmail" class="px-4 pb-2">
      <b-row>
        <b-col cols="*"><h5 class="pt-2">Additional Emails:</h5></b-col>
        <b-col>
    <multiselect v-model="emailUserSelect" @input="returnEmailAddressGet" multiple :options="deptContactData" label="email" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Forward Submission to Additional Users">
        <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span></template>
        <template slot="option" slot-scope="props">
          <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
        </template>
    </multiselect>
        </b-col>
      </b-row>
    </b-container>
    <b-card title="Submission Will be Email to:" v-if="cycleEmailValue">
      <b-badge variant="info" class="mr-2" v-for="(email, index) in returnEmailAddress" :key="index">{{ email }}</b-badge>
    </b-card>
    <b-row class="pt-2 px-5"><b-col align="right">
        <b-btn variant="success" @click="submitForm" v-if="form && submissionView">Submit Form</b-btn>
        </b-col>
    </b-row>
    </b-container>
    </b-card>
    </b-card>
        <b-card v-if="previousForm" header-tag="header">
      <div slot="header">Submission Info</div>
             <p class="mb-0" v-if="previousForm">Originally Submitted by {{ previousForm.formSubAuthor }} on {{ previousForm.createdAt }}</p>
      <div  v-if="previousForm.formSubEmails">
      <b-badge variant="info" class="mr-2" v-for="(email, index) in previousForm.formSubEmails" :key="index">{{ email }}</b-badge>
      </div>
              <b-btn variant="success" class="mt-2" @click="updateForm" v-if="!editOff">Update Submission Form</b-btn>

           </b-card>
</b-container>
</b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import formFieldModal from '../admin/digitalforms/newDigitalFormsFieldsModal.vue'
import formField from '../admin/digitalforms/newDigitalFormsFields.vue'
import draggable from 'vuedraggable'
import multiselect from "vue-multiselect";
export default {
  components: {
    formFieldModal,
    formField,
    multiselect,
    draggable
  },
  name: 'new-digital-forms',
  props: ['form', 'previousForm', 'formTemplates'],
  data () {
    return {
       formFields: [],
       editOff: null,
       formName: '',
       formActive: false,
       formSubmissionOptions: '',
       currentEditFieldObject: '',
       showNewFieldModal: false,
       formReset: false,
       vmodelHold: {},
       arrayRequiredData: [],
       formValues: {},
       submissionView: true,
       formParentCat: null,
       formCat: null,
       emailUserSelect: [],
       formSubmission: {
         emailAuthorFlag: true,
         emailChooseEmail: false,
         emailSelectFlag: false,
         emailTaggedFlag: false,
         emailSelect: [],
         emailTagged: [],
         emailRoles: [],
         editAuthor: false,
         editSelectFlag: false,
         editTaggedFlag: false,
         editSelect: [],
         editTagged: [],
         editRoles: ['admin'],
         visibilityCreateRoles: ['admin', 'adminchief', 'fireops', 'emsops', 'logisticsops', 'trainingops', 'shopops', 'supervisor', 'user'],
         visibilitySubmittedRoles: ['admin', 'adminchief', 'fireops', 'emsops', 'logisticsops', 'trainingops', 'shopops', 'supervisor'],
         lockSubmission: false,
         lockRoles: ['admin', 'adminchief'],
         unlockRoles: ['admin', 'adminchief']
       },
       cycleEmailValue: true,
       submissionTableSelection: '',
       returnEmailAddress: [],
       submissionTableHeader: '',
      submissionTableSelections: [],
      formholdid: '',
      siteRoles: [
          { text: 'Admin', value: 'adminchief'},
          { text: 'Fire Ops' , value: 'fireops'},
          { text: 'EMS Ops', value: 'emsops'},
          { text: 'Training Ops', value: 'trainingops'},
          { text: 'Shop Ops', value: 'shopops'},
          { text: 'Logistics Ops', value: 'logisticsops'},
          { text: 'Supervisor', value: 'supervisor'},
          { text: 'User', value: 'user'},
      ],
    }
  },
  created(){
    if(this.previousForm){
      this.formFields = this.previousForm.formFields
      this.editOff = true
    }
    if(this.form){
      this.formholdid = this.form.id
      this.formName = this.form.formName
      this.formFields = this.form.formFields
      this.formActive = this.form.formActive
      if(this.form.formParentCat){
        this.formParentCat = this.form.formParentCat
      }
      if(this.form.formCat){
        this.formCat = this.form.formCat
      }
      if(this.form.formTableCols){
        this.submissionTableSelections = this.form.formTableCols
      }
      if(this.form.formSubmission){
        this.returnEmailAddressGet()
      //  this.formSubmission = _.cloneDeep(this.returnFormSubmission(this.form.formSubmission))
      }
    } else {
      this.formholdid = Math.random().toString(36).substr(2, 15)
    }
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData',
          'documentTags'
        ]),
        returnGroupData(){
          var obj = {
            id: "100",
            groupName: "Module Tracking Information",
            groupVariant: "primary",
            groupType: "defined",
            groupFields: this.formFields
          }
          this.buildFormData([obj])
          return obj
        },
  
  },
  mounted(){

  },
  watch: {

  },
  methods: {
      userCanUnlock(sub){
        if(sub && sub.unlockRoles){
          var index = sub.unlockRoles.indexOf(this.userData.role)
          if(index != -1){
            return true
          } return false
        } return false
      },
      userCanEdit(sub){
        if(sub && sub.editRoles){
          var index = sub.editRoles.indexOf(this.userData.role)
          if(index != -1){
            return true
          } 
        } 
        if(sub && sub.editAuthor){
          if(this.previousForm && this.previousForm.formSubAuthorId && this.previousForm.formSubAuthorId == this.userData.id){
            return true
          }
        } 
        if(sub && sub.editSelectFlag && sub.editSelect && sub.editSelect.length > 0){
          if(sub.editSelect.indexOf(this.userData.id) != -1){
            return true
          }
        } 
        if(sub && sub.editTaggedFlag && sub.editTagged && sub.editTagged.length > 0){
          var subData = _.cloneDeep(this.previousForm.formSubData)
          var fields = sub.editTagged
          for(var f in fields){
            var obj = subData[fields[f]]
            if(obj && obj.value && obj.value.length > 0){
              if(obj.value.indexOf(this.userData.id)){
                return true
              }
            }
          }
        }
        
        return false
      },
      returnEmailAddressGet(){ 
          var formValues = this.formValues
          var array = []
          var obj = _.cloneDeep(this.form ? this.form.formSubmission : this.previousForm ? this.previousForm.formSubmission : {})
          if(obj.emailAuthorFlag){
            array.push(this.userData.email)
          }
          if(obj.emailSelectFlag && obj.emailSelect && obj.emailSelect.length > 0 ){
            var esHold = _.cloneDeep(obj.emailSelect)
            for(var e in esHold){
              if(esHold[e]){
                array.push(esHold[e])
              }
            }
          }
          if(obj.emailTaggedFlag && obj.emailTagged && obj.emailTagged.length > 0){
            var etHold = obj.emailTagged
            for(var e in etHold){
              if(formValues[etHold[e]] && formValues[etHold[e]].value){
                var ids = formValues[etHold[e]].value
                for(var i in ids){
                var user = this.deptContactData.find(p => p.id == ids[i])
                if(user && user.email){
                array.push(user.email)
                }
                }
              }
            }
          }
          if(obj.emailRoles && obj.emailRoles.length > 0){
            var erHold = obj.emailRoles
            var userFilter = this.deptContactData.filter(p => {
                var index = erHold.indexOf(p.role)
                if(index != -1){
                  array.push(p.email)
                }
            })
          }
          if(obj.emailSelect && this.emailUserSelect && this.emailUserSelect.length > 0){
              var euHold = this.emailUserSelect
              for(var e in euHold){
                if(euHold[e] && euHold[e].email){
                array.push(euHold[e].email)
                }
              }
          }
          var set = [...new Set(array.map(p => p))]
          this.returnEmailAddress = set
        },
    arrayrequired(data){
      if(data && data.length > 0){
        this.arrayRequiredData = data
      } else {
        this.arrayRequiredData = []
      }
    },
    addTableSelections(){
     var obj = this.submissionTableSelection
     obj['label'] = this.submissionTableHeader
     this.submissionTableSelections.push(obj)
     this.$nextTick(function(){
       this.submissionTableSelection = ''
       this.submissionTableHeader = ''
     })
    },
    documentSub(data){
      var returnData = this.documentTags.filter(p => {
        return p.documenttypeParent.match(data)
      })
      return _.orderBy(returnData, ['documenttypeName'], ['asc'])
    },
    returnFormSubmission(data){
      var obj = data
        obj['emailTagged'] = data.emailTagged && data.emailTagged.length > 0 ? data.emailTagged.map(item => this.getFormObj(item)) : []
        obj['emailSelect'] = data.emailSelect && data.emailSelect.length > 0 ? data.emailSelect.map(item => this.getUserObj(item, 'email')) : []
        obj['editSelect'] = data.editSelect && data.editSelect.length > 0 ? data.editSelect.map(item => this.getUserObj(item, 'id')) : []
        obj['editTagged'] = data.editTagged && data.editTagged.length > 0 ? data.editTagged.map(item => this.getFormObj(item)) : []
       // console.log(obj)
       return obj
    },
    getUserObj(value, key){
      var obj = this.deptContactData.find(p => p[key] == value)
      if(obj){
        return obj
      } return {}
    },
    getFormObj(value){
      var obj = this.form.formFields.find(p => p.label == value)
      if(obj){
        return obj
      } return {}
    },
    defaultSubmissionTableHeader(data){
      if(data && data.formLabel){
          this.submissionTableHeader = _.truncate(data.formLabel, {length: 20})
      }
    },
    showFieldModelEdit(id){
      var obj = this.formFields.find(p => p.id == id)
      if(obj){
        this.currentEditFieldObject = obj
        this.showNewFieldModal = true
      }
    },
    updateFormDataHold(data){
        var objHold = data.objKey
        delete data.objKey
        this.formValues[objHold] = data
        this.returnEmailAddressGet()
    },
    showFieldModelDelete(id){
       var index = this.formFields.map(p => p.id).indexOf(id)
      if(index != -1){
        this.formFields.splice(index, 1)
        this.showNewFieldModal = false
        this.currentEditFieldObject = ''
      }
    },
    saveModalHoldObj(data, boo){
      var objkeyhod = Math.random().toString(36).substr(2, 15)
      var id = objkeyhod + data.label.replace(/ /g,'')
      var obj = data
      if(!obj.id){
      data.id = id
      data.objKey = data.label.replace(/ /g,'') + '_' + objkeyhod
      this.formFields.push(data)
      } else {
        var index = this.formFields.map(p => p.id).indexOf(data.id)
        this.formFields[index] = data
      }
      this.formReset = true
     this.$nextTick(function(){
       this.formReset = false
     })
      if(!boo){
        this.showNewFieldModal = false
      } else {
        this.currentEditFieldObject = data
      }
    },
    buildFormData(data){
      if(this.previousForm && this.previousForm.formSubData){
        var dataP = _.cloneDeep(this.previousForm.formSubData)
        var keys = Object.keys(dataP)
        var vmodelHold = {}
        for(var k in keys){
          var obj = dataP[keys[k]]
          var val = obj.value ? obj.value : ''
          vmodelHold[keys[k]] = val
        }
        this.vmodelHold = vmodelHold
        this.formValues = dataP
        return
      }
      var obj = {}
      var vmodelHold = {}
      for(var i in data){
        var fields = data[i].groupFields
        for(var f in fields){
          if(fields[f].itemType == 'form')
          var value = ''
          if(fields[f].type == 'select'){
            var obj = fields[f].selectOptions.find(p => p.default == true)
            if(obj){
              value = obj.value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            }
          }
          if(fields[f].type == 'checkbox'){
            var obj = fields[f].selectOptions.filter(p => p.default == true)
            if(obj){
              value = obj.map(p => p.value)
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            }
          }
          if(fields[f].type == 'boolean'){
              value = fields[f].value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            
          }
          if(fields[f].type == 'upload'){
            value = []
          }
          if(fields[f].type == 'selectmulti'){
            var obj = fields[f].selectOptions.filter(p => p.default == true)
            if(obj){
              value = obj
                var array = []
                var arrayStore = []
                for(var v in value){
                    array.push(value[v].value)
                } 
                for(var v in value){
                  arrayStore.push(value[v].text)
                }
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: arrayStore, searchvalue: array}
            } else {
              value = []
            }
          }
          if(fields[f].type == 'date' || fields[f].type == 'time' || fields[f].type == 'datetime'){
            value = {'date': '', 'time': ''}
          }
          vmodelHold[fields[f].objKey] = value
        }
      }
      this.vmodelHold = vmodelHold
    //  this.formValues = vmodelHold
    },
    unlockForm(id){
          axios({
        method: 'patch',
        url: '/api/v1/content/digitalformsub/' + id,
        data: {
          formSubLocked: false,
        },
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        if(response.data && response.data.id){
        this.$emit('closeFormAndReOpen', response.data)
        }
      })  
    },
    updateChangeLog() {
      var prev = _.cloneDeep(this.previousForm.formSubData)
      var keys = Object.keys(prev)
      var update = _.cloneDeep(this.formValues)
      var changes = {}
      for(var k in keys){
        if(prev[keys[k]] && update[keys[k]]){
          if(prev[keys[k]].value && update[keys[k]].value){
            if(!(_.isEqual(prev[keys[k]].value, update[keys[k]].value))){
            changes[keys[k]] = update[keys[k]]
            changes[keys[k]]['previousValue'] = prev[keys[k]].value
            }
          }
        }
      }
      return changes
    },
    getFormName(){  
      if(this.previousForm && this.previousForm.formId){
        var obj = this.formTemplates.find(p => p.id == this.previousForm.formId)
        if(obj && obj.formName){
          return obj.formName
        } return 'UNKNOWN NAME'
      } return 'UNKNOWN NAME'
    },
    updateForm(){
      var id = this.previousForm.id
      var previousChanges = this.previousForm.formSubChangeLog ? this.previousForm.formSubChangeLog : []
      var changeObj = { 
            changeData: moment().toISOString(), 
            changeAuthor: this.userData.firstName + ' ' + this.userData.lastName,
            changeAuthorId: this.userData.id,
            change: this.updateChangeLog()
            }
      previousChanges.push(changeObj)
      var obj = {
          formSubData: this.formValues,
          formSubEmails: this.returnEmailAddress && this.returnEmailAddress.length > 0 ? this.returnEmailAddress : 
          this.previousForm.formSubEmails && this.previousForm.formSubEmails.length > 0 ? this.previousForm.formSubEmails : [],
          formSubLocked: this.previousForm.formSubmission && this.previousForm.formSubmission.lockSubmission != undefined ? this.previousForm.formSubmission.lockSubmission : null,
          formSubChangeLog: previousChanges
      }
      obj['message'] = this.userData.email
      var author = this.previousForm && this.previousForm.formSubAuthor ? this.previousForm.formSubAuthor : 'Unknown Author'
      var date = this.previousForm && this.previousForm.createdAt ? moment(this.previousForm.createdAt).format('MM/DD/YY HH:mm') : 'Unknown Date'
      obj['messageBody'] = 'UPDATE RECEIVED: ' + this.getFormName() + ' | Submitted by ' + author + ' originally on ' + date
      obj['emailCC'] = obj.formSubEmails
      this.editOff = true
     axios({
        method: 'patch',
        url: '/api/v1/content/digitalformsub/' + id,
        data: obj,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        if(response.data && response.data.id){
        this.$emit('closeFormAndReOpen', response.data)
        }
      })  
    },
    submitForm(){
      var obj = {
        formId: this.form.id,
        formFields: this.form.formFields,
        formSubmission: this.form.formSubmission,
        formSubData: this.formValues,
        formSubEmails: this.returnEmailAddress,
        formSubAuthor: this.userData.firstName + ' ' + this.userData.lastName,
        formSubAuthorId: this.userData.id,
        formSubLocked: this.form.formSubmission && this.form.formSubmission.lockSubmission != undefined ? this.form.formSubmission.lockSubmission : null,
        formName: this.form.formName
      } 
      axios({
        method: 'post',
        url: '/api/v1/content/digitalformsub/',
        data: obj,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
          if(response && response.data.id){
            this.$emit('openSubmittedForm', response.data)
                      }
      })
    },



}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.char {
  font-family: "Times", "Times New Roman", "serif", "sans-serif", "EmojiSymbols";
}
.requirement-error{
  border: 1px solid red;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
