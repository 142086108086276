<template>
  <div class="hello">
   <div v-if="watchForChange" v-for="(data, index) in userGroups" :key="index">
     <b-card><b-btn size="sm" @click.stop="setEditData(index)" variant="warning">Edit</b-btn>
              <span><h4 v-show="!data.edit">{{ data.workgroupName}}</h4>
                <b-form-input v-show="data.edit" v-model="data.workgroupName" />              
                </span>
       <b-row>
         <b-col>

       <h4>Admin Users</h4>
       <em>gives the ability to add/remove users</em>
      <multiselect  :id="'adminselect' + data.id" :disabled="!data.edit"  v-if="resetMultiSelect" @input="resetMultiSelectGet" v-model="data.updateAdminList" :options="deptContactData" :multiple="true" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer User">
           <template slot="option" slot-scope="props">
             <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
            </template>
        </multiselect>
         </b-col>
         <b-col>
       <h4>Users Assigned to the Group</h4>
        <em>will show up as a group participant</em>
        <multiselect  :id="'assignedGroup-' + data.id" v-if="resetMultiSelect" @input="resetMultiSelectGet" :disabled="!data.edit" v-model="data.updateUserList" :options="deptContactData" :multiple="true" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer User">
           <template slot="option" slot-scope="props">
             <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
            </template>
        </multiselect>
        </b-col>
        </b-row>
        <h4 class="my-1">Work Group Settings</h4>
       <span><b-form-checkbox :id="'checkbox1' + data.id"
       :disabled="!data.edit"
                     v-model="data.workgroupCalendar"
                     value="true"
                     unchecked-value="false">
            Calender </b-form-checkbox></span>
       <span><b-form-checkbox :id="'checkbox2' + data.id"
       :disabled="!data.edit"
                     v-model="data.workgroupBlog"
                     value="true"
                     unchecked-value="false">Blog </b-form-checkbox></span> 
       <span><b-form-checkbox :id="'checkbox3' + data.id"
       :disabled="!data.edit"
                     v-model="data.workgroupActive"
                     value="true"
                     unchecked-value="false">Active </b-form-checkbox></span>
                     <b-row v-show="data.edit">
                       <b-col align="left">
                      <b-btn size="sm" variant="danger" @click.stop="deleteGroup(data)">Delete</b-btn>
                      </b-col>
                       <b-col align="right">
                          <b-btn size="sm" variant="primary" @click.stop="saveChanges(data)">Save Changes</b-btn>
                       </b-col>
                       </b-row>
     </b-card>


     </div>
           <b-card class="bg-primary">
      <b-card class="border-primary" header-slot="header">
        <h5><em>Create New Work Group</em></h5>
        <b-form-input class="w-50 mx-auto" v-model="newName" placeholder="Work Group Name" />            
        <b-container>
             <b-row class="mt-2">
         <b-col>

       <h4>Admin Users</h4>
       <em>gives the ability to add/remove users</em>
      <multiselect  v-model="newAdminList" :options="deptContactData" :multiple="true" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer User">
           <template slot="option" slot-scope="props">
             <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
            </template>
        </multiselect>
         </b-col>
         <b-col>
       <h4>Users Assigned to the Group</h4>
        <em>will show up as a group participant</em>
        <multiselect  v-model="newUserList" :options="deptContactData" :multiple="true" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Custody Transfer User">
           <template slot="option" slot-scope="props">
             <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
            </template>
        </multiselect>
        </b-col>
        </b-row>
        <b-row>
          <b-col>
              <h4 class="my-1">Work Group Settings</h4>
       <span><b-form-checkbox id="checkbox1"
      
                     v-model="newCalendar"
                     value="true"
                     unchecked-value="false">
            Calender </b-form-checkbox></span>
       <span><b-form-checkbox id="checkbox2"
     
                     v-model="newBlog"
                     value="true"
                     unchecked-value="false">Blog </b-form-checkbox></span> 
       <span><b-form-checkbox id="checkbox3"
     
                     v-model="newActive"
                     value="true"
                     unchecked-value="false">Active </b-form-checkbox></span>
          </b-col>
          <b-col cols="*">
        <b-btn class="mt-2" variant="primary" @click="addNew()">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>
      </b-card>
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
import Multiselect from "vue-multiselect";
export default {
  props: ['taxData'],
  name: 'hello',
  components: {
    'multiselect': Multiselect,
  },
  data () {
    return {
      updateUserList: '',
      watchForChange: true,
      userGroups: [],
      newUserList: '',
      newAdminList: '',
      newName: '',
      newCalendar: false,
      newBlog: false,
      resetMultiSelect: true,
      newActive: true,
    }
  },
  created(){
      this.setUserData()

  },
  computed: {
      ...mapState([
        'userData',
        'documentTags',
        'shiftsData',
        'eventTags',
        'blogType',
        'deptContactData',
        'departmentRanks',
        'departmentSpecialties',
        'vehicleCheckDetails',
        'vehicleCheckItems',
        'workgroupData',
        'getSessionData'
      ]),

      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },
      /*

      How to filter against two arrays

      docArray(){
        var loaded = [... new Set(this.documentsApi.map(p => p.field_document_id))]
        var bulk = [... new Set(this.docArrayPre.map(p => p.field_document_id))]
        var test = this.docArrayPre
        console.log(loaded)
        console.log(bulk)
        for(var i = 0; i < loaded.length; i++){
          var index = bulk.indexOf(loaded[i])
          if(index > -1){
          test.splice(index, 1)}
        }
        return test

      } */
  },
  watch: {
    taxData(newData){
      return this.setUserData()
    }
},
  methods: {
    resetMultiSelectGet(){
      this.resetMultiSelect = false
      this.$nextTick(function(){
        this.resetMultiSelect = true
      })
    },
  setEditData(idx){
    this.watchForChange = false
    var edit = this.userGroups[idx].edit
    if(edit == false){
      this.userGroups[idx].edit = true
    } else {
      this.userGroups[idx].edit = false
    }
    this.$nextTick(function(){
      this.watchForChange = true
    })
  },
  checkIfAdmin(id, admin){

    var index = admin.indexOf(id)

    if(index >= 0){
      return true
    } else {
      return false
    }
  },
  setUserData(data, index){
    var userArray = [...new Set(this.taxData.map(p => p))]
    for(var u = 0; u < userArray.length; u++){
    var data = userArray[u].workgroupUsers
    var data2 = userArray[u].workgroupAdmins
    var array = []
        var array2 = []
    for(var i = 0; i < data.length; i++){
      var user = this.deptContactData.filter( p => {
        return p.id == data[i]
      })
      if(user.length > 0){
        array.push(user[0])
      }
    }
  for(var i = 0; i < data2.length; i++){
      var user = this.deptContactData.filter( p => {
        return p.id == data2[i]
      })
      if(user.length > 0){
        array2.push(user[0])
      }
    }
    userArray[u].updateUserList = array
    userArray[u].updateAdminList = array2
    userArray[u].edit = false
    }
    
   this.userGroups = userArray
  },
  addNew(){
    var admins = this.newAdminList
    var admins = admins.map(p => p.id)
    var users = this.newUserList
    var users = users.map(p => p.id)
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'workgroup', headers: headers, expire: true}
    axios({
      method: 'post',
      url: '/api/v1/taxonomy/workgroup/',
      data: {
        'workgroupUsers': users,
        'workgroupAdmins': admins,
        'workgroupActive': this.newActive,
        'workgroupBlog': this.newBlog,
        'workgroupCalendar': this.newCalendar,
        'workgroupName': this.newName
      },
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
          this.newAdminList = ''
          this.newUserList = ''
          this.newName = ''
          this.newCalender = false
          this.newBlog = false
          this.newActive = true
      })
    
  },
  deleteGroup(data){
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'workgroup', headers: headers, expire: true}
    axios({
      method: 'delete',
      url: '/api/v1/taxonomy/workgroup/' + data.id,
      data: {
      },
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
      })
  },
  saveChanges(data){
    this.watchForChange = false
    var admins = data.updateAdminList
    var admins = admins.map(p => p.id)
    var users = data.updateUserList
    var users = users.map(p => p.id)
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'workgroup', headers: headers, expire: true}
    axios({
      method: 'patch',
      url: '/api/v1/taxonomy/workgroup/' + data.id,
      data: {
        'workgroupUsers': users,
        'workgroupAdmins': admins,
        'workgroupActive': data.workgroupActive,
        'workgroupBlog': data.workgroupBlog,
        'workgroupCalendar': data.workgroupCalendar,
        'workgroupName': data.workgroupName
      },
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
          this.watchForChange = true
  
      })
    
  },

  
 },

}
</script>
<style :src="'./customcss/vue-multiselect.min.css'">

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}
</style>
