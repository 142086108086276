<template>
<div id="homelanding">
<b-container fluid class="px-0">
  <b-form-input v-model="uploadedJson" @input="fixData()" />
<b-card v-if="inventorySheetData">
                  <b-pagination
                class="mb-0"
      v-model="currentPage"
      :total-rows="inventorySheetData.length"
      :per-page="10"
      aria-controls="my-table"
    ></b-pagination>
    <b-table   :items="inventorySheetData" :current-page="currentPage" :per-page="10">
  </b-table>
</b-card>
</b-container>
<b-btn @click="submitBulkInventory">Submit</b-btn>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,
      'download-excel' : JsonExcel

},
props: ['inventoryItemsData'],
data() {
    return {
      showAdmin: false,
      showModal: false,
      currentPage: 1,
      searchData: '',
      filterSupplySearch: '',
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      inventoryTemplateData: [],
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
}
},
created () {

},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),

},
mounted(){

},
watch:{

},
methods: {
  fixData(){
    var data = JSON.parse(this.uploadedJson)
    var hold = data.map(item => {
        var obj = {}
        obj['importName'] = item.description
        obj['itemVendor'] = []
        var vender = ['vendor1', 'vendor2', 'vendor3', 'vendor4']
        for(var v in vender){
          if(item[vender[v]] && item[vender[v]] != 'NULL' && item[vender[v]] != ''){
          var vend = {}
          vend['name'] = item[vender[v]]
          vend['price'] = item[vender[v] + '_price']
          vend['vendId'] = item[vender[v] + '_id']
          vend['description'] = item.description2
          vend['saleType'] = item[vender[v] + '_pk']
          vend['saleQty'] = item[vender[v] + '_per']
          obj.itemVendor.push(vend)
        }
        }
        obj['supply'] = this.getItemId(item)
        obj['importNameBoo'] = true
        return obj
        })
    console.log(hold.filter(p => {
      return p.supply.id == null
    }).length)
    console.log(hold.length)
    this.inventorySheetData = hold.filter(p => {
      return p.supply.id != null
    })
  },
  getItemId(name){
    var item = this.inventoryItemsData.find(p => p.itemName.toLowerCase() === name.description.toLowerCase())
    if(name.description2){
    //  console.log(name.description2)
    var item2 = this.inventoryItemsData.find(p => p.itemName.toLowerCase() === name.description2.toString().toLowerCase())
    } else {
      var item2 = null
    }
    if(item || item2){
      if(item){
        return item
      } else if (item2){
        return item2
        }  return {id: null}
    } else {
      return {id: null}
    }
    },
    getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : "Unknown"
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
    modalHideEvent(){
      this.showModal = false
    },
    submitSupplyNew(data){
      var obj = {
               itemVendor: data.itemVendor, 
               itemName: data.importName,
               itemActive: true,
      }
      axios({ 
     method: 'post',
     url: '/api/v1/taxonomy/inventoryitem/',
     data: obj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')
    this.$nextTick(function(){
          this.fixData()
    })
   })

    },
     submitSupplyItem(data){
       var vendor = this.inventorySheetData.find(p => p.id == data.vendorId)
       if(vendor){
      axios({ 
     method: 'patch',
     url: '/api/v1/taxonomy/inventoryitem/' + data.id,
     data: { itemVendor: vendor.itemVendor },
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')
    this.$nextTick(function(){
          this.fixData()
    })
   })
       } else {
         console.log('FAIL')
       }

    },
    submitSupply(data){
      var obj = {
               itemVendor: data.itemVendor 
      }
      if(data.importNameBoo){
           obj['itemName'] = data.importName
      }
      axios({ 
     method: 'patch',
     url: '/api/v1/taxonomy/inventoryitem/' + data.supply.id,
     data: obj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')
    this.$nextTick(function(){
          this.fixData()
    })
   })

    },
    submitBulkInventory(){
    var array = this.inventorySheetData
    for(var i in array){
    var data = {item_vendor: array[i].itemVendor}
    axios({ 
     method: 'patch',
     url: '/api/v1/taxonomy/inventoryitem/' + array[i].supply.id,
     data: data,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)

   })
    }
    },
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
