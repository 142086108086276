<template>
<div id="homelanding" v-if="stationsData && apparatusData">
     <b-card border-variant="dark" bg-variant="dark" header-bg-variant="dark" v-if="tvView" v-for="parent in rosterBuildOut" :key="parent.id" no-body>
        <b-card class="py-0 px-0 mb-0" no-body v-show="parent.showStation" v-for="station in parent.stations.filter(p => p.id == stationVar)" :key="station.id"
             header-bg-variant="dark"
             header-text-variant="white"
             header-class="font-weight-bold"
             border-variant="dark"
             :header="station.stationName"      
          >
            <b-card no-body v-for="roster in station.rosterSpots" :key="roster.id">
                      <h5 class="text-left mt-2" :class="!tvView ?  'pl-5' : 'pl-2 font-weight-bold'" v-if="editNameId != roster.id">{{ roster.rosterName ? roster.rosterName : roster.apparatusName }} <b-btn  v-if="!printOn && !tvView" @click="editNameId = roster.id, roster.rosterName ? holdName = roster.rosterName : holdName = roster.apparatusName" variant="light" size="sm">Edit</b-btn></h5>
             <b-container fluid v-if="deptContactData.length > 0">
               <rosterSpot v-if="rosterReset" :tvView="tvView" :spot="roster" :onDutyFilter="onDutyFilter" :printOn="printOn" :apiKeyToken="apiKeyToken" :payaddativemods="payaddativemods" :selectedDate="selectedDate" :profiles="profilesFilteredByDate" :payrollCodesData="payrollCodesData" :mods="modifications" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" :deptContactData="deptContactData" />
             </b-container>
          </b-card>
          </b-card>
    </b-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import rosterSpot from './rosterspotTv.vue';
export default {
components: {
  rosterSpot
},
props: ['rosterData', 'payrollCodesData', 'rosterProfiles', 'payrollModDailyRosterData', 'selectedDateHold', 'tvView', 'stationVar', 'stationsData', 'apparatusData', 'apiKeyToken'],
data() {
    return {
    editNameId: '',
    holdName: '',
    vacancyPositions: [],
    rosterDataHoldArray: [],
    userSelected: '',
    selectedDate: '',
    onDutyFilter: false,
    rosterReset: true,
    printTabId: 'print-allview',
    printOn: false,
    printReportOn: false,
    emailAddressMenu: false,
    userSelectedEmail: [],
    printNotes: '',
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    nonSpotModification: [
       'payaddative',
    ],
    createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
    ],
    fillVacancy: [
      'overtime',
      'roster mod',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'exchange',
      'unpaid leave',
      'roster mod',
      'overtime',
    ],
    filteredSpots: [],
    deptContactData: [],
  }
},
created () {
 this.selectedDate = moment().format('YYYY-MM-DD')
 this.getDeptContactData()
 // console.log(this.stationsData)
 // console.log(this.apparatusData)
  if(this.tvView){
    this.onDutyFilter = true
  }
  //console.log()
},
beforeDestroy(){
  	
},
watch: {
  selectedDateHold(newData){
    if(newData != this.selectedDate){
    this.selectedDate = this.selectedDateHold
    }
  },
  onDutyFilter(data){
   //   console.log('WATCHER')
      this.rosterReset = false
      this.$emit('updateSelectedDate', this.selectedDate)
      this.$nextTick(function(){
        this.rosterReset = true
      })
  },
},
computed: {
  ...mapState([
    'siteconfig',
  ]),
  getTodaysDateForPrint(){
  return moment(this.selectedDate, 'YYYY-MM-DD').format('MM/DD/YY')
  },
  getCurrentTimeForPrint(){
    return moment().format('HH:mm')
  },
  offRosterPayrollMods(){
    var array = this.profilesPreDateFiltered.map(p => p.userId)
    var exchange = this.payrollModDailyRosterData.map(p => p.payrollModExchangeUserId)
    var holdArray = this.payrollModDailyRosterData.filter(p => {
      return p.payrollModRosterId == 'offroster'
             || (array.indexOf(p.payrollModTargetUserId) == -1 && exchange.indexOf(p.payrollModTargetUserId) == -1 && !p.payrollModRosterId)
    })
    return holdArray.filter(p => p.payrollModType != 'overtime availability')
  },
  rosterParentsData(){
           var array = this.rosterData
           var station = _.cloneDeep(this.stationsData)
           var parents = [...new Set(array.map(p => p.stationParent))]
           var parentsObj = parents.map(item => item = station.find(p => p.id == item))
           parentsObj.forEach(item => item['showStation'] = true)
           var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
           //console.log(parentsSort)
           return parentsSort
    },
  rosterSpotsFilter(){
          var array = this.rosterData
          var indexArray = this.rosterData.map(p => p.apparatusId)
          var apparatus = this.apparatusData.filter(p => {
            var index = indexArray.indexOf(p.id)
            if(index != -1){
              return p
            }
          })
          return _.orderBy(apparatus, ['apparatusName'], ['asc'])
  },
  overtimeModification(){
      var overtime = this.modifications.filter(p => {
      return p.payrollModType == 'overtime'
    })
    var available = overtime.map(item => item = this.getPayrollModObj(item, item))
    return available
  },
  currentShift(){
              var payroll = this.siteconfig.payroll
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = moment(this.selectedDate).diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
  },
  profilesPreDateFiltered(){
    var array = _.cloneDeep(this.rosterProfiles)
     array = array.filter(p => {
      var start = moment(p.rosterAssignmentStart).isSameOrBefore(this.selectedDate)
      var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate) : true
      if(start && end){
        return p
      }
    })
    var profiles = array.filter(p => {
        if(p.scheduledShift == this.currentShift){
          return p
        } else if (p.scheduledShift == 'Daily'){
          var index = p.scheduledWorkDays.indexOf(moment(this.selectedDate).format('ddd'))
          if(index != -1){
            return p
          }
        }
    })
    return profiles
  },
  profilesFilteredByDate(){ 
    var profiles = this.profilesPreDateFiltered
       // Adjusts Profiles for Swaps, and Swap Profile
    var swaps = this.checkForUserSwaps(profiles)
    if(false){
    console.log('Swaps')
    console.table(swaps)
    }
      // Adjusts PRofiles for Mods (Mods get added on RosterProfile)
    var mods = this.checkForUserMods(swaps)
    if(false){
    console.log('Mods')
    console.log(mods)
    }
    return mods
  },
  profilesFilterWithVacancies(){
    return []
   
  },
  rosterBuildOut(){
    var parents = this.rosterParentsData
    var buildingArray = parents.map(item => {
        var obj = item
        obj['stations'] = this.stationsFromParentsMap(item.id)
        return obj
    })
    if(this.filteredSpots.length > 0){
      var index = this.filteredSpots.map(p => p.rosterId)
      var array = buildingArray.map(p => {
          var obj = _.cloneDeep(p)
          obj.stations = []
          var station = p.stations
          var stationArray = []
          for(var s in station){
          var staObj = station[s]
          var spots = []
          var rosterIds = station[s].rosterSpots
          for(var i in rosterIds){
            if(index.indexOf(rosterIds[i].id) != -1){
              spots.push(rosterIds[i])
            }
          }
          if(spots.length > 0){
            staObj.rosterSpots = spots
            stationArray.push(staObj)
          }
          if(stationArray.length > 0){
            obj.stations = stationArray
            return obj
          }
          }
      })
      var undArray = array.filter(p => {
          return p != undefined
      })
      return undArray
    } else {
     // console.log(buildingArray)
     return buildingArray
    }
  },
  swapModifications(){
    return this.payrollModDailyRosterData.filter(p => {
      return p.payrollModExchangeUserId != '' && p.payrollModExchangeUserId != null && p.payrollModExchangeUserId != undefined
    })
  },
  payaddativemods(){
    if(this.payrollModDailyRosterData){
    var array = this.payrollModDailyRosterData.filter(p => {
             return p.payrollModType == 'payaddative'})
    return array
    } else {
      return []
    }
  },
  modifications(){
    var array = this.payrollModDailyRosterData.filter(p => {
              var index = this.spotModification.indexOf(p.payrollModType)
              if(index != -1){
                return p
              }
    })
    array = array.map(item => {
        var obj = this.setupPayrollModification(item)
        return obj
    })
    return array
  },
  modificationUsers(){
    var array = this.payrollModDailyRosterData.filter(p => {
              var index = this.spotModification.indexOf(p.payrollModType)
              if(index != -1){
                return p
              }
    })
    return array.map(p => p.payrollModTargetUserId)
  },
  
},
mounted(){

},
methods: { 
    getDeptContactData(){
      axios({
        method: 'get',
        url: '/api/v1/users/tv',
        headers: {
            'content-type': 'application/json',
            'authorization': this.apiKeyToken,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      })
        .then((response) => {
            this.deptContactData = response.data
        })

    },
    getPayrollCode(code){
      var obj = this.payrollCodesData.find(p => p.payrollCode == code)
      if(obj){
        return obj.id
      } else {
        return 'Code Not Found'
      }
    },
    getFixedSwap(array, mods){
      if(false){
      console.log('Get Fixed Swap')
      console.log(array)
      console.log(mods)
      }
       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
          var mod = mods.filter(p => moment(p.startTime).isSameOrAfter(array[a].startTime) && moment(p.endTime).isSameOrBefore(array[a].endTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  objTwo.startTime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  array.push(objTwo)
                  array[a] = obj
                }
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isAfter(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
    checkForUserSwaps(users){
    var profiles = []
    for(var u in users){
      var obj = users[u]
      obj['startTime'] = moment(this.selectedDate + 'T' + obj.rosterStartTime, "YYYY-MM-DDTHH:mm:ss").toISOString()
      obj['endTime'] = moment(obj.startTime).add(obj.rosterShiftLength, 'hours').toISOString()
      var array = this.swapModifications.filter(p => p.payrollModTargetUserId == users[u].userId)
      if(array.length > 0){
       var profile = [obj]
       var mods = []
       for(var s in array){
         if(array[s]){ //array[s].payrollModExchangeApproved
         var swap = _.cloneDeep(users[u])
         swap['id'] = obj.id + array[s].id
         swap['payrollId'] = obj.id
         swap['payrollSwap'] = this.getPayrollCode('EXON')
         swap['payrollModData'] = array[s]
         swap['payrollCode'] = array[s].payrollModCode
         swap['startTime'] = array[s].payrollModDatetime
         swap['endTime'] = moment(swap.startTime).add(array[s].payrollModHours, 'hours').toISOString()
         swap['targetUser'] = obj.userId
         swap['userId'] = array[s].payrollModExchangeUserId
         swap['payrollModStartDateTime'] = array[s].payrollModDatetime
         swap['rosterShiftLength'] = Number.parseFloat(array[s].payrollModHours)
         mods.push(swap)
         }
       }
       var fixedProfile = this.getFixedSwap(profile, mods)
        for(var f in fixedProfile){
          profiles.push(fixedProfile[f])
        }
        for(var m in mods){
          profiles.push(mods[m])
        }
    } else {
      profiles.push(obj)
    }
    }
    return profiles
  },
  checkForUserMods(users){
    var profiles = []
    for(var u in users){
     var obj = _.cloneDeep(users[u])
     var array = this.modifications.filter(p => p.userId == users[u].userId)
      if(array.length > 0){
       array = _.orderBy(array, function(e){ return Number.parseInt(e.rosterShiftLength)}, ['desc'])
       var profile = [obj]
       var mods = []
       for(var s in array){
         if(array[s]){ 
         var swap = _.cloneDeep(array[s])
         mods.push(swap)
         }
       }
       var fixedProfile = this.getFixedSwap(profile, mods)
        for(var f in fixedProfile){
          profiles.push(fixedProfile[f])
        }
    } else {
      profiles.push(users[u])
    }
    }
    return profiles
  },
    setupPayrollModification(array){
         var allMods = _.cloneDeep(this.payrollModDailyRosterData)
         var vacancyFill = array.payrollModRosterPosition && array.payrollModRosterId ? true : false
         var position = null
         var rosterid = null
         if(!vacancyFill){
           var profile = this.profilesPreDateFiltered.find(p => p.userId == array.payrollModTargetUserId)

           if(profile){
             position = profile.rosterPosition ? profile.rosterPosition : null
             rosterid = profile.rosterId ? profile.rosterId : null
           } else {
             var mod = allMods.find(p => p.payrollModExchangeUserId == array.payrollModTargetUserId)
             if(mod){
               profile = this.profilesPreDateFiltered.find(p => p.userId == mod.payrollModTargetUserId)
               if(profile){
                position = profile.rosterPosition ? profile.rosterPosition : null
                rosterid = profile.rosterId ? profile.rosterId : null
             }
           }
          }
         }
         var swap = {}
         swap['id'] = array.id
         swap['vacancyFill'] = vacancyFill
         swap['payrollId'] = array.payrollModTargetUserId
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : rosterid ? rosterid : ''
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : position ? position : null
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['userId'] = array.payrollModTargetUserId
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
    },
  getPayrollModObj(array, users){
         var index = this.createVacancy.indexOf(array.payrollModType)
         var index2 = this.fillVacancy.indexOf(array.payrollModType)
         var oldRosterId = users.rosterId
         var swap = _.cloneDeep(users)
         swap['id'] = users.id + array.id
         swap['payrollId'] = users.id
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : users.rosterId
         swap['vacancyTest'] = index2 == -1 ? true : false
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : users.rosterPosition
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
  },
  // BUILDS SPOTS IN THE INTIAL ROSTER
    stationsFromParentsMap(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])
      var stationMap = stationsSort.map(item => {
        var obj = item
        obj['rosterSpots'] = this.returnRosterSpots(id, item.id)
        return obj
      })
      return stationMap
    },
    stationsFromParents(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort
    },
    returnRosterSpots(pid, sid){
      var array = this.rosterData.filter(p => p.stationParent == pid && p.stationId == sid)
      array.forEach(item => item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId).apparatusName)
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])   
      return apparatusSort
    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.printReport {
  width: 1200px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
  
}
@media only screen and (max-width: 600px){
  .mobileView {
    padding: 0;
  }
}

</style>
