<template>
<div class="stationPanelUserData">
  <div >
  {{userAssignmentIndex}}
    <div id="stationPanelHeading" :class="shiftDateCalcClass" v-if="battalionData != ''">
    <h2 class="stationIndicator"   v-if="groupedDateLabelsIndex">{{ groupedDateLabelsIndex }}</h2>
    <h4>  {{ filterDateFormated }} | {{ shiftDateCalc }}

      <img v-if="userData.role == 'user'
                 || userData.role == 'supervisor'" src="/images/siteicons/help-icon-right-align-white.png" title="Help" height="24" width="24" @click="showTutVideoUser = !showTutVideoUser">
      <img v-if="userData.role == 'fireops' || userData.role == 'adminchief'"  src="/images/siteicons/help-icon-right-align-white.png" title="Help" height="24" width="24" @click="showTutVideoBatt = !showTutVideoBatt">
      <img v-if="userData.role == 'emsops' || userData.role == 'admin'"  src="/images/siteicons/help-icon-right-align-white.png" title="Help" height="24" width="24" @click="showTutVideoEMS = !showTutVideoEMS">
    </h4>
    <slot></slot>
    </div>
    <tutdata v-if="showTutVideoUser" singlePost="stationpanel" />
      <tutdata v-if="showTutVideoBatt" singlePost="battalionEmsQA" />
      <tutdata v-if="showTutVideoEMS" singlePost="battalionEmsQA" />
    <div id="stat-header-data" >
    <h4 class="stationSelector">
    <b-btn @click="previousStation" :disabled="this.currentSet == 0 "  class="previousStation">&#8592;</b-btn>
    <span :class="shiftDateCalc + 'text'">
      <router-link v-if="groupedDateLabelsIndex"
      :to="'/stations/' + stationLink(groupedDateLabelsIndex)" >{{ groupedDateLabelsIndex }}</router-link>

    </span>
    <b-btn @click="nextStation" v-bind:disabled="this.currentSet == groupedDateLabelMax - 1" class="nextStation">&#8594;</b-btn></h4>
  </div>

    </div>
</div>

</template>
<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import TutData from '../tracking/deptTutorialsdata.vue'
import moment from 'moment'
export default {
  props:["battalionData", "groupedDateLabels", "filterDate"],
components:{
  'tutdata' : TutData,
},
  data () {
    return {
      currentSet: '',
      currentSetLessOne: false,
      panelHideStatus: true,
      searchShift: '',
      searchText: '',
      filterAuthor: '',
      filterApparatus: '',
      showTutVideoUser: false,
      showTutVideoBatt: false,
      showTutVideoEMS: false,
    }
  },
  created(){

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
    filterDateFormated(){
      var formated = moment(this.filterDate, "MM/DD/YYYY").format('ddd, MMM Do YYYY')
      return formated
    },
    dateDifference(){
      var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
      var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
      var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
      var dateDiffCalNumber = 100*(dateDiffCal/3);
      var dateTenDigit = dateDiffCalNumber % 10;
      return Math.round(dateTenDigit);
    },
    shiftDateCalc(){
               var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
    },
    shiftDateCalcClass(){
      var shift = this.shiftDateCalc
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shift)
      if(obj){
        return obj.color
      } return shift
    },
    userAssignmentIndex(){
      this.currentSet = this.groupedDateLabels.indexOf(this.battalionData)
    },
    groupedDateLabelsIndex () {
      var indexPosition = this.currentSet
      var index = this.groupedDateLabels[indexPosition]
      return index
    },
    groupedDateLabelMax () {
      var length = this.groupedDateLabels.length
      return length
    },
},
    watch: {
      groupedDateLabelsIndex(newData){
        this.$nextTick(function(){
       return this.stationLink(newData)})
     }
    },
    methods: {
      stationSelectNameTrigger(data){
      /*  bus.$emit('stationSelectName', data) */
        bus.$emit('closeEvent')
      },
      stationLink(data){
        if(data != ''){
        var newData = data.replace(/\s+/g, '-').toLowerCase()
        return newData }
        },
      panelHide(){
        this.panelHideStatus = !this.panelHideStatus
      },
      previousStation () {
            var index = this.currentSet - 1
            this.currentSet = index
            var dataNumber = this.groupedDateLabels[index]
            this.$emit('updateParent', dataNumber)
      },
      nextStation () {
            var index = this.currentSet + 1
            this.currentSet = index
            var dataNumber = this.groupedDateLabels[index]
            this.$emit('updateParent', dataNumber)
      }
},
}

</script>

<style scoped>
#dataWrapper{
 text-align: center;
}


#stationPanelHeading {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}
#stationPanelHeading h3 {
    padding: 5px;
}

a {
  text-decoration: none;
  color: #e2070d;
}
.Red-shift{
  background-color: #e2070d;
  min-height: 30px;
  height: auto;
  padding: 10px 0px;

}
.Yellow-shift-background{
        background-color: #ffc107;
        min-height: 30px;
        height: auto;
        padding: 10px 0px;

        }
.Blue-shift{
  background-color: #007bff;
  min-height: 30px;
  padding: 10px 0px;
}
.Green-shift{
  background-color: #0FB906;
  min-height: 30px;
  padding: 10px 0px;
}
.A-shift{
  background: #e2070d;
}
.B-shift{
  background: #201B9F;
}
.C-shift{
  background: #0FB906;
}
.A-shifttext{
  color: #e2070d;
}
.B-shifttext{
  color: #201B9F;
}
.C-shifttext{
  color: #0FB906;
}
.showAndHidePanelButtons{
  text-align: center;
}
.stationSelector{
  text-align: center;
  width: 64%;
  margin: 0 auto 15px;
}
.stationSelector{
  font-size: 30px;
}
#stat-header-data span{
  cursor: pointer;

}
.stationSelector button {
font-weight: bold;
font-size: 20px;
}
.stationIndicator{
  display: none;
}
.previousStation {
  float: left;
}
.nextStation {
  float: right;
}
@media screen and (max-width: 460px) {
.stationSelector{
    text-align: center;
    width: 80%;
    margin: 0 auto 15px;
}
#dataWrapper {
  width: 100%;
}
}


</style>
