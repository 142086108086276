<template>
  <div>

        <div id="stat-header-data">
        <h4>
        <button @click="previousDate" v-bind:disabled="this.currentSet == groupedDateLabelMax - 1"></button>
        Calls {{ groupedDateLabelsIndex }}
        <button @click="nextDate" v-bind:disabled="this.currentSet == 0 ">></button></h4>
      </div>
        <div v-for="data in matchedDateData" :key="data.index">
        <h4>{{ data.chartLabel }} - {{ data.chartCalls }}</h4>

      </div>
  </div>
</template>


<script>


export default {
        props:["chartData"],
components:{

},
  data () {
    return {
      currentSet: 0,
      currentSetLessOne: false,
         }
  },
  computed: {
    groupedDateLabels () {
      return [...new Set(this.chartData.map(p => p.chartDate))]
    },
    groupedDateLabelMax () {
      return this.groupedDateLabels.length
    },
    groupedDateLabelsIndex () {
      var indexPosition = this.currentSet
      return this.groupedDateLabels[indexPosition]
    },
    matchedDateData () {
      return this.chartData.filter((data) => {
        return data.chartDate.match(this.groupedDateLabelsIndex)
      })
    },



  },
  methods: {

    previousDate () {
      this.currentSet = this.currentSet + 1
    },
    nextDate () {
      this.currentSet = this.currentSet - 1
    }

}
}
</script>

<style scoped>

#stat-header-data {
  width: 100%;
  background: #eee;
  text-align: center;
}
#stat-header-data h4 {
  padding-top: 10px;
  padding-bottom: 10px;
}
#stat-header-data button {
  margin-left: 10px;
  margin-right: 10px;
}

</style>
