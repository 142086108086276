<template>
<div id="homelanding">
  <b-card no-body>
    <b-tabs card>
      <b-tab title="Supply Inventory">
  <b-navbar type="dark" variant="dark">
      <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Supply Items:</b-navbar-brand>
        <b-form-input v-model="searchData" placeholder="Search Item Name" />
        <b-form-checkbox class="ml-2 mr-2 text-light" v-model="orderAmountSort" :check-value="true" :uncheck-value="false">Sort by Order Amount</b-form-checkbox>
                <b-pagination
                class="mb-0"
      v-model="currentPage"
      :total-rows="supplyItemCheck.length"
      :per-page="10"
      aria-controls="my-table"
    ></b-pagination>
      </b-nav-form>

      <b-nav-form class="ml-auto">
        <b-btn variant="light" @click="showCartModel = !showCartModel">CART <b-badge variant="dark" v-if="orderArray.length > 0">{{ orderArray.length }}</b-badge></b-btn>
        </b-nav-form>
    </b-navbar>
<b-container fluid>
    <b-table :fields="fields" :items="supplyItemCheck" v-if="resetList" :current-page="currentPage" :per-page="10">
      <template slot="orderAmount" slot-scope="data">
         <b-container fluid class="m-0 p-0 pr-4" v-if="resetField != data.item.id && (userData.role != 'user')">
          <b-row :title="'Desired Qty is ' + data.item.item_quantity">
            <b-col cols="*" >
          <b-btn variant="danger inline-block" @click="updateHoldValues(data.item.id, 'minus', 'count')">-</b-btn></b-col>
          <b-col class="p-0 m-0">
          <b-form-input  type="number" v-if="holdValuesCheck[data.item.id]"  v-model="holdValuesCheck[data.item.id].count" />
          </b-col><b-col cols="*">
                    <b-btn variant="success" @click="updateHoldValues(data.item.id, 'add', 'count')">+</b-btn></b-col>
                    </b-row>
                    </b-container>
                    <span v-else>{{ data.item.orderAmount }}</span>
        </template>
      <template slot="add" slot-scope="data">
        <b-btn :variant="data.item.orderAmount > 0 && holdValuesCheck[data.item.id].count > 0 ? 'primary' : data.item.orderAmount > 0 ? 'success' : 'secondary'" size="sm" @click="addToOrderArray(data.item)" :disabled="holdValuesCheck[data.item.id].count <= 0">Add</b-btn>
        </template>
      </b-table>
        </b-container>
      </b-tab>
    <b-tab title="Supply Expirations">
      <inventoryExpirations :inventoryItemsData="inventoryItemsData" :inventoryTemplateData="inventoryTemplateData" :fullLatestArray="fullLatestArray" />
    </b-tab>
    </b-tabs>
  </b-card>
  <b-modal size="lg" v-model="showCartModel" title="New Supply Order">
    <b-card v-for="(vend, index) in orderArrayReturned" :title="vend.Vendor":key="index">
    <b-table :items="vend.OrderItems" :fields="['id', 'name', 'type', 'order', 'cost', 'priceTotal']">
      <template slot="id" slot-scope="data">
        <span v-if="data.item.selected.vendId" >{{ data.item.selected.vendId }}</span>
        <span v-else></span>
        </template>
              <template slot="type" slot-scope="data">
                 <span v-if="data.item.selected.saleQty && data.item.selected.saleQty != 1">{{ data.item.selected.saleQty }}/</span>
        <span v-if="data.item.selected.saleType" >{{ data.item.selected.saleType }}</span>
        <span v-else></span>
        </template>
              <template slot="cost" slot-scope="data">
        <span v-if="data.item.selected.price" >{{ data.item.selected.price }}</span>
        <span v-else></span>
        </template>

      </b-table>
      <b-row><b-col align="right" class="font-weight-bold">{{ vend.Vendor }} Total:</b-col><b-col cols="2" align="left"><span v-if="vend.TotalCost">${{ vend.TotalCost }}</span></b-col></b-row>
    </b-card>
     <b-row class="mt-4"><b-col align="right" class="font-weight-bold"><h4 class="font-weight-bold">Order Total:</h4></b-col><b-col cols="3" align="left"><span><h4>${{ orderArrayReturnedCost }}</h4></span></b-col></b-row>
        <download-excel v-if="orderArrayReturnedExcel.length > 0"
    :name="getCurrentDate"
    :data="orderArrayReturnedExcel">
    <b-btn variant="primary">Download as Excel</b-btn>
</download-excel>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import JsonExcel from 'vue-json-excel';
import inventoryExpirations from './logisticSupplyExpirations.vue'
export default {
components: {
  'multiselect' : Multiselect,
  'download-excel' : JsonExcel,
  inventoryExpirations

},
props: ['inventoryItemsData', 'inventoryTemplateData'],
data() {
    return {
          searchData: '',
          filterCategory: false,
          filterTagType: null,
          resetField: '',
          currentPage: 1,
          getCurrentDate: 'New Supply Order ' + moment().toISOString() + '.xls',
          showCartModel: false,
          orderAmountSort: false,
          resetList: true,
          holdValuesCheck: {},
          filterDataBaseId: null,
          orderArray: [],
          fullLatestArray: [],
          
}
},
created () {

},
beforeDestroy(){

},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'logisticAssetData'
  ]),
  fields(){
      var array = ['itemName', 'desiredTotal', 'totalStock', 'orderAmount' ]
      if(this.userData.role != 'user'){
        array.push('add')
      }
      return array
  },
  orderArrayReturnedExcel(){
    if(this.orderArrayReturned){
    var array = _.cloneDeep(this.orderArrayReturned)
    var hold = []
    for(var i in array){
      var par = {vendor: array[i].Vendor, id: '', name: '', saleType: '', saleQty: '', order: '', saleCost: '', price: '' }
      hold.push(par)
      var items = array[i].OrderItems
      for(var x in items){
        if(items[x].selected){
        hold.push({vendor: '', id: items[x].selected.vendId, name: items[x].name, saleType: items[x].selected.saleType, saleQty: items[x].selected.saleQty, order: items[x].order, saleCost: items[x].selected.price, price: items[x].priceTotal })
        } else {
        hold.push({vendor: '', id: '', name: items[x].name, saleType: '', saleQty: '', order: items[x].order, saleCost: '', price: '' })
        }
      }
      hold.push({vendor: '', id: '', name: '', saleType: '', saleQty: '', order: '', saleCost: '', price: '' })
      hold.push(hold.push({vendor: '', id: array[i].Vendor, name: 'Total', saleType: '', saleQty: '', order: '', saleCost: '', price: array[i].TotalCost}))
      hold.push({vendor: '', id: '', name: '', saleType: '', saleQty: '', order: '', saleCost: '', price: '' })
    }
    if(hold.length > 0){
    hold.push(hold.push({vendor: 'Order Total', id: '', name: '', saleType: '', saleQty: '', order: '', saleCost: '', price: this.orderArrayReturnedCost}))
    return hold
    } return []
    } return []
  },
  orderArrayReturned(){
    var order = this.orderArray
    var vendors = order.filter(p => {
      return p.selected
    })
    var non = order.filter(p => {
      return !p.selected
    })
    var vendorsNames = [... new Set(vendors.map(p => p.selected.name))]
    var newArray = vendorsNames.map(item => {
              var obj = {}
              obj['Vendor'] = item
              obj['OrderItems'] = _.orderBy(vendors.filter(p => {
                return p.selected.name == item
              }), ['name'], ['asc'])
              obj['TotalCost'] = this.getTotalCost(obj.OrderItems)
              return obj
    })
    newArray = _.orderBy(newArray, ['Vendor'], ['asc'])
    if(non.length > 0){
      newArray.push({Vendor: 'No Vendor', OrderItems: _.orderBy(non, ['name'], ['asc']), TotalCost: 0.00})
    }
    return newArray
  },
  orderArrayReturnedCost(){
    if(this.orderArrayReturned){
      var orders = _.cloneDeep(this.orderArrayReturned)
      var cost = 0.00
      var map = [... new Set(orders.map(p => p.TotalCost))]
      for(var m in map){
        cost = cost + parseFloat(map[m])
      }
      return cost
    } return 0.00
  },
  supplyItemCheck(){
    var items = _.cloneDeep(this.inventoryItemsData)
    var nongeneric = this.inventorysNonGeneric
    var array = items.map(p => {
      var obj = p
      obj['formMentions'] = this.itemsFromTemplates.filter(x => x.id == p.id)
      obj['desiredTotal'] = this.getDesiredTotal(obj.formMentions)
      obj['totalStock'] = this.getTotalStock(nongeneric, p.id)
      obj['orderAmount'] = obj.desiredTotal - obj.totalStock >= 0 ? obj.desiredTotal - obj.totalStock : 0
      this.holdValuesCheck[obj.id] = {count: obj.orderAmount}
      return obj
    }).filter(p => {
      return p.itemName.toLowerCase().match(this.searchData.toLowerCase())
    })
    var nameSort = _.orderBy(array, ['itemName'], ['asc'])
    if(this.orderAmountSort){
      return _.orderBy(nameSort, ['orderAmount'], ['desc'])
    }
    return nameSort
  },
  inventoryLatest(){
    var array = this.inventoryTemplateData.filter(p => {
      return p.latest
    })
    if(array.length > 0){
    this.getAllOfTheLatest(array)
    }
    return array
  },
  inventorysNonGeneric(){
    var prevLatest = this.inventoryLatest
    var array = _.cloneDeep(this.fullLatestArray)
    var hold = []
    var items = array.map(p => p.inventorySubData)
    return hold.concat.apply(hold, items)
  },
  itemsFromTemplates(){
    var templates = this.inventoryTemplateData.filter(p => p.latest).map(p => p.inventoryItems)
    var hold = []
    var arrays = hold.concat.apply(hold, templates)
    return arrays.filter(p => p.item_type == 'supply')
  }
},
mounted(){

},
watch:{
  inventorysNonGeneric(newData){
    this.resetList = false
    this.$nextTick(function(){
      this.resetList = true
    })
  }
},
methods: {
     getTotalCost(array){
       var data = array
       var map = data.map(p => p.priceTotal)
       var hold = 0.00
       for(var i in map){
         hold = hold + parseFloat(map[i])
       }
       return hold
    },
    updateHoldValues(id, type){
    this.resetField = id
    var item = this.holdValuesCheck[id]
   // console.log(item)
    if(type == 'minus' && item.count > 0){
      this.holdValuesCheck[id].count = parseInt(this.holdValuesCheck[id].count) - 1
    } else if (type == 'add'){
       this.holdValuesCheck[id].count = parseInt(this.holdValuesCheck[id].count) + 1
    }
    this.$nextTick(function(){
      this.resetField = ''
    })
  },
  addToOrderArray(data){
     this.resetField = data.id
      var item = this.holdValuesCheck[data.id]
      var selectedVendor = ''
      if(data.itemVendor){
        var options = _.orderBy(data.itemVendor, ['price'], ['asc'])
        if(options[0] && options[0].name){
          selectedVendor = options[0]
        } 
      }
      var count = item.count
      var need = item.count
      var priceTotal = 0.00
      if(selectedVendor.saleQty && selectedVendor.saleQty != 1 && item.count){
        var float = item.count / selectedVendor.saleQty
        count = Math.floor(float) + 1
      }
      if(selectedVendor.price){
        priceTotal = count * selectedVendor.price
        priceTotal = Number(Math.round(priceTotal +'e'+ 2) +'e-'+ 2).toFixed(2);
      }
      this.orderArray.push({id: data.id, order: count, need: need, priceTotal: priceTotal, name: data.itemName, vendors: data.itemVendor, selected: selectedVendor})
      this.$nextTick(function(){
        this.holdValuesCheck[data.id].count = 0
         this.resetField = ''
      })
  },
  getAllOfTheLatest(array){
    var latest = array.map(p => p.latest)
    var ids = latest.map(p => p.id)
     axios({  
          method: 'get',
          url: '/api/v1/content/inventorysub/group/' + ids,
          headers: {
              'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
         // console.log(response.data)
          this.fullLatestArray = response.data
        })
  },
  getTotalStock(holdArray, id){
    var array = holdArray.filter(p => {
      return p.id == id
    })
    var total = 0
    for(var i in array){
        total = Number.parseInt(total) + Number.parseInt(array[i].item_count)
    }
    return total
  },
  getDesiredTotal(array){
    if(array && array.length > 0){
      var total = 0
      for(var i in array){
        total = Number.parseInt(total) + Number.parseInt(array[i].item_quantity)
      }
      return total
    } return 0
  },

  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
