<template>
<div id="homelanding">
  <b-container>
    <b-container no-body class="mb-3">
      <b-row>
        <b-col lg="2"><h5>Sheet Name</h5>
      <b-form-input type="text" v-model="dataObj.inventoryName" /></b-col>
      <b-col lg="2"><h5>Tag Type</h5>
      <b-form-select type="text" v-model="dataObj.inventoryType" :options="taxonomyTags" /></b-col>
      <b-col :lg="dataObj.inventoryType == 'apparatusData' ? '4' : '6'"><h5>Tag (leave blank for user select)</h5>
          <multiselect
              v-model="dataObj.inventoryDbid" 
              :disabled="dataObj.inventoryType == ''"
              :options="getOptionsFromSelection(dataObj.inventoryType)" 
              :multiple="false" 
              :close-on-select="true" 
              :clear-on-select="true" 
              :hide-selected="true" 
              :preserve-search="true" 
               :placeholder="dataObj.inventoryType == 'stationsData' ? 'Search Station Name' : dataObj.inventoryType == 'deptContactData' ? 'Search User Last Name' : dataObj.inventoryType == 'apparatusData' ? 'Search Apparatus Name' : dataObj.inventoryType == 'logisticAssetData' ? 'Search Asset Id' : 'Please Select a Type'"
              :label="dataObj.inventoryType == 'stationsData' ? 'stationName' : dataObj.inventoryType == 'deptContactData' ? 'lastName' : dataObj.inventoryType == 'apparatusData' ? 'apparatusName' : dataObj.inventoryType == 'logisticAssetData' ? 'assetId' : ''" 
              :track-by="dataObj.inventoryType == 'stationsData' ? 'stationName' : dataObj.inventoryType == 'deptContactData' ? 'lastName' : dataObj.inventoryType == 'apparatusData' ? 'apparatusName' : dataObj.inventoryType == 'logisticAssetData' ? 'assetId' : ''">

   <template slot="option" slot-scope="props">
      <div class="option__title" v-if="dataObj.inventoryType == 'stationsData'">{{ props.option.stationName }}</div>
 <div class="option__title" v-if="dataObj.inventoryType == 'apparatusData'">{{ props.option.apparatusName }}</div>
                    <div class="option__title" v-if="dataObj.inventoryType == 'logisticAssetData'">{{ props.option.assetDescription }} #ID:{{ props.option.assetId }} #SN:{{ props.option.assetSerialNumber }}</div>
                    <div class="option__title" v-if="dataObj.inventoryType == 'deptContactData'">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                   <div class="option__body" v-if="dataObj.inventoryType == 'deptContactData'">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
   </template>

</multiselect>
</b-col></b-row>
<b-row>
      <b-col v-if="dataObj.inventoryType == 'apparatusData'" lg="2">
        <h5>Just Hours or Miles?</h5>
          <b-btn variant="success" 
            v-if="dataObj.inventoryHead && dataObj.inventoryHead.getpm" @click="dataObj.inventoryHead.getpm = false, dataObj.inventoryHead.getBothpm = false">Turned On</b-btn>
          <b-btn variant="outline-danger" 
            v-if="!dataObj.inventoryHead || !dataObj.inventoryHead.getpm" 
            @click="dataObj.inventoryHead ? (dataObj.inventoryHead['getpm'] = true, dataObj.inventoryHead['getBothpm'] = false) : 
                    dataObj.inventoryHead = {getpm: true, reserve: false, getBothpm: false }">Turned OFF</b-btn>
        </b-col>
          <b-col v-if="dataObj.inventoryType == 'apparatusData'" lg="2">
        <h5>Both Hours or Miles?</h5>
        <b-btn variant="success" v-if="dataObj.inventoryHead && dataObj.inventoryHead.getBothpm" @click="dataObj.inventoryHead.getBothpm = false, dataObj.inventoryHead.getpm = false">Turned On</b-btn>
        <b-btn variant="outline-danger" v-if="!dataObj.inventoryHead || !dataObj.inventoryHead.getBothpm"
         @click="dataObj.inventoryHead ? (dataObj.inventoryHead['getBothpm'] = true, dataObj.inventoryHead['getpm'] = false) : 
                 dataObj.inventoryHead = {getpm: false, reserve: false, getBothpm: true}">Turned OFF</b-btn>
        </b-col>
      <b-col cols="2"><h5>Frequency</h5>
      <b-form-select v-model="dataObj.inventoryFrequency" :options="frequencyOptions" /></b-col>
        <b-col cols="2"  v-if="dataObj.inventoryHead"><h5>Reset on New</h5>
              <b-btn variant="success" v-if="dataObj.inventoryHead.resetPar" 
                                     @click="updateHead('resetPar', false, 'resetParOnNew')">Turned On</b-btn>
              <b-btn variant="outline-danger" v-if="!dataObj.inventoryHead.resetPar"
                                      @click="updateHead('resetPar', true)">Turned OFF</b-btn></b-col>
        <b-col cols="2"  v-if="dataObj.inventoryHead && updateFieldValue != 'resetParOnNew'"><h5>Reset on New</h5>
              <b-btn id="resetParONNew" variant="success" v-if="dataObj.inventoryHead.resetParOnNew && dataObj.inventoryHead.resetPar" 
                                      @click="updateHead('resetParOnNew', false)">Turned On</b-btn>
              <b-btn  id="resetParOFFNew" variant="outline-danger" v-if="!dataObj.inventoryHead.resetParOnNew && dataObj.inventoryHead.resetPar"
                                       @click="updateHead('resetParOnNew', true)">Turned OFF</b-btn></b-col>
</b-row><b-row>
      <b-col v-if="dataObj.inventoryType == 'apparatusData'">
                <h5>Allow Inventory to be used as reserve (items can be moved into this check from another inventory form)</h5>
               <b-btn variant="success" v-if="dataObj.inventoryHead && dataObj.inventoryHead.reserve" @click="dataObj.inventoryHead.reserve = false">Turned On</b-btn>
        <b-btn variant="outline-danger" v-if="dataObj.inventoryHead == null || !dataObj.inventoryHead.reserve" @click="dataObj.inventoryHead ? dataObj.inventoryHead['reserve'] = true : dataObj.inventoryHead = {getpm: true, reserve: true}">Turned OFF</b-btn>
        </b-col>
      </b-row>

      </b-container>
      <b-row class="mb-2"><b-col cols="9">
      <b-btn variant="primary" block @click="addNewParentFlag = !addNewParentFlag">Add New Parent</b-btn></b-col>
      <b-col cols="3"><b-btn variant="info" block @click="importParentModel = !importParentModel">Import Parent</b-btn></b-col></b-row>
      <b-container v-if="addNewParentFlag"><b-row><b-col><b-form-input type="text" v-model="newParentNameHold" /></b-col><b-col><b-btn variant="primary" :disabled="newParentNameHold == '' ? true : false" @click="AddNewParent(newParentNameHold)">Save</b-btn></b-col></b-row></b-container>
     <draggable v-model="parentLocationObjs" v-if="refreshForm">
    <b-card  v-if="refreshForm" v-for="par in parentLocationObjs" :key="par.item_loc_01" no-body class="pb-2" border-variant="info" header-tag="header" header-bg-variant="info" header-text-variant="light">
      <b-row slot="header"><b-col>
     <span class="font-weight-bold">{{ par.item_loc_01 }} </span><em>(Drag to re-order)</em> </b-col>
     <b-col cols="*" v-if="showSecondarys == par.item_loc_01 && !addNewSub && showEditName != par.item_loc_01">
       <b-btn variant="light" size="sm" @click="addNew(par.item_loc_01, 'NULL', 'NULL')">Add Item</b-btn>
       <b-btn  size="sm" variant="primary" @click="addNewSub = !addNewSub">Add Sub-Group</b-btn>
      <b-btn variant="warning" size="sm" @click="showEditName = par.item_loc_01, newEditNameHold = par.item_loc_01">Edit Name</b-btn></b-col>
       <b-col cols="*" v-if="showSecondarys == par.item_loc_01 && addNewSub">
       <b-form-input size="sm" v-model="newSubNameHold" placeholder="New Subgroup" /></b-col>
       <b-col cols="*" v-if="showSecondarys == par.item_loc_01 && addNewSub">
       <b-btn variant="light" size="sm" @click="par.item_loc_02.push({item_loc_02: newSubNameHold, item_loc_02_items: [], item_loc_03: []}), newSubNameHold = '', addNewSub = !addNewSub">Save</b-btn>
       <b-btn variant="danger" size="sm" @click="addNewSub = !addNewSub, newSubNameHold = ''">X</b-btn>
       </b-col>
              <b-col cols="*" v-if="showEditName == par.item_loc_01">
         <b-form-input size="sm" v-model="newEditNameHold" placeholder="New Name" /></b-col>
        <b-col cols="*" v-if="showEditName == par.item_loc_01" size="sm"><b-btn variant="light" size="sm" @click="updateSaveName(newEditNameHold, par.item_loc_01, true, undefined, undefined)">Save</b-btn>
          <b-btn variant="danger" size="sm" @click="showEditName = '', newEditNameHold = ''">X</b-btn>
        </b-col>
     <b-col align="right" cols="2"><b-btn v-if="showSecondarys != par.item_loc_01" variant="outline-light" size="sm" @click="showSecondarys = par.item_loc_01">Open</b-btn>

     <b-btn v-if="showSecondarys == par.item_loc_01"  variant="danger" size="sm" @click="showSecondarys = ''">Close</b-btn></b-col>
     </b-row>
     <b-container v-if="showSecondarys == par.item_loc_01" class="pr-0">
        <draggable v-model="par.item_loc_01_items">
        <b-row v-for="item in par.item_loc_01_items" :key="item.holdid" class="mt-1">
          <b-col cols="7">{{ item.item_name }}</b-col>
          <b-col cols="2">{{ item.item_type }}</b-col>
          <b-col cols="2">{{ item.item_quantity }}</b-col>
          <b-col cols="1"><b-btn variant="warning" size="sm" @click="setUpEditData(item)">Edit</b-btn></b-col>
          </b-row>
          </draggable>
       <draggable v-model="par.item_loc_02">
      <b-card v-for="sec in par.item_loc_02" :key="sec.item_loc_02" no-body class="mt-2 mr-0 pb-2"  border-variant="primary" header-tag="header" header-bg-variant="primary" header-text-variant="light">
        <b-row slot="header"><b-col>
     <span class="font-weight-bold">{{ sec.item_loc_02 }} </span><em>(Drag to re-order)</em> </b-col>
     <b-col cols="*" v-if="showSecondarysCat == sec.item_loc_02 && !addNewSub && showEditName != sec.item_loc_02">
       <b-btn size="sm" variant="light" @click="addNew(par.item_loc_01, sec.item_loc_02, 'NULL')">Add Item</b-btn>
       <b-btn variant="success" size="sm" @click="addNewSub = !addNewSub">Add Sub-Group</b-btn>
       <b-btn variant="warning" size="sm" @click="showEditName = sec.item_loc_02, newEditNameHold = sec.item_loc_02">Edit Name</b-btn>
       </b-col>
        <b-col cols="*" v-if="showSecondarysCat == sec.item_loc_02 && addNewSub">
       <b-form-input size="sm" v-model="newSubNameHold" placeholder="New Subgroup Name" /></b-col>
       <b-col cols="*" v-if="showEditName == sec.item_loc_02">
         <b-form-input size="sm" v-model="newEditNameHold" placeholder="New Name" /></b-col>
        <b-col cols="*" v-if="showEditName == sec.item_loc_02" size="sm"><b-btn variant="light" size="sm" @click="updateSaveName(newEditNameHold, sec.item_loc_02, undefined, true, undefined)">Save</b-btn>
          <b-btn variant="danger" size="sm" @click="showEditName = '', newEditNameHold = ''">X</b-btn>
        </b-col>
     <b-col cols="*" v-if="showSecondarysCat == sec.item_loc_02 && addNewSub">
       <b-btn variant="light" size="sm" @click="sec.item_loc_03.push({item_loc_03: newSubNameHold, item_loc_03_items: []}), newSubNameHold = '', addNewSub = !addNewSub">Save</b-btn>
       <b-btn variant="danger" size="sm" @click="addNewSub = !addNewSub, newSubNameHold = ''">X</b-btn>
       </b-col>
     <b-col alight="right" cols="2"><b-btn v-if="showSecondarysCat != sec.item_loc_02" variant="outline-light" size="sm" @click="showSecondarysCat = sec.item_loc_02">Open</b-btn><b-btn v-if="showSecondarysCat == sec.item_loc_02"  variant="danger" size="sm" @click="showSecondarysCat = ''">Close</b-btn></b-col>
     </b-row>
          <b-container v-if="showSecondarysCat == sec.item_loc_02" class="pr-0">
       <b-container>
         
       </b-container>
        <draggable v-model="sec.item_loc_02_items">
        <b-row v-for="secitem in sec.item_loc_02_items" :key="secitem.holdid" class="mt-1">
          <b-col cols="7">{{ secitem.item_name }}</b-col>
          <b-col cols="2">{{ secitem.item_type }}</b-col>
          <b-col cols="2">{{ secitem.item_quantity }}</b-col>
          <b-col cols="1"><b-btn variant="warning" size="sm" @click="setUpEditData(secitem)">Edit</b-btn></b-col>
          </b-row>
          </draggable>
       <draggable v-model="sec.item_loc_03">
      <b-card v-for="third in sec.item_loc_03" :key="third.item_loc_03" class="mt-2 mr-0"  border-variant="success" header-tag="header" header-bg-variant="success" header-text-variant="light">
        <b-row slot="header"><b-col>
     <span class="font-weight-bold">{{ third.item_loc_03 }} </span><em>(click to show and drag to re-order)</em> </b-col>
     <b-col cols="*" v-if="showThirdCat == third.item_loc_03 && showEditName != third.item_loc_03">
       <b-btn variant="light" @click="addNew(par.item_loc_01, sec.item_loc_02, third.item_loc_03)" size="sm">Add Item</b-btn>
      <b-btn variant="warning" size="sm" @click="showEditName = third.item_loc_03, newEditNameHold = third.item_loc_03">Edit Name</b-btn>
     </b-col>
            <b-col cols="*" v-if="showEditName == third.item_loc_03">
         <b-form-input size="sm" v-model="newEditNameHold" placeholder="New Name" /></b-col>
        <b-col cols="*" v-if="showEditName == third.item_loc_03" size="sm"><b-btn variant="light" size="sm" @click="updateSaveName(newEditNameHold,third.item_loc_03, undefined, undefined, true)">Save</b-btn>
          <b-btn variant="danger" size="sm" @click="showEditName = '', newEditNameHold = ''">X</b-btn>
        </b-col>
     <b-col alight="right" cols="2">
       <b-btn v-if="showThirdCat != third.item_loc_03" variant="outline-light" size="sm" @click="showThirdCat = third.item_loc_03">Open</b-btn>
       <b-btn v-if="showThirdCat == third.item_loc_03"  variant="danger" size="sm" @click="showThirdCat = ''">Close</b-btn></b-col>
     </b-row>
      <b-container v-if="showThirdCat == third.item_loc_03" class="mr-0">
        <draggable v-model="third.item_loc_03_items">
        <b-row v-for="thiritem in third.item_loc_03_items" :key="thiritem.holdid" class="mt-1">
          <b-col cols="7">{{ thiritem.item_name }}</b-col>
          <b-col cols="2">{{ thiritem.item_type }}</b-col>
          <b-col cols="2">{{ thiritem.item_quantity }}</b-col>
          <b-col cols="1"><b-btn variant="warning" size="sm" @click="setUpEditData(thiritem)">Edit</b-btn></b-col>
          </b-row>
          </draggable>
          </b-container>
        </b-card>
            </draggable>
        </b-container>
        </b-card>
            </draggable>
        </b-container>
      </b-card>
  
     </draggable>
    </b-container>
  <span v-show="false">{{ returnInventoryItems }}</span>
<b-modal v-if="showModal" v-model="showModal" :title="editItemObj.item_name" size="lg">
  <b-row><b-col cols="6" class="mb-3">
    <h6>Item Name/Descriptions</h6>
    <b-form-input type="text" v-model="editItemObj.item_name" />
  </b-col>
  <b-col cols="6" class="mb-3">
        <h6>Item Type</h6>
        <b-form-select v-model="editItemObj.item_type" :options="itemTypeOpts" @input="editItemObj.item_type == 'asset' ? (editItemObj.item_quantity = 1, delete editItemObj.id, delete editItemObj.expDate, delete editItemObj['30daycount'], editItemObj.modified == undefined ? (editItemObj.modified = false, editItemObj.modItems = []) : !editItemObj.modItems ? editItemObj.modItems = [] : '') : editItemObj.item_type == 'supply' ? (editItemObj.expDate = true, editItemObj['30daycount'] = false) : (delete editItemObj.expDate, delete editItemObj['30daycount'])" />
    </b-col>
      <b-col cols="3" class="mb-3">
        <h6>Desired/Required Qty</h6>
        <b-form-input type="number" v-model="editItemObj.item_quantity" :disabled="editItemObj.item_type == 'asset'" />
    </b-col>
          <b-col cols="3" class="mb-3">
        <h6>Location 01</h6>
        <b-form-select v-model="editItemObj.item_loc_01" :options="getParentLocations" @input="editItemObj.item_loc_02 = 'NULL', editItemObj.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
        <h6>Location 02</h6>
      <b-form-select v-if="getSecondaryLocations(editItemObj.item_loc_01)" v-model="editItemObj.item_loc_02" :options="getSecondaryLocations(editItemObj.item_loc_01)" @input="editItemObj.item_loc_03 = 'NULL'" />
    </b-col>
          <b-col cols="3" class="mb-3">
        <h6>Location 03</h6>
       <b-form-select v-if="getThirdLocations(editItemObj.item_loc_01, editItemObj.item_loc_02)" v-model="editItemObj.item_loc_03" :options="getThirdLocations(editItemObj.item_loc_01, editItemObj.item_loc_02)" />
    </b-col>
    <b-col v-if="editItemObj.item_type == 'supply'" cols="12" class="mb-3">
      <b-form-input v-model="filterSupplySearch" placeholder="Filter Supply List" />
      <b-form-select v-model="editItemObj.id" :options="filterListOfSupplies" :select-size='4' @change="checkEventData($event)" />
      </b-col>
      <h6 class="text-danger mx-5" v-if="editItemObj.item_type == 'supply'">If you wish to track expirations, expiration dates should be used for non-generic checks, otherwise for generic checks, 30 day expirations should be used.</h6>
      <b-col v-if="editItemObj.item_type == 'supply'" cols="6" class="mb-3">
        <b-form-checkbox v-model="editItemObj.expDate" @change="checkValueAndUpdate($event, '30daycount')">Require Expiration Date?</b-form-checkbox>
        </b-col>
      <b-col v-if="editItemObj.item_type == 'supply'" cols="6" class="mb-3">
         <b-form-checkbox :disabled="checkDisabledGenericVehicle" v-model="editItemObj['30daycount']" @change="checkValueAndUpdate($event, 'expDate')">Require 30day Expiration Count?</b-form-checkbox>
         </b-col>
      <b-col v-if="editItemObj.item_type == 'asset'" cols="12" class="mb-3">
          <b-form-checkbox v-model="editItemObj.modified" >Require additonal check items other then a simple pass/fail? </b-form-checkbox>
        </b-col>
  </b-row>
  
  <template slot="modal-footer">
    <b-btn v-if="editItemHoldId != 'new'" variant="danger" @click="saveUpdatedItem(beforeEditItem, null)" >Delete</b-btn>
    <b-btn v-if="editItemHoldId == 'new'" variant="success" @click="duplicateCheckItem(editItemObj)" >Save New</b-btn>
    <b-btn v-if="editItemHoldId != 'new'" variant="success" @click="duplicateCheckItem(editItemObj)" >Duplicate</b-btn>
    <b-btn v-if="editItemHoldId != 'new'" variant="primary" :disabled="checkForDisabled" @click="saveUpdatedItem(beforeEditItem, editItemObj)" >Save</b-btn>
    
    </template>
  </b-modal>
  <b-modal v-model="importParentModel" :hide-footer="true" title="Import Modal">
    <b-form-select :options="inventoryTemplateDataSheets" v-model="importSheetId" />
    <b-form-select :disabled="importSheetId == ''" :options="inventoryTemplateDataParents" v-model="importSheetParent" />
    <b-btn variant="success" @click="importParentGroupItems" :disabled="importParentGroup.length == 0" >Import {{ importParentGroup.length }} Items</b-btn>
    </b-modal>
<b-btn variant="success" @click="updateInventoryTemplate">Submit</b-btn>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import { bus } from '../../../main';
import draggable from 'vuedraggable';
export default {
components: {
  'multiselect' : Multiselect,
  draggable
},
props: ['newInventoryForm', 'inventoryItemsData', 'inventoryTemplateData'],
data() {
    return {
      searchData: '',
      fields: [{key: 'item_name', field: 'item_name'}, 'item_count', 'tracking', 'note'],
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      parentLocationObjs: [],
      editItemHoldId: '',
      editItemObj: '',
      newSubNameHold: '',
      addNewSub: false,
      listOfObjects: [],
      addNewParentFlag: false,
      newParentNameHold: '',
      showModal: false,
      importParentModel: false,
      filterSupplySearch: '',
      beforeEditItem: '',
      newEditNameHold: '',
      showEditName: '',
      importSheetId: '',
      updateFieldValue: '',
      importSheetParent: '',
      taxonomyTags: [
        {text: 'User', value: 'deptContactData'},
        {text: 'Station', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'Asset', value: 'logisticAssetData'}
      ],
      frequencyOptions: [
        {text: 'Daily', value: 'daily'},
        {text: 'Weekly', value: 'week'},
        {text: 'Bi-weekly', value: 'biweekly'},
        {text: 'Monthly', value: 'monthly'},
        {text: 'Quartly', value: 'quartly'},
        {text: 'Annually', value: 'annually'},
        {text: 'Disabled', value: 'disabled'},
        {text: 'None', value: null}
      ],
      itemTypeOpts: [
        {text: 'Supply Items', value: 'supply'},
        {text: 'Check Item', value: 'check'},
        {text: 'Asset Item', value: 'asset'}
      ],
      showSecondarys: '',
      dataResetSecond: true,
      dataResetThird: true,
      refreshForm: true,
      showSecondarysCat: '',
      showThirdCat: '',
      invetoryLastCheckData: [],
      holdValues: {},
      firstLoad: null,
      resetField: '',
      selectedInventoryForm: '',
      databaseIDTag: '',
      inventoryItemData: '',
      dataObj: {
        inventoryDbid: '',
        inventoryFrequency: 'daily',
        inventoryHead: null,
        inventoryItems: [],
        inventoryName: '',
        inventoryType: '',
      },
      sheetTaxonomyTag: null,
      checkHideList: {primary: [], secondary: [], third: []},
}
},
created () {
  this.inventoryItemData = _.cloneDeep(this.newInventoryForm.inventoryItems)
  this.setParents(this.inventoryItemData)
//  console.log(this.newInventoryForm
  if(this.newInventoryForm.inventoryName != 'NEW'){
  this.dataObj =  _.cloneDeep(this.newInventoryForm)
  
  if(this.dataObj.inventoryDbid){
    var obj = this[this.dataObj.inventoryType].find(p => p.id == this.dataObj.inventoryDbid)
   // console.log(obj)
    if(obj){
      this.dataObj.inventoryDbid = obj
    }
  }
  this.listOfObjects = _.cloneDeep(this.newInventoryForm.inventoryItems)
  this.newInventoryForm.latest ? this.invetoryLastCheckData = this.newInventoryForm.latest.inventorySubData : ''
  }
},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'logisticAssetData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  inventoryTemplateDataSheets(){
    var array = _.cloneDeep(this.inventoryTemplateData)
    array = array.map(item => {
        var obj = {}
        obj['value'] = item.id
        obj['text'] = item.inventoryName
        return obj
    })
    array = _.orderBy(array, ['text'], ['asc'])
    array.push({text: 'Please Select A Sheet', value: '', disabled: true})
    return array
  },
  inventoryTemplateDataParents(){
    var array = [{text: 'Select Parent Group', value: '', disabled: true }]
    if(this.importSheetId != ''){
    var parent = _.cloneDeep(this.inventoryTemplateData)
    parent = parent.find(p => p.id == this.importSheetId)
    if(parent && parent.inventoryItems){
      var parents = [... new Set(parent.inventoryItems.map(p => p.item_loc_01))]
      parents = _.sortBy(parents, ['asc'])
      for(var i in parents){
        array.push(parents[i])
      }
      return array
    } return array
    } return array
  },
  importParentGroup(){
    if(this.importSheetId != '' && this.importSheetParent != ''){
      var parent = _.cloneDeep(this.inventoryTemplateData)
      parent = parent.find(p => p.id == this.importSheetId)
      return parent.inventoryItems.filter(p => p.item_loc_01 == this.importSheetParent)
    } return []
  },
  filterListOfSupplies(){
    var array = this.inventoryItemsData.filter(p => {
      return p.itemName.toLowerCase().includes(this.filterSupplySearch.toLowerCase())
    })
    var sort = _.orderBy(array, ['itemName'], ['asc'])
    return sort.map(item => {
      var obj = {text: item.itemName, value: item.id, name: item.itemName, qty: item.itemDefault}
      return obj
    })
  },
  checkDisabledGenericVehicle(){
    if(this.dataObj.inventoryDbid && this.dataObj.inventoryDbid != 'generic'){
      return true
    } return false
  },
  returnInventoryItems(){
    var array = this.parentLocationObjs
 //   var list = _.cloneDeep(this.listOfObjects)
    var hold = []
    var sortOrder = 0
    for(var a in array){
      var pitems = array[a].item_loc_01_items
      for(var p in pitems){
        var obj = pitems[p]
        obj['sortOrder'] = sortOrder
        if(obj.item_type == 'asset'){
          obj['asset_id'] = ''
        }
        sortOrder = sortOrder + 1
        hold.push(obj)
      }
      var sec = array[a].item_loc_02
      for(var s in sec){
        var seci = sec[s].item_loc_02_items
        for(var i in seci){
        var sobj = seci[i]
        sobj['sortOrder'] = sortOrder
        if(sobj.item_type == 'asset'){
          sobj['asset_id'] = ''
        }
        sortOrder = sortOrder + 1
        hold.push(sobj)
        }
        var thr = sec[s].item_loc_03
        for(var t in thr){
        var thri = thr[t].item_loc_03_items
        for(var x in thri){
        var objt = thri[x]
        if(objt.item_type == 'asset'){
          objt['asset_id'] = ''
        }
        objt['sortOrder'] = sortOrder
        sortOrder = sortOrder + 1
        hold.push(objt)
        }
        }
      }
    }
   // console.log(this.parentLocationObjs)
    //console.log('UPDATED')
    //console.log(hold)
    return hold
  },
  checkForDisabled(){
    var prev = this.beforeEditItem
    var edit = this.editItemObj
    return _.isEqual(prev, edit);
  },
  getInventoryItemsList(){
    var obj = _.cloneDeep(this.newInventoryForm)
    var array = this.getArrayItemsFiltered(obj.inventoryItems)
    return array
  },
  parentLoc(){
    var array = this.parentLocationObjs
    return array
  },
  getParentLocations(){
    return [...new Set(_.cloneDeep( this.parentLocationObjs).map(p => p.item_loc_01))]
  },
},
mounted(){

},
watch:{

},
methods: {
  updateHead(obj, value){
    console.log(obj)
    console.log(value)
    this.updateFieldValue = obj
    this.dataObj.inventoryHead[obj] = value
    this.$nextTick(function(){
      this.updateFieldValue = ''
      console.log(this.dataObj.inventoryHead)
    })
  },
  updateSaveName(name, old, par, sec, third){
    var data = _.cloneDeep(this.inventoryItemData)
    if(par){
        var array = data.map(item => {
        var obj = {}
        obj = item
        if(item.item_loc_01 == old){
          obj.item_loc_01 = name
        }
        return obj
      })
      this.showSecondarys = name
      this.showEditName = ''
      this.newEditNameHold = ''
      this.setParents(array)
      this.inventoryItemData = array
    }
    if (sec){
      var array = data.map(item => {
        var obj = {}
        obj = item
        if(item.item_loc_02 == old){
          obj.item_loc_02 = name
        }
        return obj
      })
      this.showSecondarysCat = name
      this.showEditName = ''
      this.newEditNameHold = ''
      this.setParents(array)
      this.inventoryItemData = array
    }
    if (third){
        var array = data.map(item => {
        var obj = {}
        obj = item
        if(item.item_loc_03 == old){
          obj.item_loc_03 = name
        }
        return obj
      })
      this.showThirdCat = name
      this.showEditName = ''
      this.newEditNameHold = ''
      this.setParents(array)
      this.inventoryItemData = array
    } 

  },
  importParentGroupItems(){
    var items = this.importParentGroup
    for(var i in items){
      this.inventoryItemData.push(items[i])
    }
    this.importSheetId = ''
    this.importSheetParent = ''
    this.importParentModel = false 
    this.setParents(this.inventoryItemData)
  },
  checkValueAndUpdate(value, field){
  //  console.log(value)
  //  console.log(field)
    value ? this.editItemObj[field] = false : ''
  },
  getOptionsFromSelection(data){
    if(data){
     // console.log(data)
    return this[data]
    } else {
      return []
    }
  },
  checkEventData(data){
    var item = this.inventoryItemsData.find(p => p.id == data)
    if(item){
      item.itemName ? this.editItemObj.item_name = item.itemName : ''
      item.itemDefault ? this.editItemObj.item_quantity = item.itemDefault : ''
    }
  },
  saveUpdatedItem(prev, edit){
    var array = _.cloneDeep(this.parentLocationObjs)
    var first = array.find(p => p.item_loc_01 == prev.item_loc_01)
    var checkAdd = false
    if(first && prev.item_loc_02 != 'NULL'){
      var second = first.item_loc_02.find(p => p.item_loc_02 == prev.item_loc_02)
      if(second && prev.item_loc_03 != 'NULL'){
        var third = second.item_loc_03.find(p => p.item_loc_03 == prev.item_loc_03)
        if(third && third.item_loc_03_items){
         var items = third.item_loc_03_items
          var index = items.map(p => p.holdid).indexOf(prev.holdid)
          if(items && index != -1 && edit ? prev.item_loc_03 != edit.item_loc_03 : true){
          items.splice(index, 1)
          checkAdd = edit ? true : false
          } else {
          
            items[index] = edit
            this.showModal = !this.showModal
          }
        }
      } else {
        var items = second.item_loc_02_items
        var index = items.map(p => p.holdid).indexOf(prev.holdid)
        if(items && index != -1 && edit ? prev.item_loc_02 != edit.item_loc_02 : true){
        items.splice(index, 1)
        checkAdd = edit ? true : false
        } else {
          items[index] = edit
          this.showModal = !this.showModal
        }
      }
    } else {
      var items = first.item_loc_01_items
      var index = items.map(p => p.holdid).indexOf(prev.holdid)
      if(items && index != -1 && edit ? prev.item_loc_01 != edit.item_loc_01 : true){
        items.splice(index, 1)
        checkAdd = edit ? true : false
      } else {
        items[index] = edit
        this.showModal = !this.showModal
      }
    }
    if(edit && checkAdd){
      var first = array.find(p => p.item_loc_01 == edit.item_loc_01)
      if(first && edit.item_loc_02 != 'NULL'){
        var second = first.item_loc_02.find(p => p.item_loc_02 == edit.item_loc_02)
         if(second && prev.item_loc_03 != 'NULL'){
           var third = second.item_loc_03.find(p => p.item_loc_03 == prev.item_loc_03)
           if(third && third.item_loc_03_items){
             if(third.item_loc_03_items){
          third.item_loc_03_items.push(edit)
          this.showModal = !this.showModal
        } else {
          console.log('Error')
        }
           } else {
             console.log('ERROR')
           }
         } else {
          if(second.item_loc_02_items){
          second.item_loc_02_items.push(edit)
          this.showModal = !this.showModal
        } else {
          console.log('Error')
        }
         }
      } else {
          if(first.item_loc_01_items){
          first.item_loc_01_items.push(edit)
          this.showModal = !this.showModal
        } else {
          console.log('Error')
        }

    } 
    } else {
      console.log('ERROR')
    }
    this.parentLocationObjs = array
   
  },
  duplicateCheckItem(obj){
      var edit = obj
      edit.holdid = parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
      var array = this.parentLocationObjs
      var first = array.find(p => p.item_loc_01 == edit.item_loc_01)
      if(first && edit.item_loc_02 != 'NULL'){
        var second = first.item_loc_02.find(p => p.item_loc_02 == edit.item_loc_02)
         if(second && edit.item_loc_03 != 'NULL'){
           var third = second.item_loc_03.find(p => p.item_loc_03 == edit.item_loc_03)
           if(third && third.item_loc_03_items){
             if(third.item_loc_03_items){
          third.item_loc_03_items.push(edit)
          this.showModal = !this.showModal
        } else {
          console.log('Error')
        }
           } else {
             console.log('ERROR')
           }
         } else {
          if(second.item_loc_02_items){
          second.item_loc_02_items.push(edit)
          this.showModal = !this.showModal
        } else {
          console.log('Error')
        }
         }
      } else {
          if(first.item_loc_01_items){
          first.item_loc_01_items.push(edit)
          this.showModal = !this.showModal
        } else {
          console.log('Error')
        }

    } 
  },
  addNew(par, sec, third){
      var obj = {
              holdid: 'NEW',
              item_loc_01: par,
              item_loc_02: sec,
              item_loc_03: third,
              item_name: '',
              item_pass: '',
              item_quantity: '',
              item_type: '',
              notes: '',
              sortOrder: ''
      }
    this.editItemHoldId = 'new'
    this.editItemObj = obj
    this.showModal = !this.showModal
  },
  setUpEditData(data){
    var obj = _.cloneDeep(data)
    this.beforeEditItem = _.cloneDeep(data)
    this.editItemHoldId = obj.id
    this.editItemObj = obj
    this.showModal = !this.showModal
  },
  getSecondaryLocations(par){
    this.dataResetSecond = false
    var array = _.cloneDeep(this.parentLocationObjs)
    var start = array.find(p => p.item_loc_01 == par)
    var holdarray = [...new Set(start.item_loc_02.map(p => p.item_loc_02))]
    var hold = holdarray.filter(p => {
      return p != 'NULL' && p
    })
    hold.push({text: 'None', value: 'NULL'})
    this.dataResetSecond = true
    return hold
  },
  getThirdLocations(par, sec){
    this.dataResetThird = false
    var array = _.cloneDeep(this.parentLocationObjs)
    var start = array.find(p => p.item_loc_01 == par)
    var second = start.item_loc_02.find(p => p.item_loc_02 == sec)
    if(second && second.item_loc_03){
    var holdarray = [...new Set(second.item_loc_03.map(p => p.item_loc_03))]
        var hold = holdarray.filter(p => {
      return p != 'NULL' && p
    })
    } else {
      hold = []
    }
    hold.push({text: 'None', value: 'NULL'})
    this.dataResetThird = true
    return hold
  },
  setParents(data){
    var obj1 = data
   // console.log(data)
   // this.refreshForm = false
    if(obj1){
     // console.log(obj1)
    var par = [... new Set(obj1.map(p => p.item_loc_01))]
    if(par){
    var hold = par.map(item => {
        var obj = {}
        obj.item_loc_01 = item
        obj.item_loc_01_items = this.getItemsFilter(item, 'NULL', 'NULL', data)
        obj.item_loc_02 = this.getSecondaryCats(item, data)
        return obj
    })
    //console.log(hold)
    this.parentLocationObjs = hold
    } else {
      this.parentLocationObjs = []
    }
    } else {
      this.parentLocationObjs = []
    }

  },
  AddNewParent(name){
    this.parentLocationObjs.push({item_loc_01: name, item_loc_02: [], item_loc_01_items: []})
  },
  getSecondaryCats(par, data){
    var hold = data
    var obj = hold.filter(p => {
      return p.item_loc_01 == par
    })
    var par2 = [... new Set(obj.map(p => p.item_loc_02))]
    var sec = par2.filter(p => {
      return p != 'NULL' && p
    })
    var map = sec.map(item => {
        var obj = {}
        obj.item_loc_02 = item
        obj.item_loc_02_items = this.getItemsFilter(par, item, 'NULL', data)
        obj.item_loc_03 = this.getThirdCats(par, item, data)
        return obj
    })
    return map
  },
  getThirdCats(par2, sec2, data){
    var hold = data
    var obj = hold.filter(p => {
      return p.item_loc_01 == par2 && p.item_loc_02 == sec2
    })
    var par = [... new Set(obj.map(p => p.item_loc_03))]
    var sec = par.filter(p => {
      return p != 'NULL' && p
    })
    var map = sec.map(item => {
        var obj = {}
        obj.item_loc_03 = item
        obj.item_loc_03_items = this.getItemsFilter(par2, sec2, item, data)
        return obj
    })
    return map
  },
  getItemsFilter(one, two, three, data){
    var hold = data
    var obj = hold.filter(p => {
      return p.item_loc_01 == one && p.item_loc_02 == two && p.item_loc_03 == three
    })
    var map = obj.map(item => {
      var newobj = item
      if(!item.holdid){
      newobj['holdid'] =  parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
      }
      return newobj
    })
    return map
  },
  updateHoldValues(id, type){
        this.resetField = id
    var item = this.holdValues[id]
   // console.log(item)
    if(type == 'minus' && item.count > 0){
      this.holdValues[id].count = parseInt(this.holdValues[id].count) - 1
    } else if (type == 'add'){
       this.holdValues[id].count = parseInt(this.holdValues[id].count) + 1
    }
    this.$nextTick(function(){
      this.resetField = ''
    })
  },
  checkHide(item, location){
    if(this.checkHideList[location]){
      var index = this.checkHideList[location].indexOf(item)
      if(index == -1){
        this.checkHideList[location].push(item)
      } else {
        this.checkHideList[location].splice(index, 1)
      }
    } else {
      this.checkHideList[location] = []
      this.checkHideList[location].push(item)
    }
  },
  checkIfHide(item, location){
    var index = this.checkHideList[location].indexOf(item)
    if(index == -1){
      return false
    } else {
      return true
    }
  },
  getArrayItemsFiltered(items){
    var itemPar = [... new Set(items.map(p => p.item_loc_01))]
    var holdArray = [] 
    for(var p in itemPar){
      var item2 = items.filter(x => x.item_loc_01 == itemPar[p])
      var item2cat = [... new Set(item2.map(p => p.item_loc_02))]
      var item2sub = []
      for(var s in item2cat){
        var item3 = item2.filter(y => y.item_loc_02 == item2cat[s])
        var item3cat = [... new Set(item3.map(p => p.item_loc_03))]
        var obj = { secondary: item2cat[s], third: item3cat}
        item2sub.push(obj)
      }
      holdArray.push({parent: itemPar[p], secondary: item2sub})
    }
    return holdArray
  },
  getItemsSecondarFromParentThird(parent, secondary, third){
  var obj = this.newInventoryForm
  var array =  obj.inventoryItems.filter(p => p.item_loc_01 == parent && p.item_loc_02 == secondary && p.item_loc_03 == third)
  var prevmap = array.map(item => {
    var obj = item
    obj['holdId'] = item.id + parseInt(Math.random()*1000000000)
    var prev = this.invetoryLastCheckData.find(p => p.id == item.id)
    if(prev){
      obj.item_count = prev.item_count
      var holdobj = {}
      holdobj['count'] = prev.item_count
      if(item.expDate){
        if(prev.expire_date){
          holdobj['expire_date'] = prev.expire_date
        } else {
          holdobj['expire_date'] = null
        }
      }      
      if(item['30daycount']){
        if(prev['30_Day']){
          holdobj['30_Day'] = prev['30_Day']
        } else {
          holdobj['30_Day'] = 0
        }
      }
      this.holdValues[obj.holdId] = holdobj
    } else {
      obj.item_count = obj.item_quantity
      var holdobj = {}
      holdobj['count'] = obj.item_quantity
      if(item.expDate){
          holdobj['expire_date'] = null
      }      
      if(item['30daycount']){
          holdobj['30_Day'] = 0
        }
        this.holdValues[obj.holdId] = holdobj
    }
    return obj
  })
  
  return prevmap
  },
  getItemsSecondarFromParent(parent, secondary){
  var obj = this.newInventoryForm
  var array =  obj.inventoryItems.filter(p => p.item_loc_01 == parent && p.item_loc_02 == secondary && p.item_loc_03 == 'NULL')
  var prevmap = array.map(item => {
    var obj = item
obj['holdId'] = item.id + parseInt(Math.random()*1000000000)
    var prev = this.invetoryLastCheckData.find(p => p.id == item.id)
    if(prev){
      obj.item_count = prev.item_count
      var holdobj = {}
      holdobj['count'] = prev.item_count
      if(item.expDate){
        if(prev.expire_date){
          holdobj['expire_date'] = prev.expire_date
        } else {
          holdobj['expire_date'] = null
        }
      }      
      if(item['30daycount']){
        if(prev['30_Day']){
          holdobj['30_Day'] = prev['30_Day']
        } else {
          holdobj['30_Day'] = 0
        }
      }
      this.holdValues[obj.holdId] = holdobj
    } else {
      obj.item_count = obj.item_quantity
      var holdobj = {}
      holdobj['count'] = obj.item_quantity
      if(item.expDate){
          holdobj['expire_date'] = null
      }      
      if(item['30daycount']){
          holdobj['30_Day'] = 0
        }
        this.holdValues[obj.holdId] = holdobj
    }
    return obj
  })
  return prevmap
  },
  updateInventoryTemplate(){
    var dataObj = {
      inventoryDbid: this.dataObj.inventoryDbid && this.dataObj.inventoryDbid.id ? this.dataObj.inventoryDbid.id : 'generic',
      inventoryFrequency: this.dataObj.inventoryFrequency,
      inventoryHead: this.dataObj.inventoryHead ? this.dataObj.inventoryHead : null,
      inventoryItems: this.returnInventoryItems,
      inventoryName: this.dataObj.inventoryName,
      inventoryType: this.dataObj.inventoryType
    }
    var method = 'patch' 
    var id = this.dataObj.id
    if(this.newInventoryForm.inventoryName == 'NEW'){
      method = 'post'
      id = ''
    }
    axios({ 
     method: method,
     url: '/api/v1/taxonomy/inventorytemplate/' + id,
     data: dataObj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     bus.$emit('getInventoryTemplates')
     this.$emit('closeInventoryNew')
   })
    },
  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
