<template>
<div id="homelanding" v-if="stationsData && apparatusData">
  <b-btn @click="generateXml">Generate</b-btn>
  {{ xmlBuild }}
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
export default {
components: {

},
props: ['rosterData', 'payrollCodesData', 'rosterProfiles', 'payrollModDailyRosterData'],
data() {
    return {
    selectedDate: '',
    onDutyFilter: true,
    vacancyFilter: false,
    vacancyFilterUser: null,
    xmlBuild: null,
    rosterReset: true,
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    nonSpotModification: [
       'payaddative',
    ],
    removeRoster: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'upaid leave',
    ],
    createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
    ],
    fillVacancy: [
      'overtime',
      'roster mod',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'exchange',
      'unpaid leave',
      'roster mod',
      'overtime',
    ],
    filteredSpots: [],
  }
},
created () {
  this.selectedDate = '2023-08-12' //moment('2023-08-12').format('YYYY-MM-DD')
  bus.$on('showRosterData', this.showRosterData)
  bus.$on('vacancyFilterUpdate', this.fillVacancyView) 
},
beforeDestroy(){
  	
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
    rosterCodesForOffRoster(){
    var array = this.payrollCodesData.filter(p => {
      return p.payrollCode == 'EXOFF' || this.removeRoster.indexOf(p.payrollCodeCategory) != -1
    })
    return array.map(p => p.id)
  },
  kellyDayObject(){
   // // console.log('KELLY PATTERN')
    if(this.siteconfig.payroll.kellyDayPattern){
    var shifts = this.siteconfig.payroll.shifts
    var kellyPattern = this.siteconfig.payroll.kellyDayPattern
    var build = []
    for(var k in kellyPattern){
        for(var s in shifts){
        build.push({shift: shifts[s].shift, kelly: this.siteconfig.payroll.kellyDayPattern.indexOf(kellyPattern[k])})
      }
    }
    var today = moment(this.selectedDate, 'YYYY-MM-DD').clone()
    var startDate = today.clone()
   // // console.log(today)
    var subDays = 0
   // // console.log('START WHILE')
    while (true) {
      var days = moment(startDate).diff(this.siteconfig.payroll.cycleStartDate, 'days') // 21
      var mathhold = (days / 21)
      if(mathhold == Math.floor(mathhold)){
     //   // console.log('BREAK')
        break
      }
      startDate = startDate.subtract(1, 'day')
      subDays++;
    } 
     var start = moment(today).subtract(subDays, 'days') 
     var patternRun = moment(today.clone()).diff(start, 'days')
    // // console.log(patternRun)
     var index = build[patternRun]
    // // console.log(build) 
     return index
    } return null
  },
  currentShift(){
              var payroll = this.siteconfig.payroll
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = moment(this.selectedDate).diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
  },
  modifications(){
              // console.log('Modifications')
              var array = this.payrollModDailyRosterData.filter(p => {
                        var index = this.spotModification.indexOf(p.payrollModType)
                        if(index != -1){
                          return p
                        }
              })
              array = array.map(item => {
                  var obj = this.setupPayrollModification(item)
                  return obj
              })
              return array
            },
   swapModifications(){
              // console.log('swapModifications()')
            return this.payrollModDailyRosterData.filter(p => {
              return p.payrollModExchangeUserId != '' && p.payrollModExchangeUserId != null && p.payrollModExchangeUserId != undefined
            })
          },

  rosterBuildOutForXml(){
    var data = this.rosterBuildOut
    // console.log(data)
    return data
  },



  
},
mounted(){

},
methods: {
          getModObjects(item, base){
            var usercheck = item.vacancyMod ? null : item.userId ? item.userId : item.payrollModTargetUserId ? item.payrollModTargetUserId : ''
            if(usercheck){
              var obj = this.deptContactData.find(p => p.id == usercheck)
              if(obj){
                var user = obj
              } else {
                var user = null
              }
            }
            var payrollModType = item.payrollSwap ? item.payrollSwap : item.payrollCode ? item.payrollCode : item.payrollModCode ? item.payrollModCode : undefined
            if(payrollModType){
              var payrollCode = this.payrollCodesData.find(p => p.id == payrollModType)
              if(payrollCode){
                var payrollModType = payrollCode.payrollCodeCategory
              } else {
                var payrollModType = null
              }
            } else {
              var payrollModType = null
            }
            var spotObj = {
                        position: base.position, 
                        type: item.vacancyMod ? 'Vacancy' : '',
                        cert: item.cert ? item.cert : null,
                        payrollCode: item.payrollSwap ? item.payrollSwap : item.payrollCode ? item.payrollCode : item.payrollModCode ? item.payrollModCode : undefined,
                        payrollModType: payrollModType,
                        flag: item.rosterSpotType ? item.rosterSpotType : base.flag, 
                        required: base.required,
                        rosterId: item.rosterId ? item.rosterId : base.rosterId,
                        status: item.payrollSwap ? base.userId : null, 
                        userId: item.vacancyMod ? null : item.userId ? item.userId : item.payrollModTargetUserId ? item.payrollModTargetUserId : '',
                        user: user,
                        spotData: item.vacancyMod ? null : item,
                        startTime: item.startTime,
                        endTime: item.endTime,
                        }
            return spotObj
          },

          figureOutSpots(base){
            // console.log('figure out spots')
            var profilesDate = this.profilesFilteredByDate()
            // console.log(profilesDate)
            var profiles = []
            var users = profilesDate.filter(p => {
              return p.rosterId == base.rosterId && p.rosterPosition == base.position
            })
            var mods = this.modifications.filter(p => {
              return p.rosterId == base.rosterId && p.rosterPosition == base.position && p.vacancyFill == true
            })
            if(mods.length > 0){
              for(var m in mods){
                users.push(mods[m])
              }
            }
            var array = [base]
            if(users.length > 0){
              var fixedVacanciesByProfile = this.getFixedVacancies(array, users)
                for(var f in fixedVacanciesByProfile){
                  profiles.push(fixedVacanciesByProfile[f])
                }
                for(var u in users){
                  var obj = this.getModObjects(users[u], base)
                  profiles.push(obj)
                }    
                return profiles

            } else {
          return [base]
            }
          },
          checkForUserMods(users){
            // console.log('checkForUserMods(users)')
              var profiles = []
              for(var u in users){
              var obj = _.cloneDeep(users[u])
              var array = this.modifications.filter(p => p.userId == users[u].userId)
              if(users[u].kellyDay){
                  var roster = [users[u]]
                  var payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'KD')
                  if(!payrollCode){
                    var payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'OnDuty')
                  }
                  var startTime = roster[0].startTime
                  var endTime = moment(roster[0].startTime).add(24, 'hours').toISOString()
                  var newObj = {
                                  payrollModType: payrollCode ? payrollCode.payrollCodeCategory : 'Missing Mod',
                                  payrollModCode: payrollCode ? payrollCode.id : 'Missing Code',
                                  payrollModTargetUserId: roster[0].userId,
                                  payrollModDatetime: startTime,
                                  payrollModStartDatetime: startTime,
                                  payrollModHours: 24,
                                //  payrollModAuthorId: "system-kd",
                                //  payrollModApprovalUserId: "system-kd",
                                  payrollModApproved: true,
                                  payrollModNote: "",
                                  startTime: startTime,
                                  endTime: endTime,
                                // systemCreated: true
                        }
                  //// console.log(newObj)
                  array.push(newObj)
              }
                if(array.length > 0){
                array = _.orderBy(array, function(e){ return Number.parseInt(e.rosterShiftLength)}, ['desc'])
                var profile = [obj]
                var mods = []
                for(var s in array){
                  if(array[s]){ 
                  var swap = _.cloneDeep(array[s])
                  mods.push(swap)
                  }
                }
                var fixedProfile = this.getFixedSwap(profile, mods)
                  for(var f in fixedProfile){
                    profiles.push(fixedProfile[f])
                  }
              } else {
                profiles.push(users[u])
              }
              }
              return profiles
            },

          checkForUserSwaps(users){
              // console.log('checkForUserSwaps(users)')
              var profiles = []
              for(var u in users){
                var obj = users[u]
                obj['startTime'] = moment(this.selectedDate + 'T' + obj.rosterStartTime, "YYYY-MM-DDTHH:mm:ss").toISOString()
                obj['endTime'] = moment(obj.startTime).add(obj.rosterShiftLength, 'hours').toISOString()
                var array = this.swapModifications.filter(p => p.payrollModTargetUserId == users[u].userId)
                if(array.length > 0){
                var profile = [obj]
                var mods = []
                for(var s in array){
                  if(array[s]){ //array[s].payrollModExchangeApproved
                  var swap = _.cloneDeep(users[u])
                  swap['id'] = obj.id + array[s].id
                  swap['payrollId'] = obj.id
                  swap['payrollSwap'] = this.getPayrollCode('EXON')
                  swap['payrollModData'] = array[s]
                  swap['payrollCode'] = array[s].payrollModCode
                  swap['startTime'] = array[s].payrollModDatetime
                  swap['endTime'] = moment(swap.startTime).add(array[s].payrollModHours, 'hours').toISOString()
                  swap['targetUser'] = obj.userId
                  swap['userId'] = array[s].payrollModExchangeUserId
                  swap['payrollModStartDateTime'] = array[s].payrollModDatetime
                  swap['rosterShiftLength'] = Number.parseFloat(array[s].payrollModHours)
                  mods.push(swap)
                  }
                }
                var fixedProfile = this.getFixedSwap(profile, mods)
                  for(var f in fixedProfile){
                    profiles.push(fixedProfile[f])
                  }
                  for(var m in mods){
                    profiles.push(mods[m])
                  }
              } else {
                profiles.push(obj)
              }
              }
              return profiles
            },
            checkForKellyDays(profiles){
              // console.log('checkForKellyDays(profiles)')
            var kelly = _.cloneDeep(profiles)
            kelly = kelly.map(item => {
                var obj = item
                if(item.scheduledKellyCycleNumber == this.kellyDayObject.kelly && item.scheduledShift == this.kellyDayObject.shift){
                obj['kellyDay'] = true
                }
                return obj
            })
            return kelly
          },
          profilesPreDateFiltered(){
            // console.log('profilesPreDateFiltered()')
          var array = _.cloneDeep(this.rosterProfiles)
          // console.log(array)
          //// console.log(this.rosterProfiles)
          // // console.log(this.selectedDate)
          array = array.filter(p => {
            var start = moment(p.rosterAssignmentStart).isSameOrBefore(this.selectedDate, 'd')
            var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate) : true
            if(start == false){
          //   // console.log(p)
            }
            if(start && end){
              return p
            }
          })
          // console.log(array)
          var profiles = array.filter(p => {
              if(p.scheduledShift == this.currentShift){
                return p
              } else if (p.scheduledShift == 'Daily'){
                var index = p.scheduledWorkDays.indexOf(moment(this.selectedDate).format('ddd'))
                if(index != -1){
                  return p
                }
              }
          })
         // console.log('PROFILES')
         // console.log(profiles)
          return profiles
        },
        profilesFilteredByDate(){
          // console.log('profilesFilteredByDate()')
        var profiles = this.profilesPreDateFiltered()
        // console.log(profiles)
          // KELLY DAY LOGIC????
          // Adjusts Profiles for Swaps, and Swap Profile
        if(this.kellyDayObject){
          profiles = this.checkForKellyDays(profiles)
        }
        var swaps = this.checkForUserSwaps(profiles)
        if(false){
        // console.log('Swaps')
        // console.table(swaps)
        }
          // Adjusts PRofiles for Mods (Mods get added on RosterProfile)
        var mods = this.checkForUserMods(swaps)
        if(false){
        // console.log('Mods')
        // console.log(mods)
        }
        return mods
      },
      spotsReturned(spot){
            // console.log('spotsReturned(spot)')
          var spots = spot.rosterPositions
          var date = _.cloneDeep(this.selectedDate)
        // // console.log(spots)
          var returnSpots = []
          var rosterUsers = this.profilesFilteredByDate()
          var positionHold = rosterUsers.filter(p => {
            return p.rosterId == spot.id
          })
          for(var s in spots){
            var cPH = _.cloneDeep(positionHold)
            var users = cPH.filter(p => {
              if(p.rosterPosition == spots[s].position){
                positionHold.splice(positionHold.map(p => p.id).indexOf(p.id), 1)
                return p
              }
          
            })
            var startTime = moment(date + 'T' + spots[s].start, 'YYYY-MM-DDTHH:mm:ss').toISOString()
            var spotObj = {
                      rosterId: spot.id,
                      position: spots[s].position, 
                      type: 'Vacancy', 
                      flag: spots[s].type,
                      required: spots[s].type,
                      cert: spots[s].cert ? spots[s].cert : false,
                      status: 'Vacancy', 
                      userId: null, 
                      startTime: startTime,
                      endTime: moment(startTime).add(spots[s].duration, 'hours').toISOString()
                      }
          // Will need to do the vacancy loop on this //
              var holdObj = _.cloneDeep(spotObj)
              var array = this.figureOutSpots(spotObj)
              for(var a in array){
                returnSpots.push(array[a])
              }
              var mods = this.modifications.filter(p => {
                return p.rosterId == holdObj.rosterId && p.rosterPosition == holdObj.position && p.vacancyFill == false
              })
              if(mods.length > 0){
                  for(var m in mods){
                    var obj = this.getModObjects(mods[m], holdObj)
                    returnSpots.push(obj)
                  }
              }
          }
          returnSpots =  _.orderBy(returnSpots, ['position', 'startTime'], ['asc', 'asc'])
          var additional = this.modifications.filter(p => {
            return p.rosterId == spot.id && (!p.rosterPosition || spots.map(p => p.position).indexOf(p.rosterPosition) == -1)
          })
          if(additional){
            additional = _.orderBy(additional, ['payrollModDatetime'], ['asc'])
            var Hold = []
            var start = 1
            var spotObj = {
                      rosterId: spot.id,
                      position: '+' + 1, 
                      type: 'Vacancy', 
                      flag: 'Additional',
                      required: 'Additional',
                      status: 'Vacancy', 
                      userId: null, 
                      }
            for(var i in additional){
              var obj = this.getModObjects(additional[i], spotObj)
              Hold.push(obj)
              start = start + 1
            }
            for(var h in Hold){
              returnSpots.push(Hold[h])
            }
          }
        // // console.log(returnSpots)
        // return returnSpots
          if(this.payaddativemods){
          var addative = returnSpots.map(item => {
              var obj = _.cloneDeep(item)
              obj['addative'] = this.checkForPayAddative(item)
              if(obj.addative){
              }
              return obj

          })
          } else {
            var addative = returnSpots
          }
          //Add Kelly Re-Entry Here
        // // console.log(addative)
          if(this.kellyDayObject){
              var kellyProfiles = _.cloneDeep(this.profilesPreDateFiltered)
              var newkellyProfiles = kellyProfiles.filter(p => {
                      return p.scheduledKellyCycleNumber == this.kellyDayObject.kelly && p.scheduledShift == this.kellyDayObject.shift
              })
            if(newkellyProfiles && newkellyProfiles.length > 0){
              var kellySpots = []
              for(var s in returnSpots){
                newkellyProfiles.filter(p => {
                    if(p.rosterId == returnSpots[s].rosterId && p.rosterPosition && returnSpots[s].position){
                      var index = kellySpots.map(p => p.id).indexOf(p.id)
                      if(index == -1){
                      kellySpots.push(p)
                      }
                    }
                })
              
              // // console.log((kellySpots))
            }
              if(kellySpots.length > 0){
                // ADD BACK KELLY DAY LOGIC
                var kellyCode = this.payrollCodesData.find(p => p.payrollCode == 'KD')
                  var spotObj = {
                            type: 'Vacancy', 
                            addative: false,
                            status: 'Vacancy', 
                            userId: null, 
                            }
                  for(var i in kellySpots){
                    var start =  moment(moment().format('YYYY-MM-DD') + 'T' + kellySpots[i].rosterStartTime).toISOString()
                    var kObj = kellySpots[i]
                        spotObj['position'] = kellySpots[i].rosterPosition
                        kObj['rosterId'] = kellySpots[i].rosterId
                        kObj['payrollCode'] = kellyCode.id,
                        kObj['startTime'] = start
                        kObj['endTime'] = moment(start).add(kellySpots[i].rosterShiftLength, 'hours').toISOString()
                    var obj = this.getModObjects(kObj, spotObj)
                    addative.push(obj)
                  //  start = start + 1
                }
                addative =  _.orderBy(addative, ['position', 'startTime'], ['asc', 'asc'])
              } else kellySpots = addative
            } else {
              var kellySpots = addative
            }
            var kellySpots = addative
          } else {
            var kellySpots = addative
          }
          if(this.userSelected){
            var vacantFilter = kellySpots.filter(p => {
              return p.type != 'Vacancy'
            })
            var user = vacantFilter.filter(p => {
              return p.userId == this.userSelected.id
            })
          } else {
            var user = addative
          }
          if(this.onDutyFilter){
            var arrayon = user.filter(p => {
              return p.type != 'Vacancy'
            })
            var arrayoff = arrayon.filter(p => {
              if(p.payrollCode){
                return this.rosterCodesForOffRoster.indexOf(p.payrollCode) == -1
              } return p
            })
          } else {
            var arrayoff = user
          }
          if(this.filterArray && this.filterArray.filterModType && this.filterArray.filterModType.length > 0){
            var arraymodType =  arrayoff.filter(p => {
                              var index = this.filterArray.filterModType.indexOf(p.payrollModType)
                              if(index != -1){
                                return p
                              }
                      })
              
          } else {
            var arraymodType = arrayoff
          }
          if(this.filterArray && this.filterArray.filterByRank && this.filterArray.filterByRank.length > 0){
            var arrayRank = arraymodType.filter(p => {
                            if(p.user && p.user.deptRank){
                              return this.filterArray.filterByRank.indexOf(p.user.deptRank) >= 0
                            }
                      })
              
          } else {
            var arrayRank = arraymodType
          }
          if(this.filterArray && this.filterArray.filterBySpecial && this.filterArray.filterBySpecial.length > 0){
            var arraySpecial = arrayRank.filter(p => {
                            if(p.user){
                              var specials = p.user.deptSpecialties
                              if(specials){
                              for(var s in specials){
                                  var index = this.filterArray.filterBySpecial.indexOf(specials[s])
                                  if(index != -1){
                                    return p
                                  }
                                }
                              }
                            }
                      })
              
          } else {
            var arraySpecial = arrayRank
          }
          if(this.vacancyFilter){
        //   // console.log('VACANCY FILTER')
        //   // console.log(arrayoff)
            var vacancyFilterData = arraySpecial.filter(p => {
              return  p //p.type == 'Vacancy' //&& p.required == "required"
            })
            if(this.vacancyFilterUser && this.vacancyFilterUser.user && false){
            // // console.log('VACANCY USER')
          //  // console.log(vacancyFilterData)
            // // console.log(this.vacancyFilterUser)
              vacancyFilterData = vacancyFilterData.filter(p => moment(this.vacancyFilterUser.user.startTime).isSameOrBefore(p.startTime) && moment(this.vacancyFilterUser.user.endTime).isSameOrAfter(p.endTime))
            }
          } else {
            var vacancyFilterData = arraySpecial
          }
        //  // console.log(vacancyFilterData)
          return vacancyFilterData
            
        },
      returnRosterSpots(pid, sid){
        // console.log('returnRosterSpots(pid, sid)')
      var array = this.rosterData.filter(p => p.stationParent == pid && p.stationId == sid)

      array.forEach(item => item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId) ? this.apparatusData.find(p => p.id == item.apparatusId).apparatusRadioId : 'Unknown')
      var array = array.map(p => {
        var obj = p
        obj['rosterSpots'] = this.spotsReturned(p)
        return obj
      })
     // array.forEach(item => item = this.spotsReturned(item))
      
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])
      //// console.log(apparatusSort) 
      return apparatusSort
    },
      stationsFromParentsMap(id){
        // console.log('stationsFromParentsMap(id)')
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])
      var stationMap = stationsSort.map(item => {
        var obj = item
        obj['rosterSpots'] = this.returnRosterSpots(id, item.id)
        return obj
      })
      //// console.log(stationMap)
      return stationMap
    },
    rosterBuildOut(){ //export
    // console.log('rosterBuildOut()')
    var array = this.rosterData
    var station = _.cloneDeep(this.stationsData)
    var parentLoad = [...new Set(array.map(p => p.stationParent))]
    var parentsObj = parentLoad.map(item => item = station.find(p => p.id == item))
    parentsObj.forEach(item => item['showStation'] = true)
    var parents = _.orderBy(parentsObj, ['stationName'], ['asc']) 
    var buildingArray = parents.map(item => {
        var obj = item
        obj['stations'] = this.stationsFromParentsMap(item.id)
        return obj
    })
    if(this.filteredSpots.length > 0){
      var index = this.filteredSpots.map(p => p.rosterId)
      var array = buildingArray.map(p => {
          var obj = _.cloneDeep(p)
          obj.stations = []
          var station = p.stations
          var stationArray = []
          for(var s in station){
          var staObj = station[s]
          var spots = []
          var rosterIds = station[s].rosterSpots
          for(var i in rosterIds){
            if(index.indexOf(rosterIds[i].id) != -1){
              spots.push(rosterIds[i])
            }
          }
          if(spots.length > 0){
            
            staObj.rosterSpots = spots
            stationArray.push(staObj)
          }
          if(stationArray.length > 0){
           
            obj.stations = stationArray
            return obj
          }
          }
      })
      var undArray = array.filter(p => {
          return p != undefined
      })
      return undArray
    } else {
     // // console.log(buildingArray)
     return buildingArray
    }
  },
  generateXml(){
    // console.log('generate')
    var returnData = []
    var data = this.rosterBuildOut()
    // console.log(data)
    for(var d in data){
      var stations = data[d].stations
      for(var s in stations){
        var rs = stations[s].rosterSpots
        for(var r in rs){
          var apparatus = rs[r].apparatusName
          if(apparatus && apparatus != '"Unknown"'){
            var spots = rs[r].rosterSpots
            for(var t in spots){
              if(spots[t].user){
                var obj = {}
                obj['UnitId'] = apparatus.trim()
                obj['PersonnelD'] = spots[t].user.employeeId
                obj['StartDateTime'] = moment(spots[t].startTime).format()
                obj['EndDateTime'] = moment(spots[t].endTime).format()
                obj['Working'] = 1
                returnData.push({'_name': 'Record', '_content': obj})
              }
            }
          }
        }
      }
    }
     console.log(returnData)
  },
  getJsonPretty(data){
    return JSON.stringify(data, null, 2); // spacing level = 2
  },
  getPayrollCode(code){
      var obj = this.payrollCodesData.find(p => p.payrollCode == code)
      if(obj){
        return obj.id
      } else {
        return 'Code Not Found'
      }
    },
    getFixedSwap(array, mods){
      if(false){
      // console.log('Get Fixed Swap')
      // console.log(array)
      // console.log(mods)
      }
       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
          var mod = mods.filter(p => moment(p.startTime).isSameOrAfter(array[a].startTime) && moment(p.endTime).isSameOrBefore(array[a].endTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  objTwo.startTime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  array.push(objTwo)
                  array[a] = obj
                }
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isAfter(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
    setupPayrollModification(array){
         var allMods = _.cloneDeep(this.payrollModDailyRosterData)
         var vacancyFill = array.payrollModRosterPosition && array.payrollModRosterId ? true : false
         var position = null
         var rosterid = null
         if(!vacancyFill){
           var profile = this.profilesPreDateFiltered().find(p => p.userId == array.payrollModTargetUserId)

           if(profile){
             position = profile.rosterPosition ? profile.rosterPosition : null
             rosterid = profile.rosterId ? profile.rosterId : null
           } else {
             var mod = allMods.find(p => p.payrollModExchangeUserId == array.payrollModTargetUserId)
             if(mod){
               profile = this.profilesPreDateFiltered().find(p => p.userId == mod.payrollModTargetUserId)
               if(profile){
                position = profile.rosterPosition ? profile.rosterPosition : null
                rosterid = profile.rosterId ? profile.rosterId : null
             }
           }
          }
         }
         var swap = {}
         swap['id'] = array.id
         swap['vacancyFill'] = vacancyFill
         swap['payrollId'] = array.payrollModTargetUserId
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : rosterid ? rosterid : ''
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : position ? position : null
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['userId'] = array.payrollModTargetUserId
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
    },
  getPayrollModObj(array, users){
         var index2 = this.fillVacancy.indexOf(array.payrollModType)
         var swap = _.cloneDeep(users)
         swap['id'] = users.id + array.id
         swap['payrollId'] = users.id
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : users.rosterId
         swap['vacancyTest'] = index2 == -1 ? true : false
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : users.rosterPosition
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
  },
  // BUILDS SPOTS IN THE INTIAL ROSTER

    stationsFromParents(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort
    },

    //// FROM SPOTS VUE
        getFixedVacancies(array, mods){
       function doTheLoop() {
         for(var a in array){
          var mod = mods.filter(p => ( moment(p.startTime).isSameOrAfter(array[a].startTime) && moment(p.startTime).isBefore(array[a].endTime) ) 
                                    || ( moment(p.endTime).isSameOrBefore(array[a].endTime) && moment(p.endTime).isAfter(array[a].startTime) ))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last < last
                else if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  objTwo.startTime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  array.push(objTwo)
                  array[a] = obj
                }
                // first < first && last == last
                else if(moment(mod[m].startTime).isAfter(array[a].startTime)){

                  var obj = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  if(moment(obj.startTime).isBefore(obj.endTime) ){
                  array[a] = obj
                    } else {
                      array.splice(a, 1)
                      return(true);
                    }
                }
                // first == first && last < last
                else if(moment(mod[m].startTime).isSameOrBefore(array[a].startTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  if(moment(obj.startTime).isBefore(obj.endTime) ){
                    array[a] = obj 
                    } else {
                    array.splice(a, 1)
                    return(true);
                  }
                }


                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },

     checkForPayAddative(spot){
      if(spot && spot.spotData){
      var payAdd = this.payaddativemods.find(p => p.payrollModRosterId == spot.rosterId && p.payrollModTargetUserId == spot.spotData.userId && p.payrollModDatetime == spot.startTime)
      if(payAdd){
        if((this.userData.role == 'admin' || this.userData.role == 'fireops' || this.userData.role == 'adminchief') && this.showHiddenPaymods){
          bus.$emit('payAddativeTracking', payAdd.id)
         }
          return payAdd
        }  else {
      return false
      }
      } else {
        return false
      }
    },


},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.printReport {
  margin: 0;
  padding: 0;
  top:0;
  position:fixed;
  width: 1200px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
  
}
@media only screen and (max-width: 600px){
  .mobileView {
    padding: 0;
  }
}

</style>
