<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
    <b-card>
      <b-row> 
        <b-col lg="3">
          <h4>Payroll Accrual Name</h4>
        <b-form-input type="text" v-model="dataObj.payrollAccuralName" />
        </b-col>
        <b-col lg="3">
          <h4>Payroll Bank</h4>
        <b-form-select v-model="dataObj.payrollBankId" :options="bankModOptions" /> 
        </b-col>
         <b-col lg="3">
          <h4>Payroll Accrual Rate</h4>
        <b-form-input type="number" v-model="dataObj.payrollBankAccuralRate" />
        </b-col>
         <b-col lg="2">
          <h4>Accrual Type</h4>
        <b-form-select v-model="dataObj.payrollBankAccuralType" :options="payrollBankAccuralTypeOpt" /> 
                </b-col>
        <b-col lg="1">
             <h4>Add</h4>
   <b-btn  @click="newBank" variant="primary">Add</b-btn>
          </b-col>

        </b-row>
     
    </b-card>
    <b-table :fields="fields" :items="payrollAccuralData">
            <template slot="payrollAccuralName" slot-scope="data">
        <span v-if="editItemId != data.item.id">{{ data.item.payrollAccuralName }}</span>
        <b-form-input  v-if="editItemId == data.item.id" type="text" v-model="data.item.payrollAccuralName" />
        </template>
      <template slot="payrollBankId" slot-scope="data">
        <span v-if="editItemId != data.item.id">{{ getBankName(data.item.payrollBankId) }}</span>
         <b-form-select v-if="editItemId == data.item.id" v-model="data.item.payrollBankId" :options="bankModOptions" /> 
        </template>
              <template slot="payrollBankAccuralRate" slot-scope="data">
        <span v-if="editItemId != data.item.id">{{ data.item.payrollBankAccuralRate }}</span>
        <b-form-input  v-if="editItemId == data.item.id" type="number" v-model="data.item.payrollBankAccuralRate" />
        </template>
              <template slot="payrollBankAccuralType" slot-scope="data">
        <span v-if="editItemId != data.item.id">{{ data.item.payrollBankAccuralType }}</span>
          <b-form-select v-if="editItemId == data.item.id" v-model="data.item.payrollBankAccuralType" :options="payrollBankAccuralTypeOpt" /> 
        </template>
      <template slot="Edit" slot-scope="data">
      <b-btn v-if="editItemId != data.item.id" @click="editItemId = data.item.id" variant="warning" size="sm">Edit</b-btn>
      <b-btn v-if="editItemId == data.item.id" @click="updateBankMod(data.item)" variant="info" size="sm">Update</b-btn>
      <b-btn v-if="editItemId == data.item.id" @click="editItemId = ''" variant="danger" size="sm">Cancel</b-btn>
      </template>
      </b-table>
</div>
</div>

</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
export default {
  components: {
    multiselect
  },
  name: 'roster-admin',
  props: ['payrollAccuralData', 'payrollBanksData'],
  data () {
    return {
      apparatusSelectValue: null,
      stationSelectValue: '',
      editItemId: '',
      stationParentSelectValue: '',
      bankModId: '',
      dataObj: {
          payrollAccuralName: '',
          payrollBankId: '',
          payrollBankAccuralRate: 0,
          payrollBankAccuralType: 'payperiod'
      },
      payrollBankAccuralTypeOpt: [
        {text: 'Every Paycycle', value: 'paycycle'},
        {text: 'Every ShiftCycle', value: 'shiftcycle'},
        {text: 'Last Paycycle', value: 'lastpaycyle'},
        {text: 'First Paycycle', value: 'firstpaycycle'},
        {text: 'First Payperiod of Month', value: 'firstpayperiodmonth'},
        {text: 'First Day of the Month', value: 'firstdayofthemonth'}
      ],
      payrollColorOptions: ['default', 'Yellow', 'black', 'bright-blue', 'blue', 'bright-green', 'green', 'bright-orange', 'orange', 'bright-red', 'red', 'bright-purple', 'purple'],
      payrollCodeVisibilityOptions: [{text: 'admin', value: 'adminchief'}, 'fireops', 'emsops', 'shopops', 'trainingops', 'logisticsops', 'supervisor', 'user' ],
      fields: [
          {key: 'payrollAccuralName', label: 'Accrual Name'},
          'payrollBankId',
          {key: 'payrollBankAccuralRate', label: 'Accrual Rate'},
          {key: 'payrollBankAccuralType', label: 'Accrual Type'},
          'Edit'
        //  'payrollBankRolloverEnd'
      ],
      bankModType: 'addTo',
      codeTypeOptions: [
        'bank modification',
        'exchange',
        'roster mod',
        'scheduled leave',
        'modified leave',
        'unscheduled leave',
        'payaddative',
        'overtime',
        'overtime availability',
        'unpaid leave',
        'none'
      ]
    }
  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        bankModOptions(){
          var options = this.payrollBanksData.map(item => {
            var obj = {}
            obj['text'] = item.payrollBankName
            obj['value'] = item.id
            return obj
          })
         // console.log(options)
          return options
        },
        payrollCodesDataWithEdit(){
          var array = this.payrollCodesData.map(p => {
            var obj = p
            if(obj.payrollCodeBankMod == null){
              obj.payrollCodeBankMod = []
            }
            if(obj.payrollCodeVisibility == null || obj.payrollCodeVisibility == undefined){
              obj.payrollCodeVisibility = []
            }
            obj['edit'] = false
            if(obj.payrollCodeVisibility == null || obj.payrollCodeVisibility == undefined || obj.payrollCodeVisibility.length == 0){
              obj.payrollCodeVisibility = ['admin', 'adminchief', 'fireops']
            }
            if(obj.payrollCodeApprovalRole == null || obj.payrollCodeApprovalRole == undefined || obj.payrollCodeApprovalRole.length == 0){
              obj.payrollCodeApprovalRole = ['admin', 'adminchief', 'fireops']
            }
            return obj
          })
          return array
        }
       
  },
  mounted(){

  },
  watch: {
  },
  methods: {
        getBankName(id){
   
      var obj = this.payrollBanksData.find(p => p.id == id)
      if(obj && obj.payrollBankName){
        return obj.payrollBankName
      } else {
        return 'Name Not Found'
      }
    },
      updateBankMod(data){
    axios({
     method: 'patch',
     url: '/api/v1/staffing/payrollaccural/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       bus.$emit('getPayrollAccurals')
            this.editItemId = ''
   })

    },
    newBank(){
    axios({
     method: 'post',
     url: '/api/v1/staffing/payrollaccural',
     data: this.dataObj,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       bus.$emit('getPayrollAccurals')
   })

    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
