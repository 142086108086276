<template>
  <div class="bodywidth">
  <b-container fluid>
    <b-btn @click="newFeatureRequest = !newFeatureRequest">New Feature Request</b-btn>
    <b-card v-for="feature in featureRequestDataReturn" :key="feature.id"  border-variant="info" header-border-variant="info" footer-border-variant="info"> 
      <div slot="header">
        <b-row>
          <b-col align="left"><h4>{{ feature.featureTitle }}</h4>
          <h6>
            <span class="font-italic mt-1">Updated: {{ getDateFormated(feature.updatedAt) }}</span>
           <b-badge :variant="getClassForStatus(feature.featureStatus)" class="ml-2">{{ feature.featureStatus }}</b-badge>
          </h6></b-col>
          <b-col align="right"><h6 class="font-italic">{{ feature.featureAuthor.rank }} {{ feature.featureAuthor.name }}</h6><h6 class="font-italic">{{ feature.featureAuthor.dept.toUpperCase() }}</h6></b-col>
        </b-row>
      </div>
      <div class="text-align-left"><span class="font-weight-bold">Description:</span> {{ feature.featureDescription }}
      <b-card  v-if="viewCommentThread == 'main' && viewCommentThreadId == feature.id">
        <b-row ><b-col>
      <b-form-input size="sm" class="m-2" type="text" placeholder="Ask a question or add a comment" v-model="previousFeatureComment" /></b-col>
        <b-col cols="*"><b-button size="sm" variant="primary"  class="mt-2 mr-4" @click="saveCommentForFeature(feature.id)" :disabled="!previousFeatureComment">Send</b-button></b-col></b-row>
           <template>
           <b-container v-for="comment in getComments(feature.featureComments, 'main')" :key="comment.id" class="pl-5 mb-2">
             <b-row>
               <b-col align="left" cols="*">
             <span class="author-class-for-comment"> {{ comment.author.name }} | {{ comment.author.rank }} | {{ comment.author.dept }} </span>
             <b-card no-body bg-variant="light" class="p-2">{{ comment.comment }}</b-card>
              <span class="date-class-for-comment">{{ getDateFormated(comment.date) }}</span>
             </b-col>
             </b-row>
           </b-container>
           </template>
             
      </b-card>
     <h6 class="mb-0"><b-badge class="pointer" v-if="viewCommentThreadId != feature.id" @click="viewCommentThread = 'main', viewCommentThreadId = feature.id, previousFeatureComment = ''">View Previous Comments {{ getComments(feature.featureComments, 'main').length }}</b-badge></h6>
      </div>
      <b-row><b-col align="left"><b-badge variant="primary">Votes {{ feature.featureVotes && feature.featureVotes.length ? feature.featureVotes.length : 0 }}</b-badge></b-col>
             <b-col><b-badge class="pointer" variant="secondary" v-if="checkUserHasNotVoted(feature.featureVotes)" :disabled="feature.featureAuthor.id == userData.id" @click="upvoteFeature(feature.id)">Click here to vote this feature up</b-badge></b-col>
      </b-row>
    </b-card>
  </b-container>
  <b-modal v-model="newFeatureRequest" title="New Feature Request">
    <b-form-input v-model="newFeature.featureTitle" placeholder="Feature Title" />
    <b-textarea v-model="newFeature.featureDescription" placeholder="Feature Description" />
  <template slot="modal-footer">
    <b-btn variant="success" size="sm" @click="createNewFeature">Save</b-btn>
  </template>
  </b-modal>

</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
export default {
props:[],
components:{


},
  data () {
    return {
      showAdmin: false,
      newFeatureRequest: false,
      viewCommentThread: '',
      viewCommentThreadId: '',
      newFeature: {
        featureTitle: '',
        featureDescription: '',
      },
      showDetailsId: null,
      featureRequestData: [],
      previousFeatureComment: '',
      fields: [
        {key: 'createdAt', label: 'Request Date'},
        {key: 'updatedAt', label: 'Last Updated'},
        'featureTitle',
        'featureAuthor',
        'Feedback',
        'details'
      ]
    }
  },
  created(){
      this.getFeatureRequests()
  },
  computed:{
    ...mapState([
      'userData',
      'getSessionData',
      'siteconfig'
    ]),
    featureRequestDataReturn(){
      var data = _.cloneDeep(this.featureRequestData)
      data = data.map(item => {
          var obj = item
          if(!item.featureVotes){
            obj.featureVotes = []
          }
          return obj
      })
      return _.orderBy(data, [({ featureVotes }) => featureVotes.length, 'updateAt'], ['desc', 'asc'])
    },
  },
   methods:{
     getClassForStatus(data){
       if(data == 'Scheduled'){
         return 'primary'
       }
       if(data == 'Completed'){
         return 'success'
       }
       if(data == 'Requested'){
         return 'dark'
       }
       return 'info'
     },
     checkUserHasNotVoted(votes){
       if(votes){
         var index = votes.map(p => p.userId).indexOf(this.userData.id)
         if(index == -1){
           return true
         } return false
       } return true
     },
     getComments(comments, refId){
       if(comments && refId){
         var returnArray = comments.filter(p => p.refId == refId && p.comment)
         return _.orderBy(returnArray, ['date'], ['asc']) // future need to add splice and count to limit how many comments display. 
       } return []
     },
     getDateFormated(date){
       if(date){
         return moment(date).format('MM/DD/YY HH:mm')
       } return 'invalid date'
     },
     createNewFeature(){
       var data = this.newFeature
       // data['featureAuthor'] = {id: this.userData.id, rank: this.userData.rank, email: this.userData.email, name: this.userData.firstName + ' ' + this.userData.lastName, dept: this.siteconfig.deptSub } // needs to be siteconfig on sites
       data['featureAuthor'] = {id: this.userData.id, rank: this.userData.deptRank, email: this.userData.email, name: this.userData.firstName + ' ' + this.userData.lastName, dept: this.siteconfig.deptAbbr } // needs to be siteconfig on sites
       data['featureStatus'] = 'Requested'
        axios({
        method: "post",
        url: '/api/v1/content/featurerequest',
        data: data,
        headers: {
              Authorization: this.getSessionData,
              "Content-Type": "application/json"
           }
          }).then(response => {
      this.newFeatureRequest = false
      this.newFeature = {
        featureTitle: '',
        featureDescription: '',
      },
      this.getFeatureRequests()
      });
     

  },
  getFeatureRequests(){
    axios({
      method: 'get',
      url: '/api/v1/content/featurerequest/',
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => { 
        this.featureRequestData = response.data
    })
  },
  upvoteFeature(id){
      axios({
        method: "patch",
        url: '/api/v1/content/featurerequest/vote/' + id,
        data: {
            newVote: {
              id: moment().toISOString() + this.userData.id,
              date: moment().toISOString(),
              userId: this.userData.id,
              author: { rank: this.userData.deptRank, dept: this.siteconfig.deptAbbr, name: this.userData.firstName + ' ' + this.userData.lastName, email: this.userData.email},
            }
        },
        headers: {
              Authorization: this.getSessionData,
              "Content-Type": "application/json"
           }
          }).then(response => {
        
            this.getFeatureRequests()
      });
  },
  saveCommentForFeature(id, commentId){
    //  this.showDetailsId = id
      axios({
        method: "patch",
        url: '/api/v1/content/featurerequest/comment/' + id,
        data: {
            newComment: {
              id: moment().toISOString() + this.userData.id,
              date: moment().toISOString(),
              refId: commentId ? commentId : 'main',
              author: { rank: this.userData.deptRank, dept: this.siteconfig.deptAbbr,  name: this.userData.firstName + ' ' + this.userData.lastName, email: this.userData.email},
              comment: this.previousFeatureComment
            }
        },
        headers: {
              Authorization: this.getSessionData,
              "Content-Type": "application/json"
           }
          }).then(response => {
            this.previousFeatureComment = ''
            this.getFeatureRequests()
      });
  },
   }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;  
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.alignLeftClass{
  text-align: left;
}
.author-class-for-comment {
 font-size: small;
 font-weight: bold; 
}
.date-class-for-comment {
 font-size: small;
 font: italic; 
}
img {
  max-width: 100%;
  height: auto;
}
.bodywidth{
  width: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
