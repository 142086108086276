<template>
<div>
        <b-container class="pt-3" v-if="activated == true"> 
                    <b-card 
                      border-variant="dark"
                      header="Search"
                      header-bg-variant="dark"
                      header-text-variant="white">
                   <b-form-group breakpoint="lg"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                    <b-row class="my-2">
                      <b-col v-for="(field, index) in fieldsSearch" :key="index" class="mt-2" :lg="field.size" :label-for="field.idtag + '-search'"><h6>{{ field.label }}</h6>
                              <b-form-input v-if="field.type == 'text' || field.type == 'number'" v-model="holdValues[field.vmodel].value" :id="field.idtag + '-search'" :type="field.type" @change="updateValue(field.vmodel, $event, field)" ></b-form-input>
                              <b-textarea v-if="field.type == 'textarea'" :id="field.idtag + '-search'" v-model="holdValues[field.vmodel].value" :rows="field.rows" />
                              <b-btn v-if="field.type == 'textarea'" :disabled="checkTextAreaValue(field.vmodel) == holdValues[field.vmodel].value && holdValues[field.vmodel].value != undefined" @click="updateValue(field.vmodel, holdValues[field.vmodel].value, field)">Save</b-btn>
                              <b-form-checkbox v-if="field.type == 'boolean'"  :id="field.idtag + '-search'" v-model="holdValues[field.vmodel].value" :value="true" :unchecked-value="false" @change="updateValue(field.vmodel, $event, field)" />
                              <multiselect v-if="field.type == 'taxonomySingleSelect'"
                                          :id="field.idtag + '-search'"
                                          v-model="holdValues[field.vmodel].value" 
                                          :options="field.tax == 'station' ? stationsData : ''" 
                                          :multiple="field.type == 'taxonomySingleSelect' ? false : true" 
                                          :close-on-select="true" 
                                          :clear-on-select="true"
                                          :hide-selected="true" 
                                          @input="updateValue(field.vmodel, holdValues[field.vmodel].value, field)"
                                          :preserve-search="true" 
                                          :placeholder="'Select ' + field.tax" 
                                          :label="field.tax == 'station' ? 'stationName' : ''" 
                                          :track-by="field.tax == 'station' ? 'stationName' : ''"></multiselect>
                              <multiselect v-if="field.type == 'reference'"
                                          :id="field.idtag + '-search'"
                                          v-model="holdValues[field.vmodel].value" 
                                          :options="getReferenceOptions(field.reference, field.refitem, field)" 
                                          :multiple="false" 
                                          :close-on-select="true" 
                                          :clear-on-select="true"
                                          :hide-selected="true" 
                                          @input="updateValue(field.vmodel, holdValues[field.vmodel].value, field)"
                                          :preserve-search="true" 
                                          :placeholder="'Select ' + field.refitem" 
                                          :label="field.tax == 'station' ? 'stationName' : ''" 
                                          :track-by="field.tax == 'station' ? 'stationName' : ''"></multiselect>
                        
                      </b-col>
                      </b-row>   
                      
                    <b-btn @click="searchPreplan()">Search</b-btn><b-btn @click="clearSearch()">Clear Fields</b-btn>
                    </b-form-group>
                    </b-card>
                   


                      </b-container>
         
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import printJS from 'print-js'
import DatePicker from '../../inputfields/Datepickermod.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import querystring  from 'querystring';
import Multiselect from 'vue-multiselect'

export default {
  props:["preplanId", "savedPreplanData", "viewItem", "formGroups"], 
components:{
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,

},
  data () {
    return {
        showTutVideo: false,
        edit: true,
        imageResponseData: [],
        postData: '',
        timeData: '',
        stationSelectValue: '',
        showGroup: 'business',
        showModalConfirmation: false,
        pictureUpload: '',
        searchData: '',
        carouselToggle: '',
        pictureFileArray: [],
        imageLoader: '',
        holdValues: {},
        patchData: false,
        completedLoad: false,
        ppObj: {},
        fieldsSearch: [],
        activated: true,


    }
  },
  created(){
    this.currentDateSelected = moment().format('MM/DD/YYYY');
    this.currentDateSelectedChange = this.currentDateSelected
    this.getIndexValues(this.formGroups)

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'preplanStatus',
        ]),
        shiftSet(){
          var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
          var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
          var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
          var dateDiffCalNumber = 100*(dateDiffCal/3);
          var dateTenDigit = dateDiffCalNumber % 10;
          var roundedDated = Math.round(dateTenDigit)
          if (roundedDated == 0)
          return "B-shift";
          if (roundedDated == 7)
          return "A-shift";
          if (roundedDated == 3)
          return "C-shift";
        },
      formatedDateExport () {
        var dateString = moment(this.currentDateSelected, 'MM/DD/YYYY').format("YYYY-MM-DD")
        var timeString = dateString + 'T' + this.currentTime
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
      },
      todaysDate() {
        this.currentDateSelected = moment().format('MM/DD/YYYY');
        return this.currentDateSelected
      },
        getStationParent(){
          if(this.stationSelectValue != '' && this.stationSelectValue != undefined){
            var parent = this.stationSelectValue.stationParent
            if(parent != '' && parent != undefined){
              return parent
            } else {
              return this.stationSelectValue.stationName
            }
          } else {
            return ''
          }
        }
    

    },
    watch: {

      },
  beforeDestroy(){

  },
  methods:{
    clearSearch(){
      this.activated = false
      this.holdValues = {}
      this.ppObj = {}
      this.fieldsSearch = []
      this.getIndexValues(this.formGroups)
      this.$nextTick(function(){
        this.activated = true
      })
    },
  getIndexValues(fields){
    console.log('INDEXED')
      var array = []
      for(var p in fields){
      var fieldSort = fields[p].fields
      for(var i in fieldSort){
        if(fieldSort[i].searchIndex == true){
        array.push(fieldSort[i])
        var obj = {value: '', type: fieldSort[i].type}
        this.holdValues[fieldSort[i].vmodel] = obj
        this.ppObj[fieldSort[i].idtag] = ''
      }
      }
      }
    this.fieldsSearch = array
    },
    getReferenceOptions(reference, sortValue){
      var fields = this.formGroups
      var ref = []
      for(var i in fields){
        var fieldSort = fields[i].fields
        for(var p in fieldSort){
          if(fieldSort[p].vmodel == reference){
            ref.push(fieldSort[p])
          }
        }
      }
      if(ref.length > 0){
        var taxarr = ref[0].tax == 'station' ? this.stationsData : ''
        var ref = [... new Set(taxarr.map(p => p[sortValue]))]
        if(ref.length > 0){
        var values = []
        for(var r in ref){
          if(ref[r]){
            values.push(ref[r])
          }
        }
        ref = values
      } else {
        ref = []
      }
      }
      return ref
    },
    checkTextAreaValue(item){
      var data = this.ppObj
      var items = item.split('.')
      for(var i in items){
        data = data[items[i]]
      }
      return data
    },
    updateValue(item, value, field){
      var data = this.ppObj
      if(field.type == 'taxonomySingleSelect'){
        data[field.idtag] = value[field.taxitem]
      } else {
        data[field.idtag] = value
      }
    },
    searchPreplan(){
      var obj = this.ppObj
       for (var propName in obj) { 
       if (obj[propName] === null || obj[propName] === undefined || obj[propName] == '') {
            delete obj[propName];
          }
        }
      var dataObj = querystring.stringify(obj)
      console.log(dataObj)
      axios({
        method: 'get',
        url: '/api/v1/content/preplan/search/item?' + dataObj,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
        console.log(response.data)
        })
    }

}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
