<template>
  <div class="hello">
    <b-container>
      <b-container>
        <b-card 
        :class="checkShowStatus(event) ? 'my-2 border-' + editVariantColor : event.eventVariantColor ? 'my-2 border-' + event.eventVariantColor : 'my-2 border-dark'" 
        :text-variant="event.eventVariantColor == 'default' ? 'dark' : event.eventVariantColor ? 'white' : 'dark'"
        :header-bg-variant="checkShowStatus(event) ? editVariantColor : event.eventVariantColor ? event.eventVariantColor : ''" 
        v-for="event in parentEvents" 
        :key="event.index" header-slot="header">
      <b-row slot="header">
        <b-col>
          <h4 v-show="!checkShowStatus(event)" >{{ event.eventtypeName }}</h4>
          <b-form-input v-if="checkShowStatus(event)" type="text" v-model="editValue" />
        </b-col>
        <b-col cols="auto">
          <b-form-select  v-if="checkShowStatus(event)" v-model="editVariantColor">
            <option value="" disabled>Please Select Color</option>
            <option v-for="(option, index) in colorOptions" :value="option.variant" :class="'text-' + option.variant" :key="index">{{ option.label }}</option>
          </b-form-select>
          </b-col>  
        <b-col cols="auto">
          <b-card nobody body-class="m-0 pt-1 pl-1 pr-1 pb-0" bg-variant="dark">
          <b-btn size="sm" variant="warning" v-show="!checkShowStatus(event)" @click="showEditBox(event)" >Edit</b-btn>
          <b-btn @click="showEditBox(event)" v-show="checkShowStatus(event)" size="sm" variant="warning">Cancel</b-btn>
           <b-btn v-show="checkShowStatus(event)" size="sm" variant="info" :class="{disabled: event.eventtypeName == editValue && event.eventVariantColor == editVariantColor}" @click="updateEvent(event)">Update</b-btn>
          <b-btn size="sm" variant="danger" v-if="userData.role == 'admin'" :class="{disabled: filterSubEvents(event).length > 0}"  @click="deleteSub(event)">Delete</b-btn>
          </b-card>
        </b-col>
      </b-row>
        <b-alert variant="warning" :show="filterSubEvents(event).length == 0">
          Users will be unable to submit events for this Parent Category if no childern are added
        </b-alert> 
        <b-card no-body size="sm" class="my-2 pt-2" text-variant="dark" :class="'border-' + event.eventVariantColor" v-for="subevent in filterSubEvents(event)" :key="subevent.index" >
          <b-row >
          <b-col>
          <h5  v-show="!checkShowStatus(subevent)">{{ subevent.eventtypeName }}</h5>
            <b-form-input v-if="checkShowStatus(subevent)" type="text" v-model="editValue" />
        </b-col>  
        <b-col cols="3">
          <b-btn @click="showEditBox(subevent)" v-show="!checkShowStatus(subevent)" size="sm" variant="warning">edit</b-btn>
           <b-btn @click="showEditBox(subevent)" v-show="checkShowStatus(subevent)" size="sm" variant="warning">Cancel</b-btn>
           <b-btn v-show="checkShowStatus(subevent)" size="sm" variant="info"  @click="updateEvent(subevent, event.eventtypeName)">Update</b-btn>
           <b-btn size="sm" variant="danger" @click="deleteSub(subevent)" >Delete</b-btn>
        </b-col>
      </b-row>
        
          



      </b-card>
      <b-card class="mx-auto w-75" :class="'border-' + event.eventVariantColor">
        <h5 class="text-dark"><em>Add New {{ event.eventtypeName }} Sub Category</em></h5>
        <b-container>
        <b-row>
          <b-col>
        <b-form-input :placeholder="'New ' + event.eventtypeName + ' Sub Category Name'" v-model="newSubCatName" />
          </b-col>
          <b-col cols="*">
        <b-btn size="sm" class="mt-1" :variant="event.eventVariantColor" @click="addNew(event.eventtypeName)">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>



      </b-card>
      <b-card class="bg-primary">
      <b-card class="border-primary" header-slot="header">
        <h5><em>Add New Event Parent Category</em></h5>
        <b-container>
        <b-row>
          <b-col>
        <b-form-input :placeholder="'New Event Parent Category Name'" v-model="newSubCatName" @click="addNew()" />
          </b-col>
          <b-col cols="*">
        <b-btn variant="primary" @click="addNew()">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>
      </b-card>
      </b-container>
    </b-container>
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['taxData'],
  name: 'hello',
  components: { 


  },
  data () {
    return {
    newSubCatName: '',
    editId: '',
    colorOptions: [
      {variant: 'info', label: 'Teal'},
      {variant: 'primary', label: 'Blue'},
      {variant: 'success', label: 'Green'},
      {variant: 'danger', label: 'Red'},
      {variant: 'warning', label: 'Yellow'},
      {variant: 'secondary', label: 'Grey'},
      {variant: 'default', label: 'Default'}

    ],
    editData: false,
    editValue: '',
    editVariantColor: ''

    }
  },
  created(){
    
  },
  computed: {
      ...mapState([
        'userData',
        'getSessionData'
      ]),
      parentEvents(){
        return this.taxData.filter( p => {
          return p.eventtypeParent == '' || p.eventtypeParent == undefined || p.eventtypeParent == null
        })
      },
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },

      /*

      How to filter against two arrays

      docArray(){
        var loaded = [... new Set(this.eventsApi.map(p => p.field_event_id))]
        var bulk = [... new Set(this.docArrayPre.map(p => p.field_event_id))]
        var test = this.docArrayPre
        console.log(loaded)
        console.log(bulk)
        for(var i = 0; i < loaded.length; i++){
          var index = bulk.indexOf(loaded[i])
          if(index > -1){
          test.splice(index, 1)}
        }
        return test

      } */
  },
  watch: {

},
  methods: {
    showEditBox(event){
      if(this.editId == ''){
        this.editId = event.id
        this.editValue = event.eventtypeName
        this.editVariantColor = event.eventVariantColor
      } else if (event.id != this.editId){
        this.editId = event.id
         this.editValue = event.eventtypeName
          this.editVariantColor = event.eventVariantColor
      } else {
        this.editId = ''
         this.editValue = ''
          this.editVariantColor = null
      }
      
    },
    checkShowStatus(event){
        if(event.id != this.editId){
          return false
        } else {
          return true
        }
      },
    filterSubEvents(data){
      return this.taxData.filter( p => {
        if(p.eventtypeParent != '' && p.eventtypeParent != undefined && p.eventtypeParent != null){
          return p.eventtypeParent == data.eventtypeName
        }
      })
    },
    addNew(parent){
      console.log(parent)
    if(parent == undefined){
      var parent = ''}
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'eventtype', headers: headers, expire: true}
    axios({
      method: 'post',
      url: '/api/v1/taxonomy/eventtype/',
      data: {
        'eventtypeName': this.newSubCatName,
        'eventtypeParent': parent
      },
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
          this.newSubCatName = ''
  
      })
    
    },
    deleteSub(event){
      var result = confirm('Are You Sure You Want to Delete ' + event.eventtypeName + ' ? Might break the site, probably will, you really sure?')
      if (result) {
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'eventtype', headers: headers, expire: true}
    axios({
      method: 'delete',
      url: '/api/v1/taxonomy/eventtype/' + event.id,
      headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
  
      })
    } else {}
    },
    updateEvent(event, parent){
      if(parent == undefined){
        parent = ''
        var setParent = event.eventtypeName
        var childEvents = this.taxData.filter( p => {
        if(p.eventtypeParent != '' && p.eventtypeParent != undefined && p.eventtypeParent != null){
          return p.eventtypeParent == event.eventtypeName
        }
        })
      }
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'eventtype', headers: headers, expire: true}
      axios({
        method: 'patch',
        url: '/api/v1/taxonomy/eventtype/' + event.id,
        data: {
          'eventtypeName': this.editValue,
          'eventVariantColor': this.editVariantColor,
          'eventtypeParent': parent
        },
        headers: payload.headers.headers,
      }).then(response => {
        if(parent == ''){
          for(var i = 0; i < childEvents.length;){
            var count = 1
            var counter = childEvents.length
            axios({
              method: 'patch',
              url: '/api/v1/taxonomy/eventtype/' + childEvents[i].id,
              data: {
                'eventtypeName': childEvents[i].eventtypeName,
                'eventtypeParent': this.editValue
              },
               headers: payload.headers.headers,
             }).then(response => {
               count++
               if(count == counter)
              this.$nextTick(function(){
              this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
               this.editValue = ''
               this.editId = ''
                })
               })
               i++
       
          }    


        } else {
        console.log(response.data)
        this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
        this.editValue = ''
        this.editId = ''
        }
      })
    },
  
  
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>