<template>
<div @click="addModalOpen">
  <div class="fullScreen" v-if="readCodeTrue">
    <b-row><b-col>
      <span v-if="transferMeds.length > 0" class="mr-2">Selected: {{ transferMeds.length }}</span>
      <span v-if="scanMessage" :class="scanMessageVariant == 'danger' ? 'text-danger' : 'text-success'">{{ scanMessage }}</span>
      </b-col><b-col>
    <b-btn @click="readCodeTrue = !readCodeTrue, scannerOn = !scannerOn, scanMessage = '', scanMessageVariant = ''" variant="danger" >Done</b-btn>
    </b-col>
    </b-row>
      <QuaggaScanner v-if="scannerOn" @onEmitDetected="result" />
   
  </div>
  <b-modal 
       id="narcLogModal"
       ref="narcLogModal"
       v-model="showModalNarc"
       :noCloseOnBackdrop="true"
       :noCloseOnEsc="true"
  >
  <template slot="modal-header"  v-if="!showModalConfirmation">
    <span> Create New Narcotic Entry
       <img class="imgNarcIcon" src="/images/siteicons/help-icon-right-align.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo"></span>
           <b-btn align="right" size="sm" variant="danger" @click="showModalswitchEmitNarc">x</b-btn>
         </template>
         <div class="modal-body"  v-if="!showModalConfirmation">
           <slot name="body">
            <tutdata v-if="showTutVideo" singlePost="newnarc" />
           <div>
             <h5 class="text-success font-weight-bold">CRADLE TO GRAVE</h5>
     <b-btn v-if="siteconfig.craddleToGraveFlag && siteconfig.craddleToGrave" variant="link" class="text-danger" @click="switchToCradleToGrave">Switch To Legacy Narcs</b-btn>
             <b-card no-body>
               <b-tabs card>
                 <b-tab title="General">
             <b-container>
               <b-row>
                <b-col lg="12" md="6">
                  <b-form-input :disabled="newImageFileDataWitness != ''" type="date" v-model="currentDateSelected" />
                  </b-col>
                <b-col>
                  <b-form-input :disabled="newImageFileDataWitness != ''" type="time" v-model="currentTimeSelected" />
                  </b-col>
                  <b-col>
                <h5 :class="shiftDateCalcClass">Shift: {{ shiftDateCalc }} </h5>
                  </b-col>
              </b-row>
             <b-row><b-col class="genRight">
              <div >
              <b-form-select  :disabled="newImageFileDataWitness != ''" :class="{errorClass: statusSelect == ''}" class="selectStatusInput" v-model="statusSelect" :options="status" ></b-form-select>
            </div>
            </b-col>
            </b-row>
            <b-form-select :disabled="!(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1)) || newImageFileDataWitness != ''" @change="tagSelectValue = '', stationSelectValue = '', stationSelectValueMobile = '', apparatusValue = '', apparatusSelectValueMobile = ''" :options="locationTypeOptions" v-model="locationType" />
             <b-row class="desktopView mt-2" v-if="locationType != 'location'">
              <b-col>
                <div :class="{errorClass: tagSelectValue == ''}">
                <multiselect   :disabled="newImageFileDataWitness != ''" v-model="apparatusSelectValue" :options="filteredApparatusData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select an Apparatus" label="apparatusName" track-by="apparatusName"></multiselect>
              </div>
              </b-col>
            </b-row>
            <b-form-select  v-if="locationType != 'location'" class="mobileView mt-2" :class="{errorClass: tagSelectValue == ''}" v-model="apparatusSelectValueMobile" text-field="apparatusName" value-field="apparatusName"  :options="filteredApparatusData" >
              <option value="">Please Select an Apparatus</option>
              </b-form-select>
             <b-row class="desktopView mt-2" v-if="locationType == 'location'">
              <b-col>
                <div :class="{errorClass: tagSelectValue == ''}">
                <multiselect   :disabled="newImageFileDataWitness != ''" v-model="stationSelectValue" :options="stationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select a Location" label="stationName" track-by="stationName"></multiselect>
              </div>
              </b-col>
            </b-row>
            <b-form-select   v-if="locationType == 'location'" class="mobileView mt-2" :class="{errorClass: tagSelectValue == ''}" v-model="stationSelectValueMobile" text-field="stationName" value-field="stationName"  :options="stationsData" >
              <option value="">Please Select a Station</option>
              </b-form-select>

           <b-form-select class="mt-2" v-if="(statusSelect == 'distribution' || statusSelect == 'quarantine' || statusSelect == 'restock') && tagSelectValue != ''" :disabled="!(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1))" @change="reportRunNumber = '', stationSelectValueRun = '', stationSelectValueMobileRun = '', apparatusValueRun = '', apparatusSelectValueMobileRun = ''" :options="moveLocationTypeOptions" v-model="moveLocationType" />
             <b-row class="desktopView mt-2" v-if="moveLocationType != 'location' && (statusSelect == 'distribution' || statusSelect == 'quarantine' || statusSelect == 'restock') && tagSelectValue != ''">
              <b-col>
                <div :class="{errorClass: reportRunNumber == ''}">
                <multiselect   :disabled="newImageFileDataWitness != ''" v-model="apparatusSelectValueRun" :options="filteredApparatusData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select an Apparatus" label="apparatusName" track-by="apparatusName"></multiselect>
              </div>
              </b-col>
            </b-row>
            <b-form-select  v-if="moveLocationType != 'location' && (statusSelect == 'distribution' || statusSelect == 'quarantine' || statusSelect == 'restock')  && tagSelectValue != ''" class="mobileView mt-2" :class="{errorClass: reportRunNumber == ''}" v-model="apparatusSelectValueMobileRun" text-field="apparatusName" value-field="apparatusName"  :options="filteredApparatusData" >
              <option value="">Please Select the Apparatus</option>
              </b-form-select>
             <b-row class="desktopView mt-2" v-if="moveLocationType == 'location' && (statusSelect == 'distribution' || statusSelect == 'quarantine' || statusSelect == 'restock')  && tagSelectValue != ''">
              <b-col>
                <div :class="{errorClass: reportRunNumber == ''}">
                <multiselect   :disabled="newImageFileDataWitness != ''" v-model="stationSelectValueRun" :options="stationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Please select a Location" label="stationName" track-by="stationName"></multiselect>
              </div>
              </b-col>
            </b-row>
            <b-form-select   v-if="moveLocationType == 'location' && (statusSelect == 'distribution' || statusSelect == 'quarantine' || statusSelect == 'restock')  && tagSelectValue != ''" class="mobileView mt-2" :class="{errorClass: reportRunNumber == ''}" v-model="stationSelectValueMobileRun" text-field="stationName" value-field="stationName"  :options="stationsData" >
              <option value="">Please Select the Restock Station</option>
              </b-form-select>


               <b-form-select class="mt-2" v-if="statusSelect == 'use'" :disabled="newImageFileDataWitness != ''" :options="[{text: 'Agency Personnel', value: 'agency'},{text: 'Outside Agency', value: 'outside'}]" v-model="useType" />
            <b-row v-if="formTypeSelected == 'new'" class="mt-2">
              <b-col v-if="statusSelect == 'verification' || statusSelect == 'initial' || statusSelect == 'destroy' || statusSelect == 'distribution' || (statusSelect == 'use' && useType == 'agency')">
                <div :class="{errorClass: witnessOrOffGoing == ''}">
                <multiselect  :disabled="newImageFileDataWitness != ''" v-model="witnessOrOffGoing" :options="userTagListFiltered" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" :placeholder="statusSelect == 'verification' || statusSelect == 'initial' ? 'Custody Transfer (Oncoming)' : 'Agency User (Witness)'">
                 <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span></template>
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                  </template>
                </multiselect></div></b-col>
                <b-col v-if="statusSelect == 'restock' || statusSelect == 'quarantine'">
                    <div :class="{errorClass: witnessOrOffGoing == ''}">
                  <multiselect  :disabled="newImageFileDataWitness != ''" v-model="witnessOrOffGoing" :options="deptContactDataFiltered" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Use/Restock Witness">
                                    <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span></template>
                    <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }}) | {{ props.option.stationAssignment }}</span>
                    </template>
                  </multiselect>
                </div></b-col>
                <b-col v-if="statusSelect == 'use' && useType == 'outside'">
                  <b-form-input :class="{errorClass: witnessOrOffGoing == ''}" type="text"  :disabled="newImageFileDataWitness != ''" placeholder="Waste Witness Name and Title" v-model="witnessOrOffGoing" />
                </b-col>

          </b-row>
          <b-form-input class="mt-2" v-if="statusSelect == 'use'" :class="{errorClass: reportRunNumber == ''}" :disabled="newImageFileDataWitness != ''" type="text" v-model="reportRunNumber" placeholder="Patient Care Report Run Number" />
            <b-row v-if="formTypeSelected == 'attach'">
              <b-col v-show="!apparatusSelectValue == ''">
                <div :class="{errorClass: witnessOrOffGoing == '' || witnessOrOffGoing.role != 'emsops'}">
                <multiselect  :disabled="newImageFileDataWitness != ''" v-model="witnessOrOffGoing" :options="emsOpsTagListFiltered" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select EMS OP User">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
                </multiselect></div></b-col>
          

          </b-row> 
          <b-row class="mt-2" v-if="statusSelect == 'verification' && siteconfig.narcoticsAdditionalFields">
            <template v-for="fields in siteconfig.narcoticsAdditionalFields">
              <b-col :key="fields.id">
                <label>{{ fields.label }}</label>
                <b-form-input :type="fields.type" @input="stateCheckForAdditionalConsole" v-model="narcoticsAdditionalFieldsData[fields.id]"  :class="{errorClass: checkForStateUpdate[fields.id]}" />
              </b-col>
            </template>
          </b-row>
            </b-container>
            </b-tab>
            <b-tab  :disabled="checkForDisabledNarcs">
              <span slot="title">
                Narcotics <b-badge v-if="statusSelect == 'verification'" :variant="checkForVerifedDet == 0 ? 'success' : 'warning'">
                  <span v-if="checkForVerifedDet == 0">&#x2713;</span>
                  <span v-if="checkForVerifedDet != 0">{{ checkForVerifedDet }}</span>
                  </b-badge>
                </span>
           
              <div v-if="verifiedNarcs && (statusSelect == 'verification' || statusSelect == 'initial' || statusSelect == 'destroy')">
              <b-card v-if="verifiedNarcs.length > 10 && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1))" 
                border-variant="dark" header-bg-variant="dark" size="sm" 
                header-text-variant="white" header-class="py-1 cursorClass" body-class="py-2"
                header="Operational Totals">
                <b-row v-for="(total, index) in getTotalCounts(verifiedNarcs)" :key="index">
                  <b-col align="right"><span class="font-weight-bold">{{ total.name }}:</span></b-col>
                  <b-col align="left">{{ total.count }}</b-col>
                  </b-row>
                </b-card>
              <b-card v-if="removedNarcs || addedNarcs"
                    @click="hideRecentChanges = !hideRecentChanges"
                    :no-body="!hideRecentChanges"
                    :header="hideRecentChanges ? 'Hide Changes Since Last Verification' : 'Show Changes Since Last Verification'"  border-variant="danger" header-bg-variant="danger" size="sm" 
                    text-variant="white" header-class="py-1 cursorClass" body-class="py-2">
                    <template v-if="removedNarcs">
                        <b-card  v-show="hideRecentChanges" v-for="narc in filteredVerified(removedNarcs)" :key="narc.id" header-tag="header" header-bg-variant="warning" class="mb-2" header-class="py-1" no-body>
                          <h6 slot="header" class="mb-0 font-weight-bold text-dark">REMOVED: {{ narc.medication }} | CN: {{ narc.controlNumber }}</h6>
                        </b-card>
                    </template>
                    <template v-if="addedNarcs" >
                        <b-card  v-show="hideRecentChanges" v-for="narc in filteredVerified(addedNarcs)" :key="narc.id" header-tag="header" header-bg-variant="primary" text-variant="light" class="mb-2" header-class="py-1" no-body>
                          <h6 slot="header" class="mb-0 font-weight-bold text-light">Added: {{ narc.medication }} | CN: {{ narc.controlNumber }}</h6>
                        </b-card>
                    </template>
              </b-card>
              <b-card v-for="narc in filteredVerified(verifiedNarcs)" :key="narc.id" header-tag="header" header-bg-variant="dark" class="mb-2" body-class="py-1" header-class="py-1">
                 <h6 slot="header" class="mb-0 font-weight-bold text-light">{{ narc.medication }}
                    <b-badge variant="success" v-if="previousVerificationTest && previousVerificationTest.indexOf(narc.id) >= 0">&#x2713;</b-badge>
                    <b-badge variant="primary" v-if="previousVerificationTest && previousVerificationTest.indexOf(narc.id) == -1">*NEW</b-badge>
                 </h6>
              <b-row>
                <b-col align="left"><span class="font-weight-bold">CN: </span>{{ narc.controlNumber }}</b-col>
                 <b-col align="left" :class="testNarcDate(narc.expDate, currentDateSelected)"><span class="font-weight-bold">Exp: </span>{{ getExpirationDate(narc.expDate) }}</b-col>
                </b-row>
                <b-row>
                <b-col align="left"><span class="font-weight-bold">NDC: </span>{{ narc.NdcNumber }}</b-col>
                <b-col align="left"><span class="font-weight-bold">Lot: </span>{{ narc.LotNumber }}</b-col>
                </b-row>
              </b-card>
              </div>
              <div v-else-if="verifiedNarcs">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab title="Search Available" :disabled="newImageFileDataWitness != ''">
                      <b-row class="mb-2"><b-col>
                      <h6>Search Control Number</h6>
                      <b-form-input type="number" v-model="availableNumberSearch" :disabled="statusSelect == 'use' && transferMeds.length > 0" /></b-col>
                      <b-col cols="auto" class="pt-2">
                      <b-btn v-if="userData.role == 'admin'" @click="readCodeTrue = !readCodeTrue, scannerOn = !scannerOn" variant="success">Scan</b-btn>
                      </b-col>
                      </b-row>
                          <b-pagination
                              v-if="verifiedNarcs.length > 10"
                              v-model="currentPage"
                              :total-rows="verifiedNarcs.length"
                              :per-page="perPage"
                              aria-controls="my-table"
                            ></b-pagination>
                      <b-card no-body v-for="narc in filteredVerified(verifiedNarcs, true, availableNumberSearch)" :key="narc.id" >
                      <b-container>
                      <b-row class="mb-2 mt-1" valign="center">
                        <b-col cols="auto" align="right"><b-btn size="sm" variant="info" @click="addToTransferMeds(narc.id, narc)" :disabled="statusSelect == 'use' && transferMeds.length > 0">Add</b-btn></b-col>
                        <b-col align="left"><h6 class="mt-2">{{ narc.medication}} | {{ narc.controlNumber }} | {{ getExpirationDate(narc.expDate) }}</h6></b-col>
                        </b-row>
                        </b-container>
                        </b-card>
                    </b-tab>
                    <b-tab :title="'Selected (' + transferMeds.length + ')'">
                    <b-card no-body v-for="narc in filteredVerified(transferMeds)" :key="narc.id" >
                      <b-container>
                      <b-row class="mb-2 mt-1" valign="center">
                        <b-col cols="auto" align="right"><b-btn size="sm" variant="danger" :disabled="newImageFileDataWitness != ''" @click="removeFromTransferMeds(narc.id)">X</b-btn></b-col>
                        <b-col align="left"><h6 class="mt-2">{{ narc.medication}} | {{ narc.controlNumber }} | {{ getExpirationDate(narc.expDate) }}</h6></b-col>
                        </b-row>
                        </b-container>
                        </b-card>
                    
                    </b-tab>
                    </b-tabs>
                    </b-card>
                </div>
              </b-tab>
              <b-tab title="Signatures" :disabled="checkForDisabledNarcs">
           
            <b-container  id="tablePassDown" v-if="tagSelectValue != ''">
                               <div>
                               <b-container class="medContainer">
                                 <b-row v-if="statusSelect != ''"  >
                                   <b-col >
                                     <b-btn variant="primary" v-if="newImageFileData == ''" @click.prevent="userSign = !userSign" >Add {{ userData.lastName }}'s Signature</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileData != ''" v-show="authorSignatureView" @click.stop="authorSignatureView = !authorSignatureView">Hide</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileData != ''" v-show="!authorSignatureView" @click.stop="authorSignatureView = !authorSignatureView">Show</b-btn>
                                       <b-btn  size="sm" variant="danger" v-if="newImageFileData != ''" @click.stop="userSign = !userSign">Edit ({{ userData.lastName }}'s) Signature</b-btn>
                                   </b-col></b-row>
                                   <b-row><b-col  v-if="previewImageFileData != ''" v-show="authorSignatureView"><img type="image" :src="previewImageFileData" />
                                   </b-col></b-row>
                                 <b-row v-if="statusSelect == 'use'">

                                   <b-container v-if="newImageFileDataWitness == ''"><h5>Medication Use Info</h5>
                                  <span v-if="narcUseCompare" class="text-danger">Vial Contains a total of {{ narcUseCompare.vialAmount }} {{ narcUseCompare.vialCon }}</span>
                                   <b-row><b-col><h6>Used?</h6>
                                    <b-form-input type="number" v-model="textUseObject.amountUsed" />
                                    </b-col><b-col><h6>Wasted?</h6>
                                     <b-form-input type="number" v-model="textUseObject.amountWasted" />
                                     </b-col><b-col><h6>Concentration</h6>
                                      <b-form-select type="number" v-model="textUseObject.concentration" :options="concentration" />
                                      </b-col>
                                      </b-row>
                                      <div v-if="narcUseCompare && narcUseCompareMath">
                                        <span v-if="narcUseCompareMath && narcUseCompareMath != 'All'" class="text-danger">Unaccounted for Amount {{ narcUseCompareMath }} </span>
                                        <span v-if="narcUseCompareMath && narcUseCompareMath == 'All'" class="text-success">All Accounted For </span>
                                      </div>
                                      <b-btn variant="success" @click="setUpUseNarcNote(textUseObject)">{{ narcDescription == '' ? 'Save' : 'Update' }}</b-btn>
                                     
                                   </b-container>
                                 <h6 class="pt-3 mx-auto">{{ narcDescription }}</h6>
                                 </b-row>
                                 <b-row v-if="statusSelect == 'use' || statusSelect == 'restock' || emailWitness == false">
                                   <b-col>
                                     <b-btn variant="primarys" v-if="newImageFileDataWitness == '' && witnessOrOffGoing" 
                                        @click.stop="witnessSign = !witnessSign" v-show="formTypeSelected == 'new'" :disabled="statusSelect == 'use' && narcDescription == ''">Add {{statusSelect != 'use' ? witnessOrOffGoing.lastName : formTypeSelected == 'new' && !witnessOrOffGoing.lastName ? witnessOrOffGoing : witnessOrOffGoing.lastName }}'s Signature</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileDataWitness != ''" v-show="witnessSignatureView" @click.stop="witnessSignatureView = !witnessSignatureView">Hide</b-btn>
                                      <b-btn size="sm" variant="warning" v-if="newImageFileDataWitness != ''" v-show="!witnessSignatureView" @click.stop="witnessSignatureView = !witnessSignatureView">Show</b-btn>
                                       <b-btn  size="sm" variant="danger" v-if="newImageFileDataWitness != ''" @click.stop="userSign = !userSign">Edit {{statusSelect != 'use' ? witnessOrOffGoing.lastName : formTypeSelected == 'new' && !witnessOrOffGoing.lastName ? witnessOrOffGoing : witnessOrOffGoing.lastName }}'s Signature</b-btn>
                                       <b-btn  size="sm" variant="danger" v-if="newImageFileDataWitness != ''" @click.stop="clearWitnessData">Delete</b-btn>
                                   </b-col></b-row>
                                   <b-row><b-col  v-if="previewImageFileDataWitness != ''" v-show="witnessSignatureView"><img type="image" :src="previewImageFileDataWitness" />
                                   </b-col></b-row>
                                  <b-row v-if="statusSelect =='verification'" v-show="newImageFileDataWitness == ''">
                                    <b-col>
                                  <b-form-checkbox id="checkbox1"
                                      v-if="formTypeSelected == 'new' && witnessOrOffGoing"
                                      v-model="emailWitness"
                                      :value='true'
                                     :unchecked-value='false'>
                                       Transfer User unavailable to sign (explanation required)
                                         </b-form-checkbox>
                                      </b-col>
                                  </b-row>
                                  <b-form-file v-if="formTypeSelected == 'attach'" @change="formFileBufferType($event)" />
                                  <em v-if="formTypeSelected == 'attach'">file type should be png or jpg</em>
                                  <div v-if="fileAttached">
                                  <img v-if="previewSettings == 'image' || previewSettings == 'link'" :src="fileForAttach" />
                                  <b-btn variant="link" v-if="previewSettings == 'link'" target="_blank" @click="fixAttachURI(fileForAttach)">Preview</b-btn>
                                  </div>
                                  <b-row v-if="statusSelect == 'destroy'">
                                  <b-col>
                                    <b-textarea v-model="narcDescription" :row="4" placeholder="Please provide an explanation for how the narcotics were destroyed" />
                                  </b-col>
                                  </b-row>
                                  <b-row v-if="emailWitness == true && statusSelect != 'use'">
                                  <b-col>
                                    <b-textarea v-model="narcDescription" :row="4" placeholder="Please provide an explanation for procedure deviation" />
                                  </b-col>
                                  </b-row>
                               </b-container>
                             </div>
                          <h6 v-if="newImageFileDataWitness != ''">Narc Card Details are Locked Due to Witness Signature</h6>
                             </b-container>
                             </b-tab> 
                             </b-tabs>
                  </b-card> 
                   <h6 v-if="!onlineStatus" class="text-danger mt-2">Not Connected to the Internet or slow connection</h6>
                   <h6 v-if="!onlineStatus" class="text-danger">Potential Save Issue with the Card</h6>
              </div>
           </slot>
         </div>

         <template slot="modal-footer" v-if="!showModalConfirmation">
          
          <h6 v-if="errorMessage" class="text-danger mt-2"><span class="font-weight-bold">Error: </span>{{ errorMessage }}</h6>
             <b-btn v-if="formTypeSelected == 'new'" @click.once="submitNarcLogNew" :disabled="checkForDisabled">Submit Card</b-btn>
             <div v-if="formTypeSelected == 'attach'">
        <b-btn @click.stop="submitOfflineNarc" 
             :disabled="newImageFileData == '' || field_medication_tracking == '' || shiftSelect == '' || fileForAttach == '' || witnessOrOffGoing == '' || !onlineStatus || disableSubmit"
             >Submit</b-btn> 
             </div>
         </template>


         <template slot="modal-header" v-if="showModalConfirmation">
                Post Confirmation
         </template>
         <div class="modal-body" v-if="showModalConfirmation">
                <div v-if="postData == ''">
                      ...Submitting post
                      <br>
                  <img class="imageGif" src="/images/siteicons/MalteseLoadingGif.gif">
                </div>
                <div v-if="postData != ''">
                      Your Post has been submitted!
                </div>
              </div>
            
                <narcdata v-if="postData != ''" :narcs="postData" :showPost="true" />
                <template slot="modal-footer" v-if="showModalConfirmation">
                      <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                        Close Message
                      </b-btn>
               </template>
         </b-modal>

<signature v-if="userSign" :showModalSignature="userSign" :name="userData.lastName" @signatureCapture="signatureMethodUser($event)" @closeModalCancel="signatureClose"></signature>
<signature v-if="witnessSign && witnessOrOffGoing" :showModalSignature="witnessSign" :name="witnessOrOffGoing.lastName" :witness="witness" @signatureCapture="signatureMethodWitness($event)" @closeModalCancel="signatureClose"></signature>


  </div>

</template>

<script>
import { mapState } from "vuex";
import { bus } from "../../main";
import axios from "axios";
import TutData from "../tracking/deptTutorialsdata.vue";
import narcData from "../tracking/dailynarctrack/apparatusFilterData.vue";
import signature from "../inputfields/signaturepad.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import QuaggaScanner from '../inputfields/Scanner.vue'
export default {
  props: ["showModal", "filterDate", "bulkNarcs", "bulkDestroyNarcs"],
  components: {
    QuaggaScanner,
    tutdata: TutData,
    multiselect: Multiselect,
    signature: signature,
    narcdata: narcData
  },
  data() {
    return {
      readCodeTrue: false,
      scannerOn: false,
      emailWitness: false,
      showTutVideo: false,
      currentPage: 1,
      perPage: 10,
      availableNumberSearch: '',
      scanMessage: '',
      scanMessageVariant: '',
      hideRecentChanges: true,
      trueBoo: true,
      witness: "Witness",
      imageLoadReset: true,
      showModalConfirmation: false,
      field_medication_tracking: [],
      distributionPlaceHold: '',
      field_item_tracking: [],
      formTypeSelected: 'new',
      currentDateSelected: "",
      currentTimeSelected: "",
      textUseObject: {
        amountUsed: 0,
        amountWasted: 0,
        concentration: '',
      },
      transferMeds: [],
      testValue: false,
      disableSubmit: false,
      detailedDescription: "",
      apparatusSelectValue: "",
      apparatusSelectValueMobile: '',
      stationSelectValue: "",
      stationSelectValueMobile: '',
      apparatusSelectValueRun: "",
      apparatusSelectValueMobileRun: '',
      stationSelectValueRun: "",
      stationSelectValueMobileRun: '',
      apparatusSelectValueName: '',
      shiftSelect: null,
      fileAttached: false,
      tagSelectValue: '',
      witnessOrOffGoing: "",
      narcDescription: "",
      concentration: ["mg", "mcg"],
      statusSelect: 'verification',
      useType: 'outside',
      locationType: 'apparatus',
      moveLocationType: 'location',
      newDataUUID: "",
      newDataUUIDWitness: "",
      imageFileName: "",
      imageFileNameWitness: "",
      imageFileFID: "",
      imageFileFIDWitness: "",
      newImageFileData: "",
      newImageFileDataWitness: "",
      errorMessage: null,
      fileName: "",
      fileNameWitness: "",
      fileType: "data:image/png",
      fileTypeWitness: "data:image/png",
      narcUseCompare: null,
      recentNarcCards: [],
      recentNarcStock: [],
      imageResponseData: [],
      imageResponseDataWitness: [],
      previousVerificationTest: [],
      narcoticsAdditionalFieldsData: {},
      checkForStateUpdate: {},
      checkForVerifedDet: 0,
      addedNarcs: null,
      removedNarcs: null,
      onDutySignature: "",
      secondaryLocation: 'apparatus',
      userSign: false,
      previousMedsLogged: [],
      resetInput: true,
      witnessSign: false,
      previewImageFileData: "",
      previewImageFileDataWitness: "",
      authorSignatureView: true,
      witnessSignatureView: true,
      replaceValueOther: "",
      reportRunNumber: "",
      postData: "",
      timeData: "",
      showModalNarc: false,
      medicationItemArray: [],
      fileForAttach: '',
      previewSettings: '',
      onlineStatus: navigator.onLine,
    };
  },
   beforeDestroy(){ /*
    window.removeEventListener('online',  function(){
              this.updateIndicator(true)});
    window.removeEventListener('offline', function(){
              this.updateIndicator(false)}); */
     
      },
  created() {
    this.showModalNarc = this.showModal;
    this.currentDateSelected = moment().format('YYYY-MM-DD');
    this.currentTimeSelected = moment().format('HH:mm')
    window.addEventListener('online', () => this.updateConnectionStatus());
    window.addEventListener('offline', () => this.updateConnectionStatus());
    this.updateConnectionStatus();
    this.checkUserPosts(this.userPosts)
    if(this.siteconfig.narcWasteUserInternal){
      this.useType = 'agency'
    }
    if(this.siteconfig.narcoticsAdditionalFields){
      var array = this.siteconfig.narcoticsAdditionalFields
      for(var i in array){
        this.narcoticsAdditionalFieldsData[array[i].id] = ''
        if(array[i].required){
         this.checkForStateUpdate[array[i].id] = true
        }
      }
    }
  },
  mounted() {

  },
  beforeDestroy(){
    setTimeout(()=>{
        document.body.classList.remove('modal-open')
    },  1)
  },
  computed: {
    ...mapState([
      "getSessionData",
      "stationsData",
      "apparatusData",
      "userData",
      "userTagList",
      "deptContactData",
      "shiftsData",
      "userPosts",
      "medicationData",
      'medsNarctrack',
      'siteconfig'
    ]),
    onlineStatusConnect(){
      var status = navigator.onLine
      return status
    },
    narcUseCompareMath(){ 
      if(this.narcUseCompare){
        var diff = Number.parseFloat(this.narcUseCompare.vialAmount)
        var total = diff - ( Number.parseFloat(this.textUseObject.amountUsed) +  Number.parseFloat(this.textUseObject.amountWasted))
        if(total == 0){
          return 'All'
        }
        return total
      } return false
    },
    checkForDisabled(){
      if(this.statusSelect == '' || this.tagSelectValue == ''){
        this.errorMessage = 'Card Type or Target Apparatus/Location'
        return true
      }
      if(this.statusSelect == 'initial' || this.statusSelect == 'destroy'){
        if(this.verifiedNarcs == null || this.newImageFileDataWitness == '' ||  this.witnessOrOffGoing == '' || this.newImageFileData == ''){
          this.errorMessage = 'Missing Bulk Narcs or Witness or Signatures'
          return true
        }  
        this.errorMessage = null
        return false
      }
      if(this.statusSelect == 'verification'){
        if(this.verifiedNarcs.length == 0 && !(this.userData.role == 'admin' || this.userData.role == 'emsops' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1))){
          this.errorMessage = 'No Narcs to Verifiy'
          return true
        } else if( this.newImageFileData == ''){
            this.errorMessage = 'Missing Author Signature'
                return true 
          
        } else if(this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)) {
          this.errorMessage = null
          return false
        } else if ((this.newImageFileDataWitness == '') && (!this.emailWitness || this.narcDescription == '')){
           this.errorMessage = 'Missing Signatures or Transfer Explanation'
          return true
        } else {
          console.log('NO ERROR')
          this.errorMessage = null
          return false
        }
      }
      if(this.statusSelect == 'distribution'){
        if(this.reportRunNumber == ''){
          this.errorMessage = 'No Distribution Location/Apparatus'
          return true
        } else if (this.transferMeds.length <= 0){
          this.errorMessage = 'No Narcs to Distribute/Transfer'
          return true
        } else if( this.newImageFileData == ''){
            this.errorMessage = 'Missing Author Signature'
                return true 
          
        } else if (this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)) {
          this.errorMessage = null
        // console.log('Distrb Role')
          return false
        } else if (this.newImageFileDataWitness == '' || this.newImageFileData == ''){
           this.errorMessage = 'Missing Signatures'
          return true
        } else {
          this.errorMessage = null
          return false
        }
      }
      if(this.statusSelect == 'restock'){
        if(this.reportRunNumber == ''){
          this.errorMessage = 'No Restock Location/Apparatus'
          return true
        } else if (this.transferMeds.length <= 0){
          this.errorMessage = 'No Narcs to Restock'
          return true
        } else if( this.newImageFileData == ''){
            this.errorMessage = 'Missing Author Signature'
                return true 
          
        } else if (this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)) {
          this.errorMessage = null
          return false
        } else if (this.newImageFileDataWitness == '' || this.newImageFileData == ''){
           this.errorMessage = 'Missing Signatures'
          return true
        } else {
          this.errorMessage = null
          return false
        }
      }
      if(this.statusSelect == 'use'){
        if(this.reportRunNumber == ''){
          this.errorMessage = 'No Report Number'
          return true
        } else if (this.transferMeds.length <= 0){
          this.errorMessage = 'No Narcs Tagged to Waste'
          return true
        } else if (this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)) {
          this.errorMessage = null
          return false
        } else if (this.newImageFileDataWitness == '' || this.newImageFileData == ''){
           this.errorMessage = 'Missing Signatures'
          return true
        } else {
          this.errorMessage = null
          return false
        }
      }
      if(this.statusSelect == 'quarantine'){
        if(this.reportRunNumber == ''){
          this.errorMessage = 'No Report Number'
          return true
        } else if (this.transferMeds.length <= 0){
          this.errorMessage = 'No Narcs Tagged to Waste'
          return true
        } else if (this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)) {
          this.errorMessage = null
          return false
        } else if (this.newImageFileDataWitness == '' || this.newImageFileData == ''){
           this.errorMessage = 'Missing Signatures'
          return true
        } else {
          this.errorMessage = null
          return false
        }
      }
      this.errorMessage = 'Unknown Error Type'
      return true
    },
    checkForDisabledNarcs(){
      if(this.statusSelect == '' || this.tagSelectValue == ''){
        return true
      }
      if(this.statusSelect == 'initial' || this.statusSelect == 'destroy'){
        if(this.witnessOrOffGoing == ''){
          return true
        }  
        return false
      }
      if(this.statusSelect == 'verification'){
        if(this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)) {
          return false
        } else if (this.witnessOrOffGoing == '' || this.witnessOrOffGoing == null){
          return true
        } else {
          return false
        }
      }
      if(this.statusSelect == 'distribution' || this.statusSelect == 'restock'){
        if(this.reportRunNumber == ''){
          return true
        } else if (this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)) {
          return false
        } else if (this.witnessOrOffGoing == ''){
          return true
        } else {
          return false
        }
      }
      if(this.statusSelect == 'use' || this.statusSelect == 'quarantine'){
        if(this.reportRunNumber == '' || this.witnessOrOffGoing == ''){
          return true
        } else {
          return false
        }
      }
      return true
    },
    verifiedNarcs(){
      if(this.bulkNarcs){
        return this.bulkNarcs
      }
      if(this.bulkDestroyNarcs){
        return this.bulkDestroyNarcs
      }
      if(this.recentNarcCards && this.statusSelect != 'restock'){
        var meds = this.narcCardFilter(this.recentNarcCards)
        return meds.filter(p => {
          return this.transferMeds.indexOf(p) == -1
        })
      }
      if(this.recentNarcStock && this.statusSelect == 'restock'){
        var meds = this.narcCardFilter(this.recentNarcStock)
        return meds.filter(p => {
          return this.transferMeds.indexOf(p) == -1
        })
      }
      return null
    },
    locationTypeOptions(){
      if(this.statusSelect == 'distribution' || this.statusSelect == 'quarantine'){
        return [{text: 'From Apparatus (Current)', value: 'apparatus'},{text: 'From Location (Current)', value: 'location'}]
      } return [{text: 'Target Apparatus', value: 'apparatus'},{text: 'Target Location', value: 'location'}]
    },
    moveLocationTypeOptions(){
      if(this.statusSelect == 'restock'){
        return [{text: 'From Apparatus', value: 'apparatus'}, {text: 'From Location', value: 'location'}]
      } return [{text: 'To Apparatus', value: 'apparatus'},{text: 'To Location', value: 'location'}]
    },

    status(){
        var status = [
            { text: "Narcotic Verification", value: "verification" },
            { text: "Patient Use", value: "use" }, // SUBS FROM CARDS
            { text: "Restock", value: "restock" }, // ADDS TO CARDS
            { text: "Distribution/Transfer", value: "distribution" } //SUBS FROM CARDS
          ]
        if(this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)){
        //  status.push({ text: "Destroyed", value: 'destroy'})  // SUBS FROM CARDS && DISABLES MED
          status.push({ text: "Move to Quarantine", value: 'quarantine'}) // SUBS FROM CARDS
        }
        if((this.userData.role == 'adminchief' || this.userData.role == 'admin') && this.bulkDestroyNarcs){
          status = []
          status.push({ text: "Destroy", value: 'destroy'})
          this.statusSelect = 'destroy' // ADDS TO CARDS
        }
        if((this.userData.role == 'adminchief' || this.userData.role == 'admin') && this.bulkNarcs){
          status = []
          status.push({ text: "Admin Distribution", value: 'initial'})
          this.statusSelect = 'initial' // ADDS TO CARDS
        }
        status.push({ text: "Please Select Type of Log", value: 0, disabled: true })
        return status
    },
    filteredApparatusData() {
      var filterUser = this.apparatusData.filter( p => {
      var index = p.apparatusMenuVisibility.indexOf(this.userData.role)
      if(index >= 0){
        return p
      }
    })
      var apparatus = filterUser.filter(p => {
        if (
          p.apparatusParent != "Reserve"
        ) {
          return p.apparatusMedicalType == "ALS"
        }
      });
    return apparatus
    },
    userTagListFiltered() {
      if(this.siteconfig.narcControlUserAnyone){
        var options = this.deptContactData
      } else if(this.statusSelect == 'use' && this.siteconfig.narcWasteUserAnyone){
      var options = this.deptContactData
      } else if(this.statusSelect == 'verification' && this.siteconfig.narcWitnessUserAnyone){
      var options = this.deptContactData
      } else {
      var options = this.deptContactData.filter(p => {
        var spec = p.deptSpecialties.indexOf("PIC");
        if (spec >= 0) {
          return p;
        }
      });
      }
      return _.orderBy(options, ['lastName'], ['asc'])
    },
    deptContactDataFiltered(){
      return _.orderBy(this.deptContactData, ['lastName'], ['asc'])
    },
    emsOpsTagListFiltered(){
        return this.deptContactData.filter(p => {
        return p.role == 'emsops' || p.role == 'adminchief'
        })
    },
    shiftDateCalc(){
              var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(this.currentDateSelected, "YYYY-MM-DD");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
    },
    shiftDateCalcClass(){
      var shift = this.shiftDateCalc
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shift)
      if(obj){
        return obj.color
      } return shift
    },
    formatedDateExport() {
      var timeString = this.currentDateSelected + "T" +  this.currentTimeSelected + ':' + moment().format('ss');
      return moment(timeString).toISOString();
      },
  },
  watch: {
    apparatusSelectValue(newData){
      if(newData){
      this.recentNarcCards = []
      this.tagSelectValue = newData.apparatusName
        this.getRecentNarcCards(this.tagSelectValue, 'verify')
      } else {
        this.tagSelectValue = ''
      }
    },
    apparatusSelectValueMobile(newData){
      if(newData){
          var apparatus = this.filteredApparatusData.filter( p => {
            return p.apparatusName == newData
          })
          if(apparatus[0]){
          this.recentNarcCards = []
          this.tagSelectValue = apparatus[0].apparatusName
            this.getRecentNarcCards(this.tagSelectValue, 'verify')
          } else {
            this.tagSelectValue = ''
          }
        } else {
            this.tagSelectValue = ''
     }
    },
    stationSelectValue(newData){
       if(newData){
      this.tagSelectValue = newData.stationName
         this.getRecentNarcCards(this.tagSelectValue, 'verify')
       } else {
            this.tagSelectValue = ''
     }
    },
    stationSelectValueMobile(newData){
          if(newData){
      var apparatus = this.stationsData.filter( p => {
        return p.stationName == newData
      })
      if(apparatus[0]){
      this.tagSelectValue = apparatus[0].stationName
       this.getRecentNarcCards(this.tagSelectValue, 'verify')
      } else {
            this.tagSelectValue = ''
     }
     } else {
            this.tagSelectValue = ''
     }
    },
    apparatusSelectValueRun(newData){
          if(newData){
      this.reportRunNumber = newData.apparatusName
      this.getRecentNarcCards(this.reportRunNumber, 'report')
        }  else {
            this.reportRunNumber = ''
     }
    },
    apparatusSelectValueMobileRun(newData){
      if(newData){
      var apparatus = this.filteredApparatusData.filter( p => {
        return p.apparatusName == newData
      })
      if(apparatus[0]){
      this.reportRunNumber = apparatus[0].apparatusName
         this.getRecentNarcCards(this.reportRunNumber, 'report')
      } else {
            this.reportRunNumber = ''
     }} else {
            this.reportRunNumber = ''
     }
    },
    stationSelectValueRun(newData){
      if(newData){
      this.reportRunNumber = newData.stationName
         this.getRecentNarcCards(this.reportRunNumber, 'report')
      } else {
            this.reportRunNumber = ''
     }
    },
    stationSelectValueMobileRun(newData){
      if(newData){
      var apparatus = this.stationsData.filter( p => {
        return p.stationName == newData
      })
      if(apparatus[0]){
      this.reportRunNumber = apparatus[0].stationName
         this.getRecentNarcCards(this.reportRunNumber, 'report')
      } else {
            this.reportRunNumber = ''
     }} else {
            this.reportRunNumber = ''
     }
    },
    imageResponseData(newData) {
      this.imageUUID(newData);
      this.imageFileSRC(newData);
      this.imageFileFIDData(newData);
    },
    imageFileFID(newData) {
      this.sendNewPicturePost();
    },
    imageResponseDataWitness(newData) {
      this.imageUUIDWitness(newData);
      this.imageFileSRCWitness(newData);
      this.imageFileFIDDataWitness(newData);
    },
    imageFileFIDWitness(newData) {
      this.postImage();
    },
  },
  methods: {
    stateCheckForAdditionalConsole(){
      var value = this.narcoticsAdditionalFieldsData
      if(this.statusSelect == 'verification' && this.siteconfig.narcoticsAdditionalFields){
        var array = this.siteconfig.narcoticsAdditionalFields
        var obj = {}
        for(var i in array){
          if(array[i].required){
            var valueid = value[array[i].id]
            if(!valueid || valueid == ''){
              obj[array[i].id] = true
            } else {
               obj[array[i].id] = false
            }
          }
        }
        this.checkForStateUpdate =  obj
      } this.checkForStateUpdate = {}
    },
    testNarcDate(narc, dateselected){
      if(narc && dateselected){
        if(moment(narc).isSameOrAfter(moment(dateselected, 'YYYY-MM-DD'), 'day') && moment(narc).diff(dateselected, 'days') < 30 && moment(narc).diff(dateselected, 'days') >= 0){
          return 'text-warning'
        }
        if(moment(narc).isSameOrAfter(moment(dateselected, 'YYYY-MM-DD'), 'day')){
          return 'text-success'
        }
        if(moment(narc).isBefore(moment(dateselected, 'YYYY-MM-DD'), 'day')){
          return 'text-danger'
        }
      } return 'text-secondary'
    },
    checkForVerifedPrev(){
        if(this.verifiedNarcs && this.verifiedNarcs.length > 0 && this.previousVerificationTest && this.previousVerificationTest.length > 0){
        var previous = this.previousVerificationTest
        var current = this.verifiedNarcs
        var count = []
        for(var i in previous){
          var index = current.indexOf(previous[i])
          if(index == -1){
            count.push(previous[i])
          }
        }
        // console.log(count)
        if(count && count.length > 0){
           this.removedNarcs = count
          return count
        } else {
          return false
        }

      } else {
        false
      }

    },    
    checkForVerifed(){
      if(this.verifiedNarcs && this.verifiedNarcs.length > 0 && this.previousVerificationTest && this.previousVerificationTest.length > 0){
        var previous = this.previousVerificationTest
        var current = this.verifiedNarcs
        var count = []
        for(var i in current){
          var index = previous.indexOf(current[i])
          if(index == -1){
            count.push(current[i])
          }
        }
        if(count.length > 0){
          this.addedNarcs = count
        }
        var diff = count.length
        var prevDiff = this.checkForVerifedPrev()
        if(prevDiff && prevDiff.length){
          diff = diff + prevDiff.length
        }
        this.checkForVerifedDet = diff

      } else {
        this.checkForVerifedDet = 0
      }

    },
    setUpUseNarcNote(data){
      var string = "Amount Used " + data.amountUsed + data.concentration + ', Amount Wasted ' + data.amountWasted + data.concentration
      this.narcDescription = string
    },
    result (result) {
    // console.log('detected: ', result);
    //  console.log(result.codeResult)
    //  console.log(result.codeResult.code)
      this.scannerOn = false
      if(result.codeResult && result.codeResult.code){
      var array = this.filteredVerified(this.verifiedNarcs)
      var find = array.find(p => {
        return Number.parseInt(p.controlNumber) == result.codeResult.code
      })
      if(find && find.id){
        this.scanMessage = find.controlNumber + ' Added'
        this.scanMessageVariant = ''
        this.transferMeds.push(find.id)
      } else {
        this.scanMessage = 'Last Scan Failed'
        this.scanMessageVariant = 'danger'
      }
      }
      this.$nextTick(function(){
        this.scannerOn = true
      })
    //  this.results = result
    //  this.readCodeTrue = !this.readCodeTrue
    //  this.scannerOn = !this.scannerOn
    },
    switchToCradleToGrave(){
      bus.$emit("switchEmitDailyNarcLegacy")
    },
    getStationFromTag(item, type){
      var station = 'error unknown'
      var parent = 'error unknown'
      if(type == 'apparatus' && item){
        var app = this.apparatusData.find(p => p.apparatusName == item)
        if(app && app.apparatusLocation){
          station = app.apparatusLocation
        }
        if(app && app.apparatusParent){
          parent = app.apparatusParent
        }
      }
      if(type == 'location' && item){
        station = item
        var loc = this.stationsData.find(p => p.stationName == item)
        if(loc && loc.stationParent){
          parent = loc.stationParent 
        } else {
          parent = item
        }
      } 
      return {station: station, parent: parent}
    },
    narcCardFilter(data){
      if(data){
        var meds = []
        var array = _.cloneDeep(data)
       // array = array.filter(p => p.narctrackDate)
        var cards = _.orderBy(array, ['narctrackDate', 'createdAt'], ['desc', 'desc'])
        var verify = cards.filter(p => {
          return p.narctrackEntryType == 'verification'
        })
        if(verify && verify[0] && verify[0].id){
        //  console.log(cards)
          var index = cards.map(p => p.id).indexOf(verify[0].id)
        //  console.log(index)
          if(index == 0){
            meds = verify[0].narctrackMeds
            if(this.siteconfig.narcoticsAdditionalFields){
              this.checkAdditionalFieldsValue(verify[0])
            }
           // console.log(verify[0])
            if(verify[0] && verify[0].narctrackMeds){
            this.previousVerificationTest = _.cloneDeep(verify[0].narctrackMeds)
            }
          } else {
            var hold = verify[0].narctrackMeds
            if(verify[0] && verify[0].narctrackMeds){
            this.previousVerificationTest = _.cloneDeep(verify[0].narctrackMeds)
            }
            var adjust = cards.splice(0, index)
            var adjustIds = []
            for(var i in adjust){
              var items = adjust[i].narctrackMeds
              var type = adjust[i].narctrackEntryType
              for(var x in items){
                if(type == 'use' || type == 'distribution' || type == 'quarantine' || type == 'destroy'){
                adjustIds.push(items[x])
                } else if (type == 'restock' || type == 'initial'){
                  hold.push(items[x])
                }
              }
              meds = hold.filter(p => {
                return adjustIds.indexOf(p) == -1
              })
            }
          }
        } else { // ONLY NEEDED FOR INITIAL STOCKS
          for(var c in cards){
            if(cards[c].narctrackEntryType == 'initial' || cards[c].narctrackEntryType == 'restock'){
              var addMeds = cards[c].narctrackMeds
              for(var m in addMeds){
                meds.push(addMeds[m])
              }
            }
          } 
         for(var c in cards){
            if(cards[c].narctrackEntryType == 'use' || cards[c].narctrackEntryType == 'distribution' || cards[c].narctrackEntryType == 'quarantine' || cards[c].narctrackEntryType == 'destroy'){
              var addMeds = cards[c].narctrackMeds
              for(var m in addMeds){
                var index = meds.indexOf(addMeds[m])
                if(index != -1){
                    meds.splice(index, 1)
                }
                
              }
            }
          } 
        }
        return meds
    }  // 	NDC0409230802
    },
    checkAdditionalFieldsValue(data){
      // console.log('Additional Data')
      // console.log(data)
      if(data && data.narctrackAdditionalFields){
      //  console.log(data.narctrackAdditionalFields)
        var array = data.narctrackAdditionalFields
        for(var a in array){
          this.narcoticsAdditionalFieldsData[array[a].id] = array[a].value
        }
      }
    },
    addToTransferMeds(id, narc){
      if(this.statusSelect == 'use'){
        if(narc.vialCon && narc.vialAmount){
        this.textUseObject.concentration = narc.vialCon
        this.narcUseCompare = {vialAmount: narc.vialAmount, vialCon: narc.vialCon}
      } else {
        this.narcUseCompare = null
      }
      } else {
        this.narcUseCompare = null
      }
      console.log(narc)
      console.log(this.statusSelect)
      var index = this.transferMeds.indexOf(id)
      if(index < 0){
        this.transferMeds.push(id)
      }
    },
    removeFromTransferMeds(id){
      var index = this.transferMeds.indexOf(id)
      if(index > -1){
        this.transferMeds.splice(index, 1)
      }
    },
    getRecentNarcCards(data, type){
       axios({
        method: 'get',
        url: '/api/v1/content/dailynarctrack/tagid/' + data,
        headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
      //  console.log(response.data)
        if(type == 'verify'){

        this.recentNarcCards = response.data
        this.checkForVerifed()
        }
        if(type == 'report'){
          this.recentNarcStock = response.data
        }
      })
    },
    getExpirationDate(date){
      if(date){
        return moment(date).format('MM/DD/YY')
      } return 'Unknown'
    },
    getTotalCounts(ids){
      var narcs = ids.map(item => {
          var med = this.medsNarctrack.find(p => p.id == item)
          if(med){
            return med
          } else {
            return {id: item, medication: 'Unknown'}
          }
      })
      if(narcs){
      var parents = [...new Set(narcs.map(p => p.medication))]
      return parents.map(item => {
          var obj = {}
          obj.name = item
          obj.count = narcs.filter(p => p.medication == item).length
          return obj
      })
      } return []
    },
    filteredVerified(ids, boo, search){
     // console.log(ids)
      var narcs = ids.map(item => {
          var med = this.medsNarctrack.find(p => p.id == item)
          if(med){
            return med
          } else {
          //  console.log('NO Medications Found')
         //   console.log(this.medsNarctrack)
            return {id: item, medication: 'Unknown', controlNumber: 'Unknown', expDate: null, ndcNumber: 'Unknown', lotNumber: 'Unknown' }
          }
      })
      var array = _.orderBy(narcs, ['medication', 'controlNumber'], ['asc', 'asc'])
    if(this.userData.role == 'adminchief' || this.userData.role == 'emsops' || this.userData.role == 'admin' || (this.userData.secondaryAdmin && this.userData.secondaryAdmin.indexOf('adminNarcs') > -1)){
        if(search != ''){
         // this.currentPage = 1
        return array.filter(p => {
          return p.controlNumber.match(search)
        })
        } else {
          var current = (this.currentPage - 1) * 10
          var next = current + 10
          return array.splice(current, 10)
        }
      } else 
      if (!boo){
        return array
      } else {
        return array.filter(p => {
          return Number.parseInt(p.controlNumber) == search
        })
      }
    },
    checkUserPosts(data){
      if(data){
        var narcs = _.orderBy(data.filter(p => p.contentType == 'dailynarctrack' && p.narctrackWitnessId == this.userData.id), ['narctrackDate'], ['desc'])
        var narctest = data.filter(p => p.contentType == 'dailynarctrack')
        if(narcs[0]){
          var app = this.apparatusData.find(p => p.apparatusName == narcs[0].narctrackTagId)
          if(app){
            this.apparatusSelectValue = app
            this.apparatusSelectValueMobile = app.apparatusName
          }
        }
      }
    },
    checkPreviousCard(data){
      var prev = this.previousMedsLogged.find(p => p.med == data.med)
      if(prev && this.statusSelect == 'Daily Narc Transfer'){
        var hold = []
        var keys = Object.keys(prev)
        for(var k in keys){
          if(prev[keys[k]] && prev[keys[k]] != data[keys[k]]){
            hold.push('Previous ' + keys[k].toUpperCase() + ' was ' + prev[keys[k]])
          } 
        }
        if(hold.length > 0){
       //   console.log(hold)
          return hold
        } return false
      }
      return false
    },
    updateConnectionStatus() {
        this.onlineStatus = window.navigator.onLine
    },
    fixAttachURI(data){
    var w = window.open('about:blank');

    setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
        w.document.body.appendChild(w.document.createElement('iframe'))
            .src = data;
    }, 0);

    },
    formFileBufferType(e){
     var file = e.target.files || e.dataTransfer.files; 
     var file = file[0]
     var type = file.type
     var typeSet = type.split('/')
     if(typeSet[0] == 'image'){
        this.previewSettings = 'image'
     } else {
        this.previewSettings = 'link'
     }
      var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var fileData = reader.result
          var fileData = fileData.split('base64,')
          vm.fileForAttach = fileData[1]
          vm.$nextTick(function(){
              vm.fileAttached = true
          })
    
       };
         reader.onerror = function (error) {
        console.log('Error: ', error);
         };
    },
    addModalOpen(){
      document.body.classList.add('modal-open')
    },
    clearWitnessData(){
      this.previewImageFileDataWitness = ''
      this.newImageFileDataWitness = ''
    },
    toggleUserSign(event) {
      this.userSign = !this.userSign;
    },
    toggleWitnessSign(event) {
      this.witnessSign = !this.witnessSign;
    },
    signatureMethodUser(data) {
      this.toggleUserSign();
      this.showModalNarc = true;
      this.processFileUriForUpload(data);
    },
    signatureMethodWitness(data) {
      this.toggleWitnessSign();
      this.showModalNarc = true;
      this.processFileUriForUploadWitness(data);
    },
    signatureClose() {
      this.userSign = false;
      this.witnessSign = false;
      this.showModalNarc = true;
    },
    /* image */
    imageUUID(newData) {
      this.newDataUUID = newData.uuid[0].value;
    },
    imageFileSRC(newData) {
      this.imageFileName = newData.filename[0].value;
    },
    imageFileFIDData(newData) {
      this.imageFileFID = 0; /* newData.fid[0].value; */
    },
    imageUUIDWitness(newData) {
      this.newDataUUIDWitness = newData.uuid[0].value;
    },
    imageFileSRCWitness(newData) {
      this.imageFileNameWitness = newData.filename[0].value;
    },
    imageFileFIDDataWitness(newData) {
      this.imageFileFIDWitness = 0; /* newData.fid[0].value; */
    },
    processFileUriForUpload(file) {
      var dataSplice = file;
      this.previewImageFileData = file;
      var newplacedData = dataSplice.split(",");
      this.newImageFileData = newplacedData[1];
    },
    processFileUriForUploadWitness(file) {
      var dataSplice = file;
      this.previewImageFileDataWitness = file;
      var newplacedData = dataSplice.split(",");
      this.newImageFileDataWitness = newplacedData[1];
      /* Image End */
    },
    showModalswitchEmitNarc() {
      this.apparatusSelectValue = "";
      this.field_medication_tracking = [];
      this.shiftSelect = "";
      this.checkNumber = [];
      bus.$emit("switchEmitDailyNarc");
    },
    checkWitnessValue() {
      if (this.witnessOrOffGoing.id == undefined) {
        return this.witnessOrOffGoing;
      } else {
        return (
          this.witnessOrOffGoing.firstName +
          " " +
          this.witnessOrOffGoing.lastName
        );
      }
    },
    showModalConfirmationSwitch(event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet(event) {
      this.disableSubmit = false
      this.showModalConfirmation = !this.showModalConfirmation;
       bus.$emit("refreshUserPosts")
      this.showModalswitchEmitNarc();
    },
    submitOfflineNarc() {
      this.disableSubmit = true
      axios({
        method: "post",
        url: "/api/v1/content/narctrack",
        data: {
          title:
            moment().format("MM/DD/YYYY-HH:mm") +
            " | " +
            this.apparatusSelectValueName +
            " | " +
            this.statusSelect +
            " | " +
            this.userData.lastName,
          narctrackEntryType: this.statusSelect,
          narctrackDate: this.formatedDateExport,
          narctrackRunNumber: this.reportRunNumber,
          narctrackApparatusId: this.apparatusSelectValueName,
          narctrackMeds: this.field_medication_tracking,
          narctrackItems: this.field_item_tracking,
          narctrackShift: this.shiftDateCalc,
          narctrackStation: this.stationSelectValue.stationName,
          narctrackStationParent: this.stationSelectValue.stationParent,
          narctrackAuthor:
            this.userData.firstName + " " + this.userData.lastName,
          narctrackAuthorId: this.userData.id,
          narctrackAuthorSignature: this.newImageFileData,
          narctrackWitness: this.checkWitnessValue(),
          narctrackWitnessId: this.witnessOrOffGoing.id,
          narctrackWitnessSignature: this.newImageFileDataWitness,
          narctrackEntryDetails: this.narcDescription,
          narctrackEmailWitness: true,
          narctrackOfflineAttach: this.fileForAttach,
        },
        headers: {
          'Authorization': this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.postData = response.data;
      });
      this.showModalConfirmation = !this.showModalConfirmation;
      bus.$emit("refreshUserPosts");
    },
    submitNarcLogNew(){
      if(!this.disableSubmit){
      this.disableSubmit = true
      var type = this.statusSelect
      if(type == 'verification' || type == 'initial' || type == 'destroy'){
        var medsToPost = this.verifiedNarcs
      } else {
        var medsToPost = this.transferMeds
      }
      medsToPost = [... new Set(medsToPost.map(p => p))]
      var data = { 
          title: moment().format("MM/DD/YYYY-HH:mm") + " | " + this.tagSelectValue + " | " + type + " | " + this.userData.lastName,
          narctrackEntryType: type,
          narctrackDate: this.formatedDateExport,
          narctrackRunNumber: this.reportRunNumber,
          narctrackTagType: this.locationType,
          narctrackTagId: this.tagSelectValue,
          narctrackMeds: medsToPost,
          narctrackShift: this.shiftDateCalc,
          narctrackStation: this.getStationFromTag(this.tagSelectValue, this.locationType).station,
          narctrackStationParent: this.getStationFromTag(this.tagSelectValue, this.locationType).parent,
          narctrackAuthor: this.userData.firstName + " " + this.userData.lastName,
          narctrackAuthorId: this.userData.id,
          narctrackAuthorSignature: this.newImageFileData,
          narctrackAuthorSignatureBoo: this.newImageFileData ? true : false,
          narctrackWitness: this.checkWitnessValue(),
          narctrackWitnessId: this.witnessOrOffGoing.id,
          narctrackWitnessSignature: this.newImageFileDataWitness,
          narctrackWitnessSignatureBoo: this.newImageFileDataWitness ? true : false,
          narctrackEntryDetails: this.narcDescription,
          narctrackEmailWitness: this.emailWitness
      }
      if(type == 'verification' && this.siteconfig.narcoticsAdditionalFields){
          var addDataArray = []
          var loopArray = this.siteconfig.narcoticsAdditionalFields
          for(var i in loopArray){
              var obj = {id: loopArray[i].id, label: loopArray[i].label}
              obj['value'] = this.narcoticsAdditionalFieldsData[loopArray[i].id]
              addDataArray.push(obj)
          }
          data['narctrackAdditionalFields'] = addDataArray
      }
      if(type == 'restock'){
            var subArray = []
            subArray.push(data)
            var hold = _.cloneDeep(data)
            hold['narctrackEntryType'] = 'distribution'
            hold['narctrackRunNumber'] = this.tagSelectValue
            hold['narctrackTagId'] = this.reportRunNumber
            hold['narctrackStation'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).station,
            hold['narctrackStationParent'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).parent,
            subArray.push(hold)
            axios({
            method: "post",
            url: "/api/v1/content/dailynarctrack/restock",
            data: subArray,
            headers: {
              'Authorization': this.getSessionData,
              "Content-Type": "application/json"
            }
          }).then(response => {
              var arrayHold = response.data
              if(arrayHold && arrayHold.length == 2){
                this.postData = arrayHold.find(p => p.narctrackEntryType == 'restock');
                this.showModalConfirmation = !this.showModalConfirmation 
                bus.$emit('updateMedicationsTracking')
                this.$nextTick(function(){
                  this.addModalOpen
               })
              }
          })
        } else if(type == 'distribution'){
            var subArray = []
            subArray.push(data)
            var hold = _.cloneDeep(data)
            hold['narctrackEntryType'] = 'restock'
            hold['narctrackRunNumber'] = this.tagSelectValue
            hold['narctrackTagId'] = this.reportRunNumber
            hold['narctrackStation'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).station,
            hold['narctrackStationParent'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).parent,
            subArray.push(hold)
            axios({
            method: "post",
            url: "/api/v1/content/dailynarctrack/restock",
            data: subArray,
            headers: {
              'Authorization': this.getSessionData,
              "Content-Type": "application/json"
            }
          }).then(response => {
              var arrayHold = response.data
              if(arrayHold && arrayHold.length == 2){
                this.postData = arrayHold.find(p => p.narctrackEntryType == 'distribution');
                this.showModalConfirmation = !this.showModalConfirmation 
                bus.$emit('updateMedicationsTracking')
                this.$nextTick(function(){
                  this.addModalOpen
               })
              }
          })
      } else {
      axios({
        method: "post",
        url: "/api/v1/content/dailynarctrack",
        data: data,
        headers: {
          'Authorization': this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.postData = response.data;
        this.showModalConfirmation = !this.showModalConfirmation 
        bus.$emit('updateMedicationsTracking')
        this.$nextTick(function(){
          this.addModalOpen
      })
      })
      }
    }
    },
    submitNarcLog(){
      if(!this.disableSubmit){
      this.disableSubmit = true
      var type = this.statusSelect
      if(type == 'verification' || type == 'initial'){
        var medsToPost = this.verifiedNarcs
      } else {
        var medsToPost = this.transferMeds
      }
      medsToPost = [... new Set(medsToPost.map(p => p))]
      var data = { 
          title: moment().format("MM/DD/YYYY-HH:mm") + " | " + this.tagSelectValue + " | " + type + " | " + this.userData.lastName,
          narctrackEntryType: type,
          narctrackDate: this.formatedDateExport,
          narctrackRunNumber: this.reportRunNumber,
          narctrackTagType: this.locationType,
          narctrackTagId: this.tagSelectValue,
          narctrackMeds: medsToPost,
          narctrackShift: this.shiftDateCalc,
          narctrackStation: this.getStationFromTag(this.tagSelectValue, this.locationType).station,
          narctrackStationParent: this.getStationFromTag(this.tagSelectValue, this.locationType).parent,
          narctrackAuthor: this.userData.firstName + " " + this.userData.lastName,
          narctrackAuthorId: this.userData.id,
          narctrackAuthorSignature: this.newImageFileData,
          narctrackAuthorSignatureBoo: this.newImageFileData ? true : false,
          narctrackWitness: this.checkWitnessValue(),
          narctrackWitnessId: this.witnessOrOffGoing.id,
          narctrackWitnessSignature: this.newImageFileDataWitness,
          narctrackWitnessSignatureBoo: this.newImageFileDataWitness ? true : false,
          narctrackEntryDetails: this.narcDescription,
          narctrackEmailWitness: this.emailWitness
      }
      if(type == 'restock'){
            var hold = _.cloneDeep(data)
            hold['narctrackEntryType'] = 'distribution'
            hold['narctrackRunNumber'] = this.tagSelectValue
            hold['narctrackTagId'] = this.reportRunNumber
            hold['narctrackStation'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).station,
            hold['narctrackStationParent'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).parent,
            axios({
            method: "post",
            url: "/api/v1/content/dailynarctrack",
            data: hold,
            headers: {
              'Authorization': this.getSessionData,
              "Content-Type": "application/json"
            }
          }).then(response => {
         //   console.log(response.data)
          })
        }
        if(type == 'distribution'){
            var hold = _.cloneDeep(data)
            hold['narctrackEntryType'] = 'restock'
            hold['narctrackRunNumber'] = this.tagSelectValue
            hold['narctrackTagId'] = this.reportRunNumber
            hold['narctrackStation'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).station,
            hold['narctrackStationParent'] = this.getStationFromTag(this.reportRunNumber, this.moveLocationType).parent,
            axios({
            method: "post",
            url: "/api/v1/content/dailynarctrack",
            data: hold,
            headers: {
              'Authorization': this.getSessionData,
              "Content-Type": "application/json"
            }
          }).then(response => {
           // console.log(response.data)
          })
      }
      axios({
        method: "post",
        url: "/api/v1/content/dailynarctrack",
        data: data,
        headers: {
          'Authorization': this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.postData = response.data;
        this.showModalConfirmation = !this.showModalConfirmation 
        bus.$emit('updateMedicationsTracking')
        this.$nextTick(function(){
          this.addModalOpen
      })
      })

    }
    }
    
  }
};
</script>
<style :src="'./customcss/vue-multiselect.min.css'" >

</style>
<style>
.cursorClass {
  cursor: pointer;
}
.fullScreen {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: white;
}
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.errorClass {
  border: 1px solid red;
  border-radius: 5px;
}
.modal-body {
  padding: 0;
}
.A-shift {
  background: #fff;
  color: #e2070d;
}
.B-shift {
  background: #fff;
  color: #201b9f;
}
.C-shift {
  background: #fff;
  color: #0fb906;
}
.Red-shift{
  background: #fff;
  color: #e2070d;
}
.Yellow-shift{
  background: #ffffff;
  color: #ffc107;
}
.Blue-shift{
    background: #fff;
  color: #201B9F;
}
.Green-shift{
    background: #fff;
  color: #0FB906;
}
.tab-content.card-body {
  padding: 0;
}
.selectStatusInput {
  margin-top: 10px;
  margin-bottom: 10px;
}
img {
  width: 150px;
  height: auto;
}
img.imageGif {
  width: 100px;
  height: auto;
}
img.imgNarcIcon {
  width: 24px;
  height: 24px;
}
#tablePassDown.container {
  padding: 0;
}
#tablePassDown .card-body {
  padding: 0;
}
.numberInput {
  width: 30px;
}
.medtableCard.card-body {
  padding: 0;
}
.medContainer {
  margin-top: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.medContainer h5 {
  margin-top: 5px;
}
.btn {
  margin-top: 5px;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.dateSeal {
  width: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
  padding: 5px;
}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
button {
  border-style: none;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  font-weight: bold;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.timeMobile {
  display: none;
}
.mobileView {
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk {
    display: none;
  }
  .timeMobile {
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
  .mobileView {
    display: block;
  }
  .desktopView {
    display: none;
  }
}
@media screen and (max-width: 460px) {
  .col.inputColumn {
    padding: 0;
  }
}
</style>
