<template>
<div id="homelanding">
<b-container fluid class="pl-0">
            <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Month Controls:</b-navbar-brand>
        <b-btn @click="goPrev" variant="info">Previous Month</b-btn>
        <b-btn @click="goNext" variant="info" class="ml-2">Next Month</b-btn>
         <b-navbar-brand class="bootstrapTable ml-5" v-if="false && userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">View Users:</b-navbar-brand>
        <div style="width: 250px" class="ml-auto" v-if="false && userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
        <multiselect
        v-if="false"
        v-model="userSelected" 
        :options="deptContactData" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
      </b-nav-form>
      
    </b-navbar>
  <b-row>
    <b-col>
<b-card no-body>
  <calendar id="scheduleLeaveCalendar" class="calendarClass" :all-events="userRosterProfileDaysWithMods" :daydisplayboo="true" :calendarHeader="calendarHeader" :calendarType="'payroll'" :displayWeekNumber="false" :calendarSmall="true" v-if="showCalendar"></calendar>
    </b-card>
    </b-col>
  </b-row>
</b-container>
</div>
</template>

<script>
import Calendar from '../../calendarFull/Calendar.vue'
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import modsUser from './modsUser.vue'
import bankUser from './bankUser.vue'
import Multiselect from "vue-multiselect";
export default {
  props: ['payrollCodesData', 'payrollPermsData', 'calendarType', 'calendarHeader'],
components: {
  'calendar' : Calendar,
  modsUser,
  bankUser,
  'multiselect' : Multiselect,

},
data() {
    return {
  slide: 0,
  showCalendar: true,
  rosterReload: true,
  userSelected: null,
  events: [],
  payrollCodesSelectedFilter: [],
  userRosterProfileDaysWithMods: [],
  userMonthViewModsOrig: [],
  userRosterProfileDays: [],
  userMonthViewMods: [],
  currentMonth: moment().startOf('month'),
  currentView: {},
  recoReportsData: [],
  previousDataReco: '',

}
},
created () {
  //this.getUserProfile()
  console.log('SCHEDULE VIEW CREATED')
  bus.$on('CalendarCurrentDisplayMonthLeave', this.currentDateSelect)
  bus.$on('newPayrollModification', this.getNewModData)
  bus.$on('CalendarCurrentMonthObjectLeave', this.SetCurrentMonth)
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
  ]),
  recoReportsDataSorted(){
          var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
          if(array[0]){
          return array
          } else {
            return null
          }
        },

},
mounted(){

},
methods: {
  SetCurrentMonth(data){
   // console.log('SCHEDULED LEAVE METHOD')
   // console.log(data)
    this.currentMonth = data
  },
  goPrev () {
      let payload = moment(this.currentMonth).subtract(1, 'months').startOf('month');
      bus.$emit('CHANGE_MONTH_BUS', {date: payload, view: this.calendarType });
  },
  goNext () {
      let payload = moment(this.currentMonth).add(1, 'months').startOf('month');
      bus.$emit('CHANGE_MONTH_BUS', {date: payload, view: this.calendarType });
  },
  updateUserEventList(){
    var array = _.cloneDeep(this.userRosterProfileDays)
    this.userMonthViewModsOrig = this.userMonthViewMods
    var unassigned = this.userMonthViewMods.filter(p => {
      return p.payrollModApproved != false
    })
     if(this.siteconfig.payroll.customPerms && this.payrollPermsData){
       var obj = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
      // console.log('PAYROLL PERMS')
       if(obj && obj.payrollManageUsers && obj.payrollManageUsers.length > 0){
         var permsView = unassigned.filter(p => {
                var index = obj.payrollManageUsers.indexOf(p.payrollModTargetUserId)
                if(index >= 0 || p.payrollModTargetUserId == this.userData.id){
                  return p
                }
         })
       } else {
            var permsView = unassigned
       }
     } else {
      var permsView = unassigned
     }
    var mods = permsView.map(item => item = this.setUpModCalendar(item))
    for(var i in mods){

      array.push(mods[i])
    }
    this.userRosterProfileDaysWithMods = array
  },
  setUpModCalendar(item){
          var holdcolor = 'default'
          
          var obj = {}
          var user = this.userSelected ? this.userSelected.id : this.userData.id
          if(item.payrollModExchangeUserId == user){
            var payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'EXON')
            var payrollCodeFind = payrollCode.payrollCode
            var modifier = ''
            if(item.payrollModExchangeApproved && item.payrollModApproved){
              modifier = 'Approved'
            } else if(item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting Approval'
            } else if(!item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting User Approval'
            } else {
              modifier = ''
            }
          } else if (item.payrollModExchangeUserId){
             var payrollCode = this.payrollCodesData.find(p => p.id == item.payrollModCode)
            var payrollCodeFind = payrollCode.payrollCode
            var modifier = ''
            if(item.payrollModExchangeApproved && item.payrollModApproved){
              modifier = 'Approved'
            } else if(item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting Approval'
            } else if(!item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting User Approval'
            } else {
              modifier = ''
            }
          }
          else {
            var payrollCode = this.payrollCodesData.find(p => p.id == item.payrollModCode)
            if(!payrollCode){
              console.log(item)
            }
            var payrollCodeFind = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'MISSING'
            var modifier = item.payrollModTargetUserId ? this.getUserLastNameId(item.payrollModTargetUserId) : item.payrollModNote ? item.payrollModNote : 'Missing Users?'
            var modifierplus = item.payrollModApproved ? 'Approved' : item.payrollModApproved == null ? 'Awaiting Approval' : 'Denied'
          }
          obj['id'] = item.id,
          obj['title'] = item.payrollModType
          obj['description'] = [{eventDate: item.payrollModDatetime, 
                                 id: item.id,
                                 eventDescription: item.payrollModType, 
                                 eventTitleDescription: payrollCodeFind + ' (' + item.payrollModHours + 'hrs) ' + modifier,
                                 currentStatus: modifierplus}]
          obj['color'] = payrollCode && payrollCode.payrollCodeColor ? payrollCode.payrollCodeColor : 'danger'
          obj['date'] = moment(item.payrollModDatetime).format('YYYY-MM-DD')
          obj['startTime'] = item.payrollModDatetime
          obj['endTime'] = moment(item.payrollModDatetime).add(item.payrollModHours, 'hours').toISOString()
          obj['payrollMod'] = item
          return obj
  },
  getUserLastNameId(id){
    var obj = this.deptContactData.find(p => p.id == id)
    if(obj){
      return  obj.firstName.charAt(0) + '_' + obj.lastName + ' (#' + obj.employeeId + ')'
    } return 'Unknown User'
  },
  currentDateSelect(data){
    if(this.currentView.start != data.start || this.currentView.end != data.end){
    //  console.log(data.start + ' ' + data.end)
      this.currentView = data
     this.getPayrollModExchange()
    }
  },
    getNewModData(){ 
    this.getPayrollModExchange()
   // this.getPayrollModByDateByUser()
  },
  getPayrollModExchange(){
      if(this.calendarType == 'Overtime Calendar'){
        var string = 'overtime'
      } else {
        var string = 'scheduledleave'
      }
    //  this.rosterReload = false
      var start = moment(this.currentView.start, "YYYY-MM-DD").toISOString()
      var end = moment(this.currentView.end, "YYYY-MM-DD").toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/' + string + '/' + start + '/' + end + '/',
     headers: {
           'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
     var array = response.data

      var test = this.userMonthViewMods.map(p => p.id)
     for(var i in array){
       if(test.indexOf(array[i].id) == -1 ){
       this.userMonthViewMods.push(array[i])
       }
     }
     this.updateUserEventList()
    // this.rosterReload = true
   })

    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
