<template>
<div id="homelanding">
<b-container fluid class="px-0">
  <b-form-input v-model="uploadedJson" @input="fixData()" />
<b-card v-if="inventorySheetData">
                  <b-pagination
                class="mb-0"
      v-model="currentPage"
      :total-rows="inventorySheetData.length"
      :per-page="10"
      aria-controls="my-table"
    ></b-pagination>
    <b-table  v-if="false" :items="inventoryItemsDataFilter" :fields="['itemName', 'vendorId', 'add']" :current-page="currentPage" :per-page="10">
        <template slot="vendorId" slot-scope="data">
      <b-form-select v-model="data.item.vendorId" :options="getInventoryItemsList" />
    </template>
    <template slot="add" slot-scope="data">
      <b-btn  @click="submitSupplyItem(data.item)" size="sm">Add</b-btn>
      </template>
  </b-table>
<b-table v-if="false" :items="inventorySheetData" :fields="['importName', 'itemVendor', 'supply', 'importNameBoo', 'add']" :current-page="currentPage" :per-page="10">
  <template slot="supply" slot-scope="data">
    {{ data.item.supply.id }}
      <b-form-select v-if="false" v-model="data.item.supply.id" :options="filterListOfSupplies" />
    </template>
        <template slot="importNameBoo" slot-scope="data">
      <b-form-checkbox v-model="data.item.importNameBoo" :check-value="true" :uncheck-value="false" />
      </template>
    <template slot="add" slot-scope="data">
      <b-btn :disabled="data.item.supply.id == null" @click="submitSupply(data.item)" size="sm">Add</b-btn>
      <b-btn @click="submitSupplyNew(data.item)" size="sm" variant="primary">New</b-btn>
      </template>
  </b-table>
</b-card>
</b-container>
<download-excel v-if="false && fixDataExcel.length > 0"
    :name="'Missing Items From List'"
    :data="fixDataExcel">
    <b-btn variant="primary">Download Excel</b-btn>
</download-excel>
<b-btn @click="submitBulkInventory">Submit</b-btn>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,
      'download-excel' : JsonExcel

},
props: ['inventoryItemsData'],
data() {
    return {
      showAdmin: false,
      showModal: false,
      currentPage: 1,
      searchData: '',
      filterSupplySearch: '',
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      inventoryTemplateData: [],
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
}
},
created () {

},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  inventoryItemsDataReturn(){
    return this.inventoryItemsData.filter(p => {
      return p.itemName.toLowerCase().includes(this.searchData.toLowerCase())
    })
 
  },
  filterListOfSupplies(){
    var array = this.inventoryItemsData.filter(p => {
      return !p.itemVendor
    })
    var sort = _.orderBy(array, ['itemName'], ['asc'])
       this.fixData()
    return sort.map(item => {
      var obj = {text: item.itemName, value: item.id, name: item.itemName, qty: item.itemDefault}
      return obj
    })
  },
  inventoryItemsDataFilter(){
    var array = this.inventoryItemsData.filter(p => p.itemVendor == null)
    var hold = array.map(item => {
            var obj = item
            obj['vendorId'] = ''
            return obj
    })
    return _.orderBy(hold, ['itemName'], ['asc'])
  },
  getInventoryItemsList(){
    var array =  _.cloneDeep(this.inventorySheetData)
    var hold = array.map(item => {
      var obj = {}
      return {text: item.importName, value: item.id}
    })
    return _.orderBy(hold, ['text'], ['asc'])
  },
  fixDataExcel(){
    if(this.uploadedJson){
    var data = JSON.parse(this.uploadedJson)
    return data.filter(p => {
      return  this.getItemId(p).id == null
    })
    } return []
  },
},
mounted(){

},
watch:{

},
methods: {
    fixData(){
    var data = JSON.parse(this.uploadedJson)
    var hold = data.map(item => {
        var obj = {}
        obj['item_name'] = item.item_name
        obj['item_default'] = item.item_default ? item.item_default : 1,
        obj['item_active'] = true
        obj['item_vendor'] = []
        if(item.vendor_0){
          var vend = {}
          vend['name'] = item.vendor_0
          vend['price'] = item.price_0
          vend['vendId'] = item.vendId_0
          vend['description'] = item.description_0
          vend['saleType'] = item.saleType_0
          vend['saleQty'] = item.saleQty_0 ? item.saleQty_0 : 1
          obj.item_vendor.push(vend)
        }
        if(item.vendor_1){
          var vend = {}
          vend['name'] = item.vendor_1
          vend['price'] = item.price_1
          vend['vendId'] = item.vendId_1
          vend['description'] = item.description_1
          vend['saleType'] = item.saleType_1
          vend['saleQty'] = item.saleQty_1 ? item.saleQty_1 : 1
          obj.item_vendor.push(vend)
        }
        return obj
        })
    console.log(hold)
    this.inventorySheetData = hold
  },
    fixDataBack2(){
    var data = JSON.parse(this.uploadedJson)
    var hold = data.map(item => {
        var obj = {}
        obj['id'] = parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
        obj['importName'] = item.description
        obj['itemVendor'] = []
        if(item.vendor1 && item.vendor1 != 'NULL' && item.vendor1 != ''){
          var vend = {}
          vend['name'] = item.vendor1
          vend['price'] = item.vendor1_price
          vend['vendId'] = item.vendor1_id
          vend['description'] = item.vendor_description
          vend['saleType'] = item.vendor1_pk
          vend['saleQty'] = item.vendor1_per ? item.vendor1_per : 1
          obj.itemVendor.push(vend)
        }
        if(item.vendor2 && item.vendor2 != 'NULL' && item.vendor2 != ''){
          var vend = {}
          vend['name'] = item.vendor2
          vend['price'] = item.vendor2_price
          vend['description'] = item.vendor_description
          vend['vendId'] = item.vendor2_id
          vend['saleType'] = item.vendor2_pk
          vend['saleQty'] = item.vendor2_per ? item.vendor2_per : 1
          obj.itemVendor.push(vend)
        }
        return obj
        })
    this.inventorySheetData = hold
  },
  fixDataback(){
    var data = JSON.parse(this.uploadedJson)
    var hold = data.map(item => {
        var obj = {}
        obj['importName'] = item.description
        obj['itemVendor'] = []
        if(item.vendor1 && item.vendor1 != 'NULL' && item.vendor1 != ''){
          var vend = {}
          vend['name'] = item.vendor1
          vend['price'] = item.vendor1_price
          vend['vendId'] = item.vendor1_id
          vend['description'] = item.vendor_description
          vend['saleType'] = item.vendor1_pk
          vend['saleQty'] = item.vendor1_per ? item.vendor1_per : 1
          obj.itemVendor.push(vend)
        }
        if(item.vendor2 && item.vendor2 != 'NULL' && item.vendor2 != ''){
          var vend = {}
          vend['name'] = item.vendor2
          vend['price'] = item.vendor2_price
          vend['description'] = item.vendor_description
          vend['vendId'] = item.vendor2_id
          vend['saleType'] = item.vendor2_pk
          vend['saleQty'] = item.vendor2_per ? item.vendor2_per : 1
          obj.itemVendor.push(vend)
        }
        obj['supply'] = this.getItemId(item)
        obj['importNameBoo'] = true
        return obj
        })
    console.log(hold)
    this.inventorySheetData = hold.filter(p => {
      return p.supply.id != null
    })
  },
  getItemId(name){
    var item = this.inventoryItemsData.find(p => p.itemName.toLowerCase() === name.description.toLowerCase())
    if(name.description2){
    //  console.log(name.description2)
    var item2 = this.inventoryItemsData.find(p => p.itemName.toLowerCase() === name.description2.toString().toLowerCase())
    } else {
      var item2 = null
    }
    if(item || item2){
      if(item){
        return item
      } else if (item2){
        return item2
        }  return {id: null}
    } else {
      return {id: null}
    }
    },
    getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : "Unknown"
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
    modalHideEvent(){
      this.showModal = false
    },
    submitSupplyNew(data){
      var obj = {
               itemVendor: data.itemVendor, 
               itemName: data.importName,
               itemActive: true,
      }
      axios({ 
     method: 'post',
     url: '/api/v1/taxonomy/inventoryitem/',
     data: obj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')
    this.$nextTick(function(){
          this.fixData()
    })
   })

    },
     submitSupplyItem(data){
       var vendor = this.inventorySheetData.find(p => p.id == data.vendorId)
       if(vendor){
      axios({ 
     method: 'patch',
     url: '/api/v1/taxonomy/inventoryitem/' + data.id,
     data: { itemVendor: vendor.itemVendor },
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')
    this.$nextTick(function(){
          this.fixData()
    })
   })
       } else {
         console.log('FAIL')
       }

    },
    submitSupply(data){
      var obj = {
               itemVendor: data.itemVendor 
      }
      if(data.importNameBoo){
           obj['itemName'] = data.importName
      }
      axios({ 
     method: 'patch',
     url: '/api/v1/taxonomy/inventoryitem/' + data.supply.id,
     data: obj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')
    this.$nextTick(function(){
          this.fixData()
    })
   })

    },
    submitBulkInventory(){
    var array = this.inventorySheetData
    axios({ 
     method: 'post',
     url: '/api/v1/taxonomy/inventoryitem/bulk',
     data: array,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)

   })
    
    },
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
