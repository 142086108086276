<template>
<div id="homelanding">
  <b-navbar type="dark" variant="dark">
      <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable">Supply Items:</b-navbar-brand>
        <b-form-input v-model="searchData" placeholder="Search Item Name" />
                <b-pagination
                class="mb-0 ml-2"
      v-model="currentPage"
      :total-rows="supplyItemCheck.length"
      :per-page="10"
      aria-controls="my-table"
    ></b-pagination> <b-btn variant="info" class="ml-2" @click="showModalEdit = !showModalEdit">Add New Item</b-btn>
    <download-excel v-if="fixDataExcel.length > 0"
    :name="'Supply List'"
    :data="fixDataExcel">
    <b-btn variant="primary">Download as Excel</b-btn>
</download-excel>
      </b-nav-form>

      <b-nav-form class="ml-auto">
        </b-nav-form>
    </b-navbar>
<b-container fluid>
    <b-table :fields="fields" :items="supplyItemCheck" v-if="resetList" :current-page="currentPage" :per-page="10">
      <template slot="formMentions" slot-scope="data">
        <span v-if="data.item.formMentions"><b-btn variant="primary" size="sm" @click="modalInfo = data.item.formMentions, showModal = !showModal">View ({{ data.item.formMentions.length }})</b-btn></span>
        <span v-else class="text-danger">None</span>
        </template>
              <template slot="itemVendor" slot-scope="data">
        <span v-if="data.item.itemVendor">{{ data.item.itemVendor.length }}</span>
        <span v-else class="text-danger">None</span>
        </template>
      <template slot="Edit" slot-scope="data">
        <b-btn variant="warning" size="sm" @click="editModalObj(data.item), showModalEdit = !showModalEdit">Edit</b-btn>
        <b-btn v-if="false" variant="danger" size="sm" @click="deleteItem(data.item.id)">Delete</b-btn>
        </template>
      </b-table>
        </b-container>
  <b-modal size="lg" v-model="showModalEdit" :title="showModalEditObj.itemName" @hidden="closeModalEdit" >
    <b-row><b-col><h5>Item Name: </h5>
      <b-form-input type="text" v-model="showModalEditObj.itemName" placeholder="Item Display Name" /></b-col>
      <b-col><h5>Qty Default</h5>
      <b-form-input v-model="showModalEditObj.itemDefault" type="number" /></b-col>
      <b-col><h5>Current Status?</h5>
      <b-form-checkbox v-model="showModalEditObj.itemActive" :check-value="true" :uncheck-value="false">Active?</b-form-checkbox>
      </b-col>
      </b-row>
      <h5 class="mt-3">Vendor Information</h5>
      <b-table :items="showModalEditObj.itemVendor" stacked="xl" :fields="['Name/Id', 'description', 'saleType/Qty', 'price', 'edit']">
        <template slot="Name/Id" slot-scope="data">
          <span v-if="data.index != vendorEditIndex">{{ data.item.name }}</span>
               <b-form-input v-else v-model="data.item.name" placeholder="Vendor Name" />
               <p v-if="data.index != vendorEditIndex">{{ data.item.vendId }}</p>
          <b-form-input v-else v-model="data.item.vendId" type="text" placeholder="Vendor Id" />
          </template>
          <template slot="description" slot-scope="data">
          <span v-if="data.index != vendorEditIndex">{{ data.item.description }}</span>
          <b-textarea v-else v-model="data.item.description" :rows="2" placeholder="Vendor Description" />
          </template>
          <template slot="saleType/Qty" slot-scope="data">
          <span v-if="data.index != vendorEditIndex">{{ data.item.saleType }}</span>
          <b-form-select v-else v-model="data.item.saleType" :options="packageOptions" />
          <p v-if="data.index != vendorEditIndex">{{ data.item.saleQty }}</p>
            <b-form-input style="width: 100px" v-else v-model="data.item.saleQty" type="number" />
          </template>
        <template slot="price" slot-scope="data">
          <span v-if="data.index != vendorEditIndex">{{ data.item.price }}</span>
          <b-form-input style="width: 100px" v-else v-model="data.item.price" type="number" placeholder="0.00" />
          </template>
         <template slot="edit" slot-scope="data">
         <b-btn variant="primary" size="sm" @click="vendorEditIndex == data.index ? vendorEditIndex = null : vendorEditIndex = data.index">{{ vendorEditIndex == data.index ? 'Save' : 'Edit' }}</b-btn>
         <b-btn variant="danger" size="sm" v-if="vendorEditIndex == data.index"  @click="sliceVendorItem(data.index)">Delete</b-btn>
          </template>
        </b-table>
        <b-btn block variant="info" size="sm" @click="addNewVendor">Add New Vendor</b-btn>
      <template slot="modal-footer">
        <b-btn variant="success" :disabled="checkForDisabled" @click="submitUpdatedItem(showModalEditObj)">Save</b-btn>
        </template>
  </b-modal>
  <b-modal size="lg" v-model="showModal" :title="'Form Mentions for ' +  (modalInfo[0] && modalInfo[0].item_name ?  modalInfo[0].item_name : 'Error')">
    <b-table :items="modalInfo" :fields="['template', 'item_loc_01', 'item_loc_02', 'item_loc_03']" />
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import JsonExcel from 'vue-json-excel';
export default {
components: {
  'multiselect' : Multiselect,
        'download-excel' : JsonExcel

},
props: ['inventoryItemsData', 'inventoryTemplateData'],
data() {
    return {
          searchData: '',
          filterCategory: false,
          filterTagType: null,
          resetField: '',
          currentPage: 1,
          modalInfo: [],
          showModalEditObj: { itemName: '', itemActive: true, itemVendor: null, itemDefault: 0 },
          showModalEdit: false,
          showModal: false,
          orderAmountSort: false,
          resetList: true,
          holdValuesCheck: {},
          filterDataBaseId: null,
          orderArray: [],
          packageOptions: ['each', 'box', 'case', 'tray', 'pack', 'pair'],
          vendorEditIndex: null,
          itemsArrayTagged: []
}
},
created () {
  this.getArrayOfItems(this.inventoryTemplateData)

},
beforeDestroy(){

},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'logisticAssetData'
  ]),
  fields(){
      var array = ['itemName', 'formMentions', 'itemVendor', 'itemActive' ]
      if(this.userData.role != 'user'){
        array.push('Edit')
      }
      return array
  },
  checkForDisabled(){
    var a = this.showModalEditObj
   // console.log(a)
    var b = this.supplyItemCheck.find(p => p.id == a.id)
    //console.log(b)
    return _.isEqual(a, b)
  },
  supplyItemCheck(){
    var items = _.cloneDeep(this.inventoryItemsData)
    var nongeneric = this.inventorysNonGeneric
    var array = items.map(p => {
      var obj = p
      obj['formMentions'] = this.itemsArrayTagged.filter(x => x.id == p.id)
      return obj
    }).filter(p => {
      return p.itemName.toLowerCase().match(this.searchData.toLowerCase())
    })
    var nameSort = _.orderBy(array, ['itemName'], ['asc'])
    return nameSort //.filter(p => p.itemVendor == null)
  },
  inventoryLatest(){
    var array = this.inventoryTemplateData.filter(p => {
      return p.latest
    })
    return array
  },
  inventorysNonGeneric(){
    var array = this.inventoryLatest
    var hold = []
    var latest = array.map(p => p.latest)
    var items = latest.map(p => p.inventorySubData)
    return hold.concat.apply(hold, items)
  },
  itemsFromTemplates(){
    var templates = this.inventoryTemplateData.filter(p => p.latest).map(p => p.inventoryItems)
    var hold = []
    var arrays = hold.concat.apply(hold, templates)
    return arrays.filter(p => p.item_type == 'supply')
  },
  fixDataExcel(){
      var array = _.cloneDeep(this.supplyItemCheck)
      var hold = array.map(item => {
        var obj = _.cloneDeep(item)
        delete obj.itemVendor
        delete obj.formMentions
        var formMentions = [...new Set(item.formMentions.map(p => p.template))]
        obj['Form Tags'] = formMentions
        if(item.itemVendor){
        var hold = item.itemVendor
        for(var i = 0; i < hold.length; i++){
          obj['vendor_' + i] = hold[i].name
          obj['vendId_' + i] = hold[i].vendId
          obj['description_' + i] = hold[i].description
          obj['saleType_' + i] = hold[i].saleType
          obj['saleQty_' + i] = hold[i].saleQty
          obj['price_' + i] = hold[i].price
        }
        }
        return obj
      })
      return hold
  },
},
mounted(){

},
watch:{
  inventorysNonGeneric(newData){
    this.resetList = false
    this.$nextTick(function(){
      this.resetList = true
    })
  },
  inventoryTemplateData(newData){
    this.getArrayOfItems(newData)
  }
},
methods: {
  editModalObj(data){
    this.showModalEditObj = _.cloneDeep(data) 
  },
  addNewVendor(){
    var index = this.showModalEditObj.itemVendor ? this.showModalEditObj.itemVendor.length : 0
    if(index != 0){
    this.showModalEditObj.itemVendor.push({name: '', description: '', saleType: 'each', saleQty: 1, price: ''})
    } else {
       this.showModalEditObj.itemVendor = [{name: '', description: '', saleType: 'each', saleQty: 1, price: ''}]
    }
    this.vendorEditIndex = index
  },
  sliceVendorItem(index){
    var length = this.showModalEditObj.itemVendor.length
    if(length > 1){
      this.showModalEditObj.itemVendor.splice(index, 1)
    } else {
      this.showModalEditObj.itemVendor = null
    }
  },
    getArrayOfItems(templates){
   //   console.log(templates)
      var array = []
      for(var i in templates){
        var items = templates[i].inventoryItems
        for(var p in items){
          var obj = items[p]
          obj['template'] = templates[i].inventoryName
          array.push(obj)
        }
      }
      array = array.filter(p => p.item_type == 'supply')
     // console.log(array)

      this.itemsArrayTagged = array
    },
    updateHoldValues(id, type){
    this.resetField = id
    var item = this.holdValuesCheck[id]
   // console.log(item)
    if(type == 'minus' && item.count > 0){
      this.holdValuesCheck[id].count = parseInt(this.holdValuesCheck[id].count) - 1
    } else if (type == 'add'){
       this.holdValuesCheck[id].count = parseInt(this.holdValuesCheck[id].count) + 1
    }
    this.$nextTick(function(){
      this.resetField = ''
    })
  },
  addToOrderArray(data){
     this.resetField = data.id
      var item = this.holdValuesCheck[data.id]
      this.orderArray.push({id: data.id, order: item.count, name: data.itemName})
      this.$nextTick(function(){
        this.holdValuesCheck[data.id].count = 0
         this.resetField = ''
      })
  },
  getTotalStock(array, id){
    var array = array.filter(p => {
      return p.id == id
    })
    var total = 0
    for(var i in array){
        total = total + array[i].item_count
    }
    return total
  },
  getDesiredTotal(array){
    if(array && array.length > 0){
      var total = 0
      for(var i in array){
        total = total + array[i].item_quantity
      }
      return total
    } return 0
  },
  deleteItem(id){

      axios({ 
     method: 'delete',
     url: '/api/v1/taxonomy/inventoryitem/' + id,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')

   })
  },
  closeModalEdit(){
    this.showModalEditObj = { itemName: '', itemActive: true, itemVendor: null, itemDefault: 0 }
    this.showModalEdit = false
    this.vendorEditIndex = null
  },
  submitUpdatedItem(data){
     delete data.formMentions
     delete data.vendorId
     var method = 'post'
     var url = ''
     if(data.id){
        method = 'patch'
        url = data.id 
     }
      axios({ 
     method: method,
     url: '/api/v1/taxonomy/inventoryitem/' + url,
     data: data,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
    this.$emit('getInventoryItems')
    this.closeModalEdit()
   })
  

    
  },

  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
