<template>
<div>
      <b-row v-for="(rp, rindex) in spotsReturned" :key="rindex" class="mt-1"  :class="{borderBottomStacked: rindex < spotsReturned.length - 1 && rp.type != 'Vacancy' && rp.required == 'required', 'vacancy-danger': rp.type == 'Vacancy' && rp.required == 'required'}" no-gutters>
                 <b-col cols="1" md="1"><span v-if="rindex == 0 || rp.position != spotsReturned[rindex -1].position">{{ rp.position }}</span>
                 </b-col>
                 <b-col cols="10" md="10" lg="5"  xl="4" align="left">
                   <span v-if="rp.spotData"  class="d-none d-sm-block"><span v-if="rp.spotData && rp.spotData.payrollModNote" class="text-info" :title="rp.spotData.payrollModNote">&#128457;</span>{{ getUserData(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId) }}</span>
                    <span v-if="rp.spotData" class="d-sm-none"><span v-if="rp.spotData && rp.spotData.payrollModNote" class="text-info" :title="rp.spotData.payrollModNote">&#128457;</span>{{ getUserData(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId).substring(0, 23) }}{{ getUserData(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId).length > 23 ? '...' : '' }}</span>
                 <span v-else-if="rp.rosterId && false">{{ rp.rosterId }}</span>
                  <b-btn 
                  v-if="rp.payrollCode == undefined && (rp.type == 'Vacancy' ||  rp.status == 'Vacancy') && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops')" 
                  variant="link" 
                  class="m-0 p-0 text-danger" 
                  @click="addPayrollMod(rp)">Add Payroll Mod</b-btn>
                 </b-col>
                 <b-col cols="6" md="6" lg="3" xl="2" class="mx-0" >
                   <span  v-if="rp.payrollCode == undefined">{{ rp.status }} <em v-if="rp.status != 'Vacancy'">{{ rp.type }}</em></span>
                  <span class="pointer"  v-if="rp.payrollCode" :title="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' ? 'Click to Edit' : 'Unauthorized to Edit'" :class="'payroll-' + getCodeObj(rp.payrollCode).payrollCodeColor" @click="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' ? updatePayrollMod(rp) : ''">
                    {{ getCodeObj(rp.payrollCode).payrollCode }} {{ getUserDataShort(rp.status) }} ({{ getTimeDiff(rp)}})</span>
                    </b-col>
                    <b-col cols="6" md="6" lg="3" xl="2" class="mx-0" >
                    <span class="pointer"  
                          v-if="rp.addative" 
                          :title="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' ? 'Click to Edit' : 'Unauthorized to Edit'" 
                          :class="'payroll-' + getCodeObj(rp.addative.payrollModCode).payrollCodeColor" @click="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' ? updatePayrollModAdd(rp.addative) : ''">
                    {{ getCodeObj(rp.addative.payrollModCode).payrollCode }} {{ getUserDataShort(rp.addative.status) }} ({{ rp.addative.payrollModHours}})</span>
                      <span v-if="!rp.addative && !printOn"><b-btn class="ml-1 px-1 py-0 mt-0 align-top" size="sm" variant="success" @click="updatePayrollMod(rp, 'add')">+$</b-btn></span>
                    </b-col>
                 <b-col cols="6" md="2" xl="1" lg="2" align="right" class="pr-3">{{ getTime(rp.startTime) }}</b-col>
                 <b-col cols="6" md="2" xl="1" lg="2"  align="left" class="pl-3">{{ getTime(rp.endTime) }}</b-col>
      </b-row>
  
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,
},
props: ['spot', 'profiles', 'mods', 'payrollCodesData', 'overtimeModification', 'userSelected', 'selectedDate', 'payaddativemods', 'onDutyFilter', 'printOn'],
data() {
    return {
      showHiddenPaymods: false,
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    removeRoster: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'upaid leave',
    ],
    nonSpotModification: [
       'payaddative',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
      'overtime',
    ],
    emittedData: [],
  }
},
created () { 
  bus.$on('tagHiddenPayMods', this.tagHiddenPayModsToggle)
},
beforeDestroy(){
  	
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  spotsReturned(){
    var positionHold = this.spot
    var date = this.selectedDate
    var returnSpots = []
    var position = 1
    this.updateOffRoster(positionHold.filter(p => p.payrollModType == 'payaddative'))
   for(var u in positionHold){
       var spotObj = {
                rosterId: 'offroster',
                position: position, 
                type: '',
                flag: '',  
                payrollCode: positionHold[u].payrollModCode ? positionHold[u].payrollModCode : undefined,
                status:  null,
                spotData: positionHold[u],
                userid: positionHold[u].payrollModTargetUserId, 
                startTime: positionHold[u].payrollModDatetime,
                endTime: moment(positionHold[u].payrollModDatetime).add(positionHold[u].payrollModHours, 'hours').toISOString()
                }
              returnSpots.push(spotObj)
              

            //  } 
         // }
      }
    if(this.userSelected){
      var vacantFilter = returnSpots.filter(p => {
        return p.type != 'Vacancy'
      })
      var user = vacantFilter.filter(p => {
        return p.userId == this.userSelected.id
      })
    } else {
      var user = returnSpots
    }
    return user
  },
},
mounted(){

},
watcher:{

},
methods: {
    tagHiddenPayModsToggle(){
    this.showHiddenPaymods = !this.showHiddenPaymods
  },
  updateOffRoster(data){
    if((this.userData.role == 'admin' || this.userData.role == 'fireops' || this.userData.role == 'adminchief') && this.showHiddenPaymods){
    for(var d in data){
       bus.$emit('payAddativeTracking', data[d].id)
    }
    }

  },
  getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    checkForPayAddative(spot){
      if(spot && spot.spotData){
      var payAdd = this.payaddativemods.find(p => p.payrollModRosterId == spot.rosterId && p.payrollModTargetUserId == spot.spotData.userId && p.payrollModDatetime == spot.startTime)
      if(payAdd){
          return payAdd
        }  else {
      return false
      }
      } else {
        return false
      }
    },
  getTime(time){
  //  console.log(time)
    if(time){
      return moment(time).format('HH:mm:ss')
    }
  },
  getTimeDiff(data){
    return moment(data.endTime).diff(moment(data.startTime), 'hours', true)
  },
  addPayrollMod(data, type){
     var dataObj = {  payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: data.rosterId, 
                      payrollModTargetUserId: '', 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: moment(data.startTime), 
                      payrollModHours: moment(data.endTime).diff(data.startTime, 'hours'), 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: data.position,
                      payrollModApproved: null,
                      payrollModNote: '' }
      bus.$emit('updatePayrollMod', dataObj)
  },
  getUserDataShort(id){
   // console.log(id)
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.lastName
        } else {
          return 'Unknown'
        }
      } else {
        return ''
      }
    },
    getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName + ' (' + user.deptRank + ') #' + user.deptSpecialties
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    updatePayrollModAdd(data){

      bus.$emit('updatePayrollMod', data)
    },

    updatePayrollMod(data, type){
      if((!data.spotData.payrollModData || type) && data.spotData){

          var dataobj = {       payrollModType: type && type == 'add' ? 'payaddative' : data.spotData.payrollModType ? data.spotData.payrollModType : '',
                                payrollModCode: data.spotData.payrollModCode ? data.spotData.payrollModCode : '', 
                                payrollModRosterId: data.rosterId, 
                                payrollModTargetUserId: data.spotData.userId ? data.spotData.userId : data.spotData.payrollModTargetUserId ? data.spotData.payrollModTargetUserId : null, 
                                payrollModExchangeUserId: '',
                                payrollModExchangeApproved: null, 
                                payrollModDatetime: data.startTime, 
                                payrollModHours: data.spotData.rosterShiftLength ? data.spotData.rosterShiftLength : data.spotData.payrollModHours ? data.spotData.payrollModHours : null, 
                                payrollModAuthorId: '', 
                                payrollModApprovalUserId: '',
                                payrollModRosterPosition: data.position,
                                payrollModApproved: null,
                                payrollModNote: data.spotData.payrollModNote ? data.spotData.payrollModNote : '' }
          if(data.spotData.id){
            dataobj['id'] = data.spotData.id
          }
      } else {
 
        var dataobj = data.spotData.payrollModData
      }
      bus.$emit('updatePayrollMod', dataobj)
    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.pointer {
  cursor: pointer;
}
.vacancy-danger {
  border: 1px solid red;
    border-radius: 5px;
    color: red;
    font-weight: bold;
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width: 3400px){
  .borderBottomStacked {
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
  }
}

</style>
