<template>
	<div>
		<b-modal
		v-model="loginStatusToggle"
		:no-close-on-backdrop='true'
		:no-close-on-esc='true'
		 >
			<template slot="modal-header">

			</template>
				<b-container>
				  <b-img  src="/statportals.png" />
					<h4>Please Complete to Reset Your Password</h4>

					<b-row  v-if="errorResponseMessage != ''">
						<b-col align="center">
					<b-alert align="center" variant="danger" class="errorMessage"  show>
						<h6>{{ errorResponseMessage.message }}</h6>
					</b-alert>
					<b-btn variant="danger" @click="passwordSubmission = !passwordSubmission">Forgot Password?</b-btn>
				</b-col>
				</b-row>
				<form autocomplete="off">
				<h5>User Name:</h5>
				<b-form-input @keyup.native.enter="postSelect" type="email" name="email" v-model="nameInput" />
				<h5 v-if="!passwordSubmission">New Password:</h5>
				<b-form-input v-if="!passwordSubmission" @keyup.native.enter="postSelect" :state="passwordStrength"  type="password" name="password" autocomplete="no" v-model="passwordInput" />

				<em><ul class="listItems">
					<li :class='{successMet: confirmRequirement("(?=.{8,})")}'>Length is atleast 8 Characters</li>
					<li :class='{successMet: confirmRequirement("(?=.*[A-Z])")}'>1 Uppercase Character</li>
					<li :class='{successMet: confirmRequirement("(?=.*[a-z])")}'>1 Lowercase Character</li>
					<li :class='{successMet: confirmRequirement("(?=.*[0-9])")}'>1 Number</li>
					<li :class='{successMet: confirmRequirement("(?=.*[!@#\$%\^&\*])")}'>1 Special Character</li>
					</ul>
					</em> 
				<h5 v-if="!passwordSubmission">Confirm Password:</h5>
				<b-form-input v-if="!passwordSubmission" @keyup.native.enter="postSelect" :state="confirmPassword" type="password" name="password" autocomplete="no" v-model="passwordInputConfirm" />
				</form>
				<b-btn variant="primary" @keyup.enter="postSelect" @click="postSelect">Submit</b-btn>
			</b-container>
			<template slot="modal-footer">
				<div class="socialmediaWrap">
					<socialmedia />
				</div>
			</template>

		</b-modal>
	  </div>
</template>
<script>
import axios from 'axios'
import socialMedia from '../secondarymenu/socialmediaicons.vue'
export default {
	props: ["loginStatusToggle", "token"],
	components: {
		'socialmedia': socialMedia
	},
  data: function() {
    return {
			nameInput: '',
			passwordInput: '',
			passwordInputConfirm: '',
			pinInput: '',
			errorResponseMessage: [],
			dataBool: true,
			passwordSubmission: false,
			taxonomyPull: ['station', 'apparatus', 'blogtype', 'checkdetails', 'checkitems', 'deptrank', 'deptspec', 'documenttype', 'eventtype', 'medication', 'repairstatus', 'shiftrotation']
    }
  },
	created(){

		this.nameInput = ''
		this.passwordInput = ''
		this.pinInput = ''
	},
	watch: {

	},
	computed: {
	nameState() {
		if(this.nameInput.match('@')){
			return false;
		}
		else {
			return null
		}
	},
	confirmPassword(){
		if(this.passwordInputConfirm != this.passwordInput){
			return false
		} else {
			return true
		}
	},
	passwordStrength(){
		var passReg = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
		var valid = passReg.test(this.passwordInput)
		if(!valid){
			return false
		} else {
			return true
		}
	},
},
	methods: {
		confirmRequirement(reg){
			var passReg = RegExp(reg)
			var valid = passReg.test(this.passwordInput)
			if(!valid){
				return false
			} else {
				return true
			}
		},
		postSelect(){
			if(this.passwordSubmission == true){
				return this.resetSubmit()
			} else {
				this.submitPost()
			}
		},

		resetSubmit(){
			this.errorResponseMessage = []
			var data = {
				'email': this.nameInput,
				'resetPin': this.pinInput
			}
			axios.post('/api/v1/auth/password-reset', data).then( response => {
				console.log(response)
				this.errorResponseMessage = { message: response.data }
			})
					this.nameInput = ''
					this.passwordInput = ''
					this.pinInput = ''							
		},
		submitPost(){
			this.errorResponseMessage = []
			this.$cookie.remove('userToken')
			var data = {
						'password': this.passwordInput
					}
			var token = 'bearer ' + this.token
			var headers = { headers: { 'content-type': 'application/json', 'Authorization': token} }
			var data = { password: this.passwordInput}
			axios.patch('/api/v1/users/resetpass/' + this.nameInput, data, headers).then( response => {
				if(response.status == 200){
						this.autoLogin()
				} else {
					console.log(response)
					this.errorResponseMessage = response.statusText
				}
			}).catch(error => {
					//console.log(error.response.data)
				 	this.errorResponseMessage = error.response.data
			})
		},
		autoLogin(){
			var data = {
						'email': this.nameInput,
						'password': this.passwordInput
					}
			this.$store.dispatch('LOAD_USER_DATA_STORE', data).then(
										response => {
														this.$store.dispatch('LOAD_CURRENT_USER_LOGIN', response.user)
														this.$store.dispatch('LOAD_SESSION_TOKEN', response.token)
														this.$cookie.set('userToken', response.token.accessToken,"0")
														var token = 'bearer ' + response.token.accessToken
														var headers = { headers: { 'content-type': 'application/json', 'Authorization': token} }
													 for(var i = 0; i < this.taxonomyPull.length; i++){
																var payload = { taxonomy: this.taxonomyPull[i], headers: headers}
																this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
															}         
															this.$store.dispatch('LOAD_CONTACT_DATA_EXP', headers)
															this.$store.dispatch('LOAD_FORM_TYPE')
															this.$store.dispatch('LOAD_DEPT_FEED')
															this.nameInput = ''
															this.passwordInput = ''	
															this.$router.push({ path: '/' })							
									 			},
										response => {
											this.errorResponseMessage = response
										})


				}
			}
/**  
 *  Password Reset Flow: Enter Email and Click Submit
 *  											Click Link in email which provides (email/authToken)
 * 												Fill out form with double password Check & re-entering Email
 * 												Check Email / Token for correct user
 * 												Save Password
 * 												Return to login Screen on Success
 * 												User Logs In
 */												
}
</script>
<style scoped>
.listItems{
	list-style: none;
	text-align: left;
	margin: 0 auto 0;
}
.successMet{
	color: green;
	 text-decoration: line-through;
}
.errorMessage {
	font-weight: bold;
}
img {
width: 100%;
height: auto;
margin-top: 20px;
}
.container {
	width: 80%;
	margin: 0 auto 0;

}
 h4 {
	padding-top: 30px;
}
.btn {
	margin-top: 10px;
}


	/* Smartphones (portrait and landscape) */
@media only screen and (min-width : 320px) and (max-width : 480px) {
/* Styles */
}

/* Smartphones (landscape) */
@media only screen and (min-width : 321px) {
	.modal-body-sig {
		margin: 10px auto 0;


  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }

}

/* Smartphones (portrait) */
@media only screen and (max-width : 320px) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }

}

/* iPads (portrait and landscape) */
@media only screen and (min-width : 768px) and (max-width : 1024px) {
/* Styles */
}

/* iPads (landscape) */
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) */
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
/* Styles */
}

/* Desktops and laptops */
@media only screen and (min-width : 1224px) {
	.modal-mask-sig {
	  position: fixed;
	  z-index: 9998;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(0, 0, 0, .5);
	  display: table;
	  transition: opacity .3s ease;
	}

	.modal-wrapper-sig {
	  display: table-cell;
	  vertical-align: middle;
	}

	.modal-container-sig {
	  width: auto;
	  min-width: 350px;
	  max-width: 600px;
	  max-height: 500px;
	  min-height: 300px;

	  background-color: #fff;
	  border-radius: 2px;
	  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	  transition: all .3s ease;
	  font-family: Helvetica, Arial, sans-serif;

	}
	.modal-footer {
	  padding: 20px;
	}
	.modal-header h3 {
	  margin-top: 0;
	  color: #42b983;
	}

	.modal-body-sig {
	  margin: 10% 0;
	  margin-left: 20px;
	  text-align: left;
	}

	.modal-default-button {
	  float: right;
	}

	/*
	 * The following styles are auto-applied to elements with
	 * transition="modal" when their visibility is toggled
	 * by Vue.js.
	 *
	 * You can easily play with the modal transition by editing
	 * these styles.
	 */

	.modal-enter {
	  opacity: 0;
	}

	.modal-leave-active {
	  opacity: 0;
	}

	.modal-enter .modal-container-sig,
	.modal-leave-active .modal-container-sig {
	  -webkit-transform: scale(1.1);
	  transform: scale(1.1);
	}
}

/* Large screens */
@media only screen and (min-width : 1824px) {
	.modal-mask-sig {
	  position: fixed;
	  z-index: 9998;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(0, 0, 0, .5);
	  display: table;
	  transition: opacity .3s ease;
	}

	.modal-wrapper-sig {
	  display: table-cell;
	  vertical-align: middle;
	}

	.modal-container-sig {
	  width: auto;
	  min-width: 350px;
	  max-width: 600px;
	  max-height: 500px;
	  min-height: 300px;

	  background-color: #fff;
	  border-radius: 2px;
	  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	  transition: all .3s ease;
	  font-family: Helvetica, Arial, sans-serif;

	}
	.modal-footer {
	  padding: 20px;
	}
	.modal-header h3 {
	  margin-top: 0;
	  color: #42b983;
	}

	.modal-body-sig {
	  margin: 10% 0;
	  margin-left: 20px;
	  text-align: left;
	}

	.modal-default-button {
	  float: right;
	}

	/*
	 * The following styles are auto-applied to elements with
	 * transition="modal" when their visibility is toggled
	 * by Vue.js.
	 *
	 * You can easily play with the modal transition by editing
	 * these styles.
	 */

	.modal-enter {
	  opacity: 0;
	}

	.modal-leave-active {
	  opacity: 0;
	}

	.modal-enter .modal-container-sig,
	.modal-leave-active .modal-container-sig {
	  -webkit-transform: scale(1.1);
	  transform: scale(1.1);

}
}
/* iPhone 4. Comma can be used to apply same rules to multiple media queries. */
@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
/* Styles */
}

/*iPhone 6 Portrait*/
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation : portrait) {
	.modal-body-sig {
    margin: 10px auto 0;

  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }
}

/*iPhone 6 landscape*/
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation : landscape) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
  .modal-container-sig {
    width: 90%;
  	height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
    margin: 10px auto 0;
    padding: 0;


  }
}

/*iPhone 6+ Portrait*/
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation : portrait) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}

/*iPhone 6+ landscape*/
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation : landscape) {
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}

/*iPhone 6 and iPhone 6+ portrait and landscape*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px){
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}

}

/*iPhone 6 and iPhone 6+ portrait*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px) and (orientation : portrait){
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}

/*iPhone 6 and iPhone 6+ landscape*/
@media only screen and (max-width: 640px), only screen and (max-width: 667px), only screen and (max-width: 480px) and (orientation : landscape){
	.modal-body-sig {
		margin: 10px auto 0;

  }
	.modal-container-sig {
		width: 90%;
		height: 90%;
		min-width: 0;
		max-width: none;
		max-height: none;
		min-height: 0;
		margin: 10px auto 0;
		padding: 0;


	}
}
	</style>
