<template>
  <div class="hello" v-if="resetComponent"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'shopops' || userData.role == 'fireops'">
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Admin</b-navbar-brand>
        <b-form-input class="bootstrapTable mr-sm-2" type="text" v-model="searchData" placeholder="Search"></b-form-input>
          <b-btn size="sm" :disabled="editUserTable || userData.role == 'fireops'" variant="success" @click="ToggleAddNewApparatus">Add New Vehicle</b-btn>
        <b-btn  size="sm" :disabled="addNewApparatus" v-if="!editUserTable" variant="danger" @click="toggleEditUserTable">Turn On Editing</b-btn>
        <b-btn  size="sm" :disbaled="addNewApparatus" v-if="editUserTable" variant="primary" @click="toggleEditUserTable">Turn Off Editing</b-btn>
         <b-col><b-nav-text class="bootstrapTable">(check to hide)</b-nav-text></b-col>
         <b-btn-group class="bootstrapTable" size="sm">
             <b-btn v-if="vehtagCol" @click="vehtagCol = !vehtagCol">Veh Tag</b-btn>
             <b-btn v-if="!vehtagCol" variant="outline-secondary" @click="vehtagCol = !vehtagCol">Veh Tag</b-btn>
             <b-btn v-if="vinCol" @click="vinCol = !vinCol">Vin</b-btn>
             <b-btn v-if="!vinCol" variant="outline-secondary" @click="vinCol = !vinCol">Vin</b-btn>
             <b-btn v-if="assestCol" @click="assestCol = !assestCol">Assest</b-btn>
             <b-btn v-if="!assestCol" variant="outline-secondary" @click="assestCol = !assestCol">Assest</b-btn>
             <b-btn v-if="assignCol" @click="assignCol = !assignCol">Assigned</b-btn>
             <b-btn v-if="!assignCol" variant="outline-secondary" @click="assignCol = !assignCol">Assigned</b-btn>
             <b-btn v-if="medicalCol" @click="medicalCol = !medicalCol">Medical</b-btn>
             <b-btn v-if="!medicalCol" variant="outline-secondary" @click="medicalCol = !medicalCol">Medical</b-btn>
             <b-btn v-if="typeCol" @click="typeCol = !typeCol">Type</b-btn>
             <b-btn v-if="!typeCol" variant="outline-secondary" @click="typeCol = !typeCol">Type</b-btn>
             <b-btn v-if="hoursCol" @click="hoursCol = !hoursCol">PmHours</b-btn>
             <b-btn v-if="!hoursCol" variant="outline-secondary" @click="hoursCol = !hoursCol">PmHours</b-btn>
            <b-btn v-if="appNotes" @click="appNotes = !appNotes">Notes</b-btn>
             <b-btn v-if="!appNotes" variant="outline-secondary" @click="appNotes = !appNotes">Notes</b-btn>
        </b-btn-group>
        <b-form-select class="ml-2 mt-0"  text-field="stationName" value-field="stationName" v-model="filterApparatusAssignment" :options="parentGroupings">
                      <option value="">Assigned</option>
        </b-form-select>
        <b-form-select class="ml-2 mt-0"  v-model="filterApparatusStatus">
                      <option value="">Status</option>
                      <option value="is">In Service</option>
                      <option value="res">In Reserve</option>
                      <option value="oss">Out of Service</option>
        </b-form-select>
      </b-nav-form>
    </b-navbar>
    <b-container v-if="addNewApparatus">
      <b-row class="mt-3">
        <b-col>
        <b-form-input size="sm"  type="text" v-model="newApparatusData.apparatusName" placeholder="Name" />
        </b-col>
        <b-col>
        <b-form-input size="sm" type="text" v-model="newApparatusData.apparatusRadioId" placeholder="Radio Id"  />
        </b-col>
        <b-col>
        <b-form-input size="sm"  type="text" v-model="newApparatusData.apparatusTag" placeholder="Veh Tag" />
         </b-col>
        <b-col>
        <b-form-input  size="sm" type="text" v-model="newApparatusData.apparatusVin" placeholder="Veh Vin" />
        </b-col>
        <b-col>
        <b-form-input  size="sm" type="number" v-model="newApparatusData.apparatusAssetNumber" placeholder="Asset#"  />
        </b-col>
        <b-col>
        <b-form-select  size="sm" text-field="stationName" value-field="stationName" v-model="newApparatusData.apparatusParent" :options="parentGroupings">
          <option disabled="true" value="">Parent</option>
        </b-form-select>
        </b-col>
        <b-col>
        <b-form-radio-group v-model="newApparatusData.apparatusMedicalType" :options="medicalOptions" />
        </b-col>
        <b-col>
        <b-form-select size="sm" text-field="stationName" value-field="stationName" v-model="newApparatusData.apparatusLocation" :options="stationsData">
          <option disabled="true" value="">Station</option>
        </b-form-select>
        </b-col>
        <b-col>
        <b-form-select  size="sm" text-field="vehicletypeName" value-field="vehicletypeName" v-model="newApparatusData.apparatusVehicleType" :options="vehicleTypeData">
          <option disabled="true" value="">Veh Type</option>
        </b-form-select>
        </b-col>
        </b-row>
      <b-card :title="' (Vehicle Item Details)'">
      <b-row>
      <b-col class="pt-1">
        <h6 class="text-primary bold">Check Items List:</h6>
        <b-form-select size="sm"  text-field="checkitemsName" value-field="checkitemsName" v-model="newApparatusData.apparatusCheckItems" :options="vehicleCheckItems">
        </b-form-select>
        </b-col>
        <b-col class="pt-1">
          <h6 class="text-primary bold">Check PM Display Type:</h6>
          <b-form-select  size="sm" v-model="newApparatusData.apparatusPMCheckType" :options="['Miles', 'Hours']" />
          </b-col>
          <b-col class="pt-1">
             <h6 class="text-primary bold">Vehicle Phone:</h6>
            <b-form-input size="sm"  type="number" v-model="newApparatusData.apparatusPhone"></b-form-input></b-col>
        </b-row>
        <b-row class="mt-2">
        <b-col>
          <b-card header-slot="header">
          <h5 slot="header" class="text-primary bold">Check Details Area Selector</h5>
    <b-form-checkbox-group :id="'checkbox1-new'" v-model="newApparatusData.apparatusCheckRequirements" name="flavour1" :options="checkDetailsOptions">
    </b-form-checkbox-group>
    </b-card>
    </b-col></b-row>
    <b-card header-slot="header">
      <h5 slot="header" class="text-primary bold">Visibility Options (select which user groups should be able to view this apparatus)</h5>
     
    <b-form-checkbox-group :id="'checkbox2-new'" v-model="newApparatusData.apparatusMenuVisibility" name="flavour1" :options="visibilityOptions">
    </b-form-checkbox-group>
     <em>only applies to certain menus and data entry types</em>
      </b-card>
        </b-card>
      <b-card :title="'Vehicle Item Details'" v-if="false">
      <b-row>
      <b-col cols="*">
        <h5>Check Items</h5>
            <b-form-select size="sm" text-field="checkitemsName" value-field="checkitemsName" v-model="newApparatusData.apparatusCheckItems" :options="vehicleCheckItems">
        </b-form-select>
        </b-col><b-col>
          <h5>Check Details Area Selector</h5>
    <b-form-checkbox-group id="checkbox-new" v-model="newApparatusData.apparatusCheckRequirements" name="flavour1" :options="checkDetailsOptions">
    </b-form-checkbox-group></b-col></b-row>
    <b-container>
      <h5>Visibility Options (select which user groups should be able to view this apparatus)</h5>
     
    <b-form-checkbox-group id="checkbox2-new" v-model="newApparatusData.apparatusMenuVisibility" name="flavour1" :options="visibilityOptions">
    </b-form-checkbox-group>
     <em>only applies to certain menus and data entry types</em>
      </b-container>
        </b-card>

        <b-btn  @click="apparatusNewSubmit(newApparatusData)" variant="primary">Save</b-btn>
 

    </b-container>
    <b-table v-if="!editUserTable" stacked="md" striped :fields="fields" :items="filteredDepartmentApparatus">
      <template slot="apparatusStatus" slot-scope="data">
        {{ data.item.apparatusStatus ? data.item.apparatusStatus.toUpperCase() : '?'}}
      </template>
      <template  slot="notes" slot-scope="data">
        <b-btn variant="outline-info" @click="apparatusNotes(data.item.apparatusName)" size='sm'>Notes</b-btn>
      </template>
    </b-table>
      <b-pagination  v-if="editUserTable" size="md" :total-rows="departmentApparatusEdit.length" align="center" v-model="currentPage" :per-page="10" />
    <b-table v-if="editUserTable" striped  stacked="md"  :per-page="10" :current-page="currentPage" :fields="fields" :items="departmentApparatusEdit">
      <template slot="apparatusName" slot-scope="data">
        <b-form-input size="sm" :disabled="userData.role == 'fireops'" type="text" v-model="data.item.apparatusName" />
      </template>
      <template slot="apparatusRadioId" slot-scope="data">
        <b-form-input size="sm" :disabled="userData.role == 'fireops'" type="text" v-model="data.item.apparatusRadioId" />
      </template>
      <template slot="apparatusTag" slot-scope="data">
        <b-form-input size="sm" :disabled="userData.role == 'fireops'"  type="text" v-model="data.item.apparatusTag" />
      </template>
      <template slot="apparatusVin" slot-scope="data">
        <b-form-input  size="sm" :disabled="userData.role == 'fireops'" type="text" v-model="data.item.apparatusVin" />
      </template>
      <template slot="apparatusAssetNumber" slot-scope="data">
        <b-form-input  size="sm" type="number" :disabled="userData.role == 'fireops'" v-model="data.item.apparatusAssetNumber" />
      </template>
      <template  slot="apparatusParent" slot-scope="data">
        <b-form-select  size="sm" text-field="stationName" :disabled="userData.role == 'fireops'" value-field="stationName" v-model="data.item.apparatusParent" :options="parentGroupings">
        </b-form-select>
      </template>
      <template  slot="apparatusMedicalType" slot-scope="data">
        <b-form-radio-group v-model="data.item.apparatusMedicalType" :disabled="userData.role == 'fireops'" :options="medicalOptions" />
      </template>
      <template  slot="apparatusLocation" slot-scope="data">
        <b-form-select size="sm" text-field="stationName" value-field="stationName" :disabled="data.item.apparatusParent != 'Reserve' && !(userData.role == 'admin' || userData.role == 'shopops' || userData.role == 'adminchief')" title="Only can edit location of Reserve Apparatus" v-model="data.item.apparatusLocation" :options="stationsData">
        </b-form-select>
      </template>
      <template  slot="apparatusVehicleType" slot-scope="data">
        <b-form-select  size="sm" text-field="vehicletypeName" :disabled="userData.role == 'fireops'" value-field="vehicletypeName" v-model="data.item.apparatusVehicleType" :options="vehicleTypeData">
        </b-form-select>
      </template>
      <template slot="apparatusStatus" slot-scope="data">
        <b-form-select size="sm" v-model="data.item.apparatusStatus">
                      <option value="">Status</option>
                      <option value="is">In Service</option>
                      <option value="res">In Reserve</option>
                      <option value="oss">Out of Service</option>
        </b-form-select>
      </template>
      <template  slot="apparatusNextPm" slot-scope="data">
        <b-form-input type="number"  size="sm"  v-model="data.item.apparatusNextPm" >
        </b-form-input>
      </template>
      <template  slot="notes" slot-scope="data">
         <b-btn variant="outline-info" @click="apparatusNotes(data.item.apparatusName)" size='sm'>Notes</b-btn>
      </template>
      <template slot="checkOptions" slot-scope="row">
    <b-btn size="sm" variant="primary" @click.stop="row.toggleDetails">Show</b-btn>
  </template>
    <template slot="row-details" slot-scope="row" v-if="userData.role != 'fireops'">
      <b-card :title="row.item.apparatusName + ' (Vehicle Item Details)'">
      <b-row>
      <b-col class="pt-1">
        <h6 class="text-primary bold">Check Items List:</h6>
        <b-form-select size="sm"  text-field="checkitemsName" value-field="checkitemsName" v-model="row.item.apparatusCheckItems" :options="vehicleCheckItems">
        </b-form-select>
        </b-col>
        <b-col class="pt-1">
          <h6 class="text-primary bold">Check PM Display Type:</h6>
          <b-form-select  size="sm" v-model="row.item.apparatusPMCheckType" :options="['Miles', 'Hours']" />
          </b-col>
          <b-col class="pt-1">
             <h6 class="text-primary bold">Vehicle Phone:</h6>
            <b-form-input size="sm"  type="number" v-model="row.item.apparatusPhone"></b-form-input></b-col>
        </b-row>
        <b-row class="mt-2">
        <b-col>
          <b-card header-slot="header">
          <h5 slot="header" class="text-primary bold">{{ row.item.apparatusName }} - Check Details Area Selector <b-btn size="sm" variant="outline-primary" @click="showAllOptions == row.item.id ? showAllOptions = '' : showAllOptions = row.item.id">Show All</b-btn></h5>
    <b-form-checkbox-group v-if="showAllOptions == row.item.id" :id="'checkbox1-' + row.item.id" v-model="row.item.apparatusCheckRequirements" name="flavour1" :options="checkDetailsOptions">
    </b-form-checkbox-group>
        <em v-if="showAllOptions != row.item.id">Drag to re-sort the display order</em>
        <draggable v-if="showAllOptions != row.item.id" v-model="row.item.apparatusCheckRequirements" @start="drag=true" @end="drag=false">
          <b-badge variant="primary" class="mr-2" v-for="element in row.item.apparatusCheckRequirements" :key="element.id">{{ element }}</b-badge>
        </draggable>
    </b-card>
    </b-col></b-row>
    <b-card header-slot="header">
      <h5 slot="header" class="text-primary bold">{{ row.item.apparatusName }} - Visibility Options (select which user groups should be able to view this apparatus)</h5>
     
    <b-form-checkbox-group :id="'checkbox2-' + row.item.id" v-model="row.item.apparatusMenuVisibility" name="flavour1" :options="visibilityOptions">
    </b-form-checkbox-group>
     <em>only applies to certain menus and data entry types</em>
      </b-card>
        </b-card>
      </template>
      <template slot="Edit" slot-scope="data">
        <b-btn v-if="checkChanges(data.item)" @click="apparatusEditSubmit(data.item)" variant="warning">Update</b-btn>
        
      </template>
      <template slot="remove" slot-scope="data">
     <b-btn @click="apparatusDeleteSubmit(data.item)"  :disabled="userData.role == 'fireops'" variant="danger">Remove</b-btn>
        
        </template>

    </b-table>

  </div>
<b-modal v-model="noteCheck" :title="noteApparatusSelected">
                <div class="trackingBoardWrapper">
                <h4 class="mt-2">Tracking Board</h4>
                <span v-if="noteData && noteData.updatedAt && noteData.noteAuthor"><em>Last Updated at {{ noteDataFormat(noteData.updatedAt) }} <br> by {{ noteData.noteAuthor }}</em></span>
                 <vue-editor v-if="userData.role != 'user'" class="textAreaDescription" :editorToolbar="customToolbar"  v-model="detailedDescription"></vue-editor>
                 <b-btn v-show="!(detailedDescription == this.noteData.noteBody)" v-if="userData.role != 'user' && noteData != null" :disabled="detailedDescription == this.noteData.noteBody" size="sm" variant="primary" @click="updateNote(detailedDescription, noteData)">Update Board</b-btn>
                 <b-btn v-if="userData.role != 'user' && noteData == null" :disabled="detailedDescription == ''" size="sm" variant="primary" @click="createNewNote(detailedDescription)">Add Note</b-btn>
                 <b-container class="textAreaDescription mb-2"><div v-if="userData.role == 'user'" v-html="detailedDescription" /></b-container>
                </div>

</b-modal>

</div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import moment from 'moment'
import draggable from 'vuedraggable'
export default {
  components: {
    VueEditor,
    draggable
  },
  name: 'hello',
  data () {
    return {
      currentPage: 1,
      editing: false,
      originalApparatusData: [],
      medicalOptions: [
        { text: 'ALS', value: 'ALS'},
        { text: 'BLS', value: 'BLS'}
      ],
      filterApparatusAssignment: '',
      vehtagCol: true,
      vinCol: true,
      assignCol: true,
      showAllOptions: '',
      detailedDescription: '',
      customToolbar: [
          ['bold', 'italic', 'underline', 'strike'],
        ],
      noteData: '',
      noteCheck: false,
      medicalCol: true,
      assestCol: true,
      typeCol: true,
      hoursCol: true,
      appNotes: false,
      editUserTable: false,
      searchData: '',
      bodyShow: false,
      addNewApparatus: false,
      newApparatusData: {},
      resetComponent: true,
      filterApparatusStatus: '',
      noteApparatusSelected: '',

    }
  },
  created(){
    if(this.userData.role == 'fireops'){
      this.vinCol = false
      this.medicalCol = false
      this.vehtagCol = false
      this.assestCol = false
      this.appNotes = true
    }
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'vehicleCheckDetails',
          'vehicleCheckItems',
          'vehicleTypeData'
        ]),
        testUserRights(){
          if(this.userData.role == 'admin'
            || this.userData.role == 'adminchief'
            || this.userData.role == 'shopops'){
              return false
            } else {
              return true
            }
        },
        checkDetailsOptions(){
        var array = this.vehicleCheckDetails.map( p => {
          return { text: p.checkdetailsName, value: p.checkdetailsName }
        })
        return _.orderBy(array, ['text'], ['asc'])
        },
        visibilityOptions(){
          var array = [
            { text: 'user', value: 'user' },
            { text: 'admin', value: 'admin' },
            { text: 'adminchief', value: 'adminchief' },
            { text: 'fireops', value: 'fireops' },
            { text: 'emsops', value: 'emsops' },
            { text: 'trainingops', value: 'trainingops' },
            { text: 'shopops', value: 'shopops' },
            { text: 'supervisor', value: 'supervisor' },


    
          ]
          return array
        },
        parentGroupings(){
            var parentGroups = this.stationsData.filter( p => {
      return p.stationParent == '' || p.stationParent == undefined
    })
      return parentGroups
       },
        fields(){
          var objectArray = []
          objectArray.push({ key: 'apparatusName', label: 'Vehicle' })
          objectArray.push({ key: 'apparatusRadioId', label: 'Radio' })
          if(this.vehtagCol == true){
            objectArray.push({ key: 'apparatusTag', label: 'Veh Tag'})
          }
          if(this.vinCol == true){
            objectArray.push({ key: 'apparatusVin', label: 'Vin Number'})
          }
          if(this.assestCol == true){
            objectArray.push({ key: 'apparatusAssetNumber', label: 'Asset#'})
          }
          if(this.assignCol == true){
            objectArray.push({ key: 'apparatusParent', label: 'Assigned'})
          }
          if(this.medicalCol == true){
            objectArray.push({ key: 'apparatusMedicalType', label: 'Med'})
          }
          objectArray.push({ key: 'apparatusLocation', label: 'Station'})
          if(this.typeCol == true){
            objectArray.push({ key: 'apparatusVehicleType', label: 'Veh Type'})
          }
          objectArray.push({key: 'apparatusStatus', label: 'Status'})
          if(this.hoursCol == true){
            objectArray.push({ key: 'apparatusNextPm', label: 'PmHrs/Mis'})
          }
          if(this.appNotes){
             objectArray.push({ key: 'notes', label: 'Notes'})
          }
          /* objectArray.push({ key: 'id', label: 'ID'}) */
          if(this.editUserTable == true){
            objectArray.push('Edit')
            objectArray.push('remove')
          }
          return objectArray
        },
        filteredDepartmentApparatus(){
          var array = this.originalApparatusData.filter( (p) => {
            return p.apparatusName.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusRadioId.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusLocation.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusParent.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusVehicleType.toLowerCase().match(this.searchData.toLowerCase())

          })
          if(this.filterApparatusAssignment){
            var assign = array.filter(p => p.apparatusParent == this.filterApparatusAssignment)
          } else {
            var assign = array
          } 
          if(this.filterApparatusStatus){
            return assign.filter(p => p.apparatusStatus == this.filterApparatusStatus)
          } else {
            return assign
          }
        },
        departmentApparatusEdit(){
          return [...new Set(this.filteredDepartmentApparatus.map((p) => {
            return {
                  "apparatusName" : p.apparatusName,
                  "apparatusRadioId" : p.apparatusRadioId,
                  "apparatusTag" : p.apparatusTag,
                  "apparatusVin" : p.apparatusVin,
                  "apparatusAssetNumber" : p.apparatusAssetNumber,
                  "apparatusParent" : p.apparatusParent,
                  "apparatusMedicalType" : p.apparatusMedicalType,
                  "apparatusLocation" : p.apparatusLocation,
                  "apparatusStatus" : p.apparatusStatus,
                  "apparatusPhone" : p.apparatusPhone,
                  "apparatusCheckItems" : p.apparatusCheckItems,
                  "apparatusCheckRequirements" : p.apparatusCheckRequirements,
                  "apparatusVehicleType" : p.apparatusVehicleType,
                  "apparatusNextPm" : p.apparatusNextPm,
                  "apparatusMenuVisibility" : p.apparatusMenuVisibility,
                  "apparatusPMCheckType": p.apparatusPMCheckType,
                  "id" : p.id,
                  "_showDetails" : true,
            }
          })
        )]
      },
      watchAppraatusData(){
        return this.apparatusData
      }
  },
  mounted(){
    this.originalApparatusData = this.apparatusData
  },
  watch: {
    watchAppraatusData(newData){
      this.resetComponent = false
      console.log('NEW DATA')
      this.$nextTick(function(){
        this.originalApparatusData = this.apparatusData
        this.resetComponent = true
      })
    }
  },
  methods: {
    noteDataFormat(data){
      if(data){
        return moment(data).format('MM/DD/YY HH:mm')
      }
    },
    ToggleAddNewApparatus(){
      this.addNewApparatus = !this.addNewApparatus
      this.newApparatusData = {
                  apparatusName: '',
                  apparatusRadioId: '',
                  apparatusTag: '',
                  apparatusVin: '',
                  apparatusAssetNumber: '',
                  apparatusParent: '',
                  apparatusMedicalType: '',
                  apparatusLocation: '',
                  apparatusCheckItems: '',
                  apparatusCheckRequirements: '',
                  apparatusPMCheckType: '',
                  apparatusVehicleType: '',
                  apparatusNextPm: '',
                  apparatusMenuVisibility: '',
      }
    },
    checkChanges(data){
      var currentUserFilter = this.apparatusData.filter( (p) => {
        return p.id.match(data.id)
      })
      var currentUser = currentUserFilter[0]
      if( currentUser.apparatusName != data.apparatusName ||
          currentUser.apparatusRadioId != data.apparatusRadioId ||
          currentUser.apparatusTag != data.apparatusTag ||
          currentUser.apparatusVin != data.apparatusVin ||
          currentUser.apparatusAssetNumber != data.apparatusAssetNumber ||
          currentUser.apparatusParent != data.apparatusParent ||
          currentUser.apparatusMedicalType != data.apparatusMedicalType ||
          currentUser.apparatusLocation  != data.apparatusLocation ||
          currentUser.apparatusVehicleType != data.apparatusVehicleType ||
          currentUser.apparatusCheckItems != data.apparatusCheckItems ||
          currentUser.apparatusCheckRequirements != data.apparatusCheckRequirements ||
          currentUser.apparatusNextPm != data.apparatusNextPm ||
          currentUser.apparatusPhone != data.apparatusPhone ||
          currentUser.apparatusStatus != data.apparatusStatus ||
          currentUser.apparatusPMCheckType != data.apparatusPMCheckType ||
          currentUser.apparatusMenuVisibility != data.apparatusMenuVisibility 
          ){
        return true
      }
      else {
      return false
    }
    },
    testData(data){
      console.log(data)
    },
    toggleEdit(data){
      if(this.editing == data){
        this.editing = false;
      } else {
        this.editing = data;
      }
    },
    toggleEditUserTable(event){
      this.editUserTable = !this.editUserTable
    },
    toggleBodyShow(data){
      if(this.bodyShow == data){
        this.bodyShow = false;
      } else {
        this.bodyShow = data;
      }
    },
    keyCorrection(data){
      var underscore = data.replace(/_/g, ' ')
      var apparatus = underscore.replace('apparatus', '')
      var vehicle = apparatus.replace('vehicle', '')
      var last = vehicle.replace('number', '')
      var field = last.replace('field', '')
      var filter = field.replace('filter', '')
      return filter
    },
    typeValue(data){
      var matchData = this.vehicleTypes.filter(p => {
        return p.name.match(data)})
      return JSON.parse(matchData[0].id)
    },
    assignValue(data){
      var matchData = this.parentGroupings.filter(p => {
        return p.name.match(data)})
      return JSON.parse(matchData[0].id)
    },
    getMedicalType(data){
      if(data == 'ALS'){
        return 1
      }
      if(data == 'BLS'){
        return 2
      } else {
        return ''
      }
    },
    apparatusNotes(data){
      this.noteCheck = true
      this.noteData = ''
     this.detailedDescription = ''
      var apparatusData =  data.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
      this.noteApparatusSelected = apparatusData
      var headers = {
            headers: {
              "content-type": "application/json",
              "Authorization": this.getSessionData,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'              
            }}
        axios({
            method: 'get',
            url: '/api/v1/content/note/tag/' + apparatusData,
            headers: headers.headers,
          }).then(response => {
            if(response.data.length){
           this.noteData = response.data[0]
           this.detailedDescription = response.data[0].noteBody
            } else { 
              this.noteData = null 
            }
         })
    },
    createNewNote(data){
      axios({
        method: 'post',
        url: '/api/v1/content/note',
        data: {
              'noteTitle': this.noteApparatusSelected + ' Note Board ' + moment().format('MM/DD/YY HH:mm'),
              'noteBody': data,
              'noteAuthor': this.userData.firstName + ' ' + this.userData.lastName,
              'noteAuthorId': this.userData.id,
              'noteIdTag': this.noteApparatusSelected, 
        },
        headers: {
          'content-type': 'application/json',
          'authorization': this.getSessionData
        }
      }).then(response => {
          var headers = {
            headers: {
              "content-type": "application/json",
              "Authorization": this.getSessionData,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'              
            }
          };
          axios({
            method: 'get',
            url: '/api/v1/content/note/tag/' + this.noteApparatusSelected,
            headers: headers.headers,
          }).then(response => {
            if(response.data.length){
           this.noteData = response.data[0]
           this.detailedDescription = response.data[0].noteBody
            } else { 
              this.noteData = null 
            }
          })
      })
    },
  updateNote(data, note){
      axios({
        method: 'patch',
        url: '/api/v1/content/note/' + note.id,
        data: {
              'noteBody': data,
              'noteAuthor': this.userData.firstName + ' ' + this.userData.lastName,
              'noteAuthorId': this.userData.id,
        },
        headers: {
          'content-type': 'application/json',
          'authorization': this.getSessionData
        }
      }).then(response => {
          var headers = {
            headers: {
              "content-type": "application/json",
              "Authorization": this.getSessionData,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'              
            }
          };
          axios({
            method: 'get',
            url: '/api/v1/content/note/tag/' + note.noteIdTag,
            headers: headers.headers,
          }).then(response => {
            if(response.data.length){
           this.noteData = response.data[0]
            } else { 
              this.noteData = null 
            }
          })
      })
    },
    apparatusNewSubmit(data){
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'apparatus', headers: headers, expire: true}
     axios({
      method: 'post',
      url: '/api/v1/taxonomy/apparatus/',
      data: {
                  "apparatusName" : data.apparatusName,
                  "apparatusRadioId" : data.apparatusRadioId,
                  "apparatusTag" : data.apparatusTag,
                  "apparatusVin" : data.apparatusVin,
                  "apparatusStatus" : 'is',
                  "apparatusPhone" : data.apparatusPhone,
                  "apparatusAssetNumber" : data.apparatusAssetNumber,
                  "apparatusCheckItems" : data.apparatusCheckItems,
                  "apparatusCheckRequirements" : data.apparatusCheckRequirements,
                  "apparatusParent" : data.apparatusParent,
                  "apparatusMedicalType" : data.apparatusMedicalType,
                  "apparatusLocation" :data.apparatusLocation,
                  "apparatusVehicleType" : data.apparatusVehicleType,
                   "apparatusMenuVisibility": data.apparatusMenuVisibility,
                   "apparatusPMCheckType": data.apparatusPMCheckType
       
        },
      headers: headers.headers,
    }).then( response => {
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
              this.newApparatusData = {
                  apparatusName: '',
                  apparatusRadioId: '',
                  apparatusTag: '',
                  apparatusVin: '',
                  apparatusAssetNumber: '',
                  apparatusPhone: '',
                  apparatusParent: '',
                  apparatusMedicalType: '',
                  apparatusLocation: '',
                  apparatusCheckItems: '',
                  apparatusCheckRequirements: '',
                  apparatusVehicleType: '',
                  apparatusNextPm: '',
                  apparatusMenuVisibility: '',
                  apparatusPMCheckType: '',
      }
      
    })
  },
    apparatusEditSubmit(data){
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'apparatus', headers: headers, expire: true}
     axios({
      method: 'patch',
      url: '/api/v1/taxonomy/apparatus/' + data.id,
      data: {
                  "apparatusName" : data.apparatusName,
                  "apparatusRadioId" : data.apparatusRadioId,
                  "apparatusTag" : data.apparatusTag,
                  "apparatusVin" : data.apparatusVin,
                  "apparatusStatus" : data.apparatusStatus,
                  "apparatusAssetNumber" : data.apparatusAssetNumber,
                  "apparatusCheckItems" : data.apparatusCheckItems,
                  "apparatusPhone" : data.apparatusPhone,
                  "apparatusCheckRequirements" : data.apparatusCheckRequirements,
                  "apparatusParent" : data.apparatusParent,
                  "apparatusMedicalType" : data.apparatusMedicalType,
                  "apparatusLocation" :data.apparatusLocation,
                  "apparatusVehicleType" : data.apparatusVehicleType,
                  "apparatusNextPm" : data.apparatusNextPm,
                   "apparatusMenuVisibility": data.apparatusMenuVisibility ,
                   "apparatusPMCheckType": data.apparatusPMCheckType
       
        },
      headers: headers.headers,
    }).then( response => {
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
      this.resetComponent = false
      this.$nextTick(function(){
        this.resetComponent = true
      })

      
    })
  },
   apparatusDeleteSubmit(data){
    var result = confirm('Are You Sure You Want to Delete this Apparatus?')
      if (result) {
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'apparatus', headers: headers, expire: true}
   axios({
     method: 'delete',
     url: '/api/v1/taxonomy/apparatus/' + data.id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)

      
    })
  }
   }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
