<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
          <b-card no-body tabs>
        <b-tabs card>
          <b-tab title="Reports" :active="makeActive">
            <b-table :fields="['payrollBankRecoDate', 'createdAt', 'payrollRecoAuthor', 'delete']" :items="recoReportsDataSorted">
              <template slot="payrollBankRecoData" slot-scope="data">
                {{ getDateTime(data.item.payrollBankRecoDate) }}
              </template>
              <template slot="delete" slot-scope="data">
                <b-btn @click="viewRecoReport(data.item)" size="sm" variant="info">View</b-btn> 
                <b-btn v-if="recoReportsDataSorted[0].id == data.item.id" @click="deleteRecoReport(data.item.id)" size="sm" variant="danger">Delete</b-btn>
                </template>
              </b-table>
            </b-tab>
            <b-tab title="New Submission" :disabled="siteconfig.payroll.importPayBanks">
          <recoreportTable v-if="previousReportData && resetData" :recoreportDate="adjustForAccurals" :payrollBankRecoDatePrev="payrollBankRecoDate" :importReport="false" :fields="fields" :bankIds="bankIds" :previous="''" @updateBankData="updateBankData" />
          <b-btn @click="submitRecoReport">Submit</b-btn>
      </b-tab>
         <b-tab title="Import New Report" v-if="siteconfig.payroll.importPayBanks">
          <b-textarea v-model="uploadedJsonReport" @input="fixDataReport()" placeholder="Paste Excel Data Here" />
          <recoreportTable v-if="dataForSubmissionSeed.length > 0 && resetData" :importReport="true" :recoreportDate="adjustForAccurals" :payrollBankRecoDatePrev="dataForSubmissionSeed" :fields="fieldsImport" :bankIds="bankIds" :previous="''" @updateBankData="updateBankData" />
          <b-form-input type="date" v-model="payrollSeedDate" />
          <b-btn @click="submitRecoReportSeed" variant="success" :disabled="!payrollSeedDate">Submit</b-btn>
      </b-tab>
      <b-tab v-for="report in previousReportData" :key="report.id" active>
                 <template slot="title">
        {{ getDateTime(report.payrollBankRecoDate) }} <b-btn variant="danger" class="px-1 py-0" size="sm" @click="closeRecoReport(report.id)">X</b-btn>
      </template> 
          <recoreportTable :recoreportDate="report.payrollRecoData" :payrollCodesData="payrollCodesData" :previousDate="report.payrollBankRecoDate" :nonumbercoversion="siteconfig.payroll.importPayBanks" :importReport="false" :fields="fields" :bankIds="bankIds" :previous="report.id" />
        </b-tab>
        <b-tab v-if="userData.role == 'admin' && recoReportsDataSorted.length == 0" title="Seed Data">
          <b-form-input  v-if="false" type="date" v-model="payrollBankRecoDate" />
          <b-textarea v-model="uploadedJson" @input="fixData()" />
          <b-form-input type="date" v-model="payrollSeedDate" />
          <b-btn @click="submitRecoReportSeed" variant="success" :disabled="!payrollSeedDate">Submit</b-btn>
        </b-tab>
      </b-tabs>
      </b-card>

</div>
</div>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import recoreportTable from './recoTable.vue'
import PapaParse from 'papaparse'
export default {
  components: {
    multiselect,
    userCard,
    recoreportTable
  },
  
  name: 'roster-admin',
  props: ['payrollAccuralData', 'payrollBanksData', 'rosterProfiles', 'rosterData', 'payrollAccuralAssignedData', 'payrollCodesData'],
  data () {
    return {
      searchData: '',
      fields: ['firstName', 'lastName', 'employeeId'],
      fieldsImport: [],
      bankIds: [], 
      currentPage: 0,
      recoReportsData: [],
      previousReportData: [],
      payrollRecoObjectIds: [],
      resetData: true,
      payrollBankRecoDate: moment().format('YYYY-MM-DD'),
      payrollSeedDate: '',
      makeActive: true,
      cycleAdvance: 0,
      previousDataReco: undefined,
      payrollRecoMods: [],
      previousDate: '',
      endDate: '',
      uploadedJson: '',
      dataForSubmissionSeed: [],
      uploadedJsonReport: '',
    }
  },
  created(){
    this.getRecoReports()

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        deptContactDataReturn(){
          return _.cloneDeep(this.deptContactData)
        },
        userDataReturned(){
          var array = [... new Set(this.payrollAccuralAssignedData.map(p => p.payrollUserId))]
          var holdArray = []
          var bank = this.payrollBanksData
          var baseObj = []
          for(var b in bank){
            var obj = { key: bank[b].id, label: bank[b].payrollBankName }
            this.fields.push(obj)
            this.bankIds.push(obj)
            baseObj.push({id: bank[b].id, name: bank[b].payrollBankName, mods: 0, prev: 0, accural: 0, newTotal: 0,  modsAttached: []})
          }
          this.fields.push('view')
          for(var u in array){
              var assigned = this.payrollAccuralAssignedData.filter(p => p.payrollUserId == array[u]) 
              assigned = assigned.filter(p => {
                  var test = moment(p.payrollAccuralStart).isBefore(moment(this.payrollBankRecoDate))
                  if(test){
                    if(!p.payrollAccuralEnd){
                      return p
                    } else {
                      if(moment(p.payrollAccuralEnd).isAfter(moment(this.payrollBankRecoDate))){
                        return p
                      }
                    }
                  }
              })//NEED TO FILTER BY DATE
              var mods = this.modsAdjustedForBanks.filter(p => p.payrollModTargetUserId == array[u])
              var modIds = mods.map(p => p.bankid)
              var obj = this.deptContactDataReturn.find(p => p.id == array[u])
              if(obj){
              obj['banks'] = _.cloneDeep(baseObj)
              obj['mods'] = mods
              obj['modIds'] = modIds
              obj['accurals'] = assigned.map(item => {
                var obj = this.payrollAccuralData.find(p => p.id == item.payrollAccuralId)
                obj['assigned'] = item
                return obj
              })
             // console.log(obj)
              holdArray.push(obj)
              }
          }
          return _.orderBy(holdArray, ['lastName'], ['asc'])
        },
        adjustForAccurals(){
          var array = this.userDataReturned
          console.log(array)
          array = array.map(item => {
                  var obj = item
                  var bank = obj.banks
                  for(var b in bank){
                    var holdObj = _.cloneDeep(bank[b])
                  bank[b] = this.updateAccuralTimes(holdObj, item.accurals, item.id, item.mods, item.modIds, item.lastName)
                  }
                  return obj
             })
          return array
        },
        dataForSubmission(){
          var array = this.adjustForAccurals.map(item => {
                  var obj = {}
                  obj['payrollUserId'] = item.id
                  obj['banks'] = item.banks
                  return obj
          })
          return array

        },
        modsAdjustedForBanks(){
          var returnArray = []
          var mods = this.payrollRecoMods
          for(var i in mods){
              var bank = this.payrollCodesData.find(p => p.id == mods[i].payrollModCode)
              if(bank){
              var adj = bank.payrollCodeBankMod
              for(var b in adj){
                var obj = _.cloneDeep(mods[i])
                obj['mod'] = adj[b].mod
                obj['bankid'] = adj[b].id
                returnArray.push(obj)
              }
              }
          } 
       //   console.log(returnArray)
          return returnArray

        },
        lastRecoReport(){
           var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
           if(array[0]){
            var recoFirst = moment(array[0].payrollBankRecoDate)
            return recoFirst
           } return ''
        },
        firstOfTheMonthsSinceLastReport(){
          var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
          var dates = []
          if(array[0]){
            var recoFirst = moment(array[0].payrollBankRecoDate).startOf('Month')
            var currentFirst = moment().startOf('Month')
           // console.log(recoFirst)
           // console.log(currentFirst)
            var diff = currentFirst.diff(recoFirst, 'Month')
            if(diff > 0){
              return diff
            } return 0
          }
        },
        firstMonthPayperiodsSinceLastReport(){
          var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
          var dates = []
          var payroll = this.siteconfig.payroll
          var cycleType = payroll.payCycleType == 'biweekly' ? 14 : 14
          if(array[0]){
            var newest = _.cloneDeep(array[0])
            var previousDate = newest.payrollBankRecoDate
            var endDate = moment(this.payrollBankRecoDate).toDate()
            var holddates = this.previousPaydatesFromLastReco(previousDate, endDate)
            var monthHold = []
            for(var i in holddates){
              var month = moment(holddates[i]).format('MM/YYYY')
              var precycle = moment(holddates[i]).subtract(cycleType, 'd').format('MM/YYYY')
              var index = monthHold.indexOf(month)
              if(index < 0 && month != precycle){
                dates.push(holddates[i])
                monthHold.push(month)
              }
            }
          }
          return dates
        },
        recoReportsDataSorted(){
          var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
          if(array[0]){
            var newest = _.cloneDeep(array[0])
            var previousDate = newest.payrollBankRecoDate
            var endDate = moment(this.payrollBankRecoDate).toDate()
            this.cycleAdvance = this.getCyclesSinceLastReport(previousDate, endDate)
          //  
            this.previousDataReco = array[0].payrollRecoData
           // console.log(this.cycleAdvance)
          }
          return array
        },
 
       
  },
  mounted(){

  },
  watch: {
    endDate(newDate, oldDate){
      if(newDate != oldDate){
        this.getPayrollModifiersInRange(this.previousDate, newDate)
      }
    }

  },
  methods: {
          getDateTime(data){
            if(data){
              return moment(data).format('MM/DD/YYYY')
            } return 'No date'
          },
          closeRecoReport(id){
            var index = this.previousReportData.map(p => p.id).indexOf(id)
            if(index >= 0){
              this.previousReportData.splice(index, 1)
            }
          },
          previousPaydatesFromLastReco(dateStart, dateStop){
          var array = []
          if(dateStart){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
                   var presetVarDate = moment(payroll.payStartDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(dateStop).diff(presetVarDate, "days")
                   var cycleType = payroll.payCycleType == 'biweekly' ? 14 : 14
                   var end = Math.floor(dateDiffCal/cycleType)
                   var endDate = moment(presetVarDate).add(end*cycleType, 'days').toISOString()
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(cycleType - 1, 'days').toISOString()
                   array.push(startDate)
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(cycleType*i, 'days').toISOString()
                      array.push(startDay)
                   }
                   var filter = array.filter(p => {
                     return moment(p).isSameOrAfter(dateStart) && moment(p).isSameOrBefore(dateStop)
                   })
                   var sorted = _.sortBy(filter)
                   return sorted
            
          } return []
      },
      fixDataReport(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJsonReport
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.fixDataApply(results.data)
            }
        })
      },
      fixDataApply(hold){
        var emId = [... new Set(hold.map(p => p['Employee ID']))]
        var payrollRecoData = []
        var fieldsSplice = []
        for(var i in emId){
          var obj = {}
          var user = this.deptContactDataReturn.find(p => p.employeeId == emId[i].replace(/\s/g, ''))
          if(user){
          obj['payrollUserId'] = user.id
          var banks = []
          var availBanks = hold.filter(p => p['Employee ID'] == emId[i])
          for(var b in availBanks){
            if(availBanks[b]['Leave Bank ID']){
            var bank = this.payrollBanksData.find(p => p.payrollBankName.replace(/ /g, '').toLowerCase() == availBanks[b]['Leave Bank ID'].replace(/ /g, '').toLowerCase())
            } else {
              console.log('Undefined')
              console.log(availBanks[b])
              var bank = null
            }
            if(bank){
            var index = fieldsSplice.indexOf(bank.id)
             if(index == -1){
               fieldsSplice.push(bank.id)
             }
             banks.push({id: bank.id, import: true, name: bank.payrollBankName, mods: 0, prev: availBanks[b]['Prior Bal'], accural: availBanks[b]['Accrued'], newTotal:  availBanks[b]['Balance'],  modsAttached: []})
            } else {
              console.log('Lost Bank ' + availBanks[b].bank)
            }
          }
          obj['banks'] = banks
          payrollRecoData.push(obj)
          } else {
            console.log('LOST USER ' + emId[i])
            console.log(emId[i])
          }
        }
        var holdFields = _.cloneDeep(this.fields)
        var fields = holdFields.filter(p => {
            if(p.key){
            var index = fieldsSplice.indexOf(p.key)
            if(index != -1){
              return p
            }
            } else {
              return p
            }
        })
        fields.push('view')
        this.fieldsImport = fields
        this.dataForSubmissionSeed = payrollRecoData
        console.log(payrollRecoData)
      },
      fixData(){
        var data = this.uploadedJson
        var vm = this
      PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.fixDataSeed(results.data)
            }
        })
      },
      fixDataSeed(dataparse){
     //   console.log(dataparse)
       // var data = JSON.parse(this.uploadedJson)
        var hold = _.cloneDeep(dataparse)
        var emId = [... new Set(hold.map(p => p['Employee Id']))]
        var payrollRecoData = []
        var fieldsSplice = []
        for(var i in emId){
          var obj = {}
          var user = this.deptContactDataReturn.find(p => p.employeeId == emId[i])
          if(user){
          obj['payrollUserId'] = user.id
          } else {
            console.log('LOST USER ' + emId[i])
          }
          var banks = []
          var availBanks = hold.filter(p => p['Employee Id'] == emId[i])
          console.log(availBanks)
          console.log(this.payrollBanksData)
          for(var b in availBanks){
            if(availBanks[b]['Leave Bank ID']){
            var bank = this.payrollBanksData.find(p => p.payrollBankName.replace(/ /g, '').toLowerCase() == availBanks[b]['Leave Bank ID'].replace(/ /g, '').toLowerCase())
            } else {
              console.log('Undefined')
             // console.log(availBanks[b])
              var bank = null
            }
            if(bank){
          //   var index = fieldsSplice.index(bank.id)
         //    if(index == -1){
          //     fieldsSplice.push(bank.id)
          //   }
             banks.push({id: bank.id, name: bank.payrollBankName, mods: 0, prev: 0, accural: 0, newTotal:  availBanks[b]['Balance'],  modsAttached: []})
            } else {
              console.log('Lost Bank ' + availBanks[b].bank)
            }
          }
          obj['banks'] = banks
          payrollRecoData.push(obj)
        }
        console.log(payrollRecoData)

        this.dataForSubmissionSeed = payrollRecoData
    },
    viewRecoReport(data){
      if(data){
      var obj = data
      obj.payrollRecoData.map(item => {
          var hold = item
          var user = this.deptContactDataReturn.find(p => p.id == item.payrollUserId)
          if(user){
            hold['firstName'] = user.firstName
            hold['lastName'] = user.lastName
            hold['employeeId'] = user.employeeId
            hold['id'] = user.id
          }
          return hold
        })
      this.previousReportData.push(obj)
      }
    },
    updateAccuralTimes(bank, accurals, user, mods, modIds, lastName){
      var acc = accurals.find(p => p.payrollBankId == bank.id)
      if(this.previousDataReco){
      var previousData = this.previousDataReco.find(p => p.payrollUserId == user)
      } else {
        previousData = undefined
      }
      if(lastName == 'Wilcoxon' && false){
              console.log(lastName)
              console.log(previousData)
              console.log(acc)
              console.log(bank)
              console.log(accurals)
      }
      var holdBank = ''
      if(previousData && previousData.banks){
         var previousBank = previousData.banks.find(p => p.id == bank.id)
          if(previousBank){
          var newBank = _.cloneDeep(previousBank)
          bank.prev = newBank.newTotal
      if(acc && acc.payrollBankAccuralType == 'paycycle' && !this.siteconfig.payroll.importPayBanks){
          var amount = acc.payrollBankAccuralRate * this.cycleAdvance
          bank.accural = amount
          bank.newTotal = Number.parseFloat(bank.prev.replace(',', '')) + Number.parseFloat(amount)
          holdBank = bank
      } else if(acc && acc.payrollBankAccuralType == 'firstpayperiodmonth' && !this.siteconfig.payroll.importPayBanks){  /// need to do a firstofthemonthsetup for EFD "firstdayofthemonth"
          var amounttimes = 0
          if(this.firstMonthPayperiodsSinceLastReport && this.firstMonthPayperiodsSinceLastReport.length >= 0){
            amounttimes = this.firstMonthPayperiodsSinceLastReport.length
          }
          var amount = acc.payrollBankAccuralRate * amounttimes
          bank.accural = amount
          bank.newTotal = Number.parseFloat(bank.prev.replace(',', '')) + Number.parseFloat(amount)

          holdBank = bank
      } else if(acc && acc.payrollBankAccuralType == 'firstdayofthemonth' && !this.siteconfig.payroll.importPayBanks){  /// need to do a firstofthemonthsetup for EFD "firstdayofthemonth"
          var amounttimes = 0
          if(this.firstOfTheMonthsSinceLastReport && this.firstOfTheMonthsSinceLastReport >= 0){
            amounttimes = this.firstOfTheMonthsSinceLastReport
          }
          var amount = acc.payrollBankAccuralRate * amounttimes
          bank.accural = amount
          bank.newTotal = Number.parseFloat(bank.prev) + Number.parseFloat(amount)
          holdBank = bank
          if(lastName == 'Wilcoxon' && false){
              console.log('CATCH THE BANK')
              console.log(lastName)
              console.log(bank)
          }
      }  else if(this.siteconfig.payroll.importPayBanks){
       //   console.log('SITE IMPORT')
       //   console.log(bank)
           bank.prev = bank.newTotal
           bank.accrual = "N/A"
           holdBank = bank
        } else {
          bank.newTotal = bank.prev
          holdBank = bank
      }
          } else {
            holdBank = bank
          }
      } else {
        holdBank = bank
      }
      if(mods.length > 0){
        if(modIds.indexOf(bank.id) != -1){
          var modsbank = mods.filter(p => p.bankid == bank.id)
          if(modsbank.length > 0){
            for(var m in modsbank){
              if(modsbank[m].mod == 'addTo'){
                holdBank.mods = parseFloat(holdBank.mods) + parseFloat(modsbank[m].payrollModHours)
                holdBank.modsAttached.push(modsbank[m])
                holdBank.newTotal = parseFloat(holdBank.newTotal) + parseFloat(modsbank[m].payrollModHours)
                this.payrollRecoObjectIds.push(modsbank[m].id)
              } else if(modsbank[m].mod == 'subFrom'){
                holdBank.mods = parseFloat(holdBank.mods) - parseFloat(modsbank[m].payrollModHours)
                holdBank.modsAttached.push(modsbank[m])
                holdBank.newTotal = parseFloat(holdBank.newTotal) - parseFloat(modsbank[m].payrollModHours)
                 this.payrollRecoObjectIds.push(modsbank[m].id)
              }
            }
               //    console.log(lastName)
                //   console.log(holdBank)
          }
        }  /// NEED TO CHECK FOR BANK ROLLOVER CONDITIONS ie YEAR END STUFF ////

        return holdBank
      } else {
      return holdBank
      }
    },
    getCyclesSinceLastReport(previousDate, endDate){
      var payroll = this.siteconfig.payroll
      var cyclesAdvance = 0
      var diff = moment(endDate).diff(previousDate, 'days')
      var presetVarDate = moment(payroll.payStartDate, "YYYY-MM-DD")
     // console.log(diff)
      if(payroll.payCycleType == 'biweekly'){
         for(var i = 0; i < diff; i++){
         var testDate = moment(endDate).add(i, 'days')
         var dateDiffCal = moment(testDate).diff(presetVarDate, "days")
          if(Math.floor(dateDiffCal/14) == dateDiffCal/14){
              cyclesAdvance = cyclesAdvance + 1
          } 
      } 
     }
    this.previousDate = previousDate
    if(!(moment(endDate).isSame(this.endDate))){
         this.endDate = endDate
    }
    return cyclesAdvance    
    },
    updateBankData(data){
      this.payrollBankRecoDate = data
    },
    submitRecoReportSeed(){
        axios({
          method: 'Post',
          url: '/api/v1/staffing/payrollrecoreport',
          data: {
              payrollBankRecoDate: moment(this.payrollSeedDate, 'YYYY-MM-DD').toDate(),
              payrollRecoData:  this.dataForSubmissionSeed, 
              payrollRecoObjectIds: null,
              payrollRecoAuthorId: this.userData.id,
              payrollRecoAuthor: this.userData.firstName + ' ' + this.userData.lastName
          },
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
          }
        }).then(response => {
          this.getRecoReports()
          this.makeActive = false
          this.uploadedJson = ''
          this.uploadedJsonReport = ''
          this.dataForSubmissionSeed = []
          this.payrollSeedDate = ''
          this.$nextTick(function(){
            this.makeActive = true
          })
       //   console.log(response.data)
        })
    },
    submitRecoReport(){
        axios({
          method: 'Post',
          url: '/api/v1/staffing/payrollrecoreport',
          data: {
              payrollBankRecoDate: moment(this.payrollBankRecoDate, 'YYYY-MM-DD').toDate(),
              payrollRecoData:  this.dataForSubmission, 
              payrollRecoObjectIds: this.payrollRecoObjectIds,
              payrollRecoAuthorId: this.userData.id,
              payrollRecoAuthor: this.userData.firstName + ' ' + this.userData.lastName
          },
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
          }
        }).then(response => {
          this.getRecoReports()
       //   console.log(response.data)
        })
    },
    getRecoReports(){
        axios({
          method: 'get',
          url: '/api/v1/staffing/payrollrecoreport',
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
          }
        }).then(response => {
          this.recoReportsData = response.data
        })
    },
    deleteRecoReport(id){
          var result = confirm('Are You Sure You Want to this Payroll Reco Report? Once it is gone, it is gone forever!')
      if (result) {
    axios({
     method: 'delete',
     url: '/api/v1/staffing/payrollrecoreport/' + id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      this.getRecoReports()
   })
      } 

    },
    getPayrollModByDate(selectedDate){
    //  this.rosterReload = false
      this.previousMod = ''
      this.showModal = false
      var start = moment(this.selectedDate + 'T' + this.siteconfig.payroll.shiftStartTime)
      var nextShift = moment(start).add(1, 'day').toISOString()
      var hourstart = start.subtract(1, 'hour')
      hourstart = moment(hourstart).toISOString()
      var hourend = start.add(26, 'hours')
      hourend = moment(hourend).toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/daily/' + hourstart + '/' + hourend,
     headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
  }
   }).then( response => {
     var array = response.data.filter(p => {
       if(p.payrollModHours != 0){
       return moment(p.payrollModDatetime).isBefore(nextShift)
       }
     })
    console.log(array)
    this.payrollModDailyRosterData = array
    // this.rosterReload = true
   })

    },
    submitAccurals(){
      var array = this.userDataReturned
      for(var i in array){
        axios({
          method: 'Post',
          url: '/api/v1/staffing/payrollaccuralassigned',
          data: array[i],
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
          }
        }).then(response => {
       //   console.log(response.data)
        })
      }
    },
    getPayrollModifiersInRange(start, end){
      this.payrollRecoMods = []
      this.payrollRecoObjectIds = []
      this.resetData = false
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrollmod/daily/' + start + '/' + moment(end).toISOString(),
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     var array = response.data
     array = array.filter(p => {
        if(p.payrollModHours && p.payrollModHours != 0){
          return p
        }
     })
     console.log(array)
     this.payrollRecoMods = array
     this.$nextTick(function(){
       this.resetData = true
     })
        // this.rosterReload = true
      })
    },


  
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
