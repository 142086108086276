<template>
  <div class="hello">
        <div class="headerSpan">Department Blogs & Notifications  <span v-if="showAdmin">Admin</span><span class="floatRight"><b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops' || userData.role == 'fireops'" size="sm" @click="showAdmin = !showAdmin" :variant="showAdmin == true ? 'light' : 'outline-light'">Admin</b-btn></span></div>
  <b-card no-body v-if="showAdmin">
    <b-tabs card>
      <b-tab title="Blogs Admin">
        <blogAdmin />
      </b-tab>
      <b-tab title="Notification Admin">
  <notificationadmin v-if="showAdmin" />
      </b-tab>
    </b-tabs>
  </b-card>
  <b-card no-body  v-if="!showAdmin">
    <b-tabs card>
      <b-tab title="Blogs" Active  class="px-1 px-md-4">
  <b-container fluid  class="px-1 px-md-4">
      <b-navbar type="dark" variant="dark">
        <b-nav-form>
          <b-navbar-brand class="bootstrapTable">Toolbar</b-navbar-brand>
          <b-form-input class="bootstrapTable mr-sm-2" type="text" v-model="searchData" placeholder="Search"  ></b-form-input>
          <b-btn v-if="allBlogCol" @click="parentPostValue = '', subPostValue = ''" :variant="parentPostValue == '' ? 'secondary' : 'outline-secondary'">All</b-btn>
          <b-btn v-for="(par, index) in BlogParents" @click="parentPostValue = par" :key="index" :variant="checkVariant(par, index, parentPostValue)">{{ par }}</b-btn>

        </b-nav-form>
      </b-navbar>
            <b-navbar v-if="parentPostValue" type="dark" variant="dark">
        <b-nav-form>
          <b-navbar-brand class="bootstrapTable">Blog Sub Category Filter</b-navbar-brand>
          <b-btn v-if="allBlogCol" @click="subPostValue = ''" :variant="subPostValue == '' ? 'secondary' : 'outline-secondary'">All Subs</b-btn>
          <b-btn v-for="(par, index) in blogSubs(parentPostValue)" @click="subPostValue = par" :key="index" :variant="checkVariant(par, index, subPostValue)">{{ par }}</b-btn>

        </b-nav-form>
      </b-navbar>
                <b-pagination v-model="currentPage" :total-rows="filteredBlogs.length" align="center"></b-pagination>
        <b-card-group columns >
          <b-card class="blogCardClass" no-body v-for="blog in blogsPaged" :key="blog.id" :class="blog.blogTypeParent">

              <blog :blog="blog" />
            </b-card>
        </b-card-group>
  </b-container>
      </b-tab>
    <b-tab title="Notifications">
      <b-container v-if="notification == ''">
<b-table hover striped  :fields="notificationFields" :items="notificationsReturned">
  <template slot="createdAt" slot-scope="data">
    {{ notificationTimeAdjust(data.item.createdAt) }}
  </template>
  <template slot="View" slot-scope="data">
    <b-btn variant="link" @click="showNotificationData(data.item)">View</b-btn>
  </template>
</b-table>
</b-container>
<b-container v-if="notification != ''">
  <b-btn variant="danger" size="sm" @click="notification = ''">Return to Table</b-btn>
<notificationCard :notification="notification" />
</b-container>

      </b-tab>
    </b-tabs></b-card>
 
    </div>

</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from 'moment'
import blog from '../tracking/blogs/blogsPage.vue'
import { mapState } from 'vuex'
import NotificationAdmin from '../admin/adminNotification.vue'
import BlogAdmin from '../admin/adminBlogs.vue'
import NotificationCard from '../tracking/blogs/notificationCard.vue'
import axios from 'axios'
export default {
  name: 'hello',
  components:{
    'blog' : blog,
    'notificationadmin' : NotificationAdmin,
    'blogAdmin' : BlogAdmin, 
    'notificationCard' : NotificationCard,
  },
  data () {
    return {
      stringData: '',
      deptPhoneList: [],
      phoneNumberList: [],
      userSearchBox: '',
      currentPage: 1,
      notification: '',
      showAdmin: false,
      pageDisplayCount: 6,
      pageCountOptions:[
          { text: "6 Per Page", value:6},
          { text: "12 Per Page", value:12},
          { text: "24 Per Page", value:24},
          { text: "48 Per Page", value:48},
      ],
      allBlogCol: true,
      adminBlogCol: false,
      preventBlogCol: false,
      workgroups: false,
      trainingBlogCol: false,
      searchData: '',
      parentPostValue: '',
      subPostValue: '',
      notifications: [],
      notificationFields: [
      { key: 'createdAt', label: 'Date', sortable: true },
      'title',
      { key: 'notificationAuthor', label: 'Author'},
      'View'
    ],
    }
  },
  created(){
    this.getNotifications()
  },
  mounted(){
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }}
    var payload = { headers: headers }
    this.$store.dispatch('LOAD_DEPT_BLOG_EXP', payload)
  },
  computed: {
    ...mapState([
      'deptContactData',
      'departmentBlogs',
      'getSessionData',
      'userData'
    ]),
    notificationsReturned(){
      var array = this.notifications
      return _.orderBy(array, ['createdAt'], ['desc'])
    },
    filteredBlogCat(){
      return this.departmentBlogs.filter( (p) => {
      return p.blogTypeParent.toLowerCase().match(this.parentPostValue.toLowerCase()) && 
              p.blogType.toLowerCase().match(this.subPostValue.toLowerCase())
     })
    },
    
    BlogParents(){
      /*  console.log(this.departmentBlogs) */
      return [... new Set(this.departmentBlogs.map(p => p.blogTypeParent))]
    },
    filteredBlogs(){
      var array = this.filteredBlogCat.filter( (p) => {
        return p.title.toLowerCase().match(this.searchData.toLowerCase())
                  || p.blogAuthor.toLowerCase().match(this.searchData.toLowerCase())

      })
      return _.orderBy(array, ['blogDate'], ['desc'])
    },
    blogsPaged(){
      var array = _.cloneDeep(this.filteredBlogs)
      var current = (this.currentPage - 1) * 10
      var next = (this.currentPage) * 10
      if(next > array.length){
        var end = array.length
      } else {
        var end = next
      }
      return array.slice(current, end)
    },


  },
  watcher: {


  },
  methods: {
    blogSubs(par){
      var data = this.departmentBlogs.filter(p => p.blogTypeParent == par)
      return [...new Set(data.map(p => p.blogType))]
    },
        showNotificationData(data){
     this.notification = data
    },    
        notificationTimeAdjust(data){
      return moment(data).format('MM/DD/YYYY - HH:mm')
    },
    checkVariant(par, index, value){
      var indexValues = ['info', 'success', 'primary', 'danger', 'light']
      if(par == value){
        if(index < 5){
          return indexValues[index]
        } else {
          return indexValues[index - 5]
        }

      } else {
        if(index < 5){
          return 'outline-' + indexValues[index]
        } else {
          return 'outline-' + indexValues[index - 5]
        }
      }
    },
    getNotifications(){
        axios({
              method: 'get',
              url: '/api/v1/content/notification/userread/' + this.userData.id, 
              headers: {
                'content-type': 'application/json',
                'authorization': this.getSessionData
              }}
              ).
          then((response) => { this.getNotificationsData(response.data) })
    },
    getNotificationsData(data){
      var dataArray = data

      this.notifications = dataArray
    },
    changeFilter(data){
      this.allBlogCol = false,
        this.adminBlogCol = false,
        this.preventBlogCol = false,
        this.workgroups = false,
        this.trainingBlogCol = false,
        this[data] = true
    }

  },

}
</script>
<style>

@media screen and (max-width: 1200px){
.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px){
.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px){
.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px){
.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.blogCardClass {
      box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.blogSpanHeader.navbar {
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  border-radius: 0 0 5px 5px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer{
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {

  border-radius: 5px;
}
.A-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.B-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.C-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Day-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.A-shift{
  background: #e2070d;
  background: -moz-linear-gradient(left, #e2070d 0%, #e2070d 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift{
  background: #201B9F;
  background: -moz-linear-gradient(left, #201B9F 0%, #201B9F 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
.C-shift{
  background: #0FB906;
  background: -moz-linear-gradient(left, #0FB906 0%, #0FB906 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
      border-radius: 20px 20px 0 0;
}
.Day-shift{
  background: #E4CB07;
  background: -moz-linear-gradient(left, #E4CB07 0%, #E4CB07 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}

@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
  .blogSpanHeader.navbar {
    border-radius: 0;
  }
}
</style>
