<template>
<div >
 <span v-if="userData.role == 'admin' && false"> {{ testArray }} </span>
     <b-navbar type="dark" variant="dark" class="mt-3">
      <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable d-none d-md-block"> Roster Date:</b-navbar-brand>
       <b-form-input type="date" class="d-none d-md-block" v-model="selectedDateHold" @change="emitSelectedDate()" />
       <b-form-input type="date" style="min-height: 43px" class="d-md-none w-50" v-model="selectedDateHold" @change="emitSelectedDate()" />

        <b-btn class="mx-2 d-none d-md-block" @click="addNewPayrollMod" variant="primary">Add New Payroll Mod</b-btn>
        <b-btn class="d-md-none mt-2 mr-2" @click="addNewPayrollMod" variant="primary">Add New Payroll Mod</b-btn>
        <b-btn class="mr-2 d-none d-lg-block" :variant="printOn ? 'success' : 'danger'" @click="printOn = !printOn">{{ printOn ? 'Turn Off Print View' : 'Turn On Print View' }}</b-btn>
         <b-btn v-if="printOn" class="mr-2 d-none d-lg-block" variant="primary" @click="executePrintJS">Print</b-btn>
         <b-btn variant="info" @click="groupByUser = !groupByUser">Group By User</b-btn>
         <b-btn variant="warning" @click="showOnlyErrors = !showOnlyErrors">Show Hidden PayAdds List</b-btn>
         <b-btn variant="danger" v-if="showHiddenPayrollMods" @click="turnOffPayadds">Reset</b-btn>
      </b-nav-form>
    </b-navbar>
    <b-navbar type="dark" variant="secondary" class="py-1">
      <b-nav-form>
     <b-navbar-brand class="bootstrapTable d-none d-md-block"> Filter:</b-navbar-brand>
      <b-form-checkbox-group :options="spotModification" v-model="modificationFilter" class="text-light" />
      </b-nav-form>
    </b-navbar>
    <b-container id="print-daily-mod" fluid>
          <b-container v-if="printOn" class="mb-4 mt-4" fluid>
      <b-row><b-col cols="5">
      <img :src="departmentLogoForPrint" /></b-col>
      <b-col cols="7" class="pt-2">
        <h3>Daily Modification List</h3>
        <b-row><b-col>
          <h4><span class="font-weight-bold">Duty Shift: </span><span>{{ currentShift }}</span></h4>
          </b-col>
          <b-col>
               <h4><span class="font-weight-bold">Date: </span><span>{{ getTodaysDateForPrint }}</span></h4>
            </b-col>
            <b-col>
                 <h4><span class="font-weight-bold">Time: </span><span>{{ getCurrentTimeForPrint }}</span></h4>
              </b-col>
              </b-row>
              <em>Completed by {{ userData.deptRank }} {{ userData.firstName }} {{ userData.lastName }}</em>
        </b-col>
        </b-row>
      </b-container>
  <b-table :fields="fieldsData" :items="payrollMod" stacked="lg" v-if="stationsData">
      <template slot="payrollModCode" slot-scope="data">
        <span v-if="data.item.payrollModType == 'payaddative' && showHiddenPayrollMods"><span class="payroll-bright-red mr-2" v-if="checkIfOnRoster(data.item.id)" variant="danger">!</span></span> 
        <span :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
      </template>
      <template slot="payrollModDatetime" slot-scope="data">
        {{ getFormatedDateTime(data.item.payrollModDatetime)}}
      </template>
      <template slot="TargetUser" slot-scope="data">
        <span v-if="data.item.TargetUser" :id="'popoveruser-'+data.item.TargetUser.id">{{ data.item.TargetUser.firstName }}  {{ data.item.TargetUser.lastName }} </span>
         <b-popover
         v-if="data.item.TargetUser && !printOn"
          :target="'popoveruser-'+data.item.TargetUser.id"
          placement="right"
          :title="data.item.TargetUser.stationAssignment + '|' + data.item.TargetUser.shiftAssignment"
          triggers="hover focus"
          :content="'ID: ' + data.item.TargetUser.employeeId"
        >
        <div v-if="!printOn && false"><userCard :user="data.item.TargetUser" :specialitiesArray="data.item.TargetUser.deptSpecialties" :defaultShow="true" />
          </div>
        </b-popover>
        
        <br v-if="data.item.ExchangeUser">
        <span v-if="data.item.ExchangeUser">ExON: {{ data.item.ExchangeUser.firstName }}  {{ data.item.ExchangeUser.lastName }}</span>
      </template>
      <template slot="payrollModApproved" slot-scope="data">
        <b-badge :variant="getApprovalStatusColor(data.item.payrollModApproved)"><h6 class="mb-0">{{ getApprovalStatus(data.item.payrollModApproved) }}</h6></b-badge>
      </template>
      <template slot="ApprovalUser" slot-scope="data">
          <span v-if="data.item.ApprovalUser"> {{ data.item.ApprovalUser.lastName }}</span>
      </template>
      <template slot="Edit" slot-scope="data">
        <b-btn size="sm" variant="primary" @click="updatePayrollMod(data.item)">Update</b-btn>
        <b-btn size="sm" variant="danger" @click="deletePayrollMod(data.item)" v-if="userData.role == 'admin' && false">Delete</b-btn>
      </template>
    </b-table>
    </b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import html2canvas from 'html2canvas';
export default {
components: {
  'multiselect' : Multiselect,
  userCard
},
props: ['payrollCodesData', 'payrollModDailyRosterData', 'selectedDate', 'payAddativeTrackingArray'],
data() {
    return {
    start: {name: 'base', startTime: 1, endTime: 10},
    printOn: false,
    selectedDateHold: '',
    departmentLogoForPrint: '',
    groupByUser: false,
    showOnlyErrors: false,
    showHiddenPayrollMods: false,
    sortMods: [
      {name: 'firstSwap', startTime: 2, endTime: 3},
      {name: 'secondSwap', startTime: 4, endTime: 6},
      {name: 'thirdSwap', startTime: 8, endTime: 9}
    ],
    fields: [
      {key: 'payrollModCode', label: 'Code'},
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'},
      {key: 'payrollModRosterId', label: 'Roster Spot'},
      {key: 'payrollModTargetUserId', label: 'Target'},
      
      {key: 'payrollModApprovalUserId', label: 'Approved'},
      'Edit'
    ],
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    nonSpotModification: [
       'payaddative',
    ],
    modificationFilter: [
        'bank modification',
        'exchange',
        'scheduled leave',
        'unscheduled leave',
        'modified leave',
        'payaddative',
        'overtime',
        'unpaid leave',
    ],
    codeTypeOptions: [
        'bank modification',
        'exchange',
        'roster mod',
        'scheduled leave',
        'unscheduled leave',
        'modified leave',
        'payaddative',
        'overtime',
        'unpaid leave',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'exchange',
      'payaddative',
      'unpaid leave',
      'roster mod',
      'overtime',
    ]
  }
},
created () {
  //console.log(this.payrollModDailyRosterData)
  this.selectedDateHold = this.selectedDate
  this.onLoadImage(this.siteconfig.staticHost + 'banners/' + this.siteconfig.deptUrlSub + '/banner.png')
},
beforeDestroy(){
  	
},
watch: {
  selectedDate(newData){
    this.selectedDateHold = newData
    if(this.showHiddenPayrollMods){
           bus.$emit('tagHiddenPayMods')
    }
  },
  showOnlyErrors(newData){
    if(newData){
      this.showHiddenPayrollMods = true
      bus.$emit('tagHiddenPayMods')
    }
  }
},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
    currentShift(){
              var payroll = this.siteconfig.payroll
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = moment(this.selectedDate).diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
  },
    getTodaysDateForPrint(){
  return moment(this.selectedDate, 'YYYY-MM-DD').format('MM/DD/YY')
  },
  fieldsData(){ 
    var array = [
      {key: 'payrollModCode', label: 'Code'},
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'},
      {key: 'TargetUser', label: 'Target'},
    ]
    if(!this.printOn){
      array.push({key: 'payrollModApproved', label: 'Status'})
    }
    array.push( {key: 'ApprovalUser', label: 'Admin'})
    if(!this.printOn){
      array.push('Edit')
    }
    return array
  },
  getCurrentTimeForPrint(){
    return moment().format('HH:mm')
  },
  payrollMod(){
    var mods = _.cloneDeep(this.payrollModDailyRosterData)
    var mods = mods.filter(p => {
      return this.modificationFilter.indexOf(p.payrollModType) != -1 && p.payrollModTargetUserId != null
    })
    var prearray = mods.map(item => {
        var obj = item
        obj.TargetUser = this.getUserData(item.payrollModTargetUserId)
        if(obj.TargetUser){
        obj.TargetShift = obj.TargetUser && obj.TargetUser.shiftAssignment ? obj.TargetUser.shiftAssignment : ''
        obj.TargetParent = this.getTargetParent(obj.TargetUser.stationAssignment)
        obj.ExchangeUser = this.getUserData(item.payrollModExchangeUserId)
        obj.ApprovalUser = this.getUserData(item.payrollModApprovalUserId)
        obj.payrollModHours = Number.parseFloat(obj.payrollModHours)
        return obj
        } 
        obj.payrollModHours = Number.parseFloat(obj.payrollModHours)
        return obj
    })
  //  console.log(prearray)
    if(this.groupByUser){
    var array = _.orderBy(prearray, item => item.TargetUser.lastName + ' ' + item.TargetUser.employeeId, ['asc'])
    } else {
    var array = _.orderBy(prearray, ['payrollModDatetime', 'payrollModHours'], ['asc', 'asc'])
    }
    if(this.showOnlyErrors){
      return array.filter(p => this.checkIfOnRoster(p.id) && p.payrollModType == 'payaddative')
    } return array

  },
  testArray(){
    var rosterSpotStartVacant = [{start: 0, end: 24}]
    var fillVac = [{start: 4, end: 10}, {start: 21, end: 23}, {start: 12, end: 18},  {start: 23, end: 24}]
    var fixed = this.getFixed(rosterSpotStartVacant, fillVac)
    return fixed
  },

},  
mounted(){

},
watcher:{

},
methods: {
  turnOffPayadds(){
      this.showOnlyErrors = false
      this.showHiddenPayrollMods = false
      bus.$emit('tagHiddenPayMods')
  },
    checkIfOnRoster(id){
      var index = this.payAddativeTrackingArray.indexOf(id)
      if(index == -1){
        return true
      } return false
    },
    emitSelectedDate(){
   // console.error(this.selectedDate)
    this.$emit('updateSelectedDate', this.selectedDateHold)
    },
      onLoadImage(url){
      var vm = this
    //  console.log('NEW IMAGE')
    //  console.log('image loaded')
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        vm.departmentLogoForPrint = dataURL // .replace(/^data:image\/(png|jpg);base64,/, "")
      };
    },
    executePrintJS(){
      var printId = '#print-daily-mod'
      html2canvas(document.querySelector(printId)).then(canvas => {
       var image = canvas.toDataURL("image/jpeg")
       return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              style: 'img { width:1200px !important; }',
            })
   // 
    });
  },
    addNewPayrollMod(){
    var startDate = moment(this.selectedDate, 'YYYY-MM-DD').add(8, 'hours').toISOString()
     var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: this.userData.id, 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: 24, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '' }
                 bus.$emit('updatePayrollMod', dataObj)
      
  },
    getFixed(array, mods){
       function doTheLoop() {
         for(var a in array){
          var mod = mods.filter(p => p.start >= array[a].start && p.end <= array[a].end)
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(mod[m].start == array[a].start && mod[m].end == array[a].end){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(mod[m].start > array[a].start && mod[m].end == array[a].end){
                  array[a] = {start: array[a].start, end: mod[m].start}
                }
                // first == first && last < last
                if(mod[m].start == array[a].start && mod[m].end < array[a].end){
                  array[a] = {start: mod[m].end, end: array[a].end}
                }
                // first < first && last < last
                if(mod[m].start > array[a].start && mod[m].end < array[a].end){
                  array.push({start: mod[m].end, end: array[a].end})
                  array[a] = {start: array[a].start, end: mod[m].start}
                }
                return(true);
              }
             
         } 
        }
        return(false);   // done running the loop
      }
     while (doTheLoop()) {}
     console.log('Finished')
     return array
    },
    getApprovalStatus(data){
        return data == true ? 'Approved' : data == false ? 'Denied' : data == null ? 'Needs Attn' : ''
    },
    getApprovalStatusColor(data){
       return data == true ? 'success' : data == false ? 'danger' : data == null ? 'warning' : ''
    },
    getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user
        } else if (this.userData.role == 'admin'){
          return this.userData
        } else {
          return null
        }
      } else {
        return ''
      }
    },
    getTargetParent(station){
      var obj = this.stationsData.find(p => p.stationName == station)
      return obj.stationParent ? obj.stationParent : obj.stationName
    },
    getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    getFormatedDateTime(date){
      if(date){
        return moment(date).format('MM/DD/YY HH:mm')
      } else {
        return 'Unknown'
      }
    },
    deletePayrollMod(data){
    axios({
     method: 'delete',
     url: '/api/v1/staffing/payrollmod/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     bus.$emit('newPayrollModification')
       console.log(response.data)
   })

    },
    updatePayrollMod(data){
     // console.log(data)
        delete data.TargetUser
        delete data.TargetShift 
        delete data.TargetParent 
        delete data.ExchangeUser 
        delete data.ApprovalUser 
      bus.$emit('updatePayrollMod', data)
    },
  

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
