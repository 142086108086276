<template>
  <div id="page-wrapper">
    <div v-if="userDataCatch" :class=" !loginStatusToggle && siteconfig.calendarHideFire ? 'fullScreenSetup' : !loginStatusToggle && !siteconfig.calendarHideFire ? 'backgroundLogin' : ''"></div>
       <div v-if="userDataCatch" :class="!passwordResetToggle && siteconfig.calendarHideFire ? 'fullScreenSetup' : !passwordResetToggle && !siteconfig.calendarHideFire ? 'backgroundLogin' : ''"></div>
  <div v-if="loadApplication == 0 && !userDataCatch">
      <h3 class="mt-2">Almost There...</h3>
      <img src="/statportals.png" /> 
  </div>
  <div v-if="loadApplication == 1">
    <secondarymenu id="secondaryMenuWrapper" v-if="loginStatusToggle"></secondarymenu></div>
    <socialmedia class="quickLinks" v-if="showDeptInfoPanBoo"></socialmedia>
    <div id="underMainMenu" v-if="viewStatus == 'User'">
    <div id="subHeader" v-if="loginStatusToggle">
      <b-row><b-col cols="3" align="right" id="wideHeader">
    <img  class="imageWide" v-if="stationCalImages == ''" :src="siteconfig.staticHost + 'banners/' + siteconfig.deptUrlSub + '/banner.png'">
    <img class="imageSmall" v-if="stationCalImages == ''" :src="siteconfig.staticHost + 'banners/' + siteconfig.deptUrlSub + '/smallBanner.png'">
  </b-col>
    <b-col class="noPadCol"> 
    <mainmenu class="mainMenu"></mainmenu>
  </b-col></b-row>
    </div>
    
    <div id="pageContent" v-if="userDataCatch">
      <notification v-if="notificationData.length > 0" :showModal="notificationData.length > 0 ? true : false" :notification="notificationData[0]" />
      <viewPreplan v-if="viewThisPreplanBoolean" :viewpreplan="viewPreplanObj" />
      <loginModal v-if="passwordResetToggle" :loginStatusToggle="!loginStatusToggle"></loginModal>
       <resetpassword :token="this.resetToken" :loginStatusToggle="!passwordResetToggle" />
       <notifications v-if="loginStatusToggle" group="notesAlert" />
       <div v-if="getSessionData">
        <router-view v-if="loginStatusToggle" class="routerView"></router-view></div>
        <div  v-if="loginStatusToggle">
        <transition name="fade">
        <div v-if="sock" v-show="!messagePanelToggle" class="messagingTab" @click="setMessagePanelToggle" >
          <img v-show="false" class="messageIcon" title="Message Icon" src="/images/siteicons/message-icon.png" />
        <div  v-if="unreadMessages.length > 0 || unreadRoomMessages.length > 0 || unreadGroupMessages.length > 0" class="notificationWrapper" >
                    <b-badge class="messageNotifications" v-if="unreadMessages.length > 0" pill variant="primary"> {{ unreadMessages.length }} </b-badge>
                    <b-badge  class="messageNotifications" v-if="unreadRoomMessages.length > 0" pill variant="success"> {{ unreadRoomMessages.length }} </b-badge>
                    <b-badge  class="messageNotifications" v-if="unreadGroupMessages.length > 0" pill variant="info"> {{ unreadGroupMessages.length }} </b-badge>
                                 </div>
        </div>
        </transition>
        <transition name="slideButton">
                <div v-show="messagePanelToggle" class="panelOpen" @click="setMessagePanelToggle">
          <img class="messageIcon" title="Message Icon" src="/images/siteicons/message-icon.png" /> 
        <div  v-if="unreadMessages.length > 0 || unreadRoomMessages.length > 0 || unreadGroupMessages.length > 0" class="notificationWrapper" >
                    <b-badge class="messageNotifications" v-if="unreadMessages.length > 0" pill variant="primary"> {{ unreadMessages.length }} </b-badge>
                    <b-badge  class="messageNotifications" v-if="unreadRoomMessages.length > 0" pill variant="success"> {{ unreadRoomMessages.length }} </b-badge>
                    <b-badge  class="messageNotifications" v-if="unreadGroupMessages.length > 0" pill variant="info"> {{ unreadGroupMessages.length }} </b-badge>
                                 </div>
        </div>
        </transition>
        <transition name="slide">
        <div class="messagingPanel" v-show="messagePanelToggle">
        <chat v-if="userMessages"  :messagePanelStatus="messagePanelToggle" :sock="sock" @viewPreplan="viewPreplan" /> 
        </div>
        </transition>
        </div>
        <b-modal v-if="viewPreplanObj" v-model="viewPreplanModal" title="Preplan View Detected">
          <h6>Would you like to open this preplan?</h6>
          <b-container v-if="viewPreplanObj.preplanTableData && viewPreplanObj.preplanTableData.length > 0">
          <b-row v-for="data in viewPreplanObj.preplanTableData" v-show="data.key != 'map'" :key="data.key">
            <b-col cols="*" class="font-weight-bold">{{ data.label }}</b-col>
            <b-col>{{ data.value }}</b-col>
          </b-row>
          </b-container>
          <template slot="modal-footer">
            <b-btn variant="primary" @click="viewThisPreplan(viewPreplanObj)" block>VIEW THIS PREPLAN</b-btn>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import MonthlyMed from './components/addNew/newMonthlyMed.vue'
import SecondaryMenu from './components/SecondaryMenu.vue'
import MainMenu from './components/MainMenu.vue'
import LoginModal from './components/inputfields/loginModal.vue'
import PasswordReset from './components/inputfields/passwordReset.vue'
import SocialMedia from './components/secondarymenu/socialmediaicons.vue'
import Chat from './components/tracking/chat/chat.vue'
import NotificationMod from './components/tracking/blogs/notification.vue'
import { bus } from './main'
import moment from 'moment'
import socketio from 'socket.io-client'
// import siteconfigimport from '../config.json'
import Preplan from "./components/portals/preplans/preplan.vue";
export default {
  name: 'app',
  components: { 
    'secondarymenu': SecondaryMenu,
    'mainmenu' : MainMenu,
    'loginModal' : LoginModal,
    'socialmedia' : SocialMedia,
    'monthlymed' : MonthlyMed,
    'resetpassword' : PasswordReset,
    'chat' : Chat,
    'notification' : NotificationMod,
    'viewPreplan' : Preplan,
  },
  data(){
  return {
      closeEventTrigger: true,
      showDeptInfoPanBoo: false,
      showMedModal: false,
      stationName: '',
      sock: null,
      menuSelect: '',
      messagePanelToggle: false,
      loginStatusToggle: '',
      userDataCatch: false,
      showAdminMenu: false,
      viewStatus: 'User',
      adminOptionToggle: false,
      resetToken: '',
      passwordResetToggle: true,
      viewPreplanObj: null,
      viewPreplanModal: false,
      viewThisPreplanBoolean: false,
      messageArray: [],
      taxonomyPull: ['station', 'vehicletype', 'tutorial', 'apparatus', 'trainingref', 'blogtype', 'checkdetails', 'checkitems', 'deptrank', 'deptspec', 'documenttype', 'eventtype', 'medication', 'workgroup', 'repairstatus', 'shiftrotation', 'logisticasset']
      }
  },

  created(){
    // created text //
     this.$store.dispatch('LOAD_SITE_CONFIG')
     var tokenTest = this.$cookie.isKey('userToken')
      var token = this.$cookie.get('userToken')
      var thisRoute = this.$route.path
      var spliceRoute = thisRoute.slice(0, 6)
      if(tokenTest == false && spliceRoute != '/passw'){
       this.$nextTick(function(){
        this.loginStatusToggle = false
        this.userDataCatch = true
        })
      }
      if(spliceRoute == '/passw'){
        this.$nextTick(function(){
        var token = this.$route.fullPath
        this.resetToken = token.replace('/passwordreset/?_', '')
        this.passwordResetToggle = false
        this.loginStatusToggle = ''
        this.userDataCatch = true
      })}
      else {
      var token = this.$cookie.get('userToken')
    //  console.log('USER COOKIE PERSIST')
      this.$store.dispatch('LOAD_LOGIN_STATUS', token).then(
        (response) => {
    
          if(response != ''){
            this.setLoginReponseTrue(token)

          } else {
      
            this.loginStatusToggle = false
            this.passwordResetToggle = true
            this.$nextTick(function(){
            this.userDataCatch = true
            })      
            }
          },
          (response) => {
            if(response != undefined){
               this.$cookie.remove('userToken')
            //   console.log(response)
              this.$store.dispatch('REFRESH_LOGIN_TOKEN', response)
               .then(
                (response) => {
                  if(response.accessToken){
                 var token = response.accessToken
                        this.$store.dispatch('LOAD_LOGIN_STATUS', token).then(
                         (response) => {
    
                          if(response != ''){
                            this.setLoginReponseTrue(token)

                            } else {
      
                            this.loginStatusToggle = false
                            this.passwordResetToggle = true
                            this.$nextTick(function(){
                            this.userDataCatch = true
                            })      
                            }
                            },
                           (response) => {
                            this.loginStatusToggle = false
                            this.passwordResetToggle = true
                            this.$nextTick(function(){
                            this.userDataCatch = true
                              })
                      
  
                })}
                else {
            //      console.log('Persisted')
                }},
                (response) => {
                  this.$cookie.remove('userToken')
                  this.loginStatusToggle = false
                  this.passwordResetToggle = true
                  this.$nextTick(function(){
                  this.userDataCatch = true })
                })
            } else {
            this.$cookie.remove('userToken')
            this.loginStatusToggle = false
            this.passwordResetToggle = true
            this.$nextTick(function(){
            this.userDataCatch = true
           })
            }

          })
      
      bus.$on('showDeptInfoPan', this.showDeptInfoPan)
      bus.$on('stationSelectName', this.setStationName)
      bus.$on('homeSelect', this.setHome)
      bus.$on('refreshUserPosts', this.userPostsPull)
      bus.$on('toggleMessagePanel', this.setMessagePanelToggle)
      bus.$on('sendUserMessage', this.sendUserMessage)
      bus.$on('closePreplanResponseView', this.closePreplanResponseView)
      }
  },
  mounted(){

  },
  computed: {
      ...mapState([
        'loginStatus',
        'userData',
        'stationCalImages',
        'userData',
        'loadApplication',
        'getSessionData',
        'userMessages',
        'unreadMessages',
        'unreadRoomMessages',
        'unreadGroupMessages',
        'notificationData',
        'siteconfig',
        'updateTable'
          ]),
  },
  watch: {
    updateTable(newData){
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData,  'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
                for(var i = 0; i < this.taxonomyPull.length; i++){
                  var payload = { taxonomy: this.taxonomyPull[i], headers: headers}
                  this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
                }         
    },
    userData(newData){
      if(this.userData != '' && this.userData != undefined){
    //    console.log('WATCHERS DATA')
        this.userDataRole(newData)
        this.$store.dispatch('SET_USER_LOGIN_STATUS')
         this.userPostsPull()
        setTimeout(() => {
        this.passwordResetToggle = true
        this.loginStatusToggle = true
        this.$nextTick(function(){
          this.userDataCatch = true
        }) }, 100)
      } else {
        this.loginStatusToggle = false
        this.adminOptionToggle = false

      }
    },
    loginStatus(newData){
      if(newData == 0){
        this.$router.push({ path: '/' })
        this.viewStatus = 'User'
        this.adminOptionToggle = false
        this.loginStatusToggle = false
        this.messagePanelToggle = false
        this.$nextTick(function(){
        this.userDataCatch = true
        })      }
    }

  },
  methods: {
    sendUserMessage(user){
      this.messagePanelToggle = true
    },
    viewPreplan(preplan){
      this.viewPreplanObj = preplan
      this.viewPreplanModal = true
    },
    viewThisPreplan(data){
      this.viewPreplanModal = false
      this.viewThisPreplanBoolean = true
    },
    closePreplanResponseView(){
      this.viewThisPreplanBoolean = false
    },
    checkResponsePlans(){
      // CHECK CURRENT ACTIVE PLANS
    },
    setLoginReponseTrue(token){
                  var headers = { headers: { 'content-type': 'application/json', 'Authorization': 'bearer ' + token,  'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
                this.$store.dispatch('LOAD_TABLE_UPDATE_DATA', headers)
         //     for(var i = 0; i < this.taxonomyPull.length; i++){
           //       var payload = { taxonomy: this.taxonomyPull[i], headers: headers}
             //     this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
            //    }         
                this.$store.dispatch('LOAD_CONTACT_DATA_EXP', headers)
         //       console.log('SET LOGIN RESPONSE')
                  
               // this.userPostsPull(headers)
                this.loginStatusToggle = true
                this.$nextTick(function(){
                this.userDataCatch = true
            })
    },
    setMessagePanelToggle(){
          this.messagePanelToggle = !this.messagePanelToggle
    },
    userPostsPull(){
                  this.sock = socketio({
                  path: '/api/sock/socket.io/',
                  // transports: ['websocket'], 
                  reconnectionDelayMax: 10000,
                  query: {
                      token: this.getSessionData},
                      //autoConnect: false
                      })
                  this.checkResponsePlans()
                  this.$store.dispatch('CLEAR_USER_POSTS')
                  this.$store.dispatch('CLEAR_USER_MESSAGES')
                  this.$nextTick(function(){
                  var token = this.getSessionData
                  var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': token,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
                  var postVar = []
                  if(this.userData != ''){
                  if(this.userData.role == 'admin'){
                    var postVar = ['logbook', 'dailyvehicle', 'repairform', 'event']}
                  if(this.userData.role == 'adminchief'){
                    var postVar = ['logbook', 'dailyvehicle', 'repairform', 'event']}                         
                  if(this.userData.role == 'fireops'){
                    var postVar = ['logbook', 'dailyvehicle', 'repairform', 'event']}
                  if(this.userData.role == 'emsops'){
                    var postVar = ['logbook', 'dailyvehicle', 'repairform', 'event']}
                  if(this.userData.role == 'supervisor'){
                    var postVar = ['logbook', 'dailyvehicle', 'repairform', 'event']}    
                  if(this.userData.role == 'user'){
                    var postVar = ['logbook', 'dailyvehicle', 'repairform', 'event']}                                         
                } else {
                    var postVar = []
                }
                var index = this.userData.deptSpecialties.indexOf('PIC')
                  if(index >= 0){
                    postVar.push('narctrack')
                    postVar.push('dailynarctrack')
                  }
                  if(this.userData.blogAccess == true){
                    postVar.push('blog')
                  }
                  for(var i = 0; i < postVar.length; i++){
                    var payload = { id: this.userData.id, contentType: postVar[i], headers: headers }
                    this.$store.dispatch('LOAD_USER_POSTS_EXP', payload)
                  }
                   var payload = { id: this.userData.id, headers: headers }
                   if(moment().format('HHmm') > 745 &&  moment().format('HHmm') < 805){
                    setTimeout(()=> {
                      this.$store.dispatch('LOAD_USER_NOTIFICATIONS_EXP', payload)
                    }, 1000 * 60 * 60);
                  } else {
                  this.$store.dispatch('LOAD_USER_NOTIFICATIONS_EXP', payload)
                  }
                  this.$store.dispatch('LOAD_DEPT_BLOG_EXP', payload)
                  // this.$store.dispatch('LOAD_FORMTEMP_EXP', headers)
                  this.$store.dispatch('LOAD_BANNER_IMAGES_EXP', payload)
                  this.getActiveUsers(payload)
                  this.getUserMessages(payload)
                  this.$store.dispatch('LOAD_TRAINING_MODULES', payload)
                  this.$store.dispatch('LOAD_MEDICATION_DATA', payload)
                  this.$store.dispatch('LOAD_INVENTORY_TEMPLATES', payload)
                  if(this.siteconfig && this.siteconfig.loginRedirect){
                    this.$router.push({ path: this.siteconfig.loginRedirect })
                  }
               //   this.getRoomData(payload)
               //   this.getGroupData(payload)
                        })
    },
    getActiveUsers(payload){
      this.$store.dispatch('LOAD_ACTIVE_USERS', payload)
    },
    getUserMessages(payload){
   //   console.log('GET USER MESSAGES')
        this.$store.dispatch('LOAD_USER_MESSAGES_EXP', payload)
    },
    getRoomData(payload){
      this.$store.dispatch('LOAD_ROOMS_EXP', payload)
    },
    getGroupData(payload){
      this.$store.dispatch('LOAD_GROUP_MESSAGES_EXP', payload)
    },
    userDataRole(newData){
      if(newData == '[]'){
        this.adminOptionToggle = false
      }
      if(newData != '' ){
    
        this.adminOptionToggle = true
      }
      else {
        this.adminOptionToggle = false
      }

    },
    setStationName(data){
      this.menuSelect = 'station'
      this.stationName = data
    },
    setHome(){
      this.menuSelect = ''
    },
    closeEvent(event){
      this.closeEventTrigger = !this.closeEventTrigger
    },
    showDeptInfoPan(){
      this.showDeptInfoPanBoo = !this.showDeptInfoPanBoo
    },

  }
}
</script>
<style>
@media screen and (max-width: 786px) {
.modal-open {
  position: fixed;
  width: 100%;
}
}
</style>
<style scoped>
.slide-enter { transform: translateX(100%);
              margin-right: -266px;
                }
.slide-enter-to { transform: translateX(0);
                }
.slide-enter-active { position: absolute }

.slide-leave { transform: translateX(0) }
.slide-leave-to { transform: translateX(100%);
               margin-right: -266px; }

.slide-enter-active,
.slide-leave-active { transition: all 400ms ease-in-out }
.slideButton-enter { transform: translateX(100%);
              margin-right: -266px;
                }
.slideButton-enter-to { transform: translateX(0);
                }
.slideButton-enter-active { position: absolute }
.slideButton-enter-active { transition: all 400ms ease-in-out }
.fade-enter-active {
  transition: all 1.5s ease;
}
.fade-enter {
  opacity: 0;
}
.messageNotifications {
  box-shadow: 3px 5px 10px #777;
}
.notificationWrapper {
  margin-top: -10px;
}
.messagingTab {
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    z-index: 7000;
}
.panelOpen {
    position: fixed;
    bottom: 20px;
    cursor: pointer;
    z-index: 7000;
    right: 362px;
}
.messageIcon {
  box-shadow: 3px 5px 10px #777;
  border-radius: 50%;
  width: 75px;
  height: auto;
  padding: 0;
  margin: 0;
}
.messagingPanelBak {
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 70%;
}
.messagingPanel {
  width: 362px;

  position: fixed;
  border-radius: 5px 0 0 5px;
  right: -12px;
  padding-right: 3px;
  bottom: 0;
  z-index: 9999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.adminRouterWrap {
  overflow-y: scroll;
  position: fixed;
  padding-right: 10px;
  bottom: 0;
  top: 70px;
  right: 0;
  left: 160px;
}
.adminToggle {
  position: fixed;
  top: 8px;
  left: 8px;
  z-index: 1001;
}
.modal-open {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
}
.col.noPadCol {
  padding-right: 0;
}
.imageSmall {
  display: none;
}
.routerView {
  margin-top: 20px;
}
#secondaryMenuWrapper {
  position: fixed;
  min-width: 100%;
  z-index: 1000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
}
#underMainMenu {
  padding-top: 50px;
}
.quickLinks {
  background: #ddd;
  padding: 10px;
  width: auto;
  position: fixed;
  margin-top: 50px;
  right: 0;
  z-index: 1000;
  border: 1px solid #777;
  border-top: 0;
  border-bottom-left-radius: 10px;
}
#page-wrapper {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
.fullScreenSetup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:url('/images/MailBG.jpg') center top no-repeat; 
    background-size: cover;
}
.backgroundLogin{
 background:url('https://statportals-static.nyc3.digitaloceanspaces.com/images/background-login-image.jpg') center top no-repeat; 
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#pageContent{
  width: 80%;
  margin: 0 auto 0;
  padding-bottom: 50px;
  border-bottom: 1px solid;
}
#subHeader{
  width: 80%;
  margin: 0 auto 0;
}
#subHeader img {
  width: auto;
  max-height: 200px;
  margin-top: 10px;
}
#shortHeader {
  display: none;
}
.mainMenu {
  margin: 40px 10px 0 0;
  float: right;
  
}
@media screen and (max-width: 1600px) {
  #subHeader{
    width: 90%;
    margin: 0 auto 0;
  }
  #subHeader img {
    width: auto;
    max-height: 125px;
    margin-top: 10px;
  }
  #pageContent{
    width: 90%;
    margin: 0 auto 0;
    padding-bottom: 60px;
    overflow-x: none;
}

}
@media screen and (max-width: 1400px) {

  #subHeader{
    width: 95%;
    margin: 0 auto 0;
  }
  #subHeader img {
    width: auto;
    max-height: 125px;
    margin-top: 10px;
  }
  #pageContent{
    width: 95%;
    margin: 0 auto 0;
    padding-bottom: 60px;
    overflow-x: none;
}

}
@media screen and (max-width: 1200px) {

  #subHeader{
    width: 90%;
    margin: 0 auto 0;
  }
  #subHeader img {
    width: auto;
    max-height: 125px;
    margin-top: 10px;
  }
  #pageContent{
    width: 100%;
    margin: 0 auto 0;
    padding-bottom: 60px;
    overflow-x: none;
}

}
@media screen and (max-width: 1050px) {
  .imageWide{
    display: none;
  }
  .imageSmall{
    display: block;
  }
}
@media screen and (max-width: 920px) {
  .messagingPanel {
  width: 362px;
  right: -12px;
  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  position: fixed;
  background: #fff;
  bottom: 0;
  z-index: 9999;
  }
  #subHeader{
    width: 100%;
    margin: 0 auto 0;
  }
  .routerView {
    margin-top: 0px;
  }
  #shortHeader {
    display: block;
  }
}
@media screen and (max-width: 768px) {
    .messagingPanel {
  width: 362px;

  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  position: fixed;
  background: #fff;
  
  bottom: 48px;
  z-index: 999;
  }
  .notificationPanel {
    display: none;
  }
  .messagingTab {
    display: none;
  }
  .adminToggle {
  position: fixed;
  top: 10px;
  left: unset;
  right: 50px;
  z-index: 1001;
  }
  .adminUl {
  margin: 0 auto 0;
}
.adminRouterWrap {
  width: 100%;
  overflow-y: scroll;
  position: fixed;
  padding: 0 10px;
  bottom: 0;
  top: 70px;
  right: 0;
  left: unset;
}
#underMainMenu {
    padding-top: 0;
  }
 .mainMenu {
   width: 100%;
   margin-top: 0;
   float: none;
 }
 #pageContent{
   padding-top: 54px;
 }
 #subHeader {
   position: absolute;
   top: 0;
   z-index: 600;
   overflow-x: hidden;
 }
 .quickLinks {
   background: #ddd;
   padding: 10px;
   width: 100%;
   margin-top: 54px;
   position: absolute;
   right: 0;
   z-index: 500;
   border: 1px solid #777;
   border-top: 0;
   border-radius: 0;
 }
 #wideHeader {
   display: none;
 }

 #shortHeader {
   display: none;
 }
.notificationWrapper {
  display: none;
}
 .messageIcon {
   display: none;
 }
}
@media screen and (max-width: 480px) {
  .messagingPanel {
  width: 100%;
  height: 80%;
  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  position: fixed;
  background: #fff;
  right: 0;
  bottom: 48px;
  z-index: 999;
  }

}
@media screen and (max-width: 380px) {
  .messagingPanel {
  width: 100%;
  height: 90%;
  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  position: fixed;
  background: #fff;
  right: 0;
  bottom: 48px;
  z-index: 999;
  }

}
/* Let's get this party started */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(138,138,138,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(138,138,138,0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(138,138,138,0.4);
}


</style>
