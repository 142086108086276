<template>
<div id="surroundDiv">
  <div v-if="selectedUser">
 <h5> USER: {{ employee.firstName }} {{  employee.lastName }} | <em>ID: {{ employee.employeeId }}</em></h5>
  <b-card header="Current Shifts" class="my-2">
    <b-container fluid>
    <h6 class="text-danger" v-if="availableSchedules && availableSchedules.length == 0">No Currently Scheduled Shifts</h6>
    <b-card v-for="sch in availableSchedules" :key="sch.id" header-bg-variant="secondary" header-text-variant="light">
      <div slot="header">
        {{ getDateFormat(sch.date) }} <b-btn v-if="sch.auto" variant="primary" class="float-right" size="sm" @click="autoCompleteTimeCard(sch)" :disabled="currentClockInCard && currentClockInCard.length > 0">Auto</b-btn>
      </div>
      <b-row class="ml-2">
        <b-col cols="*"><span class="font-weight-bold">Start:</span></b-col><b-col align="left">{{ getTime(sch.startTime) }}</b-col>
        <b-col cols="*" align="right"><span class="font-weight-bold">End:</span></b-col><b-col align="left">{{ getTime(sch.endTime) }}</b-col>
        </b-row>
        <b-row>
          <b-col v-if="sch.lunch" aligm="left"><span class="font-weight-bold">Scheduled Lunch:</span> {{ sch.lunch }} Hrs</b-col>
        </b-row>
    </b-card>
    <div v-if="checkForWarningMessages">
      {{ checkForWarningMessages }}
      </div>
    <b-card v-for="clo in currentClockInCard" :key="clo.id" :title="'Open TimeCard: ' + getSimpleDate(clo.clockInTime)">
      <b-row class="ml-2"><b-col cols="*">Clocked In:</b-col><b-col align="left">{{ getTime(clo.clockInTime) }}</b-col>
      <b-col><b-btn :variant="taggedForClockout == clo.id ? 'success' : 'primary' " size="sm" @click="taggedForClockout == clo.id ? setTaggedForClockout(null) : setTaggedForClockout(clo.id)">{{ taggedForClockout == clo.id ? 'Un-tag' : 'Tag to Clock-out' }}</b-btn></b-col></b-row>
    </b-card>
    </b-container>
  </b-card>
  <div :id="uniqueId"></div>
  <b-btn class="mb-2" variant="info" size="sm" block @click="getUserGPSLocation()">Get or Reset Location</b-btn>
  <b-form-input v-if="!autoPostArray" type="date" v-model="timecardDate" />
  <b-form-input v-if="!autoPostArray" type="time" v-model="timecardTime" />
  <b-container v-if="autoPostArray">
    <b-row v-for="(a, index) in autoPostArray" :key="index">
      <b-col>Clock In: {{ getTime(a.clockInTime) }}</b-col>
        <b-col>Clock Out: {{ getTime(a.clockOutTime) }}</b-col>
    </b-row>
  </b-container>
  <b-textarea placeholder="Timecard Comment" v-model="timeCardComment" />
    <b-btn v-if="!taggedForClockout && !autoPostArray" variant="primary" @click="timeCardClock('clockin')">Clock-In</b-btn>
     <b-btn v-if="taggedForClockout && !autoPostArray" variant="success" @click="timeCardClock('clockout')">Clock-Out</b-btn>
     <b-btn v-if="clockOutAutoLunch" @click="submitAutomaticLunchCards()">Clock-Out (Auto Lunch) </b-btn>
     <b-btn v-if="autoPostArray" variant="primary"  @click="submitAutomaticTimeCards(autoPostArray)">Submit Automatic TimeCards</b-btn>
    </div>
</div>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import Leaf from 'leaflet';
import * as esri from "esri-leaflet";
import moment from 'moment';
import axios from 'axios'
export default {
  name: 'time-card',
  props: ['employee', 'schedules', 'openTimeCardObj'],
  components: { 

  },
  data(){
  return {
    showMap: false,
    availableSchedules: [],
    uniqueId: '',
    stateLocation: '',
    selectedUser: null,
    timecardDate: '',
    timecardTime: '',
    autoPostArray: null,
    lunchThreshold: null,
    autoLunch: null,
    lunchLength: null,
    gpsLocationData: '',
    currentClockInCard: '',
    taggedForClockout: '',
    timeCardComment: '',

  }
  },
  created(){
      this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 16);
      var user = this.employee && this.employee.id ? this.employee : this.getEmployee(this.employee)
      this.selectedUser = user
      this.timecardDate = moment().format('YYYY-MM-DD')
      this.timecardTime = moment().format('HH:mm')
      if(this.openTimeCardObj){
        var firstObj = this.openTimeCardObj
        if(firstObj && firstObj[0]){
          if(firstObj[0].date){
              this.timecardDate = firstObj[0].date
          }
          if(firstObj[0].startTime){
              this.timecardTime = firstObj[0].startTime
          }
        }
        this.getUserSchedule(user.id)
        this.loadOpenTimeCards(user.id)
      } else if (user && user.id){
      this.getUserSchedule(user.id)
      this.loadOpenTimeCards(user.id)
      }
      if(!navigator.geolocation) {
      this.stateLocation = 'Geolocation is not supported by your browser';
      } else {
      //  status.textContent = 'Locating…';
      this.checkLocationPermissions()
      }
  },
  mounted(){

  },
  computed: {
      ...mapState([
        'deptContactData',
        'siteconfig',
        'userData',
        'getSessionData',
      ]),
      checkForWarningMessages(){
        if(this.availableSchedules && this.availableSchedules.length > 0 && !this.taggedForClockout){
          var schedule = this.availableSchedules[0]
          if(schedule.startTime){
            var current = moment(this.timecardDate + this.timecardTime, 'YYYY-MM-DD HH:mm')
            var diff = moment(schedule.startTime).diff(current, 'minute')
            if(diff > 0){
              return "Clocking In Early will require prior manager approval, otherwise time will be adjusted to scheduled starttime"
            }
          }
          return false
        } 
        if(this.availableSchedules && this.availableSchedules.length > 0 && this.taggedForClockout){
          var schedule = this.availableSchedules[0]
          if(schedule.endTime){
            var current = moment(this.timecardDate + this.timecardTime, 'YYYY-MM-DD HH:mm')
            var diff = current.diff(moment(schedule.endTime), 'minute')
            console.log(schedule)
            console.log(current)
            console.log(schedule.endTime)
            console.log(diff)
            if(diff > 0){
              return "Clocking Out Late will require prior manager approval, otherwise time will be adjusted to scheduled endtime"
            }
          }
          return false
        }return "No Current Schedules for this clock in period"
      },
      clockOutAutoLunch(){
        if(this.taggedForClockout && this.lunchThreshold && this.autoLunch){
          var clockIn = this.currentClockInCard.find(p => p.id == this.taggedForClockout)
          if(clockIn){
            var start = moment(clockIn.clockInTime)
            var end = moment(this.timecardDate + this.timecardTime, 'YYYY-MM-DD HH:mm')
            var diff = end.diff(start, 'hours', true)
            if(diff >= this.lunchThreshold){
            return true
            }
            return false
          } return false
        } return false

      }
  },
  watch: {
    timecardDate(newData){
      var user = this.employee && this.employee.id ? this.employee : this.getEmployee(this.employee)
      if(user){
      this.getUserSchedule(user.id)
      this.loadOpenTimeCards(user.id)
      }
    },
    timecardTime(newData){
      var user = this.employee && this.employee.id ? this.employee : this.getEmployee(this.employee)
      if(user){
      this.getUserSchedule(user.id)
      this.loadOpenTimeCards(user.id)
      }
    }
  },
  beforeDestroy(){
        document.body.classList.remove('modal-open');
  },
  methods: {
    setTaggedForClockout(id){
      if(id){
        this.taggedForClockout = id
        if(this.availableSchedules && this.availableSchedules[0]){
          var schedule = this.availableSchedules[0]
          console.log(schedule)
          if(schedule.autoLunch && schedule.lunchThreshold){
            this.autoLunch = schedule.autoLunch
            this.lunchThreshold = schedule.lunchThreshold
            this.lunchLength = schedule.lunch

          }
       } 
        var currentComment = this.currentClockInCard.find(p => p.id)
        if(currentComment && currentComment.timecardComment){
          this.timeCardComment = currentComment.timecardComment
        }
      } else {
        this.taggedForClockout = ''
        this.timeCardComment = ''
        this.autoLunch = null
        this.lunchThreshold = null
        this.lunchLength = null
      }
    },
    checkToUpdateTime(data){

    },
    getDateFormat(date){
      if(date){
        return moment(date).format('ddd, MMM Do YYYY')
      } return "none"
    },
    getSimpleDate(date){
      if(date){
        return moment(date).format('MM/DD/YY')
      } return "none"
    },
    getTime(time){
      if(time){
        return moment(time).format('HH:mm A')
      } return "none"
    },
    getUserSchedule(id){
    //  console.log(this.schedules)
      if(this.schedules){
        var filter = this.schedules.filter(p => {
            var start = moment(p.startTime).subtract(1, 'hour')
            var end = moment(p.endTime).add(1, 'hour')
            return moment(this.timecardDate + this.timecardTime, 'YYYY-MM-DD HH:mm').isBetween(start, end)
        })
        this.availableSchedules = filter
        this.checkToUpdateTime(filter)
      }
    },
    getEmployee(id){
      var obj = this.deptContactData.find(p => p.id == id)
      if(obj){
        return null
      }
    },
    viewMapBuild(){
      if(this.gpsLocationData && this.gpsLocationData.coords && this.gpsLocationData.coords.latitude){
      this.showMap = true
      var data = {}
      data['latlng'] = {lat: this.gpsLocationData.coords.latitude, lng: this.gpsLocationData.coords.longitude}
      this.$nextTick(function(){
        this.setMap(data)
      })
      }
    },
    setMap(point){
        var center = point.latlng
        var zoom = 18
        document.getElementById(this.uniqueId).style.height = document.getElementById("surroundDiv").clientWidth + 'px'
        document.getElementById(this.uniqueId).style.width = document.getElementById("surroundDiv").clientWidth + 'px'
        var string = "<div id='mapSpot-" + this.uniqueId + "' style='width: 100%; height: 100%;'></div>"
        document.getElementById(this.uniqueId).innerHTML = string
        var map = Leaf.map('mapSpot-' + this.uniqueId, {
                dragging: false,
                tap: false
        }).setView(center, zoom);
        esri.tiledMapLayer({
            url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer',
            maxZoom: 24,
            maxNativeZoom: 19
        }).addTo(map)
        Leaf.marker(center).addTo(map);
    },
    checkLocationPermissions(){
    var vm = this
    navigator.permissions.query({
     name: 'geolocation'
      }).then(function(result) {
     if (result.state == 'granted') {
       vm.stateLocation = result.state
       console.log(result.state)
        vm.getUserGPSLocation()
     } else if (result.state == 'prompt') {
        vm.stateLocation = result.state
        console.log(result.state)
        vm.getUserGPSLocation()
     } else if (result.state == 'denied') {
        vm.stateLocation = result.state
        console.log('DENIED')
     }
    });
    },
    showUserGPSPosition(position){
      this.gpsLocationData = position
      this.$nextTick(function(){
        //this.showMap = true
        this.viewMapBuild()
      })
    //  console.log(position)
    },
    getUserGPSLocation() {
     // console.log(navigator.geolocation)
    if (navigator.geolocation) {
      var vm = this
        navigator.geolocation.getCurrentPosition(function(data, error){
          if(data){
          vm.showUserGPSPosition(data)
          } else if (error){
            vm.stateLocation = error
            console.log(error)
          }})
    }  
    },
    loadOpenTimeCards(id){
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrolltimecard/opentimecard/' + id,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {
          this.currentClockInCard = response.data
      })
    },
    autoCompleteTimeCard(data){
      var diffHours = moment(data.endTime).diff(moment(data.startTime), 'hours', true)
      if(data.lunch){
        var lunch = data.lunch
        diffHours = diffHours - parseFloat(data.lunch)
      } else {
        var lunch = 0
      }
      diffHours = diffHours/2
      var clockInFirst = moment(data.startTime)
      var clockOutFirst = clockInFirst.clone().add(diffHours, 'hours')
      var clockInSecond = clockOutFirst.clone().add(lunch, 'hours')
      var clockOutSecond = clockInSecond.clone().add(diffHours, 'hours')
      var autoCardArray = [
        {
            clockInTime: clockInFirst.toISOString(),
            clockInActual: moment().toISOString(),
            clockOutTime: clockOutFirst.toISOString(),
            clockOutActual: moment().toISOString(),
            clockInGps: {coords: 
                            {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            clockOutGps: {coords:
                           {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            timecardUserId: this.selectedUser.id,
            timecardComment: this.timeCardComment
        },
        {
            clockInTime: clockInSecond.toISOString(),
            clockInActual: moment().toISOString(),
            clockOutTime: clockOutSecond.toISOString(),
            clockOutActual: moment().toISOString(),
            clockInGps: {coords: 
                            {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            clockOutGps: {coords:
                           {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            timecardUserId: this.selectedUser.id,
            timecardComment: this.timeCardComment
        }
      ]
      this.autoPostArray = autoCardArray
    },
    submitAutomaticTimeCards(data){
      for(var i = 0; i < data.length; i++){
      axios({
        method: "post",
        url: '/api/v1/staffing/payrolltimecard/',
        data: data[i],
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {

      })
      if(i + 1 == data.length){
        this.$nextTick(function(){
          bus.$emit('closeTimeCard')
        })
        }
      }
    },
    submitAutomaticLunchCards(){
        var axiosArray = []
        var currentClockInCardObj = _.cloneDeep(this.currentClockInCard.find(p => p.id == this.taggedForClockout))
        var historyHoldArray = currentClockInCardObj.timecardHistory
        var startTime = currentClockInCardObj.clockInTime
        var lunch = this.lunchLength
        console.log(lunch)
        var currentEnd = moment(this.timecardDate + this.timecardTime, 'YYYY-MM-DD HH:mm')
        var diffHours = moment(currentEnd).diff(moment(startTime), 'hours', true)
        console.log(diffHours)
        diffHours = diffHours - parseFloat(lunch)
        diffHours = diffHours/2
        console.log(diffHours)
        var patchEnd = moment(startTime).add(diffHours, 'hours').toISOString()
        var postStart = moment(patchEnd).add(lunch, 'hours').toISOString()
        var postEnd = moment(postStart).add(diffHours, 'hours').toISOString()
        console.log(patchEnd)
        console.log(postStart)
        console.log(postEnd)
        if(!historyHoldArray){
          historyHoldArray = []
        }
        var atype = "patch"
        var aurl = '/api/v1/staffing/payrolltimecard/' + this.taggedForClockout
        var adata = {
            clockOutTime: patchEnd,
            clockOutActual: moment().toISOString(),
            clockOutGps: {coords: 
                            {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            timecardComment: this.timeCardComment
          //  timecardUserId: this.selectedUser.id
        }
        var historyHold = _.cloneDeep(adata)
        historyHold['userSubId'] = this.userData.id
        historyHold['subDate'] = moment().toISOString()
        historyHoldArray.push(historyHold)
        adata['timecardHistory'] = historyHoldArray
        var postObj = {
          type: atype,
          url: aurl,
          data: adata
        }
        axiosArray.push(postObj)
        var btype = "post"
        var burl = '/api/v1/staffing/payrolltimecard/'
        var bdata = {
            clockInTime: postStart,
            clockInActual: moment().toISOString(),
            clockInGps: {coords: 
                            {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            timecardUserId: this.selectedUser.id,
            clockOutTime: postEnd,
            clockOutActual: moment().toISOString(),
            clockOutGps: {coords: 
                            {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            timecardComment: this.timeCardComment
        }
        var bhistoryHold = _.cloneDeep(bdata)
        bhistoryHold['userSubId'] = this.userData.id
        bhistoryHold['subDate'] = moment().toISOString()
        historyHoldArray.push(bhistoryHold)
        bdata['timecardHistory'] = historyHoldArray
        var postObjb = {
          type: btype,
          url: burl,
          data: bdata
        }
        axiosArray.push(postObjb)      
        for(var i = 0; i < axiosArray.length; i++){
            axios({
              method: axiosArray[i].type,
              url: axiosArray[i].url,
              data: axiosArray[i].data,
              headers: {
                'Authorization': this.getSessionData,
                'Content-Type' : 'application/json',
              }
            }).then(response => {

            })
            if(i + 1 == axiosArray.length){
              this.$nextTick(function(){
                bus.$emit('closeTimeCard')
              })
              }
            }
    },
    timeCardClock(type){
      if(type == 'clockin'){
        var atype = "post"
        var aurl = '/api/v1/staffing/payrolltimecard/'
        var adata = {
            clockInTime: moment(this.timecardDate + " " + this.timecardTime, "YYYY-MM-DD HH:mm:ss").toISOString(),
            clockInActual: moment().toISOString(),
            clockInGps: {coords: 
                            {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            timecardUserId: this.selectedUser.id,
            timecardComment: this.timeCardComment
        }
        var historyHold = _.cloneDeep(adata)
        historyHold['userSubId'] = this.userData.id
        historyHold['subDate'] = moment().toISOString()
        adata['timecardHistory'] = [historyHold]
      }
      if(type == 'clockout'){
        var currentClockInCardObj = this.currentClockInCard.find(p => p.id == this.taggedForClockout)
        var historyHoldArray = currentClockInCardObj.timecardHistory
        if(!historyHoldArray){
          historyHoldArray = []
        }
        var atype = "patch"
        var aurl = '/api/v1/staffing/payrolltimecard/' + this.taggedForClockout
        var adata = {
            clockOutTime: moment(this.timecardDate + " " + this.timecardTime, "YYYY-MM-DD HH:mm:ss").toISOString(),
            clockOutActual: moment().toISOString(),
            clockOutGps: {coords: 
                            {accuracy: this.gpsLocationData.coords.accuracy, 
                            latitude: this.gpsLocationData.coords.latitude, 
                            longitude: this.gpsLocationData.coords.longitude
                            }, 
                          timestamp: this.gpsLocationData.timestamp},
            timecardComment: this.timeCardComment
          //  timecardUserId: this.selectedUser.id
        }
        var historyHold = _.cloneDeep(adata)
        historyHold['userSubId'] = this.userData.id
        historyHold['subDate'] = moment().toISOString()
        historyHoldArray.push(historyHold)
        console.log(historyHoldArray)
        adata['timecardHistory'] = historyHoldArray
      }
      axios({
        method: atype,
        url: aurl,
        data: adata,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then(response => {
          bus.$emit('closeTimeCard')
      })
    },
  }
}
</script>
<style scoped>


</style>
