<template>
<div>
                   <b-form-group 
                                breakpoint="lg"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                    <b-container v-if="vmodelHold">
                    <b-row class="mb-2">
                       <draggable :list="group.groupFields" class="displayContents" :options="{disabled: submissionView}">
                      <b-col v-for="(field, index) in group.groupFields" :key="index" class="mt-2" :lg="field.size" :label-for="field.objKey"><h6  :class="editOff ? 'font-weight-bold underline' : ''">{{ field.label ? field.label : '' }}
                      <b-btn size="sm" variant="outline-secondary" v-if="submissionView != true" class="float-right" @click="editThisField(field.id)">Edit</b-btn></h6>
                      <h4 class="mx-auto" v-if="field.type == 'textarea' && field.itemType == 'review'">{{ field.value }}</h4>
                         <b-container v-if="field.type == 'file' && field.itemType == 'review'"><p class="text-left" v-for="(link, index) in field.value" :key="index">
                            <b-btn class="m-0 py-1" style="white-space: normal" variant="link" @click="viewFile(link.url)" >{{ link.title }}</b-btn></p></b-container>
                     
                     <b-form-input v-if="field.type == 'text'  && !editOff" :state="checkForRequirementsVue(field.required, field.objKey)" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" />
                     <b-form-input v-if="field.type == 'number'  && !editOff" type="number" :state="checkForRequirementsVue(field.required, field.objKey)" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" />
                     <b-textarea v-if="field.type == 'textarea' && field.itemType == 'form' && !editOff" :rows="3" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" :state="checkForRequirementsVue(field.required, field.objKey)" />
                          <span v-if="(field.type == 'textarea' || field.type == 'text' || field.type == 'number') && field.itemType == 'form' && editOff">{{ vmodelHold[field.objKey] }}</span>
                    <b-row v-if="field.type == 'date' || field.type == 'datetime' || field.type == 'time'">
                      <b-col v-if="field.type == 'date' || field.type == 'datetime'">
                           <b-form-input  v-if="!editOff" type="date" v-model="vmodelHold[field.objKey]['date']" :state="checkForRequirementsVue(field.required, field.objKey, field.type)" @input="updateFormData(field.objKey, group.id, 'date')" />
                                   <span v-if="editOff">{{ getDateFormat(vmodelHold[field.objKey]['date']) }}</span>
                           </b-col>
                    <b-col v-if="field.type == 'time' || field.type == 'datetime'">
                           <b-form-input v-if="!editOff" :state="checkForRequirementsVue(field.required, field.objKey, field.type)" type="time" v-model="vmodelHold[field.objKey]['time']" @input="updateFormData(field.objKey, group.id, 'time')" />
                                        <span v-if="editOff">{{ vmodelHold[field.objKey]['time'] }}</span>
                           </b-col></b-row>
              
                    <b-form-select  v-if="field.type == 'select' && field.selectType == 'menu' && !editOff" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" :options="field.selectOptions" :state="checkForRequirementsVue(field.required, field.objKey)" />
                    <b-radio-group  v-if="field.type == 'select' && field.selectType == 'radio' && !editOff" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" :options="field.selectOptions" :state="checkForRequirementsVue(field.required, field.objKey)" />
                                         <span v-if="field.type == 'select' && editOff">{{ vmodelHold[field.objKey] }}</span>
                    <Multiselect  v-if="field.type == 'selectmulti' && !editOff" 
                                  class="mt-2 requirement-error-multi"
                                  v-model="vmodelHold[field.objKey]"
                                   @input="updateFormDataMultiSelect(field.objKey, group.id)"
                                  :options="field.selectOptions"
                                  :multiple="true" 
                                  :close-on-select="false" :clear-on-select="true" 
                                  :hide-selected="true" :preserve-search="true" 
                                  placeholder="Please Make a Selection" 
                                  label="text" track-by="text">
                                    <template slot="option" slot-scope="props">
                                      <span class="option__title">{{ props.option.text }}</span>
                                    </template>
                     </Multiselect>
                       <div v-if="field.type == 'selectmulti' && editOff"><b-badge variant="primary" class="mr-1 text-wrap" v-for="(val, index) in getFormFieldArray(vmodelHold[field.objKey])" :key="index">{{ val }}</b-badge></div>
                    <b-checkbox-group  v-if="field.type == 'checkbox'" :disabled="editOff ? true : false" v-model="vmodelHold[field.objKey]" :state="checkForRequirementsVue(field.required, field.objKey)" @input="updateFormData(field.objKey, group.id)" :options="field.selectOptions" />
                    <span v-if="field.type == 'boolean'">
                    <b-form-checkbox  v-model="vmodelHold[field.objKey]" :disabled="editOff ? true : false" :state="checkForRequirementsVue(field.required, field.objKey)" @input="updateFormData(field.objKey, group.id)" :check-value="true" :uncheck-value="false">{{ field.displayText }}</b-form-checkbox>
                    </span>
                     <Multiselect  v-if="field.type == 'dataRef' && !editOff" 
                                  class="mt-2"
                                  :class="checkForRequirementsVue(field.required, field.objKey, field.type, field.refType) == true ? 'requirement-error-multi' : 
                                          checkForRequirementsVue(field.required, field.objKey, field.type, field.refType) == false ? 'requirement-succ-multi' : ''"
                                  v-model="vmodelHold[field.objKey]"
                                   @input="updateFormDataMultiSelect(field.objKey, group.id, field.refValue, field.refType, field.refMenuProps)"
                                 :options="getDataFormReturn(field.refItem)"
                                 :multiple="field.refType == 'multiselect' ? true : false" 
                                 :close-on-select="field.refType == 'multiselect' ? false : true" :clear-on-select="true" 
                                 :hide-selected="true" :preserve-search="true" 
                                 placeholder="Please Make a Selection" 
                                 :label="getDataFormReturnPropsRef(field.refItem)" track-by="id">
                                 <template slot="singleLabel" slot-scope="props" v-if="field.refType == 'single'">
                                   <span class="option__desc">
                                     <span v-for="(refDis, index3) in  getDataFormReturnProps(field.refItem)" :key="index3">
                                        <span v-if="!(refDis == ' | ' || refDis == ' (' || refDis == ') ')">{{ props.option[refDis] }} </span>
                                        <span v-else>{{ refDis }}</span>
                                       </span>
                                     </span></template>
                                <template slot="option" slot-scope="props">
                                      <span class="option__title">
                                        <span v-for="(refmp, index2) in getDataFormReturnProps(field.refItem)" :key="index2">
                                        {{ props.option[refmp] }}
                                        </span>
                                        </span>
                                    </template>
                     </Multiselect>
                      <span v-if="field.type == 'dataRef' && editOff">{{ getNonEditValueMulti(field.refItem, vmodelHold[field.objKey]) }}</span> 
                     <b-container v-if="field.type == 'upload'" :on="setshortitle(field.objKey)">
         
                           <b-form-input class="w-50 mx-auto my-2" type="text" placeholder="Add a display name to upload file" v-model="shorttitle[field.objKey]"  @input="updateshortitle($event, field.objKey)" />
                              <input  v-if="filePosting == false" :disabled="shorttitle[field.objKey] == ''" type="file"  @change="postDocumentFile($event, shorttitle[field.objKey], field.objKey, group.id)" />
                                    <b-row v-if="filePosting == true"><b-col align="right" cols="auto" class="pt-1">
                                      <span div class="spinner">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>      
                                    </span></b-col></b-row>
                        <b-table small v-if="field.type == 'upload' && vmodelHold[field.objKey].length > 0" :fields="['url', 'remove']" :items="vmodelHold[field.objKey]">
                            <template slot="url" slot-scope="data">
                              <b-btn variant="link" @click="viewFile(data.item.url)">{{ data.item.title }}</b-btn>
                              </template>
                              <template slot="remove" slot-scope="data">
                                <b-btn variant="danger" size="sm" @click="vmodelHold[field.objKey].splice(data.index, 1)">X</b-btn>
                                </template>
                                 </b-table>


                          </b-container>


                     <div v-if="field.type == 'html'"  class="ql-editor unset-minheight" v-html="field.value" />
                     <b-container v-if="field.type == 'link' && field.itemType == 'review'"><p class="text-left" v-for="(link, index) in field.value" :key="index">
                            <b-btn class="m-0 py-1" style="white-space: normal" variant="link" @click="viewFile(link.url)" >{{ link.title }}</b-btn></p></b-container>
                  <b-container v-if="field.type == 'video' && field.itemType == 'review'">
                          <b-container  v-if="field.value.length > 0" class="w-75">
                            <div v-for="(video, index) in field.value" :key="index">
                             
                            <div class="iframeWrap">
                            <iframe class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                            
                            </div>
                            </div>
                            </b-container>
                        </b-container>
                  <b-container v-if="field.type == 'photo' && field.value.length > 0" class="w-75 mt-3">
                                  <b-carousel id="carousel1"
                                            style="text-shadow: 1px 1px 2px #333;"
                                              controls
                                            indicators
                                            interval="10000"
                                            background="#ababab"
                                            v-model="slide"
                                          >
                                    <template v-for="(pic, index) in field.value" >
                                    <b-carousel-slide  :key="index" :img-src="siteconfig.deptStore + pic" >
                          
                                    </b-carousel-slide>
                                    </template>
                                  </b-carousel>
                          </b-container>
                      </b-col>
                   </draggable>
                      </b-row> 
                    </b-container>
                    </b-form-group>

  <p v-if="submissionView" v-show="false">{{ arrayRequiredReturned }}</p>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { VueEditor } from 'vue2-editor'
import userSelector from '../../inputfields/userfilter.vue'
import moment from 'moment'
import draggable from 'vuedraggable'
export default {
  components: {
    userSelector,
    Multiselect,
    VueEditor,
    draggable
  },
  props: ['group', 'vmodelHoldDefault', 'formModules', 'submissionView', 'previousForm', 'editOff'],
  name: 'training-module-field',
  data () {
    return {
        currentPage: 1,
        showAll: true,
        edit: true,
        editGroupId: '',
        shorttitle: {},
        filePosting: false,
        showGroup: '',
        slide: 0,
        dataReset: true,
        deptRoles: ['user', 'supervisor', 'trainingops', 'emsops', 'fireops', 'adminchief'],
       trainingModuleSubmissionRequirements: [

       ],
       formGroupColorOptions: [
        { value: "info", text: "Teal" },
        { value: "primary", text: "Blue" },
        { value: "success", text: "Green" },
        { value: "danger", text: "Red" },
        { value: "warning", text: "Yellow" },
        { value: "secondary", text: "Grey" },
        { value: "default", text: "Default" }
      ],
      formValues: {

      },
      arrayRequired: [],
      vmodelHold: {

      },
      errorsHoldArray: [],
      setGroupUsersForTraining: [],
      searchData: '',
      customToolbar: [
          ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
          ['blockquote', 'code-block', 'link', { 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
    }
  },
  created(){
    console.log('CREATE')
    if(this.vmodelHoldDefault && !this.previousForm){
    
    this.vmodelHold = this.vmodelHoldDefault
    } else if(this.vmodelHoldDefault && this.previousForm){
      console.log('PREVIOUS')
      console.log(this.group)
      this.updatePreviousValues(this.vmodelHoldDefault)
    } else {
      this.vmodelHold = _.cloneDeep(this.group.groupFields)
    }
  //  console.log(this.formModules)
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),
       deptContactDataCheck(){
         var data = this.deptContactData
         data.forEach(item => item['addCredential'] = false)
         return data
       },
       setGroupUsersForTrainingFilter(){
         return this.setGroupUsersForTraining.filter(p => {
           return p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase())
         })
       },
       arrayRequiredReturned(){
         var newData = this.arrayRequired
        this.$emit('arrayrequired', newData)
       }
  
  },
  mounted(){
   //// YOU need to add the mechinism that perms the data up to formBuilder
  },
  watch: {
    vmodelHoldDefault(newData){
      console.log('DATA')
      if(this.vmodelHoldDefault && this.previousForm){
      this.updatePreviousValues(newData)
      } else {  this.vmodelHold = newData
      }
    }, 
    errorsHoldArray(newData){
     // console.log(newData)
      if(newData.length > 0){
        this.$emit('errorsPresent', true)
      } else {
        this.$emit('errorsPresent', false)
      }
    }
  },
  methods: {
    getFormFieldArray(valArray){
      var array = []
      if(valArray && valArray.length > 0){
        for(var i in valArray){
          array.push(valArray[i])
        }
      }
      return array

    },
    getDateFormat(data){
      if(data){
        return moment(data, 'YYYY-MM-DD').format('MM/DD/YY')
      } else {
        'Date Error'
      }
    },
    updatePreviousValues(data){
      console.log(data)
      var holdD = _.cloneDeep(data)
      var fields = _.cloneDeep(this.group.groupFields)
      var dataRef = fields.filter(p => p.type == 'dataRef')
      for(var i in dataRef){
        var values = holdD[dataRef[i].objKey]
        if(values && values.length > 0){
          var array = []
          for(var v in values){
            var obj = this.getRefValue(dataRef[i].refItem, values[v])
            if(obj){
              array.push(obj)
            }
          }
          holdD[dataRef[i].objKey] = array
        }
      }
      console.log(holdD)
      this.vmodelHold = holdD
    },
    getRefValue(type, id){
      var obj = this[type].find(p => p.id == id)
      if(obj){
        return obj
      } return 'Missing'
    },
    setshortitle(data){
      if(this.shorttitle && this.shorttitle[data] == undefined){
      this.shorttitle[data] = ''
      }
    },
    updateshortitle(data, key){
      this.filePosting = true
      this.shorttitle[key] = data
      this.$nextTick(function(){
        this.filePosting = false
      })
    },
    editThisField(data){
      this.$emit('editField', data)
    },
    deleteThisField(data){
      this.$emit('deleteField', data)
    },
    checkRequired(objKey, boo){
      var index = this.arrayRequired.indexOf(objKey)
      if(index > -1 && boo){
            this.arrayRequired.splice(index, 1)
      }
      if(index == -1 && !boo){
            this.arrayRequired.push(objKey)
      }
    },
    checkForRequirementsVue(required, objKey, type, second){
      if(required && !type){
        if(this.vmodelHold[objKey] == ''){
          this.checkRequired(objKey, false)
          return false
       } else {
          this.checkRequired(objKey, true)
          return true
        }
      } else if (required && type){
          if(type == 'date'){
            if(this.vmodelHold[objKey]['date'] == ''){
               this.checkRequired(objKey, false)
              return false
            } 
              this.checkRequired(objKey, true)  
              return true
          } else if(type == 'time'){
            if(this.vmodelHold[objKey]['time'] == ''){
              this.checkRequired(objKey, false)
              return false
            } this.checkRequired(objKey, true)
              return true
          } else if(type == 'datetime'){
            if(this.vmodelHold[objKey]['time'] == '' || this.vmodelHold[objKey]['date'] == ''){
              this.checkRequired(objKey, false)
              return false
            }  this.checkRequired(objKey, true)
               return true
          } else if (type == 'dataRef'){
           if(second == 'single' && this.vmodelHold[objKey] && this.vmodelHold[objKey] != null){
              this.checkRequired(objKey, true)
             return false
           } else if (second == 'multiselect' && this.vmodelHold[objKey] && this.vmodelHold[objKey].length > 0){
              this.checkRequired(objKey, true)
             return false
           }  else { 
              this.checkRequired(objKey, false)
              return true
           }
          } else {
           this.checkRequired(objKey, false)
          return false
          }
        }
      return null
    },
    getDataFormReturn(data, filter){
      console.log(data)
      console.log(filter)
      if(!filter){
        return this[data]
      } else {
        // need filter script
        return this[data]
      }
    },
    getDataFormReturnPropsRef(data){
        if(data == 'stationsData'){
          return 'stationName'
        } else if (data == 'apparatusData') {
          return 'apparatusName'
        } else if (data == 'deptContactData'){
          return 'lastName'
        }
    },
    getDataFormReturnProps(data, filter){
        if(data == 'stationsData'){
          return ['stationName']
        } else if (data == 'apparatusData') {
          return ['apparatusName']
        } else if (data == 'deptContactData'){
          return ['firstName', 'lastName', ' (', 'employeeId', ') ', ' | ', 'deptRank', 'stationAssignment']
        }
    },
    getNonEditValueMulti(type, value){
          var string = ''
          var array = this.getDataFormReturnProps(type)
          for(var v in value){
            var mini = ''
            for(var a in array){
              if(value[v][array[a]]){
                mini = mini + value[v][array[a]] + ' '
              } else {
                mini = mini + array[a]
              }
            }
            if(v < value.length - 1){
              string = string + mini + ', '
            } else {
              string = string + mini
            }

          }
          return string
    },
    updateFormData(objid, groupid, obj){
      var value = this.vmodelHold[objid]
      var newObj = {groupid: groupid, objKey: objid, value: value}
      this.$emit('updateObjectValue', newObj)

    },
    updateFormDataMultiSelect(objid, groupid, savevalue, multi, props){
      if(savevalue && multi && props){
      var value = this.vmodelHold[objid]
      var result = Array.isArray(value)
      if(!result){
        value = [value]
      }
      var array = []
      var arrayStore = []
      for(var i in value){
        array.push(value[i][savevalue])
        var obj = {}
        for(var p in props){
          obj[props[p]] = value[i][props[p]]
        }
        arrayStore.push(obj)
      } 
      var newObj = {groupid: groupid, objKey: objid, value: array}
      }
      else {
        var value = this.vmodelHold[objid]
        var result = Array.isArray(value)
        if(!result){
          value = [value]
        }
        var array = []
        var arrayStore = []
      for(var i in value){
        array.push(value[i].value)
      } 
      for(var i in value){
        arrayStore.push(value[i].text)
      }
         var newObj = {groupid: groupid, objKey: objid, value: array}
      }
    //  console.log(newObj)
     this.$emit('updateObjectValue', newObj)
    },
    getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
    },
            postDocumentFile(e, title, key, group){
      this.filePosting = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/trainingmodules/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        this.vmodelHold[key].push({ title: title, url: response.data})
        this.updateFormData(key, group)

      this.$nextTick(function(){
        this.filePosting = false
        this.shorttitle[key] = ''
      })
        });
    },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
    submitTrainingModule(data){
      this.$emit('updateFormInformation', data)
  },

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style scoped>
.underline {
  text-decoration-line: underline;
}
.displayContents{
  display: contents;
}
.boolean-requirement-error{
  padding: 2px;
  border-radius: 5px;
  border: 1px solid red;
}
.requirement-error{
  border: 1px solid red !important;
}
.requirement-error-multi{
  border: 1px solid #dc3545 !important;
  border-radius: 5px;
}
.requirement-succ-multi{
  border: 1px solid #28a745 !important;
  border-radius: 5px;
}
.unset-minheight {
  min-height: unset;
}
.text-align-clear {
  text-align: left;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
