<template>
  <div class="bodywidth">
      <div class="headerSpan">Tutorial Information  <span v-if="showAdmin">Admin</span>
 <b-btn class="float-right" v-if="userData.role == 'admin'" size="sm" @click="showAdmin = !showAdmin" :variant="showAdmin ? 'light' : 'outline-light'">Admin</b-btn>

      </div>
      <tutAdmin v-if="showAdmin" />
<b-card no-body v-if="!showAdmin">
  <b-tabs card>
    <b-tab title="Videos">
      <b-container fluid>
        <b-row>
          <b-col md="3" align="left">
 <p v-for="tut in departmentTutorialsSort" :key="tut.id" class="py-0 wrapClass"  @click="selectedVideoId = tut.id">
      {{ tut.tutorialName }}
 </p>
          </b-col><b-col>
              <div class="videoWrapper">
  <iframe :src="videoLinkSetup(currentVideoSelected.tutorialUrl)" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
</div>
            </b-col></b-row></b-container>
    </b-tab>
    <b-tab title="Wiki">
    <b-container fluid>
      <b-row>
        <b-col md="3" align="left">
          <b-card no-body header-tag="header">
            <div class="m-1">
            <b-form-input type="text" placeholder="Search all by keyword" v-model="search" />
            </div>
<div v-for="(parent, index) in tutorialParents" :key="index">
    <b-btn variant="link" class="py-0">
      {{ parent }}
    </b-btn>
    <div v-for="(child, indexc) in getChild(parent)" :key="indexc">
            <b-btn class="ml-4 py-0" variant="link text-info" @click="viewTutorialInfo = { parent: parent, child: child }" :key="indexc">{{ child }}
            </b-btn>
      </div>
  </div>
  </b-card>
        </b-col>
        <b-col md="9">
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(tut, indext) in currentChildData" :key="indext" :title="tut.tutorialName">
     
     <div v-for="(item, index) in tut.tutorialData"  :key="index">

            <div class="m-2" v-if="item.type == 'html'">
              <div class="ql-editor" v-html="item.value" ></div>
            </div>
             <div class="m-2" v-if="item.type == 'textarea'">
             <div class="textAlignClear">{{ item.value }}</div>
             </div>
            <div v-if="item.type == 'photo'">
                 <b-img class="p-5" :src="siteconfig.bridgeStore + item.value" />
              </div>
             <div v-if="item.type == 'video'">
                   <div v-if="item.value.length > 0" v-for="(video, index) in item.value" :key="index">
                  <div class="iframeWrap">
                   <iframe class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                   </div>
                  </div>
                </div>
                <div class="m-2" v-if="item.type == 'file'">
                   <b-btn variant="link" target="_blank" :href="siteconfig.bridgeStore + item.value.url" >{{ item.value.title }}</b-btn>
              </div>
    </div>
                </b-tab>
              </b-tabs>
            </b-card>
         </b-col> 
      </b-row>
</b-container>
      </b-tab>
      <b-tab v-if="userData.role == 'admin' || userData.role == 'adminchief'" title="Feature Requests">
        <featureRequest />
      </b-tab>
      <b-tab v-if="userData.role == 'admin'" title="Support Ticket">
      
      </b-tab>
  </b-tabs>
</b-card>
 </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import tutData from '../tracking/deptTutorialsdata.vue'
import tutAdmin from '../admin/adminTutorials'
import featureRequest from './tutorials/featureRequest.vue'
export default {
props:[],
components:{
  'tutdata' : tutData,
  featureRequest,
  tutAdmin
},
  data () {
    return {
      showAdmin: false,
      search: '',
      tutorials: [],
      selectedVideoId: null,
      viewTutorialInfo: {},
      previousTutorialData: {},
    }
  },
  created(){
    this.getTutorialGuides()
  },
  computed:{
    ...mapState([
      'departmentTutorials',
      'userData',
      'siteconfig'
    ]),
    currentVideoSelected(){
      if(this.selectedVideoId){
       return this.departmentTutorialsSort.find(p => p.id == this.selectedVideoId)
      } else {
        return this.departmentTutorialsSort[0]
      }
    },
    departmentTutorialsSort(){
      return _.orderBy(this.departmentTutorials, ['sortOrder'], ['asc']) 
    },
           tutorialParents(){
         var array = [... new Set(this.tutorials.map(p => p.tutorialParent))]
         var order = _.sortBy(array)
         if(order[0] && !this.viewTutorialInfo.parent){
           this.viewTutorialInfo['parent'] = order[0]
         }
         return order
         
       },
       currentChildData(){
      //   console.log(this.viewTutorialInfo)
         if(this.viewTutorialInfo){
           var view = this.viewTutorialInfo
           var array = this.tutorials.filter(p => {
             return p.tutorialParent == view.parent && p.tutorialSub == view.child
           })
           return _.orderBy(array, ['tutorialSortOrder'], ['asc'])
           } else {
             return []
           }
         }
  },
   methods:{
     videoLinkSetup(data){
       var baselink = 'https://player.vimeo.com/video/'
       var initial = data.split('.com/')
       var number = initial[1]
       return baselink + number
     },
     getTutorialGuides(){
          axios({
            method: "get",
            url: "https://bridge.statportals.com/api/v1/taxonomy/tutorialguide/outside",
            headers: {
            //  Authorization: this.getSessionData,
              "Content-Type": "application/json",
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }
          }).then(response => {
            this.tutorials = response.data;

          });
        },
                getChild(parent){
          var array = this.tutorials.filter(p => {
            return p.tutorialParent == parent
          }) 
          var child = [...new Set(array.map(p => p.tutorialSub))]
          var order = _.sortBy(child)
          if(this.viewTutorialInfo.parent && this.viewTutorialInfo.parent == parent && !this.viewTutorialInfo.child && order[0]){
            this.viewTutorialInfo['child'] = order[0]
          }
          return order
        },
        getVideoId(data){
            data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
            if (RegExp.$3.indexOf('youtu') > -1) {
              return 'https://www.youtube.com/embed/' + RegExp.$6
              } else if (RegExp.$3.indexOf('vimeo') > -1) {
              return 'https://player.vimeo.com/video/' + RegExp.$6
              }
        /*
              var videoLink = data.split('=')
                if(videoLink.length == 1){
                  var videoLink = data.split('.be/')
                }
              return videoLink[1] */
          },

  },
}
</script>

<style scoped>
.wrapClass {
    white-space:normal !important;
    word-wrap: break-word; 
    cursor: pointer;
    color: #17a2b8 !important;

}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
}
.bodywidth{
  width: 100%;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
