<template>
<div @click="addModalOpen">
  <b-modal
       v-model="showModal"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
         >
         <template slot="modal-header">
           Add New Document<b-btn align="right" size="sm" variant="danger" @click="showModalswitchEmitVehicle">x</b-btn>
         </template>
         <div class="modal-body">
            <b-form-select class="shiftSelectInput" text-field="documenttypeName" value-field="documenttypeName" v-model="docPar" :options="documentCats">
              <option :value="null" disabled> Please Select Document Category</option>
            </b-form-select>
            <b-form-select v-if="docPar != null" class="shiftSelectInput" text-field="documenttypeName" value-field="documenttypeName" v-model="documentTag" :options="documentSub(docPar)">
              <option :value="null" disabled> Please Select Document Sub-Category</option>
            </b-form-select>
            <input type="text"  v-if="documentTag != null" class="itemDescription" v-model="itemShort" placeholder="Document Id" />
              <h6  v-if="documentTag != null">Document ID is used for Sorting (ie DIR101, FORM111)</h6>
            <textarea v-if="itemShort != ''" class="textAreaDescription" :rows="2" v-model="detailedDescription" placeholder="Short Document Description."></textarea>
              <h5 class="sub-section-heading" v-if="detailedDescription != ''">Attach File:</h5>
                      <input v-if="detailedDescription != ''"  class="pictureInputButton" type="file" @change="postImage($event, itemShort)">
              </div>
         <template slot="modal-footer">
             <b-btn
             :disabled="fileUpload == '' || itemShort == '' || detailedDescription == ''"
             variant="primary" @click.stop="postDocument">Submit</b-btn>

           </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { bus } from '../../main'
import axios from 'axios'
import _ from 'lodash'
export default {
  props:["showModal", "filterDate"],
components:{
},
  data () {
    return {
        docPar: null,
        trueBoo: true,
        documentTag: null,
        itemShort: '',
        titleDescription: '',
        showModalConfirmation: false,
        detailedDescription: '',
        postData: '',
        fileUpload: '',


    }
  },
  created(){

  },
  computed:{
    ...mapState([
          'getSessionData',
          'userData',
          'documentTags',
        ]),
      userPostName(){
        return this.userData.map(function(data) {
          return data.name;
        })},
        documentCats(){
          var newArray = [... new Set(this.documentTags.filter(p => p.documenttypeParent == '')
          )]
          return _.orderBy(newArray, ['documenttypeName'], ['asc'])
        },

    },
    watch: {

      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    documentSub(data){
      var returnData = this.documentTags.filter(p => {
        return p.documenttypeParent.match(data)
      })
      return _.orderBy(returnData, ['documenttypeName'], ['asc'])
    },
    addModalOpen(){
      document.body.className += 'modal-open';
    },
    showModalswitchEmitVehicle (event) {

      bus.$emit('closeDocument')
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalswitchEmitVehicle()
    },
    postImage(e, id){
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fileUpload = response.data

        });

  
     },
     postDocument(){
       axios({
        method: 'post',
        url: '/api/v1/content/document',
      data: {
          'documentName': this.detailedDescription,
          'documentTag': this.documentTag,
          'documentTagParent': this.docPar,
          'documentId': this.itemShort,
          'documentFile': this.fileUpload
              },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
        this.postData = response.data
          })


          bus.$emit('closeDocument')
    },
  }
}
</script>

<style scoped>
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.shiftSelectInput.form-control.custom-select {
  margin-top: 10px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
input.itemDescription {
  margin-bottom: 10px;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}



</style>
