<template>

    <b-container fluid>
          <b-form-group
                breakpoint="lg"
                label="Repeat"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0">
        <b-form-group horizontal label="Every:" label-class="text-sm-right"
                  label-for="repeatNumber">
     <b-form-input  id="repeatNumber" type="number" v-model="repeatNumber" /></b-form-group>
    <b-form-group horizontal label="Type:" label-class="text-sm-right"
                  label-for="repeatNumber">
     <b-form-select  id="repeatNumber" v-model="repeatType" :options="selectOptions" /></b-form-group>
     </b-form-group>
    <b-form-group v-if="false && repeatType == 'week'"
                breakpoint="lg"
                label="On"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0">
  <b-form-checkbox-group v-model="selectedWeekDay" name="weekDay" :options="optionsWeekDays">  </b-form-checkbox-group>   </b-form-group>
  <b-form-group v-if="repeatType == 'month'"
                breakpoint="lg"
                label="On"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0">
  <b-form-select v-model="selectedMonthDate" name="weekDay" :options="optionsMonth">
      </b-form-select>
     </b-form-group>
     <b-form-group
                breakpoint="lg"
                label="End After"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0">
                   <b-form-radio-group v-model="endType"
                          :options="options"
                          name="radioInline">
              </b-form-radio-group>            
    <b-form-group v-if="endType == 'occ'" horizontal label="After:" label-class="text-sm-right"
                  label-for="endNumber">
     <b-form-input  id="endNumber" type="number" v-model="endNumber" /></b-form-group>
    <b-form-group  v-if="endType == 'date'" horizontal label="Type:" label-class="text-sm-right"
                  label-for="endDate">
     <b-form-input  :state="dateBeforeState" id="endDate" v-model="endDate" type="date" />
         <b-form-invalid-feedback id="endDateFeedback">
      <!-- This will only be shown if the preceeding input has an invalid state -->
      Date Must Be After Current Event
    </b-form-invalid-feedback></b-form-group>
     </b-form-group>
     <br>
    <div v-show="true"><p class="my-0" v-for="data in repeatingDates" :key="data.index">{{ getFormatedDate(data) }}</p></div>
    </b-container>

</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
    export default {
      components:{
          'multiselect' : Multiselect,
      },
       props: ['selectedDate'],
       data () {
           return {
               holdValues: {},
               options: [
                    { text: 'Occurences', value: 'occ' },
                    { text: 'Date', value: 'date' },
                ],
                selectOptions: [
                    { text: 'Days', value: 'day' },
                    { text: 'Weeks', value: 'week' },
                    { text: 'Months', value: 'month' },
                    { text: 'Years', value: 'year' },
                ],
               optionsWeekDays: [
                    {text: 'Sun', value: 0},
                    {text: 'Mon', value: 1},
                    {text: 'Tue', value: 2},
                    {text: 'Wed', value: 3},
                    {text: 'Thu', value: 4},
                    {text: 'Fri', value: 5},
                    {text: 'Sat', value: 6}
                ],
                optionsMonth: [

                ],
                repeatNumber: 1,
                repeatType: 'day',
                endNumber: 1,
                endDate: '',
                endType: 'occ',
                selectedWeekDay: '',
                selectedMonthDay: '',
                selectedMoment: '',
                selectedMonthDate: '',
                weeksHold: 1,

           }
       },
       created () {
           this.selectedMoment = moment(this.selectedDate).format()
           this.selectedWeekDay = moment(this.selectedMoment).day()
           this.selectedMonthDay = moment(this.selectedMoment).date()
           this.getMonthlyInfo(this.selectedMoment)
       },
       watcher: {

       },
       computed: {
           ...mapState([
               'deptContactData',
               'departmentRanks',
               'departmentSpecialties',
               'stationsData',
               'apparatusData',
               'shiftsData'
           ]),
            dateBeforeState() {
                return this.endDate == '' ? '' : !(moment(this.endDate).isSameOrBefore(this.selectedDate))
                },
           repeatingDates(){
              var array = [moment(this.selectedDate)]
               if(this.repeatType == 'day'){     
                   if(this.endType == 'occ'){
                       for(var i = 0; i < this.endNumber; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'd'))
                       }
                   } else if (this.endType == 'date'){
                    var a = this.endDate
                    var b = moment(this.selectedDate).format('YYYY-MM-DD')
                    var occ = moment(a).diff(b, 'days') // 1
                    var finalCount = occ / this.repeatNumber
                    var finalCount = Math.floor(finalCount)
                        for(var i = 0; i < finalCount; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'd'))
                       }

                   }
               } 
               else if (this.repeatType == 'week'){
                   if(this.endType == 'occ'){
                      for(var i = 0; i < this.endNumber; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'w'))
                       }
                   } else if (this.endType == 'date'){
                    var a = this.endDate
                    var b = moment(this.selectedDate).format('YYYY-MM-DD')
                    var occ = moment(a).diff(b, 'weeks') // 1
                    var finalCount = occ / this.repeatNumber
                    var finalCount = Math.floor(finalCount)
                        for(var i = 0; i < finalCount; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'w'))
                       }
                   }
               }
                else if (this.repeatType == 'month'){
                   if(this.endType == 'occ'){
                       if(this.selectedMonthDate == 'dateOfMonth'){
                    for(var i = 0; i < this.endNumber; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'M'))
                       }
                       } else if (this.selectedMonthDate == 'dayOfMonth'){
                        for(var i = 0; i < this.endNumber; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           var startDate = moment(this.selectedDate).add(number, 'M')
                           var myMonth = moment(startDate).startOf('month').format('YYYY-MM-DD')
                           var timeHold = moment(startDate).format('HH:mm:ss')
                           myMonth = moment(myMonth + 'T' + timeHold, 'YYYY-MM-DDTHH:mm:ss')
                           var weekNumber = this.weeksHold
                           var firstDayOfWeek = myMonth.clone().weekday(this.selectedWeekDay);
                            if( moment(firstDayOfWeek).month() != moment(myMonth).month() ){
                                 weekNumber = weekNumber + 1
                            }
                            var month = moment(firstDayOfWeek).add(weekNumber - 1, 'weeks');
                            if(moment(month).month() != moment(myMonth).month()){
                                var month = moment(month).subtract(1, 'weeks');
                            }
                            array.push(month)
                            }
                           
                       }
                   } else if (this.endType == 'date'){
                    var a = this.endDate
                    var b = moment(this.selectedDate).format('YYYY-MM-DD')
                    var occ = moment(a).diff(b, 'months') // 1
                    var finalCount = occ / this.repeatNumber
                    var finalCount = Math.floor(finalCount)
                    if(this.selectedMonthDate == 'dateOfMonth'){
                    for(var i = 0; i <finalCount; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'M'))
                       }
                       } else if (this.selectedMonthDate == 'dayOfMonth'){
                        for(var i = 0; i < finalCount; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           var startDate = moment(this.selectedDate).add(number, 'M')
                           var myMonth = moment(startDate).startOf('month').format('YYYY-MM-DD')
                           var timeHold = moment(startDate).format('HH:mm:ss')
                           myMonth = moment(myMonth + 'T' + timeHold, 'YYYY-MM-DDTHH:mm:ss')
                           var weekNumber = this.weeksHold
                           var firstDayOfWeek = myMonth.clone().weekday(this.selectedWeekDay);
                            if( moment(firstDayOfWeek).month() != moment(myMonth).month() ){
                                 weekNumber = weekNumber + 1
                               
                            }
                            var month = moment(firstDayOfWeek).add(weekNumber - 1, 'weeks');
                            if(moment(month).month() != moment(myMonth).month()){
                                var month = moment(month).subtract(1, 'weeks');
                            }
                            array.push(month)
                            }
                           
                       }
                   
                   }
               }
                else if (this.repeatType == 'year'){
                        if(this.endType == 'occ'){
                       for(var i = 0; i < this.endNumber; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'y'))
                       }
                   } else if (this.endType == 'date'){
                    var a = this.endDate
                    var b = moment(this.selectedDate).format('YYYY-MM-DD')
                    var occ = moment(a).diff(b, 'years') // 1
                    var finalCount = occ / this.repeatNumber
                    var finalCount = Math.floor(finalCount)
                        for(var i = 0; i < finalCount; i++){
                           var number =  parseInt(this.repeatNumber) * (parseInt(i) + 1)
                           array.push(moment(this.selectedDate).add(number, 'y'))
                       }

                   }
               }
               this.$emit('repeatDateArray', array)
               console.log(array)
               return array
           }
          
       }, 
       methods: {
           getFormatedDate(data){
               return moment(data).format('MM/DD/YY HH:mm')
           },
           getMonthlyInfo(mom){
               var options = []
               var occ = moment(mom).date()
               var rep = '1st'
               if(occ > 7 && occ <= 14){
                   var rep = '2nd'
                   this.weeksHold = 2
               } else if (occ > 14 && occ <= 21){
                   var rep = '3rd'
                     this.weeksHold = 3
               } else if (occ > 21 && occ <= 28){
                   var rep = '4th'
                     this.weeksHold = 4
               } else if (occ > 28 && occ <= 31){
                   var rep = '5th/Last'
                     this.weeksHold = 5
               }
               var dayOfWeek = moment(mom).format('dddd')
               options.push({text: 'Repeat on the ' + rep + ' ' + dayOfWeek + ' of the Month', value: 'dayOfMonth' })
               options.push({text: 'Repeat on Day ' + occ + ' of the Month', value: 'dateOfMonth' })
               this.optionsMonth = options
           }
       

       }
}
</script>
<style :src="'./customcss/vue-multiselect.min.css'"></style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>

</style>
