<template>
<div id="homelanding">
  <b-navbar type="dark" variant="dark">
      <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Seach Options:</b-navbar-brand>
                
        <b-form-select v-model="filterTagType" :options="taxonomyTags" />
        <div style="width: 250px" class="ml-2">
          <multiselect 
                    v-model="filterDataBaseId" 
                    :disabled="filterTagType == null"
                    :options="getOptionsFromSelection(filterTagType)" 
                    :multiple="false" 
                    :close-on-select="true" 
                    :clear-on-select="true" 
                    :hide-selected="true" 
                    :preserve-search="true" 
                    placeholder="Please select an Options"
                    :label="filterTagType == 'stationsData' ? 'stationName' : filterTagType == 'deptContactData' ? 'lastName' : 'apparatusName'" 
                    track-by="id">
              <template slot="option" slot-scope="props" v-if="filterTagType == 'deptContactData'" >
                <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
              </template>
          </multiselect>
        </div>
        <b-navbar-brand class="bootstrapTable ml-2" v-if="filterTagType != null">Date Options:</b-navbar-brand>
        <div v-if="filterTagType == 'deptContactData'">
          <b-form-input type="date" v-model="startDate" />
          <b-form-input type="date" v-model="endDate" />
          </div>
          <div v-if="filterTagType != 'deptContactData' && filterTagType != null">
            <b-form-select v-model="monthSelect" :options="monthsFilter" />
            <b-form-select v-model="yearSelect" :options="years" /> 
          </div>
          <b-btn variant="primary" v-if="filterTagType && filterDataBaseId" @click="pullFilterData">Search</b-btn>
          <download-excel 
              class="ml-2"
              v-if="fixDataExcelCombined.length > 0"
              :name="getStringName"
              :fields="jsonFields"
              :data="fixDataExcelCombined">
              <b-btn variant="success">Download Table</b-btn>
          </download-excel>
      </b-nav-form>
       <b-btn class="ml-2" variant="secondary" v-if="userData.role == 'admin'" @click="superAdmin = !superAdmin">Admin</b-btn>
    </b-navbar>
    <b-navbar type="dark" variant="dark" v-if="returnedData.length > 0">
      <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable">FILTER</b-navbar-brand>
        <div style="width: 400px" class="ml-2">
          <multiselect 
                    v-model="filterCategory" 
                    :options="catogoriesOrder" 
                    :multiple="true" 
                    :close-on-select="true" 
                    :clear-on-select="true" 
                    :hide-selected="true" 
                    :preserve-search="true" 
                    placeholder="Please select an Options">
      </multiselect>
        </div>
        <b-navbar-brand class="bootstrapTable ml-2" v-if="filterTagType != null">Range:</b-navbar-brand>
          <b-form-input type="date" v-model="compiledStart" />
          <b-form-input type="date" v-model="compiledEnd" />
        <b-navbar-brand class="bootstrapTable ml-2" v-if="filterTagType != null">Missing:</b-navbar-brand>
            <b-form-select v-model="missingSort" :options="['Both', 'Only', 'Hide']" />
        <b-navbar-brand class="bootstrapTable ml-2" v-if="filterTagType != null">Shift:</b-navbar-brand>
            <b-form-select v-model="shiftSort" :options="shiftOptions" />
      </b-nav-form>
    </b-navbar>
    <b-container style="min-height: 400px" v-if="!superAdmin">
      <b-table v-if="returnedData.length > 0" :fields="['date/type', 'author', 'description', 'data']" :items="compiledArray" small>
        <template slot="date/type" slot-scope="data">
          <h5 v-if="data.item._rowVariant && data.item.date" class="float-left ml-1 mb-1 mt-2 font-weight-bold"><span class="mt-2 mr-1">{{ getDateFormated(data.item.date) }}</span>
            <b-badge v-if="data.item._rowVariant && data.item.author && data.item.author.shift && data.item.author.bootstrap" :variant="data.item.author.bootstrap">{{ data.item.author.shift}}</b-badge>
          </h5>
          <h6 v-if="data.item._rowVariant && data.item.type" class="float-left ml-4 mb-0">{{ data.item.type.toUpperCase() }}</h6>
        </template>
        <template slot="author" slot-scope="data">
          <span v-if="!data.item._rowVariant && data.item.author">{{ data.item.author }}</span>
       </template>
        <template slot="data" slot-scope="data">
          <b-btn v-if="data.item.data" variant="link" @click="showModelData = data.item.data, showModel = true">View</b-btn>
          </template>
        </b-table>
      </b-container>
      <superContentExport v-if="superAdmin" />
<b-modal v-model="showModel">
  <postModal v-if="showModel" :showPost="true" :post="showModelData" />
  </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from './../../main'
import axios from 'axios'
import moment from 'moment'
import superContentExport from './adminContentExportSuper.vue'
import Multiselect from "vue-multiselect";
import postModal from "./../secondarymenu/userpaneltab/userpanelPosts.vue"
import JsonExcel from 'vue-json-excel';
export default {
components: {
  'multiselect' : Multiselect,
  postModal,
  superContentExport,
  'download-excel' : JsonExcel

},
props: [],
data() {
    return {
      filterTagType: null,
      showModel: false,
      superAdmin: false,
      showModelData: {},
      filterDataBaseId: '',
      startDate: '',
      endDate: '',
      yearSelect: '',
      monthSelect: '',
      filterCategory: '',
      filterCategoryOptions: [],
      shiftSort: 'All',
      jsonFields: {
        'Date/Shift': 'date_shift',
        'Content Type': 'type',
        'Content Date': 'date',
        'Description': 'description',
        'Link': 'link'
      },
      compiledStart: '',
      compiledEnd: '',
      missingSort: 'Both',
      taxonomyTags: [
        {text: 'User', value: 'deptContactData'},
        {text: 'Station', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'None', value: null}
      ],
      apparatusCategorys: ["event", "narctrack", "dailyvehicle", "repairform"],
      stationCategorys: ["event", "logbook", "dailyvehicle", "narctrack", "repairform"],
      userCategorys: ['narctrack', 'logbook', 'dailyvehicle', 'repairform', 'event'],
      catogoriesOrder: [],
      contentTypes: {
        'dailyvehicle':[
          {value: 'date', key: 'dailyvehicleDate'},
          {value: 'author', key: 'dailyvehicleAuthor'},
          {value: 'authorId', key: 'dailyvehicleAuthorId'},
          {value: 'description', key: 'title'}
        ],
        'event': [
          {value: 'date', key: 'eventDate'},
          {value: 'author', key: 'eventAuthor'},
          {value: 'authorId', key: 'eventAuthorId'},
          {value: 'description', key: 'eventTitleDescription'}
        ],
        'narctrack': [
          {value: 'date', key: 'narctrackDate'},
          {value: 'author', key: 'narctrackAuthor'},
          {value: 'authorId', key: 'narctrackAuthorId'},
          {value: 'description', key: 'title'}
        ],
        'logbook': [
          {value: 'date', key: 'logbookDate'},
          {value: 'author', key: 'logbookAuthor'},
          {value: 'authorId', key: 'logbookAuthorId'},
          {value: 'description', key: 'title'}
        ],
        'repairform':[
          {value: 'date', key: 'repairformDate'},
          {value: 'author', key: 'repairformAuthor'},
          {value: 'authorId', key: 'repairformAuthorId'},
          {value: 'description', key: 'title'}
        ],
      },
      months: [
        {"text": "Jan", "value": '01' },
        { "text": "Feb", "value": '02' },
        { "text": "Mar","value": '03' },
        { "text": "Apr", "value": '04' },
        { "text": "May", "value": '05' },
        { "text": "Jun", "value": '06' },
        { "text": "Jul", "value": "07" },
        { "text": "Aug", "value": "08" },
        { "text": "Sep", "value": "09" },
        { "text": "Oct", "value": "10" },
        { "text": "Nov", "value": "11" },
        { "text": "Dec", "value": "12" }
      ],
      returnedData: [],

    }
},
created() {
  this.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
  this.endDate = moment().format('YYYY-MM-DD')
  this.yearSelect = moment().format('YYYY')
  this.monthSelect = moment().format('MM')
},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'siteConfig',
    'logisticAssetData'
  ]),
  getStringName(){
    var start = 'Content Export for '
    var middle = ''
    if(this.filterTagType == 'deptContactData' && this.filterDataBaseId.firstName && this.filterDataBaseId.lastName){
      middle = this.filterDataBaseId.firstName + ' ' + this.filterDataBaseId.lastName + ' '
    } else if(this.filterTagType == 'apparatusData' && this.filterDataBaseId.apparatusName){
      middle = this.filterDataBaseId.apparatusName + ' '
    } else if(this.filterTagType == 'stationsData' && this.filterDataBaseId.stationName){
      middle = this.filterDataBaseId.stationName + ' '
    }
    var end = 'from ' + this.compiledStart + ' till ' + this.compiledEnd
    return start + middle + end
  },
  shiftOptions(){
  if(this.siteconfig && this.siteconfig.payroll && this.siteconfig.payroll.shifts){
    var array = ['All']
    var shifts = _.orderBy(this.siteconfig.payroll.shifts, ['shift'], ['asc'])
    for(var s in shifts){
      if(shifts[s].shift != 'Daily'){
        array.push(shifts[s].shift)
      }
    }
    console.log(array)
    return array
  } else {
    return []
  }
  },
  monthsFilter(){
    var months = this.months
    var array = months.filter(p => {
      var current = moment().format('YYYY-MM')
      var string = this.yearSelect + '-' + p.value
      return moment(string).isSameOrBefore(current, 'month')
    })
    return array
  },
  years(){
    var current = moment().format('YYYY')
    var array = []
    for(var i = 0; i < 7; i++){
        array.push(Number.parseInt(current - i).toString())
    }
    return array
  },
  datesArray(){
  
  var currDate = moment(this.compiledStart, 'YYYY-MM-DD').startOf('day');
  var dates = [{date: currDate.clone().format('YYYY-MM-DD'),  shift: this.dateDifference(currDate.clone().format('YYYY-MM-DD'))}];
  var lastDate = moment(this.compiledEnd, 'YYYY-MM-DD').startOf('day');
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push({date: currDate.clone().format('YYYY-MM-DD'), shift: this.dateDifference(currDate.clone().format('YYYY-MM-DD'))});
    }
    return dates;
  },
  fixDataExcelCombined(){
    var array = _.cloneDeep(this.compiledArray)
    array = array.map(item => {
      var obj = _.cloneDeep(item)
      obj.type = ''
      if(item._rowVariant && item.author && item.author.shift){
        obj['date_shift'] = item.date + ' ' + item.author.shift
        obj['date'] = ''
        obj['author'] = ''
        obj['description'] = ''
        obj['type'] = ''
        obj['link'] = ''
      } else if (item._rowVariant && item.type && item.description == 'NONE'){
        obj['type'] = item.type
        obj['date'] = ''
      }
      if(item.data && item.data.id){
        obj.type = item.type
        obj['link'] = 'https://' + document.location.hostname + '/datashare/' + item.type + '?id=' + item.data.id
      }
      if(!item.description){
        obj.description = ''
      }
      if(!item.data){
        obj.link = ''
      }
      delete obj.data
      delete obj._rowVariant
      return obj
    })
    return array.filter(obj => {
    if((obj.type != '' && obj.description != '') || obj.date_shift){
      return obj
      }
    })
  },
  compiledArray(){
    var array = this.returnedData
    var hold = array.map(item => {
        var obj = {}
        obj['type'] = _.cloneDeep(item.type)
        var data = _.cloneDeep(item)
        data['contentType'] = _.cloneDeep(item.type)
        delete data.type
        var content = this.contentTypes[obj.type]
        for(var i in content){
          var hold = item[content[i].key]
          if(hold){
            obj[content[i].value] = hold
          }
        }
        obj['created'] = data.createdAt
        obj['data'] = data
        return obj 
    })
    hold = _.orderBy(hold, ['date', 'created'], ['asc', 'asc'])
    var dates = this.datesArray
    var cats = this.filterCategory
    var missing = this.missingSort
    var sorted = []
    if(this.shiftSort != 'All'){
      dates = dates.filter(p => {
        console.log(p.shift)
        return p.shift.shift == this.shiftSort
      })
      console.log(dates)
    }
    for(var d in dates){
      sorted.push({date: dates[d].date, _rowVariant: 'secondary', author: dates[d].shift})
      for(var c in cats){
        var avail = hold.filter(p => {
          return p.type == cats[c] && moment(p.date).isSame(dates[d].date, 'day')
        })
        if(avail.length > 0 && (missing == 'Both' || missing == 'Hide') ){
        sorted.push({type: cats[c], _rowVariant: 'success'})
        for(var a in avail){
          sorted.push(avail[a])
        }
        } else if ((missing == 'Both' || missing == 'Only') && avail.length == 0) {
          sorted.push({type: cats[c], _rowVariant: 'danger', description: 'NONE'})
          }
        
        
      }
    }

    return sorted
  },
  headers(){
    var obj = {
              "content-type": "application/json",
              "Authorization": this.getSessionData,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'    
    }
    return obj          
     }


},
methods: {
   dateDifference(data){
              if(this.siteconfig.payroll){
              var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(data, "YYYY-MM-DD");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift
              } else {
                return ''
              }
  },
  getOptions(type){
    return this[type]
  },
  getAuthorInfo(id){
    if(id){
      var obj = this.deptContactData.find(p => p.id == id)
      if(obj){
        return obj.firstName + ' ' + obj.lastName
      } return ''
    } return ''
  },
  getDateFormated(data){
    if(data){
      return moment(data).format('MM/DD/YY')
    } return 'No Date'
  },
  getDateFormat(data){
    if(data){
      return moment(data).format('MM/DD/YY HH:mm')
    } return 'No Date'
  },
  getOptionsFromSelection(data){
    if(data){
      console.log(data)
    return this[data]
    } else {
      return []
    }
  },
  pullFilterData(){
    this.returnedData = []
    if(this.filterTagType == 'apparatusData'){
      this.getApparatusData(this.filterDataBaseId, this.filterDataBaseId.apparatusName, this.apparatusCategorys)
      this.filterCategory = this.apparatusCategorys
      this.catogoriesOrder = this.apparatusCategorys
    }
    if(this.filterTagType == 'stationsData'){
      this.getStationData(this.filterDataBaseId, this.stationCategorys)
      this.filterCategory = this.stationCategorys
      this.catogoriesOrder = this.stationCategorys
    }
    if(this.filterTagType == 'deptContactData'){
      this.getUserData(this.filterDataBaseId, this.userCategorys)
      this.filterCategory = this.userCategorys
      this.catogoriesOrder = this.userCategorys
    }
  },
  apparatusPull(payload, location, endstring){
      axios({
        method: 'get',
        url: '/api/v1/content/' + payload.contentType + '/' + location + '/' + endstring, 
        headers: this.headers
        }).then((response) => {
                var array = response.data
                for(var i in array){
                  var obj = array[i]
                  obj['type'] = payload.contentType
                  this.returnedData.push(obj)
                }
              })
    },
  getUserData(user, stationDataRequest) {
      var firstDay = moment(this.startDate, 'YYYY-MM-DD').toISOString()
      var secondDay = moment(this.endDate, 'YYYY-MM-DD').toISOString()
      this.compiledStart = moment(firstDay).format('YYYY-MM-DD')
      this.compiledEnd = moment(secondDay).isSameOrBefore(moment()) ? moment(secondDay).format('YYYY-MM-DD') : moment().add(1, 'day').format('YYYY-MM-DD')
      var query = firstDay + '/' + secondDay
      var location = 'user'
      for (var i = 0; i < stationDataRequest.length; i++) {
           var payload = {
              contentType: stationDataRequest[i],
              query: user.id + '/' + query,
             }
          this.apparatusPull(payload, location, payload.query)
      }

    },
  getStationData(station, stationDataRequest) {
          var parentCheck = [... new Set(this.stationsData.map(p => { if(p.stationParent != undefined ){ return p.stationParent.toLowerCase()}}))]
          var indexStation = parentCheck.indexOf(station.stationName.toLowerCase())
          if(indexStation >= 0){
            var urlParam = 'parent'
          } else {
            var urlParam = 'station'
          }
          var holdIdObj = station
          var stationID = holdIdObj.stationName
          var firstDay =  moment(this.monthSelect + '/' + this.yearSelect, 'MM/YYYY').startOf('month').toISOString()
          var secondDay = moment(this.monthSelect + '/' + this.yearSelect, 'MM/YYYY').endOf('month').toISOString()
          this.compiledStart = moment(firstDay).format('YYYY-MM-DD')
          this.compiledEnd = moment(secondDay).isSameOrBefore(moment()) ? moment(secondDay).format('YYYY-MM-DD') : moment().add(1, 'day').format('YYYY-MM-DD')
          var query = firstDay + '/' + secondDay
          for (var i = 0; i < stationDataRequest.length; i++) {
           var payload = {
              contentType: stationDataRequest[i],
              query: query,
              urlParam: urlParam + '/' + stationID,
            }
          this.apparatusPull(payload, payload.urlParam, query)
          }
    },
    getApparatusData(holdObj, name, stationDataRequest){
          var firstDay =  moment(this.monthSelect + '/' + this.yearSelect, 'MM/YYYY').startOf('month').toISOString()
          var secondDay = moment(this.monthSelect + '/' + this.yearSelect, 'MM/YYYY').endOf('month').toISOString()
          this.compiledStart = moment(firstDay).format('YYYY-MM-DD')
          this.compiledEnd = moment(secondDay).isSameOrBefore(moment()) ? moment(secondDay).format('YYYY-MM-DD') : moment().add(1, 'day').format('YYYY-MM-DD')
          var query = '/' + firstDay + '/' + secondDay
          for (var i = 0; i < stationDataRequest.length; i++) {
              var payload = {
              contentType: stationDataRequest[i],
              stationVar: name,
              dbID: stationDataRequest[i] == 'dailyvehicle' ? holdObj.id : stationDataRequest[i] == 'repairform' ? holdObj.id : null,
              query: query,
              headers: { headers: this.headers }
              }
              if (payload.contentType == 'event') {
                  payload.stationVar = payload.stationVar + payload.query }
              if (payload.contentType == 'repairform') {
                  var location = 'vehiclecheck'
              } else {
                  var location = 'apparatus'
              }
              if(payload.dbID) {
                  var endstring = payload.dbID + payload.query
              } else {
                  var endstring = payload.stationVar
              }
              if(payload.contentType == 'narctrack'){
                endstring = endstring + payload.query
              }
              this.apparatusPull(payload, location, endstring)
            }
    }
        


  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
