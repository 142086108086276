<template>
  <div class="apparatus" v-if="apparatusData.length > 0">
      <div @click="narcLogShow = !narcLogShow" id="logbookTitle" class="titleBodyWrap" :class="getShiftColor(narcsData.narctrackShift) + '-title'" >
       <h5><b-badge :class="typeOfEntry(narcsData.narctrackEntryType)">{{ typeOfEntry(narcsData.narctrackEntryType) }}</b-badge> 
       {{ narcDateFormatDate(narcsData.narctrackDate) }} {{ narcDateFormatTime(narcsData.narctrackDate) }} | {{ apparatusRadio(narcsData.narctrackTagId) }}
       | {{ returnAuthorLastName(narcsData.narctrackAuthorId) }} |
        <span v-if="narcsData.narctrackEntryType != 'Daily Narc Use'">{{ returnWitnessLastName(narcsData) }}</span>
        <span v-if="narcsData.narctrackEntryType == 'Daily Narc Use'">{{ narcsData.narctrackWitness }}</span>
         <span v-if="narcsData.narctrackWitness != ''"><span v-if="narcsData.narctrackWitnessSignature == 'Signature Required'" class="flagWarning">&#9873; </span></span></h5>
        </div>
    <b-container v-if="narcLogShow" class="narcContainer">
    
      <b-row class="narcbox-topbar-house">
        <b-col class="narcbox-topbar-col"><div class="narcbox-topbar" :class="typeOfEntry(narcsData.narctrackEntryType)" ><span class="narcbox-topbar-span">{{ typeOfEntry(narcsData.narctrackEntryType) }}</span>
                <div class="rightborder" :class="typeOfEntry(narcsData.narctrackEntryType) + '-border'"></div></div></b-col>
        <b-col class="narcbox-topbar-col"><div><span class="narcbox-topbar-span">{{ narcsData.narctrackTagId }}</span></div></b-col>
        <b-col class="narcbox-topbar-col"><div  class="narcbox-topbar-right" :class="getShiftColor(narcsData.narctrackShift)"><div class="leftborder" :class="getShiftColor(narcsData.narctrackShift) + '-border'"></div>
          <span class="narcbox-topbar-span">{{ narcsData.narctrackShift }}</span></div></b-col>
      </b-row>
    <b-row>
      <b-col><label><h5 class="labelHeaders">Date: </h5></label> {{ narcDateFormatDateCard(narcsData.narctrackDate) }}</b-col>
      <b-col cols="auto"><b-badge v-if="narcs && narcs.narctrackEntryType == 'verification'" :variant="checkForVerifedDet == 0 ? 'success' : 'warning'">
                  <span v-if="checkForVerifedDet == 0">&#x2713;</span>
                  <span v-if="checkForVerifedDet != 0">{{ checkForVerifedDet }}</span>
                  </b-badge></b-col>
      <b-col><label><h5 class="labelHeaders">Time: </h5></label> {{ narcDateFormatTime(narcsData.narctrackDate) }}</b-col>
    </b-row>
    <div  v-if="!editNarcLog">
     <b-container>
    <b-card v-if="removedNarcs || addedNarcs" 
            @click="hideRecentChanges = !hideRecentChanges"
            :no-body="!hideRecentChanges"
            :header="hideRecentChanges ? 'Hide Changes Since Last Verification' : 'Show Changes Since Last Verification'" border-variant="danger" header-bg-variant="danger" size="sm" 
            text-variant="white" header-class="py-1" body-class="py-2"> 
            <template v-if="removedNarcs">
                <b-card   v-show="hideRecentChanges && narcsData.narctrackEntryType && narcsData.narctrackEntryType != 'initial'" v-for="narc in filteredVerifiedMethod(removedNarcs)" :key="narc.id" header-tag="header" header-bg-variant="warning" class="mb-2" header-class="py-1" no-body>
                   <h6 slot="header" class="mb-0 font-weight-bold text-dark">REMOVED: {{ narc.medication }} | CN: {{ narc.controlNumber }}</h6>
                </b-card>
            </template>
            <template v-if="addedNarcs" >
                <b-card  v-show="hideRecentChanges" v-for="narc in filteredVerifiedMethod(addedNarcs)" :key="narc.id" header-tag="header" header-bg-variant="primary" text-variant="light" class="mb-2" header-class="py-1" no-body>
                   <h6 slot="header" class="mb-0 font-weight-bold text-light">Added: {{ narc.medication }} | CN: {{ narc.controlNumber }}</h6>
                </b-card>
            </template>
      </b-card>
      <b-card v-for="narc in filteredVerifiedReturned" :key="narc.id" header-tag="header" header-bg-variant="dark" class="mb-2" body-class="py-1" header-class="py-1">
                 <h6 slot="header" class="mb-0 font-weight-bold text-light">{{ narc.medication }}
                    <b-badge variant="success" v-if="previousVerificationTest && previousVerificationTest.indexOf(narc.id) >= 0">&#x2713;</b-badge>
                    <b-badge variant="primary" v-if="previousVerificationTest && previousVerificationTest.indexOf(narc.id) == -1">*NEW</b-badge>
                 </h6>
              <b-row class="mt-0 pt-0">
                <b-col align="left" ><span class="font-weight-bold">CN: </span>{{ narc.controlNumber }}</b-col>
                 <b-col align="left" :class="testNarcDate(narc.expDate, narcsData.narctrackDate)"><span class="font-weight-bold">Exp: {{ getExpirationDate(narc.expDate) }}</span></b-col>
                </b-row>
                <b-row class="mt-0 pt-0">
                <b-col align="left"><span class="font-weight-bold">NDC: </span>{{ narc.NdcNumber }}</b-col>
                <b-col align="left"><span class="font-weight-bold">Lot: </span>{{ narc.LotNumber }}</b-col>
                </b-row>
              </b-card>
              <b-pagination v-if="filteredVerified.length > 8" align="center"  v-model="currentPage" :per-page="8" :total-rows="filteredVerified.length"  />
    </b-container>
    <template v-if="narcsData.narctrackAdditionalFields">
      <b-row>
      <b-col v-for="ndata in narcsData.narctrackAdditionalFields" :key="ndata.id">
          <h6 class="font-weight-bold">{{ ndata.label }}: </h6> 
          {{ ndata.value }}
        </b-col>
        </b-row>
    </template>
     <b-card v-if="narcsData.narctrackEntryDetails" border-variant="danger" class="mx-3" no-body>
          <p><span class="font-weight-bold"> Entry Details:</span> {{ narcsData.narctrackEntryDetails }}</p>
          </b-card>
      <b-card class="mx-3" no-body>
      <b-btn v-if="userData.role == 'emsops' || userData.role == 'admin' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1)" 
             block 
             variant="info" 
             small 
             @click="!adminNoteOn ? adminNoteOn = !adminNoteOn : adminNoteOn && narcSubNote ? submitNarcNote() : adminNoteOn = !adminNoteOn">{{ narcsData.narctrackSubmissionNote && !adminNoteOn ? 'Edit Admin Note' : narcSubNote && adminNoteOn ? 'Save Note' : 'Add Admin Note' }}</b-btn>
      <b-textarea v-if="adminNoteOn" :disabled="!adminNoteOn" v-model="narcSubNote" />
      <p v-if="narcSubNote && !adminNoteOn">Admin Note: {{ narcSubNote }}</p>
      </b-card>
      
      <b-row id="userNamesLabels"><b-col><label><h5 class="labelHeaders">Author </h5></label> </b-col>
        <b-col><label><h5 class="labelHeaders">Witness </h5></label> </b-col>
      </b-row>
      <b-row id="userNames"><b-col>{{ narcsData.narctrackAuthor }}</b-col><b-col>{{ narcsData.narctrackWitness }}</b-col></b-row>
      <b-row>
 <b-col v-if="!(narcsData.narctrackAuthorSignature == 'Signature Saved' || narcsData.narctrackAuthorSignature == 'Signature Required') && narcsData.narctrackAuthorSignature"><img width="150px" :src='"data:image/png;base64," + fixImage(narcsData.narctrackAuthorSignature)' /></b-col>

  <b-col v-if="narcsData.narctrackAuthorSignature == 'Signature Saved' || narcsData.narctrackAuthorSignature == 'Signature Required'">
        <b-btn 
        :variant='narcsData.narctrackAuthorSignature == "Signature Saved" ? "success" : "danger"'
        :disabled="narcsData.narctrackAuthorSignature == 'Signature Required' && userData.id != narcsData.narctrackAuthorId"
        @click="narcsData.narctrackAuthorSignature == 'Signature Saved' ? getSignatures(narcsData.id) : launchSignaturePanel(narcsData)"
        >{{ narcsData.narctrackAuthorSignature }}</b-btn>
        </b-col>
        <b-col v-if="narcsData.narctrackWitnessSignature && narcsData.narctrackWitnessSignature != 'Signature Saved' && narcsData.narctrackWitnessSignature != 'Signature Required'" ><img width="150px" :src='"data:image/png;base64," + fixImage(narcsData.narctrackWitnessSignature)' /></b-col>
        <b-col v-if="narcsData.narctrackWitnessSignature == 'Signature Saved' || narcsData.narctrackWitnessSignature == 'Signature Required'">{{ narcsData.narctrackWitnessSignature }}
        <b-btn size="sm" :disabled="narcsData.narctrackWitnessId != userData.id" variant="danger" @click="launchSignaturePanel(narcsData)" v-if="narcsData.narctrackWitnessSignature == 'Signature Required'">Add Signature</b-btn>
        </b-col>
        </b-row>
        <b-img fluid v-if="narcsData.narctrackOfflineAttach" :src='"data:image/png;base64," + fixImage(narcsData.narctrackOfflineAttach.data)' />
       
        <b-row v-if="userData.role == 'admin' || userData.role == 'emsops' || (userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1)" class="mb-2"><b-col align="right">
          <b-form-checkbox v-model="deleteNarcFlag" :check-value="true" :uncheck-value="false">Activate Delete Button</b-form-checkbox></b-col>
          <b-col><b-btn @click="removeNarcLog(narcsData.id)" variant="danger" :disabled="!deleteNarcFlag" size="sm">Delete</b-btn></b-col></b-row>
      </div>
    
  </b-container>
   
  </div>

</template>

<script>
import moment from "moment";
import axios from 'axios';
import { mapState } from "vuex";
import signature from '../../inputfields/signaturepad.vue';
import { bus } from '../../../main'
import Multiselect from "vue-multiselect";
export default {
  props: ["narcs", "showPost"],
  components: {
    'signature': signature,
     multiselect: Multiselect,
  },
  name: "apparatus",
  data() {
    return {
      items: [],
      narcLogShow: false,
      editNarcLog: false,
      resetInput: true,
      currentPage: 1,
      medicationArrayData: [],
      medicationItemArray: [],
      itemArrayData: [],
      witnessSign: false,
      deleteNarcFlag: false,
      adminNoteOn: false,
      narcSubNote: '',
      medication: [],
      previousVerificationTest: null,
      addedNarcs: null,
      removedNarcs: null,
      verifiedNarcs: null,
      hideRecentChanges: true,
      checkForVerifedDet: false,
      concentration: [{ value: "mg" }, { value: "mcg" }, {value: "min"}],
      selectYesOrNo: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      itemData: [
        { value: "Adult Probe", text: "Adult Probe" },
        { value: "Child Probe", text: "Child Probe" },
        { value: "Replacement(other)", text: "Replacement(other)" }
      ],
      editReason: "",
      returnData: null,
    };
  },
  created() {
    if(this.showPost == true){
      this.narcLogShow = true
    }
    this.removedNarcs = null
    this.addedNarcs = null
  },
  computed: {
    ...mapState(["userData", "apparatusData", "deptContactData", "getSessionData", "medicationData", 'medsNarctrack', 'siteconfig']),
     filteredVerified(){
      var ids = this.narcs.narctrackMeds
      ids = [... new Set(ids.map(p => p))]
    //  console.log(ids)
      var narcs = ids.map(item => {
          var med = this.medsNarctrack.find(p => p.id == item)
          if(med){
            return med
          } else {
            return {id: item, medication: 'Unknown', controlNumber: 'Unknown', expDate: null, ndcNumber: 'Unknown', lotNumber: 'Unknown' }
          }
      })
      return _.orderBy(narcs, ['Name', 'ControlNumber'], ['asc', 'asc'])
    },
    filteredVerifiedReturned(){
      var array = _.cloneDeep(this.filteredVerified)
      var current = (this.currentPage - 1) * 8
      return array.splice(current, 8)
    },
     narcsData(){
       if(!this.returnData){
       var narcObj = this.narcs 
      this.narcSubNote  = narcObj.narctrackSubmissionNote
       } else {
       var narcObj = this.returnData
       this.narcSubNote = narcObj.narctrackSubmissionNote
       }
       return narcObj
     },

  },
  watch: {
    narcLogShow(newData){
      if(newData == true && this.narcs && this.narcs.narctrackEntryType && this.narcs.narctrackTagId && this.narcs.narctrackEntryType == 'verification' && this.narcs.narctrackMeds){
        this.verifiedNarcs = this.narcs.narctrackMeds
        this.getRecentNarcCards(this.narcs.narctrackTagId, 'verify')
    //    console.log(this.narcs)
      }
    }
  },
  methods: {
        getShiftColor(shiftdata){
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shiftdata)
      if(obj){
        return obj.color
      } return shift
    },
    testNarcDate(narc, dateselected){
      if(narc && dateselected){
       // console.log(moment(narc).diff(dateselected, 'days'))
        if(moment(narc).isSameOrAfter(moment(dateselected, 'YYYY-MM-DD'), 'day') && moment(narc).diff(dateselected, 'days') < 30 && moment(narc).diff(dateselected, 'days') >= 0){
          return 'text-warning'
        }
        if(moment(narc).isSameOrAfter(moment(dateselected, 'YYYY-MM-DD'), 'day')){
          return 'text-success'
        }
        if(moment(narc).isBefore(moment(dateselected, 'YYYY-MM-DD'), 'day')){
          return 'text-danger'
        }
      } return 'text-secondary'
    },
      getRecentNarcCards(data, type){
       var startDate = moment(this.narcs.narctrackDate).add(1, 'd').toISOString()
       var endDate = moment(startDate).subtract(1, 'y').toISOString() //need to change this back to 30 days
       axios({
        method: 'get',
        url: '/api/v1/content/dailynarctrack/apparatus/' + data + '/' + endDate + '/' + startDate,
        headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
       // console.log(response.data)
        if(type == 'verify'){
        var cards = _.orderBy(response.data, ['narctrackDate', 'createdAt'], ['desc', 'desc'])
        var verify = cards.filter(p => {
        return p.narctrackEntryType == 'verification'
        })
        var index = verify.map(p => p.id).indexOf(this.narcs.id)
        if(index >= 0){
          var nextCard = index + 1
          if(verify[nextCard] && verify[nextCard].narctrackMeds){
         //   console.log(verify[nextCard].narctrackMeds)
            this.previousVerificationTest = verify[nextCard].narctrackMeds
            this.checkForVerifed()
          }
        }
        }
      })
    },
    checkForVerifedPrev(){
        if(this.verifiedNarcs && this.verifiedNarcs.length > 0 && this.previousVerificationTest && this.previousVerificationTest.length > 0){
        var previous = this.previousVerificationTest
        var current = this.verifiedNarcs
        var count = []
        for(var i in previous){
          var index = current.indexOf(previous[i])
          if(index == -1){
            count.push(previous[i])
          }
        }
        // console.log(count)
        if(count && count.length > 0){
          this.removedNarcs = count
          return count
        } else {
          return false
        }

      } else {
        false
      }

    },    
    checkForVerifed(){
      if(this.verifiedNarcs && this.verifiedNarcs.length > 0 && this.previousVerificationTest && this.previousVerificationTest.length > 0){
        var previous = this.previousVerificationTest
        var current = this.verifiedNarcs
        var count = []
        for(var i in current){
          var index = previous.indexOf(current[i])
          if(index == -1){
            count.push(current[i])
          }
        }
        if(count.length > 0){
          this.addedNarcs = count
        }
        var diff = count.length
        var prevDiff = this.checkForVerifedPrev()
        if(prevDiff && prevDiff.length){
          diff = diff + prevDiff.length
        }
        this.checkForVerifedDet = diff

      } else {
        this.checkForVerifedDet = false
      }

    },
    filteredVerifiedMethod(ids, boo, search){
      var narcs = ids.map(item => {
          var med = this.medsNarctrack.find(p => p.id == item)
          if(med){
            return med
          } else {
          //  console.log('NO Medications Found')
         //   console.log(this.medsNarctrack)
            return {id: item, medication: 'Unknown', controlNumber: 'Unknown', expDate: null, ndcNumber: 'Unknown', lotNumber: 'Unknown' }
          }
      })
      return _.orderBy(narcs, ['medication', 'controlNumber'], ['asc', 'asc'])
    },
    getExpirationDate(date){
      if(date){
        return moment(date).format('MM/DD/YY')
      } return 'Unknown'
    },

    launchSignaturePanel(narcsData){
      bus.$emit('LaunchSignaturePanelDaily', narcsData)
    },
    narcsWitnessTest(narcsData){
      if(narcsData.narctrackWitnessSignature.data == '' && narcsData.narctrackWitnessId == this.userData.id){
        return true
      } else {
        return false
      }
    },
    returnAuthorLastName(data){
      
      var user = this.deptContactData.filter( p => {
        return p.id.match(data)
      })
      if(user != undefined && user.length > 0){
      return user[0].lastName
      }
    },
    returnWitnessLastName(data){

      if(data.narctrackWitnessId != undefined && data.narctrackWitnessId != null && data.narctrackWitnessId != ''){
          var user = this.deptContactData.filter( p => {
            return p.id == data.narctrackWitnessId
          })
          if(user != undefined && user.length > 0){
            if(data.narctrackRunNumber != undefined && data.narctrackRunNumber != null && data.narctrackRunNumber != ''){
              return user[0].lastName + ' | ' + data.narctrackRunNumber
            }
          return user[0].lastName
       }
      } else if (data.narctrackRunNumber != undefined && data.narctrackRunNumber != null && data.narctrackRunNumber != '') {
        return data.narctrackRunNumber

      } else {
        return ''
      }

    },
    apparatusRadio(data){
      var app = this.apparatusData.filter( p => {
        return p.apparatusName.match(data)
      })
      if(app[0] && app[0].apparatusRadioId){
      return app[0].apparatusRadioId
      } return data
    },
    fixImage(data) {
      var string = new Buffer(data.data, "base64").toString("utf8");

      return string;
    },
    editNarcLogSwitch(event) {
      this.editNarcLog = !this.editNarcLog;
      this.medicationArrayData = this.narcsData.narctrackMeds
    },
    cancelEditNarcLogSwitch(event) {
      this.editNarcLog = !this.editNarcLog;
      this.medicationArray(this.narcsData);
      this.itemArray(this.narcsData);
    },
    checkSaveData(data){
       axios({
        method: 'patch',
        url: '/api/v1/content/narctrack/adminpatch/' + this.narcsData.id,
        data: {
                'narctrackMeds': data,
              },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
    //    console.log(response.data)
      })
    },
    getSignatures(id){
      axios({
        method: 'get',
        url: '/api/v2/content/dailynarctrack/signatures/' + id,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
        }).then(response => {
          this.returnData = response.data
      })
    },
    narcAppDate(data) {
      return moment(data).format("MM/DD/YYYY");
    },
    typeOfEntry(data) {
      return data
    },
    narcDateFormatDate(data) {
      var date = moment(data).format("MM/DD/YY");
      var current = moment().format("MM/DD/YY");
      if (date == current) {
        return;
      } else {
        return date + " | ";
      }
    },
        updateMedicationSelection(data, value){
      this.resetInput = false
      var newMed = this.medicationItemArray.find(p => p.medicationName == value)
      var type = this.apparatusSelectValue.apparatusVehicleType.toLowerCase()
      var count = type + "Count";
      var obj = newMed
      var value = obj[count]
      if(!value){
        value = 0
      }
      var string = { med: newMed.medicationName }
      if(newMed.narcoticTrackCt){
        if(this.statusSelect != 'Daily Narc Restock'){
        string['count'] = value
        } else {
          string['count'] = 1
        }
        }
      if(newMed.narcoticTrackDate){
         string['inDate'] = true
        }
      if(newMed.narcoticTrackId){
          string['id'] = ''
          string['requireId'] = true
      }
      if(newMed.narcoticTrackTamper){
         string['tamperSealed'] = true
          }
      if(newMed.alsSupply){
        this.medicationArrayData[data] = string
      } else {
        this.medicationArrayData[data] = string
      }
      this.$nextTick(function(){
        this.resetInput = true
      })
    },
    medicationArray(data) {
      return data.narctrackMeds
    },
    itemArray(data) {
      return data.narctrackItems
    },
    narcDateFormatTime(data) {
      return moment(data).format("HH:mm");
    },
    narcDateFormatDateCard(data) {
      return moment(data).format("MM/DD/YY");
    },
    removeNarcLog(data){
    var result = confirm('Are You Sure You Want to Delete this Narclog? No Going Back!')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/content/dailynarctrack/' + data,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
    this.$nextTick(function(){
        bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
        bus.$emit('emsPullRequestChange', undefined)
        bus.$emit('updateMedicationsTracking')
        
    })
  }) 
      }
    },
    submitNarcNote(){
       axios({
        method: 'patch',
        url: '/api/v1/content/dailynarctrack/' + this.narcsData.id,
        data: {
                "narctrackSubmissionNote": this.narcSubNote
              },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
      //  console.log(response)
        if(response && response.data[0] && response.data[0]){
      this.returnData = response.data[0]
      this.adminNoteOn = false
        }

      })
    },
    submitNarcLog(){
       axios({
        method: 'post',
        url: '/api/v1/content/narctrack',
        data: {
                "title": moment().format('MM/DD/YYYY-HH:mm') + ' | ' + this.apparatusSelectValue.apparatusName + ' | ' + this.statusSelect + ' | ' + this.userData.lastName,
                'narctrackEntryType': this.statusSelect,
                'narctrackDate': this.formatedDateExport,
                'narctrackRunNumber': this.reportRunNumber,
                'narctrackTagId': this.apparatusSelectValue.apparatusName,
                'narctrackMeds': this.field_medication_tracking,
                'narctrackItems': this.field_item_tracking, 
                'narctrackShift': this.shiftSet, 
                'narctrackStation': this.stationSelectValue.stationName, 
                'narctrackStationParent': this.stationSelectValue.stationParent, 
                'narctrackAuthor': this.userData.firstName + ' ' + this.userData.lastName, 
                'narctrackAuthorId': this.userData.id, 
                'narctrackAuthorSignature': this.newImageFileData, 
                'narctrackWitness': this.witnessOrOffGoing.firstName + ' ' + this.witnessOrOffGoing.lastName, 
                'narctrackWitnessId': this.witnessOrOffGoing.id, 
                'narctrackWitnessSignature': this.newImageFileDataWitness, 
                'narctrackEntryDetails': this.narcDescription, 
                'narctrackEmailWitness': this.emailWitness
              },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
      this.postData = response.data
        })
        this.showModalConfirmation = !this.showModalConfirmation;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flagWarning {
  color: red;
}
.narcbox-house {
  margin: 0 auto 0;

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #fff;
}
.narcbox-topbar-house {
  height: 41px;
  width: 100%;
  border-bottom: 1px solid #777;
  border-radius: 10px 10px 0px 0px;
  margin: 0 auto 0;
  padding-top: 0 !important;
}
.narcbox-topbar {
  width: 100%;
  height: 40px;
  border-radius: 10px 10px 0 0;
}
.narcbox-topbar-right {
  width: 100%;
  height: 40px;
  border-radius: 0 10px 0 0;
}
.narcbox-topbar-span {
  display: inline-block;
  padding-top: 8px;
  margin-left: -15px;
  margin-right: -15px;
}

.rightborder {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid #ccc;
  height: 0;
  width: 0;
}
.leftborder {
  float: left;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid #fff;
  height: 0;
  width: 0;
}
.narcbox-topbar-col {
  padding: 0 !important;
}

.apparatus {
  border-radius: 10px;
  text-align: center;
}
#userNamesLabels.row {
  padding-top: 10px;
}
#userNames.row {
  padding-top: 0;
}
.Red-shift {
  background: #dc3545;
  color: #fff;
}
.Yellow-shift {
  background: #ffc107;
  color: #000;
}
.Blue-shift {
  background: #007bff;
  color: #fff;
}
.Green-shift {
  background: #28a745;
  color: #fff;
}
.apparatusTitleBar {
  color: #fff;
}
.row {
  padding-top: 10px;
  padding-bottom: 0;
}
#medLabelHeaders .col {
  font-weight: bold;
}
.medsLayout {
  border: 1px solid #aaa;
  margin-bottom: 5px;
}
h5.medNameHeader {
  font-weight: bold;
  background: #eee;
  padding: 2px;
  padding-top: 0;
}
h5.labelHeaders {
  font-weight: bold;
}
.narcContainer {
  width: auto;
  border: 1px solid #777;
  margin: 10px;
  border-radius: 10px;
  overflow-x: hidden;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.initialcontainer {
  border: 1px solid #6c757d;
  margin-bottom: 15px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.verificationcontainer {
  border: 1px solid #28a745;
  margin-bottom: 15px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.distributioncontainer {
  border: 1px solid #17a2b8;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.restockcontainer {
  border: 1px solid #007bff;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.usecontainer {
  border: 1px solid #ffc107;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.destroycontainer {
  border: 1px solid #000000;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.initial {
  background-color: #6c757d;
  color: #fff;
}
.verification {
  background-color: #28a745;
  color: #fff;
}
.distribution {
  background-color: #17a2b8;
  color: #fff;
}
.restock {
  background-color: #007bff;
  color: #fff;
}
.quarantine {
  background-color: #dc3545;
  color: #fff;
}
.use {
  background-color: #ffc107;
  color: #fff;
}
.destroy {
  background-color: #000000;
  color: #fff;
}
.usecontainer {
  border: 1px solid #ffc107;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.destroy-border {
  border-left: 10px solid #000000;
  float: right;
  background: #fff;
}
.use-border {
  border-left: 10px solid #ffc107;
  float: right;
  background: #fff;
}
.restock-border {
  border-left: 10px solid #007bff;
  float: right;
  background: #fff;
}
.initial-border {
  border-left: 10px solid #6c757d;
  float: right;
  background: #fff;
}
.verification-border {
  border-left: 10px solid #28a745;
  float: right;
  background: #fff;
}
.distribution-border {
  border-left: 10px solid #17a2b8;
  float: right;
  background: #fff;
}
.quarantine-border {
  border-left: 10px solid #dc3545;
  float: right;
  background: #fff;
}
#medContainers .col {
  padding: 0;
  margin: 0;
}
#medContainers .row {
  padding: 0;
}
h1,
h2 {
  font-weight: normal;
}
.titleBodyWrap {
  margin: 0 auto 0;
  text-align: left;
  width: 80%;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 5px;
}
.Red-shift-title {
  color: #e2070d;
}
.Yellow-shift-title {
  color: #ffc107;
}
.Blue-shift-title {
  color: #201b9f;
}
.Green-shift-title {
  color: #0fb906;
}

@media screen and (max-width: 768px) {
  .titleBodyWrap {
    margin: 0 auto 0;
    width: 95%;
    padding: 8px;
    margin-bottom: 0;
    margin-top: 5px;
  }
}
</style>
