<template>
  <div class="hello">
 
 <b-card header-tag="header">
   <b-row><b-col><b-btn v-show="clockRun == false" variant="success" @click="interval = 0, clockRun = true">Start Time</b-btn>
   <b-btn v-show="clockRun == true" variant="danger" @click="initialTime = currentClockTime, clockRun = false">Pause Time</b-btn>
   </b-col>
   <b-col><div id="clock" class="clock">{{ currentClockTime }}</div></b-col>
  <b-col><b-btn variant="info" @click="clockRun = false, initialTime = startTime">Reset</b-btn></b-col>
   </b-row>
   </b-card>
   {{ incident }}
  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'hello',
  components: {

  },
  props: ['incident'],
  data () {
    return {
      shiftSelect: '',
      rankHold: [],
      specHold: [],
      hoverId: '',
      fields: [
        {key: 'dispatch_dttm', label: 'Date'},
        {key: 'incident_number', label: 'Incident'},
        'address',
        'call_type',
        'call_type_group',
        'unit_id',
        'map',
        'pre-plan',
      ],
      originalDataPull: [],
      CadTableData: [],
      singleIncident: '',
      incidentDataArray: [],
      elaspedTime: 0,
      incidentSort: [],
      clockRun: false,
      interval: 0,
      startTime: '',
    }
  },
  created(){
    this.incidentSort = _.orderBy(this.incident, ['dispatch_dttm'], ['asc'])
    this.startTime = moment(this.incidentSort[0].dispatch_dttm).format('MM/DD/YY  HH:mm:ss')
    this.initialTime = moment(this.incidentSort[0].dispatch_dttm).format('MM/DD/YY  HH:mm:ss')
    var self = this
    this.interval = setInterval(() => {
          self.interval = self.interval + 1
        }, 1000)
  },
  mounted(){
  },
  computed: {
      ...mapState([
        'apparatusData',
        'stationsData',
        'deptContactData',
        'userData',
        'documentTags',
        'getSessionData',
        'documentsApi'
      ]),
    currentClockTime(){
      if(this.clockRun == true){
        var time = moment(this.initialTime, 'MM/DD/YY  HH:mm:ss').add(this.interval, 'seconds').format('MM/DD/YY  HH:mm:ss')
        return time
      } else {
        return this.initialTime
      }
    },
    departmentFilter(){
      var shift = this.deptContactData.filter(p => {
        return p.shiftAssignment.match(this.shiftSelect)
      })
      return shift
      },
      departmentData(){
      var data = this.buildData(this.departmentFilter)
      return data
      },
      departmentDataRanks(){
        var data = this.buildDataRank(this.departmentFilter)
        return data
      },
      departmentDataSpec(){
        var data = this.buildDataSpec(this.departmentFilter)
        return data
      },
    

  },
  watch: {
  },
  methods: {

    setIncidentDataArray(id){
      var data = this.originalDataPull.filter(p => {
        return p.incident_number == id
      })
      this.incidentDataArray = data
    },
    getTime(data){
      if(data){
      return moment(data).format('MM/DD/YY HH:mm:ss')
      } else {
        return 'Not Applicable'
      }
    },
   /* https://data.cityofchicago.org/resource/6zsd-86xi.json?$where=date between '2015-01-10T12:00:00' and '2015-01-10T14:00:00' */
    getCADData(){
      var date = moment().subtract(2, 'days').format('YYYY-MM-DD')
      var time = moment().format('HH:mm')
      var string = "$where=call_date between '" + date + "T00:00:00'" +  " and '" + date + 'T' + "23:59:00'"
   //   console.log(string)
      axios({
          type: 'Get',
          url: "https://data.sfgov.org/resource/enhu-st7v.json?" + string,
           data: {
        }
}).then(response => {
  var data = response.data
  this.originalDataPull = _.cloneDeep(data)
  var map = [...new Set(data.map(p => p.incident_number))]
//  console.log(map.length)
  var arr = []
  for(var i in data){
    var index = arr.map(p => p.incident_number).indexOf(data[i].incident_number)
    if(index < 0){
      arr.push(data[i])
    } else {
      var unit = arr[index].unit_id
      var unit = unit + ', ' + data[i].unit_id
      arr[index].unit_id = unit
    }
  }
  var data = arr
  var data = _.orderBy(data, ['incident_number'], ['desc'])
  this.CadTableData = data
//  console.log(response.data);
});
    },
    buildData(shift){
      var stations = [...new Set(this.stationsData.filter(p => {
      return p.stationParent != ''
    }))]
    var dataArr = []
    for(var i in stations){
      var users = shift.filter(p => {
        return p.stationAssignment == stations[i].stationName
      })
      if(users){
        var time = []
        for(var p in users){
          time.push(this.getYearsService(users[p].hireDate))
        }
        var total = 0
        if(time.length > 0){
          for(var t in time){
            total += time[t]
          }
          var total = total/time.length
          dataArr.push({id: stations[i].id, name: stations[i].stationName, time: total})
        }
      }
    }
   return _.orderBy(dataArr, ['time'], ['asc'])
    },
   buildDataRank(shift){
      var stations = [...new Set(this.stationsData.filter(p => {
      return p.stationParent != ''
    }))]
    var ranksHoldArr = []
    var dataArr = []
    for(var i in stations){
      var users = shift.filter(p => {
        return p.stationAssignment == stations[i].stationName
      })
      if(users){
        var employees = users.length
        var ranks = []
        for(var p in users){
          ranksHoldArr.push(users[p].deptRank)
          ranks.push(users[p].deptRank)
        }
        var total = []
        var rankSetup = [...new Set(ranks.map(p => p))]
        var rankSetup = _.sortBy(rankSetup)
        if(rankSetup.length > 0){
          for(var r in rankSetup){
           var countlength = ranks.filter(p => {
              return p == rankSetup[r]
            })
            total.push({rankName: rankSetup[r], count: countlength.length})
          }
          dataArr.push({id: stations[i].id, name: stations[i].stationName, ranks: total, totalCt: employees})
        }
      }
    }
    var rankSetupNew = [...new Set(ranksHoldArr.map(p => p))]
    this.rankHold =  _.sortBy(rankSetupNew)
   return dataArr
    },
       buildDataSpec(shift){
      var stations = [...new Set(this.stationsData.filter(p => {
      return p.stationParent != ''
    }))]
    var ranksHoldArr = []
    var dataArr = []
    for(var i in stations){
      var users = shift.filter(p => {
        return p.stationAssignment == stations[i].stationName
      })
      if(users){
        var employees = users.length
        var ranks = []
        for(var p in users){
          var spec = users[p].deptSpecialties
          for( var s in spec){
          ranksHoldArr.push(spec[s])
          ranks.push(spec[s])
          }
        }
        var newHoldLength = ranks.length
        var total = []
        var rankSetup = [...new Set(ranks.map(p => p))]
        var rankSetup = _.sortBy(rankSetup)
        if(rankSetup.length > 0){
          for(var r in rankSetup){
           var countlength = ranks.filter(p => {
              return p == rankSetup[r]
            })
            total.push({rankName: rankSetup[r], count: countlength.length})
          }
          dataArr.push({id: stations[i].id, name: stations[i].stationName, ranks: total, totalCt: employees,  totalSpec: newHoldLength})
        }
      }
    }
    var rankSetupNew = [...new Set(ranksHoldArr.map(p => p))]
    this.specHold =  _.sortBy(rankSetupNew)
  //  console.log(dataArr)
   return dataArr
    },
        getYearsService(data){
      var dataSlice = data.slice(0, -5)
      return moment().diff(dataSlice, 'years')

    },
  
  }

}
</script>
<style scope>
.clock {
  height: 50px;
  width: 70%;
  line-height: 50px;  
  margin: 0 auto 0;
  padding: 0 5px;
  background: #e2070d;
  color: #eee;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 0 7px #222;
  text-shadow: 0 0 3px #fff;
}
.chart {
  font: 10px sans-serif;
  background-color: steelblue;
  text-align: right;
  padding: 3px;
  margin: 1px;
  color: white;
  display: inline-block;
}
.rankDivWrapper {
  display: inline-block;
}
.specDivWrapper {
  display: inline-block;
  background-color:#aaa;
}
.specDiv {
  height: 4px;
}
.rankDiv {
  display: inline-block;
  font-size: 12px;
  min-height: 16px;
  color: #f2f2f2;
  padding-left: 5px;
}
.keyBox {
  width: 10px;
  height: 10px;
      display: inline-block;
    margin-right: 5px;
    margin-left: 15px;
}
.rank-0 {
  background-color: rgb(0, 136, 0);
}
.rank-1 {
  background-color: rgb(209, 171, 0);
}
.rank-2 {
  background-color: rgb(1, 51, 190);
}
.rank-3 {
  background-color: rgb(0, 121, 177);
}
.rank-4 {
  background-color: #777;
}
.rank-5 {
  background-color: rgb(158, 0, 0);
}
.rank-6 {
  background-color: rgb(3, 141, 118);
}
.rank-7 {
  background-color: #666;
}
.rank-8 {
  background-color: #777;
}
.rank-9 {
  background-color: #888;
}
.rank-10 {
  background-color: #999;
}
.rank-11 {
  background-color: #aaa;
}
.chartWrapper {
  text-align: left;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 1s;
  opacity: 0;
}
.slide-fade-move {
  transition: transform 1s;

}
.slide-fade-enter, .slide-fade-leave {
  opacity: 0;
  width: 0% !important;
  max-height: 10px !important;
}
</style>
