<template>
<div>
  <b-card no-body> 
    <b-tabs card>
      <b-tab title="Credential Groups">
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Credential Groups</b-navbar-brand>
          <b-btn size="sm" :disabled="editUserTable" :variant="!newCredentialGroup ? 'success' : 'danger'" @click="newCredentialGroup = !newCredentialGroup">
            {{ !newCredentialGroup ? 'Add New Credential Group' : 'Cancel Add Credential Group' }}</b-btn>
      </b-nav-form>
    </b-navbar>
    <b-container v-if="newCredentialGroup" class="pt-2">
      <b-row><b-col>
      <b-form-input type="text" v-model="newCredentialGroupName" placeholder="New Credential Group Name" />
      </b-col><b-col cols="*">
      <b-btn @click="createNewCredentialGroup">Save</b-btn>
      </b-col></b-row>
      </b-container>
    <b-card class="mx-5 mt-1" v-if="credentialsData" :no-body="currentlyViewing != parents.id ? true : false" v-for="parents in parentGroupingsOverview" :key="parents.id"
     header-tag="header">
     <h4 slot="header"  @click="!currentlyViewing ? currentlyViewing = parents.id : currentlyViewing != parents.id ? currentlyViewing = parents.id : currentlyViewing = null">
       {{ parents.credentialName }}
       <b-btn v-if="userData.role == 'admin'" @click.stop="credentialDeleteSubmit(parents)">Delete</b-btn>
       </h4>
     <b-container fluid v-if="currentlyViewing == parents.id">
      <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Credentials Admin</b-navbar-brand>
          <b-btn size="sm" v-if="false" :disabled="editUserTable" variant="success" @click.stop="ToggleAddNewCredential">Add New {{parents.credentialName }} Credential</b-btn>
          <b-btn size="sm" :disabled="editUserTable" :variant="!addNewCredential ? 'success' : 'danger'" @click.stop="ToggleAddNewCredential">
            {{ !addNewCredential ? 'Add New' : 'Cancel Add' }} {{parents.credentialName }} Credential</b-btn>
        <b-btn class="bootstrapTable" size="sm" :disabled="addNewCredential" :variant="editUserTable ? 'danger' : 'outline-danger'" @click.stop="editUserTable = !editUserTable">{{ editUserTable ? 'Turn Off Editing' : 'Turn On Editing' }}</b-btn>

      </b-nav-form>
            <b-nav-form class="ml-auto" v-if="!activeTraining">
             <h5 class="text-light mr-2 mt-2" >Filter: </h5> 
        <b-form-input type="date" v-model="previousTrainingDate" :disabled="activeTraining" />
      </b-nav-form>
            <b-nav-form class="ml-auto">
        <b-form-checkbox class="text-light" v-model="activeTraining" :check-value="true" :unchecked-value="false">Currently Active</b-form-checkbox>
                </b-nav-form>

    </b-navbar>
        <b-table v-if="addNewCredential" :fields="fieldsEdit" :items="newStationData">
      <template slot="credentialName" slot-scope="data">
        <b-form-input  v-model="data.item.credentialName" />
      </template>
            <template slot="credentialParent" slot-scope="data">
              {{ parents.credentialName }}
      </template>
            <template slot="credentialStartDate" slot-scope="data">
        <b-form-input type="date" v-model="data.item.credentialStartDate" />
      </template>
            <template slot="credentialEndDate" slot-scope="data">
        <b-form-input type="date" v-model="data.item.credentialEndDate" />
      </template>
            <template slot="credentialRequirements" slot-scope="data">

              </template>
            <template slot="credentialAssignedUsers" slot-scope="data">
 
            </template>
      <template slot="Action" slot-scope="data">
        <b-btn :disabled="data.item.credentialName && data.item.credentialStartDate && data.item.credentialEndDate && checkDateCompliance(data.item.credentialStartDate, data.item.credentialEndDate) ? false : true" @click="credentialNewSubmit(data.item)" variant="primary">Save</b-btn>
      </template>
    </b-table>
    <b-table  :fields="!editUserTable ? fields : fieldsEdit" :items="getCurrentlyViewing">
      <template slot="credentialName" slot-scope="data">
         <span v-if="!editUserTable">{{ data.item.credentialName }}</span>
           <b-form-input v-if="editUserTable"  v-model="data.item.credentialName" />
        </template>
      <template slot="credentialParent" slot-scope="data">

       {{ parents.credentialName }}
     
        </template>
      <template slot="credentialStartDate" slot-scope="data">
        <span v-if="!editUserTable">{{ noteDataFormat(data.item.credentialStartDate) }}</span>
            <b-form-input v-if="editUserTable" type="date" v-model="data.item.credentialStartDate" />
        </template>
              <template slot="credentialEndDate" slot-scope="data">
        <span v-if="!editUserTable">{{ noteDataFormat(data.item.credentialEndDate) }}</span>
         <b-form-input v-if="editUserTable" type="date" v-model="data.item.credentialEndDate" />
        </template>
        <template slot="credentialRequirements" slot-scope="data">
          <span v-if="!editUserTable">{{ data.item.credentialRequirements ? data.item.credentialRequirements.length : '' }}</span>
           <b-btn v-if="editUserTable" variant="outline-info" size="sm" @click="showModal = !showModal, setCredentialRequirements(), modalType = 'set_requirements', editItem = data.item">Requirements ({{ data.item.credentialRequirements ? data.item.credentialRequirements.length : '0' }})</b-btn>
        </template>
        <template slot="credentialAssignedUsers" slot-scope="data">
          <span v-if="!editUserTable">{{ data.item.credentialAssignedUsers ? data.item.credentialAssignedUsers.length : '' }}</span>
          <b-btn v-if="editUserTable" variant="outline-info" size="sm" @click="showModal = !showModal, modalType = 'assign_users', editItem = data.item">Assigned ({{ data.item.credentialAssignedUsers ? data.item.credentialAssignedUsers.length : '0' }})</b-btn>
        </template>
        <template slot="Action" slot-scope="data">
            <b-btn @click="updateCredentials(data.item)">Save</b-btn>
            </template>
    </b-table>
    </b-container>
    </b-card>
    </b-tab>
    <b-tab title="Credential Requirements">
      <credentialRequirments :credentialsData="credentialsData" @updateCrentialRequires="getCredentialRequirements" />
      </b-tab>
      </b-tabs>
      </b-card>
      <b-modal v-model="showModal" size="lg" :title="modalType.replace('_', ' ').toUpperCase()">
        <b-container fluid v-if="modalType == 'assign_users'">
            <b-btn v-b-toggle.collapse1 variant="dark" block>Show Filter (Filtered: {{ setGroupUsersForTraining.length }})</b-btn>
              <b-collapse id="collapse1" class="mt-2">
                <b-card>
                <userSelector @groupedUsers="setGroupUsers" />
                </b-card>
           </b-collapse>
           <b-row><b-col>
          <b-form-input v-model="searchData" placeholder="Search by User Name" /></b-col><b-col>
          <b-checkbox :check-value="true" :uncheck-value="false" @change="updateAllSelectedUsers($event)">Modify All (Filtered: {{ setGroupUsersForTraining.length }})</b-checkbox>
          </b-col>
          </b-row>
          <b-table :fields="['firstName', 'lastName', 'employeeId', 'deptRank', 'addCredential']" :items="setGroupUsersForTrainingFilter" :current-page="currentPage" :per-page="10" >
            <template slot="addCredential" slot-scope="data">
              <b-checkbox v-model="data.item.addCredential" :check-value="true" :uncheck-value="false" @input="updateSelectedUsers()" />
              </template>
          </b-table>
          <b-pagination align="center" size="md" :total-rows="setGroupUsersForTrainingFilter.length" v-model="currentPage" :per-page="10"></b-pagination>
        </b-container>
        <b-container fluid v-if="modalType == 'set_requirements'">
          <b-table :fields="['credentialRequirementName', 'credentialRequirementHours', 'addRequirement']" :items="setCredentialRequirementsData">
                        <template slot="addRequirement" slot-scope="data">
              <b-checkbox v-model="data.item.addRequirement" :check-value="true" :uncheck-value="false" @input="updateSelectedRequirement()" />
              </template>
            </b-table>
          </b-container>
        <div slot="modal-footer">
          <b-btn  v-if="modalType == 'assign_users'" variant="primary" class="float-right" @click="updateUserItems(selectedUserAdd, editItem)">Add Credential to {{ selectedUserAdd.length }} Users</b-btn>
          <b-btn v-if="modalType == 'set_requirements'" variant="primary" class="float-right" @click="updateRequirementItems(selectedRequirementAdd, editItem)">Add ({{ selectedRequirementAdd.length }}) to Credential</b-btn>
          </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import credentialRequirments from './credentialRequirements.vue'
import userSelector from '../../inputfields/userfilter.vue'
import moment from 'moment'
export default {
  components: {
    credentialRequirments,
    userSelector
  },
  name: 'training-credential',
  data () {
    return {
      currentPage: 1,
      newCredentialGroupName: '',
      activeTraining: true,
      setGroupUsersForTraining: [],
      setCredentialRequirementsData: [],
      selectedUserAdd: [],
      selectedRequirementAdd: [],
      editItem: '',
      previousTrainingDate: '',
      showModal: false,
      modalType: '',
      newCredentialGroup: false,
      currentlyViewing: null,
      editing: false,
      originalStationsData: [],
      addNewCredential: false,
      newStationData: {},
      resetComponent: true,
      editUserTable: false,
      searchData: '',
      credentialsData: [
      ],
      credentialRequirementsData: [],
      fields: [
        'credentialName',
        'credentialParent',
        'credentialStartDate',
        'credentialEndDate',
        'credentialRequirements',
        'credentialAssignedUsers',
      ],
      fieldsEdit: [
        'credentialName',
        'credentialParent',
        'credentialStartDate',
        'credentialEndDate',
        'credentialRequirements',
        'credentialAssignedUsers',
        'Action'
      ]
    }
  },
  created(){
    this.getCredentials()
    this.getCredentialRequirements()
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),
        parentGroupingsOverview(){
          if(this.credentialsData){
            return this.credentialsData.filter(p => {
              if(!p.credentialParent){
                return p
              }
            })
          } else {
            return []
          }
       },
       deptContactDataCheck(){
         var data = this.deptContactData
         data.forEach(item => item['addCredential'] = false)
         return data
       },
       credentialRequirementsCheck(){
         var data = this.credentialRequirementsData
         data.forEach(item => item['addRequirement'] = false)
         return data
       },
       setGroupUsersForTrainingFilter(){
         return this.setGroupUsersForTraining.filter(p => {
           return p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase())
         })
       },
       parentGroupings(){
          if(this.credentialsData){
            var array = this.credentialsData.filter(p => {
              if(!p.credentialParent){
                return p
              }
            })
            var optionsArray = []
            for(var i in array){
              optionsArray.push({value: array[i].id, text: array[i].credentialName})
            }
            return optionsArray
          } else {
            return []
          }
       },
       getCurrentlyViewing(){
         if(this.currentlyViewing && this.credentialsData){
           var array = this.credentialsData.filter(p => {
             return p.credentialParent == this.currentlyViewing
           })
           if(this.activeTraining){
             var date = moment().format()
             array = array.filter(p => {
               if(moment(p.credentialStartDate).isBefore(date) && moment(p.credentialEndDate).isAfter(date)){
                 return p
               }
             })
           } else {
             if(this.previousTrainingDate){
             var date = moment(this.previousTrainingDate, 'YYYY-MM-DD')
             array = array.filter(p => {
               if(moment(p.credentialStartDate).isBefore(date) && moment(p.credentialEndDate).isAfter(date)){
                 return p
               }
             })
             } else {
               array = array
             }
           }
           if(this.editUserTable){
             array.forEach(item => item.credentialStartDate = moment(item.credentialStartDate).format('YYYY-MM-DD'))
             array.forEach(item => item.credentialEndDate = moment(item.credentialEndDate).format('YYYY-MM-DD')) 
             return _.orderBy(array, ['credentialName'], ['asc'])
           } else {
             return  _.orderBy(array, ['credentialName'], ['asc'])
           }
         } else {
           return []
         }
       },
  },
  mounted(){

  },
  watch: {
  },
  methods: {
    updateUserItems(data, row){
        row.credentialAssignedUsers = data
        this.showModal = false
        this.editItem = ''
        this.modalType = ''
        this.searchData = ''
    },
    updateRequirementItems(data, row){
        row.credentialRequirements = data
        this.showModal = false
        this.editItem = ''
        this.modalType = ''
        this.searchData = ''
    },
    setGroupUsers(data){
      var users = this.deptContactDataCheck
      if(this.editItem.credentialAssignedUsers){
        var usersid = users.map(p => p.id)
        var setTrue = this.editItem.credentialAssignedUsers
        for(var i in setTrue){
          users[usersid.indexOf(setTrue[i])].addCredential = true
        }
      }
      var array = users.filter(p => {
           var index = data.indexOf(p.id)
             if(index != -1){
               return p
             }
         })
      this.setGroupUsersForTraining = array
    },
    setCredentialRequirements(){
      var data = this.credentialRequirementsCheck
      if(this.editItem.credentialRequirements){
        var dataids = data.map(p => p.id)
        var setTrue = this.editItem.credentialRequirements
        for(var i in setTrue){
          data[dataids.indexOf(setTrue[i])].addRequirement = true
        }
      }
      this.setCredentialRequirementsData = data
    },
    updateSelectedRequirement(){
        var data = this.setCredentialRequirementsData.filter(p => {
           return p.addRequirement == true
         })
         var array = data.map(p => p.id)
         this.selectedRequirementAdd = array
    },
    updateSelectedUsers(){
         var data = this.setGroupUsersForTraining.filter(p => {
           return p.addCredential == true
         })
         var array = data.map(p => p.id)
         this.selectedUserAdd = array
       },
    updateAllSelectedUsers(data){
         var array = this.setGroupUsersForTraining
         array.forEach(item => item.addCredential = data)
         this.setGroupUsersForTraining = array
         this.updateSelectedUsers()
    },
    checkDateCompliance(start, end){
      if(start && end){
        return moment(end).isAfter(start)
      } else {
        return false
      }

    },
    noteDataFormat(data){
      if(data){
        return moment(data).format('MM/DD/YY')
      }
    },
    ToggleAddNewCredential(){
      this.addNewCredential = !this.addNewCredential
      this.newStationData = [{
                  credentialName: '',
                  credentialParent: null,
                  credentialStartDate: null,
                  credentialEndDate: null,
                  credentialRequirements: null,
                  credentialAssignedUsers: null,
      }]
    },
    checkChanges(data){
      var currentUserFilter = this.stationsData.filter( (p) => {
        return p.id.match(data.id)
      })
      var currentUser = currentUserFilter[0]
      if( currentUser.stationName != data.stationName ||
          currentUser.stationParent != data.stationParent ||
          currentUser.stationPhone != data.stationPhone ||
          currentUser.stationState != data.stationState ||
          currentUser.stationAddress != data.stationAddress ||
          currentUser.stationCity != data.stationCity ||
          currentUser.stationZip != data.stationZip
          ){
        return true
      }
      else {
      return false
    }
    },
    testData(data){
      console.log(data)
    },
    toggleEdit(data){
      if(this.editing == data){
        this.editing = false;
      } else {
        this.editing = data;
      }
    },
    toggleEditUserTable(event){
      this.editUserTable = !this.editUserTable
    },
    toggleBodyShow(data){
      if(this.bodyShow == data){
        this.bodyShow = false;
      } else {
        this.bodyShow = data;
      }
    },
    reloadStationData(){
      console.log('RELOADING DATA')
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'station', headers: headers, expire: true}
          this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
    },
    getCredentials(){
      axios({
        method: 'get',
        url: '/api/v1/training/credential/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialsData = response.data
      })
    },
    getCredentialRequirements(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialRequirementsData = response.data
      })
    },
    createNewCredentialGroup(){
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
      var data = { credentialName: this.newCredentialGroupName }
           axios({
      method: 'post',
      url: '/api/v1/training/credential/',
      data: data,
      headers: headers.headers,
    }).then( response => {
      this.getCredentials()
      this.newCredentialGroupName = ''
      this.newCredentialGroup = !this.newCredentialGroup
    })
    },
    credentialNewSubmit(info){
      var data = _.cloneDeep(info)
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
      data['credentialAuthorId'] = this.userData.id
      data['credentialAuthorName'] = this.userData.firstName + ' ' + this.userData.lastName
      data['credentialParent'] = this.currentlyViewing
      var postDateStart = null
      var postDateEnd = null
      var postDateId = null
      delete data.credentialStartDate
      delete data.credentialEndDate
      data['credentialStartDate'] = moment(info.credentialStartDate, 'YYYY-MM-DD').toISOString()
      data['credentialEndDate'] = moment(info.credentialEndDate, 'YYYY-MM-DD').endOf('day').toISOString()
      if(data.credentialParent){
        postDateId = data.credentialParent
      }
     axios({
      method: 'post',
      url: '/api/v1/training/credential/',
      data: data,
      headers: headers.headers,
    }).then( response => {
      this.getCredentials()
      this.newStationData = [{
                  credentialName: '',
                  credentialParent: postDateId,
                  credentialStartDate: postDateStart,
                  credentialEndDate: postDateEnd,
                  credentialRequirements: null,
                  credentialAssignedUsers: null,
      }]
      
    })
  },
    updateCredentials(info){
      var data = _.cloneDeep(info)
      delete data.credentialStartDate
      delete data.credentialEndDate
      data['credentialStartDate'] = moment(info.credentialStartDate, 'YYYY-MM-DD').toISOString()
      data['credentialEndDate'] = moment(info.credentialEndDate, 'YYYY-MM-DD').endOf('day').toISOString()
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     axios({
      method: 'patch',
      url: '/api/v1/training/credential/' + data.id,
      data: data,
      headers: headers.headers,
    }).then( response => {
        this.getCredentials()
      
    })
  },
  credentialDeleteSubmit(data){
    var result = confirm('Are You Sure You Want to Delete this Credential?')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/training/credential/' + data.id,
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json'}
     }).then( response => {
         this.getCredentials()

      
    })
  }
   }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
