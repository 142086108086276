<template>
  <div class="tool-bar-deptsocialmedia">
  <b-container fluid>
    <b-row class="justify-content-center">
    <b-col class="mb-2" v-for="(item, index) in social" :key="index" lg="2" cols="3">
    <a target="_blank" :href="item.href"><img :src="siteconfig.staticHost + item.src.replace('/','')" :title="item.title" class="social-media-icons">
    </a></b-col>
      </b-row>
  </b-container>

</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Social-Media',
  data () {
    return {
      stations: [],
      stationList:[],
      showStations: false,
      showStationsSub: false,
      showUlClass: true,
      msg: 'Welcome to Your Vue.js App'
    }
  },
  created () {

},
computed: {
    ...mapState ([
      'siteconfig'
  ]),
  social(){
    console.log('SOCIAL', this.siteconfig)
    if(this.siteconfig.social && this.siteconfig){
      return this.siteconfig.social
    } return {}
  }
},
methods: {
  },
}
</script>
<style scoped>
.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.col {
  min-width: 40px;
   -webkit-flex: 1 1 15em; /* this */
   flex: 1;

}
.btn {
  margin-bottom: 5px;
}
.tool-bar-deptsocialmedia {
  margin: 0 auto 0;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
img.social-media-icons {
  height: 32px;
  width: 32px;
}
.tool-bar-deptsocialmedia-icons {
  margin: 1px;
}
  @media screen and (max-width: 768px) {
.tool-bar-deptsocialmedia-icons {
  padding: 0;
}
img.social-media-icons {
  height: 32px;
  width: 32px;
}
}
</style>
