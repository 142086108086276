<template>
<div>
        <b-navbar class="blogSpanHeader" toggleable="lg" type="dark" variant="dark" v-if="sitevisitsData">
           <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                     <b-navbar-brand class="ml-2">Scheduled ({{ formatedTableValues.length }})</b-navbar-brand>
            <b-collapse is-nav id="nav_collapse">
              <b-navbar-nav>
        <b-nav-form class="ml-2">
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchBusiness" placeholder="Location Name"></b-form-input>
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchAddress" placeholder="Address"></b-form-input>
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchAssigned" placeholder="Assigned"></b-form-input>
          <b-form-select class="ml-2" size="sm"  v-model="sortOrder" v-if="false">
            <option value="desc">Newest</option>
            <option value="asc">Oldest</option>
          </b-form-select>
      
      <b-form-radio-group  class="ml-3" size="sm"  id="radios2" v-model="sortBySelected" name="radioSubComponent">
        <b-form-radio :value="false" class="text-light">Exact</b-form-radio>
        <b-form-radio :value="true" class="text-light">Contains</b-form-radio>
      </b-form-radio-group>
        </b-nav-form>
              </b-navbar-nav>
                    </b-collapse>
          <b-navbar-nav class="ml-auto">
            </b-navbar-nav>

      </b-navbar>
     <b-table v-if="dataViewMode == 'Table'" :fields="fields" :items="formatedTableValues" :per-page="10" :current-page="currentPage">
        <template slot="select" slot-scope="data">
          <b-form-checkbox v-model="data.item.select" />
        </template>
        <template slot="sitevisitDate" slot-scope="data">
          <span :title="getDateFormatTitle(data.item.sitevisitDate, data.item.sitevisitAuthor)" >{{ getDateFormat(data.item.sitevisitDate)}}</span>
        </template>
        <template slot="complete" slot-scope="data">
          <b-btn variant="outline-info" size="sm" @click='emitNewsitevisit(data.item)'>Complete</b-btn>
        </template>
        <template slot="Remove" slot-scope="data">
          <b-btn variant="outline-danger" size="sm" @click='deleteItem(data.item.id)'>Delete</b-btn>
        </template>
      </b-table>
       <b-pagination class="pt-2" align="center" size="md" v-if="formatedTableValues && dataViewMode == 'Table'" :total-rows="formatedTableValues.length" v-model="currentPage" :per-page="10" />
  <b-modal v-model="scheduleModal" title="sitevisit Scheduler">
   <b-form-select v-model="taxonomyScheduleType" :options="taxonomyTags" class="mb-2" />
             <multiselect
              class="mb-2"
              v-model="taxonomyScheduleId" 
              :disabled="!taxonomyScheduleType"
              :options="getOptionsFromSelection(taxonomyScheduleType)" 
              :multiple="false" 
              :close-on-select="true" 
              :clear-on-select="true" 
              :hide-selected="true" 
              :preserve-search="true" 
               :placeholder="taxonomyScheduleType == 'stationsData' ? 'Search Station Name' : taxonomyScheduleType == 'deptContactData' ? 'Search User Last Name' : taxonomyScheduleType == 'apparatusData' ? 'Search Apparatus Name' : taxonomyScheduleType == 'logisticAssetData' ? 'Search Asset Id' : 'Please Select a Type'"
              :label="taxonomyScheduleType == 'stationsData' ? 'stationName' : taxonomyScheduleType == 'deptContactData' ? 'lastName' : taxonomyScheduleType == 'apparatusData' ? 'apparatusName' : taxonomyScheduleType == 'logisticAssetData' ? 'assetId' : ''" 
              :track-by="taxonomyScheduleType == 'stationsData' ? 'stationName' : taxonomyScheduleType == 'deptContactData' ? 'lastName' : taxonomyScheduleType == 'apparatusData' ? 'apparatusName' : taxonomyScheduleType == 'logisticAssetData' ? 'assetId' : ''">

   <template slot="option" slot-scope="props">
      <div class="option__title" v-if="taxonomyScheduleType == 'stationsData'">{{ props.option.stationName }}</div>
 <div class="option__title" v-if="taxonomyScheduleType == 'apparatusData'">{{ props.option.apparatusName }}</div>
                    <div class="option__title" v-if="taxonomyScheduleType == 'logisticAssetData'">{{ props.option.assetDescription }} #ID:{{ props.option.assetId }} #SN:{{ props.option.assetSerialNumber }}</div>
                    <div class="option__title" v-if="taxonomyScheduleType == 'deptContactData'">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                   <div class="option__body" v-if="taxonomyScheduleType == 'deptContactData'">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
   </template>

</multiselect>
          <b-container >
                    <b-row :align-h="documentWidth ? 'center' : 'start'">
                      <b-col cols="*">
                  <datepicker
                    class="datepickervue"
                    v-model="dateSelected"
                    :inline='documentWidth'
                    name="inlineCal">
                  </datepicker>
                      </b-col></b-row>
                  </b-container>
                  <template slot="modal-footer">
           
                  </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import printJS from 'print-js'
import DatePicker from '../../inputfields/Datepickermod.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import querystring  from 'querystring';
import Multiselect from 'vue-multiselect'
import arrayData from '../../../../files/dispatchReport.js'
import convert from 'xml-js'
export default {
  props:["formTableStyle", "submittedBusiness", "sitevisitsData"], 
components:{
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,

},
  data () {
    return {
        showTutVideo: false,
        edit: true,
        imageResponseData: [],
        postData: '',
        timeData: '',
        imageSrc: '',
        searchGrid: '',
        scheduleModal: false,
        searchBusiness: '',
        searchStation: '',
        searchAddress: '',
        searchAssigned: '',
        currentPage: 1,
        dateSelected: moment().format("MM/DD/YYYY"),
        bootstrapDate: moment().format("YYYY-MM-DD"),
        taxonomyScheduleType: null,
        taxonomyTags: [
        {text: 'User', value: 'deptContactData'},
        {text: 'Station', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'None', value: null }
        ],
        stationSelectValue: '',
        showGroup: 'business',
        showModalConfirmation: false,
        pictureUpload: '',
        searchData: '',
        sortOrder: 'asc',
        carouselToggle: '',
        pictureFileArray: [],
        imageLoader: '',
        holdValues: {},
        taxonomyScheduleId: null,
        patchData: false,
        completedLoad: false,
        ppObj: {},
        fields: [],
        tableRows: [],
        jsonArray: [],
        buildData: [],
        mapStatus: 'both',
        disableMapStatus: false,
        dataViewMode: 'Table',
        sortBySelected: true,
        windowWidth: '',

    }
  },
  created(){
  this.getWindowWidth()
  window.addEventListener('resize', this.getWindowWidth);
      console.log('created')

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  mounted(){
    var data = _.cloneDeep(this.submittedBusiness)
    var sitevisits = _.cloneDeep(this.sitevisitsData)
    this.buildTableData(sitevisits)
    console.log('Mounted')

  
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'Businesstatus',
          'deptContactData'
        ]),
        documentWidth(){
          if(this.windowWidth > 992){
            var boo = true
          } else {
             var boo = false
          }
          return boo
        },
        formatedTableValuesPreFilter(){
          var data = _.cloneDeep(this.sitevisitsData)
          var mapHold = data.map(item => {
          var obj = item
          obj['assigned'] = this.getTaggedType(item.sitevisitAssignedType, item.sitevisitAssignedId)
          return obj
      })
      return mapHold

        },
        formatedTableValues(){
          var returnData = this.formatedTableValuesPreFilter
          console.log(returnData)
          return returnData
        },
    
    

    },
  methods:{
    getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : type == 'deptContactData' ? obj.firstName + ' ' + obj.lastName : "Unknown"
      } else {
        return null
      }
      } else {
        return null
      }
    },
    updateDate(date, event) {
      this.dateSelected = moment(date).format("MM/DD/YYYY");
      this.bootstrapDate = moment(date).format("YYYY-MM-DD");
    },
    getWindowWidth(event) {
        this.windowWidth = document.documentElement.clientWidth;
    },
    emitNewsitevisit(data){
      if(data && data.sitevisitPreplanId){
        var obj = { id: data.sitevisitPreplanId }
        bus.$emit('openPreplan', obj)
      }
    },
    getOptionsFromSelection(data){
    if(data){
     // console.log(data)
    return this[data]
    } else {
      return []
    }
    },
    getDateFormat(data){
      if(data){
        return moment(data).fromNow()
      } else {
        return 'invalid'
      }
    },
    getDateFormatTitle(data, author){
      if(data){
        var time = moment(data).format('MM/DD/YYYY HH:mm')
        return 'Scheduled for ' + time + ' by ' + author
      } else {
        return 'invalid'
      }
    },
    buildTableData(data){
      this.fields.push({key: 'sitevisitName', label: 'Location'})
      this.fields.push({key: 'sitevisitAddress', label: 'Address'})
      this.fields.push({key: 'sitevisitDate', label: 'Scheduled'})
      this.fields.push({key: 'assigned', label: 'Assigned To'})
      this.fields.push('complete')
      if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
        this.fields.push('Remove')
      }
    },
    emitItem(data){
      console.log('data')
      var obj = this.submittedBusiness.find(p => p.id == data)
      this.$emit('showbusiness', obj)
    },
    deleteItem(data){
          axios({
            method: 'delete',
            url: "/api/v1/preplan/sitevisit/" + data,
            headers: {
              Authorization: this.getSessionData,
              "Content-Type": "application/json"
              }

          }).then(response => {
              this.$emit('getsitevisits')
              console.log(response.data)
          })
        
    },
    schedulesitevisit(data){
      var array = data
      var count = 0
      for(var i in array){
      var data = {
               sitevisitBusinessId: array[i],
               sitevisitDate: moment(this.dateSelected, 'MM-DD-YYYY').toISOString, 
               sitevisitAuthor: this.userData.firstName + ' ' + this.userData.lastName, 
               sitevisitAuthorId: this.userData.id,
               sitevisitAssignedType: this.taxonomyScheduleType, 
               sitevisitAssignedId: this.taxonomyScheduleId ? this.taxonomyScheduleId.id : null,
       }
      axios({
        method: "post",
        url: "/api/v1/preplan/sitevisit",
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }
      }).then(response => {
         count = count + 1
         if(count == array.length){
         this.$emit('getsitevisits')
         this.scheduleModal = false
         }
        })
      }
 
     },


}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.datepickervue {
  width: 300px;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.numberInputSize {
  width: 70px;
}
.textInputSize {
  width: 140px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
.datePickerClassMobile {
  display: none;
}
@media screen and (max-width: 992px) {
  .time-picker.timeDesk{
    display: none;
  }
    .datePickerClass {
    display: none;
  }
  .datePickerClassMobile {
    display: block;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
