<template>
    <b-container fluid>
  <b-navbar type="dark" variant="dark">
    <b-nav-form class="ml-auto">
      <b-btn :variant="printOn ? 'danger' : 'success'" @click="printOn = !printOn">Turn {{ printOn ? 'Off' : 'On '}} Print View</b-btn>
      <b-btn class="ml-3" v-if="printOn" variant="primary" @click="executePrintJS">Print</b-btn>
      <b-btn class="ml-3" variant="primary" @click="showFilter = !showFilter">Show Filter</b-btn>
    </b-nav-form>
    </b-navbar>
      <b-navbar v-if="showFilter">
        <b-form-input type="text" class="ml-2" v-model="nameFilter" placeholder="Last Names (seperate by a space)" />
        <b-form-select class="ml-2" v-model="filterByRank" multiple :options="ranksReturned" />
        <b-form-select class="ml-2" v-model="filterBySpecial" multiple :options="specialtiesReturned" />
        <b-form-select class="ml-2" v-model="filterModType" multiple :options="modTypesReturn" />
    </b-navbar>
    <div :id="'print-' + printId"  :class="printReportOn ? 'printReport' : ''">
         <b-container v-if="printOn" class="mb-4" fluid>
      <b-row><b-col cols="5">
      <img :src="departmentLogoForPrint" /></b-col>
      <b-col cols="7" class="pt-2">
        <h3>Daily Combined Report</h3>
        <b-row><b-col>
          <h4><span class="font-weight-bold">Duty Shift: </span><span>{{ currentShift }}</span></h4>
          </b-col>
          <b-col>
               <h4><span class="font-weight-bold">Date: </span><span>{{ getTodaysDateForPrint }}</span></h4>
            </b-col>
            <b-col>
                 <h4><span class="font-weight-bold">Time: </span><span>{{ getCurrentTimeForPrint }}</span></h4>
              </b-col>
              </b-row>
              <em>Completed by {{ userData.deptRank }} {{ userData.firstName }} {{ userData.lastName }}</em>
        </b-col>
        </b-row>
      </b-container>
    <b-container v-if="currentView">
        <b-table :items="rosterProfileTopBarFilter" :fields="['user','payrollModDatetime', 'payrollModExport', 'payrollModCode', 'payrollModType', 'payrollModHours']">
          <template slot="user" slot-scope="data">
            {{ data.item.lastName + ', ' + data.item.firstName + ' (' + data.item.employeeId + ')' }}
          </template>
          <template slot="payrollModDatetime" slot-scope="data">
            {{ getDateTime(data.item.payrollModDatetime) }}
            </template>
            <template slot="payrollModCode" slot-scope="data">
                <span :class="'payroll-' + data.item.payrollModColor">{{ data.item.payrollModCode }}</span>
            </template>
          </b-table>
    </b-container>
      </div>
      </b-container>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import Papa from 'papaparse'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import JsonExcel from 'vue-json-excel';
import Blob from 'blob'
import customJs from '../../../../customjs.js';
import html2canvas from 'html2canvas';
export default {
  components: {
    multiselect,
    userCard,
    'download-excel' : JsonExcel,
  },
  
  name: 'roster-admin',
  props:  ['payrollAccuralData', 'payrollBanksData', 'rosterProfiles', 'rosterData', 'payrollAccuralAssignedData', 'payrollModDailyRosterData', 'payrollCodesData', 'selectedDateHold'],
  data () {
    return {
      currentPage: 0,
      searchData: '',
      payrollBankRecoDate: '',
      printOn: false,
      currentView: null,
      showFilter: false,
      hideUsersWithNone: false,
      departmentLogoForPrint: '',
      selectAllBoo: false,
      cycleType: 'Pay Period',
      currentYear: '',
      selectedFilter: [],
      payrollRecoMods: [],
      filterBySpecial: [],
      filterByRank: [],
      filterModType: [],
      nameFilter: '',
      payrollReports: [],
      viewReports: [],
      printId: '',
      printReportOn: false,
      modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'}, 
     ],
      createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      ],
      selectedDate: '',
    }
  },
  created(){
    this.selectedDate = moment().format('YYYY-MM-DD')
    this.getCurrentPayPeriod()
    this.printId = moment().unix()
    this.onLoadImage(this.siteconfig.staticHost + 'banners/' + this.siteconfig.deptUrlSub + '/banner.png')
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        kellyDayObject(){
          // console.log('KELLY PATTERN')
            if(this.siteconfig.payroll.kellyDayPattern){
            var shifts = this.siteconfig.payroll.shifts
            var kellyPattern = this.siteconfig.payroll.kellyDayPattern
            var build = []
            for(var k in kellyPattern){
                for(var s in shifts){
                build.push({shift: shifts[s].shift, kelly: this.siteconfig.payroll.kellyDayPattern.indexOf(kellyPattern[k])})
              }
            }
            var today = moment(this.selectedDate, 'YYYY-MM-DD').clone()
            var startDate = today.clone()
          // console.log(today)
            var subDays = 0
          // console.log('START WHILE')
            while (true) {
              var days = moment(startDate).diff(this.siteconfig.payroll.cycleStartDate, 'days') // 21
              var mathhold = (days / 21)
              if(mathhold == Math.floor(mathhold)){
            //   console.log('BREAK')
                break
              }
              startDate = startDate.subtract(1, 'day')
              subDays++;
            } 
            var start = moment(today).subtract(subDays, 'days') 
            var patternRun = moment(today.clone()).diff(start, 'days')
            // console.log(patternRun)
            var index = build[patternRun]
            // console.log(build) 
            return index
            } return null
          },
          getTodaysDateForPrint(){
          return moment(this.selectedDate, 'YYYY-MM-DD').format('MM/DD/YY')
          },
          getCurrentTimeForPrint(){
            return moment().format('HH:mm')
          },
          currentShift(){
              var payroll = this.siteconfig.payroll
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = moment(this.selectedDate).diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
          },
          profilesPreDateFiltered(){
            var array = this.rosterProfiles.filter(p => {
              var start = moment(p.rosterAssignmentStart).isSameOrBefore(this.selectedDate)
              var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate) : true
              if(start && end){
                return p
              }
            })
            var profiles = array.filter(p => {
                if(p.scheduledShift == this.currentShift){
                  return p
                } else if (p.scheduledShift == 'Daily'){
                  var index = p.scheduledWorkDays.indexOf(moment(this.selectedDate).format('ddd'))
                  if(index != -1){
                    return p
                  }
                }
            })
            return profiles
          },
        rosterProfileTopBarFilter(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
              if(this.filterByRank && this.filterByRank.length > 0){
                array = array.filter(p => {
                        var index = this.filterByRank.indexOf(p.deptRank)
                        if(index != -1){
                          return p
                        }
                })
              }
              if(this.filterModType && this.filterModType.length > 0){
                array = array.filter(p => {
                        var index = this.filterModType.indexOf(p.payrollModType)
                        if(index != -1){
                          return p
                        }
                })
              }
              if(this.filterBySpecial && this.filterBySpecial.length > 0){
                array = array.filter(p => {
                      var specials = p.deptSpecialties
                      for(var s in specials){
                        var index = this.filterBySpecial.indexOf(specials[s])
                        if(index != -1){
                          return p
                        }
                      }
                })
              }
              if(this.nameFilter && this.nameFilter != ''){
                array = array.filter(p => {
                    var option = this.nameFilter.split(' ')
                //    console.log(option)
                    var optionscheck = false
                    for(var o in option){
                      if(p.lastName.toLowerCase().includes(option[o].toLowerCase())){
                        optionscheck = true
                      }
                    }
                    if(optionscheck){
                      return p
                    }

                })
              
              } 
              return array
        },
        rosterProfilesFilterdByPeriodFilter(){
          var array = _.cloneDeep(this.rosterProfilesFilterdByPeriod)
          if(this.selectedFilter && this.selectedFilter.length > 0){
              var filter = []
              for(var p in array){
                var obj = array[p]
                obj.shifts = array[p].shifts.filter(x => {
                  var index = this.selectedFilter.indexOf(x.payrollModCode)
                  if(index < 0){
                    return x
                  }
              })
              if(!this.hideUsersWithNone){
              filter.push(obj)
              } else if (this.hideUsersWithNone && obj.shifts && obj.shifts.length > 0){
                filter.push(obj)
              }
            
            }
            return filter
          }
          var holdArray = []
          for(var i in array){
            var shifts = array[i].shifts
            if(shifts && shifts.length > 0){
              for(var s in shifts){
                var obj = shifts[s]
                obj['firstName'] = array[i].firstName
                obj['lastName'] = array[i].lastName
                obj['employeeId'] = array[i].employeeId
                obj['deptSpecialties'] = array[i].deptSpecialties
                obj['deptRank'] = array[i].deptRank
                holdArray.push(obj) 
              }
            }
          }
          bus.$emit('dailyRosterDataForList', holdArray)
          return holdArray
        },
        payrollModDailyRosterDataFilter(){
          var array = _.cloneDeep(this.payrollModDailyRosterData)
          return array.filter(p => p.payrollModType != 'roster mod')
        },
        payrollModAdditions(){
          var array = _.cloneDeep(this.payrollModDailyRosterDataFilter)
          var overtimemods = array.filter(p => p.payrollModType == 'overtime')
          return overtimemods
        },
        rosterProfilesFilterdByPeriod(){
          var array = _.cloneDeep(this.rosterProfiles)
          var rosters = this.profilesPreDateFiltered
          var users = [...new Set(rosters.map(p => p.userId))]
          var usersFromMods = [... new Set(this.payrollModDailyRosterDataFilter.map(p => p.payrollModExchangeUserId))]
          var usersFromModsTarget = [... new Set(this.payrollModDailyRosterDataFilter.map(p => p.payrollModTargetUserId))]
          usersFromMods.concat(usersFromModsTarget)
          for(var i in usersFromMods){
              var index = users.indexOf(usersFromMods[i])
              if(index == -1 && usersFromMods[i] && usersFromMods[i] != ''){
                users.push(usersFromMods[i])
              }
          }
          var hold = users.map(id => {
            var obj = this.deptContactData.find(p => p.id == id)
            if(obj){
            obj['userid'] = id
            var rosterFil = rosters.filter(p => p.userId == id)
            obj['shifts'] = this.userProfileBuildShifts(rosterFil)
            return obj
            }
          })
          hold = hold.filter(p => {
              if(p){
                return p
              }
          })
          var mods = hold.map(item => {
            var obj = item
            var paymods = this.payrollModDailyRosterDataFilter.filter(p => p.payrollModTargetUserId == item.id || p.payrollModExchangeUserId == item.id)
            if(paymods){
             // console.log("PAYROLL MODS")
              obj.shifts = this.sortModifications(item.shifts, paymods, item.userid, item.employeeId)
             // console.log("OBJ SHIFTS")
             // console.log(obj.shifts)
              return obj
            } else {
              return obj
            }
          })
         // console.log(mods)
         var additions = this.payrollModAdditions
         if(additions){
           for(var a in additions){
             var obj = this.deptContactData.find(p => p.id == additions[a].payrollModTargetUserId)
             var shift = additions[a]
             var payrollMod = this.payrollCodesData.find(p => p.id == shift.payrollModCode)
           //  console.log(payrollMod)
             if(obj && shift && payrollMod){
               shift['payrollModColor'] = payrollMod && payrollMod.payrollCodeColor ? payrollMod.payrollCodeColor : 'default'
               shift['payrollModExport'] = payrollMod && payrollMod.payrollCodeExport ? payrollMod.payrollCodeExport : 'No Export'
               shift['payrollModCode'] =  payrollMod && payrollMod.payrollCode ? payrollMod.payrollCode : 'No Code'
               shift['payrollModeCodeId'] =  payrollMod && payrollMod.payrollCode
               obj['shifts'] = [shift]
               mods.push(obj)
             }
           }
         }
       //  console.log(mods)
          return _.orderBy(mods, ['lastName'], ['dsc'])
        },
        modTypesReturn(){
                var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
                var specials = []
                for(var i in array){
                  var spec = array[i].payrollModType
                  var index = specials.indexOf(spec)
                    if(index == -1){
                      specials.push(spec)
                    }
                }
            //   console.log(specials)
                return specials
          },
        ranksReturned(){
                var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
                var specials = []
                for(var i in array){
                  var spec = array[i].deptRank
                  var index = specials.indexOf(spec)
                    if(index == -1){
                      specials.push(spec)
                    }
                }
            //   console.log(specials)
                return specials
          },
          specialtiesReturned(){
                var array = _.cloneDeep(this.rosterProfilesFilterdByPeriodFilter)
                var specials = []
                for(var i in array){
                  var spec = array[i].deptSpecialties
                  for(var s in spec){
                    var index = specials.indexOf(spec[s])
                    if(index == -1){
                      specials.push(spec[s])
                    }
                  }
                }
            //   console.log(specials)
                return specials
          },
       
  },
  mounted(){

  },
  watch: {
  selectedDateHold(newData){
    if(newData != this.selectedDate){
    this.selectedDate = this.selectedDateHold
    }
  }

  },
  methods: {
    onLoadImage(url){
      var vm = this
    //  console.log('NEW IMAGE')
    //  console.log('image loaded')
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        vm.departmentLogoForPrint = dataURL // .replace(/^data:image\/(png|jpg);base64,/, "")
      };
    },
    executePrintJS(){
        console.log('Print')
      var printId = '#print-' + this.printId
           this.printReportOn = true
      html2canvas(document.querySelector(printId)).then(canvas => {
       var image = canvas.toDataURL("image/jpeg")
       return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              style: 'img { width:1200px !important; }',
            })
   // 
    });
    this.$nextTick(function(){
        this.printReportOn = false
    })
  },
    selectAllEventMods(boo, data){
      if(boo){
        this.selectedFilter = data
      } else {
        this.selectedFilter = []
      }
    },
      getDateTime(data){
        if(data){
          return moment(data).format('MM/DD/YYYY HH:mm:ss')
        } return 'Null Date'
      },
      sortModifications(reg, mods, user, employeeId){
        var array = reg
        var modsA = []
        var adj = []
        for(var i in mods){
          var obj = {}
          obj = _.cloneDeep(mods[i])
          obj['payrollModCodeDb'] = mods[i].payrollModCode
          var payrollMod = this.payrollCodesData.find(p => p.id == mods[i].payrollModCode)
          var exchange = mods[i].payrollModExchangeUserId == user
          var exOn = ''
          if(exchange){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXON')
          }
          if (mods[i].payrollModType == 'exchange' && mods[i].payrollModExchangeUserId != user){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXOFF')
          }
          obj['startTime'] == mods[i].payrollModDatetime
          obj['endTime'] = moment(mods[i].payrollModDatetime).add(mods[i].payrollModHours, 'hours').toISOString()
          obj['payrollModColor'] = payrollMod && payrollMod.payrollCodeColor ? payrollMod.payrollCodeColor : 'default'
          obj['payrollModExport'] = payrollMod && payrollMod.payrollCodeExport ? payrollMod.payrollCodeExport : exOn && exOn.payrollCodeExport ? exOn.payrollCodeExport : 'No Export'
          obj['payrollModCode'] =  payrollMod && payrollMod.payrollCode ? payrollMod.payrollCode : exOn && exOn.payrollCode ? exOn.payrollCode : 'No Code'
          if(this.createVacancy.indexOf(mods[i].payrollModType) != -1 || obj.payrollModCode == 'EXOFF'){
            adj.push(obj)
          } else {
          modsA.push(obj)
          }
        }
        var fixed =  this.checkForUserMods(array, adj)
        for(var m in modsA){
          fixed.push(modsA[m])
        }
      //  console.log(fixed)
        return _.orderBy(fixed, ['payrollModDatetime'], ['asc'])
      },
      getFixedSwap(array, mods){
      //  console.log('Get Fixed Swap')

       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
          var mod = mods.filter(p => moment(p.payrollModDatetime).isSameOrAfter(array[a].payrollModDatetime) && moment(p.endTime).isSameOrBefore(array[a].endTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].payrollModDatetime).isSame(array[a].payrollModDatetime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].payrollModDatetime).isAfter(array[a].payrollModDatetime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.payrollModDatetime = array[a].payrollModDatetime
                  obj.endTime = mod[m].payrollModDatetime
                  obj['payrollModHours'] = moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].payrollModDatetime).isSame(array[a].payrollModDatetime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.payrollModDatetime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  obj['payrollModHours'] =  moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].payrollModDatetime).isAfter(array[a].payrollModDatetime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.payrollModDatetime = array[a].payrollModDatetime
                  obj.endTime = mod[m].payrollModDatetime
                  obj['payrollModHours'] =  moment(obj.endTime).diff(obj.payrollModDatetime, 'hours', true)
                  objTwo.payrollModDatetime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  objTwo['payrollModHours'] =  moment(objTwo.endTime).diff(objTwo.payrollModDatetime, 'hours', true)
                  array.push(objTwo)
                  array[a] = obj
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
   //  console.log('ARRAY')
     return array
    },
      checkForUserMods(dates, adjust){
          // console.log('CHECK FOR USER MODS')
          var array = []
          var allMods = _.cloneDeep(adjust)
          // console.log(dates)
          // console.log(allMods)
          for(var u in dates){
              var mods = adjust.filter(p => {
              return moment(p.payrollModDatetime).isBefore(dates[u].endTime) && moment(dates[u].payrollModDatetime).isBefore(p.endTime)
              })
              mods = _.orderBy(mods, ['payrollModDatetime'], ['asc'])
              if(mods.length > 0){
                var base = _.cloneDeep(dates[u])
                var obj = [base]
                var basesplit = this.getFixedSwap(obj, mods)
              if(basesplit.length > 0){
                 // console.log('BASESPLIT')
                 // console.log(basesplit)
                  for(var b in basesplit){
                    array.push(basesplit[b])
                  }
                }
              } else {
              array.push(dates[u])
              } 
        }  
        if(allMods.length > 0){
         var nonSwap = allMods //.filter(p => p.payrollModType != 'exchange')
         if(nonSwap.length > 0){
           for(var i in nonSwap){
             array.push(nonSwap[i])
           }
         }
        }    
        return array
      },
      getCurrentPayPeriod(){
        var start = moment(this.selectedDate + 'T' + this.siteconfig.payroll.shiftStartTime).subtract(10, 'mins').toISOString()
       var end = moment(this.selectedDate + 'T' + this.siteconfig.payroll.shiftStartTime).add(1, 'day').add(20, 'mins').toISOString()
                       this.currentView = {start: start, end: end}
                
      }, 
       userProfileBuildShifts(data){ 
        var events = []
        for(var i in data){
        var indexColor = this.siteconfig.payroll.shifts.map(p => p.shift).indexOf(data[i].scheduledShift)
        if(indexColor != -1){
          var holdcolor = this.siteconfig.payroll.shifts[indexColor].bootstrap
        } else {
          var holdcolor = 'default'
        }
         var obj = {}
         var startTime = moment(this.selectedDate + 'T' + data[i].rosterStartTime, "YYYY-MM-DDTHH:mm:ss").toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Regular Duty')
          // console.log(payrollCode)
          obj['color'] = 'text-' + holdcolor
          obj['payrollModType'] = 'normal',
          obj['payrollModColor'] = payrollCode && payrollCode.payrollCodeColor ? payrollCode.payrollCodeColor : 'default'
          obj['payrollModCode'] = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'No Export',
          obj['payrollModExport'] = payrollCode && payrollCode.payrollCodeExport ? payrollCode.payrollCodeExport : 'No Export',
          obj['payrollModDatetime'] = startTime, 
          obj['endTime'] = endTime,
          obj['payrollModHours'] = data[i].rosterShiftLength
          events.push(obj)
    }
    return events
  },
    findFirstAvailableShifts(date, profileshift, id){
              var payroll = this.siteconfig.payroll
              var indexOfDate = payroll.shifts.map(p => p.shift).indexOf(profileshift)
              var selectedVarDate = moment(date, "YYYY-MM-DD");
              var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var advance = indexOfDate - roundedDated
              var testDate = selectedVarDate.add(advance, 'd')
              if(testDate.isBefore(date)){
                testDate = testDate.add(payroll.shiftRotation, 'd')
              } 
              return testDate
  },
  getPreviousPayrollExports(){
  axios({
        method: 'get',
        url: '/api/v1/staffing/payrollexport/short',
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     this.payrollReports = _.orderBy(response.data, ['createdAt'], ['desc'])
      })
  },
  getPayrollModifiersInRange(start, end){
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrollmod/daily/' + start + '/' + end,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
        if(response.data){
     this.payrollRecoMods = response.data.filter(p => p.payrollModType != 'roster mod' && moment(p.payrollModDatetime).isBefore(end))
        } 

        // this.rosterReload = true
      })
    },
   


  }



  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.printReport {
  width: 1200px;
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
