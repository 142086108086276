<template>

        <div v-if="show">
            <div class="modal" @click.self.stop="clickBackdrop">
                <div class="modal-dialog" ref="dialog">
                    <div class="modal-content">

                        <div class="modal-header">

                            <h4 class="modal-title">{{event.title}}</h4>
                                                        <b-btn aria-label="Close" size="sm" variant="outline-danger" @click="cancel">
                                <span aria-hidden="true">X</span>
                            </b-btn>
                        </div>

                        <div class="modal-body">
                        <eventdetails v-if="calendarType != 'payroll'" :calendarEvents="event.description" :showBody="true" />
                        <div v-if="calendarType == 'payroll'">
                            <div v-for="single in event.description" class="text-left mb-2" :class="'text-' + event.color" :key="single.id" @click="calendarType == null ? showEventDetails : calendarType == 'payroll' ? showPayrollModification(single) : ''">
                                <p class="noPaddingBottom">{{ getTime(single.eventDate) }} | {{ single.eventTitleDescription }}</p>
                                <em v-if="single.currentStatus" :class="single.currentStatus == 'Awaiting Approval' || single.currentStatus == 'Available' ? 'text-Yellow' : single.currentStatus == 'Approved' ? 'text-bright-green' : 'text-text-danger'">{{ single.currentStatus }}</em>
                                </div>
                        </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" @click="cancel">
                                <span>close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop in"></div>
        </div>

</template>

<script>
    import {EVENT_DELETED, CANCEL_DETAILS_EVENT_MODAL, SHOW_DETAILS_EVENT_MODAL} from '../actions';
    import eventDetails from '../../tracking/events/eventsfilter.vue'
    import { bus } from '../../../main'
    import moment from 'moment'
    export default {
      components:{
        'eventdetails': eventDetails
      },
       props: {
           show: {
               type: Boolean,
               default: false
           },
           event: {
               type: Object,
               required: true,
           },
           calendarType: {
                type: String,
                default: null
           },
           canDeleteEvent: {
               type: Boolean,
           }
       },

       created () {
           if (this.show)
               document.body.className += ' modal-open';
       },

       beforeDestroy () {
           document.body.className = document.body.className.replace(/\s?modal-open/, '');
       },

       watch: {
           show (value) {
               if (value) {
                   document.body.className += ' modal-open';
               }
           }
       },

       methods: {
            getTime(data){
                if(data){
                    return moment(data).format('HH:mm')
                } else {
                    return ''
                }
            },
            showPayrollModification(event){
                console.log('EVENT UPDATE', event)
                if(event.payrollMod){
                 bus.$emit('updatePayrollMod', event.payrollMod)
                }
            },
            deleteEvent () {
                this.$emit(EVENT_DELETED, this.event);
                this.closeModal();
            },

            cancel () {
                this.closeModal();
                document.body.className = document.body.className.replace(/\s?modal-open/, '')
            },

            clickBackdrop () {
                this.cancel();
            },

            closeModal() {
            document.body.className = document.body.className.replace(/\s?modal-open/, '')
                 this.$emit(SHOW_DETAILS_EVENT_MODAL, false)
                 this.$emit('closeModal')
            }
       }
    };
</script>

<style scoped>
    .modal {
        display: block;
        z-index: 1030;
    }
    .modal-enter-active, .modal-leave-active {
        transition: opacity .2s
    }
    .modal-enter, .modal-leave-to {
        opacity: 0
    }
    .modal-backdrop {
        opacity: 0.3;
        z-index: 1020;
    }
    .text-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-text-primary {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-text-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.text-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-text-success {
        background-color: #28a745;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-text-danger {
    background-color: #dc3545;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.text-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
</style>
