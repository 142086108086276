<template>
<div id="homelanding">
              <b-container v-if="formType == 'schedule'" fluid>
              <b-row><b-col>
                <b-form-input type="date" v-model="eventDate" /></b-col>
                    <b-col><b-form-input type="time" v-model="eventTime" /></b-col></b-row>
               <b-form-select class="mt-3" :class="{errorClass: eventCatSelect == null}"  text-field="eventtypeName" value-field="eventtypeName" v-model="eventCatSelect" :options="eventCat">
                  <option :value="null" disabled> Please select Event Category</option>
                </b-form-select>
                <b-form-select class="mt-3" :disabled="eventCatSelect == null" :class="{errorClass: eventTagSubSelect == null}" text-field="eventtypeName" value-field="eventtypeName" v-model="eventTagSubSelect" :options="eventTagSub(eventCatSelect)">
                  <option :value="null" disabled> Please select Type or Location</option>
                </b-form-select>
              <b-form-textarea class="mt-3" :rows='4' v-model="detailedTextArea" placeholder="Detailed Description of the Event"/>
              
              </b-container>

              <b-container v-if="formType == 'completion'">
              <b-row><b-col>
                <b-form-input type="date" v-model="eventDate" /></b-col>
                    <b-col><b-form-input type="time" v-model="eventTime" /></b-col></b-row>
                      <b-row align-h="center" class="mt-2">
                 <b-col lg="6" md="12" class="pt-2">Training Hours</b-col><b-col lg="6" md="6"><b-form-input :disabled="trainingCompletionHoursDisabled" type="number" v-model="trainingCompletionHours" /></b-col></b-row>
              </b-container>
              <b-container class="mt-2">
                <h6>Tagged Users:</h6>
                 <multiselect v-if="resetForm" v-model="userHoldArraySelect" :multiple="true" :options="deptContactBatt" label="lastname" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Tagged Users">
              <template slot="tag" slot-scope="props"><span :class="'custom__tag ' + 'bg-primary'">
                  <span>
                    <template>{{ props.option.lastName }} ({{ props.option.employeeId }})</template></span>
                  <span class="custom__remove" @click="props.remove(props.option), emitUserRemove(props.option)">X</span></span>
                  </template>
                   
                   <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                    </template>
                  </multiselect>
                  <h6 v-if="formType == 'schedule'">Tagged Stations:</h6>
                 <multiselect v-if="resetForm && formType == 'schedule'" v-model="stationSelectValue" :multiple="true" :options="stationsData" label="stationName" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Tagged Stations">
              <template slot="tag" slot-scope="props"><span :class="'custom__tag ' + 'bg-primary'">
                  <span>
                    <template>{{ props.option.stationName }}</template></span>
                  <span class="custom__remove" @click="props.remove(props.option)">X</span></span>
                  </template>
                   
                   <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.stationName }}</span>
                    </template>
                  </multiselect>
                </b-container>
                <b-container class="mt-2">
                  <b-row><b-col align="right">
                    <b-btn v-if="formType == 'schedule'" 
                    :disabled="eventTagSubSelect == '' || eventTagSub == '' || userHoldArraySelect.length == 0 || stationSelectValue.length == 0"
                    variant="success" 
                    @click="submitArticl">Schedule Training</b-btn>
                      <b-btn v-if="formType == 'completion'" variant="success" @click="submitTraining">Submit Training</b-btn>
                    </b-col></b-row>
                  </b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
export default {
props:[ 'formType', 'userHoldArray', 'deptContactBatt', 'trainingModule'],
components: {
 'multiselect': Multiselect
},
data() {
    return {
    credentialRequirement: [],
    detailedTextArea: '',
    eventCatSelect: null,
    eventTagSubSelect: null,
    eventDate: '',
    resetForm: true,
    eventTime: '',
    runScheduled: false,
    userHoldArraySelect: [],
    stationSelectValue: [],
    trainingCompletionHoursDisabled: false,
    trainingCompletionHours: '',
}
},
created () {
  this.eventDate = moment().format('YYYY-MM-DD')
  this.eventTime = '09:00'
  this.detailedTextArea = this.trainingModule.trainingModuleName
},
mounted(){
      var hours = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'hours')
      if(hours){
        this.trainingCompletionHours = hours.perms
      }
      var disHours = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'hourstype')
      if(disHours){
        if(disHours.perms == 'fixed'){
          this.trainingCompletionHoursDisabled = true
        }
      }
      
},
watch: {
  userHoldArray(newData){
    this.resetForm = false
    this.userHoldArraySelect = this.userHoldArray
    this.stationCheck(this.userHoldArray)
    this.$nextTick(function(){
      this.resetForm = true
    })
  }
},
computed: {
  ...mapState([
    'getSessionData',
    'deptContactData',
    'stationsData',
    'eventTags',
    'userData'
  ]),
  formatedDateExport() {
      var timeString = this.eventDate + "T" + this.eventTime + ':' + '00';
      var offSet = moment().utcOffset();
      var hourOffSet = offSet * -1 / 60;
      var timeStringForm = moment(timeString).add(hourOffSet, "hours");
      var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
      return timeStringAdd + "Z";
      },
      eventCat(){
        if(this.eventTags != undefined){
        var array = [... new Set(this.eventTags.map(p => { if(p.eventtypeParent != '' && p.eventtypeParent != undefined){ 
          return p.eventtypeParent
        }
        }))]
       var index = array.indexOf(undefined)
       if(index >= 0){
         array.splice(index, 1)
       }
       return _.sortBy(array)
        }
      },
      returnedTargetIdsStations () {
        var array = []
        var station = this.stationSelectValue
        for(var i = 0; i < station.length; i++){
          var string = station[i].stationName
          array.push(string)
        }
        return array
      },
      userSelectedFormatExport(){
        if(this.userHoldArraySelect.length > 0){
          var users = [... new Set(this.userHoldArraySelect.map(p => p.id))]
          return users
        }
      },
      contentUrl(){
        return [{
          text: this.trainingModule.trainingModuleName,
          url: '/training/' + this.trainingModule.id
        }]
      }
},
methods: {
   stationCheck(data){
     var stations = [... new Set(data.map(p => p.stationAssignment))]
     for(var i in stations){
       var index = this.stationSelectValue.map(p => p.stationName).indexOf(stations[i])
       if(index == -1){
         var obj = this.stationsData.find(p => p.stationName == stations[i])
         if(obj){
           this.stationSelectValue.push(obj)
         }
       }
     }
   },
    getStationParents(data){
      var array = []
      for(var i = 0; i < data.length; i++){
        var string = data[i].stationParent
        var index = array.indexOf(string)
          if(string != '' && string != null && string != undefined && index < 0){
          array.push(string)
          }
        }
      return array
    },
   eventTagSub(data){
      var array = [... new Set(this.eventTags.filter(p => p.eventtypeParent.match(data)))]
      if(data == 'Other Dept Activities' && this.workgroupData.length > 0){
        var work = this.workgroupData.filter( p => { return p.workgroupCalendar == true})
        for(var i = 0; i < work.length; i++){
          var string = { eventtypeName: work[i].workgroupName }
          array.push(string)
        }
      } return array
    },
    emitUserRemove(data){
      this.$emit('removeUserFromHold', data)
    },
   // training_record_completion_date timestamptz NOT NULL, 
   // training_record_complete boolean DEFAULT false, 
  //  training_record_module_id text, 
  // training_record_form_data json[], 
  //  training_record_users text[], 
   // training_record_author text, 
  //  training_record_author_id text
    submitTraining(){
     axios({
      method: 'post',
      url: '/api/v1/training/trainingrecord/',
      data: {
              "trainingRecordHours": this.trainingCompletionHours,
              "trainingRecordCompletionDate":this.formatedDateExport,
              "trainingRecordComplete": true,
              "trainingRecordModuleId": this.trainingModule.id,
              "trainingRecordModuleName" : this.trainingModule.trainingModuleName, 
              "trainingRecordCredentials": this.trainingModule.trainingModuleCredential, 
              "trainingRecordUsers": this.userSelectedFormatExport,
              "trainingRecordFormData": [],
              "trainingRecordAuthor": this.userData.firstName + ' ' + this.userData.lastName,
              "trainingRecordAuthorId": this.userData.id,
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
          }
        }).then( response => {
          this.$emit('refreshSubmitData')
          this.stationSelectValue = []
          bus.$emit('refresh-calendar-list')
        })
    },
    submitArticl(){
     axios({
      method: 'post',
      url: '/api/v1/content/event',
      data: {
              "title": this.trainingModule.trainingModuleName + ' | ' + this.formatedDateExport,
              "eventStation":this.returnedTargetIdsStations,
              "eventDate":this.formatedDateExport,
              "eventDescription": [this.detailedTextArea],
              "eventPrivate": false,
              "eventTag": this.eventTagSubSelect,
              "eventTitleDescription": this.trainingModule.trainingModuleName,
              "eventTagParent":this.eventCatSelect,
              "eventUser": this.userSelectedFormatExport,
              "eventStationParent": this.getStationParents(this.stationSelectValue),
              "eventAuthor": this.userData.firstName + ' ' + this.userData.lastName,
              "eventAuthorId": this.userData.id,
              "eventContentId": this.trainingModule.id,
              "eventContentUrl": this.contentUrl,
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
          }
        }).then( response => {
          this.$emit('refreshSubmitData')
          this.stationSelectValue = []
          bus.$emit('refresh-calendar-list')
        })

     },
}

}
</script>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.searchBar {
  padding: 5px 10px;
    background: #444;
}
.subHeadingClass {
  background: #eee;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.userSpan {
    background: #f0454a;
    padding: 2px 0 0px 7px;
    color: #fff;
    border-radius: 5px;
    margin: 4px;
    display: inline-block;
}
.btn-Userdanger {
  background: #f0454a;
  color: #444;
  font-weight: bold;
  margin: auto;
  cursor: pointer;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
  min-height: 800px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
