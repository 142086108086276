<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>

      <div  v-if="userData.role == 'adminchief'
      || userData.role == 'admin' || userData.role == 'emsops' || userData.role == 'fireops'">
      <b-btn @click="editBanner = !editBanner" :variant="editBanner === false ? 'outline-info' : 'info'">{{ editBanner === false ? 'Edit Banners' : 'Turn Off Editing' }}</b-btn>
<b-container v-if="bannerImages.length > 0">


<b-table v-if="!editBanner" hover striped :fields="fields" :items="returnedBannerImages">
   <template slot="row-details" slot-scope="row">
     <b-container><b-row><b-col cols="8">
      <b-container><b-row><b-col><strong>Item Title: </strong>{{ row.item.bannerItem }}</b-col></b-row><b-row><b-col class="pt-2">
        <h6><strong>Description:</strong></h6> 
     {{ row.item.bannerDescription }}</b-col></b-row></b-container>
     </b-col><b-col>
     <b-img :src="siteconfig.deptStore + row.item.bannerPicture" />
     </b-col></b-row></b-container>
   </template>
   <template slot="Operation" slot-scope="data">
     <b-btn size="sm" variant="danger" @click="deleteNotification(data.item)">Remove</b-btn>
   </template>
</b-table>


<b-table v-if="editBanner" hover striped :fields="fields" :items="returnedBannerImages">
   <template slot="row-details" slot-scope="row">
     <b-container><b-row><b-col cols="8">
      <b-container><b-row><b-col><strong>Item Title: </strong>
      
      <b-form-input type="text" v-model="row.item.bannerItem" />
      
      </b-col></b-row><b-row><b-col class="pt-2">
        <h6><strong>Description:</strong></h6> 
     <b-textarea :rows="3" v-model="row.item.bannerDescription" /></b-col></b-row></b-container>
     </b-col><b-col>
     
       <b-btn variant="primary" size="sm">Change Photo</b-btn>
       <b-form-file ref="fileinput" @change="postImage($event, row.item)" />
     <b-img :src="siteconfig.deptStore + row.item.bannerPicture" />

     </b-col></b-row></b-container>
   </template>
   <template slot="Operation" slot-scope="data">
     <b-btn size="sm" variant="warning" :disabled="checkForUpdates(data.item)" @click="updateBanner(data.item)">Update</b-btn>
   </template>
</b-table>
</b-container>


  </div>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import axios from 'axios'
import UserTable from './usertable/userTable.vue'
export default {
  name: 'hello',
  components: {
    'usertable': UserTable
  },
  data () {
    return {
 
      fields: [
      {key: 'bannerAuthor', label: 'Author'},
      { key: 'title', label: 'Info'},
      'Operation'
    ],
      editBanner: false,
      notification: '',
    }
  },
  created(){

  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'getSessionData',
        'deptContactData',
        'bannerImages',
        'siteconfig'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },
      returnedBannerImages(){
        var bannerImages = _.cloneDeep(this.bannerImages)
        bannerImages.forEach(item => item._showDetails = true)
        return _.orderBy(bannerImages, ['updatedAt'], ['desc'])
      }

  },
  watch: {
 
},
  methods: {
    checkForUpdates(data){
      var orig = this.bannerImages.filter(p => {
        return p.id == data.id
      })
      var orig = orig[0]
      if(orig.bannerItem == data.bannerItem && orig.bannerDescription == data.bannerDescription && orig.bannerPicture == data.bannerPicture){
        return true
      } else {
        return false
      }
    },
    submitAsRead(data){
     axios({
      method: 'patch',
      url: '/api/v1/content/notification/adminread/' + this.notification.id + '/' + data,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      this.notification = response.data
      this.getNotifications()
      
      })
    },
    deleteNotification(data){
     axios({
      method: 'delete',
      url: '/api/v1/content/banner/' + data.id,
      data: {

            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
       var token = this.getSessionData
       var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': token,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
        var payload = { id: this.userData.id, headers: headers }
             this.$store.dispatch('LOAD_BANNER_IMAGES_EXP', payload)
      
      })
    },
    postImage(e, dataItem) {
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])

       axios({
        method: 'post',
        url: '/api/v1/upload/files/bannerImages/' + this.userData.lastName + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          dataItem.bannerPicture = response.data

        });

  
     },
     updateBanner(data){
       axios({
        method: 'patch',
        url: '/api/v1/content/banner/' + data.id,
        data: {
                "bannerItem": data.bannerItem,
                "bannerDescription": data.bannerDescription,
                "bannerPicture": data.bannerPicture,
                "bannerAuthor": this.userData.firstName + ' ' + this.userData.lastName,
                "bannerAuthorId": this.userData.id,
                "title": data.title

              },
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
       var token = this.getSessionData
       var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': token,
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
        var payload = { id: this.userData.id, headers: headers }
             this.$store.dispatch('LOAD_BANNER_IMAGES_EXP', payload)
          
        })
    },

 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
img {
  max-width: 100%;
  height: auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.notification-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}

.notificationTitle {
  color: red;
}
.notificationSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.notificationSubData {
  font-size: 16px;
  color: #777;
}
.notificationBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}
</style>
