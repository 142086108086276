<template >
  <div >
                  <b-navbar type="dark" variant="dark">
      <b-nav-form>
         <b-navbar-brand class="bootstrapTable">Audit Range</b-navbar-brand>
      <b-form-input type="date" v-model="graphStart" />
      <b-form-input type="date" v-model="graphEnd" />
      <b-navbar-brand class="bootstrapTable ml-2">Units</b-navbar-brand>
      <b-form-select v-model="searchBy" :options="searchByUnits" />
      <b-btn class="mt-1 ml-2" variant="danger" @click="getNarcForGraph">
        Run Search
      </b-btn>
      </b-nav-form>
    </b-navbar>
          <h5 class="text-success" v-if="graphTabs.length == 0">LOADING NARC GRAPHS</h5>
          <b-card no-body v-if="graphTabs.length > 0 && graphData.length > 0">
            <b-tabs card>
              <b-tab v-for="(narc, index) in graphTabs" :title="narc" :key="index" @click="viewNarcGraph = narc">
                    <b-container  v-if="viewNarcGraph == narc && getNarcDataForGraph.length > 0 && searchBy == 'all'" fluid>
                      <b-container v-for="(data, index) in getNarcDataForGraph" :key="index" fluid>
                    <barChart :dataSent="{ labels: getNarcLabelsForGraph, datasets: data }" />
                      </b-container>
                      </b-container>
                      <b-container v-if="viewNarcGraph == narc && getNarcDataForGraph.length > 0 && searchBy != 'all'" fluid>
                             <barChart :dataSent="{ labels: getNarcLabelsForGraph, datasets: getNarcDataForGraph }" />
                        </b-container>
                      </b-tab>
                      </b-tabs> 
                      </b-card>

</div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { bus } from '../../../main'
import printJS from 'print-js'
import _ from 'lodash'
import { mapState } from 'vuex'
import JsonExcel from 'vue-json-excel'
import Multiselect from 'vue-multiselect'
import MedAdmin from '../../admin/adminMedication.vue'
import barChart from './apparatusChart.vue'
export default {
  props: ["medsNarctrack"],
  name: 'apparatus',
  components: {
    'multiselect' : Multiselect,
    'medadmin' : MedAdmin,
    'download-excel' : JsonExcel,
    barChart
  },
  data () {
    return {
      viewNarcGraph: '',
      graphStart: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      graphEnd: moment().format('YYYY-MM-DD'),
      graphDateRange: '30 Days',
      graphDateRangeOptions: ['30 Days', '90 Days', '6 Months'],
      graphData: [],
      searchBy: 'all'

    }
  },
created (){

},
mounted(){
  
},
computed: {
  ...mapState([
      'apparatusData',
      'userData',
      'deptContactData',
      'getSessionData'
      ]),
      searchByUnits(){
        var data = this.apparatusData.filter(p => {
          return p.apparatusMedicalType == 'ALS'
        })
        var hold = [{value: 'all', text: 'ALL'}]
        for(var i in data){
            hold.push({value: data[i].apparatusName, text: data[i].apparatusName })
        }
        return hold
      },
      graphDataReturn(){
        var EndDate = moment().format('YYYY-MM-DD')
        var startDate 
        var array = this.graphData.filter(p => {
          return moment(p.narctrack_date).isBetween(this.graphStart, this.graphEnd, 'days', '[]')
        })
        array = array.map(item => {
            var obj = item
            var array = item.narctrack_meds.map(x => {
                return this.medsNarctrack.find(p => p.id == x)
            })
            obj['meds'] = array
            var medHold = [...new Set(array.map(p => p.medication))]
            for(var m in medHold){
              obj[medHold[m]] = array.filter(p => p.medication == medHold[m])
            }
            return obj
        })
       // console.log(array)
        return _.orderBy(array, ['narctrack_date'], ['asc'])
      },
      graphTabs(){
        var array = this.graphDataReturn
        var hold = array.map(p => p.meds)
        var meds = []
        for(var i in hold){
          var arraymed = hold[i]
          for(var a in arraymed){
                meds.push(arraymed[a])
            
            
          }
        }
        var undefinedTest = meds.filter(p => {
          return !p.med
        })
       // console.log('Undefined')
     //   console.log(undefinedTest)
        meds = [... new Set(meds.map(p => p.medication))]
        meds = meds.filter(p => {
          if(p){
            return p
          }
        })
        if(meds[0]){
          this.viewNarcGraph = meds[0]
        }
        return meds
      },
      getNarcLabelsForGraph(){
          var now = moment(this.graphStart, 'YYYY-MM-DD')
          var dates = []
          while (now.isSameOrBefore(this.graphEnd)) {
              dates.push(now.format('YYYY-MM-DD'));
              now.add(1, 'days');
          }
          return dates;
      },
      getNarcDataForGraph(){
          var narc = this.viewNarcGraph
          var array = this.graphDataReturn.filter(p => {
                if(p[narc]){
                  return p
                }
          })
          var dates = this.getNarcLabelsForGraph
          var data = []
          var totals = array.filter(p => p.narctrack_entry_type != 'use' && p.narctrack_entry_type != 'restock' && p.narctrack_entry_type != 'distribution' && p.narctrack_entry_type != 'quarantine' && p.narctrack_entry_type != 'destroy' && p.narctrack_entry_type != 'initial')
          if(totals.length > 0){
            var obj = {}
            obj['label'] = "Verification"
            obj['backgroundColor'] = "#008000"
            obj['data'] = []
            for(var d in dates){
              var count = 0
              var narcT = totals.filter(p => moment(p.narctrack_date).isSame(dates[d], 'day'))
              narcT = narcT.filter(p => p.narctrack_tag_id != 'EMS 01' && p.narctrack_tag_id != 'EMS 02')
              var appIds = [...new Set(narcT.map(p => p.narctrack_tag_id))]
              var narcD = []
              for(var a in appIds){
                  var arrayh = narcT.filter(p => p.narctrack_tag_id == appIds[a])
                  if(arrayh.length > 1){
                    var logs = _.orderBy(arrayh, ['narctrack_date'], ['dsc'])
                    narcD.push(logs[0])
                  } else if(arrayh.length == 1) {
                    narcD.push(arrayh[0])
                  }
              }
              if(narcD.length > 0){
                for(var c in narcD){
                  if(narcD[c][narc]){
                    count = count + narcD[c][narc].length
                  }
                }
              }
              obj.data.push(count)
            }
            if(this.searchBy == 'all'){
            data.push([obj])
            } else {
              data.push(obj)
            }
          }
          var stock_distrub = []
          var stock = array.filter(p => p.narctrack_entry_type == 'quarantine')
          if(stock.length > 0){
            var appIds = [...new Set(stock.map(p => p.narctrack_tag_id))]
            for(var a in appIds){
            var arrayh = stock.filter(p => p.narctrack_tag_id == appIds[a])
            var obj = {}
            obj['label'] = appIds[a] + " Total"
            obj['backgroundColor'] = "#000000"
            obj['data'] = []
            for(var d in dates){
              var count = 0
              var narcT = arrayh.filter(p => moment(p.narctrack_date).isSame(dates[d], 'day'))
              var narcD = {}
                  if(narcT.length > 1){
                    var logs = _.orderBy(narcT, ['narctrack_date'], ['dsc'])
                    narcD = logs[0]
                  } else if(narcT.length == 1) {
                    narcD = narcT[0]
                  }
                  if(narcD[narc]){
                      count = count + narcD[c][narc].length
                  }
              obj.data.push(count)
                      
              }
              if(obj.data.length > 0){
              stock_distrub.push(obj)
              }
            }
       
          }
          var distrib = array.filter(p => p.narctrack_entry_type == 'distribution')
          if(distrib.length > 0){
            var obj = {}
            obj['label'] = "Narc Distribution"
            obj['backgroundColor'] = "#e2070d"
            obj['data'] = []
            for(var d in dates){
              var count = 0
              var narcD = distrib.filter(p => moment(p.narctrack_date).isSame(dates[d], 'day'))
              if(narcD.length > 0){
                for(var c in narcD){
                  if(narcD[c][narc]){
                     count = count + narcD[c][narc].length
                  }
                }
              }
              obj.data.push(count)
            }
              if(obj.data.length > 0){
              stock_distrub.push(obj)
              }
          }
          if(stock_distrub.length > 0){
          data.push(stock_distrub)
          }
          var use_restock = []
          var use = array.filter(p => p.narctrack_entry_type == 'use')
          if(use.length > 0){
            var obj = {}
            obj['label'] = "Narc Use"
            obj['backgroundColor'] = "#ffe007"
            obj['data'] = []
            for(var d in dates){
              var count = 0
              var narcD = use.filter(p => moment(p.narctrack_date).isSame(dates[d], 'day'))
              if(narcD.length > 0){
                for(var c in narcD){
                  if(narcD[c][narc]){
                    count = count + 1
                  }
                }
              }
              obj.data.push(count)
            }
            if(this.searchBy == 'all'){
            use_restock.push(obj)
            } else {
              data.push(obj)
            }
          }
          var restock = array.filter(p => p.narctrack_entry_type == 'restock')
          if(restock.length > 0){
            var obj = {}
            obj['label'] = "Narc Restock"
            obj['backgroundColor'] = "#0000ff"
            obj['data'] = []
            for(var d in dates){
              var count = 0
              var narcD = restock.filter(p => moment(p.narctrack_date).isSame(dates[d], 'day'))
              if(narcD.length > 0){
                for(var c in narcD){
                  if(narcD[c][narc]){
                     count = count + narcD[c][narc].length
                  }
                }
              }
              obj.data.push(count)
            }
            if(this.searchBy == 'all'){
            use_restock.push(obj)
            } else {
              data.push(obj)
            }
          }
          if(use_restock.length > 0){
          data.push(use_restock)
          }
    
          return data
     },
 
},
watch:{
    
},
methods: {
  getNarcForGraph(){
      this.graphData = []
     var token = this.getSessionData
     this.graphStart
     var headers = {
        headers: {
          "content-type": "application/json",
          Authorization: this.getSessionData,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
          }}
    axios({
      method: 'get',
      url: '/api/v1/content/dailynarctrack/admingraph/' + moment(this.graphStart).subtract(1, 'd').toISOString() + '/' + moment(this.graphEnd).add(1, 'd').toISOString() + '/' + this.searchBy ,
      headers: headers.headers
       }).then( response => {
   //   console.log(response.data)
      this.graphData = response.data
    })
  },
 
}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style :src="'./customcss/vue-multiselect.min.css'"></style>
<style>
.hideNarcRow {
  display: none;
}
</style>
<style slot-scoped>
.floatRight {
  float: right;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
}
.apparatusNameClass{
  font-weight: bold;
  background: #fff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}
.narcRow {

  margin-top: 10px;
  padding-top: 5px;
  background: #eee;
  min-height: 50px;
}
.apparatusNameClass{
  font-weight: bold;
  background: #fff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}
.A-shift-app {
  background: red;
  color: #fff;
}
.B-shift-app {
  background: blue;
  color: #fff;
}
.C-shift-app {
  background: green;
  color: #fff;
}
.apparatusTitleBar {
  color: #fff;
}
.badge.Use.badge-secondary {
  background-color: #ffe007;
  color: #000;
}
.badge.Transfer.badge-secondary {
  background-color: green;
  color: #fff;
}
.badge.Distribution.badge-secondary {
  background-color: red;
    color: #fff;
}
.badge.Total.badge-secondary {
  background-color: #333;
    color: #fff;
}
.badge.Restock.badge-secondary {
  background-color: blue;
    color: #fff;
}

</style>
