<template>
<div>

                   <b-form-group 
                                breakpoint="lg"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                    <b-container>
                    <b-row class="mb-2">
                      <b-col v-for="(field, index) in group.groupFields" :key="index" class="mt-2" :lg="field.size" :label-for="field.objKey"><h6>{{ field.label ? field.label : '' }}
                      <b-btn size="sm" variant="outline-secondary" v-if="submissionView != true" class="float-right" @click="editThisField(field.objKey)">Edit</b-btn></h6>
                      <h4 class="mx-auto" v-if="field.type == 'textarea' && field.itemType == 'review'">{{ field.value }}</h4>
                         <b-container v-if="field.type == 'file' && field.itemType == 'review'"><p class="text-left" v-for="(link, index) in field.value" :key="index">
                            <b-btn class="m-0 py-1" style="white-space: normal" variant="link" @click="viewFile(link.url)" >{{ link.title }}</b-btn></p></b-container>
                     
                     <b-form-input v-if="field.type == 'text'" :class="checkForRequirements(field.requirements, field.objKey, 'class')" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" />
                     <b-form-input v-if="field.type == 'number'" type="number" :class="checkForRequirements(field.requirements, field.objKey, 'class')" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" />
                     <b-textarea v-if="field.type == 'textarea' && field.itemType == 'form'" :rows="3" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" />
                  
                    <b-row v-if="field.type == 'date' || field.type == 'datetime' || field.type == 'time'">
                      <b-col v-if="field.type == 'date' || field.type == 'datetime'">
                    <b-form-input   type="date" v-model="vmodelHold[field.objKey]['date']" :class="checkForRequirements(field.requirements, field.objKey, 'class', field.type)" @input="updateFormData(field.objKey, group.id, 'date')" /></b-col>
                    <b-col v-if="field.type == 'time' || field.type == 'datetime'">
                    <b-form-input  v-if="field.type == 'time' || field.type == 'datetime'" :class="checkForRequirements(field.requirements, field.objKey, 'class', field.type)" type="time" v-model="vmodelHold[field.objKey]['time']" @input="updateFormData(field.objKey, group.id, 'time')" /></b-col></b-row>
                    
                    <b-form-select  v-if="field.type == 'select' && field.selectType == 'menu'" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" :options="field.selectOptions" />
                    <b-radio-group  v-if="field.type == 'select' && field.selectType == 'radio'" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" :options="field.selectOptions" />
                    <Multiselect  v-if="field.type == 'selectmulti'" 
                                  class="pt-2"
                                  v-model="vmodelHold[field.objKey]"
                                   @input="updateFormDataMultiSelect(field.objKey, group.id)"
                                 :options="field.selectOptions"
                                 :multiple="true" 
                                 :close-on-select="false" :clear-on-select="true" 
                                 :hide-selected="true" :preserve-search="true" 
                                 placeholder="Please Make a Selection" 
                                 label="text" track-by="text">
                                    <template slot="option" slot-scope="props">
                                      <span class="option__title">{{ props.option.text }}</span>
                                    </template>
                     </Multiselect>
                    <b-checkbox-group  v-if="field.type == 'checkbox'" v-model="vmodelHold[field.objKey]" :class="checkForRequirements(field.requirements, field.objKey, 'class')" @input="updateFormData(field.objKey, group.id)" :options="field.selectOptions" />
                    <span v-if="field.type == 'boolean'" :class="'boolean-' + checkForRequirements(field.requirements, field.objKey, 'class', field.type)">
                    <b-form-checkbox  v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.id)" :check-value="true" :uncheck-value="false">{{ field.displayText }}</b-form-checkbox>
                    </span>
                     <Multiselect  v-if="field.type == 'dataRef'" 
                                  class="pt-2"
                                  v-model="vmodelHold[field.objKey]"
                                   @input="updateFormDataMultiSelect(field.objKey, group.id, field.refValue, field.refType, field.refMenuProps)"
                                 :options="getDataFormReturn(field.refItem)"
                                 :multiple="field.refType == 'multiselect' ? true : false" 
                                 :close-on-select="field.refType == 'multiselect' ? false : true" :clear-on-select="true" 
                                 :hide-selected="true" :preserve-search="true" 
                                 placeholder="Please Make a Selection" 
                                 :label="field.refKey" track-by="id">
                                    <template slot="option" slot-scope="props">
                                      <span class="option__title">
                                        <span v-for="(refmp, index2) in field.refMenuProps" :key="index2">
                                        {{ props.option[refmp] }}
                                        </span>
                                        </span>
                                    </template>
                     </Multiselect>
                     <b-container v-if="field.type == 'signature'">
                       <b-btn v-if="!vmodelHold[field.objKey]" @click="openSingatureModal(field.objKey, group.id)">Add Signature</b-btn>
                       <b-img class="signature-picture" :src="vmodelHold[field.objKey]" />
                     </b-container>
                     <b-container v-if="field.type == 'upload'" :on="setshortitle(field.objKey)">
         
                           <b-form-input class="w-50 mx-auto my-2" type="text" placeholder="Add a display name to upload file" v-model="shorttitle[field.objKey]"  @input="updateshortitle($event, field.objKey)" />
                              <input  v-if="filePosting == false" :disabled="shorttitle[field.objKey] == ''" type="file"  @change="postDocumentFile($event, shorttitle[field.objKey], field.objKey, group.id)" />
                                    <b-row v-if="filePosting == true"><b-col align="right" cols="auto" class="pt-1">
                                      <span div class="spinner">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>      
                                    </span></b-col></b-row>
                        <b-table small v-if="field.type == 'upload' && vmodelHold[field.objKey].length > 0" :fields="['url', 'remove']" :items="vmodelHold[field.objKey]">
                            <template slot="url" slot-scope="data">
                              <b-btn variant="link" @click="viewFile(data.item.url)">{{ data.item.title }}</b-btn>
                              </template>
                              <template slot="remove" slot-scope="data">
                                <b-btn variant="danger" size="sm" @click="vmodelHold[field.objKey].splice(data.index, 1)">X</b-btn>
                                </template>
                                 </b-table>


                          </b-container>


                     <div v-if="field.type == 'html'"  class="ql-editor unset-minheight" v-html="field.value" />
                     <b-container v-if="field.type == 'link' && field.itemType == 'review'"><p class="text-left" v-for="(link, index) in field.value" :key="index">
                            <b-btn class="m-0 py-1" style="white-space: normal" variant="link" @click="viewFile(link.url)" >{{ link.title }}</b-btn></p></b-container>
                  <b-container v-if="field.type == 'video' && field.itemType == 'review'">
                          <b-container  v-if="field.value.length > 0" class="w-75">
                            <div v-for="(video, index) in field.value" :key="index">
                             
                            <div class="iframeWrap">
                            <iframe class="videoIframe" :src="getVideoId(video)" frameborder="0" allowfullscreen></iframe>
                            
                            </div>
                            </div>
                            </b-container>
                        </b-container>
                  <b-container v-if="field.type == 'photo' && field.value.length > 0" class="w-75 mt-3">
                                  <b-carousel id="carousel1"
                                            style="text-shadow: 1px 1px 2px #333;"
                                              controls
                                            indicators
                                            interval="10000"
                                            background="#ababab"
                                            v-model="slide"
                                          >
                                    <template v-for="(pic, index) in field.value">
                                    <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" >
                          
                                    </b-carousel-slide>
                                    </template>
                                  </b-carousel>
                          </b-container>
                        <h6 class="text-danger" v-if="checkForRequirements(field.requirements, field.objKey, 'errormsg')">{{ checkForRequirements(field.requirements, field.objKey, 'errormsg') }}</h6>
                      </b-col>
                      </b-row>   
                    </b-container>
                    </b-form-group>
      <b-btn  @click="consoleField(vmodelHold)" v-if="userData.role == 'admin' && false">Console log vmodelHold</b-btn>
     <signature v-if="openSignature" :showModalSignature="openSignature" :name="userData.lastName" @signatureCapture="signatureMethodUser($event)" @closeModalCancel="openSignature = !openSignature"></signature>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { VueEditor } from 'vue2-editor'
import userSelector from '../../../inputfields/userfilter.vue'
import signature from "../../../inputfields/signaturepad.vue";
import moment from 'moment'
export default {
  components: {
    userSelector,
    Multiselect,
    VueEditor,
    signature
  },
  props: ['group', 'vmodelHoldDefault', 'formModules', 'submissionView'],
  name: 'training-module-field',
  data () {
    return {
        currentPage: 1,
        showAll: true,
        edit: true,
        editGroupId: '',
        shorttitle: {},
        filePosting: false,
        showGroup: '',
        slide: 0,
        dataReset: true,
        openSignature: false,
        signatureHoldObject: {},
        deptRoles: ['user', 'supervisor', 'trainingops', 'emsops', 'fireops', 'adminchief'],
       trainingModuleSubmissionRequirements: [

       ],
       formGroupColorOptions: [
        { value: "info", text: "Teal" },
        { value: "primary", text: "Blue" },
        { value: "success", text: "Green" },
        { value: "danger", text: "Red" },
        { value: "warning", text: "Yellow" },
        { value: "secondary", text: "Grey" },
        { value: "default", text: "Default" }
      ],
      formValues: {

      },
      vmodelHold: {

      },
      errorsHoldArray: [],
      setGroupUsersForTraining: [],
      searchData: '',
      customToolbar: [
          ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
          ['blockquote', 'code-block', 'link', { 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
    }
  },
  created(){
    this.vmodelHold = this.vmodelHoldDefault
  //  console.log(this.formModules)
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),
       deptContactDataCheck(){
         var data = this.deptContactData
         data.forEach(item => item['addCredential'] = false)
         return data
       },
       setGroupUsersForTrainingFilter(){
         return this.setGroupUsersForTraining.filter(p => {
           return p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase())
         })
       },
  
  },
  mounted(){
   //// YOU need to add the mechinism that perms the data up to formBuilder
  },
  watch: {
    vmodelHoldDefault(newData){
      this.vmodelHold = newData
    }, 
    errorsHoldArray(newData){
     // console.log(newData)
      if(newData.length > 0){
        this.$emit('errorsPresent', true)
      } else {
        this.$emit('errorsPresent', false)
      }
    }
  },
  methods: {
    openSingatureModal(objid, groupid){
      this.signatureHoldObject = {groupid: groupid, objid: objid}
      this.openSignature = true
    },
    processFileUriForUpload(groupid, objid, file) {
    //  var dataSplice = file;
    //  var newplacedData = dataSplice.split(",");
    //  var fileHold = newplacedData[1]
     // console.log('UPDATE FOR DATA')
      console.log(file)
      console.log(groupid)
      console.log(objid)
      //this.$emit('updateObjectValue', newObj)
        this.vmodelHold[objid] = file
        this.updateFormData(objid, groupid)
     // this.signatureHoldObject = {}
    },
    signatureMethodUser(data) {
      this.openSignature = false;
      this.processFileUriForUpload(this.signatureHoldObject.groupid, this.signatureHoldObject.objid, data);
    },
    consoleField(data){
      console.log(data)
    },
    setshortitle(data){
      if(this.shorttitle[data] == undefined){
      this.shorttitle[data] = ''
      }
    },
    updateshortitle(data, key){
      this.filePosting = true
      this.shorttitle[key] = data
      this.$nextTick(function(){
        this.filePosting = false
      })
    },
    editThisField(data){
      this.$emit('editField', data)
    },
    deleteThisField(data){
      this.$emit('deleteField', data)
    },
    checkForRequirements(requirements, objKey, type, flag){
      //console.log(requirements)
    //  console.log(objKey)
     // console.log(type)
     // console.log(flag)
      var classHold = ''
      var errorHold = ''
      if(requirements){
        var reqText = []
        for(var i in requirements){
          var test = this.checkEachRequirement(requirements[i], flag)
          if(!test.status){
            reqText.push('pass')
          } else {
            reqText.push('failed')
            errorHold = test.errorText
          }
        }
        if(reqText.indexOf('failed') != -1){
           var index = this.errorsHoldArray.indexOf(objKey)
             if(index == -1){
            this.errorsHoldArray.push(objKey)
          }
          classHold = 'requirement-error'
        } else {
          var index = this.errorsHoldArray.indexOf(objKey)
          if(index > -1){
            this.errorsHoldArray.splice(index, 1)
          }
        }
      }
      if(type == 'class'){
      return classHold
      } else if (type == 'errormsg'){
        return errorHold
      }
    },
    checkEachRequirement(req, flag){
      var returnVal = {status: false, errorText: ''}
      var reqs = req.requirements
      var array = []
      for(var i in reqs){
        if(reqs[i].type == 'isBlank'){
          if(flag == 'datetime' || flag == 'time' || flag == 'date'){
            if(flag == 'datetime' && (this.vmodelHold[reqs[i].variable]['date'] == '' || this.vmodelHold[reqs[i].variable]['time'] == '' )){
              array.push('true')
            } else if (flag == 'date' && this.vmodelHold[reqs[i].variable]['date'] == ''){
              array.push('true')
            } else if (flag == 'time' && this.vmodelHold[reqs[i].variable]['time'] == ''){
              array.push('true')
            } else {
              array.push('false')
            } 
          }
          if(flag == 'boolean'){
            if(this.vmodelHold[reqs[i].variable]){
              array.push('false')
            } else {
              array.push('true')
            }
          }
          else if(this.vmodelHold[reqs[i].variable] == ''){
            array.push('true')
          } else {
            array.push('false')
          }
        }
        if(reqs[i].type == 'isNotBlank'){
          console.log(this.vmodelHold[reqs[i].variable])
          if(this.vmodelHold[reqs[i].variable] != '' && this.vmodelHold[reqs[i].variable] != undefined){
            array.push('true')
          } else {
            array.push('false')
          }
        }
        if(reqs[i].type == 'equals'){
          if(this.vmodelHold[reqs[i].variable] == reqs[i].typeVar){
            array.push('true')
          } else {
            array.push('false')
          }
        }
        if(reqs[i].type == 'doesNotEqual'){
          if(this.vmodelHold[reqs[i].variable] != reqs[i].typeVar){
            array.push('true')
          } else {
            array.push('false')
          }
        }
        if(reqs[i].type == 'isOneOf'){
          if(reqs[i].refKey){
            var reqvar = reqs[i].typeVar
            var testHold = false
            for(var v in reqvar){
              if(this.vmodelHold[reqs[i].variable] && this.vmodelHold[reqs[i].variable].map(p => p[reqs[i].refKey]).indexOf(reqvar[v]) != -1){
                testHold = true
              }
            } if(testHold){
              array.push('true')
            } else {
              array.push('false')
            }
          } else {
            var reqvar = reqs[i].typeVar
            var testHold = false
            for(var v in reqvar){
              if(this.vmodelHold[reqs[i].variable] && this.vmodelHold[reqs[i].variable].indexOf(reqvar[v]) != -1){
                testHold = true
              }
            } if(testHold){
              array.push('true')
            } else {
              array.push('false')
            }
          }
          
        }
      if(reqs[i].type == 'isNoneOf'){
          if(reqs[i].refKey){
            var reqvar = reqs[i].typeVar
            var testHold = false
            for(var v in reqvar){
              if(this.vmodelHold[reqs[i].variable] && this.vmodelHold[reqs[i].variable].map(p => p[reqs[i].refKey]).indexOf(reqvar[v]) == -1){
                testHold = true
              }
            } if(testHold){
              array.push('true')
            } else { 
              array.push('false')
            } 
          } else {
            var reqvar = reqs[i].typeVar
            var testHold = false
            for(var v in reqvar){
              if(this.vmodelHold[reqs[i].variable] && this.vmodelHold[reqs[i].variable].indexOf(reqvar[v]) == -1){
                testHold = true
              }
            } if(testHold){
              array.push('true')
            } else {
              array.push('false')
            }
          }
          
        }
        
      }
     // console.log(array)
      //console.log(array)
      if(array.indexOf('false') == -1){
        returnVal = {status: true, errorText: req.errorText}
      }
      return returnVal
    },
    testFunction(){
      if(firstName == '' || (rankrole == 'Admin' && lastName != '')){
      return true
      }
    },
    getDataFormReturn(data, filter){
      if(!filter){
        return this[data]
      } else {
        // need filter script
        return this[data]
      }
    },
    updateFormData(objid, groupid){
      console.log('UPDATE FOR DATA')
      var value = this.vmodelHold[objid]
      var newObj = {groupid: groupid, objKey: objid, value: value}
      this.$emit('updateObjectValue', newObj)

    },
    updateFormDataMultiSelect(objid, groupid, savevalue, multi, props){
      if(savevalue && multi && props){
      var value = this.vmodelHold[objid]
      var result = Array.isArray(value)
      if(!result){
        value = [value]
      }
      var array = []
      var arrayStore = []
      for(var i in value){
        array.push(value[i][savevalue])
        var obj = {}
        for(var p in props){
          obj[props[p]] = value[i][props[p]]
        }
        arrayStore.push(obj)
      } 
      var newObj = {groupid: groupid, objKey: objid, value: arrayStore, searchvalue: array}
      }
      else {
        var value = this.vmodelHold[objid]
        var result = Array.isArray(value)
        if(!result){
          value = [value]
        }
        var array = []
        var arrayStore = []
      for(var i in value){
        array.push(value[i].value)
      } 
      for(var i in value){
        arrayStore.push(value[i].text)
      }
         var newObj = {groupid: groupid, objKey: objid, value: arrayStore, searchvalue: array}
      }
     this.$emit('updateObjectValue', newObj)
    },
    getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
    },
    postDocumentFile(e, title, key, group){
      this.filePosting = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/trainingmodules/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        this.vmodelHold[key].push({ title: title, url: response.data})
        this.updateFormData(key, group)

      this.$nextTick(function(){
        this.filePosting = false
        this.shorttitle[key] = ''
      })
        });
    },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
    submitTrainingModule(data){
      this.$emit('updateFormInformation', data)
  },

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style scoped>
.signature-picture{
  max-width: 300px;
}
.boolean-requirement-error{
  padding: 2px;
  border-radius: 5px;
  border: 1px solid red;
}
.requirement-error{
  border: 1px solid red !important;
}
.unset-minheight {
  min-height: unset;
}
.text-align-clear {
  text-align: left;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
