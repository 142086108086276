<template>
  <div class="hello">
    <b-container fluid>
    <b-card no-body v-if="!addNew && !viewSchedule">
      <b-tabs card>
        <b-tab title="Active Schedules">
      <b-btn  block variant="info" @click="addNew = !addNew">ADD NEW</b-btn>
      <b-table v-if="!addNew && !viewSchedule" :items="scheduleDataReturnActive" :fields="scheduleFields">
        <template slot="view" slot-scope="data">
          <b-btn variant="primary" size="sm" @click="getScheduleData(data.item.id)">View</b-btn>
          </template>
        </b-table>
        </b-tab>
        <b-tab title="Inactive Schedules">
           <b-table v-if="!addNew && !viewSchedule" :items="scheduleDataReturnInactive" :fields="scheduleFields">
        <template slot="view" slot-scope="data">
          <b-btn variant="primary" size="sm" @click="getScheduleData(data.item.id)">View</b-btn>
          </template>
        </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
      <b-card v-if="viewSchedule" >
        <b-btn block variant="danger" @click="viewSchedule = false, editViewSchedule = false">Return to Table</b-btn>
        <div slot="header">
          <h4 v-if="!editViewSchedule">{{ viewSchedule.scheduleName + '(' + viewSchedule.patternType + ')' }}</h4>
          <b-form-input v-if="editViewSchedule" type="text" v-model="viewSchedule.scheduleName" /> {{ '(' + viewSchedule.patternType + ')' }}
          </div>
          <b-card no-body>
            <b-btn v-if="checkForVisibility(viewSchedule)" variant="info" @click="AddNewPatternViewSchedule(viewSchedule)">Add New Pattern</b-btn>
            <b-tabs card>
              <b-tab fluid v-for="(pat, index) in viewSchedule.schedulePattern" :key="index">
                <div slot="title">{{ 'Pattern ' + (index + 1) }}</div>
                
                <div  class="days-grid">
                  <template v-for="(day, indexd) in pat.interval" >
                    <ul cols="1" :key="indexd">
                      <h6 class="font-weight-bold">Day {{ indexd + 1 }}</h6>
                      <h6 v-if="!editViewSchedule && day.startTime">{{ day.startTime }}</h6>
                      <h6 v-if="!editViewSchedule && day.length">{{ day.length }}hrs</h6>
                      <h6 v-if="!editViewSchedule && !day.startTime">OFF</h6>
                      <b-form-input v-if="editViewSchedule" :disabled="!day.startTime" type="time" v-model="day.startTime" />
                      <b-form-input v-if="editViewSchedule"  :disabled="!day.length" type="number" v-model="day.length" />
                      <b-btn v-if="editViewSchedule" size="sm" :variant="day.startTime ? 'danger' : 'primary'" @click="updateWorkDataViewSchedule(index, indexd, day.startTime)">{{ day.startTime ? 'Off' : 'Work' }}</b-btn>
                      </ul>
                    
                    </template>
                    </div>
                                    <b-container fluid><b-row>
                  <b-col align="right" cols="*"><h5 class="mt-2">Repeat Pattern:</h5></b-col>
                  <b-col cols="2"><b-form-input type="number" v-model="pat.repeat" /></b-col>
                  <b-col cols="*" v-if="editViewSchedule"><b-btn    variant="danger" class="float-right" @click="removePattern(index)" size="sm">Delete Pattern {{ index + 1 }}</b-btn></b-col>
                  <b-col cols="6" align="right" v-if="!editViewSchedule"><b-btn variant="warning" size="sm" @click="editViewSchedule = !editViewSchedule">Edit</b-btn></b-col>
                  <b-col  v-if="editViewSchedule"><b-checkbox v-model="viewSchedule.active">Check to Activate Schedule</b-checkbox></b-col>
                  <b-col cols="3" align="right" v-if="editViewSchedule"><b-btn variant="success" size="sm" @click="updateSchedule">Update Schedule</b-btn><b-btn variant="warning" @click="viewSchedule = false, editViewSchedule = false" size="sm" >Cancel Edit</b-btn></b-col>
                  </b-row>
                  </b-container>
                    
              </b-tab>
            </b-tabs>
            <b-btn block variant="danger" size="sm" v-if="false && userData.role == 'admin'" @click="deleteSchedule()">Delete</b-btn>
            </b-card>
      </b-card>
      <b-card title="New Schedule" v-if="addNew">
         <b-btn block variant="danger" @click="viewSchedule = false, editViewSchedule = false, addNew = false">Return to Table</b-btn>
        <b-row class="m-2">
          <b-col>
           <b-form-input type="text" v-model="newScheduleName" placeholder="Schedule Name" /></b-col><b-col>
        <b-form-select :options="newPatternTypeOpt" v-model="newPatternType" /></b-col>
        </b-row>
        <b-card class="m-1">
          <b-row class="m-2"><b-col align="right"><h5 class="mt-2">Start Time:</h5></b-col><b-col>
          <b-form-input type="time" v-model="defaultTime" /></b-col>
          <b-col align="right"><h5 class="mt-2">Duration (hrs):</h5></b-col><b-col>
          <b-form-input type="number" v-model="defaultHours" /></b-col><b-col>
          <b-btn variant="info" @click="addNewPattern">Add New Pattern</b-btn></b-col></b-row>
          <b-container >
          <b-card no-body>
            <b-tabs card>
              <b-tab fluid v-for="(pat, index) in newSchedulePattern" :key="index" :title="'Pattern ' + (index + 1)" >
                <div  class="days-grid">
                  <template v-for="(day, indexd) in pat.interval" >
                    <ul cols="1" :key="indexd">
                      <h6 class="font-weight-bold">Day {{ indexd + 1 }}</h6>
                      <b-form-input :disabled="!day.startTime" type="time" v-model="day.startTime" />
                      <b-form-input  :disabled="!day.length" type="number" v-model="day.length" />
                      <b-btn size="sm" :variant="day.startTime ? 'danger' : 'primary'" @click="updateWorkData(index, indexd, day.startTime)">{{ day.startTime ? 'Off' : 'Work' }}</b-btn>
                      </ul>
                    
                    </template>
                    </div>
                                    <b-container fluid><b-row>
                  <b-col align="right"><h5 class="mt-2">Repeat Pattern:</h5></b-col>
                  <b-col><b-form-input type="number" v-model="pat.repeat" /></b-col>
                  <b-col cols="6" align="right"><b-btn variant="success" @click="newSchedule">Create Schedule</b-btn><b-btn variant="danger" @click="viewSchedule = false, editViewSchedule = false, addNew = false">Cancel</b-btn></b-col>
                  </b-row>
                  </b-container>
              </b-tab>
            </b-tabs>
            </b-card>
          </b-container>
        </b-card>
      </b-card>
    </b-container>
    <b-card no-body class="m-2" v-if="viewSchedule || addNew">
      <b-tabs card> 
        <b-tab title="Calendar">
    <b-row class="m-2" v-if="viewSchedule || addNew"><b-col align="right" cols="*"><h5 class="mt-2">Start Date:</h5></b-col><b-col><b-form-input type="date" v-model="scheduleStartDate" /></b-col>
            <b-col align="right" cols="*"><h5 class="mt-2">End Date:</h5></b-col><b-col><b-form-input type="date" v-model="scheduleEndDate" /></b-col>
            <b-col cols="*"><b-btn @click="viewSchedule ? viewScheduleTest() : buildTestSchedule()">Test</b-btn><b-btn variant="danger" @click="userRosterProfileDays = []">Clear</b-btn></b-col>
            <b-col cols="*"><b-btn @click="goPrev" variant="info">Prev Month</b-btn><b-btn @click="goNext" variant="info">Next Month</b-btn></b-col>
            </b-row>
    <calendar v-if="viewSchedule || addNew" class="calendarClass" :all-events="userRosterProfileDays" :daydisplayboo="true" :calendarHeader="calendarHeader" :calendarType="'payroll'" :displayWeekNumber="false" :calendarSmall="true" ></calendar>
        </b-tab>
        <b-tab title="Assigned Schedules" v-if="viewSchedule">
            <rosterprofileschedule  :rosterProfiles="rosterProfilesReturned" :rosterData="rosterData" :scheduleData="scheduleData" :hideEmpty="true" />
        </b-tab>
        </b-tabs>
    </b-card>
</div>

</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import Calendar from '../../calendarFull/Calendar.vue'
import rosterprofileschedule from './rosterprofileSchedule.vue'
export default {
  components: {
    multiselect,
    'calendar' : Calendar,
    rosterprofileschedule
  },
  name: 'schedule-builder',
  props: ['scheduleData', 'rosterProfiles', 'rosterData'],
  data () {
    return {
      calendarHeader: 'Sample Schedule Pattern',
      currentMonth: moment().startOf('month'),
      currentView: {},
      scheduleFields: [{key: 'updatedAt', sortable: true},{key: 'scheduleName', sortable: true}, 'patternType', 'active', 'view'],
      userRosterProfileDays: [],
      showCalendar: true,
      addNew: false,
      viewSchedule: false,
      editViewSchedule: false,
      scheduleStartDate: moment().format('YYYY-MM-DD'),
      scheduleEndDate: '',
      startDate: '2021-01-05',
      dates: [],
      newScheduleName: '',
      newPatternType: 'weekly',
      newSchedulePattern: [], /**  [
          { interval: [
              {startTime: '08:00', length: 24},
              {startTime: null, length: null},
              {startTime: null, length: null},
              {startTime: '08:00', length: 24},
              {startTime: null, length: null},
              {startTime: null, length: null},
              {startTime: '08:00', length: 24},
            ],
            repeatType: 'PatternInterations',
            repeat: 1
          },
                    { interval: [
              {startTime: null, length: null},
              {startTime: null, length: null},
              {startTime: '08:00', length: 24},
              {startTime: null, length: null},
              {startTime: null, length: null},
              {startTime: '08:00', length: 24},
              {startTime: null, length: null},
            ],
            repeatType: 'PatternInterations',
            repeat: 1
          },
                    { interval: [
              {startTime: null, length: null},
              {startTime: '08:00', length: 24},
              {startTime: null, length: null},
              {startTime: null, length: null},
              {startTime: '08:00', length: 24},
              {startTime: null, length: null},
              {startTime: null, length: null},
            ],
            repeatType: 'PatternInterations',
            repeat: 1
          },

      ], */
      newPatternTypeOpt: [
        {text: 'Daily', value: 'daily'},
       // {text: 'Cycle', value: 'cycle'},
        {text: 'Weekly', value: 'weekly'},
       // {text: 'Bi-Weekly', value: 'biweekly'},
       // {text: 'Monthly', value: 'monthly'},
      ],
      defaultTime: "08:00",
      defaultHours: "9",
      schobj:{
        scheduleName: '',
        patternType:  'weekly', // 'weekly', 'monthly', 'cycle', //
        schedulePattern: [
          { pattern: 1,
            minInterations: 2,
            maxInterations: 5,
            interval: [
              {day: 1, startTime: '08:00', length: 9.0},
              {day: 2, startTime: '08:00', length: 9.0},
              {day: 5, startTime: null, length: null},
              {day: 6, startTime: null, length: null},
              {day: 3, startTime: '08:00', length: 9.0},
              {day: 4, startTime: '08:00', length: 9.0},
              {day: 7, startTime: '08:00', length: 9.0},
            ],
            repeatType: 'SwitchTrigger',
            repeat: 'WeekBeforeEndOfTheMonth'
          },
          { pattern: 2, 
            interval: [
              {day: 1, startTime: '08:00', length: 9.0},
              {day: 2, startTime: '08:00', length: 9.0},
              {day: 5, startTime: null, length: null},
              {day: 3, startTime: '08:00', length: 9.0},
              {day: 4, startTime: '08:00', length: 9.0},
              {day: 7, startTime: '08:00', length: 9.0},
              {day: 6, startTime: null, length: null},
            ],
            repeatType: 'PatternInterations',
            repeat: 1
          },
          { pattern: 3,
            minInterations: 2,
            maxInterations: 5,
            interval: [
              {day: 1, startTime: '08:00', length: 9.0},
              {day: 2, startTime: '08:00', length: 9.0},
              {day: 3, startTime: '08:00', length: 9.0},
              {day: 4, startTime: '08:00', length: 9.0},
              {day: 5, startTime: null, length: null},
              {day: 6, startTime: null, length: null},
              {day: 7, startTime: '08:00', length: 9.0},
            ],
            repeatType: 'SwitchTrigger',
            repeat: 'firstOfTheMonth'
          },
        ]
        }
    }
  },
  created(){
  bus.$on('CalendarCurrentDisplayMonth', this.currentDateSelect)
  bus.$on('newPayrollModification', this.getNewModData)
  bus.$on('CalendarCurrentMonthObject', this.SetCurrentMonth)
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        rosterProfilesReturned(){
          if(this.viewSchedule && this.viewSchedule){
          var rosters = _.cloneDeep(this.rosterProfiles)
          return rosters.filter(p => p.scheduledShift == this.viewSchedule.id)
          } return []
        }
       
  },
  mounted(){
  

  },
  watch: {

  },
  methods: {
    checkForVisibility(viewSchedule){
    return viewSchedule.schedulePattern && viewSchedule.schedulePattern.length > 0 && this.editViewSchedule ? true :
           viewSchedule.schedulePattern && viewSchedule.schedulePattern.length == 0  ? true :
           !viewSchedule.schedulePattern ? true : false
    },
    AddNewPatternViewSchedule(data){
      //console.log(data)
      var type = data.patternType
      var length = type == 'weekly' ? 7 : type == 'biweekly' ? 14 : type == 'monthly' ? '30' : 1
      var arrayHold = []
      for(var i = 0; i < length; i++){
        var obj = {startTime: this.defaultTime, length: this.defaultHours }
        arrayHold.push(obj)
      }
      var pobj = {
        minInterations: null,
        maxInterations: null,
        interval: arrayHold,
        repeatType: 'PatternInterations',
        repeat: 1
      }
      this.viewSchedule.schedulePattern.push(pobj)

    },
    removePattern(index){
    var result = confirm('Are You Sure You Want to Remove Pattern ' + (index + 1) + '? This will update the schedule and can not be reversed.')
      if (result) {
      this.viewSchedule.schedulePattern.splice(index, 1)
      this.updateSchedule()
      }
    },
    scheduleDataReturnActive(){
      var data = _.cloneDeep(this.scheduleData)
      return _.orderBy(data.filter(p => p.active), ['scheduleName'], ['asc'])

    },
    scheduleDataReturnInactive(){
      var data = _.cloneDeep(this.scheduleData)
      return _.orderBy(data.filter(p => !p.active), ['scheduleName'], ['asc'])

    },
    getScheduleData(id){
      var obj = this.scheduleData.find(p => p.id == id)
      if(obj){
        this.viewSchedule = _.cloneDeep(obj)
      } return false
    },
    updateWorkDataViewSchedule(index, indexD, startTime){
      var pattern = _.cloneDeep(this.viewSchedule.schedulePattern)
      if(startTime){
        pattern[index].interval[indexD] = {startTime: null, length: null }
      } else {
        pattern[index].interval[indexD] = {startTime: this.defaultTime, length: this.defaultHours }
      }
      this.viewSchedule.schedulePattern = pattern
    },
    updateWorkData(index, indexD, startTime){
      var pattern = _.cloneDeep(this.newSchedulePattern)
      if(startTime){
        pattern[index].interval[indexD] = {startTime: null, length: null }
      } else {
        pattern[index].interval[indexD] = {startTime: this.defaultTime, length: this.defaultHours }
      }
      this.newSchedulePattern = pattern
    },
    currentDateSelect(data){
    if(this.currentView.start != data.start || this.currentView.end != data.end){
  //    console.log(data.start + ' ' + data.end)
      this.currentView = data
      if(this.userRosterProfileDays && this.userRosterProfileDays.length > 0){
        console.log('RUN SCHEDULE')
        this.$nextTick(function(){
            if(this.viewSchedule){
              this.viewScheduleTest()
            } else {
            this.buildTestSchedule()
            }
        })

      }
    // this.getUserProfile()
    }
  },
    SetCurrentMonth(data){
      this.currentMonth = data
    },
    goPrev () {
        let payload = moment(this.currentMonth).subtract(1, 'months').startOf('month');
        bus.$emit('CHANGE_MONTH_BUS',  {date: payload, view: 'Sample Schedule Pattern'});
    },
    goNext () {
        let payload = moment(this.currentMonth).add(1, 'months').startOf('month');
        bus.$emit('CHANGE_MONTH_BUS',  {date: payload, view: 'Sample Schedule Pattern'});
    },
    addNewPattern(){
      var type = this.newPatternType
      var length = type == 'weekly' ? 7 : type == 'biweekly' ? 14 : type == 'monthly' ? '30' : 1
      var arrayHold = []
      for(var i = 0; i < length; i++){
        var obj = {startTime: this.defaultTime, length: this.defaultHours }
        arrayHold.push(obj)
      }
      var pobj = {
        minInterations: null,
        maxInterations: null,
        interval: arrayHold,
        repeatType: 'PatternInterations',
        repeat: 1
      }
      this.newSchedulePattern.push(pobj)

    },
    dateFormat(date, type){
      if(type == 'day'){
        return moment(date, 'YYYY-MM-DD').format('ddd')
      }

    },
    enumerateDaysBetweenDates(startDate, endDate){
    //  console.log(startDate)
    //  console.log(endDate)
    var now = _.cloneDeep(startDate)
    var end = endDate
    var dates = []
    while (moment(now).isBefore(end)) {
        dates.push(now);
        now = moment(now, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    }
    return dates;
    },
    processDaysWithPattern(patterns, dates, logpat){
      var processed = []
      var pattern = _.cloneDeep(patterns)
      for(var i = 0; i < pattern.length; i++){
        var pat = pattern[i]
        if(pat.startTime != null){
          processed.push({date: dates[i], startTime: pat.startTime, length: pat.length, work: true, pat: logpat})
        } else {
         // processed.push({date: dates[i], pat: logpat})
        }
      }
      return processed
    },
    viewScheduleTest(){
      var schedule = {
        patternType: this.viewSchedule.patternType,
        schedulePattern: this.viewSchedule.schedulePattern
      }
      this.testScheduleView(schedule)
    },
    buildTestSchedule(){
      var schedule = {
        patternType: this.newPatternType,
        schedulePattern: this.newSchedulePattern
      }
      this.testScheduleView(schedule)
    },
    testScheduleView(schedule){
      var startDate = this.scheduleStartDate
      var start = moment(startDate, 'YYYY-MM-DD')
      var end = this.currentMonth.clone().endOf('month')
      if(this.scheduleEndDate && moment(this.scheduleEndDate).isBefore(end)){
        end =  moment(this.scheduleEndDate)
      }
      var pattern = schedule.patternType
      var momentDiff = pattern == 'weekly' ? 'week' : 'day'
      var momentEnd = pattern == 'weekly' ? 7 : 1
      var startOfMonth = this.currentMonth.clone().startOf('month')
      var startOfMonthClone = _.cloneDeep(startOfMonth)
      var patIterations = startOfMonthClone.clone().diff(start.clone(), 'day')
      var patternArray = schedule.schedulePattern
      var dayTotal = 0
      var patHold = []
      for(var i in patternArray){
        var patTotal = 0
        var length = patternArray[i].interval.length
        var repeat = patternArray[i].repeat
        patTotal = length * repeat
        patHold.push(patTotal)
        dayTotal = dayTotal + patTotal
      }
      console.log(patIterations)
      console.log(dayTotal)
      if(patIterations > (dayTotal * 2)){
              var daysFromStart = 0
              var patIntCalc = _.cloneDeep(patIterations)
              while(Math.floor((patIntCalc/dayTotal)) != (patIntCalc/dayTotal)){
                  daysFromStart++;
                  patIntCalc = patIntCalc - 1
              }
              console.log(daysFromStart)
                  var newStart = this.currentMonth.clone().subtract(daysFromStart, 'day')
                  var interations = end.diff(newStart, momentDiff, true)
                  var pattern = 0 // patternStart +1
                  var totalIterations = 0
                  var startHold = newStart //startOfMonth
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
              } else {
                  var interations = end.diff(start, momentDiff, true)
                  var pattern = 0
                  var totalIterations = 0
                  var startHold = start
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
      }
      if(true){
        var patternBuild = []
        var vm = this
        function doTheLoop() {
        for(var i = 0; totalIterations < interations; i++){
            var currentP = patternArray[pattern]
            if(!currentP){
              pattern = 0
              currentP = patternArray[pattern]
            }
            var lastDate = startHold.clone().add(momentEnd, 'days')
            if(vm.scheduleEndDate && moment(vm.scheduleEndDate).isBefore(lastDate)){
                 lastDate =  moment(vm.scheduleEndDate).add(1, 'day')
             }
            if (currentP.repeatType == "PatternInterations") {
                  if(currentP.repeat > repeatPat){
                    repeatPat = repeatPat + 1
                    var daysList = vm.enumerateDaysBetweenDates(startHold.format('YYYY-MM-DD'), lastDate.format('YYYY-MM-DD'))
                    var processDays = vm.processDaysWithPattern(currentP.interval, daysList, pattern)
                    patternBuild = patternBuild.concat(processDays)
                    totalIterations = totalIterations + 1
                    startHold = lastDate.clone()
                    if(vm.scheduleEndDate && startHold.isSameOrAfter(moment(vm.scheduleEndDate))){
                      console.log('END MADNESS')
                      console.log(startHold)
                      console.log(moment(vm.scheduleEndDate))
                       return (false)
                       totalIterations = interations
                    }
                  }  else {
                 //    console.log('Repeat TRIGGERED')
                      repeatPat = 0
                      pattern = pattern + 1
                  }
            } else {
              pattern = pattern + 1
              totalIterations = totalIterations + 1
            }
            if(totalIterations < interations){
              return (true)
            } else {
              return (false)
            }

        }
      }
      while (doTheLoop()) {}
      //console.log(patternBuild)
    }
    this.dates = patternBuild.filter(p => p.date)
    console.log(this.dates)
    this.userProfileBuildShifts(this.dates)
    },
    userProfileBuildShifts(data){
    var events = []
    for(var i in data){
          var index = data.indexOf([i])
          var eventDate = data[i].date + 'T' + data[i].startTime
          var startTime = moment(data[i].startTime).toISOString()
          var endTime = moment(data[i].startTime).add(data[i].length, 'hours').toISOString()
          var holdcolor = 'default'
          var obj = {}
          obj['id'] = 'test-' + index,
          obj['title'] = 'Schedule Assignment'
          obj['description'] = [{id: 'test-2-' + index, eventDate: eventDate, eventDescription: 'Roster Assignment', eventTitleDescription: 'OnDuty (' + data[i].length + 'hrs)'}]
          obj['color'] = 'text-' + holdcolor
          obj['date'] = data[i].date
          obj['startTime'] = startTime
          obj['endTime'] = endTime
        events.push(obj)
    }
        this.userRosterProfileDays = events
        return
    },
    postNewSchedule(){
      var obj = {
        scheduleName: this.newScheduleName,
        schedulePattern: this.newSchedulePattern,
        patternType: this.newPatternType,
        scheduleStartDate: this.scheduleStartDate,
        scheduleEndDate: this.scheduleEndDate
      }
    },
    triggerInfo(){
      var startDate = this.startDate
      var schedule = this.schobj
      var start = moment(startDate, 'YYYY-MM-DD')
      var end = moment().add('11', 'month')
      var pattern = schedule.patternType
      if(pattern == 'weekly'){
        var interations = end.diff(start, 'week', true)
        var patternArray = schedule.schedulePattern
        var pattern = 0
        var totalIterations = 0
        var startHold = start
        var lastDate = ''
        var minInterations = 0
        var maxInterations = 0
        var movePattern = null
        var repeatPat = 0
        var patternBuild = []
        var vm = this
        function doTheLoop() {
        for(var i = 0; totalIterations < interations; i++){
            var currentP = patternArray[pattern]
            if(!currentP){
              pattern = 0
              currentP = patternArray[pattern]
            }
            console.log(currentP.pattern)
            var lastDate = startHold.clone().add(7, 'days')
            if(currentP.repeatType == 'SwitchTrigger'){
              if(currentP.repeat == 'firstOfTheMonth'){
                var firstOfTheMonth = lastDate.clone().startOf('month')
               // console.log(repeatPat)
                if(firstOfTheMonth.isSameOrAfter(startHold.clone()) && movePattern != pattern && minInterations >= currentP.minInterations){
                //  console.log('TRIGGERED')
               //   console.log(firstOfTheMonth.clone().format('MM/DD/YYYY'))
                  minInterations = 0
                  maxInterations = 0
                  movePattern = pattern
                  pattern = pattern + 1
                } else if(maxInterations ? maxInterations >= currentP.maxInterations : false) {
                  minInterations = 0
                  maxInterations = 0
                  pattern = pattern + 1
                  } else {
                //  console.log(currentP.pattern)
                //  console.log('CONTINUE PATTERN')
                  movePattern = null
                  var daysList = vm.enumerateDaysBetweenDates(startHold.format('YYYY-MM-DD'), lastDate.format('YYYY-MM-DD'))
                  var processDays = vm.processDaysWithPattern(currentP.interval, daysList, pattern)
                //  console.log('DAYS LIST')
                //  console.log(daysList)
                //  console.log(currentP.interval)
                //  console.log(processDays)
                  patternBuild = patternBuild.concat(processDays)
                  minInterations = minInterations + 1
                  maxInterations = maxInterations + 1
                  totalIterations = totalIterations + 1
                  startHold = lastDate.clone()
                }
              } else if(currentP.repeat == 'WeekBeforeEndOfTheMonth'){
                var WeekBeforeEndOfTheMonth = lastDate.clone().endOf('month').subtract(6, 'days')
                var lastWeekFull = lastDate.clone().subtract(1, 'day').endOf('day').isSame(lastDate.clone().subtract(1, 'day').endOf('month'))
              // console.log('PATTERN START')
              //  console.log(startHold.clone().format('MM/DD/YY'))
              //  console.log(lastDate.clone().format('MM/DD/YY'))
              //  console.log(lastDate.clone().subtract(1, 'day').endOf('day'))
              //  console.log(lastDate.clone().subtract(1, 'day').endOf('month'))
              //  console.log(lastWeekFull)
              // console.log(repeatPat)
                if(((WeekBeforeEndOfTheMonth.isSameOrAfter(startHold.clone()) && WeekBeforeEndOfTheMonth.isSameOrBefore(lastDate.clone())) || lastWeekFull) && movePattern != pattern && (minInterations ?  minInterations >= currentP.minInterations : true)){
              //  console.log('TRIGGERED')
              //   console.log(firstOfTheMonth.clone().format('MM/DD/YYYY'))
                  movePattern = pattern
                  minInterations = 0
                  maxInterations = 0
                  pattern = pattern + 1
                } else if( maxInterations ? maxInterations >= currentP.maxInterations : false) {
                  minInterations = 0
                  maxInterations = 0
                  pattern = pattern + 1
                  } else {
                //  console.log(currentP.pattern)
                //  console.log('CONTINUE PATTERN')
                  
                  movePattern = null
                  var daysList = vm.enumerateDaysBetweenDates(startHold.clone().format('YYYY-MM-DD'), lastDate.clone().format('YYYY-MM-DD'))
                  console.log(daysList)
                  var processDays = vm.processDaysWithPattern(currentP.interval, daysList, pattern)
                //  console.log('DAYS LIST')
                //  console.log(daysList)
                //  console.log(currentP.interval)
                //  console.log(processDays)
                  patternBuild = patternBuild.concat(processDays)
                  minInterations = minInterations + 1
                  maxInterations = maxInterations + 1
                  totalIterations = totalIterations + 1
                  startHold = lastDate.clone()
                }
              }
            } else if (currentP.repeatType == "PatternInterations") {
                  if(currentP.repeat > repeatPat){
                  //   console.log(currentP.pattern)
                  //  console.log('Repeat Continue')
                    repeatPat = repeatPat + 1
                    var daysList = vm.enumerateDaysBetweenDates(startHold.format('YYYY-MM-DD'), lastDate.format('YYYY-MM-DD'))
                    var processDays = vm.processDaysWithPattern(currentP.interval, daysList, pattern)
                  //  console.log(daysList)
                  //  console.log(currentP.interval)
                  //  console.log(processDays)
                    patternBuild = patternBuild.concat(processDays)
                    totalIterations = totalIterations + 1
                    startHold = lastDate.clone()
                  }  else {
                     console.log('Repeat TRIGGERED')
                      repeatPat = 0
                      pattern = pattern + 1
                      totalIterations = totalIterations + 1
                  }
            } else {
           //   console.log('FAILED TO CAPTURE')
              pattern = pattern + 1
              totalIterations = totalIterations + 1
            }

            // after success //
            if(totalIterations < interations){
              return (true)
            } else {
              return (false)
            }

        }
      }
      while (doTheLoop()) {}
      console.log(patternBuild)
    }
    this.dates = patternBuild
    },
    deleteSchedule(){
    axios({
     method: 'delete',
     url: '/api/v1/staffing/schedule/' +  this.viewSchedule.id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.viewSchedule = false
     this.editViewSchedule = false
     bus.$emit('getSchedule')
   })
    },
  updateSchedule(){
    var data = {
      schedulePattern: this.viewSchedule.schedulePattern,
      scheduleName: this.viewSchedule.scheduleName,
      active: this.viewSchedule.active
    }
    axios({
     method: 'patch',
     url: '/api/v1/staffing/schedule/' + this.viewSchedule.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
    // this.viewSchedule = false
     this.editViewSchedule = false
     bus.$emit('getSchedule')
     console.log(response.data)
    })
    },
    newSchedule(){
    var data = {
      scheduleName: this.newScheduleName,
      schedulePattern: this.newSchedulePattern,
      patternType: this.newPatternType
    }
    axios({
     method: 'post',
     url: '/api/v1/staffing/schedule/',
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.addNew = false
     this.newScheduleName = ''
     this.newSchedulePattern = []
     this.newPatternType = 'weekly'
     bus.$emit('getSchedule')
     console.log(response.data)
    })
    }

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.days-grid {
  /* 7 equal columns for weekdays and days cells */
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.days-grid {
  height: 100%;
  position: relative;
  /* Show border between the days */
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  border-top: solid 1px var(--grey-200);
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
