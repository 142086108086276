<template>
<div>
  <b-container class="px-0 pt-3" fluid>
    <b-textarea v-model="businessJson" @change="fixBusinessData" />
    <b-btn variant="success" v-if="businessDataForUpload.length > 0" @click="submitBusinessBulk">Submit Bulk</b-btn>
      </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { bus } from "../../main";
import axios from "axios";
import moment from "moment";
import PapaParse from 'papaparse';
export default {
  props: ["formGroups", "formTableStyles", "submittedBusiness"],
  components: {

  },
  data() {
    return {
      ppObj: {},
      holdValues: {},
      cadData: [],
      businessJson: '',
      businessDataForUpload: [],
      missingData: [],
    };
  },
  created() {
    this.buildppObjArray(this.formGroups)
  },
  mounted() {
   
  },
  computed: {
    ...mapState([
      "getSessionData",
      "stationsData",
      "apparatusData",
      "userData",
      "shiftsData",
      "preplanStatus",
      "siteconfig"
    ]),
    businessParentId(){
      var array = _.cloneDeep(this.submittedBusiness)
      var newArray = []
      for(var i in array){
          var obj = {}
          obj['id'] = array[i].id
          var business = array[i].businessTableData
          var name = business.find(p => p.key == 'businessName')
          if(name && name.value){
            obj['name'] = name.value.replace(/ /g, '').toLowerCase()
            newArray.push(obj)
          } else {
            console.log('Missing Name')
            console.log(array[i])
          }
      }
      return newArray
    },

  },
  watch: {

  },
  beforeDestroy() {},
  methods: {
    buildppObjArray(groups) {
      var array = [];
      for (var i in groups) {
        var field = groups[i].fields;
        for (var p in field) {
          var obj = { value: "", type: field[p].type };
           if(field[p].default){
            obj.value = field[p].default
          }
          this.holdValues[field[p].vmodel] = obj;
          var newObj = {
            vmodel: field[p].vmodel,
            idtag: field[p].idtag,
            value: "",
            label: field[p].label
          };
          if(field[p].default){
            newObj.value = field[p].default
          }
          if (field[p].type == "reference") {
            newObj["refItem"] = field[p].refitem;
          }
          if (field[p].searchIndex == true) {
            newObj["searchIndex"] = true;
          }
          if(field[p].cadOpt == true){
            newObj["cadOpt"] = true
          }
          if (field[p].type == "taxonomySingleSelect") {
            newObj["taxItem"] = field[p].taxitem;
          }
          if (field[p].cad == true) {
            this.cadData.push({
              cadValue: field[p].cadValue,
              vmodel: field[p].vmodel
            });
          }
          array.push(newObj);
        }
      }
      this.ppObj["business_data"] = array;
      this.ppObj['business_building_sketch'] =  ''
      this.ppObj['business_picture_array'] = []
      this.ppObj['business_building_data'] = null

   },
   fixBusinessData(data){
        var data = this.businessJson
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.fixDataApply(results.data)
            }
        })
   },
   fixDataApply(data){
     var parentLoadFirst = false
     if(parentLoadFirst){
       var holddata = data.filter(p => {
          if(p.parent != ''){
            return p
          }
       })
     } else {
       var holddata = data.filter(p => {
         if(p.parent_name != ''){
           return p
         }
       })
     }
     var array = holddata.map(item => {
        var obj = this.buildBussinessObject(item, parentLoadFirst)
        return obj
     })
     console.log(array)
     console.log(this.missingData)
     this.businessDataForUpload = array
   },
   buildBussinessObject(data, parentboo){
      var dataObj = _.cloneDeep(this.ppObj)
      dataObj = this.findObjValues(dataObj, data)
      var holdObjTableData = this.setupTableValues(this.formTableStyles, dataObj)
      dataObj['business_table_data'] = holdObjTableData
      dataObj['business_author'] = this.userData.firstName + ' ' + this.userData.lastName
      dataObj['business_author_id'] = this.userData.id
      if(parentboo){
      dataObj['business_parent'] = null
      } else {
        dataObj['business_parent'] = this.getParentId(data.parent_name)
      }
      return dataObj
    },
    getParentId(data){
      if(data){
      var parents = _.cloneDeep(this.businessParentId)
      var obj = parents.find(p => p.name == data.replace(/ /g, '').toLowerCase())
      if(obj && obj.id){
        return obj.id
      } else {
        this.missingData.push(data)
        console.log('Missing Parent')        
        console.log(data)
        console.log(this.businessParentId)
        return null
      }
      }
    },
    findObjValues(base, data){
      var obj = base
      var objdata = obj.business_data
      var newdata = []
      for(var i in objdata){
          var datahold = objdata[i]
          if(data[datahold.vmodel]){
            datahold.value = data[datahold.vmodel]
            newdata.push(datahold)
          } else {
            newdata.push(datahold)
          }
      }
      obj.business_data = newdata
      return obj
    },
    getIndexValues(data) {
      var obj = {};
      var fieldSort = data;
      for (var i in fieldSort) {
        if (fieldSort[i].searchIndex == true) {
          var key = fieldSort[i].vmodel;
          var value = fieldSort[i].value;
          if (value != undefined && value != null && value != "") {
            if (value == undefined) {
              value = "Not Entered";
            }
            if (fieldSort[i].type == "computed") {
              value = this.getComputedValues(fieldSort[i]);
            }
            if (fieldSort[i].taxItem) {
              var refval = value[fieldSort[i].taxItem];
              obj[key] = refval;
            
            } else {
              obj[key] = value;
              
            }
          }
        }
      }
      return obj;
    },
    getTableItems(display, fields) {
      var fieldSort = fields.filter(p => p.order == display);
      var obj = {};
      for (var i in fieldSort) {
        var key = fieldSort[i].label;
        var value = this.holdValues[fieldSort[i].vmodel].value;
        if (value == undefined) {
          value = "Not Entered";
        }
        if (fieldSort[i].type == "computed") {
          value = this.getComputedValues(fieldSort[i]);
        }
        if (fieldSort[i].type == "taxonomySingleSelect") {
          value = this.holdValues[fieldSort[i].vmodel].value[
            fieldSort[i].taxitem
          ];
        }
        if (fieldSort[i].type == "reference") {
          value = this.holdValues[fieldSort[i].reference].value[
            fieldSort[i].refitem
          ];
        }
        if (false && fieldSort[i].displayType && fieldSort[i].displayType == 'phone' && value){
        obj[key] = "<a href='tel:" + value + "'>" + value + "</a>" 
        } else {
        obj[key] = value
        }
      }
      var arr = [];
      arr.push(obj);
      return arr;
    },
    getComputedValues(data) {
      var array = [];
      for (var i in data.ref) {
        var hold = this.holdValues[data.ref[i]].value;
        if (hold != undefined && hold != null && hold != "") {
          array.push(hold);
        }
      }
      var operator = function(a, b) {
        return a * b;
      };
      var holdValue = 0;
      for (var i = 0; i < array.length; i++) {
        if (i == 0) {
          var item = operator(array[0], array[1]);

          holdValue = item;
        } else if (i > 1) {
          var item = operator(holdValue, array[i]);
          holdValue = item
        }
      }
      return holdValue;
    },
    submitBusinessBulk() {
      axios({
        method: "post",
        url: "/api/v1/preplan/business/bulk",
        data: this.businessDataForUpload,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.businessDataForUpload = []
        this.businessJson = ''
        this.$emit('savedBusinessDataEmit')
      });
    },
    setupTableValues(table, data){
      var array = []
      for(var p in table){
        var key = table[p].key
        var values = table[p].values
        var label = table[p].label
        var holdValue = this.getValueForItem(data, values)
        array.push({key: key, value: holdValue, label: label})
      }
      return array
    },
    getValueForItem(item, values){
      var data = item.business_data
      var arrayString = ''
      for(var i in values){
        var dataobj = data.find(p => p.vmodel == values[i].vmodel) 
        if(dataobj && dataobj.taxItem){
          var arrayString = arrayString + ' ' + dataobj.value[dataobj.taxItem]
        } else {
          var end = dataobj && dataobj.value ? dataobj.value : ''
          if(end && values[i].prefix){
            end = values[i].prefix + end
          }
          if(values[i].postfix){
            end = end + values[i].postfix
          }
          if(end){
          var arrayString = arrayString + ' ' + end
          }
        }
      }
      return arrayString
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.overflowData {
  overflow: hidden;
    position: fixed;
    z-index: 900;
}
.fullScreenSetup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8000;
}
.maxWidthContainer {
  max-width: 500px;
}
.invalidState {
  border: 1px solid red;
  border-radius: 5px;
}
.validState {
  border: 1px solid green;
  border-radius: 5px;
}
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.textAreaDescription {
  margin-top: 10px;
}
.iconAddMapp {
  width: 50px;
}
span {
  margin-bottom: 10px;
}
.multiselect {
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
  border: 1px solid #bbb;
}
#tablePassDown {
  margin: 0;
  padding: 0;
}
.sub-section-heading {
  color: White;
  background: #aaa;
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0;
  margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
  padding: 5px;
}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;
}
.addNewArticle:hover {
  background: grey;
}
.timeMobile {
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk {
    display: none;
  }
  .timeMobile {
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}
</style>
