<template>
<div>
  <b-card header-tag="header" footer-tab header-bg-variant="primary" no-body>
    <div slot="header">
      <span class="text-light">
        <h4>{{ trainingModule.trainingModuleName }}</h4>
        <em v-if="trainingModule.trainingModuleType"> Training Type: {{ trainingModule.trainingModuleType.toUpperCase() }}</em>
        </span>
    </div>

      <b-card no-body>
        <b-tabs card>
          <b-tab title="Training Plan">
          <b-btn size="sm" class="ml-2" v-if="trainingModule.trainingModuleFormData.length > 1" v-for="btn in trainingModule.trainingModuleFormData" :disabled="showAll" :variant="showGroup == btn.id ? btn.groupVariant : 'outline-' + btn.groupVariant" @click="showGroup = btn.id" :key="btn.id">{{ btn.btnName }}</b-btn>
            <b-form-checkbox v-if="trainingModule.trainingModuleFormData.length > 1" 
              v-model="showAll" :value="true" :unchecked-value="false" @change="showAll && !showGroup && trainingModule.trainingModuleFormData[0] ? showGroup = trainingModule.trainingModuleFormData[0].id : ''">View All</b-form-checkbox>
              <b-card v-for="(group, indexg) in trainingModule.trainingModuleFormData" :key="indexg"
                      :border-variant="group.groupVariant"
                       v-show="showAll == true ? true : showAll == false && showGroup == group.id ? true : false"
                       v-if="group.groupVisibility ? getFieldVisibility(group.groupVisibility) : true"
                       header-tag="header"
                      :header-bg-variant="group.groupVariant"
                      :header-text-variant="group.groupVariant == 'warning' || group.groupVariant == 'default' ? 'dark' : 'white'">
                  <div slot="header"><span>{{ group.groupName }}</span>
                  </div>
                  <formfields :group="group" :vmodelHoldDefault="vmodelHold" :formModules="trainingModule" @editField="editModalField($event, group.id)"  @updateObjectValue="updateFormDataHold" @errorsPresent="errorsPresent" :submissionView="true" />

                                          
                      </b-card>
            </b-tab>
            <b-tab :title="'Tagged for Completion(' + completionUsersForSubmssion.length + ')'"  >
              {{ checkForAddUsersPerm }}
              <b-btn v-if="!checkForAddUsersPerm" :disabled="checkForAddUsersPerm">Add Users for Sign Off</b-btn>
             <b-container fluid>
                            <b-btn v-b-toggle.collapse1 variant="dark" block>Show Filter (Filtered: {{ setGroupUsersForTraining.length }})</b-btn>
                              <b-collapse id="collapse1" class="mt-2">
                                <b-card>
                                <userSelector @groupedUsers="setGroupUsers($event)" />
                                </b-card>
                          </b-collapse>
                          <b-row><b-col>
                          <b-form-input v-model="searchData" placeholder="Search by User Name" /></b-col><b-col>
                          <b-checkbox :check-value="true" :uncheck-value="false" @change="updateAllSelectedUsers($event)">Modify All (Filtered: {{ setGroupUsersForTraining.length }})</b-checkbox>
                          <b-checkbox v-model="showOnlyTagged" >Tagged Only</b-checkbox>
                          </b-col>
                          </b-row>
                          <b-table :fields="['firstName', 'lastName', 'employeeId', 'deptRank', {key: 'addCredential', label: 'Tag'}]" :items="setGroupUsersForTrainingFilter" :current-page="currentPage" :per-page="10" >
                            <template slot="addCredential" slot-scope="data">
                              <b-checkbox v-model="data.item.addCredential" :check-value="true" :uncheck-value="false" @input="updateSelectedUsers" />
                              </template>
                          </b-table>
                          <b-pagination align="center" size="md" :total-rows="setGroupUsersForTrainingFilter.length" v-model="currentPage" :per-page="10"></b-pagination>
                        </b-container>
             

            </b-tab>
          </b-tabs>

           <b-container class="pr-5">
             <b-row><b-col align="right"></b-col>
           <b-col v-if="trainingModule.trainingModuleCredential" cols="*" align="right" class="pl-0 ml-0">Credential Application: 
      <b-badge variant="danger" class="mr-1" v-for="(cred, index) in trainingModule.trainingModuleCredential" :key="index">{{ getCredentialName(cred).credentialRequirementName }}</b-badge>
            </b-col>
            </b-row>
        </b-container>
        </b-card>
       
        <div slot="footer">
          <b-container>
          <b-row>
             <b-col lg="4" md="12">
               <b-row align-h="center">
                 <b-col lg="6" md="12" class="pt-2">Training Hours</b-col><b-col lg="6" md="6"><b-form-input :disabled="trainingCompletionHoursDisabled" type="number" v-model="trainingCompletionHours" /></b-col></b-row>
             </b-col>
             <b-col lg="8" md="12"  v-if="!checkForSubmissionPerm || trainingCompletionConfirmVal">
               <b-row  align-h="center">
                 <b-col lg="4" md="12" class="pt-2">{{ !formErrorsPresent ? 'Completion Date' : 'Progress Date'}}</b-col>
                <b-col lg="4" md="6"><b-form-input type="date" v-model="trainingCompletionDate" /></b-col>
                <b-col lg="4" md="6"><b-form-input type="time" v-model="trainingCompletionTime" /></b-col>
                </b-row>
                </b-col>
            </b-row>
           <b-row v-if="validationRequired">
            <b-col>
 <b-checkbox v-if="validationRequired" class="mt-2" v-model="trainingCompletionConfirmVal" :check-value="true" :uncheck-value="false">I affirm the training above was completed per directive on the date listed and is ready to be validated</b-checkbox>      
            </b-col>
            <b-col>
         <multiselect  :disabled="!trainingCompletionConfirmVal" v-model="valdiationUser" :options="deptContactDataFilter" label="lastName" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Validation User">
                    <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                    </template>
                  </multiselect>
              </b-col>
            <b-col cols="*" align="right">
              <b-btn class="mt-1" size="sm" variant="success" 
              v-if="!formErrorsPresent"
              :disabled="!trainingCompletionConfirmVal && !valdiationUser"
              @click="submitTraining(false, valdiationUser)"
              >Submit</b-btn>
              <b-btn v-if="!trainingCompletionConfirmVal" class="mt-1" size="sm" variant="primary"  @click="submitTraining(false)">Save</b-btn>
              <b-btn v-if="userData.role == 'admin' && false" @click="submitTrainingBulk(3)">Bulk</b-btn>
            </b-col>
          </b-row>
          <b-row v-if="!checkForSubmissionPerm">
            <b-col>
            <b-checkbox v-if="!formErrorsPresent" class="mt-2" v-model="trainingCompletionConfirm" :check-value="true" :uncheck-value="false">I affirm the training above was completed per directive on the date listed</b-checkbox>
            </b-col>
            <b-col cols="*" align="right">
              <b-btn class="mt-1" size="sm" variant="success" 
              v-if="!formErrorsPresent"
              :disabled="completionUsers.length > 0 && trainingCompletionDate && trainingCompletionTime && trainingCompletionConfirm ? false : true"
              @click="submitTraining(true)"
              >Submit</b-btn>
              <b-btn v-if="formErrorsPresent || !trainingCompletionConfirm" class="mt-1" size="sm" variant="primary"  @click="submitTraining(false)">Save</b-btn>
              <b-btn v-if="userData.role == 'admin' && false" @click="submitTrainingBulk(3)">Bulk</b-btn>
            </b-col>
          </b-row>
          </b-container>
        </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios'
import multiselect from 'vue-multiselect'
import moment from 'moment'
import TutData from '../tracking/deptTutorialsdata.vue'
import userSelector from './../inputfields/userfilter.vue'
import formfields from '../admin/training/trainingModule/trainingModuleFormBuilderFields.vue'
export default {
  props:["trainingModule", "credentialRequirement", "completionUsersAdd", "trainingPage"], 
components:{
      'tutdata' : TutData,
      userSelector,
      formfields,
      multiselect
},
  data () {
    return {
      showAll: true,
      showGroup: '',
      searchData: '',
      currentPage: 1,
      showTutVideo: false,
      completionUsersUpdated: true,
      trainingCompletionHours: '',
      trainingCompletionHoursDisabled: false,
      trainingCompletionDate: '',
      trainingCompletionTime: '',
      trainingCompletionConfirm: false,
      trainingCompletionConfirmVal: false,
      valdiationUser: null,
      completionUsers: [],
      vmodelHold: {},
      formValues: {},
      setGroupUsersForTraining: [],
      showOnlyTagged: false,
      completionUsersForSubmssion: [],
      formErrorsPresent: false,
      validationRequired: true,
      validationEmail: false,
    }
  },
  created(){
   this.buildFormData(this.trainingModule)
   if(this.trainingPage){
     this.showOnlyTagged = true
   }
  },
  mounted(){
      this.trainingCompletionDate = moment().format('YYYY-MM-DD')
      this.trainingCompletionTime = moment().format('HH:mm')
      var hours = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'hours')
      if(hours){
        this.trainingCompletionHours = hours.perms
      }
      var disHours = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'hourstype')
      if(disHours){
        if(disHours.perms == 'fixed'){
          this.trainingCompletionHoursDisabled = true
        }
      }
      
  },
  computed:{
    ...mapState([
          'getSessionData',
          'userData',
          'deptContactData'
        ]),
        deptContactDataFilter(){
         var validation = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'validation')
         if(validation && validation.perms){
           validation = validation.perms
           this.validationEmail = validation.email
           if(validation.type == 'role'){
           //  console.log(validation.role)
             return this.deptContactData.filter(p => {
                var roleI = validation.role.indexOf(p.role)
                  if(roleI != -1){
                    return p
                  } 
                })
             } else if (validation.type == 'user'){
               return validation.user
             } else {
               return this.deptContactData
             }
           
         } else {
          return this.deptContactData
         }
        },
        checkForAddUsersPerm(){
          if(this.trainingModule.trainingModuleSubmissionVisibility){
            var role = this.userData.role
            var self = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'others')
            if(self){
              var index = self.perms.indexOf(role)

              if(index != -1){
                return false
              } else {
                  return true
                }
                
            
            } else {
              return true
            }
          } else {
              return true
          }
        },
        checkForSubmissionPerm(){
          if(this.trainingModule.trainingModuleSubmissionVisibility){
            var role = this.userData.role
            var self = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'self')
            var validation = this.trainingModule.trainingModuleSubmissionVisibility.find(p => p.permtype == 'validation')
            if(validation && validation.perms){
              validation = validation.perms
            }
            if(self){
              var index = self.perms.indexOf(role)
              if(index != -1 || this.userData.role == 'admin'){
                if(this.completionUsers.indexOf(this.userData.id) == -1 && this.trainingModule.trainingModuleType != 'scheduled'){
                 this.completionUsers.push(this.userData.id)
                 this.updateSelectedUsers()
                }
                if(validation && validation.boo == false){
                 // console.log('No Validation')
                     this.validationRequired = false 
                     return false
                     } else {
                    this.validationRequired = true
                    if(validation && validation.type == 'role'){
                      var indexR = validation.role.indexOf(role)
                      if(index != -1){
                        console.log('Validation Required')
                         this.validationRequired = true
                        return true
                  
                      } else {
                        this.validationRequired = false
                        return false
                      }
                    } else if(validation && validation.type == 'user'){
                      var userf = validation.user.find(p => p.id == this.userData.id)
                        if(userF){
                           this.validationRequired = false
                          return false
                        } else {
                          return true
                        }
                      } else {
                        return true
                      }
                    }
                  
              } else {
                return true
              }
            } else {
              return false
            }
          } else {
              return false
          }
         
        },
        deptContactDataCheck(){
         var comp = this.completionUsers
         var data = this.deptContactData
         data.forEach(item => item['addCredential'] = false)
         if(comp){
           var userIds = data.map(p => p.id)
           for(var i in comp){
             var index = userIds.indexOf(comp[i])
             if(index != -1){
               data[index].addCredential = true
             }
           }
         }
         return data
       },
         setGroupUsersForTrainingFilter(){
         var array = this.setGroupUsersForTraining.filter(p => {
           return p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase())
         })
         if(this.showOnlyTagged){
           array = array.filter(p => {
             return p.addCredential == true
           })
         }
         return _.orderBy(array, ['lastName', 'firstName'], ['asc', 'asc'])
       },
       formatedCompletionTime(){
        var timeString = this.trainingCompletionDate + 'T' + this.trainingCompletionTime + ':00'
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
       },
  },
  watch: {
    completionUsersAdd(newData){
      this.addDataToCompletionUsers(newData)
    },
  },
  beforeDestroy(){
    
  },
  methods:{
      addDataToCompletionUsers(data){
        var array = this.completionUsers
        var filter = data.filter(p => {
          return array.indexOf(p.id) == -1
        })
        for(var f in filter){
          this.completionUsers.push(filter[f].id)
        }
        this.updateSelectedUsers()
      },
      errorsPresent(data){
        this.formErrorsPresent = data
        if(data == true){
          this.trainingCompletionConfirm = false
        }
      },
      getCredentialName(id){
          if(id && this.credentialRequirement){
          var obj = this.credentialRequirement.find(p => p.id == id)
          if(obj){
            return obj
          }
          else {
            return { credentialRequirementName: 'Unavailable'}
          }
          } else {
            return ''
          }
        },
            getVideoId(data){
       data.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
       if (RegExp.$3.indexOf('youtu') > -1) {
        return 'https://www.youtube.com/embed/' + RegExp.$6
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + RegExp.$6
        }
    },
    setGroupUsers(data){
      var users = this.deptContactDataCheck
      var array = users.filter(p => {
           var index = data.indexOf(p.id)
             if(index != -1){
               return p
             }
         })
      this.setGroupUsersForTraining = array
    },
    updateSelectedUsers(){
      this.completionUsersUpdated = false
      this.$nextTick(function(){
        this.completionUsersUpdated = true
        var array = this.deptContactDataCheck.filter(p => {
            return p.addCredential == true })
        this.completionUsersForSubmssion = array
      })
       },
    updateAllSelectedUsers(data, objKey){
         var array = this.setGroupUsersForTraining
         array.forEach(item => item.addCredential = data)
         this.setGroupUsersForTraining = array
         this.updateSelectedUsers()
    },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
         updateFormDataHold(data){
      //     console.log(data)
     //      console.log(this.formValues)
        var objHold = data.objKey
        delete data.objKey
        this.formValues[objHold].value = data
    },
    buildFormData(newData){
      var data = newData.trainingModuleFormData
      var obj = {}
      var vmodelHold = {}
      for(var i in data){
        var fields = data[i].groupFields

        for(var f in fields){
          if(fields[f].itemType == 'form'){
          var value = ''
          if(fields[f].type == 'select'){
            var obj = fields[f].selectOptions.find(p => p.default == true)
            if(obj){
              value = obj.value
                vmodelHold[fields[f].objKey] = value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            }
          }
           else if(fields[f].type == 'checkbox'){
            var obj = fields[f].selectOptions.filter(p => p.default == true)
            if(obj){
              value = obj.map(p => p.value)
                vmodelHold[fields[f].objKey] = value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            }
          }
           else if(fields[f].type == 'boolean'){
                value = fields[f].value
                vmodelHold[fields[f].objKey] = value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
            
          }
          else if(fields[f].type == 'upload'){
            value = []
            vmodelHold[fields[f].objKey] = value
              this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
          }
          else if(fields[f].type == 'selectmulti'){
            var obj = fields[f].selectOptions.filter(p => p.default == true)
            if(obj){
              value = obj
                var array = []
                var arrayStore = []
                for(var v in value){
                    array.push(value[v].value)
                } 
                for(var v in value){
                  arrayStore.push(value[v].text)
                }
                vmodelHold[fields[f].objKey] = value
                this.formValues[fields[f].objKey] = {groupid: data[i].id, value: arrayStore, searchvalue: array}
            } else {
              value = []
              vmodelHold[fields[f].objKey] = value
              this.formValues[fields[f].objKey] = {groupid: data[i].id, value: arrayStore, searchvalue: array}
            }
          }
          else if(fields[f].type == 'date' || fields[f].type == 'time' || fields[f].type == 'datetime'){
            value = {'date': '', 'time': ''}
            vmodelHold[fields[f].objKey] = value
             this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
          } else {
            vmodelHold[fields[f].objKey] = value
             this.formValues[fields[f].objKey] = {groupid: data[i].id, value: value}
          }
        }
        }
      }

      this.vmodelHold = vmodelHold

    },
    submitTrainingBulk(repeat){
     // console.log(repeat)
    for(var d = 0; d < repeat; d++){
      var date = moment(this.formatedCompletionTime).add(d, 'day')
      var users = this.deptContactData
      for(var u = 0; u < users.length; u++){
        
    axios({
    method: 'Post',
    url: '/api/v1/training/trainingrecord',
    data: {
      "trainingRecordCompletionDate": date, 
      "trainingRecordCredentials": this.trainingModule.trainingModuleCredential, 
      "trainingRecordModuleId": this.trainingModule.id, 
      "trainingRecordModuleName" : this.trainingModule.trainingModuleName, 
      "trainingRecordFormData": this.formValues, 
      "trainingRecordUsers": [users[u].id], 
      "trainingRecordComplete": true,
      "trainingRecordAuthor": this.userData.firstName + ' ' + this.userData.lastName, 
      "trainingRecordAuthorId": this.userData.id,
      "trainingRecordHours": this.trainingCompletionHours
    },
    headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
  }).then( response => {
      this.$nextTick(function(){
   //     console.log(response.data)
      })
      })
      }
    }
    }, 
    submitTraining(complete, validation){
      var users = this.completionUsersForSubmssion.map(p => p.id)
      if(validation){
        var url = '/api/v1/training/trainingrecord/validation'
        var form = this.formValues
        form['validation'] = {user: validation, email: this.validationEmail}
        var valUserId = validation.id
        } else {
          var url = '/api/v1/training/trainingrecord'
           var form = this.formValues
           var valUserId = null
        }
        axios({
          method: 'Post',
          url: url,
          data: {
            "trainingRecordCompletionDate": this.formatedCompletionTime, 
            "trainingRecordCredentials": this.trainingModule.trainingModuleCredential, 
            "trainingRecordModuleId": this.trainingModule.id, 
            "trainingRecordModuleName" : this.trainingModule.trainingModuleName, 
            "trainingRecordFormData": form, 
            "trainingRecordValidationId": valUserId,
            "trainingRecordUsers": users, 
            "trainingRecordComplete": complete,
            "trainingRecordAuthor": this.userData.firstName + ' ' + this.userData.lastName, 
            "trainingRecordAuthorId": this.userData.id,
            "trainingRecordHours": this.trainingCompletionHours
          },
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
            }
  }).then( response => {
    this.$emit('closeTrainingModal')
    // console.log(response.data)
  })
    }, 
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.text-align-clear {
  text-align: left;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
