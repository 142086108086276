<template>
<div @click="addModalOpen">
  <b-modal
       id="repairModal"
       ref="repairModal"
       v-model="showModal"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
         >
         <template slot="modal-header">
           Create New Dev Report <b-btn align="right" size="sm" variant="danger" @click="showModalswitchEmitVehicle">x</b-btn>
         </template>
         <div class="modal-body">
            <b-form-select class="shiftSelectInput" v-model="devTypeSelect" :options="devTypes">
              <option :value="null" disabled> Please Select Dev Request Type </option>
            </b-form-select>
            <b-form-input type="text" class="itemDescription" v-model="itemShort" placeholder="Short Item Description" />
            <b-form-textarea class="textAreaDescription" :rows="4" v-model="detailedDescription" placeholder="Detailed Item Description, including specifics about the require repair." />
              <h5 class="sub-section-heading">Optional Screen Shot:</h5>
              <input v-if="imageLoadReset" class="pictureInputButton" type="file" @change="processFile($event)">

              </div>
         <template slot="modal-footer">
             <b-btn variant="primary" @click.stop="submitPost" v-if="textPost">Submit</b-btn>
             <b-btn variant="primary" @click.stop="postImage" v-if="imagePost">Submit</b-btn>

           </template>
    </b-modal>
    <b-modal v-model="showModalConfirmation"
    :no-close-on-backdrop="trueBoo"
    :no-close-on-esc="trueBoo">
      <template slot="modal-header">
             Post Confirmation
      </template>
             <div v-if="postData == ''">
                   ...Submitting post
                   <br>
               <img src="https://firerescuedev.com/drupalBack/web/sites/default/files/siteicons/MalteseLoadingGif.gif">
             </div>
             <div v-if="postData != ''">
                   Your Post has been submitted!
             </div>

             <template slot="modal-footer">
                   <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                     Close Message
                   </b-btn>
            </template>
      </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import axios from 'axios';
export default {
  props:["showModal"],
components:{

},
  data () {
    return {
        trueBoo: true,
        imageLoadReset: true,
        itemShort: '',
        showModalConfirmation: false,
        testValue: null,
        detailedDescription: '',
        newDataUUIDL: '',
        imageFileName: '',
        imageFileFID: '',
        newImageFileData: '',
        fileName: '',
        fileType: '',
        textPost: true,
        imagePost: false,
        imageResponseData: [],
        devTypes: [
          { text: "Bug Report", value: 1 },
          { text: "Support Request", value: 2 },
          { text: "Feature Request", value: 3 },
          { text: "Other", value: 4 }
        ],
        devTypeSelect: null,
        postData: [],



    }
  },
  created(){

  },
  computed:{
    ...mapState([
          'getSessionData',
          'userData',
        ]),
    },
    watch: {
      imageResponseData(newData){
        this.imageUUID(newData)
        this.imageFileSRC(newData)
        this.imageFileFIDData(newData)
      },
      imageFileFID(newData){
        this.sendNewPicturePost()
      }
      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    addModalOpen(){
      document.body.className += 'modal-open';
    },
    /* image */
    imageUUID(newData){
          this.newDataUUID = newData.uuid[0].value;
      },
    imageFileSRC(newData){
      this.imageFileName = newData.filename[0].value;
    },
    imageFileFIDData(newData){
      this.imageFileFID = newData.fid[0].value;
    },
    processFile(event) {
      this.photoFileUpload = event.target.files || event.dataTransfer.files;
        console.log(this.photoFileUpload)
        this.fileName = this.photoFileUpload[0].name
        this.fileType = this.photoFileUpload[0].type
        console.log(this.fileName)
        this.processFileUri(this.photoFileUpload[0])
    },
    processFileUri(file) {
    var reader = new FileReader();
      reader.onload = (e) => {
            this.image = e.target.result;
              this.processFileUriForUpload(this.image)
                    };
      reader.readAsDataURL(file);
    },
    processFileUriForUpload(file){
      var dataSplice = file
      this.previewImageFileData = file
      var newplacedData = dataSplice.split(',')
      this.newImageFileData = newplacedData[1]
      this.textPost = false
      this.imagePost = true
    }, /* Image End */
    showModalswitchEmitVehicle (event) {
        bus.$emit('closeDev')
        this.itemShort = ''
        this.detailedDescription = ''
        this.devTypeSelect = null
        this.postData = []
        this.imageResponseData = []
        this.newDataUUIDL = ''
        this.imageFileName = ''
        this.imageFileFID = ''
        this.newImageFileData = ''
        this.fileName = ''
        this.fileType = ''
        this.textPost = true
        this.imagePost = false
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalswitchEmitVehicle()
    },
    postImage(){
       axios({
        method: 'post',
        url: 'https://firerescuedev.com/drupalBack/web/entity/file?_format=hal_json',
        data: {'_links': {
                        'type': {
                            'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/file/image'
                          }},
                "uri":[{"value":"public://publicuploads/" + this.userData[0].name + moment().format('YYYY-MM-DD-HH-MM') + this.fileName}],
                "data":[{"value": this.newImageFileData
              }],
                "filemime":[{"value": this.fileType}],
                "filename":[{"value":this.userData[0].name + moment().format('YYYY-MM-DD-HH-MM') + this.fileName}],
              },
        headers: {
          'X-CSRF-TOKEN': this.getSessionData,
          'Content-Type' : 'application/hal+json',
        }
      }).then((response) => {
          this.imageResponseData = response.data;
        });


     },
     sendNewPicturePost(){
       axios({
        method: 'post',
        url: 'https://firerescuedev.com/drupalBack/web/entity/node?_format=hal_json',
        data: {'_links': {
                        'type': {
                            'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/node/support_tickets'
                          }},
                "title":[{"value": this.itemShort + ' **Support Ticket' }],
                "type":[{"target_id":"support_tickets"}],
                "_embedded": {
                  "https://firerescuedev.com/drupalBack/web/rest/relation/node/support_tickets/field_support_image": [{
                    "_links": {
                      "type": {
                        "href": "https://firerescuedev.com/drupalBack/web/rest/type/file/image"
                      }
                    },
                    "uuid": [{
                      "value": this.newDataUUID
                    }],
                    "alt": this.itemShort,
                    "title": this.itemShort,
                    "width": "120",
                    "height": "120"

                  }]
                },
                "field_support_type":[{"value": this.devTypeSelect}],
                "field_subject":[{"value": this.itemShort}],
                "field_body":[{"value": this.detailedDescription}],

              },
        headers: {
          'X-CSRF-TOKEN': this.getSessionData,
        /*    */
          'Content-Type' : 'application/hal+json',
        }
      }).then( response => {

            this.postData = response.data

          })

          this.showModalConfirmation = !this.showModalConfirmation;
    },
    submitPost(){
     axios({
      method: 'post',
      url: 'https://firerescuedev.com/drupalBack/web/entity/node?_format=hal_json',
      data: {'_links': {
                      'type': {
                          'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/node/support_tickets'
                        }},
              "title":[{"value": this.itemShort + ' **Support Ticket' }],
              "type":[{"target_id":"support_tickets"}],
              "field_support_type":[{"value": this.devTypeSelect}],
              "field_subject":[{"value": this.itemShort}],
              "field_body":[{"value": this.detailedDescription}],
            },
      headers: {
        'X-CSRF-TOKEN': this.getSessionData,
        /*    */
        'Content-Type' : 'application/hal+json',
      }
    }).then( response => {
        this.postData = response.data

      })

        this.showModalConfirmation = !this.showModalConfirmation;
  }
}
}

</script>
<style>

@media screen and (max-width: 920px) {

}


</style>
