<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
    <b-card no-body>
      <b-tabs card >
        <b-tab title="Schedule Setup" v-if="siteconfig.payroll.flexibleSchedule">
           <b-card no-body>
            <b-tabs card>
          <b-tab title="Schedule Org & Positions">
           <roster  :rosterProfiles="rosterProfiles" :rosterData="rosterData" :scheduleData="scheduleData" />
            </b-tab>
            <b-tab title="Position Assignments">
         <rosterprofileschedule  :rosterProfiles="rosterProfiles" :rosterData="rosterData" :scheduleData="scheduleData" />
            </b-tab>
            <b-tab title="Shift/Schedule Builder" active>
         <scheduleBuilder :scheduleData="scheduleData" :rosterProfiles="rosterProfiles"  :rosterData="rosterData" />
            </b-tab>
            <b-tab title="Schedule View/Approval Perms" v-if="siteconfig.payroll.customPerms">
            <admin-staffing-perms  :rosterProfiles="rosterProfiles" :rosterData="rosterData" :payrollPermsData="payrollPermsData" />
            </b-tab>
            </b-tabs>
           </b-card>
        </b-tab>
        <b-tab title="Roster Settings" v-if="!siteconfig.payroll.flexibleSchedule">
          <b-card no-body>
            <b-tabs card>
        <b-tab title="Roster Template" >
          <roster  :rosterProfiles="rosterProfiles" :rosterData="rosterData" />
          </b-tab>
        <b-tab  title="Roster Profiles">
          <rosterprofile  :rosterProfiles="rosterProfiles" :rosterData="rosterData" />
        </b-tab>
        </b-tabs>
        </b-card>
        </b-tab>
        <b-tab title="Payroll Taxonomy">
          <b-card no-body>
            <b-tabs card>
        <b-tab title="Payroll Codes">
          <payrollcodes :payrollCodesData="payrollCodesData" :payrollBanksData="payrollBanksData" />
          </b-tab>
          <b-tab title="Payroll Banks">
          <payrollbanks :payrollCodesData="payrollCodesData" :payrollBanksData="payrollBanksData" />
          </b-tab>
          <b-tab title="Payroll Accruals">
            <payrollaccural :payrollBanksData="payrollBanksData" :payrollAccuralData="payrollAccuralData" />
            </b-tab>
           <b-tab title="Payroll Codes Bulk" v-if="userData.role == 'admin'">
            <payrollcodesbulk :payrollCodesData="payrollCodesData" :payrollBanksData="payrollBanksData" />
            </b-tab>
            <b-tab title="Payroll Holidays" v-if="siteconfig.payroll.holidayTable">
            <payrollHolidays :payrollHolidaysData="payrollHolidaysData" />
            </b-tab>
          </b-tabs>
          </b-card>
          </b-tab>
          <b-tab title="User Admin"  v-if="!siteconfig.payroll.flexibleSchedule">
          <payrollaccuralassigned :payrollBanksData="payrollBanksData" :payrollAccuralAssignedData="payrollAccuralAssignedData" @getAccuralAssigned="getAccuralAssigned" :payrollAccuralData="payrollAccuralData" :rosterProfiles="rosterProfiles"  :rosterData="rosterData" />
          </b-tab>
          <b-tab title="Payroll Export"  :lazy="true">
            <payrollExport  v-if="!siteconfig.payroll.flexibleSchedule" :payrollBanksData="payrollBanksData" :payrollCodesData="payrollCodesData" :payrollAccuralAssignedData="payrollAccuralAssignedData" :payrollAccuralData="payrollAccuralData" :rosterProfiles="rosterProfiles"  :rosterData="rosterData" />
            <payrollExportTimecard   v-if="siteconfig.payroll.flexibleSchedule" :payrollBanksData="payrollBanksData" :payrollCodesData="payrollCodesData" :payrollAccuralAssignedData="payrollAccuralAssignedData" :payrollAccuralData="payrollAccuralData" :rosterProfiles="rosterProfiles"  :rosterData="rosterData" />
          </b-tab>
          <b-tab title="Reco Report"  :lazy="true">
            <payrollaccuralReco :payrollBanksData="payrollBanksData" :payrollCodesData="payrollCodesData" :payrollAccuralAssignedData="payrollAccuralAssignedData" :payrollAccuralData="payrollAccuralData" :rosterProfiles="rosterProfiles"  :rosterData="rosterData" />
          </b-tab>
        </b-tabs>
        </b-card>
</div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import roster from './staffing/roster.vue'
import rosterprofile from './staffing/rosterprofile.vue'
import rosterprofileschedule from './staffing/rosterprofileSchedule.vue'
import payrollcodes from './staffing/payrollcodes.vue'
import payrollcodesbulk from './staffing/payrollcodesBulk.vue'
import payrollbanks from './staffing/payrollbanks.vue'
import payrollaccural from './staffing/payrollaccurals.vue'
import payrollaccuralassigned from './staffing/payrollaccuralsassigned.vue'
import payrollaccuralReco from './staffing/payrollaccuralReco.vue'
import payrollExport from './staffing/payrollExportNew.vue'
import payrollExportTimecard from './staffing/payrollExportTimecard.vue'
import scheduleBuilder from './staffing/scheduleBuilder.vue'
import payrollHolidays from './staffing/payrollHolidays.vue'
import adminStaffingPerms from './staffing/payrollPerms.vue'
export default {
  components: {
    roster,
    rosterprofile,
    rosterprofileschedule,
    payrollcodes,
    payrollbanks,
    payrollaccural,
    payrollaccuralassigned,
    payrollaccuralReco,
    payrollExport,
    payrollcodesbulk,
    scheduleBuilder,
    payrollHolidays,
    payrollExportTimecard,
    adminStaffingPerms
  },
  props: ['rosterData', 'scheduleData','rosterProfiles', 'payrollCodesData', 'payrollBanksData', 'payrollAccuralData', 'payrollHolidaysData', 'payrollPermsData'],
  name: 'staffing-admin',
  data () {
    return {
      payrollAccuralAssignedData: [],
    }
  },
  created(){
    this.getAccuralAssigned()
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
       
  },
  mounted(){

  },
  watch: {
  },
  methods: {
        getAccuralAssigned(){
        axios({
        method: 'get',
        url: '/api/v1/staffing/payrollaccuralassigned',
        headers: {
              'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.payrollAccuralAssignedData = response.data

      })
    },

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
