<template>
<div>
  <b-table v-if="formData != ''" :fields="fields" :items="formTableData">
    <template v-if="signatureIds.length > 0" v-for="(ids, index) in signatureIds" :slot="ids" slot-scope="data">
      <b-img v-if="data.value != ''" :key="index" width="150px" :src='"data:image/png;base64," + data.value' />
      <h5 :key="index" v-if="data.value == ''">Missing</h5>
      </template>
      <template slot="View" slot-scope="data">
        <b-btn size="sm" variant="primary" disabled title="Just a Demo Button">View</b-btn>
        </template>
    </b-table>
      <h5 v-if="formData == ''">Please Save a Preview Form to see the table display</h5>
</div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  props:['formData'],
components:{
     
},
  data () {
    return {
      formTableData: [],
      signatureIds: [],
      fields: [
        {key: 'updatedAt', label: 'Created'},

      ],
    }
  },
  created(){
    this.getFormTrackedFields(this.formData)
 
  },
  computed:{
    ...mapState([
      'userData',
      'getSessionData',
      'formtempData'
    ]),

  },
  methods: {
    getData(data){
      console.log(data)
    },
    getFormTrackedFields(data){
      var newData = data
      if(newData.tableview != undefined){
        var td = newData.tableview
        for(var i = 0; i < td.length; i++){
          this.fields.push({key: td[i].id, label: td[i].item})
        if(td[i].type == 'signature'){
          this.signatureIds.push(td[i].id)
        }
        if(td[i].type == 'stationsData' ||td[i].type == 'apparatusData' || td[i].type == 'deptContactData'){
         newData[td[i].id] = this.getItemDetails(td[i], td[i].type)
        } else {
        newData[td[i].id] = td[i].value
        }
        }
        this.formTableData.push(newData)
      } else {
        this.formTableData.push(newData)
      }
      this.fields.push('View')
    },
     getItemDetails(item, filter){
      if(Array.isArray(item.value)){
      var newArray = []
      for(var i = 0; i < item.length; i++){
      if(arrayData[i] != null){
      if(item.type == 'stationsData'){
        newArray.push(arrayData[i].stationName)
      } else if (item.type == 'apparatusData') {
        newArray.push(arrayData[i].apparatusName)
      } else if (item.type == 'deptContactData'){
        newArray.push(arrayData[i].firstName + ' ' + arrayData[i].lastName)
      } else {
        return 
      }} else {
        return
      }
      }
      return newArray
    } else {
    if(item.value != undefined){
      if(item.type == 'stationsData'){
        return item.value.stationName
      } else if (item.type == 'apparatusData') {
        return item.value.apparatusName
      } else if (item.type == 'deptContactData'){
        return item.value.firstName + ' ' + item.value.lastName
      } else {
        return ''
      }
    } else {
      return ''
    } 
    }
  },
    
  }
}
</script>

<style scoped>
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 0;
}
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 5px;
  border-radius: 0 0 5px 5px;
}
a {
  text-decoration: none;
}
.A-shift{
  color: red;
}
.B-shift{
  color: blue;
}
.C-shift{
  color: green;
}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
