<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>

      <div  v-if="userData.role == 'adminchief'
      || userData.role == 'admin'">
       <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Document Search</b-navbar-brand>
        <b-form-input class="bootstrapTable mr-sm-1" type="text"  v-model="searchData" placeholder="Search"></b-form-input>
     <b-btn class="mt-1 p-1" variant="primary" @click="toggleAddNewDocument">Add New Document</b-btn>
     </b-nav-form>
<b-container><em v-if="dataReset">Click Row to Edit</em></b-container>
<b-table v-if="dataReset" hover striped :fields="fields" :items="filterdocumentsApiSearch" @row-clicked="updateDocumentFile($event)" >
  <template slot="updatedAt" slot-scope="data">
      <div v-if="updateFile != data.item.id"> {{ setDateFormat(data.item.updatedAt)}}</div>
        <b-btn size="sm" variant="success" v-if="updateFile == data.item.id" @click="updateDocument(data.item.id)">Save</b-btn>
       <b-btn size="sm" variant="danger" v-if="updateFile == data.item.id" @click="clearImageData()">Cancel</b-btn>
  </template>
  <template slot="documentId" slot-scope="data">
    <div v-if="updateFile != data.item.id">{{ data.item.documentId }}</div>
         <b-form-input v-if="updateFile == data.item.id" type="text" class="itemDescription" v-model="itemShort" placeholder="Document Id" />
  </template>
  <template slot="documentName" slot-scope="data">
       <div v-if="updateFile != data.item.id">{{ data.item.documentName }}</div>
 <b-textarea  v-if="updateFile == data.item.id"  :rows="2" v-model="detailedDescription" placeholder="Short Document Description." />
  </template>
  <template slot="documentTag" slot-scope="data">
             <div v-if="updateFile != data.item.id">{{ data.item.documentTag }}</div>
            <b-form-select v-if="updateFile == data.item.id" class="shiftSelectInput" text-field="documenttypeName" value-field="documenttypeName" v-model="documentTag" :options="documentSub(docPar)">
              <option :value="null" disabled> Please Select Document Sub-Category</option>
            </b-form-select>
  </template>
  <template slot="documentTagParent" slot-scope="data">
         <div v-if="updateFile != data.item.id">{{ data.item.documentTagParent }}</div>
                       <b-form-select v-if="updateFile == data.item.id" class="shiftSelectInput" text-field="documenttypeName" value-field="documenttypeName" v-model="docPar" :options="documentCats">
              <option :value="null" disabled> Please Select Document Category</option>
            </b-form-select>

  </template>
  <template slot="documentFile" slot-scope="data" v-if="dataReset">
    <b-btn size="sm" v-if="data.item.documentFile" v-show="updateFile != data.item.id" @click.stop="viewFile(data.item.documentFile)" target="_Blank" variant="primary">View</b-btn>
    <b-btn size="sm" v-if="false && data.item.documentFile" v-show="updateFile != data.item.id" variant="warning" @click="updateDocumentFile(data.item)">Update</b-btn>
    <b-btn size="sm" v-if="updateFile == data.item.id" v-show="fileUpload == ''" variant="danger"  @click="clearImageData">Cancel</b-btn>
    <input v-show="fileDocumentId != data.item.documentId" v-if="!data.item.documentFile || updateFile == data.item.id" type="file" @change="postImage($event, data.item.documentId)" />
    <b-btn size="sm" v-if="fileDocumentId == data.item.documentId" v-show="fileUpload != ''" @click="viewFile(fileUpload)" target="_Blank" variant="primary">View Updated</b-btn>
    <b-btn size="sm" variant="success" v-if="false && fileDocumentId == data.item.documentId" @click="updateDocument(data.item.id)">Save Changes</b-btn>
    <b-btn size="sm" variant="danger" v-if="false && fileDocumentId == data.item.documentId" @click="clearImageData">Cancel</b-btn>
  </template>
  <template slot="Notification" slot-scope="data">
    <b-btn variant="info" size="sm" @click.stop="createNotificationItem(data.item)">Create</b-btn>
    </template>
  <template slot="Operations" slot-scope="data">
    <b-btn size="sm" @click.stop="deletePost(data.item.id)" variant="danger" :title="data.item.id">Delete Row</b-btn>
  </template>
</b-table>

<addnewblogpost :showModal="addNewBlogPost" @closeModal="ToggleAddNewBlogPost" :postNotificationObj="postNotificationObj" />
<addnewdocument :showModal="addNewDocument" @closeModal="addNewDocument" />
  </div>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import moment from 'moment'
import addnewdocument from './../addNew/newDocument.vue'
import addnewblogpost from './../addNew/newBlogPost.vue'
import axios from 'axios'
export default {
  name: 'hello',
  components: {
    addnewdocument,
    addnewblogpost
  },
  data () {
    return {
      uploadedJson: [

      ],
      fields: [
      { key: 'updatedAt', label: 'Updated', sortable: true },
      { key: 'documentId', label: 'ID', sortable: true },
      { key: 'documentName', label: 'Description'},
      { key: 'documentTagParent', label: 'Cat'},
      { key: 'documentTag', label: 'Sub-Cat'},
      { key: 'documentFile', label: 'File'},
      'Notification',
      'Operations'
    ],
      dataReset: true,
      addNewDocument: false,
      addNewBlogPost: false,
      postNotificationObj: '',
      postNid: '',
      searchData: '',
      updateFile: 'test',
      vehicleTypes: [],
      parentGroupings: [],
      stationsData: [],
      departmentRanks: [],
      departmentSpecialties: [],
      fileDocumentId: '',
      shifts: [],
      fileUpload: '',
    }
  },
  created(){
      bus.$on('closeDocument', this.toggleAddNewDocument);
  },
  computed: {
      ...mapState([
        'apparatusData',
        'userData',
        'documentTags',
        'getSessionData',
        'documentsApi'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },
       filterdocumentsApiSearch(){
          var array = this.documentsApi.filter( (p) => {
            return p.documentName.toLowerCase().match(this.searchData.toLowerCase()) ||
                  p.documentId.toLowerCase().match(this.searchData.toLowerCase())
          })
        return _.orderBy(array, ['updatedAt'], ['desc'])
      },
      documentCats(){
          var newArray = [... new Set(this.documentTags.filter(p => p.documenttypeParent == '')
          )]
          return _.orderBy(newArray, ['documenttypeName'], ['asc'])
        },

  },
  watch: {
 
},
  methods: {
    eventConsole(data){
      console.log(data)
    },
    setDateFormat(data){
      return moment(data).format('MM/DD/YYYY')
    },
    documentSub(data){
      var returnData = this.documentTags.filter(p => {
        return p.documenttypeParent.match(data)
      })
      return _.orderBy(returnData, ['documenttypeName'], ['asc'])
    },
    updateDocumentFile(data){
    if(data.id != this.updateFile){
     this.detailedDescription = data.documentName
     this.documentTag = data.documentTag
     this.docPar = data.documentTagParent
     this.itemShort = data.documentId
     this.fileUpload = data.documentFile
     this.updateFile = data.id
    } else {
      return
    }
    },
    viewFile(fileName){
      var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              
              window.open(response.data.signedRequest, '_blank')
            }) 
       },
    toggleAddNewDocument(){
      if(this.addNewDocument == true){
        this.pullDocuments()
      }
      this.addNewDocument = !this.addNewDocument
    },
    ToggleAddNewBlogPost(){
      this.addNewBlogPost = !this.addNewBlogPost
    },
    createNotificationItem(data){
      bus.$emit('addNewNotification', data)
    },
    pullDocuments(){
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
     this.$store.dispatch('LOAD_DOCUMENTS_API_EXP', headers)
    },
    getDocTag(data){
      var documents = this.documentTags.filter(p => {
        return p.name_1.match("Directives")
      })
      var matchData = documents.filter(p => {
        return p.name.match(data)})
      return JSON.parse(matchData[0].tid)
    },
    resetComponent() {
        this.dataReset = false
        this.fileUpload = ''
        this.fileDocumentId = ''
 
        this.detailedDescription = ''
        this.documentTag = ''
        this.docPar = ''
        this.itemShort = ''
        this.$nextTick(function(){
          this.dataReset = true
         this.updateFile = '',
          this.pullDocuments()
        })
      },
    clearImageData(){
      if(this.fileUpload != ''){
      var result = confirm('Are You Sure You Want to Cancel any changes?')
      if (result) {
      this.resetComponent()
      } else {}
    }
    else {
      this.resetComponent()
      }
    
    },
    deletePost(data){
      var result = confirm('Are You Sure You Want to Delete this Post?')
      if (result) {
        var id = data
        axios({
          method: 'delete',
          url: '/api/v1/content/document/' + id,
          data: {},
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/hal+json',}
          }).then( response => {
            this.resetComponent()
            this.pullDocuments()
            this.searchData = ''
            })
          }


    else {}
      },
  postImage(e, id){
      this.fileDocumentId = id
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + id + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fileUpload = response.data

        });

  
     },
    updateDocument(id){
    axios({
     method: 'patch',
     url: '/api/v1/content/document/' + id,
     data: {
     'documentName': this.detailedDescription,
     'documentTag': this.documentTag,
      'documentTagParent': this.docPar,
       'documentId': this.itemShort,
      'documentFile': this.fileUpload

           },
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     this.resetComponent()
   })

 }
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
