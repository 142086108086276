<template>
<div id="homelanding" v-if="stationsData && apparatusData">
    <b-navbar type="dark" variant="dark" v-if="!tvView">
      <b-nav-form>
      <b-navbar-brand class="bootstrapTable d-none d-md-block">Schedule Date:</b-navbar-brand>
       <b-btn variant="info" @click="moveDateRange(true)">Prev</b-btn>
       <b-form-input type="date" class="d-none d-md-block" v-model="selectedDate" @change="emitSelectedDate()" />
       <b-form-input type="date" style="min-height: 43px" class="d-md-none w-50" v-model="selectedDate" @change="emitSelectedDate()" />
        <b-btn variant="info" @click="moveDateRange(false)">Next</b-btn>
        <div style="width: 250px" class="ml-2 d-none d-md-block" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
        <multiselect v-model="userSelected" 
        v-if="false"
        :options="deptContactData" 
        @input="getUserProfile"
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
        <div class="pl-1 d-md-none w-50"  v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
        <multiselect 
        v-model="userSelected" 
        v-show="false"
        :options="deptContactData" 
        @input="getUserProfile"
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
        <b-btn class="mx-2 d-none d-md-block" @click="addNewPayrollMod" variant="primary">Add New Payroll Mod</b-btn>
        <b-btn class="d-md-none mt-2 mr-2" @click="addNewPayrollMod" variant="primary">Add New Payroll Mod</b-btn>
        <b-btn v-if="false" class="mr-2 d-none d-lg-block" :variant="printOn ? 'success' : 'danger'" @click="printOn = !printOn">{{ printOn ? 'Turn Off Print View' : 'Turn On Print View' }}</b-btn>
         <b-btn v-if="printOn" class="mr-2 d-none d-lg-block" variant="primary" @click="executePrintJS" >Print</b-btn>
         <b-btn v-if="printOn" class="mr-2 d-none d-lg-block" variant="info" @click="emailAddressMenu = !emailAddressMenu, userSelectedEmail.push(userData)">Email</b-btn>
        <b-form-checkbox v-if="false" class="text-light" v-model="onDutyFilter">On Duty Only</b-form-checkbox>
      </b-nav-form>
    </b-navbar>
    <b-container v-if="emailAddressMenu" class="py-2"><b-row>
      <b-col cols="*" class="font-weight-bold pt-2">Select Users to Email</b-col>
      <b-col>
    <multiselect 
         v-model="userSelectedEmail" 
        :options="deptContactData"
        multiple
        :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
                  </b-col>
        <b-col cols="*"><b-btn variant="success" size="sm" class="mt-2" :disabled="!(userSelectedEmail && userSelectedEmail.length > 0)" @click="executePrintJSEmail">Send</b-btn></b-col>          
        </b-row></b-container>
    <b-card no-body v-if="!tvView && rosterReset">
      <b-tabs card>
        <b-tab title="All" @click="printTabId = 'print-allview'" id="print-allview" class="mobileView" v-if="false && stationsData && rosterData && rosterBuildOut" :class="printReportOn ? 'printReport' : ''">
      <b-container v-if="printOn" class="mb-4" fluid>
      <b-row><b-col cols="5">
      <img :src="departmentLogoForPrint" /></b-col>
      <b-col cols="7" class="pt-2">
        <h3>Schedule</h3>
        <b-row><b-col>
          <h4 v-if="false"><span class="font-weight-bold">Duty Shift: </span><span>{{ currentShift }}</span></h4>
          </b-col>
          <b-col>
               <h4><span class="font-weight-bold">Date: </span><span>{{ getTodaysDateForPrint }}</span></h4>
            </b-col>
            <b-col>
                 <h4><span class="font-weight-bold">Time: </span><span>{{ getCurrentTimeForPrint }}</span></h4>
              </b-col>
              </b-row>
              <em>Completed by {{ userData.firstName }} {{ userData.lastName }}</em>
        </b-col>
        </b-row>
      </b-container>
    <b-card v-for="parent in rosterBuildOut" :key="parent.id" body-class="mobileView" header-tag="header" header-class="my-0 pb-0 pt-1" bg-variant="dark">
       <h4 slot="header" class="clickPointer text-light text-left">{{ parent.stationName }}</h4>
        <b-card class="mb-2" no-body v-show="parent.showStation" v-for="station in parent.stations" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1" @click="stationViewId = station.id">
          <h4 slot="header" class="clickPointer text-left">{{ station.stationName }}</h4>
            <b-card no-body v-for="roster in station.rosterSpots" :key="roster.id">
             <h5 class="text-left pl-5 mt-2" v-if="editNameId != roster.id && false">{{ roster.rosterName ? roster.rosterName : roster.apparatusName }} <b-btn  v-if="!printOn" @click="editNameId = roster.id, roster.rosterName ? holdName = roster.rosterName : holdName = roster.apparatusName" variant="light" size="sm">Edit</b-btn></h5>
             <b-row v-if="editNameId == roster.id">
               <b-col><b-form-input v-model="holdName" /></b-col>
              <b-col align="left" class="mt-1"><b-btn size="sm" variant="info" :disabled="holdName == roster.apparatusName" @click="updateRosterName(editNameId, holdName)">Save</b-btn>
              <b-btn size="sm" variant="danger" v-if="holdName == roster.apparatusName" @click="editNameId = '', holdName = ''">Cancel</b-btn>
              <b-btn size="sm" variant="danger" v-if="roster.rosterName" @click="updateRosterName(editNameId, null)">Return name to {{ roster.apparatusName }}</b-btn></b-col>
              </b-row>
             <b-container fluid>
               <rosterSpot v-if="rosterReset && rosterProfiles && resetRoster" :rosterProfiles="rosterProfiles" :spot="roster" :onDutyFilter="onDutyFilter" :printOn="printOn" :payaddativemods="payaddativemods" :selectedDate="selectedDate" :profiles="profilesFilteredByDate" :payrollCodesData="payrollCodesData" :mods="modifications" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" :payrollHolidaysData="payrollHolidaysData"  />
             </b-container>
          </b-card>
          </b-card>
    </b-card>
    <b-card header-tag="header" header-class="my-0 pb-0 pt-1" bg-variant="dark" v-if="!tvView">
       <h4 slot="header" class="clickPointer text-light text-left">Off Roster</h4>
        <b-card class="mb-2" no-body header-tag="header" header-class="my-0 pb-0 pt-1">
             <rosterSpotOffroster  :spot="offRosterPayrollMods" :onDutyFilter="onDutyFilter" :printOn="printOn" :payaddativemods="payaddativemods" :selectedDate="selectedDate" :overtimeModification="overtimeModification" :profiles="profilesFilterWithVacancies" :payrollCodesData="payrollCodesData" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" />
            </b-card>
          </b-card>
        <b-container v-if="printOn" fluid class="pt-2">
      <h5>Commander Notes (Info for Print Only)</h5>
          <b-textarea :rows="5" v-model="printNotes" />
      </b-container>
      </b-tab>
      <template v-for="rparent in rosterParentsData">
    <b-tab  v-if="stationsData && rosterParentsData.length > 0 && rosterData && rosterBuildOut && checkPerms(rosterBuildOut.filter(p => p.id == rparent.id).map(p => p.stations))"  :key="rparent.id" :title="rparent.stationName" @click="printTabId = 'print-' + rparent.id" :id="'print-' + rparent.id"  :class="printReportOn ? 'printReport' : ''">
    <b-container v-if="printOn" class="mb-4" fluid>
      <b-row><b-col cols="5">
      <img :src="departmentLogoForPrint" /></b-col>
      <b-col cols="7" class="pt-2">
        <h3>SHIFT COMMAND ROSTER</h3>
        <b-row><b-col>
          <h4><span class="font-weight-bold">Duty Shift: </span><span>{{ currentShift }}</span></h4>
          </b-col>
          <b-col>
               <h4><span class="font-weight-bold">Date: </span><span>{{ getTodaysDateForPrint }}</span></h4>
            </b-col>
            <b-col>
                 <h4><span class="font-weight-bold">Time: </span><span>{{ getCurrentTimeForPrint }}</span></h4>
              </b-col>
              </b-row>
              <em>Completed by {{ userData.deptRank }} {{ userData.firstName }} {{ userData.lastName }}</em>
        </b-col>
        </b-row>
      </b-container>
    <div>
    <b-card v-for="parent in rosterBuildOut.filter(p => p.id == rparent.id)" :key="parent.id" header-tag="header" header-class="my-0 pb-0 pt-1" :bg-variant="!printOn ? 'dark' : ''" >
       <h4 slot="header" class="clickPointer text-left" :class="!printOn ? 'text-light' : ''">{{ parent.stationName }}</h4>
        <b-card class="mb-2" no-body v-for="station in parent.stations" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1">
          <h4 slot="header" class="clickPointer text-left">{{ station.stationName }}</h4>
            <b-card no-body v-for="roster in station.rosterSpots" :key="roster.id">
             <h5 class="text-left pl-5 mt-2">{{ roster.rosterName ? roster.rosterName : roster.apparatusName }} <b-btn v-if="!printOn && false" variant="light" size="sm">Edit</b-btn></h5>
             <b-container fluid>
            <rosterSpot v-if="resetRoster && rosterReset && roster && printTabId == ('print-' + rparent.id)" :spot="roster" :onDutyFilter="onDutyFilter" :profiles="profilesFilteredByDate" :selectedDate="selectedDate" :printOn="printOn" :payrollCodesData="payrollCodesData" :mods="modifications" :payaddativemods="payaddativemods" :userSelected="userSelected" :payrollModDailyRosterData="payrollModDailyRosterData" :payrollHolidaysData="payrollHolidaysData" />
             </b-container>
          </b-card>
          </b-card>
    </b-card>
    </div>
        <b-container v-if="printOn" fluid class="pt-2">
           <h5>Commander Notes (Info for Print Only)</h5>
          <b-textarea :rows="5" v-model="printNotes" />
      </b-container>
    </b-tab>
    </template>
      </b-tabs>
    </b-card>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import html2canvas from 'html2canvas';
import rosterSpot from './rosterspotGantt'
import customPermJs from '../../../../customperm.js';
export default {
components: {
  'multiselect' : Multiselect,
  rosterSpot
},
props: ['rosterData', 'payrollCodesData', 'rosterProfiles', 'payrollModDailyRosterData', 'selectedDateHold', 'tvView', 'stationVar', 'scheduleData', 'resetRoster', 'payrollPermsData', 'payrollHolidaysData'],
data() {
    return {
    editNameId: '',
    holdName: '',
    vacancyPositions: [],
    rosterDataHoldArray: [],
    stationViewId: '',
    userSelected: '',
    selectedDate: '',
    onDutyFilter: false,
    rosterReset: true,
    printTabId: 'print-allview',
    printOn: false,
    printReportOn: false,
    emailAddressMenu: false,
    userSelectedEmail: [],
    printNotes: '',
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    nonSpotModification: [
       'payaddative',
    ],
    createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
    ],
    fillVacancy: [
      'overtime',
      'roster mod',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'exchange',
      'unpaid leave',
      'roster mod',
      'overtime',
      'payaddative'
    ],
    windowWidth: 0,
    intialDate: true,
    currentPage: 1,
    filteredSpots: [],
    monthStart: null,
    monthEnd: null,
    pagedOn: false,
    trackIdConsole: false,
  }
},
created () {
  this.selectedDate = moment().format('YYYY-MM-DD')
  bus.$on('showRosterData', this.showRosterData)
  if(this.tvView){
    this.onDutyFilter = true
  }
  if(this.siteconfig.payroll.customPerms){
    this.setCustomPermsView()
  }
  this.onLoadImage(this.siteconfig.staticHost + 'banners/' + this.siteconfig.deptUrlSub + '/banner.png')
},
beforeDestroy(){
  	
},
watch: {
  selectedDateHold(newData){
    if(newData != this.selectedDate){
      this.selectedDate = this.selectedDateHold
            this.rosterReset = false
      this.$nextTick(function(){
        this.rosterReset = true
      })
    }
  },
  onDutyFilter(data){
   //  console.log('WATCHER')
      this.rosterReset = false
      this.$emit('updateSelectedDate', this.selectedDate)
      this.$nextTick(function(){
        this.rosterReset = true
      })
  },
},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  getTodaysDateForPrint(){
  return moment(this.selectedDate, 'YYYY-MM-DD').format('MM/DD/YY')
  },
  getCurrentTimeForPrint(){
    return moment().format('HH:mm')
  },
  offRosterPayrollMods(){
    var array = this.profilesPreDateFiltered.map(p => p.userId)
    var exchange = this.payrollModDailyRosterData.map(p => p.payrollModExchangeUserId)
    var holdArray = this.payrollModDailyRosterData.filter(p => {
      return p.payrollModRosterId == 'offroster'
             || (array.indexOf(p.payrollModTargetUserId) == -1 && exchange.indexOf(p.payrollModTargetUserId) == -1 && !p.payrollModRosterId)
    })
    return holdArray.filter(p => p.payrollModType != 'overtime availability')
  },
  rosterParentsData(){
           var array = this.rosterData
           var station = _.cloneDeep(this.stationsData)
           var parents = [...new Set(array.map(p => p.stationParent))]
           var parentsObj = parents.map(item => item = station.find(p => p.id == item))
           parentsObj.forEach(item => item['showStation'] = true)
           var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
           if(parentsSort && parentsSort.length > 0){
             var printTab = 'print-' + parentsSort[0].id
            // console.log(printTab)
             this.printTabId = printTab
           }
           return parentsSort
    },
  rosterSpotsFilter(){
          var array = this.rosterData
          var indexArray = this.rosterData.map(p => p.apparatusId)
          var apparatus = this.apparatusData.filter(p => {
            var index = indexArray.indexOf(p.id)
            if(index != -1){
              return p
            }
          })
          return _.orderBy(apparatus, ['apparatusName'], ['asc'])
  },
  overtimeModification(){
      var overtime = this.modifications.filter(p => {
      return p.payrollModType == 'overtime'
    })
    var available = overtime.map(item => item = this.getPayrollModObj(item, item))
    return available
  },
  currentShift(){
              var payroll = this.siteconfig.payroll
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = moment(this.selectedDate).diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
  },
  profilesPreDateFiltered(){
    var array = _.cloneDeep(this.rosterProfiles)
     array = array.filter(p => {
      var start = moment(p.rosterAssignmentStart).isSameOrBefore(this.selectedDate, 'day')
      var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate) : true
      if(start && end){
        return p
      }
    })
  //  console.log('Profiles')
 //   console.log(array)
    if(this.siteconfig.payroll.flexibleSchedule){
       // console.log('PROFILE BUILD')
        var profiles =  this.buildScheduleView(array)
        return profiles
        profiles = profiles.filter(p => {
            return  moment(p.date, "YYYY-MM-DD").isSame(this.selectedDate) || moment(p.date + p.rosterStartTime, "YYYY-MM-DDHH:mm").add(p.rosterShiftLength, 'hours').isSame(this.selectedDate, 'day') 
        })
        return profiles
    } else {
    var profiles = array.filter(p => {
        if(p.scheduledShift == this.currentShift){
          return p
        } else if (p.scheduledShift == 'Daily'){
          var index = p.scheduledWorkDays.indexOf(moment(this.selectedDate).format('ddd'))
          if(index != -1){
            return p
          }
        }
    })
  //  console.log('PROFILES')
  //  console.log(profiles)
    return profiles
    }
  },
  profilesFilteredByDate(){ 
    var profiles = this.profilesPreDateFiltered
       // Adjusts Profiles for Swaps, and Swap Profile
    var swaps = this.checkForUserSwaps(profiles)
    if(false){
    console.log('Swaps')
    console.table(swaps)
    }
      // Adjusts PRofiles for Mods (Mods get added on RosterProfile)
    var mods = this.checkForUserMods(swaps)
   // console.log(this.modifications)
    if(false){
    console.log('Mods')
    console.log(mods)
    }
    // Check for Holidays
    return mods
  },
  profilesFilterWithVacancies(){
    return []
   
  },
  rosterBuildOut(){
    var parents = this.rosterParentsData
    var buildingArray = parents.map(item => {
        var obj = item
        obj['stations'] = this.stationsFromParentsMap(item.id)
        return obj
    })
    if(this.filteredSpots.length > 0){
      var index = this.filteredSpots.map(p => p.rosterId)
      var array = _.cloneDeep(buildingArray)
      array.forEach(p => p.stations = p.stations.filter(s => s.rosterSpots = s.rosterSpots.filter(i => index.indexOf(i.id) >= 0)))
      array.forEach(p => p.stations = p.stations.filter(s => s.rosterSpots.length > 0))
      array = array.filter(p => p.stations.length > 0)
    //  console.log(array)
      if(array && array.length > 0){
             var printTab = 'print-' + array[0].id
            // console.log(printTab)
             this.printTabId = printTab
      }
      return array
    } else {
     // console.log("ROSTER BUILT")
     // console.log(buildingArray)
     return buildingArray
    }
  },
  swapModifications(){
    return this.payrollModDailyRosterData.filter(p => {
      return p.payrollModExchangeUserId != '' && p.payrollModExchangeUserId != null && p.payrollModExchangeUserId != undefined
    })
  },
  payaddativemods(){
    if(this.payrollModDailyRosterData){
    var array = this.payrollModDailyRosterData.filter(p => {
             return p.payrollModType == 'payaddative'})
    return array
    } else {
      return []
    }
  },
  modifications(){
    var array = this.payrollModDailyRosterData.filter(p => {
              var index = this.spotModification.indexOf(p.payrollModType)
              if(index != -1){
                return p
              }
    })
    array = array.map(item => {
        var obj = this.setupPayrollModification(item)
        return obj
    })
    return array
  },
  modificationUsers(){
    var array = this.payrollModDailyRosterData.filter(p => {
              var index = this.spotModification.indexOf(p.payrollModType)
              if(index != -1){
                return p
              }
    })
    return array.map(p => p.payrollModTargetUserId)
  },
  
},
mounted(){

},
methods: {
    setCustomPermsView(){
   //  console.log('SET CUSTOM PERMS')
      if(this.payrollPermsData){
      var permsData = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
      if(permsData && permsData.payrollViewPerms){
        this.filteredSpots = permsData.payrollViewPerms.map(p => p = { rosterId: p})
     //   console.log(this.filteredSpots)
      }
      }

    },
    checkPerms(roster){
      if(this.siteconfig.payroll.customPerms && roster && roster.length == 0){
      return false
      } return true
    },
    checkForHolidays(roster){
    //  console.log('holidays')
      var holidays = this.payrollHolidaysData.filter(p => {
          return moment(p.holidayDate).isSame(moment(this.selectedDate, 'YYYY-MM-DD'), 'day')
      })
      if(holidays && holidays.length > 0){
      var user = this.deptContactData.find(p => p.id == roster.userId)
      if(user){
          var holiday = this.payrollHolidaysData.find(p => {
          return moment(p.holidayDate).isSame(moment(this.selectedDate, 'YYYY-MM-DD'), 'day') && p.holidayPaytype.indexOf(user.deptRank) >= 0
          })
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Holiday')
          if(holiday && payrollCode){
            var startTime = roster.startTime
            var endTime = moment(roster.startTime).add(holiday.holidayHours, 'hours').toISOString()
            var newObj = {
                        payrollModType: payrollCode.payrollCodeCategory,
                        payrollModCode: payrollCode.id,
                        payrollModTargetUserId: this.userData.id,
                        payrollModDatetime: startTime,
                        payrollModHours: holiday.holidayHours,
                        payrollModAuthorId: "system",
                        payrollModApprovalUserId: "system",
                        payrollModApproved: true,
                        payrollModNote: holiday.holidayName,
                        startTime: startTime,
                        endTime: endTime,
                        systemCreated: true
              }
              return newObj
            } return false
      } return false
      } else {
      //  console.log('NO HOLIDAYS')
        return false
      }


      
    },
    enumerateDaysBetweenDates(startDate, endDate){
    var now = _.cloneDeep(startDate)
    var end = endDate
    var dates = []
    while (moment(now).isBefore(end)) {
        dates.push(now);
        now = moment(now, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    }
    return dates;
    },
    processDaysWithPattern(patterns, dates, logpat, schObj){
      var processed = []
      var pattern = _.cloneDeep(patterns)
      for(var i = 0; i < pattern.length; i++){
        var pat = pattern[i]
        if(pat.startTime != null){
          var obj = _.cloneDeep(schObj)
          obj.rosterStartTime = pat.startTime
          obj.date = dates[i]
          obj.rosterShiftLength = pat.length
          processed.push(obj)
        } else {
         // processed.push({date: dates[i], pat: logpat})
        }
      }
      return processed
    },
    buildScheduleView(schedules){
     // console.log("schedules")
    //  console.log(schedules)
      var holdDatesArray = []
      for(var s in schedules){
      var schObj = schedules[s]
      var schDataObj = this.scheduleData.find(p => p.id == schObj.scheduledShift)
      var schedule = {
        patternType: schDataObj.patternType,
        schedulePattern: schDataObj.schedulePattern
      }
      var startDate = schObj.rosterAssignmentStart // comes from their profile
      var start = moment(startDate, 'YYYY-MM-DD')
      var end = moment(this.selectedDate).clone().endOf('month')
      if(schObj.rosterAssignmentEnd && moment(schObj.rosterAssignmentEnd).isBefore(end)){
        end =  moment(schObj.rosterAssignmentEnd) // comes from their profile
      }
      var pattern = schedule.patternType
      var momentDiff = pattern == 'weekly' ? 'week' : 'day'
      var momentEnd = pattern == 'weekly' ? 7 : 1
      var startOfMonth =  moment(this.selectedDate).clone()
      var startOfMonthClone = _.cloneDeep(startOfMonth)
      var patIterations = startOfMonthClone.clone().diff(start.clone(), 'day')
      var patternArray = schedule.schedulePattern
      var dayTotal = 0
      var patHold = []
      for(var i in patternArray){
        var patTotal = 0
        var length = patternArray[i].interval.length
        var repeat = patternArray[i].repeat
        patTotal = length * repeat
        patHold.push(patTotal)
        dayTotal = dayTotal + patTotal
      }
      if(schObj.userId == "da044601-e3fd-4ddc-a428-62162aea6c43" && this.trackIdConsole){
        console.log('FIRST POINT')
        console.log(patternArray)
        console.log(patIterations)
        console.log(dayTotal)
      }
      if(patIterations > (dayTotal * 2)){
              var daysFromStart = 0
              var patIntCalc = _.cloneDeep(patIterations)
              while(Math.floor((patIntCalc/dayTotal)) != (patIntCalc/dayTotal)){
                  daysFromStart++;
                  patIntCalc = patIntCalc - 1
              }
                  if(schObj.userId == "da044601-e3fd-4ddc-a428-62162aea6c43" && this.trackIdConsole){
                  //  console.log('Second POINT')
                  //  console.log(daysFromStart)
                  }
                  var newStart =  moment(this.selectedDate).clone().subtract(daysFromStart, 'day')
                  var interations = end.diff(newStart, momentDiff, true)
                  var pattern = 0 // patternStart +1
                  var totalIterations = 0
                  var startHold = newStart //startOfMonth
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
              } else {
                  var interations = end.diff(start, momentDiff, true)
                  var pattern = 0
                  var totalIterations = 0
                  var startHold = start
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
      }
      if(schObj.userId == "da044601-e3fd-4ddc-a428-62162aea6c43" && this.trackIdConsole){
      //  console.log('Third POINT')
      //  console.log(newStart.format('MM-DD-YYYY'))
     //   console.log(interations)
       // console.log(totalIterations)
      //  console.log(startHold)
      }
      if(true){
        var patternBuild = []
        var vm = this
        function doTheLoop() {
        for(var i = 0; totalIterations < interations; i++){
            var currentP = patternArray[pattern]
            if(!currentP){
              pattern = 0
              currentP = patternArray[pattern]
            }
            var lastDate = startHold.clone().add(momentEnd, 'days')
            if(vm.scheduleEndDate && moment(vm.scheduleEndDate).isBefore(lastDate)){
                 lastDate =  moment(vm.scheduleEndDate).add(1, 'day')
             }
            if (currentP.repeatType == "PatternInterations") {
                  if(currentP.repeat > repeatPat){
                    repeatPat = repeatPat + 1
                    var daysList = vm.enumerateDaysBetweenDates(startHold.format('YYYY-MM-DD'), lastDate.format('YYYY-MM-DD'))
                      if(schObj.userId == "2fee1546-886b-46ab-8e64-077d0c444a68" && vm.trackIdConsole){
                      //  console.log('FABER DAY LIST')
                       // console.log(daysList)
                        }
                    var processDays = vm.processDaysWithPattern(currentP.interval, daysList, pattern, schObj)
                    patternBuild = patternBuild.concat(processDays)
                    totalIterations = totalIterations + 1
                    startHold = lastDate.clone()
                    if(vm.scheduleEndDate && startHold.isSameOrAfter(moment(vm.scheduleEndDate))){
                    //  console.log('END MADNESS')
                     // console.log(startHold)
                     // console.log(moment(vm.scheduleEndDate))
                       return (false)
                       totalIterations = interations
                    }
                  }  else {
                      if(schObj.userId == "da044601-e3fd-4ddc-a428-62162aea6c43" && vm.trackIdConsole){
                     //    console.log('Repeat TRIGGERED')
                        }
                      repeatPat = 0
                      pattern = pattern + 1
                  }
            } else {
              pattern = pattern + 1
              totalIterations = totalIterations + 1
            }
            if(totalIterations < interations){
              return (true)
            } else {
              return (false)
            }

        }
      }
      while (doTheLoop()) {}
      //console.log(patternBuild)
  }
    var datesHold = patternBuild.filter(p => p.date)
    holdDatesArray = holdDatesArray.concat(datesHold)
  }  
  //  console.log('FABER DATES')
  //  console.log(holdDatesArray.filter(p => p.userId == '2fee1546-886b-46ab-8e64-077d0c444a68'))
    return holdDatesArray
  },
    showRosterData(data){
    this.selectedDate = moment(data.date._d).format('YYYY-MM-DD')
  },
  onLoadImage(url){
      var vm = this
    //  console.log('NEW IMAGE')
    //  console.log('image loaded')
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        vm.departmentLogoForPrint = dataURL // .replace(/^data:image\/(png|jpg);base64,/, "")
      };
    },
  getUserProfile(){
     this.rosterReset = false
     this.rosterDataHoldArray = []
      this.$nextTick(function(){
        this.rosterReset = true
      })
  },
  resetRosterCheck(data){
    if(!data || true){
      this.rosterReset = false
      this.$emit('updateSelectedDate', this.selectedDate)
      this.$nextTick(function(){
        this.rosterReset = true
      })
    }
  },
  executePrintJS(){
      var printId = '#' + this.printTabId
           this.printReportOn = true
      html2canvas(document.querySelector(printId)).then(canvas => {
       var image = canvas.toDataURL("image/jpeg")
       return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              style: 'img { width:1200px !important; }',
            })
   // 
    });
    this.$nextTick(function(){
        this.printReportOn = false
    })
  },
  executePrintJSEmail(){
        this.printReportOn = true
        var printId = '#' + this.printTabId
        var documentHold = document.querySelector(printId)

        html2canvas(documentHold).then(canvas => {
      /*  var image = canvas.toDataURL("image/jpeg")
        return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              imageStyle: 'margin-bottom:20px;'
            }) */
       var hm = this
        canvas.toBlob(function(blob) {
        hm.postImageAxiosEmail(blob) 
        }); 
      });
      this.$nextTick(function(){
        this.printReportOn = false
      })
    },
    postImageAxiosEmail(result){
        var data = new FormData();
        data.append('uploadFile', result);
  /*    var data = new FormData();
      data.append('uploadFile', files[0]) */
          axios({
        method: 'post',
        url: '/api/v1/upload/files/staffingrosters/email/' + moment().format('YYYY-MM-DD-HH-MM-SS'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
        this.sendEmailData(response.data)

      })
     },
     sendEmailData(url){
       var obj = {
         email: this.userSelectedEmail.map(p => p.email),
         url: url
       }
           axios({
     method: 'post',
     url: '/api/v1/staffing/roster/email',
     data: obj,
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
     }
   }).then( response => {
      this.emailAddressMenu = false
      this.printOn = false
      this.onDutyFilter = false
      this.userSelectedEmail = []
   })

     },
  addNewPayrollMod(){
    var startDate = moment(this.selectedDate, 'YYYY-MM-DD').add(8, 'hours').toISOString()
     var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: this.userData.id, 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: 8, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '' }
                 bus.$emit('updatePayrollMod', dataObj)
      
  },
  emitSelectedDate(){
   // console.error(this.selectedDate)
  // console.log('EMITTED DATE')
    this.$emit('updateSelectedDate', this.selectedDate)
  },
  moveDateRange(boo){
   // var rotation = this.siteconfig.payroll.shiftRotation
    var rotation = 1
    if(boo){
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').subtract(rotation, 'days').format('YYYY-MM-DD')
    } else {
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').add(rotation, 'days').format('YYYY-MM-DD')
    }
    this.emitSelectedDate()
  },
  getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName + ' (' + user.deptRank + ') #' + user.deptSpecialties
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getPayrollCode(code){
      var obj = this.payrollCodesData.find(p => p.payrollCode == code)
      if(obj){
        return obj.id
      } else {
        return 'Code Not Found'
      }
    },
    getFixedSwap(array, mods){
      if(false){
      console.log('Get Fixed Swap')
      console.log(array)
      console.log(mods)
      }
       function doTheLoop() {
         for(var a = 0; a < array.length; a++){
          var mod = mods.filter(p => moment(p.startTime).isSameOrAfter(array[a].startTime) && moment(p.endTime).isSameOrBefore(array[a].endTime))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = array[a].startTime
                  array[a] = obj
                 // HAD TO CHANGE THIS TO KEEP THE ITEMS FROM DROPPING OFF THE GANTT
                 // array.splice(a, 1)
                  return(true);
                }
                // first < first && last == last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  array[a] = obj
                }
                // first == first && last < last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  array[a] = obj
                }
                // first < first && last < last
                if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  objTwo.startTime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  array.push(objTwo)
                  array[a] = obj
                }
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isAfter(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
    checkForUserSwaps(users){
    var profiles = []
    for(var u in users){
      var obj = users[u]
      obj['startTime'] = moment(obj.date + 'T' + obj.rosterStartTime, "YYYY-MM-DDTHH:mm:ss").toISOString()
      obj['endTime'] = moment(obj.startTime).add(obj.rosterShiftLength, 'hours').toISOString()
      var array = this.swapModifications.filter(p => p.payrollModTargetUserId == users[u].userId)
      if(array.length > 0){
       var profile = [obj]
       var mods = []
       for(var s in array){
         if(array[s]){ //array[s].payrollModExchangeApproved
         var swap = _.cloneDeep(users[u])
         swap['id'] = obj.id + array[s].id
         swap['payrollId'] = obj.id
         swap['payrollSwap'] = this.getPayrollCode('EXON')
         swap['payrollModData'] = array[s]
         swap['payrollCode'] = array[s].payrollModCode
         swap['startTime'] = array[s].payrollModDatetime
         swap['endTime'] = moment(swap.startTime).add(array[s].payrollModHours, 'hours').toISOString()
         swap['targetUser'] = obj.userId
         swap['userId'] = array[s].payrollModExchangeUserId
         swap['payrollModStartDateTime'] = array[s].payrollModDatetime
         swap['rosterShiftLength'] = Number.parseFloat(array[s].payrollModHours)
         mods.push(swap)
         }
       }
       var fixedProfile = this.getFixedSwap(profile, mods)
        for(var f in fixedProfile){
          profiles.push(fixedProfile[f])
        }
        for(var m in mods){
          profiles.push(mods[m])
        }
    } else {
      profiles.push(obj)
    }
    }
    return profiles
  },
  checkForUserMods(users){
    var profiles = []
    for(var u in users){
     var obj = _.cloneDeep(users[u])
     var array = this.modifications.filter(p => p.userId == users[u].userId)
     if(this.siteconfig.payroll.holidayTable && this.payrollHolidaysData){
         var holidays = this.checkForHolidays(obj)
         if(holidays){
          //  console.log('HOLIDAY PASS')
          //  console.log(holidays)
            array.push(holidays)
         }
      }
    //  console.log('CHECK FOR USER MODS ARRAY')
   //   console.log(array)
      if(array.length > 0){
       array = _.orderBy(array, function(e){ return Number.parseInt(e.rosterShiftLength)}, ['desc'])
       var profile = [obj]
       var mods = []
       for(var s in array){
         if(array[s]){ 
         var swap = _.cloneDeep(array[s])
         mods.push(swap)
         }
       }
       var fixedProfile = this.getFixedSwap(profile, mods)
      // console.log(fixedProfile)
        for(var f in fixedProfile){
          profiles.push(fixedProfile[f])
        }
    } else {
      profiles.push(users[u])
    }
    }
    return profiles
  },
  updateRosterName(id, data){
    axios({
     method: 'patch',
     url: '/api/v1/staffing/roster/name/' + id,
     data: {rosterName: data},
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.editNameId = ''
     this.holdItemId = ''
     bus.$emit('getRoster')
   })

    },
    setupPayrollModification(array){
         var allMods = _.cloneDeep(this.payrollModDailyRosterData)
         var vacancyFill = array.payrollModRosterPosition && array.payrollModRosterId ? true : false
         var position = null
         var rosterid = null
         if(!vacancyFill){
           var profile = this.profilesPreDateFiltered.find(p => p.userId == array.payrollModTargetUserId)

           if(profile){
             position = profile.rosterPosition ? profile.rosterPosition : null
             rosterid = profile.rosterId ? profile.rosterId : null
           } else {
             var mod = allMods.find(p => p.payrollModExchangeUserId == array.payrollModTargetUserId)
             if(mod){
               profile = this.profilesPreDateFiltered.find(p => p.userId == mod.payrollModTargetUserId)
               if(profile){
                position = profile.rosterPosition ? profile.rosterPosition : null
                rosterid = profile.rosterId ? profile.rosterId : null
             }
           }
          }
         }
         var swap = {}
         swap['id'] = array.id
         swap['vacancyFill'] = vacancyFill
         swap['payrollId'] = array.payrollModTargetUserId
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : rosterid ? rosterid : ''
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : position ? position : null
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['userId'] = array.payrollModTargetUserId
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
    },
  getPayrollModObj(array, users){
         var index = this.createVacancy.indexOf(array.payrollModType)
         var index2 = this.fillVacancy.indexOf(array.payrollModType)
         var oldRosterId = users.rosterId
         var swap = _.cloneDeep(users)
         swap['id'] = users.id + array.id
         swap['payrollId'] = users.id
         swap['rosterId'] = array.payrollModRosterId ? array.payrollModRosterId : users.rosterId
         swap['vacancyTest'] = index2 == -1 ? true : false
         swap['payrollCode'] = array.payrollModCode
         swap['payrollModData'] = array
         swap['rosterPosition'] = array.payrollModRosterPosition ? array.payrollModRosterPosition : users.rosterPosition
         swap['payrollModStartDateTime'] = array.payrollModDatetime
         swap['startTime'] = array.payrollModDatetime
         swap['endTime'] = moment(array.payrollModDatetime).add(array.payrollModHours, 'hours').toISOString()
         swap['rosterShiftLength'] = array.payrollModHours
         return swap
  },
  // BUILDS SPOTS IN THE INTIAL ROSTER
    stationsFromParentsMap(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])
      var stationMap = stationsSort.map(item => {
        var obj = item
        obj['rosterSpots'] = this.returnRosterSpots(id, item.id)
        return obj
      })
      //console.log(stationMap)
      return stationMap
    },
    stationsFromParents(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort
    },
    returnRosterSpots(pid, sid){
      var array = this.rosterData.filter(p => p.stationParent == pid && p.stationId == sid)
      array.forEach(item => item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId) ? this.apparatusData.find(p => p.id == item.apparatusId).apparatusName : 'Unknown')
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])
      //console.log(apparatusSort) 
      return apparatusSort
    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.printReport {
  width: 1200px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
  
}
@media only screen and (max-width: 600px){
  .mobileView {
    padding: 0;
  }
}

</style>
