<template>
         <b-form-group
                breakpoint="lg"
                label-size="md"
                label-class="font-weight-bold pt-0"
                class="mb-0">
                <b-row class="my-2">
            <b-col class="mt-2" v-for="(formsub, indexSub) in formcompData" :key="indexSub"
                  v-show="visibilitySettingsCheck(formcomp.groupComponents, formsub.visibilitySettings)"
                  :lg="formsub.labelPosition"
                  :label-for="formsub.label">
                   <h6>{{ formsub.label }}</h6>
                   <div v-if="formsub.completed == true">

                   </div>
                    <forminputs :formsubdata="formsub" :formcompData="formcompData" @updateValue="setNewValue($event)" />
             </b-col>
             </b-row>
         </b-form-group>
</template>

<script>

import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import formInputs from './forminputs.vue'
export default {
props: ['formcomp', 'formTemp'],
components: {
        'multiselect' : Multiselect,
        'forminputs' : formInputs,
},
data() {
    return {

      showPostDataArray: false,
      showComponentArray: false,
      resetComponent: false,
      formcompData: [],
}
},
created () {
  this.buildDataArray(this.formcomp)

},
beforeDestroy(){
  
},
watch: {
 
},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData',
    'stationsData',
    'apparatusData',
    'deptContactData'
  ]),

},
mounted(){

  

},
methods: {
  setNewValue(data){
    var obj = {id: this.formcomp.id, value: data}
    this.$emit('NewData', obj)
  },
  buildDataArray(data){
    var prev = data.data
    var array = this.formTemp.formtempComponentData.filter(p => {
      return p.id == data.id
    })
    var array = array[0].groupComponents
    for(var i = 0; i < array.length; i++){
        var item = prev.filter(p => {
          return p.id == array[i].id
        })
        array[i].value = item[0].value
        array[i].completed = item[0].completed
    }
    this.formcompData = array
  },
  checkForDisabled(data){
    var boo = data.defaultValueLoggedUser
    if(boo == true){
      if(data.value == ''){
        data.value = this.userData
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
  checkFormState(req, value){
    if(req == true){
      if(value != ''){
        return true
      } else {
        return false
      }
    } else {
      return null
    }
  },
  checkFormStateAdvanced(req, value){
    if(req == true){
      if(value != null){
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  },
  visibilitySettingsCheck(array, data){
    var operators = {
    '==': function(a, b) { return a == b },
    '!=': function(a, b) { return a != b },
    'notBlank': function(a, b) { return a != ''},
    'blank': function(a, b) { return a == ''},
    'contains': function(a, b){
                      var item = b.filter( p => {
                        return p == a
                      })
                      if(item.length > 0){
                        return true
                      } else {
                        return false
                      }
                      }
    }  
    var booArray = []
    if(data != undefined && data.length > 0){
      for(var i = 0; i < data.length; i++){
        var item = array.filter(p => {
          return p.id == data[i].item
        })       
       if(item[0] != undefined && item[0] != null){
          var boo = operators[data[i].modifier](item[0].value, data[i].value)
          booArray.push(boo)
        }
       }
    var index = booArray.indexOf(false)
    if(index < 0){
      return true
    } else {
      return false
    }
      
    } else {
    return true
    }
  

  },
  getRefValue(ref){
    var item = this.formcompData
    var data = item.filter(p => {
      return p.id == ref.itemId
    })
    if(data[0].value != undefined && data[0].value != null){
      var datai = data[0].value
      return datai[ref.refItem]
    }
  },
  getDataFormReturn(data, filter){
    var array = this[data]
    if(filter != undefined && filter != null && filter.length > 0){
      for(var i = 0; i < filter.length; i++){
        if(filter[i].multiple == true){
            var array = array.filter( p => {
            var arrayTest = p[filter[i].filterKey]
            if(Array.isArray(arrayTest)){
              var userArray = arrayTest
              let found = userArray.some(r=> filter[i].filterValue.indexOf(r) >= 0)
              if(found){
                return p
              }
            } else {
              var indexR = filter[i].filterValue.indexOf(p[filter[i].filterKey])
              if(indexR >= 0){
                return p
              }
            }
          })

        } else {
          var array = array.filter( p => {
            var arrayTest = p[filter[i].filterKey]
            if(Array.isArray(arrayTest)){
              var indexOfArr = arrayTest.indexOf(filter[i].filterValue)
              if(indexOfArr >= 0){
                return p
              }
            } else {
            return p[filter[i].filterKey] == filter[i].filterValue
            }
          })

         }
       } return array    
    } else {
    return array
    }
  },
  getLabel(data){
    if(data == 'stationsData'){
      return 'stationName'
    } else if ( data == 'apparatusData') {
      return 'apparatusName'
    } else {
      return 'lastName'
    }
  },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.errorClass {
  border: 1px solid red;
  border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
