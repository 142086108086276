<template>
<div>
  <b-container class="px-0 pt-3" fluid>
         <div v-if="!showModalConfirmation">
          <tutdata v-if="showTutVideo" singlePost="newpreplan" />
          <b-container fluid>
            <b-btn v-if="patchData" variant="success" @click="executePrintJS">Print Form</b-btn>
            <b-btn v-if="patchData" @click="emitClosePreplan" variant="danger">Close</b-btn>
                 <b-btn   v-if="patchData && !edit" @click="edit = !edit" :variant="!edit ? 'outline-secondary' : 'secondary'">{{!edit ? 'Edit' : 'Editing' }}</b-btn>
                 <b-btn variant="info" v-show="(!patchData || edit) && requiredValuesCheck(requiredValues).length == 0" @click="patchData == false ? postNewPreplan(ppObj) : (postUpdatePreplan(), edit = !edit)">Save</b-btn><br>
                     {{ patchData ? 'Last Inspected on ' + getFormatedTime : 'Unknown Last Inspection'}}
                    <div v-if="formValidationReset" v-show="requiredValuesCheck(requiredValues).length > 0">
                      <span class="text-danger">Required for Submission: 
                        <span v-for="(item, index) in requiredValuesCheck(requiredValues)" :key="index"><em>{{ item.replace(':', '') + ',' }} </em></span></span>
                      
                      </div>
            <b-card no-body :class="responseView ? 'fullScreenSetup' : 'px-0'" >
              <b-container v-if="responseView" fluid class="m-0 p-0">
           <b-container class="p-0 m-0 overflowData" fluid v-if="buildingDataBar">
            <b-navbar  class="blogSpanHeader" type="dark" variant="dark">         
                    <b-nav-form>
                        <b-navbar-brand class="bootstrapTable">Building</b-navbar-brand>
              <b-btn size="sm" class="ml-2" v-for="(btn, index) in formGroups" :disabled="showAll" :variant="showGroup == btn.group ? btn.variant : 'outline-light'" @click="showGroup = btn.group" :key="index">{{ btn.label }}</b-btn>
                        </b-nav-form>
                         <b-navbar-nav class="ml-auto">
                           <b-btn @click="toggleBuildingDataBar" size="sm">Building Data (on)</b-btn>
                        
                         </b-navbar-nav>
                            <b-btn size="sm" class="ml-3" @click="responseView = !responseView" variant="danger">X</b-btn>
                  </b-navbar>
                    <b-card v-for="(group, index) in formGroups" :key="index"
                      :border-variant="group.variant" v-show="showAll == true ? true : showAll == false && showGroup == group.group ? true : false"
                      :header="group.header"
                      :header-bg-variant="group.variant"
                      header-text-variant="white font-weight-bold p-1"
                      body-text-variant="center"
                      class="p-0" body-class="p-0" header-class="p-0">
                   <b-container breakpoint="lg"
                                fluid
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                      <template v-for="(display, index) in group.displayOrder">
                        <b-table  stacked="lg" small v-if="display.type == 'table'" :items="getTableItems(display.order, group.fields)" :key="index" />
                        <div  v-if="display.type == 'textarea'" v-for="(textArea, index2) in group.fields.filter(p => p.order == display.order)" :key="index2">
                        <b-card no-body class="my-1" >{{ holdValues[textArea.vmodel].value }}</b-card>
                        </div>
                        </template> 
                    </b-container>
                    </b-card>

              </b-container>
               <preplanMap v-if="ppObj.hydrantMapData" :availableBusiness="availableBusiness" @toggleBuildingDataBar="toggleBuildingDataBar" :buildingData="ppObj.hydrantMapData" :edit="edit" :refPhotos="ppObj.hydrantPicture" :responseView="responseView" @updateFeatureData='updateFeatureData($event)' @photoArrayUpdate="photoArrayUpdate($event)" />
              </b-container>
              <b-tabs card v-if="!responseView">
                  <h6 v-if="showMap && tempBuildingData && tempBuildingDataLatLng == ''">Click on the Location of they Hydrant</h6>
                  <b-btn v-if="tempBuildingDataLatLng != ''" variant="success" @click="allowMapPick = false, setBuildingData(tempBuildingDataLatLng), tempBuildingDataLatLng = ''">Set Marker as Hydrant Location</b-btn>
                   <b-tab  title="Address Verification/Set Map" @click="showMap = true, setCadDataSearch(cadData)" :active="responseView">
                 <preplanMap v-if="showMap && ppObj.hydrantMapData && !tempBuildingData" :availableBusiness="availableBusiness" :buildingData="ppObj.hydrantMapData" :edit="edit" :refPhotos="ppObj.hydrantPicture"  :responseView="responseView" @updateFeatureData='updateFeatureData($event)' @photoArrayUpdate="photoArrayUpdate($event)" />
                <preplanMap v-if="showMap && tempBuildingData"  :availableBusiness="availableBusiness" :buildingData="tempBuildingData" @mapBuildingLatLng="setBuildingLatLng" :edit="edit" :tempView="true" />
                 <b-container v-if="!ppObj.hydrantMapData">
                     <span v-if="!edit" class="text-info">Click Edit to Add Setup Location Map</span>
                    <b-form-group breakpoint="lg"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                    <b-row class="my-2">
                                            
                      <b-col  v-if="false" v-for="(field, index) in cadData" :key="index" class="mt-2" lg="3" :label-for="field.cadValue"><h6>{{ field.cadValue }}</h6>
                              <b-form-input :disabled="!edit" v-model="field.value" :id="field.cadValue" :type="field.type" ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input :disabled="!edit" v-model="cadDataSearch" type="text" placeholder="example: '123 Main St, Sarasota, FL 34236'" /></b-col><b-col cols="*">
                        <b-btn :disabled="!edit" variant="outline-success" @click="getAddress(cadDataSearch)" >Search For Address</b-btn>
                        <b-btn v-if="false" :disabled="!edit" variant="outline-info" @click="getUserGPSLocation()" >Get Current Location</b-btn>
                        </b-col>
                      </b-row>     
                    </b-form-group>
                    <b-container v-if="addressData.length > 0">
                    <h6 class="font-weight-bold">Verified and Returned Addresses</h6>
                     <b-row v-for="(data, index) in addressData" :key="index">
                       <b-col>{{ data.properties.Place_addr }}</b-col>
                       <b-col cols="*"><b-btn variant="outline-primary" @click="viewMapBuild(data)">Set As Starting Location</b-btn></b-col>
                       <b-col cols="*" v-if="false"><b-btn variant="outline-info" :disabled="allowMapPick" @click="viewMapBuild(data)">Tag Building on Map</b-btn></b-col>
                       </b-row>
                    </b-container>
                    <b-container>
                    <b-row class="mt-5" v-if="searchPerformed && false">
                      <b-col>
                      <h6 class="font-weight-bold text-danger">Unable to Verify Address</h6></b-col>
                       <b-col cols="*"><b-btn variant="outline-danger" @click="allowMapPick = false">Set as Unverified</b-btn></b-col>
                       <b-col cols="*"><b-btn variant="outline-info" :disabled="allowMapPick" @click="viewMapBuild(data)">Tag Building on Map</b-btn></b-col>
                      </b-row></b-container>
                     


                 </b-container>
                 </b-tab>
                 <b-tab title="Hydrant Info" class="px-0 pt-3" :disabled="!patchData && allowMapPick && false" :active="!responseView && !edit">
                  <b-btn v-if="edit" v-for="(btn, index) in formGroups" :disabled="showAll" :variant="showGroup == btn.group ? btn.variant : 'outline-' + btn.variant" @click="showGroup = btn.group" :key="index">{{ btn.label }}</b-btn>
                  <b-form-checkbox v-if="edit" v-model="showAll" :value="true" :unchecked-value="false">View All</b-form-checkbox>
                  <b-container class="pt-3" v-if="edit == true"> 
                    <b-card v-for="(group, index) in formGroups" :key="index"
                      :border-variant="group.variant" v-show="showAll == true ? true : showAll == false && showGroup == group.group ? true : false"
                      :header="group.header"
                      :header-bg-variant="group.variant"
                      header-text-variant="white">
                   <b-form-group breakpoint="lg"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                    <b-row class="my-2">
                      <b-col v-for="(field, index) in group.fields" :key="index" class="mt-2" :lg="field.size" :label-for="field.idtag + uniqueKey"><h6>{{ field.label }}</h6>
                        <b-form-input :state="checkForState(field.vmodel)" v-if="(field.type == 'text' || field.type == 'number' || field.type == 'date')" v-model="holdValues[field.vmodel].value" :id="field.idtag + uniqueKey" :type="field.type" @change="updateValue(field.vmodel, $event, field)" ></b-form-input>
                        <b-textarea v-if="field.type == 'textarea'" :id="field.idtag + uniqueKey" v-model="holdValues[field.vmodel].value" :rows="field.rows"  @change="updateValue(field.vmodel, $event)" />
                        <b-form-checkbox v-if="field.type == 'boolean'"  :id="field.idtag + uniqueKey" v-model="holdValues[field.vmodel].value" :value="true" :unchecked-value="false" @change="updateValue(field.vmodel, $event)" />
                              <multiselect v-if="field.type == 'taxonomySingleSelect'"
                                          :class="{'invalidState': !checkForState(field.vmodel), 'validState': checkForState(field.vmodel)}"
                                          :id="field.idtag + uniqueKey"
                                          v-model="holdValues[field.vmodel].value" 
                                          :options="field.tax == 'station' ? stationsData : ''" 
                                          :multiple="field.type == 'taxonomySingleSelect' ? false : true" 
                                          :close-on-select="true" 
                                          :clear-on-select="true"
                                          :hide-selected="true" 
                                          @input="updateValue(field.vmodel, holdValues[field.vmodel].value, field)"
                                          :preserve-search="true" 
                                          :placeholder="'Select ' + field.tax" 
                                          :label="field.tax == 'station' ? 'stationName' : ''" 
                                          :track-by="field.tax == 'station' ? 'stationName' : ''"></multiselect>
                        <b-form-select v-if="field.type == 'select'" v-model="holdValues[field.vmodel].value" :options="getSelectOptions(field.options)" @input="updateValue(field.vmodel, holdValues[field.vmodel].value, field)" />
                        <b-form-input v-if="field.type == 'reference' && refValueSwitch != field.vmodel" :value="holdValues[field.vmodel].value" :id="field.idtag + uniqueKey" disabled></b-form-input>
                      </b-col>
                      </b-row>   
                    </b-form-group>
    
                    <br><br>
    
                    </b-card>

                

                      </b-container>
              <b-container class="px-0 pt-3" fluid v-if="!edit" id="printJS-form" >
         
                    <b-card v-for="(group, index) in formGroups" :key="index"
                      :border-variant="group.variant"
                      :header="group.header"
                      :header-bg-variant="group.variant"
                      header-text-variant="white font-weight-bold p-1"
                      body-text-variant="center"
                      class="p-0" body-class="p-0" header-class="p-0">
                   <b-container breakpoint="lg"
                                fluid
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                      <template v-for="(display, index) in group.displayOrder">
                        <b-table  stacked="lg" small v-if="display.type == 'table'" :items="getTableItems(display.order, group.fields)" :key="index">
                        </b-table>
                        <div  v-if="display.type == 'textarea'" v-for="(textArea, index2) in group.fields.filter(p => p.order == display.order)" :key="index2">
                        <b-card no-body class="my-1" >{{ holdValues[textArea.vmodel].value }}</b-card>
                        </div>
                        </template> 
                    </b-container>
                    </b-card>

              </b-container>
               </b-tab>
                <b-tab v-if="!responseView" title="Photos" :disabled="!patchData">
                <b-container class="w-50">
                    <h6 v-if="!edit" class="text-info">Click Edit to Add/Update Reference Photos</h6>
                    
                      <b-form-file v-if="edit" ref="fileinput"  @change="postImage" v-model="pictureUpload" />
   
                      <br>
                </b-container>
                <b-container>
                <b-row v-if="displayItem == 'Loading' || displayItem == 'Compressing'"><b-col align="right" cols="auto" class="pt-1">
                  <span div class="spinner">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>      
                  </span></b-col><b-col align="left"><h4><strong>{{ displayItem }}</strong></h4></b-col></b-row>
                </b-container>
                <b-container fluid class="maxWidthContainer">
                      <b-carousel 
                                 v-if="ppObj.hydrantPicture"
                                 id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                 controls
                                 
                                 indicators
                                 background="#ababab"
                                v-model="slide"
                               >
                        <template v-for="(pic, index) in ppObj.hydrantPicture">
                        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic.url">
                            <p v-for="(details, index2) in pic.type" :key="index2">
                            <strong>{{ details.type }}:</strong> {{ details.location }}</p>
                        </b-carousel-slide>
                        </template>
                      </b-carousel>
                      </b-container>
                </b-tab>
                <b-tab title="Files" v-if="!responseView" :disabled="!patchData">
                  <b-container>
                    <b-row v-if="edit">
                      <b-col><b-form-input type="text" placeholder="Short File Title" v-model="shortTitleForFile" /></b-col>
                      <b-col><b-form-file v-model="fileForUpload" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."  @change="postDocumentFile($event, shortTitleForFile)"></b-form-file></b-col>
                      </b-row>
                      <b-table v-if="businessDocumentsArray" :fields="['title', 'author', 'uploaded']" :items="businessDocumentsArray">
                        <template slot="title" slot-scope="data">
                          <b-btn variant="link" @click="viewFile(data.item.url)">{{ data.item.title }}</b-btn>
                          </template>
                        </b-table>
                    </b-container>
                  </b-tab>
                <b-tab title="Previous Inspections" :disabled="!ppObj.hydrantInspectionData">
                  <b-container v-for="(inspection, index) in filteredHydrantInspections" :key="index">
                    <b-card :title="getCardTitle(inspection)">
                     <b-row>
                      <b-col class="mt-3" v-for="(input, indexd) in formInput" :key="indexd" :lg="input.size">
                        <b-row>
                        <b-col align="right" cols="*"><h6 class="font-weight-bold pt-1">{{ input.text}}:</h6></b-col>
                        <b-col align="left" :cols="input.type == 'textarea' ? '' : '*'">
                        <b-form-checkbox disabled v-if="input.type == 'boolean'" v-model="inspection[input.vmodel]" />
                        <span v-if="input.type == 'text' || input.type == 'number'"><h6 class="pt-1">{{ inspection[input.vmodel] }}</h6></span>
                        <b-textarea v-if="input.type == 'textarea'" v-model="inspection[input.vmodel]"  />
                        </b-col>
                        </b-row>
                      </b-col>
                        </b-row>
                    </b-card>

                  </b-container>
                </b-tab>
                <b-tab v-if="responseView" title="Switch to Edit View" @click="responseView ? setupResponseView() : ''">
                </b-tab>
                <b-tab v-if="false" title="holdValues">
                  {{ holdValues }}
                  </b-tab>
               <b-tab v-if="false"  title="Data Obj">
                  {{ ppObj }}
                  </b-tab>
              </b-tabs>
            </b-card>
          </b-container>
              </div>
      </b-container>
      <b-modal v-model="showDuplicateModal" title="Duplicate Options">
        <b-card header="Copy Data" bg-variant="primary" text-variant="white" class="text-center">
          <p v-if="!subBusinessId">Utilize this if you only wish to share data from one business to another new business. These are separate businesses that often excist under the same roof.
            </p>
          <p v-else>Utilize this if you want to copy this Business Sub, and Create a New Sub for the same parent</p>
          <b-btn variant="outline-light" @click="emitBusinessDuplicate(false)">Copy Data</b-btn>
          </b-card>
          <b-card header="Copy and Set as Sub"  bg-variant="success" text-variant="white" class="text-center mt-2" v-if="!subBusinessId">
            Utilize this if you wish to tag the sub, and link it back to this business. This is often use for seperate buildings that belong to the same business.
            <b-btn variant="outline-light" @click="emitBusinessDuplicate(true)">Set as Sub</b-btn>
            </b-card>
            <h6 v-if="subBusinessId" class="text-danger">This Business is already the sub of another business. If you wish to create another sub for the parent business you can copy this business data over. Only do this if you wish to create a sub attached to the original parent, otherwise you will have to create new business.</h6>
        </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { bus } from "../../main";
import Leaf from "leaflet";
import * as esri from "esri-leaflet";
import * as geocode from "esri-leaflet-geocoder"
import axios from "axios";
import printJS from "print-js";
import TutData from "../tracking/deptTutorialsdata.vue";
import DatePicker from "../inputfields/Datepickermod.vue";
import TimePicker from "../inputfields/timepicker.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import ImageCompressor from 'image-compressor.js'
import preplanMap from "./preplan/newHydrantMaps.vue";

export default {
  props: ["preplanId", "savedHydrantData", "viewItem", "formGroups", "formTableStyles"],
  components: {
    tutdata: TutData,
    timepicker: TimePicker,
    multiselect: Multiselect,
    datepicker: DatePicker,
    preplanMap
  },
  data() {
    return {
      uniqueKey: '',
      showTutVideo: false,
      allowMapPick: true,
      searchPerformed: false,
      showDuplicateModal: false,
      edit: true,
      slide: 0,
      responseView: false,
      buildingDataBar: true,
      imageResponseData: [],
      postData: "",
      timeData: "",
      imageSrc: "",
      showMap: false,
      cadDataSearch: '',
      imageType: "Streets",
      tempBuildingDataLatLng: '',
      mapViewOptions: [
        { text: "Ariel", value: "ImageryTransportation" },
        { text: "2D", value: "Streets" }
      ],
      formInput: [
           {vmodel:  'hydrantStatus', text: 'Hydrant Operable', type: 'boolean', default:  true, size: '3', inspectionFlag: false},
           {vmodel:  'hydrantValveStatus', text: 'Hydrant Valve Operable', type: 'boolean', default:  true, size: '3', inspectionFlag: false},
           {vmodel:  'HydrantMainValveSize', text: 'Main Valve Size', type: 'number', default: 0.0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantPumperOutletHeight', text: 'Pumper Outlet Height', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantStaticPressure', text: 'Static Pressure', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantResidualPressure', text: 'Residual Pressure', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantPitotReading', text: 'Pitot Reading', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantGpm', text: 'GPM', type: 'number', default: 0.0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantGpmTwenty', text: 'GPM@20PSI', type: 'number', default: 0.0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantFlowTime', text: 'Flow Time', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantPainted', text: 'Painted', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hydrantNewtag', text: 'New Tag', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hydrantNewrpm', text: 'New RPM', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hydrantNozzleOpen', text: 'Nozzle Open', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hyrdantFlushMinutes', text: 'Flush Time', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantComment', text: 'Hydrant Comment', type: 'textarea', default: '', size: '12', inspectionFlag: false},
           {vmodel:  'hydrantValveSize', text: 'Hydrant Valve Size', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveOpenDirection', text: 'Valve Open Direction', type: 'text', default: 'left', size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveTurns', text: '# of Valve Turns', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveDepth', text: 'Valve Depth', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveComment', text: 'Hydrant Valve Comment', type: 'textarea', default: '', size: '12', inspectionFlag: false},
        ],
      occupancyOptions: [
              {text: 'PLEASE SELECT OCCUPANCY TYPE', value: '', disabled: true},
              {text: 'Assembly (Fixed seating/Production/Viewing)', value: 'Assembly (Fixed seating / Production/Viewing)'},
              {text: 'Assembly (Restaurants/Nightclubs)', value: 'Assembly (Restaurants/Nightclubs)'},
              {text: 'Assembly (Other Assembly/Gyms/Museums)', value: 'Assembly (Other Assembly/Gyms/Museums)'},
              {text: 'Assembly (Arenas, Pools)', value: 'Assembly (Arenas, Pools)'},	
              {text: 'Assembly (Bleachers, Grandstands)', value: 'Assembly (Bleachers, Grandstands)'},
              {text: 'Business (Offices/Clinics/Goverment)', value: 'Business (Offices/Clinics/Goverment)'},
              {text: 'Educational (Schools ≤ grade 12)', value: 'Educational (Schools ≤ grade 12)'},
              {text: 'Industrial (Moderate Hazards)', value: 'Industrial (Factories with Moderate Hazards)'},
              {text: 'Industrial (Low Hazard, bricks, glass)', value: 'Industrial (Low Hazard, bricks, glass)'},
              {text: 'Storage (Storage with Moderate Hazards (furniture)', value: 'Storage (Storage with Moderate Hazards (furniture)'},
              {text: 'Storage (Storage with Low Hazards (canned goods)', value: 'Storage (Storage with Low Hazards (canned goods)'},
              {text: 'High Hazard (Detonation Hazard (explosives)', value: 'High Hazard (Detonation Hazard (explosives)'},
              {text: 'High Hazard (Accelerated Burning Hazard (flamm. gasses)', value: 'High Hazard (Accelerated Burning Hazard (flamm. gasses)'},
              {text: 'High Hazard (Supported Combustion Hazard (flamm. Solids)', value: 'High Hazard (Supported Combustion Hazard (flamm. Solids)'},
              {text: 'High Hazard (Health Hazards)', value: 'High Hazard (Health Hazards)'},
              {text: 'High Hazard (Semiconductor Fabrications)', value: 'High Hazard (Semiconductor Fabrications)'},
              {text: 'Medical Care Institutional (Nursing Homes)', value: 'Medical Care Institutional (Nursing Homes)'},
              {text: 'Board & Care (Personal Care Services & Lodging)', value: 'Board & Care (Personal Care Services & Lodging)'},
              {text: 'Day-Care (Care & Supervision (>24 hr)', value: 'Day-Care (Care & Supervision (>24 hr)'},
              {text: 'Detention & Correctional (Occupants Under Restraint)', value:  'Detention & Correctional (Occupants Under Restraint)'},
              {text: 'Mercantile (Display, Sale of Goods, Retail)', value: 'Mercantile (Display, Sale of Goods, Retail)'},
              {text: 'Residential (Hotels, Motels, Transient)', value: 'Residential (Hotels, Motels, Transient)'},
              {text: 'Residential (Dormitories)', value: 'Residential (Dormitories)'},
              {text: 'Residential (High Rise/Condo)', value: 'Residential (High Rise/Condo)'},
              {text: 'Residential (Multi-Family)', value: 'Residential (Multi-Family)'},
              {text: 'Residential (Apartments)', value: 'Residential (Apartments)'},
              {text: 'Residential (Boarding)', value: 'Residential (Boarding)'},
              {text: 'Residential (Board & Care, Rehab Facilities)', value: 'Residential (Board & Care, Rehab Facilities)'},
              {text: 'Special Structures (Towers, Tanks)', value: 'Special Structures (Towers, Tanks)'},
              {text: 'OTHER (DOCUMENTED IN TACTICAL PLAN', value: 'OTHER (DOCUMENTED IN TACTICAL PLAN'}
      ],
      constructionOptions: [
        {text: 'PLEASE SELECT CONSTRUCTION TYPE', value: '', disabled: true},
        {text: 'Type 1 (Fire Resistive)', value: 'Type 1 (Fire Resistive)'},
        {text: 'Type 2 (Non-combustible)', value: 'Type 2 (Non-combustible)'},
        {text: 'Type 3 (Ordinary)', value: 'Type 3 (Ordinary)'},
        {text: 'Type 4 (Heavy Timber)', value: 'Type 4 (Heavy Timber)'},
        {text: 'Type 5 (Wood Frame)', value: 'Type 5 (Wood Frame)'},
        {text: 'OTHER (DOCUMENTED IN TACTICAL PLAN', value: 'OTHER (DOCUMENTED IN TACTICAL PLAN'}
      ],
      gpsLocation: {},
      displayItem: '',
      pictureArraySelectionOption: '',
      refValueSwitch: "",
      stationSelectValue: "",
      showGroup: "Hydrant Location",
      showModalConfirmation: false,
      pictureUpload: "",
      searchData: "",
      holdMapObj: {},
      carouselToggle: "",
      showAll: true,
      pictureFileArray: [],
      businessDocumentsArray: [],
      shortTitleForFile: '',
      fileForUpload: '',
      imageLoader: "",
      holdValues: {},
      patchData: false,
      completedLoad: false,
      ppObj: {},
      cadData: [],
      cadReset: true,
      checkDisabled: true,
      addressData: [],
      hydrantFeatures: [],
      map: null,
      requiredValues: [],
      formValidationReset: true,
      buildingData: null,
      checkDisable: [],
      tempBuildingData: null,
      hydrantPointData: [],
      availableBusiness: [],
      subBusinessId: null,
    };
  },
  created() {
    this.uniqueKey = moment().toISOString() + '_business'
    this.currentDateSelected = moment().format("MM/DD/YYYY");
    this.currentDateSelectedChange = this.currentDateSelected;
    if (

      this.savedHydrantData == undefined ||
      this.savedHydrantData == null ||
      this.savedHydrantData == ""
    ) {
      this.buildppObjArray(this.formGroups);
    } else {
      this.ppObj = this.savedHydrantData
      this.buildCadData(this.formGroups)
      this.setPreSaveItems(this.savedHydrantData.hydrantData, this.formGroups);
      this.patchData = true;
    }
    if (this.viewItem == true) {
      this.edit = false;
    }
    if(this.formTableStyles){
      this.setupDisabledValues(this.formTableStyles)
    }
    if(this.savedHydrantData && !this.savedHydrantData.businessGisPoint){
      this.setUpGISForSave(this.savedHydrantData.hydrantMapData)
    }
  },
  mounted() {},
  computed: {
    ...mapState([
      "getSessionData",
      "stationsData",
      "apparatusData",
      "userData",
      "shiftsData",
      "preplanStatus",
      "siteconfig"
    ]),
    getFormatedTime(){
      var time = this.ppObj.hydrantLastInspection
      if(time){
        return moment(time).format('MM/DD/YY - HH:mm')
      } else {
        '***Updated Time Unavailabled'
      }
    },
    filteredHydrantInspections(){
       var previousData = _.cloneDeep(this.ppObj.hydrantInspectionData)
       var newest = _.orderBy(previousData, ['inspectionDate'], ['desc'])
       return newest
    },
    formatedDateExport() {
      var dateString = moment(this.currentDateSelected, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeString = dateString + "T" + this.currentTime;
      var offSet = moment().utcOffset();
      var hourOffSet = offSet * -1 / 60;
      var timeStringForm = moment(timeString).add(hourOffSet, "hours");
      var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
      return timeStringAdd + "Z";
    },
    todaysDate() {
      this.currentDateSelected = moment().format("MM/DD/YYYY");
      return this.currentDateSelected;
    },
    getStationParent() {
      if (
        this.stationSelectValue != "" &&
        this.stationSelectValue != undefined
      ) {
        var parent = this.stationSelectValue.stationParent;
        if (parent != "" && parent != undefined) {
          return parent;
        } else {
          return this.stationSelectValue.stationName;
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    imageSrc(data) {}
  },
  beforeDestroy() {},
  methods: {
    getCardTitle(data){
      var author = 'Unknown'
      var date = 'Unknown'
      if(data.inspectionAuthor){
        author = data.inspectionAuthor
      }
      if(data.inspectionDate){
        date = moment(data.inspectionDate).format('MM/DD/YY HH:mm')
      }
      return 'Inspected by ' + author + ' on ' + date
    },
    emitBusinessDuplicate(boo){
      var obj = _.cloneDeep(this.savedHydrantData)
      if(boo){
      obj['businessParent'] = this.savedHydrantData.id
      }
      obj.id = 'duplicate'
      this.$emit('duplicateBusiness', obj)
      this.showDuplicateModal = false
    },
    getSelectOptions(opt){
      return this[opt]
    },
    toggleBuildingDataBar(){
      this.buildingDataBar = !this.buildingDataBar
    },
    setupResponseView(){
      this.responseView = !this.responseView
    },
    requiredValuesCheck(required){
      var data = this.holdValues
      return required.filter(p => {
        var index = this.ppObj.hydrantData.map(i => i.label).indexOf(p)
        if(!this.holdValues[this.ppObj.hydrantData[index].vmodel].value){
          return p
        }
      })
    },
    checkForState(data){
      var index = this.checkDisable.indexOf(data)
      if(index >= 0){
        var holdvalue = this.holdValues[data].value == '' ? false : true
        this.$nextTick(function(){
        this.formValidationReset = true
        })
                return holdvalue
      } else {
        this.formValidationReset = true
        return null
      }

    },
    setupDisabledValues(data){
      var disabledValues = []
      for(var i in data){
        var values = data[i].values
        for(var i in values){
          var obj = this.ppObj.hydrantData.find(p => p.vmodel == values[i].vmodel)
          if(obj && obj.cadOpt != true){
          disabledValues.push(values[i].vmodel)
          var index = this.ppObj.hydrantData.map(p => p.vmodel).indexOf(values[i].vmodel)
          this.requiredValues.push(this.ppObj.hydrantData[index].label)
          }
        }
      }
      this.checkDisable = disabledValues
    },
    emitClosePreplan(){
      this.$emit('closeHydrant', this.savedHydrantData.id)
    },
    photoArrayUpdate(data){
      var photo = this.ppObj.hydrantPicture[data.photoIndex]
      if(data.add && photo.type){
        photo.type.push({id: data.item.id, type: data.item.type, location: data.item.properties.SERVICEADDRESS})
      } else if (data.add){
        photo.type = [{id: data.item.id, type: data.item.type}]
      } else {
        var index = photo.type.map(p => p.id).indexOf(data.item.id)
        if(index >= 0){
          photo.type.splice(index, 1)
        }
      }
    },
    updateFeatureData(data){
      var update = this.ppObj.hydrantMapData
      update[data.type] = data.data
    },
    setUpGISForSave(data){
      if(data && data.latlng){
            this.gpsLocation = data.latlng
      }
    },
    setBuildingData(data) {
  //    console.log(data)
      var obj = {}
      obj['latlng'] = data
      this.gpsLocation = data
      this.showMap = false
      this.tempBuildingData = null
      this.ppObj['hydrantMapData'] = obj;
        this.$nextTick(function(){
        this.showMap = true
      })
      this.edit = true
    },
    setBuildingLatLng(data){
      this.tempBuildingDataLatLng = {lat: data.lat, lng: data.lng}
    },
    viewMapBuild(data, boo){
      if(!boo){
      var canData = data.properties
      var cadvalues = {"StreetNumber3f1myd54" : "AddNum", "Streetbn8ry055": "StName", "Suffixrtum4856" : "StType", "Building32238ry055" : "BldgName", "Unitrtum4556" : "UnitName", "City7xgpvk57" : "City", "State5lm01g58": "RegionAbbr", "Ziplmanxr59" : "Postal"}
      var items = Object.keys(cadvalues)
      for(var i in items){
          var key = cadvalues[items[i]]
          if(canData[key] && this.holdValues[items[i]]){
            this.holdValues[items[i]].value = canData[key]
            this.updateValue(items[i], canData[key]) 
          }
      }
      }
      this.showMap = false
      this.tempBuildingData = data
      this.dataReset = false
      axios({
        method: "get",
        url: "/api/v1/preplan/business/find?lat=" + data.latlng.lat + '&lng=' + data.latlng.lng,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
         this.availableBusiness = response.data
      this.$nextTick(function(){
        this.showMap = true
      })
      });
    },
    mouseClickEvent(data) {
      console.log(data);
    },
    executePrintJS(event) {
      var string = "Fire Pre-Plan Report";
      return printJS({
        printable: "printJS-form",
        type: "html",
        header: string
      });
    },
    buildCadData(groups) {
      var array = [];
      for (var i in groups) {
        var field = groups[i].fields;
        for (var p in field) {
          if (field[p].cad == true) {
           array.push({
              cadValue: field[p].cadValue,
              vmodel: field[p].vmodel
            })
          }
        }
      }
      this.cadData = array
     this.checkForCadValues(array)
   },
    buildppObjArray(groups) {
      var array = [];
      for (var i in groups) {
        var field = groups[i].fields;
        for (var p in field) {
          var obj = { value: "", type: field[p].type };
           if(field[p].default){
            obj.value = field[p].default
          }
          this.holdValues[field[p].vmodel] = obj;
          var newObj = {
            vmodel: field[p].vmodel,
            idtag: field[p].idtag,
            value: "",
            label: field[p].label
          };
          if(field[p].default){
            newObj.value = field[p].default
          }
          if (field[p].type == "reference") {
            newObj["refItem"] = field[p].refitem;
          }
          if (field[p].searchIndex == true) {
            newObj["searchIndex"] = true;
          }
          if(field[p].cadOpt == true){
            newObj["cadOpt"] = true
          }
          if (field[p].type == "taxonomySingleSelect") {
            newObj["taxItem"] = field[p].taxitem;
          }
          if (field[p].cad == true) {
            this.cadData.push({
              cadValue: field[p].cadValue,
              vmodel: field[p].vmodel
            });
          }
          array.push(newObj);
        }
      }
      this.ppObj["hydrantData"] = array;
      this.ppObj['hydrantPicture'] = null
      this.ppObj['hydrantMapData'] = null

   },
    getIndexValues(data) {
      var obj = {};
      var fieldSort = data;
      for (var i in fieldSort) {
        if (fieldSort[i].searchIndex == true) {
          var key = fieldSort[i].vmodel;
          var value = fieldSort[i].value;
          if (value != undefined && value != null && value != "") {
            if (value == undefined) {
              value = "Not Entered";
            }
            if (fieldSort[i].type == "computed") {
              value = this.getComputedValues(fieldSort[i]);
            }
            if (fieldSort[i].taxItem) {
              var refval = value[fieldSort[i].taxItem];
              obj[key] = refval;
            
            } else {
              obj[key] = value;
              
            }
          }
        }
      }
      return obj;
    },
    getTableItems(display, fields) {
      var fieldSort = fields.filter(p => p.order == display);
      var obj = {};
      for (var i in fieldSort) {
        var key = fieldSort[i].label;
        var value = this.holdValues[fieldSort[i].vmodel].value;
        if (value == undefined) {
          value = "Not Entered";
        }
        if (fieldSort[i].type == "computed") {
          value = this.getComputedValues(fieldSort[i]);
        }
        if (fieldSort[i].type == "taxonomySingleSelect") {
          value = this.holdValues[fieldSort[i].vmodel].value[
            fieldSort[i].taxitem
          ];
        }
        if (fieldSort[i].type == "reference") {
          value = this.holdValues[fieldSort[i].reference].value[
            fieldSort[i].refitem
          ];
        }
        if (false && fieldSort[i].displayType && fieldSort[i].displayType == 'phone' && value){
        obj[key] = "<a href='tel:" + value + "'>" + value + "</a>" 
        } else {
        obj[key] = value
        }
      }
      var arr = [];
      arr.push(obj);
      return arr;
    },
    getComputedValues(data) {
      var array = [];
      for (var i in data.ref) {
        var hold = this.holdValues[data.ref[i]].value;
        if (hold != undefined && hold != null && hold != "") {
          array.push(hold);
        }
      }
      var operator = function(a, b) {
        return a * b;
      };
      var holdValue = 0;
      for (var i = 0; i < array.length; i++) {
        if (i == 0) {
          var item = operator(array[0], array[1]);

          holdValue = item;
        } else if (i > 1) {
          var item = operator(holdValue, array[i]);
          holdValue = item
        }
      }
      return holdValue;
    },
    setPreSaveItems(data, inputs) {
      var holdObj = {};
      for (var i in inputs) {
        var fields = inputs[i].fields;
        for (var p in fields) {
          var value = this.checkTextAreaValue(fields[p].vmodel);
          var obj = { value: value, type: fields[p].type };
          holdObj[fields[p].vmodel] = obj;
        }

      }
      this.holdValues = holdObj;
    },
    checkTextAreaValue(item) {
      var data = this.ppObj.hydrantData;
      var obj = data.find(p => p.vmodel == item);
      if (obj) {
        return obj.value;
      } else {
        return "";
      }
    },
    updateValue(item, value, field) {
      if (field != undefined && field.refBy != undefined) {
        this.updateRefValue(value, field);
      }
      if (field != undefined && field.cad == true) {
        this.checkForCadValues(this.cadData);
      }
      var data = this.ppObj.hydrantData.find(p => p.vmodel == item);
      if(data){
      data.value = value;
      } else {
        var newObj = {
            vmodel: field.vmodel,
            idtag: field.idtag,
            value: value,
            label: field.label
          };
        this.ppObj.hydrantData.push(newObj)
      }
     this.formValidationReset = false
      this.checkForState(item)
    },
    updateRefValue(value, field) {
      var ref = this.ppObj.hydrantData.find(p => p.vmodel == [field.refBy]);
      if (ref) {
        this.refValueSwitch = ref.vmodel;
        this.holdValues[ref.vmodel].value = value[ref.refItem];
        ref.value = value[ref.refItem];
        this.$nextTick(function() {
          this.refValueSwitch = "";
        });
      }
    },
    checkForCadValues(data) {
      var hold = this.holdValues;
      var returnNew = false;
      for (var i in data) {
        if (hold[data[i].vmodel]) {
          var value = hold[data[i].vmodel];
          if (value.value) {
            var obj = data[i];
            obj["value"] = value.value;
          } else {
            returnNew = true;
          }
        } else {
         if(this.savedHydrantData.hydrantData){
           var savedata = this.savedHydrantData.hydrantData
           var index = savedata.map(p => p.vmodel).indexOf(data[i].vmodel)
           if(index >= 0){
             var value = savedata[index].value
             if(value){
               var obj = data[i]
               obj["value"] = value
             }
           }
         }
        }
      }
      if (this.buildingData) {
        var returnNew = false;
      }
      if(this.ppObj.hydrantMapData){
        var returnNew = false
      }
      this.checkDisabled = returnNew; //returnNew
    },
    modifyCadData(data){
     
    },
    getDescendantProp(obj, desc) {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    },
    getFiles(obj) {
      this.postImage(obj);
    },
    clearFiles() {
      this.fileForUpload = ''
      this.$refs.fileinput.reset();
      this.$refs.fileinputSketch.reset();
      this.displayItem = ''
    },
    setCurrentDate() {
      this.currentDateSelected = moment(this.currentDateSelectedChange).format(
        "MM/DD/YYYY"
      );
    },
    setCadDataSearch(data){
     var string = ''
     for(var i in data){
       if(data[i].value != '' && data[i].value != undefined){
       string = string + data[i].value + ' '
       }
     }
    this.cadDataSearch = string
    },
    showUserGPSPosition(position){
      // console.log(position)
      this.showMap = false
      var data = {}
      data['latlng'] = {lat: position.coords.latitude, lng: position.coords.longitude}
      this.tempBuildingData = data
      this.$nextTick(function(){
        this.showMap = true
      })
     // console.log(position)
    },
    getUserGPSLocation() {
    if (navigator.geolocation) {
      var vm = this
        navigator.geolocation.getCurrentPosition(function(data){
          vm.showUserGPSPosition(data)});
    } else { 
        console.log("Geolocation is not supported by this browser.");
    }
    },
    getAddress(data){
      axios({
        method: 'get',
        url: '/api/v1/preplan/preplan/getarctoken',
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        //console.log(response.data)
     this.getAddressWithKey(data, response.data)
      })

    },  
    getAddressWithKey(data, key) {
      this.searchPerformed = true
      var vm = this
    //  console.log('GEO CODE')
      var arckey = key
     // console.log(arckey)
      axios({
        method: 'get',
        url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?singleLine=' + data + '&forStorage=true&token=' + arckey + '&outFields=*&f=pjson',
        headers: {
          "Content-Type": "application/json"
        }     
      }).then(response => {
         var array = response.data.candidates
       //  console.log(array)
         var filter = array.filter(p => {
           return p.score > 89.5
         })
         vm.addressData = filter.map(item => {
            var obj = {}
            obj.text = item.address
            obj.properties = item.attributes
            obj.score = item.score
            obj.latlng = {lat: item.location.y, lng: item.location.x}
            return obj

         })
      })
    },
    postImage(e){
      this.displayItem = 'Compressing'
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result)
        }
        })
      
     },
    postPreplanSketch(e){
      this.displayItem = 'Compressing'
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .8,
        success(result) {
         vm.postImageAxiosSketch(result)
        }
        })
      
     },
    postImageAxiosSketch(e) {
      this.displayItem = 'Uploading'
        var data = new FormData();
        data.append('uploadFile', e);
      axios({
        method: "post",
        url:
          "/api/v1/upload/files/business/" +
          this.ppObj.id +
          "/" +
          moment().format("YYYY-MM-DD"),
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "multipart/form-data"
        }
      }).then(response => {
        this.ppObj["businessBuildingSketch"] = response.data;
        this.clearFiles();
      });
    },
    postImageAxios(e) {
         this.displayItem = 'Uploading'
        var data = new FormData();
        data.append('uploadFile', e);
      axios({
        method: "post",
        url:
          "/api/v1/upload/files/business/" +
          this.ppObj.id +
          "/" +
          moment().format("YYYY-MM-DD"),
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "multipart/form-data"
        }
      }).then(response => {
        var url = response.data
        var type = this.pictureArraySelectionOption
        var obj = {url: url, type: type}
        this.ppObj.hydrantPicture.push(obj)
        this.carouselToggle = true
        this.pictureArraySelectionOption = ''
        this.clearFiles();
      });
    },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
               var a = document.createElement('a')
                    window.open(response.data.signedRequest, '_blank')
       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
    postDocumentFile(e, title){
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/business/' + this.userData.lastName + this.userData.employeeId + '-uploadfile/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
      this.businessDocumentsArray.push({ title: title, 
                                url: response.data, 
                                uploaded: moment().format('MM/DD/YY HH:mm'), 
                                author: this.userData.firstName + ' ' + this.userData.lastName + ' (' + this.userData.employeeId + ')'})

      this.$nextTick(function(){
        this.clearFiles();
        this.shortTitleForFile = ''
            })
        });
    },
    postNewPreplan(data) {
      var dataObj = data
      var holdObjTableData = this.setupTableValues(this.formTableStyles, dataObj)
      dataObj['hydrantTableData'] = holdObjTableData
      delete dataObj.id
      delete dataObj.createdAt
      delete dataObj.updatedAt
      delete dataObj.hydrantGisPoint
      if(this.gpsLocation){
        dataObj['gpsLocation'] = this.gpsLocation
      }
      //   dataObj['preplanSearchIndex'] = this.getIndexValues(dataObj, this.formGroups)
      axios({
        method: "post",
        url: "/api/v1/preplan/hydrant",
        data: dataObj,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.$emit('savedHydrantDataEmit', response.data[0])
      });
    },
    setupTableValues(table, data){
      var array = []
      for(var p in table){
        var key = table[p].key
        var values = table[p].values
        var label = table[p].label
        var holdValue = this.getValueForItem(data, values)
        array.push({key: key, value: holdValue, label: label})
      }
      if(this.ppObj.hydrantMapData){
        if(this.ppObj.hydrantMapData.latlng){
          array.push({key: 'map', obj: this.ppObj.hydrantMapData.latlng, point: [this.ppObj.hydrantMapData.latlng.lng, this.ppObj.hydrantMapData.latlng.lat]})
        }
      }
      return array
    },
    getValueForItem(item, values){
      var data = item.hydrantData
      var arrayString = ''
      for(var i in values){
        var dataobj = data.find(p => p.vmodel == values[i].vmodel) 
        if(dataobj && dataobj.taxItem){
          var arrayString = arrayString + ' ' + dataobj.value[dataobj.taxItem]
        } else {
          var end = dataobj && dataobj.value ? dataobj.value : ''
          if(end && values[i].prefix){
            end = values[i].prefix + end
          }
          if(values[i].postfix){
            end = end + values[i].postfix
          }
          if(end){
          var arrayString = arrayString + ' ' + end
          }
        }
      }
      return arrayString
    },
    postUpdatePreplan() {
      var dataObj = this.ppObj;
      this.showMap = false
      var holdObjTableData = this.setupTableValues(this.formTableStyles, dataObj)
      dataObj['hydrantTableData'] = holdObjTableData
      if(this.gpsLocation && this.gpsLocation.lat && this.gpsLocation.lng){
        dataObj['gpsLocation'] = this.gpsLocation
      }
     // dataObj["preplanSearchIndex"] = this.getIndexValues(dataObj.hydrantData);
      axios({
        method: "patch",
        url: "/api/v1/preplan/hydrant/" + dataObj.id,
        data: dataObj,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.ppObj = response.data[0];
        this.setPreSaveItems(response.data[0].hydrantData, this.formGroups)
        this.patchData = true
        this.showMap = true
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.overflowData {
  overflow: hidden;
    position: fixed;
    z-index: 900;
}
.fullScreenSetup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8000;
}
.maxWidthContainer {
  max-width: 500px;
}
.invalidState {
  border: 1px solid red;
  border-radius: 5px;
}
.validState {
  border: 1px solid green;
  border-radius: 5px;
}
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.textAreaDescription {
  margin-top: 10px;
}
.iconAddMapp {
  width: 50px;
}
span {
  margin-bottom: 10px;
}
.multiselect {
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
  border: 1px solid #bbb;
}
#tablePassDown {
  margin: 0;
  padding: 0;
}
.sub-section-heading {
  color: White;
  background: #aaa;
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0;
  margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
  padding: 5px;
}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;
}
.addNewArticle:hover {
  background: grey;
}
.timeMobile {
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk {
    display: none;
  }
  .timeMobile {
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}
</style>
