<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
    <b-card>
      <b-row> 
        <b-col lg="4">
          <h4>Bank Name</h4>
        <b-form-input type="text" v-model="dataObj.payrollBankName" />
        </b-col>
        <b-col lg="4">
          <h4>Bank Rollover</h4>
        <b-form-input type="number" v-model="dataObj.payrollBankRollover" /> 
        </b-col>
         <b-col lg="4" v-if="false">
          <h4>Bank Rollover End</h4>
        {{ dataObj.payrollBankRolloverEnd }}
        </b-col>
        <b-col class="pt-4"><b-btn  @click="newBank">Submit</b-btn></b-col>
        </b-row>

    </b-card>
    <b-table :fields="fields" :items="payrollBanksData">
    <template slot="payrollBankName" slot-scope="data">
      <span v-if="editItem != data.item.id">{{ data.item.payrollBankName }}</span>
      <b-form-input v-if="editItem == data.item.id" type="text" v-model="data.item.payrollBankName" />
      </template>
     <template slot="payrollBankRollover" slot-scope="data">
      <span v-if="editItem != data.item.id">{{ data.item.payrollBankRollover }}</span>
      <b-form-input v-if="editItem == data.item.id" type="number" v-model="data.item.payrollBankRollover" />
      </template>
      <template slot="Edit" slot-scope="data">
      <b-btn v-if="editItem != data.item.id" @click="editItem = data.item.id" variant="warning" size="sm">Edit</b-btn>
      <b-btn v-if="editItem == data.item.id" @click="updateBankMod(data.item)" variant="info" size="sm">Update</b-btn>
      <b-btn v-if="editItem == data.item.id" @click="editItem = ''" variant="danger" size="sm">Cancel</b-btn>
      </template>
      </b-table>
</div>
</div>

</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
export default {
  components: {
    multiselect
  },
  name: 'roster-admin',
  props: ['payrollCodesData', 'payrollBanksData'],
  data () {
    return {
      apparatusSelectValue: null,
      stationSelectValue: '',
      editItemId: '',
      stationParentSelectValue: '',
      bankModId: '',
      editItem: '',
      dataObj: {
          payrollBankName: '',
          payrollBankRollover: 0,
          payrollBankRolloverEnd: 'yearend'
      },
      payrollColorOptions: ['default', 'Yellow', 'black', 'bright-blue', 'blue', 'bright-green', 'green', 'bright-orange', 'orange', 'bright-red', 'red', 'bright-purple', 'purple'],
      payrollCodeVisibilityOptions: [{text: 'admin', value: 'adminchief'}, 'fireops', 'emsops', 'shopops', 'trainingops', 'logisticsops', 'supervisor', 'user' ],
      fields: [
        //'id',
          'payrollBankName',
          'payrollBankRollover',
          'Edit'
        //  'payrollBankRolloverEnd'
      ],
      bankModType: 'addTo',
      codeTypeOptions: [
        'bank modification',
        'exchange',
        'roster mod',
        'scheduled leave',
        'unscheduled leave',
        'payaddative',
        'overtime',
        'overtime availability',
        'unpaid leave',
        'none'
      ]
    }
  },
  created(){
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        bankModOptions(){
          var options = this.payrollBanksData.map(item => {
            var obj = {}
            obj['text'] = item.payrollBankName
            obj['value'] = item.id
            return obj
          })
         // console.log(options)
          return options
        },
        payrollCodesDataWithEdit(){
          var array = this.payrollCodesData.map(p => {
            var obj = p
            if(obj.payrollCodeBankMod == null){
              obj.payrollCodeBankMod = []
            }
            if(obj.payrollCodeVisibility == null || obj.payrollCodeVisibility == undefined){
              obj.payrollCodeVisibility = []
            }
            obj['edit'] = false
            if(obj.payrollCodeVisibility == null || obj.payrollCodeVisibility == undefined || obj.payrollCodeVisibility.length == 0){
              obj.payrollCodeVisibility = ['admin', 'adminchief', 'fireops']
            }
            if(obj.payrollCodeApprovalRole == null || obj.payrollCodeApprovalRole == undefined || obj.payrollCodeApprovalRole.length == 0){
              obj.payrollCodeApprovalRole = ['admin', 'adminchief', 'fireops']
            }
            return obj
          })
          return array
        }
       
  },
  mounted(){

  },
  watch: {

  },
  methods: {
        getBankName(id){
   
      var obj = this.payrollBanksData.find(p => p.id == id)
      if(obj && obj.payrollBankName){
        return obj.payrollBankName
      } else {
        return 'Name Not Found'
      }
    },
    updateBankMod(data){
    axios({
     method: 'patch',
     url: '/api/v1/staffing/payrollbank/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       bus.$emit('getPayrollBanks')
       this.editItem = ''
   })

    },
    newBank(){
    axios({
     method: 'post',
     url: '/api/v1/staffing/payrollbank/',
     data: this.dataObj,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       bus.$emit('getPayrollBanks')
   })

    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
