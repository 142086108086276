<template>
<div>
  <div class="headerSpanStaffing" v-if="!tvView">
    <span class="float-left">
      <b-btn variant="danger" class="ml-1" @click="returnToTrainingPage">Return to Training Page</b-btn>
    </span>

    Credential Audit

  </div>

    <b-card no-body>
      <b-tabs card>
      <b-tab  v-for="tab in credentialTabs" :key="tab.id" :title="tab.title">
     <b-table v-if="tablereset &&  tab.type == 'option'" class="credential-table" :fields="fields" :items="credentialsDataModifiedFunction(tab.return)">
      <template slot="credentialStartDate" slot-scope="data">
        {{ getDateFormat(data.item.credentialStartDate)}}
      </template>
            <template slot="credentialEndDate" slot-scope="data">
        {{ getDateFormat(data.item.credentialEndDate)}}
      </template>
      <template slot="credentialAssignedUsers" slot-scope="data">
       <span v-if="data.item.credentialAssignedUsers"> {{ data.item.credentialAssignedUsers.length }} </span>
      </template>
      <template slot="View" slot-scope="data">
        <b-btn variant="primary" v-if="data.item.credentialAssignedUsers" :disabled="checkForOpenReport(data.item.id)" size="sm" @click="getUsersTraining(data.item)">Run Report</b-btn>
      </template>
      </b-table>
      <div v-if="tab.type == 'report'">
            <credentialReport :dataUser="tab.data" :credentialRequirement="credentialRequirement" :credentialsData="credentialsData" :trainingDrills="trainingDrills" @closeTab="closeTab" />
       </div>
      </b-tab>
      </b-tabs>
      </b-card>
</div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'
import credentialReport from './trainingReport/trainingReportingCredential.vue'
export default {
  props:['refresh', 'userId'],
components:{
  credentialReport,
  'download-excel' : JsonExcel
},
  data () {
    return {
      credentialsData: [],
      currentPage: 1,
      credentialRequirement: [],
      usersTraining: [],
      activeTab: '01',
      userReport: null,
      credentialTabs: [
        {id: '01', title: 'Current Credentials', return: 'current', type: 'option'},
        {id: '02', title: 'Previous Credentials', return: 'past', type: 'option'},
      ],
      fields: [
        {key: 'credentialName', label: 'Name' },
        {key: 'credentialStartDate', label: 'Start'},
        {key: 'credentialEndDate', label: 'End'},
        {key: 'credentialAssignedUsers', label: 'Users'},
        'View'
      ],
      fieldsSub: [
        {key: 'credentialRequirementName', label: 'Requirement'},
        {key: 'credentialRequirementHours', label: 'Hours'},
        'Completed'
      ],
      trainingDrills: [],
      loadingIndicator: null,
      checkId: '',
      checkIfLoaded: false,
      tablereset: true,
      credentialsDataModified: [],
      userReportCredentials: [],
      userReportFields: [
          'firstName',
          'lastName',
          'employeeId',
          'stationAssignment',
          'shiftAssignment'
      ],
   
      userReportTable: [],
    }
  },
  created(){
    this.selectedDate = moment().format('YYYY-MM-DD')
    if(this.userId){
      this.checkId = this.userId
    } else {
      this.checkId = this.userData.id
    }
    this.getCredentialRequirements()
    this.getCredentials()
    this.getTrainingModules()
  },
  watch: {
   
  },
  computed:{
    ...mapState([
    'getSessionData',
    'deptContactData',
    'stationsData',
    'userData',
    'config',
    'userPosts'
    ]),

    },
    methods: {
      returnToTrainingPage(){
        this.$router.push({path: '/portal/training?active=trainingtrack'})
      },
      checkForOpenReport(id){
        var index = this.credentialTabs.map(p => p.id).indexOf(id)
        if(index != -1){
          return true
        } else {
          return false
        }
      },
      getDateFormat(data){
        if(data){
        return moment(data).format('MM/DD/YY')
        } else {
          return ''
        }
      },
      getCredentials(){
      axios({
        method: 'get',
        url: '/api/v1/training/credential/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.setupCredentialTable(response.data)
      })
    
    },
    setupCredentialTable(data){
      var array = []
      var parents = [... new Set(data.map(p => p.credentialParent))]
      for(var i in parents){
        if(parents[i]){
        var obj = {}
        var name = data.find(p => p.id == parents[i])
        obj['credentialName'] = name ? name.credentialName : 'Unknown'
        obj['_rowVariant'] = 'info'
        obj['_showDetails'] = false
        array.push(obj)
        var child = data.filter(p => {
          return p.credentialParent == parents[i]
        })
        for(var c in child){
          var cobj = child[c]
          cobj['_showDetails'] = true
          array.push(cobj)
        }
        }
      }
        this.checkIfLoaded = true
      this.credentialsData = array
    },
    credentialsDataModifiedFunction(datemod){
      var date = this.selectedDate
      return this.credentialsData.filter(p => {
        if(datemod == 'current'){
          var initial = p.credentialStartDate
          var stop = p.credentialEndDate
          if(initial == undefined || stop == undefined){
            return p
          } else {
          var start = moment(initial).isBefore(date, 'YYYY-MM-DD')
            var end = moment(stop).isAfter(date, 'YYYY-MM-DD')
            if(start && end){
              return p
            }
          }
      } else {
          var initial = p.credentialStartDate
          var stop = p.credentialEndDate
          if(initial == undefined || stop == undefined){
            return p
          } else {
          var start = moment(initial).isBefore(date, 'YYYY-MM-DD')
            var end = moment(stop).isBefore(date, 'YYYY-MM-DD')
            if(start && end){
              return p
            }
          }
      }
      })
      

    },
    getCredentialRequirements(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
       this.credentialRequirement = response.data
      })
    },
    getTrainingModules(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingmodule/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.trainingDrills = response.data

      })
    },
    closeTab(data){
      this.activeTab = '01'
      var index = this.credentialTabs.map(p => p.id).indexOf(data)
      if(index != -1){
        this.credentialTabs.splice(index, 1)
      }
    },
    getUsersTraining(data){
      this.credentialTabs.push({id: data.id, title: data.credentialName + ' (' + this.getDateFormat(data.credentialEndDate) + ')' , type: 'report', data: data})
    },

    



},

}
</script>

<style scoped>
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #000;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}

.credential-table {
  text-align: center;
}
.changeWidth {
  width: 80%;
  margin: 0 auto 0;
  text-align: center;
}
.userPostIcon{
  position: relative;
  margin: 0px 0 -80px 0px;
  width:32px;
  height:32px;
  opacity: 0.3;
  
}
.blogadjust {
  text-align: center;
    width: 80%;
    margin: 0 auto 0;
}
.userPostForms{
  width:80%;
  padding-top: 10px;
  margin: 0 auto 0;
  border-bottom: 1px solid #aaa;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}



#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {
  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
@media screen and (max-width: 768px) {
.userPostIcon{
    display: none;
  
}
}

</style>
