<template>
  <div id="appCalendar" v-if="stationsData.length > 0 && apparatusData.length > 0">
      <div>
        <div class="headerSpan" v-if="stationName">{{ nameResolve(stationName) }} 
           <span class="floatRight">
      <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief'" size="sm" @click="showAdmin = !showAdmin" :variant="showAdmin ? 'light' : 'outline-light'">Admin</b-btn>
        </span>
        </div>
        <div class="headerSpan" v-if="apparatusName">{{ nameResolve(apparatusName) }}</div>
        <stationAdmin v-if="showAdmin" />
      <b-container fluid v-if="!showAdmin">
        <b-row align-h="center">
        <b-col lg="auto">
        <b-container fluid class="calendarSearchHouse px-0">
          <b-card no-body>
              <b-tabs ref="tabs" card>
                <b-tab title="Board" active>
                <div class="trackingBoardWrapper" v-if="!viewPrevious">
                <h4 class="mt-2">Tracking Board</h4>
                <span v-if="noteData && noteData.updatedAt && noteData.noteAuthor"><em>Last Updated at {{ noteDataFormat(noteData.updatedAt) }} <br> by {{ noteData.noteAuthor }}</em></span>
                 <vue-editor v-if="userData.role != 'user'" class="textAreaDescription" :editorToolbar="customToolbar"  v-model="detailedDescription"></vue-editor>
                 <b-btn v-show="!(detailedDescription == this.noteData.noteBody)" v-if="userData.role != 'user' && noteData != null" :disabled="detailedDescription == this.noteData.noteBody" size="sm" variant="primary" @click="updateNote(detailedDescription, noteData)">Update Board</b-btn>
                 <b-btn v-if="userData.role != 'user' && noteData == null" :disabled="detailedDescription == ''" size="sm" variant="primary" @click="createNewNote(detailedDescription)">Add Board</b-btn>
                 <b-container class="textAreaDescription mb-2"><div v-if="userData.role == 'user'" v-html="detailedDescription" /></b-container>
                </div>
                <b-btn variant="link" v-if="previousNotes.length > 0" @click="viewPrevious = !viewPrevious, viewPreviousNoteId = ''">{{ viewPrevious ? 'View Current' : 'View Previous' }}</b-btn>
                <div class="trackingBoardWrapper" v-if="viewPrevious">
                <h4 class="mt-2">Previous Notes</h4>
                <div v-for="note in previousNotesReturned" :key="note.id">
                <b-btn variant="link" @click="viewPreviousNoteId = note.id">{{ getFormatedDate(note.updatedAt) }} | {{ note.noteAuthor }}</b-btn>
                <div v-if="viewPreviousNoteId == note.id && note.noteBody" v-html="note.noteBody" class="textAreaDescription" />
                <div v-if="viewPreviousNoteId == note.id && note.noteBody == ''" class="text-danger">Note Cleared</div>
                </div>
               <b-pagination  align="center" v-if="viewPrevious && previousNotes.length > 5" v-model="currentPage" :total-rows="previousNotes.length" :per-page="5" size="sm"></b-pagination>
                <b-container class="textAreaDescription mb-2"><div v-if="userData.role == 'user'" v-html="detailedDescription" /></b-container>
                </div>
                  </b-tab>
                <b-tab title="Date Filter" @click="toggleDate">
                  <div id="labelDateSortDiv" :class="{datePickerClass: minimizeFilterPan}">
                    <b-row>
                      <b-col class="datePickerClassMobile MobileToolbar">
                        <span v-show="false">{{ formatedBootStrapDate }}</span>
                    <b-form-input v-model="bootstrapDate" type="date" @input="updateDate" size="sm" />
                  </b-col>

                </b-row>

                  </div>
                  <datepicker
                  class="datePickerClass"
                    v-if="datePanel"
                    id="datepickervue"
                    :disabled="disabledDate"
                    v-model="dateSelected"
                    :inline='true'
                    name="inlineCal">
                  </datepicker>
                </b-tab>
                <b-tab title="Log Filter" @click="toggleList">
                  <div id="secondaryFilterPanel" class="secondaryFilterButtons"  :class="{datePickerClass: minimizeFilterPan}">
                    <div  id="labelAllDiv">
                      <b-btn-group>
                        <b-btn @click="toggleVehicle"  v-show="sortVehicle">Vehicle</b-btn>
                        <b-btn @click="toggleVehicle" v-show="!sortVehicle" variant="outline">Vehicle</b-btn>
                        <b-btn @click="toggleLogbook" v-show="sortLogbook" v-if="calendarAppType == 'station'">Logbook</b-btn>
                        <b-btn @click="toggleLogbook" v-show="!sortLogbook" v-if="calendarAppType == 'station'" variant="outline">Logbook</b-btn>
                        <b-btn @click="toggleLogbook" v-show="sortLogbook" v-if="calendarAppType == 'apparatus'">NarcLog</b-btn>
                        <b-btn @click="toggleLogbook" v-show="!sortLogbook" v-if="calendarAppType == 'apparatus'" variant="outline">NarcLog</b-btn>
                        <b-btn @click="toggleRepair"  v-show="sortRepair">Repairs</b-btn>
                        <b-btn @click="toggleRepair" v-show="!sortRepair" variant="outline">Repairs</b-btn>
                      </b-btn-group>
                    </div>

                      <b-container id="secondaryFilter" >


                            <b-form-input type="text" v-model="searchText" placeholder="Title Keyword"/>
                            <b-form-select v-model="searchShift" :options="shiftSelections" />
                            <div v-if="stationLogbooks.length > 0">
                            <b-form-select v-show="sortLogbook" :options="filterLogbookAuthors" v-model="filterAuthor"  v-if="calendarAppType == 'station'" />
                            </div>
                            <div v-if="apparatusAppNarcs.length > 0">
                              <b-form-select v-show="sortLogbook" :options="filterNarcAuthors" v-model="filterAuthor" v-if="calendarAppType == 'apparatus'" />
                            </div>
                             <div v-if="apparatusDailyAppNarcs.length > 0">
                              <b-form-select v-show="sortLogbook" :options="filterDailyNarcAuthors" v-model="filterAuthor" v-if="calendarAppType == 'apparatus'" />
                            </div>
                            <div v-if="vehicleChecks.length > 0">
                            <b-form-select v-show="sortVehicle" :options="filterVehicleCheckAuthors" v-model="filterAuthor" />
                          </div>

                          <div v-if="stationRepair.length > 0">
                            <b-form-select v-show="sortRepair" :options="filterRepairAuthors" v-model="filterAuthor" />
                          </div>
                          <div v-if="stationRepair.length > 0">
                            <b-form-select v-show="sortRepair" :options="filterRepairApparatus" v-model="filterApparatus" />
                          </div>
                          <div v-if="vehicleChecks.length > 0">
                            <b-form-select v-show="sortVehicle" :options="filterVehicleCheckApparatus" v-model="filterApparatus" />
                          </div>
                            <div 
                                    v-if="stationName == 'battalion-1'
                                      || stationName == 'battalion-2'
                                      || stationName == 'battalion-3'
                                      || stationName == 'battalion-4'">
                              <b-form-select  v-if="sortLogbook" :options="filterLogbookStations" v-model="filterStation" />
                              <b-form-select  v-if="sortVehicle" :options="filterVehicleCheckStations" v-model="filterStation" />
                              <b-form-select  v-if="sortRepair" :options="filterRepairStations" v-model="filterStation" />
                            </div>

                      </b-container>
                    </div>
                </b-tab>
                <b-nav-item  class="datePickerClassMobile" size="sm" slot="tabs" @click="minimizeFilterPan = !minimizeFilterPan">
                _
              </b-nav-item>

              </b-tabs>
          </b-card>
        </b-container>
        </b-col>
      <b-col class="panelCol" lg="7" xl="5" >
      <div class="shiftListHouse">
        <shiftlist
            v-if="reloadView"
            :stationVarId="stationVarId"
            :dailyEvents="dailyEvents"
            :dailyLogs="dailyLogs"
            :sortVehicle="sortVehicle"
            :sortLogbook="sortLogbook"
            :sortRepair="sortRepair"
            :filterDate='formatedDate'
            :calendarEvents='calendarEvents'
            :typePanel="typePanel"
            :datePanel="datePanel"
            :searchText="searchText"
            :searchShift="searchShift"
            :filterAuthor="filterAuthor"
            :currentLogType="currentLogType"
            :filterStation="filterStation"
            :filterApparatus="filterApparatus"
            :calendarAppType="calendarAppType"
            :keepJournalOpen="keepJournalOpen"
        ></shiftlist>
          </div>
        </b-col>
        <b-col xl="3" v-if="stationName">
            <personnel v-if="reloadView" :stationName="stationName" :filterDate="formatedDate" />
        </b-col>
                <b-col v-if="apparatusName">
                 <apparatusinfo :apparatusName="apparatusName" />
                 </b-col>
       
      </b-row>
      </b-container>
    </div>

    </div>


</template>

<script>
import moment from "moment";
import Datepicker from "./inputfields/Datepickermod.vue";
import axios from "axios";
import ShiftList from "./CalendarAppComponent/ShiftList.vue";
import StationCharts from "./CalendarAppComponent/stationcharts.vue";
import ApparatusInfo from "./CalendarAppComponent/apparatusData.vue";
import stationAdmin from "./admin/adminStations";
import { bus } from "./../main";
import { mapState } from "vuex";
import Personnel from "./tracking/personnel/personnelData.vue";
import { VueEditor } from 'vue2-editor'

export default {
  name: "calendarapp",
  props: ["stationName", "apparatusName"],
  components: {
    personnel: Personnel,
    stationAdmin: stationAdmin,
    datepicker: Datepicker,
    shiftlist: ShiftList,
    apparatusinfo: ApparatusInfo,
    stationcharts: StationCharts,
    VueEditor,
  },
  data() {
    return {
      minimizeFilterPan: false,
      dateSelected: this.todaysDate,
      bootstrapDate: "",
      shiftDates: [],
      showAdmin: false,
      reloadView: true,
      disabledDate: {
        to: new Date("01/01/2017")
      },
      previousNotes: [],
      viewPrevious: false,
      viewPreviousNoteId: '',
      sideBarHide: true,
      sideBarShow: false,
      datePanel: true,
      typePanel: false,
      keepJournalOpen: false,
      dailyEvents: true,
      dailyLogs: true,
      sortVehicle: false,
      sortLogbook: true,
      sortRepair: false,
      currentPage: 1,
      searchText: "",
      searchShift: "",
      filterAuthor: "",
      filterStation: "",
      filterApparatus: "",
      postResponsetoken: "",
      calendarAppType: "",
      currentLogType: "",
      currentAppType: "",
      stationVarId: '',
      stationID: undefined,
      apparatusID: undefined,
      customToolbar: [
          ['bold', 'italic', 'underline', 'strike'],
        ],
      detailedDescription: '',
      noteData: ''
    };
  },
  created() {
    bus.$on("refresh-calendar-list", this.refreshModalSwitch);
    this.refreshModalSwitch()
    this.bootstrapDate = moment(this.dateSelected).format("YYYY-MM-DD");
    bus.$on('changeMonthSelection', this.updateDate)
    bus.$on('keepJournalOpen', this.keepJournalOpenToggle)
  },
  mounted(){

  },
  watch: {
    stationsData(newData){
       this.refreshModalSwitch()
    },
    apparatusData(newData){
      this.refreshModalSwitch()
    },
    stationName(newdata) {
      if(newdata && this.stationsData){
      this.watchStationName();
      } else if(newdata){
        var station = newdata
        this.stationName == null
        this.$nextTick(function(){
          this.stationName = newdata
        })
      }
    }, 
    apparatusName(newdata) {
      if(newdata){
      this.watchApparatusName();
      }
    },
    filterRepairAuthors(newData) {
      if (this.filterRepairAuthors.length > 0) {
        this.filterRepairAuthors.push({ value: "", text: "Filter by Author" });
      } else {
        return;
      }
    },
    filterLogbookAuthors(newData) {
      if (this.filterLogbookAuthors.length > 0) {
        this.filterLogbookAuthors.push({ value: "", text: "Filter by Author" });
      } else {
        return;
      }
    },
    filterNarcAuthors(newData) {
      if (this.filterNarcAuthors.length > 0) {
        this.filterNarcAuthors.push({ value: "", text: "Filter by Author" });
      } else {
        return;
      }
    },
    filterDailyNarcAuthors(newData) {
      if (this.filterDailyNarcAuthors.length > 0) {
        this.filterDailyNarcAuthors.push({ value: "", text: "Filter by Author" });
      } else {
        return;
      }
    },
    filterVehicleCheckAuthors(newData) {
      if (this.filterVehicleCheckAuthors.length > 0) {
        this.filterVehicleCheckAuthors.push({
          value: "",
          text: "Filter by Author"
        });
      } else {
        return;
      }
    },
    filterVehicleCheckApparatus(newData) {
      if (this.filterVehicleCheckApparatus.length > 0) {
        this.filterVehicleCheckApparatus.push({
          value: "",
          text: "Filter by Apparatus"
        });
      } else {
        return;
      }
    },
    filterRepairApparatus(newData) {
      if (this.filterRepairApparatus.length > 0) {
        this.filterRepairApparatus.push({
          value: "",
          text: "Filter by Apparatus"
        });
      } else {
        return;
      }
    },
    filterLogbookStations(newData) {
      if (this.filterLogbookStations.length > 0) {
        this.filterLogbookStations.push({
          value: "",
          text: "Filter by Station"
        });
      } else {
        return;
      }
    },
    filterRepairStations(newData) {
      if (this.filterRepairStations.length > 0) {
        this.filterRepairStations.push({
          value: "",
          text: "Filter by Station"
        });
      } else {
        return;
      }
    },
    filterVehicleCheckStations(newData) {
      if (this.filterVehicleCheckStations.length > 0) {
        this.filterVehicleCheckStations.push({
          value: "",
          text: "Filter by Station"
        });
      } else {
        return;
      }
    },
    formatedDate(newDate, oldDate){
      var newTest = moment(newDate, 'MM/DD/YYYY').format('MM')
      var oldTest = moment(oldDate, 'MM/DD/YYYY').format('MM')
      if(newTest != oldTest){
        if(this.calendarAppType == 'station'){
          this.watchStationName()
        } else if (this.calendarAppType == 'apparatus'){
          this.watchApparatusName()
        }
      }
    }

    /*  this.stationDataPull(this.stationName) */
  },
  computed: {
    ...mapState([
      "getSessionData",
      "stationsData",
      "eventTags",
      "apparatusData",
      "userData",
      "siteconfig"
    ]),
    ...mapState({
      calendarEvents: "stationCalEvents",
      vehicleChecks: "stationCalVehicleChecks",
      stationLogbooks: "stationCalLogbooks",
      stationCalInventorySub: "stationCalInventorySub",
      stationRepair: "stationCalRepair",
      stationImages: "stationCalImages",
      stationStatistics: "stationCalStatistics",
      apparatusAppNarcs: "apparatusAppNarcs",
      apparatusDailyAppNarcs: "apparatusDailyAppNarcs"
    }),
    shiftSelections(){
      var shifts = _.cloneDeep(this.siteconfig.payroll.shifts)
      shifts = _.orderBy(shifts, ['shift'], ['asc'])
      if(shifts){
        var array = [{value: "", text: "Filter by Shift"}]
        for(var s in shifts){
          var obj = {value: shifts[s].shift, text: shifts[s].shift }
          array.push(obj)
        }
        return array
      }
      var array = [
        { value: "", text: "Filter by Shift" },
        { value: "A-shift", text: "A-Shift" },
        { value: "B-shift", text: "B-Shift" },
        { value: "C-shift", text: "C-Shift" }
      ]
      return array
    },   
    previousNotesReturned(){
      var array = _.cloneDeep(this.previousNotes)
      if(array.length > 0){
        array.splice(0, 1)
        var start = (this.currentPage - 1) * 5
        var returned = array.splice(start, start + 5)
      }
      return returned
    },
    stationImageUrl() {
      var base = this.baseUrl;
      var imageSrcData =
        this.stationImages.length > 0
          ? this.stationImages[0].field_station_logo
          : "";
      var imageSrc = imageSrcData;
      return base + imageSrc;
    },
    filterShifts() {
      return this.shiftDates.filter(shiftdate => {
        return shiftdate.date.match(this.formatedDate);
      });
    },
    formatedDate(dateSelected) {
      return moment(this.dateSelected).format("MM/DD/YYYY");
    },
    formatedBootStrapDate() {
      this.bootstrapDate = moment(this.dateSelected).format("YYYY-MM-DD");
    },
    todaysDate() {
      return moment().format("MM/DD/YYYY");
    },
    filterLogbookAuthors() {
      if (this.stationLogbooks.length > 0) {
        var unique = [
          ...new Set(this.stationLogbooks.map(p => p.logbookAuthor))
        ];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    },
    filterNarcAuthors() {
      if (this.apparatusDailyAppNarcs.length > 0) {
        var unique = [...new Set(this.apparatusDailyAppNarcs.map(p => p.narctrackAuthor))];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return []
      }
    },
    filterDailyNarcAuthors() {
      if (this.apparatusAppNarcs.length > 0) {
        var unique = [...new Set(this.apparatusAppNarcs.map(p => p.narctrackAuthor))];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return []
      }
    },
    filterVehicleCheckAuthors() {
      if (this.vehicleChecks.length > 0) {
        var unique = [
          ...new Set(this.vehicleChecks.map(p => p.dailyvehicleAuthor))
        ];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    },
    filterRepairAuthors() {
      if (this.stationRepair.length > 0) {
        var unique = [...new Set(this.stationRepair.map(p => p.repairformAuthor))];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    },
    filterLogbookStations() {
      if (this.stationLogbooks.length > 0) {
        var unique = [
          ...new Set(this.stationLogbooks.map(p => p.logbookStation))
        ];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    },
    filterVehicleCheckStations() {
      if (this.vehicleChecks.length > 0) {
        var unique = [
          ...new Set(this.vehicleChecks.map(p => p.dailyvehicleStation))
        ];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    },
    filterRepairStations() {
      if (this.stationRepair.length > 0) {
        var unique = [
          ...new Set(this.stationRepair.map(p => p.repairformStation))
        ];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    },
    filterVehicleCheckApparatus() {
      if (this.vehicleChecks.length > 0) {
        var unique = [
          ...new Set(this.vehicleChecks.map(p => p.dailyvehicleApparatusId))
        ];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    },
    filterRepairApparatus() {
      if (this.stationRepair.length > 0) {
        var unique = [
          ...new Set(this.stationRepair.map(p => p.repairformApparatusId))
        ];
        return [
          ...new Set(
            unique.map(p => {
              return { value: p, text: p };
            })
          )
        ];
      } else {
        return [];
      }
    }
  },
  methods: {
    keepJournalOpenToggle(data){
      console.log(data)
      this.keepJournalOpen = data
    },
    getFormatedDate(date){
      if(date){
        return moment(date).format('MM/DD/YY HH:mm')
      } return 'Unknown'
    },
    nameResolve(data) {
      var dataReplace = data.replace(/-/g, " ");
      return dataReplace.toUpperCase();
    },
    watchStationName() {
      this.reloadView = false;
      this.detailedDescription = ''
      this.$store.dispatch("CLEAR_DATA_STORE").then(response => {
        this.$nextTick(function() {
          var stationID = this.$route.params.stationName;
          var stationID = stationID.replace(/-/g, " ");
          this.stationVarId = stationID
          var parentCheck = [... new Set(this.stationsData.map(p => { if(p.stationParent != undefined ){ return p.stationParent.toLowerCase()}}))]
          var indexStation = parentCheck.indexOf(stationID)
          if(indexStation >= 0){
            var urlParam = 'parent'
            var stationsHold = this.stationsData.filter(p => {
              if(p.stationParent){
              return p.stationParent.toLowerCase() == stationID.toLowerCase()
              }
            })
            var holdIds = stationsHold.map(p => p.id)
            var holdNames = stationsHold.map(p => p.stationName.toLowerCase())
            holdNames.push(stationID)
            var apparatusHold = this.apparatusData.filter(p => {
              if(p.apparatusLocation){
              return holdNames.indexOf(p.apparatusLocation.toLowerCase()) != -1
              }
            })
           // console.log(holdNames)
          //  console.log(apparatusHold)
            var holdAppIds = apparatusHold.map(p => p.id)
            var holdBothIds = holdIds.concat(holdAppIds)
          } else {
            var urlParam = 'station'
            var stationsHold = this.stationsData.find(p => {
                return p.stationName.toLowerCase() == stationID.toLowerCase()
            })
            var holdIds = []
            if(stationsHold){
              holdIds.push(stationsHold.id)
            }
            var apparatusHold = this.apparatusData.filter(p => {
              if(p.apparatusLocation){
              return p.apparatusLocation.toLowerCase() == stationID.toLowerCase()
              }
            })
            var holdAppIds = apparatusHold.map(p => p.id)
            var holdBothIds = holdIds.concat(holdAppIds)
          }
          var holdIdObj = this.stationsData.find(p => {
            console.log(stationID.toLowerCase())
            console.log(p.stationName.toLowerCase())
            return p.stationName.toLowerCase() == stationID.toLowerCase()
          })
          var stationID = holdIdObj.stationName
          this.stationID = stationID
          var token = this.getSessionData;
          var headers = {
            headers: {
              "content-type": "application/json",
              'Authorization': token,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }
          };
          var secondDay = moment(this.formatedDate, 'MM/DD/YYYY').endOf('month').format('YYYY-MM-DDTHH:mm:ss')
          var firstDay = moment(secondDay).subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss')
          var query = '/' + firstDay + 'Z/' + secondDay + 'Z'
          var stationDataRequest = ["logbook", "dailyvehicle", "event", "narctrack", "repairform", "inventorysub"];
          for (var i = 0; i < stationDataRequest.length; i++) {
            this.$store.dispatch("LOAD_STATION_CONTENT_EXP", {
              contentType: stationDataRequest[i],
              stationVar: stationID,
              query: query,
              urlParam: urlParam,
              headers: headers,
              logisticsIds: holdBothIds
            });
          }
          axios({
            method: 'get',
            url: '/api/v1/content/note/tag/' + stationID,
            headers: headers.headers,
          }).then(response => {
             if(response.data.length > 0){
              var array = _.orderBy(response.data, ['updatedAt'], ['desc'])
              this.previousNotes = array
              this.noteData = array[0]
              this.detailedDescription = array[0].noteBody
            } else {  
              this.noteData = null 
            }
          })
          this.calendarAppType = "station";
          this.currentLogType = "Logbook Entries";
          this.reloadView = true;
        });
      });
    },
    watchApparatusName() {
      this.reloadView = false;
            this.detailedDescription = ''
      this.$store.dispatch("CLEAR_DATA_STORE").then(response => {
        this.$nextTick(function() {
          var apparatusID = this.$route.params.apparatusName;
          var apparatusID = apparatusID.replace(/-/g, " ");
          this.apparatusVarId = apparatusID
          var holdIdObj = this.apparatusData.find(p => {
            var appone = p.apparatusName.toLowerCase() == apparatusID.toLowerCase()
            if(appone){
                return appone
              } else {
                var apptest = p.apparatusName.toLowerCase().match(this.apparatusName.toLowerCase())
                return apptest
              } 
          })
          var apparatusID = holdIdObj.apparatusName
          //console.log(holdIdObj)
          this.apparatusID = apparatusID
          var token = this.getSessionData;
          var headers = {
            headers: {
              "content-type": "application/json",
              "Authorization": token,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'              
            }
          };
          var secondDay = moment(this.formatedDate, 'MM/DD/YYYY').endOf('month').format('YYYY-MM-DDTHH:mm:ss')
          var firstDay = moment(secondDay).subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss')
          var query = '/' + firstDay + 'Z/' + secondDay + 'Z'
          var stationDataRequest = ["narctrack", "dailyvehicle", "event", "repairform", "dailynarctrack", "inventorysub"];
          for (var i = 0; i < stationDataRequest.length; i++) {
            this.$store.dispatch("LOAD_APPARATUS_CONTENT_EXP", {
              contentType: stationDataRequest[i],
              stationVar: apparatusID,
              dbID: stationDataRequest[i] == 'dailyvehicle' ? holdIdObj.id : stationDataRequest[i] == 'repairform' ? holdIdObj.id : stationDataRequest[i] == 'inventorysub' ? holdIdObj.id + query : null,
              query: query,
              headers: headers
            });
          }
       
          axios({
            method: 'get',
            url: '/api/v1/content/note/tag/' + apparatusID,
            headers: headers.headers,
          }).then(response => {
             if(response.data.length > 0){
              var array = _.orderBy(response.data, ['updatedAt'], ['desc'])
              this.previousNotes = array
              this.noteData = array[0]
              this.detailedDescription = array[0].noteBody
            } else {  
              this.noteData = null 
            }
             })
          this.calendarAppType = "apparatus";
          this.currentLogType = "Narcotic Entries";
          this.reloadView = true;
        });
        });
    },
        updateNote(data, note){
      axios({
        method: 'post',
        url: '/api/v1/content/note/',
        data: {
              'noteTitle': note.noteIdTag + ' Note Board ' + moment().format('MM/DD/YY HH:mm'),
              'noteBody': data,
              'noteAuthor': this.userData.firstName + ' ' + this.userData.lastName,
              'noteAuthorId': this.userData.id,
              'noteIdTag': note.noteIdTag
        },
        headers: {
          'content-type': 'application/json',
          'authorization': this.getSessionData
        }
      }).then(response => {
          var headers = {
            headers: {
              "content-type": "application/json",
              "Authorization": this.getSessionData,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'              
            }
          };
          axios({
            method: 'get',
            url: '/api/v1/content/note/tag/' + note.noteIdTag,
            headers: headers.headers,
          }).then(response => {
            if(response.data.length > 0){
              var array = _.orderBy(response.data, ['updatedAt'], ['desc'])
              this.previousNotes = array
              this.noteData = array[0]
            } else {  
              this.noteData = null 
            }
          })
      })
    },
    createNewNote(data){
      if(this.stationID){
        var tag = this.stationID
      } else if (this.apparatusID) {
        var tag = this.apparatusID
      } else {
        var tag = 'noNameEntered'
      }
      axios({
        method: 'post',
        url: '/api/v1/content/note',
        data: {
              'noteTitle': tag + ' Note Board ' + moment().format('MM/DD/YY HH:mm'),
              'noteBody': data,
              'noteAuthor': this.userData.firstName + ' ' + this.userData.lastName,
              'noteAuthorId': this.userData.id,
              'noteIdTag': tag, 
        },
        headers: {
          'content-type': 'application/json',
          'authorization': this.getSessionData
        }
      }).then(response => {
          var headers = {
            headers: {
              "content-type": "application/json",
              "Authorization": this.getSessionData,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'              
            }
          };
          axios({
            method: 'get',
            url: '/api/v1/content/note/tag/' + tag,
            headers: headers.headers,
          }).then(response => {
            if(response.data.length){
           this.noteData = response.data[0]
            } else { 
              this.noteData = null 
            }
          })
      })
    },
    updateDate(date, event) {
      this.dateSelected = moment(date).format("MM/DD/YYYY");
      this.bootstrapDate = moment(date).format("YYYY-MM-DD");
    },
    refreshModalSwitch() {
    if (this.$route.params.stationName && this.stationsData.length > 0){
      this.$nextTick(function() {
        this.watchStationName();
      });
    }
    if (this.$route.params.apparatusName && this.apparatusData.length > 0) {
      this.$nextTick(function() {
        this.watchApparatusName();
      });
    }
    },
    noteDataFormat(data){
      if(data){
        return moment(data).format('MM/DD/YY HH:mm')
      }
    },
    toggleSideBar(event) {
      this.sideBarShow = !this.sideBarShow;
      this.sideBarHide = !this.sideBarHide;
    },
    toggleDate(event) {
      this.typePanel = false;
      this.datePanel = true;
    },
    toggleList(event) {
      this.datePanel = false;
      this.typePanel = true;
    },
    toggleRepair(event) {
      this.sortVehicle = false;
      this.sortLogbook = false;
      this.sortRepair = true;
      this.currentLogType = "Repair Logs";
    },
    toggleLogbook(event) {
      this.sortRepair = false;
      this.sortVehicle = false;
      this.sortLogbook = true;
      if (this.calendarAppType == "stations") {
        this.currentLogType = "Logbook Entries";
      }
      if (this.calendarAppType == "apparatus") {
        this.currentLogType = "Narcotics Entries";
      }
    },
    toggleVehicle(event) {
      this.sortLogbook = false;
      this.sortRepair = false;
      this.sortVehicle = true;
      this.currentLogType = "Vehicle Checks";
    },
    ArrayAuthorFilter() {
      if (this.sortLogbook == true) {
        this.stationLogbooks = this.stationLogbooks.filter(logbook => {
          return logbook.logbookAuthor.match(this.filterAuthor);
        });
      }
    },
    ArrayShiftFilter() {
      this.stationLogbooks = this.stationLogbooks.filter(logbook => {
        return logbook.shiftClass.match(this.filterShifts);
      });
    }
  }
};
</script>
<style>
.MobileToolbar {
  margin: 0;
  padding: 0;
}
</style>
<style scoped>
.floatRight {
  float: right;
}
.textAreaDescription  {
  width: 300px;
  margin: 0 auto 0;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
}
.calendarSearchHouse {
  margin-top: 20px;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
}
.shiftListHouse {
  margin-top: 20px;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 5px 5px;
}
#appCalendar {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  width: 100%;
}
.activeSecondaryFilterButton {
  background: grey;
  color: #fff;
  border: 1px solid #555;
}
.secondaryFilterButtons {
  padding-top: 10px;
  text-align: center;
}

.activeSecondaryFilterButton:focus {
  outline: 0;
}
.activeButton {
  background: #fff;
  padding: 5px;
  border-bottom: 2px solid #fff;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-style: none;
}
.activeButton:focus {
  outline: 0;
}
.inactiveButton:focus {
  outline: 0;
}
.inactiveButton {
  padding: 5px;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-style: none;
  border-bottom: none;
}

.searchItem {
  display: inline-table;
  padding: 5px;
  text-align: left;
}
#switchViewButtons {
  width: 300px;
  background: grey;
  padding-top: 8px;
  margin-right: 0;
}
#secondaryFilter {
  width: 300px;
  margin: 0 auto 0;
  position: static;
}
#secondaryFilter h4 {
  margin-top: 10px;
  margin-bottom: 0;
}
#vdp-datepicker {
  width: 30%;
  float: left;
}
#shiftListDiv {
  width: 50%;
  display: inline-table;
  min-width: 300px;
}

.datePickerClassMobile {
  display: none;
}
@media only screen and (max-width: 1120px) {
  .headerSpan {
    border-radius: 0;
  }
}
@media only screen and (min-width: 769px) {
  .card {
    width: auto;
  }
}
@media only screen and (max-width: 992px) {
  .card {
    width: 100%;
  }

  #appCalendar {
    margin-top: 0;
  }
  #shiftListDiv {
    display: block;
    width: 100%;
  }
  #leftPanelSelector {
    width: 100%;
  }
  #switchViewButtons {
    width: 100%;
  }
  .datePickerClass {
    display: none;
  }
  .datePickerClassMobile {
    display: block;
  }
    .panelCol.col-md-6 {
    padding: 0;
  }
}
@media only screen and (max-width: 576px) {
  .panelCol.col-md-6 {
    padding: 0;
  }
}
</style>
