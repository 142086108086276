<template>
<div>
        <b-navbar class="blogSpanHeader" toggleable="lg" type="dark" variant="dark">
           <b-navbar-brand class="ml-2">Report Type: </b-navbar-brand>
           <b-navbar-nav>
                  <b-nav-form>
           <b-form-radio-group class="text-light" inline  v-model="reportType" >
             <b-form-radio name="report-types" :value="'preplans'">Preplan Site Visits</b-form-radio>
             <b-form-radio name="report-types" :value="'inspections'">Business Inspections</b-form-radio>
            </b-form-radio-group>

            <b-navbar-brand class="ml-2">Start Date: </b-navbar-brand>
              <b-form-input v-model="startDate" type="date" />
               <b-navbar-brand class="ml-4">End Date: </b-navbar-brand>
               <b-form-input v-model="endDate" type="date" />
                </b-nav-form>
                <b-btn variant="info" class="ml-5" @click="runReports">Run Report</b-btn>
           </b-navbar-nav>
           

      </b-navbar>
      <b-container v-if="reportData">
        <b-card no-body class="pt-2 mt-2">
          <h4>{{ reportType.toUpperCase() }} from {{ getDate(startDate) }} to {{ getDate(endDate )}}</h4>
          <h5> Report ran by {{ userData.rank }} {{ userData.firstName }} {{ userData.lastName }} on {{ getDate(currentDate) }}</h5>
        </b-card>
        <b-table :items="reportData">
        </b-table>
      </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import printJS from 'print-js'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import convert from 'xml-js'
export default {
  props:["submittedData", "reporttype"], 
components:{
      'multiselect' : Multiselect,

},
  data () {
    return {
      reportType: 'preplans',
      startDate: '',
      endDate: '',
      reportData: '',
      currentDate: ''

    }
  },
  created(){
    this.currentDate = moment()
      this.startDate = moment().startOf('Month').format('YYYY-MM-DD')
       this.endDate = moment().endOf('Month').format('YYYY-MM-DD')
  },
  mounted(){
  
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'preplanStatus',
        ]),

    

    },
  methods:{
    getDate(date){
      if(date){
        return moment(date).format('MM/DD/YYYY')
      } return "bad date"
    },
    runReports(){
      var api = this.reportType == 'preplans' ? '/sitevisits/report' : 'inspection/report'
      var start = moment(this.startDate, 'YYYY-MM-DD').toISOString()
      var end = moment(this.endDate, 'YYYY-MM-DD').toISOString()
      var string = api + '/' + start + '/' + end
      axios({
        method: "get",
        url: "/api/v1/preplan/" + string,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
           'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      }).then(response => {
          this.reportData = response.data
        });

    },
  


}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.numberInputSize {
  width: 70px;
}
.textInputSize {
  width: 140px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
