<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
      <b-btn @click="addNew = !addNew" v-if="!addNew" block variant="primary">Add New Holiday</b-btn>
    <b-table :fields="fields" :items="dataObj" v-if="addNew">
      <template slot="holidayName" slot-scope="data" class="text-left">
          <b-form-input type="text" v-model="data.item.holidayName" placeholder="Name" />
      </template>
      <template slot="holidayDate" slot-scope="data" class="text-left">
          <b-form-input type="date" v-model="data.item.holidayDate"  />
      </template>
      <template slot="holidayPaytype" slot-scope="data" class="text-left">
          <b-form-group>
              <b-form-checkbox-group :id="'payrollCodeVis' + data.item.id"  v-model="data.item.holidayPaytype" :options="departmentRanksNamed">
                </b-form-checkbox-group>
         </b-form-group>
      </template>
      <template slot="holidayHours" slot-scope="data" class="text-left">
          <b-form-input type="number" v-model="data.item.holidayHours"  />
      </template>
       <template slot="edit" slot-scope="data">
            <b-btn @click="addNew = !addNew, resetDataObj" size="sm" variant="danger">Cancel</b-btn>
              <b-btn @click="addNewHoliday(data.item)" :disabled="checkForDisabled(data.item)" size="sm" variant="info">Add</b-btn>
        </template>
      </b-table>
      <b-table :fields="fields" :items="holidayDateFormated" >
           <template slot="holidayName" slot-scope="data" class="text-left">
            <span v-if="editItemId != data.item.id">{{  data.item.holidayName }}</span>
          <b-form-input  v-if="editItemId == data.item.id" type="text" v-model="data.item.holidayName" placeholder="Name" />
      </template>
      <template slot="holidayDate" slot-scope="data" class="text-left">
        <span v-if="editItemId != data.item.id">{{  data.item.holidayDate }}</span>
          <b-form-input v-if="editItemId == data.item.id" type="date" v-model="data.item.holidayDate"  />
      </template>
      <template slot="holidayPaytype" slot-scope="data" class="text-left">
          <span v-if="editItemId != data.item.id">{{  data.item.holidayPaytype }}</span>
          <b-form-group>
              <b-form-checkbox-group  v-if="editItemId == data.item.id" :id="'payrollCodeVisedit' + data.item.id"  v-model="data.item.holidayPaytype" :options="departmentRanksNamed">
                </b-form-checkbox-group>
         </b-form-group>
      </template>
      <template slot="holidayHours" slot-scope="data" class="text-left">
         <span v-if="editItemId != data.item.id">{{  data.item.holidayHours }}</span>
          <b-form-input v-if="editItemId == data.item.id" type="number" v-model="data.item.holidayHours"  />
      </template>
        <template slot="edit" slot-scope="data">
          <b-btn v-if="editItemId != data.item.id" @click="editItemId = data.item.id" size="sm" variant="warning">Edit</b-btn>
            <b-btn v-if="editItemId == data.item.id" @click="editItemId = ''" size="sm" variant="danger">Cancel</b-btn>
              <b-btn v-if="editItemId == data.item.id" @click="updatePayrollCode(data.item)" size="sm" variant="info">Update</b-btn>
              <b-btn v-if="editItemId == data.item.id" @click="deletePayrollCode(data.item.id)" size="sm" variant="danger">Delete</b-btn>
        </template>
      </b-table>
  
    </div>
</div>

</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import PapaParse from 'papaparse'
import moment from 'moment'
export default {
  components: {

  },
  name: 'roster-holidays',
  props: ['payrollHolidaysData'],
  data () {
    return {
      addNew: false,
      editItemId: '',
      dataObj: [{ 
            holidayName: "",
            holidayDate: moment().format('YYYY-MM-DD'),
            holidayPaytype: [],
            holidayHours: 8,
            }],
      fields: [
        'holidayName',
        'holidayDate',
        'holidayPaytype',
        'holidayHours',
        'edit'
      ],
    }
  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'siteconfig',
          'departmentRanks'
        ]),
        departmentRanksNamed(){
          return this.departmentRanks.map(p => p.deptrankName)
        },
        holidayDateFormated(){
          return this.payrollHolidaysData.map(item => {
            var obj = item
            obj.holidayDate = moment(item.holidayDate).format('YYYY-MM-DD')
            return obj
          })
        },
 
       
  },
  mounted(){

  },
  watch: {

  },
  methods: {
    checkForDisabled(data){
        if(data.holidayName == "" || data.holidayDate == '' || data.holidayPaytype.length == 0 || data.holidayHours <= 0){
          return true
        } else {
          return false
        }
    },
    resetDataObj(){
      this.dataObj = [{ 
            holidayName: "",
            holidayDate: moment().format('YYYY-MM-DD'),
            holidayPaytype: [],
            holidayHours: 8
            }]
    },
    updatePayrollCode(data){
     // console.log(data)
      delete data.edit
      delete data.updatedAt
      delete data.createdAt
      data.holidayDate = moment(data.holidayDate, 'YYYY-MM-DD').toISOString()
    axios({
     method: 'patch',
     url: '/api/v1/staffing/holiday/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     bus.$emit('getPayrollCodes')
     this.editItemId = ''
   })

    },
    deletePayrollCode(data){
    var result = confirm('Are You Sure You Want to Delete this Holiday? No Going Back!')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/staffing/holiday/' + data,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
    this.$nextTick(function(){
     bus.$emit('getHolidays')
     this.editItemId = ''
    })
  }) 
      }
    },
    addNewHoliday(data){
    data.holidayDate = moment(data.holidayDate, 'YYYY-MM-DD').toISOString()
    axios({
     method: 'post',
     url: '/api/v1/staffing/holiday',
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       bus.$emit('getHolidays')
       this.resetDataObj()
       this.addNew = !this.addNew
       this.$nextTick(function(){
         this.addNew = !this.addNew
       })
   })

    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
