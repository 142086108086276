<template>
<div id="homelanding">
<b-container fluid class="px-0">
  <b-textarea v-model="uploadedJson" @input="fixDataReport()" />
<b-form-input v-model="selectedInventoryForm" placeholder="Sheet Name" />
<b-form-select v-model="inventoryNameFilter" :options="inventoryNameFilterOptions" @input="selectedInventoryForm = inventoryNameFilter" />
 <b-form-select v-model="inventoryFrequency" :options="frequencyOptions" /></b-col>
<b-form-select v-model="sheetTaxonomyTag" :options="['apparatusData', 'stationsData', {text: 'None', value: null}]" />
<multiselect v-if="sheetTaxonomyTag" 
              v-model="databaseIDTag" 
              :options="getOptionsFromSelection" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please select a Station" :label="sheetTaxonomyTag == 'stationsData' ? 'stationName' : 'apparatusName'" track-by="id">
                
</multiselect>
<b-btn @click="postInventoryTemplate">Submit Sheet</b-btn>
<b-table :items="vehicleCheckData" />
<b-container v-if="false">
  <b-card v-for="(veh, index) in vehicleCheckData" :title="veh.item_loc_01" :key="index" class="text-left">
     <p v-for="(items, indexvi) in veh.items" :key="indexvi">{{ items.item_name }} {{ items.id }} </p>
    <b-card v-for="(sec, indexs) in veh.item_loc_02" :key="indexs" :title="sec.item_loc_02">
      <p v-for="(item, indexsec) in sec.items" :key="indexsec">{{ item.item_name }}</p>
      <b-card v-for="(th, indext) in sec.item_loc_03" :key="indext" :title="th.item_loc_03">
        <p v-for="(item, indexi) in th.items" :key="indexi">{{ item.item_name }}</p>
        </b-card>
      </b-card>
    </b-card>
  </b-container>
</b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import PapaParse from 'papaparse'
export default {
components: {
  'multiselect' : Multiselect,

},
props: ['inventoryItemsData'],
data() {
    return {
      showAdmin: false,
      showModal: false,
      searchData: '',
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      inventoryTemplateData: [],
      inventoryFrequency: 'daily',
      frequencyOptions: [
        {text: 'Daily', value: 'daily'},
        {text: 'Weekly', value: 'week'},
        {text: 'Bi-weekly', value: 'biweekly'},
        {text: 'Monthly', value: 'monthly'},
        {text: 'Quartly', value: 'quartly'},
        {text: 'Annually', value: 'annually'},
        {text: 'None', value: null}
      ],
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
      allInventoryHold: [],
      inventoryNameFilter: null,
}
},
created () {

},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  inventoryNameFilterOptions(){
        var array = _.cloneDeep(this.allInventoryHold)
        var newSet = [... new Set(array.map(p => p.Inventory_name))]
        newSet.push({text: 'none', value: null})
        return newSet
  },
  inventoryItemsDataReturn(){
    return this.inventoryItemsData.filter(p => {
      return p.itemName.toLowerCase().includes(this.searchData.toLowerCase())
    })
  },
  getOptionsFromSelection(){
    return this[this.sheetTaxonomyTag]
  },
  inventorySheetDataParent(){
    var array = _.cloneDeep(this.inventorySheetData)
    return array.map(p => p.inventoryName)
  },
  getInventoryItemsList(){
    var obj = this.inventorySheetData.find(p => p.inventoryName == this.selectedInventoryForm)
    return obj.inventoryItems
  },
  vehicleCheckData(){
    if(this.inventoryNameFilter){
    var array = _.cloneDeep(this.allInventoryHold).filter(p => {
      return p.Inventory_name == this.inventoryNameFilter
    })
    array = array.map(item => {
      var obj = item
      delete obj.Inventory_name
      delete obj.inventory_dbid
      delete obj.inventory_type
      return obj
    })
    console.log(array)
    return array
    } 
    console.log('Select Inventory Name')
    return []
  },

},
mounted(){

},
watch:{

},
methods: {
        fixDataReport(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJson
        console.log(data)
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              console.log(results)
              vm.fixData(results.data)
            }
        })
      },
  fixData(data){
    console.log(data)
    var hold = _.cloneDeep(data)
    var array = hold.map(item => {
            var obj = item
            if(obj.item_type == 's'){
            obj['id'] = this.getItemId(obj.item_name)
            obj['holdid'] = obj.id + '_' + parseInt(Math.random()*1000000000)
            obj['expDate'] = true
            obj['30daycount'] = false
            obj['item_type'] = 'supply'
            obj['notes'] = ''
            } else if (obj.item_type == 'c'){
            obj['holdid'] = parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
            obj['item_pass'] = true
            obj['item_type'] = 'check'
            obj['notes'] = ''
            } else if (obj.item_type == 'a'){
            obj['holdid'] = parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
            obj['asset_id'] = ''
            obj['modifed'] = false
            obj['modItems'] = []
            obj['item_pass'] = true
            obj['notes'] = ''
            obj['item_type'] = 'asset'
            obj['']
            }
            obj['sortOrder'] = 0
            return obj
          })
      console.log(array)
      this.allInventoryHold = array
  },
  getItemId(name){
    var item = this.inventoryItemsData.find(p => p.itemName == name)
    if(item && item.id){
    return item.id
    } else {
      console.log(name)
      this.missingInventoryItems.push({item_name: name})
     // console.log(name)
      return ''
    }
    },
    getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : "Unknown"
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
    modalHideEvent(){
      this.showModal = false
    },
    postInventoryTemplate(){
    var obj = {inventoryItems: this.vehicleCheckData, inventoryName: this.selectedInventoryForm }
    if(this.sheetTaxonomyTag){
        obj['inventoryType'] = this.sheetTaxonomyTag
        obj['inventoryDbid'] = this.databaseIDTag && this.databaseIDTag.id ? this.databaseIDTag.id : 'generic'
        obj['inventoryFrequency'] = this.inventoryFrequency
    }
    axios({ 
     method: 'post',
     url: '/api/v1/taxonomy/inventorytemplate/',
     data: obj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     this.$emit('getInventoryTemplates')
   })
    },
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
