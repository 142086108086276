<template>
  <div class="hello">
      <b-card no-body :border-variant="checkForChanges == false ? 'danger' : ''">

        <b-tabs card>
          <b-tab title="Form Info">
              <b-card header-slot="header">
                <b-container slot="header">
                  <b-row><b-col align="left" cols="*" v-if="!checkForChanges">
                  <b-btn variant="success" :disabled="checkForChanges" size="sm" v-if="formId != 'new'" @click="updateForm(formData)">Update Form Template</b-btn>
                   <b-btn variant="success" :disabled="checkForChanges" size="sm" v-if="formId == 'new'" @click="submitNewForm(formData)">Save New Form Template</b-btn>
                    </b-col><b-col v-if="!editForm">
                  {{ formData.formtempName }}</b-col>
                  <b-col v-if="editForm"><b-form-input type="text" v-model="formData.formtempName" />
                  </b-col>
                  <b-col cols="*">
                    <b-btn v-show="editForm" variant="outline-primary" title="Save" size="sm" @click="editForm = false"> &#10004;</b-btn>
                  <b-btn v-show="!editForm" variant="warning" title="Edit" size="sm" @click="editForm = true">	&#x270e;</b-btn></b-col></b-row>
                  </b-container>
                <b-container v-if="!editForm">
                  {{ formData.formtempDescription }}
                  <b-card-group deck>
                    <b-card header="Form Permissions">
                        Create New: <br>
                        Edit Saved: <br>
                        Edit Submitted: <br>
                      </b-card>
                    <b-card header="Form Visibility">
                        View Form: <br>
                        View Saved: <br>
                        View Submitted: <br>
                    </b-card>
                    </b-card-group>
                </b-container>
                <b-container v-if="editForm">
                  <b-textarea v-model="formData.formtempDescription" />

                </b-container>
               </b-card> 
          </b-tab>
          <b-tab title="Form Data">
             
          <formsuboptions :componentData="formData.formtempComponentData" @updateArrayChanges="updateArrayChanges($event)" @updateEvalChanges="updateEvalChanges($event)">
            <span v-if="!checkForChanges">
                  <b-btn variant="success" :disabled="checkForChanges" size="sm" v-if="formId != 'new'" @click="updateForm(formData)">Update Form Template</b-btn>
                   <b-btn variant="success" :disabled="checkForChanges" size="sm" v-if="formId == 'new'" @click="submitNewForm(formData)">Save New Form Template</b-btn>
                   </span>
          </formsuboptions>
           
           </b-tab>
           <b-tab title="Preview Form" @click="refreshFormData">
             <b-container>
           <formsub :formData="formData" :preview="true" @previewSubmission="submissionPreviewData($event)" />
             </b-container>
          </b-tab>
         <b-tab title="Preview Table" @click="refreshFormData">
           <formtableview v-if="formResetPreview" :formData="submissionPreviewForm" />
          </b-tab>
        </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import formSubs from './forms/formsub.vue'
import formSubsEdit from './forms/formsubedit.vue'
import formSubOptions from './forms/formsuboptions.vue'
import formTableView from './forms/formtableview.vue'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import axios from 'axios'

export default {
components: {
  'formsub' : formSubs,
  'formsubedit' : formSubsEdit,
  'formsuboptions' : formSubOptions,
  'formtableview' : formTableView,
        'multiselect' : Multiselect,
},
props: ['formId'],
data() {
    return {
      editForm: false,
      formData: '',
      formResetPreview: false,
      submissionPreviewForm: '',
      trackChanges: '',
      evalComponents: true,

}
},
created () {
 this.getFormData(this.formId)
},
beforeDestroy(){
  
},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData',
    'stationsData',
    'apparatusData',
    'deptContactData',
    'formtempData'
  ]),
  checkForChanges(){
  var saved = this.trackChanges
 
  var form = this.formData
 
  if(
    saved != undefined &&
    saved.formtempCategory == form.formtempCategory &&
    saved.formtempDescription == form.formtempDescription &&
    saved.formtempName == form.formtempName &&
    saved.formtempParentCategory == form.formtempParentCategory &&
    this.evalComponents &&
    this.checkForArrayChanges(saved, form)
  ) {
    return true
  } else {
    return false
  }
  
  
  
  }


},
mounted(){

  

},
watch:{

  
},
methods: {
  checkForArrayChanges(saved, form){
    var savecomp = saved.formtempComponentData
    var formcomp = form.formtempComponentData
    var test = []
    for(var i = 0; i < savecomp.length; i++){
        if(savecomp[i].id == formcomp[i].id){
          test.push(true)
        } else {
          test.push(false)
        }
    }
    var index = test.indexOf(false)
    if(index < 0){
      return true
    } else {
    return false
    }
 },
  updateArrayChanges(data){
    this.formData.formtempComponentData = data
  },
  updateEvalChanges(data){
    this.evalComponents = data
  },
  refreshFormData(data){
    this.formResetPreview = false
    this.$nextTick(function(){
      this.formResetPreview = true
    })
  },
  submissionPreviewData(data){
    this.submissionPreviewForm = data
    this.refreshFormData
  },
  submitNewForm(data){
    axios({
        method: 'post',
        url: '/api/v1/forms/formtemp',
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {

       this.formId = response.data.id
          
        })
  },
    updateForm(data){
    axios({
        method: 'patch',
        url: '/api/v1/forms/formtemp/' + this.formId,
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {

         console.log(response.data)
          
        })
  },
    getFormData(){
    if(this.formId != 'new'){
    var form = this.formtempData.filter(p => {
      return p.id == this.formId
    })
    var formData = form[0]
    formData['saved'] = true
    this.formData = formData
    this.trackChanges = _.cloneDeep(formData)
  } else {
    var obj = {
      formtempCategory: '',
      formtempParentCategory: '',
      formtempName: '',
      formtempComponentData: [],
      formtempDescription: '',
      saved: true,
    }
    this.formData = obj
    console.log(this.formData)
    this.trackChanges = _.cloneDeep(formData)
  }
  }
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
