<template>
  <div>
  <b-container>
  <b-row  align-v="center" v-for="app in filteredALS" class="narcRow" :key="app.id">
  <b-col cols="*" class="appCol pl-2" ><span class="apparatusNameClass">{{ app.apparatusRadioId }}</span></b-col>
  <b-col class="narcPreviewCol" align="left" v-if="dailyNarcs.length > 0 || dailyNarctracks.length > 0">
     <div v-for="narcs in filteredApparatusNarcs(app.apparatusName)" :key="narcs.id">
    <apparatus :narcs="narcs" />
    </div>
    <div v-for="narcs in filteredDailyApparatusNarcs(app.apparatusName)" :key="narcs.id + '_daily'">
    <dailyApparatusNarcs :narcs="narcs" />
    </div>
    <div v-show="filteredApparatusNarcs(app.apparatusName).length == 0 && filteredDailyApparatusNarcs(app.apparatusName).length == 0">
      <h5 class="warningMessage">*No Narc Logs Submitted</h5>
    </div>
</b-col>
<b-col align="left" v-else>
    <div>
      <h5 class="warningMessage">*No Narc Logs Submitted</h5>
    </div>
</b-col> 
</b-row>
</b-container>
</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import ApparatusNarcs from './apparatusFilterData.vue'
import dailyApparatusNarcs from '../dailynarctrack/apparatusFilterData.vue'
export default {
  props: ["apparatusAppNarcs", "battalionData"],
  name: 'apparatusQA',
  components: {
    'apparatus': ApparatusNarcs,
    dailyApparatusNarcs
  },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      items: [],
    }
  },
created (){

},
computed: {
    ...mapState([
      'dailyNarcs',
      'dailyNarctracks',
      'apparatusData',
      ]),
    filteredALS(){
      return this.apparatusData.filter((d) => {
        return d.apparatusMedicalType.match('ALS') && d.apparatusParent == this.battalionData
      })
    },
    filterDate() {
      var now = moment().format("MM/DD/YYYY");
      var hour = moment().format('HH')
      if(hour < 8){
        var now = moment(now).subtract(1, 'd').format('MM/DD/YYYY')

      }
      return now
    },

},
watch: {


},
methods: {
  filteredApparatusNarcs(data){
    var dataArray = this.dailyNarcs.filter( (p) => {
      var date = moment(p.narctrackDate).format('MM/DD/YYYY')
      var momTest = moment(date, 'MM/DD/YYYY').isSame(this.filterDate, 'day')
      if(momTest == true && p.narctrackApparatusId == data)
      return p
    })
    return dataArray
  },
  filteredDailyApparatusNarcs(data){
   // console.log(this.filterDate)
    var dataArray = this.dailyNarctracks.filter( (p) => {
      var date = moment(p.narctrackDate).format('MM/DD/YYYY')
      var momTest = moment(date, 'MM/DD/YYYY').isSameOrAfter(this.filterDate, 'day')
      if(momTest == true && p.narctrackTagId == data)
      return p
    })
    return dataArray
  },

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.warningMessage {
  margin: 0 auto 0;
  padding-left: 20%;
  font-style: italic;
  color: #e2070d;
}
.narcRow {
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #fff;
  box-shadow: 1px 2px 5px #ddd;
  min-height: 50px;
}
.apparatusNameClass{
  font-weight: bold;
  background: #fff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}
.A-shift-app {
  background: red;
  color: #fff;
}
.B-shift-app {
  background: blue;
  color: #fff;
}
.C-shift-app {
  background: green;
  color: #fff;
}
.apparatusTitleBar {
  color: #fff;
}

</style>
