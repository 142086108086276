<template>
<div id="homelanding">
  <b-card no-body>
  <b-tabs card>
      <b-tab title="Item Checks" v-if="false && userData.role == 'admin' && siteconfig.detailedVehicleChecks">
      <b-card no-body>
  <b-tabs card>
      <b-tab title="Item CheckS">
      </b-tab>
      <b-tab title="New Item Check">
  <h6>Your agency wishes to use detailed item checks, therefore the grouping/locations for items/inventorys/assets need to be set for the vehicle on this page, even if there are no items from item checcks going into the category. Each of the parent/group categories will be available as a location selection under inventory/asset.</h6>
        </b-tab>
        </b-tabs>
      </b-card>
      </b-tab>
    <b-tab title="Inventory" v-if="userData.role == 'admin' || userData.role == 'adminchief'">
<b-card no-body>
  <b-tabs card>
    <b-tab title="Inventory Forms">
    <b-btn block variant='primary' @click="editInventoryListItem(null)">Add New</b-btn>
    <b-table v-if="inventoryTemplateData" :fields="['inventoryName', 'Tagged', 'inventoryFrequency', 'Edit']" :items="inventoryTemplateData">
      <template slot="Tagged" slot-scope="data">
            <span v-if="data.item.inventoryType && data.item.inventoryDbid">{{ getTaggedType(data.item.inventoryType, data.item.inventoryDbid) }}</span>
        </template>
      <template slot="Edit" slot-scope="data">
            <b-btn variant="info" size="sm" @click="editInventoryListItem(data.item)">Edit</b-btn>
            <b-btn variant="danger" v-if="inventorySubsDataSorted && inventorySubsDataSorted.length > 0" :disabled="checkDisabled(data.item.latest)" size="sm" @click="deleteInventoryTemplate(data.item.id)">Delete</b-btn>
        </template>
        </b-table>
    </b-tab>
    <b-tab v-for="item in editInventoryArray" :key="item.id" active>
         <template slot="title">
        {{ item.inventoryName }} <b-btn variant="danger" class="px-1 py-0" size="sm" @click="closeInventoryItems(item.id)">X</b-btn>
      </template>
      <invSheet :newInventoryForm="item" :inventoryItemsData="inventoryItemsData"  :inventoryTemplateData="inventoryTemplateData" @closeInventoryNew="closeInventoryNew" />
    </b-tab>
      <b-tab v-if="userData.role == 'admin'" title="Excel Upload InventoryForms">
      <vehicleCheckExcel :inventoryItemsData="inventoryItemsData" />
    </b-tab>
    </b-tabs>
    </b-card>
        </b-tab>
        <b-tab title="Assets">
          <b-card no-body>
            <b-tabs card>
              <b-tab title="Asset List">
          <logisticasset :logisticAssetData="logisticAssetData" />
          </b-tab>
              <b-tab v-if="userData.role == 'admin'" title="Excel Upload Asset List">
      <inventoryExcelAsset :logisticAssetData="logisticAssetData" />
    </b-tab>
    </b-tabs>
  </b-card>
  </b-tab>
    <b-tab title="Supplies">
      <b-card no-body><b-tabs card>
      <b-tab title="Inventory Items">
      <logisticSupply :inventoryItemsData="inventoryItemsData" :inventoryTemplateData="inventoryTemplateData" @getInventoryItems="getInventoryItems" />
    </b-tab>
    <b-tab v-if="userData.role == 'admin'" title="Excel Upload Item List">
      <inventoryExcel :inventoryItemsData="inventoryItemsData" @getInventoryItems="getInventoryItems" />
    </b-tab>
    <b-tab v-if="userData.role == 'admin'" title="Excel Vendor">
      <inventoryExcelVendor :inventoryItemsData="inventoryItemsData" @getInventoryItems="getInventoryItems" />
    </b-tab>
        <b-tab v-if="userData.role == 'admin'" title="Excel Vendor After">
      <inventoryExcelVendorAfter :inventoryItemsData="inventoryItemsData" @getInventoryItems="getInventoryItems" />
    </b-tab>
    </b-tabs></b-card>
      </b-tab>
    </b-tabs>
    </b-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import inventoryExcel from './logistics/inventoryItemsExcel.vue'
import inventoryExcelVendor from './logistics/inventoryItemsExcelVendor.vue'
import inventoryExcelVendorAfter from './logistics/inventoryItemsExcelVendorAfter.vue'
import invSheet from './logistics/InvSheet.vue'
import logisticasset from './logistics/logisticAsset.vue'
import inventoryExcelAsset from './logistics/inventoryAssetExcel.vue'
import vehicleCheckExcel from './logistics/itemCheckExcel.vue'
import logisticSupply from './logistics/logisticSupply.vue'
export default {
components: {
  'multiselect' : Multiselect,
  inventoryExcel,
  vehicleCheckExcel,
  invSheet,
  logisticasset,
  inventoryExcelVendor,
  logisticSupply,
  inventoryExcelAsset,
  inventoryExcelVendorAfter

},
props: ['logisticAssetData', 'inventoryTemplateData', 'inventorySubsDataSorted'],
data() {
    return {
      showAdmin: false,
      showModal: false,
      inventoryItemsData: [],
      editInventoryArray: [],
      searchData: '',
      uploadedJson: '',
      missingInventoryItems: [],
      inventorySheetData: [],
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
}
},
created () {
  this.getInventoryItems()
  // this.getInventoryTemplates()
},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
 

},
mounted(){

},
watch:{

},
methods: {
  checkDisabled(data){
    if(data){
      return true
    } return false
  },
  closeInventoryNew(data){
    var index = this.editInventoryArray.map(p => p.inventoryName).indexOf('NEW')
       if(index != -1){
      //   console.log(index)
         this.editInventoryArray.splice(index, 1)
       }
  },
  editInventoryListItem(data){
      if(data){
      this.editInventoryArray.push(data)
      } else {
       var id = parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
       this.editInventoryArray.push({inventoryName: 'NEW', id: id})
      }
    },
    closeInventoryItems(id){
       var index = this.editInventoryArray.map(p => p.id).indexOf(id)
    //   console.log(index)
       if(index != -1){
      //   console.log(index)
         this.editInventoryArray.splice(index, 1)
       }
    },
    getTaggedType(type, id){
      var array = this[type]
      if(array){
      var obj = array.find(p => p.id == id)
      if(obj){
        return type == 'apparatusData' ? obj.apparatusName : type == 'stationsData' ? obj.stationName : "Unknown"
      } else {
        return 'Unknown'
      }
      } else {
        return 'Unknown Type'
      }
    },
  getInventoryTemplates(){
    axios({ 
     method: 'get',
     url: '/api/v1/taxonomy/inventorytemplate/',
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.inventoryTemplateData = response.data
   })
    },
    modalHideEvent(){
      this.showModal = false
    },
    deleteInventoryTemplate(id){
          var result = confirm('Are You Sure You Want to Delete this Inventory Form? It will be gone forever!!!')
      if (result) {
    axios({ 
     method: 'delete',
     url: '/api/v1/taxonomy/inventorytemplate/' + id,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     bus.$emit('getInventoryTemplates')
   })
      }
    },
  getInventoryItems(){
    axios({ 
     method: 'get',
     url: '/api/v1/taxonomy/inventoryitem/',
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.inventoryItemsData = response.data
   })
    },
    modalHideEvent(){
      this.showModal = false
    },

    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
