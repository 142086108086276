<template>
<div>

      <b-navbar type="dark" variant="dark">
      <b-nav-form>
         <b-navbar-brand class="bootstrapTable" v-if="!userReport">Filter:</b-navbar-brand>
         <b-navbar-brand class="bootstrapTable" v-if="userReport">User Report</b-navbar-brand>
         <b-form-input  v-if="!userReport" v-model="searchData" type="text" placeholder="Last Name" />
         <div class="filterOptions"  v-if="!userReport">
         <multiselect v-model="stationSelectValue" :options="stationsData" :multiple="false" :searchable="false" :close-on-select="false" :show-labels="false" placeholder="Station/Parent" label="stationName" track-by="stationName">
                  <template v-if="false" slot="singleLabel" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="stationSelectValue = {}">❌</span></span></template>
          </multiselect>
          </div>
           <b-form-select  v-if="!userReport && userReportTable.length > 0" class="ml-2" v-model="sortShift" :options="sortShiftOptions" />
         </b-nav-form>
          <b-navbar-nav class="ml-auto">
          <b-nav-form>
         <b-navbar-brand  v-if="!userReport" class="ml-2">Sort:</b-navbar-brand>
         <b-form-select  v-if="!userReport" v-model="sortColumn" :options="sortColumnOptions" />
         <b-form-select  v-if="!userReport" class="ml-2" v-model="sortOrder" :options="sortOrderOptions" />
                     <download-excel class="ml-2" v-if="!loadingIndicator && userReportTableReturn.length > 0"
              :name="getReturnedName"
              :data="userReportTableReturnExcel">
              <b-btn variant="primary" :disabled="!(!loadingIndicator && userReportTableReturn.length > 0)">Download Excel</b-btn>
          </download-excel>
           <b-btn @click="userReport = null" variant="danger" v-if="userReport">Return to Table</b-btn>
          <b-btn  class="ml-2" variant="danger" @click="emitClose" v-if="!userReport">X</b-btn>
          </b-nav-form>
          </b-navbar-nav>
      </b-navbar><b-navbar type="dark" variant="dark"  v-if="!userReport">
       <b-navbar-nav class="mx-auto">
          <b-nav-form  v-if="!loadingIndicator">
      <b-pagination v-show="!userReport && userReportTableReturn.length > 0 && !loadingIndicator" class="pt-0 mb-0" size="md" :total-rows="userReportTableReturn.length" v-model="currentPage" :per-page="10">
          </b-pagination>
         
              <b-navbar-brand v-if="loadingIndicator">
          {{ loadingIndicator }}
          </b-navbar-brand>
    
          <div class="lds-facebook"  v-if="loadingIndicator"><div></div><div></div><div></div></div>
          </b-nav-form >
       </b-navbar-nav>
           
    </b-navbar>
         
          <b-table v-show="!userReport && userReportTableReturn.length > 0" :per-page="10" :current-page="currentPage" :fields="userReportFields" :items="userReportTableReturn">
          <template v-for="cred in userReportCredentials" :slot="cred.id" slot-scope="data">
            <span :id="cred.id + '-' + data.item.id" :key="cred.id + '-' + data.item.id" v-if="data.item.trainingSort == undefined">Loading</span>
            <span :id="cred.id + '-' + data.item.id" :key="cred.id + '-' + data.item.id" v-if="data.item.trainingSort">{{ getTrainingCompliance(data.item.trainingSort, cred.id) }}%<span v-if="getTrainingComplianceRollover(data.item.trainingSort, cred.id)">*</span></span>
          </template>
          <template slot="View Data" slot-scope="data">
            <b-btn variant="primary" @click="userReport = data.item">View</b-btn>
          </template>
          </b-table>
      <individualReport v-if="userReport" :userReport="userReport" :trainingDrills="trainingDrills" :userReportCredentials="userReportCredentials" />
</div>
</template>
<script>

import axios from 'axios'
import { mapState } from 'vuex'
import multiselect from 'vue-multiselect'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'
import individualReport from './trainingReportIndividual.vue'
export default {
  props:['dataUser', 'credentialsData', 'credentialRequirement', 'trainingDrills'],
components:{
  individualReport,
  multiselect,
  'download-excel' : JsonExcel
},
  data () {
    return {
      searchData: '',
      sortColumn: null,
      stationSelectValue: null,
      sortOrder: 'asc',
      sortOrderOptions: [
        {text: 'Ascending', value: 'asc'},
        {text: 'Descending', value: 'desc'}
      ],
      sortShift: null,
      currentPage: 1,
      usersTraining: [],
      userReport: null,
      loadingIndicator: null,
      userReportCredentials: [],
      userReportFields: [
          'firstName',
          'lastName',
          'employeeId',
          'stationAssignment',
          'stationParent',
          'shiftAssignment'
      ],
   
      userReportTable: [],
    }
  },
  created(){
    this.getUsersTraining(this.dataUser)
  },
  watch: {
   
  },
  computed:{
    ...mapState([
    'getSessionData',
    'deptContactData',
    'stationsData',
    'userData',
    'config',
    'userPosts'
    ]),
    sortShiftOptions(){
      var option = [{text: 'Select Shift', value: null}]
      var shifts = [... new Set(this.userReportTable.map(p => p.shiftAssignment))]
      var shifts = _.sortBy(shifts)
      for(var s in shifts){
        option.push({text: shifts[s], value: shifts[s]})
      }
      return option
    },
    sortColumnOptions(){
      var arr = [{text: 'Select Column', value: null}]
      var opt = this.userReportCredentials
      for(var c in opt){
        arr.push({text: opt[c].credentialRequirementName, value: opt[c].id})
      }
      arr.push({text: 'Overall', value: 'total'})
      return arr
    },
    getReturnedName(){
      if(!this.userReport){
       return 'CredentialReport' + '_' + moment().format('YYYY-MM-DD') + '.xls'
      } else {
        return this.userReport.lastName + '_' + moment().format('YYYY-MM-DD') + '.xls'
      }
      
    },
    userReportTableReturn(){
      var array = this.userReportTable.filter(p => {
        return p.lastName.toLowerCase().match(this.searchData.toLowerCase()) 
      })
      if(this.stationSelectValue){
        if(!this.stationSelectValue.stationParent){
         var station = array.filter(p => {
            return p.stationParent == this.stationSelectValue.stationName
          })
        } else {
        var station =  array.filter(p => {
            return p.stationAssignment == this.stationSelectValue.stationName
          })
        }
      } else {
        var station =  array
      }
      if(this.sortColumn){
        station.map(item => {
              var value = item.trainingSort.find(p => p.id == this.sortColumn)
              if(value.Compliance){
                item['orderBy'] = value.Compliance
              } else {
                item['orderBy'] = 0
              }
              return item
        })
        var column = _.orderBy(station, ['orderBy'], [this.sortOrder])
      } else {
        var column = station
      }
      if(this.sortShift){
        var shift = column.filter(p => {
          return p.shiftAssignment == this.sortShift
        })
      } else {
        var shift = column
      }
      return shift
    },
    userReportTableReturnExcel(){
      var array = this.userReportTableReturn
      var newArray = []
      var credRes = this.userReportCredentials
      var credTrans = []
      for(var c in credRes){
        var obj = {
          id: credRes[c].id,
          colName: credRes[c].credentialRequirementName + '(' + credRes[c].credentialRequirementHours + 'hrs)'
        }
        credTrans.push(obj)
      }
      for(var i in array){
        var obj = {
          id: array[i].id ? array[i].id : '',
           firstName: array[i].firstName ? array[i].firstName : '',
            lastName: array[i].lastName ? array[i].lastName : '',
             employeeId: array[i].employeeId ? array[i].employeeId : '',
              hireDate: array[i].hireDate ? array[i].hireDate : '',
               deptSpecialties: array[i].deptSpecialties ? array[i].deptSpecialties : '',
                deptRank: array[i].deptRank ? array[i].deptRank : '',
                stationAssignment: array[i].stationAssignment ? array[i].stationAssignment : '',
                shiftAssignment: array[i].deptRank ? array[i].deptRank : '',
        }
        for(var c in credTrans){
          var train = array[i].trainingSort.find(p => p.id == credTrans[c].id)
          if(train){
          obj[credTrans[c].colName] = train.Completed + 'hrs (' + train.Compliance
          if(train.AttachedRollover){
            obj[credTrans[c].colName] = obj[credTrans[c].colName] + '%)*'
          } else {
              obj[credTrans[c].colName] = obj[credTrans[c].colName] + '%)'
          }
          } else {
           obj[credTrans[c].colName] = 'None'
          }
        }
        newArray.push(obj)
      }
      return newArray
    },

    },
    methods: {
      emitClose(){

        this.$emit('closeTab', this.dataUser.id)
      },
      getTrainingCompliance(training, id){
        var train = training.find(p => p.id == id)
        if(train && train.Compliance != undefined){
          return train.Compliance
        } else {
          return 'Error'
        }

      },
      getTrainingComplianceRollover(training, id){
        var train = training.find(p => p.id == id)
        if(train && train.AttachedRollover && train.AttachedRollover.length > 0){
          return true
        } else {
          return false
        }
      },
      getDateFormat(data){
        if(data){
        return moment(data).format('MM/DD/YY')
        } else {
          return ''
        }
      },
    getUsersTraining(data){
      this.loadingIndicator = 'Building Table'
      var crreq = data.credentialRequirements
      var reqArr = []
      var reqArraFields = []
      for(var c in crreq){
        var index = this.credentialRequirement.map(p => p.id).indexOf(crreq[c])
        if(index != -1){
          var obj = this.credentialRequirement[index]
          reqArr.push(obj)
          reqArraFields.push({key: obj.id, label: obj.credentialRequirementName, id: obj.id, dataObj: obj})
          
        }
      }
      this.userReportCredentials = reqArr
      for(var f in reqArraFields){
       this.userReportFields.push(reqArraFields[f])
      }
     
      var users = data.credentialAssignedUsers
      var table = []
      for(var i in users){
        var user = this.deptContactData.find(p => p.id == users[i])
        if(user){
          var obj = user
          var station = this.stationsData.find(p => p.stationName == obj.stationAssignment)
          if(station){
            if(station.stationParent){
              obj['stationParent'] = station.stationParent
            } else {
              obj['stationParent'] = station.stationName
            }
          } else {
            obj['stationParent'] = 'Unknown'
          }
          
          table.push(obj)
        }
      }
      var tablesort = _.orderBy(table, 'lastName', ['asc'])
      this.userReportTable = tablesort
      this.getUsersTrainingPull(tablesort)
    },
    getUsersTrainingPull(data){
      this.loadingIndicator = 'Fetching Training'
      axios({
        method: 'get',
        url: '/api/v1/training/trainingrecord/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
       this.loadingIndicator = 'Determining Compliance'
       this.$nextTick(function(){
          this.addUserTraining(response.data, data)
       })
      
      }) 
    },
    addUserTraining(data, table){
      //console.log(data.length)
      var training = data
      var wilbHold = table
      wilbHold.map(item => item['trainingFilter'] = this.filterArrayOfTraining(item, training))
      wilbHold.map(item => item = this.filterArrayOfTrainingAddCredential(item, this.userReportCredentials))
      this.userReportFields.push('View Data')   
      this.userReportTable = wilbHold
      this.completeTrainingHoursChecks()

    },
    filterArrayOfTraining(user, training){
        var obj = {}
        obj = user
        var utrain = training.filter(p => {
          var index = p.trainingRecordUsers ? p.trainingRecordUsers.indexOf(obj.id) : -1
          if(index != -1){
            return p
          }
        })
        return utrain
    },
    filterArrayOfTrainingAddCredential(item, tcre){
       var obj = item
       var cred = []
       for(var c in tcre){
          var fbyc = obj.trainingFilter.filter(a => {
              var indexC = a.trainingRecordCredentials.indexOf(tcre[c].id)
              if(indexC != -1){
                return a
              }
          })
          cred.push({id: tcre[c].id, array: fbyc})
          obj[tcre[c].id] = tcre[c]

       }
      obj['trainingSort'] = cred
      return obj
    },
    completeTrainingHoursChecks(){
      var users = this.userReportTable
      console.log(users.filter(p => p.trainingFilter.length > 0))
      users.map((user) => {
      var obj = user
      var tcre = obj.trainingSort
      var rollover = []
      tcre.map(item => {
        if(item.array && item.array.length > 0){
          var goal = parseInt(obj[item.id].credentialRequirementHours)
            var totalHours = 0
            var fbyc = item.array
            for(var i in fbyc){
              var test = fbyc[i].trainingRecordHours
              if(test && test[0] && test[0] == '.'){
                test = 0 + test
              }
       //       console.log(test)
       //       console.log(parseInt(test))
              totalHours = totalHours + parseInt(test)
            }
            if(goal > totalHours){
                item['Completed'] = totalHours
                var holdComp = Math.floor((totalHours / goal) * 100)
                item['Compliance'] = holdComp
      
            } else if(obj[item.id].credentialRequirementRolloverId) {
              item['Completed'] = goal
              item['Compliance'] = 100
              rollover.push({id: obj[item.id].credentialRequirementRolloverId, rolloverid: obj[item.id].id, hours: totalHours - goal})
            } else {
              item['Completed'] = goal
              item['Compliance'] = 100           
            }
            
        } else {
               item['Completed'] = 0
               item['Compliance'] = 0
        }
      return item
      })
      if(rollover.length > 0){
      tcre.map(item => {
          var roll = rollover.filter(p => {
            return p.id == item.id
          })
          if(roll && roll.length > 0){
            if(item.Compliance != 100){
              var rollTotal = 0
              for(var r in roll){
                rollTotal = rollTotal + roll[r].hours
              }
              var currComp = item.Completed
              var totalHours = currComp + rollTotal
              var goal = parseInt(obj[item.id].credentialRequirementHours)
              if(goal > totalHours){
                item.Completed = totalHours
                var holdComp = Math.floor(goal / totalHours * 100)
                item.Compliance = holdComp
            }  else {
              item.Completed = goal
              item.Compliance = 100 
            
            }
            item['AttachedRollover'] = roll
            }
          }
      return item
      }) 
      }
      return user
      
      })
     // console.log(users)
      // var wilb = users.find(p => p.id == 'f660c13a-c699-4e80-a20f-37ae4df30571')
      this.loadingIndicator = null
      this.userReportTable = users
    },
    filteringAllThetraining(user, training, tcre){
        var obj = {}
        obj = user
        var utrain = training.filter(p => {
          var index = p.trainingRecordUsers ? p.trainingRecordUsers.indexOf(obj.id) : -1
          if(index != -1){
            return p
          }
        })
        if(obj.id == 'cf6161bb-7e4d-44a4-b6d2-cebd745bd08b' || obj.id == 'f660c13a-c699-4e80-a20f-37ae4df30571'){
  
        }
        var rollover = []
        for(var c in tcre){
          var fbyc = utrain.filter(a => {
              var indexC = a.trainingRecordCredentials ? a.trainingRecordCredentials.indexOf(tcre[c].id) : -1
              if(indexC != -1){
                return a
              }
          })
          if(fbyc.length > 0){
            obj[tcre[c].id] = {}
            obj[tcre[c].id] = tcre[c]
            var goal = parseInt(obj[tcre[c].id].credentialRequirementHours)
            obj[tcre[c].id]['attachedTraining'] = fbyc
            var totalHours = 0
            for(var i in fbyc){
              totalHours = totalHours + parseInt(fbyc[i].trainingRecordHours)
            }
            if(goal > totalHours){
                obj[tcre[c].id]['Completed'] = totalHours
                var holdComp = Math.floor((totalHours / goal) * 100)
                obj[tcre[c].id]['Compliance'] = holdComp
      
            } else if(obj[tcre[c].id].credentialRequirementRolloverId) {
              obj[tcre[c].id]['Completed'] = goal
              obj[tcre[c].id]['Compliance'] = 100
                if(fbyc.length == 1){
         //         console.log('ROLLOVER TEST')
                }
              rollover.push({id: obj[tcre[c].id].credentialRequirementRolloverId, rolloverid: obj[tcre[c].id].id, hours: totalHours - goal})
            } else {
              obj[tcre[c].id]['Completed'] = goal
              obj[tcre[c].id]['Compliance'] = 100 
                  if(fbyc.length == 1){
       //           console.log('ROLLOVER ELSE TEST')
                }
            
            }
          } else {
     //       console.log(tcre[c])
             obj[tcre[c].id] = tcre[c]
             obj[tcre[c].id]['Completed'] = 0
              obj[tcre[c].id]['Compliance'] = 0
          }
        } 
        if(rollover.length > 0){
        for(var t in tcre){
          var roll = rollover.filter(p => {
            return p.id == tcre[t].id
          })
          if(roll && roll.length > 0){
            var prevTrain = obj[tcre[t].id]
            if(prevTrain.Compliance != 100){
              var rollTotal = 0
              for(var r in roll){
                rollTotal = rollTotal + roll[r].hours
              }
              var currComp = obj[tcre[t].id].Completed
              var totalHours = currComp + rollTotal
              var goal = parseInt(obj[tcre[t].id].credentialRequirementHours)
              if(goal > totalHours){
                obj[tcre[t].id].Completed = totalHours
                var holdComp = Math.floor(goal / totalHours * 100)
                obj[tcre[t].id].Compliance = holdComp
      
            }  else {
              obj[tcre[t].id].Completed = goal
              obj[tcre[t].id].Compliance = 100 
            
            }
            obj[tcre[t].id]['AttachedRollover'] = roll
            }
          }

        }
        }
        console.log(obj)
        return obj
    }

    



},

}
</script>
<style :src="'./customcss/vue-multiselect.min.css'" >

</style>
<style scoped>
.filterOptions {
    width: 200px;
    padding-left: 10px;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
    height: 50px;
    margin-top: -14px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}

.credential-table {
  text-align: center;
}
.changeWidth {
  width: 80%;
  margin: 0 auto 0;
  text-align: center;
}
.userPostIcon{
  position: relative;
  margin: 0px 0 -80px 0px;
  width:32px;
  height:32px;
  opacity: 0.3;
  
}
.blogadjust {
  text-align: center;
    width: 80%;
    margin: 0 auto 0;
}
.userPostForms{
  width:80%;
  padding-top: 10px;
  margin: 0 auto 0;
  border-bottom: 1px solid #aaa;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}



#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {
  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
@media screen and (max-width: 768px) {
.userPostIcon{
    display: none;
  
}
}

</style>
