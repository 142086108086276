import Vue from 'vue'
import Router from 'vue-router'
import Documents from '../components/admin/adminDocuments.vue'
import Apparatus from '../components/admin/adminApparatus.vue'
import Tutorials from '../components/portals/deptTutorials.vue'
import TrainingPortal from '../components/portals/trainingPortal.vue'
import logisticsPortal from '../components/portals/logisticsPortal.vue'
import logisticsInvPortal from '../components/portals/logisticsInvPortal.vue'
import Vacation from '../components/portals/vacationPortal.vue'
import MaintenancePortal from '../components/portals/maintenancePortal.vue'
import Employees from '../components/admin/adminEmployees.vue'
import Medication from '../components/admin/adminMedication.vue'
import Blogs from '../components/portals/blogPortal.vue'
import formsMain from '../components/portals/digitalForms.vue'
import formsNew from '../components/tracking/forms/formsnew.vue'
import formSubs from '../components/tracking/forms/formsubs.vue'
import formData from '../components/tracking/forms/formsdata.vue'
import Datashare from '../components/tracking/dataShare.vue'
import TrainingDrill from '../components/tracking/training/trainingDrillDataPage.vue'
import TrainingReportPage from '../components/tracking/training/trainingReportingPage.vue'
import AdminTraining from '../components/admin/adminTraining.vue'
import AdminContentExport from '../components/admin/adminContentExport.vue'
import reportsMain from '../components/tracking/report/reports.vue'
import AdminBanner from '../components/admin/adminBanner.vue'
import AdminMain from '../components/admin/adminMain.vue'
import DocumentsViewer from '../components/portals/documentViewer.vue'
import staffingPortal from '../components/portals/staffingPortal.vue'
import preplanPortal from '../components/portals/preplanPortal.vue'
import deptDemo from '../components/portals/deptDemo.vue'
import CalendarApp from '@/components/CalendarApp'
import Contact from '@/components/mainMenu/Contact'
import WorkGroup from '@/components/tracking/workgroups/workgroupmain.vue'
import Taxonomy from '@/components/admin/adminTaxonomy.vue'
import mainApp from '@/components/mainApp'
import EMSQA from '@/components/tracking/narclog/ApparatusEMSQAAudit.vue'
import BulkAdd from '../components/bulkAdd.vue'
import BulkAddPostGres from '../components/bulkAddPostGres.vue'
import FormBuilder from '../components/admin/adminForms.vue'
import VersionHistory from '../components/secondarymenu/uVersionHistory.vue'
import adminNarcotics from '../components/admin/adminNarcotics.vue'
import apidocs from '@/components/apidocs.vue'
import tvView from '@/components/tv/tv.vue'
import cprtest from '../components/secondarymenu/cprtraining.vue'
import medicTruck from '../components/tracking/report/medicTruckData.vue'
import runData from '../components/tracking/preplans/runTable.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'mainApp',
      component: mainApp
    },
    {
      path: '/report/rundata',
      name: 'runData',
      component: runData
    },
    {
      path: '/report/medictruck',
      name: 'medicTruck',
      component: medicTruck
    },
    {
      path: '/version-history',
      name: 'VersionHistory',
      component: VersionHistory
    },
    {
      path: '/cprtest',
      name: 'CprTest',
      component: cprtest
    },
    {
      path: '/server-api',
      name: 'ApiDocs',
      component: apidocs
    },
    {
      path: '/tv',
      name: 'Tv',
      component: tvView
    },
    {
      path: '/portal/forms',
      name: 'formsMain',
      component: formsMain
    },
    {
      path: '/reports',
      name: 'reportsMain',
      component: reportsMain
    },
    {
      path: '/newform/:formId',
      name: 'New Form',
      component: formsNew,
      props: true
    },
    {
      path: '/formsubmission/:formId',
      name: 'Form Submission',
      component: formSubs,
      props: true
    },
    {
      path: '/formsdata/:formId',
      name: 'Form Data',
      component: formData,
      props: true
    },
    {
      path: '/bulkadd',
      name: 'bulk add',
      component: BulkAdd
    },
    {
      path: '/bulkaddpostgres',
      name: 'bulk add pg',
      component: BulkAddPostGres
    },
    {
      path: '/admin',
      name: 'adminmain',
      component: AdminMain
    },
    {
      path: '/admin/narcotics',
      name: 'adminNarcotics',
      component: adminNarcotics
    },
    {
      path: '/admin/medications',
      name: 'adminMed',
      component: Medication
    },
    {
      path: '/contentExport',
      name: 'adminContentExport',
      component: AdminContentExport
    },
    {
      path: '/report/training',
      name: 'trainingreport',
      component: TrainingReportPage
    },
    {
      path: '/admin/banner',
      name: 'adminBanner',
      component: AdminBanner
    },
    {
      path: '/datashare/:dataShareType',
      name: 'datashare',
      component: Datashare,
      props: true
    },
    {
      path: '/formadmin/:formId',
      name: 'adminForm',
      component: FormBuilder,
      props: true
    },
    {
      path: '/admin/taxonomy',
      name: 'adminTax',
      component: Taxonomy
    },
    {
      path: '/admin/training',
      name: 'admintraining',
      component: AdminTraining
    },
    {
      path: '/workgroup/:wgName?',
      name: 'Work Group Page',
      component: WorkGroup,
      props: true
    },
    {
      path: '/portal/vacation',
      name: 'Vacation',
      component: Vacation
    },
    {
      path: '/portal/preplan',
      name: 'PrePlan',
      component: preplanPortal
    },
    {
      path: '/portal/deptdemo',
      name: 'Dept Demo',
      component: deptDemo
    },
    {
      path: '/portal/staffing',
      name: 'Staffing',
      component: staffingPortal
    },
    {
      path: '/portal/tutorials',
      name: 'tutorials',
      component: Tutorials
    },
    {
      path: '/admin/documents',
      name: 'documentsAdmin',
      component: Documents
    },
    {
      path: '/admin/employees',
      name: 'employeesAdmin',
      component: Employees
    },
    {
      path: '/admin/apparatus',
      name: 'apparatusAdmin',
      component: Apparatus
    },
    {
      path: '/portal/documents',
      name: 'documents',
      component: DocumentsViewer

    },
    {
      path: '/portal/blogs',
      name: 'blogs',
      component: Blogs
    },
    {
      path: '/portal/logisticsback',
      name: 'logistics',
      component: logisticsPortal
    },
    {
      path: '/portal/logistics',
      name: 'logisticsinv',
      component: logisticsInvPortal
    },
    {
      path: '/portal/training',
      name: 'training',
      component: TrainingPortal
    },
    {
      path: '/portal/maintenance',
      name: 'Maintenance',
      component: MaintenancePortal
    },
    {
      path: '/stations/:stationName',
      name: 'calendarapp',
      component: CalendarApp,
      props: true

    },
    {
      path: '/training/:id',
      name: 'Training Drill Data',
      component: TrainingDrill,
      props: true
    },
    {
      path: '/apparatus/:apparatusName',
      name: 'apparatus',
      component: CalendarApp,
      props: true
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/reporting/emsqa',
      name: 'emsqa',
      component: EMSQA
    }
  ]
})
