<template>
  <div>
    <a id="resp-menu" class="responsive-menu" :class="siteconfig.theme == 'blue' ? 'responsive-menu-blue' : siteconfig.theme == 'green' ? 'responsive-menu-green' : 'responsive-menu-red'" @click.stop="showUlClass =! showUlClass">&#9776; Menu</a>
    <span class="mobileBranding">{{ siteconfig.deptAbbr }}</span>
    <ul class="menu" :class="showUlClass ? 'menuHide' : '' && siteconfig.theme == 'blue' ? 'menu-background-blue' : siteconfig.theme == 'green' ? 'menu-background-green' : 'menu-background-red'" v-click-outside="closeEvent" >
      <li>
        <router-link to="/" >Home</router-link>
      </li>
      <li v-if="!siteconfig.hideEmployeePortals">
        <a @click="showEmployeePortalToggle"  >Employee Portals</a>
        <ul class="sub-menu" v-show="showEmployeePortal">
          <li v-if="!siteconfig.hideBlogPortalLink">
            <router-link to="/portal/blogs">Blogs/Notifications</router-link>
          </li>
          <li v-if="!siteconfig.hideDocumentPortalLink">
            <router-link to="/portal/documents">Documents/Forms</router-link>
          </li>
          <li v-if="!siteconfig.hideLogisticPortalLink">
            <router-link to="/portal/logistics">Logistics</router-link>
          </li>
          <li v-if="!siteconfig.hideMaintenancePortalLink">
            <router-link to="/portal/maintenance">Maintenance</router-link>
          </li>
          <li v-if="siteconfig.payroll && siteconfig.payroll.staffing">
            <router-link to="/portal/staffing">Staffing</router-link>
          <li>
          <li v-if="!siteconfig.hidePreplanPortalLink">
            <router-link to="/portal/preplan" >Preplans/Inspections</router-link>
          <li>
          <li v-if="!siteconfig.hideTrainingPortalLink">
            <router-link to="/portal/training">Training</router-link>
          </li>
          <li v-if="!siteconfig.hideWorkgroupPortalLink">
            <a  @click="showWorkGroupsToggle">Work Groups</a>
            </li>
        </ul>
        <ul class="sub-menu" v-show="showWorkGroups">
                   <li><a @click.stop="showEmployeePortalToggle">Back</a></li>
                  <li v-for="group in workGroupFiltered" :key="group.index">
                     <router-link :to="`/workgroup/${apparatusLink(group.workgroupName)}`">{{ group.workgroupName }}</router-link>
                  </li>
                  <li v-if="workgroupData.length == 0">
                     <router-link :to="`/workgroup/`">Work Groups</router-link>
                  </li>
        </ul>
      </li>
      <li v-show="!siteconfig.hideApparatusMainLink">
        <a @click="showAppparatusToggle">Apparatus</a>
         <ul class="sub-menu" v-show="showApparatus">
             <li  v-for="parent in filterApparatusParents" :key="parent.index">
               <a  @click.stop="setApparatusParentAndShowBatt(parent)">{{ parent }}</a>
            </li>
        </ul>
        <ul class="sub_sub_menu1" v-show="showBattSub">
          <li><a @click.stop="showAppparatusToggle">Back</a></li>
          <li v-for="batt in filterApparatusBatt" @click.stop="filterApparatusLocationByParentsAndBatt(batt)" :key="batt.index"><a>{{ batt }}</a></li>
        </ul>
        <ul class="sub_sub_sub_menu1" v-show="showApparatusSubSub">
          <li><a @click.stop="showBattalionToggle">Back</a></li>
            <li v-for="appBatt in apparatusSecondList" :key="appBatt.index">
            <router-link :to="`/apparatus/${apparatusLink(appBatt.apparatusName)}`" >{{ appBatt.apparatusName }}</router-link>
          </li>
        </ul>
        <ul class="sub_sub_menu1" v-show="showApparatusNoSub">
          <li><a @click.stop="showAppparatusToggle">Back</a></li>
          <li v-for="appNoBatt in apparatusList" :key="appNoBatt.index">
              <router-link :to="`/apparatus/${apparatusLink(appNoBatt.apparatusName)}`">{{ appNoBatt.apparatusName }}</router-link>
            </li>
        </ul>
      </li>
      <li v-if="filterStationParents.length > 0 && !siteconfig.hideStationsMainLink">
        <a @click="showStationsToggle">Stations</a>
        <ul class="sub-menu" v-show="showStations">
          <li v-for="station in filterStationParents" :key="station">
            <a @click.stop="filterStationByParents(station)" >{{ station }}</a>
          </li>
        </ul>
        <ul class="sub_sub_menu1" v-show="showStationsSub" >
          <li v-if="!singleStationParent"><a @click.stop="showStationsToggle">Back</a></li>
            <li v-for="sta in stationList" :key="sta.index">
            <router-link :to="`/stations/${apparatusLink(sta.stationName)}`"  >{{ sta.stationName }}</router-link>
          </li>
      </ul>
      </li>
      <li>
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
    <div class="deptInfoButton">
      <a @click.stop="toggleDeptInfoPan">
      <img class="deptInfoButtonImg" src="/images/siteicons/external-links.png" title="Dept Info" height="32" width="32">
    </a>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { bus } from './../main'
import _ from 'lodash'
export default {
  props: ['closeEventTrigger'],
  data () {
    return {
      stationList:[],
      apparatusList: [],
      apparatusSecondList: [],
      apparatusParent: '',
      showStations: false,
      showApparatus: false,
      showStationsSub: false,
      showApparatusSub: false,
      showApparatusSubSub: false,
      showApparatusNoSub: false,
      showEmployeePortal: false,
      showWorkGroups: false,
      singleStationParent: false,
      filteredStation: [],
      parentMenuArray: [],
      showUlClass: true,
      showBattSub: false,
      filterApparatusBatt: [
      ],
      msg: 'Welcome to Your Vue.js App'
    }
  },
  created () {

},
computed: {
  ...mapState ({
    apparatus: 'apparatusData',
    stations: 'stationsData',
    userData: 'userData',
    workgroupData: 'workgroupData',
    siteconfig: 'siteconfig'
  }),
  filterApparatusParents () {
    var filterUser = this.apparatus.filter( p => {
      if(!p.apparatusMenuVisibility){
        console.log(p)
      }
      var index = p.apparatusMenuVisibility.indexOf(this.userData.role)
      if(index >= 0){
        return p
      }
    })
    var filterApp = [...new Set(filterUser.map(p => p.apparatusVehicleType))]
    var parents = filterApp.filter( p => {
      if(p != '' && p != undefined){
        return p
      }
    })
    return _.sortBy(parents)
  },
  filterStationParents () {
    var filteredSta = [...new Set(this.stations.map(p => p.stationParent))]
    var parents = filteredSta.filter( p => {
      if(p != '' && p != undefined && p != null){
        return p
      }
    })
   // console.log('PARENTS')
   // console.log(parents)
    if(parents.length == 1){
     // console.log('1 Parent')
      this.singleStationParent = true
    }
    return _.sortBy(parents)
  },
  workGroupFiltered(){
    return this.workgroupData.filter( p => {
      return p.workgroupActive == true
    })
  },
},
  watch: {
    $route(newRoute){
      return this.closeEvent()
    },
 /*   stations(newData){
      return this.filterStationParents(newData)
    }, */
  /*  apparatus(newData){
      return this.filterApparatusParents(newData)
    }, */
},
methods: {
  closeEvent(){
    this.showStations = false,
    this.showApparatus = false,
    this.showStationsSub = false,
    this.showApparatusSub = false,
    this.showApparatusSubSub = false,
    this.showApparatusNoSub = false,
    this.showEmployeePortal = false,
    this.showWorkGroups = false,
    this.showUlClass = true
  },

  apparatusLink(name){
        return name.replace(/\s+/g, '-').toLowerCase();
  },
  toggleDeptInfoPan(event){
    bus.$emit('showDeptInfoPan')
  },
  showWorkGroupsToggle(){
    this.showApparatus = false,
    this.showBattSub = false
    this.showApparatusNoSub = false
    this.showApparatusSubSub = false
    this.showStationsSub = false,
    this.showEmployeePortal = false,
    this.showStations = false,
    this.showWorkGroups = !this.showWorkGroups
  },
  showStationsToggle(){
    this.showApparatus = false,
    this.showBattSub = false
    this.showApparatusNoSub = false
    this.showApparatusSubSub = false
    this.showEmployeePortal = false
    this.showWorkGroups = false
    if(this.filterStationParents.length > 1){
      this.showStations = !this.showStations
      this.showStationsSub = false
    } else if(this.filterStationParents.length == 1) {   
     // console.log('THIS FILTER STATIONS PARENTS LENGTH')
    this.showStationsSub = !this.showStationsSub
    this.stationList = []
    this.filterStationByParents(this.filterStationParents[0])
    this.showStations = false
    } else {
      this.showStations = !this.showStations
      this.showStationsSub = false
    }
  },
  showAppparatusToggle(){
    this.showStations = false
    this.showStationsSub = false
    this.showBattSub = false
    this.showApparatusNoSub = false
    this.showApparatusSubSub = false
    this.showEmployeePortal = false
    this.showWorkGroups = false,
    this.showApparatus = !this.showApparatus
  },
  showEmployeePortalToggle(){
      this.showApparatus = false,
      this.showStationsSub = false,
      this.showBattSub = false
      this.showApparatusNoSub = false
      this.showApparatusSubSub = false
      this.showEmployeePortal = false
      this.showStations = false,
      this.showWorkGroups = false,
      this.showEmployeePortal = !this.showEmployeePortal

  },
  showBattalionToggle(){
    if(this.parentMenuArray.length <= 1){
      this.showApparatusSubSub = false
      this.showApparatus = true
    } else {
    this.showApparatusSubSub = false
    this.showBattSub = true
  }
  },
  stationSelectNameTrigger(data){
    bus.$emit('stationSelectName', data)
    this.showUlClass =! this.showUlClass
  },
  homeTrigger(){
    bus.$emit('homeSelect')
  },
  filterStationByParents (parent) {
   // console.log(parent)
    if(this.stationList != '' ){
    var currentStationList = this.stationList[0].stationParent 
    }
   // console.log(this.stationList)
  // console.log(this.stations)
    var stationList = this.stations.filter((station) => {
    if(station.stationParent != '' && station.stationParent != undefined && station.stationParent != null){
       // console.log(station)
        return station.stationParent.match(parent)
      }
      if(station.stationName == parent && (this.userData.role == 'admin' || this.userData.role == 'fireops' || this.userData.role == 'emsops' || this.userData.role == 'trainingops' || this.userData.role == 'adminchief')){
        return station
      }
      else {
        return
      }}
    )
    this.stationList = _.orderBy(stationList, ['stationName'], ['asc'])
   // console.log('STATIONS LIST')
   // console.log(stationList)
   // console.log(currentStationList)
   // console.log(this.stationList[0].stationParent)
   // console.log(this.showStationsSub)
    if ((currentStationList == (this.stationList[0].stationParent == null ? undefined : this.stationList[0].stationParent )) && (this.showStationsSub == true) && !this.singleStationParent){
    //  console.log('FIRST SHOW STATION')
      this.showStationsSub = false
      this.showStations = true
    }
    else{
     // console.log('ELSE STATEMENT')
      this.showStations = false
      this.showStationsSub = true
    }

  },
    filterApparatusLocationByParents (parent) {
      if(this.apparatusList != '' ){
      var currentApparatusList = this.apparatusList.apparatusName }
      var apparatusList = this.apparatus.filter((p) => {
      return p.apparatusName.match(parent)})
      this.apparatusList = apparatusList
      if ((currentApparatusList == this.apparatusList.apparatusName) && (this.showApparatusNoSub == true)){
        this.showApparatusNoSub = false
      }
      else{
        this.showApparatus = false
        this.showApparatusNoSub = true
      }
    },
    setApparatusParentAndShowBatt(parent){
      this.parentMenuArray = []
      this.apparatusParent = parent
      var parentSet = this.apparatus.filter((p) => {
      if(p.apparatusVehicleType != '' && p.apparatusVehicleType != undefined){
        return p.apparatusVehicleType.match(parent)
         } else {
           return
         }
      })
      /* implement a filter that bypasses battalion seperation if the parentSet.length < 8
      console.log(parentSet.length) */
      var newParentMenu = [... new Set(parentSet.map(p => p.apparatusParent))]
      var orderParentMenu = _.sortBy(newParentMenu)
      this.parentMenuArray = orderParentMenu
      if(orderParentMenu.length == 1){
        this.filterApparatusLocationByParentsAndBatt(orderParentMenu)
        this.showApparatus = false
      } else {
      this.filterApparatusBatt = orderParentMenu
      this.showApparatus = false
      this.showBattSub = true
    }
    },
      filterApparatusLocationByParentsAndBatt (batt) {
        if(this.apparatusSecondList != '' ){
        var currentApparatusList = this.apparatusSecondList.apparatusParent }
        var apparatusSecondList = this.apparatus.filter((p) => {
          if(p.apparatusVehicleType != '' && p.apparatusVehicleType != undefined && p.apparatusParent != undefined){
        return p.apparatusVehicleType == this.apparatusParent && p.apparatusParent.match(batt)
        } else {
          return
        }})
        this.apparatusSecondList = _.orderBy(apparatusSecondList, ['apparatusName'], ['asc'])
        if ((currentApparatusList == this.apparatusSecondList[0].apparatusParent) && (this.showApparatusSubSub == true)){
          this.showApparatusSubSub = false
        }
        else{
          this.showBattSub = false
          this.showApparatusSubSub = true
        }

        }

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}

  html { -webkit-font-smoothing: antialiased; }

        a {
            color: #BA0707;
            text-decoration: none;
        }

        a:hover { color: #e2070d; }
        body {
            background: #e5e5e5;
            color: #e2070d;
            font: 14px "roboto", Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            line-height: 1;
            width: 100%;
        }

        nav {
            display: block;
            background: #ffffff;;
        }

        .menu {
            margin: 0 auto;
            padding: 0;
            display: block;
            float: none;
            min-width: 200px;
            position: relative;
            z-index: 300;
            font-size: 16px;
            font-family: "Open Sans", sans-serif;
            width: 100%;
            font-weight: 400;
            clear: both;
            min-height: 2.4em;
            background: #fff;
          }
        .sub-menu, .sub_sub_sub_menu1, .sub_sub_menu1{
          text-align: left;
      		border-radius: 0 0 5px 5px;
      		border: 1px solid #777;
      		border-top: #fff;
      		left:0px !important;
      		padding: 1px !important;
        }

        .menu li {
            display: inline-block;
            position: relative;
            z-index: 100;
        }

        .menu li:first-child { margin-left: 0; }

        .menu li a {
            font-weight: 700 !important;
            text-decoration: none;
            padding: 10px 15px;
            display: block;
            color: #222;
            transition: all 0.2s ease-in-out 0s;
        }

        .menu li a:hover,
        .menu-background-red li:hover>a {
            color: #e2070d;
            background: #fff;
        }
        .menu-background-blue li:hover>a {
            color: #007bff;
            background: #fff;
        }
        .menu-background-green li:hover>a {
            color: #28a745;
            background: #fff;
        }

        .menu ul {

            opacity: 1;
            margin: 0;
            padding: 0;
            width: 170px;
            position: absolute;
            left: -60px;
            background: #fff;
            z-index: 99;
            transition: all 0.2s ease-out;
        }



        .menu ul li {
            display: block;
            float: none;
            background: none;
            margin: 0;
            padding: 0;
        }

        .menu ul li a {
            font-size: 12px;
            font-weight: normal;
            display: block;
            color: #333;
            background: #fff;
           }
      .menu ul li a:hover{
            background: #eeeeee;
            color: blue;
            cursor: pointer;
        }

        .menu li:hover>ul {
            display:block;
            opacity: 1;
            transform: translate(0, 0);
        }

        .menu li:hover ul.sub-menu {
            display:block;
            opacity: 1;
            transform: translate(0, 0);
        }

        .menu ul ul {
            left: 169px;
            top: 0px;

            opacity: 1;
            transform: translate(20px, 20px);
            transition: all 0.2s ease-out;
        }


        .menu li>ul ul:hover {

            opacity: 1;
            transform: translate(0, 0);
        }

        .responsive-menu {
            display: none;
            width: 100%;
            padding: 15px 15px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 600;
        }
        .responsive-menu-red {
           background: #e2070d;
        }
        .responsive-menu-blue {
           background: #007bff;
        }
        .responsive-menu-green {
           background: #28a745;
        }
        .mobileBranding {
          display: none;
        }
        .responsive-menu:hover {
            color: #fff;
            text-decoration: none;
        }
        .deptInfoButton {
          display: none;
        }
        a.hover { background: #e2070d; }
        @media (min-width: 782px) and (max-width: 979px) {
            .mainWrap { width: 768px; }

            .menu ul { top: 37px; }

            .menu li a { font-size: 16px; padding: 10px 10px;}
            a.hover { background: #e2070d; }
        }
        @media (max-width: 768px) {
          .menu{
            	border-bottom: 3px solid #000;
          }
          .deptInfoButton {
          	display: block;
          	float: right;
          	position: relative;
          	margin: -55px 0 0 0;
          }

          .deptInfoButtonImg {
            width: 32px !important;
            height: 32px !important;
            margin-right: 15px;
            margin-top: 10px !important;
          }
          #menuBar  {
              width: 35px;
              height: 5px;
              background-color: black;
              margin: 6px 0;

            }

            .mainWrap {
                width: auto;
                padding: 50px 20px;
            }

            .menuHide { display: none; }

            .mobileBranding {
              display: block;
              position: absolute;
              top: 0;
              padding-top: 4px;
              left: 40%;
              font-size: 30px;
              font-weight: bold;
              color: white;
              text-shadow: 1px 2px #000;
            }
            .responsive-menu { display: block; z-index: 1000; text-align: left;}
            a.responsive-menu { color: white; cursor: pointer;}

            nav {
                margin: 0;
                background: none;
            }

            .menu li {
                display: block;
                text-align: left;
                margin: 0;
            }

            .menu li a {
                background: #fff;
                color: #797979;
            }

            .menu li a:hover,
            .menu li:hover>a {
                background: #e2070d;
                color: #fff;
            }

            .menu ul {

                opacity: 1;
                top: 0;
                left: 0;
                width: 100%;
                transform: initial;
            }

            .menu li:hover>ul {

                opacity: 1;
                position: relative;
                transform: initial;
            }

            .menu ul ul {
                left: 0;
                transform: initial;
            }

          .menu li>ul ul:hover { transform: initial; }
        }




</style>
