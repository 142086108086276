<template>
    <b-container class="MobileWidth">
      <b-row align-h="center"  class="titleHead">
        <b-col cols="*" class="pl-2 pt-2" v-if="calendarType != 'payroll'"><b-form-checkbox :check-value="true" :uncheck-value="false" v-model="dayselectboo" @change="setDayDisplay">Group?</b-form-checkbox></b-col>
        <b-col align="right">
        <div class="titleSub">{{ calendarHeader }}</div></b-col>
        <b-col  cols="3" align="left">
        <div  @click="calendarType == 'payroll' ? addPayroll() : showModalSwitch"  >
            <img class="calendarIcon" src="/images/siteicons/calendar-plus-icon.png" title="User" height="40" width="auto">
        </div>
      </b-col>
      </b-row>
      <b-row>
          <div class="title"> {{title}}</div>
      </b-row>
      <div class="event-box">
        <eventcard
        v-for="event in formatedDay"
        :daydisplay="daydisplayboo"
        :event="event"
        :key="event.id"
        :is-day-selected="isDaySelected"
        :calendarType="calendarType"
          >
        ></eventcard>
      </div>
        <b-row align-h="center">
            <div class="btn-group">
                <button @click.stop="goPrev" class="btn btn-primary">&lArr;
                    <span>Prev</span>
                </button>

                <button @click.stop="goToday" class="btn btn-secondary today-button">

                    <span>Today</span>
                </button>

                <button @click.stop="goNext" class="btn btn-primary">
                    <span>Next</span>
                    &rArr;
                </button>
            </div>
        </b-row>
    <newevent v-if="showModalEvent" :showModal="showModalEvent" :filterDate="filteredDate" />
    </b-container>
</template>
<script>
    import { bus } from '../../../main'
    import moment from 'moment';
    import NewEvent from '../../addNew/newCalendarPostFull.vue'
    import {CHANGE_MONTH} from '../actions';
    import { mapState } from 'vuex'
    import EventCard from './EventCard.vue'
    export default {
        props : {
            events : {
              type: Array,
              default: [],
            },
            currentMonth: {
              type: Object
            },
            calendarHeader: {
              type: String,
              default: 'Calendar'
            },
            daydisplayboo: {
                type: Boolean,
                default: false,
            },
            calendarType: {
                type: String,
                default: null
            }
        },
        components: {
          'eventcard': EventCard,
          'newevent': NewEvent
        },
        data (){
            return {
                showModalEvent: false,
                selectedDate: '',
                isDaySelected: true,
                daydisplay: true,
                dayselectboo: false,
            }
        },
        created(){
          this.selectedDate =  moment().format()
          bus.$on("showModalSwitchFullCalendar", this.showModalSwitch);
          this.dayselectboo = !this.daydisplayboo
        },
        computed: {
          ...mapState([
              'userData',
              'siteconfig'
          ]),
          filterEvents(){
            var formatDate = moment(this.selectedDate).format('YYYY-MM-DD')
            return this.events.filter(p => p.date.match(formatDate))
          },
          filteredDate(){
          return  moment(this.selectedDate).format('MM/DD/YYYY')
          },
          title(){
            return  moment(this.selectedDate).format('ddd: MMM Do YYYY')
          },
          formatedDay(){
              if(this.daydisplayboo){
              return this.filterEvents
              } else {
                  var events = this.filterEvents
                  var holdArray = []
                  for(var e in events){
                      var items = events[e].description
                      for(var i in items){
                          var obj = {}
                          obj['date'] = events[e].date
                          obj['color'] = events[e].color
                          obj['id'] = items[i].id
                          obj['eventDate'] = items[i].eventDate
                          obj['description'] = [items[i]]
                          obj['title'] = events[e].title
                          holdArray.push(obj)
                      }

                  }
                //  console.log(holdArray)
                  return _.orderBy(holdArray, ['eventDate'], ['asc'])
              }
          }

        },

        methods : {
           setDayDisplay(){
                this.$emit('setDayDisplay', this.dayselectboo)
            },
            showModalSwitch (event) {
            this.showModalEvent = !this.showModalEvent
            },
            addPayroll(){
              console.log(this.selectedDate)
              console.log(this.siteconfig.payroll.shiftStartTime)
                var date = moment(this.selectedDate).format('YYYY-MM-DD')
                var startDate = moment(date + 'T' + this.siteconfig.payroll.shiftStartTime, 'YYYY-MM-DDTHH:mm:ss').toISOString()
                console.log(startDate)
                     var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: this.userData.id, 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: 24, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '' }
                 bus.$emit('updatePayrollMod', dataObj)
            },
            goPrev () {
                var currentDate = this.selectedDate
                var newDate = moment(this.selectedDate).subtract(1, 'day')
                if(moment(currentDate).format('MM') != moment(newDate).format('MM')){
                  var payload = moment(this.currentMonth).subtract(1, 'months').startOf('month');
                    bus.$emit('CHANGE_MONTH', payload);
                    this.selectedDate = newDate
                }
                else{
                this.selectedDate = newDate }
            },
            goNext () {
              var currentDate = this.selectedDate
              var newDate = moment(this.selectedDate).add(1, 'day')
              if(moment(currentDate).format('MM') != moment(newDate).format('MM')){
                var payload = moment(this.currentMonth).add(1, 'months').startOf('month');
                  bus.$emit('CHANGE_MONTH', payload);
                  this.selectedDate = newDate
              }
              else{
              this.selectedDate = newDate }
            },
            goToday () {
                var currentDate = this.selectedDate
                var newDate = moment().format()
                if(moment(currentDate).format('MM') != moment(newDate).format('MM')){
                  var payload = moment().startOf('month');
                    bus.$emit('CHANGE_MONTH', payload);
                    this.selectedDate = moment().format()
                }
                else{
                this.selectedDate = moment().format() }
              },




        }
    }
</script>
<style scope>
    .MobileWidth.container {
      margin-bottom: 50px;
    }
    .event-box {
      margin-top: 10px;
    }
    .full-calendar-header{
        display: flex;
        align-items: center;
    }
    .header-center{
        flex:3;
    }
    .title {
        text-align: center;
        font-size: 1.5em;
        font-weight: bolder;
        width:100%;
    }
    .titleSub {
        font-size: 1.5em;
        font-weight: bolder;
    }
    .titleHead {
        Background: #444;
        color: #fff;
        padding: 5px;
        border-radius: 5px;

    }
    .language-select {
        display: inline-block;
        width: 50%;
    }
    .calendarIcon {
        height: 40px;
    }
@media screen and (max-width: 450px) {
    .titleSub {
        font-size: 1.5em;
        font-weight: bolder;
    }
}
</style>
