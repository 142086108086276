<template>
  <div @click="showFullCard = !showFullCard">
              <b-row class="cardRow" v-if="showFullCard" >
              <span :class="user.shiftAssignment" class="imageColumn"><img :src="userPhotoLink(user.picture)" /></span>
              <span  class="userDataSpan">

              </span>
                <div class="iconDiv">
                  <a :href="'mailto:' + user.email">
                    <img class="phoneIcons" src="/images/siteicons/email-user.png" />
                  </a>
                  <a v-if="user.personalMobile" :href="'tel:' + user.personalMobile">
                  <img class="phoneIcons" src="/images/siteicons/call-user.png" />
                  </a>
                  <a v-if="user.personalMobile == '' || user.personalMobile == null" disabled>
                  <img class="phoneIcons noPhone" src="/images/siteicons/call-user.png" />
                  </a>
              </div>

              </b-row>
              <div class="userDataWrap">
              <span class="spanClassNameAssign">{{ user.firstName }} {{ user.lastName }}</span>
            </div>
              <div class="clear"></div>
              <div class="userRank"><h6> {{ user.deptRank }}</h6></div>
              <div class="userRank" v-if="addShiftAndStation"><h6>{{ user.stationAssignment }} | {{ user.shiftAssignment}}</h6></div>
              <div slot=footer>

                      <div class="userDataWrap">
                      <span>
                    {{ getYearsService(user.hireDate)}}</span>
                   <span v-if="specialitiesArray.length > 0"><span v-for="(sp, index) in specialitiesArray" :key="index"><span class="specialH" :title="sp">{{ sp }}</span></span></span>
                  </div>
            </div>
</div>
</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'hello',
  props:[ 'user', 'specialitiesArray', 'defaultShow', 'addShiftAndStation'],
  components:{
  },
  data () {
    return {
      stringData: '',
      deptPhoneList: [],
      phoneNumberList: [],
      userSearchBox: '',
      showFullCard:  false,
    }
  },
  created(){
    if(this.defaultShow){
      this.showFullCard = true
    }
  },
  computed: {
    ...mapState([
      'deptContactData',
      'siteconfig'
    ]),
    phoneContactCat(){
      return [...new Set(this.deptPhoneList.map(p => p.Location))]
    },
    filterDepartmentSearch(){
      console.log('Filtered')
      return this.deptContactData.filter(p => {
        var stationName = this.stationName.replace('-', ' ')
        return p.field_station_assignment.toLowerCase().match(stationName.toLowerCase())
      })
    },
    tabDataPull(){
      return [... new Set(this.filterDepartmentSearch.map(p => p.field_shift_assignment))]
    },

  },
  watcher: {


  },
  methods: {
    shiftFilterDepartmentSearch(data){
      return this.filterDepartmentSearch.filter(p => {
        return p.shiftAssignment.match(data)
      })
    },
    getYearsService(data){
      var dataSlice = data.slice(0, -5)
      return moment(dataSlice).fromNow(true)
    },
    userPhotoLink(fileName){
    fileName = fileName ? fileName : 'files/default_images/Blank_User_Big.png'
    if(fileName.charAt(0) == '/'){
      return this.siteconfig.deptStore + fileName.replace('/', '')
        } else {
          return  this.siteconfig.deptStore + fileName
        }
    },
    parseDataField(){
    var dataParse = this.papa.parse(this.stringData, {header: true})
    this.phoneNumberList = dataParse
    },
    deptPhoneListData(){
       this. $http('/static/phonelist').then( response => {
        this.deptPhoneList = response.data
      })
    },
    filteredDeptPhoneList(parent){
      return this.deptPhoneList.filter(p => {
        return p.Location.match(parent)
      })
    },
  }

}
</script>

<style scoped>
.hello{
  min-width: 300px;
  margin: 20px 0 0 10px;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
}
.headerSpan {
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
}
div.headerSpan {
  background: #777;
  width: 100%;
  border-radius: 5px 5px 0 0;
  margin-bottom: 10px;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer{
  padding-bottom: 10px;
  margin-top: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {

  border-radius: 5px;
}
.A-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.B-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.C-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Day-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
a.nav-link.active.A-shift {
  background-color: red;
  display: none;
}
.A-shift{
  background: #e2070d;
  background: -moz-linear-gradient(left, #e2070d 0%, #e2070d 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift{
  background: #201B9F;
  background: -moz-linear-gradient(left, #201B9F 0%, #201B9F 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
.C-shift{
  background: #0FB906;
  background: -moz-linear-gradient(left, #0FB906 0%, #0FB906 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
      border-radius: 20px 20px 0 0;
}
.Day-shift{
  background: #E4CB07;
  background: -moz-linear-gradient(left, #E4CB07 0%, #E4CB07 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}

@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
