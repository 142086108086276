<template>
<div @click="addModalOpen">
  <b-modal
       id="bannerModal"
       ref="bannerModal"
       v-model="showModal"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
         >
         <template slot="modal-header" v-if="!showModalConfirmation">
           <span>
          Add New Banner Image
           <img src="/images/siteicons/help-icon-right-align.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo"></span>
           <b-btn align="right" size="sm" variant="danger" @click="showModalSwitchEmitBanner">x</b-btn>
         </template>
         <div class="modal-body" v-if="!showModalConfirmation">
           <tutdata v-if="showTutVideo" singlePost="newrepair" />


                  <b-container>
                    <br>
            <input type="text" class="itemDescription" v-model="itemShort" placeholder="Banner Image Title" />
            <textarea class="textAreaDescription" rows="4" v-model="detailedDescription" placeholder="Image Description"></textarea>
                  </b-container>

                <b-container v-if="pictureFile == ''">
                      <b-form-file ref="fileinput" @change="postImage" v-model="pictureUpload" />
   
                      <br>
                </b-container>
              <b-container v-if="imageSrc && pictureFile == ''">
                <img id="image" :src="imageSrc">
              </b-container>  
                <b-container v-if="imageSrc && pictureFile == ''">
                  <b-btn size="sm" variant="success" @click="postImageData" :disabled="saveCropped">Save Cropped Image</b-btn>
                  </b-container>
              </div>
              <b-container v-if="pictureFile">
                <b-img :src="siteconfig.deptStore + pictureFile" />
              </b-container>
         <template slot="modal-footer" v-if="!showModalConfirmation">
             <b-btn variant="primary" @click.stop="postNewBanner" 
             :disabled="itemShort == '' || detailedDescription == '' || pictureFile == ''"
             >Submit</b-btn>

           </template>


      <template slot="modal-header" v-if="showModalConfirmation">
             Post Confirmation
      </template>
      <div class="modal-body"  v-if="showModalConfirmation">
             <div v-if="postData == ''">
                   ...Submitting post
                   <br>
               <img src="https://firerescuedev.com/drupalBack/web/sites/default/files/siteicons/MalteseLoadingGif.gif">
             </div>
             <div v-if="postData != ''">
                   Your Post has been submitted!
             </div>
           
           </div>
             <template v-if="showModalConfirmation" slot="modal-footer">
                   <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                     Close Message
                   </b-btn>
            </template>
      </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios'
import moment from 'moment'
import TutData from '../tracking/deptTutorialsdata.vue'
import Cropper from 'cropperjs'
import ImageCompressor from 'image-compressor.js'
export default {
  props:["showModal", "filterDate"], 
components:{
      'tutdata' : TutData,

},
  data () {
    return {
        showTutVideo: false,
        pictureUpload: '',
        trueBoo: true,
        imageLoadReset: true,
        slide: 0,
        sliding: false,
        itemShort: '',
        showModalConfirmation: false,
        testValue: null,
        detailedDescription: '',
        carouselToggle: false,
        postData: '',
        imageSrc: '',
        pictureFile: '',
        imageLoader: '',
        storedCropper: '',
        saveCropped: false,

    }
  },
  created(){

  },
  computed:{
    ...mapState([
          'getSessionData',
          'userData',
          'siteconfig'

        ]),
      getTitleData(){
          return this.itemShort + ' | ' + this.userData.lastName + ' | ' + moment().format('YYYY-MM-DD')

      },
  

    },
    watch: {
      imageSrc(data){
        console.log(data)
      }
      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    getFiles(obj){
      this.postImage(obj)
    },
    clearFiles () {
      this.$refs.fileinput.reset();
      this.saveCropped = false
      this.storedCropper = ''
      this.imageSrc = ''
    },
    addModalOpen(){
      document.body.className += 'modal-open';
    },
    showModalSwitchEmitBanner () {
      this.itemShort = ''
      this.detailedDescription = ''
      this.pictureFile = []
      bus.$emit('switchEmitBanner');
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalSwitchEmitBanner()
    },
    postImage(e){
      var preview = document.querySelector('image')
      var files = e.target.files || e.dataTransfer.files; 
     // this.imageSrc = URL.createObjectURL(files[0])
      var size = files[0].size
      console.log(size)
      var comp = .6
      if(size && size > 2000000){
        comp = .5
      }
      if(size && size > 4000000){
        comp = .4
      }
      if(size && size > 6000000){
        comp = .3
      }
      if(size && size > 8000000){
        comp = .2
      }
      if(size && size > 10000000){
        comp = .1
      }
      var vm = this
      var successReturn = new ImageCompressor(files[0], {
        quality: comp,
        success(result) {
          console.log(result)
          vm.imageSrc = URL.createObjectURL(result)
            vm.$nextTick(function(){
              vm.cropperSet()
            })
        }
        })

    },
    cropperSet(){
      const image = document.getElementById('image');
      const cropper = new Cropper(image, {
        aspectRatio: 16 / 7,
        crop(event) {
      /**   console.log(event.detail.x);
        console.log(event.detail.y);
          console.log(event.detail.width);
          console.log(event.detail.height);
          console.log(event.detail.rotate);
          console.log(event.detail.scaleX);
          console.log(event.detail.scaleY); */
        },
      });
      this.storedCropper = cropper
    },
    postImageData(){ 
      this.saveCropped = true
      const cropper = this.storedCropper
      cropper.getCroppedCanvas().toBlob((blob) => {
          const formData = new FormData();

          formData.append('uploadFile', blob);
      axios({
        method: 'post',
        url: '/api/v1/upload/files/bannerImages/' + this.userData.lastName + '/' + moment().format('YYYY-MM-DD'),
        data: formData,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.pictureFile = response.data
          this.clearFiles()

        });
      })
    },
    /*
    postImage(e) {
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])

       axios({
        method: 'post',
        url: '/api/v1/upload/files/bannerImages/' + this.userData.lastName + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.pictureFile = response.data
          this.clearFiles()

        });

  
    }, */
     postNewBanner(){
       axios({
        method: 'post',
        url: '/api/v1/content/banner',
        data: {
                "title": this.getTitleData,
                "bannerItem": this.itemShort,
                "bannerDescription": this.detailedDescription,
                "bannerPicture": this.pictureFile,
                "bannerAuthor": this.userData.firstName + ' ' + this.userData.lastName,
                "bannerAuthorId": this.userData.id,

              },
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {

         this.postData = response.data
          
        })
       bus.$emit('refreshUserPosts')
          this.showModalConfirmation = !this.showModalConfirmation;
    },

}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
img {
  max-width: 100%; /* This rule is very important, please do not ignore this! */
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
