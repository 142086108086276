<template>
<div>
  <div class="maxFormWidth" v-if="getFormId != ''">
    <formsub :formData="getFormId" :preview="false" @submitForm="saveForm($event)" @completeForm="completeForm($event)" />
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import formSub from '../../admin/forms/formsub.vue'
import axios from 'axios'
export default {
  props:['formId'],
components:{
     'formsub' : formSub,
},
  data () {
    return {
      getFormId: '',
    }
  },
  created(){
    this.getFormData(this.formId)
  },
  computed:{
    ...mapState([
      'userData',
      'getSessionData',
      'formtempData'
    ]),

  },
  methods: {
    getFormData(data){
    axios({
        method: 'get',
        url: '/api/v1/forms/submissions/' + data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {

        this.getFormId = response.data
          
        })
    },
   saveForm(data){
    axios({
        method: 'patch',
        url: '/api/v1/forms/submissions/' + data.id,
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
            this.$router.push({ path: `/formsubmission/${response.data.id}` })
         console.log(response.data)
          
        })
    },
    completeForm(data){
      data['formCompleted'] = true
    axios({
        method: 'patch',
        url: '/api/v1/forms/submissions/' + data.id,
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
            this.$router.push({ path: `/formsubmission/${response.data.id}` })
         console.log(response.data)
          
        })
    },
  }
}
</script>

<style scoped>
.maxFormWidth{
  max-width: 900px;
  margin: 0 auto 0;
}
#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 5px;
  border-radius: 0 0 5px 5px;
}
a {
  text-decoration: none;
}
.A-shift{
  color: red;
}
.B-shift{
  color: blue;
}
.C-shift{
  color: green;
}
</style>
