<template>
  <div class="hello">
    <b-container>
      <b-container>
        <b-card no-body size="sm" class="my-2 border-dark pt-2" v-for="rank in parentDeptSpecs" :key="rank.index">
      <b-row>
        <b-col>
          <h4 v-show="!checkShowStatus(rank)" >{{ rank.deptspecName }}</h4>
          <b-form-input v-if="checkShowStatus(rank)" type="text" v-model="editValue" />
        </b-col>  
        <b-col cols="3">
          <b-btn size="sm" variant="warning" v-show="!checkShowStatus(rank)" @click="showEditBox(rank)" >Edit</b-btn>
          <b-btn @click="showEditBox(rank)" v-show="checkShowStatus(rank)" size="sm" variant="warning">Cancel</b-btn>
           <b-btn v-show="checkShowStatus(rank)" size="sm" variant="info" :class="{disabled: rank.deptspecName == editValue}" @click="updateDeptSpec(rank)">Update</b-btn>
          <b-btn size="sm" variant="danger" v-if="userData.role == 'admin'" :class="{disabled: filterSubDeptSpecs(rank).length > 0}"  @click="deleteSub(rank)">Delete</b-btn>
        </b-col>
      </b-row>

      </b-card>
      <b-card class="bg-primary">
      <b-card class="border-primary" header-slot="header">
        <h5><em>Add New Department Specialty</em></h5>
        <b-container>
        <b-row>
          <b-col>
        <b-form-input :placeholder="'New DeptSpec Parent Category Name'" v-model="newSubCatName" @click="addNew()" />
          </b-col>
          <b-col cols="*">
        <b-btn variant="primary" @click="addNew()">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>
      </b-card>
      </b-container>
    </b-container>
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['taxData'],
  name: 'departmentSpecsAdmin',
  components: { 


  },
  data () {
    return {
    newSubCatName: '',
    editId: '',
    editData: false,
    editValue: ''

    }
  },
  created(){
    
  },
  computed: {
      ...mapState([
        'userData',

        'getSessionData'
      ]),
      parentDeptSpecs(){
        return this.taxData
      },
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },

      /*

      How to filter against two arrays

      docArray(){
        var loaded = [... new Set(this.ranksApi.map(p => p.field_rank_id))]
        var bulk = [... new Set(this.docArrayPre.map(p => p.field_rank_id))]
        var test = this.docArrayPre
        console.log(loaded)
        console.log(bulk)
        for(var i = 0; i < loaded.length; i++){
          var index = bulk.indexOf(loaded[i])
          if(index > -1){
          test.splice(index, 1)}
        }
        return test

      } */
  },
  watch: {

},
  methods: {
    showEditBox(rank){
      if(this.editId == ''){
        this.editId = rank.id
        this.editValue = rank.deptspecName
      } else if (rank.id != this.editId){
        this.editId = rank.id
         this.editValue = rank.deptspecName
      } else {
        this.editId = ''
         this.editValue = ''
      }
      
    },
    checkShowStatus(rank){
        if(rank.id != this.editId){
          return false
        } else {
          return true
        }
      },
    filterSubDeptSpecs(data){
      return this.taxData.filter( p => {
        if(p.deptspecParent != '' && p.deptspecParent != undefined && p.deptspecParent != null){
          return p.deptspecParent == data.deptspecName
        }
      })
    },
    addNew(parent){
      console.log(parent)
    if(parent == undefined){
      var parent = ''}
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'deptspec', headers: headers, expire: true}
    axios({
      method: 'post',
      url: '/api/v1/taxonomy/deptspec/',
      data: {
        'deptspecName': this.newSubCatName,
       
      },
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
          this.newSubCatName = ''
  
      })
    
    },
    deleteSub(rank){
      var result = confirm('Are You Sure You Want to Delete ' + rank.deptspecName + ' ?')
      if (result) {
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'deptspec', headers: headers, expire: true}
    axios({
      method: 'delete',
      url: '/api/v1/taxonomy/deptspec/' + rank.id,
      headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
  
      })
    } else {}
    },
    updateDeptSpec(rank, parent){
      if(parent == undefined){
        parent = ''
        var setParent = rank.deptspecName
        var childDeptSpecs = this.taxData.filter( p => {
        if(p.deptspecParent != '' && p.deptspecParent != undefined && p.deptspecParent != null){
          return p.deptspecParent == rank.deptspecName
        }
        })
      }
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'deptspec', headers: headers, expire: true}
      axios({
        method: 'patch',
        url: '/api/v1/taxonomy/deptspec/' + rank.id,
        data: {
          'deptspecName': this.editValue,
          
        },
        headers: payload.headers.headers,
      }).then(response => {
        if(parent == ''){
          for(var i = 0; i < childDeptSpecs.length;){
            var count = 1
            var counter = childDeptSpecs.length
            axios({
              method: 'patch',
              url: '/api/v1/taxonomy/deptspec/' + childDeptSpecs[i].id,
              data: {
                'deptspecName': childDeptSpecs[i].deptspecName,
               
              },
               headers: payload.headers.headers,
             }).then(response => {
               count++
               if(count == counter)
              this.$nextTick(function(){
              this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
               this.editValue = ''
               this.editId = ''
                })
               })
               i++
       
          }    


        } else {
        console.log(response.data)
        this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
        this.editValue = ''
        this.editId = ''
        }
      })
    },
  
  
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
