<template>
  <div class="hello">

        <b-navbar class="lastLogin" type="dark" variant="dark">
        <b-nav-form>
          <b-navbar-brand >Apparatus Contacts ({{ filterApparatusDataSort.length }})</b-navbar-brand>
          <b-form-select v-model="stationSearch" class="ml-2" :options="getListStations">
            <option value="">Station Filter</option>
          </b-form-select>
          <b-form-select v-model="assignedSearch" class="ml-2" :options="getListAssigned" >
                      <option value="">Assigned Filter</option>
          </b-form-select>
            <b-form-select v-model="typeSearch" class="ml-2" :options="getListType" >
                      <option value="">Type Filter</option>
          </b-form-select>
        </b-nav-form>
        <b-nav-form class="ml-auto">
          <b-btn variant="outline-danger" @click="shiftSearch = '', stationSearch = ''">Reset</b-btn>
        </b-nav-form>
      </b-navbar>
        <b-table stacked="md" :fields="fields" :items="filterApparatusDataSort">
          <template slot="apparatusPhone" slot-scope="data">
            <b-btn class="m-0 p-0" v-if="data.item.apparatusPhone" variant="link" :href="'tel:' + data.item.apparatusPhone">{{ data.item.apparatusPhone }}</b-btn>
          </template>
          </b-table>
  </div>
</template>

<script>
/*
<input type="file" @change="processFile($event)" />
<b-form-textarea v-model="stringData" />
<b-btn @click="parseDataField">Parse</b-btn>
*/
import moment from 'moment'
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  name: 'hello', 
  props: ['filterApparatusData'],
  components:{
  },
  data () {
    return {
      assignedSearch: '',
      stationSearch: '',
      typeSearch: '',
      sortOrder: 'desc',
      previousLoginAttempt: null,
      fields: [
        'apparatusName',
        'apparatusRadioId',
        {key: 'apparatusLocation', label: 'Station'},
         {key: 'apparatusParent', label: 'Assigned'},
          {key: 'apparatusVehicleType', label: 'Type'},
        'apparatusPhone'
      ]
    } 
  },
  created(){

  },
  computed: {
    ...mapState([
      'deptContactData',
      'userData'
    ]),
    getListStations(){
      var array = [... new Set(this.filterApparatusData.map(p => p.apparatusLocation))]
      return _.sortBy(array)
    },
    getListAssigned(){
      var array = [... new Set(this.filterApparatusData.map(p => p.apparatusParent))]
      return _.sortBy(array)
    },
    getListType(){
      var array = [... new Set(this.filterApparatusData.map(p => p.apparatusVehicleType))]
      return _.sortBy(array)
    },
    filterApparatusDataSort(){
      var array = _.cloneDeep(this.filterApparatusData)
      if(this.stationSearch){
      var station = array.filter(p => {
        return p.apparatusLocation == this.stationSearch
      })} else { var station = array}
      if(this.assignedSearch){
        var assigned = station.filter(p => {
          return p.apparatusParent == this.assignedSearch
        })
      } else { var assigned = station }
      if(this.typeSearch){
        var type = assigned.filter(p => {
          return p.apparatusVehicleType == this.typeSearch
        })
      } else { var type = assigned }
      return _.sortBy(type)
    },


  },
  watcher: {


  },
  methods: {
    getLoginData(data){
      if(data){
      return moment(data).format('MM/DD/YY HH:mm')
      }
    },
  }

}
</script>
<style>


</style>
<style scoped>
@media screen and (max-width: 1200px){
.userContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px){
.userContactContainer.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px){
.userContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px){
.userContactContainer.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
.activeOnline {
      font-size: small;
    background-color: green;
    border-radius: 5px;
    color: white;
    padding: 2px 4px;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.floatRight {
  float: right;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer{
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {

  border-radius: 5px;
}
.A-shiftROW{
  border: 1px solid #e2070d;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.B-shiftROW{
  border: 1px solid #201B9F;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.C-shiftROW{
  border: 1px solid #0FB906;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.Day-shiftROW{
  border: 1px solid #E4CB07;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
.A-shift{
  background: #e2070d;
  background: -moz-linear-gradient(left, #e2070d 0%, #e2070d 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #e2070d 0%,#e2070d 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2070d', endColorstr='#ffffff',GradientType=1 );
  border-radius: 20px 20px 0 0;
}
.B-shift{
  background: #201B9F;
  background: -moz-linear-gradient(left, #201B9F 0%, #201B9F 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #201B9F 0%,#201B9F 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#201B9F', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
.C-shift{
  background: #0FB906;
  background: -moz-linear-gradient(left, #0FB906 0%, #0FB906 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #0FB906 0%,#0FB906 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0FB906', endColorstr='#ffffff',GradientType=1 );
      border-radius: 20px 20px 0 0;
}
.Day-shift{
  background: #E4CB07;
  background: -moz-linear-gradient(left, #E4CB07 0%, #E4CB07 50%, #ffffff 51%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  background: linear-gradient(to right, #E4CB07 0%,#E4CB07 50%,#ffffff 51%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E4CB07', endColorstr='#ffffff',GradientType=1 );
    border-radius: 20px 20px 0 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
@media screen and (max-width: 850px){
   .phoneIcons {margin: 20px 5px;
      height: 40px;
      width: 40px;
      border: 0;
      border-radius: 0;
      background: none;
      }
}
@media screen and (max-width: 750px){
.phoneIcons {
    margin: 20px 2px;
    height: 35px;
    width: 35px;
    border: 0;
    border-radius: 0;
    background: none;
}
}
</style>
