<template>
    <div>
    <div class="row fullWidth">

        <div v-if="loading">Loading ...</div>

        <div v-if="error" class="error"></div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">

                    <h2>{{ stationSelectValue ? calendarHeader + ' (' + stationSelectValue.stationName + ')' : calendarHeader }} 
                        <b-btn variant="info" class="ml-2" v-if="(userData.role == 'admin' || userData.role == 'adminchief') && calendarHeader == 'Department Wide'"
                        @click="exportCalendarData"
                        >Export</b-btn></h2>
                </div>

                <div class="card-block">
                    <div class="row">
                        <div class="col-sm-12">
                            <CalendarHeader :current-month="currentMonth"
                                       :first-day="firstDay"
                                       :locale="appLocale"
                                       @setData="setData"
                                       @setDataGroup="setDataGroup"
                                       @setDayDisplay="setDayDisplay"
                                       @setShiftDisplay="setShiftDisplay"
                                       :daydisplay="daydisplay"
                                       :allEvents="allEvents"
                                       :calendarSmall="calendarSmall"
                                       :calendarType="calendarType"
                                       >                            
                            </CalendarHeader>

                            <div class="full-calendar-body">
                                <div class="weeks">
                                    <strong class="week" v-for="dayIndex in 7" :key="dayIndex.index">{{ (dayIndex - 1) | weekDayName(firstDay, appLocale) }}</strong>
                                </div>

                                <div class="dates" ref="dates">
                                    <Week v-for="week in WeeksViewFilter"
                                          :daydisplay="daydisplay"
                                          :firstDay="firstDay"
                                          :key="week.index"
                                          :week="week"
                                          :canAddEvent="canAddEvent"
                                          :canDeleteEvent="canDeleteEvent"
                                          :shiftDisplayBoo="shiftDisplayBoo"
                                          :displayWeekNumber="displayWeekNumber"
                                          @eventAdded="eventAdded"
                                          @eventDeleted="eventDeleted"
                                          :calendarType="calendarType">
                                    </Week>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="day-cell-mobile">
      <mobilecalendar :events="allEvents" :currentMonth="currentMonth" :calendarType="calendarType" :calendarHeader="calendarHeader" @setDayDisplay="setDayDisplay"  :daydisplayboo="daydisplay"  />
    </div>

  </div>
</template>
<script>
    import moment from 'moment';
    import { bus } from './../../main'
    import { mapState } from 'vuex'
    import MobileCalendar from './Components/mobileCalendarFull.vue'
    import {CHANGE_MONTH, EVENT_ADDED, EVENT_DELETED} from './actions';
    import CalendarHeader from './Components/Header.vue'
    import Week from './Components/Week.vue'
    import Multiselect from 'vue-multiselect'
    import _ from 'lodash'
    import Papa from 'papaparse'
    export default {
        components: {
            'CalendarHeader': CalendarHeader,
            'Week': Week,
            'mobilecalendar': MobileCalendar,
             'multiselect' : Multiselect,
        },
        props: {
            calendarHeader: {
              type: String,
              default: 'Calendar'
            },
            allEvents: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            calendarSmall: {
                type: Boolean,
                default: false
            },
            firstDay: {
                type: Number | String,
                validator (val) {
                    let res = parseInt(val);
                    return res >= 0 && res <= 6;
                },
                default: 0
            },

            displayWeekNumber: {
                type: Boolean,
                default: false,
            },

            canAddEvent: {
                type: Boolean,
                default: true
            },

            canDeleteEvent: {
                type: Boolean,
                default: true
            },
            daydisplayboo: {
                type: Boolean,
                default: false,
            },
            calendarType: {
                type: String,
                default: null
            }
        },

        data () {
            return {
                loading: true,
                error: null,
                currentMonth: moment().startOf('month'),
                stationSelectValue: '',
                privateOnly: false,
                userOnly: false,
                eventTagFilter: [],
                eventTagParentFilter: [],
                daydisplay: false,
                pageView: null,
                shiftDisplayBoo: false,
            }
        },

        created () {
            bus.$on('CHANGE_MONTH', this.changeMonthFunctionNotPayroll);
            bus.$on('CHANGE_MONTH_BUS', this.changeMonthFunction);
            bus.$on('refresh-full-calendar', this.reloadCalendar)
            bus.$on('refresh-calendar-list', this.reloadCalendar)
          
            if(this.calendarHeader != 'payroll'){ 
                  var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData,  'Cache-Control': 'no-cache',
                                      'Pragma': 'no-cache',
                                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
            var apiStartDate = moment(this.currentMonth._d).format('YYYY-MM-DD')
            var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(this.currentMonth._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
          /*  var joinApiDates = '?field_date_of_event_value[min]=' + apiStartDate + '&field_date_of_event_value[max]=' + apiEndDate */
            var joinApiDates = apiStartDate + '/' + apiEndDate
            var payload = { dateRange: joinApiDates, headers: headers, id: this.userData.id }
            this.$store.dispatch('LOAD_FULL_CAL_EVENTS_EXP', payload)
            this.$store.dispatch('LOAD_FULL_CAL_EVENTS_EXP_PRIVATE', payload)    
            }
            if(this.calendarHeader == 'Employee Schedule'){
               // console.log('CALENDAR EMPLOYEE')
                            var apiStartDate = moment(this.currentMonth._d).format('YYYY-MM-DD')
            var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(this.currentMonth._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthEmployee', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectEmployee', _.cloneDeep(this.currentMonth))
            } else if (this.calendarHeader == 'Scheduled Leave'){
             //   console.log('CALENDAR SCHEDULE LEAVE')
                            var apiStartDate = moment(this.currentMonth._d).format('YYYY-MM-DD')
            var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(this.currentMonth._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthLeave', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectLeave', _.cloneDeep(this.currentMonth))
            } else if (this.calendarHeader == 'Full Calendar'){
             //   console.log('CALENDAR SCHEDULE LEAVE')
                            var apiStartDate = moment(this.currentMonth._d).format('YYYY-MM-DD')
            var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(this.currentMonth._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthLeave', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectLeave', _.cloneDeep(this.currentMonth))
            } else if (this.calendarHeader == 'Overtime Calendar'){
             //   console.log('CALENDAR SCHEDULE LEAVE')
                            var apiStartDate = moment(this.currentMonth._d).format('YYYY-MM-DD')
            var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(this.currentMonth._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthLeave', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectLeave', _.cloneDeep(this.currentMonth))
            }  else {
                            var apiStartDate = moment(this.currentMonth._d).format('YYYY-MM-DD')
            var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(this.currentMonth._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonth', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObject', _.cloneDeep(this.currentMonth))
            }
            this.daydisplay = this.daydisplayboo
        },
        watch: {
            daydisplayboo(newData){
                console.log('Update Display', newData)
                this.daydisplay = newData
            },
          currentMonth(newData){
            if(!this.calendarType || this.calendarType != 'payroll'){
            var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData,  'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
            var apiStartDate = moment(newData._d).format('YYYY-MM-DD')
             var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(newData._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
           /* var joinApiDates = '?field_date_of_event_value[min]=' + apiStartDate + '&field_date_of_event_value[max]=' + apiEndDate */
            var joinApiDates = apiStartDate + '/' + apiEndDate
             var payload = { dateRange: joinApiDates, headers: headers, id: this.userData.id }
            this.$store.dispatch('LOAD_FULL_CAL_EVENTS_EXP', payload)
            this.$store.dispatch('LOAD_FULL_CAL_EVENTS_EXP_PRIVATE', payload)
            }
            if(this.calendarHeader == 'Employee Schedule' && this.pageView == 'Employee Schedule'){
            //   console.log('Calendar Employee Update')
                var apiStartDate = moment(newData._d).format('YYYY-MM-DD')
             var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(newData._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthEmployee', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectEmployee', _.cloneDeep(this.currentMonth))
            } else if (this.calendarHeader == 'Scheduled Leave' && this.pageView == 'Scheduled Leave'){
             //     console.log('Schedule Leave Update')
                    var apiStartDate = moment(newData._d).format('YYYY-MM-DD')
             var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(newData._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthLeave', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectLeave', _.cloneDeep(this.currentMonth))
            } else if (this.calendarHeader == 'Overtime Calendar' && this.pageView == 'Overtime Calendar'){
             //     console.log('Schedule Leave Update')
                    var apiStartDate = moment(newData._d).format('YYYY-MM-DD')
             var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(newData._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthLeave', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectLeave', _.cloneDeep(this.currentMonth))
            } else if (this.calendarHeader == 'Full Calendar' && this.pageView == 'Full Calendar'){
             //     console.log('Schedule Leave Update')
                    var apiStartDate = moment(newData._d).format('YYYY-MM-DD')
             var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(newData._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
               bus.$emit('CalendarCurrentDisplayMonthLeave', {start: apiStartDate, end: apiEndDate})
               bus.$emit('CalendarCurrentMonthObjectLeave', _.cloneDeep(this.currentMonth))
            } else {
                 var apiStartDate = moment(newData._d).format('YYYY-MM-DD')
             var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(newData._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
            bus.$emit('CalendarCurrentDisplayMonth', {start: apiStartDate, end: apiEndDate})
            bus.$emit('CalendarCurrentMonthObject', _.cloneDeep(this.currentMonth))
            }

          },
        },
        mounted () {
            this.loading = false;

        },

        computed: {
            ...mapState([
                'getSessionData',
                'stationsData',
                'userData',
                'siteconfig'
            ]),
            Weeks () {

                let monthMomentObject = this.getMonthViewStartDate(this.currentMonth, this.firstDay);

                let weeks = [], week = [], day = null, weekIndex, dayIndex;

                for (weekIndex=0; weekIndex < 5; weekIndex++) {
                    week = [];

                    for (dayIndex=0; dayIndex < 7; dayIndex++) {
                        day = {
                            isToday: monthMomentObject.isSame(moment(), 'day'),
                            isCurrentMonth: monthMomentObject.isSame(this.currentMonth, 'month'),
                            weekDay: dayIndex,
                            isWeekEnd: (dayIndex == 0 || dayIndex == 6),
                            date: moment(monthMomentObject),
                            shift: this.dateDifference(moment(monthMomentObject)),
                            payday: this.getIfPayDay(moment(monthMomentObject)),
                            endOfCycle: this.getEndOfCycle(monthMomentObject),
                            events: this.getEvents(monthMomentObject),
                            week: weekIndex

                        };
                        week.push(day);

                        monthMomentObject.add(1, 'day');
                    }
                    weeks.push(week);
                }
                return weeks
            },
            WeeksViewFilter(){
                return this.Weeks
            },
            appLocale : function () {
                return 'en';
            },
            clonedEvents(){
                var array = _.cloneDeep(this.allEvents)
           //     console.log('Cloned')
          //      console.log(array)
                return array
            },
            events(){
                var events = _.cloneDeep(this.clonedEvents)
            //    console.log('Before Filter')
            //    console.log(events)
                if(this.eventTagParentFilter.length > 0){
              //         console.log('Event Parent')
             //       console.log(this.eventTagParentFilter)
                        var events = events.filter(p => {
                            var index = this.eventTagParentFilter.indexOf(p.title)
                            if(index != -1){
                                return p
                            }
                        })
                    }
                for(var i in events){
                   var array = events[i].description
                   if(this.privateOnly){
            //           console.log('Private')
             //          console.log(this.privateOnly)
                       var priv = array.filter(p => {
                           return p.eventPrivate == true
                       })
                   } else {
                       var priv = array
                   }
                   if(this.userOnly){
                 //      console.log('UserONly')
                   //    console.log(this.userOnly)
                       var user = priv.filter(p => {
                        if(p.eventUser){
                        var index = p.eventUser.indexOf(this.userData.id)
                        } else {
                         var index = -1
                        }
                        if(index >= 0 || p.eventAuthorId == this.userData.id){
                            return p
                        }
                    })
                   } else {
                       var user = priv
                   }
                   if(this.eventTagFilter.length > 0){
             //          console.log('EventTagFilter')
              //         console.log(this.eventTagFilter)
                       var eve = user.filter(p => {
                           var index = this.eventTagFilter.indexOf(p.eventTag)
                           if(index != -1){
                               return p
                           }
                       })
                   } else {
                       var eve = user
                   }
                    var filterData = this.stationSelectValue
                    if(filterData != null && filterData != undefined && filterData != ''){
                        if(filterData.stationParent == ''){
                            var type = 'eventStationParent'
                        } else {
                            var type = 'eventStation'
                        }
                //        console.log('Station Filter')
                //        console.log(filterData)
                            var value = filterData.stationName
                        var station = eve.filter(p => {
                        var index = p[type].indexOf(value)
                       if(index >= 0 || value == undefined){
                           return p
                       }
                        })
                    } else {
                        var station = eve
                    }
                   var filtered = station
                   events[i].description = filtered
                }
             //   console.log(events)
                var events = events.filter(p => {
              //      console.log('Events Description Filter')
                    if(p.description.length > 0){
                        return p
                    }
                })
            //    console.log(events)
                return events
            },
        },

        methods: {
            exportCalendarData(){
                var month = _.cloneDeep(this.currentMonth)
                var events =  _.cloneDeep(this.allEvents)
                var exports = []
                for(var e in events){
                    var obj = events[e]
                    for(var d in obj.description){
                        var pushObj = obj.description[d]
                        delete pushObj.id
                        delete pushObj.createdAt
                        delete pushObj.updatedAt
                        delete pushObj.eventContentId
                        delete pushObj.eventContentUrl
                        delete pushObj.eventAuthorId
                        delete pushObj.eventUser
                        delete pushObj.eventGroupId
                        delete pushObj.eventPrivate
                        pushObj.title = pushObj.title.replace('| ' + pushObj.eventDate, '')
                        pushObj.eventApparatus = pushObj.eventApparatus.toString()
                        pushObj.eventStation = pushObj.eventStation.toString()
                        pushObj.eventStationParent = pushObj.eventStationParent.toString()
                        pushObj.eventDescription = pushObj.eventDescription.toString()
                        if(moment(pushObj.eventDate).isSameOrAfter(month.startOf('Month')) && moment(pushObj.eventDate).isSameOrBefore(month.endOf('Month'))){
                            exports.push(pushObj)
                        }
                    }   
                }
                var data = Papa.unparse(exports, {header: true})
                // console.log(data)
                const blob = new Blob([data], { type: 'text/csv' })
                // console.log(data)
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob)
                link.setAttribute("download", 'Events for ' + month.format('MMM YYYY'));
                link.click();
               // console.log(exports)
            },
            changeMonthFunction(payload){
               if(payload.view && this.calendarHeader == payload.view){
                    this.currentMonth = _.cloneDeep(payload.date)
                    this.pageView = payload.view
                }
            },
            changeMonthFunctionNotPayroll(payload){
                this.currentMonth = payload
            },
            setDayDisplay(data){
                this.daydisplay = data
            },
            setShiftDisplay(data){
                this.shiftDisplayBoo = data
            },
            setData(data){
                if(data){
                    this[data.data] = data.value
                }
            },
            setDataGroup(group){
                this.privateOnly = false
                this.userOnly = false
                this.eventTagParentFilter = []
                this.eventTagFilter = []
                for(var i in group){
                    if(group[i].data == 'userOnly' || group[i].data == 'privateOnly'){
                        this[group[i].data] = true
                    }
                    if(group[i].data == 'eventTagParent'){
                        var indexp = this.eventTagParentFilter.indexOf(group[i].value)
                            if(indexp == -1){
                            this.eventTagParentFilter.push(group[i].value)
                        }
                    }
                    if(group[i].data == 'eventTag'){
                        var indexp = this.eventTagParentFilter.indexOf(group[i].parent)
                        var indexc = this.eventTagFilter.indexOf(group[i].value)
                        if(indexp == -1){
                            this.eventTagParentFilter.push(group[i].parent)
                        }
                        if(indexc == -1){
                            this.eventTagFilter.push(group[i].value)
                        }
                    }
                }
            
            },
            reloadCalendar(event){
            var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData,  'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
            var apiStartDate = moment(this.currentMonth._d).format('YYYY-MM-DD')
            var apiStartDate = moment(apiStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
            var apiEndDate = moment(this.currentMonth._d).endOf('month').format('YYYY-MM-DD')
            var apiEndDate = moment(apiEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
            var joinApiDates = apiStartDate + '/' + apiEndDate
            var payload = { dateRange: joinApiDates, headers: headers, id: this.userData.id }
            bus.$emit('CalendarCurrentDisplayMonth', {start: apiStartDate, end: apiEndDate})
            this.$store.dispatch('LOAD_FULL_CAL_EVENTS_EXP', payload)
             this.$store.dispatch('LOAD_FULL_CAL_EVENTS_EXP_PRIVATE', payload)
          },
            getEvents (date) {
                return this.events.filter(event => {
                    return date.isSame(event.date, 'day') ? event : null;
                });
            },
            getEndOfCycle(data){
                var payroll = this.siteconfig.payroll
                var selectedVarDate = moment(data._d, "MM/DD/YYYY");
                var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD")
                var dateDiffCal = selectedVarDate.diff(presetVarDate, "days")
                   if(Math.floor(dateDiffCal/payroll.cycle) == dateDiffCal/payroll.cycle){
                    //   console.log(data._d)
                       return true
                   } else {
                     return false
                }
            },
            getIfPayDay(data){
                var payroll = this.siteconfig.payroll
                var selectedVarDate = moment(data._d, "MM/DD/YYYY");
                var presetVarDate = moment(payroll.payStartDate, "YYYY-MM-DD")
                if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = selectedVarDate.diff(presetVarDate, "days")
                   if(Math.floor(dateDiffCal/14) == dateDiffCal/14){
                       return true
                   } else {
                       return false
                   }
                } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = selectedVarDate.diff(presetVarDate, "days")
                   if(Math.floor(dateDiffCal/7) == dateDiffCal/7){
                       return true
                   } else {
                       return false
                   }
                }  else {
                    return false
                }
               
            },
            dateDifference(data){
               var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(data._d, "MM/DD/YYYY");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              if(this.siteconfig.calendarHideFire){
                  shift = {color: ''}
              }
              return shift
            },
            getMonthViewStartDate (date, firstDay) {
                firstDay = parseInt(firstDay);
                let start = moment(date).locale(this.appLocale);
                let startOfMonth = moment(start.startOf('month'));
                start.subtract(startOfMonth.day(), 'days');
                if (startOfMonth.day() < firstDay) {
                    start.subtract(7, 'days');
                }
                start.add(firstDay, 'days');
                return start;
            },
            eventAdded(event) {
                this.$emit(EVENT_ADDED, event);
            },

            eventDeleted(event) {
                this.$emit(EVENT_DELETED, event);
            }
        },
        filters: {
            weekDayName (weekday, firstDay, locale) {
                firstDay = parseInt(firstDay);
                const localMoment = moment().locale(locale);
                return localMoment.localeData().weekdaysShort()[(weekday + firstDay) % 7];
            }
        }
    }

</script>
<style>
    ul, p {
        margin: 0;
        padding: 0;
    }

    .full-calendar-body {
        margin-top: 10px;
    }
    .weeks {
        display: flex;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
    }
    .week {
        flex: 1;
        padding: 5px;
        text-align: center;
        border-right: 1px solid #e0e0e0;
    }

    .dates {
        position: relative;
    }
    .day-cell-mobile {
      display: none;
    }
    @media screen and (max-width: 768px) {
    .row.fullWidth {
      display: none;
    }
    .day-cell-mobile {
        flex: 1;
        display: block;
        margin-top: 10px;
        min-height: 112px;
        padding: 10px 4px 4px 4px;
        border-radius: 10px;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        background: rgba(147, 147, 147, 0.1);
    }
    }
</style>
