<template>
    <b-container fluid>
  <b-navbar type="dark" variant="dark">
           <b-nav-form>
      <b-navbar-brand class="bootstrapTable">Options:</b-navbar-brand>
         <b-form-select v-model="currentYear" :options="yearsDropDown" />
         <b-navbar-brand class="bootstrapTable ml-2">
           <b-form-select v-model="cycleType" :options="cycleTypeOptions" @change="getCurrentPayPeriod" />
         </b-navbar-brand>
         <b-form-select v-if="cycleType == 'Pay Period'" v-model="currentView" :options="previousPayrolls" />
         <b-form-select v-if="cycleType == 'Work Cycle'" v-model="currentView" :options="previousWorkCycles" />
         <b-form-select v-if="cycleType == 'Work Week'" v-model="currentView" :options="previousWorkWeeks" />
         <b-form-select v-if="cycleType == 'Kelly Cycle'" v-model="currentView" :options="previousKellyCycles" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectStart" />
         <b-form-input type="date" v-if="cycleType == 'Select Range'" v-model="selectEnd" />
         <b-btn variant="info" v-if="cycleType == 'Select Range'" @click="runRangeDate">Run</b-btn>
      </b-nav-form>
    </b-navbar>
    <b-card v-if="permsData && managedUsers" no-body>
    <b-tabs  card>
      <b-tab  v-for="user in managedUsers" :key="user.id" @click="currentUser = user.id">
        <div slot="title">
          <h6>{{ user.lastName + ', ' + user.firstName }}</h6>
          <b-badge class="mr-2" :variant="checkForUserApproved(user , 'user') ? 'success' : 'danger'">User</b-badge>
          <b-badge :variant="checkForUserApproved(user, 'sup') ? 'success' : 'danger'">Supervisor</b-badge>
        </div>
      <timecardUser
            v-if="currentUser == user.id && rosterUserProfiles"
            :rosterUserProfilesData="rosterUserProfiles" 
            :scheduleData="scheduleData"      
            :payrollCodesData="payrollCodesData"
            :payrollHolidaysData="payrollHolidaysData"
            :payrollPermsData="payrollPermsData"
            :holdState="holdState"
            :payrollReports="payrollReports"
            :currentView="currentView"
            :cycleType="cycleType"
            :userSelected="user" />
      </b-tab>
    </b-tabs>
    </b-card>
         </b-container>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import Papa from 'papaparse'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import JsonExcel from 'vue-json-excel';
import timecardUser from './timecardManagerUser.vue'
export default {
  components: {
    multiselect,
    'download-excel' : JsonExcel,
    timecardUser
  },
  
  name: 'time-card-verification-manager',
  props:  ['scheduleData', 'permsData', 'payrollCodesData', 'payrollHolidaysData', 'holdState', 'payrollPermsData'],
  data () {
    return {
      currentPage: 0,
      searchData: '',
      payrollBankRecoDate: '',
      currentView: null,
      showFilter: false,
      denyCheck: false,
      approveTimeCardView: false,
      currentUser: '',
      hideUsersWithNone: false,
      userApproveCheck: false,
      approvedUserTimecard: null,
      supervisorApproveCheck: false,
      selectAllBoo: false,
      userShifts: false,
      tempTimeCardHours: 0,
      showHoursId: '',
      rosterUserProfiles: null,
      timeCardCommentModal: false,
      timeCardCommentModalValue: '',
      timeCardCommentModalId: '',
      cycleType: 'Work Cycle',
      currentYear: '',
      selectedFilter: [],
      payrollRecoMods: [],
      payrollReports: [],
      additionalTimeCards: [],
      timeCardHold: {},
      approvedTimeCardsData: [],
      viewReports: [],
      modfields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'}, 
     ],
     timeCardShowHideHold: {},
      selectStart: '',
      selectEnd: '',
      departmentCodingRequired: true,
      departmentCodeDefault: '',
      userTimeCards: [],
      userPayrollMods: [],
      cycleTypeOptions: [],
      lockedReport: null,
      createVacancy: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      ],
    }
  },
  created(){
    if(this.holdState){
     // console.log('HoldState')
     // console.log(this.holdState)
      this.currentView = this.holdState.currentView
      this.currentYear = this.holdState.currentYear
      this.cycleType = this.holdState.cycleType
    } else {
      this.getCurrentPayPeriod()
    }
    this.getUserRosterProfiles()
    this.getPreviousPayrollExports()
    this.getCycleTypeOptions()
    this.getPayrollModifiersInRange()
    this.selectStart = moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD')
    this.selectEnd = moment().startOf('month').format('YYYY-MM-DD')

  },
  computed: {
    ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        managedUsers(){
          var users = []
          var data = this.permsData.payrollManageUsers
          for(var u in data){
              var user = this.deptContactData.find(p => p.id == data[u])
              if(user){
                users.push(user)
              }
          }
          if(users){
            users = _.orderBy(users, ['lastName'], ['asc'])
            this.currentUser = users[0].id
            return users
          } return false
        },
        typicalStartTime(){
          if(this.rosterUserProfiles && this.rosterUserProfiles[0] && this.scheduleData){
            var schDataObj = this.scheduleData.find(p => p.id == this.rosterUserProfiles[0].scheduledShift)
            if(schDataObj && schDataObj.schedulePattern){
             var times = schDataObj.schedulePattern.map(p => p.interval.map(x => x.startTime));
             var overAllTimes = []
             times.forEach((time) => {
                time.forEach((start) => {
                  if(start){
                    overAllTimes.push(start)
                  }
                })
             })
             var baseSet = [...new Set(overAllTimes.map(p => p))]
             var rank = []
             baseSet.forEach((time) => {
                  var amount = overAllTimes.filter(p => p == time).length
                  rank.push({time: time, amount: amount})
             })
             rank = _.orderBy(rank, ['amount'], ['desc'])
             if(rank && rank[0]){
               return rank[0].time
              } return this.siteconfig.payroll.shiftStartTime
            } return this.siteconfig.payroll.shiftStartTime
          } return this.siteconfig.payroll.shiftStartTime
        },
        previousPayrolls(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
              if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*2, 'weeks').format("YYYY-MM-DD")
                   var startSub = payroll.endAtMidnight ? 13 : 14
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(startSub, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(14, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(14, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(14*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            }
          } return []
        },
        previousWorkWeeks(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7) //Changed this to get the appropriate start Days to align, need to see why 6/8 is still listed/should be 6/9 at 0800
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(7, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(7, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(7*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
          } return []
        },
        previousKellyCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'd').format("YYYY-MM-DD")
                   array.push({start: moment(startDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.keyllyCycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(payroll.keyllyCycle*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
        previousWorkCycles(){
          var array = []
          if(this.currentView && this.currentYear){
            var payroll = this.siteconfig.payroll
            var today = moment().format("YYYY-MM-DD")
            var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.cycle)
                   var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle - 1, 'days').format("YYYY-MM-DD")
                   if(payroll.workCycleMainExport){
                     endDate = moment(endDate, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD")
                   }
                   array.push({start: moment(startDate).add(payroll.cycle, 'days').format("YYYY-MM-DD"), end: moment(endDate).add(payroll.cycle, 'days').format("YYYY-MM-DD")})
                   array.push({start: startDate, end: endDate})
                   for(var i = 1; i < end; i++){
                      var startDay = moment(startDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      var endDay = moment(endDate).subtract(payroll.cycle*i, 'days').format("YYYY-MM-DD")
                      array.push({start: startDay, end: endDay})
                   }
                   var filter = array.filter(p => {
                     return moment(p.start).format('YYYY') == this.currentYear // && moment(p.end).format('YYYY') == this.currentYear
                   })
                   var sorted = _.orderBy(filter, ['start'], ['desc'])
                   var returnArr = []
                   for(var i in sorted){
                     returnArr.push({value: {start: sorted[i].start, end: sorted[i].end}, text: sorted[i].start + ' till ' + sorted[i].end})
                   }
                   return returnArr
            
          } return []
        },
        yearsDropDown(){
           var years = []
           var payroll = this.siteconfig.payroll
           var today = moment().format('YYYY')
           var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD").format('YYYY')
           var diff = moment(today).diff(presetVarDate, 'years')
           for(var i = 0; i < diff; i++){
              if(Number.parseInt(presetVarDate) + i < Number.parseInt(today)){
                years.push(Number.parseInt(presetVarDate) + i)
              }
           }
           years.push(Number.parseInt(today))
           var hold = []
           for(var i in years){
             hold.push({value: years[i].toString(), text: years[i]})
           }
           if(!this.holdState){
           this.currentYear = Number.parseInt(today)
           }
           return _.orderBy(hold, ['text'], ['desc'])
        },
       
  },
  mounted(){

  },
  watch: {
    currentView(data){
      this.getPayrollModifiersInRange()
    }

  },
  methods: {
    checkForUserApproved(user, type){
      var data = this.approvedTimeCardsData
      data = data.filter(p => p.timecardUserId == user.id && !p.timecardDenied)
      if(data && data[0]){
        if(type == 'user' && data[0].userApprovedId){
          return true
        } else if(type == 'sup' && data[0].supervisorApprovedId){
          return true
        } return false
      } return false
    },
    getUserRosterProfiles(){
         axios({
        method: 'get',
        url: '/api/v1/staffing/rosterprofile/',
        headers: {
           'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.rosterUserProfiles = response.data
       });
    },
      getPayrollModifiersInRange(){
      var data = this.currentView
      this.approvedTimeCardsData = []
      axios({
        method: 'get',
        url: '/api/v1/staffing/payrolltimecardapproval/submitted/' + data.start + 'till' + data.end,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
         this.approvedTimeCardsData = _.orderBy(response.data, ['updatedAt'], ['desc'])

      })
    },
    getCycleTypeOptions(){
        if(this.siteconfig.payroll.hidePayrollCycleOption){
        var array = ['Work Cycle', 'Work Week']
        } else if(this.siteconfig.payroll.hideWorkCycle){
          var array = ['Work Week', 'Pay Period']
          this.cycleType = 'Pay Period'
          if(!this.holdState){
            this.getCurrentPayPeriod()
          }
        } else {
        var array = ['Pay Period', 'Work Cycle', 'Work Week']
        }
        if(this.siteconfig.payroll.kellyCycleDate){
          array.push('Kelly Cycle')
        }
        array.push('Select Range')
        this.cycleTypeOptions = array
    },
    runRangeDate(){
      this.currentView = {start: this.selectStart, end: this.selectEnd }
    },
      getCurrentPayPeriod(){
        if(this.cycleType == 'Pay Period'){
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                if(payroll.payCycleType == 'biweekly'){
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/14)
                   var endDate = moment(presetVarDate).add((end+1)*2, 'weeks').format("YYYY-MM-DD")
                   var startSub = payroll.endAtMidnight ? 13 : 14
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(startSub, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else if(payroll.payCycleType == 'weekly'){
                   var dateDiffCal = moment(today, 'YYYY-MM-DD').diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
                } else {
                    return false
                }
        } else if (this.cycleType == 'Work Week') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.payrollCycleDate, "YYYY-MM-DD")
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/7)
                   var endDate = moment(presetVarDate).add(end*1, 'weeks').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else if (this.cycleType == 'Kelly Cycle') {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.kellyCycleDate, "YYYY-MM-DD").subtract(1, 'day')
                   var dateDiffCal = moment(today).diff(presetVarDate, "days")
                   var end = Math.floor(dateDiffCal/payroll.keyllyCycle)
                   var endDate = moment(presetVarDate).add(end*payroll.keyllyCycle, 'days').format("YYYY-MM-DD")
                   var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.keyllyCycle - 1, 'days').format("YYYY-MM-DD")
                   endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                   this.currentView = {start: startDate, end: endDate}
          } else {
                var payroll = this.siteconfig.payroll
                var today = moment().format("YYYY-MM-DD")
                var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD").subtract(1, 'day')
                var dateDiffCal = moment(today).diff(presetVarDate, "days")
                var end = Math.floor(dateDiffCal/payroll.cycle)
                var endDate = moment(presetVarDate).add(end*payroll.cycle, 'days').format("YYYY-MM-DD")
                var startDate = moment(endDate, "YYYY-MM-DD").subtract(payroll.cycle -1, 'days').format("YYYY-MM-DD")
                if(payroll.workCycleMainExport){
                  endDate = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")
                }
                this.currentView = {start: startDate, end: endDate}
        //        console.log(this.currentView)
        }
                
      }, 
  getPreviousPayrollExports(){
  axios({
        method: 'get',
        url: '/api/v1/staffing/payrollexport/short',
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
     this.payrollReports = _.orderBy(response.data, ['createdAt'], ['desc'])
      })
  },
   


  }



  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.cursor {
  cursor: pointer;
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
