<template>
  <div class="hello">
          Sample Data from San Fransico FD Previous Day<br>
          <b-btn @click="getCADData" v-show="singleIncident == ''">Get CAD</b-btn>
          <div v-show="singleIncident == '' && CadTableData.length > 0">Unique Incidents: {{ CadTableData.length }}</div>
          <b-table v-if="singleIncident == ''" :fields="fields" :items="CadTableData">
            <template slot="dispatch_dttm" slot-scope="data">
              {{ getTime(data.item.dispatch_dttm) }}
            </template>
            <template slot="incident_number" slot-scope="data">
              <b-btn variant="link" @click="singleIncident = data.item.incident_number, setIncidentDataArray(singleIncident)">{{ data.item.incident_number }}</b-btn>
            </template>
            <template slot="map" slot-scope="data">
              <b-btn variant="link" target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=' + data.item.location.coordinates[1] + ',' + data.item.location.coordinates[0]">View Map</b-btn>
            </template>
          </b-table>
          <b-container v-if="singleIncident != ''"><b-btn @click="singleIncident = '', incidentDataArray = [], simulateDispatch = false">Return to Dispatch Table</b-btn>
          <b-btn @click="simulateDispatch = true" v-if="userData.role == 'admin'">Simulate Dispatch</b-btn>
            <div v-if="simulateDispatch == false">
            <b-card header-tag="header">
              <template slot="header">
                <b-container fluid>
                  <b-row>
                    <b-col align="left"><em>id:</em> {{ incidentDataArray[0].incident_number }}<br>
                    <em>call:</em> {{ getTime(incidentDataArray[0].received_dttm) }}
                    </b-col>
                    <b-col>
                <h4>{{ incidentDataArray[0].address }}</h4>
                    </b-col>
                    <b-col><em>area:</em> {{ incidentDataArray[0].neighborhoods_analysis_boundaries }}<br>
                          <em>final:</em> {{ incidentDataArray[0].call_final_disposition }}
                    </b-col>
                    
                  </b-row>
                    </b-container>
              </template>
              <b-container fluid>
                <b-card class="mb-4 p-0">
               <b-row class="mx-auto">
                 <b-col cols="auto" align="right">CALL TYPE:</b-col><b-col cols="auto" align="left">{{ incidentDataArray[0].call_type }}</b-col>
                 <b-col cols="auto" align="right">SUB TYPE:</b-col><b-col cols="auto" align="left">{{ incidentDataArray[0].call_type_group }}</b-col>
                 <b-col cols="auto" align="right">Priority:</b-col><b-col  cols="auto" align="left">{{ incidentDataArray[0].priority }}</b-col>
                 </b-row></b-card>
                <b-row>
              <b-col cols="3"><b-card class="p-0">
                <b-row><b-col align="right" cols="7">STA AREA:</b-col><b-col align="left">{{ incidentDataArray[0].station_area }}</b-col></b-row>
                <b-row><b-col align="right" cols="7">DISTRICT:</b-col><b-col align="left">{{ incidentDataArray[0].supervisor_district }}</b-col></b-row>
                <b-row><b-col align="right" cols="7">BATTALION:</b-col><b-col align="left">{{ incidentDataArray[0].battalion }}</b-col></b-row>
             </b-card>
              </b-col>
                <b-col class="p-0 mx-0">
                 <b-img fluid :src="'https://www.mapquestapi.com/staticmap/v4/getmap?size=700,500&type=map&pois=pcenter,' + incidentDataArray[0].location.coordinates[1] + ',' + incidentDataArray[0].location.coordinates[0] + 
                 '|&zoom=17&center=' + incidentDataArray[0].location.coordinates[1] + ',' + incidentDataArray[0].location.coordinates[0] + '&imagetype=PNG&key=LgQdxkQjDZAGLnWg6IqdrgabHeElZhA6'" />
                </b-col>
                </b-row>
              </b-container>
              </b-card>
            <b-card-group :columns="incidentDataArray.length > 3" :deck="incidentDataArray.length <= 3">
            <b-card v-for="unit in incidentDataArray" :key="unit.rowid"  header-tag="header">
              <template slot="header">  
              {{ unit.unit_id }}<em> {{ '(' + unit.unit_type + ')' }}</em>
              </template>
              <b-container>
              <b-row><b-col>Dispatch</b-col><b-col cols="auto">{{ getTime(unit.dispatch_dttm) }}</b-col></b-row>
              <b-row><b-col>Enroute</b-col><b-col cols="auto">{{ getTime(unit.response_dttm) }}</b-col></b-row>
              <b-row><b-col>OnScene</b-col><b-col cols="auto">{{ getTime(unit.on_scene_dttm) }}</b-col></b-row>
              <b-row><b-col>Transport</b-col><b-col cols="auto">{{ getTime(unit.hospital_dttm) }}</b-col></b-row>       
              <b-row><b-col>Arrived</b-col><b-col cols="auto">{{ getTime(unit.transport_dttm) }}</b-col></b-row>        
              <b-row><b-col>Available</b-col><b-col cols="auto">{{ getTime(unit.available_dttm) }}</b-col></b-row>
              </b-container>
              </b-card>
            </b-card-group>
          </div>
          <div v-if="simulateDispatch == true">
          <simulated :incident="incidentDataArray" />
            </div>
          </b-container>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import simulated from './simulated.vue'
import axios from 'axios'
export default {
  name: 'hello',
  components: {
    simulated
  },
  data () {
    return {
      simulateDispatch: false,
      fields: [
        {key: 'dispatch_dttm', label: 'Date'},
        {key: 'incident_number', label: 'Incident'},
        'address',
        'call_type',
        { key: 'call_final_disposition', label: 'Disposition' },
        'unit_id',
        'map',
        'pre-plan',
      ],
      originalDataPull: [],
      CadTableData: [],
      singleIncident: '',
      incidentDataArray: [],
    }
  },
  mounted(){
  },
  computed: {
      ...mapState([
        'apparatusData',
        'stationsData',
        'deptContactData',
        'userData',
        'documentTags',
        'getSessionData',
        'documentsApi'
      ]),
    

  },
  watch: {
  },
  methods: {
    setIncidentDataArray(id){
      var data = this.originalDataPull.filter(p => {
        return p.incident_number == id
      })
      this.incidentDataArray = data
    },
    getTime(data){
      if(data){
      return moment(data).format('MM/DD/YY HH:mm:ss')
      } else {
        return 'Not Applicable'
      }
    },
   /* https://data.cityofchicago.org/resource/6zsd-86xi.json?$where=date between '2015-01-10T12:00:00' and '2015-01-10T14:00:00' */
    getCADData(){
      var date = moment().subtract(2, 'days').format('YYYY-MM-DD')
      var time = moment().format('HH:mm')
      var string = "$where=call_date between '" + date + "T00:00:00'" +  " and '" + date + 'T' + "23:59:00'"
   //   console.log(string)
      axios({
          type: 'Get',
          url: "https://data.sfgov.org/resource/enhu-st7v.json?" + string,
           data: {
        }
}).then(response => {
  var data = response.data
  this.originalDataPull = _.cloneDeep(data)
  var map = [...new Set(data.map(p => p.incident_number))]
//  console.log(map.length)
  var arr = []
  for(var i in data){
    var index = arr.map(p => p.incident_number).indexOf(data[i].incident_number)
    if(index < 0){
      arr.push(data[i])
    } else {
      var unit = arr[index].unit_id
      var unit = unit + ', ' + data[i].unit_id
      arr[index].unit_id = unit
    }
  }
  var data = arr
  var data = _.orderBy(data, ['incident_number'], ['desc'])
  this.CadTableData = data
//  console.log(response.data);
});
    },
  
  }

}
</script>
<style scope>
.chart {
  font: 10px sans-serif;
  background-color: steelblue;
  text-align: right;
  padding: 3px;
  margin: 1px;
  color: white;
  display: inline-block;
}
.rankDivWrapper {
  display: inline-block;
}
.specDivWrapper {
  display: inline-block;
  background-color:#aaa;
}
.specDiv {
  height: 4px;
}
.rankDiv {
  display: inline-block;
  font-size: 12px;
  min-height: 16px;
  color: #f2f2f2;
  padding-left: 5px;
}
.keyBox {
  width: 10px;
  height: 10px;
      display: inline-block;
    margin-right: 5px;
    margin-left: 15px;
}
.rank-0 {
  background-color: rgb(0, 136, 0);
}
.rank-1 {
  background-color: rgb(209, 171, 0);
}
.rank-2 {
  background-color: rgb(1, 51, 190);
}
.rank-3 {
  background-color: rgb(0, 121, 177);
}
.rank-4 {
  background-color: #777;
}
.rank-5 {
  background-color: rgb(158, 0, 0);
}
.rank-6 {
  background-color: rgb(3, 141, 118);
}
.rank-7 {
  background-color: #666;
}
.rank-8 {
  background-color: #777;
}
.rank-9 {
  background-color: #888;
}
.rank-10 {
  background-color: #999;
}
.rank-11 {
  background-color: #aaa;
}
.chartWrapper {
  text-align: left;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 1s;
  opacity: 0;
}
.slide-fade-move {
  transition: transform 1s;

}
.slide-fade-enter, .slide-fade-leave {
  opacity: 0;
  width: 0% !important;
  max-height: 10px !important;
}
</style>
