<template>
  <div>
  <b-card-header  class="p-1">
    <b-btn block @click="toggleHideShow(parent)" >{{ parent }}</b-btn>
  </b-card-header>
  <b-container v-show="toggleshow">
    <b-card-group columns>
    <b-card no-body v-for="list in filteredDeptPhoneList(parent)" :key="list.index" class="Day-shiftROW">
      <b-container>
      <b-row class="userName">
      <b-col align="center">{{ list.Name }}</b-col>
    </b-row>
      <b-row>
      <b-col align="right">
        <a v-if="list.Landline != ''" :href="'tel:' + list.Landline">
        <img class="phoneIcons" :src="'./images/siteicons/landline-call-user.png'" />
        </a>
        <a v-if="list.Landline == ''" disabled>
        <img class="phoneIcons noPhone" :src="'./images/siteicons/landline-call-user.png'" />
        </a>
      </b-col>
      <b-col align="left"><a v-if="list.Cell != ''" :href="'tel:' + list.Cell">
      <img class="phoneIcons" :src="'./images/siteicons/call-user.png'" /></a>
      <a v-if="list.Cell == ''" disabled>
      <img class="phoneIcons noPhone" :src="'./images/siteicons/call-user.png'" />
      </a>
   </b-col></b-row>
  </b-container>
    </b-card>
  </b-card-group>
  </b-container>
</div>
</template>

<script>
import { bus } from '../../main.js'
import { mapState } from 'vuex'
export default {
  props:[ "parent"],
  name: 'hello',
  data () {
    return {
      stringData: '',
      deptPhoneList: [],
      toggleshow: false,



    }
  },
  created(){
    bus.$on('toggleGroupHide', this.toggleGroupHide)

    this.deptPhoneListData()
  },
  computed: {
    ...mapState([
      'deptContactData'
    ]),
    phoneContactCat(){
      return [...new Set(this.deptPhoneList.map(p => p.Location))]
    }

  },
  watcher: {


  },
  methods: {
    toggleHideShow(parent){
      bus.$emit('toggleGroupHide', parent)
      this.toggleshow = !this.toggleshow

    },
    toggleGroupHide($event){
      if(this.parent != $event){
        this.toggleshow = false;}
    },
    userPhotoLink(link){
      return 'https://firerescuedev.com' + link
    },
    userSpecialities(newData){
      this.specialitesArray = newData.split(',')
    },
    parseDataField(){
    var dataParse = this.papa.parse(this.stringData, {header: true})
    this.phoneNumberList = dataParse
},
deptPhoneListData(){
   this. $http('./static/phonelist').then( response => {
    this.deptPhoneList = response.data
  })
},
filteredDeptPhoneList(parent){
  return this.deptPhoneList.filter(p => {
    return p.Location.match(parent)
  })
}
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-tabs {
  padding: 0 20px 0 20px;
}
.card-body{
  padding: 0;
}
@media screen and (max-width: 1200px){
.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
@media screen and (max-width: 650px){
.card-columns {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 1% 0 1%;
  }
}
@media screen and (max-width: 576px){
.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 10% 0 10%;
  }
}
@media screen and (max-width: 450px){
.card-columns {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 0 5% 0 5%;
  }
}
</style>
<style scoped>
.row.userName {
  border-radius: 22px 22px 0 0;
  color: #fff;
  font-weight: bold;
  background: #777;
  padding: 5px 0;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.iconDiv {
  margin: 0 auto 0;
}
.clear {
  clear: both;
}
.cardRow {
  margin: 0;
}
.spanClassNameAssign {
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto 0;
  white-space:nowrap;
}
.userDataWrap {
    text-align: center;
}
.userRank{
  text-align: center;
}
.userDataSpan {
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
}
.userContactContainer{
  padding-bottom: 10px;
}
.deptContactContainer {
  height: 100%;
}

img {
  object-fit: cover;
  width:100px;
  height:100px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin: auto;
  background: #fff;
}
.phoneIcons {
  margin: 10px 5px 10px 5px;
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 0;
  background: none;
}
.noPhone {
  opacity: .4;
}
.headerContactRow {
  border-radius: 5px;
}
.Day-shiftROW{
  border: 1px solid #aaa;
  border-radius: 22px;
  text-align: left;

    padding: 0;
}
span.imageColumn {
  padding-left: 15px;
}
.imageColumn {
  padding-right: 0;
  margin: 0;
}
.col {
  margin: 0;

}
.specialH {
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 14px;
  background: rgba(138,138,138,1);
  color: #fff;
  border-radius: 10px;
}
h1, h2 {
  font-weight: normal;
}



a {
  color: #42b983;
}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
