<template>
<div id="homelanding">
<b-container fluid class="pl-0">
            <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Month Controls:</b-navbar-brand>
        <b-btn @click="goPrev" variant="info">Previous Month</b-btn>
        <b-btn @click="goNext" variant="info" class="ml-2">Next Month</b-btn>
         <b-navbar-brand class="bootstrapTable ml-5" v-if="false && userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">View Users:</b-navbar-brand>

      </b-nav-form>
      
    </b-navbar>
    <b-navbar type="dark" variant="secondary" class="py-1">
      <b-nav-form>
     <b-navbar-brand class="bootstrapTable d-none d-md-block"> Filter:</b-navbar-brand>
      <b-form-checkbox-group size="sm" :options="spotModification" v-model="modificationFilter" class="text-light" />
      </b-nav-form>
    </b-navbar>
    <b-navbar  type="dark" variant="secondary" class="py-1">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable  d-none d-md-block" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">Filter:</b-navbar-brand>
                    <b-form-checkbox-group
        id="checkboxes-show"
        name="checkboxs-show"
        size="sm"
        class="text-light"
        v-model="showSelected"
        :options="showSelectedOpt"
      /> <div style="width: 300px" class="mr-2" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
      <multiselect v-model="payrollCodesSelectedFilter" 
        :options="payrollCodesDataReturn" 
        :multiple="true" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Payroll Code Filter" label="payrollCode" 
        track-by="id">                    
             <template slot="tag" slot-scope="props"><span :class="'mr-1 payroll-' + props.option.payrollCodeColor">{{ props.option.payrollCode }} <a @click="removePropOptions(props.option.id)" class="remove-prop">X</a></span></template>
        <template slot="option" slot-scope="props">
                      <div class="option__body"><span :class="'mr-1 payroll-' + props.option.payrollCodeColor">{{ props.option.payrollCode }}</span>{{ props.option.payrollCodeName }}</div>
     
                    </template></multiselect></div>
                    <div style="width: 250px" class="mx-2" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
        <multiselect
        v-model="userSelected" 
        :options="deptContactData" 
        :multiple="true" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
        <b-form-checkbox  size="sm" class="text-light" v-model="groupData"> Show All Mods? </b-form-checkbox>
      </b-nav-form>
    </b-navbar>
  <b-row>
    <b-col>
<b-card no-body>
  <calendar id="scheduleLeaveCalendar" class="calendarClass" :all-events="userRosterProfileDaysWithMods" :daydisplayboo="groupData" :calendarHeader="calendarHeader" :calendarType="'payroll'" :displayWeekNumber="false" :calendarSmall="true" v-if="showCalendar"></calendar>
    </b-card>
    </b-col>
  </b-row>
</b-container>
</div>
</template>

<script>
import Calendar from '../../calendarFull/Calendar.vue'
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import modsUser from './modsUser.vue'
import bankUser from './bankUser.vue'
import Multiselect from "vue-multiselect";
export default {
  props: ['payrollCodesData', 'payrollPermsData', 'calendarType', 'calendarHeader'],
components: {
  'calendar' : Calendar,
  modsUser,
  bankUser,
  'multiselect' : Multiselect,

},
data() {
    return {
  slide: 0,
  showCalendar: true,
  rosterReload: true,
  groupData: false,
  userSelected: [],
  events: [],
  showSelected: [true],
  showSelectedOpt: [{text: 'Approved', value: true},{text: 'Denied', value: false},{text: 'Needs Attn', value: null}],
  payrollCodesSelectedFilter: [],
  userRosterProfileDaysWithModsData: [],
  userMonthViewModsOrig: [],
  userRosterProfileDays: [],
  userMonthViewMods: [],
  currentMonth: moment().startOf('month'),
  currentView: {},
  recoReportsData: [],
  spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'exchange',
      'payaddative',
      'unpaid leave',
      'roster mod',
      {value: 'overtime', text: 'additions/overtime'},
      {value: 'overtime availability', text: 'avail'},
    ],
    modificationFilter: [
        'bank modification',
        'exchange',
        'scheduled leave',
        'unscheduled leave',
        'modified leave',
        'payaddative',
        'unpaid leave'
    ],
  previousDataReco: '',

}
},
created () {
  //this.getUserProfile()
  //console.log('FULL CALENDAR VIEW CREATED')
  bus.$on('CalendarCurrentDisplayMonthLeave', this.currentDateSelect)
  bus.$on('newPayrollModification', this.getNewModData)
  bus.$on('CalendarCurrentMonthObjectLeave', this.SetCurrentMonth)

},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
  ]),
  payrollCodesDataReturn(){
    return _.orderBy(this.payrollCodesData, ['payrollCode'], ['asc'])
  },
  recoReportsDataSorted(){
          var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
          if(array[0]){
          return array
          } else {
            return null
          }
        },
userRosterProfileDaysWithMods(){
            var data = this.userRosterProfileDaysWithModsData
            if(this.modificationFilter && this.modificationFilter.length > 0){
              data = data.filter(p => {
                  if(p.payrollMod && p.payrollMod.payrollModType){
                    return this.modificationFilter.indexOf(p.payrollMod.payrollModType) >= 0
                  }
                   
              })
            }
            if(this.showSelected && this.showSelected.length > 0){
              data = data.filter(p => {
                  if(p.payrollMod){
                    return  this.showSelected.indexOf(p.payrollMod.payrollModApproved) >= 0
                  }
                   
              })
            }
            if(this.userSelected && this.userSelected.length > 0){
              data = data.filter(p => {
                  if(p.payrollMod){
                    return  this.userSelected.map(p => p.id).indexOf(p.payrollMod.payrollModExchangeUserId) >= 0 ||
                    this.userSelected.map(p => p.id).indexOf(p.payrollMod.payrollModTargetUserId) >= 0
                  }
                   
              })
            }
            if(this.payrollCodesSelectedFilter && this.payrollCodesSelectedFilter.length > 0){
              data = data.filter(p => {
                  if(p.payrollMod){
                    return  this.payrollCodesSelectedFilter.map(p => p.id).indexOf(p.payrollMod.payrollModCode) >= 0 
                  }
                   
              })
            }
            
            if(this.groupData){
                console.log('FALSE')
                return data
              } else {
                var array = data
                var dates = [... new Set(array.map(p => p.date))]
                var groupDataHold = []
                for(var d in dates){
                  var dayDate = array.filter(p => p.date == dates[d])
                  var cats = [... new Set(dayDate.map(p => p.payrollCode))]
                  cats = _.sortBy(cats)
                  for(var c = 0; c < cats.length; c ++ ){
                    var availableItems = dayDate.filter(p => p.payrollCode == cats[c])
                    var payrollCode = this.payrollCodesData.find(p => p.payrollCode == cats[c])
                    var newEvent = {
                        id:c+1,
                        title: cats[c],
                        description: availableItems.map(p => p.descriptionObj),
                        color: payrollCode && payrollCode.payrollCodeColor ? payrollCode.payrollCodeColor : 'danger',
                        date: dates[d],
                    }
                    groupDataHold.push(newEvent)
                  }
                }
                console.log('groupData', groupDataHold)
               return groupDataHold
          } 
        }
},
mounted(){
  if(this.siteconfig.payroll.fullCalendarShowAllMods){
    this.modificationFilter = [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'exchange',
      'payaddative',
      'unpaid leave',
      'roster mod',
      'overtime',
      'overtime availability'
    ]
  }
},
methods: {
  SetCurrentMonth(data){
   // console.log('SCHEDULED LEAVE METHOD')
   // console.log(data)
    this.currentMonth = data
  },
  removePropOptions(id){
    var index = this.payrollCodesSelectedFilter.map(p => p.id).indexOf(id)
    if(index != -1){
      this.payrollCodesSelectedFilter.splice(index, 1)
    }
  },
  goPrev () {
      let payload = moment(this.currentMonth).subtract(1, 'months').startOf('month');
      bus.$emit('CHANGE_MONTH_BUS', {date: payload, view: this.calendarType });
  },
  goNext () {
      let payload = moment(this.currentMonth).add(1, 'months').startOf('month');
      bus.$emit('CHANGE_MONTH_BUS', {date: payload, view: this.calendarType });
  },
  updateUserEventList(){
    var array = _.cloneDeep(this.userRosterProfileDays)
    this.userMonthViewModsOrig = this.userMonthViewMods
    var unassigned = this.userMonthViewMods.filter(p => {
      return p.payrollModApproved != false
    })
     if(this.siteconfig.payroll.customPerms && this.payrollPermsData){
       var obj = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
      // console.log('PAYROLL PERMS')
       if(obj && obj.payrollManageUsers && obj.payrollManageUsers.length > 0){
         var permsView = unassigned.filter(p => {
                var index = obj.payrollManageUsers.indexOf(p.payrollModTargetUserId)
                if(index >= 0 || p.payrollModTargetUserId == this.userData.id){
                  return p
                }
         })
       } else {
            var permsView = unassigned
       }
     } else {
      var permsView = unassigned
     }
    var mods = permsView.map(item => item = this.setUpModCalendar(item))
    for(var i in mods){

      array.push(mods[i])
    }
    console.log(array)
    this.userRosterProfileDaysWithModsData = array


  },
  setUpModCalendar(item){
          var holdcolor = 'default'
          
          var obj = {}
          var user = this.userSelected ? this.userSelected.id : this.userData.id
          if(item.payrollModExchangeUserId == user){
            var payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'EXON')
            var payrollCodeFind = payrollCode.payrollCode
            var modifier = ''
            if(item.payrollModExchangeApproved && item.payrollModApproved){
              modifier = 'Approved'
            } else if(item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting Approval'
            } else if(!item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting User Approval'
            } else {
              modifier = ''
            }
          } else if (item.payrollModExchangeUserId){
             var payrollCode = this.payrollCodesData.find(p => p.id == item.payrollModCode)
            var payrollCodeFind = payrollCode.payrollCode
            var modifier = ''
            if(item.payrollModExchangeApproved && item.payrollModApproved){
              modifier = 'Approved'
            } else if(item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting Approval'
            } else if(!item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting User Approval'
            } else {
              modifier = ''
            }
          }
          else {
            var payrollCode = this.payrollCodesData.find(p => p.id == item.payrollModCode)
            if(!payrollCode){
              console.log(item)
            }
            var payrollCodeFind = payrollCode && payrollCode.payrollCode ? payrollCode.payrollCode : 'MISSING'
            var modifier = item.payrollModTargetUserId ? this.getUserLastNameId(item.payrollModTargetUserId) : item.payrollModNote ? item.payrollModNote : 'Missing Users?'
            var modifierplus = item.payrollModApproved ? 'Approved' : item.payrollModApproved == null ? 'Awaiting Approval' : 'Denied'
          }
        //  console.log(item.payrollModType)
          obj['id'] = item.id,
          obj['title'] = item.payrollModType
          obj['description'] = [{eventDate: item.payrollModDatetime, 
                                 id: item.id,
                                 eventDescription: item.payrollModType, 
                                 eventTitleDescription: payrollCodeFind + ' (' + item.payrollModHours + 'hrs) ' + modifier,
                                 currentStatus: modifierplus}]
          obj['color'] = payrollCode && payrollCode.payrollCodeColor ? payrollCode.payrollCodeColor : 'danger'
          obj['date'] = moment(item.payrollModDatetime).format('YYYY-MM-DD')
          obj['startTime'] = item.payrollModDatetime
          obj['endTime'] = moment(item.payrollModDatetime).add(item.payrollModHours, 'hours').toISOString()
          obj['payrollMod'] = item
          obj['payrollCode'] = payrollCodeFind
          obj['descriptionObj'] = {eventDate: item.payrollModDatetime, 
                                 id: item.id,
                                 eventDescription: item.payrollModType, 
                                 eventTitleDescription: payrollCodeFind + ' (' + item.payrollModHours + 'hrs) ' + modifier,
                                 currentStatus: modifierplus,
                                payrollMod: item}
          return obj
  },
  getUserLastNameId(id){
    var obj = this.deptContactData.find(p => p.id == id)
    if(obj){
      return  obj.firstName.charAt(0) + '_' + obj.lastName + ' (#' + obj.employeeId + ')'
    } return 'Unknown User'
  },
  currentDateSelect(data){
    if(this.currentView.start != data.start || this.currentView.end != data.end){
    //  console.log(data.start + ' ' + data.end)
      this.currentView = data
     this.getPayrollModExchange()
    }
  },
    getNewModData(){ 
    this.getPayrollModExchange()
   // this.getPayrollModByDateByUser()
  },
  getPayrollModExchange(){
    //  this.rosterReload = false
      var start = moment(this.currentView.start, "YYYY-MM-DD").toISOString()
      var end = moment(this.currentView.end, "YYYY-MM-DD").toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/approval/' + start + '/' + end + '/',
     headers: {
           'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
     var array = response.data

      var test = this.userMonthViewMods.map(p => p.id)
     for(var i in array){
       if(test.indexOf(array[i].id) == -1 ){
       this.userMonthViewMods.push(array[i])
       }
     }
     this.updateUserEventList()
    // this.rosterReload = true
   })

    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
