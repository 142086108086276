<template>
<div v-if="dataReset">
            <b-btn size="sm" class="ml-2" v-for="btn in trainingModule" v-if="getFieldVisibility(btn.groupVisibility)" :disabled="showAll" :variant="showGroup == btn.id ? btn.groupVariant : 'outline-' + btn.groupVariant" @click="showGroup = btn.id" :key="btn.id">{{ btn.groupName }}</b-btn>
            <b-form-checkbox v-if="edit" v-model="showAll" :value="true" :unchecked-value="false" @change="showAll && !showGroup && trainingModule[0] ? showGroup = trainingModule[0].id : ''">View All</b-form-checkbox>
            <b-container class="pt-3"> 
                    <b-card v-for="(group, index) in trainingModule" :key="index"
                      :border-variant="group.groupVariant"
                       v-show="showAll == true ? true : showAll == false && showGroup == group.id ? true : false"
                       v-if="group.groupVisibility ? getFieldVisibility(group.groupVisibility) : true"
                       header-tag="header"
                      :header-bg-variant="group.groupVariant"
                      :header-text-variant="group.groupVariant == 'warning' || group.groupVariant == 'default' ? 'dark' : 'white'"
                      class="mb-2"
                      >
                  <div slot="header"><span v-if="group.id != editGroupId" >{{ group.groupName }}</span> 
                       <b-btn size="sm" v-if="group.groupType == 'userDefinedFormItems'" class="float-right" variant="success" @click="newUserFormGroup = !newUserFormGroup">Add New Training Group</b-btn>
                    <b-row v-if="group.id == editGroupId"><b-col align="right"><b-form-input type="text" v-model="group.groupName" placeholder="Give The Form Group A Name" /></b-col>
                        <b-col><b-form-select v-model="group.groupVariant" :options="formGroupColorOptions" /></b-col>
                    <b-col align="left" cols="*">
                      <b-btn size="sm" class="float-right" variant="outline-dark" @click="editGroupId = ''">Save</b-btn></b-col></b-row>      
                    <b-btn  v-if="false && group.id != editGroupId" size="sm" class="float-right" variant="dark"  @click="editGroupId = group.id">Edit</b-btn>
                  </div>
                   <b-form-group 
                                v-if="group.groupType == 'defined' || group.groupType == 'definedpermissions'"
                                breakpoint="lg"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0">
                    <b-row class="mb-2">
                      <b-col v-for="(field, index) in group.groupFields" :key="index" class="mt-2" :lg="field.size" :label-for="field.objKey"><h6>{{ field.label ? field.label : '' }}</h6>
                      <h4 class="mx-auto" v-if="field.type == 'textarea' && field.itemType == 'review' && (field.visibility ? getFieldVisibility(field.visibility) : true)">{{ field.value }}</h4>
                     <b-form-input :class="checkForSubmissionRequirements(field.objKey)" v-if="field.type == 'text' && (field.visibility ? getFieldVisibility(field.visibility) : true)" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.dataStore)" />
                     <b-form-select  :class="checkForSubmissionRequirements(field.objKey)" v-if="field.type == 'select' && (field.visibility ? getFieldVisibility(field.visibility) : true)" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.dataStore)" :options="field.selectOptions" />
                     <b-form-input   :class="checkForSubmissionRequirements(field.objKey)" v-if="field.type == 'date' && (field.visibility ? getFieldVisibility(field.visibility) : true)" type="date" v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.dataStore, 'date')" />
                     <b-form-checkbox v-if="field.type == 'boolean'"  v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.dataStore)" :check-value="true" :uncheck-value="false" >{{ field.checkBoxLabel }}</b-form-checkbox>
                     <Multiselect  v-if="field.type == 'dataRef' && (field.visibility ? getFieldVisibility(field.visibility) : true)" 
                                  v-model="vmodelHold[field.objKey]"
                                   @input="updateFormDataMultiSelect(field.objKey, group.dataStore, field.refValue, field.refType)"
                                 :options="getDataFormReturn(field.refItem)"
                                 :multiple="field.refType == 'multiselect' ? true : false" 
                                 :close-on-select="false" :clear-on-select="true" 
                                 :hide-selected="true" :preserve-search="true" 
                                 placeholder="Please Make a Selection" 
                                 :label="field.refKey" :track-by="field.refKey">
                                    <template slot="option" slot-scope="props">
                                      <span class="option__title">{{ props.option[field.refKey] }}</span>
                                    </template>
                     </Multiselect>
                     <vue-editor v-if="field.type == 'html'" :editorToolbar="customToolbar"  v-model="vmodelHold[field.objKey]" @input="updateFormData(field.objKey, group.dataStore)"  ></vue-editor>
                        <b-container fluid v-if="field.type == 'userBuilderArray'">
                            <b-btn block variant="primary" v-if="vmodelHold.trainingModuleCredential && vmodelHold.trainingModuleCredential.length > 0" @click="updateCredentialUsers(field.objKey)">Add {{ getUsersFromCredentials.length }} Users for the Assigned Credentials</b-btn>
                            <b-btn v-b-toggle.collapse1 variant="dark" block>Show Filter (Filtered: {{ setGroupUsersForTraining.length }})</b-btn>
                              <b-collapse id="collapse1" class="mt-2">
                                <b-card>
                                <userSelector @groupedUsers="setGroupUsers($event, field.objKey)" />
                                </b-card>
                          </b-collapse>
                          <b-row><b-col>
                          <b-form-input v-model="searchData" placeholder="Search by User Name" /></b-col><b-col>
                          <b-checkbox :check-value="true" :uncheck-value="false" @change="updateAllSelectedUsers($event, field.objKey)">Modify All (Filtered: {{ setGroupUsersForTraining.length }})</b-checkbox>
                          </b-col>
                          </b-row>
                          <b-table :fields="['firstName', 'lastName', 'employeeId', 'deptRank', 'addCredential']" :items="setGroupUsersForTrainingFilter" :current-page="currentPage" :per-page="10" >
                            <template slot="addCredential" slot-scope="data">
                              <b-checkbox v-model="data.item.addCredential" :check-value="true" :uncheck-value="false" @input="updateSelectedUsers(field.objKey)" />
                              </template>
                          </b-table>
                          <b-pagination align="center" size="md" :total-rows="setGroupUsersForTrainingFilter.length" v-model="currentPage" :per-page="10"></b-pagination>
                        </b-container>
                    <b-container v-if="field.type == 'submissionBuilder'">
                      <b-card no-body>
                        <b-tabs card>
                          <b-tab title="Hours">
                          <h5>Select the Training Hours Type and Defaults</h5>
                          <b-container><b-row class="mr-5 my-2"><b-col align="right" class="mt-1"><h5>Type: </h5></b-col><b-col>
                          <b-form-select v-model="hoursType" :options="hoursTypeOpt" @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" />
                          </b-col><b-col align="right" class="mt-1"><h5>Default: </h5></b-col><b-col>  
                          <b-form-input type="number" v-model="hoursAmount" @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)"  />
                          </b-col></b-row></b-container>
                          </b-tab>
                          <b-tab title="Completion Roles">
                          <h5>Select the user roles that can submit as complete</h5>
                          <b-form-group>
                             <b-form-checkbox-group :id="'checkboxes1-selfSign_' + randomId" name="roles" v-model="submissionSelfSign" @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" :options="deptRoles">
                          </b-form-checkbox-group>
                          </b-form-group>
                          </b-tab>
                          <b-tab title="Tag Roles">
                             <h5>Select the user roles that can add/tag users to the submission</h5>
                          <b-form-group>
                             <b-form-checkbox-group :id="'checkboxes1-addUsers_' + randomId" name="roles" v-model="submissionAddUsers" @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" :options="deptRoles">
                          </b-form-checkbox-group>
                      </b-form-group>
                      </b-tab>
                      <b-tab title="Individual Admin">
                      <h5>Select Individual Users that can add/tag submit as complete</h5>
                      <b-row><b-col class="px-5">
                      <multiselect v-model="submissionIndividual"
                      @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" 
                      :options="deptContactData" label="lastName" track-by="lastName" 
                      :searchable="true" 
                      :multiple="true"
                      :close-on-select="false" :show-labels="false" placeholder="Select individual training admins">
                          <template slot="option" slot-scope="props">
                            <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                          </template>
                       </multiselect>
                       </b-col></b-row>
                      </b-tab>
                      <b-tab title="Validation">
                        <h5>If you require validation for this training, any user role that is not one of the validation roles will be required to select a user
                          that will be required to validate the training prior to it being logged as complete.</h5>
                        <b-row><b-col cols="4">
                        <b-form-checkbox v-model="validation.boo" :check-value="true" :uncheck-value="false"  @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" >Require Validation</b-form-checkbox>
                        </b-col><b-col>
                        <b-radio-group v-model="validation.type" :disabled="!validation.boo" label="Type of Validation"  @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" >
                          <b-form-radio value="role">Filter available users by Role</b-form-radio>
                          <b-form-radio value="user">Specify the available Users</b-form-radio>
                        </b-radio-group>
                        </b-col></b-row>
                        <b-row class="m-3" v-if="validation.boo" ><b-col cols="3" align="right"><h4 class="pt-1">Display Message:</h4></b-col><b-col align="left">
                           <b-form-input type="text" v-model="validation.message"  @change="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)"  />
                           </b-col></b-row>
                        <b-row class="m-3" v-if="validation.boo && validation.type == 'role'" ><b-col cols="3" align="right"><h4 class="pt-1">Roles:</h4></b-col><b-col align="left">
                          <b-form-checkbox-group class="pt-2"  :id="'checkboxes1-validationRole' + randomId"  @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)"  name="roles" v-model="validation['role']"  :options="deptRoles">
                          </b-form-checkbox-group></b-col></b-row>
                            <b-row class="m-3" v-if="validation.boo && validation.type == 'user'" ><b-col cols="3" align="right"><h4 class="pt-1">Users:</h4></b-col><b-col align="left">
                      <multiselect v-model="validation.user"
                      @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" 
                      :options="deptContactData" label="lastName" track-by="lastName" 
                      :searchable="true" 
                      :multiple="true"
                      :close-on-select="false" :show-labels="false" placeholder="Select individual validation users">
                          <template slot="option" slot-scope="props">
                            <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                          </template>
                       </multiselect>
                       </b-col></b-row>
                        <b-row class="m-3" v-if="validation.boo"><b-col>
                          <b-form-checkbox v-model="validation.email" :check-value="true" :uncheck-value="false"  @input="updateFormDataPermissions(group.dataStore, submissionSelfSign, submissionAddUsers, submissionIndividual, hoursType, hoursAmount, validation)" >
                            Generate email notification for the validation user on submission?
                          </b-form-checkbox>
                          </b-col></b-row>
                      </b-tab>
                        </b-tabs>
                      </b-card>
                       </b-container>
                      </b-col>
                      </b-row>   
                    </b-form-group>
                    <formBuilder v-if="group.groupType == 'userDefinedFormItems'" @updateFormInformation="setUserGeneratedFormData($event, group.dataStore)" :previousForm="previousForm" @closeAddNewGroup="closeAddNewGroup" :newUserFormGroup="newUserFormGroup" />
                    <br>
    
                    </b-card>
                    <b-container class="mt-2">
                      <b-row>
                        <b-col align="right">
                          <b-btn :disabled="evaluatFormSubmission(trainingModuleSubmissionRequirements)" @click="!previousForm ? submitTrainingModule(formValues) : updateTrainingModule(formValues)" variant="success">{{ !previousForm ? 'Submit' : 'Update' }}</b-btn>
                        </b-col>
                      </b-row>
                    </b-container>

                  </b-container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../../main'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { VueEditor } from 'vue2-editor'
import formBuilder from './trainingModuleFormBuilder.vue'
import userSelector from '../../../inputfields/userfilter.vue'
import moment from 'moment'
export default {
  components: {
    userSelector,
    Multiselect,
    VueEditor,
    formBuilder
  },
  props: ['credentialRequirement', 'previousForm', 'credentialsData'],
  name: 'training-module',
  data () {
    return {
        currentPage: 1,
        showAll: true,
        edit: true,
        editGroupId: '',
        showGroup: '',
        newUserFormGroup: false,
        dataReset: true,
        usersFromCredentials: [],
        deptRoles: ['user', 'supervisor', 'trainingops', 'emsops', 'fireops', 'adminchief'],
        submissionAddUsers: ['supervisor', 'trainingops', 'emsops', 'fireops', 'adminchief'],
        updateCheckBoxGroup: true,
        validation: {
          boo: false,
          type: 'role',
          role: ['supervisor', 'trainingops', 'emsops', 'fireops', 'adminchief'],
          user: [],
          message: 'Please Select the Officer that is verifying your training submission',
          email: false,
        },
        submissionSelfSign: ['user', 'supervisor', 'trainingops', 'emsops', 'fireops', 'adminchief'],
        submissionIndividual: [],
        hoursTypeOpt: [
          {text: 'User Defined', value: 'user'},
          {text: 'Fixed', value: 'fixed'}
        ],
        hoursType: 'user',
        hoursAmount: '',
        trainingModule: [
          {
            id: '100',
            groupName: 'Module Tracking Information',
            groupVariant: 'primary',
            groupType: 'defined',
            groupFields: [
              {objKey: 'review918810391', itemType: 'review', type: 'textarea', value: 'Please Utilize this section to set the basic module info', size: "12"},
              {objKey: 'trainingModuleName', itemType: 'form', type: 'text', vmodel: "obj", label: "Module Name", saveData: true, size: "8"},
              {objKey: 'trainingModuleType', itemType: 'form', type: 'select', vmodel: "obj", label: "Module Type", saveData: true, size: "4", selectOptions: [{text: 'In Progress', value: ''},{text: 'Assigned', value: 'assigned'}, {text: 'Scheduled', value: 'scheduled'}, {text: 'User Assigned', value: 'user'}]},
              {objKey: 'trainingModuleCompletionDate', itemType: 'form', type: 'date', vmodel: "obj", label: "Module Completion/Due Date", saveData: true, size: "4", visibility: [{variable: 'trainingModuleType', type: 'isOneOf', values: ['scheduled', 'assigned']}]},
              {objKey: 'trainingModuleCredential', itemType: 'form', type: 'dataRef', vmodel: "obj", label: "Module Credentials", refType: 'multiselect', refItem: 'credentialRequirement', refValue: 'id', refKey: 'credentialRequirementName', saveData: true, size: "5"},
              {objKey: 'trainingModuleActive', itemType: 'form', type: 'boolean', vmodel: "obj", label: "Training Module Status?", checkBoxLabel: 'Active', default: true, saveData: true, size: "3"}
            ]
          },
          {
            id: '101',
            groupName: 'Module Review or Form Items',
            groupVariant: 'secondary',
            groupType: 'userDefinedFormItems',
            dataStore: 'trainingModuleFormData',

          },
          {
            id: '102',
            groupName: 'Module Assigned Users',
            groupVariant: 'secondary',
            groupType: 'defined',
            groupVisibility: [{variable: 'trainingModuleType', type: 'isOneOf', values: ['assigned']}],
            groupFields: [
              {objKey: 'trainingModuleAssignedUsers', itemType: 'array', type: 'userBuilderArray', label: "Assigned Users", saveData: true, size: "12"}
            ]
          },
          {
            id: '200',
            groupName: 'Training Module Submission Permissions',
            groupVariant: 'success',
            groupType: 'definedpermissions',
            dataStore: 'trainingModuleSubmissionVisibility',
            groupFields: [
              {objKey: 'role', itemType: 'array', type: 'submissionBuilder', saveData: true, size: "12"}
              ]
          }
        ],
       trainingModuleSubmissionRequirements: [
           {type: 'isNotBlank', variable: 'trainingModuleName'},
           {type: 'isNotBlank', variable: 'trainingModuleFormData'},
           {type: 'ifEqualsThen', ifVar: 'trainingModuleType', ifVal: ['assigned', 'scheduled'], variable: 'trainingModuleCompletionDate', thenCon: 'isNotBlank'},
           {type: 'ifEqualsThen', ifVar: 'trainingModuleType', ifVal: ['assigned'], variable: 'trainingModuleAssignedUsers', thenCon: 'isNotBlank'}
       ],
       formGroupColorOptions: [
        { value: "info", text: "Teal" },
        { value: "primary", text: "Blue" },
        { value: "success", text: "Green" },
        { value: "danger", text: "Red" },
        { value: "warning", text: "Yellow" },
        { value: "secondary", text: "Grey" },
        { value: "default", text: "Default" }
      ],
      formValues: {

      },
      vmodelHold: {

      },
      setGroupUsersForTraining: [],
      searchData: '',
      customToolbar: [
          ['bold', 'italic', 'underline', 'strike', { 'align': [] }],
          ['blockquote', 'code-block', 'link', { 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'script': 'sub'}, { 'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }],
        ]
    }
  },
  created(){
    var form = _.cloneDeep(this.trainingModule)
    this.buildFormData(form)
    
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),
        randomId(){
          return  parseInt(Math.random()*1000000000) + '_' + parseInt(Math.random()*1000000000)
        },
       deptContactDataCheck(){
         var data = this.deptContactData
         data.forEach(item => item['addCredential'] = false)
         return data
       },
       getUsersFromCredentials(){
         var users = []
         if(this.vmodelHold.trainingModuleCredential){
           var credReq = this.vmodelHold.trainingModuleCredential
           for(var i in credReq){
             var credentials = this.credentialsData.filter(p => {
                if(p.credentialRequirements){
                var index = p.credentialRequirements.indexOf(credReq[i].id)
                if(index != -1){
                  return p
                }
                }
             })
             for(var r in credentials){
               var cusers = credentials[r].credentialAssignedUsers
               for(var u in cusers){
                 users.push(cusers[u])
               }
             }
           }
         }

         var holdUsers = [... new Set(users.map(p => p))]
         return holdUsers
       },
       setGroupUsersForTrainingFilter(){
         return this.setGroupUsersForTraining.filter(p => {
           return p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase())
         })
       },
  
  },
  mounted(){

  },
  watch: {
  },
  methods: {
    closeAddNewGroup(){
      this.newUserFormGroup = false
    },
        updateUserItems(data, row){
        row.credentialAssignedUsers = data
        this.showModal = false
        this.editItem = ''
        this.modalType = ''
        this.searchData = ''
    },
    updateRequirementItems(data, row){
        row.credentialRequirements = data
        this.showModal = false
        this.editItem = ''
        this.modalType = ''
        this.searchData = ''
    },
    setGroupUsers(data, objKey){
      var users = this.deptContactDataCheck
      if(this.vmodelHold[objKey]){
        var usersid = users.map(p => p.id)
        var setTrue = this.vmodelHold[objKey]
        for(var i in setTrue){
          users[usersid.indexOf(setTrue[i])].addCredential = true
        }
      }
      var array = users.filter(p => {
           var index = data.indexOf(p.id)
             if(index != -1){
               return p
             }
         })
      this.setGroupUsersForTraining = _.orderBy(array, ['lastName'], ['asc'])
    },
    updateSelectedUsers(objKey){
         var data = this.setGroupUsersForTraining.filter(p => {
           return p.addCredential == true
         })
         var array = data.map(p => p.id)
         this.vmodelHold[objKey] = array
        this.updateFormData(objKey)
       },
    updateCredentialUsers(objKey){
      if(this.getUsersFromCredentials){
        var array = this.setGroupUsersForTraining
        var users = this.getUsersFromCredentials
        for(var i in array){
          var index = this.getUsersFromCredentials.indexOf(array[i].id)
          if(index != -1){
            array[i].addCredential = true
          }
        }
      }
    },
    updateAllSelectedUsers(data, objKey){
         var array = this.setGroupUsersForTraining
         array.forEach(item => item.addCredential = data)
         this.setGroupUsersForTraining = array
         this.updateSelectedUsers(objKey)
    },
    updateFormDataPermissions(item, self, others, individual, hourstype, hours, validation){
        this.updateCheckBoxGroup = false
        this.formValues[item] = []
        if(individual){
          var holdusers = individual.map(p => p.id)
        } else {
          var holdusers = null
        }
        this.submissionAddUsers = others
        console.log(others)
        this.formValues[item].push({permtype: 'self', perms: self})
        this.formValues[item].push({permtype: 'others', perms: others})
        this.formValues[item].push({permtype: 'individual', perms: holdusers})
        this.formValues[item].push({permtype: 'hourstype', perms: hourstype})
        this.formValues[item].push({permtype: 'hours', perms: hours})
        this.formValues[item].push({permtype: 'validation', perms: validation})
        
        this.$nextTick(function(){
      //   console.log(this.formValues[item])
          this.updateCheckBoxGroup = true
        })
    },
    evaluatFormSubmission(req){
      var booleanHold = false
      var data = this.vmodelHold
      var data2 = this.formValues
      for(var i in req){
        if(req[i].type == 'isNotBlank'){
          if(!data[req[i].variable] && !data2[req[i].variable]){
            booleanHold = true
          } else if(data[req[i].variable] && data[req[i].variable].length == 0){
                  booleanHold = true
         } else if(data2[req[i].variable] && data2[req[i].variable].length == 0){
                  booleanHold = true
         }
        } else if(req[i].type == 'ifEqualsThen'){
          var index = req[i].ifVal.indexOf(data[req[i].ifVar])
            if(index != -1){
              if(req[i].thenCon == 'isNotBlank'){
                if(!data[req[i].variable]){
                  booleanHold = true
                } else if(data[req[i].variable].length == 0){
                  booleanHold = true
                }
              }
          }
        }
      }
      return booleanHold
    },
    setUserGeneratedFormData(data, group){
    //  console.log(data)
      this.formValues[group] = data
    },
    checkForSubmissionRequirements(objKey){
     var ids = this.trainingModuleSubmissionRequirements.map(p => p.variable)
     var index = ids.indexOf(objKey)
     if(index != -1 && !this.vmodelHold[objKey]){
        return 'requirement-error'
        } else if (index != -1 && this.vmodelHold[objKey].length == 0){
       return 'requirement-error'
       } else if (index != -1) {
       return 'requirement-success'
     } else {
       return
     }
    },
    getFieldVisibility(fn){
      var booleanhold = true
      for(var i in fn){
        if(fn[i].type == 'isOneOf'){
          var index = fn[i].values.indexOf(this.vmodelHold[fn[i].variable])
          if(index == -1){
            booleanhold = false
          }
        } 
      }
      return booleanhold
    },
    getDataFormReturn(data, filter){
      if(!filter){
        return this[data]
      } else {
        // need filter script
        return this[data]
      }
    },
    updateFormDataMultiSelect(obj, dataStore, savevalue, multi){
      var value = this.vmodelHold[obj]
      if(multi == 'multiselect'){
      var array = []
      for(var i in value){
        array.push(value[i][savevalue])
      }
      value = array
      } else {
        value = value[savevalue]
      }
      if(dataStore){
        this.formValues[dataStore][obj] = value
      } else {
        this.formValues[obj] = value
      }
    },
    updateFormData(obj, dataStore, type){
      if(type && type == 'date'){
      var value = this.vmodelHold[obj]
      if(value){
              var date = value + 'T23:59:59'
              var offSet = moment().utcOffset();
              var hourOffSet = offSet * -1 / 60;
              var timeStringForm = moment(date).add(hourOffSet, "hours");
              var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss");
              value = timeStringAdd + ".000Z"
      } 
      } else {
        var value = this.vmodelHold[obj]
      }
      if(dataStore){
        var array = Array.isArray(this.formValues[dataStore])
        if(array){
          var index = this.formValues[dataStore].map(p => p.id).indexOf(obj)
          if(index != -1){
            this.formValues[dataStore][index].value = this.vmodelHold[obj]
          } else {
            this.formValues[dataStore].push({id: obj, value: this.vmodelHold[obj]})
          }
        this.formValues[dataStore][obj] = value
        } else {
          this.formValues[dataStore] = []
          this.formValues[dataStore].push({id: obj, value: this.vmodelHold[obj]})
        }

      } else {
        this.formValues[obj] = value
      }

    },
    formReset(){
      this.dataReset = false
      this.formValues = {}
      this.vmodelHold = {}
      this.$nextTick(function(){
        this.dataReset = true
         this.buildFormData(this.trainingModule)
      })
    },
    buildFormData(data){
      console.log(data)
      var obj = {}
      var vmodelHold = {}
      for(var i in data){
        if(data[i].groupType != 'definedpermissions'){
        var input = data[i].groupFields
         if(!data[i].dataStore){
        for(var p in input){
          if(input[p].saveData){
            var value = input[p].itemType == 'array' ? [] : input[p].itemType == 'obj' ? {} : input[p].type == 'boolean' && input[p].default ? input[p].default : ''
            obj[input[p].objKey] = value
            vmodelHold[input[p].objKey] = value
          }
          }
        } else {
          var newObj = {}
          for(var p in input){
             if(input[p].saveData){
              var value = input[p].itemType == 'array' ? [] : input[p].itemType == 'obj' ? {} : ''
              newObj[input[p].objKey] = value
               vmodelHold[input[p].objKey] = value
             }
          }
          obj[data[i].dataStore] = newObj
        }
      }
      else if(data[i].groupType == 'definedpermissions') {
        obj[data[i].dataStore] = [
              {permtype: 'self', perms: this.submissionSelfSign},
              {permtype: 'others', perms: this.submissionAddUsers},
              {permtype: 'individual', perms: this.submissionIndividual}
        ]
      }
      } 
      this.vmodelHold = vmodelHold
      this.formValues = obj
      if(this.previousForm){
        this.updatePreviousValues()
      }
    },
    updatePreviousValues(){
  //    console.log('PREVIOUS')
   //   console.log(this.previousForm)
      var prev = this.previousForm
      var formkeys = Object.keys(this.formValues)
      for(var i in formkeys){
        if(prev[formkeys[i]]){
          if (formkeys[i] == 'trainingModuleSubmissionVisibility'){
                    var vals = prev[formkeys[i]]
                    var self = vals.find(p => p.permtype == 'self')
                    if(self){
                     this.submissionSelfSign = self.perms
                    }
                    var other = vals.find(p => p.permtype == 'others')
                    if(other){
                      this.submissionAddUsers = other.perms
                    }
                    var hourstype = vals.find(p => p.permtype == 'hourstype')
                    if(hourstype){
                      this.hoursType = hourstype.perms
                    }
                    var amount = vals.find(p => p.permtype == 'hours')
                    if(amount){
                      this.hoursAmount = amount.perms
                    }
            
                    var individual = vals.find(p => p.permtype == 'individual')
                   // console.table(individual)
                    if(individual){
                      var array = []
                      var start = individual.perms
                      for(var i in start){
                        var user = this.deptContactData.find(p => p.id == start[i])
                        if(user){
                          array.push(user)
                        }
                      }
                      this.submissionIndividual = array
                    }
                    var validation = vals.find(p => p.permtype == 'validation')
                    if(validation){
                      this.validation = validation.perms
                    }
                this.formValues[formkeys[i]] = prev[formkeys[i]]
          } else if (formkeys[i] == 'trainingModuleFormData'){
         //     console.log('FORMDATA')
           //   console.log(prev[formkeys[i]])
             this.formValues[formkeys[i]] = prev[formkeys[i]]
          } else {
          this.formValues[formkeys[i]] = prev[formkeys[i]]
          }
        }
      }
      var vmod = Object.keys(this.vmodelHold)
         for(var i in vmod){
        if(prev[vmod[i]]){
          if(vmod[i] == 'trainingModuleCredential'){
                var vals = prev[vmod[i]]
                var valarray = []
                for(var c in vals){
                  var obj = this.credentialRequirement.find(p => p.id == vals[c])
                  if(obj){
                    valarray.push(obj)
                  }
                }
                this.vmodelHold[vmod[i]] = valarray
          } else if (vmod[i] == 'trainingModuleCompletionDate'){
                    var vals = prev[vmod[i]]
                this.vmodelHold[vmod[i]] = moment(vals).format('YYYY-MM-DD')
            } else {
          this.vmodelHold[vmod[i]] = prev[vmod[i]]
          }
        }
      }
      console.log(this.formValues)
    },
    submitTrainingModule(data){
      if(!data['trainingModuleCompletionDate']){
        data['trainingModuleCompletionDate'] = null
      }
      if(data['trainingModuleAssignedUsers'].length == 0){
        data['trainingModuleAssignedUsers'] = null
      }
      if(data['trainingModuleCredential'].length == 0){
        data['trainingModuleCredential'] = null
      }
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
      data['trainingModuleAuthorName'] = this.userData.firstName + ' ' + this.userData.lastName
      data['trainingModuleAuthorId'] = this.userData.id
     axios({
      method: 'post',
      url: '/api/v1/training/trainingmodule/',
      data: data,
      headers: headers.headers,
    }).then( response => {
      this.$emit('updateTrainingTable')
      bus.$emit('updateTrainingModules')
      this.vmodelHold = {}
      this.formValues = {}
      this.$nextTick(function(){
        this.formReset()
      })
     
      
    })
  },
  updateTrainingModule(data){
     // console.log(data)
      if(!data['trainingModuleCompletionDate']){
        data['trainingModuleCompletionDate'] = null
      }
      if(data['trainingModuleAssignedUsers'].length == 0){
        data['trainingModuleAssignedUsers'] = null
      }
      if(data['trainingModuleCredential'].length == 0){
        data['trainingModuleCredential'] = null
      }
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
      data['trainingModuleAuthorName'] = this.userData.firstName + ' ' + this.userData.lastName
      data['trainingModuleAuthorId'] = this.userData.id
     axios({
      method: 'patch',
      url: '/api/v1/training/trainingmodule/' + this.previousForm.id,
      data: data,
      headers: headers.headers,
    }).then( response => {
      this.$emit('updateTrainingTable')
      this.$bus('updateTrainingModules')
      this.vmodelHold = {}
      this.formValues = {}
      this.$nextTick(function(){
        this.formReset()
      })
      
    })
  },

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.requirement-error{
  border: 1px solid red;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
