<template>
<div>
  <b-modal
       id="repairModal"
       ref="repairModal"
       v-model="showModal"
       size="lg"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
       v-on:hide="emitCloseHydrant"
         >
         <template slot="modal-header">
           <span>New Hydrant Inspection</span>
             <b-btn align="right" size="sm" variant="danger" @click="emitCloseHydrant">x</b-btn>
         </template>
         <div class="modal-body">
            <b-row><b-col>
             <b-form-input type="date" v-model="holdDate" />
             </b-col><b-col>
             <b-form-input type="time" v-model="holdTime" />
            </b-col><b-col>
             <b-form-input type="text" v-model="holdAuthor" place-holder="Inspected by" />
             </b-col>
             </b-row>
           <b-row>

             <b-btn block variant="primary" @click="addInspection = !addInspection">Show Full Inspection</b-btn>
             <b-col class="mt-3" v-for="(input, index) in formInputReturned" :key="index" :lg="!addInspection && input.size != '12' ? '6': input.size">
               <h6>{{ input.text}}</h6>
               <b-form-checkbox v-if="input.type == 'boolean'" v-model="ppObj[input.vmodel]" @change="updatePPValue($event, input.vmodel)" />
               <b-form-input v-if="input.type == 'text' || input.type == 'number'" :type="input.type" v-model="ppObj[input.vmodel]" @input="updatePPValue($event, input.vmodel)" />
               <b-textarea v-if="input.type == 'textarea'" v-model="ppObj[input.vmodel]"  @input="updatePPValue($event, input.vmodel)" />
             </b-col>
           </b-row>
           <span v-show="false">{{ ppObjReturned }}</span>
         </div>
         <template slot="modal-footer">
           
           <b-btn @click="submitFormData">Submit</b-btn>
         </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios';
import moment from 'moment'
export default {
  props:["hydrantId"],
components:{

},
  data () {
    return {
        showModal: true,
        trueBoo: true,
        ppObj: {
          hydrantStatus: true,
          HydrantMainValveSize: 0.0,
          hydrantPumperOutletHeight: 0,
          hydrantStaticPressure: 0,
          hydrantResidualPressure: 0,
          hydrantPitotReading: 0,
          hydrantGpm: 0.0,
          hydrantGpmTwenty: 0.0,
          hydrantFlowTime: 0,
          hydrantPainted: false,
          hydrantNewtag: false,
          hydrantNewrpm:false,
          hydrantNozzleOpen: false,
          hyrdantFlushMinutes: 0,
          hydrantComment: '',
          hydrantValveStatus: true,
          hydrantValveSize: 0,
          hydrantValveOpenDirection: 'left',
          hydrantValveTurns: 0,
          hydrantValveDepth: 0,
          hydrantValveComment: '',
          inspectionAuthor: '',
          inspectionAuthorId: '',
        },
        formInput: [
           {vmodel:  'hydrantStatus', text: 'Hydrant Operable', type: 'boolean', default:  true, size: '3', inspectionFlag: false},
           {vmodel:  'hydrantValveStatus', text: 'Hydrant Valve Operable', type: 'boolean', default:  true, size: '3', inspectionFlag: false},
           {vmodel:  'HydrantMainValveSize', text: 'Main Valve Size', type: 'number', default: 0.0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantPumperOutletHeight', text: 'Pumper Outlet Height', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantStaticPressure', text: 'Static Pressure', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantResidualPressure', text: 'Residual Pressure', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantPitotReading', text: 'Pitot Reading', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantGpm', text: 'GPM', type: 'number', default: 0.0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantGpmTwenty', text: 'GPM@20PSI', type: 'number', default: 0.0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantFlowTime', text: 'Flow Time', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantPainted', text: 'Painted', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hydrantNewtag', text: 'New Tag', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hydrantNewrpm', text: 'New RPM', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hydrantNozzleOpen', text: 'Nozzle Open', type: 'boolean', default: false, size: '2', inspectionFlag: true},
           {vmodel:  'hyrdantFlushMinutes', text: 'Flush Time', type: 'number', default: 0, size: '4', inspectionFlag: true},
           {vmodel:  'hydrantComment', text: 'Hydrant Comment', type: 'textarea', default: '', size: '12', inspectionFlag: false},
           {vmodel:  'hydrantValveSize', text: 'Hydrant Valve Size', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveOpenDirection', text: 'Valve Open Direction', type: 'text', default: 'left', size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveTurns', text: '# of Valve Turns', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveDepth', text: 'Valve Depth', type: 'number', default: 0, size: '3', inspectionFlag: true},
           {vmodel:  'hydrantValveComment', text: 'Hydrant Valve Comment', type: 'textarea', default: '', size: '12', inspectionFlag: false},
        ],
        originalData: '',
        noPreviousData: false,
        addInspection: false,
        holdDate: '',
        holdTime: '',
        holdAuthor: '',
    }
  },
  created(){
    this.getHydrantData(this.hydrantId)
    this.holdAuthor = this.userData.firstName + ' ' + this.userData.lastName
    this.holdDate = moment().format('YYYY-MM-DD')
    this.holdTime = moment().format('HH:mm')
  },
  mounted(){

  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'deptContactData',
          'userData',
          'shiftsData',
          'repairStatus',
          'blogType',
          'workgroupData',
          'documentsApi',
          'siteconfig'
        ]),
        formInputReturned(){
          if(this.addInspection){
            return this.formInput
          } else {
            return this.formInput.filter(p => p.inspectionFlag == false)
          }
        },
        ppObjReturned(){
          return this.ppObj
        },  
  
    },
    watch: {


      },
  beforeDestroy(){
      document.body.classList.remove('modal-open');
  },
  methods:{
    updatePPValue(data, model){
      this.ppObj[model] = data
    },
    emitCloseHydrant () {
      this.showModal = false
      this.$emit('emitCloseHydrant')
    },
    getHydrantData(id) {
      axios({
        method: "get",
        url: "/api/v1/preplan/hydrant/" + id,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        if(response.data && response.data[0]){
            this.buildOutData(response.data[0])
        }
       
      });
    },
    buildOutData(data){
      this.originalData = _.cloneDeep(data)
      var previousData = data.hydrantInspectionData
      if(previousData && previousData.length > 0){
        var keys = Object.keys(this.ppObj)
        var newest = _.orderBy(previousData, ['inspectionDate'], ['desc'])
        var inspections = newest.filter(p => p.fullInspection != false)
        if(inspections && inspections[0]){
          var insObj = inspections[0]
          for(var i in keys){
            var value = insObj[keys[i]]
            this.ppObj[keys[i]] = value
          }
          this.ppObj['hydrantStatus'] = data.hydrantOperable
          this.ppObj['hydrantValveStatus'] = data.hydrantValveOperable
        }
      } else {
        this.noPreviousData = true
      }
    },
    submitFormData(){
      var inspection = this.ppObj
      var previous = this.originalData.hydrantInspectionData
      if(previous && previous.length > 0){
         var holdArray = previous
      } else {
        var holdArray = []
      }
      if(this.addInspection){
        var data = {}
        data['hydrantValveOperable'] = inspection.hydrantValveStatus
        data['hydrantOperable'] = inspection.hydrantStatus
        data['hydrantLastInspection'] = moment(this.holdDate + ' ' + this.holdTime, 'YYYY-MM-DD HH:mm').toISOString()
        data['hydrantValveLastInspection'] = moment(this.holdDate + ' ' + this.holdTime, 'YYYY-MM-DD HH:mm').toISOString()
        data['hydrantGpm'] = inspection.hydrantGpmTwenty ? inspection.hydrantGpmTwenty : inspection.hydrantGpm ? inspection.hydrantGpm : null
        inspection['inspectionDate'] = moment(this.holdDate + ' ' + this.holdTime, 'YYYY-MM-DD HH:mm').toISOString()
        inspection['inspectionAuthor'] = this.holdAuthor
        inspection['inspectionAuthorId'] = this.userData.id
        holdArray.push(inspection)
        data['hydrantInspectionData'] = holdArray
      } else {
        var data = {}
        data['hydrantValveOperable'] = inspection.hydrantValveStatus
        data['hydrantOperable'] = inspection.hydrantStatus
        inspection['fullInspection'] = false
        inspection['inspectionDate'] = moment(this.holdDate + ' ' + this.holdTime, 'YYYY-MM-DD HH:mm').toISOString()
        inspection['inspectionAuthor'] = this.holdAuthor
        inspection['inspectionAuthorId'] = this.userData.id
        holdArray.push(inspection)
        data['hydrantInspectionData'] = holdArray
      }
     axios({
          method: 'patch',
          url: '/api/v1/preplan/hydrant/' + this.hydrantId,
          data: data,
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
            }).then( response => {
          
              console.log(response.data)
              this.emitCloseHydrant()
          
      })
    },

}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>
<style scoped>
.error-border {
  border: 1px solid red;
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > input
{
    display: none;
}
.closeButtonCarousel {
    width: 20px;
    background: red;
    padding: 0 4px;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto 0;
    cursor: pointer;
}
.closeButton {
  position: relative;
  width: 20px;
  background: red;
  float: left;
  padding: 0 4px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.videoRemove {

}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
