<template>
<div id="homelanding">

  <div class="headerSpanStaffing" v-if="!tvView">Department Staffing <span v-if="showAdmin">Admin</span>
  <span class="float-right">
      <b-btn v-if="siteconfig.staffingMain && false" size="sm" @click="updateFullScreen" :variant="fullScreenViewToggle ? 'light' : 'outline-light'">{{ !fullScreenViewToggle ? 'Full Screen' : 'Normal View' }}</b-btn>
    </span>
<span class="float-right">
      <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief'" size="sm" @click="showAdmin = !showAdmin" :variant="showAdmin ? 'light' : 'outline-light'">Admin</b-btn>
    </span>

  </div>
<adminStaffing v-if="showAdmin"  
                :rosterProfiles="rosterProfiles" 
                :rosterData="rosterData"
                :scheduleData="scheduleData"
                :payrollCodesData="payrollCodesData" 
                :payrollBanksData="payrollBanksData" 
                :payrollAccuralData="payrollAccuralData"
                :payrollHolidaysData="payrollHolidaysData"
                :payrollPermsData="payrollPermsData"
                />

<b-container fluid class="px-0" v-if="!showAdmin">
   <dailyroster v-if="rosterProfiles.length > 0 && rosterData.length > 0 && rosterReload && tvView && !siteconfig.payroll.flexibleSchedule"
                 :tvView="true" 
                 :stationVar="stationVar"
                 :payrollCodesData="payrollCodesData"
                 :rosterProfiles="rosterProfiles" 
                 :selectedDateHold="selectedDate"
                 :rosterData="rosterData" 
                 :scheduleData="scheduleData"
                 @updateSelectedDate="updateSelectedDate" 
                 :payrollModDailyRosterData="payrollModDailyRosterData" />
<b-card no-body class="d-none d-md-block" v-if="!tvView">
  <b-tabs card v-model="tabIndex">
    <b-tab no-body title="Employee" @click="updateVisible('employeeTab')" :active="employeeTabActive">
            <employeeInfo
            v-if="!this.siteconfig.payroll.flexibleSchedule && fieldsObj.employeeTab"
            :recoReportsData="recoReportsData"
            :payrollCodesData="payrollCodesData" 
            :payrollBanksData="payrollBanksData" 
            :payrollAccuralData="payrollAccuralData"
            :scheduleData="scheduleData"
            :payrollHolidaysData="payrollHolidaysData"
            :payrollPermsData="payrollPermsData"
                   :holdState="holdState"
            @userSelected="setUserSelected($event)" />
            <employeeInfo
            v-if="this.siteconfig.payroll.flexibleSchedule && fieldsObj.employeeTab && scheduleData && scheduleData.length > 0"
            :recoReportsData="recoReportsData"
            :payrollCodesData="payrollCodesData" 
            :payrollBanksData="payrollBanksData" 
            :payrollAccuralData="payrollAccuralData"
            :scheduleData="scheduleData"
            :payrollHolidaysData="payrollHolidaysData"
            :payrollPermsData="payrollPermsData"
                   :holdState="holdState"
            @userSelected="setUserSelected($event)" />
    </b-tab>
    <b-tab :title="siteconfig.payroll.flexibleSchedule ? 'Daily Schedule' : 'Daily Roster'"  @click="updateVisible('dailyRoster')" v-if="!siteconfig.payroll.flexibleSchedule ">
    <dailyroster v-if="rosterData.length > 0 && rosterReload && !siteconfig.payroll.flexibleSchedule && fieldsObj.dailyRoster"
                 :payrollCodesData="payrollCodesData"
                 :payAddativeTrackingArray="payAddativeTrackingArray"
                 :rosterProfiles="rosterProfiles" 
                 :selectedDateHold="selectedDate"
                 :rosterData="rosterData" 
                 :scheduleData="scheduleData"
                 @updateSelectedDate="updateSelectedDate" 
                 :payrollModDailyRosterData="payrollModDailyRosterData" />
    </b-tab>
    <b-tab v-if="userData.role == 'admin' && !siteconfig.payroll.flexibleSchedule" title="server-side">
          <serverSideBuild v-if="rosterData.length > 0 && rosterReload"
                 :payrollCodesData="payrollCodesData"
                 :payAddativeTrackingArray="payAddativeTrackingArray"
                 :rosterProfiles="rosterProfiles" 
                 :selectedDateHold="selectedDate"
                 :rosterData="rosterData" 
                 :scheduleData="scheduleData"
                 @updateSelectedDate="updateSelectedDate" 
                 :payrollModDailyRosterData="payrollModDailyRosterData" />
      </b-tab>
    <b-tab title="Daily Schedule" @click="updateVisible('dailyRoster')" v-if="siteconfig.payroll.flexibleSchedule">
    <dailyRosterScheduled v-if="rosterData.length > 0 && rosterReload && fieldsObj.dailyRoster" 
                 :payrollCodesData="payrollCodesData"
                 :rosterProfiles="rosterProfiles"
                 :resetRoster="resetRoster"
                 :selectedDateHold="selectedDate"
                 :rosterData="rosterData" 
                 :scheduleData="scheduleData"
                 :payrollHolidaysData="payrollHolidaysData"
                 @updateSelectedDate="updateSelectedDate" 
                 :payrollModDailyRosterData="payrollModDailyRosterData"
                 :payrollPermsData="payrollPermsData" />
    </b-tab>
        <b-tab :title="siteconfig.payroll.krajicView && false ? 'Krajic View' : 'Daily Combined'" :disabled="payrollModDailyRosterData.length == 0" @click="updateVisible('dailyCombined')" v-if="(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops')  && !siteconfig.payroll.flexibleSchedule">
      <dailyRosterCombined 
                  v-if="rosterProfiles.length > 0 && rosterData.length > 0 && rosterReload && !siteconfig.payroll.flexibleSchedule && fieldsObj.dailyCombined" 
                :payrollCodesData="payrollCodesData"
                 :rosterProfiles="rosterProfiles" 
                 :selectedDateHold="selectedDate"
                 :rosterData="rosterData"
                 :scheduleData="scheduleData"
                 @updateSelectedDate="updateSelectedDate" 
                 :payrollModDailyRosterData="payrollModDailyRosterData" />
    </b-tab>
    <b-tab title="Daily Modifications" :disabled="payrollModDailyRosterData.length == 0" @click="updateVisible('dailyModifications')" v-if="(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops') && !siteconfig.payroll.flexibleSchedule">
    <dailymods v-if="payrollModDailyRosterData.length > 0 && fieldsObj.dailyModifications" :payAddativeTrackingArray="payAddativeTrackingArray" :selectedDate="selectedDate" :payrollCodesData="payrollCodesData" :payrollModDailyRosterData="payrollModDailyRosterData"    @updateSelectedDate="updateSelectedDate"  />
    </b-tab>
    <b-tab title="Modification Admin"  @click="updateVisible('modificationAdmin')" v-if="(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops')">
      <modificationAdmin v-if="fieldsObj.modificationAdmin" 
            :payrollCodesData="payrollCodesData"     
            :recoReportsData="recoReportsData"              
            :payrollBanksData="payrollBanksData" 
            :payrollPermsData="payrollPermsData"
            :payrollAccuralData="payrollAccuralData"/>
    </b-tab>
        <b-tab  @click="updateVisible('timeCardManager')" title="Timecard Manager" v-if="payrollPermsDataUsers && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops')">
      <timecardManager v-if="fieldsObj.timeCardManager" :payrollCodesData="payrollCodesData" :payrollPermsData="payrollPermsData"  :permsData="payrollPermsDataUsers"     
      :rosterUserProfiles="rosterUserProfiles" 
      :scheduleData="scheduleData"      
      :payrollHolidaysData="payrollHolidaysData"
      :holdState="holdState"  />
      </b-tab>
    <b-tab  @click="updateVisible('scheduledLeave')" title="Scheduled Leave" v-if="checkForDisableUser">
      <scheduledLeave v-if="fieldsObj.scheduledLeave" :payrollCodesData="payrollCodesData" :payrollPermsData="payrollPermsData" :calendarHeader="'Scheduled Leave'" :calendarType="'Scheduled Leave'"  />
      </b-tab>
          <b-tab  @click="updateVisible('scheduledLeave')" title="Overtime Calendar" v-if="checkForDisableUser && siteconfig.payroll.showOvertimeCalendar">
      <scheduledLeave v-if="fieldsObj.scheduledLeave" :payrollCodesData="payrollCodesData" :payrollPermsData="payrollPermsData" :calendarHeader="'Overtime Calendar'" :calendarType="'Overtime Calendar'"  />
      </b-tab>
      <b-tab  @click="updateVisible('fullCalendar')" title="Full Calendar" v-if="checkForDisableUser">
      <fullCalendar v-if="fieldsObj.fullCalendar" :payrollCodesData="payrollCodesData" :payrollPermsData="payrollPermsData" :calendarHeader="'Full Calendar'" :calendarType="'Full Calendar'"  />
      </b-tab>
      <b-tab title="List Reports" v-if="siteconfig.payroll.payrollLists" @click="updateVisible('listReports')">
        <listReports :payrollCodesData="payrollCodesData"  :selectedDate="selectedDate"  :payrollModDailyRosterData="payrollModDailyRosterData" @updateSelectedDate="updateSelectedDate"   />
      </b-tab>
    </b-tabs>
    </b-card>
    <b-card no-body class="d-md-none">
      <b-card-header header-tag="header" class="p-1" role="tab"  @click="updateVisible('employeeAccord')">
        <b-button block href="#" v-b-toggle.accordion-1 variant="primary" >Employee</b-button>
      </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body class="p-0">
            <employeeInfo
            v-if="!this.siteconfig.payroll.flexibleSchedule && fieldsObj.employeeAccord"
            :recoReportsData="recoReportsData"
            :payrollCodesData="payrollCodesData" 
            :payrollBanksData="payrollBanksData" 
            :payrollAccuralData="payrollAccuralData"
            :scheduleData="scheduleData"
            :payrollHolidaysData="payrollHolidaysData"
            :payrollPermsData="payrollPermsData"
                   :holdState="holdState"
            @userSelected="setUserSelected($event)" />
            <employeeInfo
            v-if="this.siteconfig.payroll.flexibleSchedule && fieldsObj.employeeAccord && scheduleData && scheduleData.length > 0"
            :recoReportsData="recoReportsData"
            :payrollCodesData="payrollCodesData" 
            :payrollBanksData="payrollBanksData" 
            :payrollAccuralData="payrollAccuralData"
            :scheduleData="scheduleData"
            :payrollHolidaysData="payrollHolidaysData"
            :payrollPermsData="payrollPermsData"
                   :holdState="holdState"
            @userSelected="setUserSelected($event)" />
        </b-card-body>
      </b-collapse>
    </b-card>
        <b-card no-body class="d-md-none" @click="consolelog">
      <b-card-header header-tag="header" class="p-1" role="tab" @click="updateVisible('dailyRosterAccord')">
        <b-button block href="#" v-b-toggle.accordion-2 variant="info" >Daily Roster</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body class="p-0">
               <dailyroster v-if="rosterData.length > 0 && rosterReload && !siteconfig.payroll.flexibleSchedule && fieldsObj.dailyRosterAccord" 
                 :payrollCodesData="payrollCodesData"
                 :rosterProfiles="rosterProfiles" 
                 :rosterData="rosterData" 
                 :scheduleData="scheduleData"
                 @updateSelectedDate="updateSelectedDate" 
                 :payrollModDailyRosterData="payrollModDailyRosterData" />
        </b-card-body>
      </b-collapse>
      
    </b-card>
   <b-card no-body class="d-md-none" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
      <b-card-header header-tag="header" class="p-1" role="tab"   @click="updateVisible('dailyModificationsAccord')">
        <b-button block href="#" v-b-toggle.accordion-3 variant="info" >Daily Modifications</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body class="p-0">
            <dailymods v-if="payrollModDailyRosterData.length > 0 && resetRoster && fieldsObj.dailyModificationsAccord" :payrollCodesData="payrollCodesData" :payrollModDailyRosterData="payrollModDailyRosterData" />
        </b-card-body>
      </b-collapse>
      
    </b-card>
       <b-card no-body class="d-md-none" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'" >
      <b-card-header header-tag="header" class="p-1" role="tab" @click="updateVisible('modificationAdminAccord')">
        <b-button block href="#" v-b-toggle.accordion-4 variant="info" >Modification Admin</b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
        <b-card-body class="p-0">
            <modificationAdmin  v-if="fieldsObj.modificationAdminAccord" :payrollCodesData="payrollCodesData"  :payrollPermsData="payrollPermsData"   :recoReportsData="recoReportsData"             :payrollBanksData="payrollBanksData" 
            :payrollAccuralData="payrollAccuralData" />
        </b-card-body>
      </b-collapse>
      
    </b-card> 
        <b-card no-body class="d-md-none" v-if="checkForDisableUser">
      <b-card-header header-tag="header" class="p-1" role="tab"  @click="updateVisible('scheduledLeaveAccord')">
        <b-button block href="#" v-b-toggle.accordion-5 variant="primary">Scheduled Leave</b-button>
      </b-card-header>
      <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
        <b-card-body class="p-0">
            <scheduledLeave v-if="fieldsObj.scheduledLeaveAccord" :payrollPermsData="payrollPermsData" :payrollCodesData="payrollCodesData" />
        </b-card-body>
      </b-collapse>
    </b-card>
     <b-card no-body class="d-md-none" v-if="checkForDisableUser && siteconfig.payroll.showOvertimeCalendar">
      <b-card-header header-tag="header" class="p-1" role="tab"  @click="updateVisible('overtimeScheduleLeave')">
        <b-button block href="#" v-b-toggle.accordion-6 variant="primary">Overtime Calendar</b-button>
      </b-card-header>
      <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
        <b-card-body class="p-0">
 <scheduledLeave v-if="fieldsObj.overtimeScheduleLeave" :payrollCodesData="payrollCodesData" :payrollPermsData="payrollPermsData" :calendarHeader="'Overtime Calendar'" :calendarType="'Overtime Calendar'"  />
        </b-card-body>
      </b-collapse>
    </b-card>
</b-container>
<b-modal v-if="showModal" v-model="showModal" size="lg" :hide-footer="true" body-class="m-0 p-0" @hide="modalHideEvent">
    <addNewPayrollMod :payrollCodesData="payrollCodesData" :previousMod="previousMod" :rosterData="rosterData" :payrollPermsData="payrollPermsData" />
</b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import axios from 'axios'
import moment from 'moment'
import adminStaffing from '../admin/adminStaffing.vue'
import addNewPayrollMod from '../addNew/newPayrollModification.vue'
import dailyroster from './staffing/dailyRosterNew.vue'
import dailymods from './staffing/dailyMods.vue'
import Multiselect from "vue-multiselect";
import employeeInfo from './staffing/employeeInfo.vue'
import modificationAdmin from './staffing/modificationAdmin.vue'
import scheduledLeave from './staffing/scheduledLeave.vue'
import fullCalendar from './staffing/fullCalendar.vue'
import listReports from './staffing/listReports.vue'
import dailyRosterCombined from './staffing/dailyRosterCombinedExport.vue'
import dailyRosterScheduled from './staffing/dailyRosterScheduled.vue'
import serverSideBuild from './staffing/serverSideBuild.vue'
import timecardManager from './staffing/timecardManager.vue'
import VueCookie from 'vue-cookies'
export default {
components: {
  dailyroster,
  dailymods,
  adminStaffing,
  addNewPayrollMod,
  modificationAdmin,
  'multiselect' : Multiselect,
  employeeInfo,
  scheduledLeave,
  fullCalendar,
  listReports,
  dailyRosterCombined,
  dailyRosterScheduled,
  serverSideBuild,
  timecardManager
},
props: ['tvView', 'stationVar'],
data() {
    return {
  slide: 0,
  verticalView: true,
  resetRoster: true,
  showAdmin: false,
  selectedDate: '',
  employeeTabActive: true,
  showCalendar: true,
  showModal: false,
  previousMod: null,
  rosterReload: true,
  userSelected: null,
  userViewToggle: false,
  checkActive: false,
  tabIndex: 0,
  fieldsObj: {employeeTab: true, dailyRoster: false, dailyCombined: false, dailyModifications: false, modificationAdmin: false, scheduledLeave: false, listReports: false, employeeAccord: false, dailyRosterAccord: false, modificationAdminAccord: false, scheduledLeaveAccord: false},
  events: [],
  rosterData: [],
  scheduleData: [],
  rosterProfiles: [],
  payrollCodesData: [],
  payrollBanksData: [],
  payrollHolidaysData: [],
  payrollAccuralData: [],
  payrollModDailyRosterData: [],
  userRosterProfileDaysWithMods: [],
  userMonthViewModsOrig: [],
  recoReportsData: [],
  userRosterProfileDays: [],
  rosterUserProfiles: [],
  payrollPermsData: null,
  fullScreenViewToggle: false,
  payAddativeTrackingArray: [],
  userMonthViewMods: [],
  holdState: null,
  calendarHeader: 'Employee Monthly Schedule',
  currentView: {}

}
},
created () {
  var width = document.body.clientWidth
  if(width && width < 769){
      this.updateVisible('employeeAccord')
  }
  this.selectedDate = moment().format('YYYY-MM-DD')
  window.addEventListener("resize", this.checkWidth)
  this.getRosterProfile()
  if(this.siteconfig.payroll.flexibleSchedule){
  this.getSchedule()
  }
  if(this.siteconfig.payroll.customPerms){
  this.getPayrollPerms()
  bus.$on('getPayrollPerms', this.getPayrollPerms)
  }
  this.getRoster()
  this.getPayrollCodes()
  this.getPayrollBanks()
  this.getAccuralTypes()
  this.getRecoReports()
  if(this.siteconfig.payroll.holidayTable){
    this.getHolidays()
  }
  var fullScreenView = this.$cookie.get('fullScreenView')
  // console.log('FULL SCREEN')
  // console.log(fullScreenView)
  if(fullScreenView && fullScreenView == "yes"){
    console.log(fullScreenView)
    this.updateFullScreen()
  }
  bus.$on('getRoster', this.getRoster)
  bus.$on('getSchedule', this.getSchedule)
  bus.$on('getHolidays', this.getHolidays)
  bus.$on('getRosterProfile', this.getRosterProfile)
  bus.$on('getPayrollCodes', this.getPayrollCodes)
  bus.$on('getPayrollBanks', this.getPayrollBanks)
  bus.$on('newPayrollModification', this.getNewModData)
  bus.$on('updatePayrollMod', this.updatePayrollMod)
  bus.$on('getPayrollAccurals', this.getAccuralTypes)
  bus.$on('showRosterData', this.showRosterData)
  bus.$on('payAddativeTracking', this.payAddativeTracking)
  bus.$on('holdState', this.holdStateUpdate)
  bus.$on('goToCalendar', this.showEmployeeCalendar)
},
beforeDestroy(){
  	window.removeEventListener("resize", this.checkWidth);
},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  checkForDisableUser(){
    if(this.siteconfig.payroll.flexibleSchedule && this.userData.role == 'user'){
      return false
    } return true
  },
  payrollPermsDataUsers(){
    var permsData = _.cloneDeep(this.payrollPermsData)
    if(this.userData.role == 'admin' && this.siteconfig.payroll.flexibleSchedule){
      return {payrollManageUsers: this.deptContactData.map(p => p.id)}
    }
    if(permsData){
    var userPerms = permsData.find(p => p.payrollUserId == this.userData.id)
    console.log(userPerms)
    if(userPerms && userPerms.payrollManageUsers && userPerms.payrollManageUsers.length > 0){
    return userPerms
    } return false
     } return false
  }

},
mounted(){

},
watch:{
  selectedDate(newData){
  //  console.log(newData)
    if(this.fieldsObj.dailyRoster || this.fieldsObj.dailyRosterAccord || this.fieldsObj.listReports){
     this.getPayrollModByDate()
    }
  }
},
methods: {
  showEmployeeCalendar(){
    this.employeeTabActive = false
    this.$nextTick(function(){
      this.employeeTabActive = true
    })
  },
  payAddativeTracking(payadd){
   // this.payAddativeTrackingArray.push(payadd)
    //return
    //console.log(payadd)
    if(payadd){
      var index = this.payAddativeTrackingArray.indexOf(payadd)
      if(index == -1){
        this.payAddativeTrackingArray.push(payadd)
      }
    }
   // console.log(this.payAddativeTrackingArray)
  },
  updateVisible(field){
    if(!this.fieldsObj[field]){
    this.fieldsObj[field] = true
    if(field == 'listReports'){
      this.fieldsObj.dailyCombined = true
    }
    if(field == 'dailyRoster' || field == 'dailyRosterAccord' || field == 'listReports'){
        this.getPayrollModByDate()
    }
    var keys = Object.keys(this.fieldsObj)
    for(var k in keys){
      if(keys[k] != field){
      //  this.fieldsObj[keys[k]] = false
      }
    }
    }
  },
  updateFullScreen(){
    var currentValue = this.fullScreenViewToggle
    if(currentValue){
        this.$cookie.set('fullScreenView', "no", { expires: '7D' })
        this.fullScreenViewToggle = false
    } else {
        this.$cookie.set('fullScreenView', "yes", { expires: '7D' })
        this.fullScreenViewToggle = true
    }
    bus.$emit('toggleFullScreenStaffing')
  },
  consolelog(){
    console.log('Click Registered')
  },
  setUserSelected(data){
    if(data && data.id != this.userData.id){
    this.userViewToggle = true
    this.userSelected = data
    } else {
      this.userViewToggle = false
      this.userSelected = null
    }
  },
  showRosterData(data){
    this.getPayrollModByDate(moment(data.date._d).format('YYYY-MM-DD'))
    this.tabIndex = 1
  },
  checkWidth(){
        var width = document.documentElement.clientWidth;
        if(width < 750){
          this.verticalView = false
        } else { this.verticalView = true }
  },
  getNewModData(){ 
    this.getPayrollModByDate()
   // this.getPayrollModByDateByUser()
  },
  updateSelectedDate(data){
    if(this.selectedDate != data){
        this.resetRoster = false
       console.log('RESET DATA')
        this.selectedDate = data
        this.$nextTick(function(){
          this.resetRoster = true
        })
    }
  },
  getRosterProfile(){
    axios({ 
     method: 'get',
     url: '/api/v1/staffing/rosterprofile/',
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.rosterProfiles =  response.data
   })
    },
    getPayrollModByDate(selectedDate){
    //  this.rosterReload = false
      this.previousMod = ''
      this.showModal = false
      if(!selectedDate){
      var start = moment(this.selectedDate + 'T' + this.siteconfig.payroll.shiftStartTime)
      } else {
        var start = moment(selectedDate + 'T' + this.siteconfig.payroll.shiftStartTime)
      }
      var nextShift = moment(start).add(1, 'day').toISOString()
      var hourstart = start.subtract(1, 'minute')
      if(this.siteconfig.payroll.flexibleSchedule){
        hourstart = hourstart.subtract(1, 'day')
      }
      hourstart = moment(hourstart).toISOString()
      var hourend = start.add(26, 'hours')
      if(this.siteconfig.payroll.flexibleSchedule){
        hourend = start.add(48, 'hours')
      }
      hourend = moment(hourend).toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/daily/' + hourstart + '/' + hourend,
     headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
  }
   }).then( response => {
      var array = response.data.filter(p => {
     if(p.payrollModDatetime && p.payrollModHours > 0 && p.payrollModHours != null){
       return moment(p.payrollModDatetime).isBefore(nextShift)
     }
     })
    this.payrollModDailyRosterData = array
    // this.rosterReload = true
   })

    },
    holdStateUpdate(data){
      console.log('HoldState')
      this.holdState = data
    },
    getRoster(data){
    axios({
     method: 'get',
     url: '/api/v1/staffing/roster/',
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache',

     }
   }).then( response => {
     this.rosterData = response.data
     if(data){
     bus.$emit('refeshRosterData', response.data)
     }
   })

    },
    getSchedule(){
    axios({
     method: 'get',
     url: '/api/v1/staffing/schedule/',
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache',

     }
   }).then( response => {
     this.scheduleData = response.data
     
   })

    },
    getPayrollPerms(){
    axios({
     method: 'get',
     url: '/api/v1/staffing/payrollperms/',
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache',

     }
   }).then( response => {
     this.payrollPermsData = response.data
     
   })

    },
  getHolidays(){
    axios({
     method: 'get',
     url: '/api/v1/staffing/holiday/',
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache',

     }
   }).then( response => {
     this.payrollHolidaysData = response.data
     
   })

    },
    updatePayrollMod(data){
      // console.log(data)
       if(data.id){
         this.previousMod = data
         console.log(data.id)
         this.showModal = true
       } else if(this.userViewToggle && this.userSelected && !data.rosterUpdateData){  //&& !data.payrollModTargetUserId){
         this.showModal = true
         var obj = data
         obj.payrollModTargetUserId = this.userSelected.id
        this.previousMod = obj
      } else if(data.rosterUpdateData){
        this.showModal = true
        // delete data.rosterupdate
        this.previousMod = data
      } else {
      this.showModal = true
   //   console.log(data)
      this.previousMod = data
      }
    },
    getPayrollBanks(){
        axios({
        method: 'get',
        url: '/api/v1/staffing/payrollbank',
        headers: {
              'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.payrollBanksData = _.orderBy(response.data, ['payrollBankName'], ['asc'])

      })
    },
    getAccuralTypes(){
        axios({
        method: 'get',
        url: '/api/v1/staffing/payrollaccural',
        headers: {
              'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.payrollAccuralData =  _.orderBy(response.data, ['payrollAccuralName'], ['asc'])

      })
    },
    getRecoReports(){
        axios({
          method: 'get',
          url: '/api/v1/staffing/payrollrecoreport',
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
          }
        }).then(response => {
          this.recoReportsData = response.data
        })
    },
    getPayrollCodes(){
        axios({
        method: 'get',
        url: '/api/v1/staffing/payrollcode',
        headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.payrollCodesData = response.data

      })
    },
    modalHideEvent(data){
     this.previousMod = ''
     this.showModal = false
    },


},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
