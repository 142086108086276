<template >
  <div >
    <h4>{{ updateData.title }}</h4>
   <b-textarea v-model="addNewControlNumbers" @update="fixDataReport" />
   <b-table :fields="medsFieldEdit" :items="currentMedications">
     <template slot="Remove" slot-scope="data">
       <b-btn variant="danger" size="sm" @click="removeItemFromList(data.item.id)">Remove</b-btn>
     </template>
   </b-table>
   <b-btn block @click="updateNarcoticsCard">Update Card</b-btn>
</div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { bus } from '../../../main'
import printJS from 'print-js'
import _ from 'lodash'
import { mapState } from 'vuex'
import JsonExcel from 'vue-json-excel'
import Multiselect from 'vue-multiselect'
import MedAdmin from '../adminMedication.vue'
import barChart from './apparatusChart.vue'
import PapaParse from 'papaparse'
export default {
  props: ["cardData", "medsNarctrack"],
  name: 'admineditcard',
  components: {
    'multiselect' : Multiselect,
    'medadmin' : MedAdmin,
    'download-excel' : JsonExcel,
    barChart
  },
  data () {
    return {
      updateData: '',
      currentMedications: [],
      addNewControlNumbers: '',
      medsFieldEdit: [
        'id',
        'createdAt',
        'medication',
        'controlNumber',
        'LotNumber',
        'expDate',
        'NdcNumber',
        'Remove'
      ],
    }
  },
created (){
  this.updateData = _.cloneDeep(this.cardData)
  this.getCurrentMeditcations(_.cloneDeep(this.cardData))
},
mounted(){
  
},
computed: {
  ...mapState([
      'apparatusData',
      'userData',
      'deptContactData',
      'getSessionData'
      ]),
  
 
},
watch:{
    
},
methods: {
  getCurrentMeditcations(data){
    console.log(data)
    if(data && data.narctrackMeds){
    var meds = data.narctrackMeds
    var medsMap = meds.map(item => {
        var obj = this.medsNarctrack.find(p => p.id == item)
        if(obj){
          return obj
        } else {
          return {id: item, medication: 'UNKNOWN'}
        }
       
    })
    medsMap = medsMap.filter(p => p)
    this.currentMedications = medsMap
    }
  },
  fixDataReport(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.addNewControlNumbers
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              vm.addListofItemsToCM(results.data)
            }
        })
      },
  addListofItemsToCM(data){
    console.log(data)
    console.log(this.medsNarctrack)
  if(data){
    var meds = data
    var medsMap = meds.map(item => {
        console.log(item.cn)
        var obj = this.medsNarctrack.find(p => Number.parseInt(p.controlNumber) == item.cn)
        return obj
    })
    for(var i in medsMap){
      if(medsMap[i] && medsMap[i].id){
        var index = this.currentMedications.map(p => p.id).indexOf(medsMap[i].id)
        if(index == -1){
          this.currentMedications.push(medsMap[i])
        }
      }
    }
    }

  },
  removeItemFromList(id){
    var index = this.currentMedications.map(p => p.id).indexOf(id)
    this.currentMedications.splice(index, 1)
  },
  updateNarcoticsCard(){
    var medsToPost = [... new Set(this.currentMedications.map(p => p.id))]
    console.log(medsToPost)
    axios({
        method: "patch",
        url: "/api/v1/content/dailynarctrack/" + this.updateData.id,
        data: {
          narctrackMeds: medsToPost
        },
        headers: {
          'Authorization': this.getSessionData,
          "Content-Type": "application/json"
        }
      }).then(response => {
        console.log(response.data)
      })
  },
 
 
}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style :src="'./customcss/vue-multiselect.min.css'"></style>
<style>
.hideNarcRow {
  display: none;
}
</style>
<style slot-scoped>
.floatRight {
  float: right;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
}
.apparatusNameClass{
  font-weight: bold;
  background: #fff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}
.narcRow {

  margin-top: 10px;
  padding-top: 5px;
  background: #eee;
  min-height: 50px;
}
.apparatusNameClass{
  font-weight: bold;
  background: #fff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}
.A-shift-app {
  background: red;
  color: #fff;
}
.B-shift-app {
  background: blue;
  color: #fff;
}
.C-shift-app {
  background: green;
  color: #fff;
}
.apparatusTitleBar {
  color: #fff;
}
.badge.Use.badge-secondary {
  background-color: #ffe007;
  color: #000;
}
.badge.Transfer.badge-secondary {
  background-color: green;
  color: #fff;
}
.badge.Distribution.badge-secondary {
  background-color: red;
    color: #fff;
}
.badge.Total.badge-secondary {
  background-color: #333;
    color: #fff;
}
.badge.Restock.badge-secondary {
  background-color: blue;
    color: #fff;
}

</style>
