<template>
<div id="homelanding">

  <div class="headerSpan">Maintenance  <span v-if="showAdmin">Admin</span>
    <span class="floatRight">
      <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || userData.role == 'shopops'" size="sm" @click="showAdmin = !showAdmin" :variant="checkState">Admin</b-btn>
      <b-btn v-if="!(userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || userData.role == 'shopops') && false" size="sm" @click="mainView = !mainView" :variant="checkState">Apparatus Status</b-btn>
    </span></div>
  <apparatusadmin v-if="showAdmin" />
  <b-container fluid v-if="!showAdmin" v-show="mainView" class="px-1 px-xl-4">
  <b-card no-body>
    <b-tabs card>
      <b-tab title="Maintence Schedule" class="px-1 px-xl-2">
<b-row><b-col b-col xl="7" class="px-1 px-xl-2 mx-md-0">
<calendar class="calendarClass" :all-events="events" :calendarHeader="calendarHeader" :displayWeekNumber="false" :calendarSmall="true" v-if="showCalendar"></calendar>
</b-col>
<b-col xl="5" class="px-1 px-xl-2">
<div class="tableHeaderMaintence"><h2 class="tableHeaderMaintenanceTitle">Apparatus Data</h2></div>
  <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Filter</b-navbar-brand>
        <b-form-input type="text" v-model="searchDataMain" placeholder="Name" />
        <b-navbar-brand class="bootstrapTable ml-2" >Type</b-navbar-brand>
        <b-form-select v-model="vehicleTypeSearchMain" :options="vehicleTypeSearchMainOpt" />
      </b-nav-form>
    </b-navbar>
<b-table v-if="renderedVehicleData.length > 0" outlined :fields="fields" :items="renderedVehicleData" stacked="md" >
  <template slot="name" slot-scope="data" >
    <span :id="'exPopover2-'+data.item.asset">{{ data.item.name }}</span>
          <b-popover  v-if="renderedVehicleData.length > 0" :target="'exPopover2-'+data.item.asset" placement="bottomleft" triggers="hover focus">
           <template slot="title">Apparatus Info</template>
             <strong>STA: </strong>{{ data.item.stationAssign }}
        </b-popover>
  </template>
  <template slot="vehicleChecks" slot-scope="data">
      <div v-if="data.item.vehicleChecks.length > 0" :id="'exPopover3-'+ data.item.asset"> {{ data.item.vehicleChecks[0].dailyvehicleHoursMiles }} </div>
      <b-popover v-if="data.item.vehicleChecks.length > 0" :target="'exPopover3-'+data.item.asset" placement="bottomleft" triggers="hover focus">
           <template slot="title">Recent Check Hrs/Miles</template>
           <div v-for="check in data.item.vehicleChecks" :key="check.index">
             {{ adjustDateCheck(check.dailyvehicleDate) }} | {{ check.dailyvehicleHoursMiles }}
             </div>
        </b-popover>
  </template> 
  <template slot="diff" slot-scope="data">
    <div v-if="data.item.vehicleChecks.length > 0" v-show="data.item.nextPm != ''"> {{ data.item.diff }}</div>
  </template>
  <template slot="repairForms" slot-scope="row">
    <b-btn size="sm" variant="primary" :class="{disabled: row.item.repairForms.length == 0}" @click.stop="row.toggleDetails"> View({{ row.item.repairForms.length }})</b-btn>
  </template>
    <template slot="row-details" slot-scope="row">
            <div>
      <b-row v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'shopops'"><b-col>
        <b-btn variant="success" size="sm" @click="hoursUpdateForm = !hoursUpdateForm">
          <span v-if="hoursUpdateForm == true">Switch to Print View</span><span v-if="hoursUpdateForm == false">Switch to Update PM View</span></b-btn></b-col></b-row>
          <br>
          <b-row v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'shopops'"><b-col> <b-textarea v-if="hoursUpdateForm == false" v-model="shopNotesForPrint" placeholder="Add a note to the print form" /></b-col>
          <b-col cols="auto"><b-btn v-show="hoursUpdateForm == false" variant="primary" size="sm" @click="printModalSet(row.item)">Print</b-btn></b-col></b-row>
       
       <div v-if="hoursUpdateForm == false">
         <br v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'shopops'">
         <h4 v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'shopops'"> Click on Repair forms to show in Print View </h4>
         </div>
         <br v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'shopops'">
       <div v-if="hoursUpdateForm == true">
         <h4> Enter New PM Hours/Miles for {{ row.item.name }}</h4>
         <b-row><b-col align="right"><b-form-input type="number" v-model="newPMSetting" /></b-col><b-col cols="auto"><b-btn variant="primary" @click="submitPmChanges(row.item.vehicleId, newPMSetting)">Submit Changes</b-btn></b-col></b-row>
        </div>
      <div v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'shopops'" v-show="hoursUpdateForm == false" :id="'pringJsForm' + row.item.vehicleId">
        <b-container fluid>
        <b-row><b-col class="boldText">Vehicle</b-col><b-col class="boldText">Asset</b-col><b-col class="boldText">Station</b-col></b-row>
        <b-row><b-col>{{ row.item.name }}</b-col><b-col>{{ row.item.asset }}</b-col><b-col>{{ row.item.stationAssign }}</b-col></b-row>
        </b-container>
        <b-card v-if="shopNotesForPrint != ''">
          <h3>Shop Notes</h3>
          {{ shopNotesForPrint }}
          </b-card>
      </div>
      <div v-if="row.item.repairForms.length > 0">
      <h6 v-if="row.item.repairData.length == 0">...Loading Repair Forms  {{ getRepairFormData(row) }}</h6>
        <repairdata v-if="row.item.repairData.length > 0" :stationRepair="row.item.repairData" />
      </div>
      </div>
  
  </template>
  </b-table>

</b-col>
</b-row>
      </b-tab>
      <b-tab title="Repair Tracking" v-if="userData.role == 'admin' || userData.role == 'shopops' || userData.role == 'fireops' || userData.role == 'adminchief'">
      <repairFormTrack />
      </b-tab>
      <b-tab title="Apparatus Status">
<b-container fluid>
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Apparatus</b-navbar-brand>
        <b-form-input class="bootstrapTable mr-sm-2" type="text" v-model="searchData" placeholder="Search"></b-form-input>

        <b-form-select class="ml-2 mt-0"  text-field="stationName" value-field="stationName" v-model="filterApparatusAssignment" :options="parentGroupings">
                      <option value="">Assigned</option>
        </b-form-select>
        <b-form-select class="ml-2 mt-0"  v-model="filterApparatusStatus">
                      <option value="">Status</option>
                      <option value="is">In Service</option>
                      <option value="res">In Reserve</option>
                      <option value="oss">Out of Service</option>
        </b-form-select>
      </b-nav-form>
    </b-navbar>
    <b-table class="bootstrapTable" :fields="filterFields" :items="filteredDepartmentApparatus">
      <template slot="apparatusStatus" slot-scope="data">
        {{ data.item.apparatusStatus ? data.item.apparatusStatus.toUpperCase() : '?'}}
      </template>
    </b-table>
  </b-container>
        </b-tab>
      </b-tabs>
      </b-card>
</b-container>

<printmodal v-if="showModal" :showModal="showModal" :apparatus="printApparatus" :repairData="printRepair" :printType="'apparatus'" @closeModal="showModal = !showModal" />

</div>
</template>

<script>
import Calendar from '../calendarFull/Calendar.vue'
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import axios from 'axios'
import moment from 'moment'
import repairData from '../tracking/repairs/stationrepairfilter.vue'
import ApparatusAdmin from '../admin/adminApparatus.vue'
import PrintModal from '../tracking/repairs/repairPrintModal.vue'
import repairFormTrack from '../tracking/repairs/repairFormTrack.vue'
import _ from 'lodash'
export default {
components: {
  'calendar' : Calendar,
  'repairdata' : repairData,
  'apparatusadmin' : ApparatusAdmin,
  'printmodal' : PrintModal,
  repairFormTrack
},
data() {
    return {
  slide: 0,
  sliding: null,
  mainView: true,
  showAdmin: false,
  dataTrimmed: true,
  dataRange: 2500,
  missingDataPm: 1000,
  hoursUpdateForm: false,
  events: [],
  printRepair: [],
  printApparatus: '',
  showModal: false,
  showCalendar: true,
  newPMSetting: '',
  calendarHeader: 'Maintenance Calendar',
  showDevModal: false,
  betaTestData: [],
  shopNotesForPrint: '',
  recentVehicleChecks: [],
  recentRepairCheck: [],
  filterApparatusAssignment: '',
  filterApparatusStatus: 'res',
  searchData: '',
  searchDataMain: '',
  vehicleTypeSearchMain: '',
  inventorySubsData: [],
  vehicleTypeSearchMainOpt: [
    {text: 'Any', value: ''},
    {text: 'Miles', value: 'Miles'},
    {text: 'Hours', value: 'Hours'}
  ],
  dataCount: 0,
  filterFields: [
    { key: 'apparatusName', label: 'Vehicle' },
    { key: 'apparatusRadioId', label: 'Radio' },
    { key: 'apparatusParent', label: 'Assigned'},
    { key: 'apparatusLocation', label: 'Station'},
    { key: 'apparatusVehicleType', label: 'Veh Type'},
    {key: 'apparatusStatus', label: 'Status'},
    { key: 'apparatusNextPm', label: 'PmHrs/Mis'}
  ],
  fields:[
    { key: 'name', sortable: true },
    'asset',
    'nextPm',
    { key: 'vehicleChecks', label: 'Current'},
    { key: 'diff',  sortable: true, },
    { key: 'repairForms', label: 'Repairs', sortable: true}
  ],
  renderedVehicleDataHold: [],
}
},
created () {
    bus.$on('closeDev', this.showDev)
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
    var payload = { taxonomy: 'apparatus', expire: true, headers: headers, }
    this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
    var headers2 = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
    var payload2 = { taxonomy: 'station', expire: true, headers: headers2, }
    this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload2)

    
},
watch: {
  fullCalendarEvents(newData){
    if(newData != undefined && newData != ''){
    this.filterTrainingData(newData) }
  },
  dataCount(newData){
    if(newData > 2){
      this.buildNewArray()
    } else {
      return
    }
    
  },
  apparatusData(newData){
    this.buildNewArray()
  },
  shopUpdate(newData){
    this.buildNewArray()
  },
},
computed: {
  ...mapState([
    'apparatusData',
    'fullCalendarEvents',
    'getSessionData',
    'userData',
    'eventTags',
    'stationsData'
  ]),
  renderedVehicleData(){
    var array = this.renderedVehicleDataHold.filter(p => {
      return p.name.toLowerCase().match(this.searchDataMain.toLowerCase()) &&
            p.pmType.match(this.vehicleTypeSearchMain)
            
            
    })
    //console.log(array)
    return array
  },
  checkState(){
    if(this.showAdmin == true){
      return 'light'
    } else {
      return 'outline-light'
    }
  },
  parentGroupings(){
      var parentGroups = this.stationsData.filter( p => {
      return p.stationParent == '' || p.stationParent == undefined
    })
      return parentGroups
  },
  filteredDepartmentApparatus(){
          var array = this.apparatusData.filter( (p) => {
            return p.apparatusName.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusRadioId.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusLocation.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusParent.toLowerCase().match(this.searchData.toLowerCase())
                      || p.apparatusVehicleType.toLowerCase().match(this.searchData.toLowerCase())

          })
          if(this.filterApparatusAssignment){
            var assign = array.filter(p => p.apparatusParent == this.filterApparatusAssignment)
          } else {
            var assign = array
          } 
          if(this.filterApparatusStatus){
            return assign.filter(p => p.apparatusStatus == this.filterApparatusStatus)
          } else {
            return assign
          }
        },

},
mounted(){
    this.getInventorySubs();   
    this.getRecentVehicleCheckData();
    this.getRecentRepairTrackingData();

},
methods: { 
   printModalSet(data){
    this.printRepair = []
    this.printApparatus = ''
    this.printApparatus = data.vehicleId
    if(data.repairData.length > 0){
    this.printRepair = data.repairData
    }
    this.showModal = true
    },
    executePrintJS(data){
    var printId = 'pringJsForm' + data
    var string = 'Vehicle Maintenance Report |' + moment().format('MM/DD/YYYY - HH:mm')
    return printJS({ printable: printId, type: 'html', header: string})
  },
  adjustDateCheck(data){
    return moment(data).format('MM/DD/YYYY')
  },
  buildNewArray(){
    var newDataMap = this.apparatusData.map( p => Object.assign({
              name: p.apparatusName,
              nextPm: p.apparatusNextPm,
              asset: p.apparatusAssetNumber,
              stationAssign: p.apparatusLocation,
              vehicleId: p.id,
              vehicleChecks: this.filteredVehicleChecks(p.id),
              repairForms: this.filteredRepairForms(p.id),
              repairData: [],
              pmType: p.apparatusPMCheckType ? p.apparatusPMCheckType : ''
    }))
    newDataMap.forEach( item => {
          if(item.vehicleChecks.length > 0){
          var arrayData = this.returnTotalData(item)
            item['diff'] = arrayData
          } else {
            item['diff'] = 100000
          }})
    this.renderedVehicleDataHold = newDataMap

  },
  getInventorySubs(){
    axios({ 
     method: 'get',
     url: '/api/v1/content/inventorysub/quick',
     headers: {
            'content-type': 'application/json', 
            'Authorization': this.getSessionData,  
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
     }
   }).then( response => {
     this.inventorySubsData = response.data
           this.dataCount++
   })
    },
  filteredVehicleChecks(name){
    var vehicle = this.apparatusData.find(p => p.id == name)
    var checks = this.recentVehicleChecks.filter( p => {
           return p.dailyvehicleApparatusDbid == name
    })
    var inventory = this.inventorySubsData.filter(p => {
          return p.inventorySubDbid == name && p.inventoryHeadData && (p.inventoryHeadData.currentHoursMiles || p.inventoryHeadData.currentHoursBoth || p.inventoryHeadData.currehtMilesBoth)
    })
    inventory = inventory.map(item => {
          var obj = item
          obj['dailyvehicleHoursMiles'] = item.inventoryHeadData.currentHoursMiles ? Number.parseInt(item.inventoryHeadData.currentHoursMiles) :
                                          vehicle && vehicle.apparatusPMCheckType == 'Hours' && item.inventoryHeadData.currentHoursBoth ? Number.parseInt(item.inventoryHeadData.currentHoursBoth) :
                                           vehicle && vehicle.apparatusPMCheckType == 'Miles' && item.inventoryHeadData.currehtMilesBoth ? Number.parseInt(item.inventoryHeadData.currehtMilesBoth) : 0
          obj['dailyvehicleDate'] = item.inventorySubDate
          return obj
    })
    var hold = checks.concat(inventory)
    var dataSort = _.orderBy(hold, ['dailyvehicleDate'],['desc'])
    return dataSort.splice(0, 10)
  },
    filteredRepairForms(name){
    var checks = this.recentRepairCheck.filter( p => {
            if(p.repairformDbid != undefined){
           return p.repairformDbid == name
            }
    })
    return checks
  },
  returnTotalData(data){
      if(data.nextPm != undefined && data.nextPm != ''){
      var int = data.nextPm
       var int2 = data.vehicleChecks[0].dailyvehicleHoursMiles
       return int - int2 } else {
         return 100000
       }
  },
  getRecentVehicleCheckData(){
    var token = this.getSessionData
    var headers = { 'content-type': 'application/json', 
                    'Authorization': token,  
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} 
          axios({
            method: 'get',
            url: '/api/v1/content/dailyvehicle/maintenance',
            data: {}, 
            headers: headers
            }).then( response => {
      this.recentVehicleChecks = response.data
      this.dataCount++
    })
    

  },
    getRecentRepairTrackingData(){
      var token = 'bearer ' + this.$cookie.get('userToken') 
        if(token == 'bearer null'){
      var token = this.getSessionData
    }
		var headers = { headers: { 'content-type': 'application/json', 'Authorization': token,  'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
    axios.get('/api/v1/content/repairform', headers).then( response => {
      this.recentRepairCheck = response.data.filter(p => {
        return p.repairformStatus != 'Resolved' && p.repairformStatus != 'Replaced'
      })
      this.dataCount++
    })
  },
  getRepairFormData(data){
    var indexOfRow = this.renderedVehicleData.indexOf(data.item)
    var repairIds = [... new Set(data.item.repairForms.map( p => p.id))]
														var headers = { headers: { 
                      'content-type': 'application/json', 
                      'Authorization': this.getSessionData,  
                      'Cache-Control': 'no-cache',
                      'Pragma': 'no-cache',
                      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'} }
    for(var i = 0; i < repairIds.length; i++){
    var url = '/api/v1/content/repairform/' + repairIds[i]
    axios.get(url, headers).then( response => {
      this.renderedVehicleData[indexOfRow].repairData.push(response.data)
    })
    }
  },
    submitPmChanges(vehId, newPm){
     axios({
      method: 'patch',
      url: '/api/v1/taxonomy/apparatus/' + vehId,
      data: {
          
                "apparatusNextPm": newPm,
        },
      headers: {
       'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
      var headers = { headers: { "content-type": "application/json", 'Authorization': this.getSessionData, }}
      var payload = { taxonomy: 'APPARATUS', expire: true, headers: headers}
       this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
       this.newPMSetting = ''
       this.hoursUpdateForm = false
/*       this.renderedVehicleData = []
       this.dataCount = 0
       this.$nextTick(function(){
       this.getRecentVehicleCheckData()
       this.getRecentRepairTrackingData()
       })
 */
         
       })
     

      
    
  },
  showDev(event){
    this.showDevModal = !this.showDevModal
  },
  filterTrainingData(data){
    var filteredData = data.filter( (p) => {
      return p.eventTagParent.match('Maintenance')
    })
    if(filteredData != undefined){
    this.setNewEvents(filteredData) }
  },
  setNewEvents(data){
    var map = new Map
    var newArr = [];
    for(var i = 0; i < data.length; i ++ ){
      var obj = data[i];
      var day = moment(data[i].eventDate).format('YYYY-MM-DD')
      if(map[day] === undefined || map[obj.eventTagParent] === null){
      map[day] = [];
      map[day].push(obj.eventTagParent);
      newArr.push({ day: day, tag: obj.eventTagParent });
  }else if(!map[day].includes(obj.eventTagParent)){
      map[day].push(obj.eventTagParent);
      newArr.push({ day: day, tag: obj.eventTagParent });
    }
  }
  var filteredCalEve = []
  for(var i = 0; i < newArr.length; i ++ ){
    var descriptionBody = [...new Set(data.filter( p => {
      return moment(p.eventDate).format('YYYY-MM-DD').match(newArr[i].day)
    }))]
    var descriptionTag = [...new Set(descriptionBody.filter(p => {
      return p.eventTagParent.includes(newArr[i].tag)
    }))]
    var newEvent = {
        id:i+1,
        title: newArr[i].tag,
        description: descriptionTag,
        color: this.getEventTagsVariant(newArr[i].tag),
        date: newArr[i].day,
    }
    filteredCalEve.push(newEvent)
  }
  return this.events = filteredCalEve
  },
    getEventTagsVariant(data){
    var obj = this.eventTags.find(p => p.eventtypeName == data)
    if(obj.eventVariantColor){
      return obj.eventVariantColor
    } else {
      return 'default'
    }
  },
  onSlideStart(slide) {
      this.sliding = true;
  },
  onSlideEnd(slide) {
      this.sliding = false;
  }
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.floatRight {
  float: right;
}
.tableHeaderMaintence {
  margin-top: 10px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-radius: 5px 5px 0 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border: 1px solid rgba(0, 0, 0, 0.125)
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
