<template>
  <b-container fluid>
    <b-row>
      <b-col lg="9">
      <b-card header-tag="header">
          <h4 slot="header">Form Data Model <b-btn class="px-1 py-0" title="More Info" variant="outline-secondary" size="sm" @click="showFormInfo = !showFormInfo">&#9432;</b-btn>
          <slot></slot>
          </h4>
          <b-container v-if="showFormInfo">
           <em><strong>Groups:</strong> provide an easy arrangment of inputs. All forms require atleast one group.<br>
           <strong>Inputs:</strong> choose the appopriate input for the data you wish to gather. Each type of input contains different options settings</em>
            </b-container>
        <b-row><b-col>
      <b-form-input placeholder="Form Group Name" v-model="formGroupName" />
      </b-col><b-col cols="*">
      <b-btn class="mr-2" :disabled="formGroupName == ''" variant="primary" @click="addNewComponent(formGroupName)">New Form Group</b-btn>
      <b-btn class="mr-2" :disabled="formtempData.length <= 0" variant="info" @click="showPreviousComponents = !showPreviousComponents">Prev Form Group</b-btn> 
      </b-col></b-row>
      <b-card v-show="showPreviousComponents" no-body header-tag="header">
                  <b-container slot="header"><b-row><b-col>
                  <h5>Groups from Previously Created Forms</h5></b-col><b-col cols="*">
                    <b-btn :disabled="addPreviousData == ''" variant="success" size="sm" @click="addPreviousFormGroups(addPreviousData)">Add Group</b-btn></b-col></b-row></b-container>
                  <b-container>
                      <b-row >
                  <b-col>
                    <h6>Filter by Forms</h6>
                      <div>
                        <b-form-select :select-size="previousForms.length" v-model="addPreviousForm" :options="previousForms" class="mb-3">
                        </b-form-select>
                      </div>
                  </b-col>
                  <b-col><h6>Available Groups</h6>
                      <div>
                        <b-form-select v-if="previousForms != ''" :select-size="previousFields.length == 1 ? 2 : previousFields.length" v-model="addPreviousData" :options="previousFields" class="mb-3">
                        </b-form-select>
                      </div>
                  </b-col>
                </b-row>
                  </b-container>
                </b-card>
      </b-card>
          <draggable v-model="newComponentData" :options="{ disabled: checkIfPropDisabled }"  @start="drag=true" @end="drag=false, emitNewArrayData(newComponentData)">
      <b-card class="mt-2" v-for="(formcomp, index) in componentDataReturned" :key="index" :no-body="formcomp.groupMin" bg-variant="light" header-tag="header">
           <b-container slot="header">
                   <b-row>    
                     <b-col alight="left"><span class="font-weight-bold pt-0">{{ formcomp.groupLabel }}</span></b-col>
                     <b-col cols="*" align="right" class="m-0 mr-1">
                  <b-btn v-show="editGroupId == formcomp.id" variant="outline-primary" title="Save" size="sm" @click="editGroupId = ''"> &#10004;</b-btn>
                  <b-btn v-show="editGroupId != formcomp.id" variant="outline-secondary" title="Edit" size="sm" @click="editGroupId = formcomp.id, formcomp.groupMin = false">	&#x270e;</b-btn>
                  <b-btn variant="outline-info" :disabled="formcomp.groupComponents.length <= 1" title="Visibility" size="sm" @click="formcomp.visibility = true">	&#x1f441;</b-btn>
                  <b-btn variant="outline-success" title="Minimize" size="sm" @click="formcomp.groupMin = !formcomp.groupMin">&#128469;</b-btn>
                  <b-btn variant="outline-primary" title="Duplicate" size="sm">&#10064;</b-btn>
                  <b-btn variant="outline-danger" :disabled="false" size="sm" @click.stop="componentData.splice(index, 1)">X</b-btn>
                    </b-col>
                   </b-row>
                   </b-container>
         <b-form-group 
                breakpoint="lg"
                label-size="md"
     
                class="mb-0">
              
                <div v-show="editGroupId == formcomp.id"><b-card no-body class="px-3 pt-2 pb-1 mx-4" bg-variant="light" border-variant="secondary">
                <b-form-input class="mb-2" placeholder="Enter the form question or item, and select the type" v-model="formQuestion" />
                <b-container v-show="formQuestion != ''">
                <b-dropdown text="Text" variant="outline-primary" :disabled="formQuestion == ''">
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'text', formQuestion)">Text Input</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'textarea', formQuestion)">TextArea</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'number', formQuestion)">Number</b-dropdown-item-button>
                </b-dropdown>
                <b-btn variant="outline-info" :disabled="formQuestion == ''" @click="addNewSubComponent(index, 'radio', formQuestion)" >Radio</b-btn>
                <b-btn variant="outline-success" :disabled="formQuestion == ''"  @click="addNewSubComponent(index, 'checkbox', formQuestion)">CheckBox</b-btn>
                <b-btn variant="outline-primary" :disabled="formQuestion == ''"  @click="addNewSubComponent(index, 'multiSelect', formQuestion)">MultiSelect</b-btn>
                <b-btn variant="outline-info" :disabled="formQuestion == ''"  @click="addNewSubComponent(index, 'singleSelect', formQuestion)">SingleSelect</b-btn>
                <b-dropdown :disabled="formQuestion == ''" text="Date/Time" variant="outline-primary">
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'date', formQuestion)">Date Only</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'dateTime', formQuestion)">Date & Time</b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown :disabled="formQuestion == ''" text="Advanced" variant="outline-primary">
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'stationsData', formQuestion)">Stations</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'apparatusData', formQuestion)">Apparatus</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addNewSubComponent(index, 'deptContactData', formQuestion)">User</b-dropdown-item-button>
                </b-dropdown>
                </b-container>
                </b-card>
                </div>
                <b-row class="my-2">
         
            <formsubedit class="mt-2" v-for="(formsub, indexSub) in formcomp.groupComponents" @updateChangesToSubs="updateChangesToSubs($event)" :formcomp="formcomp" :tableCount="tabledFields.length" :indexCount="indexedFields.length" :editGroupId="editGroupId" :key="indexSub" :formsub="formsub" :index="index" :indexSub="indexSub" />

             </b-row>
         </b-form-group>
      </b-card>
          </draggable>
      </b-col>
      <b-col lg="3">
          <b-card header-tag="header" border-variant="danger">
          <h4 slot="header">Indexed Fields <b-btn class="px-1 py-0" title="More Info" variant="outline-secondary" size="sm" @click="showIndexedFields = !showIndexedFields">&#9432;</b-btn></h4>
          <b-container v-if="showIndexedFields">
           <em><strong>Indexed Fields:</strong> fields that carry importance to the over data that is being captured. These fields are restricted to certain input types, 
           and should be set to provide easy form searching. ie. Index by Employee, or Index by Completion Date. Max of 6 fields.
           </em>
            </b-container>
            <b-card no-body class="p-0 mt-1" v-for="(field, index) in indexedFields" :key="index">
              <div class="m-1" :title="field.group">{{ field.item }}</div>
              </b-card>
            </b-card>
         <b-card header-tag="header" class="mt-2" border-variant="info">
          <h4 slot="header">Tabled Fields <b-btn class="px-1 py-0" title="More Info" variant="outline-secondary" @click="showTabledFields = !showTabledFields">&#9432;</b-btn></h4>
          <b-container v-if="showTabledFields">
           <em><strong>Tabled Fields:</strong> fields that should be displayed when viewing multiple forms in a table. These fields are restricted to non-text input types,
           and can be previewed in the "preview table" panel after saving a "preview form". Max of 8</em>
            </b-container>
            <b-card no-body class="p-0 mt-1" v-for="(field, index) in tabledFields" :key="index">
              <div class="m-1" :title="field.group">{{ field.item }}</div>
              </b-card>
            </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/**               <div  v-for="(formsub, indexSub) in formcomp.groupComponents" :key="indexSub">
             <formsub :formsubdata="formsub" :indexSub="indexSub" :index="index" 
             @duplicate="duplicateItem($event)" />
             </div> */
import { mapState } from 'vuex'
import formSubsEdit from './formsubedit.vue'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import Draggable from 'vuedraggable'
export default {
props: ['componentData'],
components: {
      'formsubedit' : formSubsEdit,
        'multiselect' : Multiselect,
        'draggable' : Draggable
},
data() {
    return {
      formGroupName: '',
      formQuestion: '',
      editGroupId: '',
      showFormInfo: false,
      showIndexedFields: false,
      showTabledFields: false,
      sizeSelectOptions: [
        { text: '100%', value: 12},
        { text: '75%', value: 9},
        { text: '50%', value: 6},
        { text: '25%', value: 3},
      ],
      postData: [],
      tableData: [],
      savedFormData: [],
      indexedFields: [],
      tabledFields: [],
      textData: [],
      tableDataFields: [],
      addPreviousData: '',
      addPreviousForm: '',
      changesToSubs: false,
      showPreviousComponents: false,
      visSelectOpt: [
        { text: "Equals", value: '=='},
        { text: "Not Equal", value: '!='},
        { text: "Not Blank", value: 'notBlank' },
        { text: "Is Blank", value: 'blank'},
        { text: "Contains", value: 'contains'}
      ],
      showPostDataArray: false,
      showComponentArray: false,
      newComponentData: [],
}
},
created () {
  this.newComponentData = this.componentData
},
beforeDestroy(){
  
},
watch: {

},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData',
    'stationsData',
    'apparatusData',
    'deptContactData',
    'formtempData'
  ]),
  componentDataReturned(){
    this.setFormIndexAndTabled(this.newComponentData)
    return this.newComponentData
  },
  checkIfPropDisabled(){
    var boo = this.newComponentData.map(p => p.groupMin)
    var index = boo.indexOf(false)
    if(index < 0){
      return false
    } else {
    return true
    }
  },
  previousForms(){
    if(this.formtempData.length > 0){
      var comp = this.formtempData
      var arrayComp = []
      for(var i = 0; i < comp.length; i++){
            arrayComp.push({text: comp[i].formtempName, value: comp[i].id})
      }
      return arrayComp
    } else {
      return []
    }
  },
  previousFields(){
    if(this.formtempData.length > 0){
      var filterArray = this.formtempData.filter(p => {
        return p.id.match(this.addPreviousForm)
      })
      var comp = filterArray.map(p => p.formtempComponentData)
      var arrayComp = []
      for(var i = 0; i < comp.length; i++){
          var objs = comp[i]
          for(var p = 0; p < objs.length; p++){
            var index = this.componentData.map(p => p.id).indexOf(objs[p].id)
            if(index < 0){
            var dis = false
            } else {
              var dis = true
            }
            arrayComp.push({text: objs[p].groupLabel, value: objs[p], disabled: dis})
          }
      }
      return arrayComp
    } else {
      return []
    }

  },

},
methods: {
  emitNewArrayData(data){
    this.$emit('updateArrayChanges', data)
  },
  updateChangesToSubs(data){
    this.$emit('updateEvalChanges', data)
  },
  setEditGroupId(data){
    console.log("EMITTED")
    console.log(data)
    this.editGroupId = data
  },
  setFormIndexAndTabled(data){
    var indexData = []
    var tableData = []
    var item = data
    for(var i = 0; i < item.length; i++){
       var comp = item[i].groupComponents
      for(var p = 0; p < comp.length; p++){
      if(comp[p].tableView != undefined && comp[p].tableView == true){
           tableData.push({item: comp[p].label, group: item[i].groupLabel, type: comp[p].type})
          
        } 
      if(comp[p].indexValue != undefined && comp[p].indexValue == true){
         indexData.push({item: comp[p].label, group: item[i].groupLabel, type: comp[p].type})
        } 
      }
    }
    this.indexedFields = indexData
    this.tabledFields = tableData
    
  },
  variantTypeCheck(boo){
    if(boo == true){
      return 'danger'
    } else {
      return ''
    }
  },
  addNewComponent(name){
    var id = name.replace(/ /g,'-')
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    var newComponent = { 
                id: id,
                groupLabel: name,
                groupMin: false,
                groupCompleted: false,
                groupVisibilityOpt: [],
                groupComponents: []   
                    }
     this.componentData.push(newComponent)
     if(boo == true){
       var ids = this.componentData.map(p => p.id)
       var index = ids.indexOf(newComponent.id)
       this.addNewSubComponent(index, type, newComponent.groupLabel)
     }
     this.editGroupId = id
    },
  addNewSubComponent(index, inputType, formQuestion){
    var array = this.componentData[index].groupComponents
    if(inputType == 'textarea'){
      var value = '3'
    } else {
      var value = ''
    }
    if(inputType == 'multiSelect'){
      var valueType = []
    } else if (inputType == 'dateTime' ) {
      var valueType = {data: '', time: ''}
     }
    else {
      var valueType = ''
    }
    if(inputType == 'stationsData' || inputType == 'apparatusData' || inputType == 'deptContactData'){
      var option = [{multiselect: true, filter: []}]
      } else {
        var option = [{ text: '', value: value, disabled: false }]
      }
    var id = formQuestion.replace(/ /g,'-')
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    var newArrayItem = { 
                id: id,
                label: formQuestion,
                labelPosition: 12,
                type: inputType, //Radio, input, checkbox, multiselect, singleselect, textarea, 
                value: valueType,
                options: option,
                visibility: true,
                visibilityOpt: [],
                visibilitySettings: [],
                visibilityEdit: false,
                filterOpt: [],
                refBy: [],
                edit: true,    
                        }
    array.push(newArrayItem)
    this.componentData[index].groupComponents = array
    this.formQuestion = ''
  },
    duplicateItem(index, obj){
    var newArrayItem = _.cloneDeep(obj)
    newArrayItem.edit = true
    var id = newArrayItem.id
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    newArrayItem.id = id
      this.componentData[index].groupComponents.push(newArrayItem)
  },
  addPreviousFormGroups(obj){
    var newArrayItem = _.cloneDeep(obj)
      this.componentData.push(obj)
      this.addPreviousData = ''
  }
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
