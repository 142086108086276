<template>
  <div>
    <h4>Vacation Calendar</h4>
<b-container fluid>
  <b-tabs>
    <b-tab v-for="(month, index) in monthsNumArray" :title="getMonthName(month)" :key="index" >
      {{ slotNames }}
     <b-table responsive :items="filteredDates(month)">
       <template v-for="(slot, index) in slotNames"  :slot="slot" slot-scope="data">
          <b-btn size="sm" variant="primary" v-if="data.value == 'Open'" @click="addVacationSlotData(data.item.date, slot)" :key="index" >Add</b-btn>

          
        </template>
        </b-table>
    </b-tab>
  </b-tabs>
</b-container>
<b-modal v-model="showModal" title="Add New Vacation Day">
       <h4> Date: {{ selectedDate }} | {{ selectedSlot }} </h4>
         <b-row><b-col cols="5" align="right"><h5 class="formLabels">User:</h5></b-col><b-col>
        <multiselect v-model="vacationUser" :options="deptContactData" label="name" track-by="name" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="User Select">
                    <template slot="option" slot-scope="props">
                      <span class="option__title">{{ props.option.field_first_name }} {{ props.option.field_last_name }} ({{ props.option.field_employee_id_number }})</span>
                    </template>
                  </multiselect>
         </b-col></b-row>
      <b-row><b-col cols="5" align="right"><h5 class="formLabels">Start:</h5></b-col><b-col>
        <b-form-input type="time" v-model="startTime" />
        </b-col></b-row>
        <b-row><b-col cols="5" align="right"><h5 class="formLabels">End:</h5></b-col><b-col>
        <b-form-input type="time" v-model="endTime" />
         </b-col></b-row>
        <b-row><b-col cols="5" align="right"><h5 class="formLabels">Round:</h5></b-col><b-col>
        <b-form-select :options="selectionRounds" v-model="roundSelected" />
        </b-col></b-row>
        <b-row><b-col>
          <b-form-checkbox id="checkbox1"
                     v-model="shiftFullStatus"
                     value="True"
                     unchecked-value="False">
      Full Shift or Locked Slot?
    </b-form-checkbox>
    </b-col>
        </b-row>
  </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
export default {
props:[],
components:{
  'multiselect' : Multiselect,
},
  data () {
    return {
      monthsNumArray: [1,2,3,4,5,6,7,8,9,10,11,12],
      year: '',
      slotAmount: 14,
      slotNames: [],
      protectedSlot: 1,
      vacationData: [],
      selectedDate: '',
      selectedSlot: '',
      showModal: false,
      startTime: '08:00',
      vacationUser: '',
      endTime: '08:00',
      roundSelected: 'Other',
      shiftFullStatus: 'True',
      selectionRounds:[
        { text: 'Round 1', value: 'Round1'},
        { text: 'Round 2', value: 'Round2'},
        { text: 'Round 3', value: 'Round3'},
        { text: 'Other', value: 'Other'},
      ]

    }
  },
  created(){
    this.year = moment().year()
    this.getVacationData()
  },
  computed:{
    ...mapState([
      'getSessionData',
      'deptContactData',
    ]),
    months(){
      return moment.duration().months() 
    }
  
  },
   methods:{
     addVacationSlotData(data, slot){
       this.selectedDate = data
       this.selectedSlot = slot
       this.showModal = true
     },
  getVacationData(){
    var headers = { "content-type": "application/json", 'X-CSRF-TOKEN': this.getSessionData, }
    var url = 'https://firerescuedev.com/drupalBack/web/reports/vacation_days_api?_format=hal_json'
    axios.get(url, headers).then( response => {
      this.vacationData = response.data
  })
  
  },
  filteredDates(months){
    var dateObj = this.getSlotsArray(months)
    return dateObj.filter( (p) => {
      return p.Day.match('')
    })
  },
   getMonthName(data){
     return moment(data, 'MM').format('MMMM')
   },
  getDaysArrayByMonth(data) {
  var month = moment(data, 'MM').format('MM')
  var daysInMonth = moment(month).daysInMonth();
  var arrDays = [];
  for(var i = 1; i <= daysInMonth; i++){
    var day = i
    var year = this.year
    var sting = year + '-' + month + '-' + i
    var date = moment(sting, 'YYYY-MM-DD').format('MM/DD/YYYY')
    arrDays.push(date)
  }
  return arrDays
  },
  filterVacationData(data){
    return this.vacationData.filter( (p) => {
      var vacaMonth = moment(p.field_vacation_day, 'YYYY-MM-DD').format('MM')
      return vacaMonth.match(data)
    })
  },
  getSlotsArray(data){
  var arrDays = this.getDaysArrayByMonth(data)
  var arrVaca = this.filterVacationData(data)
  var newObjArray = []
  var slotAmount = this.slotAmount
  var protectedSlot = this.protectedSlot
  for(var i = 0; i < arrDays.length; i++){
    var vacaDaysByDay = arrVaca.filter( (d) => {
        var vacaMonth = moment(d.field_vacation_day, 'YYYY-MM-DD').format('MM/DD/YYYY')
        return vacaMonth.match(arrDays[i])
    })
    var intialStrng = { date: arrDays[i] }
    var dayOfWeek = moment(arrDays[i]).format('ddd')
    intialStrng['Day'] = dayOfWeek
    for(var s = 0; s < slotAmount; s++){
    var slotString = 'Slot' + (s + 1)
    if(i == 0 && this.slotNames.length < slotAmount){
      this.slotNames.push(slotString)
    }
    var vacaSlotData = vacaDaysByDay.filter( (p) => {
      return p.field_slot_name.match(slotString)
    })
    if(vacaSlotData.length > 0){
      intialStrng[slotString] = vacaSlotData
    } else 
    intialStrng[slotString] = 'Open' 
    }
    for(var p = 0; p < protectedSlot; p++){
    var slotString = 'QA' + (p + 1)
    intialStrng[slotString] = 'Open'     
    } 
    newObjArray.push(intialStrng)
  }
  return newObjArray
}

  },


}
</script>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.videoIframe{
  width: 100%;
  height: 300px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-size: 18px;
  font-style: italic;
  color: #666;
}
h6.blogSubData {
  font-size: 16px;
  color: #777;
}
h5.formLabels {
  margin-top: 10px;
}
.blogBody {
  text-align: left;
  clear: both;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
</style>
