<template>
  <div class="hello">
    <b-table striped hover :items="taxData" />
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['taxData'],
  name: 'hello',
  components: {

  },
  data () {
    return {
    
    }
  },
  created(){
    
  },
  computed: {
      ...mapState([
        'userData',
        'documentTags',
        'shiftsData',
        'eventTags',
        'blogType',
        'departmentRanks',
        'departmentSpecialties',
        'vehicleCheckDetails',
        'vehicleCheckItems',
        'workgroupData',
        'getSessionData'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },
      /*

      How to filter against two arrays

      docArray(){
        var loaded = [... new Set(this.documentsApi.map(p => p.field_document_id))]
        var bulk = [... new Set(this.docArrayPre.map(p => p.field_document_id))]
        var test = this.docArrayPre
        console.log(loaded)
        console.log(bulk)
        for(var i = 0; i < loaded.length; i++){
          var index = bulk.indexOf(loaded[i])
          if(index > -1){
          test.splice(index, 1)}
        }
        return test

      } */
  },
  watch: {

},
  methods: {
  
  
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
