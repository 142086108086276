<template>
  <div class="hello">
    <h4 v-if="testUserRights">Opps... It looks like you don't have the appropriate credentials.</h4>
    <div  v-if="userData.role == 'adminchief' || userData.role == 'admin'">
    <h4>Taxonomy Admin Panel</h4>
    <h5 class="text-danger">WARNING: CHANGES TO THIS DATA CAN CREATE POSTING ISSUES FOR USER.</h5>
    <h5 class="text-danger"> PLEASE CONTACT STAT PORTALS IF YOU HAVE ANY QUESTIONS!</h5>
    <b-card>
      <b-tabs>
      <b-tab v-if="blogType" title="Blog Types"> 
        <blogtypetax :taxData="blogType" />
      </b-tab>
      <b-tab v-if="documentTags" title="Document Tags"> 
          <documenttypetax :taxData="documentTags" />
      </b-tab>
      <b-tab v-if="departmentRanks" title="User Ranks"> 
          <deptranktax :taxData="departmentRanks" />
      </b-tab>      
      <b-tab v-if="departmentSpecialties" title="User Specialties"> 
          <deptspecialtiestax :taxData="departmentSpecialties" />
      </b-tab>
      <b-tab v-if="eventTags" title="Event Tags"> 
          <eventtagstax :taxData="eventTags" />
      </b-tab>
      <b-tab v-if="vehicleCheckDetails" title="Vehicle Details"> 
          <vehicledetailstax :taxData="vehicleCheckDetails" />
      </b-tab>
      <b-tab v-if="vehicleCheckItems" title="Vehicle Items"> 
          <vehicleitemstax :taxData="vehicleCheckItems" />
      </b-tab>
      <b-tab v-if="vehicleTypeData" title="Vehicle Types"> 
          <vehicletypetax :taxData="vehicleTypeData" />
      </b-tab>
      <b-tab v-if="trainingrefData" title="Training Ref"> 
          <trainingreftax :taxData="trainingrefData" />
      </b-tab>
      <b-tab v-if="workgroupData" title="Work Groups" :active="defaultTax == 'workgroup'" > 
          <workgrouptax :taxData="workgroupData" />
      </b-tab>
      </b-tabs>
      </b-card>
  </div>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main'
import Basetax from './taxonomy/basetax.vue'
import BlogTypeTax from './taxonomy/blogtypeTax.vue'
import VehicleTypeTax from './taxonomy/vehicletypeTax.vue'
import DeptRankTax from './taxonomy/deptranksTax.vue'
import DeptSpecialtiesTax from './taxonomy/deptSpecialtiesTax.vue'
import EventTagsTax from './taxonomy/eventtagsTax.vue'
import DocumentTypeTax from './taxonomy/documenttypeTax.vue'
import VehicleDetailsTax from './taxonomy/vehicledetailsTax.vue'
import VehicleItemsTax from './taxonomy/vehicleItemsTax.vue'
import TrainingRefTax from './taxonomy/trainingrefTax.vue'
import WorkGroupTax from './taxonomy/workgroupTax.vue'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['defaultTax'],
  name: 'hello',
  components: {
    'basetax': Basetax,
    'blogtypetax': BlogTypeTax,
    'vehicletypetax': VehicleTypeTax,
    'deptranktax': DeptRankTax,
    'deptspecialtiestax': DeptSpecialtiesTax,
    'eventtagstax': EventTagsTax,
    'documenttypetax': DocumentTypeTax,
    'vehicledetailstax': VehicleDetailsTax,
    'vehicleitemstax': VehicleItemsTax,
    'workgrouptax': WorkGroupTax,
    'trainingreftax': TrainingRefTax,
  },
  data () {
    return {
    
    }
  },
  created(){
    
  },
  computed: {
      ...mapState([
        'userData',
        'documentTags',
        'shiftsData',
        'eventTags',
        'blogType',
        'departmentRanks',
        'departmentSpecialties',
        'vehicleCheckDetails',
        'vehicleCheckItems',
        'workgroupData',
        'trainingrefData',
        'getSessionData',
        'vehicleTypeData'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },
      /*

      How to filter against two arrays

      docArray(){
        var loaded = [... new Set(this.documentsApi.map(p => p.field_document_id))]
        var bulk = [... new Set(this.docArrayPre.map(p => p.field_document_id))]
        var test = this.docArrayPre
        console.log(loaded)
        console.log(bulk)
        for(var i = 0; i < loaded.length; i++){
          var index = bulk.indexOf(loaded[i])
          if(index > -1){
          test.splice(index, 1)}
        }
        return test

      } */
  },
  watch: {
    imageResponseData(newData){
      this.imageUUID(newData)
      this.imageFileSRC(newData)
      this.imageFileFIDData(newData)
    },
    imageFileFID(newData){
      this.sendNewPicturePost()
    }
},
  methods: {
    toggleAddNewDocument(){
      if(this.addNewDocument == true){
        this.pullDocuments()
      }
      this.addNewDocument = !this.addNewDocument
    },
    pullDocuments(){
      var headers = {
        headers: {
          'X-CSRF-TOKEN': this.getSessionData,
          'Content-Type' : 'application/hal+json',
        }
      }
      axios.get('https://firerescuedev.com/drupalBack/web/documents_api?_format=hal_json', this.headers).then( response => {
        this.documentsApi = response.data
      })
    },
    getTrainingDrills(){
    var headers = { "content-type": "application/json", 'X-CSRF-TOKEN': this.getSessionData, }
    axios.get('https://firerescuedev.com/drupalBack/web/training/builder/training_track_api?_format=hal_json', {withCredentials: true}, headers).then( response => {
      this.trainingDrills = response.data
      })
    },
    getTrainingSubModDrills(){
    var headers = { "content-type": "application/json", 'X-CSRF-TOKEN': this.getSessionData, }
    axios.get('https://firerescuedev.com/drupalBack/web/training/builder/training_track_submodule_api?_format=hal_json', {withCredentials: true}, headers).then( response => {
      this.trainingSubModDrills = response.data
      })
    },
   
    imageUUID(newData){
          this.newDataUUID = newData.uuid[0].value;
      },
    imageFileSRC(newData){
      this.imageFileName = newData.filename[0].value;
    },
    imageFileFIDData(newData){
      this.imageFileFID = newData.fid[0].value;
    },
    processFile(event, data) {
      this.postNid = data.item.nid
      this.photoFileUpload = event.target.files || event.dataTransfer.files;
        this.fileName = this.photoFileUpload[0].name
        this.fileType = this.photoFileUpload[0].type
        this.processFileUri(this.photoFileUpload[0])
    },
    processFileUri(file) {
    var reader = new FileReader();
      reader.onload = (e) => {
            this.image = e.target.result;
              this.processFileUriForUpload(this.image)
                    };
      reader.readAsDataURL(file);
    },
    processFileUriForUpload(file){
      var dataSplice = file
      this.previewImageFileData = file
      var newplacedData = dataSplice.split(',')
      this.newImageFileData = newplacedData[1]
    },
    getDocTag(data){
      var documents = this.documentTags.filter(p => {
        return p.name_1.match("Directives")
      })
      var matchData = documents.filter(p => {
        return p.name.match(data)})
      return JSON.parse(matchData[0].tid)
    },
    resetComponent() {
        this.dataReset = false
        this.fileName = ''
        this.fileType = ''
        this.newImageFileData = ''
        this.photoFileUpload = ''
        this.previewImageFileData = ''
        this.postNid = ''
        this.$nextTick(function(){
          this.dataReset = true
          this.pullDocuments()
        })
      },
    clearImageData(){
      var result = confirm('Are You Sure You Want to Clear this Data?')
      if (result) {
      this.postData = '',
      this.resetComponent()
    }
    else {}
    },
    deletePost(data){
      var result = confirm('Are You Sure You Want to Delete this Post?')
      if (result) {
        var nid = data
        axios({
          method: 'delete',
          url: 'https://firerescuedev.com/drupalBack/web/node/' + nid + '?_format=hal_json',
          data: {'_links': {
                          'type': {
                              'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/node/documents'
                            }},
                },
          headers: {
            'X-CSRF-TOKEN': this.getSessionData,
            'Content-Type' : 'application/hal+json',}
          }).then( response => {
            this.resetComponent()
            this.pullDocuments()
            })
          }


    else {}
      },
  postImage(){
     axios({
      method: 'post',
      url: 'https://firerescuedev.com/drupalBack/web/entity/file?_format=hal_json',
      data: {'_links': {
                      'type': {
                          'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/file/image'
                        }},
              "uri":[{"value":"private://documents/" + moment().format('YYYY-MM-DD-HH-mm') + this.fileName}],
              "data":[{"value": this.newImageFileData
            }],
              "filemime":[{"value": "application/pdf"}],
              "filename":[{"value":moment().format('YYYY-MM-DD-HH-mm') + this.fileName}],
            },
      headers: {
        'X-CSRF-TOKEN': this.getSessionData,
        'Content-Type' : 'application/hal+json',
      }
    }).then((response) => {
        this.imageResponseData = response.data;
      });
    },
    sendNewPicturePost(){
    var nid = this.postNid
    axios({
     method: 'patch',
     url: 'https://firerescuedev.com/drupalBack/web/node/' + nid + '?_format=hal_json',
     data: {'_links': {
                     'type': {
                         'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/node/documents'
                       }},
                       "_embedded": {
                         "https://firerescuedev.com/drupalBack/web/rest/relation/node/documents/field_document_file": [{
                           "_links": {
                             "type": {
                               "href": "https://firerescuedev.com/drupalBack/web/rest/type/file/document"
                             }
                           },
                           "uuid": [{
                             "value": this.newDataUUID
                           }],
                      /*     "alt": "imageAlt",
                           "title": "imageTitle",
                           "width": "240",
                           "height": "120"
 */
                         }],
                       }


           },
     headers: {
         'X-CSRF-TOKEN': this.getSessionData,
       'Content-Type' : 'application/hal+json',
     }
   }).then( response => {
        this.pullDocuments()
   })

 },
    submitArticl(){
    axios({
     method: 'post',
     url: 'https://firerescuedev.com/drupalBack/web/entity/node?_format=hal_json',
     data: {'_links': {
                     'type': {
                         'href' : 'https://firerescuedev.com/drupalBack/web/rest/type/node/documents'
                       }},
             "title":[{"value": this.docArray[i].field_document_title  }],
             "type":[{"target_id":"documents"}],
             "field_document_id":[{"value": this.docArray[i].field_document_id }],
             "field_document_tag":[{"target_id": this.getDocTag(this.docArray[i].field_document_tag) }]

           },
     headers: {
         'X-CSRF-TOKEN': this.getSessionData,
       'Content-Type' : 'application/hal+json',
     }
   }).then( response => {

   })

 }
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
