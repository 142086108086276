<template>
  <div class="hello"  v-if="userData.role == 'admin'">
    <b-card tabs>
      <b-tabs card>
        <b-tab title="submission">
            <br>
        <b-radio-group v-model="databaseType" :options="databaseOptions" />
        <b-form-select v-model="selectedItem" :options="sortBy(optionsForTaxonomy)" @input="setOptionsType($event)" />
        <b-form-input type="text" v-model="urlEndpoint" placeholder="apparatus/pg" />
        <b-textarea  v-model="uploadedJson" @input="fixDataReport()" />
         <b-btn @click="dataSub(uploadedJsonFixed, urlEndpoint)">Submit Data</b-btn>
        <b-btn v-if="false" @click="submitFix(userFixData)">Submit Fix</b-btn>
        test
         
         <b-table v-if="dataRefresh" :items="uploadedJsonFixed" />
         {{ uploadedJsonFixed }}

        </b-tab>


    </b-tabs>
    </b-card>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import PapaParse from 'papaparse'
export default {
  name: 'hello',
  components: {

  },
  data () {
      return {
      uploadedJson: '',
      msg: 'Welcome to Your Vue.js App',
      uploadedJsonFixed: [],
      dataForSubmission: {
          fields: ['id'],
          location: '',
          items: [
     
          ]
      },
      bulkFreeFormValueGet: '/content/',
      bulkFreeFormValue: undefined,
      bulkFreeFormUpdateValue: '',
      testDataReturned: [],
      newEmployeeData: [],
      varCount: 0,
      selectedItem: '',
      submitNumber: 0,
      selectedItemData: [],
      userFix: [

],
      optionsTableType: [
        {text: 'Text', value: 'text'},
        {text: 'UUID', value: 'uuid'},
        {text: 'Text[]', value: 'text[]'},
        {text: 'INT', value: 'integer'},
        {text: 'Boolean', value: 'boolean'},
        {text: 'Json', value: 'json'},
        {text: 'Json[]', value: 'json[]'},
        {text: 'Date', value: 'date'}
      ],
      createTableOptions: [],
      userToken: '',
      refreshToken: '',
      urlEndpoint: 'apparatus/pgbulk',
      getResponseData: [],
      userLoggedIn: '',
      holdDataForPreviousId: [],
      file: '',
      notesData: [],
      notificationsData: [],
      allrepair: [],
      preplansData: [],
      imagePicture: '',
      dataRefresh: true,
      databaseType: '',
      databaseOptions: ['taxonomy', 'content', 'users', 'forms'],
      optionsForTaxonomy: [
        // users
        {text: 'deptContactData', value: 'deptContactData', name: 'users', group: 'users'},
        // taxonomy
        {text: 'stationsData', value: 'stationsData', name: 'station', group: 'taxonomy'},
        {text: 'apparatusData', value: 'apparatusData', name: 'apparatus', group: 'taxonomy'},
        {text: 'documentTags', value: 'documentTags', name: 'document_type', group: 'taxonomy'},
        {text: 'shiftsData', value: 'shiftsData', name: 'shift_rotation', group: 'taxonomy'},
        {text: 'eventTags', value: 'eventTags', name: 'event_type', group: 'taxonomy'},
        {text: 'blogType', value: 'blogType', name: 'blog_type', group: 'taxonomy'},
        {text: 'departmentRanks', value: 'departmentRanks', name: 'dept_rank', group: 'taxonomy'},
        {text: 'departmentSpecialties', value: 'departmentSpecialties', name: 'dept_spec', group: 'taxonomy'},
        {text: 'vehicleCheckDetails', value: 'vehicleCheckDetails', name: 'check_details', group: 'taxonomy'},
        {text: 'vehicleCheckItems', value: 'vehicleCheckItems', name: 'check_items', group: 'taxonomy'},
        {text: 'workgroupData', value: 'workgroupData', name: 'work_group', group: 'taxonomy'},
        {text: 'trainingrefData', value: 'trainingrefData', name: 'training_ref', group: 'taxonomy'},  
        {text: 'medicationData', value: 'medicationData', name: 'medication', group: 'taxonomy'},  
        {text: 'vehicleTypeData', value: 'vehicleTypeData', name: 'vehicle_type', group: 'taxonomy'},  
        {text: 'repairStatus', value: 'repairStatus', name: 'repair_status', group: 'taxonomy'},             
        {text: 'departmentTutorials', value: 'departmentTutorials', name: 'tutorial', group: 'taxonomy'},
        // content            
        {text: 'departmentBlogs', value: 'departmentBlogs', name: 'blog', group: 'content', idFields: [{id: 'blogAuthorId', type: 'single'}]},
        {text: 'bannerImages', value: 'bannerImages', name: 'banner', group: 'content'},
        {text: 'alldailyvehicle', value: 'alldailyvehicle', name: 'dailyvehicle', group: 'content', idFields: [{id: 'dailyvehicleAuthorId', type: 'single'},{id: 'dailyvehicleBottleData', type: 'array', nested: 'bottleAuthorId'} ] },
        {text: 'documentsApi', value: 'documentsApi', name: 'document', group: 'content'},
        {text: 'allevent', value: 'allevent', name: 'event', group: 'content', idFields: [{id: 'eventAuthorId', type: 'single'},{id: 'eventUser', type: 'nonnestedarray'} ] },
        {text: 'alllogbook', value: 'alllogbook', name: 'logbook', group: 'content', idFields: [{id: 'logbookAuthorId', type: 'single'}]},
        {text: 'allnarctrack', value: 'allnarctrack', name: 'narctrack', group: 'content', idFields: [{id: 'narctrackAuthorId', type: 'single'}, {id: 'narctrackWitnessId', type: 'single'}]},
        {text: 'notesData', value: 'notesData', name: 'note', group: 'content', idFields: [{id: 'noteAuthorId', type: 'single'}]},
        {text: 'notificationsData', value: 'notificationsData', name: 'notification', group: 'content', idFields: [{id: 'notificationAuthorId', type: 'single'},{id: 'notificationNotifiedUserId', type: 'single'},{id: 'notificationNotifiedUsers', type: 'array', nested: 'id'},{id: 'notificationUsers', type: 'nonnestedarray'}]},
        {text: 'preplansData', value: 'preplansData', name: 'preplan', group: 'content'},
        {text: 'allrepair', value: 'allrepair', name: 'repairform', group: 'content', idFields: [{id: 'repairformAuthorId', type: 'single'}]},
  ],

    }
  },
  created(){

  },
  mounted(){
    
  },
  computed: {
    ...mapState([
        'stationsData',
        'getSessionData',
        'fullDeptContactData',
        'apparatusData',
        'repairStatus',
        'userData',
        'documentTags',
        'shiftsData',
        'eventTags',
        'blogType',
        'medicationData',
        'departmentRanks',
        'departmentSpecialties',
        'vehicleCheckDetails',
        'vehicleCheckItems',
        'workgroupData',
        'trainingrefData',
        'getSessionData',
        'vehicleTypeData',
         'departmentTutorials',
         'departmentBlogs',
         'bannerImages',
         'allevent',
         'alldailyvehicle',
         'alllogbook',
         'allnarctrack',
        'documentsApi'
    ]),
     visibilityOptions(){
          var array = [
            { text: 'user', value: 'user' },
            { text: 'admin', value: 'admin' },
            { text: 'adminchief', value: 'adminchief' },
            { text: 'fireops', value: 'fireops' },
            { text: 'emsops', value: 'emsops' },
            { text: 'trainingops', value: 'trainingops' },
            { text: 'shopops', value: 'shopops' },
            { text: 'supervisor', value: 'supervisor' }


    
          ]
          return array.map(p => p.value)
     },
     userFixData(){
       var array = _.cloneDeep(this.fullDeptContactData)
       var mapHold =  this.userFix.map(p => p.employee_id)
       console.log(mapHold)
       var newArray = array.map(item => {
              var obj = item
              var map 
              var index = mapHold.indexOf(parseInt(item.employeeId))
              console.log(item.employeeId)
              console.log(index)
              if(index != -1){
                obj['hireDate'] = moment(this.userFix[index].hire_date, 'MM/DD/YYYY').toISOString()
              }
              return obj
       })
       console.log(newArray)
       return newArray
     },

  },
  methods: {
    sortBy(data){
      var data = data.filter(p => p.group.match(this.databaseType))
      return _.sortBy(data, ['text'])
    },
    fixDataReport(){
       // var data  = JSON.parse(this.uploadedJsonReport)
        var data = this.uploadedJson
        var vm = this
        PapaParse.parse(data, {
            header: true,
          	complete: function(results) {
              console.log(results)
              console.log(results.data)
              vm.correctUserJsonArray(results.data)
            }
        })
      },
    correctUserJsonArray(data){
      var array = data
      if(this.selectedItem == 'stationsData' || this.selectedItem == 'apparatusData'){
        this.buildStationsData(array, data)
      } else if (this.selectedItem == 'deptContactData'){
        this.buildUsersData(array, data)
      }
    },
    setOptionsType(data){
      var obj = this.optionsForTaxonomy.find(p => p.value == data)
      this.urlEndpoint = '/' + obj.group + '/' + obj.name + '/bulk'
      this.dataRefresh = false
      var data = this[data]
      this.selectedItemData = data
    //  this.correctUserJsonArray(data)

    },
    buildStationsData(array, data){
      console.log('Stations')
      if(this.selectedItem == 'apparatusData'){
        var array = array.map(item => {
            var obj = item
            obj['apparatus_menu_visibility'] = this.visibilityOptions
            return obj
        })
      }
      this.uploadedJsonFixed = array
      this.$nextTick(function(){
        this.dataRefresh = true
      })
    },
    getUserRole(data){
      var overrideSiteRoles = [
        { text: "admin", value: "adminchief"},
        { text: "director" , value: "fireops"},
        { text: "manager", value: "trainingops"},
        { text: "supervisor", value: "supervisor"},
        { text: "user", value: "user"}
    ]
    var roleObj = overrideSiteRoles.find(p => p.text == data)
    if(roleObj){
      return roleObj.value
    } return 'user'
    },
    buildUsersData(array, data){
      console.log('Users')
      var users = array.map(item => {
          var obj = item
          if(obj.middle_initial){
            obj.first_name = obj.first_name + ' ' + obj.middle_initial + '.'
          }
          delete obj.middle_initial
          obj.hire_date = moment(obj.hire_date, 'MM/DD/YYYY').toISOString()
          obj.email = obj.email.toLowerCase()
          obj.password = 'temporary_' +  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) 
          obj.picture = "/files/default_images/Blank_User_Big.png"
          obj.role = this.getUserRole(obj.role)
          var special = obj.dept_specialties.split(',')
          if(special.length > 0){
            obj.dept_specialties = special
          } else {
            obj.dept_specialties = []
          } return obj
      })
      console.log(users)
      this.uploadedJsonFixed = users
      this.$nextTick(function(){
        this.dataRefresh = true
      })
    },
    submitFix(data){
      for(var i in data){
        axios({
          method: 'Patch',
          url: '/api/v1/users/' + data[i].id,
          data: {'hireDate': data[i].hireDate},
                  headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        },
    }).then( response => {
      console.log(response.data)
        })
      }

    },
    dataSub(data, url){
     var length = data.length
     var count = 1
     axios({
      method: 'post',
      url: '/api/v1' + url,
      data: data,
        headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        },
    }).then( response => {
      count = count + 1
      if(length == count){
        this.$store.dispatch('DELETE_LOCAL_DATA')
        this.setOptionsType(this.selectedItem)

      }
      this.$nextTick(function(){
      console.log(response.data)

      })
    })

  },
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}

</style>
