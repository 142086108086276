<template>
<div id="homelanding">

<b-container>
  <b-row><b-col v-if="newInventoryForm.inventoryDbid == 'generic'">
            <h5>Tag Invetory</h5>
            <multiselect
              
              v-model="inventoryDbidObj" 
              :options="getOptionsFromSelection(newInventoryForm.inventoryType)" 
              :multiple="false" 
              :close-on-select="true" 
              :clear-on-select="true" 
              :hide-selected="true" 
              :preserve-search="true" 
               :placeholder="dataObj.inventoryType == 'stationsData' ? 'Search Station Name' : dataObj.inventoryType == 'deptContactData' ? 'Search User Last Name' : dataObj.inventoryType == 'apparatusData' ? 'Search Apparatus Name' : dataObj.inventoryType == 'logisticAssetData' ? 'Search Asset Id' : 'Please Select a Type'"
              :label="dataObj.inventoryType == 'stationsData' ? 'stationName' : dataObj.inventoryType == 'deptContactData' ? 'lastName' : dataObj.inventoryType == 'apparatusData' ? 'apparatusName' : dataObj.inventoryType == 'logisticAssetData' ? 'assetId' : ''" 
              :track-by="dataObj.inventoryType == 'stationsData' ? 'stationName' : dataObj.inventoryType == 'deptContactData' ? 'lastName' : dataObj.inventoryType == 'apparatusData' ? 'apparatusName' : dataObj.inventoryType == 'logisticAssetData' ? 'assetId' : ''">

   <template slot="option" slot-scope="props">
      <div class="option__title" v-if="dataObj.inventoryType == 'stationsData'">{{ props.option.stationName }}</div>
 <div class="option__title" v-if="dataObj.inventoryType == 'apparatusData'">{{ props.option.apparatusName }}</div>
                    <div class="option__title" v-if="dataObj.inventoryType == 'logisticAssetData'">{{ props.option.assetDescription }} #ID:{{ props.option.assetId }} #SN:{{ props.option.assetSerialNumber }}</div>
                    <div class="option__title" v-if="dataObj.inventoryType == 'deptContactData'">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                   <div class="option__body" v-if="dataObj.inventoryType == 'deptContactData'">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
   </template>

</multiselect>
</b-col>
<b-col v-if="dataObj.inventoryDbid 
            && dataObj.inventoryDbid != 'generic' 
            && dataObj.inventoryHead
            && dataObj.inventoryHead.getpm 
            && dataObj.inventoryType == 'apparatusData'"
            cols="12"
            md="4">
<h5>Current Vehicle {{ getPMType(dataObj.inventoryDbid) }}</h5>
<b-form-input type="number" v-model="currentHoursMiles" :state="dataObj.inventoryDbid && dataObj.inventoryDbid != 'generic' && dataObj.inventoryHead && dataObj.inventoryHead.getpm && dataObj.inventoryType == 'apparatusData' && currentHoursMiles == '' ? false : true"  />
</b-col>
<b-col v-if="dataObj.inventoryDbid && dataObj.inventoryDbid != 'generic' && dataObj.inventoryHead && dataObj.inventoryHead.reserve && dataObj.inventoryType == 'apparatusData'"             
            cols="12"
            md="4">
<h5>Reserve Vehicle Tool</h5>
<b-btn variant="primary" @click="showReserve = !showReserve">Item Swap</b-btn>
</b-col>
</b-row>
<b-row v-if="dataObj.inventoryDbid 
            && dataObj.inventoryDbid != 'generic' 
            && dataObj.inventoryHead
            && dataObj.inventoryHead.getBothpm 
            && dataObj.inventoryType == 'apparatusData'">
<b-col cols="12"
            md="4">
<h5>Current Vehicle Hours</h5>
<b-form-input type="number" v-model="currentHoursBoth" :state="dataObj.inventoryDbid && dataObj.inventoryDbid != 'generic' && dataObj.inventoryHead && dataObj.inventoryHead.getBothpm && dataObj.inventoryType == 'apparatusData' && currentHoursBoth == '' ? false : true"  />
</b-col><b-col cols="12"
            md="4">
<h5>Current Vehicle Miles</h5>
<b-form-input type="number" v-model="currentMilesBoth" :state="dataObj.inventoryDbid && dataObj.inventoryDbid != 'generic' && dataObj.inventoryHead && dataObj.inventoryHead.getBothpm && dataObj.inventoryType == 'apparatusData' && currentMilesBoth == '' ? false : true"  />
</b-col>
</b-row>
<b-row v-if="dataObj.inventoryHead
            && dataObj.inventoryHead.resetPar
            && !dataObj.inventoryHead.resetParOnNew
            && userData.role != 'user'">
            <b-col>
              <b-btn block variant="danger" @click="resetParLevels">Reset Par Levels to Original Amounts</b-btn>
              </b-col></b-row>
</b-container>
<b-table v-if="getInventoryItemsListValues && resetData" :fields="fields" stacked="md" :items="getInventoryItemsListValues"  small class="text-left mt-2">
      <template slot="item_name" slot-scope="data">
      <span v-if="data.item._rowVariant == 'primary'" class="font-weight-bold underline" @click="checkHide(data.item.item_name, 'primary')">
        
       <b-btn variant="link">{{ data.item.item_name }}</b-btn><em v-if="checkIfHide(data.item.item_name, 'primary')"> click to show</em></span>
      <span v-else-if="data.item._rowVariant == 'info'" class="font-bold ml-0 ml-md-4" @click="checkHide(data.item.item_name, 'secondary')">
        <b-btn variant="link">{{ data.item.item_name }}</b-btn><em v-if="checkIfHide(data.item.item_name, 'secondary')"> click to show</em></span>
       <span v-else-if="data.item._rowVariant == 'success'" class="font-bold ml-0 ml-md-4" @click="checkHide(data.item.item_name, 'third')"><span class="ml-0 ml-md-4">
         <b-btn variant="link">{{ data.item.item_name }}</b-btn><em v-if="checkIfHide(data.item.item_name, 'third')"> click to show</em></span></span>
         <span v-else class="ml-0 ml-md-5"><span class="ml-0 ml-md-4"><span class="ml-0 ml-md-4" :title="data.item.item_name">
           <span v-if="data.item.reserveid" class="text-primary">&#10010;</span>{{ data.item.item_name.substring(0, 30) }}<em v-if="data.item.item_name.length > 30">...</em></span></span></span>
           <h6 v-if="userData.role == 'admin'">{{ data.item.holdid }}</h6>
      </template>
      <template slot="item_count" slot-scope="data">
        <h4 v-if="data.item._rowVariant == 'primary'">
          <b-badge v-if="getNotesCritical(true, null, null, data.item.item_name)" variant="danger">{{  getNotesCritical(true, null, null, data.item.item_name).length }}</b-badge>
          <b-badge class="ml-2" variant="warning" v-if="getNotesWarning(true, null, null, data.item.item_name)">{{  getNotesWarning(true, null, null, data.item.item_name).length }}</b-badge></h4>
        <h4 v-if="data.item._rowVariant == 'info'">
          <b-badge v-if="getNotesCritical(null, true, null, data.item.item_name, data.item)" variant="danger">{{  getNotesCritical(null, true, null, data.item.item_name, data.item).length }}</b-badge>
          <b-badge class="ml-2" variant="warning" v-if="getNotesWarning(null, true, null, data.item.item_name, data.item)">{{  getNotesWarning(null, true, null, data.item.item_name, data.item).length }}</b-badge></h4>
        <h4 v-if="data.item._rowVariant == 'success'">
          <b-badge v-if="getNotesCritical(null, null, true, data.item.item_name, data.item)" variant="danger">{{  getNotesCritical(null, null, true, data.item.item_name, data.item).length }}</b-badge>
          <b-badge class="ml-2" variant="warning" v-if="getNotesWarning(null, null, true, data.item.item_name, data.item)">{{  getNotesWarning(null, null, true, data.item.item_name, data.item).length }}</b-badge></h4>
        <b-container fluid class="m-0 p-0 pr-4" v-if="data.item._rowVariant == undefined && resetField != data.item.id && (data.item.item_type == 'check' || data.item.item_type == 'supply')">
          <b-row :title="'Desired Qty is ' + data.item.item_quantity">
            <b-col cols="*" >
          <b-btn variant="danger inline-block" @click="updateHoldValues(data.item.holdid, 'minus', 'count')">-</b-btn></b-col>
          <b-col class="p-0 m-0">
          <b-form-input  type="number" :class="holdValuesCheck[data.item.holdid].count < data.item.item_quantity ? 'text-danger' : ''" v-if="holdValuesCheck[data.item.holdid]" v-model="holdValuesCheck[data.item.holdid].count" />
          </b-col><b-col cols="*">
                    <b-btn variant="success" @click="updateHoldValues(data.item.holdid, 'add', 'count')">+</b-btn></b-col>
                    </b-row>
                    </b-container>
        <b-container fluid class="m-0 p-0 pr-4" v-if="data.item._rowVariant == undefined && resetField != data.item.id && data.item.item_type == 'asset'">
          <b-row><b-col cols="*" >
          <b-btn variant="outline-danger inline-block" v-if="!holdValues[data.item.holdid].asset_id && addAssetTag != data.item.holdid" @click="addAssetTag = data.item.holdid">Asset Tag Required</b-btn></b-col>
          <b-container v-if="addAssetTag == data.item.holdid">
            <b-form-input  v-model="assetSearch" placeholder="Enter Serial Number or Asset Id to Search" />
            <b-container fluid v-if="assetReturnList" class="mt-2">
            <b-row v-for="asset in assetReturnList" :key="asset.id" class="mb-2">
              <b-col align="left">
                <p class="mb-0">{{ asset.assetDescription }} (id: {{ asset.assetId }})</p>
                  </b-col>
                  <b-col cols="2" align="left">
                    <b-btn variant="primary" @click="holdValues[data.item.holdid].asset_id = asset.id, addAssetTag = '', assetSearch = ''" size="sm">Tag</b-btn>
                    </b-col>
              </b-row>
              </b-container>
              </b-container>
          <b-col class="p-0 pt-1 m-0" v-if="holdValues[data.item.holdid].asset_id">
          {{ getItemAssetInfo(holdValues[data.item.holdid].asset_id) }}
          </b-col><b-col cols="*" v-if="data.item.asset_id != ''">
                    <b-btn variant="warning" @click="addAssetTag = data.item.holdid">Edit</b-btn></b-col>
                    </b-row>
                    </b-container>
      </template>
      <template slot="tracking" slot-scope="data">
        <b-form-input v-if="data.item.item_type == 'supply' && data.item.expDate && holdValues[data.item.holdid]" :class="checkForMomentDate(holdValues[data.item.holdid].expire_date)" type="date" v-model="holdValues[data.item.holdid].expire_date" @change="resetFieldFlip(data.item.id)" />
        <b-row class="ml-3 mr-2" v-else-if="data.item.item_type == 'supply' && data.item['30daycount'] && holdValues[data.item.holdid] && resetField != data.item.id">
          <b-col class="pt-2 mr-2" cols="*">{{ getCurrent30DayExp }}</b-col>
          <b-col cols="*" ><b-btn variant="danger" @click="updateHoldValues(data.item.holdid, 'minus', '30_Day')">-</b-btn></b-col>
          <b-col  class="p-0 m-0"><b-form-input type="number" v-model="holdValues[data.item.holdid]['30_Day']"  /></b-col> 
          <b-col cols="*"><b-btn variant="success" @click="updateHoldValues(data.item.holdid, 'add', '30_Day')">+</b-btn></b-col>
        </b-row>
        <b-form-checkbox v-else-if="data.item._rowVariant == undefined && data.item.item_type != 'asset' && data.item.item_type != 'supply'" v-model="holdValues[data.item.holdid].item_pass" @change="resetFieldFlip(data.item.id)">Passed?</b-form-checkbox>
       <b-form-checkbox v-else-if="data.item._rowVariant == undefined && data.item.item_type == 'asset'" :disabled="holdValues[data.item.holdid].asset_id == ''" v-model="holdValues[data.item.holdid].item_pass"  @change="resetFieldFlip(data.item.id)">Passed?</b-form-checkbox>
      </template>
      <template slot="note" slot-scope="data">
         <b-btn variant="primary" v-if="data.item.notes != '' && data.item._rowVariant == undefined && resetField != data.item.id" @click="modalObj = data.item, showModal = !showModal">&#128065;</b-btn>
         <b-btn variant="outline-primary" v-if="data.item.notes == '' && data.item._rowVariant == undefined && checkForItemError(data.item) && resetField != data.item.id" @click="modalObj = data.item, showModal = !showModal">&#9998;</b-btn>
         <b-btn variant="outline-danger" title="Note Required" v-if="data.item.notes == '' && data.item._rowVariant == undefined && !checkForItemError(data.item) && resetField != data.item.id" @click="modalObj = data.item, showModal = !showModal">&#9998;</b-btn>
      </template>
</b-table>
<b-btn variant="success" 
    v-if="dataObj.inventoryDbid 
          && dataObj.inventoryDbid != 'generic' 
          && dataObj.inventoryHead 
          && dataObj.inventoryHead.getpm 
          && dataObj.inventoryType == 'apparatusData'
          && currentHoursMiles != '' 
          && !previousData" 
           :disabled="disabledSubmit" 
           @click.once="submitInventory" >Submit</b-btn>
<h6 v-else-if="currentHoursMiles == ''  && dataObj.inventoryHead  && dataObj.inventoryHead.getpm" class="text-danger">Current Vehicle {{ getPMType(dataObj.inventoryDbid) }} required for submission</h6>
<b-btn variant="success" 
    v-else-if="dataObj.inventoryDbid 
          && dataObj.inventoryDbid != 'generic' 
          && (!dataObj.inventoryHead || !dataObj.inventoryHead.getpm)
          && !previousData" 
           :disabled="disabledSubmit" 
           @click.once="submitInventory" >Submit</b-btn>
<div  v-else-if="previousData && newInventoryForm.id && (newInventoryForm.inventorySubAuthorId == userData.id || userData.role != 'user')">
<b-btn variant="success" @click="submitInventoryUpdate(newInventoryForm.id)" >Save Changes</b-btn>
<b-btn variant="primary" @click="submitInventoryUpdate(newInventoryForm.id, true)">Save and Exit</b-btn>
</div>
<h6 v-else class="text-danger">You either do not have the appropriate permissions, or there is a current Form Error, Contact Your Systems Administrator</h6>
<b-modal v-if="showModal && modalObj" v-model="showModal" :title="modalObj.item_name" @hide="resetFieldFlip(modalObj.holdid, modalObj.notes)" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true">
  <b-textarea v-model="modalObj.notes" :rows="3" />
  </b-modal>
    <b-modal v-if="showReserve" v-model="showReserve" size="lg" title="Reserve Item Swap" @hidden="''" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true">
    <b-form-select v-model="reserveSheetId" :options="inventoryTemplateDataOptions" />
    <b-card no-body v-if="reserveSheetId || reserveItemsFilter">
      <b-tabs card>
        <b-tab title="Available" v-if="getInventoryTemplate">
          
          <invSheetReserve v-if="resetReserveList" :newInventoryForm="getInventoryTemplate" :reserveform="newInventoryForm" @addItemToReserve="addItemToReserve" />
          </b-tab>
          <b-tab title="Swapped" v-if="reserveItemsFilter">
              <invSheetReserve :reserveTabToggle="reserveTabToggle" :newInventoryForm="reserveItemsFilter" :reserve="true" :reserveform="newInventoryForm" @addItemToReserve="removeItemFromReserve" />
            </b-tab>
            </b-tabs>
            </b-card>
  </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import invSheetReserve from './InvSheetReserve.vue'
export default {
components: {
  'multiselect' : Multiselect,
  invSheetReserve

},
props: ['newInventoryForm', 'inventoryTemplateData', 'previousData'],
data() {
    return {
      inventoryItemsData: [],
      searchData: '',
      fields: [{key: 'item_name', field: 'item_name'}, {key: 'item_count', label: 'Count/Asset'}, 'tracking', 'note'],
      uploadedJson: '',
      missingInventoryItems: [],
      addAssetTag: '',
      showModal: false,
      showReserve: false,
      resetReserveList: true,
      reserveSheetId: '',
      modalObj: '',
      assetSearch: '',
      currentHoursMiles: '',
      currentHoursBoth: '',
      currentMilesBoth: '',
      inventorySheetData: [],
      inventoryDbidObj: '',
      disabledSubmit: false,
      invetoryLastCheckData: [],
      reserveHoldArray: [],
      initialHold: {primary: [], secondary: [], third: []},
      initialHoldBreak: true,
      reserveTabToggle: true,
      holdValues: {},
      headValues: null,
      resetField: '',
      dataObj: {},
      selectedInventoryForm: '',
      databaseIDTag: '',
      sheetTaxonomyTag: null,
      returnTrue: false,
      resetData: true,
      checkHideList: {primary: [], secondary: [], third: []},
      getInventoryItemsListValuesHold: [],
}
},
created () {
  if(this.previousData){

    if(this.newInventoryForm && this.newInventoryForm.inventoryHeadData && this.newInventoryForm.inventoryHeadData.currentHoursMiles){
      this.currentHoursMiles = this.newInventoryForm.inventoryHeadData.currentHoursMiles
    }
    if(this.newInventoryForm && this.newInventoryForm.inventoryHeadData && this.newInventoryForm.inventoryHeadData.currentHoursBoth){
      this.currentHoursBoth = this.newInventoryForm.inventoryHeadData.currentHoursBoth
    }
    if(this.newInventoryForm && this.newInventoryForm.inventoryHeadData && this.newInventoryForm.inventoryHeadData.currentMilesBoth){
      this.currentMilesBoth = this.newInventoryForm.inventoryHeadData.currentMilesBoth
    }
    var previousInventoryForm = this.inventoryTemplateData.find(p => p.id == this.newInventoryForm.inventorySubId)
    if(previousInventoryForm){
    var obj = previousInventoryForm
    obj.inventoryItems =  _.cloneDeep(this.newInventoryForm.inventorySubData)
    this.dataObj = obj
    } else {
    this.dataObj['inventoryItems'] = _.cloneDeep(this.newInventoryForm.inventorySubData)
    }
  } else {
    this.dataObj = _.cloneDeep(this.newInventoryForm)
  }
  if(this.newInventoryForm.latest && this.newInventoryForm.inventoryDbid != 'generic'){
        var prev = this.newInventoryForm.latest.inventorySubData
        // console.log(this.newInventoryForm)
        this.invetoryLastCheckData = prev
        var reserve = prev.filter(p => {
          return p.reserveid
        })
        for(var i in reserve){
          this.dataObj.inventoryItems.push(reserve[i])
        }
        this.getInventoryItemsListValuesInitial()
  }  else if(this.previousData){
        var prev = _.cloneDeep(this.newInventoryForm.inventorySubData)
        this.invetoryLastCheckData = prev
        var reserve = prev.filter(p => {
          return p.reserveid
        })
        for(var i in reserve){
          this.dataObj.inventoryItems.push(reserve[i])
        }
        this.getInventoryItemsListValuesInitial()
  } else {
    this.getInventoryItemsListValuesInitial()
  }
  if(this.dataObj.inventoryHead && this.dataObj.inventoryHead.resetPar && this.dataObj.inventoryHead.resetParOnNew){
    console.log("Reset Par Levels")
    this.resetParLevels()
  }
},
beforeDestroy(){

},
watch: {
  inventoryDbidObj(newData){
   // console.log(newData)
    if(newData){
      this.dataObj.inventoryDbid = newData.id
    }
  }

},
computed: {
  ...mapState([
    'bannerImages',
    'deptContactData',
    'apparatusData',
    'stationsData',
    'logisticAssetData',
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),
  getNotesData(){
    if(this.previousData && this.newInventoryForm && this.newInventoryForm.inventorySubData){
      var errors = this.newInventoryForm.inventorySubData.filter(p => {
        if(p.item_type != 'asset'){
        return p.notes != '' 
              || p.item_count < p.item_quantity
              || (p.expDate && moment(p.expire_date).diff(moment(), "days") < 30 ? true : false)
        } else {
          return p.notes != '' 
        }
      })
        return errors
    } return []
  },
  getCurrent30DayExp(){
    var string = 'Exp by '
    if(this.previousData){
      if(this.newInventoryForm.inventorySubDate){
        var date = moment(this.newInventoryForm.inventorySubDate).add(1, 'month').format('MM/YY')
      } else {
        var date = 'Unknown Date'
      }
    } else {
     var date = moment().add(1, 'month').format('MM/YY')
    }
    return string + ' ' + date
  },
  assetReturnList(){
          var array = this.logisticAssetData.filter(p => {
            return p.assetSerialNumber ? p.assetSerialNumber.toLowerCase().match(this.assetSearch.toLowerCase()) : p.assetId.toLowerCase().match(this.assetSearch.toLowerCase())
          })
          if(array.length < 5){
            return array
          } else {
            return null
          }
    },
    reserveItemsFilter(){
      var array = this.dataObj.inventoryItems.filter(p => {
        return p.reserveid
      })
      this.reserveTabToggle = false
      this.$nextTick(function(){
        this.reserveTabToggle = true
      })
      var obj = {}
      obj['latest'] = {}
      obj.latest['inventorySubData'] = []
      obj.latest.inventorySubData = array
      if(array.length > 0){
        return obj
      }
      return null
    },
    getInventoryTemplate(){
      var id = this.reserveSheetId
      var array = _.cloneDeep(this.inventoryTemplateData)
      var obj = array.find(p => p.id == id)
      if(obj){
        this.resetReserveList = false
        this.$nextTick(function(){
          this.resetReserveList = true
        })
        return obj
      } else {
        return null
      }
    },
  inventoryTemplateDataOptions(){
    var array = _.cloneDeep(this.inventoryTemplateData)
    var latest = array.filter(p => {
      return p.latest
    })
    return latest.map(item => {
        var obj = {text: item.inventoryName, value: item.id }
        return obj
    })
  },
  getInventoryItemsList(){
    var obj = this.dataObj
    var array = this.getArrayItemsFiltered(obj.inventoryItems)
  //  console.log(array)
    return array
  },
  holdValuesCheck(){
    return this.holdValues
  },
  getInventoryItemsListValues(){
   // console.log('Initial')
    var data = this.getInventoryItemsListValuesHold
    var array = data.filter(p => {
          if(!p._rowVariant){
            if(p.item_loc_01 && p.item_loc_02 && p.item_loc_03){
              if(this.checkHideList.primary.indexOf(p.item_loc_01) == -1
                && this.checkHideList.secondary.indexOf(p.item_loc_02) == -1
                && this.checkHideList.third.indexOf(p.item_loc_03) == -1){
                return p
              } 
            } 
          } else if(!p.parent) {
            return p
          } else {
            if(p._rowVariant == 'info' && this.checkHideList.primary.indexOf(p.parent) == -1){
              return p
            } else if(p._rowVariant == 'success' && this.checkHideList.secondary.indexOf(p.parent) == -1) {
              return p
            }
          }
    })
    return array
  },
  InventoryItemArrayMap(){
    var array = this.dataObj.inventoryItems.map(item => {
              var obj = item
              if(this.holdValues[obj.holdid]){
              obj.item_pass = this.holdValues[obj.holdid].item_pass
              obj.item_count = this.holdValues[obj.holdid].count
              if(item.item_type == 'asset' && this.holdValues[obj.holdid].asset_id){
                obj.asset_id =  this.holdValues[obj.holdid].asset_id
              }
              if(obj.expDate){
                obj.expire_date = this.holdValues[obj.holdid].expire_date
              }
              if(obj['30daycount']){
                obj['30_Day'] = this.holdValues[obj.holdid]['30_Day']
              }
              if(this.holdValues[obj.holdid].notes){
                obj['notes'] = this.holdValues[obj.holdid].notes
              }
              return obj
              } else {
                console.log(this.holdValues)
                console.log(item)
              }
    })
    return array
  }

},
mounted(){

},
methods: {
    checkForItemError(item){
      if(item.item_type == 'asset'){
        return this.holdValues[item.holdid].item_pass == true
      }
      if(item.item_type == 'check'){
        if(item.item_name == 'Cell Phone Charger' && false){
          console.log(item)
          console.log(this.holdValues[item.holdid].item_pass == true)
          console.log(this.holdValuesCheck[item.holdid].count)
          console.log(item.item_quantity)
        }
        var boo = this.holdValues[item.holdid].item_pass == true && !(this.holdValuesCheck[item.holdid].count < item.item_quantity)
        return boo
      }
      if(item.item_type == 'supply' && item.expDate){
         if(item.item_name == 'IV Start Kit' && false){
          console.log(item)
          console.log(this.holdValues[item.holdid].expire_date)
          console.log(this.holdValuesCheck[item.holdid].count)
          console.log(item.item_quantity)
        }
        return this.holdValues[item.holdid].expire_date && !(this.holdValuesCheck[item.holdid].count < item.item_quantity)
      }
      if(item.item_type == 'supply'){
        return !(this.holdValuesCheck[item.holdid].count < item.item_quantity)
      }
      return false
    },
    checkClickEvent(val, id){
      console.log(val)
      console.log(id)
      console.log(this.getInventoryItemsListValues)
    },
    checkForMomentDate(date){
     // console.log(date)
     var validCheck = moment(date, 'YYYY-MM-DD', true).isValid();
     if(validCheck){
       if(moment(date, 'YYYY-MM-DD').diff(moment(), "days") < 1){
         return 'border-danger text-danger font-weight-bold'
       } else {
         var dayHold = moment(date, 'YYYY-MM-DD').diff(moment(), "days")
           if(dayHold > 0 && dayHold < 30){
             return 'border-warning text-warning font-weight-bold'
           } else {
             return ''
           }
       }
     } else {
       return 'text-danger'
     }
        // false
    },
    resetParLevels(){
    var holdKeys = Object.keys(this.holdValues)
    var baseArray = this.newInventoryForm.inventoryItems
     if(baseArray && holdKeys){
       for(var i in holdKeys){
         var id = holdKeys[i]
         var holdKeyObjs = _.cloneDeep(this.holdValues)
         var obj = baseArray.find(p => p.holdid == id)
         var holdKeyFind = holdKeyObjs[id]
         if(id == '588271457_783794996'){
         /**  console.log(holdKeys[i])
           console.log(obj)
           console.log(holdKeyFind)
           console.log(holdKeyFind.count)
           console.log(obj.item_quantity)
           console.log(obj) */ 
         }
         if(obj && obj.item_quantity){
          if(id == '588271457_783794996'){
           // console.log('IT MET REQUIREMENT')
            }
          this.resetField = id
          this.holdValues[id]['count'] = parseInt(obj.item_quantity)

         }
       }
              this.$nextTick(function(){
          this.resetField = ''
        })
     }  
    },
    getInventoryItemsListValuesInitial(){
    var array = this.getInventoryItemsList
    var hold = []
    var holdPrimary = false
    for(var i in array){
      hold.push({item_name: array[i].parent, _rowVariant: 'primary'})
      if(holdPrimary && this.initialHoldBreak){
        this.checkHideList['primary'].push(array[i].parent)
        this.initialHold['primary'].push(array[i].parent)
      }
      if(true){
      var secondary = array[i].secondary
      for(var s in secondary){
        if(secondary[s].secondary == 'NULL'){
          var items = this.getItemsSecondarFromParent(array[i].parent, secondary[s].secondary)
          for(var x in items){
            hold.push(items[x])
          }
        } else {
            if(holdPrimary && this.initialHoldBreak){
              this.checkHideList['secondary'].push(secondary[s].secondary)
              this.initialHold['secondary'].push(secondary[s].secondary)
          }
          hold.push({item_name: secondary[s].secondary, _rowVariant: 'info', parent: array[i].parent})
          if(true){
          var third = secondary[s].third
          for(var t in third){
            if(third[t] == 'NULL'){
          var items = this.getItemsSecondarFromParentThird(array[i].parent, secondary[s].secondary, third[t])
          for(var x in items){
            hold.push(items[x])
          }
            } else {
                     if(holdPrimary && this.initialHoldBreak){
                  this.checkHideList['third'].push(third[t])
                  this.initialHold['third'].push(third[t])
              }
                hold.push({item_name: third[t], _rowVariant: 'success', parent: secondary[s].secondary})
                if(true){
                     var items = this.getItemsSecondarFromParentThird(array[i].parent, secondary[s].secondary, third[t])
               for(var x in items){
                 hold.push(items[x])
               }
            }
            }
          }
        }
        }
      }

      }
      holdPrimary == false ? holdPrimary = true : ''
            
    }
    //console.log(hold)
    this.getInventoryItemsListValuesHold = hold

  },
  getNotesCritical(primary, secondary, third, value, rowdata){
        if(this.InventoryItemArrayMap && value && (primary || secondary || third)){
          var data = _.cloneDeep(this.InventoryItemArrayMap)
          var initialfilter = data.filter(p => {
                  if(primary){
                    return p.item_loc_01 == value
                  } else if (secondary && rowdata){
                    return p.item_loc_02 == value && p.item_loc_01 == rowdata.parent
                  } else if (third && rowdata){
                    return p.item_loc_03 == value && p.item_loc_02 == rowdata.parent
                  }
          })
          var errors = initialfilter.filter(p => {
            if(p.item_type != 'asset'){
            return p.notes != ''
                  || p.item_count < p.item_quantity
                  || (p.expDate && p.expire_date && moment(p.expire_date).diff(moment(), "days") < 1 ? true : false)
                 // || (this.holdValues[p.holdid].item_pass  && this.holdValues[p.holdid].item_pass == false)
            } else {
              return p.notes != ''
            }
          })
          if(errors && errors.length > 0){
          return errors
          } return null
        } return null
        },
  getNotesWarning(primary, secondary, third, value, rowdata){
          if(this.InventoryItemArrayMap && value && (primary || secondary || third)){
          var data = _.cloneDeep(this.InventoryItemArrayMap)
             var initialfilter = data.filter(p => {
                  if(primary){
                    return p.item_loc_01 == value
                  } else if (secondary && rowdata){
                    return p.item_loc_02 == value && p.item_loc_01 == rowdata.parent
                  } else if (third && rowdata){
                    return p.item_loc_03 == value && p.item_loc_02 == rowdata.parent
                  }
          })
          var errors = initialfilter.filter(p => {
            if(p.item_type != 'asset'){
              var day = p.expDate && p.expire_date && moment(p.expire_date).diff(moment(), "days")
              if(day > 0 && day < 30){
              return  p
            }
            }
          })
          if(errors && errors.length > 0){
          return errors
          } return null
        } return null
  },
  addItemToReserve(data){
    var id = this.reserveSheetId
    var obj = data 
    obj['reserveid'] = id
    this.dataObj.inventoryItems.push(obj)
    this.$nextTick(function(){
        this.getInventoryItemsListValuesInitial()
    })
   

  },
  removeItemFromReserve(data){
    var index = this.dataObj.inventoryItems.map(p => p.holdid).indexOf(data.holdid)
    this.dataObj.inventoryItems.splice(index, 1)
        this.$nextTick(function(){
        this.getInventoryItemsListValuesInitial()
    })
  },
  updateHoldValues(id, type, field){
    this.resetField = id
    var item = this.holdValues[id]
   // console.log(item)
    if(type == 'minus' && item[field] > 0){
      this.holdValues[id][field] = parseInt(this.holdValues[id][field]) - 1
    } else if (type == 'add'){
       this.holdValues[id][field] = parseInt(this.holdValues[id][field]) + 1
    }
    this.$nextTick(function(){
      this.resetField = ''
    })
  },
  resetFieldFlip(id, note){

      this.resetField = id
      if(note){
      this.holdValues[id]['notes'] = note
      }
        this.$nextTick(function(){
            this.modalObj = ''
            this.resetField = ''
          })
        },
  getPMType(id){
    if(id){
      var app = this.apparatusData.find(p => p.id == id)
      if(app && app.apparatusPMCheckType){
        return app.apparatusPMCheckType
      } return 'Unknown'
    } return 'Unknown'
  },  
  checkHide(item, location){
    var initial = this.initialHold[location].indexOf(item)
      if(index != -1){
 
        this.initialHoldBreak = false
      }
    if(this.checkHideList[location]){
      var index = this.checkHideList[location].indexOf(item)
      if(index == -1){
        this.checkHideList[location].push(item)
      } else {
        this.checkHideList[location].splice(index, 1)
      }
    } else {
      this.checkHideList[location] = []
      this.checkHideList[location].push(item)
    }
  },
  checkIfHide(item, location){
    var index = this.checkHideList[location].indexOf(item)
    if(index == -1){
      return false
    } else {
      return true
    }
  },
  getItemAssetInfo(id){
    if(id){
      var obj = this.logisticAssetData.find(p => p.id == id)
      if(obj){
        return obj.assetDescription + ' (id: ' + obj.assetId + ')'
      } return 'No Asset Found'
    } return 'No Asset Found'
    
  },
  getOptionsFromSelection(data){
    if(data){
     // console.log(data)
    return this[data]
    } else {
      return []
    }
  },
  getArrayItemsFiltered(items){
    var itemPar = [... new Set(items.map(p => p.item_loc_01))]
    var holdArray = [] 
    for(var p in itemPar){
      var item2 = items.filter(x => x.item_loc_01 == itemPar[p])
      var item2cat = [... new Set(item2.map(p => p.item_loc_02))]
      var item2sub = []
      for(var s in item2cat){
        var item3 = item2.filter(y => y.item_loc_02 == item2cat[s])
        var item3cat = [... new Set(item3.map(p => p.item_loc_03))]
        var obj = { secondary: item2cat[s], third: item3cat}
        item2sub.push(obj)
      }
      holdArray.push({parent: itemPar[p], secondary: item2sub})
    }
    return holdArray
  },
  getItemsSecondarFromParentThird(parent, secondary, third){
  var obj = this.dataObj
  var array =  obj.inventoryItems.filter(p => p.item_loc_01 == parent && p.item_loc_02 == secondary && p.item_loc_03 == third)
  var prevmap = array.map(item => {
    var obj = _.cloneDeep(item)
  //  obj['holdid'] = item.id ? item.id + parseInt(Math.random()*1000000000) : parseInt(Math.random()*1000000000) + parseInt(Math.random()*1000000000)
    var prev = this.invetoryLastCheckData.find(p => p.holdid == obj.holdid)
    // console.log(this.invetoryLastCheckData.map(p => p.holdid).indexOf(obj.holdid))
    // console.log(prev)
    // console.log(obj)
    if(prev){
      obj.item_count = prev.item_count
      var holdobj = {}
      holdobj['count'] = prev.item_count
      holdobj['item_pass'] = this.siteconfig.resetPassEachDay && !this.previousData ? false : prev.item_pass
      if(item.item_type == 'supply'){
        holdobj['item_pass'] = true
      }
      if(item.expDate){
        if(prev.expire_date){
        //  console.log(prev)
        //  console.log(prev.expire_date)
          holdobj['expire_date'] = this.siteconfig.resetPassEachDay ? null : prev.expire_date
        } else {
          holdobj['expire_date'] = null
        }
      }
      if(item.item_type == 'asset' && prev.asset_id){
        holdobj['asset_id'] = prev.asset_id
      }      
      if(item['30daycount']){
        if(prev['30_Day']){
          holdobj['30_Day'] = prev['30_Day']
        } else {
          holdobj['30_Day'] = 0
        }
      }
      this.holdValues[obj.holdid] = holdobj
    } else {
      obj.item_count = obj.item_quantity
      var holdobj = {}
      holdobj['count'] = obj.item_quantity
      holdobj['item_pass'] = this.siteconfig.resetPassEachDay && !this.previousData ? false : ''
      holdobj['asset_id'] = ''
      if(item.expDate){
          holdobj['expire_date'] = null
      }      
      if(item['30daycount']){
          holdobj['30_Day'] = 0
        }
        this.holdValues[obj.holdid] = holdobj
    }
    return obj
  })
  
  return prevmap
  },
  getItemsSecondarFromParent(parent, secondary){
  var obj = this.dataObj
  var array =  obj.inventoryItems.filter(p => p.item_loc_01 == parent && p.item_loc_02 == secondary && p.item_loc_03 == 'NULL')
  var prevmap = array.map(item => {
    var obj = _.cloneDeep(item)
   // obj['holdid'] = item.id + parseInt(Math.random()*1000000000)
    var prev = this.invetoryLastCheckData.find(p => p.holdid == item.holdid)
    //console.log(item.holdid)
      if(prev){
      obj.item_count = prev.item_count
      var holdobj = {}
      holdobj['count'] = prev.item_count
      if(prev.item_pass){
      holdobj['item_pass'] = this.siteconfig.resetPassEachDay && !this.previousData ? false : prev.item_pass
      }
      if(item.expDate){
        if(prev.expire_date){
        //  console.log(prev)
        //  console.log(prev.expire_date)
          holdobj['expire_date'] =  this.siteconfig.resetPassEachDay ? null : prev.expire_date
        } else {
          holdobj['expire_date'] = null
        }
      }
      if(item.item_type == 'asset' && prev.asset_id){
        holdobj['asset_id'] = prev.asset_id
      }      
      if(item['30daycount']){
        if(prev['30_Day']){
          holdobj['30_Day'] = prev['30_Day']
        } else {
          holdobj['30_Day'] = 0
        }
      }
      this.holdValues[obj.holdid] = holdobj
    } else {
      obj.item_count = obj.item_quantity
      var holdobj = {}
      holdobj['count'] = obj.item_quantity
      holdobj['item_pass'] = this.siteconfig.resetPassEachDay && !this.previousData ? false : ''
      holdobj['asset_id'] = ''
      if(item.expDate){
          holdobj['expire_date'] = null
      }      
      if(item['30daycount']){
          holdobj['30_Day'] = 0
        }
        this.holdValues[obj.holdid] = holdobj
    }
    return obj
  })
  return prevmap
  },
  getNotesDataSubmission(data){
        if(data){
          var errors = data.filter(p => {
            if(p.item_type != 'asset'){
            return p.notes != '' 
                  || p.item_count < p.item_quantity
                  || (p.expDate && moment(p.expire_date).diff(moment(), "days") < 1 ? true : false)
            } else {
              return p.notes != '' 
            }
          })
            return errors
        } return []
        },
  getNotesWarningSubmission(data){
        if(data){
          var errors = data.filter(p => {
            if(p.item_type != 'asset'){
              var day = p.expDate && moment(p.expire_date).diff(moment(), "days")
              if(day > 0 && day < 30){

              return  p
            }
            }
          })
          return errors
        } return []
  },
  submitInventoryUpdate(id, boo){
    this.disabledSubmit = true
    var dataArray = this.dataObj.inventoryItems.map(item => {
              var obj = item
              if(this.holdValues[obj.holdid]){
              obj.item_count = this.holdValues[obj.holdid].count
              obj.item_pass = this.holdValues[obj.holdid].item_pass
              if(item.item_type == 'asset' && this.holdValues[obj.holdid].asset_id){
                obj.asset_id =  this.holdValues[obj.holdid].asset_id
              }
              if(obj.expDate){
                obj.expire_date = this.holdValues[obj.holdid].expire_date
              }
              if(obj['30daycount']){
                obj['30_Day'] = this.holdValues[obj.holdid]['30_Day']
              }
                            if(this.holdValues[obj.holdid].notes){
                obj['notes'] = this.holdValues[obj.holdid].notes
              }
              return obj
              } else {
                console.log(this.holdValues)
                console.log(item)
              }
    })
    var criticals = this.getNotesDataSubmission(dataArray)
    var warnings = this.getNotesWarningSubmission(dataArray)
    var dataObj = {
      inventorySubAuthor: this.userData.firstName + ' ' + this.userData.lastName,
      inventorySubAuthorId: this.userData.id,
      inventorySubData: dataArray,
      inventoryHeadData: this.currentHoursMiles ? {currentHoursMiles: this.currentHoursMiles } : 
                         this.currentHoursBoth && this.currentMilesBoth ? { currentHoursBoth: this.currentHoursBoth, currentMilesBoth: this.currentMilesBoth } :null,
      inventoryNotifications: {critical: criticals, warnings: warnings }
    }
    axios({ 
     method: 'patch',
     url: '/api/v1/content/inventorysub/' + id,
     data: dataObj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.$emit('getInventorySubs')
     this.disabledSubmit = false
     if(boo){
       console.log('EMIT')
    this.$emit('closeInventoryShowTable', id)
     }
   })
    
  },
  submitInventory(){
    var dataArray = this.dataObj.inventoryItems.map(item => {
              var obj = item
              if(this.holdValues[obj.holdid]){
              obj.item_count = this.holdValues[obj.holdid].count
              obj.item_pass = this.holdValues[obj.holdid].item_pass
              if(item.item_type == 'asset' && this.holdValues[obj.holdid].asset_id){
                obj.asset_id =  this.holdValues[obj.holdid].asset_id
              }
              if(obj.expDate){
                obj.expire_date = this.holdValues[obj.holdid].expire_date
              }
              if(obj['30daycount']){
                obj['30_Day'] = this.holdValues[obj.holdid]['30_Day']
              }
                            if(this.holdValues[obj.holdid].notes){
                obj['notes'] = this.holdValues[obj.holdid].notes
              }
              return obj
              } else {
                console.log(this.holdValues)
                console.log(item)
              }
    })
    var criticals = this.getNotesDataSubmission(dataArray)
    var warnings = this.getNotesWarningSubmission(dataArray)
    var dataObj = {
      inventorySubDate: moment().toISOString(),
      inventorySubId: this.newInventoryForm.id,
      inventorySubAuthor: this.userData.firstName + ' ' + this.userData.lastName,
      inventorySubAuthorId: this.userData.id,
      inventorySubData: dataArray,
      inventorySubDbid: this.dataObj.inventoryDbid,
      inventoryHeadData: this.currentHoursMiles ? {currentHoursMiles: this.currentHoursMiles } : 
                         this.currentHoursBoth && this.currentMilesBoth ? { currentHoursBoth: this.currentHoursBoth, currentMilesBoth: this.currentMilesBoth } :null,
      inventoryNotifications: {critical: criticals, warnings: warnings }
    }
    axios({ 
     method: 'Post',
     url: '/api/v1/content/inventorysub/',
     data: dataObj,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.$emit('getInventorySubs')
     this.$emit('closeInventoryShowTable', dataObj.inventorySubId)
   })
    },
  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
