<template>
<div id="homelanding">
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable" v-if="siteconfig.payroll.overtimeSignup">Available For:</b-navbar-brand>
        <b-form-input @change="emitSelectedDate" v-if="siteconfig.payroll.overtimeSignup" class="mr-2" type="date" v-model="selectedDateLocal" />
        <b-navbar-brand class="bootstrapTable">Report Range:</b-navbar-brand>
        <b-form-input type="date" v-model="startDate" :disabled="recoReportData" />
        <b-form-input type="date" v-model="endDate" />
        <b-form-select :options="payrollLists" v-model="selectedList" @change="payCodeReportData = null" />
        <b-btn @click="getDataForListReport" variant="primary">Run Report</b-btn>
        <b-btn @click="executePrintJS" variant="info" v-if="payCodeReportData">Print</b-btn>
        <b-btn variant="secondary" class="ml-2" v-if="payCodeReportData" @click="showFilter = !showFilter">Filter</b-btn>
        <b-btn variant="danger" class="ml-2" v-if="showFilter" @click="resetFilterData">Reset Filter</b-btn>
              </b-nav-form>
    </b-navbar>
    <b-navbar v-if="showFilter">
        <b-form-checkbox v-if="siteconfig.payroll.overtimeSignup" v-model="showOnlySignedUp">Only Show Available?</b-form-checkbox>
        <b-form-checkbox v-model="showOnlyOnRoster">Only Show OnRoster</b-form-checkbox>
        <b-form-checkbox v-model="hideSignups" class="ml-2">Hide Signups</b-form-checkbox>
        <b-form-select class="ml-2" v-model="filterByRank" multiple :options="ranksReturned" />
        <b-form-select class="ml-2" v-model="filterBySpecial" multiple :options="specialtiesReturned" />
        <b-form-input type="text" class="ml-2" v-model="nameFilter" placeholder="Last Names (seperate by a space)" />
    </b-navbar>
    <b-container fluid :id="'print_report_' + this.randomField" :class="printReportOn ? 'printReport' : ''">
    <b-row v-if="printReportOn"><b-col cols="*">Range: {{ startDate }} - {{ endDate }}</b-col>
    <b-col>Type: {{ selectedList }}</b-col>
    <b-col>Ran on {{ currentDate }} by {{ userData.firstName }} {{ userData.lastName }}</b-col>
    </b-row>
    <b-container v-if="siteconfig.payroll.overtimeSignup && !hideSignups" fluid>
      <b-table :fields="fieldsAvailable" :items="payrollModsOvertimeAvailable">
        <template slot="payrollModCode" slot-scope="data">
          <span :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
        </template>
        <template slot="payrollModDatetime" slot-scope="data">
        <span :class="getSpanClass(data.item.payrollModDatetime, data.item.updatedAt)" :title="'Last Updated: ' + getFormatedDateTime(data.item.updatedAt, true)"> {{ getFormatedDateTime(data.item.payrollModDatetime, false)}}</span>
        </template>
        <template slot="TargetUser" slot-scope="data">
          <span v-if="data.item.TargetUser" :id="'popoveruser-'+data.item.TargetUser.id+data.index"
          v-b-tooltip.hover :title="data.item.TargetUser.stationAssignment + ' | ' + data.item.TargetUser.shiftAssignment" >{{ data.item.TargetUser.firstName }}  {{ data.item.TargetUser.lastName }} </span>
          <br v-if="data.item.ExchangeUser">
          <span v-if="data.item.ExchangeUser">ExON: {{ data.item.ExchangeUser.firstName }}  {{ data.item.ExchangeUser.lastName }}</span>
        </template>
        <template slot="createdAt" slot-scope="data">
          {{ getFormatedDateTime(data.item.createdAt, true)}}
        </template>
        <template slot="payrollModApproved" slot-scope="data">
          <span v-if="siteconfig.deptUrlSub == 'scfd'" :class="checkForAvailibility(data.item) == 'Available' ? 'payroll-blue' : 'payroll-bright-green'" class="text-light m4">{{ checkForAvailibility(data.item) }}</span>
          <b-badge v-else :variant="getApprovalStatusColor(data.item.payrollModApproved, data.item.ExchangeUser, data.item.payrollModExchangeApproved)"><h6 class="mb-0">{{ getApprovalStatus(data.item.payrollModApproved, data.item.ExchangeUser, data.item.payrollModExchangeApproved) }}</h6></b-badge>
        </template>
        <template slot="ApprovalUser" slot-scope="data">
        <span v-if="data.item.ApprovalUser"> {{ data.item.ApprovalUser.lastName }}</span>
        </template>
      </b-table>
    </b-container>
    <b-table small :fields="fields" :items="payrollDataReturned" v-if="payCodeReportData">
      <template slot="name" slot-scope="data">
        {{ data.item.lastName }}, {{ data.item.firstName }}
      </template>
            <template slot="rank" slot-scope="data">
        {{ data.item.deptRank }}
      </template>
      <template slot="specialties" slot-scope="data">
                    <span v-for="spec in data.item.deptSpecialties" class="mr-1">
        <b-badge  >{{ spec}}</b-badge>
                    </span>
      </template>
      <template slot="view" slot-scope="data">
        <b-btn variant="link" v-if="data.item.mods && data.item.mods.length > 0" @click="viewOvertimeMods(data.item)">Mods({{ data.item.mods.length }})</b-btn>
      </template>
    </b-table>
    </b-container>
    <b-modal v-if="modalObj" v-model="showModModal" size="lg">
      <template slot="modal-header">
        <h5>{{ selectedList }} for {{ modalObj.firstName }} {{ modalObj.lastName }} from {{ startDate }} - {{ endDate }}</h5>
      </template>
      <b-table small :fields="modalFields" :items="modalObj.mods">
        <template slot="payrollModDatetime" slot-scope="data">
          {{ getFormatedTime(data.item.payrollModDatetime)}}
        </template>
      <template slot="payrollModCode" slot-scope="data">
        <span :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
      </template>
      <template slot="payrollModApprovalUserId" slot-scope="data">
        {{ getApprovalUserLastName(data.item.payrollModApprovalUserId)}}
      </template>
      </b-table>
    <template slot="modal-footer">
      <b-btn variant="danger" @click="modalObj = null, showModModal = false">Close</b-btn>
    </template>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import modsUser from './modsUser.vue'
import Multiselect from "vue-multiselect";
import html2canvas from 'html2canvas';
export default {
  props: ['payrollCodesData', 'selectedDate', 'payrollModDailyRosterData'],
components: {


},
data() {
    return {
      startDate: '',
      endDate: '',
      payrollLists: '',
      selectedList: '',
      randomField: '',
      nameFilter: '',
      showFilter: false,
      hideSignups: false,
      filterByRank: [],
      filterBySpecial: [],
      removeRoster: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'upaid leave',
      ],
      modalFields: [
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModCode', label: 'Code'},
      {key: 'payrollModHours', label: 'Hours'},
      {key: 'payrollModApprovalUserId', label: 'Approved'},
    ],
    fieldsAvailable: [
      {key: 'payrollModCode', label: 'Code'},
      {key: 'payrollModDatetime', label: 'Avail Start'},
      {key: 'payrollModHours', label: 'Avail Hours'},
      {key: 'TargetUser', label: 'Target'},
      {key: 'payrollModApproved', label: 'Status'},
      {key: 'createdAt', label: 'Submitted'},
      {key: 'ApprovalUser', label: 'Author'},
    ],
      showModModal: '',
      modalObj: null,
      currentDate: '',
      printReportOn: false,
      payCodeReportData: null,
      selectedDateLocal: null,
      showOnlySignedUp: false,
      showOnlyOnRoster: false,
      recoReportData: false,
      recoReportLatest: null,
      setDailyData: [],
}
},
created () {
 // this.getUserProfile()
  this.randomField = _.uniqueId()
  this.currentDate = moment().format('YYYY-MM-DD')
  if(this.siteconfig.payroll.listreportstartofyear){
      this.startDate = moment().startOf('year').format('YYYY-MM-DD')
  } else {
      this.startDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
  }
  this.endDate = moment().format('YYYY-MM-DD')
  this.payrollLists = this.siteconfig.payroll.payrollLists
  this.selectedList = this.siteconfig.payroll.payrollLists[0]
  
  if(this.selectedDate){
  this.selectedDateLocal = this.selectedDate
  }
  if(this.siteconfig.payroll.overtimeSignup){
    this.showOnlySignedUp = true
  }
  if(this.siteconfig.payroll.payrollListsFromBank){ 
    this.recoReportData = true
    this.getRecoReports()
  }
  bus.$on('dailyRosterDataForList', this.setDailyRoster)
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData'
  ]),
  fields(){
      var array = ['employeeId', 'name', 'rank', 'specialties', 'hours', 'personalMobile']
      if(!this.printReportOn && (this.userData.role == 'admin' || this.userData.role == 'adminchief' || this.userData.role == 'fireops')){
        array.push('view')
      }
      return array
  },
  payrollCodeArray(){
    var array = _.cloneDeep(this.payrollCodesData)
    var codes = array.filter(p => {
          if(p.payrollCodeListId){
            var index = p.payrollCodeListId.indexOf(this.selectedList)
            if(index != -1){
              return p
            }
          }
    })
    return codes.map(p => p.id)
  },
  payrollModsOvertimeOn(){
      var array = _.cloneDeep(this.payrollModDailyRosterData)
      array = array.filter(p => p.payrollModType == 'overtime')
      return array
  },
  payrollModsOvertimeAvailable(){
    var array = _.cloneDeep(this.payrollModDailyRosterData)
   // console.log(array)
    array = array.filter(p => p.payrollModType == 'overtime availability')
   // console.log(array)
    return array.map(item => {
        var obj = item
        obj.TargetUser = this.getUserData(item.payrollModTargetUserId)
        if(obj.TargetUser){
        obj.TargetShift = obj.TargetUser && obj.TargetUser.shiftAssignment ? obj.TargetUser.shiftAssignment : ''
        obj.TargetParent = this.getTargetParent(obj.TargetUser.stationAssignment)
        obj.ExchangeUser = this.getUserData(item.payrollModExchangeUserId)
        obj.ApprovalUser = this.getUserData(item.payrollModApprovalUserId)
        return obj
        } return obj
    })
  },
  ranksReturned(){
    if(this.siteconfig.payroll.overtimeRanks){
        var ranks = _.cloneDeep(this.siteconfig.payroll.overtimeRanks)
        return ranks
    } else {
        return [... new Set(this.deptContactData.map(p => p.deptRank))] 
    }
  },
  specialtiesReturned(){
    var array = _.cloneDeep(this.deptContactData)
    var specials = []
    for(var i in array){
      var spec = array[i].deptSpecialties
      for(var s in spec){
        var index = specials.indexOf(spec[s])
        if(index == -1){
          specials.push(spec[s])
        }
      }
    }
 //   console.log(specials)
    return specials
  },
  payrollDataReturned(){
    var array = _.cloneDeep(this.deptContactData)
    if(this.siteconfig.payroll.listreportyearnumber){
        array = _.orderBy(array, ['hireDate', 'employeeId'], ['asc'])
    } else {
        array = _.orderBy(array, ['employeeId'], ['asc'])
    } 
    array = array.filter(p => {
        if(this.siteconfig.payroll.overtimeRanks){
          var index = this.siteconfig.payroll.overtimeRanks.indexOf(p.deptRank)
          if(index != -1){
            return p
          }
        } else {
        return p.deptRank == 'Apprentice' || p.deptRank == 'Firefighter' || p.deptRank == 'Lieutenant' || p.deptRank == 'EMS Captain' || p.deptRank == 'Captain' || p.deptRank == 'Battalion Chief' || p.deptRank == 'Journeyman'
        }
    })
    array = array.map(item => {
        var obj = item
        var mods = this.payCodeReportData.filter(p => p.payrollModTargetUserId == item.id)
        if(mods.length > 0){
          obj['hours'] = this.calcHours(mods)
          obj['mods'] = mods
        } else {
          obj['hours'] = 0
        }
        return obj
    })
    if(this.recoReportLatest && this.recoReportLatest.payrollRecoData){
      var holdData = this.recoReportLatest.payrollRecoData
      array = array.map(item => {
           var obj = item
           var banks = holdData.find(p => p.payrollUserId == item.id)
           if(banks && banks.banks){
             var bank = banks.banks.find(p => p.name == this.siteconfig.payroll.payrollListsFromBank[0])
             if(bank && bank.newTotal){
               obj.hours = Number.parseFloat(obj.hours) + Number.parseFloat(bank.newTotal)
             }
           }
           return obj
      })
    }
    if(this.showOnlySignedUp){
      var ids = _.cloneDeep(this.payrollModsOvertimeAvailable)
      ids = ids.map(p => p.payrollModTargetUserId)
      array = array.filter(p => {
          var index = ids.indexOf(p.id)
          if(index != -1){
            return p
          }
      })
    } 
    if(this.showOnlyOnRoster){
      var ids = _.cloneDeep(this.setDailyData)
      ids = ids.map(p => p.employeeId)
      array = array.filter(p => {
          var index = ids.indexOf(p.employeeId)
          if(index != -1){
            return p
          }
      })
    }
    if(this.filterByRank && this.filterByRank.length > 0){
      array = array.filter(p => {
              var index = this.filterByRank.indexOf(p.deptRank)
              if(index != -1){
                return p
              }
      })
    }
    if(this.filterBySpecial && this.filterBySpecial.length > 0){
      array = array.filter(p => {
            var specials = p.deptSpecialties
            for(var s in specials){
              var index = this.filterBySpecial.indexOf(specials[s])
              if(index != -1){
                return p
              }
            }
      })
    }
    if(this.nameFilter && this.nameFilter != ''){
      array = array.filter(p => {
          var option = this.nameFilter.split(' ')
      //    console.log(option)
          var optionscheck = false
          for(var o in option){
             if(p.lastName.toLowerCase().includes(option[o].toLowerCase())){
               optionscheck = true
             }
          }
          if(optionscheck){
            return p
          }

      })
    }
    return _.orderBy(array, ['hours'], ['asc'])
  },
},
mounted(){

},
methods: {
      resetFilterData(){
        this.filterByRank = []
        this.filterBySpecial = []
        this.filterModType = []
        this.nameFilter = ''
      },
      setDailyRoster(data){
        var filterData = data.filter(p => {
        if(p.payrollModType){
          return this.removeRoster.indexOf(p.payrollModType) == -1
        } //return p
      })
     // console.log('Filter Data')
    //  console.log(filterData)
        this.setDailyData = filterData
      },
      checkForAvailibility(row){
       // console.log(row)
        var index = this.payrollModsOvertimeOn.filter(p => {
          return p.payrollModTargetUserId == row.payrollModTargetUserId
                && moment(p.payrollModDatetime).isSameOrAfter(row.payrollModDatetime)
                && moment(p.payrollModDatetime).add(p.payrollModHours, 'hours').isSameOrBefore(moment(row.payrollModDatetime).add(row.payrollModHours, 'hours'))
        })
        if(index && index.length > 0){
          var hours = 0
          for(var i in index){
            hours = hours + Number.parseFloat(index[i].payrollModHours)

          }
          return "Assigned " + hours
        } return "Available"
      },
      getRecoReports(){
        axios({
          method: 'get',
          url: '/api/v1/staffing/payrollrecoreport',
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
          }
        }).then(response => {
            var array = _.orderBy(response.data, ['payrollBankRecoDate'], ['desc'])
            if(array && array[0]){
          //  console.log(array[0])
              this.recoReportLatest = array[0]
              if(array[0].payrollBankRecoDate){
                this.startDate = moment(array[0].payrollBankRecoDate).format('YYYY-MM-DD')
              }
            }
        })
    },
      getSpanClass(date, update){
      if(date && update){
        var selected = moment(date).format('YYYY-MM-DD')
        var start = moment(selected + 'T' + this.siteconfig.payroll.shiftStartTime)
        var nextShift = moment(start).add(1, 'day').toISOString()
        var boo = moment(nextShift).isAfter(update)
        if(boo){
          return ''
        } else {
          return 'text-danger font-weight-bold' 
        }
      } return ''
    },
      emitSelectedDate(){
    this.$emit('updateSelectedDate', this.selectedDateLocal)
  },
    getApprovalStatus(data, exchange, status){
        if(!exchange){
        return data == true ? 'Approved' : data == false ? 'Denied' : data == null ? 'Needs Attn' : ''
        } else {
          return status == true && data == true ? 'Approved' : data == false ? 'Denied' : status == false ? 'User Denied' : status == true ? 'Needs Attn' : 'Awaiting'
        }
    },
    getApprovalStatusColor(data, exchange, status){
          if(!exchange){
       return data == true ? 'success' : data == false ? 'danger' : data == null ? 'warning' : ''
        } else {
          return status == true && data == true ? 'success' : data == false ? 'danger' : status == false ? 'danger' : status == true ? 'warning' : 'info'
        }
    },
        getFormatedDateTime(date, seconds){
      if(date){
        if(seconds){
        return moment(date).format('MM/DD/YY HH:mm:ss')
        } else {
           return moment(date).format('MM/DD/YY HH:mm')
        }
      } else {
        return 'Unknown'
      }
    },
   getTargetParent(station){
      var obj = this.stationsData.find(p => p.stationName == station)
      return obj.stationParent ? obj.stationParent : obj.stationName
    },
  getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user
        } else if (this.userData.role == 'admin'){
          return this.userData
        } else {
          return null
        }
      } else {
        return ''
      }
    },
      getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
  getFormatedTime(data){
    if(data){
      return moment(data).format('MM/DD/YY HH:mm')
    } return 'Invaild'
  },
  getApprovalUserLastName(data){
    var users = _.cloneDeep(this.deptContactData)
    var user = users.find(p => p.id == data)
    if(user){
      return user.lastName
    } return 'Invaild'
  },
  viewOvertimeMods(data){
      var modalObj = {}
      modalObj = data
      modalObj.mods = _.orderBy(data.mods, ['payrollModDatetime'], ['desc'])
      this.modalObj = data
      this.showModModal = true
    },
    executePrintJS(){
         this.printReportOn = true
      var printId = '#print_report_' + this.randomField
      html2canvas(document.querySelector(printId)).then(canvas => {
       var image = canvas.toDataURL("image/jpeg")
       return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              imageStyle: 'margin-bottom:20px;'
            })
   // 
    });
          this.$nextTick(function(){
        this.printReportOn = false
      })
  },
  calcHours(mods){
    var hours = 0
    for(var i in mods){
      hours = hours + parseFloat(mods[i].payrollModHours)
    }
    return hours
  },
  getDataForListReport(){
      var start = moment(this.startDate, "YYYY-MM-DD").toISOString()
      var end = moment(this.endDate, "YYYY-MM-DD").toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/payrolllist/' + start + '/' + end + '/' + this.payrollCodeArray + '/',
     headers: {
           'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
     this.payCodeReportData = response.data.filter(p => p.payrollModApproved == true)
     this.showFilter = true
   })

    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.printReport{
  width: 1200px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
