<template>
<div @click="addModalOpen">


  <b-modal
  class="mainPost"
       id="logbookModal"
       ref="logbookModal"
       v-model="showModal"
       :no-close-on-backdrop="trueBoo"
       :no-close-on-esc="trueBoo"
         >
         <template slot="modal-header"  v-if="!showModalConfirmation">
          <span> Create New Daily Logbook Post
            <img src="/images/siteicons/help-icon-right-align.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo">
            <b-btn size="sm" variant="outline-secondary" @click="toolTips = !toolTips, toolTipLocation = 1">Tips Off</b-btn></span>
           <b-btn size="sm" variant="danger" align="right" @click="showModalswitchEmitVehicle">x</b-btn>
         </template>
         <div class="modal-body"  v-if="!showModalConfirmation">
           <tutdata v-if="showTutVideo" singlePost="newlogbook" />
          <b-col align="left">
          <h5>Logbook Date:</h5>
          </b-col>
          <h5 :class="shiftDateCalcClass" @click="changeDate = !changeDate" v-show="!changeDate" id="todaysDateDisplay">Today: {{ currentDateSelected }} (click to edit)</h5>
          <datepicker v-if="changeDate" @input="setCurrentDate" v-model="currentDateSelectedChange"></datepicker>
            <div id="logbookBody">
            <h5 :class="shiftDateCalcClass">Shift: {{ shiftDateCalc }} </h5>
                <div id="stationDropDown">
                <multiselect v-model="stationSelectValue" :options="filterStationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please select a Station" label="stationName" track-by="stationName">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
                </multiselect>
                <div v-if="false" class="toolTipBox">First Select Your Station</div>
                </div>
                <h4 class="sub-section-heading">Logbook Passdown</h4>
                <b-container  id="tablePassDown">
                <draggable v-model="arrayOfPassDowns" draggable=".title-drag">
                <b-container v-for="post in arrayOfPassDowns" id="logbookPassDown" :key="post.index">
                    <b-row class="editRowValue"><b-col align="left">
                      <timepicker class="timeDesk" @change="timeEdit(timeValueReturned, post)" :v-on="timeValueSet(post.time)" v-model="timeValueReturned" format="HH:mm"></timepicker>
                      <input class="timeMobile" type="time" v-model="post.time" />
                      </b-col>
                      <b-col align="left"><h5 class="title-drag">Item {{ indexPosition(post) }}</h5></b-col>
                        <b-col cols="2" align="right"><b-btn size="sm"  @click.stop="removeCurrentRow(post)" variant="danger">X</b-btn></b-col></b-row>
                    <b-form-textarea :rows="3" placeholder="Please Enter Passdown Item" :max-rows="6" v-model="post.note" />
                  </b-container>
                </draggable>
               <b-btn class="mt-2" block  @click.stop="addNewRow" size="md" variant="outline-primary">Add New Item</b-btn>
              <div v-if="false" class="toolTipTop"></div>
              <div v-if="false" class="toolTipBox">First Select Your Station</div>
            </b-container>



              </div>

         </div>

         <template slot="modal-footer"  v-if="!showModalConfirmation"><h4 v-show="stationSelectValue == ''">Please Select a Station</h4>
            <div v-show="stationSelectValue != ''">
              <h4 v-show="arrayOfPassDowns == ''">Please add an item</h4>
             <b-btn v-show="arrayOfPassDowns != ''" variant="primary" @click="submitArticl">Submit</b-btn>
           </div>

       </template>
       <template slot="modal-header"  v-if="showModalConfirmation">
              Post Confirmation
       </template>
       <div  v-if="showModalConfirmation" class="modal-body">
              <div v-if="postData == ''">
                    ...Submitting post
                    <br>
                <img :src="'.e/images/siteicons/MalteseLoadingGif.gif'">
              </div>
              <div v-if="postData != ''">
                    Your Post has been submitted!
              </div>

              <logbook v-if="postData != ''" :logbook="postData" :showPost="true" />
            </div>
              <template slot="modal-footer"  v-if="showModalConfirmation">
                    <b-btn class="modal-default-button" @click.stop="showModalConfirmationGet" :disabled="postData == ''">
                      Close Message
                    </b-btn>
             </template>
       </b-modal>
   </div>
</template>

<script>
/* <b-form-select class="shiftSelectInput" text-field="name" value-field="tid" v-model="shiftSelect" :options="shiftsData">
  <option :value="null" disabled> Please select a Shift</option>
</b-form-select>
*/
import logbookdata from '../tracking/stationlogbook/stationlogbookfilterdata.vue'
import { mapState } from 'vuex'
import { bus } from '../../main'
import axios from 'axios'
import TutData from '../tracking/deptTutorialsdata.vue'
import DatePicker from '../inputfields/Datepickermod.vue'
import TimePicker from '../inputfields/timepicker.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable'
import _ from 'lodash'
export default {
  props:["showModal", "filterDate"],
components:{
      'tutdata' : TutData,
      'draggable' : draggable,
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,
      'logbook' : logbookdata
},
  data () {
    return {
      showTutVideo: false,
        trueBoo: true,
        titleDescription: '',
        title:'',
        showModalConfirmation: false,
        currentDateSelected: '',
        currentDateSelectedChange: '',
        testValue: null,
        detailedTextArea: '',
        eventTagsSelectValue:[],
        stationSelectValue:'',
        apparatusSelectValue:[],
        changeDate: false,
        field_passdown_list:[],
        arrayOfPassDowns: [],
        LogbookFormat: 'hh:mm',
        editButton: 'edit',
        showEditView: false,
        shiftSelect: null,
        postData: '',
        currentTime: '',
        timeData: '',
        toolTips: false,
        toolTipLocation: 1,
    }
  },
  created(){
        this.currentDateSelected = moment().format('MM/DD/YYYY');
        this.currentDateSelectedChange = this.currentDateSelected
        this.currentTime = moment().format('"HH:mm"')
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'eventTags',
          'apparatusData',
          'userData',
          'userPosts',
          'departmentFeed',
          'shiftsData',
          'siteconfig'
        ]),
        getToolTarget(){
          if(this.stationSelectValue == ''){
          return 'stationDropDown'
          } else if(this.stationSelectValue != '' && this.arrayOfPassDowns.length == 0){
            return 'addNewPassDown'
          }
        },
        getToolTitle(){
           if(this.stationSelectValue == ''){
          return 'First Select Your Station!'
           } else if(this.stationSelectValue != '' && this.arrayOfPassDowns.length == 0){
             this.toolTipLocation = 2
            return 'Click Here to Add an Entry'
          }
        },
      filterStationsData(){
        var stationParentIndex = [...new Set(this.stationsData.map(p => p.stationParent))]
        var stations = this.stationsData.filter((p) => {
          if(p.stationName != '' && (this.userData.role == 'admin' || this.userData.role == 'adminchief')){
            return p
          } else if (this.userData.role == 'fireops') {
            var index = stationParentIndex.indexOf(p.stationName)

             if((p.stationParent != null && p.stationParent != undefined && p.stationParent != '') || index != -1){
               return p
             }
          }
          else{
            return p.stationParent != null && p.stationParent != undefined && p.stationParent != ''
          }
        })
        return _.orderBy(stations, ['stationName'], ['asc'])

      },
      formatedDateExport () {
        var dateString = moment(this.currentDateSelected, 'MM/DD/YYYY').format("YYYY-MM-DD")
        var timeString = dateString + 'T' + '00:00:00'
        var offSet = moment().utcOffset()
        var hourOffSet = ((offSet * -1)/60)
        var timeStringForm = moment(timeString).add(hourOffSet, 'hours')
        var timeStringAdd = moment(timeStringForm).format("YYYY-MM-DDTHH:mm:ss")
        return timeStringAdd + 'Z'
      },
      shiftDateCalc(){
               var payroll = this.siteconfig.payroll
              var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var shift = payroll.shifts[roundedDated]
              return shift.shift
    },
    shiftDateCalcClass(){
      var shift = this.shiftDateCalc
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shift)
      if(obj){
        return obj.color
      } return shift
    },


    },
 beforeDestroy(){
  document.body.classList.remove('modal-open');
      },
  methods:{
    addModalOpen(){
     document.body.classList.add('modal-open');
    },
    setCurrentDate(){
      this.currentDateSelected = moment(this.currentDateSelectedChange).format('MM/DD/YYYY')
    },
    shiftId(data){
      var shiftObj = this.shiftsData.filter(p => p.name.match(data))
      return shiftObj[0].tid
    },
    setTimeNow(){
      var timeGet = moment()
      this.currentTime = moment(timeGet).format('"HH:mm"')
    },
    indexPosition(post){
        var idx = this.field_passdown_list.indexOf(post)
        return idx + 1
    },
    showModalSwitchEmit () {
      bus.$emit('switchEmit');
    },
    showModalswitchEmitVehicle () {
      this.eventTagsSelectValue = ''
      this.stationSelectValue = ''
      this.field_passdown_list = []
      this.shiftSelect = ''
      bus.$emit('switchEmit');
    },
    showModalConfirmationSwitch (event) {
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    showModalConfirmationGet (event) {
      bus.$emit('refreshUserPosts')
      this.showModalConfirmation = !this.showModalConfirmation
      this.showModalSwitchEmit()
      this.eventTagsSelectValue = ''
      this.stationSelectValue = ''
      this.field_passdown_list = []
      this.shiftSelect = ''
    },
     addNewRow(logbook){
      var currentTime = this.currentTime.replace(/"/g,'')
      this.arrayOfPassDowns.push({ time: currentTime, note: '' })
    },
    removeCurrentRow(post){
      var idx = this.arrayOfPassDowns.indexOf(post)
      this.arrayOfPassDowns.splice(idx, 1)
    },
    timeEdit(eventData, post){
      var NewTimeValue = eventData.HH + ':' + eventData.mm
      var idx = (this.arrayOfPassDowns.indexOf(post))
      this.$set(this.arrayOfPassDowns, idx, { time: NewTimeValue, note: post.note})
    },
    timeValueSet(value){
      var valueSplit = value.split(':')
      var valueObj = { HH: valueSplit[0], mm: valueSplit[1], }
      this.timeData = value
      this.timeValueReturned = valueObj
    },
        indexPosition(post){
        var idx = this.arrayOfPassDowns.indexOf(post)
        return idx + 1
    },
    postValueMethod(value){
      var timeNote = value.split(' || ')
      var timeOnly = timeNote[0].split('time:')
      this.timeOnly = timeOnly[1]
      var noteOnly = timeNote[1].split('note:')
      this.noteOnly = noteOnly[1]
    },
    editValueMethod(value){
      var timeNote = value.split(' || ')
      var timeOnly = timeNote[0].split('time:')
      var timeHHmm = timeOnly[1]
      var timeArrayHHMM = timeHHmm.split(':')
      this.timeOnlyHH = timeArrayHHMM[0]
      this.timeOnlyMM = timeArrayHHMM[1]
      var noteOnly = timeNote[1].split('note:')
      this.noteOnly = noteOnly[1]
      this.timeSetData = timeHHmm
      this.submissionTimeSet = {
        value:{
            HH: this.timeOnlyHH,
            mm: this.timeOnlyMM
        }};

    },
     getStationParent(data){
        if(data != undefined && data != ''){
        var parent = this.stationsData.filter(p => {
            return p.stationName == data
        })
        if(parent[0].stationParent){
        return parent[0].stationParent }
        else {
          return parent[0].stationName
        }
        } else {
           return ''
        }
      },
    submitArticl(){
     axios({

      method: 'post',
      url: '/api/v1/content/logbook',
      data: {
              "title": this.currentDateSelected + ' | ' + this.stationSelectValue.stationName + ' | ' + this.shiftDateCalc + ' | ' + this.userData.lastName,
              "logbookDate": this.formatedDateExport,
              "logbookStation": this.stationSelectValue.stationName,
              "logbookShift": this.shiftDateCalc,
              "logbookPassdown": this.arrayOfPassDowns,
              "logbookAuthor": this.userData.firstName + ' ' + this.userData.lastName,
              "logbookAuthorId": this.userData.id,
              "logbookStationParent" : this.getStationParent(this.stationSelectValue.stationName)
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      },

    }).then( response => {
       this.postData = response.data
        bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list')
      })
  this.showModalConfirmation = !this.showModalConfirmation;

  }
}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

.multiselect__option--highlight {
  background: #f0454a;
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>

<style scoped>
.toolTipBox {
    position: absolute;
    left: 10%;
    margin-top: 5px;
    background: red;
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
}
.toolTipBox::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 80%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent red transparent;
}
.A-shift {
  background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;

}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.Red-shift{
  background: #fff;
  color: #e2070d;
}
.Yellow-shift{
  background: #ffffff;
  color: #ffc107;
}
.Blue-shift{
    background: #fff;
  color: #201B9F;
}
.Green-shift{
    background: #fff;
  color: #0FB906;
}
#tablePassDown .container {
  padding-left: 0;
  padding-right: 0;
}
.shiftSelectInput{
  margin-bottom: 10px;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
text-align: center;
margin-top: 20px;
}
h4.sub-section-heading{
  padding: 5px;
}
.addNewButton {
  margin-top: 10px;
}
.editRowValue {
  margin-top: 20px;
  margin-bottom: 10px;
}
.input-group{
  margin-bottom: 10px;
}
input {
  border-radius: 5px;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 15px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;
}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
@media screen and (max-width: 920px) {
  .time-picker.timeDesk{
    display: none;
  }
  .timeMobile{
    display: block;
    width: 100px;
  }
}


</style>
