<template>
<div id="homelanding">

  <div class="headerSpan">Logistics Tracking (BACK)</div>
  <div class="fullScreen" v-if="readCodeTrue">
    <b-btn @click="readCodeTrue = !readCodeTrue" variant="danger" >Close</b-btn>
      <QuaggaScanner v-if="scannerOn" @onEmitDetected="result" />
   
      </div>
         <div v-if="results">{{ results.codeResult.code }}</div>
      <b-btn v-if="userData.role == 'admin'" @click="readCodeTrue = !readCodeTrue, scannerOn = !scannerOn">Read</b-btn>
      <b-btn v-if="userData.role == 'admin'" @click="getConverstationsAll" variant="secondary">Get Messages</b-btn>
<b-container fluid>
          <b-navbar class="blogSpanHeader" type="dark" variant="dark">
        <b-nav-form>
          <b-navbar-brand >Items ({{ formatedTableValues.length }})</b-navbar-brand>
          <b-form-input class="mr-3" type="number" v-model="searchSerial" placeholder="Seiral"></b-form-input>
          <b-form-input  class="mr-3" type="text" v-model="searchAsset" placeholder="Asset" ></b-form-input>
          <b-form-input  class="mr-3" type="text" v-model="searchAssigned" placeholder="Assigned" ></b-form-input>
      <b-form-radio-group id="radios2" v-model="sortBySelected" name="radioSubComponent">
        <b-form-radio value="manufactureDate" class="text-light">Manufacture</b-form-radio>
        <b-form-radio value="lastInspectionDate" class="text-light">Inspection</b-form-radio>
      </b-form-radio-group>
          <b-form-select v-model="sortOrder">
            <option value="desc">Newest</option>
            <option value="asc">Oldest</option>
          </b-form-select>
        </b-nav-form>
        <b-nav-form class="ml-auto">
          <b-btn variant="success">Add New</b-btn>
        </b-nav-form>
      </b-navbar>
   <div v-if="false">{{ formatedTableValues }}</div>
  <b-table v-if="logisticsData" :fields="fields" :items="formatedTableValues" :current-page="currentPage" :per-page="10">
        <template slot="manufactureDate" slot-scope="data">
      {{ formatDataTime(data.item.manufactureDate) }}
    </template>
    <template slot="lastInspectionDate" slot-scope="data">
      {{ formatDataTime(data.item.lastInspectionDate) }}
    </template>
    <template slot="itemHistory" slot-scope="data">
      <b-btn size="sm" variant="outline-primary" @click="showModalData = data.item, showData = true">{{ data.item.currentAssignment.note }}</b-btn>
    </template>
  </b-table>
     <b-pagination class="pt-2" align="center" size="md" v-if="formatedTableValues" :total-rows="formatedTableValues.length" v-model="currentPage" :per-page="10" />
</b-container>
<b-modal v-model="showData" size="lg" title="Item History">
  <b-container fluid>
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="current">
      {{ showModalData.currentAssignment }}
          </b-tab>
          <b-tab title="history">
              <b-navbar class="blogSpanHeader" type="dark" variant="dark">
        <b-nav-form>
          <b-navbar-brand >Items ({{ sortedShowModalData.length }})</b-navbar-brand>
      <b-form-radio-group id="radios2" v-model="sortBySelectedNotes" name="radioSubComponent2">
         <b-form-radio value="" class="text-light">All</b-form-radio>
        <b-form-radio value="note" class="text-light">Notes</b-form-radio>
        <b-form-radio value="assigned" class="text-light">Assigned</b-form-radio>
      </b-form-radio-group>
          <b-form-select v-model="sortOrderNotes">
            <option value="desc">Newest</option>
            <option value="asc">Oldest</option>
          </b-form-select>
        </b-nav-form>
           </b-navbar>
            <b-table :items="sortedShowModalData" :fields="['type','date', 'note']">
              <template slot="date" slot-scope="data">
                {{ formatDataTime(data.item.date)}}
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-container>
</b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import axios from 'axios'
import QuaggaScanner from '../inputfields/Scanner.vue'
import moment from 'moment'
//import logisticsDummyData from './logistsDummyExport.js'

export default {
components: {
    QuaggaScanner
},
data() {
    return {
      readCodeTrue: false,
      scannerOn: false,
      results: null,
      readerType: "code_39_reader",
      currentPage: 1,
      logisticsData: [],
      sortBySelectedNotes: '',
      sortOrderNotes: 'asc',
      logisticsDataStart: [
        {
          id: '0180018101',
          serialNumber: 'aalsdjlaksdfjf',
          itemDescription: 'Globe Jacket',
          assetId: '4832048',
          manufactureDate: '2017-08-02T08:00:00',
          lastInspectionDate: '2018-08-02T08:00:00',
          outOfServiceData: '',
          itemHistory: [
            {type: 'note', date: '2018-08-02T08:00:00', note: 'Item was Inspected', assignedType: 'deptContactData', assignedId: '7e7c4809-c2f8-4dc2-b96c-7ba56ef21221'},
            {type: "assigned", date: "2016-09-26T10:28:31.000Z", note: 'Logistics', asignedType: 'stationsData', assignedId: 'b76caf9e-f164-411d-b21c-841b8096743e'},
            {type: "assigned", date: "2016-09-27T10:28:31.000Z", note: 'Engine 02', asignedType: 'apparatusData', assignedId: '68b52e4e-ef09-4d36-af1f-b9b5afd3645b'},
            {type: "assigned", date: "2016-09-28T10:28:31.000Z", note: 'Michael Cavallo', asignedType: 'deptContactData', assignedId: '7e7c4809-c2f8-4dc2-b96c-7ba56ef21221'},          
          ],
          itemCost: '$300'
        }
      ],
      noteSort: 'desc',
      fields: [
        'serialNumber',
        'assetId',
        {key: 'itemDescription', label: 'Description'},
        'manufactureDate',
        {key: 'lastInspectionDate', label: 'Last Inspection'},
        'itemHistory',
        {key: 'itemCost', label: 'cost'},
      ],
      deviceWidth: '',
      deviceHeight: '',
      showData: false,
      showModalData: {},
      modalType: '',
      searchSerial: '',
      searchAsset: '',
      searchAssigned: '',
      sortOrder: 'asc',
      sortBySelected: 'lastInspectionDate',
}
},
created () {
this.setLogisticsData()
},
beforeDestroy(){
  
},
mounted(){

},
watch:{

},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'deptContactData',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData'
  ]),
  formatedTableValues(){
    var data = this.logisticsData.filter(p => {
      return p.serialNumber.includes(this.searchSerial) &&
            p.assetId.includes(this.searchAsset) 
         && p.currentAssignment.note.toLowerCase().includes(this.searchAssigned.toLowerCase())
    })
    return _.orderBy(data, [this.sortBySelected], [this.sortOrder])
  },
  sortedShowModalData(){
    if(this.showModalData && this.showModalData.itemHistory){
    var data = this.showModalData.itemHistory
    var type = data.filter(p => {
      return p.type.match(this.sortBySelectedNotes)
    })
    return _.orderBy(type, ['date'], [this.sortOrderNotes])
    } else {
      return []
    }
  },
  
},
methods: {
       getConverstationsAll(){
          axios({
            method: "get",
            url: "/api/v1/chat/adminview",
            headers: {
             Authorization: this.getSessionData,
              "Content-Type": "application/json",
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }
          }).then(response => {
            console.log(response.data)

          });
        },
  sortNoteItems(array, sort){
    var array = _.orderBy(array, ['date'], [sort])
    return array
  },
  formatDataTime(date){
    return moment(date).format('MM/DD/YY')
  },
  result (result) {
  console.log('detected: ', result);
  this.results = result
  this.readCodeTrue = !this.readCodeTrue
  this.scannerOn = !this.scannerOn
},
 setLogisticsData(){
   //var user = this.deptContactData
   var data = this.logisticsDataStart
   if(data){
   //data.forEach(item => item['itemNotes'] = [{date: item.lastInspectionDate, note: 'Cleaned And Inspected, No Issues Noted, Gear returned to Service', author: 'Rick Wilbert'},{date: moment(item.lastInspectionDate).add(10, 'd').format('YYYY-MM-DDTHH:mm:ssZ'), note: 'Repaired after failed inspection, returned for service', author: 'Rick Wilbert'}])
   //data.forEach(item => item.assetId = item.assetId.toString())
  // data.forEach(item => item.itemCost = item.itemCost.toString())
  // data.forEach(item => item = this.getRandomUser(item, user))
  data.forEach(item => item['currentAssignment'] = this.getCurrentAssignment(item.itemHistory))
  console.log(data)
   this.logisticsData = data
   
   /* var data2 = JSON.stringify(data)
    var blob = new Blob([data2], {type: 'text/plain'})
    var e = document.createEvent('MouseEvents'),
    a = document.createElement('a');
    a.download = "test.json";
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
    e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e); */
   }
 },
 getCurrentAssignment(data){
   var history = data
   var hold = data.filter(p => {
     return p.type == 'assigned'
   })
   if(hold){
     var current =  _.orderBy(hold, ['date'], ['desc'])
     return current[0]
   } else {
     return {type: "assigned", date: "unknown", note: 'None Listed', asignedType: '', assignedId: ''}
   }
 },
 /**    item['assignedType'] = 'deptContactData'
   item['assignedId'] = randomElement.id
   item['assignedName'] = randomElement.firstName + ' ' + randomElement.lastName
   item['assignedDate'] = item.manufactureDate */
 getRandomUser(item, user){
  /* var length = 3
   item['assigned'] = []
   for(var i = 0; i < length; i++){
   var randomElement = _.sample(user)
   var itemObj = {}
   if(randomElement){
   itemObj['assignedType'] = 'deptContactData'
   itemObj['assignedId'] = randomElement.id
   itemObj['assignedName'] = randomElement.firstName + ' ' + randomElement.lastName
   itemObj['assignedDate'] = moment(item.manufactureDate).add(i, 'd')
   item.assigned.push(itemObj)
   } else {
     this.getRandomUser(item)
   }
   } */
   var array = _.cloneDeep(item.assigned)
   var array2 = _.orderBy(array, ['assignedDate'], ['desc'])
   var newObj = array2[0]
   item['assignedType'] = newObj.assignedType
   item['assignedId'] = newObj.assignedId
   item['assignedName'] = newObj.assignedName
   item['assignedDate'] = newObj.assignedDate
 }
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.fullScreen {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: white;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
