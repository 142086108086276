import { render, staticRenderFns } from "./staffingPortal.vue?vue&type=template&id=0cde5c79&scoped=true"
import script from "./staffingPortal.vue?vue&type=script&lang=js"
export * from "./staffingPortal.vue?vue&type=script&lang=js"
import style0 from "./staffingPortal.vue?vue&type=style&index=0&id=0cde5c79&prod&lang=css"
import style1 from "./staffingPortal.vue?vue&type=style&index=1&id=0cde5c79&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cde5c79",
  null
  
)

export default component.exports