<template>
  <div class="hello" v-if="resetTable">
      <b-navbar type="dark" variant="dark">
       <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Check Items:</b-navbar-brand>
     <b-btn class="ml-2" size="sm" variant="success" @click="toggleAddNewCheckItem">Add New Check Item</b-btn>
          <b-btn class="ml-2"  size="sm" variant="danger" @click="edit = !edit">Edit Check Items</b-btn>
     </b-nav-form>
      </b-navbar>
    <b-table striped hover :fields="fields" :items="addNewCheckItem == true ? newCheckItems : holdData">
      <template slot="checkitems" slot-scope="data">
         <h6 class="font-weight-bold">Check Item Title:</h6>
         <b-form-input  v-if="!edit" type="text" v-model="data.item.checkitemsTitle" @input="addNewCheckItem == true ? data.item.checkitemsName = data.item.checkitemsTitle.replace(/ /g, '') : ''" class="mb-2" />
         <h6 v-if="edit">{{ data.item.checkitemsTitle }}</h6>
         <h6 class="font-weight-bold">Check Item Menu Id:</h6>
       <b-form-input  v-if="!edit" type="text" v-model="data.item.checkitemsName" />
           <h6 v-if="edit">{{ data.item.checkitemsName }}</h6>
       <p class="text-danger" v-if="!edit">Menu Id Can not be the same as another Check Item & Must not contain spaces</p>
      </template>
      <template slot="checkitemsList" slot-scope="data">
        <b-container v-if="edit">
          <b-row><b-col  class="font-weight-bold">Label</b-col><b-col  class="font-weight-bold">Type</b-col><b-col class="font-weight-bold">Default</b-col></b-row>
           <b-row class="mt-1" v-for="(item, index) in data.item.checkitemsList" :key="index">
             <b-col>{{ item.itemName }}</b-col><b-col>{{ item.itemType }}</b-col><b-col>default({{ item.itemDefaultValue }})</b-col>
             </b-row>
        </b-container> 
        <b-container v-if="!edit">
        <b-row><b-col>Label</b-col><b-col>Type</b-col><b-col>Default</b-col></b-row>
         <b-row v-for="(item, index) in data.item.checkitemsList" :key="index">
                <b-col class="genLeft"><b-form-input type="text" v-model="item.itemName" /></b-col>
                <b-col><b-form-select v-model="item.itemType" :options="typeOptions" /></b-col>
                <b-col class="genRight">
                    <b-form-input v-if="item.itemType == 'Number'"  :class="{inputError: item.value == '' }" type="number" inputmode="numeric" pattern="[0-9]*" v-model="item.itemDefaultValue" />
                    <b-form-select v-if="item.itemType == 'Boolean'" v-model="item.itemDefaultValue"><option value="Yes">Yes</option><option value="No">No</option></b-form-select>
            </b-col>
            <b-col cols="*"><b-btn size="sm" variant="danger" @click="data.item.checkitemsList.splice(index, 1)">X</b-btn></b-col>
             </b-row>
             <b-btn class="mt-2" variant="outline-primary" @click="addNewItemToList(data.item)">Add New List Item</b-btn>
        </b-container>

        </template>
        <template slot="action" slot-scope="data">

          <b-btn variant="outline-info" :disabled="!addNewCheckItem ? checkForDisabled(data.item) : (data.item.checkitemsName == '' || data.item.checkitemsList.length == 0 || data.item.checkitemsTitle == '')" @click="!addNewCheckItem ? updateTaxItem(data.item) : createTaxItem(data.item)">Save</b-btn>
           <b-btn v-if="!addNewCheckItem" variant="outline-info" @click="removeTaxItem(data.item.id)">Delete</b-btn>
        </template>
      </b-table>
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['taxData'],
  name: 'hello',
  components: {

  },
  data () {
    return {
      edit: false,
      resetTable: true,
      addNewCheckItem: false,
      newCheckItems: [],
      typeOptions: [
        'Number',
        'Boolean',
        'Signature'
      ],
      fields: [
        'checkitems',
        'checkitemsList',
        'action'
      ]
    }
  },
  created(){
   var data = _.cloneDeep(this.taxData)
   this.holdData = _.orderBy(data, ['checkitemsName'], ['asc'])
    
  },
  computed: {
      ...mapState([
        'userData',
        'documentTags',
        'shiftsData',
        'eventTags',
        'blogType',
        'departmentRanks',
        'departmentSpecialties',
        'vehicleCheckDetails',
        'vehicleCheckItems',
        'workgroupData',
        'getSessionData'
      ]),
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },
      /*

      How to filter against two arrays

      docArray(){
        var loaded = [... new Set(this.documentsApi.map(p => p.field_document_id))]
        var bulk = [... new Set(this.docArrayPre.map(p => p.field_document_id))]
        var test = this.docArrayPre
        console.log(loaded)
        console.log(bulk)
        for(var i = 0; i < loaded.length; i++){
          var index = bulk.indexOf(loaded[i])
          if(index > -1){
          test.splice(index, 1)}
        }
        return test

      } */
  },
  watch: {
    vehicleCheckItems(newData){
      var data = _.cloneDeep(newData)
      this.holdData = _.orderBy(data, ['checkitemsName'], ['desc'])
      this.resetTable = false
            this.$nextTick(function(){
        this.resetTable = true
      })
    },
    edit(newData){
      if(!newData){
        this.fields.push('action')
      } else {
        this.fields.splice(this.fields.indexOf('action'), 1)
      }
    }
},
  methods: {
    toggleAddNewCheckItem(){
      this.addNewCheckItem = true
      this.edit = false,
      this.newCheckItems = [{checkitemsTitle: '', checkitemsName: '', checkitemsList: []}]
    },
    addNewItemToList(item){
      var id = Math.random().toString(36).substr(2, 15)
      var obj = {_id: id, itemLabel: '', itemType: 'Number', itemDefault: ''}
      item.checkitemsList.push(obj)
    },
    checkForDisabled(data){
      var current = _.cloneDeep(this.vehicleCheckItems)
      var index = current.filter(p => {
        return p.checkitemsName == data.checkitemsName })
      var currentObj = this.vehicleCheckItems.find(p => p.id == data.id)
      if(currentObj){
        var array =  _.isEqual(currentObj.checkitemsList, data.checkitemsList)
      if(currentObj.checkitemsName != data.checkitemsName
          || currentObj.checkitemsTitle != data.checkitemsTitle
          || !array
        ){
          if(index.length < 2){
            console.log(index.length)
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
       
    },
    createTaxItem(data){
    var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkitems', headers: headers, expire: true}
     data.checkitemsName = data.checkitemsName.replace(/ /g, '')
    axios({
      method: 'post',
      url: '/api/v1/taxonomy/checkitems/',
      data: data,
      headers: payload.headers.headers,
      }).then(response => {
      this.addNewCheckItem = false
      this.edit = true
      this.newCheckItems = []
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)

      })
    },
    updateTaxItem(data){
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkitems', headers: headers, expire: true}
     data.checkitemsName = data.checkitemsName.replace(/ /g, '')
    axios({
      method: 'patch',
      url: '/api/v1/taxonomy/checkitems/' + data.id,
      data: data,
      headers: payload.headers.headers,
      }).then(response => {
  
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)

      })
    },
    removeTaxItem(id){
      var result = confirm('Are You Sure You Want to Delete This Check Item?')
      if (result) {
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkitems', headers: headers, expire: true}
    axios({
      method: 'delete',
      url: '/api/v1/taxonomy/checkitems/' + id,
      headers: payload.headers.headers,
      }).then(response => {
  
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)

      })
    } else {}
    },
  
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
