import { render, staticRenderFns } from "./recoTable.vue?vue&type=template&id=61afddd8"
import script from "./recoTable.vue?vue&type=script&lang=js"
export * from "./recoTable.vue?vue&type=script&lang=js"
import style0 from "./recoTable.vue?vue&type=style&index=0&id=61afddd8&prod&lang=css"
import style1 from "./recoTable.vue?vue&type=style&index=1&id=61afddd8&prod&slot-scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports