<template>
<div id="homelanding">
<b-container fluid class="px-0">
   <dailyroster v-if="rosterProfiles.length > 0 && rosterData.length > 0 && rosterReload && tvView && apparatusData && stationsData"
                 :tvView="true" 
                 :stationVar="stationVar"
                 :payrollCodesData="payrollCodesData"
                 :rosterProfiles="rosterProfiles" 
                 :selectedDateHold="selectedDate"
                 :rosterData="rosterData"
                 :apiKeyToken="apiKeyToken"
                 :stationsData="stationsData"
                 :apparatusData="apparatusData"
                 @updateSelectedDate="updateSelectedDate" 
                 :payrollModDailyRosterData="payrollModDailyRosterData" />

</b-container>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import axios from 'axios'
import moment from 'moment'
import dailyroster from './tvPayroll/dailyRosterTv.vue'
export default {
components: {
  dailyroster,
},
props: ['tvView', 'stationVar', 'apiKeyToken', 'stationsData', 'apparatusData'],
data() {
    return {
  slide: 0,
  verticalView: true,
  resetRoster: true,
  showAdmin: false,
  selectedDate: '',
  showCalendar: true,
  showModal: false,
  previousMod: null,
  rosterReload: true,
  userSelected: null,
  userViewToggle: false,
  checkActive: false,
  tabIndex: 0,
  events: [],
  rosterData: [],
  rosterProfiles: [],
  payrollCodesData: [],
  payrollBanksData: [],
  payrollAccuralData: [],
  payrollModDailyRosterData: [],
  userRosterProfileDaysWithMods: [],
  userMonthViewModsOrig: [],
  userRosterProfileDays: [],
  rosterUserProfiles: [],
  userMonthViewMods: [],
  calendarHeader: 'Employee Monthly Schedule',
  currentView: {}

}
},
created () {
  this.selectedDate = moment().format('YYYY-MM-DD')
  window.addEventListener("resize", this.checkWidth)
  this.getRosterProfile()
  this.getRoster()
  this.getPayrollCodes()
  this.getPayrollModByDate()

},
beforeDestroy(){
  	window.removeEventListener("resize", this.checkWidth);
},
watch: {

},
computed: {
  ...mapState([
    'siteconfig',
    'userData',
    'getSessionData',
    'eventTags'
  ]),

},
mounted(){

},
watch:{
  selectedDate(newData){
    //console.log(newData)
    this.getPayrollModByDate()
  }
},
methods: {
  consolelog(){
    console.log('Click Registered')
  },
  showRosterData(data){
    this.getPayrollModByDate(moment(data.date._d).format('YYYY-MM-DD'))
    this.tabIndex = 1
  },
  checkWidth(){
        var width = document.documentElement.clientWidth;
        if(width < 750){
          this.verticalView = false
        } else { this.verticalView = true }
  },
  getNewModData(){ 
    this.getPayrollModByDate()
   // this.getPayrollModByDateByUser()
  },
  updateSelectedDate(data){
    this.resetRoster = false
    console.log('RESET DATA')
    this.selectedDate = data
    this.$nextTick(function(){
      this.resetRoster = true
    })
  },
  getRosterProfile(){
    axios({ 
     method: 'get',
     url: '/api/v1/staffing/rosterprofile/tv',
     headers: {
         'Authorization': this.apiKeyToken,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     this.rosterProfiles =  response.data
   })
    },
    getPayrollModByDate(selectedDate){
    //  this.rosterReload = false
      this.previousMod = ''
      this.showModal = false
      if(!selectedDate){
      var start = moment(this.selectedDate + 'T' + this.siteconfig.payroll.shiftStartTime)
      } else {
        var start = moment(selectedDate + 'T' + this.siteconfig.payroll.shiftStartTime)
      }
      var nextShift = moment(start).add(1, 'day').toISOString()
      var hourstart = start.subtract(1, 'minute')
      hourstart = moment(hourstart).toISOString()
      var hourend = start.add(26, 'hours')
      hourend = moment(hourend).toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/dailytv/' + hourstart + '/' + hourend,
     headers: {
        'authorization': this.apiKeyToken,
        'Content-Type' : 'application/json',
  }
   }).then( response => {
     //console.log(response.data)
      var array = response.data.filter(p => {
     if(p.payrollModDatetime && p.payrollModHours != 0){
       return moment(p.payrollModDatetime).isBefore(nextShift)
     }
     })
     //console.log(array)
    this.payrollModDailyRosterData = array
    // this.rosterReload = true
   })

    },
    getRoster(){
    axios({
     method: 'get',
     url: '/api/v1/staffing/roster/tv',
     headers: {
            'authorization': this.apiKeyToken,
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache',
     }
   }).then( response => {
     this.rosterData = response.data
   })
    },
    updatePayrollMod(data){
  //   console.log(this.userViewToggle)
  //    console.log(data)
      if(this.userViewToggle && this.userSelected){
         this.showModal = true
         var obj = data
         obj.payrollModTargetUserId = this.userSelected.id
        this.previousMod = obj
      } else {
      this.showModal = true
   //   console.log(data)
      this.previousMod = data
      }
    },
    getPayrollCodes(){
        axios({
        method: 'get',
        url: '/api/v1/staffing/payrollcode/tv',
        headers: {
            'authorization': this.apiKeyToken,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.payrollCodesData = response.data

      })
    },
    modalHideEvent(data){
     this.previousMod = ''
     this.showModal = false
    },


},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
