<template>
  <div>
            <div class="currentTime"><h1>{{ currentTime }}</h1></div>
            <div class="deptName"><h1>{{ siteconfig.deptName }}</h1></div>
        <div class="stationName" v-if="station && station.stationName"><h1>{{ station.stationName }}</h1></div>
    <b-container class="mx-0" fluid>
    <b-container fluid  v-if="station && station.stationName">
    <b-row v-if="station && station.stationName">
      <b-col cols="4" class="">
    <payrollStaffing :tvView="true" :stationVar="station.id" :apiKeyToken="apiKeyToken" :stationsData="stationsData" :apparatusData="apparatusData" />
      </b-col>
      <b-col cols="8">
        <b-row class="paddingTopTwenty">
          <b-col v-for="date in next3Shifts">
            <b-card 
             :header-bg-variant="date.shift.bootstrap"
             header-text-variant="white"
             header-class="font-weight-bold"
             :border-variant="date.shift.bootstrap"
             :header="date.text + ' | ' + date.shift.shift + ' | ' + getDateFormated(date.date)"
             no-body
             >
             <div v-for="event in getEventsFromStation(date.date, station.id)" :key="event.id">
                <EventCard :eventdata="event" :tvView="true" :showBodyTv="checkCurrentTime(event.eventDate)" :key="event.id" />
               </div>
              </b-card>
          </b-col>
        </b-row>
      </b-col>
      </b-row>
    </b-container>
    </b-container>
    </div>
  </div>
 
</template>

<script>
import { mapState } from 'vuex'
import payrollStaffing from './staffingPortalTv.vue'
import moment from 'moment'
import axios from 'axios'
import EventCard from '../tracking/events/eventsfilterdata.vue'
export default {
  name: 'tvview',
  components: {
    payrollStaffing,
    EventCard
  },
  props: ['apiKeyToken'],
  data () {
      return {
        stationIndex: 0,
        station: null,
        refresh: false,
        currentTime: '',
        currentDate: '',
        tvCalendarEvents: [],
        stationsData: null,
        apparatusData: null,

    }
  },
  created(){
    setInterval(this.getTime, 1000);
  },
  mounted(){
    this.getStationsData()
    this.getApparatusData()
    this.getNewData()
  },
  computed: {
    ...mapState([
        'siteconfig' 
    ]),
    stations(){
      var array = _.cloneDeep(this.stationsData)
      return array.filter(p => {
          if(p.stationParent){
            return p
          }
      })
    },
    next3Shifts(){
      var timeboo = moment().format('HHmm') < moment(this.siteconfig.payroll.shiftStartTime, "HH:mm:ss").format('HH:mm')
      var start = timeboo ? moment().subtract(1, 'd') : moment()
      var next = moment(start).add(1, 'd')
      var third = moment(next).add(2, 'd')
      var array = [{date: start, text: 'Current'},{date: next, text: 'Tomorrow'}, {date: third, text: 'Next'}]
      return array.map(item => {
        var obj = item
        obj['shift'] = this.dateDifference(item.date)
        return obj
      })

    },
    calendarEventsFiltered(){
      var events = this.tvCalendarEvents
      var timeboo = moment().format('HHmm') < moment(this.siteconfig.payroll.shiftStartTime, "HH:mm:ss").format('HH:mm')
      return events.filter(p => {
          var start = timeboo ? moment().subtract(1, 'd').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
          var end = timeboo ? moment().add(2, 'd').format('YYYY-MM-DD') : moment().add(3, 'd').format('YYYY-MM-DD')
          return moment(moment(p.eventDate).format('YYYY-MM-DD')).isSameOrAfter(start) && moment(moment(p.eventDate).format('YYYY-MM-DD')).isSameOrBefore(end)
      })
    }
    

  },
  methods: {
    getTime(){
      this.currentDate = moment()
      this.currentTime = moment().format('HH:mm:ss')
    },
    getDateFormated(date){
      return moment(date).format('MMMM Do')
    },
    dateDifference(data){
        var payroll = this.siteconfig.payroll
      var selectedVarDate = moment(data);
      var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
      var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
      var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
      var dateTenDigit = dateDiffCalNumber % 10;
      var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
      var shift = payroll.shifts[roundedDated]
      return shift
    },
    checkCurrentTime(date){
      var test = _.cloneDeep(moment(date))
      var beforeTime = moment().subtract(1, 'hour')
      var afterTime = moment().add(4, 'hour')
      var boo = moment(test).isBetween(beforeTime, afterTime)
      return boo
    },
    getNewData(){
      this.getTvCalendarEvents()
     // console.log('NEW ROSTER CHECK')
      var vm = this
        setTimeout(() => {
            vm.getNewData();
        }, 20000);
    },
     getApparatusData(){
      axios({
        method: 'get',
        url: '/api/v1/taxonomy/apparatus/tv',
        headers: {
            'content-type': 'application/json',
            'authorization': this.apiKeyToken,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      })
        .then((response) => {
            this.apparatusData = response.data
        })

    },
    getStationsData(){
      axios({
        method: 'get',
        url: '/api/v1/taxonomy/station/tv',
        headers: {
            'content-type': 'application/json',
            'authorization': this.apiKeyToken,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      })
        .then((response) => {
            this.stationsData = response.data
            this.stationSelect()
        })

    },
    getTvCalendarEvents(){
      var url = '/api/v1/content/event/tv/' + moment(this.currentDate).subtract(1, 'd').toISOString() + '/' + moment(this.currentDate).add(5, 'd').toISOString()
      axios({
        method: 'post',
        url: url,
        headers: {
            'content-type': 'application/json',
            'authorization': this.apiKeyToken,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      })
        .then((response) => {
            this.tvCalendarEvents = response.data
        })
    },
    getEventsFromStation(date, station){
      var stations = _.cloneDeep(this.stationsData)
      var station = stations.find(p => p.id == station)
      var datefilter = moment(date).format('DD')
      return this.calendarEventsFiltered.filter(p => {
          var returnDate = moment(p.eventDate).format('DD')
          var index = p.eventStation.indexOf(station.stationName)
          return index != -1 && datefilter == returnDate
      })
    },
    stationSelect: function(){
        this.refresh = false
        var index = _.cloneDeep(this.stationIndex)
        var station = this.stations[this.stationIndex]
        //console.log(station)
        if(!station){
          var index = -1
          this.station = this.stations[0] 
        } else {
          this.station = station
        }
        this.stationIndex = index + 1
        this.$nextTick(function(){
          this.refresh = true
        })
        var vm = this
        if(true){
        setTimeout(() => {
            vm.stationSelect();
        }, 10000);
        }
    }

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h2 {
  font-weight: normal;
}
.paddingTopTwenty {
  padding-top: 20px;
}
.deptName {
    height: 100px;
    font-size: 2.2em;
    margin-top: -20px;
    height: auto;
    color: #fff;
    text-align: center;
    padding: 0px 0px;
}
.currentTime {
    height: 100px;
    position: absolute !important;
    top: 20px;
    left: 0;
    height: auto;
    color: #fff;
    background: #333;
    text-align: center;
    padding: 0px 40px;
}
.currentTime:after {
    content: '';
    line-height: 0;
    font-size: 0;
    width: 0;
    height: 0;
    border-top: 80px solid #333333;
    border-bottom: 50px solid transparent;
    border-right: 50px solid transparent;
    border-left: 0px solid transparent;
    position: absolute;
    right: -50px;
    top: 0;
}
.stationName {
    height: 100px;
    position: absolute !important;
    top: 20px;
    right: 0;
    background: #333333;
    height: auto;
    color: #fff;
    text-align: center;
    padding: 0px 40px;
}
.stationName:before {
    content: '';
    line-height: 0;
    font-size: 0;
    width: 0;
    height: 0;
    border-top: 80px solid #333333;
    border-bottom: 50px solid transparent;
    border-left: 50px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    left: -50px;
}
.fullScreenSetup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8000;
    background:url('/images/MailBG.jpg') center top no-repeat; 
    background-size: cover;
    margin-top: -20px;
}
</style>
