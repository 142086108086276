<template>
  <div id="repair-listing">
    <b-container fluid :class="showPostData == true && !userPanel ? 'border rounded shadow' : ''" class="repairBodyWrap">
              <b-row v-if="showPostData" class="mt-2 pr-2" variant="secondary">
          <b-col align="right">
            <b-btn v-if="userData.role"
             size="sm" @click="buildEmailData(repair)" variant="outline-info" title="Send As Email">&#x2709;</b-btn>
              <b-btn :disabled="userPanel" size="sm" @click="printModalSet(repair)" variant="outline-secondary" :title="userPanel == true ? 'Please Go to the Station Page to Print' : 'Print Form'">&#x1f5b6;</b-btn>
              <b-btn v-if="editPermissionsCheck(repair) && !editEvent" size="sm" @click="editEvent = !editEvent" variant="outline-primary" title="Edit Form">	&#x270e;</b-btn>
              <b-btn v-if="editPermissionsCheck(repair) && editEvent"  size="sm" @click="editEvent = !editEvent" variant="outline-danger">X</b-btn>
          </b-col>
          </b-row>
        <div id="repairCheckTitle" data-clickout="stop">
        <h5 data-clickout="stop" @click="showPostDataToggle" id="repairHeading" :class="getShiftColor(repair.repairformShift)">{{ repair.title }}</h5>
      </div>

        <div v-if="!showPostData" class="subTitle"><span>
          <span id="repairStatus" :class="repair.repairformStatus"></span><span><strong>Item:</strong> {{ repair.repairformItem }}</span>
                        <span v-if="repair.repairformType == 'asset'" class="pl-2"><span class="font-weight-bold">Asset:</span><span>{{ getAssetData(repair.repairformDbid) }}</span></span></span></div>
        <b-container v-show="showPostData">
          <div v-if="!showEmail">
          <b-alert :show="emailNotification != ''" @click.native="emailNotification = ''">{{ emailNotification }}</b-alert>
          
              <b-container class="my-3">
      <b-row v-if="editEvent" class="mb-2">
        <b-col cols="*">
          <h6 class="pt-2 font-weight-bold">Sub Date:</h6>
        </b-col>
        <b-col>
          <b-form-input type="date" v-model="repairformDate" />
        </b-col>
      </b-row>
      <b-row>
        <b-col align="left">
  
            <span v-if="!editEvent"><strong>Item:</strong> {{ repair.repairformItem }}</span>
            <b-form-input v-if="editEvent" type="text" v-model="repair.repairformItem" />
          </b-col>
          <b-col v-if="!editEvent">
            <strong>Status:</strong> {{ repair.repairformStatus }} <span id="repairStatus" :class="repair.repairformStatus"></span>
            </b-col>
  
          <b-col v-if="editEvent">
            <b-form-select class="shiftSelectInput" text-field="repairstatusName" value-field="repairstatusName" v-model="repair.repairformStatus" :options="repairStatus" />
          </b-col>
    
       </b-row >
             </b-container>
    <b-row>
      <b-container class="my-3">
      <b-col align="left">
      <h6><strong>Description: </strong></h6>
      <p v-if="!editEvent">    {{ repair.repairformDescription }} </p>
      <b-textarea rows="3" v-if="editEvent" v-model="repair.repairformDescription" />
        </b-col>
        </b-container>
    </b-row>
      <div v-if="showPostData">
      <b-carousel v-if="repair.repairformPicture && repair.repairformPicture.length > 0" id="carousel1"
                                 style="text-shadow: 1px 1px 2px #333;"
                                  controls
                                 indicators
                                 background="#ababab"
                                :interval="4000"
                                v-model="slide">
        <template v-for="(pic, index) in repair.repairformPicture" >
        <b-carousel-slide :key="index" :img-src="siteconfig.deptStore + pic" />
        </template>
      </b-carousel>
      </div>


      <div >
        <h4 class="repairNoteTitle" v-if="repair.repairformNotes.length > 0">Repair Notes</h4>
      <b-container v-if="repair.repairformNotes.length > 0" v-for="item in repair.repairformNotes">
        <p class="mb-0">{{ item.note }}</p>
       <p class="mb-2"><em>{{ item.timestamp }} | {{ item.author }}</em></p>
       </b-container>
        <b-row class="mb-3"><b-col>
        <b-btn v-if="editEvent" variant="primary" @click="addNewRepairNote" size="sm">Add Repair Note</b-btn></b-col>
        <b-col>
          <b-btn v-if="editEvent" variant="primary" @click="addRepairCost = !addRepairCost" size="sm">{{ repair.repairCost ? 'Edit' : 'Add'}} Cost Info</b-btn></b-col></b-row>
      </div>
      <div v-if="editEvent">
        <b-container  v-if="newRepairNote != ''" v-for="newNote in newRepairNote" :key="newNote.index">
              <b-row>
                     <b-col align="left"><h5>{{ newNote.author }}</h5></b-col>
                     <b-col cols="2" align="right"><b-btn variant="danger" size="sm" @click.stop="removeCurrentRow(newNote)">x</b-btn>
                   </b-col>
               </b-row>
               <b-row>
                 <b-col align="left">
                   <b-input-group>
                   <timepicker class="timeDesk" v-model="newNote.time"></timepicker>
                 </b-input-group>
                 </b-col>
               </b-row>
             <b-form-textarea :rows="3" :max-rows="6" v-model="newNote.note" />


       </b-container>
      </div>
      <b-container v-if="editEvent && addRepairCost">
        <b-row class="mb-2"><b-col cols="*" class="ml-2 pt-1">Parts Cost:</b-col><b-col><b-form-input type="number" v-model="repairCostObj.parts" /></b-col></b-row>
        <b-textarea v-model="repairCostObj.partsDescription" placeholder="Parts Description, Invoice Numbers, Etc" />
        <b-row class="mb-2 mt-2"><b-col cols="*" class="ml-2 pt-1">Labor Cost:</b-col><b-col><b-form-input type="number" v-model="repairCostObj.labor" /></b-col></b-row>
        <b-textarea v-model="repairCostObj.laborDescription" placeholder="Labor Description, Invoice Numbers, Etc" />
      </b-container>
      <b-container v-if="!editEvent && repair.repairCost">
        <b-row><b-col align="right">Parts Cost:</b-col><b-col align="left">${{ repair.repairCost.parts }}</b-col></b-row>
        <b-row><b-col>{{ repair.repairCost.partsDescription }}</b-col></b-row>
        <b-row class="mt-2"><b-col align="right">Labor Cost:</b-col><b-col align="left">${{ repair.repairCost.labor }}</b-col></b-row>
        <b-row><b-col>{{ repair.repairCost.laborDescription }}</b-col></b-row>
      </b-container>
      <b-row v-if="editEvent" class="my-2">
        <b-col align="left" v-if="userData.role == 'admin' || userData.role == 'adminchief'">
          <b-btn variant="danger" size="sm" @click="deleteLogbook(repair)">Delete</b-btn>
        </b-col>
        <b-col align="right"><b-btn size="sm" variant="success" @click="submitArticl(repair)">Save</b-btn></b-col></b-row>
      </div>
      <div v-if="showEmail">
        <b-col align="right"><b-btn size="sm"  :disabled="disableEmailButtons"  variant="danger" @click="showEmail = !showEmail">X</b-btn></b-col>
        <h6> *** This will generate an email to Maintenance *** </h6>
        <h6><em>Please ensure you are following current directives</em></h6>
        <b-row>
          <b-col class="pt-1" cols="*">
        From:</b-col><b-col><b-form-input disabled :placeholder="userData.firstName + ' ' + userData.lastName + ' (' + userData.email + ')'" /></b-col>
        </b-row><b-row>
        <b-col class="pt-1" cols="*">
          To:</b-col><b-col>
            <multiselect v-model="emailTo" :options="deptContactData" label="lastName" track-by="lastName" :multiple="true" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="To">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.email }})</span>
                  </template>
                </multiselect>
            </b-col></b-row><b-row>
                  <b-col class="pt-1" cols="*">
          cc:</b-col><b-col>
            <multiselect v-model="emailCC" :options="deptContactData" label="lastName" track-by="employeeId" :multiple="true" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="CC">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.email }})</span>
                  </template>
                </multiselect>
          </b-col>
        </b-row>
        SUBJECT
        <b-form-input :placeholder="repair.repairformItem" v-model="emailSubject" />
        EMAIL BODY
        <b-textarea :rows="4" :placeholder="repair.repairformDescription" v-model="emailBody" />
        <b-col align="right">
        <b-btn :disabled="disableEmailButtons" class="mt-1" variant="primary" size="sm" @click="sendEmailForm" >Send Email</b-btn></b-col>

        </div>
        </b-container>
      </b-container>

    <b-modal v-model="showImageModal" :hide-header="true" :hide-footer="true">
        <div class="modalBoss" >
        <b-img v-if="repair.repairformPicture != ''"  fluid-grow @click="showImageModal = !showImageModal" :img-src="siteconfig.deptStore + repair.repairformPicture" />
      </div>
    </b-modal>
<printmodal v-if="showModal" :showModal="showModal" :apparatus="printApparatus" :station="printStation" :repairData="printRepair" :printType="printType" @close="showModal = false"/>
  </div>
</template>

<script>
import moment from 'moment'
import { bus } from '../../../main'
import axios from 'axios'
import TimePicker from '../../inputfields/timepicker.vue'
import { mapState } from 'vuex'
import PrintModal from './repairPrintModal.vue'
import Multiselect from "vue-multiselect"
export default {
  props:{
        repair: {
            type: Object
        },
        showPost: {
            type: Boolean,
            default: false,
        },
        userPanel: {
          type: Boolean,
          default: false
        }
      },
components:{
    'timepicker' : TimePicker,
    'multiselect' : Multiselect,
    'printmodal' : PrintModal,

},
  data () {
    return {
      addRepairCost: false, 
      repairCostObj: {
          parts: 0,
          labor: 0,
          laborDescription: '',
          partsDescription: '',
      },
      showImageModal: false,
      editEvent: false,
      newRepairNote: [],
      previousNotes : [],
      emailTo: [],
      emailCC: [],
      emailFrom: '',
      emailSubject: '',
      emailBody: '',
      showModal: false,
      emailNotification: '',
      showEmail: false,
      printApparatus: '',
      printStation: '',
      printRepair: [],
      showPostData: false,
      printType: '',
      slide: 0,
      sliding: false,
      disableEmailButtons: false,
      repairformDate: '',
      fields: [
        { key: 'note', label: 'Note'},
      ]
    }
  },
  created(){
  this.showPostData = this.showPost
  if(this.repair && this.repair.repairCost){
    this.repairCostObj = this.repair.repairCost
  }
  if(this.repair && this.repair.repairformDate){
    this.repairformDate = moment(this.repair.repairformDate).format('YYYY-MM-DD')
  }
},
  computed:{
  ...mapState([
    'getSessionData',
    'userData',
    'deptContactData',
    'repairStatus',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'logisticAssetData',
    'siteconfig'
  ]),
  filterAuthors () {
    return [...new Set(this.stationLogbooks.map(p => p.logbookAuthor))]
  },
  filteredRepair:function(){
    return this.stationRepair.filter((repair) => {
      return repair.title.toLowerCase().includes(this.searchText.toLowerCase())
      && repair.repairformShift.includes(this.searchShift)
      && repair.uid.match(this.filterAuthor)
      && repair.field_station_location.match(this.filterStation)
      && repair.field_apparatus_id.match(this.filterApparatus);
    })
  }

  },
  methods: {
    getShiftColor(shiftdata){
      var shifts = this.siteconfig.payroll.shifts
      var obj = shifts.find(p => p.shift == shiftdata)
      if(obj){
        return obj.color
      } return shift
    },
    printModalSet(data){
      this.printRepair = []
      this.printApparatus = null
      this.printStation = null
      this.printType = ''

      if(data.repairformApparatusId != undefined && data.repairformApparatusId != null && data.repairformApparatusId != ''){
        var apparatus = this.apparatusData.filter( p => {
                return p.apparatusName == data.repairformApparatusId
            })
            this.printApparatus = apparatus[0].id
            this.printType = 'apparatus'
           
      } else if(data.repairformStation != undefined && data.repairformStation != null && data.repairformStation != ''){
        var station = this.stationsData.filter( p => {
                return p.stationName == data.repairformStation
            })
            this.printStation = station[0].id
            this.printType = 'station'
          
      }
    this.printRepair.push(data)
    this.showModal = true
    },
    buildEmailData(repair){
      this.emailFrom = {name: this.userData.firstName + ' ' + this.userData.lastName, email: this.userData.email}
      if(repair.repairformApparatusId == undefined){
        var user = this.deptContactData.filter(p => {
          return p.firstName == 'Facility'
        })
        if(user){
          var array = []
          for(var u in user){
         array.push(user[u])
          }
          this.emailTo = array
        } else {
          this.emailTo = ''
        }
      } else {
        this.emailTo = ''
      }
      var staOff = this.deptContactData.filter(p => {
        return p.stationAssignment == repair.repairformStation
                && p.role == 'supervisor'
      })
      this.emailCC = staOff
      this.emailSubject = repair.repairformItem
      this.emailBody = repair.repairformDescription
      this.emailTitle = repair.title
      this.showEmail = !this.showEmail
    },
    returnUserEmail(data){
      var email = []
      for(var i = 0; i < data.length; i++){
        email.push(data[i].email)
      }
      return email
    },
    getAssetData(id){
      if(id){
        var obj = this.logisticAssetData.find(p => p.id == id)
        if(obj){  
          return obj.assetDescription + ' (#' + obj.assetId + ')'
        } return ''
      } return 'Not Found'
    },
    sendEmailForm(){
      this.disableEmailButtons = true
     axios({
      method: 'post',
      url: '/api/v1/sayhello/datashare',
      data: {

                "emailFrom": this.emailFrom,
                "emailTo": this.returnUserEmail(this.emailTo),
                "emailCC": this.returnUserEmail(this.emailCC),
                "emailSubject": this.emailSubject,
                "emailTitle": this.emailTitle,
                "emailBody": this.emailBody,
                "viewLink": '/datashare/repairform?id=' + this.repair.id
              
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
        this.emailNotification = response.data.data
        this.showEmail = !this.showEmail
        this.disableEmailButtons = false
    })
    },
    showPostDataToggle(event){
      this.showPostData = !this.showPostData
    },
    removeCurrentRow(newNote){
      var index = this.newRepairNote.indexOf(newNote)
      this.newRepairNote.splice(index, 1)
    },
    addNewRepairNote(event){
      var currentH = moment().format('HH')
      var currentM = moment().format('mm')
      var time = { HH: currentH, mm: currentM }
      var currentUser = this.userData.firstName + ' ' + this.userData.lastName
      this.newRepairNote.push({ author: currentUser, time: time, note: '' })
    },
    editPermissionsCheck(data){
      if(data.repairformAuthorId == this.userData.id){
        return true
      } else {
        if(this.userData.role == 'admin' || this.userData.role == 'adminchief'){
          return true
        } else {
          if(this.userData.role == 'fireops' || this.userData.role == 'emsops' || this.userData.role == 'shopops' || this.userData.role == 'supervisor'){
            return true
          } else {
            return false
          }
        }

      }
    },
  getStatusId(data){
    var status = this.repairStatus.filter((p) => {
      return p.name.match(data)
    })
  
    return status[0].tid

  },
  returnDateFormat(data){
    var time = data.HH + ':' + data.mm
    var current = moment().format("MM/DD/YYYY")
    return current + ' ' + time
  },
  setRepairNotes(data){
    var array = []
    for(var i in data){
      var newObJ = { note: data[i].note, author: data[i].author }
      newObJ['timestamp'] = this.returnDateFormat(data[i].time)
      array.push(newObJ)
    }
    var rep = this.repair.repairformNotes
    for(var i = 0; i < rep.length; i++){
      array.push(rep[i])
    }
    return array

  },
    submitArticl(data){
      var id = data.id
      var dateTest = moment(data.repairformDate).isSame(moment(this.repairformDate), 'day')
      if(!dateTest){
        var title = data.title.split(' | ')
        title.splice(0, 1)
        var string =  moment(this.repairformDate, 'YYYY-MM-DD').format('MM/DD/YYYY') + ' | '
        for(var i = 0; i < title.length; i++){
          string = string + title[i]
          if(i < (title.length - 1)){
            string = string + ' | '
          }
        }
        var newTitle = string
      } else {
        var newTitle = data.title
      }
     axios({
      method: 'patch',
      url: '/api/v1/content/repairform/' + id,
      data: {

                "repairformStatus": data.repairformStatus,
                "repairformItem": data.repairformItem,
                "repairformDescription": data.repairformDescription,
                "repairformNotes": this.setRepairNotes(this.newRepairNote),
                "repairCost": this.repairCostObj,
                "title": newTitle,
                "repairformDate": moment(this.repairformDate, 'YYYY-MM-DD').toISOString()
              
            },
      headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
      }
    }).then( response => {
        this.editEvent = false
                bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list');
    })


    },
     deleteLogbook(logbook){
    var result = confirm('Are You Sure You Want to Delete this repairform? It will be gone forever!')
      if (result) {
   axios({
     method: 'delete',
     url: '/api/v1/content/repairform/' + logbook.id,
     data: {},
    headers: {
      'Authorization': this.getSessionData,
      'Content-Type' : 'application/json',}
     }).then( response => {
    this.$nextTick(function(){
                bus.$emit('refreshUserPosts')
        bus.$emit('refresh-calendar-list');
    })
  }) 
      }
},
}
}
</script>

<style scoped>
img {
  width: 120px;
  height: auto;
}
.repairNoteTitle {
  text-align: center;
  background: #aaa;
  padding: 5px;
  border-radius: 10px 10px 0 0;
  margin: 10px 0 0 0;
}
.subTitle {
  margin: 0 auto 0;
  text-align: center;
}
#secondaryFilter {
  padding: 10px;
  background: grey;
}
#repairCheckTitle {
  background: #fff;
  cursor: pointer;
}
.repairBodyWrap {

  margin: 0 auto 0;
  width: 90%;
  padding-bottom: 5px;
  margin-top: 5px;
}
#repairCheckTitle:hover {
  background: #ddd;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .repairBodyWrap {

    margin: 0 auto 0;
    width: 95%;
    padding-bottom: 5px;
    margin-top: 5px;
  }
}
#repairCheckTitle h5 {
  background: #fff;
}
#repairCheckTitle h6 {
  margin: 2px;
   background: #fff;
}
#repairHeading {
  width: 100%;

}
#repairStatus {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-table;
}
.Open {
    background: #e2070d;
}
.Resolved {
  background: green;
}
.In-Progress{
  background: yellow;
}
.Parts-Ordered {
  background: yellow;
}
.Replaced {
  background: green;
}
.Other {
  background: black;
}
.Red-shift{
  color: #e2070d;
  margin: 0 auto 0;
  width: 80%;
  padding: 5px;
}
.Yellow-shift{
  color: #ffc107;
  margin: 0 auto 0;
  width: 80%;
  padding: 5px;
}
.Blue-shift{
  color: #007bff;
  margin: 0 auto 0;
  width: 80%;
  padding: 5px;
}
.Green-shift{
  color: #0FB906;
  margin: 0 auto 0;
  width: 80%;
  padding: 5px;
}
</style>
