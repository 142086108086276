<template>
<div id="homelanding">
       
  <b-container fluid>
     <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-btn variant="danger" size="sm" to="/portal/training?active=trainingtrack">Return to Training Page</b-btn>
          </b-nav-form>
      </b-navbar>
    <b-row class="mt-2">
      <b-col xl="8" order-xl="1" order="2">
  <b-card no-body>
    <b-tabs card>
      <b-tab title="Training Form">
      <template slot="title">
        <b-badge variant="danger" v-if="completionUsers.length > 0"><b-spinner type="grow" variant="light" small ></b-spinner>UNSAVED</b-badge> Training Form
      </template>
        <newTrainingSubmission v-if="trainingModule && resetTrainingForm" :credentialRequirement="credentialRequirement" :trainingModule="trainingModule" :completionUsersAdd="completionUsers" :trainingPage="true" @closeTrainingModal="refreshData" />
      </b-tab>
      <b-tab v-if="trainingModule 
                  && (userData.role == 'fireops' || userData.role == 'trainingops' 
                  || userData.role == 'emsops' || userData.role == 'adminchief' 
                  || userData.role == 'admin') && trainingModule.trainingModuleType != 'user'" 
                  :title="trainingModule.trainingModuleType == 'scheduled' ? 'Unassigned ' + '(' + deptContactDataUnassigned.length +')' : 'Inomplete ' + '(' + deptContactDataUnassigned.length +')'">
        <b-navbar variant="dark">
          <b-nav-form>

            <b-form-input type="text" v-model="searchData" placeholder="Search" />
        
                <b-form-select :options="parentUserGroups" v-model="battalionFilter" @change="stationFilter = ''"/>
                 <b-form-select :options="stationUserGroups" v-model="stationFilter" />
                         <b-form-select :options="shiftsUserGroups" v-model="shiftFilter" />

            </b-nav-form>
          </b-navbar>
          <b-pagination size="md" align="center" :total-rows="deptContactDataUnassigned.length" v-model="currentPage" :per-page="10">
    </b-pagination>
          <b-container fluid>
        <b-table  v-if="resetButton" :fields="fields" :items="deptContactDataUnassigned"  :current-page="currentPage" :per-page="10" >
          <template slot="schedule" slot-scope="data">
            <b-btn  variant="primary" size="sm" @click="userHoldArray.push(data.item)" :disabled="disabledEditId">Add</b-btn>
          </template>
          <template slot="complete" slot-scope="data">
             <b-btn variant="success" size="sm" @click="completionUsers.push(data.item)">Done</b-btn>
          </template>
          </b-table>
          </b-container>
      </b-tab>
      <b-tab :title="'Scheduled ' + '(' + deptContactDataScheduled.length + ')'" v-if="trainingModule && trainingModule.trainingModuleType == 'scheduled' && (userData.role == 'fireops' || userData.role == 'trainingops' || userData.role == 'emsops' || userData.role == 'adminchief' || userData.role == 'admin')">
        <b-navbar variant="dark">
          <b-nav-form>

            <b-form-input type="text" v-model="searchData" placeholder="Search" />
        
                <b-form-select :options="parentUserGroups" v-model="battalionFilter" @change="stationFilter = ''"/>
                 <b-form-select :options="stationUserGroups" v-model="stationFilter" />
               <b-form-select :options="shiftsUserGroups" v-model="shiftFilter" />

            </b-nav-form>
          </b-navbar>
          <b-pagination size="md" align="center" :total-rows="deptContactDataScheduled.length" v-model="currentPage" :per-page="10">
    </b-pagination>
          <b-container fluid>
        <b-table :fields="fieldsScheduled" :items="deptContactDataScheduled"  :current-page="currentPage" :per-page="10" >
          <template slot="scheduled" slot-scope="data">
           <b-btn variant="link" @click="setSelectedDate(data.item.id)"><span :class="getUserScheduledDate(data.item.id).variant">{{ getUserScheduledDate(data.item.id).date }}</span></b-btn>
          </template>
          <template slot="add" slot-scope="data">
            <b-btn variant="primary" size="sm" @click="completionUsers.push(data.item)" >Add</b-btn>
          </template>
          </b-table>
          </b-container>
      </b-tab>
      <b-tab v-if="trainingModule.trainingModuleType != 'user'" :title="'Completed ' + '(' + deptContactDataCompleted.length + ')'">
        <b-navbar variant="dark">
          <b-nav-form>

            <b-form-input type="text" v-model="searchData" placeholder="Search" />
        
                <b-form-select :options="parentUserGroups" v-model="battalionFilter" @change="stationFilter = ''"/>
                 <b-form-select :options="stationUserGroups" v-model="stationFilter" />
                         <b-form-select :options="shiftsUserGroups" v-model="shiftFilter" />

            </b-nav-form>
          </b-navbar>
          <b-pagination size="md" align="center" :total-rows="deptContactDataCompleted.length" v-model="currentPage" :per-page="10">
    </b-pagination>
          <b-container fluid>
        <b-table :fields="fieldsCompleted" :items="deptContactDataCompleted"  :current-page="currentPage" :per-page="10" >
          <template slot="completed" slot-scope="data">
           {{ getUserCompletedDate(data.item.id) }}
          </template>
          <template slot="view" slot-scope="data">
           <b-btn variant="info" size="sm" @click="viewTrainingSubmission(data.item.id)">View</b-btn>
          </template>
          </b-table>
          </b-container>
        </b-tab>
        <b-tab title="Submissions" v-if="trainingModule && trainingModule.trainingModuleType == 'user'">
          <b-table :fields="fieldsSubmissions" :items="trainingModuleCompletions">
            <template slot="View" slot-scope="data">
               <b-btn variant="info" size="sm" @click="viewTrainingSubmissionObj(data.item)">View</b-btn>
            </template>
            </b-table>
          </b-tab>
        </b-tabs>
  </b-card>
      </b-col>
      <b-col xl="4" order-xl="2" order="1" v-if="trainingModule && trainingModule.trainingModuleType == 'scheduled'">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Scheduled">
               <h6 class="text-danger">To Add User, Search for Events by Date</h6>
                                   <b-row :align-h="documentWidth ? 'center' : 'start'">
                      <b-col cols="*">
            <datepicker
                    class="datepickervue"
                    v-if="datePickerReset"
                    v-model="selectedDate"
                    name="inlineCal">
                  </datepicker></b-col>
                  </b-row>
           
            <b-row>
              <b-col class="p-0 m-0" md="6" lg="4" xl="12" v-for="events in returnedEvents" :key="events.id">
            <eventCard :eventdata="events" :editId="editId" :userHoldArray="userHoldArray" @editIdChange="editIdChange($event)" :key="events.id" :trainingPage="true" />
              </b-col>
            </b-row>
            </b-tab>
            <b-tab title="Sch/Comp" v-if="false" active>
              <b-row><b-col> 
                <b-form-radio-group v-model="formType">
                  <b-form-radio value="schedule">Schedule</b-form-radio>
                  <b-form-radio value="completion">Completion</b-form-radio>
                </b-form-radio-group></b-col></b-row>
              <trainingDrillForm :formType="formType" :trainingModule="trainingModule" :userHoldArray="userHoldArray" @refreshSubmitData="refreshSubmitData" :deptContactBatt="deptContactBatt" @removeUserFromHold="removeUserFromHold" />
              </b-tab>
            </b-tabs>
        </b-card>
      </b-col>
      </b-row>
  </b-container>
    <b-modal v-if="viewTrainingRecordModal" v-model="viewTrainingRecordModal" size="lg">
          <trainingRecordView :training="viewTrainingRecord" @CloseTrainingModal="refreshData(), viewTrainingRecordModal = false, viewTrainingRecord = {}" />
    </b-modal>
</div>
</template>

<script>
import Calendar from '../../calendarFull/Calendar.vue'
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import DatePicker from '../../inputfields/Datepickermod.vue'
import eventCard from '../../tracking/events/eventsfilterdata.vue'
import newtrainingsub from '../../addNew/newTrainingSubmission.vue'
import trainingDrillForm from './trainingDrillForms/trainingCalendarSubmission.vue'
import trainingRecordView from './trainingView/trainingRecordView.vue'
export default {
props:[ 'id'],
components: {
  'calendar' : Calendar,
  'multiselect' : Multiselect,
  'newTrainingSubmission' : newtrainingsub,
  'datepicker' : DatePicker,
  trainingDrillForm,
  trainingRecordView,
  eventCard
},
data() {
    return {
    trainingModule: '',
    credentialRequirement: [],
    trainingModuleEvents: [],
    trainingModuleCompletions: [],
    completionUsers: [],
    userHoldArray: [],
    searchData: '',
    formType: 'schedule',
    battalionFilter: '',
    stationFilter: '',
    shiftFilter: '',
    eventDate: '',
    selectedDate: '',
    eventTime: '',
    currentPage: 1,
    viewTrainingRecordModal: false,
    datePickerReset: true,
    viewTrainingRecord: {},
    resetButton: true,
    editId: '',
    disabledEditId: true,
    resetTrainingForm: true,
    fields:[
      'firstName',
      'lastName',
      'employeeId',
      'stationAssignment',
      'shiftAssignment',
      'schedule',
      'complete'
    ],
    fieldsScheduled:[
      'scheduled',
      'firstName',
      'lastName',
      'employeeId',
      'stationAssignment',
      'shiftAssignment',
      'add'
    ],
    fieldsCompleted:[
      'completed',
      'firstName',
      'lastName',
      'employeeId',
      'stationAssignment',
      'shiftAssignment',
      'view'
    ],
    fieldsSubmissions: [
     {key: 'trainingRecordCompletionDate', lable: 'Date'},
     {key: 'trainingRecordComplete', label: 'Completed'},
     {key: 'trainingRecordModuleName', label: 'Training'},
     {key: 'trainingRecordAuthor', label: 'Author'},
     'View'

    ],
    runScheduled: false,
    windowWidth: '',


}
},
created () {
      this.getTrainingDrill()
      this.getTrainingDrillEvents()
      this.getTrainingDrillCompletions()
      this.getCredentialRequirements()
      this.getWindowWidth()
      window.addEventListener('resize', this.getWindowWidth);
      this.selectedDate = moment().format('MM-DD-YYYY')
      bus.$on('refresh-full-calendar', this.refreshSubmitData)
      
},
beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
},
watch: {
  
},
computed: {
  ...mapState([
    'getSessionData',
    'deptContactData',
    'stationsData',
    'eventTags',
    'userData',
    'shiftsData'
  ]),
  documentWidth(){
          if(this.windowWidth > 1199){
            var boo = true
          } else {
            var boo = false
          }
          return boo
  },
  returnedEvents(){
    var array = this.trainingModuleEvents
    var hold = array.filter(p => {

      return moment(p.eventDate).isSame(this.selectedDate, 'day')
    })
      return _.orderBy(hold, ['eventDate'], ['asc'])
      },
      shiftsUserGroups(){
        var array = [...new Set(this.shiftsData.map(p => p.shiftrotationName))]
        var avail = _.sortBy(array)
        var push = [{value: '', text: 'All Shifts'}]
        for(var i in avail){
          if(avail[i]){
          push.push(avail[i])
          }
        }
        return push
      },
      stationUserGroups(){
        var array = [...new Set(this.deptContactData.map(p => p.stationAssignment))]
        var parent = this.stationsData.filter(p => {
                var index = array.indexOf(p.stationName)
                if(index != -1){
                  return p
                }
        })
        var stations = parent.filter(p => {
          if(p.stationParent){
          return p.stationParent.match(this.battalionFilter)
          } else {
           // console.log(p.stationParent)
          }
        })
        var avail = [...new Set(stations.map(p => p.stationName))]
        var avail = _.sortBy(avail)
        var push = [{value: '', text: 'All Stations'}]
        for(var i in avail){
          if(avail[i]){
          push.push(avail[i])
          }
        }
        return push
      },
      parentUserGroups(){
        var array = [...new Set(this.deptContactData.map(p => p.stationAssignment))]
        var parent = this.stationsData.filter(p => {
                var index = array.indexOf(p.stationName)
                if(index != -1){
                  return p
                }
        })
        var avail = [...new Set(parent.map(p => p.stationParent))]
        var avail = _.sortBy(avail)
        var push = [{value: '', text: 'All Parents'}]
        for(var i in avail){
          if(avail[i]){
          push.push(avail[i])
          }
        }
        return push
      },
      deptContactBatt(){
        var contacts = this.deptContactData
        contacts.forEach(i => i['parentAssignment'] = this.getStationAssignment(i.stationAssignment))
        return _.orderBy(contacts, ['lastName'], ['asc'])
      },
      scheduledUserIds(){
        var events = this.trainingModuleEvents
        var array = []
        if(events){
          for(var i in events){
            var users = events[i].eventUser
            if(users){
              for(var u in users){
                array.push({id: users[u], date: events[i].eventDate})
              }
            }
          }
        }
        return array
      },
      completionUserIds(){
        var comp = this.trainingModuleCompletions
        var array = []
        if(comp){
          for(var i in comp){
            var users = comp[i].trainingRecordUsers
            if(users){
              for(var u in users){
                array.push({id: users[u], date: comp[i].trainingRecordCompletionDate, training: comp[i].id})
              }
            }
          }
        }
        return array
      },
      deptContactDataCompleted(){
          return this.deptContactBatt.filter(p => {
          return  p.stationAssignment.match(this.stationFilter) &&
                  p.parentAssignment.match(this.battalionFilter) &&
                  p.shiftAssignment.match(this.shiftFilter) &&
                  this.completionUserIds.map(p => p.id).indexOf(p.id) != -1 &&
                (p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase()))
        })
      },
      deptContactDataScheduled(){
          return this.deptContactBatt.filter(p => {
          return  p.stationAssignment.match(this.stationFilter) &&
                  p.parentAssignment.match(this.battalionFilter) &&
                  p.shiftAssignment.match(this.shiftFilter) &&
                  this.scheduledUserIds.map(p => p.id).indexOf(p.id) != -1 &&
                  this.userHoldArray.map(p => p.id).indexOf(p.id) == -1 &&
                  this.completionUsers.map(p => p.id).indexOf(p.id) == -1 &&
                  this.completionUserIds.map(p => p.id).indexOf(p.id) == -1 &&
                (p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase()))
        })
      },
      deptContactDataUnassigned(){
        return this.deptContactBatt.filter(p => {
          if(p.stationAssignment && p.parentAssignment && p.shiftAssignment){
          return  p.stationAssignment.match(this.stationFilter) &&
                  p.parentAssignment.match(this.battalionFilter) &&
                  p.shiftAssignment.match(this.shiftFilter) &&
                  this.userHoldArray.map(p => p.id).indexOf(p.id) == -1 &&
                  this.completionUsers.map(p => p.id).indexOf(p.id) == -1 &&
                  this.scheduledUserIds.map(p => p.id).indexOf(p.id) == -1 &&
                  this.completionUserIds.map(p => p.id).indexOf(p.id) == -1 &&
                (p.firstName.toLowerCase().match(this.searchData.toLowerCase()) || p.lastName.toLowerCase().match(this.searchData.toLowerCase()))
          } else {
            //console.log(p)
          }
        })
      } 
},
methods: {
    refreshData(){
     // console.log('REFRESH DATA')
      this.getTrainingDrillEvents()
      this.getTrainingDrillCompletions()
      this.completionUsers = []
      this.resetTrainingForm = false
      this.$nextTick(function(){
        this.resetTrainingForm = true
      })
    },
    getWindowWidth(event) {
        this.windowWidth = document.documentElement.clientWidth;
    },
    editIdChange(data){
     if(data != ''){
       if(data != null){
          this.disabledEditId = false
          this.editId = data
        } else if (data == null) {
          this.editId = ''
          this.disabledEditId = true
       }
     }
    },
    getUserScheduledDate(id){
      var obj = this.scheduledUserIds.find(p => p.id == id)
      if(obj && obj.date){
        var hold = {}
        var date = moment(obj.date)
        var today = moment()
        hold['date'] = date.format('MM/DD/YY HH:mm')
        hold['variant'] = today.isBefore(date) ? 'text-dark' : today.isSame(date, 'day') ? 'text-primary' : 'text-danger'
        return hold
      } else {
        return {date: 'Unknown', variant: 'text-danger'}
      }
    },
    setSelectedDate(id){
      var obj = this.scheduledUserIds.find(p => p.id == id)
      if(obj && obj.date){
        this.selectedDate = moment(obj.date).format('MM-DD-YYYY')
      }
    },
    viewTrainingSubmissionObj(train){
              this.viewTrainingRecordModal = true
              this.viewTrainingRecord = train

    },
    viewTrainingSubmission(id){
      var obj = this.completionUserIds.find(p => p.id == id)
      if(obj && obj.training){
        var train = this.trainingModuleCompletions.find(p => p.id == obj.training)
        if(train){
              this.viewTrainingRecordModal = true
              this.viewTrainingRecord = train
        }
      }
    },
    getUserCompletedDate(id){
      var obj = this.completionUserIds.find(p => p.id == id)
      if(obj){
        return moment(obj.date).format('MM/DD/YY HH:mm')
      }
    },
    getStationAssignment(data){
      if(data){
      var station = this.stationsData.find(p => p.stationName == data)
      if(station && station.stationParent){
        return station.stationParent
      } else {
        return ''
      }
      } else {
        return ''
      }
    },
    removeUserFromHold(data){
      var index = this.userHoldArray.indexOf(data.id)
      this.userHoldArray.splice(index, 1)
    },
    getTrainingDrill(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingmodule/' + this.id,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {

        this.trainingModule = response.data[0]

      })
    },
    getTrainingDrillEvents(){
      this.editId = ''
      this.userHoldArray = []
      axios({
        method: 'get',
        url: '/api/v1/content/event/content/' + this.id,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.trainingModuleEvents = response.data

      })
    },
    getTrainingDrillCompletions(){
      axios({
        method: 'get',
        url: '/api/v1/training/trainingrecord/completions/' + this.id,
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
      
        this.trainingModuleCompletions = response.data

      })
    },
    refreshSubmitData(){
      this.getTrainingDrillEvents()
      this.getTrainingDrillCompletions()
      this.userHoldArray = []
      this.editId = ''
    },
      getCredentialRequirements(){
      axios({
        method: 'get',
        url: '/api/v1/training/credentialrequirement/',
        headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData }
      }).then(response => {
        this.credentialRequirement = response.data
      })
    },

}

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.datepickervue {
  width: 300px;
}
.searchBar {
  padding: 5px 10px;
    background: #444;
}
.subHeadingClass {
  background: #eee;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.userSpan {
    background: #f0454a;
    padding: 2px 0 0px 7px;
    color: #fff;
    border-radius: 5px;
    margin: 4px;
    display: inline-block;
}
.btn-Userdanger {
  background: #f0454a;
  color: #444;
  font-weight: bold;
  margin: auto;
  cursor: pointer;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
  min-height: 800px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
