<template>
    <div class="day-cell"
         :class="[{'today' : day.isToday, 'current-month' : day.isCurrentMonth, 'payDay': day.payday, 'weekend': day.isWeekEnd, 'selected-day':isDaySelected }, day.shift.color+'-Cal']"
    @click="showDayOptions">
        <div class="row" v-if="shiftDisplayBoo || calendarType == 'payroll'">
            <div class="col-sm-12">
            <div v-if="!siteconfig.calendarHideFire" :class="day.shift.color+'-background'">{{ spliceName(day.shift.shift) }}
                
                </div>
            </div>
        </div>
        <div class="row" :class="{'endofCycle': day.endOfCycle}">
            <div class="col-sm-6">
                <span v-show="isDaySelected" v-if="canAddEventTest">
                    <span class="badge badge-primary" @click="calendarType == 'payroll' ? addPayroll(day) : showAddEventForm(day)">+</span>
                </span>

            </div>
            <div class="col-sm-6"  :title="day.shift.shift" >
                <p class="day-number" @click="calendarType == 'payroll' ? showRosterData(day) : showEventDay(day)"> {{ day.date.format('D') }}</p>
            </div>
        </div>
        <div class="event-box">
            <EventCard
                    v-for="event in formatedDay"
                    :event="event"
                    :key="event.id"
                    :day-date="day.date"
                    :is-day-selected="isDaySelected"
                    :daydisplay="daydisplay" 
                    :canDeleteEvent="canDeleteEvent"
                    :calendarType="calendarType"
                    @eventDeleted="eventDeleted">
            </EventCard>
        </div>
        <div class="row" v-if="day.payday">
              <div class="col-sm-12 float-right-payday">
            <span class="moneyClass">$ </span>
            </div>
            </div>
            <EventDetails v-if="showModal" :show="showModal" :event="modalFormattedDay" :canDeleteEvent="canDeleteEvent" @closeModal="showEventDay"></EventDetails>
    </div>

</template>
<script>
    import moment from 'moment';
    import {DAY_SELECTED, CHANGE_MONTH, EVENT_ADDED, EVENT_DELETED} from '../actions';
    import EventCard from './EventCard.vue'
    import EventModal from './EventModal.vue'
    import { mapState } from 'vuex'
    import { bus } from '../../../main'
    import EventDetails from './EventDetails.vue'
    export default {
        components: {
            'EventCard' : EventCard,
            'EventModal': EventModal,
            EventDetails
        },

        props:{
            day: {
                type: Object
            },
            firstDay: {
                type: String
            },
            canAddEvent: {
                type: Boolean,
            },
            canDeleteEvent: {
                type: Boolean,
            },
            daydisplay: {
                type: Boolean,
                default: false,
            },
            shiftDisplayBoo: {
                type: Boolean,
                default: false,
            },
            calendarType: {
                type: String,
                default: null
            }
        },

        data () {
            return {
                isDaySelected: true,
                showAddEvent: false,
                allowEvent: false,
                showModal: false
            }
        },

        created(){
            let me = this;
            this.$root.$on(DAY_SELECTED, function (payload) {
                if(payload.dayDate != me.day.date) {
                    me.isDaySelected = true;
                }
            });
            
            this.$root.$on(CHANGE_MONTH, function () {
                me.isDaySelected = true;
            });
         
        },
        computed: {
          ...mapState([
              'userData',
              'siteconfig'
          ]),
          canAddEventTest(){
            let startOfToday = moment().startOf('day');
            if(this.day.date.isAfter(startOfToday) || this.day.date.isSame(startOfToday)) {
              return true
            }
            else{
              return false
            }
          },
          modalFormattedDay(){
            var events = _.cloneDeep(this.day.events)
            var data = []
            for(var i in events){
                var eventData = events[i].description
                for(var e in eventData){
                    var obj = _.merge(_.cloneDeep(events[i]), eventData[e])
                    data.push(obj)
                }
            }
            var dataHold =  _.orderBy(data, ['eventDate'], ['asc'])
            return {title: 'All Day', description: dataHold}
          },
          formatedDay(){
              if(!this.daydisplay){
              return this.day.events
              } else {
                  var events = this.day.events
                  var holdArray = []
                  for(var e in events){
                      var items = events[e].description
                      for(var i in items){
                          var obj = {}
                          obj['date'] = events[e].date
                          obj['color'] = events[e].color
                          obj['id'] = items[i].id
                          obj['eventDate'] = items[i].eventDate
                          obj['description'] = [items[i]]
                          obj['title'] = events[e].title
                          if(events[e].payrollMod){
                              obj['payrollMod'] = events[e].payrollMod
                          }
                          holdArray.push(obj)
                      }

                  }
                    // console.log(holdArray)
                  return _.orderBy(holdArray, ['eventDate'], ['asc'])
              }
          }

        },
        methods : {
            showEventDay(day){
                this.showModal = !this.showModal
              //  console.log(this.modalFormattedDay)
            },
            spliceName(name){
                return name.replace('-shift', '')
            },
          correctedDate(date){
            return moment(date.date).format('MM/DD/YYYY')
          },
            showDayOptions(){
                let me = this;
              /*  let startOfToday = moment().startOf('day');
                if(this.day.date.isAfter(startOfToday) || this.day.date.isSame(startOfToday)) { */
                    this.isDaySelected = true;
                    this.$root.$emit(DAY_SELECTED, {dayDate:me.day.date});
            /*    } */
            },

            showAddEventForm(data){
               var day = moment(data.date).format('MM/DD/YYYY')
               var thisRoute = this.$route.path
                bus.$emit('addNewEventFromCal', day, thisRoute, "")

            },
            cancelAddEventForm(){
              this.showAddEvent = false;
            },
            eventAdded(event) {
                this.$emit(EVENT_ADDED, event);
            },

            eventDeleted(event) {
                this.$emit(EVENT_DELETED, event);
            },
            showRosterData(data){
                var startDate = data.date
                bus.$emit('showRosterData', data)
            },
            addPayroll(data){
                var startDate = data.date.add(8, 'hours').toISOString()
                     var dataObj = {  
                      payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: '', 
                      payrollModTargetUserId: this.userData.id, 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: startDate, 
                      payrollModHours: 24, 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: null,
                      payrollModApproved: null,
                      payrollModNote: '' }
                 bus.$emit('updatePayrollMod', dataObj)
            },
        }
    }
</script>
<style scoped>
    .day-cell {
        flex: 1;
        min-height: 112px;
        padding: 4px;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        background: rgba(147, 147, 147, 0.1);
    }

    .day-number {
        text-align: right;
        color: rgba(0, 0, 0, .25);
        font-size: 0.8em;
    }

    .current-month {
        background: #fff;
    }

    .current-month .day-number {
        color: rgba(0, 0, 0, .5);
        font-size: 1.0em;
    }

    .selected-day .day-number {
        font-size: 1.4em;
        font-weight: bold;
    }

    .weekend .day-number {
        color: rgba(210, 2, 2, 0.6);
    }
    .shiftDayLetter {
      font-size: 1.4em;
      padding-left: 4px;
    }
    .Red-shift-background {
      background: #dc3545;
      color: #fff;

    }
    .Yellow-shift-background{
        background: #ffc107;
        color: #fff;
        }
    .Blue-shift-background {
      background:#007bff;
     color: #fff;
    }
    .Green-shift-background {
      background:#28a745;
     color: #fff;
    }
    .Red-shift-Cal .day-number {
      color: #dc3545;
    }
    .Yellow-shift-Cal .day-number {
        color: #ffc107;
        }
    .Blue-shift-Cal .day-number {
      color: #007bff;
    }
    .Green-shift-Cal .day-number {
      color: #28a745;
    }
    .endofCycle  {
       border-left: 3px solid red;
       margin-left: -3px;
    }
    .float-right-payday {
        text-align: right;
    }
    .moneyClass {
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    color: #fff;
    border: 1px solid #0fb906;
    background: #0fb906;
    border-radius: 5px;
    padding: 1px 5px 1px 5px;
    margin-right: 3px;
    }
    .today {
        background-color: #ddd;
    }

    .today .day-number {
        font-weight: bold;
    }

</style>
