<template>
<div>
  <b-table :fields="fields" :items="trainingValidationData" >
     <template slot="View Record" slot-scope="data">
                    <b-btn variant="info" @click="viewTrainingRecordSet(data.item)">View</b-btn>
                  </template>
               </b-table>
      <b-modal v-if="viewTrainingRecordModal" v-model="viewTrainingRecordModal" size="lg" @hide="updateValidation()">
          <trainingRecordView :training="viewTrainingRecord" :trainingDrills="trainingDrills"  />
    </b-modal>
</div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
import trainingRecordView from './trainingView/trainingRecordView.vue'

export default {
  props:['trainingValidationData', 'trainingDrills'],
components:{
trainingRecordView,
},
  data () {
    return {
        fields: [
            {key: 'trainingRecordModuleName', label: 'Name'},
            {key: 'trainingRecordCompletionDate', label: 'Date'},
             {key: 'trainingRecordHours', label: 'Hours'},
              {key: 'trainingRecordAuthor', label: 'Author'},
              'View Record'
        ],
        viewTrainingRecordModal: false,
        viewTrainingRecord: null,
    }
  },
  created(){

  },
  watch: {
   
  },
  computed:{
    ...mapState([
    'getSessionData',
    'deptContactData',
    'stationsData',
    'userData',
    'config',
    'userPosts'
    ]),

    },
    methods: {
            viewTrainingRecordSet(item){
          this.viewTrainingRecord = item
          this.viewTrainingRecordModal = true
        },
      updateValidation(){
        this.$emit('updateValidation')
      }



},

}
</script>

<style scoped>
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #000;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}

.credential-table {
  text-align: center;
}
.changeWidth {
  width: 80%;
  margin: 0 auto 0;
  text-align: center;
}
.userPostIcon{
  position: relative;
  margin: 0px 0 -80px 0px;
  width:32px;
  height:32px;
  opacity: 0.3;
  
}
.blogadjust {
  text-align: center;
    width: 80%;
    margin: 0 auto 0;
}
.userPostForms{
  width:80%;
  padding-top: 10px;
  margin: 0 auto 0;
  border-bottom: 1px solid #aaa;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}



#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {
  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
@media screen and (max-width: 768px) {
.userPostIcon{
    display: none;
  
}
}

</style>
