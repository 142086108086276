<template>
<div>
      <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Module Filter</b-navbar-brand>
        <b-form-input class="bootstrapTable mr-sm-2" type="text" v-model="searchData" placeholder="Search by Name"></b-form-input>
        <b-form-select v-model="filterByType" :options="getModTypes" />
        <b-form-select class="ml-2" v-model="filterByCredential" :options="getCurrentCredentials" />
         <b-form-select class="ml-2" v-model="activeMods" :options="activeModsOpts" />
      </b-nav-form>
    </b-navbar>
  <b-table :fields="fields" :items="trainingModulesFiltered">
    <template slot="trainingModuleCredential" slot-scope="data">
      <b-badge variant="primary" v-for="(cred, index) in data.item.trainingModuleCredential" :key="index">{{ getCredentialName(cred).credentialRequirementName }}</b-badge>
      </template>
          <template slot="trainingModuleType" slot-scope="data">
      <span v-if="data.item.trainingModuleType != ''">{{ data.item.trainingModuleType.charAt(0).toUpperCase() + data.item.trainingModuleType.slice(1) }}</span>
      <span v-if="data.item.trainingModuleType == ''">In Progress</span>
    </template>
    <template slot="View" slot-scope="data">
      <b-btn size="sm" variant="outline-info" @click="viewTrainingModule(data.item.id)">View</b-btn>
    </template>
  </b-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
export default {
  components: {

  },
  props: ['trainingModules', 'credentialRequirement', 'credentialsData'],
  name: 'training-module',
  data () {
    return {
      searchData: '',
      activeMods: true,
      filterByCredential: null,
      filterByType: null,
      activeModsOpts: [
        {text: 'Active', value: true},
        {text: 'InActive', value: false},
        {text: 'All', value: null},
      ],
      fields: [
        {key: 'trainingModuleName', label: 'Module Name'},
        {key: 'trainingModuleCompletionDate', label: 'Completion Date'},
        {key: 'trainingModuleType', label: 'Mod Type'},
        {key: 'trainingModuleCredential', label: 'Credentials'},
        {key: 'trainingModuleAuthorName', label: 'Author'},
        {key: 'updatedAt', label: 'Updated'},
        'View'
        
      ]
    }
  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'userData',
          'deptContactData'
        ]),
        trainingModulesFiltered(){
          var mods = this.trainingModules
          if(this.activeMods != null){
            var activeMods = mods.filter(p => {
              return p.trainingModuleActive == this.activeMods
            })
          } else { var activeMods = mods }
          if(this.filterByCredential != null){
            var cred = activeMods.filter(p => {
              var values = p.trainingModuleCredential
              if(values){
              var index = values.indexOf(this.filterByCredential)
              if(index >= 0){
                return p
              }
              }
            })
          } else { var cred = activeMods }
         if(this.filterByType != null){
            var type = cred.filter(p => {
              return p.trainingModuleType == this.filterByType
            })
          } else { var type = cred }
          return type
        },
        getModTypes(){
          var data = [... new Set(this.trainingModules.map(p => p.trainingModuleType))]
             
          var values = []
          for(var i in data){
            if(data[i]){
              var string = data[i].charAt(0).toUpperCase() + data[i].slice(1)
              values.push({text: string, value: data[i]})
            } else if(data[i] == ''){
              values.push({text: 'In Progess', value: ''})
            }
          }
          values.push({text: 'All Types', value: null})
          return _.sortBy(values)
        },
        getCurrentCredentials(){
          var creds = [] 
          var mods = this.trainingModules
          for(var i in mods){
            if(mods[i].trainingModuleCredential){
              var credshold = mods[i].trainingModuleCredential
              for(var c in credshold){
                var index = creds.indexOf(credshold[c])
                if(index < 0){
                  creds.push(credshold[c])
                }
              }
            }
          }
          var values = []
          for(var i in creds){
            values.push({value: creds[i], text: this.getCredentialName(creds[i]).credentialRequirementName})
          }
          values.push({value: null, text: 'All Credentials'})
          return _.sortBy(values)
        }
  },
  mounted(){

  },
  watch: {
    
  },
  methods: {
    getCredentialName(id){
      if(id && this.credentialRequirement){
      var obj = this.credentialRequirement.find(p => p.id == id)
      if(obj){
        return obj
      }
      else {
        return { credentialRequirementName: 'Unavailable'}
      }
      } else {
        return ''
      }
    },
    viewTrainingModule(id){
      this.$emit('viewTrainingModule', id)
    }


}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.requirement-error{
  border: 1px solid red;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
