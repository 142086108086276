<template >
  <div >
    <div>
    <div v-if="!showAdmin && medsNarctrack">
        <b-navbar type="dark" variant="dark">
      <b-nav-form>
         <b-navbar-brand class="bootstrapTable">Report</b-navbar-brand>
      <b-button-group class="mx-0 mt-1" v-if="false">
        <b-btn :variant="individualView ? 'light' : 'outline-light'" @click="individualView = true, dailyView = false">Individual</b-btn>
        <b-btn :variant="dailyView ? 'light' : 'outline-light'" @click="individualView = false, dailyView = true">Daily View</b-btn>
      </b-button-group>
        <b-navbar-brand class="ml-2">Filter</b-navbar-brand>
        <b-form-input type="text" v-model="keywordSearch" placeholder="Author/Witness" />
        <b-form-select class="ml-1" v-model="narcLogType" :options="narcLogTypeOpt" />
        <b-form-select class="ml-1" v-model="battalionData" :options="battalionOptions" />
         <b-form-select class="ml-1" v-model="filterMissing" :options="filterMissingOpt" />
                 <b-navbar-brand v-if="dailyView" class="ml-2">Date</b-navbar-brand>
                   <b-form-input  v-if="dailyView" type="date" :placeholder="currentSearchDate" v-model="userSelectedDate"></b-form-input>
              <b-btn v-show="!dailyView" class="mt-1 ml-2" variant="danger" @click="clearSearchData">
        Reset Search
      </b-btn>
         <b-btn class="mt-1 ml-2" variant="success" @click="executePrintJS">
                         Print PDF
      </b-btn>
      </b-nav-form>
    </b-navbar>
  <b-card no-body>
    <b-tabs card>
  <b-tab title="Daily View" @click="individualView = false, dailyView = true">
  <div>
                     <b-container v-if="narcReportData.length > 0">
                <b-btn variant="info" @click="getSignaturesApp(narcReportData)" :disabled="gettingSignatures">Download Signatures for {{ narcReportData.length }} Cards</b-btn>
                   <h6 class="text-danger" v-if="gettingSignatures">Please be patient while fetching the signatures</h6>
                   <span v-if="progressTotal">{{ getValueForProgress + '%'}}</span>
                </b-container>
  <b-table id="printJS-form" v-if="apparatusData.length > 0 && apparatusListCheck.length > 0 && resetData" stacked="xl" striped head-variant="inverse" hover :fields="fieldObjectkeys" :items="filteredApparatusNarcData">

    <template slot="narctrackTagId" slot-scope="data">
        <span class="apparatusNameClass">{{  shortAppName(data.value) }}</span>
    </template>
    <template slot="narctrackEntryType" slot-scope="data">
       <b-badge :variant="cardVariant[data.item.narctrackEntryType]">{{ data.item.narctrackEntryType }}</b-badge>
      </template>
      <template slot="narctrackDate" slot-scope="data">
        {{ narcDateFormatDate(data.value) }}<br>
        {{ narcDateFormatTime(data.value) }}
      </template>
    <template slot="narctrackAuthor" slot-scope="data">
            <h6>  {{ data.value }} </h6>
        <img v-if="data.item.narctrackAuthorSignature  && data.item.narctrackAuthorSignature != 'Signature Saved'" width="150px" :src='"data:image/png;base64," + fixImage(data.item.narctrackAuthorSignature, "author")' />
                    <span v-if="data.item.narctrackAuthorSignature && fixImage(data.item.narctrackAuthorSignature) == 'error' && data.item.narctrackAuthorSignature != 'Signature Saved'" class="text-danger">Missing/Error</span>
          <span v-if="data.item.narctrackWitnessSignature && data.item.narctrackAuthorSignature == 'Signature Saved'" class="text-success">Signature Saved</span>
    </template>
    <template slot="narctrackWitness" slot-scope="data">
      <h6> {{ data.item.narctrackWitness }} </h6>
            <img v-if="data.item.narctrackWitnessSignature && fixImage(data.item.narctrackWitnessSignature) != 'error' && data.item.narctrackWitnessSignature != 'Signature Saved'" width="150px" :src='"data:image/png;base64," + fixImage(data.item.narctrackWitnessSignature, "witness")' />
            <span v-if="data.item.narctrackWitnessSignature && fixImage(data.item.narctrackWitnessSignature) == 'error' && data.item.narctrackWitnessSignature != 'Signature Saved' && data.item.narctrackWitnessId" class="text-danger">Missing/Error</span>
  <span v-if="data.item.narctrackWitnessSignature && data.item.narctrackWitnessSignature == 'Signature Saved'" class="text-success">Signature Saved</span>
    </template>
    <template v-if="addedFields.length > 0" v-for="fields in addedFields" :slot="fields" slot-scope="data">
      <div v-if="data.value != undefined && data.item[fields]" :key="fields.index"><span :key="fields.index">{{ data.item[fields].length }} </span></div>
    </template>
        <template slot="Card" slot-scope="data">
      <b-btn 
          v-if="data.item.narctrackEntryType &&data.item.narctrackEntryType != 'Daily Narc Missing'" 
          size="sm" 
          :variant="cardVariant[data.item.narctrackEntryType]" 
          @click="showNarcCard = !showNarcCard, narcCardData = data.item"
          >View</b-btn>
          <b-btn v-if="userData.role == 'admin' && userData.lastName == 'Wilbert'" size="sm" @click="editNarcCard(data.item)">Edit</b-btn>
      </template>
    </b-table>
    </div>
  </b-tab>
       <b-tab title="Audit Reporting" @click="individualView = true, dailyView = false">
            <div class="mt-4">
          <b-container v-show="!hideSearchBox">
            <b-radio-group v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'emsops'" v-model="reportSearchType">
              <b-radio value="Individual">Individual User/Apparatus</b-radio>
                <b-radio value="Admin Backup">Admin Audit</b-radio>
            </b-radio-group>
            <b-container class="mb-2" v-if="reportSearchType == 'Admin Backup'">
              This report will generate a large amount of data, please be patient.
            </b-container>
            <b-row  v-if="apparatusSelectSearch == '' || apparatusSelectSearch == null">
              <b-col align="right" class="p-2">
                <h5>User Search</h5>
              </b-col>
              <b-col>
         <multiselect :disabled="reportSearchType == 'Admin Backup'" v-if="individualView" v-model="userSelectSearch" :options="deptContactData" label="lastName" track-by="lastName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select User">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
        </multiselect>
              </b-col>
            </b-row>
         
           <b-row v-if="userSelectSearch == '' || userSelectSearch == null">
              <b-col align="right" class="p-2">
                <h5>Apparatus Search</h5>
              </b-col>
              <b-col>
         <multiselect :disabled="reportSearchType == 'Admin Backup'" v-if="individualView" v-model="apparatusSelectSearch" :options="apparatusData" label="apparatusName" track-by="apparatusName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Apparatus">
                  <template slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.apparatusName }}</span>
                  </template>
        </multiselect>
              </b-col>
            </b-row>
              <b-row><b-col><h4> <em>then</em> </h4></b-col></b-row>
            <b-row>
              <b-col align="right" class="p-2">
                <h5>Select Month</h5>
              </b-col>
              <b-col align="left">
                <b-form-select :options="monthData" v-model="selectedMonth" placeholder="Search Month" />
              </b-col>
            </b-row>
             <b-row>
              <b-col align="right" class="p-2">
                <h5> Select Year </h5>
              </b-col>
              <b-col align="left">
                <b-form-select :options="currentYear" v-model="selectedYear" />
              </b-col>
            </b-row>
            <b-row v-if="reportSearchType == 'Individual'"> 
              <b-col>
                <b-btn v-show="userSelectSearch.id != undefined" v-if="apparatusSelectSearch == '' || apparatusSelectSearch == null" variant="primary" @click="getIndividualData" :disabled="checkForDisabledStatus" >Search</b-btn>
                <b-btn  v-show="apparatusSelectSearch.apparatusName != undefined" v-if="userSelectSearch == '' || userSelectSearch == null" variant="primary" @click="getIndividualData" :disabled="checkForDisabledStatus" >Search</b-btn>
              </b-col>
              </b-row>
            <b-row v-if="reportSearchType == 'Admin Backup'"><b-col>
               <b-btn variant="primary" @click="getAdminDataPull" :disabled="checkForDisabledStatus" >Search</b-btn>
              </b-col>
              </b-row>
          </b-container>
          <b-container v-if="searchLoading">
            <h4> ... Loading Report </h4>
            </b-container>
            <b-container v-show="!searchLoading" v-if="individualReportData.length == 0">
              <h4> No Data Found, or Awaiting Valid Search </h4>
              </b-container>
              <b-container v-if="individualReportData.length > 0 && false">
                <b-btn variant="info" @click="getSignatures(individualReportData)">Download Signatures for {{ individualReportData.length }} Cards</b-btn>
                   <span v-if="progressTotal">{{ getValueForProgress + '%'}}</span>
                </b-container>
<download-excel v-if="individualReportExcelData.length > 0"
    :name="getReturnedName"
    :fields="excelFields"
    :data="individualReportExcelData">
    <b-btn variant="primary">Download Excel</b-btn>
</download-excel>
  <b-card no-body v-if="individualReportData.length > 0 && tableReset">
    <b-tabs card>
      <b-tab title="All Cards">
           <b-pagination v-model="currentPage" :total-rows="individualReportData.length" :per-page="20" aria-controls="my-table">
    ></b-pagination>
  <b-table  stacked="xl" striped head-variant="inverse" hover :fields="fieldObjectkeys" :current-page="currentPage"  per-page="20" :items="individualListCheck">
    <template slot="narctrackTagId" slot-scope="data">
        <span class="apparatusNameClass">{{  shortAppName(data.value) }}</span>
    </template>
    <template slot="narctrackEntryType" slot-scope="data">
       <b-badge :variant="cardVariant[data.item.narctrackEntryType]">{{ data.item.narctrackEntryType }}</b-badge>
      </template>
      <template slot="narctrackDate" slot-scope="data">
        {{ narcDateFormatDate(data.value) }}<br>
        {{ narcDateFormatTime(data.value) }}
      </template>
    <template slot="narctrackAuthor" slot-scope="data">
            <h6>  {{ data.value }} </h6>
        <img v-if="data.item.narctrackAuthorSignature  && data.item.narctrackAuthorSignature != 'Signature Saved'" width="150px" :src='"data:image/png;base64," + fixImage(data.item.narctrackAuthorSignature, "author")' />
                    <span v-if="data.item.narctrackAuthorSignature && fixImage(data.item.narctrackAuthorSignature) == 'error' && data.item.narctrackAuthorSignature != 'Signature Saved'" class="text-danger">Missing/Error</span>
          <span v-if="data.item.narctrackWitnessSignature && data.item.narctrackAuthorSignature == 'Signature Saved'" class="text-success">Signature Saved</span>
    </template>
    <template slot="narctrackWitness" slot-scope="data">
      <h6> {{ data.item.narctrackWitness }} </h6>
            <img v-if="data.item.narctrackWitnessSignature && fixImage(data.item.narctrackWitnessSignature) != 'error' && data.item.narctrackWitnessSignature != 'Signature Saved'" width="150px" :src='"data:image/png;base64," + fixImage(data.item.narctrackWitnessSignature, "witness")' />
            <span v-if="data.item.narctrackWitnessSignature && fixImage(data.item.narctrackWitnessSignature) == 'error' && data.item.narctrackWitnessSignature != 'Signature Saved'" class="text-danger">Missing/Error</span>
  <span v-if="data.item.narctrackWitnessSignature && data.item.narctrackWitnessSignature == 'Signature Saved'" class="text-success">Signature Saved</span>
    </template>
    <template v-if="addedFields.length > 0" v-for="fields in addedFields" :slot="fields" slot-scope="data">
     <div v-if="data.value != undefined && data.item[fields]" :key="fields.index"><span :key="fields.index">{{ data.item[fields].length }} </span></div>
    </template>
    <template slot="Card" slot-scope="data">
      <b-btn size="sm" >View</b-btn>
      </template>
    </b-table>
      </b-tab>
      <b-tab title="Use Report">
      <b-table :fields="['user', 'useCount', 'uses']" :items="individualReportDataUseReturned">
        <template slot="user" slot-scope="data">
          {{ getUserName(data.item.userId)}}
          </template>
          <template slot="uses" slot-scope="data">
              <b-table  stacked="xl" striped head-variant="inverse" hover :fields="fieldObjectkeys" :items="data.item.uses">
    <template slot="narctrackTagId" slot-scope="data">
        <span class="apparatusNameClass">{{  shortAppName(data.value) }}</span>
    </template>
    <template slot="narctrackEntryType" slot-scope="data">
       <b-badge :variant="cardVariant[data.item.narctrackEntryType]">{{ data.item.narctrackEntryType }}</b-badge>
      </template>
      <template slot="narctrackDate" slot-scope="data">
        {{ narcDateFormatDate(data.value) }}<br>
        {{ narcDateFormatTime(data.value) }}
      </template>
    <template slot="narctrackWitness" slot-scope="data">
      <h6> {{ data.item.narctrackWitness }} </h6>
            <img v-if="data.item.narctrackWitnessSignature && fixImage(data.item.narctrackWitnessSignature) != 'error' && data.item.narctrackWitnessSignature != 'Signature Saved'" width="150px" :src='"data:image/png;base64," + fixImage(data.item.narctrackWitnessSignature, "witness")' />
            <span v-if="data.item.narctrackWitnessSignature && fixImage(data.item.narctrackWitnessSignature) == 'error' && data.item.narctrackWitnessSignature != 'Signature Saved'" class="text-danger">Missing/Error</span>
  <span v-if="data.item.narctrackWitnessSignature && data.item.narctrackWitnessSignature == 'Signature Saved'" class="text-success">Signature Saved</span>
    </template>
    </b-table>
            </template>
        </b-table>
      </b-tab>

      </b-tabs>
      </b-card>
  </div>
  </b-tab>
        <b-tab title="Audit Graph" @click="activateGraph = true">
          <auditGraph v-if="activateGraph" :medsNarctrack="medsNarctrack" />
        </b-tab>
    <template v-for="card in editCardPush" >
      <b-tab :title="card.title" :key="card.id">
        <b-btn @click="closeEditCard(card)" variant="danger" size="sm" class="float-right">Close</b-btn>
        <adminEditNarcCard :cardData="card"  :medsNarctrack="medsNarctrack" />
        </b-tab>
      </template>
    </b-tabs>
    </b-card>
    </div>
    </div>
         <b-modal v-if="narcCardData" v-model="showNarcCard" :title="narcCardData.title" @hide="narcCardData = ''">
       <narcCard :narcs="narcCardData" :showPost="true" :medsNarctrack="medsNarctrack" />
       </b-modal>
</div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { bus } from '../../../main'
import printJS from 'print-js'
import _ from 'lodash'
import { mapState } from 'vuex'
import JsonExcel from 'vue-json-excel'
import Multiselect from 'vue-multiselect'
import barChart from './apparatusChart.vue'
import auditGraph from './ApparatusEMSQAAuditGraph.vue'
import narcCard from '../../tracking/dailynarctrack/apparatusFilterData.vue'
import adminEditNarcCard from './narcoticAdminEditCard.vue'
export default {
  props: ['medsNarctrack', 'loading'],
  name: 'apparatus',
  components: {
    'multiselect' : Multiselect,
    'download-excel' : JsonExcel,
    barChart,
    auditGraph,
    narcCard,
    adminEditNarcCard
  },
  data () {
    return {
      items: [],
      filteredArray: [],
      filterMissing: null,
      progressTotal: '',
      progressStatus: '',
      narcCardData: '',
      showNarcCard: false,
      getValueForProgress: 0,
      activateGraph: false,
      showAdmin: false,
      hideSearchBox: false,
      resetData: false,
      searchLoading: false,
      reportSearchType: 'Individual',
      apparatusNameVar: '',
      keywordSearch: '',
      emptyRow: false,
      viewNarcGraph: '',
      currentPage: 0,
      narcLogType: '',
      narcLogTypeOpt: [
        {text: 'All Types', value: ''},
        { text: "Transfer", value: "Daily Narc Transfer" },
        { text: "Use", value: "Daily Narc Use" },
        { text: "Restock", value: "Daily Narc Restock" },
        { text: "Distribution", value: "Daily Narc Distribution" },
        { text: "Total", value: "Daily Narc Total" }
      ],
      filterMissingOpt: [
        {text: 'Missing (Off)', value: null},
        {text: 'Missing Card', value: 'card'},
        {text: 'Missing Signature', value: 'sign'},
        {text: 'Missing Either', value: 'either'}
      ],
      cardVariant: {
            verification: 'success',
            use: 'warning',
            restock: 'primary',
            distribution: 'info',
            destroy: 'dark',
            quarantine: 'danger',
            initial: 'secondary'
      },
      initialRows: [],
      emitedRows: [],
      filteredALSData: [],
      individualView: false,
      selectedMonth: null,
      apparatusSelectSearch: '',
      selectedYear: '',
      dailyView: true,
      userSelectSearch: '',
      tableReset: true,
      dataReRoll: true,
      battalionData: null,
      excelFields: {},
      userSelectedDate: '',
      narcReportData: [],
      individualReportData: [],
      currentSearchDate: '',
      addedFields: [],
      signaturesReturned: [],
      signaturesReturnedApp: [],
      gettingSignatures: false,
      graphData: [],
      editCardPush: [],
      fieldObjectkeysUse: [
                        { key: "narctrackTagId", label: "App", },
                        { key: "narctrackEntryType", label: "Type", },
                        { key: "narctrackDate", label: "Date", },
                        { key: "narctrackShift", label: "Shift", },
                        { key: "narctrackRunNumber", label: "Secondary Tag", },
                        { key: "narctrackWitness", label: "Witness" },
                        'Card'
        ,
                        /* { key: "field_assignment_filter", label: "Assigned", }, */
                        ],
      fieldObjectkeys: [
                        { key: "narctrackTagId", label: "App", },
                        { key: "narctrackEntryType", label: "Type", },
                        { key: "narctrackDate", label: "Date", },
                        { key: "narctrackShift", label: "Shift", },
                        { key: "narctrackRunNumber", label: "Secondary Tag", },
                        { key: "narctrackAuthor", label: "Author", },
                        { key: "narctrackWitness", label: "Witness", },
                        /* { key: "field_assignment_filter", label: "Assigned", }, */
                        ],
      battalionOptions:[

              ],
      monthData: [{ text: 'January', value: '01'}, { text: 'Febuary', value: '02'}, { text: 'March', value: '03'}, { text: 'April', value: '04'}, 
      { text: 'May', value: '05'}, { text: 'June', value: '06'}, { text: 'July', value: '07'}, { text: 'August', value: '08'}, 
      { text: 'September', value: '09'}, { text: 'October', value: '10'}, { text: 'November', value: '11'}, { text: 'December', value: '12'},
      { text: 'Select Month', value: null, disabled: true}]
    }
  },
created (){
  bus.$on('hideRow', this.hideRowMethod)
  this.userSelectedDate = moment().format('YYYY-MM-DD')
  this.getNarcLogData(this.userSelectedDate)

},
mounted(){
  
},
computed: {
  ...mapState([
      'apparatusData',
      'userData',
      'deptContactData',
      'fullDeptContactData',
      'getSessionData'
      ]),

    individualReportDataUseReturned(){
      var array = _.cloneDeep(this.individualReportData)
      var use = array.filter(p => {
        return p.narctrackEntryType == 'Daily Narc Use'
      })
      var users = [... new Set(use.map(p => p.narctrackAuthorId))]
      var hold = users.map(id => {
          var obj = {}
          obj['userId'] = id
          var logs = use.filter(p => p.narctrackAuthorId == id)
          obj['useCount'] = logs.length
          obj['uses'] = logs
          return obj
      })
     // console.log(hold)
      return _.orderBy(hold, ['useCount'], ['desc'])
    },   
    checkState(){
      if(this.showAdmin == true){
      return 'light'
       } else {
      return 'outline-light'
     }
    },
    checkForDisabledStatus(){
      if(this.selectedYear == '' || this.selectedMonth == null){
        return true
      }
      else {
        return false
      }
    },
    getReturnedName(){
      if(this.individualView){
        if(this.userSelectSearch){
          var name = this.userSelectSearch.lastName
        } else if (this.apparatusSelectSearch) {
          var name = this.apparatusSelectSearch.apparatusName
        } else {
          var name = 'ADMIN'
        }
        return name + '_' + this.selectedMonth + '_' + this.selectedYear + '.xls'
      }
      
    },
    individualReportExcelData(){
      var array = _.cloneDeep(this.individualReportDataReturned)
      var newArray = []
      var fields = {
        'Unit': 'narc_unit',
        'Date': 'narc_date',
        'Type': 'narc_entry_type',
        'Case or Run': 'run_number',
        'AuthorName': 'author_name',
        'AuthorSignature': 'author_signature',
        'Witness': 'witness',
        'WitnessSignature': 'witness_signature',
        'AuthorId': 'author_id',
       }
             
      for(var i in array){
        var newObj = {}
        newObj['database_id'] = array[i].id
        newObj['narc_date'] = moment(array[i].narctrackDate).format('MM/DD/YYYY HH:mm:ss')
        newObj['narc_unit'] = array[i].narctrackTagId
        newObj['narc_entry_type'] = array[i].narctrackEntryType
        newObj['run_number'] = array[i].narctrackRunNumber
        newObj['author_id'] = array[i].narctrackAuthorId
        newObj['author_name'] = array[i].narctrackAuthor
        newObj['author_signature'] = array[i].narctrackAuthorSignature
        newObj['witness'] = this.checkForWitness(array[i])
        newObj['witness_signature'] = array[i].narctrackWitnessSignature
        var medsarr = array[i].narctrackMeds.map(item => item = this.medsNarctrack.find(p => p.id == item))
        var newFields = medsarr.map(p => p.medication)
        for(var n in newFields){
          var meds = medsarr.filter(p => p.medication == newFields[n])
          var startstring = null
          for(var m = 0; m < meds.length; m++){
              var count = m + 1
              if(startstring == null){
              startstring = count + '. CN:' + meds[m].controlNumber + ' Exp:' + moment(meds[m].expData).format('MM/DD/YYYY') + ' Lot:' + meds[m].LotNumber + ' NDC:' + meds[m].NdcNumber 
              } else {
              startstring = startstring + ' \n ' + count + '. CN:' + meds[m].controlNumber + ' Exp:' + moment(meds[m].expData).format('MM/DD/YYYY') + ' Lot:' + meds[m].LotNumber + ' NDC:' + meds[m].NdcNumber 
              }
              
          }
          fields[newFields[n]] = newFields[n]
          newObj[newFields[n]] = startstring
        }
        //console.log(medsarr)
        newArray.push(newObj)
      }
      fields['DbID'] = 'database_id'
      this.excelFields = fields
   //   console.log('EXCEL')
   //   console.log(fields)
    console.log(newArray)
      return _.orderBy(newArray, ['narc_unit', 'narc_date'])
    },
    individualReportDataReturned(){
      var array = _.cloneDeep(this.individualReportData)
      var arrayids = this.signaturesReturned.map(p => p.id)
      var holdArray = []
      for(var i in array){
        var index = arrayids.indexOf(array[i].id)
        if(index < 0){
          holdArray.push(array[i])
        } else {
          holdArray.push(this.signaturesReturned[index])
        }
      }
        if(this.filterMissing){
          if(this.filterMissing == 'card'){
            var holdArray = holdArray.filter(p => {
              return p.narctrackEntryType == 'Daily Narc Missing'
            })
          }
          if(this.filterMissing == 'sign'){
            var holdArray = holdArray.filter(p => {
              return p.narctrackWitnessSignature == 'Signature Required'
            })
          }
        if(this.filterMissing == 'either'){
            var holdArray = holdArray.filter(p => {
              return p.narctrackWitnessSignature == 'Signature Required' ||
              p.narctrackEntryType == 'Daily Narc Missing'
            })
          }
        }
      return holdArray
    },
    narcDataReturned(){
      var array = this.narcReportData
      var arrayids = this.signaturesReturnedApp.map(p => p.id)
      var holdArray = []
      for(var i in array){
        var index = arrayids.indexOf(array[i].id)
        if(index < 0){
          holdArray.push(array[i])
        } else {
          holdArray.push(this.signaturesReturnedApp[index])
        }
      }
      return holdArray
    },
    currentYear(){
      var array = []
      var current = moment().format('YYYY')
      if(this.selectedYear == ''){
        this.selectedYear = current - 0
      }
      for(var i = 0; i < 7; i++){
        var year = current - i
        array.push({text: year, value: year})
      }
    return array
    },
    checkViewStatusIndividual(){
      if(this.individualView == true){
        return 'secondary'
      } else {
        return 'light'
      }
    },
    checkViewStatusDaily(){
      if(this.dailyView == true){
        return 'secondary'
      } else {
        return 'light'
      }
    },
    filteredALS(){
      return this.apparatusData.filter((d) => {
        return d.apparatusMedicalType.match('ALS') && d.apparatusParent != "Reserve";
      })
    },
    filteredApparatusNarcData(){
        var holdArray = this.apparatusListCheck.filter((p) => {
         // var date = moment(p.narctrackDate).format('YYYY-MM-DD')
        //  return p.narctrackStationParent.match(this.battalionData) &&
        //  p.narctrackEntryType.match(this.narcLogType)
        return p
        })
        //console.log(holdArray)
        if(this.filterMissing){
          if(this.filterMissing == 'card'){
            var holdArray = holdArray.filter(p => {
              return p.narctrackEntryType == 'Daily Narc Missing'
            })
          }
          if(this.filterMissing == 'sign'){
            var holdArray = holdArray.filter(p => {
              return p.narctrackWitnessSignature == 'Signature Required'
            })
          }
        if(this.filterMissing == 'either'){
            var holdArray = holdArray.filter(p => {
              return p.narctrackWitnessSignature == 'Signature Required' ||
              p.narctrackEntryType == 'Daily Narc Missing'
            })
          }
        }
        if(this.battalionData){
          console.log('Filter', this.battalionData)
          var holdArray = holdArray.filter(p => {
              return p.narctrackTagId == this.battalionData
            })
        }
        return holdArray
      },
      apparatusListCheck(){
        this.resetData = false
        if(this.narcDataReturned.length > 0){
                  var array = this.narcDataReturned
        } else {
          var array = []
        }

        var ALSVeh = [... new Set(this.filteredALS.map(p => p.apparatusName))]
        var appsWithNarcs = [... new Set(array.map(p => p.narctrackTagId))]
        for(var i = 0; i < appsWithNarcs.length; i++){
          var index = (ALSVeh.indexOf(appsWithNarcs[i]))
          ALSVeh.splice(index, 1)
        }
        //var parentSearch = [...new Set(array.map(p => p.narctrackStationParent))]
        ///var parentSearch = _.sortBy(parentSearch)

        for(var i = 0; i < ALSVeh.length; i++){
          var titleString = ALSVeh[i] + ' Missing Narc Log'
          var assignment = this.filteredALS.filter((p) => p.apparatusName.match(ALSVeh[i]))
         var objectString = { title: titleString, narctrackTagId: ALSVeh[i], narctrackEntryType: 'Daily Narc Missing', narctrackStationParent: assignment[0].apparatusParent }
          array.push(objectString)
        }

        var array = _.orderBy(array, ['narctrackTagId'], ['asc'])
        array.forEach( item => {
          var arrayData = this.medicationSeperation2(item.narctrackMeds, item)

          for(var i = 0; i < arrayData.length; i++){
            var keyData = Object.keys(arrayData[i])
            item[keyData] = arrayData[i][keyData]
          }
        })
        this.battalionOptions = [{text: 'All Units', value: null}]
        console.log(this.battalionData)
        var newSetId = [...new Set(array.map(p => p.narctrackTagId))]
        console.log('NewSet', newSetId)
        for(var i = 0; i < newSetId.length; i ++){
          var index = this.battalionOptions.map(p => p.value).indexOf(newSetId[i])
          console.log(newSetId[i], index)
          if(index == -1){
            var string = { text: newSetId[i], value: newSetId[i]}
            this.battalionOptions.push(string)
          }
        }
        this.resetData = true
        var index = this.fieldObjectkeys.indexOf('Card')
        if(index != -1){
          this.fieldObjectkeys.splice(index, 1)
        }
        this.fieldObjectkeys.push('Card')
        return array
      },
      individualListCheck(){
        var array = this.individualReportDataReturned
        array.forEach( item => {
          var arrayData = this.medicationSeperation2(item.narctrackMeds, item)

          for(var i = 0; i < arrayData.length; i++){
            var keyData = Object.keys(arrayData[i])
            item[keyData] = arrayData[i][keyData]
          }
        })
        return _.orderBy(array, ['narctrackTagId', 'narctrackDate'], ['asc','asc'])
      },
},
watch:{
      userSelectedDate(data){
        this.getNarcLogData(data)
      },
      loading(newData){
        if(newData){
          this.getNarcLogData(this.userSelectedDate)
        }
      }
},
methods: {
  closeEditCard(data){
      var index = this.editCardPush.map(p => p.id).indexOf(data.id)
      this.editCardPush.splice(index, 1)
      },
  checkForWitness(data){
    if(data && data.narctrackWitnessId)
    var user = this.fullDeptContactData.find(p => p.id == data.narctrackWitnessId)
    if(user && user.firstName && user.lastName){
      return 'Inside: ' + user.firstName + ' ' + user.lastName + ' #' + user.employeeId 
    }
    if(data && data.narctrackWitness){
      return 'Outside: ' + data.narctrackWitness
    }
    return ''
  },
  editNarcCard(data){
         var headers = {
        headers: {
          "content-type": "application/json",
          Authorization: this.getSessionData,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
          }}
    axios({
      method: 'get',
      url: '/api/v1/content/dailynarctrack/' + data.id,
      headers: headers.headers
       }).then( response => {
         this.editCardPush.push(response.data)
    })

  },
  getUserName(id){
    if(id){
      var user = this.deptContactData.find(p => p.id == id)
      if(user){
        return user.firstName + ' ' + user.lastName
      } return 'Unkown User'
    } return 'Unknown Id'
  },
  clearSearchData(){
    this.individualReportData = []
    this.apparatusSelectSearch = ''
    this.userSelectSearch = ''
    this.hideSearchBox = false
    this.searchLoading = false
          this.progressTotal = ''
          this.getValueForProgress = 0
      this.progressStatus = 0
  },
  setIndividualView(){
     
    this.individualView = true
    this.dailyView = false
  },
  setDailyView(){
     this.getNarcLogData(this.userSelectedDate)
    this.dailyView = true
    this.individualView = false
  },
  fixImage(data, type, obj) {
      if(data && data.data){
      var string = new Buffer(data.data, "base64").toString("utf8");
      return string;
      } else {
        return 'error'
      }
    },
  getNarcForGraph(){
     var token = this.getSessionData
     this.graphStart
     var headers = {
        headers: {
          "content-type": "application/json",
          Authorization: this.getSessionData,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
          }}
    axios({
      method: 'get',
      url: '/api/v1/content/dailynarctrack/admingraph/' + moment(this.graphStart).subtract(1, 'd').toISOString() + '/' + moment(this.graphEnd).add(1, 'd').toISOString() + '/' + 'Rescue 12' ,
      headers: headers.headers
       }).then( response => {
   //   console.log(response.data)
      this.graphData = response.data
    })
  },
  getNarcLogData(data){
    this.narcReportData = []
    var start = data + "T00:00:00"
    var end = data + "T23:59:59"
    var offSet = moment().utcOffset();
    var hourOffSet = offSet * -1 / 60;

    var timeStringFormstart = moment(start).add(hourOffSet, "hours");
    var timeStringAddstart = moment(timeStringFormstart).format("YYYY-MM-DDTHH:mm:ss");
    var timeStringAddstart =  timeStringAddstart + "Z"

    var timeStringFormend = moment(end).add(hourOffSet, "hours");
    var timeStringAddend = moment(timeStringFormend).format("YYYY-MM-DDTHH:mm:ss");
    var timeStringAddend =  timeStringAddend + "Z"

    var token = this.getSessionData
     var headers = {
        headers: {
          "content-type": "application/json",
          Authorization: this.getSessionData,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
          }}
        axios({
      method: 'get',
      url: '/api/v1/content/dailynarctrack/date/' + timeStringAddstart + '/' + timeStringAddend,
      headers: headers.headers
       }).then( response => {

      this.narcReportData = response.data
    })
  },
    getAdminDataPull(){
    this.searchLoading = true
    this.hideSearchBox = true
    var test = this.selectedYear + '-' + this.selectedMonth
    var daysIn = moment(test, "YYYY-MM").daysInMonth();
    var startDate = this.selectedYear + '-' + this.selectedMonth + '-' + '01T00:00:00'
    var endDate = this.selectedYear + '-' + this.selectedMonth + '-' + daysIn + 'T23:59:59'
        var offSet = moment().utcOffset();
        var hourOffSet = offSet * -1 / 60;
    var timeStringFormstart = moment(startDate).add(hourOffSet, "hours");
    var timeStringAddstart = moment(timeStringFormstart).format("YYYY-MM-DDTHH:mm:ss");
    var timeStringAddstart =  timeStringAddstart + "Z"

    var timeStringFormend = moment(endDate).add(hourOffSet, "hours");
    var timeStringAddend = moment(timeStringFormend).format("YYYY-MM-DDTHH:mm:ss");
    var timeStringAddend =  timeStringAddend + "Z"

   var headers = {
        headers: {
          "content-type": "application/json",
          'Authorization':  this.getSessionData,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT' }}
    axios({
      method: 'get',
      url: '/api/v1/content/dailynarctrack/adminpull/' + timeStringAddstart + '/' + timeStringAddend ,
      headers: headers.headers
       }).then( response => {

      this.individualReportData = response.data
     // console.log(response.data)
      this.searchLoading = false
    })
  },
  getSignatures(data){
      this.progressTotal = data.length
      this.progressStatus = 0
      for(var i in data){
       axios({
        method: 'get',
        url: '/api/v2/content/dailynarctrack/signatures/' + data[i].id,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
          
        }
        }).then(response => {         
          this.signaturesReturned.push(response.data)
          this.progressStatus = this.progressStatus + 1
          this.getValueForProgress = Math.round((this.progressStatus / this.progressTotal) * 100)
      })
      }
  },
  getSignaturesApp(data){
       this.gettingSignatures = true
       var ids = [...new Set(data.map(p => p.id))]
       axios({
        method: 'post',
        url: '/api/v2/content/dailynarctrack/signaturesbulk/',
        data: ids,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
        }).then(response => {         
          this.signaturesReturnedApp = response.data
          this.$nextTick(function(){
             this.gettingSignatures = false
          })
      })
  },
    getSignaturesAppBack(data){
      this.progressTotal = data.length
      this.progressStatus = 0
      for(var i in data){
       axios({
        method: 'get',
        url: '/api/v2/content/dailynarctrack/signatures/' + data[i].id,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
        }).then(response => {         
          this.signaturesReturnedApp.push(response.data)
          this.progressStatus = this.progressStatus + 1
          this.getValueForProgress = Math.round((this.progressStatus / this.progressTotal) * 100)
      })
      }
  },
  getSignaturestest(data){
      var arrayids = this.individualReportData.map(p => p.id)
      this.signatureLoop(0, data, arrayids)
    },
    signatureLoop(i, data, arrayids){
      var num = i || 0; 
      if(num < data.length) {
       axios({
        method: 'get',
        url: '/api/v2/content/dailynarctrack/signatures/' + data[i].id,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
        }).then(response => {
          var index = arrayids.indexOf(response.data.id)
          this.signaturesReturned.push(response.data)
          var newnum = num + 1
          this.signatureLoop(newnum, data, arrayids)


      })
      }
      
    },
  getIndividualData(){
    this.searchLoading = true
    this.hideSearchBox = true
    var test = this.selectedYear + '-' + this.selectedMonth
    var daysIn = moment(test, "YYYY-MM").daysInMonth();
    var startDate = this.selectedYear + '-' + this.selectedMonth + '-' + '01T00:00:00'
    var endDate = this.selectedYear + '-' + this.selectedMonth + '-' + daysIn + 'T23:59:59'
        var offSet = moment().utcOffset();
        var hourOffSet = offSet * -1 / 60;
    var timeStringFormstart = moment(startDate).add(hourOffSet, "hours");
    var timeStringAddstart = moment(timeStringFormstart).format("YYYY-MM-DDTHH:mm:ss");
    var timeStringAddstart =  timeStringAddstart + "Z"

    var timeStringFormend = moment(endDate).add(hourOffSet, "hours");
    var timeStringAddend = moment(timeStringFormend).format("YYYY-MM-DDTHH:mm:ss");
    var timeStringAddend =  timeStringAddend + "Z"

    if(this.userSelectSearch != null && this.userSelectSearch != null){
      var url = 'userIndividual/' + this.userSelectSearch.id + '/' + timeStringAddstart + '/' + timeStringAddend
    }
    if(this.apparatusSelectSearch != null && this.apparatusSelectSearch != ''){
      var url = 'apparatusIndividual/' + this.apparatusSelectSearch.apparatusName + '/' + timeStringAddstart + '/' + timeStringAddend
    }
     var headers = {
        headers: {
          "content-type": "application/json",
          'Authorization':  this.getSessionData,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT' }}
    axios({
      method: 'get',
      url: '/api/v1/content/dailynarctrack/' + url,
      headers: headers.headers
       }).then( response => {

      this.individualReportData = response.data
      this.searchLoading = false
    })
  },
  executePrintJS(event){
    var string = 'Daily Narc Report | ' + this.userData.lastName + ' on ' + moment().format('MM/DD/YYYY - HH:mm')
    return printJS({ printable: 'printJS-form', type: 'html', header: string})
  },
  shortAppName(data){
    var app = this.apparatusData.filter( p => { 
      return p.apparatusName == data})
    return app.apparatusRadioId
  },
  searchDateMethod(){
    this.getNarcLogData(this.userSelectedDate)
  },
  typeOfEntry(valueD){
    return valueD
  },
  narcDateFormatDate(data){
    if(data){
    return moment(data).format('MM/DD/YY')
  } else {
    return ''
  }
  },
  narcDateFormatTime(data){
    if(data){
    return moment(data).format('HH:mm')
  } else {
    return ''
  }
  },
  medicationSeperation2(meds, firstItem){
    if(meds != undefined && meds != '' && this.medsNarctrack){
    var medSplit = _.cloneDeep(meds)
    medSplit = [... new Set(medSplit.map(p => p))]
    medSplit = medSplit.map(item => {
      var obj = this.medsNarctrack.find(p => p.id == item)
      if(obj){
        return obj
      } else {
        console.log(firstItem)
        console.log(item)
        return null
      }
    
    })
    medSplit = medSplit.filter(p => p)
    var object = []
    var medNames = medSplit.map(p => p.medication)
    for (var i in medNames) {
    var objectData = {}
    var medItems = medSplit.filter(p => {
      return p.medication == medNames[i]
    })
    var testIndex = this.fieldObjectkeys.indexOf(medNames[i])
    if(testIndex < 0){
      this.fieldObjectkeys.push(medNames[i])
      this.addedFields.push(medNames[i])
    }
    objectData[medNames[i]] = medItems 
    object.push(objectData)
  } 
  return object 
  
  } else { return "" }
  },
  shortAppName(data){
    if(data == ''){
      return '?'
    }
    var appParts = data.split(' ')
    if(!appParts[1]){
      return data
    }
    var firstLetter = appParts[0].substring(0, 1)
    if( appParts[0] == 'EMS' || appParts[0] == 'ALS'){
      var firstLetter = appParts[0]
    }
    return firstLetter + appParts[1]
  },
  filteredApparatusNarcs(apparatusId){
    return this.apparatusAppNarcs.filter((check) => {
      return check.field_apparatus_id.match(apparatusId);
    })

  },
  rowArrayFilteredAls(data){
      this.initialRows = data
    /*  this.dataReRoll = false
      this.$nextTick(function(){
          this.dataReRoll = true
      }
      ) */
    },
  hideRowMethod($event){
    var nameId = $event
    var indexArray = []
    for(var i = 0; i < this.initialRows.length; i ++ ){
      if(this.initialRows[i].name === nameId ){
      this.emitedRows.push(this.initialRows[i])
       this.initialRows.pop(i, 1)
    }
}
}
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style :src="'./customcss/vue-multiselect.min.css'"></style>
<style>
.hideNarcRow {
  display: none;
}
</style>
<style slot-scoped>
.floatRight {
  float: right;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0 0;
}
div.headerSpan {
  background: #777;
  width: 100%;
}
.apparatusNameClass{
  font-weight: bold;
  background: #fff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}
.narcRow {

  margin-top: 10px;
  padding-top: 5px;
  background: #eee;
  min-height: 50px;
}
.apparatusNameClass{
  font-weight: bold;
  background: #fff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
}
.A-shift-app {
  background: red;
  color: #fff;
}
.B-shift-app {
  background: blue;
  color: #fff;
}
.C-shift-app {
  background: green;
  color: #fff;
}
.apparatusTitleBar {
  color: #fff;
}
.badge.Use.badge-secondary {
  background-color: #ffe007;
  color: #000;
}
.badge.Transfer.badge-secondary {
  background-color: green;
  color: #fff;
}
.badge.Distribution.badge-secondary {
  background-color: red;
    color: #fff;
}
.badge.Total.badge-secondary {
  background-color: #333;
    color: #fff;
}
.badge.Restock.badge-secondary {
  background-color: blue;
    color: #fff;
}

</style>
