<template>
<div>
<div>
<h4 id="filterHeading">Logbook Posts</h4> 
<div class="bodyData">
  <h4 class="errorText" v-if="filteredLogbook == 0">No Logbooks Posted</h4>
  <div v-if="stationLogbooks.length > 0">
<div  v-for="logbook in filteredLogbook" :key="logbook.id" >
    <stationlogbookfilterdata 
    :logbook="logbook" 
    :showPost="getshowPost(logbook)" 
    :check="filteredCheck(logbook)" 
    :repair="filteredRepair(logbook)"></stationlogbookfilterdata>
</div>
</div>
<b-btn variant="link" size="sm" v-if="journal" @click="showJournal = !showJournal">Journal View</b-btn>
</div>
</div>
<b-modal v-model="showJournal" size="lg">
  <template slot="modal-title">
    <b-btn size="sm" @click="changeMonthSelection('prev')" variant="info">PREV</b-btn>
    {{ getCurrentMonth(filterDate) }}
    <b-btn size="sm" @click="changeMonthSelection('next')" :disabled="checkIfDisabledNext" variant="info">NEXT</b-btn>
     | Logbook Journal View | 
    <b-btn size="sm" variant="primary" @click="arrayDirection = !arrayDirection">Order: {{ arrayDirection ? 'Desc' : 'Asc' }}</b-btn>
    </template>
  <b-table size="sm" :fields="['title']"  :items="stationLogbooksJournal">
    <template slot="title" slot-scope="data">
      <b-card no-body :bg-variant="data.item.color">
        <h4 class="text-light">{{ data.item.day}} | {{ data.item.title }}</h4>
        </b-card>
    </template>
     <template slot="row-details" slot-scope="row">
       <b-table size="sm" :fields="['time', 'note']" :items="row.item.data">
         <template slot="note" slot-scope="data">
           {{ data.item.note }}
           <p v-if="data.item.author"><em>Entry by: {{ data.item.author }}</em></p>
         </template>
       </b-table>
       </template>
    </b-table>
  </b-modal>
</div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { bus } from '../../../main.js'
import StationLogbookFilterData from './stationlogbookfilterdata.vue'
export default {

  props:{
        filterDate: {
            type: String,
            default: '',
        },
        stationLogbooks: {
            type: Array,
            default: () => []
        },
        vehicleChecks: {
            type: Array,
        },
        stationRepair: {
            type: Array,
        },
        searchShift: {
            type: String,
            default: '',
        },
        searchText: {
            type: String,
            default: '',
        },
        filterAuthor: {
            type: String,
            default: '',
        },
        showPost: {
          type: Boolean,
          default: false,
        },
        stationVarId: {
          type: String,
          default: null
        }, 
        journal: {
          type: Boolean,
          default: false
        },
        keepJournalOpen: {
          type: Boolean,
          default: false 
        }
      },
components:{
        'stationlogbookfilterdata' : StationLogbookFilterData,
},
  data () {
    return {
      showJournal: false,
      arrayDirection: true,
    }
  },
  created(){
      if(this.keepJournalOpen){
        this.showJournal = true
        bus.$emit('keepJournalOpen', false)
      }
  },
  computed:{
    ...mapState([
      'userData',
      'getSessionData',
      'siteconfig'
    ]),
    checkIfDisabledNext(){
     // console.log(this.filterDate)
      var current = moment().format('YYYYMM')
      var select = moment(this.filterDate, 'MM/DD/YYYY').format('YYYYMM')
      if(select >= current){
        return true
      } return false
    },
    stationLogbooksJournal(){
      var array = _.cloneDeep(this.stationLogbooks)
      var arrayDirection = this.arrayDirection ? 'desc' : 'asc'
      array =  _.orderBy(array, ['logbookDate'], [arrayDirection])
      return array.map(item => {
            var obj = {}
            obj['title'] = item.title
            obj['day'] = moment(item.logbookDate).format('dddd')
            obj['data'] = item.logbookPassdown
            obj['color'] = this.getShiftColor(item.logbookShift)
            obj['_showDetails'] = true
            return obj
      })
    },
    filteredLogbook(){
      var array = this.stationLogbooks.filter((logbook) => {     
        var dateFormat = moment(logbook.logbookDate).format('MM/DD/YYYY')
        return logbook.title.toLowerCase().includes(this.searchText.toLowerCase())
        && logbook.logbookShift.includes(this.searchShift)
        && logbook.logbookAuthor.match(this.filterAuthor)
        && dateFormat.match(this.filterDate);
      })
      return _.orderBy(array, ['logbookDate'], ['desc'])
    },

  },
  methods:{
    getCurrentMonth(date){
      if(date){
        return moment(date, 'MM/DD/YYYY').format('MMMM')
      } return 'Unknown Month'
    },
    changeMonthSelection(type){
      var current = moment(this.filterDate, 'MM/DD/YYYY')
      if(type == 'prev'){
        var date = current.subtract(1, 'month')
        bus.$emit('keepJournalOpen', true)
        bus.$emit('changeMonthSelection', date)
      } else {
        var date = current.add(1, 'month')
        bus.$emit('keepJournalOpen', true)
        bus.$emit('changeMonthSelection', date)
      }
    },
    getShiftColor(shift){
      if(this.siteconfig && this.siteconfig.payroll && this.siteconfig.payroll.shifts && shift){
        var shiftb = this.siteconfig.payroll.shifts.find(p => p.shift == shift)
        if(shiftb && shiftb.bootstrap){
          return shiftb.bootstrap
        } return 'secondary'

      } return 'secondary'
    },
    getshowPost(data){
      if(this.stationVarId){
        if(this.stationVarId.toLowerCase() == data.logbookStation.toLowerCase()){
          return true
        } else {
          return false
        }
      } else {
        return this.showPost
      }
    },
    filteredCheck(data){
      var stationLoc = data.logbookStation
      var dateRef = moment(data.logbookDate).format('MM/DD/YYYY')
      return this.vehicleChecks.filter((check) => {
        return moment(check.dailyvehicleDate).format('MM/DD/YYYY').match(dateRef) && check.dailyvehicleStation.match(stationLoc) ;
      })
    },
    filteredRepair(data){
      var stationLoc = data.logbookStation
      var dateRef = moment(data.logbookDate).format('MM/DD/YYYY')
      return this.stationRepair.filter((repair) => {
        return moment(repair.repairformDate).format('MM/DD/YYYY').match(dateRef) && repair.repairformStation.match(stationLoc);
      })
    },
  },
}
</script>

<style scoped>


#filterHeading {
  font-weight: bold;
  background: #777;
  text-shadow: 1px 2px #000;
  color: #fff;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.bodyData {
  border: 1px solid #777;
  min-height: 10px;
  margin-top: 0;
  margin-bottom: 5px;
  border-radius: 0 0 5px 5px;
}
h4.errorText {
  text-align: center;
  margin-top: 10px;
  color: #e2070d;
  font-style: italic;
}
a {
  text-decoration: none;
}
.A-shift{
  color: red;
}
.B-shift{
  color: blue;
}
.C-shift{
  color: green;
}
</style>
