<template>
<div class="stationPanelUserData">
  <groupInputSelector v-if="battalionData != ''" :filterDate="filterDate" :battalionData="battalionData" :groupedDateLabels="stationParents"  @updateParent="EMSDataPullRequest">
      <b-btn variant="light" size="sm" to="/admin/narcotics">New Narcotic Admin</b-btn>
            <b-btn v-if="!siteconfig.hideLegacyNarcotics" variant="light" size="sm" to="/reporting/emsqa">Legacy Narc Admin</b-btn>
  </groupInputSelector>
  <div id="dataWrapper">
            <apparatus v-if="awaitData" :battalionData="battalionData"/> 
      </div>
    </div>


</template>
<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import moment from 'moment'
import Apparatus from '../tracking/narclog/ApparatusEMSQA.vue'
import groupInputSelector from '../inputfields/groupInputSelector.vue'
export default {
  props:['stationParents'],
components:{
  'apparatus' : Apparatus,
  'groupInputSelector' : groupInputSelector
},
  data () {
    return {
      currentSet: '',
      currentSetLessOne: false,
      panelHideStatus: true,
      battalionData: '',
      searchShift: '',
      searchText: '',
      filterAuthor: '',
      filterApparatus: '',
      awaitData: true,

    }
  },
  created(){
      var userSta = this.userData.stationAssignment
      var userStaPare = this.stationsData.filter( p => {
        return p.stationName == userSta
      })
      if(parentVar){
      var parentVar = userStaPare[0].stationParent;
      this.EMSDataPullRequest(parentVar)
      } else {
        var parents = [... new Set(this.stationsData.map(p => p.stationParent))]
        var hold = parents.filter(p => {
          if(p){
            return p
          }
        })
        if(hold.length > 0){
        var parentVar = hold[0];
        this.EMSDataPullRequest(parentVar)
        }
      }
      bus.$on('emsPullRequestChange', this.EMSDataPullRequest)
  },
  computed:{
    ...mapState([
      'userData',
      'stationsData',
      'getSessionData',
      'siteconfig'
      
    ]),
        filterDate() {
      var now = moment().format("MM/DD/YYYY");
      var hour = moment().format('HH')
      if(hour < 8){
        var now = moment(now).subtract(1, 'd').format('MM/DD/YYYY')

      }
      return now
    },
},
    watch: {
      battalionData(newData){
        this.awaitData = false
        this.$nextTick(function(){
          this.awaitData = true
        })
      }

    },
    methods: {
    EMSDataPullRequest(data) {
      if(!data){
        data = this.battalionData
      } else {
      this.battalionData = data
      }
      var parentVar = data
       if(data == undefined || data == '' || data == null){
        var stationList = this.stationsData.filter(p => {
          if(p.stationParent != ''){
            return p
          }
        })
        var stationParent = [...new Set(stationList.map(p => p.stationParent))]
        stationParent.splice(stationParent.indexOf(undefined), 1)
        var stationParent = _.sortBy(stationParent, ['asc'])
        var parentVar = stationParent[0]
        if(this.battalionData == '' || this.battalionData == undefined || this.battalionData == null){
          this.battalionData = parentVar
        }
      }
      if (this.userData.role == "emsops" || this.userData.role == 'admin' || this.userData.role == 'adminchief') {
        var dataPull = ["narctrack", "dailynarctrack"];
        var urlPlace = "parent";
        var secondDay = moment(this.filterDate, 'MM/DD/YYYY').add(2, 'day').format('YYYY-MM-DDTHH:mm:ss')
        var firstDay = moment(secondDay).subtract(2, 'day').format('YYYY-MM-DDTHH:mm:ss')
        var query = '/' + firstDay + 'Z/' + secondDay + 'Z'
        var headers = {
        headers: {
          "content-type": "application/json",
          'Authorization': this.getSessionData,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT' }}
      for (var i = 0; i < dataPull.length; i++) {
        var payload = {
          contentType: dataPull[i],
          place: urlPlace,
          stationVar: parentVar,
          headers: headers,
           query: query
        };
        this.$store.dispatch("LOAD_SEC_DATA_EXP", payload);
      } } else {
        return
      }
    },
      panelHide(){
        this.panelHideStatus = !this.panelHideStatus
      },
},
}

</script>

<style scoped>
#dataWrapper{
 text-align: center;
}


#stationPanelHeading {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}
#stationPanelHeading h3 {
    padding: 5px;
}
.routerLink {
  color: #fff;
}
a {
  text-decoration: none;
  color: #e2070d;
}
.A-shift{
  background: #e2070d;
}
.B-shift{
  background: blue;
}
.C-shift{
  background: green;
}
.A-shifttext{
  color: #e2070d;
}
.B-shifttext{
  color: blue;
}
.C-shifttext{
  color: green;
}
.showAndHidePanelButtons{
  text-align: center;
}
.stationSelector{
  text-align: center;
  width: 64%;
  margin: 0 auto 15px;
}
.stationSelector{
  font-size: 30px;
}
#stat-header-data span{
  cursor: pointer;

}
.stationSelector button {background: #ddd;
border-style: none;
width: 15%;
height: 40px;
color: #777;
font-weight: bold;
font-size: 20px;
padding: 0px 0px 4px;
margin: 0;
line-height: 5px;}
.stationIndicator{
  display: none;
}
.previousStation {
  float: left;
}
.nextStation {
  float: right;
}
@media screen and (max-width: 460px) {
#dataWrapper {
  width: 100%;
}
}


</style>
