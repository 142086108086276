<template>
<div class="stationPanelUserData">
    <groupInputSelector v-if="userData != ''" :filterDate="filterDate" :battalionData="userStationAssignment" :groupedDateLabels="groupedDateLabels" @updateParent="StationDataPullRequest" />
  <div id="hideShowButtons" class="showAndHidePanelButtons">
    <b-btn size="sm"  v-show="dailyEvents" @click="toggleDailyEventsShow">Events</b-btn>
    <b-btn size="sm"   variant="outline-secondary" v-show="!dailyEvents" @click="toggleDailyEventsShow">Events</b-btn>
    <b-btn  size="sm" v-show="dailyLogs" @click="toggleDailyLogsShow">DailyLogs</b-btn>
    <b-btn size="sm" variant="outline-secondary" v-show="!dailyLogs" @click="toggleDailyLogsShow">DailyLogs</b-btn>
  </div><div id="dataWrapper">
      <div v-if="dailyEvents">
            <events :filterDate="filterDate"  :calendarEvents="calendarEvents"></events>
      </div>
      <div v-if="dailyLogs">
            <stationlogbookfilter  :userData="userData" :filterDate="filterDate" :stationLogbooks="stationLogbooks" :vehicleChecks="vehicleChecks" :stationRepair="stationRepair"  :getSessionData="getSessionData"></stationlogbookfilter>
            <vehiclecheckfilter :vehicleChecks="vehicleChecks" :filterDate="filterDate"></vehiclecheckfilter>
            <stationrepairfilter :stationRepair="stationRepair" :filterDate="filterDate"></stationrepairfilter>
        </div>
      </div>

</div>

</template>
<script>
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import moment from 'moment'
import VehicleCheckFilter from '../tracking/vehiclechecks/vehiclecheckfilter.vue'
import StationLogbookFilter from '../tracking/stationlogbook/stationlogbookfilter.vue'
import StationRepairFilter from '../tracking/repairs/stationrepairfilter.vue'
import groupInputSelector from '../inputfields/groupInputSelector.vue'
import Events from '../tracking/events/eventsfilter.vue'
export default {
  props:[],
components:{
  'events': Events,
  'vehiclecheckfilter': VehicleCheckFilter,
  'stationlogbookfilter' : StationLogbookFilter,
  'stationrepairfilter' : StationRepairFilter,
    'groupInputSelector' : groupInputSelector
},
  data () {
    return {
      dailyEvents: true,
      dailyLogs: true,
      currentSet: '',
      currentSetLessOne: false,
      panelHideStatus: true,
      userStationAssignment: ''
    }
  },
  created(){
      var stationAssignment = this.userData.length != '' ? this.userData.stationAssignment : ''
      this.userStationAssignment = stationAssignment
      this.StationDataPullRequest(stationAssignment)
      bus.$on('refreshDomEvent', (data) => {
      if(data != undefined){
      this.StationDataPullRequest(data)
      }
      else{
        this.StationDataPullRequest(this.userStationAssignment)
      }
      }); 
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'userData',
          "dailyNarcs",
          "vehicleChecks",
          "stationLogbooks",
          "calendarEvents",
          "stationRepair"
        ]),
    filterDate(){
      var now = moment().format('MM/DD/YYYY')
      var hour = moment().format('HH')
      if(hour < 8){
        var now = moment(now).subtract(1, 'd').format('MM/DD/YYYY')

      }
      return now
    },
    filterDateFormated(){
      var formated = moment().format('ddd, MMM Do YYYY')
      return formated
    },
    dateDifference(){
      var selectedVarDate = moment(this.filterDate, "MM/DD/YYYY");
      var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
      var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
      var dateDiffCalNumber = 100*(dateDiffCal/3);
      var dateTenDigit = dateDiffCalNumber % 10;
      return Math.round(dateTenDigit);
    },
    shiftDateCalc(){
      if (this.dateDifference == 0)
      return "B-shift";
      if (this.dateDifference == 7)
      return "A-shift";
      if (this.dateDifference == 3)
      return "C-shift";
    },
    filterStationsData(){
      return this.stationsData.filter((p) => {
        if(p.stationParent != '' && this.userData.role == 'fireops' ){
          return p
        }
        else{
          return p.stationName.match("Station")
        }
      })

    },
    groupedDateLabels () {
      var stationData = [...new Set(this.filterStationsData.map(p => p.stationName))];
      return stationData
      },
    userAssignmentIndex(){
      var stationAssignment = this.userData.length > 0 ? this.userData.stationAssignment : ''
      var stringInt = this.groupedDateLabels.indexOf(stationAssignment)
      var newInt = parseInt(stringInt)
      this.currentSet = newInt
    },
    groupedDateLabelsIndex () {
      var indexPosition = this.currentSet
      return this.groupedDateLabels[indexPosition]
    },
    groupedDateLabelMax () {
      return this.groupedDateLabels.length
    },
  },
    watch: {
      groupedDateLabelsIndex(newData){
        return this.stationLink(newData)
      }
    },
    methods: {
      StationDataPullRequest(data) {
      var parentVar = data
      if (this.userData.role == "user" || this.userData.role == 'supervisor') {
        var dataPull = ["event", "logbook", "dailyvehicle", "repairform"];
        var urlPlace = "station";
       var secondDay = moment(this.filterDate, 'MM/DD/YYYY').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
      var firstDay = moment(secondDay).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
      var query = '/' + firstDay + 'Z/' + secondDay + 'Z'
      
      var headers = {
        headers: {
          "content-type": "application/json",
          'Authorization': this.getSessionData,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT' }}
      for (var i = 0; i < dataPull.length; i++) {
        var payload = {
          contentType: dataPull[i],
          place: urlPlace,
          stationVar: parentVar,
          headers: headers,
          query: query
        };
        this.$store.dispatch("LOAD_SEC_DATA_EXP", payload);
      } } else {
        return
      }
    },
      stationSelectNameTrigger(data){
        bus.$emit('closeEvent')
      },
      stationLink(data){
        return data.replace(/\s+/g, '-').toLowerCase();
        },
      panelHide(){
        this.panelHideStatus = !this.panelHideStatus
      },
      toggleDailyEventsShow(){
        this.dailyEvents = !this.dailyEvents
      },
      toggleDailyLogsShow(){
        this.dailyLogs = !this.dailyLogs
      },
      previousStation () {
            this.currentSet = this.currentSet - 1
            bus.$emit('refreshDomEvent', this.groupedDateLabelsIndex)
      },
      nextStation () {
            this.currentSet = this.currentSet + 1
            bus.$emit('refreshDomEvent', this.groupedDateLabelsIndex)
      }
},
}

</script>

<style scoped>
#dataWrapper{
  width:80%;
  margin: 0 auto 0;
}
#hideShowButtons button {
  width: 18%;
  height: 40px;
}
#hideShowButtons {
    padding-bottom: 10px;
    border-bottom: 3px solid #bbb;
}
#feedTopControls button :active {
  color: #fff
}
.deactivatedButton{
  font-weight: bold;
  border-style: solid;
  border: 1px solid #777;
  color: #777;
  background: #fff
}
.activeButton {

}
#stationPanelHeading {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}
#stationPanelHeading h3 {
    padding: 5px;
}

a {
  text-decoration: none;
  color: #e2070d;
}
.A-shift{
  background: #e2070d;
}
.B-shift{
  background: #201B9F;
}
.C-shift{
  background: #0FB906;
}
.A-shifttext{
  color: #e2070d;
}
.B-shifttext{
  color: #201B9F;
}
.C-shifttext{
  color: #0FB906;
}
.showAndHidePanelButtons{
  text-align: center;
}
.stationSelector{
  text-align: center;
  width: 64%;
  margin: 0 auto 15px;
}
.stationSelector{
  font-size: 30px;
}
#stat-header-data span{
  cursor: pointer;

}
.stationSelector button {background: #ddd;
border-style: none;
width: 15%;
height: 40px;
color: #777;
font-weight: bold;
font-size: 20px;
padding: 0px 0px 4px;
margin: 0;
line-height: 5px;}
.stationIndicator{
  display: none;
}
.previousStation {
  float: left;
}
.nextStation {
  float: right;
}
@media screen and (max-width: 460px) {
#dataWrapper {
  width: 100%;
}
}


</style>
