<template>
<div >
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Date Range:</b-navbar-brand>
        <b-form-input type="date" v-model="startDate" />
        <b-form-input type="date" v-model="endDate" />
        <b-btn @click="getPayrollModForApprovalButton" variant="primary">Search</b-btn>
        <b-btn @click="getPayrollModForApprovalAdmin" v-if="userData.role == 'admin'" variant="danger">Admin</b-btn>
                <b-navbar-brand class="bootstrapTable ml-5 d-none d-lg-block" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">View Users:</b-navbar-brand>
        <div style="width: 250px" class="mr-2" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
        <multiselect v-model="userSelected" 
        :options="deptContactDataFiltered" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
                <download-excel 
    class="ml-2"
    v-if="fixDataExcelCombined.length > 0"
    :name="'Modification Admin Report from ' + startDate + ' to '  + endDate"
    :data="fixDataExcelCombined">
    <b-btn variant="success" >Export</b-btn>
    </download-excel>
              </b-nav-form>
    </b-navbar>
     <b-navbar type="dark" variant="dark">
      <b-nav-form>

         <b-navbar-brand class="bootstrapTable ml-3 d-none d-lg-block" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">Sort Users:</b-navbar-brand>
         <b-form-select v-model="payrollModParent" :options="payrollModParentOpt" />
         <b-form-select v-model="payrollModShift" :options="payrollModShiftOpt" class="mr-2" />
              <b-navbar-brand class="bootstrapTable ml-3 d-none d-lg-block" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">Show:</b-navbar-brand>
                    <b-form-checkbox-group
        id="checkboxes-show"
        name="checkboxs-show"
        class="text-light"
        v-model="showSelected"
        :options="showSelectedOpt"
      /> <div style="width: 300px" class="mr-2" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops'">
      <multiselect v-model="payrollCodesSelectedFilter" 
        :options="payrollCodesData" 
        :multiple="true" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Payroll Code Filter" label="payrollCode" 
        track-by="id">                    
                         <template slot="tag" slot-scope="props"><span :class="'mr-1 payroll-' + props.option.payrollCodeColor">{{ props.option.payrollCode }} <a @click="removePropOptions(props.option.id)" class="remove-prop">X</a></span></template>
        <template slot="option" slot-scope="props">
                      <div class="option__body"><span :class="'mr-1 payroll-' + props.option.payrollCodeColor">{{ props.option.payrollCode }}</span>{{ props.option.payrollCodeName }}</div>
     
                    </template></multiselect></div>
      </b-nav-form>
    </b-navbar>
    <b-table :fields="fields" :items="payrollMod" stacked="lg">
      <template slot="payrollModCode" slot-scope="data">
        <span :class="'payroll-' + getCodeObj(data.item.payrollModCode).payrollCodeColor" class="mr-4">{{ getCodeObj(data.item.payrollModCode).payrollCode }}</span>
        <span class="hoursCursor" v-if="getCodeObjLeave(data.item.payrollModCode) && recoReportsDataSorted" variant="info" @click="showBankUser(data.item)">hrs</span>
      </template>
      <template slot="payrollModDatetime" slot-scope="data">
       <span :class="getSpanClass(data.item.payrollModDatetime, data.item.updatedAt)" :title="'Last Updated: ' + getFormatedDateTime(data.item.updatedAt, true)"> {{ getFormatedDateTime(data.item.payrollModDatetime, false)}}</span>
      </template>
      <template slot="TargetUser" slot-scope="data">
        <span v-if="data.item.TargetUser" :id="'popoveruser-'+data.item.TargetUser.id">{{ data.item.TargetUser.firstName }}  {{ data.item.TargetUser.lastName }} </span>
         <b-popover
          v-if="data.item.TargetUser"
          :target="'popoveruser-'+data.item.TargetUser.id"
          placement="right"
          :title="data.item.TargetUser.stationAssignment + '|' + data.item.TargetUser.shiftAssignment"
          triggers="hover focus"
          :content="data.item.TargetUser.employeeId"
        >
        <div><userCard :user="data.item.TargetUser" :specialitiesArray="data.item.TargetUser.deptSpecialties" :defaultShow="true" />
          </div>
        </b-popover>
        
        <br v-if="data.item.ExchangeUser">
        <span v-if="data.item.ExchangeUser">ExON: {{ data.item.ExchangeUser.firstName }}  {{ data.item.ExchangeUser.lastName }}</span>
      </template>
      <template slot="createdAt" slot-scope="data">
        {{ getFormatedDateTime(data.item.createdAt, true)}}
      </template>
      <template slot="payrollModApproved" slot-scope="data">
        <b-badge :variant="getApprovalStatusColor(data.item.payrollModApproved, data.item.ExchangeUser, data.item.payrollModExchangeApproved)"><h6 class="mb-0">{{ getApprovalStatus(data.item.payrollModApproved, data.item.ExchangeUser, data.item.payrollModExchangeApproved) }}</h6></b-badge>
      </template>
      <template slot="ApprovalUser" slot-scope="data">
       <span v-if="data.item.ApprovalUser"> {{ data.item.ApprovalUser.lastName }}</span>
      </template>
      <template slot="Edit" slot-scope="data">
        <b-btn size="sm" variant="primary" @click="updatePayrollMod(data.item.id)">Update</b-btn>
        <b-btn size="sm" variant="danger" @click="deletePayrollMod(data.item)" v-if="userData.role == 'admin' && false">Delete</b-btn>
      </template>
    </b-table>
    <b-modal v-model="showUserBanks" :title="bankUserRequest.lastName + ', ' + bankUserRequest.firstName">
    <bankUser 
    v-if="recoReportsDataSorted"  
    :previousDataReco="recoReportsDataSorted[0]" 
    :userSelected="bankUserRequest" 
    :payrollBanksData="payrollBanksData"
    :payrollAccuralData="payrollAccuralData"
    :payrollCodesData="payrollCodesData"
    :stacked="true"
     />
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import bankUser from './bankUser.vue'
import rosterSpot from './rosterspotNew.vue'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import JsonExcel from 'vue-json-excel';
export default {
components: {
  'multiselect' : Multiselect,
  rosterSpot,
  userCard,
  bankUser,
  'download-excel' : JsonExcel
},
props: ['payrollCodesData', 'recoReportsData', 'payrollBanksData', 'payrollAccuralData', 'payrollPermsData'],
data() {
    return {
    showUserBanks: false,
    userSelected: '',
    bankUserRequest: '',
    start: {name: 'base', startTime: 1, endTime: 10},
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
    payrollModDailyRosterData: [],
    payrollCodesSelectedFilter: [],
    sortMods: [
      {name: 'firstSwap', startTime: 2, endTime: 3},
      {name: 'secondSwap', startTime: 4, endTime: 6},
      {name: 'thirdSwap', startTime: 8, endTime: 9}
    ],
    fields: [
      {key: 'payrollModCode', label: 'Code'},
      {key: 'payrollModDatetime', label: 'Date'},
      {key: 'payrollModHours', label: 'Hours'},
      {key: 'TargetUser', label: 'Target'},
      {key: 'payrollModApproved', label: 'Status'},
      {key: 'createdAt', label: 'Submitted'},
      {key: 'ApprovalUser', label: 'Admin'},
      'Edit'
    ],
    payrollModParent: null,
    payrollModShift: null,
    showSelected: [true, null],
    showSelectedOpt: [{text: 'Approved', value: true},{text: 'Denied', value: false},{text: 'Needs Attn', value: null}],
    holdUsersNoFilter: [],
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    nonSpotModification: [
       'payaddative',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
      'overtime',
    ]
  }
},
created () {
  bus.$on('newPayrollModification', this.getPayrollModForApproval)

},
beforeDestroy(){
  	
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  recoReportsDataSorted(){
          var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
          if(array[0]){
          return array
          } else {
            return null
          }
  },
  deptContactDataFiltered(){
    if(this.payrollModDailyRosterData && this.payrollModDailyRosterData.length > 0){
    var mods = _.cloneDeep(this.payrollModDailyRosterData)
    var userids = mods.map(p => p.payrollModTargetUserId)
    var exchange = mods.map(p => p.payrollModExchangeUserId)
    var array = this.deptContactData.filter(p => {
      return userids.indexOf(p.id) != -1 || exchange.indexOf(p.id) != -1
    })
    return _.orderBy(array, ['lastName'], ['asc'])
    } return _.orderBy(this.deptContactData, ['lastName'], ['asc'])

  },
  payrollMod(){
    var mods = _.cloneDeep(this.payrollModDailyRosterData)
    var prearray = mods.map(item => {
        var obj = item
        obj.TargetUser = this.getUserData(item.payrollModTargetUserId)
        if(obj.TargetUser){
        obj.TargetShift = obj.TargetUser && obj.TargetUser.shiftAssignment ? obj.TargetUser.shiftAssignment : ''
        obj.TargetParent = this.getTargetParent(obj.TargetUser.stationAssignment)
        obj.ExchangeUser = this.getUserData(item.payrollModExchangeUserId)
        obj.ApprovalUser = this.getUserData(item.payrollModApprovalUserId)
        return obj
        } return obj
    })
    var array = prearray.filter(p => {
        return p && p.TargetUser && this.showSelected.indexOf(p.payrollModApproved) > -1
    })
    this.holdUsersNoFilter = _.cloneDeep(array)
    if(this.payrollModShift){
      var shift = array.filter(p => {
        return p.TargetShift == this.payrollModShift
      })
    } else {
      var shift = array
    }
    if(this.payrollModParent){
      var parent = shift.filter(p => {
        return p.TargetParent == this.payrollModParent
      })
    } else {
      var parent = shift
    }
    if(this.userSelected){
      var userfilter = parent.filter(p => {
        return p.payrollModExchangeUserId == this.userSelected.id || p.payrollModTargetUserId == this.userSelected.id
      })
    } else {
      var userfilter = parent
    }
    if(this.payrollCodesSelectedFilter && this.payrollCodesSelectedFilter.length > 0){
      var pcodeArray = this.payrollCodesSelectedFilter.map(p => p.id)
      var payrollCodeFilter = userfilter.filter(p => {
            var index = pcodeArray.indexOf(p.payrollModCode)
            if(index != -1){
              return p
            }
      })
    } else {
      var payrollCodeFilter = userfilter
    }
    return _.orderBy(payrollCodeFilter, ['payrollModDatetime'], ['asc'])
  },
  fixDataExcelCombined(){
    if(this.payrollMod){
      var data = _.cloneDeep(this.payrollMod)
      var array = data.map(item => {
        var obj = {}
        obj.targetUser = this.getUserDataExport(item.TargetUser)
        obj.payrollModCode = this.getCodeObj(item.payrollModCode).payrollCode
        obj.payrollModType = item.payrollModType
        obj.payrollModHours = item.payrollModHours
        obj.payrollModDatetime = this.getFormatedDateTime(item.payrollModDatetime)
        obj.targetShift = item.TargetShift
        obj.targetParent = item.TargetParent
        obj.exchangeUser = this.getUserDataExport(item.ExchangeUser)
        obj.approvedBy = this.getUserDataExport(item.ApprovalUser)
        obj.lastUpdated = this.getFormatedDateTime(item.updatedAt)
        obj.notes = item.payrollModNote
        obj.status = this.getApprovalStatus(item.payrollModApproved, item.ExchangeUser, item.payrollModExchangeApproved) 
        return obj
      })
      // console.log(array)
      return array
    } return []
  },
  payrollModShiftOpt(){
    var shiftArr = [{text: 'None', value: null}]
    var array = this.holdUsersNoFilter
    var users = array.map(p => p.TargetUser)
    var shifts = _.sortBy([...new Set(users.map(p => p.shiftAssignment))])
    for(var i in shifts){
      shiftArr.push(shifts[i])
    }
    return shiftArr
  },
  payrollModParentOpt(){
    var shiftArr = [{text: 'None', value: null}]
    var array = this.holdUsersNoFilter
    var shifts = _.sortBy([...new Set(array.map(p => p.TargetParent))])
    for(var i in shifts){
      shiftArr.push(shifts[i])
    }
    return shiftArr
  },
  startArrayItems(){
    var base = _.cloneDeep(this.start)
    var array = []
    var mods = this.sortMods
    for(var i = 0; i < mods.length; i++){
      if(i == 0){
        if(base.startTime < mods[i].startTime){
        var obj = {name: 'Base_' + i, startTime: base.startTime, endTime: mods[i].startTime }
        array.push(obj)
        array.push(mods[i])
        } else {
          array.push(mods[i])
        }
      } else if (i < mods.length){
        if(mods[i - 1].endTime < mods[i].startTime){
         var obj = {name: 'Base_' + i, startTime: mods[i-1].endTime, endTime: mods[i].startTime }
            array.push(obj)
            array.push(mods[i])
        } else {
             array.push(mods[i])
        }
      }
      if(i + 1 == mods.length){
          if(base.endTime > mods[i].endTime){
           var obj = {name: 'Base_' + (i + 1), startTime: mods[i].endTime, endTime: base.endTime } 
           array.push(obj)   
      } 
      }
    }
    return array
  },
},  
mounted(){

},
watcher:{

},
methods: {
  showBankUser(data){
    this.bankUserRequest = data.TargetUser
    this.showUserBanks = true
  },
  getUserDataExport(data){
    if(data && data.firstName){
     // console.log(data)
      return data.firstName + ' ' + data.lastName + ' ' + data.employeeId
    } return ''
  },
  removePropOptions(id){
    var index = this.payrollCodesSelectedFilter.map(p => p.id).indexOf(id)
    if(index != -1){
      this.payrollCodesSelectedFilter.splice(index, 1)
    }
  },
  getPayrollModForApprovalAdmin(){
    //  this.rosterReload = false
      this.previousMod = ''
      this.showModal = false
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/',
     headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
  }
   }).then( response => {
     var data = response.data
       this.payrollModDailyRosterData = data
     
   })

    },
  getPayrollModForApprovalButton(){
    //  this.rosterReload = false
      this.previousMod = ''
      this.showModal = false
      var start = moment(this.startDate, 'YYYY-MM-DD').toISOString()
      var end = moment(this.endDate, 'YYYY-MM-DD').add(1, 'day').toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/approval/' + start + '/' + end,
     headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
  }
   }).then( response => {
     var data = response.data
     if(this.siteconfig.payroll.customPerms && this.payrollPermsData){
       var obj = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
      // console.log('PAYROLL PERMS')
       if(obj && obj.payrollManageUsers && obj.payrollManageUsers.length > 0){
         this.payrollModDailyRosterData = data.filter(p => {
                var index = obj.payrollManageUsers.indexOf(p.payrollModTargetUserId)
                if(index >= 0 || p.payrollModTargetUserId == this.userData.id){
                  return p
                }
         })
       } else {
            this.payrollModDailyRosterData = data
       }
     } else {
       this.payrollModDailyRosterData = data
     }
   })

    },
  getPayrollModForApproval(){
      if(this.payrollModDailyRosterData.length > 0){
      this.previousMod = ''
      this.showModal = false
      var start = moment(this.startDate, 'YYYY-MM-DD').toISOString()
      var end = moment(this.endDate, 'YYYY-MM-DD').add(1, 'day').toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/approval/' + start + '/' + end,
     headers: {
        'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',
  }
   }).then( response => {
     var data = response.data
     if(this.siteconfig.payroll.customPerms && this.payrollPermsData){
       var obj = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
      // console.log('PAYROLL PERMS')
       if(obj && obj.payrollManageUsers && obj.payrollManageUsers.length > 0){
         this.payrollModDailyRosterData = data.filter(p => {
                var index = obj.payrollManageUsers.indexOf(p.payrollModTargetUserId)
                if(index >= 0 || p.payrollModTargetUserId == this.userData.id){
                  return p
                }
         })
       } else {
            this.payrollModDailyRosterData = data
       }
     } else {
       this.payrollModDailyRosterData = data
     }
   })
      }
    //  this.rosterReload = false


    },
    getSpanClass(date, update){
      if(date && update){
        var selected = moment(date).format('YYYY-MM-DD')
        var start = moment(selected + 'T' + this.siteconfig.payroll.shiftStartTime)
        var nextShift = moment(start).add(1, 'day').toISOString()
        var boo = moment(nextShift).isAfter(update)
        if(boo){
          return ''
        } else {
          return 'text-danger font-weight-bold' 
        }
      } return ''
    },
    getApprovalStatus(data, exchange, status){
        if(!exchange){
        return data == true ? 'Approved' : data == false ? 'Denied' : data == null ? 'Needs Attn' : ''
        } else {
          return status == true && data == true ? 'Approved' : data == false ? 'Denied' : status == false ? 'User Denied' : status == true ? 'Needs Attn' : 'Awaiting'
        }
    },
    getApprovalStatusColor(data, exchange, status){
          if(!exchange){
       return data == true ? 'success' : data == false ? 'danger' : data == null ? 'warning' : ''
        } else {
          return status == true && data == true ? 'success' : data == false ? 'danger' : status == false ? 'danger' : status == true ? 'warning' : 'info'
        }
    },
  getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user
        } else if (this.userData.role == 'admin'){
          return 'Previous Employee'
        } else {
          return null
        }
      } else {
        return null
      }
    },
    getCodeObjLeave(id){
      var obj =  this.payrollCodesData.find(p => p.id == id)
      if(obj && obj.payrollCodeBankMod && obj.payrollCodeBankMod.length > 0){
        var array = obj.payrollCodeBankMod.filter(p => p.mod == 'subFrom')
        if(array && array.length > 0){
          return true
        } return false
      } return false
    },
    getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    getFormatedDateTime(date, seconds){
      if(date){
        if(seconds){
        return moment(date).format('MM/DD/YY HH:mm:ss')
        } else {
           return moment(date).format('MM/DD/YY HH:mm')
        }
      } else {
        return 'Unknown'
      }
    },
    getTargetParent(station){
      var obj = this.stationsData.find(p => p.stationName == station)
      return obj && obj.stationParent ? obj.stationParent : obj && obj.stationName ? obj.stationName : station
    },
    deletePayrollMod(data){
          var result = confirm('Are You Sure You Want to this Payroll Modification? A better option would be to Deny the modification to save the record!')
      if (result) {
    axios({
     method: 'delete',
     url: '/api/v1/staffing/payrollmod/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     bus.$emit('newPayrollModification')
    //  console.log(response.data)
   })
      } 

    },
    updatePayrollMod(id){
      var data = this.payrollModDailyRosterData.find(p => p.id == id)
      bus.$emit('updatePayrollMod', data)
    },
  

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.remove-prop {
  color: #ddd !important;
  font-weight: bold;
  cursor: pointer;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.hoursCursor {
    background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
