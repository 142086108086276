<template>
  <div class="hello">
    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Admin User Toolbar</b-navbar-brand>
        <b-form-input class="bootstrapTable mr-sm-2" type="text" v-model="searchData" placeholder="Search"></b-form-input>
      
          <b-btn size="sm" v-if="!addNewMed" :disabled="editUserTable" @click="addNewMed = !addNewMed" variant="success">Add New Med</b-btn>
          <b-btn size="sm" v-if="addNewMed" :disabled="editUserTable"  @click="addNewMed = !addNewMed" variant="danger">Cancel</b-btn>
        <b-btn :disabled="addNewMed" class="bootstrapTable" size="sm" v-if="!editUserTable" variant="danger" @click="toggleEditUserTable">Turn On Editing</b-btn>
        <b-btn class="bootstrapTable" size="sm" v-if="editUserTable" variant="primary" @click="toggleEditUserTable">Turn Off Editing</b-btn>

      </b-nav-form>
    </b-navbar>
    <b-table v-if="addNewMed" class="bootstrapTable" :fields="fields" :items="addNew" >
      <template slot="medicationName" slot-scope="data">
        <b-form-input size="sm"  type="text" v-model="data.item.medicationName" placeholder="name" />
      </template>
      <template slot="rescueCount" slot-scope="data">
        <b-form-input  size="sm" type="number" v-model="data.item.rescueCount" />
      </template>
      <template slot="engineCount" slot-scope="data">
        <b-form-input  size="sm" type="number" v-model="data.item.engineCount" />
      </template>
      <template slot="narcoticTrack" slot-scope="data">
      <b-form-checkbox :id="'checkbox1' + data.item.id"
                 v-model="data.item.narcoticTrack"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="coldMedication" slot-scope="data">
      <b-form-checkbox :id="'checkbox2' + data.item.id"
                 v-model="data.item.coldMedication"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="alsSupply" slot-scope="data">
      <b-form-checkbox :id="'checkbox3' + data.item.id"
                 v-model="data.item.alsSupply"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="id" slot-scope="data">
        <b-btn variant="primary" size="sm" @click="submitArticl(data.item)">Add</b-btn>
      </template>

    </b-table>
    <b-table v-if="!editUserTable" class="bootstrapTable" :fields="fields" :items="filteredDepartmentMedications">
      <template slot="id" slot-scope="data">
        <h5 :title="data.item.id">dbId</h5>
      </template>

    </b-table>
    <b-table v-if="editUserTable" class="bootstrapTable" :fields="fields" :items="departmentMedicationEdit">
      <template slot="medicationName" slot-scope="data">
        <b-form-input size="sm"  type="text" v-model="data.item.medicationName" />
      </template>
      <template slot="rescueCount" slot-scope="data">
        <b-form-input  size="sm" type="number" v-model="data.item.rescueCount" />
      </template>
      <template slot="engineCount" slot-scope="data">
        <b-form-input  size="sm" type="number" v-model="data.item.engineCount" />
      </template>
      <template slot="narcoticTrack" slot-scope="data">
      <b-form-checkbox :id="'checkbox1' + data.item.id"
                 v-model="data.item.narcoticTrack"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="narcoticTrackId" slot-scope="data">
      <b-form-checkbox :id="'checkbox2' + data.item.id"
                :disabled="!data.item.narcoticTrack"
                 v-model="data.item.narcoticTrackId"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
            <template slot="narcoticTrackDate" slot-scope="data">
      <b-form-checkbox :id="'checkbox12' + data.item.id"
       :disabled="!data.item.narcoticTrack"
                 v-model="data.item.narcoticTrackDate"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="narcoticTrackCt" slot-scope="data">
      <b-form-checkbox :id="'checkbox13' + data.item.id"
       :disabled="!data.item.narcoticTrack"
                 v-model="data.item.narcoticTrackCt"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
            <template slot="narcoticTrackTamper" slot-scope="data">
      <b-form-checkbox :id="'checkbox14' + data.item.id"
                :disabled="!data.item.narcoticTrack"
                 v-model="data.item.narcoticTrackTamper"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="coldMedication" slot-scope="data">
      <b-form-checkbox :id="'checkbox26' + data.item.id"
                 v-model="data.item.coldMedication"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="alsSupply" slot-scope="data">
      <b-form-checkbox :id="'checkbox17' + data.item.id"
                 v-model="data.item.alsSupply"
                 value="true"
                 unchecked-value="false">
               </b-form-checkbox>
      </template>
      <template slot="Edit" slot-scope="data">
        <b-btn v-if="checkChanges(data.item)" @click="editMedData(data.item)" variant="warning">Update</b-btn>
      </template>
       <template slot="Remove" slot-scope="data">
        <b-btn size="sm" @click="deleteMedicationRow(data.item.id)" variant="danger">Delete</b-btn>
       </template>
    </b-table>


  </div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  name: 'hello',
  data () {
    return {
      addNewMed: false,
      editing: false,
      vehicleTypes: [],
      parentGroupings: [],
      originalApparatusData: [],
      medicalOptions: [
        { text: 'ALS', value: 'ALS'},
        { text: 'BLS', value: 'BLS'}
      ],
      rescueCountCol: true,
      engineCountCol: true,
      coldMedCol: true,
      medicalCol: true,
      
      narcoticCol: true,
      alsSupplyCol: true,
      typeCol: true,
      editUserTable: false,
      searchData: '',
      bodyShow: false,
      addNew: [
        {
          coldMedication: "false",
          engineCount:"1",
          narcoticTrack: "false",
          rescueCount: "2",
                    narcoticTrackId: "false",
          narcoticTrackDate: "false",
          narcoticTrackCt: "false",
          narcoticTamper: "false",
          name: "",
        }
      ]


    }
  },
  created(){

  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'medicationData',
        ]),
        testUserRights(){
          if(this.userData.role == 'adminchief'
            || this.userData.role == 'admin'
            || this.userData.role == 'emsops'){
              return false
            } else {
              return true
            }
        },
        fields(){
          var objectArray = []
          objectArray.push({ key: 'medicationName', label: 'Medication' })
          if(this.rescueCountCol == true){
            objectArray.push({ key: 'rescueCount', label: 'RES Ct'})
          }
          if(this.engineCountCol == true){
            objectArray.push({ key: 'engineCount', label: 'ENG Ct'})
          }
          if(this.narcoticCol == true){
            objectArray.push({ key: 'narcoticTrack', label: 'NarcTrack?'})
          }
          objectArray.push({key: 'narcoticTrackId', label: 'Id?'})
          objectArray.push({key: 'narcoticTrackDate', label: 'inDate?'})
          objectArray.push({key: 'narcoticTrackCt', label: 'Ct?'})
          objectArray.push({key: 'narcoticTrackTamper', label: 'Tamper?'})
          if(this.coldMedCol == true){
            objectArray.push({ key: 'coldMedication', label: 'Cold Med'})
          }
          if(this.alsSupplyCol == true){
            objectArray.push({ key: 'alsSupply', label: 'ALS Supply'})
          }
          objectArray.push({ key: 'id', label: 'ID'})
          if(this.editUserTable == true){
            objectArray.push('Edit')
          }
          if(this.editUserTable == true){
            objectArray.push('Remove')
          }
          return objectArray
        },
        filteredDepartmentMedications(){
          var meds = this.medicationData.filter( (p) => {
            return p.medicationName.toLowerCase().match(this.searchData.toLowerCase())

          })
          return _.orderBy(meds, ['medicationName'], ['asc'])
        },
        departmentMedicationEdit(){
          return [...new Set(this.filteredDepartmentMedications.map((p) => {
            return {
                  "medicationName" : p.medicationName,
                  "rescueCount" : p.rescueCount,
                  "engineCount" : p.engineCount,
                  "narcoticTrack" : p.narcoticTrack,
                  "narcoticTrackId" : p.narcoticTrackId,
                  "narcoticTrackDate" : p.narcoticTrackDate,
                  "narcoticTrackCt": p.narcoticTrackCt,
                   "narcoticTrackTamper": p.narcoticTrackTamper,
                  "coldMedication" : p.coldMedication,
                  "alsSupply" : p.alsSupply,
                  "id" : p.id
            }
          })
        )]
      }
  },
  mounted(){

  },
  watcher: {

  },
  methods: {
    checkChanges(data){
      var currentUserFilter = this.medicationData.filter( (p) => {
        return p.id.match(data.id)
      })
      var currentUser = currentUserFilter[0]
      if( currentUser.medicationName != data.medicationName ||
          currentUser.coldMedication != data.coldMedication ||
          currentUser.narcoticTrack != data.narcoticTrack ||
          currentUser.engineCount != data.engineCount ||
          currentUser.narcoticTrackId != data.narcoticTrackId ||
          currentUser.narcoticTrackDate != data.narcoticTrackDate ||
          currentUser.narcoticTrackCt != data.narcoticTrackCt ||
         currentUser.narcoticTrackTamper != data.narcoticTrackTamper ||
          currentUser.rescueCount != data.rescueCount ||
          currentUser.alsSupply != data.alsSupply
          ){
        return true
      }
      else {
      return false
    }
    },
    testData(data){
      console.log(data)
    },
    toggleEdit(data){
      if(this.editing == data){
        this.editing = false;
      } else {
        this.editing = data;
      }
    },
    toggleEditUserTable(event){

      this.editUserTable = !this.editUserTable
    },
    toggleBodyShow(data){
      if(this.bodyShow == data){
        this.bodyShow = false;
      } else {
        this.bodyShow = data;
      }
    },
    keyCorrection(data){
      var underscore = data.replace(/_/g, ' ')
      var apparatus = underscore.replace('apparatus', '')
      var vehicle = apparatus.replace('vehicle', '')
      var last = vehicle.replace('number', '')
      var field = last.replace('field', '')
      var filter = field.replace('filter', '')
      return filter
    },
    stationValue(data){
      console.log(data)
      var matchData = this.stationsData.filter(p => {
        return p.name.match(data)})
        console.log(matchData)
      return JSON.parse(matchData[0].tid)
    },
    typeValue(data){
      var matchData = this.vehicleTypes.filter(p => {
        return p.name.match(data)})
      return JSON.parse(matchData[0].tid)
    },
    assignValue(data){
      var matchData = this.parentGroupings.filter(p => {
        return p.name.match(data)})
      return JSON.parse(matchData[0].tid)
    },
    jsonValidationReturn(data){
      console.log(data)
      if(data == "false"){
        return false
      }
      if(data == "True"){
        return true
      }
      if(data == true){
        return true
      }
      if(data == "true"){
        return true
      }
      else {
        return false
      }
    },
    editMedData(data){
      console.log(data)
      var nodeID = data.id
     axios({
      method: 'patch',
      url: '/api/v1/taxonomy/medication/' + nodeID,
      data: {
                  "medicationName" : data.medicationName,
                  "rescueCount" : data.rescueCount,
                  "engineCount" : data.engineCount,
                  "narcoticTrack" :this.jsonValidationReturn(data.narcoticTrack),
                  "narcoticTrackId": this.jsonValidationReturn(data.narcoticTrackId),
                  "narcoticTrackDate": this.jsonValidationReturn(data.narcoticTrackDate),
                  "narcoticTrackCt": this.jsonValidationReturn(data.narcoticTrackCt),
                  "narcoticTrackTamper": this.jsonValidationReturn(data.narcoticTrackTamper),
                  "coldMedication" : this.jsonValidationReturn(data.coldMedication),
                  "alsSupply" :  this.jsonValidationReturn(data.alsSupply),
              
        },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
    }).then( response => {
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
      var payload = { taxonomy: 'medication', headers: headers, expire: true}
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)


      })

  },
    submitArticl(data){
          axios({
      method: 'post',
      url: '/api/v1/taxonomy/medication/',
      data: {
                  "medicationName" : data.medicationName,
                  "rescueCount" : data.rescueCount,
                  "engineCount" : data.engineCount,
                  "narcoticTrack" :this.jsonValidationReturn(data.narcoticTrack),
                  "narcoticTrackId": this.jsonValidationReturn(data.narcoticTrackId),
                  "narcoticTrackDate": this.jsonValidationReturn(data.narcoticTrackDate),
                  "narcoticTrackCt": this.jsonValidationReturn(data.narcoticTrackCt),
                  "narcoticTrackTamper": this.jsonValidationReturn(data.narcoticTrackTamper),
                  "coldMedication" : this.jsonValidationReturn(data.coldMedication),
                  "alsSupply" :  this.jsonValidationReturn(data.alsSupply),
              
        },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
    }).then( response => {
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
      var payload = { taxonomy: 'medication', headers: headers, expire: true}
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
      this.addNew = [
        {
          coldMedication: "false",
          engineCount:"1",
          narcoticTrack: "false",
          narcoticTrackId: "false",
          narcoticTrackDate: "false",
          narcoticTrackCt: "false",
          narcoticTrackTamper: "false",
          rescueCount: "2",
          name: "",
        }
      ]


      })

  },
  deleteMedicationRow(id){
   axios({
      method: 'delete',
      url: '/api/v1/taxonomy/medication/' + id,
      data: { },
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
    }).then( response => {
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
      var payload = { taxonomy: 'medication', headers: headers, expire: true}
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
  })
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
h1, h2 {
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
