<template>
  <div class="hello">
    <b-radio-group v-model="shiftSelect">
      <b-radio value="">All</b-radio>
      <b-radio value="A-shift">A Shift</b-radio>
      <b-radio value="B-shift">B Shift</b-radio>
      <b-radio value="C-shift">C Shift</b-radio>
    </b-radio-group>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Age">
    <transition-group name="slide-fade" tag="div">
   <div v-for="data in departmentData" :key="data.id" class="chartWrapper">
     <span>{{ data.name }}</span> <div  class="chart" :style="{width: data.time * 50 + 'px'}" >{{ data.time.toFixed(2) + ' yrs'  }}</div>
   </div>
    </transition-group>
        </b-tab>
        <b-tab title="Ranks">
          <span class="dataKey" v-for="(rank, index) in rankHold" :key="index"><div class="keyBox" :class="'rank-' + index"></div>{{ rank }}</span>
          <transition-group name="slide-fade" tag="div">
          <div v-for="data in departmentDataRanks" :key="data.id" class="chartWrapper" @mouseover="hoverId = data.id"  @mouseout="hoverId = ''">
           <span>{{ data.name }}</span> <div class="rankDivWrapper" :style="{width: 800 + 'px'}" ><div v-for="(sub, index) in data.ranks" :key="index" :class="'rankDiv rank-' + rankHold.indexOf(sub.rankName)" :style="{width: (sub.count / data.totalCt * 780).toFixed(0) + 'px'}"></div></div>
             <b-container class="breakDownList" v-if="hoverId == data.id">
               <h5>Station Total {{ data.totalCt }} ({{ (data.totalCt / deptContactData.length * 100).toFixed(2) }}% of Dept)</h5>
                  <b-row v-for="(sub, index) in data.ranks" :key="index"><b-col cols="3" align="right">{{ sub.rankName }} ({{ sub.count }}) ({{  (sub.count  / data.totalCt  * 100).toFixed(2) }}%)</b-col><b-col cols="7"><div  :class="'rankDiv rank-' + rankHold.indexOf(sub.rankName)" :style="{width: (sub.count / data.totalCt * 780).toFixed(0) + 'px', height: '18px'}"></div></b-col></b-row>
             </b-container>
             </div>
              </transition-group>
        </b-tab>
        <b-tab title="Specialties">
          <span class="dataKey" v-for="(rank, index) in specHold" :key="index"><div class="keyBox" :class="'rank-' + index"></div>{{ rank }}</span>
          <transition-group name="slide-fade" tag="div">
          <div v-for="data in departmentDataSpec" :key="data.id" class="chartWrapper" @mouseover="hoverId = data.id" @mouseout="hoverId = ''">
           <span>{{ data.name }}</span> <div class="specDivWrapper" :style="{width: 600 + 'px'}" ><div v-for="(sub, index) in data.ranks" :key="index" :class="'specDiv rank-' + specHold.indexOf(sub.rankName)" :style="{width: (sub.count / data.totalCt * 600).toFixed(0) + 'px'}"></div></div>
             <b-container class="breakDownList" v-if="hoverId == data.id">
               <h5>Station Total {{ data.totalCt }} ({{ (data.totalCt / deptContactData.length * 100).toFixed(2) }}% of Dept)</h5>
                  <b-row v-for="(sub, index) in data.ranks" :key="index"><b-col cols="3" align="right">{{ sub.rankName }} ({{ sub.count }}) ({{  (sub.count  / data.totalCt  * 100).toFixed(2) }}%)</b-col><b-col cols="7"><div  :class="'rankDiv rank-' + specHold.indexOf(sub.rankName)" :style="{width: (sub.count / data.totalCt * 780).toFixed(0) + 'px', height: '18px'}"></div></b-col></b-row>
             </b-container>
             </div>
              </transition-group>
        </b-tab>
        <b-tab title="CAD">
          <caddata />
          </b-tab>
        </b-tabs>
    </b-card>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'

import caddata from './cadData.vue'
import axios from 'axios'
export default {
  name: 'hello',
  components: {

    caddata
  },
  data () {
    return {
      shiftSelect: '',
      rankHold: [],
      specHold: [],
      hoverId: '',
    }
  },
  mounted(){
  },
  computed: {
      ...mapState([
        'apparatusData',
        'stationsData',
        'deptContactData',
        'userData',
        'documentTags',
        'getSessionData',
        'documentsApi'
      ]),
    departmentFilter(){
      var shift = this.deptContactData.filter(p => {
        return p.shiftAssignment.match(this.shiftSelect)
      })
      return shift
      },
      departmentData(){
      var data = this.buildData(this.departmentFilter)
      return data
      },
      departmentDataRanks(){
        var data = this.buildDataRank(this.departmentFilter)
        return data
      },
      departmentDataSpec(){
        var data = this.buildDataSpec(this.departmentFilter)
        return data
      },
    

  },
  watch: {
  },
  methods: {
    buildData(shift){
      var stations = [...new Set(this.stationsData.filter(p => {
      return p.stationParent != ''
    }))]
    var dataArr = []
    for(var i in stations){
      var users = shift.filter(p => {
        return p.stationAssignment == stations[i].stationName
      })
      if(users){
        var time = []
        for(var p in users){
          time.push(this.getYearsService(users[p].hireDate))
        }
        var total = 0
        if(time.length > 0){
          for(var t in time){
            total += time[t]
          }
          var total = total/time.length
          dataArr.push({id: stations[i].id, name: stations[i].stationName, time: total})
        }
      }
    }
   return _.orderBy(dataArr, ['time'], ['asc'])
    },
   buildDataRank(shift){
      var stations = [...new Set(this.stationsData.filter(p => {
      return p.stationParent != ''
    }))]
    var ranksHoldArr = []
    var dataArr = []
    for(var i in stations){
      var users = shift.filter(p => {
        return p.stationAssignment == stations[i].stationName
      })
      if(users){
        var employees = users.length
        var ranks = []
        for(var p in users){
          ranksHoldArr.push(users[p].deptRank)
          ranks.push(users[p].deptRank)
        }
        var total = []
        var rankSetup = [...new Set(ranks.map(p => p))]
        var rankSetup = _.sortBy(rankSetup)
        if(rankSetup.length > 0){
          for(var r in rankSetup){
           var countlength = ranks.filter(p => {
              return p == rankSetup[r]
            })
            total.push({rankName: rankSetup[r], count: countlength.length})
          }
          dataArr.push({id: stations[i].id, name: stations[i].stationName, ranks: total, totalCt: employees})
        }
      }
    }
    var rankSetupNew = [...new Set(ranksHoldArr.map(p => p))]
    this.rankHold =  _.sortBy(rankSetupNew)
   return dataArr
    },
       buildDataSpec(shift){
      var stations = [...new Set(this.stationsData.filter(p => {
      return p.stationParent != ''
    }))]
    var ranksHoldArr = []
    var dataArr = []
    for(var i in stations){
      var users = shift.filter(p => {
        return p.stationAssignment == stations[i].stationName
      })
      if(users){
        var employees = users.length
        var ranks = []
        for(var p in users){
          var spec = users[p].deptSpecialties
          for( var s in spec){
          ranksHoldArr.push(spec[s])
          ranks.push(spec[s])
          }
        }
        var newHoldLength = ranks.length
        var total = []
        var rankSetup = [...new Set(ranks.map(p => p))]
        var rankSetup = _.sortBy(rankSetup)
        if(rankSetup.length > 0){
          for(var r in rankSetup){
           var countlength = ranks.filter(p => {
              return p == rankSetup[r]
            })
            total.push({rankName: rankSetup[r], count: countlength.length})
          }
          dataArr.push({id: stations[i].id, name: stations[i].stationName, ranks: total, totalCt: employees,  totalSpec: newHoldLength})
        }
      }
    }
    var rankSetupNew = [...new Set(ranksHoldArr.map(p => p))]
    this.specHold =  _.sortBy(rankSetupNew)
  //  console.log(dataArr)
   return dataArr
    },
        getYearsService(data){
      var dataSlice = data.slice(0, -5)
      return moment().diff(dataSlice, 'years')

    },
  
  }

}
</script>
<style scope>
.chart {
  font: 10px sans-serif;
  background-color: steelblue;
  text-align: right;
  padding: 3px;
  margin: 1px;
  color: white;
  display: inline-block;
}
.rankDivWrapper {
  display: inline-block;
}
.specDivWrapper {
  display: inline-block;
  background-color:#aaa;
}
.specDiv {
  height: 4px;
}
.rankDiv {
  display: inline-block;
  font-size: 12px;
  min-height: 16px;
  color: #f2f2f2;
  padding-left: 5px;
}
.keyBox {
  width: 10px;
  height: 10px;
      display: inline-block;
    margin-right: 5px;
    margin-left: 15px;
}
.rank-0 {
  background-color: rgb(0, 136, 0);
}
.rank-1 {
  background-color: rgb(209, 171, 0);
}
.rank-2 {
  background-color: rgb(1, 51, 190);
}
.rank-3 {
  background-color: rgb(0, 121, 177);
}
.rank-4 {
  background-color: #777;
}
.rank-5 {
  background-color: rgb(158, 0, 0);
}
.rank-6 {
  background-color: rgb(3, 141, 118);
}
.rank-7 {
  background-color: #666;
}
.rank-8 {
  background-color: #777;
}
.rank-9 {
  background-color: #888;
}
.rank-10 {
  background-color: #999;
}
.rank-11 {
  background-color: #aaa;
}
.chartWrapper {
  text-align: left;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 1s;
  opacity: 0;
}
.slide-fade-move {
  transition: transform 1s;

}
.slide-fade-enter, .slide-fade-leave {
  opacity: 0;
  width: 0% !important;
  max-height: 10px !important;
}
</style>
