<template>
  <div class="secondaryMenuWrapper" >
    <div id="panelDisplayTop" v-click-outside="closeEvent">
      <userpanel
          :testStationLogbook="testStationLogbook"
          v-show="userPanelShow"
          class="panelClass"
          :userData="userData"
          :userPosts="userPosts"
          @showSMSModal="showSMSModal"
      ></userpanel>
      <stationpanel
      v-if="dataReadyToSend"
      v-show="stationPanelShow"
      class="panelClass"
      />
      <battalionpanel
      v-if="stationParents.length > 0"
      :stationParents="stationParents"
      v-show="battalionPanelShow"
      class="panelClass"
      />
      <emsqapanel
      v-if="stationParents.length > 0"
      :stationParents="stationParents"
      v-show="emsqaPanelShow"
      class="panelClass"
      />
    </div>
    <div v-show="emsqaPanelShow || battalionPanelShow || stationPanelShow || userPanelShow" class="menu-backdrop"></div>



      <div id="secondaryMenu" :class="siteconfig.theme == 'blue' ? 'background-blue' : siteconfig.theme == 'green' ? 'background-green' : 'background-red'" v-if="userData != ''">
         <div  v-if="siteconfig.calendarHideFire"  class="notification-updates views secondary-user-menu-toolbar">
          <a>
            <img @click="userLogoutAction" :src="siteconfig.staticHost + 'images/siteicons/logout-btn-cir.png'" title="Logout" height="32" width="32">
          </a>
    	 </div>
        <div v-else class="notification-updates views secondary-user-menu-toolbar">
          <a>
            <img @click="userLogoutAction" :src="siteconfig.staticHost + 'images/siteicons/userlogout.png'" title="Logout" height="32" width="32">
          </a>
    	 </div>
       <div v-if="siteconfig.calendarHideFire" class="notification-updates views secondary-user-menu-toolbar" @click.stop="toggleUserPanelShow">
           <img :src="siteconfig.staticHost + 'images/siteicons/gen-user.png'"  title="User" height="32" width="32">
       </div>       
       <div v-else class="notification-updates views secondary-user-menu-toolbar" @click.stop="toggleUserPanelShow">
           <img :src="siteconfig.staticHost + 'images/siteicons/user.png'"  title="User" height="32" width="32">
       </div>
       <transition name="rotate">
       <div class="notification-updates views secondary-user-menu-toolbar plusButton " :class="{rotate: !secondaryDivIconsShow, rotateZero: secondaryDivIconsShow}">
           <img  :src="siteconfig.staticHost + 'images/siteicons/ChevronIconUp.png'"  @click.stop="secondaryDivIconsShowChange" title="Station" height="50" width="50">
      </div>
    </transition>
      <div class="notification-updates views secondary-user-menu-toolbar plusButtonEmpty" ></div>

       <div class="notification-updates views secondary-user-menu-toolbar mobileOnlyIcon" v-show="false">
                 <div  v-if="unreadMessages.length > 0 || unreadRoomMessages.length > 0" class="notificationWrapperMobile" >
           <b-badge class="messageNotifications" v-if="unreadMessages.length > 0" pill variant="light"> {{ unreadMessages.length + unreadRoomMessages.length }} </b-badge>
                       
                                 </div>
           <img :src="siteconfig.staticHost + 'images/siteicons/message-icon-black.png'"
           title="Messages" height="32" width="32" @click.stop="toggleMessagePanelShow">


       </div>
       <div v-if="(userData.role == 'user'
                  || userData.role == 'supervisor')
                  && !siteconfig.hideStationLink"
                   class="notification-updates views secondary-user-menu-toolbar"  @click.stop="toggleStationPanelShow" >
           <img :src="siteconfig.staticHost + 'images/siteicons/station.png'" title="Station" height="32" width="32">
   
       </div>
       <div v-if="(userData.role == 'fireops' || userData.role == 'adminchief'
       || userData.role == 'admin') && !siteconfig.hideBattalionLink" class="notification-updates views secondary-user-menu-toolbar"  @click.stop="toggleBattalionPanelShow" >
           <img :src="siteconfig.staticHost + 'images/siteicons/fire-officer-icon.png'"  title="Battalion" height="32" width="32">

      </div>
      <div v-if="userData.role == 'emsops' && !siteconfig.hideEMSQALink" class="notification-updates views secondary-user-menu-toolbar"  @click.stop="toggleEMSQAPanelShow" >
          <img  :src="siteconfig.staticHost + 'images/siteicons/ems-qa-Icon.png'" title="EMS Captain" height="32" width="32">

      </div>
      <div v-if="siteconfig.calendarHideFire" class="notification-updates views secondary-user-menu-toolbar">
        <router-link to="/portal/staffing" >
           <img :src="siteconfig.staticHost + 'images/siteicons/gen-staffing.png'" title="staffing" height="32" width="32">
         </router-link>
       </div>
      <div v-else class="notification-updates views secondary-user-menu-toolbar">
        <router-link to="/portal/staffing" >
           <img :src="siteconfig.staticHost + 'images/siteicons/admin-user-icon.png'" title="staffing" height="32" width="32">
         </router-link>
       </div>
      <div id="secondaryAddTool" class="desktopSecondaryAddTool">
       <div class="notification-updates views secondary-user-menu-toolbar" v-if="!siteconfig.hideNarcoticsLink">
         <a @click.stop="siteconfig.craddleToGrave ? toggleAddNewDailyNarcEntry() : toggleAddNewNarcEntry()">
           <img :src="siteconfig.staticHost + 'images/siteicons/narcs.png'" title="Narcotics Log" height="32" width="32">
      </a>
       </div>
       <div class="notification-updates views secondary-user-menu-toolbar" v-if="!siteconfig.hideVehicleCheckLink">
        <router-link to="/portal/logistics" v-if="siteconfig.detailedVehicleChecks">
           <img :src="siteconfig.staticHost + 'images/siteicons/rescue.png'"  title="Apparatus" height="32" width="32">
         </router-link>
         <a @click.stop="toggleAddNewVehicleCheck" v-else>
           <img :src="siteconfig.staticHost + 'images/siteicons/rescue.png'" title="Apparatus" height="32" width="32">
         </a>
       </div>
       <div key="3" class="notification-updates views secondary-user-menu-toolbar"  v-if="!siteconfig.hideLogbookLink">
         <a @click.stop="toggleAddNewLogbook">
           <img :src="siteconfig.staticHost + 'images/siteicons/logbook.png'" title="Logbook" height="32" width="32">
         </a>
       </div>
       <div class="notification-updates views secondary-user-menu-toolbar"  v-if="!siteconfig.hideRepairLink">
         <a @click.stop="toggleAddNewRepairForm">
           <img :src="siteconfig.staticHost + 'images/siteicons/repair.png'" title="Repair Forms" height="32" width="32">
         </a>
       </div>
       <div  v-if="(userData.role == 'adminchief'
         || userData.role == 'admin' || userData.blogAccess == true) && !siteconfig.hideBlogLink" class="notification-updates views secondary-user-menu-toolbar">
         <a @click.stop="toggleAddNewBlogPost">
           <img :src="siteconfig.staticHost + 'images/siteicons/blog-post-icon.png'" title="Blog Post" height="32" width="32">
         </a>
       </div>
      <div v-if="userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1 && !siteconfig.hideEMSQALink" class="notification-updates views secondary-user-menu-toolbar"  @click.stop="toggleEMSQAPanelShow" >
          <img :src="siteconfig.staticHost + 'images/siteicons/ems-qa-Icon.png'" title="EMS Captain" height="32" width="32">
      </div>
       <div class="notification-updates views secondary-user-menu-toolbar"  v-if="!siteconfig.hidePreplanLink">
        <router-link to="/portal/preplan" >
           <img :src="siteconfig.staticHost + 'images/siteicons/pre-plan.png'" title="preplans" height="32" width="32">
         </router-link>
       </div>

     </div>
     <transition name="easeIn">
      <div id="secondaryAddTool" class="mobileSecondaryAddTool" v-if="!secondaryDivIconsShow">
       <div class="notification-updates views secondary-user-menu-toolbar">
         <a @click.stop="siteconfig.craddleToGrave ? toggleAddNewDailyNarcEntry() : toggleAddNewNarcEntry()">
           <img :src="siteconfig.staticHost + 'images/siteicons/narcs.png'"  title="Narcotics Log" height="32" width="32">
         </a>
       </div>
       <div class="notification-updates views secondary-user-menu-toolbar">
        <router-link to="/portal/logistics" v-if="siteconfig.detailedVehicleChecks">
           <img :src="siteconfig.staticHost + 'images/siteicons/rescue.png'" title="Apparatus" height="32" width="32">
         </router-link>
         <a @click.stop="toggleAddNewVehicleCheck" v-else>
           <img  :src="siteconfig.staticHost + 'images/siteicons/rescue.png'" title="Apparatus" height="32" width="32">
         </a>
       </div>
       <div key="3" class="notification-updates views secondary-user-menu-toolbar">
         <a @click.stop="toggleAddNewLogbook">
           <img :src="siteconfig.staticHost + 'images/siteicons/logbook.png'"   title="Logbook" height="32" width="32">
         </a>
       </div><div class="notification-updates views secondary-user-menu-toolbar">
         <a @click.stop="toggleAddNewRepairForm">
           <img :src="siteconfig.staticHost + 'images/siteicons/repair.png'" title="Repair Forms" height="32" width="32">
         </a>
       </div>
       <div v-if="(userData.role == 'adminchief'
         || userData.role == 'admin') && !siteconfig.hideBlogLink" class="notification-updates views secondary-user-menu-toolbar">
         <a @click.stop="toggleAddNewBlogPost">
           <img :src="siteconfig.staticHost + 'images/siteicons/blog-post-icon.png'" title="Blog Post" height="32" width="32">
         </a>
       </div>
             <div v-if="userData.secondaryAdmin && userData.secondaryAdmin.indexOf('adminNarcs') > -1" class="notification-updates views secondary-user-menu-toolbar"  @click.stop="toggleEMSQAPanelShow" >
          <img :src="siteconfig.staticHost + 'images/siteicons/ems-qa-Icon.png'"   title="EMS Captain" height="32" width="32">
      </div>
             <div class="notification-updates views secondary-user-menu-toolbar">
        <router-link to="/portal/preplan">
           <img  :src="siteconfig.staticHost + 'images/siteicons/pre-plan.png'" title="preplans" height="32" width="32">
         </router-link>
       </div>
     </div>
   </transition>
     <div class="notification-updates views secondary-user-menu-toolbar deptInfoButton">
       <a @click.stop="toggleDeptInfoPan">
       <img :src="siteconfig.staticHost + 'images/siteicons/external-links.png'"  title="Dept Info" height="32" width="32">
     </a>
     </div>
     <div style="clear:both;"></div>
 </div>
<newlogbook v-if="addNewLogbook" :showModal="addNewLogbook"></newlogbook>
<newblogpost v-if="AddNewBlogPost" :showModal="AddNewBlogPost" :postNotificationObj="postNotificationObj" ></newblogpost>
<newvehiclecheck v-if="addNewVehicleCheck" :showModal="addNewVehicleCheck"></newvehiclecheck>
<newrepairform v-if="addNewRepairForm" :showModal="addNewRepairForm"></newrepairform>
<newnarcentry v-if="addNewNarcEntry" :showModal="addNewNarcEntry"></newnarcentry>
<newdailynarcentry v-if="addNewDailyNarcEntry" :showModal="addNewDailyNarcEntry" :bulkNarcs="addNewDailyNarcBulk" :bulkDestroyNarcs="addNewDailyNarcBulkDestroy" @hidden="updateBulkDestroyNarcs($event)"></newdailynarcentry>
<trainingrefitem v-if="addNewTrainingItem" :showModal="addNewTrainingItem"></trainingrefitem>
<calendaraddmodal :filterDate="sendData" v-if="showNewCalendarPost" :showModal="showNewCalendarPost" :defaultEvent="defaultEvent" />
<signature v-if="witnessSign" :showModalSignature="witnessSign" :name="userData.lastName" @signatureCapture="signatureMethodWitness($event)" @closeModalCancel="witnessSign = !witnessSign"></signature>
<b-modal v-model="showSMSModalToggle" title="SMS Message Data Disclosure" ok-title="I agree" @hide="closeSMSModal($event)" >
  <p>By clicking agree, you will allow your department's administrators to notify you of any changes via
    SMS messaging. Standard Message and Data rates will apply via your cellular carrier. All parties, your
    agency and yourself, understand that Stat Portals, Inc offers no compenstation for cellular carrier charges due to
    SMS notifications. You are free to Opt-out and Opt-in from text message notification, and notifications
    will be sent to your user email instead.</p>
</b-modal>
</div>
</template>

<script>

import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { bus } from './../main'
import axios from 'axios'
import moment from 'moment'
import newLogbook from './addNew/newLogbook.vue'
import newVehicleCheck from './addNew/newVehicleCheck.vue'
import newBlogPost from './addNew/newBlogPost.vue'
import newTrainingItem from './addNew/newTrainingRefItem.vue'
import newRepairForm from './addNew/newRepairForm.vue'
import newNarcEntry from './addNew/newNarcoticsLog.vue'
import newDailyNarcEntry from './addNew/newDailyNarcoticsLog.vue'
import CalendarAddModal from './addNew/newCalendarPostFull.vue'
import UserPanel from './secondarymenu/userpanel.vue'
import StationPanel from './secondarymenu/stationpanel.vue'
import BattalionPanel from './secondarymenu/battalionpanel.vue'
import EmsQA from './secondarymenu/emsqapanel.vue'
import signature from './inputfields/signaturepad.vue'
export default {
  name: 'SecondaryMenu',
  props:[ 'closeEventTrigger'],
  components: {
    'calendaraddmodal': CalendarAddModal,
    'userpanel' : UserPanel,
    'newblogpost' : newBlogPost,
    'stationpanel' : StationPanel,
    'newlogbook' : newLogbook,
    'newvehiclecheck' : newVehicleCheck,
    'newrepairform' : newRepairForm,
    'newnarcentry' : newNarcEntry,
    'newdailynarcentry' : newDailyNarcEntry,
    'battalionpanel' : BattalionPanel,
    'emsqapanel' : EmsQA,
    'signature' : signature,
    'trainingrefitem' : newTrainingItem,
  },
  data () {
    return {
        commentFeedDept:[],
        showNewCalendarPost: false,
        testStationLogbook:[],
        commentFeedUser:[],
        addNewDailyNarcBulk: null,
        addNewDailyNarcBulkDestroy: null,
        defaultEvent: '',
        defaultSubEvent: '',
        parentOptions: [],
        postNotificationObj: undefined,
        secondaryDivIconsShow: true,
        userPanelShow: false,
        feedPanelShow: false,
        stationPanelShow: false,
        userStationLinkUrl:'',
        userStationVariable:'',
        currentSet: '',
        transEffect: false,
        dataReadyToSend: true,
        addNewLogbook: false,
        AddNewBlogPost: false,
        addNewVehicleCheck: false,
        addNewRepairForm: false,
        addNewNarcEntry: false,
        addNewDailyNarcEntry: false,
        addNewTrainingItem: false,
        battalionPanelShow: false,
        emsqaPanelShow: false,
        messagePanelOpen: false,
        showSMSModalToggle: false,
        battalionData: '',
        narcCraddleToGrave: false,
        holdValue: '',
        sendData: '',
        witnessSign: false,
        narcsLog: '',
    }
  },
  created (){
    bus.$on('refreshFeedList', this.refreshFeedListGet);
    bus.$on('refreshUserFeedList', this.userDepartmentFeed);
    bus.$on('closeEvent', this.closeEvent);
    bus.$on('updateUserData', this.updateUserDataGet)
    bus.$on('switchEmit', this.toggleAddNewLogbook)
    bus.$on('switchEmitBlog', this.toggleAddNewBlogPost)
    bus.$on('addNewNotification', this.addNewNotificationSetup)
    bus.$on('switchEmitVehicle', this.toggleAddNewVehicleCheck)
    bus.$on('switchEmitRepair', this.toggleAddNewRepairForm)
    bus.$on('switchEmitNarc', this.toggleAddNewNarcEntry)
    bus.$on('switchEmitDailyNarc', this.toggleAddNewDailyNarcEntry)
    bus.$on('switchEmitDailyNarcLegacy', this.toggleAddNewNarcEntryLegacy)
    bus.$on('addNewEventFromCal', this.toggleShowModalTest)
    bus.$on('addNewTrainingItem', this.toggleTrainingItemAdd)
    bus.$on('showModalSwitchFullCalendar', this.cancelAddEventForm)
    bus.$on('LaunchSignaturePanel', this.openSignatureModal)
    bus.$on('LaunchSignaturePanelDaily', this.openSignatureModalDaily)
   /* bus.$on('refreshDomEvent', (data) => {
      if(data != undefined){
      this.currentSet = data
      this.updateStationDataSet(data)
      console.log(data)
      }
      else{
        this.updateStationDataSet(this.holdValue)
      }

    }) */
    this.parentOptions = this.stationParents
   },
   mounted(){

   },
  computed: {
    ...mapState([
          'getSessionData',
          'stationsData',
          'eventTags',
          'apparatusData',
          'userData',
          'userPosts',
          'userDataStore',
          'userAutho',
          'parentGroup',
          'unreadMessages',
          'unreadRoomMessages',
          'siteconfig'

        ]),
      stationParents(){
        var parentObj = [... new Set(this.stationsData.map( p => p.stationParent))]
        var parent = parentObj.filter( p => {
          if(p != '' && p != undefined){
            return p
          }
        })
        return parent
      
      }
    

},
  watch: {
    closeEventTrigger(newData){
     
      return this.closeEvent()
    },
    $route(newRoute){
      if(newRoute){
      return this.closeEvent()
      }
    },
    stationData(newData){
 
    }
  },
  methods: {
    openSignatureModal(data){
      this.narcsLog = data
      this.witnessSign = true
    },
    openSignatureModalDaily(data){
      this.narcsLog = data
      this.narcCraddleToGrave = true
      this.witnessSign = true
    },
    signatureMethodWitness(data){
      this.witnessSign = false
      var newplacedData = data.split(',')
      var signed = newplacedData[1]
      this.submitNarcEdit(signed)
    },
    toggleShowModalTest(data, event, subEvent){
      var splitEvent = event.split('/')
      if(splitEvent.length == 3){
        this.defaultEvent = splitEvent[2]
      } else {
        this.defaultEvent = ''
      }
      this.sendData = data
      this.showNewCalendarPost = true
    },
    cancelAddEventForm(){
      this.showNewCalendarPost = false
      setTimeout(()=>{
        document.body.classList.remove('modal-open')
      },  500)

    },
    userLogoutAction(){
      this.$cookie.remove('userToken')  
      bus.$emit('LoggingOutUserSocket')
     // console.log('LOGGED OUT')
      this.$store.dispatch('LOGOUT_OUT_USER') /*.then(
        response => {
        this.$nextTick(function(){
        this.$router.push({path: '/'})
        var token = ''
        this.$store.dispatch('LOAD_LOGIN_STATUS', token)})},
        response => {
        this.$nextTick(function(){
        this.$router.push({path: '/'}),
        this.$store.dispatch('LOAD_LOGIN_STATUS', token)})}
        ) */

    },
    secondaryDivIconsShowChange(){
      this.userPanelShow = false
      this.stationPanelShow = false
      this.battalionPanelShow = false
      this.emsqaPanelShow = false
      this.feedPanelShow = false
      this.secondaryDivIconsShow = !this.secondaryDivIconsShow
      this.$nextTick(function(){
      this.transEffect = true })
    },
      toggleUserPanelShow(){
        this.feedPanelShow = false
        this.stationPanelShow = false
        this.battalionPanelShow = false
        this.secondaryDivIconsShow = true
        this.emsqaPanelShow = false
        this.userPanelShow = !this.userPanelShow
      },
      toggleStationPanelShow(){
        this.emsqaPanelShow = false
        this.battalionPanelShow = false
        this.feedPanelShow = false
        this.userPanelShow = false
        this.secondaryDivIconsShow = true
        this.stationPanelShow = !this.stationPanelShow
      },
      toggleBattalionPanelShow(){
        this.dataReadyToSend = false
        this.emsqaPanelShow = false
        this.feedPanelShow = false
        this.userPanelShow = false
        this.secondaryDivIconsShow = true
        this.battalionPanelShow = !this.battalionPanelShow
      },
      toggleTrainingItemAdd(){
        
        this.addNewTrainingItem = !this.addNewTrainingItem
      },
      toggleEMSQAPanelShow(){
        this.battalionPanelShow = false
        this.feedPanelShow = false
        this.userPanelShow = false
        this.secondaryDivIconsShow = true
        this.emsqaPanelShow = !this.emsqaPanelShow
      },
      toggleMessagePanelShow(){
        this.userPanelShow = false
        this.stationPanelShow = false
        this.battalionPanelShow = false
        this.emsqaPanelShow = false
        this.secondaryDivIconsShow = true
        this.messagePanelOpen = !this.messagePanelOpen
        if(this.messagePanelOpen == true){
        document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
        bus.$emit('toggleMessagePanel')
      },
      toggleAddNewLogbook(){
        if(this.addNewLogbook == true){
          this.secondaryDivIconsShow = true
            this.addNewLogbook = !this.addNewLogbook
        }
        else{
        var logbooks = this.userPosts.filter((p) => {
           return p.contentType.match("logbook")
        })
        var currentDate = moment().format('MM/DD/YYYY')
        var todayLogbook = logbooks.filter((p) => {
          var formatedDate = moment(p.createdAt).format('MM/DD/YYYY')
          return formatedDate.match(currentDate)
        })
      if(todayLogbook.length > 0){
        var result = confirm('It appears you have already created a logbook for today, which can be edited from either the station or user panel. Would you like to create another?')
        if (result) {
          this.secondaryDivIconsShow = true
            this.addNewLogbook = !this.addNewLogbook
          }
          else {}
      }
      else {
      this.secondaryDivIconsShow = true
        this.addNewLogbook = !this.addNewLogbook
      }
      }
                    if(!this.addNewLogbook){
      setTimeout(()=>{
        document.body.classList.remove('modal-open')
      },  500)
      }
      },
      showSMSModal(){
        console.log('ShowModalSMS')
        this.showSMSModalToggle = !this.showSMSModalToggle
       
      },
       closeSMSModal(event){
          if(event && event.trigger && event.trigger == 'ok'){
            bus.$emit('updateSMSApproval', true)
          }
          this.$nextTick(function(){
              this.userPanelShow = true
          })
         
       },
      toggleAddNewRepairForm(){
      this.secondaryDivIconsShow = true
        this.addNewRepairForm = !this.addNewRepairForm
              if(!this.addNewRepairForm){
      setTimeout(()=>{
        document.body.classList.remove('modal-open')
      },  500)
      }
      },
      toggleAddNewVehicleCheck(){
      this.secondaryDivIconsShow = true
      this.addNewVehicleCheck = !this.addNewVehicleCheck
      if(!this.addNewVehicleCheck){
      setTimeout(()=>{
        document.body.classList.remove('modal-open')
      },  500)
      }


      },
      toggleAddNewNarcEntryLegacy(){
         this.addNewDailyNarcEntry = !this.addNewDailyNarcEntry
         this.addNewNarcEntry = !this.addNewNarcEntry
      },
      toggleAddNewNarcEntry(){
      this.secondaryDivIconsShow = true
      this.addNewNarcEntry = !this.addNewNarcEntry

      },
      toggleAddNewDailyNarcEntry(data, destroy){
      this.addNewDailyNarcBulk = null
      this.addNewDailyNarcBulkDestroy = null
      this.secondaryDivIconsShow = true
      if(!destroy){
      this.addNewDailyNarcBulk = data
      }
      if(destroy){
      this.addNewDailyNarcBulkDestroy = data
      }
      this.addNewDailyNarcEntry = !this.addNewDailyNarcEntry
      },
      toggleAddNewBlogPost(){
      this.secondaryDivIconsShow = true
        this.AddNewBlogPost = !this.AddNewBlogPost
      },
      addNewNotificationSetup(data){
        this.secondaryDivIconsShow = true
        this.postNotificationObj = data
        this.$nextTick(function(){
          this.AddNewBlogPost = !this.AddNewBlogPost
        })
      },
      toggleDeptInfoPan(){
        bus.$emit('showDeptInfoPan')
      },
      closeEvent() {
      //  console.log('CLOSE EVENT')
        if(
        this.userPanelShow == true ||
        this.stationPanelShow == true ||
        this.battalionPanelShow == true ||
        this.emsqaPanelShow == true ||
        this.secondaryDivIconsShow == false){
        this.dataReadyToSend = false
        this.userPanelShow = false
        this.stationPanelShow = false
        this.battalionPanelShow = false
        this.emsqaPanelShow = false
        this.secondaryDivIconsShow = true
        document.body.className = document.body.className.replace(/\s?modal-open/, '')
        this.$nextTick(function(){
          this.dataReadyToSend = true
        })
      }
     },
    submitNarcEdit(data){
        var id = this.narcsLog.id
        var path = this.narcCraddleToGrave ? 'dailynarctrack' : 'narctrack'
        var holddata = {}
        if(this.narcsLog.narctrackWitnessId == this.userData.id){
         // console.log('WITNESS')
          holddata['narctrackWitnessSignature'] = data
        } else {
         // console.log('AUTHOR')
           if(this.narcsLog.narctrackAuthorId == this.userData.id){
           holddata['narctrackAuthorSignature'] = data
           }
        }
       axios({
        method: 'patch',
        url: '/api/v1/content/' + path + '/' + id,
        data: holddata,
        headers: {
          'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
       bus.$emit('refreshDataShare')
       bus.$emit('refreshUserPosts')
        })
       
      
    },
   },
}
</script>

<style scoped>
img {
  -ms-interpolation-mode: bicubic;
}
.menu-backdrop {
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 399;
    background-color: #000;
    opacity: 0.5;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}

#secondaryMenu {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  top: 0;
  min-height: 50px;
  width: 100%;
  padding-left: 10%;
  z-index: 500;
  min-width: 100%;
}
.background-red {
  background: #e2070d;
}
.background-blue {
  background: #007bff;
}
.background-green {
   background: #28a745;
}
.secondaryDivIcons {
  display: inline-table;
}
.secondary-user-menu-toolbar {
    display:inline-table;
    text-align: center;
    margin: 5px 10px 0px;
    padding: 0px !important;
}
div.plusButton {
  display: none;
}
div.plusButtonEmpty {
  display: none;
}
.secondaryMenuWrapper {
  position: relative;
}
.panelClass {
  text-align: left;
  position: absolute;
  background: white;
  z-index: 400;
  position: fixed;
  overflow: auto;
  overflow-x: hidden;
  border: 1px solid #aaa;
  border-top: 0;
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  max-height: 80%;
  margin-bottom: 10px;
  margin-left: 80px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3);
  padding: 0 10px 10px 10px;
}
#panelDisplayTop {
  display: block;
}
#panelDisplayBottom{
  display: none;
}
.deptInfoButton {
  margin-right: 20px;
  display: inline;
  float: right;
}
.mobileSecondaryAddTool{
  display: none;
}
.desktopSecondaryAddTool{
  display: inline;
}
.mobileOnlyIcon {
  display: none;
}
.notificationWrapperMobile {
  position: absolute;
  margin: -4px 26px;
  font-size: 10px;
}
@media screen and (min-width: 769px) {
  .panelClass {
    top: 50px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .menu-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 50px;
      left: 0;
      z-index: 399;
      background-color: #000;
      opacity: 0.5;
  }
  .deptInfoButton {
    display: none !important;
  }
  #secondaryMenu{
    margin:0 auto 0 !important;
    text-align: center;
      padding-left: 0;
  }
.desktopSecondaryAddTool{
  display: none;
}
.mobileSecondaryAddTool{
  display: block;
}
.mobileOnlyIcon {
  display: inline-table;
}
div.plusButton {
  display: inline-table;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: -22px 0px 0px 5px;

  border-radius: 50%;
  padding: 0px 0px !important;
  z-index: 400;
}
.plusButton img{
  object-fit: cover;
width: 50px;
height: 50px;
border-radius: 50%;
box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 7px 3px rgba(0,0,0,0.23);

}
.easeIn-enter-active, .easeIn-leave-active {
  -webkit-transition:all 0.35s ease-in 0s;
-moz-transition: all 0.35s ease-in 0s;
-o-transition: all 0.35s ease-in 0s;
transition: all 0.35s ease-in 0s;
}

.rotate {
    -moz-transition: all 0.25s linear;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
}
.rotateZero {
  -moz-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -moz-transform:rotate(0deg);
  -webkit-transform:rotate(0deg);
  transform:rotate(0deg);

}

div.plusButtonEmpty{
  width: 40px;
  display: inline-table;
} /*
#secondaryAddTool div:nth-child(1) {
  position: relative;
  margin: 40px 0px 0px -122px;
    transition: all 1s ease;
}
#secondaryAddTool img {
    transition: all 1s ease;
}
#secondaryAddTool div:nth-child(2) {
  position: absolute;
  margin: 10px 0px 0px 4px;

}
#secondaryAddTool div:nth-child(3) {
  position: absolute;
      margin: 11px 0px 0px 53px;

}
#secondaryAddTool div:nth-child(4) {
  position: absolute;
  margin: 39px 0px 0px 88px;

} */
.secondaryMenuWrapper {
  position:fixed !important;
  width:100% !important;
  bottom:0;
  z-index: 500;
}
.secondaryDivIcons {
  display: none !important;
}
#secondaryAddTool{
  background: #888;
  margin-top: 15px;
  padding: 10px 0;
  /*
   margin-top: -126px;
   display: block;
      	padding-bottom: 17px;
   background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.01) 1%, rgba(255,255,255,1) 93%);
   background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,1) 93%);
   background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,1) 93%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
   */

}
#panelDisplayBottom{
  display: none;
}
#panelDisplayTop{
  display: block;

}
.panelClass {
  text-align: left;
  position: absolute;
  background: white;
  z-index: 400;
  bottom: 0;
  margin-bottom: 50px;
  position: fixed;
  overflow: auto;
  overflow-x: hidden;
  border: 1px solid #aaa;
  border-bottom: 0;
  min-width: 400px;
  width: 100%;
  margin-right: 40px;
  max-width: 600px;
  max-height: 90%;
  margin-left: 0;
  color: #000;
  box-shadow: none;
  border-radius: 10px 10px 0 0;

}
@media screen and (max-width: 460px) {
  .panelClass {
    min-width: 300px;

  }
  .feedWrapper {
    min-width: 300px;
    max-width: 400px;
  }
  .panelClass {
    padding: 0 0 10px;
  }
}
}


</style>
