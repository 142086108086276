<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
                    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable">Roster Permissions:</b-navbar-brand>
      </b-nav-form>
    </b-navbar>
    <b-table :items="returnUsersPermissions" :fields="fields">
      <template slot="role" slot-scope="data">
        <span v-if="siteRoles && siteRoles.length > 0" :title="data.item.role">{{ getSiteRoleOveride(data.item.role) }}</span>
        <span v-else>{{ data.item.role }}</span>
      </template>
                <template slot="viewPerms" slot-scope="data">
      <span v-if="!data.item.viewPerms.value">{{ data.item.viewPerms.text }}</span>
      <span v-if="data.item.viewPerms.value">{{ data.item.viewPerms.text.length }} Divs</span>
    </template>
          <template slot="manageUsers" slot-scope="data">
      <span v-if="!data.item.manageUsers.value">{{ data.item.manageUsers.text }}</span>
      <span v-if="data.item.manageUsers.value">{{ data.item.manageUsers.text.length }} Users</span>
    </template>
        <template slot="payrollMods" slot-scope="data">
      {{ checkForBoo(data.item.permData ? data.item.permData.payrollModSelfApprove : false, data.item.id) }}
      </template>
          <template slot="timecard" slot-scope="data">
  {{ checkForBoo(data.item.permData ? data.item.permData.payrollTimecardSelfApprove : false, data.item.id) }}
      </template>
      <template slot="edit" slot-scope="data">
        <b-btn size="sm" :variant="data.item.permData ? 'success' : 'info'" @click="addOrEditUserPermData(data.item)">{{ data.item.permData ? 'Edit' : 'Add' }}</b-btn>
        <b-btn v-if="checkForMultiplePerms(data.item).length > 1" @click="multiUser = data.item, showMultiModel = !showMultiModel" size="sm" variant="danger">Multi</b-btn>
        </template>
    </b-table>


</div>
<b-modal v-model="showModal" size="lg" :title="editUser.name + ' | ' + editUser.department + ' | ' + editUser.division " :hide-footer="true">
  <b-card title="Can View These Divisions:">
    <b-form-checkbox-group :options="availableDepartmentViews" v-model="editObj.viewPerms" />
  </b-card>
  <b-card title="Can Self Approve These Items:" v-if="editUser.role == 'fireops' || editUser.role == 'adminchief'">
    <b-row><b-col>
     <b-form-checkbox v-model="editObj.payrollModSelfApprove">Self Approve Payroll Mods?</b-form-checkbox></b-col><b-col>
    <b-form-checkbox v-model="editObj.payrollTimecardSelfApprove">Self Approve Timecard?</b-form-checkbox></b-col>
    </b-row>
  </b-card>
  <b-card title="Can Manage These Users:" v-if="editUser.role == 'fireops' || editUser.role == 'adminchief'">
    <b-form-select :options="managedUsersAvailableFilters.dept" v-model="filterDepartment" />
    <b-form-select :options="managedUsersAvailableFilters.div"  v-model="filterDivision" />
  <b-table :items="managedUsersAvailableReturned" :fields="fieldsEdit" :per-page="10" :current-page="currentPage">
    <template slot="add" slot-scope="data">
      <b-form-checkbox v-model="data.item.add" />
    </template>
  </b-table>
      <b-pagination
      v-model="currentPage"
      :total-rows="managedUsersAvailableReturned.length"
      :per-page="10"
    ></b-pagination>
  </b-card>
  <b-btn @click="saveData" variant="success" class="mt-2" block>Save</b-btn>
</b-modal>
<b-modal v-model="showMultiModel" size="">
  <b-table :items="checkForMultiplePerms(multiUser)" 
           :fields="['createdAt', 'remove']"
  >
  <template slot="remove" slot-scope="data">
    <b-btn variant="danger" @click="removePermission(data.item.id)">Remove</b-btn>
  </template>
  </b-table>
</b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import rosterprofileView from './rosterprofileView.vue'
import userSelector from '../../inputfields/userfilter.vue'
import _ from 'lodash'
export default {
  components: {
    multiselect,
    rosterprofileView,
    userSelector
  },
  name: 'roster-admin',
    props: ['rosterData', 'rosterProfiles', 'payrollPermsData'],
  data () {
    return {
      fields: [
        'name',
        'role',
        'department',
        'division',
        'viewPerms',
        'manageUsers',
        'payrollMods',
        'timecard',
        'edit'
      ],
      fieldsEdit: [
        'name',
        'department',
        'division',
        {key: 'add', sortable: true}
      ],
      showMultiModel: false,
      multiUser: {},
      currentPage: 1,
      filterDepartment: null,
      filterDivision: null,
      showModal: false,
      editObj: {},
      editUser: {},
      siteRoles: [],
      permissionsData: [],
      managedUsersAvailable: [],
    }
  },
  created(){
  //  console.log('ROSTER PROFILES')
 //   console.log(this.rosterProfiles)
  //  console.log(this.rosterData)
    if(this.siteconfig.overrideSiteRoles){
      this.siteRoles = this.siteconfig.overrideSiteRoles
    }
  },
  mounted(){
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'deptContactData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        availableDepartmentViews(){
          var data = _.cloneDeep(this.rosterData)
          return data.map(p => {
                var obj = {text: p.rosterName, value: p.id}
                return obj
          })
        },
        managedUsersAvailableReturned(){
            var data = this.managedUsersAvailable
            if(this.filterDepartment){
              data = data.filter(p => {
                  return this.filterDepartment.indexOf(p.department) >= 0
              })
            }
            if(this.filterDivision){
              data = data.filter(p => {
                  return this.filterDivision.indexOf(p.division) >= 0
              })
            }
            data = data.filter(p => {
                return p.id != this.editUser.id
            })
            return data
        },
        managedUsersAvailableFilters(){
          var obj = {}
          var dept = [...new Set(this.managedUsersAvailable.map(p => p.department))]
          dept.push({value: null, text: 'All Departments'})
          var div = [...new Set(this.managedUsersAvailable.map(p => p.division))]
          div.push({value: null, text: 'All Divisions'})
          obj['dept'] = _.sortBy(dept)
          obj['div'] = _.sortBy(div)
          return obj
        },
        profilesFilteredByDate(){
          var array = this.rosterProfiles.filter(p => {
            var start = moment(p.rosterAssignmentStart).isBefore(moment())
            var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(moment()) : true
            if(start && end){
              return p
            }
          
          })
            return array
        },
        returnUsersPermissions(){
          var permData = this.payrollPermsData
         // console.log(permData)
          permData = _.orderBy(permData, ['updatedAt'], ['desc'])
          var users = _.cloneDeep(this.deptContactData).map(p => {
                  var userPerms = permData.find(d => d.payrollUserId == p.id)
              //    console.log(userPerms)
                  var obj = {}
                  obj['id'] = p.id
                  obj['name'] = p.lastName + ', ' + p.firstName
                  obj['role'] = p.role
                  obj['department'] = this.checkForRoster(p.id, 'dept')
                  obj['division'] = this.checkForRoster(p.id, 'div')
                  obj['viewPerms'] = this.checkForPerms(p.id, 'viewPerms', userPerms)
                  obj['manageUsers'] = this.checkForPerms(p.id, 'manageUsers', userPerms, p.role)
                  obj['permData'] = userPerms
                  return obj
          })
        //  console.log(users)
          return _.orderBy(users, ['name'], ['asc'])
        }
  
  },
  mounted(){

  },
  watch: {

  },
  methods: {
    checkForMultiplePerms(data){
      var permData = this.payrollPermsData
      var userPerms = permData.filter(d => d.payrollUserId == data.id)
      if(userPerms && userPerms.length > 0){
        return userPerms
      } return []
    },
    checkForBoo(data, userRole){
      if(userRole){
        var user = this.deptContactData.find(p => p.id == userRole)
      } else {
        var user = false
      }
      if(user && user.role && user.role == 'fireops'){
        var role = true
      } else {
        var role = false
      }
      if(role){
       // console.log(data)
        if(data){
            return 'Self Approve'
        } return 'Not Self Approve'
      } return 'Insufficient Role'
    },
    saveData(){
      var array = this.managedUsersAvailable.filter(p => p.add)
      var obj = this.editObj
      obj.manageUsers = array.map(p => p.id)
    //  console.log(this.editUser)
    //  console.log(obj)
      this.updateUserPermissions(this.editUser, obj)
    },
    setGroupUsers(data){
      // console.log(data)
       this.editObj.manageUsers = data
     },
     updateUserAdd(value, id){
    //   console.log(value)
    //   console.log(id)
     },
    addOrEditUserPermData(item){
      var data = item.permData
     // console.log(data)
      this.editUser = item
      if(data){
         this.editObj = {
          viewPerms: data.payrollViewPerms && data.payrollViewPerms.length > 0 ? data.payrollViewPerms : [],
          manageUsers: [],
          payrollModSelfApprove: data.payrollModSelfApprove,
          payrollTimecardSelfApprove: data.payrollTimecardSelfApprove
        }
        this.managedUsersAvailable = _.orderBy(this.deptContactData.map(p => {
                var obj = this.returnUserObjForPerms(p, false)
                if(data.payrollManageUsers && data.payrollManageUsers.length > 0 && data.payrollManageUsers.indexOf(p.id) >= 0){
                  obj.add = true
                }
                return obj
        }), ['name'], ['asc']) 
        this.showModal = true        
      } else {
        this.editObj = {
          viewPerms: [],
          manageUsers: []
        }
        this.managedUsersAvailable = _.orderBy(this.deptContactData.map(p => {
                var obj = this.returnUserObjForPerms(p, false)
                return obj
        }), ['name'], ['asc'])
        this.showModal = true
      }
    },
    returnUserObjForPerms(p, boo){
                var obj = {}
                obj['id'] = p.id
                obj['name'] = p.lastName + ', ' + p.firstName
                obj['department'] = this.checkForRoster(p.id, 'dept')
                obj['division'] = this.checkForRoster(p.id, 'div')
                obj['add'] = boo
                return obj
    },
    checkForPerms(id, type, data, role){
      if(type == 'manageUsers' && role != ('fireops' || 'admin')){
        return {value: false, text: 'Insufficient Role'}
      }
      if(type == 'manageUsers' && role == ('fireops' || 'admin')){
        if(data && data.payrollManageUsers && data.payrollManageUsers.length > 0){
          return {value: true, text: data.payrollManageUsers}
          return 'checkPerms'
        } else {
          return {value: false, text: 'Default  *ALL'}
        }
      }
      if(type == 'viewPerms'){
        if(data && data.payrollViewPerms && data.payrollViewPerms.length > 0){
          return {value: true, text: data.payrollViewPerms}
          return 'checkPerms'
        } else {
          return {value: false, text: 'Default  *ALL'}
        }
      } return 'Function'
    },
    checkForRoster(id, type){
      var roster = this.profilesFilteredByDate.filter(p => p.userId == id)
    //  console.log(roster)
      if(roster && roster.length == 1){
        var uroster = roster[0]
        var rosterData = this.rosterData.find(p => p.id == uroster.rosterId)
        if(rosterData && type){
          if(type == 'dept'){
            var station = this.stationsData.find(p => p.id == rosterData.stationParent)
            if(station && station.stationName){
              return station.stationName
            } return 'Missing Dept'
          }
          if(type == 'div'){
            var station = this.stationsData.find(p => p.id == rosterData.stationId)
            if(station && station.stationName){
              return station.stationName
            } return 'Missing Div'
          }
        } return 'Missing Roster'
      } else if(roster && roster.length > 1){
        return 'Multiple Rosters Active'
      } else {
      return 'No Roster'
      }
    },
    getSiteRoleOveride(data){
      if(data){
        var role = this.siteRoles.find(p => p.value == data)
        if(role && role.text){
          return role.text
        } return data
      }
    },
    updateUserPermissions(user, perms){
    var method = 'post'
    var url = ''
    if(user.permData){
      method = 'patch'
      url = user.permData.id
    }
    var data = {
        payrollUserId: user.id,
        payrollViewPerms: perms.viewPerms && perms.viewPerms.length > 0 ? perms.viewPerms : null,
        payrollManageUsers: perms.manageUsers && perms.manageUsers.length > 0 ? perms.manageUsers : null,
        payrollModSelfApprove: perms.payrollModSelfApprove ? perms.payrollModSelfApprove : null,
        payrollTimecardSelfApprove: perms.payrollTimecardSelfApprove ? perms.payrollTimecardSelfApprove : null
    }
    axios({
     method: method,
     url: '/api/v1/staffing/payrollperms/' + url,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
        bus.$emit('getPayrollPerms')
   })
    },
    removePermission(id){
    axios({
     method: 'delete',
     url: '/api/v1/staffing/payrollperms/' + id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getPayrollPerms')
   })
    },
    newProfileSubmit(data){
    axios({
     method: 'post',
     url: '/api/v1/staffing/rosterprofile/',
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
      this.showModal = false
      this.previousProfile = null
   })

    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
