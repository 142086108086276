<template>
        <div>
          <b-form-input type="number" v-if="formsub.type == 'number'" :state="checkFormState(formsub.required, formsub.value)" v-model="formsub.value" />
          <b-form-input type="date" v-if="formsub.type == 'date'" v-model="formsub.value" />
          <b-row><b-col>
            <b-form-input type="date" v-if="formsub.type == 'dateTime'"  :state="checkFormState(formsub.required, formsub.value.date)"  v-model="formsub.value.date" />
            </b-col><b-col>
            <b-form-input type="time" v-if="formsub.type == 'dateTime'"  :state="checkFormState(formsub.required, formsub.value.date)"   v-model="formsub.value.time" />
          </b-col></b-row>
          <b-form-input v-if="formsub.type == 'text'" :state="checkFormState(formsub.required, formsub.value)"  v-model="formsub.value" :placeholder="formsub.options[0].text" />
          <b-radio-group  v-if="formsub.type == 'radio'" :state="checkFormState(formsub.required, formsub.value)"  v-model="formsub.value" :options="formsub.options" />
          <b-form-checkbox-group v-if="formsub.type == 'checkbox'" :state="checkFormState(formsub.required, formsub.value)" v-model="formsub.value" :options="formsub.options" />
          <b-textarea v-if="formsub.type == 'textarea'" :state="checkFormState(formsub.required, formsub.value)" v-model="formsub.value" :placeholder="formsub.options[0].text" 
                        :rows="formsub.options[0].value" />
          <b-form-select v-if="formsub.type == 'multiSelect'" :state="checkFormState(formsub.required, formsub.value)"  multiple :select-size="4" v-model="formsub.value" :options="formsub.options">
          </b-form-select>
          <b-form-select v-if="formsub.type == 'singleSelect'" :state="checkFormState(formsub.required, formsub.value)"  v-model="formsub.value" :options="formsub.options">
          </b-form-select>
          <multiselect :disabled='formsub.completed' v-if="formsub.type == 'stationsData' || formsub.type == 'apparatusData' || formsub.type == 'deptContactData'" :class="{errorClass: checkFormStateAdvanced(formsub.required, formsub.value)}" v-model="formsub.value" :options="getDataFormReturn(formsub.type, formsub.filterOpt)" :multiple="formsub.options[0].multiselect" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please Make a Selection" :label="getLabel(formsub.type)" :track-by="getLabel(formsub.type)">
                  <template v-if="formsub.type == 'stationsData'" slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
                 <template v-if="formsub.type == 'apparatusData'" slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.apparatusName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
                <template  v-if="formsub.type == 'deptContactData'" slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
          </multiselect>
          <b-form-input disabled v-show="formsub.visibility" v-if="formsub.type == 'ref'" v-model="formsub.value" :placeholder="getRefValue(formsub.options)" />
            <b-btn v-if="formsub.type == 'signature'" :disabled="checkForUserData(formsub)" v-model="formsub.value" @click="userSign = !userSign" variant="primary">Add Signature</b-btn>
            <div v-if="formsub.type == 'signature'">
            <div v-if="previewImageFileData != ''"><img type="image" :src="previewImageFileData.image" /></div>
            <div v-if="previewImageFileData != ''"><span>Signed By {{ previewImageFileData.track }}</span></div>
            </div> 
            <signature v-if="userSign" :showModalSignature="userSign" :name="userData.lastName" @signatureCapture="signatureMethodUser($event)" @closeModalCancel="signatureClose"></signature>            
  </div>
</template>

<script>

import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import signature from "../../../inputfields/signaturepad.vue"
import moment from 'moment'
export default {
props: ['formsubdata', 'formcompData'],
components: {
        'multiselect' : Multiselect,
        'signature': signature,
},
data() {
    return {

      showPostDataArray: false,
      showComponentArray: false,
      resetComponent: false,
      formsub: '',
      userSign: false,
      previewImageFileData: '',
      signatureIds: [],
      
}
},
created () {
  this.formsub = this.formsubdata
},
beforeDestroy(){
  
},
watch: {
  'formsub.value': function(newVal, oldVal){
    if(oldVal != undefined && newVal != oldVal){
      var id = this.formsub.id
      var completed = this.formsub.completed
      if(completed != true){
      var obj = { id: id, value: newVal }
      this.$emit('updateValue', obj)
      }
    }
  },
},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData',
    'stationsData',
    'apparatusData',
    'deptContactData'
  ]),

},
mounted(){
  

},
methods: {
  checkForUserData(data){
    var id = this.formcompData[0].value.id
    if(id != undefined){
      if(id == this.userData.id){
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  },
 signatureClose() {
      this.userSign = false;
    },
  signatureMethodUser(data) {
      this.signatureClose();
      this.processFileUriForUpload(data);
    },
  processFileUriForUpload(file) {
      var dataSplice = file;
      this.previewImageFileData = {}
      var obj = {}
      obj['image'] = file
      obj['track'] = this.userData.firstName + ' ' + this.userData.lastName + ' @ ' + moment().format('MM/DD/YYYY HH:mm')
      this.previewImageFileData = obj;
      var newplacedData = dataSplice.split(",");
      this.formsub.value = { image: newplacedData[1], track: obj.track }
    },
  checkForDisabled(data){
    var boo = data.defaultValueLoggedUser
    var id = this.formcompData[0].value.id
    var saved = data.completed
    if(boo == true){
          if(data.value == '' || data.value == this.userData.id){
         data.value = this.userData}
        return true
      } else if (id != undefined){
      
        var user = this.deptContactData.filter(p => {
          return p.id == id
        })
        if(user.length > 0){
        data.value = user[0]
        }
        return true 
        } else if (saved != undefined && saved == true) {
          var users = data.value
          var hold = []
          for(var i = 0; i < users.length; i++){
          var user = this.deptContactData.filter(p => {
          return p.id == data.value
        })
        if(user.length > 0){
        hold.push(user[0])
        }
          }
          data.value = hold
        return true 
        }
        else {
        return false
    } 
  },
  checkFormState(req, value){
    if(req == true){
      if(value != ''){
        return true
      } else {
        return false
      }
    } else {
      return null
    }
  },
  checkFormStateAdvanced(req, value){
    if(req == true){
      if(value != null){
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  },
  visibilitySettingsCheck(array, data){
    var operators = {
    '==': function(a, b) { return a == b },
    '!=': function(a, b) { return a != b },
    'notBlank': function(a, b) { return a != ''},
    'blank': function(a, b) { return a == ''},
    'contains': function(a, b){
                      var item = b.filter( p => {
                        return p == a
                      })
                      if(item.length > 0){
                        return true
                      } else {
                        return false
                      }
                      }
    }  
    var booArray = []
    if(data != undefined && data.length > 0){
      for(var i = 0; i < data.length; i++){
        var item = array.filter(p => {
          return p.id == data[i].item
        })       
       if(item[0] != undefined && item[0] != null){
          var boo = operators[data[i].modifier](item[0].value, data[i].value)
          booArray.push(boo)
        }
       }
    var index = booArray.indexOf(false)
    if(index < 0){
      return true
    } else {
      return false
    }
      
    } else {
    return true
    }
  

  },
  getRefValue(ref){
    var item = this.formcompData
    var data = item.filter(p => {
      return p.id == ref.itemId
    })
    if(data[0].value != undefined && data[0].value != null){
      var datai = data[0].value
      return datai[ref.refItem]
    }
  },
  getDataFormReturn(data, filter){
    var array = this[data]
    if(filter != undefined && filter != null && filter.length > 0){
      for(var i = 0; i < filter.length; i++){
        if(filter[i].multiple == true){
            var array = array.filter( p => {
            var arrayTest = p[filter[i].filterKey]
            if(Array.isArray(arrayTest)){
              var userArray = arrayTest
              let found = userArray.some(r=> filter[i].filterValue.indexOf(r) >= 0)
              if(found){
                return p
              }
            } else {
              var indexR = filter[i].filterValue.indexOf(p[filter[i].filterKey])
              if(indexR >= 0){
                return p
              }
            }
          })

        } else {
          var array = array.filter( p => {
            var arrayTest = p[filter[i].filterKey]
            if(Array.isArray(arrayTest)){
              var indexOfArr = arrayTest.indexOf(filter[i].filterValue)
              if(indexOfArr >= 0){
                return p
              }
            } else {
            return p[filter[i].filterKey] == filter[i].filterValue
            }
          })

         }
       } return array    
    } else {
    return array
    }
  },
  getLabel(data){
    if(data == 'stationsData'){
      return 'stationName'
    } else if ( data == 'apparatusData') {
      return 'apparatusName'
    } else {
      return 'lastName'
    }
  },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.errorClass {
  border: 1px solid red;
  border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
