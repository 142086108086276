<template>
<div>
      <b-row v-for="(rp, rindex) in spotsReturned" :key="rindex" 
      :class="{borderBottomStacked: rindex < spotsReturned.length - 1 && rp.type != 'Vacancy' && rp.required == 'required', 'vacancy-danger': rp.type == 'Vacancy' && rp.required == 'required'}" class="my-1" no-gutters>
                <template v-if="tvView">
                  <div class="ml-4 tvViewFont">
                   <span v-if="rindex == 0 || rp.position != spotsReturned[rindex -1].position" class="font-weight-bold">{{ rp.position }})</span>
                   <span v-else>&nbsp;&nbsp;&nbsp;</span>
                   <span v-if="rp.spotData">
                          {{ getUserData(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId) }} | {{ getTime(rp.startTime) }} - {{ getTime(rp.endTime, true, rp.startTime) }}
                    </span>
                  </div>
                </template>
                <template v-if="!tvView && !(rp.type == 'Vacancy' && rp.flag == 'hidden')">
                 <b-col cols="1" md="1"><span v-if="rindex == 0 || rp.position != spotsReturned[rindex -1].position">{{ rp.position }}</span>
                 </b-col>
                 <b-col cols="10" md="10" lg="5"  xl="4" align="left">
                   <span v-if="rp.spotData"  class="d-none d-sm-block pointer" v-b-tooltip.hover :title="getUserDataAndNote(rp.spotData)" @click="goToCalendar(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId)"><span v-if="rp.spotData && rp.spotData.payrollModNote" class="text-info" :title="rp.spotData.payrollModNote">&#128457;</span>{{ getUserData(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId) }}</span>
                   <span v-if="rp.spotData" class="d-sm-none pointer" v-b-tooltip.hover :title="getUserDataAndNote(rp.spotData)" @click="goToCalendar(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId)">
                   <span v-if="rp.spotData && rp.spotData.payrollModNote" class="text-info">&#128457;</span>{{ getUserData(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId).substring(0, 23) }}{{ getUserData(rp.spotData.userId ? rp.spotData.userId : rp.spotData.payrollModTargetUserId).length > 23 ? '...' : '' }}</span>
                   <span v-else-if="rp.rosterId && false">{{ rp.rosterId }}</span>
                  <b-btn 
                  v-if="rp.payrollCode == undefined && !vacancyFilter && (rp.type == 'Vacancy' ||  rp.status == 'Vacancy') && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user'))" 
                  variant="link" 
                  class="m-0 p-0 text-danger" 
                  @click="addPayrollMod(rp)">Add Payroll Mod <em v-if="rp.cert" class="font-weight-bold">| Missing {{ rp.cert }}</em></b-btn>
                  <b-btn v-if="false && rp.payrollCode == undefined && vacancyFilter && (rp.type == 'Vacancy' ||  rp.status == 'Vacancy') && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user'))" 
                  variant="link" 
                  class="m-0 p-0 text-danger" 
                  @click="addPayrollModVacancy(rp)">Move Here<em v-if="rp.cert" class="font-weight-bold">| Missing {{ rp.cert }}</em></b-btn>
                  <b-btn v-if="vacancyFilter && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user'))" 
                  variant="link" 
                  class="m-0 p-0 text-danger" 
                  @click="addPayrollModVacancy(rp)">Move Here<em v-if="rp.cert" class="font-weight-bold">| Missing {{ rp.cert }}</em></b-btn>
                 </b-col>
                 <b-col cols="6" md="6" lg="3" xl="2" class="mx-0"  v-if="!tvView">
                  <span  v-if="rp.payrollCode == undefined">{{ rp.status }} <em v-if="rp.status != 'Vacancy'">{{ rp.type }}</em></span>
                  <span class="pointer"  v-if="rp.payrollCode" :title="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') ? 'Click to Edit' : 'Unauthorized to Edit'" :class="'payroll-' + getCodeObj(rp.payrollCode).payrollCodeColor" @click="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') ? updatePayrollMod(rp) : ''">
                    {{ getCodeObj(rp.payrollCode).payrollCode }} {{ getUserDataShort(rp.status) }} ({{ getTimeDiff(rp)}})</span><span v-if="checkForUserSwap(rp)" :title="checkForUserSwap(rp)" class="payroll-orange">+</span>
                  <span v-if="checkIfAvailForFill(rp.spotData)" class="payroll-black pointer" @click="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') ? fillVacancyWithUser(rp) : ''">&#8594;</span>
                  </b-col>
                    <b-col cols="6" md="6" lg="3" xl="2" class="mx-0" v-if="!tvView">
                    <span class="pointer"  
                          v-if="rp.addative" 
                          :title="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') ? 'Click to Edit' : 'Unauthorized to Edit'" 
                          :class="'payroll-' + getCodeObj(rp.addative.payrollModCode).payrollCodeColor" @click="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user') ? updatePayrollModAdd(rp.addative) : ''">
                    {{ getCodeObj(rp.addative.payrollModCode).payrollCode }} {{ getUserDataShort(rp.addative.status) }} ({{ rp.addative.payrollModHours}})</span>
                      <span v-if="!rp.addative && !printOn"><b-btn class="ml-1 px-1 py-0 mt-0 align-top" size="sm" variant="success" @click="updatePayrollMod(rp, 'add')">+$</b-btn></span>
                    </b-col>
                 <b-col cols="6" md="2" xl="1" lg="2" align="right" class="pr-3">{{ getTime(rp.startTime) }}</b-col>
                 <b-col cols="6"  md="2" xl="1" lg="2" v-if="userData.lastName == 'Wilbert2' || userData.role == 'admin' || false"><b-btn size="sm" variant="link" @click="consoleLogObj(rp)">{{ getTime(rp.endTime, true, rp.startTime) }}</b-btn></b-col>
                 <b-col cols="6" md="2" xl="1" lg="2"  align="left" class="pl-3" v-else>{{ getTime(rp.endTime, true, rp.startTime) }}</b-col>
                </template>
      </b-row>
  
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
export default {
components: {
  'multiselect' : Multiselect,
},
props: ['spot', 'profiles', 'mods', 'payrollCodesData', 'filterArray', 'selectedDate', 'payaddativemods', 'onDutyFilter', 'printOn', 'profilesPreDateFiltered', 'userSelected', 'kellyDayObject', 'payrollModDailyRosterData', 'tvView', 'vacancyFilter', 'vacancyFilterUser'],
data() {
    return {
    showHiddenPaymods: false,
    offRosterMods: [
        'bank modification',
        'exchange',
        'overtime availability',      
        'none'
    ],
    removeRoster: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'upaid leave',
    ],
    nonSpotModification: [
       'payaddative',
    ],
    spotModification: [
      'scheduled leave',
      'unscheduled leave',
      'modified leave',
      'unpaid leave',
      'roster mod',
      'overtime',
    ],
    emittedData: [],
  }
},
created(){
  bus.$on('tagHiddenPayMods', this.tagHiddenPayModsToggle)
 
}, 
updated () { 
  if(this.spot.apparatusName == 'Rescue 91' && false){
  console.log(this.spot.apparatusName)
  console.log(this.spot)
  console.table(this.mods)
  }

},
beforeDestroy(){
  	
},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
    'stationsData',
    'apparatusData'
  ]),
  rosterCodesForOffRoster(){
    var array = this.payrollCodesData.filter(p => {
      return p.payrollCode == 'EXOFF' || this.removeRoster.indexOf(p.payrollCodeCategory) != -1
    })
    return array.map(p => p.id)
  },
  profilesAdjusted(){
    var profiles = this.profiles
    return profiles
  },
  spotsReturned(){
    var spots = this.spot.rosterPositions
    var date = _.cloneDeep(this.selectedDate)
   // console.log(spots)
    var returnSpots = []
    var rosterUsers = _.cloneDeep(this.profiles)
    var positionHold = rosterUsers.filter(p => {
      return p.rosterId == this.spot.id
    })
    for(var s in spots){
      var cPH = _.cloneDeep(positionHold)
      var users = cPH.filter(p => {
        if(p.rosterPosition == spots[s].position){
          positionHold.splice(positionHold.map(p => p.id).indexOf(p.id), 1)
          return p
        }
     
      })
      var startTime = moment(date + 'T' + spots[s].start, 'YYYY-MM-DDTHH:mm:ss').toISOString()
      var spotObj = {
                rosterId: this.spot.id,
                position: spots[s].position, 
                type: 'Vacancy', 
                flag: spots[s].type,
                required: spots[s].type,
                cert: spots[s].cert ? spots[s].cert : false,
                status: 'Vacancy', 
                userId: null, 
                startTime: startTime,
                endTime: moment(startTime).add(spots[s].duration, 'hours').toISOString()
                }
     // Will need to do the vacancy loop on this //
         var holdObj = _.cloneDeep(spotObj)
         var array = this.figureOutSpots(spotObj)
         for(var a in array){
          returnSpots.push(array[a])
        }
        var mods = this.mods.filter(p => {
          return p.rosterId == holdObj.rosterId && p.rosterPosition == holdObj.position && p.vacancyFill == false
        })
        if(mods.length > 0){
            for(var m in mods){
              var obj = this.getModObjects(mods[m], holdObj)
              returnSpots.push(obj)
            }
        }
    }
    returnSpots =  _.orderBy(returnSpots, ['position', 'startTime'], ['asc', 'asc'])
    var additional = this.mods.filter(p => {
      return p.rosterId == this.spot.id && (!p.rosterPosition || spots.map(p => p.position).indexOf(p.rosterPosition) == -1)
    })
    if(additional){
      additional = _.orderBy(additional, ['payrollModDatetime'], ['asc'])
      var Hold = []
      var start = 1
      var spotObj = {
                rosterId: this.spot.id,
                position: '+' + 1, 
                type: 'Vacancy', 
                flag: 'Additional',
                required: 'Additional',
                status: 'Vacancy', 
                userId: null, 
                }
      for(var i in additional){
        var obj = this.getModObjects(additional[i], spotObj)
        Hold.push(obj)
        start = start + 1
      }
      for(var h in Hold){
        returnSpots.push(Hold[h])
      }
    }
    if(this.payaddativemods){
    var addative = returnSpots.map(item => {
        var obj = _.cloneDeep(item)
        obj['addative'] = this.checkForPayAddative(item)
        if(obj.addative){
        }
        return obj

    })
    } else {
      var addative = returnSpots
    }
    //Add Kelly Re-Entry Here
   // console.log(addative)
    if(this.kellyDayObject){
        var kellyProfiles = _.cloneDeep(this.profilesPreDateFiltered)
        var newkellyProfiles = kellyProfiles.filter(p => {
                return p.scheduledKellyCycleNumber == this.kellyDayObject.kelly && p.scheduledShift == this.kellyDayObject.shift
        })
      if(newkellyProfiles && newkellyProfiles.length > 0){
         var kellySpots = []
         for(var s in returnSpots){
           newkellyProfiles.filter(p => {
              if(p.rosterId == returnSpots[s].rosterId && p.rosterPosition && returnSpots[s].position){
                var index = kellySpots.map(p => p.id).indexOf(p.id)
                if(index == -1){
                kellySpots.push(p)
                }
              }
           })
         
        // console.log((kellySpots))
      }
        if(kellySpots.length > 0){
          // ADD BACK KELLY DAY LOGIC
          var kellyCode = this.payrollCodesData.find(p => p.payrollCode == 'KD')
            var spotObj = {
                      type: 'Vacancy', 
                      addative: false,
                      status: 'Vacancy', 
                      userId: null, 
                      }
            for(var i in kellySpots){
              var start =  moment(moment().format('YYYY-MM-DD') + 'T' + kellySpots[i].rosterStartTime).toISOString()
              var kObj = kellySpots[i]
                  spotObj['position'] = kellySpots[i].rosterPosition
                  kObj['rosterId'] = kellySpots[i].rosterId
                  kObj['payrollCode'] = kellyCode.id,
                  kObj['startTime'] = start
                  kObj['endTime'] = moment(start).add(kellySpots[i].rosterShiftLength, 'hours').toISOString()
              var obj = this.getModObjects(kObj, spotObj)
              addative.push(obj)
            //  start = start + 1
          }
          addative =  _.orderBy(addative, ['position', 'startTime'], ['asc', 'asc'])
        } else kellySpots = addative
      } else {
        var kellySpots = addative
      }
      var kellySpots = addative
    } else {
      var kellySpots = addative
    }
    if(this.userSelected){
      var vacantFilter = kellySpots.filter(p => {
        return p.type != 'Vacancy'
      })
      var user = vacantFilter.filter(p => {
        return p.userId == this.userSelected.id
      })
    } else {
      var user = addative
    }
    if(this.onDutyFilter){
      var arrayon = user.filter(p => {
        return p.type != 'Vacancy'
      })
      var arrayoff = arrayon.filter(p => {
        if(p.payrollCode){
          return this.rosterCodesForOffRoster.indexOf(p.payrollCode) == -1
        } return p
      })
    } else {
      var arrayoff = user
    }
    if(this.filterArray && this.filterArray.filterModType && this.filterArray.filterModType.length > 0){
      var arraymodType =  arrayoff.filter(p => {
                        var index = this.filterArray.filterModType.indexOf(p.payrollModType)
                        if(index != -1){
                          return p
                        }
                })
        
    } else {
      var arraymodType = arrayoff
    }
    if(this.filterArray && this.filterArray.filterByRank && this.filterArray.filterByRank.length > 0){
      var arrayRank = arraymodType.filter(p => {
                      if(p.user && p.user.deptRank){
                        return this.filterArray.filterByRank.indexOf(p.user.deptRank) >= 0
                      }
                })
        
    } else {
      var arrayRank = arraymodType
    }
    if(this.filterArray && this.filterArray.filterBySpecial && this.filterArray.filterBySpecial.length > 0){
      var arraySpecial = arrayRank.filter(p => {
                      if(p.user){
                        var specials = p.user.deptSpecialties
                        if(specials){
                        for(var s in specials){
                            var index = this.filterArray.filterBySpecial.indexOf(specials[s])
                            if(index != -1){
                              return p
                            }
                          }
                        }
                      }
                })
        
    } else {
      var arraySpecial = arrayRank
    }
    if(this.vacancyFilter){
   //   console.log('VACANCY FILTER')
   //   console.log(arrayoff)
      var vacancyFilterData = arraySpecial.filter(p => {
        return  p //p.type == 'Vacancy' //&& p.required == "required"
      })
      if(this.vacancyFilterUser && this.vacancyFilterUser.user && false){
       // console.log('VACANCY USER')
     //  console.log(vacancyFilterData)
      // console.log(this.vacancyFilterUser)
        vacancyFilterData = vacancyFilterData.filter(p => moment(this.vacancyFilterUser.user.startTime).isSameOrBefore(p.startTime) && moment(this.vacancyFilterUser.user.endTime).isSameOrAfter(p.endTime))
      }
    } else {
      var vacancyFilterData = arraySpecial
    }
   //  console.log(vacancyFilterData)
    return vacancyFilterData
      
  },
},
mounted(){

},
watcher:{

},
methods: {
  consoleLogObj(data){
    console.log(data)
  },
  tagHiddenPayModsToggle(){
    this.showHiddenPaymods = !this.showHiddenPaymods
  },
  goToCalendar(id){
    //console.log(id)
    bus.$emit('goToCalendar', id)
  },
  fillVacancyWithUser(data){

    bus.$emit('vacancyFilterUpdate', data)
  },
  checkForUserSwap(obj){
    if(obj.spotData && obj.spotData.userId && obj.spotData.payrollModData && obj.spotData.payrollModData.payrollModType){
      if(obj.spotData.payrollModData.payrollModType != 'exchange'){
        var exOn = this.payrollModDailyRosterData.find(p => p.payrollModExchangeUserId == obj.spotData.userId)
        if(exOn){
          return 'ExOn for ' + this.getUserDataShort(exOn.payrollModTargetUserId)
        } return null
      } return null
    } return null
  },
  getCodeObj(id){
      var obj = this.payrollCodesData.find(p => p.id == id)
      if(obj){
        return obj 
      } else {
        return {payrollCodeColor: 'default', payrollCode: 'None'}
      }
    },
    checkForPayAddative(spot){
      if(spot && spot.spotData){
      var payAdd = this.payaddativemods.find(p => p.payrollModRosterId == spot.rosterId && p.payrollModTargetUserId == spot.spotData.userId && p.payrollModDatetime == spot.startTime)
      if(payAdd){
        if((this.userData.role == 'admin' || this.userData.role == 'fireops' || this.userData.role == 'adminchief' || (this.siteconfig.payroll.staffingFireOpsNonUser && this.userData.role != 'user')) && this.showHiddenPaymods){
          bus.$emit('payAddativeTracking', payAdd.id)
         }
          return payAdd
        }  else {
      return false
      }
      } else {
        return false
      }
    },
    getFixedVacancies(array, mods){
       function doTheLoop() {
         for(var a in array){
          var mod = mods.filter(p => ( moment(p.startTime).isSameOrAfter(array[a].startTime) && moment(p.startTime).isBefore(array[a].endTime) ) 
                                    || ( moment(p.endTime).isSameOrBefore(array[a].endTime) && moment(p.endTime).isAfter(array[a].startTime) ))
            if(mod){
              for(var m in mod){
                // first == first && last == last
                if(moment(mod[m].startTime).isSame(array[a].startTime) && moment(mod[m].endTime).isSame(array[a].endTime)){
                  array.splice(a, 1)
                  return(true);
                }
                // first < first && last < last
                else if(moment(mod[m].startTime).isAfter(array[a].startTime) && moment(mod[m].endTime).isBefore(array[a].endTime)){
                  var obj = _.cloneDeep(array[a])
                  var objTwo = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  objTwo.startTime = mod[m].endTime
                  objTwo.endTime = array[a].endTime
                  array.push(objTwo)
                  array[a] = obj
                }
                // first < first && last == last
                else if(moment(mod[m].startTime).isAfter(array[a].startTime)){

                  var obj = _.cloneDeep(array[a])
                  obj.startTime = array[a].startTime
                  obj.endTime = mod[m].startTime
                  if(moment(obj.startTime).isBefore(obj.endTime) ){
                  array[a] = obj
                    } else {
                      array.splice(a, 1)
                      return(true);
                    }
                }
                // first == first && last < last
                else if(moment(mod[m].startTime).isSameOrBefore(array[a].startTime)){
                  var obj = _.cloneDeep(array[a])
                  obj.startTime = mod[m].endTime
                  obj.endTime = array[a].endTime
                  if(moment(obj.startTime).isBefore(obj.endTime) ){
                    array[a] = obj 
                    } else {
                    array.splice(a, 1)
                    return(true);
                  }
                }


                return(true);
              }
             
         } 
        }
        return(false);   
      }
     while (doTheLoop()) {}
     return array
    },
  figureOutSpots(base){
    var profiles = []
    var users = this.profilesAdjusted.filter(p => {
      return p.rosterId == base.rosterId && p.rosterPosition == base.position
    })
    var mods = this.mods.filter(p => {
      return p.rosterId == base.rosterId && p.rosterPosition == base.position && p.vacancyFill == true
    })
    if(mods.length > 0){
      for(var m in mods){
        users.push(mods[m])
      }
    }
    var array = [base]
    if(users.length > 0){
       var fixedVacanciesByProfile = this.getFixedVacancies(array, users)
        for(var f in fixedVacanciesByProfile){
          profiles.push(fixedVacanciesByProfile[f])
        }
        for(var u in users){
          var obj = this.getModObjects(users[u], base)
          profiles.push(obj)
        }    
        return profiles

    } else {
   return [base]
    }
  },
  getModObjects(item, base){
    var usercheck = item.vacancyMod ? null : item.userId ? item.userId : item.payrollModTargetUserId ? item.payrollModTargetUserId : ''
    if(usercheck){
      var obj = this.deptContactData.find(p => p.id == usercheck)
      if(obj){
        var user = obj
      } else {
        var user = null
      }
    }
    var payrollModType = item.payrollSwap ? item.payrollSwap : item.payrollCode ? item.payrollCode : item.payrollModCode ? item.payrollModCode : undefined
    if(payrollModType){
      var payrollCode = this.payrollCodesData.find(p => p.id == payrollModType)
      if(payrollCode){
        var payrollModType = payrollCode.payrollCodeCategory
      } else {
        var payrollModType = null
      }
    } else {
      var payrollModType = null
    }
    var spotObj = {
                position: base.position, 
                type: item.vacancyMod ? 'Vacancy' : '',
                cert: item.cert ? item.cert : null,
                payrollCode: item.payrollSwap ? item.payrollSwap : item.payrollCode ? item.payrollCode : item.payrollModCode ? item.payrollModCode : undefined,
                payrollModType: payrollModType,
                flag: item.rosterSpotType ? item.rosterSpotType : base.flag, 
                required: base.required,
                rosterId: item.rosterId ? item.rosterId : base.rosterId,
                status: item.payrollSwap ? base.userId : null, 
                userId: item.vacancyMod ? null : item.userId ? item.userId : item.payrollModTargetUserId ? item.payrollModTargetUserId : '',
                user: user,
                spotData: item.vacancyMod ? null : item,
                startTime: item.startTime,
                endTime: item.endTime,
                }
    return spotObj
  },
  checkForUserMods(users){
    var profiles = []
    for(var u in users){
     var array = this.mods.filter(p => p.payrollModTargetUserId == users[u].userId)
      if(array.length > 0){
      //  console.log('Mod')
      //  console.log(array)
        profiles.push(users[u])
    } else {
      profiles.push(users[u])
    }
    }
    return profiles
  },
  getTime(time, boo, start){
  //  console.log(time)
    if(time && boo != true){
      if(this.tvView){
        return moment(time).format('HH:mm')
      }
      return moment(time).format('HH:mm:ss')
    } else if (time && boo && start){
      var day = time
      var start = moment(start).isDST()
      var today = moment(day).isDST()
      var yesterday = moment(day).subtract(1, 'd').isDST()
      var tomorrow = moment(day).add(1, 'd').isDST()
      if((yesterday != today || today != tomorrow) && start != today ){
        if(yesterday == false && today == true){
           if(this.tvView){
              return  moment(time).subtract(1, 'hour').format('HH:mm')
            }
          return moment(time).subtract(1, 'hour').format('HH:mm:ss')
        } else {
             if(this.tvView){
              return  moment(time).add(1, 'hour').format('HH:mm')
            }
          return moment(time).add(1, 'hour').format('HH:mm:ss')
        }
      }
      if(this.tvView){
         return moment(time).format('HH:mm')
       }
      return moment(time).format('HH:mm:ss')
    }
  },
  getTimeDiff(data){
    return moment(data.endTime).diff(moment(data.startTime), 'hours', true)
  },
  checkIfAvailForFill(data){
   // console.log(data)
    var exoffCode = this.payrollCodesData.find(p => p.payrollCode == 'EXOFF')
   // console.log(exonCode)
    if(data && data.userId && !data.payrollModData && !data.payrollCode){
      return true
    }
    if(exoffCode && data && exoffCode.id == data.payrollCode && data.targetUser != data.userId && data.rosterAssignmentStart){
      return true
    }
    return false
  },
  addPayrollModVacancy(data){
    // var scrollTop = window.pageYOffset
     if(this.vacancyFilterUser && this.vacancyFilterUser.user && this.vacancyFilterUser.roster){
       var user = this.vacancyFilterUser.user
       var roster = this.vacancyFilterUser.roster
       var userStation = roster.find(p => p.id == user.rosterId)
       var vacancyStation = roster.find(p => p.id == data.rosterId)
        if(userStation && vacancyStation && userStation.stationId == vacancyStation.stationId){
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Move Up')
        } else {
          var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Move Out')
        }
        if(payrollCode){
       //     console.log(payrollCode)
       //     console.log(user)
       //     console.log(data)
            var startTime = moment(user.startTime).isSameOrAfter(data.startTime) ? moment(user.startTime) : moment(data.startTime).isAfter(user.startTime) ? moment(data.startTime) : moment(user.startTime)
            var endTime = moment(user.endTime).isSameOrBefore(data.endTime) ? moment(user.endTime) : moment(data.endTime).isSameOrBefore(user.endTime) ? moment(data.endTime) : moment(user.endTime)
            var dataObj = {   payrollModType: payrollCode.payrollCodeCategory,
                              payrollModCode: payrollCode.id, 
                              payrollModRosterId: data.rosterId, 
                              payrollModTargetUserId: user.userId, 
                              payrollModExchangeUserId: '',
                              payrollModExchangeApproved: null, 
                              payrollModDatetime: startTime, 
                              payrollModHours: moment(endTime).diff(startTime, 'hours', true), 
                              payrollModAuthorId: this.userData.id, 
                              payrollModApprovalUserId: this.userData.id,
                              payrollModRosterPosition: data.position,
                              payrollModApproved: true,
                              payrollModNote: '' }
      //    console.log('DATA OBJ')
         // console.log(vacancyStation)
          if(payrollCode.payrollCodeNotification){
              var userEmail = this.deptContactData.find(p => p.id == user.userId)
                dataObj.message =  userEmail.email
                dataObj.emailContact = true
                var string = ''
                dataObj.payrollModType ? string = string + dataObj.payrollModType.toUpperCase() + '| ' : ''
                dataObj.payrollModCode ? string = string + this.payrollCodesData.find(p => p.id == dataObj.payrollModCode).payrollCodeName + ': ' : ''
                dataObj.payrollModRosterId && (dataObj.payrollModType == 'roster mod' || dataObj.payrollModType == 'overtime' || dataObj.payrollModType == 'payaddative') ? string = string + vacancyStation.apparatusName + ' ' : this.offRosterFlag ? 'offroster' : ''
                dataObj.payrollModRosterPosition && (dataObj.payrollModType == 'roster mod' || dataObj.payrollModType == 'overtime' || dataObj.payrollModType == 'payaddative') ? string = string + 'Pos:' + dataObj.payrollModRosterPosition + ' Start: ' : ''
                string = string + ' at Date/Time ' + moment(dataObj.payrollModDatetime).format('MM/DD/YY HH:mm') + ' For: ' + Number.parseFloat(dataObj.payrollModHours) + 'Hrs'
                dataObj.payrollModApproved == true ? string = string + ' APPROVED by ' + this.userData.firstName + ' ' + this.userData.lastName : dataObj.payrollModApproved == false ? string = string + ' DENIED by ' + this.userData.firstName + ' '  + this.userData.lastName + ' Note: ' + dataObj.payrollModNote + '' : ''
                dataObj.messageBody = string
          }
            var method = 'post'
            var url = '/api/v1/staffing/payrollmod/'
          axios({
                method: method,
                url: url,
                data: dataObj,
                headers: {
                  'Authorization': this.getSessionData,
                  'Content-Type' : 'application/json',
                }
              }).then( response => {
                bus.$emit('vacancyFilterUpdate', false)
                bus.$emit('newPayrollModification')
                //  console.log(response.data)
              })
         // console.log(dataObj)
        } else {
            console.log('ERROR')
            console.log(payrollCode)
            console.log(user)
            console.log(data)
        }

     } else {
     bus.$emit('vacancyFilterUpdate', false)
     }
     this.$nextTick(function(){
    //  document.body.scrollTop = scrollTop
     })
     console.log(dataObj)

  },
  addPayrollMod(data, type){
     var dataObj = {  payrollModType: '',
                      payrollModCode: '', 
                      payrollModRosterId: data.rosterId, 
                      payrollModTargetUserId: '', 
                      payrollModExchangeUserId: '',
                      payrollModExchangeApproved: null, 
                      payrollModDatetime: moment(data.startTime), 
                      payrollModHours: moment(data.endTime).diff(data.startTime, 'hours', true), 
                      payrollModAuthorId: '', 
                      payrollModApprovalUserId: '',
                      payrollModRosterPosition: data.position,
                      payrollModApproved: null,
                      payrollModNote: '' }
      bus.$emit('updatePayrollMod', dataObj)
  },
  getUserDataShort(id){
   // console.log(id)
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.lastName
        } else {
          return 'Unknown'
        }
      } else {
        return ''
      }
    },
    getUserDataAndNote(data){
      var note = data.payrollModNote
      var userId = data.userId ? data.userId : data.payrollModTargetUserId
      if(userId){
        var user = this.deptContactData.find(p => p.id == userId)
        var partOne = user && user.employeeId ? 'Employee Id: ' + user.employeeId : 'No ID Found'
      }
      if(note){
        partOne = partOne + ' | RosterNote: ' + note
      }
      return partOne
    },
    getUserData(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          var first = user.firstName + ' '
          var short = user.lastName + ' (' + user.deptRank + ')'
          if(this.tvView){
            var first = first.charAt(0) + '. '
            return first + short
          }
          return first + short + ' ' + user.deptSpecialties
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getUserNameOnly(id){
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
        getPayrollCode(code){
      var obj = this.payrollCodesData.find(p => p.payrollCode == code)
      if(obj){
        return obj.id
      } else {
        return 'Code Not Found'
      }
    },
    updatePayrollModAdd(data){
      var dataobj = data
      dataobj['rosterUpdateData'] = true
      bus.$emit('updatePayrollMod', dataobj)
    },

    updatePayrollMod(data, type){
      console.log(data)
      console.log(type)
      if((!data.spotData.payrollModData || type) && data.spotData){

          var dataobj = {       payrollModType: type && type == 'add' ? 'payaddative' : data.spotData.payrollModType ? data.spotData.payrollModType : '',
                                payrollModCode: data.spotData.payrollModCode ? data.spotData.payrollModCode : '', 
                                payrollModRosterId: data.rosterId, 
                                payrollModTargetUserId: data.spotData.userId ? data.spotData.userId : data.spotData.payrollModTargetUserId ? data.spotData.payrollModTargetUserId : null, 
                                payrollModExchangeUserId: '',
                                payrollModExchangeApproved: null, 
                                payrollModDatetime: data.startTime, 
                                payrollModHours: data.spotData.rosterShiftLength ? data.spotData.rosterShiftLength : data.spotData.payrollModHours ? data.spotData.payrollModHours : null, 
                                payrollModAuthorId: '', 
                                payrollModApprovalUserId: '',
                                payrollModRosterPosition: data.position,
                                payrollModApproved: null,
                                payrollModNote: '' }
          if(data.spotData.id && type != 'add'){
            dataobj['id'] = data.spotData.id
          }
      } else {
 
        var dataobj = data.spotData.payrollModData
      }
      dataobj['rosterUpdateData'] = true
      bus.$emit('updatePayrollMod', dataobj)
    },

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.tvViewFont {
      font-size: 20px;
}
.pointer {
  cursor: pointer;
}
.vacancy-danger {
  border: 1px solid red;
    border-radius: 5px;
    color: red;
    font-weight: bold;
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width: 3400px){
  .borderBottomStacked {
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
  }
}

</style>
