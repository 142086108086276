<template>
  <div class="hello">
  <digitalFormAdmin v-if="showAdmin" :formTemplates="formTemplates" @getFormTemplates="getFormTemplates" />
  <b-container v-if="!showAdmin" fluid  class="px-1 px-md-4">
      <b-card no-body>
       <b-tabs card>
         <b-tab title="File List"  class="px-1 px-md-4">
        <b-table :fields="fields" :items="formTemplates" v-if="!viewPreviousFormSubsId" stacked="md">
          <template slot="viewSubmissions" slot-scope="data">
            <b-btn variant="info" size="sm" @click="viewPreviousFormSubsId = data.item.id, viewPreviousForm = data.item" :disabled="checkIfDisabledView(data.item)">View</b-btn>
          </template>
          <template slot="createNew" slot-scope="data">
            <b-btn variant="primary" size="sm" :disabled="checkCreatePerm(data.item)" @click="createNewSub(data.item)">New</b-btn>
            <b-btn v-if="false" variant="primary" size="sm" :disabled="checkCreatePerm(data.item)" @click="newModelObj = data.item, newModelRequest = !newModelRequest">Model</b-btn>
          </template>

        </b-table>
        <b-btn v-if="viewPreviousFormSubsId" block variant='danger' @click="viewPreviousFormSubsId = null, viewPreviousForm = {}">Back to Form List</b-btn>
        <previousformsubs v-if="viewPreviousFormSubsId" :previousFormId="viewPreviousFormSubsId" :form="viewPreviousForm" @viewPreviousFormEntry="viewPreviousFormEntryAdd" />
         </b-tab>
        <template v-for="(form, index) in createNewForm">
          <b-tab :key="index + '_newForm'" active  class="px-1 px-md-4">
        <template slot="title">
              {{ form.formName }} <b-btn variant="danger" class="px-1 py-0" size="sm" @click="createNewForm.splice(index, 1)">X</b-btn>
      </template>
            <newDigitalForm  :form="form" @openSubmittedForm="viewPreviousFormEntryAdd, createNewForm.splice(index, 1)" />
          </b-tab>
        </template>
        <template v-for="(form, index) in previousFormEntryArray">
          <b-tab :key="index + '_previousForm'" active>
            <template slot="title">
              {{ getFormIdName(form.formId) }} <b-btn variant="danger" class="px-1 py-0" size="sm" @click="previousFormEntryArray.splice(index, 1)">X</b-btn>
            </template>
            <newDigitalForm  :previousForm="form" @closeFormAndReOpen="viewPreviousFormEntryAdd" :formTemplates="formTemplates" />
          </b-tab>
        </template>
       </b-tabs>
    </b-card>
  </b-container>
  <b-modal v-if="newModelObj" v-model="newModelRequest" size="lg">
     <newDigitalForm v-if="newModelObj" :form="newModelObj" @openSubmittedForm="viewPreviousFormEntryAdd" />
  </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import digitalFormAdmin from '../admin/adminDigitalForms.vue'
import newDigitalForm from '../addNew/newDigitalForms.vue'
import previousformsubs from './digitalForms/previousFormSubs.vue'
export default {
components: {
  digitalFormAdmin,
  newDigitalForm,
  previousformsubs
},
props: ['showAdmin'],
data() {
    return {
      checkForChanges: true,
      newModelRequest: false,
      newModelObj: null,
      formTemplates: [],
      createNewForm: [],
      previousFormEntryArray: [],
      viewPreviousFormSubsId: '',
      viewPreviousForm: {},
      fields: [
        'formName',
        {key: 'formAuthor', label: 'Created By'},
        {key: 'formParentCat', label: 'Parent Category'},
        {key: 'formCat', label: 'Category'},
        'viewSubmissions',
        'createNew'
      ]

}
},
created () {
  this.getFormTemplates()
},
mounted(){
    var data = this.$route.query
     if(!_.isEmpty(data)){
       if(data.digitalform){
         console.log('DIGITAL FORM PAGE')
        var obj = {id: data.digitalform}
        this.viewPreviousFormEntryAdd(obj, true)
       }
     }

},
beforeDestroy(){
  
},
computed: {
  ...mapState([
    'bannerImages',
    'departmentBlogs',
    'userData',
    'fullCalendarEvents',
    'getSessionData',
    'documentsApi',
    'trainingrefData',
    'trainingItemData',
    'stationsData',
    'apparatusData',
    'deptContactData',
    'formtempData'
  ]),


},
methods: {
  getFormIdName(id){
    var obj = this.formTemplates.find(p => p.id == id)
    if(obj){
      return obj.formName
    } return 'Unknown'
  },
  viewPreviousFormEntryAdd(data, idOnly){
    var index = this.previousFormEntryArray.map(p => p.id).indexOf(data.id)
    if(index != -1){
      this.previousFormEntryArray.splice(index, 1)
      if(!idOnly){
      this.previousFormEntryArray.push(data)
      } else {
        index = -1
      }
    }
    if(index == -1){
       axios({ 
        method: 'get',
        url: '/api/v1/content/digitalformsub/' + data.id,
        headers: {
            'Authorization': this.getSessionData,
          'Content-Type' : 'application/json',
        }
      }).then( response => {
         if(response.data && response.data[0]){
      this.previousFormEntryArray.push(response.data[0])
         }
    })

    }
  },
  checkCreatePerm(data){
     if(data && data.formSubmission && data.formSubmission.visibilityCreateRoles){
       var index = data.formSubmission.visibilityCreateRoles.indexOf(this.userData.role)
       if(index > -1){
         return false
       } return true
     } return true
  },
  checkIfDisabledView(form){
    if(form && form.formSubmission && form.formSubmission.visibilitySubmittedRoles){
      var index = form.formSubmission.visibilitySubmittedRoles.indexOf(this.userData.role)
      if(index != -1){
        return false
      } return true
    } return false
  },
  createNewSub(data){
    var obj = _.cloneDeep(data)
    this.createNewForm.push(obj)
  },
  getFormTemplates(){
    axios({ 
     method: 'get',
     url: '/api/v1/taxonomy/digitalforms/',
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
       this.formTemplates = response.data
      })
    },
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
