<template>
<div>
        <b-navbar class="blogSpanHeader" toggleable="lg" type="dark" variant="dark" v-if="formatedTableValuesPreFilter">
           <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                     <b-navbar-brand class="ml-2">Business ({{ formatedTableValues.length }})</b-navbar-brand>
            <b-collapse is-nav id="nav_collapse">
              <b-navbar-nav>
        <b-nav-form class="ml-2">
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchBusiness" placeholder="Business Name"></b-form-input>
          <b-form-input class="ml-2 textInputSize" size="sm"  type="text" v-model="searchAddress" placeholder="Address"></b-form-input>
          <b-form-select class="ml-2" size="sm"  v-model="sortOrder" v-if="false">
            <option value="desc">Newest</option>
            <option value="asc">Oldest</option>
          </b-form-select>
      
      <b-form-radio-group  class="ml-3" size="sm"  id="radios2" v-model="sortBySelected" name="radioSubComponent">
        <b-form-radio :value="false" class="text-light">Exact</b-form-radio>
        <b-form-radio :value="true" class="text-light">Contains</b-form-radio>
      </b-form-radio-group>
          <b-btn variant="info" size="sm" :disabled="getSelectedBusinesses.length == 0" @click="siteinspectionScheduleBoo = !siteinspectionScheduleBoo">Schedule ({{ getSelectedBusinesses.length }})</b-btn>
          <b-btn variant="primary" size="sm" :disabled="getSelectedBusinesses.length > 1 || getSelectedBusinesses.length == 0" @click="emitNewInspection(getSelectedBusinesses)">New Inspection</b-btn>
        </b-nav-form>
              </b-navbar-nav>
                    </b-collapse>
          <b-navbar-nav class="ml-auto">
            <b-btn variant="outline-success"  size="sm" @click="dataViewMode == 'Map' ? (dataViewMode = 'Table', disableMapStatus = false, mapStatus = 'both') : (dataViewMode = 'Map', disableMapStatus = true, mapStatus = true)">{{ dataViewMode == 'Map' ? 'Table' : 'Map' }}</b-btn>
            </b-navbar-nav>

      </b-navbar>
      <span v-show="false">{{ viewBusinessId }}</span>
     <b-table v-if="dataViewMode == 'Table'" :fields="fields" :items="formatedTableValues" :per-page="10" :current-page="currentPage"  stacked="lg"> 
        <template slot="select" slot-scope="data">
          <b-form-checkbox v-model="data.item.select" />
        </template>
        <template slot="updatedAt" slot-scope="data">
          <span :title="getDateFormatTitle(data.item.updatedAt, data.item.businessAuthor)" >{{ getDateFormat(data.item.updatedAt)}}</span>
        </template>
        <template slot="view" slot-scope="data">
          <b-btn variant="outline-info" size="sm" @click='emitItem(data.item.id)'>View</b-btn>
        </template>
        <template slot="Remove" slot-scope="data">
          <b-btn variant="outline-danger" size="sm" @click='deleteItem(data.item.id)' :disabled="true">Delete</b-btn>
        </template>
      </b-table>
      <b-btn v-if="dataViewMode == 'business'" @click="dataViewMode = 'Table', viewImage = ''" variant="danger">Return To Table</b-btn>
      <b-btn v-if="viewImage" variant="danger" @click="viewImage = ''" class="ml-2">Close Inspection</b-btn>
      <b-container v-if="viewImage" id="printView" class="my-2">
      <b-img :src="viewImage" />
      </b-container>
      <b-table v-if="dataViewMode == 'business'" :fields="inspectionFields" :items="previousBusinessInspections">
        <template slot="inspectionDate" slot-scope="data">
          {{ getFormatedDate(data.item.inspectionDate) }}
        </template>
        <template slot="updatedAt" slot-scope="data">
          {{ getFormatedDate(data.item.updatedAt)}}
        </template>
        <template slot="inspectionComplete" slot-scope="data">
          <b-badge variant="success" v-if="data.item.inspectionComplete">&#10004;</b-badge>
          <b-badge variant="warning" v-if="data.item.inspectionPassed && data.item.inspectionPassed.length > 0 && !data.item.inspectionComplete">started</b-badge>
        </template>
        <template slot="inspectionViolations" slot-scope="data">
          <b-badge v-if="data.item.inspectionViolations" :variant="data.item.inspectionViolations.length > 0 ? 'danger' : 'success'">{{ data.item.inspectionViolations.length }}</b-badge>
        </template>
        <template slot="inspectionFinalPdf" slot-scope="data">
          <div v-if="data.item.inspectionFinalPdf && data.item.inspectionFinalPdf.length > 0">
            <b-btn v-for="(pdfItem, index) in parseData(data.item.inspectionFinalPdf)" :key="index"  size="sm" target="_blank" @click="viewImage = pdfItem.inspection" variant="primary">
             View
            </b-btn>
            <b-btn v-for="(pdfItem, index1) in parseData(data.item.inspectionFinalPdf)" :key="index1 + '2'"  size="sm" @click="printPdf(pdfItem.inspection)" variant="success">
             Print
            </b-btn>
            <b-btn @click="emailInspection(data.item)" variant="info" size="sm">Email Inspection</b-btn>
            <b-btn v-show="userData.role != 'user'" size="sm" @click="openInpsectionForm(data.item, true)" variant="warning">
             Regenerate
            </b-btn>
          </div>
          <b-btn v-else-if="data.item.inspectionPassed && data.item.inspectionPassed.length > 0 && !data.item.inspectionComplete" size="sm" variant="warning" @click="openInpsectionForm(data.item)">Finish</b-btn>
          <b-btn v-else variant="info" size="sm" @click="openInpsectionForm(data.item)">Start</b-btn>
        </template>
      </b-table>
       <b-pagination class="pt-2" align="center" size="md" v-if="formatedTableValues && dataViewMode == 'Table'" :total-rows="formatedTableValues.length" v-model="currentPage" :per-page="10" />
      <businessMap v-if="dataViewMode == 'Map'" :responseView="false" :availableBusiness="formatedTableValues" @showBusiness="emitItem($event)" />
    <siteinspectionSchedule v-if="siteinspectionScheduleBoo" :formatedTableValues="getSelectedBusinesses" :scheduleType="'Inspection'" @ClosedModal="siteinspectionScheduleBoo = !siteinspectionScheduleBoo" @getInspections="EmitgetInspections" />
    <b-modal  v-model="emailInspectionModel" title="Email Inspection" :hide-footer="true">
      <b-form-input type="email"  v-model="emailTempHold" placeholder="Add Email" />
      <b-btn variant="info" @click="emailInspectionObj.emailArray.push(emailTempHold), emailTempHold = ''">Add</b-btn>
      <div v-for="(email, index) in emailInspectionObj.emailArray" :key="index">{{ email }} <b-badge variant="danger" class="pointer" @click="emailInspectionObj.emailArray.splice(index, 1)">X</b-badge></div>
        <b-btn variant="primary" @click="emailDataToUsers(emailInspectionObj)">Send Email(s)</b-btn>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import printJS from 'print-js'
import DatePicker from '../../inputfields/Datepickermod.vue'
import TimePicker from '../../inputfields/timepicker.vue'
import moment from 'moment'
import Leaf from 'leaflet'
import html2canvas from 'html2canvas';
import businessMap from './inspectionMap.vue'
import querystring  from 'querystring';
import Multiselect from 'vue-multiselect'
import arrayData from '../../../../files/dispatchReport.js'
import convert from 'xml-js'
import siteinspectionSchedule from './siteinspectionSchedule.vue'
export default {
  props:["formTableStyle", "submittedBusiness", "viewBusiness"], 
components:{
      'businessMap' : businessMap,
      'timepicker' : TimePicker,
      'multiselect' : Multiselect,
      'datepicker' : DatePicker,
      siteinspectionSchedule

},
  data () {
    return {
        showTutVideo: false,
        edit: true,
        imageResponseData: [],
        postData: '',
        timeData: '',
        imageSrc: '',
        searchGrid: '',
        scheduleModal: false,
        searchBusiness: '',
        searchStation: '',
        searchAddress: '',
        emailInspectionObj: {},
        emailTempHold: '',
        emailInspectionModel: false,
        currentPage: 1,
        siteinspectionScheduleBoo: false,
        dateSelected: moment().format("MM/DD/YYYY"),
        bootstrapDate: moment().format("YYYY-MM-DD"),
        inspectionFields: [
          {key: 'inspectionDate', label: 'Scheduled'},
          {key: 'updatedAt', label: 'Last Update'},
          {key: 'inspectionAuthor', label: 'Author'},
          {key: 'inspectionComplete', label: 'Completed?'},
          {key: 'inspectionCompletedDate', label: 'Completed Date'},
          {key: 'inspectionViolations', label: 'Violations'},
          {key: 'inspectionFinalPdf', label: 'View'}
        ],
        stationSelectValue: '',
        showGroup: 'business',
        showModalConfirmation: false,
        pictureUpload: '',
        searchData: '',
        sortOrder: 'asc',
        carouselToggle: '',
        pictureFileArray: [],
        viewImage: '',
        imageLoader: '',
        holdValues: {},
        taxonomyScheduleId: null,
        patchData: false,
        completedLoad: false,
        ppObj: {},
        fields: [],
        tableRows: [],
        formatedTableValuesPreFilter: [],
        jsonArray: [],
        buildData: [],
        mapStatus: 'both',
        disableMapStatus: false,
        dataViewMode: 'Table',
        sortBySelected: true,
        windowWidth: '',
        previousBusinessInspections: [],

    }
  },
  created(){
  var data = arrayData.arrayData
  this.getWindowWidth()
  window.addEventListener('resize', this.getWindowWidth);
  var jsonArray = []
  for(var i in data){
    var result = convert.xml2json(data[i], {compact: true, spaces: 0})
    var parsed = JSON.parse(result)
    jsonArray.push(parsed)
  }
    this.buildData = []
   this.jsonArray = jsonArray
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  mounted(){
    var data = _.cloneDeep(this.submittedBusiness)
    this.buildTableData(this.formTableStyle, data)
    this.setupTableValues(this.formTableStyle, data)
  
  },
  computed:{
    ...mapState([
          'getSessionData',
          'stationsData',
          'apparatusData',
          'userData',
          'shiftsData',
          'Businesstatus',
          'deptContactData'
        ]),
        documentWidth(){
          if(this.windowWidth > 992){
            var boo = true
          } else {
             var boo = false
          }
          return boo
        },
        viewBusinessId(){
          console.log('Compute business')
          if(this.viewBusiness){
          this.emitItem(this.viewBusiness)
          }
          return this.viewBusiness
        },
        getSelectedBusinesses(){
          var array = this.formatedTableValues
          var arrayFilter = array.filter(p => p.select)
          var ids = arrayFilter.map(p => p.id)
          return ids
        },
        shiftSet(){
          var selectedVarDate = moment(this.currentDateSelected, "MM/DD/YYYY");
          var presetVarDate = moment("01/01/2016", "MM/DD/YYYY");
          var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
          var dateDiffCalNumber = 100*(dateDiffCal/3);
          var dateTenDigit = dateDiffCalNumber % 10;
          var roundedDated = Math.round(dateTenDigit)
          if (roundedDated == 0)
          return "B-shift";
          if (roundedDated == 7)
          return "A-shift";
          if (roundedDated == 3)
          return "C-shift";
        },
        stationSearchOptions(){
          var optionOne = {text: 'Station', value: ''}
          var array = []
          array.push(optionOne)
          var data = this.stationsData.map(p => p.stationName)
          var data = _.sortBy(data, ['asc'])
          for(var i in data){
            var index = array.indexOf(data[i])
            if(index < 0){
              array.push(data[i])
            }
          }
          return array
        },
        formatedTableValues(){
          if(this.searchGrid){
          var grid = this.formatedTableValuesPreFilter.filter(p => {
                  if(this.searchGrid){ 
                  return p.gridNumber.toString().match(this.searchGrid.toString())
                  } else {
                    return p
                  }
          })
        } else { var grid = this.formatedTableValuesPreFilter }
        if(this.searchStation){
          var station = grid.filter(p => {
            return  p.primaryStation.includes(this.searchStation)
          })
         } else { var station = grid }
          if(this.searchBusiness){
           var business = station.filter(p => {
             if(this.sortBySelected){
               var searchOpt = this.searchBusiness.toLowerCase().split(' ')
               var searchResult = true
               for(var i in searchOpt){
                 var index = p.businessName.toLowerCase().includes(searchOpt[i])
                   if(!index){
                     searchResult = false
                   }
                 }
                 if(searchResult){
                   return p
                 }
               
             } else {
                return p.businessName.toLowerCase().includes(this.searchBusiness.toLowerCase()) 
             }
           })
         } else { var business = station   }
         var map = business.filter(p => {
                 if(this.mapStatus == false){

                    if(!p.map){
                      return p
                    }
                  } else if (this.mapStatus == true){
                    if(p.map){
                      return p
                    }
                  } else {
                    return p
                  }
         })
         if(this.searchAddress){
           var address = map.filter(p => {
             if(this.sortBySelected){
               var searchOpt = this.searchAddress.toLowerCase().split(' ')
               var searchResult = true
               for(var i in searchOpt){
                 var index = p.streetNumberStreetStreetSuffix.toLowerCase().includes(searchOpt[i])
                   if(!index){
                     searchResult = false
                   }
                 }
                 if(searchResult){
                   return p
                 }
               
             } else {
                return p.streetNumberStreetStreetSuffix.toLowerCase().includes(this.searchAddress.toLowerCase()) 
             }
           })
         } else {
           var address = map
         }
         return _.orderBy(address, ['updatedAt'], [this.sortOrder])
        },
    
    

    },
  methods:{
    regenerateInspection(data){
      console.log(data)
    },
    emailDataToUsers(data){
      //console.log(data)
      axios({
        method: 'post',
        url: "/api/v1/preplan/inspection/emailinspection",
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }}).then(response => {
          this.emailInspectionModel = false
          this.emailInspectionObj = {}
        })
    },
    EmitgetInspections(){
      this.$emit('getInspections')
    },
    emailInspection(data){
        var inspection = data
        if(inspection && inspection.inspectionFinalPdf[0] && inspection.inspectionFinalPdf[0]){
          var parsed = JSON.parse(inspection.inspectionFinalPdf[0])
          this.emailInspectionObj = {emailImage: parsed.inspection, emailArray: []}
          this.emailInspectionModel = true
        }
    },
    printPdf(pdf){
      var vm = this
      console.log('NEW Print IMAGE')
      var img = new Image()
      img.src = pdf;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return  printJS({printable: dataURL, 
                      style: 'img { width:1200px !important; }',
                       type: 'image'})
      //  vm.departmentInspectionImage = dataURL // .replace(/^data:image\/(png|jpg);base64,/, "")
      };

    },
    openInpsectionForm(inspObj, regenerate){
      var obj = inspObj
      obj['business'] = this.formatedTableValuesPreFilter.find(p => p.id == obj.inspectionBusinessId)
      if(regenerate){
        obj['regenerate'] = true
      }
      this.$emit('openInspectionForm', obj)
    },
    getFormatedDate(date){
      if(date){
        return moment(date).format("MM/DD/YY")
      } return 'Unknown Date'
    },
    parseData(data){
      if(data){
        var array = data.map(item => JSON.parse(item))
       // console.log(array)
        return array
      }
    },
    updateDate(date, event) {
      this.dateSelected = moment(date).format("MM/DD/YYYY");
      this.bootstrapDate = moment(date).format("YYYY-MM-DD");
    },
    getWindowWidth(event) {
        this.windowWidth = document.documentElement.clientWidth;
    },
    emitNewInspection(data){
      if(data && data[0]){
        this.$emit('createNewInspection', data[0])
      }
    },
    getOptionsFromSelection(data){
    if(data){
     // console.log(data)
    return this[data]
    } else {
      return []
    }
    },
    getDateFormat(data){
      if(data){
        return moment(data).fromNow()
      } else {
        return 'invalid'
      }
    },
    getDateFormatTitle(data, author){
      if(data){
        var time = moment(data).format('MM/DD/YYYY HH:mm')
        return 'Last Updated on ' + time + ' by ' + author
      } else {
        return 'invalid'
      }
    },
    buildTableData(table, data){
      if(this.userData.role != 'user'){
      this.fields.push('select')
      }
      for(var i in table){
        var obj = {key: table[i].key, label: table[i].label}
        this.fields.push(obj)
      }
      this.fields.push({key: 'businessLastAnnual', label: 'Last Periodic', sortable: true})
      this.fields.push({key: 'businessLastInspection', label: 'Last Inspection', sortable: true})
      this.fields.push('view')
      if(this.userData.role == 'admin' && false){
        this.fields.push('Remove')
      }
     
    },
    setupTableValuesLoop(obj, vals){
        for(var p in vals){
          if(vals[p].key != 'map'){
          obj[vals[p].key] = vals[p].value
          } else if (vals[p].key == 'map'){
            obj[vals[p].key] = vals[p]
          }
        }  
      return obj
    },
    setupTableValues(table, dataArray){
      var businessPar = [...new Set(dataArray.map(p => p.businessParent))]
      businessPar = businessPar.filter(p => p != null && p != undefined)
      var data = dataArray.filter(p => !p.businessParent)
      var newArray = this.buildData
      for(var i in data){
        var obj = data[i]
        if(businessPar.indexOf(data[i].id) != -1){
          obj['select'] = false
          obj['_rowVariant'] = 'primary'
          var vals = obj.businessTableData
          obj = this.setupTableValuesLoop(obj, vals) 
          newArray.push(obj)
          var array = dataArray.filter(p => p.businessParent == data[i].id)
          for(var f in array){
              var Holdobj = this.setupTableValuesLoop(array[f], array[f].businessTableData)
              Holdobj['_rowVariant'] = 'info'
              Holdobj['select'] = false
              newArray.push(Holdobj)
          } 
          } else {
          var vals = obj.businessTableData
          obj = this.setupTableValuesLoop(obj, vals) 
          obj['select'] = false
          newArray.push(obj)
        }
      }
   //   newArray.forEach(item => item.map = this.getItemMap(item.map))
   //   var holdnewArray = newArray
      this.formatedTableValuesPreFilter = newArray
    },
    getItemMap(item){
      if(!item){
          var southWest= {lat: 27.128813241669747, lng: -82.99415588378908}
          var northEast = {lat: 27.54480631775389, lng: -82.03765869140626}
          var lngSpan = northEast.lng - southWest.lng
          var latSpan = northEast.lat - southWest.lat
          return {obj: {y: southWest.lat + latSpan * Math.random(), x: southWest.lng + lngSpan * Math.random()}}
      } else {
        return item
      }
    },
    emitItem(data){

      this.getInspectionsTagged(data)
    },
    deleteItem(data){
   //   this.$emit('deletebusiness', data)
    },
    getTableItems(display, fields){
      var fieldSort = fields.filter(p => p.order == display)
       var obj = {}
      for(var i in fieldSort){
        var key = fieldSort[i].idtag
        var value = this.holdValues[fieldSort[i].vmodel].value
        if(value == undefined){
          value = 'Not Entered'
        }
        if(fieldSort[i].type == 'computed'){
          value = this.getComputedValues(fieldSort[i])
        }
        if(fieldSort[i].type == 'taxonomySingleSelect'){
          value = this.holdValues[fieldSort[i].vmodel].value[fieldSort[i].taxitem]
        }
        if(fieldSort[i].type == 'reference'){
          value = this.holdValues[fieldSort[i].reference].value[fieldSort[i].refitem]
        }
        obj[key] = value
      }
    var arr = []
    arr.push(obj)
    return arr
    },
    getComputedValues(data){
      var array = []
      for(var i in data.ref){
        var hold = this.holdValues[data.ref[i]].value
        if(hold != undefined && hold != null && hold != ''){
          array.push(hold)
        }
      }
      var operator = function(a, b){ return a * b}
      var holdValue = 0
      for(var i in array){
        if(i == 0){
          var item = operator(array[i], array[1])

          holdValue = item
        } else if(i > 1) {
           var item = operator(holdValue, array[i]) 
        }
      }
      return holdValue
    },
    getDescendantProp(obj, desc) {
    var arr = desc.split(".");
    while(arr.length && (obj = obj[arr.shift()]));
    return obj;
    },   
    getInspectionsTagged(data){
      axios({
        method: "get",
        url: "/api/v1/preplan/inspection/business/" + data,
        data: data,
        headers: {
          Authorization: this.getSessionData,
          "Content-Type": "application/json",
        }
      }).then(response => {
         this.previousBusinessInspections = _.orderBy(response.data, ['inspectionDate'], ['desc'])
         this.dataViewMode = 'business'
      })
 
     },


}
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.datepickervue {
  width: 300px;
}
.A-shift {
    background: #fff;
  color: #e2070d;
}
.B-shift {
    background: #fff;
  color: #201B9F;
}
.C-shift {
    background: #fff;
  color: #0FB906;
}
.multiselect__option--highlight {
  background: #f0454a
}
span.custom__tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #f0454a;
  margin-bottom: 8px;
  white-space: nowrap;
}
span.custom__remove {
  color: #444;
  font-weight: bold;
  margin-left: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style scoped>
.numberInputSize {
  width: 70px;
}
.textInputSize {
  width: 140px;
}
.textAreaDescription {
  margin-top: 10px;
}
span{
  margin-bottom: 10px;
}
.multiselect{
  margin-bottom: 15px;
}
.itemDescription {
  margin-top: 10px;
  border-style: solid;
    border: 1px solid #bbb;
}
#tablePassDown{
  margin: 0;
  padding: 0;
}
.sub-section-heading {
color: White;
background: #aaa;
width: 100%;
border-radius: 5px;
padding: 7px;
text-align: center;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 0;
margin-left: 0;
}
textarea {
  width: 100%;
  border-radius: 5px;
}
.centerTheHeading {
  text-align: center;
}
.genLeft h3 {
  margin-right: 30px;
  text-align: right;
}
.genCenter {
  text-align: center;
}
.removeButton {
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0;
}
input {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
span.defDescrip {
  border: 1px solid red;
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-left: 40px;
}
span.Yes {
  background: green;
  color: white;
  padding: 3px;
}
span.No {
  background: red;
  color: white;
  padding: 3px;
}
table {
  width: 100%;
  text-align: center;
}
tr.headerRows {
  background: #aaa;
  font-weight: bold;
}
#confirmationTable td {
    padding: 5px;

}
.selectedButtonGreen {
  background: green;
  color: white;
}
.selectedButtonRed {
  background: red;
  color: white;
}
#todaysDateDisplay {
  margin-bottom: 0;
  cursor: pointer;
  color: red;
}
#logbookBody {
  margin-top: 60px;
  width: 100%;
}
.addNewArticle {
  border-radius: 50%;

}
.addNewArticle:hover {
  background: grey;
}
.timeMobile{
  display: none;
}
.datePickerClassMobile {
  display: none;
}
@media screen and (max-width: 992px) {
  .time-picker.timeDesk{
    display: none;
  }
    .datePickerClass {
    display: none;
  }
  .datePickerClassMobile {
    display: block;
  }
  .timeMobile{
    display: block;
    min-width: 120px;
    max-width: 150px;
  }
}


</style>
