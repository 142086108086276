<template>
  <div class="hello"> 
    <div v-if="userData.role == 'admin' || userData.role == 'adminchief'">
                    <b-navbar type="dark" variant="dark">
      <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable">Roster Profile Date:</b-navbar-brand>
       <b-form-input type="date" v-model="selectedDate" />
        <b-btn @click="addNewModal()">Add New Profile</b-btn>
      </b-nav-form>
    </b-navbar>
     
    <b-card no-body>
      <b-tabs card  :lazy="true">
        <b-tab v-for="(shift, index) in rosterShifts" :title="shift" :key="index" @click="currentShift = shift">
    <b-card no-body v-if="shift == currentShift">
      <b-tabs card  :lazy="true">
    <b-tab v-for="parent in rosterParentsData" :key="parent.id" :title="parent.stationName"  :lazy="true" @click="currentParent = parent.id">
       <h4 slot="header" class="clickPointer text-light text-left">{{ parent.stationName }}</h4>
        <b-card v-if="currentParent == parent.id" class="mb-2" no-body v-show="parent.showStation" v-for="station in stationsFromParents(parent.id)" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1">
          <h4 slot="header" class="clickPointer text-left">{{ station.stationName }}</h4>
            <b-card no-body v-for="roster in returnRosterSpots(parent.id, station.id)" :key="roster.id">
             <h5 class="text-left pl-5 mt-2">{{ roster.apparatusName }}</h5>
             <div v-for="(rosterSpot, indexp) in roster.rosterPositions" :key="indexp">
             <b-container v-if="rosterProfilesFind(roster.id, shift, rosterSpot.position).length > 0">
              <b-card  no-body v-for="profile in rosterProfilesFind(roster.id, shift, rosterSpot.position)" :key="profile.id" border-variant="light">
                  <b-row><b-col lg="1">
                    {{ profile.rosterPosition }}</b-col>
                    <b-col lg="4">
                    {{ getUser(profile.userId) }} 
                    </b-col>
                    <b-col lg="2">{{ profile.rosterSpotType }} 
                    </b-col>
                    <b-col lg="2">
                     Start: {{ getDateFormated(profile.rosterAssignmentStart) }}<br>
                      End: {{ getDateFormated(profile.rosterAssignmentEnd) }}
                    </b-col>
                    <b-col lg="2">
                      {{ profile.rosterStartTime }}<br>
                      {{ profile.rosterShiftLength }} Hrs
                      </b-col>
                    <b-col lg="1">
                      <b-btn variant="info" size="sm" @click="editProfile(profile)">Edit</b-btn>
                       <b-btn v-if="profileDeleteTest(profile.rosterAssignmentStart)" size="sm" variant="danger" @click="deleteProfile(profile.id)">Delete</b-btn>
                    </b-col>
                    </b-row>
                </b-card>
             </b-container>
            <b-container v-else>
              <b-card  no-body border-variant="light">
                  <b-row><b-col lg="1" class="text-danger">
                    {{ rosterSpot.position }}</b-col>
                    <b-col lg="4">
                     <h6 class="text-danger">No Assignments</h6>
                    </b-col>
                    <b-col lg="2" class="text-danger">{{ rosterSpot.type }} 
                    </b-col>
                    <b-col lg="2">
                    
                    </b-col>
                    <b-col lg="2" class="text-danger">
                      {{ rosterSpot.start }}<br>
                      {{ rosterSpot.duration }} Hrs
                      </b-col>
                    <b-col lg="1">
                      <b-btn variant="success" size="sm" @click="fillVacancyProfile(roster, rosterSpot, shift)">Fill</b-btn>
                    </b-col>
                    </b-row>
                </b-card>
                </b-container>
             </div>
            <b-container v-if="rosterProfilesFindWithoutPosition(roster.id, shift, roster.rosterPositions).length > 0">
            <b-card  no-body v-for="profile in rosterProfilesFindWithoutPosition(roster.id, shift, roster.rosterPositions)" :key="profile.id" border-variant="light">
                  <b-row><b-col lg="1" class="text-info font-weight-bold" title="OFF ROSTER - Position not on roster template">
                    {{ profile.rosterPosition }}*</b-col>
                    <b-col lg="4">
                    {{ getUser(profile.userId) }} 
                    </b-col>
                    <b-col lg="2">{{ profile.rosterSpotType }} 
                    </b-col>
                    <b-col lg="2">
                     Start: {{ getDateFormated(profile.rosterAssignmentStart) }}<br>
                      End: {{ getDateFormated(profile.rosterAssignmentEnd) }}
                    </b-col>
                    <b-col lg="2">
                      {{ profile.rosterStartTime }}<br>
                      {{ profile.rosterShiftLength }} Hrs
                      </b-col>
                    <b-col lg="1">
                      <b-btn variant="info" size="sm" @click="editProfile(profile)">Edit</b-btn>
                      <b-btn v-if="profileDeleteTest(profile.rosterAssignmentStart)" size="sm" variant="danger" @click="deleteProfile(profile.id)">Delete</b-btn>
                    </b-col>
                    </b-row>
                </b-card>
             </b-container>
          </b-card>
         <b-container v-if="unassignedUsersThatMeetRequirement(station.stationName, shift) && false">
              <b-card  no-body v-for="profile in unassignedUsersThatMeetRequirement(station.stationName, shift)" :key="profile.id" border-variant="light">
                  <b-row>
                    <b-col lg="2">
                    {{ getUser(profile.id) }} 
                    </b-col>
                             <b-col lg="2">
        <multiselect
        @input="updateMultiSelectValue($event, profile.id)"
         v-model="profile.rosterSpotHold" 
        :options="rosterSpotsFilter" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Roster Spot" label="apparatusName" 
        track-by="id"></multiselect>
        </b-col>
<b-col lg="1">
         
          <b-form-select v-model="profile.rosterPosition" :options="rosterPositionOpt" />
          </b-col>
                    <b-col lg="2">
    
          <b-form-input type="date" v-model="profile.rosterStartDate" />
          </b-col>
                    <b-col lg="2">
     
          <b-form-select v-model="profile.rosterSpotType" :options="rosterSpotTypeOptions" />
          </b-col>
                <b-col lg="2">
        
        <b-form-select v-model="profile.shift" :options="rosterShifts" />
        </b-col>
          <b-col lg="1">
            <b-btn @click="updateUserBulkUser()">Save</b-btn></b-col>
                    </b-row>
                </b-card>
             </b-container>
          </b-card>
    </b-tab>
    <b-tab title="All" v-if="false">
    <b-card v-for="parent in rosterParentsData" :key="parent.id" header-tag="header" header-class="my-0 pb-0 pt-1" bg-variant="dark">
       <h4 slot="header" class="clickPointer text-light text-left">{{ parent.stationName }}</h4>
        <b-card class="mb-2" no-body v-show="parent.showStation" v-for="station in stationsFromParents(parent.id)" :key="station.id" header-tag="header" header-class="my-0 pb-0 pt-1">
          <h4 slot="header" class="clickPointer text-left">{{ station.stationName }}</h4>
            <b-card no-body v-for="roster in returnRosterSpots(parent.id, station.id)" :key="roster.id">
             <h5 class="text-left pl-5 mt-2">{{ roster.apparatusName }}</h5>
             <b-container v-if="rosterProfilesFind(roster.id, shift).length > 0">
              <b-card  no-body v-for="profile in rosterProfilesFind(roster.id, shift)" :key="profile.id" border-variant="light">
                  <b-row><b-col lg="1">
                    {{ profile.rosterPosition }}</b-col>
                    <b-col lg="4">
                    {{ getUser(profile.userId) }} 
                    </b-col>
                    <b-col lg="2">{{ profile.rosterSpotType }} 
                    </b-col>
                    <b-col lg="2">
                     Start: {{ getDateFormated(profile.rosterAssignmentStart) }}<br>
                      End: {{ getDateFormated(profile.rosterAssignmentEnd) }}
                    </b-col>
                    <b-col lg="2">
                      {{ profile.rosterStartTime }}<br>
                      {{ profile.rosterShiftLength }} Hrs
                      </b-col>
                    <b-col lg="1">
                                   <b-btn variant="warning" size="sm" @click="editProfile(profile)">Edit</b-btn>
                     <b-btn v-if="profileDeleteTest(profile.rosterAssignmentStart)" variant="danger" size="sm" @click="deleteProfile(profile.id)">Delete</b-btn>
                    </b-col>
                    </b-row>
                </b-card>
             </b-container>
             <h6 class="text-danger" v-else>No Assignments</h6>
          </b-card>
         <b-container v-if="unassignedUsersThatMeetRequirement(station.stationName, shift) && false">
              <b-card  no-body v-for="profile in unassignedUsersThatMeetRequirement(station.stationName, shift)" :key="profile.id" border-variant="light">
                  <b-row>
                    <b-col lg="2">
                    {{ getUser(profile.id) }} 
                    </b-col>
                             <b-col lg="2">
        <multiselect
        @input="updateMultiSelectValue($event, profile.id)"
         v-model="profile.rosterSpotHold" 
        :options="rosterSpotsFilter" 
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="Roster Spot" label="apparatusName" 
        track-by="id"></multiselect>
        </b-col>
<b-col lg="1">
         
          <b-form-select v-model="profile.rosterPosition" :options="rosterPositionOpt" />
          </b-col>
                    <b-col lg="2">
    
          <b-form-input type="date" v-model="profile.rosterStartDate" />
          </b-col>
                    <b-col lg="2">
     
          <b-form-select v-model="profile.rosterSpotType" :options="rosterSpotTypeOptions" />
          </b-col>
                <b-col lg="2">
        
        <b-form-select v-model="profile.shift" :options="rosterShifts" />
        </b-col>
          <b-col lg="1">
 
            <b-btn @click="updateUserBulkUser()">Save</b-btn></b-col>
                    </b-row>
                </b-card>
             </b-container>
          </b-card>
    </b-card>

      </b-tab>
      </b-tabs>
    </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
</div>
<b-modal v-model="showModal" size="lg" hide-footer>
  <rosterprofileView 
  v-if="showModal"
  :rosterData="rosterData" 
  :rosterProfiles="rosterProfiles" 
  :rosterShifts="rosterShifts" 
  :rosterSpotsFilter="rosterSpotsFilter" 
  :deptContactDataFiltered="deptContactDataFiltered"
  :previous="previousProfile"
  @updateProfileSubmit="updateProfileSubmit"
  @newProfileSubmit="newProfileSubmit" />
  </b-modal>
</div>

</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import rosterprofileView from './rosterprofileView.vue'
export default {
  components: {
    multiselect,
    rosterprofileView
  },
  name: 'roster-admin',
    props: ['rosterData', 'rosterProfiles'],
  data () {
    return {
      showModal: false,
      apparatusSelectValue: null,
      stationSelectValue: '',
      stationParentSelectValue: '',
      previousProfile: null,
      rosterUserId: '',
      rosterId: '',
      rosterSpotType: 'permanent',
      rosterPosition: '',
      rosterScheduleShift: '',
      currentShift: '',
      currentParent: '',
      rosterKellyCycleNumber: '',
      rosterAssignmentStart: '',
      rosterNote: '',
      updateMultiSelect: true,
      rosterAssignmentEnd: '',
      bulkHoldObject: [],
      rosterStartTime: '08:00:00',
      rosterShiftLength: 24,
      selectedDate: moment().format('YYYY-MM-DD'),
      rosterProfileTableFields: [
        'userId',
        'rosterSpotType',
        'rosterAssignmentStart',
        'rosterAssignmentEnd'
      ],
      scheduledWorkDaysOptions:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      scheduledWorkDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      rosterPositionOpt: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      rosterParentsData: [],
      rosterSpotTypeOptions: ['permanent', 'float', 'temporary'],
      rosterFields: [
        'apparatusName',

      ],
    }
  },
  created(){
 this.rosterParents(this.rosterData)
  },
  mounted(){
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'deptContactData',
          'stationsData',
          'userData',
          'siteconfig'
        ]),
        // user_id, roster_id, roster_spot_type, roster_position, roster_note, scheduled_shift, scheduled_kelly_cycle_number, roster_assignment_start, roster_assignment_end
        rosterShifts(){
          var shifts = this.siteconfig.payroll.shifts
          if(shifts){
            var names = shifts.map(p => p.shift)
            var shifts = _.sortBy(names)
            this.currentShift = shifts[0]
            shifts.push('Daily')
            return shifts
          }
        },
        rosterSpotsFilter(){
          var array = this.rosterData
          console.log('rosterSpotsFilter')
          console.log(array)
          var indexArray = this.rosterData.map(p => p.apparatusId)
          var apparatus = this.apparatusData.filter(p => {
            var index = indexArray.indexOf(p.id)
            if(index != -1){
              return p
            }
          })
          return _.orderBy(array, ['apparatusName'], ['asc'])
        },
        deptContactDataFiltered(){
          var open = this.rosterProfiles.filter(p => {
            return p.rosterAssignmentEnd == null
          })
          var indexArray = open.map(p => p.userId)
          var unassigned = this.deptContactData.filter(p => {
            var index = indexArray.indexOf(p.id)
            if(index == -1){
              return p
            }
          })
     var date = moment().format('YYYY-MM-DD')
     var type = 'permanent'
     if(unassigned){
       var array = []
     for(var i in unassigned){
       var obj = {}
       obj['id'] = unassigned[i].id
       obj['shift'] = unassigned[i].shiftAssignment
       obj['stationAssignment'] = unassigned[i].stationAssignment
       obj['rosterStartDate'] = date
       obj['rosterSpotType'] = type
       obj['rosterId'] = ''
       obj['rosterSpotHold'] = ''
       obj['rosterPosition'] = ''
       array.push(obj)
     }
     this.bulkHoldObject = array
    // console.log(this.bulkHoldObject)
     return unassigned
     } 
        },
     profilesFilteredByDate(){
    var array = this.rosterProfiles.filter(p => {
      var start = moment(p.rosterAssignmentStart).isBefore(this.selectedDate)
      var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate) : true
      if(start && end){
        return p
      }
    
    })
      return array
     }
  
  },
  mounted(){

  },
  watch: {
    rosterUserId(newData){
     // console.log(newData)
      if(newData && newData.shiftAssignment){
      var shifts = this.rosterShifts
      var userShift = newData.shiftAssignment
      var index = shifts.indexOf(userShift)
      if(index != -1){
        this.rosterScheduleShift = shifts[index]
      } 
      }
    },
    apparatusSelectValue(newData){
      if(newData.apparatusLocation){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusLocation)
        if(station){
          this.stationSelectValue = station
        }
      }
    if(newData.apparatusParent){
        var station = this.stationsData.find(p => p.stationName == newData.apparatusParent)
        if(station){
          this.stationParentSelectValue = station
        }
      }
    }
  },
  methods: {
    addNewModal(){
      console.log('New')
      this.showModal = !this.showModal
    },
    fillVacancyProfile(roster, spot, shift){
      var obj = {
        rosterAssignmentStart: moment().toDate(),
        rosterAssignementEnd: null,
        rosterId: roster.id,
        rosterPosition: spot.position,
        rosterShiftLength: spot.duration,
        rosterStartTime: spot.start,
        scheduledShift: shift,
        scheduledWorkDays: null,
        scheduledKellyCycleNumber: null,
      }
      this.previousProfile = obj
      this.showModal = !this.showModal
    },
    profileDeleteTest(date){
      if(moment(date).isBefore(moment()) || this.userData.role == 'admin'){
        return true
      } return false
    },
    updateMultiSelectValue(data, id){
         // console.log(this.bulkHoldObject)
    this.bulkHoldObject.find(p => p.id == id).rosterId = this.getRosterIdFromApp(data.id)
    this.updateMultiSelect = false
    this.$nextTick(function(){
      this.updateMultiSelect = true
    })
    //console.log(this.bulkHoldObject[id])
    },
    unassignedUsersThatMeetRequirement(station, shift){
     return this.bulkHoldObject.filter(p => {
       return p.stationAssignment == station && p.shift == shift
     })


    },
    getUser(id){
   
      if(id){
        var user = this.deptContactData.find(p => p.id == id)
        if(user){
          return user.firstName + ' ' + user.lastName + ' (' + user.deptRank + ') #' + user.deptSpecialties
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getDateFormated(date){
      if(date){
        return moment(date).format('MM/DD/YYYY')
      } else {
       return 'continuous'
      }
    },
    rosterProfilesFind(id, shift, position){
      var rosters = this.profilesFilteredByDate.filter(p => {
        return p.rosterId == id && p.scheduledShift == shift && p.rosterPosition == position
            //   && moment(p.rosterAssignmentStart).isBefore(this.selectedDate, 'YYYY-MM-DD') &&
           //    p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate, 'YYYY-MM-DD') : true
      })
      if(rosters){
        return _.orderBy(rosters, ['rosterPosition'], ['asc'])
      } else {
        return []
      }

    },
    rosterProfilesFindWithoutPosition(id, shift, positions){
      var arrayPos = positions.map(p => p.position)
      var rosters = this.profilesFilteredByDate.filter(p => {
        return p.rosterId == id && p.scheduledShift == shift && arrayPos.indexOf(p.rosterPosition) == -1
            //   && moment(p.rosterAssignmentStart).isBefore(this.selectedDate, 'YYYY-MM-DD') &&
           //    p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.selectedDate, 'YYYY-MM-DD') : true
      })
      return rosters
    },
    rosterParents(data){
           var array = data
           var parents = [...new Set(array.map(p => p.stationParent))]
           var parentsObj = parents.map(item => item = this.stationsData.find(p => p.id == item))
          // parentsObj.forEach(item => item['showStation'] = true)
          //console.log(parentsObj)
           var parentsSort = _.orderBy(parentsObj, ['stationName'], ['asc'])
           this.currentParent = parentsSort[0] ? parentsSort[0].id : ""
           this.rosterParentsData = parentsSort
        },
    stationsFromParents(id){
      var array = this.rosterData.filter(p => p.stationParent == id)
      var stations = [...new Set(array.map(p => p.stationId))]
      var stationsObj = stations.map(item => item = this.stationsData.find(p => p.id == item))
      var stationsSort = _.orderBy(stationsObj, ['stationName'], ['asc'])   
      return stationsSort

    },
    returnRosterSpots(pid, sid){
      var array = this.rosterData.filter(p => { 
        return p.stationParent == pid && 
               p.stationId == sid 


        })
      array.forEach(item => item.rosterGroupType && item.rosterGroupType == 'variable' ? item['apparatusName'] = item.rosterName : 
                            item.apparatusId && this.apparatusData.find(p => p.id == item.apparatusId) != undefined ? item['apparatusName'] = this.apparatusData.find(p => p.id == item.apparatusId).apparatusName : 
                            item['apparatusName'] =  '') 
      var apparatusSort = _.orderBy(array, ['apparatusName'], ['asc'])   
      return apparatusSort
    },
        // user_id, roster_id, roster_spot_type, roster_position, roster_note, scheduled_shift, 
        // scheduled_kelly_cycle_number, roster_assignment_start, roster_assignment_end
    getRosterIdFromApp(id){
      var roster = this.rosterData.find(p => p.apparatusId == id)
      if(roster){
        return roster.id
      } else {
        return null
      }
    },
    editProfile(data){
    //  console.log(this.rosterSpotsFilter)
      this.previousProfile = _.cloneDeep(data)
      this.showModal = !this.showModal

     
    },
    updateUserBulkUser(){
        var roster = this.bulkHoldObject.filter(p => {
          return p.rosterId != ''
        })
        for(var i in roster){
        axios({
          method: 'post',
          url: '/api/v1/staffing/rosterprofile/',
          data: {
            userId: roster[i].id,
            rosterId: roster[i].rosterId,
            rosterSpotType: roster[i].rosterSpotType,
            rosterPosition: roster[i].rosterPosition,
            scheduledShift: roster[i].shift,
            scheduledKellyCycleNumber: roster[i].rosterKellyCycleNumber ? roster[i].rosterKellyCycleNumber : null,
            rosterAssignmentStart: moment(roster[i].rosterStartDate, 'YYYY-MM-DD').toDate(),
            rosterShiftLength: this.rosterShiftLength,
            rosterStartTime: this.rosterStartTime,
            scheduledWorkDays: roster[i].shift == 'Daily' ? this.scheduledWorkDays : null


            },
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
      //    console.log(response.data)
        })
        }
        this.$nextTick(function(){
          bus.$emit('getRosterProfile')
        })
    },
    updateProfileSubmit(data){
    axios({
     method: 'patch',
     url: '/api/v1/staffing/rosterprofile/' + data.id,
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
      this.showModal = false
      this.previousProfile = null
   })

    },
    deleteProfile(id){
          var result = confirm('Are You Sure You Want to Delete this Roster Profile?')
      if (result) {
    axios({
     method: 'delete',
     url: '/api/v1/staffing/rosterprofile/' + id,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
   })
      }
    },
    newProfileSubmit(data){
    axios({
     method: 'post',
     url: '/api/v1/staffing/rosterprofile/',
     data: data,
     headers: {
       'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
      bus.$emit('getRosterProfile')
      this.showModal = false
      this.previousProfile = null
   })

    },

    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
