<template>
            <b-col class="mt-2" v-show="!formcomp.groupMin"
                  :lg="getColumnClass(formsub.edit, formsub.visibilityEdit, formsub.labelPosition)">

                    <b-card header-tag="header" header-class="p-0" @mouseenter="mouseover = true" @mouseleave="mouseover = false"> 
                      <b-container slot="header">
                   <b-row>
                     <b-col align="left">
                         <b-badge class="mt-0" v-if="formsub.indexValue == true" variant="danger">i</b-badge>
                       <b-badge class="mt-0" v-if="formsub.tableView == true" variant="info">t</b-badge>
                       </b-col>
                     <b-col cols="*" v-if="editGroupId == formcomp.id" align="right" class="m-0 mr-0 pr-0">
                  <b-btn v-show="formsub.edit" :variant="mouseover == true ? 'outline-primary' : 'light'" title="Save" size="sm" @click="formsub.edit = false, formsub.visibilityEdit = false"> &#10004;</b-btn>
                  <b-btn v-show="!formsub.edit" :variant="mouseover == true ? 'outline-secondary' : 'light'" title="Edit" size="sm" @click="formsub.edit = true">	&#x270e;</b-btn>
                  <b-btn :variant="mouseover == true ? 'outline-info' : 'light'" :disabled="formcomp.groupComponents.length <= 1" title="Visibility" size="sm" @click="visibilityToggle(index, indexSub)">		&#x1f441;</b-btn>
                  <b-btn :variant="mouseover == true ? 'outline-primary' : 'light'" title="Duplicate" size="sm" @click.stop="duplicateItem(index, formsub)">&#10064;</b-btn>
                  <b-btn :variant="mouseover == true ? 'outline-danger' : 'light'" v-if="formsub.type != 'ref'" :disabled="formsub.refBy.length > 0" :title="getDeleteTitle(formsub.refBy.length)" size="sm" @click.stop="formcomp.groupComponents.splice(indexSub, 1)">&#10007;</b-btn>
                  <b-btn :variant="mouseover == true ? 'outline-danger' : 'light'" v-if="formsub.type == 'ref'" :disabled="formsub.refBy.length > 0" :title="getDeleteTitle(formsub.refBy.length)" size="sm" @click.stop="deleteItemRef(index, indexSub, formsub)">&#10007;</b-btn>
                    </b-col>
                   </b-row>
                   <b-row><b-col>{{ formsub.label }}
                                                         </b-col></b-row>
                   </b-container>
            <div v-if="formsub.edit == true">
              <b-container>
                <b-row><b-col cols="*"><div class="mt-2">Qustion/Item Title:</div></b-col><b-col><b-form-input v-model="formsub.label"  placeholder="Form Question or Item text" /></b-col></b-row>
                <b-row><b-col cols="*"><div class="mt-2">Width:</div></b-col><b-col>
                <b-form-select v-model="formsub.labelPosition" :options="sizeSelectOptions" /></b-col><b-col cols="*">
                <b-form-checkbox class="mt-2" v-if="formsub.type != 'ref'" v-model="formsub.required" :value="true" :unchecked-value="false">Required?</b-form-checkbox>
                <b-form-checkbox class="mt-2" :disabled="formsub.tableView == true ? false : tableCount >= 8 ? true : false" v-if="!(formsub.type == 'text' || formsub.type == 'textarea')" v-model="formsub.tableView" :value="true" :unchecked-value="false">Show In Table?</b-form-checkbox>
                 <b-form-checkbox class="mt-2" v-if="formsub.type == 'ref'" v-model="formsub.visibility" :value="true" :uncheck-value="false">Show Value on Form</b-form-checkbox>
                 <b-form-checkbox class="mt-2" :disabled="formsub.indexValue == true ? false : indexCount >= 6 ? true : false" v-if="!(formsub.type == 'text' || formsub.type == 'textarea' || formsub.type == 'signature')" v-model="formsub.indexValue" :value="true" :unchecked-value="false">Index Value (Searchable)</b-form-checkbox>
                 </b-col>
                </b-row>
                 </b-container>
                <b-row><b-col>
                
                </b-col><b-col cols="*" v-if="formsub.type == 'radio' || formsub.type == 'checkbox' || formsub.type == 'multiSelect' || formsub.type == 'singleSelect'">
                <b-dropdown :text="'Type (' + formsub.type + ')'" variant="outline-primary">
                  <b-dropdown-item-button :disabled="formsub.type == 'radio'" @click="formsub.type = 'radio'">Radio</b-dropdown-item-button>
                  <b-dropdown-item-button :disabled="formsub.type == 'checkbox'" @click="formsub.type = 'checkbox'">CheckBox</b-dropdown-item-button>
                  <b-dropdown-item-button :disabled="formsub.type == 'multiSelect'" @click="formsub.type = 'multiSelect'">MultiSelect</b-dropdown-item-button>
                  <b-dropdown-item-button :disabled="formsub.type == 'singleSelect'" @click="formsub.type = 'singleSelect'">SingleSelect</b-dropdown-item-button>
                </b-dropdown></b-col></b-row>
                <b-form-input v-if="formsub.type == 'text'" :id="formsub.label + indexSub" placeholder="Enter Text Box PlaceHolder" v-model="formsub.options[0].text"></b-form-input>
                <div v-if="formsub.type == 'textarea'">
                      <b-row><b-col>
                     <b-form-input placeholder="Enter Text Box PlaceHolder" :id="formsub.label + indexSub + '_'" v-model="formsub.options[0].text" /></b-col>
                     <b-col cols="auto"> <b-form-group label="Rows" horizontal><b-form-input type="number" placeholder="Rows" v-model="formsub.options[0].value" class="w-25" /></b-form-group></b-col></b-row>
                </div>
                <b-card size="sm" v-if="formsub.type == 'radio' || formsub.type == 'checkbox' || formsub.type == 'multiSelect' || formsub.type == 'singleSelect'">
                  <b-row><b-col>Text</b-col><b-col>Value</b-col><b-col cols="2">Default</b-col><b-col cols="2">Disabled</b-col><b-col cols="1"></b-col></b-row>
                  <b-row class="my-2" v-for="(option, indexOpt) in formsub.options" :key="indexOpt">
                    <b-col><b-form-input @change="option.value = option.text" v-model="option.text" placeholder="Select Option Text" /></b-col>
                    <b-col><b-form-input v-model="option.value" placeholder="Select Option Value" /></b-col>
                    <b-col cols="2"><b-form-checkbox v-model="formsub.value" @change="formsub.defaultValue = option.value, formsub.value = option.value" :value="option.value"></b-form-checkbox></b-col>
                    <b-col cols="2"><b-form-checkbox v-model="option.disabled" :value="true" :uncheck-value="false"></b-form-checkbox></b-col>
                    <b-col cols="1"><b-btn variant="danger" size="sm" @click.stop="formsub.options.splice(indexOpt, 1)">X</b-btn></b-col>
                    </b-row>
                    <b-btn class="w-50 mx-auto" block variant="primary" @click="formsub.options.push({ text: '', value: '', disabled: false })">Add Another Option</b-btn>
                </b-card>
                <b-card v-if="formsub.type == 'stationsData' || formsub.type == 'apparatusData' || formsub.type == 'deptContactData'">
                   <em>Selections will be stored in an array by the saved id of the item. When displayed in the table, only the items NAME will be visible and searchable,
                        if you would like more data to be shown in the table, only single select items can be used.</em><br>
                    <b-form-checkbox v-model="formsub.options[0].multiselect"  :disabled="formsub.defaultValueLoggedUser" :value="true" :uncheck-value="false">Allow for multiple selections/tags</b-form-checkbox>
                  <b-dropdown :disabled="formsub.options[0].multiselect == true" text="Add Reference Value" variant="outline-primary">
                  <b-dropdown-item-button v-for="(ref, indexRef) in getKeys(formsub.type )" @click="addReferenceItem(index, formsub, ref, indexSub)" :key="indexRef" >{{ ref }}</b-dropdown-item-button>
                </b-dropdown> 
                 </b-card> 
               <div>
                  <b-card-group deck v-if="formsub.type == 'stationsData' || formsub.type == 'apparatusData' || formsub.type == 'deptContactData'">
                    <b-card header="Filter Available Options">
                      <div v-for="(filter, indexFilter) in formsub.filterOpt" :key="indexFilter"><b-card>
                        <b-row><b-col>
                        <b-form-select v-model="filter.filterKey" :options="getFilterOptions(formcomp.trackedType)" />
                        <b-form-checkbox v-model="filter.multiple" @input="filter.multiple == true ? filter.filterValue = [] : filter.filterValue = ''" :value="true" :uncheck="false">Filter by Multiple Options?</b-form-checkbox>
                        <b-form-select v-if="filter.filterKey" :multiple="filter.multiple" v-model="filter.filterValue" :options="getFilterValue(formcomp.trackedType, filter.filterKey)" /></b-col>
                        <b-col cols="*"><b-btn variant="danger" size="sm" @click.stop="formsub.filterOpt.splice(indexFilter, 1)">X</b-btn></b-col></b-row>
                          </b-card>
                        </div>
                        <b-btn variant="primary" :disabled="formsub.defaultValueLoggedUser" @click="formsub.filterOpt.push({filterKey: '', filterValue: ''})">Add Filter</b-btn>
                      </b-card>
                    <b-card header="Additional Options">
                      <b-form-checkbox v-if="formsub.type == 'deptContactData'" v-model="formsub.defaultValueLoggedUser" @change="formsub.options[0].multiselect = false, formsub.filterOpt = []" :value="true" :unchecked-value="false" >Default To Logged In User</b-form-checkbox>
                      <b-form-checkbox v-if="formsub.type == 'deptContactData'"
                       v-model="formsub.requireSignature"  @change="formsub.requireSignature == false ? addReferenceItem(index, formsub, 'signature', indexSub) : deleteItemRefSig('signature')" :value="true" :unchecked-value="false" >Require Signature?</b-form-checkbox>
                    </b-card>
                    </b-card-group>
                  </div>
            </div>
            <div v-if="formsub.edit == false">
              <b-row v-if="editGroupId == formcomp.id"><b-col>
                <b-form-input type="number" v-if="formsub.type == 'number'" v-model="formsub.value" />
                  <b-form-input type="date" v-if="formsub.type == 'date'" v-model="formsub.value" />
                  <b-row><b-col>
                    <b-form-input type="date" v-if="formsub.type == 'dateTime'" v-model="formsub.value.date" /></b-col><b-col>
                           <b-form-input type="time" v-if="formsub.type == 'dateTime'" v-model="formsub.value.date" /></b-col></b-row>
                <b-form-input v-if="formsub.type == 'text'" v-model="formsub.value" :placeholder="formsub.options[0].text" />
                <b-radio-group  v-if="formsub.type == 'radio'" v-model="formsub.value" :options="formsub.options" />
                <b-form-checkbox-group v-if="formsub.type == 'checkbox'" v-model="formsub.value" :options="formsub.options" />
                <b-textarea v-if="formsub.type == 'textarea'" v-model="formsub.value" :placeholder="formsub.options[0].text" 
                        :rows="formsub.options[0].value" />
                 <b-form-select v-if="formsub.type == 'multiSelect'" multiple :select-size="4" v-model="formsub.value" :options="formsub.options">
                 </b-form-select>
                 <b-form-select v-if="formsub.type == 'singleSelect'" v-model="formsub.value" :options="formsub.options">
                 </b-form-select>
               <multiselect  v-if="formsub.type == 'stationsData' || formsub.type == 'apparatusData' || formsub.type == 'deptContactData'" :class="{errorClass: formsub.value == ''}" v-model="formsub.value" :options="getDataFormReturn(formsub.type)" :multiple="formsub.options[0].multiselect" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please Make a Selection" :label="getLabel(formsub.type)" :track-by="getLabel(formsub.type)">
                  <template v-if="formsub.type == 'stationsData'" slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
                 <template v-if="formsub.type == 'apparatusData'" slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.apparatusName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
                <template  v-if="formsub.type == 'deptContactData'" slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
                </multiselect>
                <b-form-input disabled v-show="formsub.visibility" v-if="formsub.type == 'ref'" v-model="formsub.value" :placeholder="getRefValue(index, formsub.options)" />
                </b-col></b-row>
                  <b-card v-if="formsub.visibilityEdit == true">
                    <div v-for="(vis, indexVis) in formsub.visibilitySettings" :key="indexVis">
                      <b-row><b-col>
                    <b-form-select v-model="vis.item" :options="getVisibilityOptions(formcomp.groupComponents, indexSub)" /></b-col><b-col>
                    <b-form-select v-model="vis.modifier" v-if="vis.item != ''" :options="getVisSelectOptions(formcomp.groupComponents, vis.item)" /></b-col><b-col>
                    <b-form-select v-if="vis.modifier != ''" :disabled="vis.modifier == 'blank' || vis.modifier == 'notBlank'" :multiple="vis.modifier == 'contains'" v-model="vis.value" :options="getOptionsForSelectedVis(formcomp.groupComponents, vis.item)" />  
                      </b-col>
                      <b-col cols="*">  <b-btn variant="danger" size="sm" @click="formsub.visibilitySettings.splice(indexVis, 1)" >X</b-btn>
                      </b-col></b-row>

                      </div>
                    <b-btn block class="w-50 mx-auto" variant="primary" size="sm" @click="formsub.visibilitySettings.push({item: '', modifier: '', value: ''})" >Add New Visibility Rule</b-btn>
                  </b-card>
            <div v-if="editGroupId != formcomp.id" >
                  <div v-show="visibilitySettingsCheck(formcomp.groupComponents, formsub.visibilitySettings)"> 
                  <b-form-input type="number" v-if="formsub.type == 'number'" v-model="formsub.value" />
                  <b-form-input type="date" v-if="formsub.type == 'date'" v-model="formsub.value" />
                  <b-row><b-col>
                    <b-form-input type="date" v-if="formsub.type == 'dateTime'" v-model="formsub.value.date" /></b-col><b-col>
                           <b-form-input type="time" v-if="formsub.type == 'dateTime'" v-model="formsub.value.time" /></b-col></b-row>
                <b-form-input v-if="formsub.type == 'text'" v-model="formsub.value" :placeholder="formsub.options[0].text" />
                <b-radio-group  v-if="formsub.type == 'radio'" v-model="formsub.value" :options="formsub.options" />
                <b-form-checkbox-group v-if="formsub.type == 'checkbox'" v-model="formsub.value" :options="formsub.options" />
                <b-textarea v-if="formsub.type == 'textarea'" v-model="formsub.value" :placeholder="formsub.options[0].text" 
                        :rows="formsub.options[0].value" />
                 <b-form-select v-if="formsub.type == 'multiSelect'" multiple :select-size="4" v-model="formsub.value" :options="formsub.options">
                 </b-form-select>
                 <b-form-select v-if="formsub.type == 'singleSelect'" v-model="formsub.value" :options="formsub.options">
                 </b-form-select>
               <multiselect  v-if="formsub.type == 'stationsData' || formsub.type == 'apparatusData' || formsub.type == 'deptContactData'" :class="{errorClass: formsub.value == ''}" v-model="formsub.value" :options="getDataFormReturn(formsub.type)" :multiple="formsub.options[0].multiselect" :close-on-select="false" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please Make a Selection" :label="getLabel(formsub.type)" :track-by="getLabel(formsub.type)">
                  <template v-if="formsub.type == 'stationsData'" slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
                 <template v-if="formsub.type == 'apparatusData'" slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.apparatusName }}</span><span class="custom__remove" @click="props.remove(props.option)">X</span></span></template>
                <template  v-if="formsub.type == 'deptContactData'" slot="option" slot-scope="props">
                    <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} ({{ props.option.employeeId }})</span>
                  </template>
                </multiselect>
                <b-form-input disabled v-show="formsub.visibility" v-if="formsub.type == 'ref'" v-model="formsub.value" :placeholder="getRefValue(index, formsub.options)" />
               </div>
                </div>
              </div>
              </b-card>
             </b-col>
</template>

<script>
/**               <div  v-for="(formsub, indexSub) in formcomp.groupComponents" :key="indexSub">
             <formsub :formsubdata="formsub" :indexSub="indexSub" :index="index" 
             @duplicate="duplicateItem($event)" />
             </div> */
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
export default {
props: ["formsub", "index", "indexSub", "formcomp", "editGroupId", "tableCount", "indexCount"],
components: {
        'multiselect' : Multiselect,
},
data() {
    return {
      mouseover: false,
      formGroupName: '',
      formQuestion: '',
      sizeSelectOptions: [
        { text: '100%', value: 12},
        { text: '75%', value: 9},
        { text: '50%', value: 6},
        { text: '25%', value: 3},
      ],
      postData: [],
      tableData: [],
      savedFormData: [],
      textData: [],
      tableDataFields: [],
      visSelectOpt: [
        { text: "Equals", value: '=='},
        { text: "Not Equal", value: '!='},
        { text: "Not Blank", value: 'notBlank' },
        { text: "Is Blank", value: 'blank'},
        { text: "Contains", value: 'contains'}
      ],
      showPostDataArray: false,
      showComponentArray: false,
      trackedObj: '',
}
},
created () {
 this.trackedObj = _.cloneDeep(this.formsub)
 console.log(this.formsub)
},
beforeDestroy(){
  
},
watch: {
 
},
computed: {
  ...mapState({
    deptartmentBlogs: 'departmentBlogs',
    userData: 'userData',
    getSessionData: 'getSessionData',
    stationsData: 'stationsData',
    apparatusData: 'apparatusData',
    deptContactData: 'deptContactData',
    shiftAssignment: 'shiftsData',
    vehicleCheckDetails: 'vehicleCheckDetails',
    vehicleCheckItems: 'vehicleCheckItems',
    workgroupData: 'workgroupData',
    vehicleTypeData: 'vehicleTypeData',
    deptRank: 'departmentRanks',
    deptSpecialties: 'departmentSpecialties'
  }),
  checkForChanges(){
    var check = _.isEqual(this.formsub, this.trackedObj)
    return check
  }

},
mounted(){
},  
watch:{
  
  checkForChanges(data){
    this.$emit('updateChangesToSubs', data)
    }
  

},
methods: {
    toggleEditId(data){
      this.formSub.edit = true
      this.$emit('editGroupId', data)
    },
    getFilterOptions(type){
    var array = this[type]
    var obj = array[0]
    var keys = Object.keys(obj)
    var index = keys.indexOf('id')
    if(index >= 0){
      keys.splice(index, 1)
    }
    var array2 = []
    for(var i = 0; i < keys.length; i++){
        array2.push({text: keys[i], value: keys[i]})
    }
    return array2
  },
  getFilterValue(type, key){
    var array = this[type]
    var newArray = [... new Set(array.map(p => p[key]))]
    var percent = (newArray.length / array.length)
    if(percent > 0.75){
      var state = this[key]
      if(state != undefined && state != null && state.length > 0){
      var keys = Object.keys(state[0])
      var index = keys.indexOf('id')
      if(index >= 0){
      keys.splice(index, 1)
      }
      if(keys.length == 1){
        var newArray = [... new Set(state.map(p => p[keys]))]
        return newArray
      } else {
        return [{ text: 'Invalid Filter Option', value: '', disabled: true}]
      }
      
      } else {
        return [{ text: 'Invalid Filter Option', value: '', disabled: true}]
      }
    } else {
    return newArray
    }
  },
  getColumnClass(edit, vis, cols){
    if(edit == false && vis == false){
      return cols
    } else {
      return 12 
    }
  },
  visibilitySettingsCheck(array, data){
    var operators = {
    '==': function(a, b) { return a == b },
    '!=': function(a, b) { return a != b },
    'notBlank': function(a, b) { return a != ''},
    'blank': function(a, b) { return a == ''},
    'contains': function(a, b){
                      var item = b.filter( p => {
                        return p == a
                      })
                      if(item.length > 0){
                        return true
                      } else {
                        return false
                      }
                      }
    }  
    var booArray = []
    if(data != undefined && data.length > 0){
      for(var i = 0; i < data.length; i++){
        var item = array.filter(p => {
          return p.id == data[i].item
        })       
       if(item[0] != undefined && item[0] != null){
          var boo = operators[data[i].modifier](item[0].value, data[i].value)
          booArray.push(boo)
        }
       }
    var index = booArray.indexOf(false)
    if(index < 0){
      return true
    } else {
      return false
    }
      
    } else {
    return true
    }
  

  },
  getVisibilityOptions(array, index){
    var data = [... new Set(array)]
    data.splice(index, 1)
    var items = data
    var options = []
    for(var i = 0; i < items.length; i++){
      var string = {text: items[i].label, value: items[i].id}
      options.push(string)
    } 
    return options
  },
  getVisSelectOptions(array, data){
    var array = [... new Set(array.map(p => p))]
    var item = array.filter(p => {
      return p.id == data
    })
    if(item[0].type == 'text' || item[0].type == 'textarea'){
      return [ { text: "Not Blank", value: 'notBlank' },
        { text: "Is Blank", value: 'blank'},]
    } else {
      return this.visSelectOpt
    }
  },
  getOptionsForSelectedVis(array, data){
    var array = [... new Set(array.map(p => p))]
    var item = array.filter(p => {
      return p.id == data
    })
    return item[0].options
  },
  visibilityToggle(index, indexSub){
    var vis = this.formcomp.groupComponents[this.indexSub].visibilityEdit
    var type = this.formcomp.groupComponents[this.indexSub].visibility
    if(vis == false && type == true){
      this.formcomp.groupComponents[this.indexSub].visibility = 'variable'
      this.formcomp.groupComponents[this.indexSub].visibilityEdit = true
      this.formcomp.groupComponents[this.indexSub].edit = false
    } else if (vis == true && type == 'variable'){
      var data = this.formcomp.groupComponents[this.indexSub].visibilitySettings
      if(data.length == 0){
        this.formcomp.groupComponents[this.indexSub].visibility = true
        this.formcomp.groupComponents[this.indexSub].visibilityEdit = false
      }  else {
        this.formcomp.groupComponents[this.indexSub].visibilityEdit = false
      }
    } else if (vis == false && type == 'variable'){
        this.formcomp.groupComponents[this.indexSub].visibilityEdit = true
        this.formcomp.groupComponents[this.indexSub].edit = false
        } else {
          this.formcomp.groupComponents[this.indexSub].visibilityEdit = false
        }
  },
  getDeleteTitle(num){
    if(num > 0){
      return 'Disabled due to Ref'
    } else {
      return 'Delete'
    }
  },
  getRefValue(index, ref){
    var item = this.formcomp.groupComponents
    var data = item.filter(p => {
      return p.id == ref.itemId
    })
    if(data[0].value != undefined){
      var datai = data[0].value
      return datai[ref.refItem]
    }
  },
  getDataFormReturn(data){
    return this[data]
  },
  getLabel(data){
    if(data == 'stationsData'){
      return 'stationName'
    } else if ( data == 'apparatusData') {
      return 'apparatusName'
    } else {
      return 'lastName'
    }
  },
  getKeys(data){
    var array = this[data]
    if(array.length > 0 ){
      var item = array[0]
    } else {
      return
    }
  var keys =  Object.keys(item)
  var index = keys.indexOf("id")
  if(index >= 0){
  keys.splice(index, 1)
  }
  var index2 = keys.indexOf("createdAt")
    if(index2 >= 0){
  keys.splice(index2, 1)
  }
  var index3 = keys.indexOf("createdAt")
    if(index3 >= 0){
  keys.splice(index2, 1)
  }
  return keys
  },

  addReferenceItem(index, item, ref, subIndex){
    var id = item.id
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    var label = ref.toString()
    if(ref == 'signature'){
      var type = 'signature'
    } else {
      var type = 'ref'
    }
    var newArrayItem = { 
                id: id,
                label: label,
                labelPosition: 12,
                type: type, //Radio, input, checkbox, multiselect, singleselect, textarea, 
                value: '',
                defaultValue: '',
                defaultValueLoggedUser: false,
                required: false,
                tableTracked: false,
                indexValue: false,
                options: {itemId: item.id, refItem: ref},
                visibility: true,
                visibilityEdit: false,
                visibilityOpt: [],
                visibilitySettings: [],
                refBy: [],
                edit: true,    
                        }  
    var newArrayItem = _.cloneDeep(newArrayItem)
    this.formcomp.groupComponents.push(newArrayItem)
   this.formcomp.groupComponents[subIndex].refBy.push(newArrayItem.id)
    
  },
    duplicateItem(index, obj){
    var newArrayItem = _.cloneDeep(obj)
    newArrayItem.edit = true
    var id = newArrayItem.id
    var length = 15
    var id = id + Math.random().toString(36).substr(2, length)
    var id = _.uniqueId(id)
    newArrayItem.id = id
      this.formcomp.groupComponents.push(newArrayItem)
  },
    deleteItemRef(index, obj, formsub){
    var array = [... new Set(this.formcomp.groupComponents.map(p => p.id))]
    var refIndex = array.indexOf(formsub.options.itemId)
    var refIdex = this.formcomp.groupComponents[refIndex].refBy.indexOf(formsub.id)
     this.formcomp.groupComponents[refIndex].refBy.splice(refIdex, 1)
    var refIdex = this.formcomp.groupComponents.indexOf(formsub.options.itemId)
    this.formcomp.groupComponents.splice(obj, 1)
  },
  deleteItemRefSig(item){
    var refIndex = this.formcomp.groupComponents.map(p => p.type).indexOf(item)
    if(refIndex >= 0){
    this.formcomp.groupComponents.splice(refIndex, 1)
    }
  }
},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}

</style>
