import Vue from 'vue'
import es6Promise from 'es6-promise'
import Vuex from 'vuex'
import axios from 'axios'
import _ from 'lodash'
import local from 'localforage'
import moment from 'moment'
import VueCookie from 'vue-cookies'
import { stat } from 'fs';
es6Promise.polyfill()

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    configGet: {
      headers: { 'content-type': 'application/json' }
    },
    configGetLogout: {
      headers: { 'content-type': 'text/html' }
    },
    userAutho: '',
    getSessionData: [],
    getSessionDataX: [],
    apparatusData: [],
    siteconfig: {},
    stationsData: [],
    shiftsData: [],
    eventTags: [],
    dailyNarcs: [],
    dailyNarctracks: [],
    appratusData: [],
    calendarEvents: [],
    vehicleChecks: [],
    stationLogbooks: [],
    stationRepair: [],
    userPosts: [],
    loadApplication: 0,
    userData: [],
    documentsApi: [],
    departmentFeed: [],
    departmentFeedUser: [],
    testStationLogbook: [],
    config: [],
    loginStatus: 0,
    userTagList: '',
    allevent: [],
    alldailyvehicle: [],
    alllogbook: [],
    allrepairform: [],
    allnarctrack: [],
    stationCalEvents: [],
    stationCalVehicleChecks: [],
    stationCalLogbooks: [],
    stationCalInventorySub: [],
    stationCalRepair: [],
    stationCalImages: [],
    stationCalStatistics: [],
    apparatusAppNarcs: [],
    apparatusDailyAppNarcs: [],
    deptContactData: [],
    fullDeptContactData: [],
    bannerImages: [],
    departmentBlogs: [],
    parentGroup: [],
    fullCalendarEvents: [],
    fullCalendarEventsPrivate: [],
    repairStatus: [],
    documentTags: [],
    blogType: [],
    inventoryTemplateData: [],
    departmentRanks: [],
    departmentSpecialties: [],
    medicationData: [],
    formTypeData: [],
    departmentTutorials: [],
    trainingLocations: [],
    trainingCats: [],
    vehicleCheckDetails: [],
    vehicleCheckItems: [],
    userMessages: [],
    unreadMessages: [],
    roomData: [],
    unreadRoomMessages: [],
    groupData: [],
    unreadGroupMessages: [],
    workgroupData: [],
    trainingrefData: [],
    trainingItemData: [],
    vehicleTypeData: [],
    refreshToken: '',
    formtempData: [],
    notificationData: [],
    activeUsers: [],
    updateTable: null,
    payrollAccuralData: [],
    payrollCodesData: [],
    payrollBanksData: [],
    rosterData: [],
    rosterProfiles: [],
    trainingDrills: [],
    payrollAccuralAssignedData: [],
    medsNarctrack: [],
    logisticAssetData: [],
    cacheNameFix: { 'station': 'station', 'vehicletype': 'vehicle_type', 'tutorial': 'tutorial', 'apparatus': 'apparatus', 'trainingref': 'training_ref',
      'blogtype': 'blog_types', 'checkdetails': 'check_details', 'checkitems': 'check_items', 'deptrank': 'dept_rank', 'deptspec': 'dept_spec',
      'eventtype': 'event_type', 'medication': 'medication', 'workgroup': 'work_group', 'repairstatus': 'repair_status', 'shiftrotation': 'shift_rotation', 'logisticasset': 'logistic_asset' }

  },
  actions: {
    LOAD_USER_DATA_STORE ({ commit }, userData) {
      return new Promise((resolve, reject) => {
        var base = '/api/v1/auth/login'
        axios.post(base, userData).then(
          (response) => {
            commit('SET_USER_DATA_STORE', { list: response.data })
            commit('SET_SESSION_TOKEN', { list: response.data.token })
            resolve(response.data)
          }).catch((error) => {
          reject(error.response.data)
        })
      })
    },
    LOGOUT_OUT_USER ({ commit }, token) {
      var list = []
      commit('SET_CLEAR_USER_MESSAGES', { list })
      list = ''
      commit('SET_LOGIN_STATUS', { list: 0 })
      Vue.nextTick(function () {
        commit('SET_SESSION_TOKEN', { list: { accessToken: '' } })
        commit('SET_USER_DATA_STORE', { list })
      })
    },
    LOGIN_WITH_REFRESH_TOKEN ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/api/v1/auth/refresh-token',
          data: {
            'email': payload.email,
            'refreshToken': payload.refreshToken
          },
          headers: {
            'Content-Type': 'application/json'
          } })
          .then((response) => {
            var data = { email: payload.email, refreshToken: response.data.refreshToken }
            dispatch('LOAD_LOGIN_PERSIST', data)
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          })
      })
    },
    SET_USER_LOGIN_STATUS ({ commit }) { commit('SET_LOGIN_STATUS', { list: 1 }) },
    SET_BASIC_AUTH_ACTION ({ commit }, basicAuth) { commit('SET_BASIC_AUTH', { list: basicAuth }) },
    LOAD_LOGIN_STATUS ({ commit }, token) {
      //  console.log('TOKEN')
      //     console.log(token)
      var headersConfig = { headers: { 'content-type': 'application/json', 'Authorization': 'bearer ' + token } }
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/users/profile', headersConfig)
          .then((response) => {
            commit('SET_LOGIN_STATUS', { list: 1 })
            commit('SET_USER_DATA_STORE', { list: { user: response.data } })
            commit('SET_SESSION_TOKEN', { list: { accessToken: token } })
            resolve(response.data)
          }).catch((error) => {
            local.getItem('LoginPersist').then(function (value) {
              if (value) {
                console.log('LoginPersist')
                //      console.log(value)
                reject(value)
              } else {
                var value = undefined
                reject(value)
              }
            }), (err) => {
              console.log(err)
            }
          }), (err) => {
          console.log(err)
        }
      })
    },
    SET_SUPER_USER_ROLE ({ commit }, data) {
      var list = data
      commit('UPDATE_SUPER_USER_ROLE', { list })
    },
    LOAD_LOGIN_PERSIST ({ commit }, data) {
      var list = data
      commit('SET_LOGIN_PERSIST', { list })
    },
    LOAD_SITE_CONFIG ({ commit }, list) {
      axios.get('/api/v1/config')
        .then(response => {
          commit('SET_SITE_CONFIG', { list: response.data })
        })
    },
    LOAD_SESSION_TOKEN ({ commit }, token) {
      var list = token
      commit('SET_SESSION_TOKEN', { list })
    },
    LOAD_SESSION_XTOKEN ({ commit }, Xtoken) {
      var list = Xtoken
      commit('SET_SESSION_XTOKEN', { list })
    },
    LOAD_INVENTORY_TEMPLATES ({ commit }, payload) {
      axios.get('/api/v1/taxonomy/inventorytemplate/', payload.headers)
        .then(response => {
          commit('SET_INVENTORY_TEMPLATES', { list: response.data })
        })
    },
    LOAD_TRAINING_MODULES ({ commit }, payload) {
      axios.get('/api/v1/training/trainingmodule/', payload.headers)
        .then(response => {
          commit('SET_TRAINING_MODULES', { list: response.data })
        })
    },
    async LOAD_MEDICATION_DATA ({ commit }, payload) {
      const response = await axios.get('/api/v1/taxonomy/mednarctrack/', payload.headers)
      commit('SET_MEDICATION_DATA', { list: response.data })
      return response.data
    },
    LOAD_STAFFING_DATA_EXP ({ commit, dispatch, state }, payload) {
      //   console.log(state.updateTable)
      var commitType = 'SET_STAFFING_' + payload.urlParam.toUpperCase()
      local.getItem(payload.urlParam + '_expire').then(function (value) {
        if (value) {
          var unix = moment().unix()
          var time = moment(unix).isBefore(value)
          if (state.updateTable) {
            var obj = state.updateTable.find(p => p.table_name == payload.urlParam)
            if (obj) {
              var server = moment(unix, 'X').isAfter(obj.updated_at)
            } else {
              var server = true
            }
          } else {
            var server = true
          }
        } else {
          var time = false
        }
        local.getItem(payload.urlParam).then(function (value) {
          if (value && payload.expire != true && time == true && server == true) {
            commit(commitType, { list: value, type: payload.urlParam })
          } else {
            axios.get('/api/v1/staffing/' + payload.urlParam, payload.headers)
              .then((response) => {
            //    console.log('TAX DATA' + payload.urlParam)
                var type = payload.urlParam
                var data = response.data
                commit(commitType, { list: data, type: type })
                commit('SET_LOCAL_FORGE_DATA', { list: data, type: type })
              }), (err) => {
              console.log(err)
              if (err.response.data.code == 401) {
                dispatch('LOGOUT_OUT_USER')
              }
            }
          }
        }).catch(function (err) {
          // This code runs if there were any errors
          console.log(err)
          axios.get('/api/v1/staffing/' + payload.urlParam, payload.headers)
            .then((response) => {
              var type = payload.urlParam
              var data = response.data
              commit(commitType, { list: data, type: type })
              commit('SET_LOCAL_FORGE_DATA', { list: data, type: type })
            })
        })
      }).catch(function (err) {
        console.log(err)
      })
    },
    /** INITIAL USER LOGIN LOAD **/
    DELETE_LOCAL_DATA ({ commit, state }) {
      commit('SET_UPDATE_TABLE', { list: [] })
      local.clear()
    },
    LOAD_TAXONOMY_DATA_EXP ({ commit, dispatch, state }, payload) {
      //   console.log(state.updateTable)
      var commitType = 'SET_TAX_' + payload.taxonomy.toUpperCase()
      local.getItem(payload.taxonomy + '_expire').then(function (value) {
        if (value) {
          var unix = moment().unix()
          var time = moment(unix).isBefore(value)
          if (state.cacheNameFix[payload.taxonomy] && state.updateTable) {
            var obj = state.updateTable.find(p => p.table_name == state.cacheNameFix[payload.taxonomy])
            if (obj) {
              var server = moment(unix, 'X').isAfter(obj.updated_at)
            } else {
              var server = true
            }
          } else {
            var server = true
          }
        } else {
          var time = false
        }
        local.getItem(payload.taxonomy).then(function (value) {
          if (value && payload.expire != true && time == true && server == true) {
            commit(commitType, { list: value, type: payload.taxonomy })
          } else {
            axios.get('/api/v1/taxonomy/' + payload.taxonomy, payload.headers)
              .then((response) => {
                console.log('TAX DATA' + payload.taxonomy)
                var type = payload.taxonomy
                var data = response.data
                commit(commitType, { list: data, type: type })
                commit('SET_LOCAL_FORGE_DATA', { list: data, type: type })
              }), (err) => {
              console.log(err)
              if (err.response.data.code == 401) {
                dispatch('LOGOUT_OUT_USER')
              }
            }
          }
        }).catch(function (err) {
          // This code runs if there were any errors
          console.log(err)
          axios.get('/api/v1/taxonomy/' + payload.taxonomy, payload.headers)
            .then((response) => {
              var type = payload.taxonomy
              var data = response.data
              commit(commitType, { list: data, type: type })
              commit('SET_LOCAL_FORGE_DATA', { list: data, type: type })
            })
        })
      }).catch(function (err) {
        console.log(err)
      })
    },
    CLEAR_USER_MESSAGES ({ commit }) {
      var list = []
      commit('SET_CLEAR_USER_MESSAGES', { list })
    },
    CLEAR_USER_POSTS ({ commit }) {
      var list = []
      commit('SET_CLEAR_USER_POSTS', { list })
    },
    LOAD_USER_POSTS_EXP ({ commit, dispatch }, payload) {
      axios.get('/api/v1/content/' + payload.contentType + '/user/' + payload.id, payload.headers)
        .then((response) => {
          commit('SET_USER_POSTS', { list: response.data, contentType: payload.contentType })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
      /* axios.get('/api/v1/content/' + payload.contentType, payload.headers).
              then((response) => { commit('SET_ALL_POSTS', { list: response.data, contentType: payload.contentType })
              dispatch('CHECK_RESPONSE_CODE', response.status)}, (err) => { console.log(err)
                dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
                }) */
    },
    LOAD_TABLE_UPDATE_DATA ({ commit }, payload) {
      axios.get('/api/v1/users/updatetable', payload)
        .then((response) => { commit('SET_UPDATE_TABLE', { list: response.data }) }, (err) => {
          console.log(err)
        })
    },
    LOAD_USER_NOTIFICATIONS_EXP ({ commit }, payload) {
      axios.get('/api/v1/content/notification/user/' + payload.id, payload.headers)
        .then((response) => { commit('SET_NOTIFICATION_DATA', { list: response.data }) }, (err) => {
          console.log(err)
        })
    },
    LOAD_CONTACT_DATA_EXP ({ commit, dispatch }, token) {
      axios.get('/api/v1/users', token)
        .then((response) => {
          commit('SET_CONTACT_DATA', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_FORMTEMP_EXP ({ commit }, headers) {
      axios.get('/api/v1/forms/formtemp', headers)
        .then((response) => { commit('SET_FORMTEMP_DATA', { list: response.data }) }
          , (err) => {
            console.log(err)
          })
    },
    /** Content DATA PULL */
    LOAD_STATION_CONTENT_EXP ({ commit, dispatch }, payload) {
      var commitType = 'SET_CAL_' + payload.contentType.toUpperCase()
      // if (payload.contentType == 'event') {
      if(payload.contentType != 'inventorysub'){
      payload.stationVar = payload.stationVar + payload.query
      } else {
        payload.stationVar = payload.logisticsIds + payload.query
      }
      // }
      axios({
        method: 'get',
        url: '/api/v1/content/' + payload.contentType + '/' + payload.urlParam + '/' + payload.stationVar,
        headers: payload.headers.headers }
      )
        .then((response) => {
          commit(commitType, { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          commit(commitType, { list: '' })
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_APPARATUS_CONTENT_EXP ({ commit, dispatch }, payload) {
    // console.log(payload)
      var commitType = 'SET_CAL_' + payload.contentType.toUpperCase()
  
      if (payload.contentType == 'event' || payload.contentType == 'narctrack' || payload.contentType == 'dailynarctrack') {
        payload.stationVar = payload.stationVar + payload.query
      }
      if (payload.contentType == 'dailyvehicle' || payload.contentType == 'repairform') {
        var location = 'vehiclecheck'
      } else {
        var location = 'apparatus'
      }
      if (payload.dbID) {
        var endstring = payload.dbID
      } else {
        var endstring = payload.stationVar
      }
      axios.get('/api/v1/content/' + payload.contentType + '/' + location + '/' + endstring, payload.headers)
        .then((response) => {
          commit(commitType, { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          commit(commitType, { list: '' })
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_DEPT_BLOG_EXP ({ commit, dispatch }, payload) {
      axios.get('/api/v1/content/blog', payload.headers)
        .then((response) => {
          commit('SET_DEPT_BLOG', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_BANNER_IMAGES_EXP ({ commit, dispatch }, payload) {
      axios.get('/api/v1/content/banner', payload.headers)
        .then((response) => {
          commit('SET_BANNER_IMAGES', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_DOCUMENTS_API_EXP ({ commit, dispatch }, payload) {
      axios.get('/api/v1/content/document', payload)
        .then((response) => {
          commit('SET_DOCUMENT_API', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_TRAINING_ITEM_API_EXP ({ commit, dispatch }, payload) {
      axios.get('/api/v1/content/trainingitem', payload)
        .then((response) => {
          commit('SET_TRAINING_ITEM_API', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },

    /** CHAT DATA */
    LOAD_ACTIVE_USERS ({ commit, dispatch }, payload) {
      axios.get('/api/v1/users/active', payload.headers)
        .then((response) => {
          commit('SET_ACTIVE_USERS', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_USER_MESSAGES_EXP ({ commit, dispatch, state }, payload) {
     // console.log(state.getSessionData)
      axios.get('/api/v1/chat', payload.headers)
        .then((response) => {
          commit('SET_USER_MESSAGES', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    NEW_MESSAGE_TRACKING ({ commit }, msg) { commit('SET_MESSAGE_TRACKING', { list: msg }) },
    REMOVE_MESSAGE_TRACKING ({ commit }, msg) { commit('REMOVE_SET_MESSAGE_TRACKING', { list: msg }) },

    LOAD_ROOMS_EXP ({ commit, dispatch }, payload) {
      axios.get('/api/v1/chat/room', payload.headers)
        .then((response) => {
          commit('SET_ROOM_DATA', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    NEW_ROOM_MESSAGE_TRACKING ({ commit }, msg) { commit('SET_ROOM_MESSAGE_TRACKING', { list: msg }) },
    REMOVE_ROOM_MESSAGE_TRACKING ({ commit }, msg) { commit('REMOVE_SET_ROOM_MESSAGE_TRACKING', { list: msg }) },

    LOAD_GROUP_MESSAGES_EXP ({ commit, dispatch }, payload) {
      axios.get('/api/v1/chat/group', payload.headers)
        .then((response) => {
          commit('SET_GROUP_MESSAGE_DATA', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    NEW_GROUP_MESSAGE_TRACKING ({ commit }, msg) { commit('SET_GROUP_MESSAGE_TRACKING', { list: msg }) },
    REMOVE_GROUP_MESSAGE_TRACKING ({ commit }, msg) { commit('REMOVE_GROUP_ROOM_MESSAGE_TRACKING', { list: msg }) },

    /** Full Calendar Data Pull */
    LOAD_FULL_CAL_EVENTS_EXP_PRIVATE ({ commit, dispatch }, payload) {
      var url = '/api/v1/content/event/private/' + payload.id
      axios({
        method: 'get',
        url: url,
        headers: payload.headers.headers })
        .then((response) => {
          commit('SET_FULL_CAL_EVENTS_PRIVATE', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    LOAD_FULL_CAL_EVENTS_EXP ({ commit, dispatch }, payload) {
      var url = '/api/v1/content/event/range/' + payload.dateRange
      axios({
        method: 'get',
        url: url,
        headers: payload.headers.headers })
        .then((response) => {
          commit('SET_FULL_CAL_EVENTS', { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },

    /** Content Secondary Menu Pull */
    LOAD_SEC_DATA_EXP ({ commit, dispatch }, payload) {
      var commitType = 'SET_SEC_' + payload.contentType.toUpperCase()
      if (payload.contentType == 'event' || payload.contentType == 'narctrack' || payload.contentType == 'dailynarctrack' || payload.contentType == 'logbook' || payload.contentType == 'dailyvehicle' || payload.contentType == 'repairform') {
        payload.stationVar = payload.stationVar + payload.query
      }
      axios({
        method: 'get',
        url: '/api/v1/content/' + payload.contentType + '/' + payload.place + '/' + payload.stationVar,
        headers: payload.headers.headers })
        .then((response) => {
          commit(commitType, { list: response.data })
          dispatch('CHECK_RESPONSE_CODE', response.status)
        }, (err) => {
          console.log(err)
          dispatch('CHECK_RESPONSE_CODE', err.response.data.code)
        })
    },
    CHECK_RESPONSE_CODE ({ dispatch, state }, code) {
      if (code == 200) {
        local.getItem('loginTime').then(function (value) {
          if (value) {
            var unix = moment().unix()
            var time = moment(unix).isAfter(value)
            if (time == false) {
            } else if (time == true) {
              local.getItem('LoginPersist').then(function (value) {
                if (value) {
                  console.log('LoginPersisted')
                  dispatch('REFRESH_LOGIN_TOKEN', value)
                } else {
                  dispatch('LOGOUT_OUT_USER')
                }
              })
            }
          } else {
            var time = false
          }
        }).catch(function (err) {
          // This code runs if there were any errors
          console.log(err)
        })
      } else if (code == 401) {
        dispatch('LOGOUT_OUT_USER')
      } else {
        console.log(code)
      }
    },
    REFRESH_LOGIN_TOKEN ({ commit, dispatch, state }, data) {
      console.log('refreshed')
      //     console.log(state.refreshToken)
      // console.log(data)
      axios({
        method: 'post',
        url: '/api/v1/auth/refresh-token',
        data: {
          'email': data.email,
          'refreshToken': data.refreshToken
        },
        headers: {
          'Content-Type': 'application/json'
        } })
        .then((response) => {
          dispatch('LOAD_LOGIN_STATUS', response.data.accessToken)
          //, commit('SET_SESSION_TOKEN', { list: response.data })//
        }, (err) => { console.log(err) })
    },
    CLEAR_DATA_STORE ({ commit }) {
      var list = []
      commit('SET_CAL_EVENT', { list })
      commit('SET_CAL_DAILYVEHICLE', { list })
      commit('SET_CAL_REPAIRFORM', { list })
      commit('SET_CAL_LOGBOOK', { list })
      commit('SET_APP_NARCS', { list })
      commit('SET_CAL_INVENTORYSUB', { list })
    }

  },
  mutations: {
    SET_SITE_CONFIG: (state, { list }) => { state.siteconfig = list },
    SET_USER_DATA_STORE: (state, { list }) => {
      var user = list.user
      if(user && user.role == 'admin'){
        user['superUser'] = true
      }
      state.userData = user
    },
    UPDATE_SUPER_USER_ROLE: (state, { list }) => {
      var current = state.userData
      current[list.type] = list.value
      console.log(current)
      state.userData = current
    },
    SET_LOGIN_PERSIST: (state, { list }) => {
      var items = list
      var type = 'LoginPersist'

      local.setItem(type, items).then(function (value) {
      }).catch(function (err) {
        console.log(err)
      })
    },
    SET_LOGIN_STATUS: (state, { list }) => { state.loginStatus = list },
    SET_BASIC_AUTH: (state, { list }) => { state.userAutho = list },
    SET_SESSION_TOKEN: (state, { list }) => {
      state.loadApplication = 1
      var authString = 'bearer ' + list.accessToken
      VueCookie.set('userToken', list.accessToken, { expires: '1D' })
      state.getSessionData = authString
      state.refreshToken = list.refreshToken
      if (list.accessToken != '') {
        var time = moment().add(24, 'hour').unix()
        local.setItem('loginTime', time).then(function (value) {
        }).catch(function (err) {
          console.log(err)
        })
      } else {
        VueCookie.remove('userToken')
        local.removeItem('LoginPersist').then(function (value) {
        }).catch(function (err) {
          console.log(err)
        })
        local.removeItem('loginTime').then(function (value) {
        }).catch(function (err) {
          console.log(err)
        })
      }
      state.config = {
        headers: { 'content-type': 'application/json', 'Authorization': authString }
      }
      state.configGetLogout = {
        headers: { 'content-type': 'text/html', 'Authorization': authString }
      }
    },
    SET_SESSION_XTOKEN: (state, { list }) => {
      state.getSessionDataX = list
    },
    SET_USER_POSTS: (state, { list, contentType }) => {
      list.forEach(item => {
        item['contentType'] = contentType
        return item
      })
      for (var i = 0; i < list.length; i++) {
        state.userPosts.push(list[i])
      }
    },
    SET_ALL_POSTS: (state, { list, contentType }) => {
      state['all' + contentType] = list
    },
    SET_CLEAR_USER_POSTS: (state, { list }) => {
      state.userPosts = []
    },
    /** TAXONOMY  */
    SET_LOCAL_FORGE_DATA: (state, { list, type }) => {
      local.setItem(type, list).then(function (value) {
      }).catch(function (err) {
        console.log(err)
      })
      var time = moment().add(4, 'd').unix()
      local.setItem(type + '_expire', time).then(function (value) {

      }).catch(function (err) {
        console.log(err)
      })
    },
    SET_TAX_STATION: (state, { list, type }) => {
      var data = _.orderBy(list, ['stationName'], ['asc'])
      state.stationsData = data
    },
    SET_NOTIFICATION_DATA: (state, { list }) => {
      state.notificationData = list
    },
    SET_UPDATE_TABLE: (state, { list }) => {
      state.updateTable = list.map(item => {
        let obj = item
        let name = item.table_name.split('.')
        obj.table_name = name[1]
        return obj
      })
    },
    SET_INVENTORY_TEMPLATES: (state, { list }) => { state.inventoryTemplateData = list },
    SET_TAX_BLOGTYPE: (state, { list }) => { state.blogType = list },
    SET_TAX_WORKGROUP: (state, { list }) => { state.workgroupData = list },
    SET_TAX_REPAIRSTATUS: (state, { list }) => { state.repairStatus = list },
    SET_TAX_EVENTTYPE: (state, { list }) => { state.eventTags = list },
    SET_TAX_DEPTRANK: (state, { list }) => { state.departmentRanks = list },
    SET_TAX_DEPTSPEC: (state, { list }) => { state.departmentSpecialties = list },
    SET_TAX_DOCUMENTTYPE: (state, { list }) => { state.documentTags = list },
    SET_TAX_SHIFTROTATION: (state, { list }) => { state.shiftsData = list },
    SET_TAX_APPARATUS: (state, { list }) => { state.apparatusData = _.orderBy(list, ['apparatusName'], ['asc']) },
    SET_TAX_CHECKDETAILS: (state, { list }) => { state.vehicleCheckDetails = list },
    SET_TAX_CHECKITEMS: (state, { list }) => { state.vehicleCheckItems = list },
    SET_TAX_MEDICATION: (state, { list }) => { state.medicationData = list },
    SET_TAX_TUTORIAL: (state, { list }) => { state.departmentTutorials = list },
    SET_TAX_TRAININGREF: (state, { list }) => { state.trainingrefData = list },
    SET_TAX_VEHICLETYPE: (state, { list }) => { state.vehicleTypeData = list },
    SET_TAX_LOGISTICASSET: (state, { list }) => { state.logisticAssetData = list },
    SET_FORMTEMP_DATA: (state, { list }) => { state.formtempData = list },

    /** STAFFING */

    SET_STAFFING_PAYROLLACCURAL: (state, { list }) => { state.payrollAccuralData = _.orderBy(list, ['payrollAccuralName'], ['asc']) },
    SET_STAFFING_PAYROLLBANK: (state, { list }) => { state.payrollBanksData = _.orderBy(list, ['payrollBankName'], ['asc']) },
    SET_STAFFING_PAYROLLCODE: (state, { list }) => { state.payrollCodesData = list },
    SET_STAFFING_ROSTER: (state, { list }) => { state.rosterData = list },
    SET_STAFFING_ROSTERPROFILE: (state, { list }) => { state.rosterProfiles = list },
    SET_STAFFING_PAYROLLACCURALASSIGNED: (state, { list }) => { state.payrollAccuralAssignedData = list },

    /** CHAT */
    SET_ACTIVE_USERS: (state, { list }) => {
      state.activeUsers = list
    },

    /** Messages */
    SET_CLEAR_USER_MESSAGES: (state) => {
      state.unreadGroupMessages = []
      state.unreadMessages = []
      state.unreadRoomMessages = []
      state.userMessages = []
      state.roomData = []
      state.groupData = []
    },
    SET_USER_MESSAGES: (state, { list }) => {
      state.unreadMessages = []
      state.userMessages = list
    },
    SET_MESSAGE_TRACKING: (state, { list }) => {
      //   console.log(list)
      if (list.id) {
        var ids = [...new Set(state.unreadMessages.map(p => p.id))]
        var index = ids.indexOf(list.id)
        if (index < 0) {
          state.unreadMessages.push(list)
        } else if (list === 'REFRESH') {
          //   console.log('REFRESH')
          //   state.unreadMessages = []
        }
      }
    },
    REMOVE_SET_MESSAGE_TRACKING: (state, { list }) => {
      var ids = [...new Set(state.unreadMessages.map(p => p.id))]
      var index = ids.indexOf(list)
      if (index >= 0) { state.unreadMessages.splice(index, 1) }
    },
    /** Rooms */
    SET_ROOM_DATA: (state, { list }) => {
      state.unreadRoomMessages = []
      state.roomData = list
    },
    SET_ROOM_MESSAGE_TRACKING: (state, { list }) => {
      var ids = [...new Set(state.unreadRoomMessages.map(p => p.id))]
      var index = ids.indexOf(list.id)
      if (index < 0) { state.unreadRoomMessages.push(list) }
    },
    REMOVE_SET_ROOM_MESSAGE_TRACKING: (state, { list }) => {
      var ids = [...new Set(state.unreadRoomMessages.map(p => p.id))]
      var index = ids.indexOf(list)
      if (index >= 0) { state.unreadRoomMessages.splice(index, 1) }
    },
    /** Groups */
    SET_GROUP_MESSAGE_DATA: (state, { list }) => {
      state.unreadGroupMessages = []
      state.groupData = list
    },
    SET_GROUP_MESSAGE_TRACKING: (state, { list }) => {
      var ids = [...new Set(state.unreadGroupMessages.map(p => p.id))]
      var index = ids.indexOf(list.id)
      if (index < 0) { state.unreadGroupMessages.push(list) }
    },
    REMOVE_GROUP_ROOM_MESSAGE_TRACKING: (state, { list }) => {
      var ids = [...new Set(state.unreadGroupMessages.map(p => p.id))]
      var index = ids.indexOf(list)
      if (index >= 0) { state.unreadGroupMessages.splice(index, 1) }
    },
    /** CONTENT STATION CALENDAR */
    SET_CAL_EVENT: (state, { list }) => { state.stationCalEvents = _.orderBy(list, ['eventDate'], ['asc']) },
    SET_CAL_LOGBOOK: (state, { list }) => { state.stationCalLogbooks = list },
    SET_CAL_INVENTORYSUB: (state, { list }) => { state.stationCalInventorySub = list },
    SET_CAL_DAILYVEHICLE: (state, { list }) => { state.stationCalVehicleChecks = list },
    SET_CAL_NARCTRACK: (state, { list }) => { state.apparatusAppNarcs = list },
    SET_CAL_DAILYNARCTRACK: (state, { list }) => { state.apparatusDailyAppNarcs = list },
    SET_CAL_REPAIRFORM: (state, { list }) => { state.stationCalRepair = list },
    SET_DEPT_BLOG: (state, { list }) => { state.departmentBlogs = list },
    SET_DOCUMENT_API: (state, { list }) => { state.documentsApi = list },
    SET_TRAINING_ITEM_API: (state, { list }) => { state.trainingItemData = list },
    SET_TRAINING_MODULES: (state, { list }) => { state.trainingDrills = list },
    SET_MEDICATION_DATA: (state, { list }) => { state.medsNarctrack = list },
    
    /** CONTENT SECONDARY MENU */
    SET_SEC_EVENT: (state, { list }) => { state.calendarEvents = _.orderBy(list, ['eventDate'], ['asc']) },
    SET_SEC_LOGBOOK: (state, { list }) => { state.stationLogbooks = list },
    SET_SEC_DAILYVEHICLE: (state, { list }) => { state.vehicleChecks = list },
    SET_SEC_NARCTRACK: (state, { list }) => { state.dailyNarcs = list },
    SET_SEC_DAILYNARCTRACK: (state, { list }) => { state.dailyNarctracks = list },
    SET_SEC_REPAIRFORM: (state, { list }) => { state.stationRepair = list },

    SET_PARENT_GROUP_DATA: (state, { list }) => { state.parentGroup = list },
    SET_TRAINING_LOCATIONS: (state, { list }) => { state.trainingLocations = list },
    SET_TRAINING_CATS: (state, { list }) => { state.trainingCats = list },
    SET_FULL_CAL_EVENTS: (state, { list }) => { state.fullCalendarEvents = list },
    SET_FULL_CAL_EVENTS_PRIVATE: (state, { list }) => { state.fullCalendarEventsPrivate = list },
    SET_DEPT_TUTORIALS: (state, { list }) => { state.departmentTutorials = list },

    SET_CURRENT_USER: (state, { list }) => { state.userData = list },

    SET_USER_TAG_LIST: (state, { list }) => { state.userTagList = list },
    SET_DOCUMENTS_API: (state, { list }) => { state.documentsApi = list },
    SET_BANNER_IMAGES: (state, { list }) => { state.bannerImages = list },

    SET_FORM_TYPE: (state, { list }) => { state.formTypeData = list },
    SET_CAL_STATIONIMG: (state, { list }) => { state.stationCalImages = list },
    SET_CAL_STATISTICS: (state, { list }) => { state.stationCalStatistics = list },

    SET_APP_NARCS: (state, { list }) => { state.apparatusAppNarcs = list },
    SET_CONTACT_DATA: (state, { list }) => {
      //console.log(state.config)
      if(state.siteconfig.removeSenority){
      var holdlist = _.orderBy(list, ['lastName'], ['asc'])
      } else {
      var holdlist = _.orderBy(list, ['hireDate'], ['asc'])
      }
      state.fullDeptContactData = holdlist
      var newlist = holdlist.filter(p => {
        if (p.deactivated != undefined) {
          return p.deactivated == false
        } else {
          return p
        }
      })
      var templist = newlist.filter(p => {
        if (p.role != undefined) {
          return p.role != 'admin'
        } else {
          return p
        }
      })
      state.deptContactData = templist
    }

  },
  getters: {

  },
  modules: {

  }

})
export default store
